import { shouldUseInsuranceMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'
import { PRODUCT_TYPE_LUX_PLUS } from 'constants/offer'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { createSelector } from 'reselect'
import { getBaseTierOfferWithNoJoiningFee, getBaseTierOfferWithJoiningFee } from 'selectors/luxPlusSelectors'

export const getLuxPlusPointsCalculationOptions = createSelector(
  getBaseTierOfferWithJoiningFee,
  getBaseTierOfferWithNoJoiningFee,
  (
    baseTierOfferWithFee,
    baseTierOfferNoFee,
  ): {
    withJoinFee: Array<App.LuxLoyaltyPointsCalculatorOptions>,
    noJoinFee: Array<App.LuxLoyaltyPointsCalculatorOptions>,
  } => ({
    withJoinFee: [{
      price: (baseTierOfferWithFee?.price ?? 0) + (baseTierOfferWithFee?.joinFee ?? 0),
      productType: PRODUCT_TYPE_LUX_PLUS,
      margin: 0,
    }],
    noJoinFee: [{
      price: (baseTierOfferNoFee?.price ?? 0),
      productType: PRODUCT_TYPE_LUX_PLUS,
      margin: 0,
    }],
  }),
)

export const getPointsCalculationsByKeys = createSelector(
  (state: App.State) => state.luxLoyalty.pointsCalculator,
  (_state: App.State, pointCalculationKeys: Array<string>) => pointCalculationKeys,
  (pointsCalculator, keys): Array<App.LuxLoyaltyPointsCalculatorResult | undefined> => keys.map(key => pointsCalculator[key]),
)

export function shouldUseInsuranceMemberPriceForCalculation(state: App.State) {
  const luxPlusEnabled = isLuxPlusEnabled(state)
  const luxPlusDiscountedInsurance = shouldUseInsuranceMemberPrice(state)
  const accountTier = state.luxLoyalty.account.data?.tier

  // Current business logic but subject to change
  return luxPlusDiscountedInsurance || (luxPlusEnabled && (accountTier === 'gold' || accountTier === 'platinum'))
}

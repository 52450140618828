import { css } from 'styled-components'
import { mediaQueryDown } from 'components/utils/breakpoint'

export default css`
  ${mediaQueryDown.desktop} {
    .genesys-app {
      display: none;
    }
  }
`

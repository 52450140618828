export function getRegionCodeFromPathname(pathname: string) {
  const matchData = pathname.match(/^\/([a-zA-Z]{2})$/) || pathname.match(/^\/([a-zA-Z]{2})(\/|\?)/)
  return matchData ? matchData[1].toUpperCase() : null
}

export function pathnameHasRegionCode(pathname: string, regionCode: string) {
  return getRegionCodeFromPathname(pathname)?.toLowerCase() === regionCode.toLowerCase()
}

export function removeRegionCode(path: string, regionCode: string) {
  if (pathnameHasRegionCode(path, regionCode)) {
    if (path.substr(3, 1) !== '/') {
      return '/' + path.substr(3)
    }
    return path.substr(3)
  }
  return path
}

import React, { ComponentProps } from 'react'
import _PriceRowPrice from './_PriceRowPrice'

type Props = Pick<ComponentProps<typeof _PriceRowPrice>, 'data-testid' | 'discountPercentage' | 'discountType' | 'price' | 'priceFormat' | 'saleUnit' | 'signDisplay' | 'size'>

/**
 * Displays a formatted price with a trailing sale unit.
 */
function PriceRowPrice(props: Props) {
  return <_PriceRowPrice {...props} className={PriceRowPrice.name} />
}

export default PriceRowPrice

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAppleAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M12.2423 3.3076c.4941-.4837 1.1155-.842 1.8106-1.1894.4939-.247 1.0946-.0467 1.3416.4473.2469.494.0466 1.0947-.4474 1.3416-.9771.4885-1.3638.8039-1.5809 1.1802-.1603.2779-.274.6684-.3289 1.3405.6082-.267 1.2679-.4152 1.9627-.4152 1.6248 0 3.1525.584 4.2686 1.7868C20.3816 8.9988 21 10.7202 21 12.846c0 2.1767-.8554 4.4175-2.0674 6.1143-1.1783 1.6496-2.8973 3.0524-4.7659 3.0524-.8112 0-1.538-.2206-2.1667-.6094-.6287.3888-1.3555.6094-2.1667.6094-1.8686 0-3.5876-1.4028-4.7659-3.0524C3.8554 17.2635 3 15.0227 3 12.846c0-2.1258.6184-3.8472 1.7314-5.0466.7754-.8356 1.7495-1.3725 2.8167-1.6225a3.622 3.622 0 0 1-.3303-.7073c-.2525-.7355-.294-1.588-.1313-2.5664a1 1 0 0 1 .8621-.8281c1.2001-.1505 2.1895-.0778 2.9828.2453.5681.2314.9969.5756 1.3109.9872ZM9.1094 4.8203c-.0715-.2082-.1202-.4753-.1197-.8197.5569-.0082.9345.069 1.1873.172.3221.1312.4946.3239.6112.5773.1331.2891.2012.6803.2216 1.1864.0038.0965.006.19.007.2822-.5313-.1606-.9819-.3447-1.3354-.6167-.266-.2047-.4582-.4501-.572-.7815Zm-2.912 4.3395C5.501 9.9105 5 11.1058 5 12.846c0 1.6892.6806 3.5318 1.6949 4.9518 1.048 1.4672 2.2456 2.2149 3.1384 2.2149.5814 0 1.0661-.206 1.4877-.5959a1 1 0 0 1 1.358 0c.4216.3899.9063.5959 1.4877.5959.8928 0 2.0904-.7477 3.1384-2.2149C18.3194 16.3778 19 14.5352 19 12.846c0-1.7402-.5009-2.9355-1.1975-3.6862C17.109 8.4124 16.1367 8.0127 15 8.0127c-.8164 0-1.6091.3539-2.2886 1.0417a1 1 0 0 1-1.4228 0C10.6091 8.3666 9.8163 8.0127 9 8.0127c-1.1367 0-2.109.3997-2.8025 1.147Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineAppleAltIcon

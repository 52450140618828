import request from 'api/requestUtils'
import config from 'constants/config'
import qs from 'qs'

interface BlogPostOptions {
  categoryIds?: Array<number>;
  tagIds?: Array<number>;
  limit?: number;
}

function mapServerBlogPost(serverPost: any): App.DreamSiteBlogPost {
  const usefulData = serverPost.yoast_head_json
  const image = usefulData.og_image[0]

  return {
    id: serverPost.id,
    categories: serverPost.categories,
    tags: serverPost.tags,
    image: image ? {
      url: image.url,
      title: serverPost.title.rendered,
    } : {},
    shortDescription: usefulData.description,
    title: serverPost.title.rendered,
    url: serverPost.link,
  }
}

export function getDreamSiteBlogPosts(options: BlogPostOptions) {
  const query = qs.stringify({
    categories: options.categoryIds,
    tags: options.tagIds,
    per_page: options.limit,
  }, { arrayFormat: 'comma' })

  return request.get(`/posts?${query}`, {}, config.DREAM_SITE_API_URL).then(response => {
    // dream site returns response directly, doesn't match our API
    return (response as Array<any>).map(mapServerBlogPost)
  })
}

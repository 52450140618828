import { Models } from '@luxuryescapes/lib-flights-types'
import { FlightSelectParams, selectflights } from 'api/flights'
import { mapAddedBaggageToPaxMap } from 'checkout/lib/utils/flights/extras'
import { getOtherFees } from 'checkout/lib/utils/flights/fees'
import { buildBaggageMapFromFares, applyTravelFusionComplementaryReducer } from 'checkout/lib/utils/flights/finalFares'
import { isOneWayFlight } from 'lib/flights/flightUtils'
import { isEmptyObject } from 'lib/object/objectUtils'
import { AppDispatch } from '../store'
import { API_CALL, CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE, CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE } from './actionConstants'
import { FETCH_FINAL_FLIGHT_FARES } from './apiActionConstants'
import { OptimizelyExperiments } from 'constants/optimizely'
import { AppAction } from './ActionTypes'

type Actions = Utils.FullActionMap<{
  [CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE]: { itemId: string, flightIndex: number, paxBaggageMap: App.Checkout.PaxBaggageMap }
  [CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE]: { itemId: string, newTotalFare: number, newDepartingTotalFare?: number, newReturningTotalFare?: number, newFlightLegFares?: Array<number> },
}>

export function updateFlightPaxBaggage(
  itemId: string,
  flightIndex: number,
  paxBaggageMap: App.Checkout.PaxBaggageMap,
): Actions['CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE'] {
  return {
    type: CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE,
    itemId,
    flightIndex,
    paxBaggageMap,
  }
}

function applyComplementaryBaggageSelections(
  dispatch: AppDispatch,
  selectedFares: Array<{ flightGroups: Models.Search.FlightGroup }>,
  targetItem: App.Checkout.FlightItem,
  flightIndex: number,
) {
  const passengers = targetItem.passengers
  /**
   * @todo Get proper types for flight group, does not match the modals above
   */
  const flightGroupFares = selectedFares?.map(fare => fare.flightGroups[0]) || []
  const oneWay = isOneWayFlight(targetItem.fareType)

  const isComplementaryBaggageApplicable = (oneWay && flightGroupFares.length === 1) || (!oneWay && flightGroupFares.length === 2)

  if (isComplementaryBaggageApplicable) {
    const departingComplementaryQuantityMap = buildBaggageMapFromFares(flightGroupFares[0])

    if (!isEmptyObject(departingComplementaryQuantityMap)) {
      const departingMap = passengers.reduce<App.Checkout.PaxBaggageMap>(
        applyTravelFusionComplementaryReducer(departingComplementaryQuantityMap), {})
      dispatch(updateFlightPaxBaggage(targetItem.itemId, flightIndex, departingMap))
    }

    if (!oneWay) {
      const returningComplementaryQuantityMap = buildBaggageMapFromFares(flightGroupFares[1])
      if (!isEmptyObject(returningComplementaryQuantityMap)) {
        const returningMap = passengers.reduce<App.Checkout.PaxBaggageMap>(
          applyTravelFusionComplementaryReducer(returningComplementaryQuantityMap), {})
        dispatch(updateFlightPaxBaggage(targetItem.itemId, flightIndex, returningMap))
      }
    }
  }
}

export function getCheckoutFlightItemFinalFare(item: App.Checkout.FlightItem, itemIndex: number): AppAction {
  return function(dispatch, getState) {
    const state = getState()
    if (item.searchId && item.flights[0] && (item.fareType !== 'return' || item.flights[1])) {
      const selectedFareIds = item.flights.map(flight => flight.journeyId)

      const params: FlightSelectParams = {
        journeyId: item.searchId,
        departingJourneyKey: item.flights[0].journeyKey,
        departingFareFamilyId: item.flights[0].fareFamily?.id,
        returningJourneyKey: item.fareType == 'return' ? item.flights[1]?.journeyKey : undefined,
        returningFareFamilyId: item.flights[1]?.fareFamily?.id,
        departingAirport: item.originAirportCode,
        arrivalAirport: item.destinationAirportCode,
        providerSearchId: item.flights[0].providerSearchId,
        region: state.geo.currentRegionCode,
        isStandalone: !item.bundledItemIds?.length,
        provider: item.flights[0].provider,
        isFlightsCredit: item.isFlightCredit,
        viewType: item.viewType,
        selectedFareIds,
        isFlightMerchantFeeExperimentOn: state.optimizely.optimizelyExperiments[OptimizelyExperiments.paymentsFlightsMerchantFees]?.enabled,
        forceBundleId: item.forceBundleId,
      }

      dispatch({
        itemId: item.itemId,
        type: API_CALL,
        api: FETCH_FINAL_FLIGHT_FARES,
        request: () => selectflights(params).then(data => {
          const { priceSummary, selectedFares } = data.result
          const totalFare = priceSummary?.find(price => price.type === 'total_fare')?.amount
          const otherFees = getOtherFees(item, priceSummary)

          if (totalFare) {
            applyComplementaryBaggageSelections(dispatch, selectedFares, item, itemIndex)
          }

          const costs = selectedFares?.map(fare => Number(fare.price.all?.totalFareOriginalCurrency)) ?? []

          return {
            totalFare,
            otherFees,
            costs,
            journeysById: state.flights.journeysById,
          }
        }),
      })
    }
  }
}

export function updateFlightTotalFare(
  itemId: string,
  newTotalFare: number,
  newDepartingTotalFare?: number,
  newReturningTotalFare?: number,
  newFlightLegFares?: Array<number>,
): Actions['CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE'] {
  return {
    type: CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE,
    itemId,
    newTotalFare,
    newDepartingTotalFare,
    newReturningTotalFare,
    newFlightLegFares,
  }
}

export function updateCheckoutFlightCheckedBaggage(
  itemId: string,
  flightIndex: number,
  baggage: Array<Array<App.AddedBaggage>>,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const flightItem = state.checkout.cart.items.find(item => item.itemId === itemId)
    if (flightItem?.itemType === 'flight') {
      const paxBaggageMap = mapAddedBaggageToPaxMap(flightItem.passengers, baggage)

      dispatch(updateFlightPaxBaggage(itemId, flightIndex, paxBaggageMap))
    }
  }
}

import React, { ComponentProps, forwardRef, ReactElement } from 'react'
import _SearchTileBase from './_SearchTileBase'
import _SearchTileContent from './_SearchTileContent'
import _SearchTileContentPrimary from './_SearchTileContentPrimary'
import _SearchTileContentSecondary from './_SearchTileContentSecondary'
import _SearchTileHero from './_SearchTileHero'
import _SearchTilePricing from './_SearchTilePricing'

type Inherited_SearchTileBaseProps = Pick<ComponentProps<typeof _SearchTileBase>, 'state' | 'banner'>
type InheritedSearchTileContentProps = Pick<ComponentProps<typeof _SearchTileContent>, 'extension'>
type InheritedSearchTileContentPrimaryProps = Pick<ComponentProps<typeof _SearchTileContentPrimary>, 'cancellationPolicyLabel' | 'features' | 'labels' | 'logo' | 'meta' | 'overline' | 'rating' | 'subtitle' | 'title'>
type InheritedSearchTileHeroProps = Pick<ComponentProps<typeof _SearchTileHero>, 'bookmarkAction' | 'images' | 'onImageChange' | 'onImageLoad' | 'productLabel'>
type InheritedSearchTilePricingProps = Pick<ComponentProps<typeof _SearchTilePricing>, 'action'>

export interface SearchTileLogo {
  full: App.Image;
  compact: App.Image
}

interface Props extends Inherited_SearchTileBaseProps, InheritedSearchTileContentProps, InheritedSearchTileContentPrimaryProps, InheritedSearchTileHeroProps, InheritedSearchTilePricingProps, Utils.PropsWithDataAttributes {
  productType: App.ProductType
  offerType: App.OfferType
  logo?: SearchTileLogo
  /**
   * Price point rows and stacks
   */
  pricePoint?: ReactElement | false
  className?: string
}

const SearchTile = forwardRef<HTMLDivElement, Props>(function SearchTile(
  {
    productType,
    offerType,
    state,
    banner,
    bookmarkAction,
    cancellationPolicyLabel,
    features,
    images,
    labels,
    logo,
    meta,
    overline,
    productLabel,
    pricePoint,
    action,
    rating,
    title,
    className,
    subtitle,
    extension,
    onImageChange,
    onImageLoad,
    ...dataAttributes
  },
  ref,
) {
  return <_SearchTileBase
    {...dataAttributes}
    data-product={productType}
    data-offer={offerType}
    state={state}
    banner={banner}
    className={className}
    ref={ref}
  >
    <_SearchTileHero
      bookmarkAction={bookmarkAction}
      images={images}
      productLabel={productLabel}
      onImageChange={onImageChange}
      onImageLoad={onImageLoad}
    />
    <_SearchTileContent extension={extension}>
      <_SearchTileContentPrimary
        cancellationPolicyLabel={cancellationPolicyLabel}
        features={features}
        labels={labels}
        meta={meta}
        overline={overline}
        rating={rating}
        title={title}
        subtitle={subtitle}
        logo={logo}
      />
      <_SearchTileContentSecondary rating={rating} logo={logo}>
        <_SearchTilePricing action={action}>
          {pricePoint}
        </_SearchTilePricing>
      </_SearchTileContentSecondary>
    </_SearchTileContent>
  </_SearchTileBase>
})
export default SearchTile

export function descriptionBuilder(
  name: string,
  price: number,
  value: number,
  brand: string,
  brandTitle: string,
) {
  const formattedBrand = (brand === 'luxuryescapes') ? `${brandTitle} Travel` : brandTitle

  if (price && value && price !== value) {
    const percent = Math.round((1 - price / value) * 100)
    const descStart = `Today's ${formattedBrand} Deal: ${name}.`
    const descEnd = `Buy Now & Save ${percent}% on ${formattedBrand} Deals.`
    return descStart + ' ' + descEnd
  } else {
    return `Today's ${formattedBrand} Deal: ${name}.`
  }
}

import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import noop from 'lib/function/noop'
import styled from 'styled-components'
import cn from 'clsx'

const Root = styled.input`
  &.is-uppercase {
    text-transform: uppercase;
  }
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onDirtyChange?: (isDirty: boolean) => void;
  onErrorUpdate?: (input: HTMLInputElement) => void;
  uppercase?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    onDirtyChange = noop,
    onErrorUpdate = noop,
    onChange = noop,
    onInvalid = noop,
    onBlur = noop,
    type = 'text',
    uppercase,
    ...inputProps
  } = props
  const [isDirty, setDirty] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(ref, () => inputRef.current!)

  useEffect(() => {
    // value changed and has a value, must be a controlled component , re-evaluate our validity
    if (inputProps.value && inputRef.current) {
      updateErrorMessage(inputRef.current)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputProps.value])

  const onSetDirty = () => {
    if (!isDirty) {
      onDirtyChange(true)
      setDirty(true)
    }
  }

  const updateErrorMessage = (input: HTMLInputElement) => {
    const validity = input.validity
    if (validity) {
      onErrorUpdate(input)
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateErrorMessage(e.currentTarget)
    onChange(e)
  }

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onSetDirty()
    updateErrorMessage(e.currentTarget)
    onBlur(e)
  }

  const onInputInvalid = (e: React.InvalidEvent<HTMLInputElement>) => {
    onSetDirty()
    updateErrorMessage(e.currentTarget)
    onInvalid(e)
  }

  return (<Root
    {...inputProps}
    className={cn(inputProps.className, cn({ 'is-uppercase': uppercase }))}
    type={type}
    ref={inputRef}
    onChange={onInputChange}
    onBlur={onInputBlur}
    onInvalid={onInputInvalid}
  />)
})

Input.displayName = 'Input'

export default Input

import { getTypeaheadSuggestions } from 'api/search'
import GeoContext from 'contexts/geoContext'
import { GlobalSearchDispatchContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import debounce from 'debounce-promise'
import { useContext, useEffect, useMemo, useState } from 'react'

interface Args {
  search: string
  typeaheadTypes?: Array<App.SearchPlaceType>
  searchTypes?: Array<App.SearchType>
  characterThreshold?: number
  debounceDelay?: number
}

const useGlobalSearchTypeahead = ({
  search,
  typeaheadTypes,
  searchTypes,
  characterThreshold = 1,
  debounceDelay = 300,
}: Args) => {
  const { currentRegionCode } = useContext(GeoContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  const getDebouncedPredictions = useMemo(
    () =>
      debounce((search: string) => {
        getTypeaheadSuggestions(search, currentRegionCode, {
          types: typeaheadTypes,
          searchTypes,
        })
          .then((searchItems) => {
            globalSearchDispatch({ type: GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS, searchItems })
            setIsError(false)
            setIsLoading(false)
          })
          .catch(() => setIsError(true))
      }, debounceDelay),
    [currentRegionCode, typeaheadTypes, searchTypes, debounceDelay, globalSearchDispatch],
  )

  useEffect(() => {
    if (search.length >= characterThreshold) {
      setIsLoading(true)
      getDebouncedPredictions(search)
    }
  }, [search, getDebouncedPredictions, characterThreshold])

  return { isLoading, isError }
}

export default useGlobalSearchTypeahead

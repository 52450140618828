import VillasPageAmenity from 'components/OfferPage/Villas/VillasPageAmenity'
import { mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: ${rem(4)};
  overflow: hidden;

  ${mediaQueryOnly.mobile} {
    grid-auto-rows: 0;
    grid-template-rows: auto auto auto;

    > *:nth-child(n+10) {
      display: none;
    }
  }

${mediaQueryUp.desktop} {
  gap: ${rem(8)};
  grid-template-columns: auto auto;
  text-align: start;

  > *:nth-child(n+7) {
    display: none;
  }
}
`

interface Props {
  amenities: Array<App.PackageRoomAmenity>
}

function SearchRentalAmenities({ amenities }: Props) {
  return (<AmenitiesGrid>
    {amenities.map((amenity) => (<VillasPageAmenity key={amenity.description} amenity={amenity} />))}
  </AmenitiesGrid>)
}

export default SearchRentalAmenities

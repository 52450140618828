import type { BookmarkRequest, BookmarkResponse } from './types'
import { baseUri, getOptions } from '../utils'

import request from 'api/requestUtils'

export async function processBookmarks(
  { region, items, tripIdsAdded, tripIdsRemoved }: BookmarkRequest,
  accessToken?: string,
): Promise<BookmarkResponse['result']> {
  return request
    .post<BookmarkResponse, BookmarkRequest>(
      `${baseUri}/bookmark`,
      {
        items,
        region,
        tripIdsAdded,
        tripIdsRemoved,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

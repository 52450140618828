import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBlenderIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11.5 10c.5523 0 1 .4477 1 1v2c0 .5523-.4477 1-1 1s-1-.4477-1-1v-2c0-.5523.4477-1 1-1Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M8.5 2c-1.6569 0-3 1.3432-3 3v.5H3a1 1 0 0 0-.8137 1.5812l2.3654 3.3116 1.462 6.7716a.998.998 0 0 0 .2894.5528l-1.224 2.8932A.9998.9998 0 0 0 6 22h11a1.0002 1.0002 0 0 0 .921-1.3896l-1.2241-2.8932a.9969.9969 0 0 0 .2999-.6375L17.1632 16H19c1.6569 0 3-1.3431 3-3v-2c0-1.6569-1.3431-3-3-3h-1.1168l.1136-1.4203A.9998.9998 0 0 0 17 5.5h-.5V5c0-1.6568-1.3431-3-3-3h-5Zm6.145 16h-6.29l-.8461 2h7.9822l-.8461-2Zm.4318-2 .84-8.5H4.9432l1.3705 1.9188a.9988.9988 0 0 1 .1727.4168L7.8471 16h7.2297ZM19 14h-1.6768l.4-4H19c.5523 0 1 .4477 1 1v2c0 .5523-.4477 1-1 1Zm-4.5-8.5V5c0-.5523-.4477-1-1-1h-5c-.5523 0-1 .4477-1 1v.5h7Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineBlenderIcon

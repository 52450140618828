import moment from 'moment'
import React, { ComponentProps, forwardRef, useCallback, useMemo, useState } from 'react'
import DatePicker from 'components/Common/Calendar/DatePicker'
import useToggle from 'hooks/useToggle'
import noop from 'lib/function/noop'
import DateInput from './DateInput'
import { DMY_DATE_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import TextInput from './TextInput'
import useForwardedRef from 'hooks/useForwardedRef'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'

export type DatePickerInputDateChangeHandler = (date?: Date, isoDate?: string) => void;

interface Props extends Partial<Omit<React.ComponentProps<typeof DateInput>, 'onChange' | 'type'>> {
  onDateChange?: DatePickerInputDateChangeHandler;
  dropdownPlacement?: ComponentProps<typeof DropdownSheet>['placement'];
  defaultMonth?: number;
  defaultYear?: number;
  dayRender?: Exclude<React.ComponentProps<typeof DatePicker>['dayRender'], undefined>
}

const DatePickerInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    onDateChange = noop,
    value,
    dropdownPlacement,
    defaultMonth,
    defaultYear,
    onFocus,
    defaultValue,
    dayRender,
    ...inputProps
  } = props
  const triggerRef = useForwardedRef<HTMLInputElement>(ref)
  const [open, toggleOpen, openDropdown, closeDropdown] = useToggle()
  const [localValue, setLocalValue] = useState(value ?? defaultValue)

  const onDatePickerChange = useCallback((value: Date) => {
    const isoDate = moment(value).format(ISO_DATE_FORMAT)
    setLocalValue(isoDate)
    onDateChange(value, isoDate)
    closeDropdown()
  }, [onDateChange, closeDropdown])

  const onInputChange = useCallback((date: string | undefined, isoDate: string | undefined) => {
    setLocalValue(isoDate)
    onDateChange(isoDate ? new Date(isoDate) : undefined, isoDate)
  }, [onDateChange])

  const onNativeInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.currentTarget.valueAsDate
    // native inputs give 'null' back for cleared, but we prefer undefined to match the type
    const nextValue = date ?? undefined
    const isoDate = date ? moment(date).format(ISO_DATE_FORMAT) : undefined
    setLocalValue(isoDate)
    onDateChange(nextValue, isoDate)
  }, [onDateChange])

  const finalValue = value ?? localValue
  const valueAsDate = useMemo(() => {
    if (finalValue) {
      const momentVal = moment(finalValue, ISO_DATE_FORMAT, true)
      if (momentVal.isValid()) {
        return momentVal.startOf('day').toDate()
      }
    }
    return undefined
  }, [finalValue])

  const onInputFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    openDropdown()
    onFocus?.(e)
  }, [openDropdown, onFocus])

  const useNativeInput = useIsMobileDevice()
  // We want to take advantage of native date pickers for genuine mobile devices
  // they do a much better job than anything we could come up with
  if (useNativeInput) {
    return <TextInput
      {...inputProps}
      type="date"
      value={finalValue}
      min={inputProps.min ? moment(inputProps.min).format(ISO_DATE_FORMAT) : undefined}
      max={inputProps.max ? moment(inputProps.max).format(ISO_DATE_FORMAT) : undefined}
      onChange={onNativeInputChange}
      onFocus={onFocus}
      placeholder={inputProps.placeholder ?? DMY_DATE_FORMAT}
    />
  }

  return <div className={inputProps.className}>
    <DateInput
      {...inputProps}
      onChange={onInputChange}
      onFocus={onInputFocus}
      ref={triggerRef}
      value={finalValue}
    />
    <DropdownSheet
      size="M"
      triggerRef={triggerRef}
      anchorRef={triggerRef}
      open={open}
      onClose={toggleOpen}
      placement={dropdownPlacement}
      crossAxisShift
    >
      <DatePicker
        defaultMonth={defaultMonth}
        defaultYear={defaultYear}
        onDayClick={onDatePickerChange}
        value={valueAsDate}
        min={inputProps.min}
        max={inputProps.max}
        dayRender={dayRender}
      />
    </DropdownSheet>
  </div>
})

DatePickerInput.displayName = 'DatePickerInput'

export default DatePickerInput

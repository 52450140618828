import React from 'react'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import PageBanner from 'components/Luxkit/Banners/PageBanner'

interface Props {
  className?: string;
}

const AtolProtectedBanner = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className } = props

  return <PageBanner
    ref={ref}
    className={className}
    kind="informational-primary"
    align="center"
    icon={<img src={getSVGImageUrl('984lj4elpskyg828z90qb')} width="16" height="16" alt="" />}
    description="ATOL protected"
  />
})

export default AtolProtectedBanner

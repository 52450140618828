import { get as getLocalStorage, set as setLocalStorage, remove } from 'lib/storage/isomorphicLocalStorage'

export function get<T>(key: string): T | undefined {
  const item = getLocalStorage(key)

  if (item) {
    const { value, expireTimestamp = 0 } = item
    const now = Date.now()
    if (expireTimestamp > now) {
      return value
    } else {
      remove(key)
    }
  }
}

/**
 * @param key
 * @param value
 * @param ttl time to live (second)
 */
export function set<T>(key: string, value: T, ttl: number) {
  const now = Date.now()
  const expireTimestamp = now + ttl * 1000

  setLocalStorage(key, { value, expireTimestamp })
}

export { remove } from 'lib/storage/isomorphicLocalStorage'

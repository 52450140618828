import TextInput from 'components/Common/Form/Input/TextInput'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import useKeyPress from 'hooks/useKeyPress'
import { EmptyArray } from 'lib/array/arrayUtils'
import React, { ComponentProps, useEffect, useRef, useState } from 'react'
import AirportDropdownOption from './AirportDropdownOption'
import NoAirportsOptionListItem from './NoAirportsOptionListItem'
import ListItemLoadingSkeleton from 'components/Luxkit/List/ListItemLoadingSkeleton'

const MAX_VISIBLE_OPTIONS = 7

interface Props extends Pick<ComponentProps<typeof DropdownList>, 'anchorRef' | 'triggerRef' | 'open' | 'onClose'> {
  id?: string;
  loading?: boolean;
  airports?: Array<App.AirportLocation>;
  inputValue?: string;
  heading?: string;
  airportIcon?: React.ReactNode;
  enableSearchBox?: boolean;
  onSelectAirport: (airport: App.AirportLocation) => void;
  onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function AirportSelectDropdown(props: Props) {
  const {
    anchorRef,
    triggerRef,
    id,
    open,
    loading,
    onClose,
    airports = EmptyArray,
    onSelectAirport,
    inputValue = '',
    heading,
    airportIcon,
    enableSearchBox,
    onChangeText,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const [cursor, setCursor] = useState<number>(0)
  const [downPress, upPress, enterPress] = useKeyPress(['ArrowDown', 'ArrowUp', 'Enter'])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const airportsCount = airports.length

  useEffect(() => {
    if (!open) return

    // change selected airport option by up & down arrow keys
    if (airportsCount && downPress) {
      const newCursor = cursor < airportsCount - 1 ? cursor + 1 : cursor
      setCursor(newCursor)

      // scroll down
      if (newCursor >= MAX_VISIBLE_OPTIONS) {
        wrapperRef.current?.scrollBy({ top: 58, behavior: 'smooth' })
      }
    }

    if (airportsCount && upPress) {
      const newCursor = cursor > 0 ? cursor - 1 : cursor
      setCursor(newCursor)

      // scroll up
      if (newCursor < (airportsCount - MAX_VISIBLE_OPTIONS)) {
        wrapperRef.current?.scrollBy({ top: -58, behavior: 'smooth' })
      }
    }

    if (enterPress && airports[cursor]) {
      onSelectAirport(airports[cursor])
    }
  }, [downPress, upPress, enterPress]) // eslint-disable-line react-hooks/exhaustive-deps

  const noAirportsAvailable = !loading && airports.length === 0 && inputValue.length > 2

  useEffect(() => {
    if (open) {
      inputRef.current?.focus()
    }
  }, [open])

  return (
    <DropdownList
      size="fill-anchor"
      open={!!open}
      anchorRef={anchorRef}
      triggerRef={triggerRef}
      onClose={onClose}
      title={heading}
      headerExtension={enableSearchBox && <TextInput
        placeholder="Search by city or airport"
        value={inputValue}
        onChange={onChangeText}
        ref={inputRef}
        endIcon={<LineSearchIcon />}
        noValidationSpacing
        noValidationMessage
      />}
    >
      <div ref={wrapperRef}>
        {loading && <>
          <ListItemLoadingSkeleton size="medium" title="50%" subtitle="30%" startIcon />
          <ListItemLoadingSkeleton size="medium" title="40%" subtitle="60%" startIcon />
        </>}
        {!loading && airports?.map((airport, index) =>
          <AirportDropdownOption
            key={airport.id}
            id={id}
            subRecord={airport.isCityAirport}
            airport={airport}
            onSelectAirport={onSelectAirport}
            selected={cursor === index}
            icon={airportIcon}
          />,
        )}
        {noAirportsAvailable && <NoAirportsOptionListItem inputValue={inputValue}/>}
      </div>
    </DropdownList>
  )
}

export default AirportSelectDropdown

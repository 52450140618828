import useBusinessTravellerBusinessBudgetFetcher from 'businessTraveller/hooks/useBusinessTravellerBusinessBudgetFetcher'
import FormatCurrency from 'components/Common/FormatCurrency/FormatCurrency'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { pluralizeToString } from 'lib/string/pluralize'
import React from 'react'

type Props = {
  numberOfNights: number
}

function BusinessTravellerOfferHotelCreditsOverBudgetInfoModal({ numberOfNights }: Props) {
  const { budgetData } = useBusinessTravellerBusinessBudgetFetcher({})

  return <Modal title="Exceeds budget">
    <BodyText variant="large">
      This accommodation exceeds your budget of{' '}
      {budgetData?.status === 'pending' && <TextLoadingBox width="6ch" />}
      {budgetData?.status === 'success' && <FormatCurrency value={budgetData.maxNightlyBudget * numberOfNights} />}
      {' '}for {pluralizeToString('night', numberOfNights)}. You may need to check with your company prior to making a booking.
    </BodyText>
  </Modal>
}

export default BusinessTravellerOfferHotelCreditsOverBudgetInfoModal

import { useEffect, useRef } from 'react'

function usePrevious<T extends unknown>(value: T): T | undefined;
function usePrevious<T extends unknown>(value: T, defaultValue: T): T;
function usePrevious<T extends unknown>(value: T, defaultValue?: T): T | undefined {
  const ref = useRef<T | undefined>(defaultValue)
  const previous = ref.current

  useEffect(() => {
    ref.current = value
  })

  return previous
}

export default usePrevious

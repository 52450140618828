import { getLowestPricePackage } from 'lib/offer/getLowestPricePackage'
import { groupByBundlePackages } from 'lib/bundleAndSave/groupByBundlePackages'
import { groupByOfferId } from 'lib/offer/offerUtils'
import { validateBundlePackages } from 'lib/bundleAndSave/validateBundlePackages'

export function getGroupedBundledPackages(offer: App.BundleOffer, options?: Array<{
  offerId: string;
  duration: number;
}>) {
  if (options) {
    return groupByBundlePackages(offer, validateBundlePackages(offer, offer.packages.filter((p) => options.some((o) => o.offerId === p.offerId && o.duration === p.duration))))
  }

  const bundlePackage = groupByBundlePackages(offer, offer.packages)

  const lowestPricesBundlePackages: Map<string, App.Package> = new Map()

  for (const keyBundlePackage of Object.keys(bundlePackage)) {
    const offerPackages = groupByOfferId(bundlePackage[keyBundlePackage])

    const lowestPackages: Array<App.Package> = []
    for (const attachedOfferId of Object.keys(offerPackages)) {
      const attachedOffer = offer.bundledOffers[attachedOfferId]
      const attachedOfferPackages = offerPackages[attachedOfferId]
      const lowestPricePackage = getLowestPricePackage(attachedOffer, attachedOfferPackages)
      if (lowestPricePackage) {
        lowestPackages.push(lowestPricePackage)
      }
    }

    for (const pkg of validateBundlePackages(offer, lowestPackages)) {
      lowestPricesBundlePackages.set(`${pkg.offerId}:${pkg.lePackageId}:${pkg.roomRate?.id}`, pkg)
    }
  }

  return groupByBundlePackages(offer, Array.from(lowestPricesBundlePackages.values()))
}

import { useCallback, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import { io, Socket } from 'socket.io-client'
import config from 'constants/config'
import {
  set as setLocalStorage,
  get as getLocalStorage,
  remove as removeLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { authLogout } from 'actions/AuthActions'
import { CONNECTION, SESSION, ClientToServerEvents, ServerToClientEvents } from 'api/supportAssistant'
import { selectLoggedIn } from 'selectors/accountSelectors'

export interface SessionSocket<T> extends Socket {
  sessionId?: string
  customerId?: string
  events?: T;
}

export type SocketType = SessionSocket<ClientToServerEvents | ServerToClientEvents>;

export let globalSocket: SocketType | undefined = undefined

export const socket: SocketType | undefined = undefined

export default function useSocket() {
  const dispatch = useAppDispatch()
  const [socket, setSocket] = useState<SocketType | undefined>(undefined)

  const userIsLoggedIn = useAppSelector(selectLoggedIn)
  const customerId = useAppSelector((state) => state.auth.account?.memberId)
  const accessToken = useAppSelector((state) => state.auth.accessToken)

  useEffect(() => {
    if (!globalSocket || !socket) {
      const sessionId = getLocalStorage('supportAssistantSessionId')

      globalSocket = io(config.API_HOST, {
        transports: ['websocket', 'polling'],
        path: '/api/support/chat/socket.io',
        auth: {
          sessionId,
          customerId,
          accessToken,
        },
      })

      setSocket(globalSocket)
    }
  }, [accessToken, customerId, dispatch, socket])

  // We need to await the creation of the new socket so any messages are sent to the new socket
  // instead of the old.
  const resetSocket = useCallback(() => {
    return new Promise<void>((resolve) => {
      globalSocket = undefined
      setSocket(undefined)

      const checkSocket = () => {
        if (globalSocket && socket) {
          resolve()
        } else {
          setTimeout(checkSocket, 50)
        }
      }

      checkSocket()
    })
  }, [socket])

  useEffect(() => {
    if (!userIsLoggedIn) {
      removeLocalStorage('supportAssistantSessionId')
      removeLocalStorage('supportAssistantCustomerId')
    }
  }, [userIsLoggedIn])

  useEffect(() => {
    if (socket) {
      socket.on(SESSION.CREATED, (socket) => {
        const socketSessionId = socket.sessionId
        const socketCustomerId = socket.customerId

        if (socketCustomerId === customerId) {
          setLocalStorage('supportAssistantSessionId', socketSessionId)
        } else {
          setLocalStorage('supportAssistantCustomerId', socketCustomerId)
        }
      })

      socket.on(SESSION.EXPIRED, () => {
        globalSocket = undefined
        setSocket(undefined)
        removeLocalStorage('supportAssistantSessionId')
        removeLocalStorage('supportAssistantCustomerId')
      })

      socket.on(SESSION.INVALID, () => {
        dispatch(authLogout())
      })

      socket.on(CONNECTION.ERROR, () => {
        // revert to classic upgrade
        socket.io.opts.transports = ['polling', 'websocket']
      })
    }
  }, [dispatch, customerId, socket])

  return {
    socket,
    resetSocket,
  }
}

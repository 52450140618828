import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import React, { useCallback, useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { calculateNights } from '../../../../../tripPlanner/utils'
import SearchTileEventsContext, { SearchTileEvents } from '../../../OfferList/OfferListTiles/SearchOfferTiles/SearchTileEventsContext'

interface Props {
  checkIn: string;
  checkOut: string;
}

function HotelSearchTileAlternativeDatesBanner({ checkIn, checkOut }: Props) {
  const searchTileEventsDispatch = useContext(SearchTileEventsContext)
  const nights = calculateNights(checkIn, checkOut)

  const [inViewRef, hasBeenInView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    if (hasBeenInView) {
      searchTileEventsDispatch({
        type: SearchTileEvents.viewAlternativeDatesBanner,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenInView])

  const handlePrimaryActionClick = useCallback(() => {
    searchTileEventsDispatch({
      type: SearchTileEvents.clickAlternativeDateViewOffer,
    })
  }, [searchTileEventsDispatch])

  return <MessageBanner
    ref={inViewRef}
    kind="informational-primary"
    description={<>
      Stay for at least <strong>{nights} nights</strong> to unlock a limited time deal on this hotel.
    </>}
    secondaryActionButtonProps={{
      children: 'View offer',
      onClick: handlePrimaryActionClick,
    }}
  />
}

export default HotelSearchTileAlternativeDatesBanner

import * as Optimizely from './optimizely/optimizely'

import {
  SelfDescribingJson,
  addGlobalContexts,
  clearGlobalContexts,
  newTracker,
  trackPageView,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker'

import config from 'constants/config'
import Snowplow from 'constants/snowplow'
import { APP_CONTEXT_SCHEMA } from 'constants/analytics'

import {
  SnowplowClientEventParams,
  SnowplowEvent,
  asyncBaseContext,
  baseContext,
  clientEvent,
} from './snowplow/events'
import overrideTrackerIds from './snowplow/helpers/overrideTrackedIds'

import { reportClientError } from 'services/errorReportingService'

import { getWhiteLabelAppConfig } from 'lib/whitelabels/whitelabels'
import { getCookies, deleteCookie } from 'lib/web/cookieUtils'
import { AppStore } from '../store'

const mergeAsyncContext = (context: Array<SelfDescribingJson<Record<string, unknown>>> | null | undefined, asyncContext: Array<SelfDescribingJson<Record<string, unknown>>> | null | undefined) => {
  const newContext = context || []
  newContext.push(...(asyncContext || []))
  return newContext
}

export async function trackClientEvent(eventData: SnowplowClientEventParams) {
  trackEvent(clientEvent(eventData))
}

export async function trackEvent(passedEvent: SnowplowEvent | undefined | Promise<SnowplowEvent | undefined>) {
  const isPromise = passedEvent instanceof Promise
  const event = isPromise ? (await passedEvent) : passedEvent

  if (!event) {
    return
  }

  try {
    const asyncContextResult = await asyncBaseContext(event.itemContextSource, event.options)
    event.context = mergeAsyncContext(event?.context, asyncContextResult)
    if ('title' in event) {
      trackPageView(event)
    } else if ('event' in event) {
      trackSelfDescribingEvent(event)
    }
  } catch (error) {
    console.error(error)
  }
}

let listenerAdded = false
export function init(store: AppStore) {
  Optimizely.init(store)

  if (!config.TRACKING_HOST) return

  // MOBILE_APP_PLATFORM value (ios/android) will be passed only for the WL-Mobile build
  const mobileAppPlatform = config.MOBILE_APP_PLATFORM || getWhiteLabelAppConfig().platform

  const tracker = newTracker('sp', config.TRACKING_HOST, {
    appId: mobileAppPlatform ? config.wlMobileApp?.[mobileAppPlatform]?.snowplow?.appId : config.snowplow.appId,
    platform: mobileAppPlatform ? 'app' : 'web',
    plugins: [],
    // you must specify the path since the default path
    // /com.snowplowanalytics.snowplow/tp2 will be blocked by adblock
    postPath: Snowplow.postPath,
    // not-encoding the data makes debugging easier
    encodeBase64: false,
  })

  try {
    // If user came from mobile webview or we want to override the userId data, we grab custom cookies to
    // override Snowplow with those Ids
    const dict = getCookies()
    if (dict[Snowplow.domainUserIdCookie] || dict[Snowplow.sessionIdCookie] || dict[Snowplow.sessionIdxCookie]) {
      overrideTrackerIds(
        tracker,
        dict[Snowplow.sessionIdCookie],
        dict[Snowplow.domainUserIdCookie],
        Number.isFinite(dict[Snowplow.sessionIdxCookie]) ? Number(dict[Snowplow.sessionIdxCookie]) : undefined,
      )

      deleteCookie(Snowplow.sessionIdxCookie)
      deleteCookie(Snowplow.sessionIdCookie)
    }
  } catch (err) {
    reportClientError(new Error('Snowplow init error'), { error: err.message })
  }

  if (!listenerAdded) {
    listenerAdded = true
    resetContext()
  }
}

export function resetContext() {
  clearGlobalContexts()

  addGlobalContexts([
    {
      schema: APP_CONTEXT_SCHEMA,
      data: {
        appVersion: config.commitSha || '-',
      },
    },
    baseContext,
  ])
}

import clsx from 'clsx'
import React, { PropsWithChildren, forwardRef, useReducer } from 'react'
import styled from 'styled-components'
import { DropdownBehaviourDispatchContext, DropdownBehaviourStateContext } from './DropdownBehaviourContexts'
import { DROPDOWN_BEHAVIOUR_INITIAL_STATE, dropdownBehaviourStateReducer } from './DropdownBehaviourState'
import { rem } from 'polished'

export const DROPDOWN_SHEET_MIN_HEIGHT_CSS_VARIABLE = '--dropdown-min-h'
export const DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE = '--dropdown-max-h'
export const DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE = '--dropdown-min-w'
export const DROPDOWN_SHEET_WIDTH_CSS_VARIABLE = '--dropdown-w'
export const DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE = '--dropdown-max-w'

const DropdownSheetContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  box-shadow: ${props => props.theme.shadow.flat.small};
  min-height: var(${DROPDOWN_SHEET_MIN_HEIGHT_CSS_VARIABLE}, auto);
  max-height: min(var(${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}, auto), 100dvh);
  max-width: min(var(${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}, 100%), calc(100dvw - ${rem(16)}));
  width: var(${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE});
  min-width: var(${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE});

  &.height-auto {
    height: auto;
  }
  &.height-max {
    height: calc(var(--vh) * 100);
    height: 100dvh;
  }

  &, > form {
    display: grid;
    grid-template:
      "dropdown-header" auto
      "dropdown-body" 1fr
      "dropdown-footer" auto / 1fr;
  }

  > form {
    /* stretch the form over the grid */
    grid-column: 1/-1;
    grid-row: 1/-1;
  }
`

interface Props extends PropsWithChildren {
  /**
   * @example
   * `auto` => hug the content vertically.
   *
   * `max` => expand the container vertically as much as the size permits.
   *
   * @default auto
   */
  height?: 'auto' | 'max'
  className?: string
}

const _DropdownSheet = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, height = 'auto', className } = props

  const [state, dispatch] = useReducer(
    dropdownBehaviourStateReducer,
    DROPDOWN_BEHAVIOUR_INITIAL_STATE,
  )

  return <DropdownSheetContainer
    ref={ref}
    className={clsx(className, `height-${height}`)}
  >
    <DropdownBehaviourStateContext.Provider value={state}>
      <DropdownBehaviourDispatchContext.Provider value={dispatch}>
        {children}
      </DropdownBehaviourDispatchContext.Provider>
    </DropdownBehaviourStateContext.Provider>
  </DropdownSheetContainer>
})

export default _DropdownSheet

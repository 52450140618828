import BodyText from 'components/Luxkit/Typography/BodyText'
import React from 'react'

interface Props {
  start: string
  end: string
}

function SearchTileRowOverlineJourney({ start, end }: Props) {
  const roundTrip = start === end

  return <BodyText variant="small" colour="neutral-two" className={SearchTileRowOverlineJourney.name}>
    {!roundTrip && <>Starts in <b>{start}</b>, end is <b>{end}</b></>}
    {roundTrip && <>Starts and ends in <b>{start}</b></>}
  </BodyText>
}

export default SearchTileRowOverlineJourney

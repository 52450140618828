export const all = (currencyCode: string) =>
  ['experiences', currencyCode] as const
export const lists = (currencyCode: string) =>
  [...all(currencyCode), 'list'] as const
export const list = (currencyCode: string, filters: string | Array<string>) => [
  ...lists(currencyCode),
  { filters },
]
export const details = (currencyCode: string) =>
  [...all(currencyCode), 'details'] as const

// ToDo: Perhaps find a better way to do this?
export const detail = (currencyCode: string, id?: string) => [
  ...details(currencyCode),
  id,
]

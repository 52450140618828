import { FlightDropdownOption } from 'components/Flights/types'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'
import ListItem from 'components/Luxkit/List/ListItem'
import React from 'react'

interface Props {
  selected: boolean;
  option: FlightDropdownOption;
  'data-testid'?: string;
  onOptionSelect: (option: FlightDropdownOption) => void;
}

function FareDropdownOption(props: Props) {
  const { selected, option, onOptionSelect } = props

  const onSelect = () => {
    onOptionSelect(option)
  }

  return (
    <ListItem
      title={option.label}
      selected={selected}
      key={option.value}
      onClick={onSelect}
      disabled={option.disabled}
      data-testid={props['data-testid']}
    >
      {option.comingSoon && <StructureLabel variant="information">
        Coming soon!
      </StructureLabel>}
    </ListItem>
  )
}

export default FareDropdownOption

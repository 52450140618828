import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineThumbsUpIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.3 10.08A3.0007 3.0007 0 0 0 19 9h-4.56L15 7.57a4.1308 4.1308 0 0 0-1.9701-5.1067A4.1297 4.1297 0 0 0 11.11 2a.9998.9998 0 0 0-.91.59L7.35 9H5a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h12.73a3 3 0 0 0 2.95-2.46l1.27-7a2.9995 2.9995 0 0 0-.65-2.46ZM7 20H5a.9997.9997 0 0 1-1-1v-7a1.0001 1.0001 0 0 1 1-1h2v9Zm13-7.82-1.27 7a.9998.9998 0 0 1-1 .82H9v-9.79l2.72-6.12a2.1105 2.1105 0 0 1 1.5171 1.9253A2.1106 2.1106 0 0 1 13.1 6.87l-.53 1.43a1.9996 1.9996 0 0 0 .2293 1.8388A2.0007 2.0007 0 0 0 14.44 11H19a.9998.9998 0 0 1 .77.36 1.0007 1.0007 0 0 1 .23.82Z"/>
  </SvgIcon>
}

export default LineThumbsUpIcon

import cx from 'clsx'
import ButtonGroupToggle from 'components/Common/ButtonGroupToggle'
import { ButtonToggleItem } from 'components/Common/ButtonGroupToggle/ButtonGroupToggleItem'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import noop from 'lib/function/noop'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import CruiseDateSelectCalendar from './CruiseWhenSelectCalendar'
import CruiseWhenSelectMonths from './CruiseWhenSelectContentMonths'
import CruiseWhenSelectHowLong from './CruiseWhenSelectHowLong'
import { getCruiseExtraFilters, getGlobalFacetFilters } from 'lib/cruises/cruiseUtils'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import useQueryParams from 'hooks/useQueryParams'
import { omitKeys } from 'lib/object/objectUtils'

const TabButtons: Array<ButtonToggleItem> = [
  { id: 'departure-month', label: 'Months' },
  { id: 'date-range', label: 'Date Range' },
  { id: 'anytime', label: 'Anytime' },
]

interface Props {
  onSelect?: () => void;
  onChange?: (value: App.CruiseGlobalFilters) => void;
  drawerMode?: boolean;
}

function CruiseWhenSelectContent(props: Props) {
  const { drawerMode, onSelect = noop, onChange = noop } = props
  const { checkinDate, checkoutDate } = useContext(GlobalSearchStateContext)
  const queryParams = useQueryParams()

  const defaultTab = checkinDate && checkoutDate ? TabButtons[1] : TabButtons[0]
  const [tab, setTab] = useState<ButtonToggleItem>(defaultTab)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const cruiseExtraFilters = getCruiseExtraFilters(queryParams)
  const globalFilters = useContext(GlobalSearchStateContext)
  const facetFilters = getGlobalFacetFilters(globalFilters)
  const [filteredCruiseDates] = useCruiseSearchFacets({
    facetTypes: ['cruise_dates'],
    ...omitKeys(['departureMonths', 'departureStartDate', 'departureEndDate'], { ...cruiseExtraFilters, ...facetFilters }),
  })

  const filteredCruiseDatesMap = useMemo(() => {
    return filteredCruiseDates.map(facet => facet.date!).filter(Boolean)
  }, [filteredCruiseDates])

  const onTabSelect = useCallback((nextTab: ButtonToggleItem) => {
    if (nextTab.id === 'anytime') {
      searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: '' })
      searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: undefined })
      searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: undefined })
    }

    setTab(nextTab)
  }, [searchDispatch])

  return <Group direction="vertical" gap={12}>
    <Group direction="vertical" gap={12}>
      <CruiseWhenSelectHowLong onSelect={onSelect} drawerMode={drawerMode} onChange={onChange}/>

      <Group direction="vertical" gap={12}>
        <Group direction="vertical" gap={12} horizontalAlign="center">
          <Heading align="center" variant="heading6">When would you like to sail?</Heading>
          <ButtonGroupToggle
            items={TabButtons}
            activeItem={tab}
            onItemClick={onTabSelect}
            className={cx({ drawer: drawerMode })}
          />
        </Group>

        {tab.id === 'departure-month' && <CruiseWhenSelectMonths
          onSelect={onSelect}
          onChange={onChange}
          drawerMode={drawerMode}
          filteredCruiseDatesMap={filteredCruiseDatesMap}
        />}

        {tab.id === 'date-range' && <CruiseDateSelectCalendar
          type={drawerMode ? 'single' : 'dual'}
          availableMonths={filteredCruiseDatesMap}
          onChange={onChange}
        />}

        {tab.id === 'anytime' && <BodyText variant="medium" align="center" data-testid="cruise-when-select-anytime">
          Find all available cruises.
        </BodyText>}
      </Group>
    </Group>
  </Group>
}

export default CruiseWhenSelectContent

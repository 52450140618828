import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUsdCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11 9h4a1 1 0 1 0 0-2h-2V6a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 6v1a3 3 0 0 0 0 6h2a1.0001 1.0001 0 0 1 0 2H9a1.0002 1.0002 0 0 0-.7071 1.7071C8.4804 16.8946 8.7348 17 9 17h2v1a1.0001 1.0001 0 0 0 2 0v-1a3 3 0 0 0 0-6h-2a1.0001 1.0001 0 0 1 0-2Zm1-8a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm0 20a8.9996 8.9996 0 0 1-8.315-5.5558A9.0002 9.0002 0 0 1 15.4443 3.6851 8.9999 8.9999 0 0 1 21 12a9.0002 9.0002 0 0 1-9 9Z"/>
  </SvgIcon>
}

export default LineUsdCircleIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMapPinIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.37 12.79a1.0014 1.0014 0 0 0-.766.0108.9996.9996 0 0 0-.534.5492.9995.9995 0 0 0 .0108.766.9996.9996 0 0 0 .5492.534C17.09 15.23 18 16.13 18 17c0 1.42-2.46 3-6 3s-6-1.58-6-3c0-.87.91-1.77 2.37-2.35a1.0008 1.0008 0 0 0 .026-1.8492 1.0007 1.0007 0 0 0-.766-.0108C5.36 13.69 4 15.26 4 17c0 2.8 3.51 5 8 5s8-2.2 8-5c0-1.74-1.36-3.31-3.63-4.21ZM11 9.86V17a1.0001 1.0001 0 0 0 2 0V9.86a3.9998 3.9998 0 0 0 1.6442-6.8743A3.9999 3.9999 0 0 0 8.6894 8.232 3.9998 3.9998 0 0 0 11 9.86ZM12 4a1.9998 1.9998 0 0 1 1.9616 2.3902 2.0003 2.0003 0 0 1-2.727 1.4576A1.9998 1.9998 0 0 1 10 6a1.9999 1.9999 0 0 1 2-2Z"/>
  </SvgIcon>
}

export default LineMapPinIcon

import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { getDueAtProperty } from './accommodation'
import getCheckoutTotal from './getCheckoutTotal'

const getPayableTotal = createSelector(
  getCheckoutTotal,
  getDueAtProperty,
  (total, dueAtProperty): number => floatify(total - dueAtProperty),
)

export default getPayableTotal

import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { rem } from 'polished'
import Caption from 'components/Luxkit/Typography/Caption'
import CalendarGrid from './CalendarGrid'
import memoize from 'lib/memoize/memoize'

const CalendarContainer = styled.div`
  > * + * {
    margin-top: ${rem(4)};
  }
`

const WeekHeader = styled(Caption)`
  display: grid;
  grid-template-columns: repeat(7 , 1fr);
`

const RelativeBlock = styled.div`
  position: relative;
`

interface Props {
  className?: string;
  // month and year this calendar is for
  month: number;
  year: number;
  // minimum and maximum dates that are selectable
  minDate?: Date;
  maxDate?: Date;
  // the selected dates
  startDate?: Date;
  endDate?: Date;
  // Labels that'll appear in the start/end days
  startLabel?: string;
  endLabel?: string;
  onDayClick: (day: moment.Moment) => void;
}
const getStartOfMonth = memoize((month: number, year: number) => new Date(year, month), (month, year) => `${month}${year}`)

// Class used to prevent onDayClick reference from changing on each click
class Calendar extends React.PureComponent<React.PropsWithChildren<Props>> {
  onDayClick = (day: moment.Moment) => {
    this.props.onDayClick(day)
  }

  render() {
    const {
      className,
      startDate,
      endDate,
      minDate,
      maxDate,
      startLabel,
      endLabel,
      month,
      year,
    } = this.props

    const startOfMonth = getStartOfMonth(month, year)

    return (
      <CalendarContainer className={className}>
        <WeekHeader variant="medium" align="start" colour="neutral-three">
          <abbr title="Sunday">Sun</abbr>
          <abbr title="Monday">Mon</abbr>
          <abbr title="Tuesday">Tue</abbr>
          <abbr title="Wednesday">Wed</abbr>
          <abbr title="Thursday">Thu</abbr>
          <abbr title="Friday">Fri</abbr>
          <abbr title="Saturday">Sat</abbr>
        </WeekHeader>
        <RelativeBlock>
          <CalendarGrid
            minDate={minDate}
            maxDate={maxDate}
            startOfMonth={startOfMonth}
            onDayClick={this.onDayClick}
            startDate={startDate}
            endDate={endDate}
            startLabel={startLabel}
            endLabel={endLabel}
          />
        </RelativeBlock>
      </CalendarContainer>
    )
  }
}

export default Calendar

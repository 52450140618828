import React from 'react'
import styled from 'styled-components'

import ButtonGroupToggleItem, { ButtonToggleItem } from './ButtonGroupToggleItem'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const ButtonGroupContainer = styled.div`
  display: flex;

  > * {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & + * {
      margin-left: -1px; /* to overlap border */
    }

    ${mediaHoverable} {
      &:hover {
        z-index: 1; /* so that the overlapped border can be visible */
      }
    }
  }
`

interface Props {
  className?: string;
  items: Array<ButtonToggleItem>;
  activeItem?: ButtonToggleItem;
  onItemClick?: (item: ButtonToggleItem, e?: React.MouseEvent) => void;
}

function ButtonGroupToggle(props: Props) {
  const { className, items, activeItem, onItemClick } = props

  return (
    <ButtonGroupContainer className={className}>
      {items.map(item =>
        <ButtonGroupToggleItem
          key={item.id}
          item={item}
          active={activeItem == item}
          onItemClick={onItemClick}
        />,
      )}
    </ButtonGroupContainer>
  )
}

export default React.memo(ButtonGroupToggle)

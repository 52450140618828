import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineStarCardIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M20.2929 6.2929A1 1 0 0 1 22 7v10a4.9997 4.9997 0 0 1-5 5H7a1.0002 1.0002 0 0 1-.7071-1.7071A1.0002 1.0002 0 0 1 7 20h10a3 3 0 0 0 3-3V7c0-.2652.1054-.5196.2929-.7071ZM18 5v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3ZM4 5v10a1 1 0 0 0 1 1h10a1.0001 1.0001 0 0 0 1-1V5a1.0002 1.0002 0 0 0-1-1H5a1 1 0 0 0-1 1Zm2.4845 7.6205.2609-1.5574-1.0976-1.0803a1.3866 1.3866 0 0 1-.3292-1.4031 1.3865 1.3865 0 0 1 1.0939-.9375l1.4573-.216.6568-1.3955A1.35 1.35 0 0 1 9.75 5.25a1.3503 1.3503 0 0 1 1.2235.7807l.6567 1.3954 1.4573.216a1.3852 1.3852 0 0 1 1.0939.9376 1.3873 1.3873 0 0 1-.3292 1.4031l-1.0796 1.0983.2609 1.5574a1.3964 1.3964 0 0 1-.5667 1.3594 1.3131 1.3131 0 0 1-1.4034.063l-1.2954-.7112-1.2774.7292a1.3128 1.3128 0 0 1-1.4214-.081 1.3952 1.3952 0 0 1-.5847-1.3774ZM9 8.8913a1.3578 1.3578 0 0 1-.6084.2543l-.8456.135.6567.6752a1.387 1.387 0 0 1 .3778 1.1973l-.144.9003.7108-.3871a1.3303 1.3303 0 0 1 1.2954 0l.7107.3871-.144-.9003a1.4144 1.4144 0 0 1 .3779-1.2063l.6567-.6662-.8996-.126a1.3936 1.3936 0 0 1-1.0256-.7742l-.3508-.7472-.3509.7472a1.3592 1.3592 0 0 1-.417.511Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineStarCardIcon

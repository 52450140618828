import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWheelchairIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 6.5a2 2 0 1 0-.0001-4A2 2 0 0 0 12 6.5Zm7.5 14h-1v-5a1.0001 1.0001 0 0 0-1-1h-5v-2h5a1.0003 1.0003 0 0 0 1-1 1.0001 1.0001 0 0 0-1-1h-5v-2a1.0002 1.0002 0 0 0-1.7071-.7071A.9997.9997 0 0 0 10.5 8.5v7a.9997.9997 0 0 0 1 1h5v5a.9997.9997 0 0 0 1 1h2a1.0003 1.0003 0 0 0 1-1 1.0001 1.0001 0 0 0-1-1Zm-6.8-1.6a4 4 0 0 1-7.2-2.4 4 4 0 0 1 2.4-3.66 1.0034 1.0034 0 0 0-.0323-1.8534A1.0032 1.0032 0 0 0 7.1 11a5.9994 5.9994 0 0 0-3.4644 6.7626A6 6 0 0 0 14.3 20.1a1.0002 1.0002 0 0 0-.9415-1.59 1.0002 1.0002 0 0 0-.6585.39Z"/>
  </SvgIcon>
}

export default LineWheelchairIcon

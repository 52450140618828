import ListItem from 'components/Luxkit/List/ListItem'
import TextLoadingBox, { isTextLoadingBoxWidth } from 'components/Luxkit/Typography/TextLoadingBox'
import React, { ComponentProps, ReactNode, useMemo } from 'react'
import IconLoadingBox from '../Icons/IconLoadingBox'

function determineLoadingSlot(value: TextLoadingBoxWidth | ReactNode): ReactNode {
  if (isTextLoadingBoxWidth(value)) {
    return <TextLoadingBox width={value} />
  }
  return value
}

type TextLoadingBoxWidth = ComponentProps<typeof TextLoadingBox>['width']

interface Props extends Pick<ComponentProps<typeof ListItem>, 'size'> {
  /**
   * @example true => 20%
   */
  overline?: boolean | TextLoadingBoxWidth | ReactNode
  /**
   * @example true => 40%
   * @default true
   */
  title?: true | TextLoadingBoxWidth | ReactNode
  /**
   * @example true => 50%
   */
  subtitle?: boolean | TextLoadingBoxWidth | ReactNode
  startIcon?: boolean | ReactNode
  endIcon?: boolean | ReactNode
}

function ListItemLoadingSkeleton(props: Props) {
  const {
    overline,
    title = true,
    subtitle = '50%',
    endIcon,
    startIcon,
    size,
  } = props

  const overlineSlot = useMemo(() => {
    if (!overline) return
    return determineLoadingSlot(overline === true ? '20%' : overline)
  }, [overline])
  const titleSlot = useMemo(() => {
    return determineLoadingSlot(title === true ? '40%' : title)
  }, [title])
  const subtitleSlot = useMemo(() => {
    if (!subtitle) return
    return determineLoadingSlot(subtitle === true ? '50%' : subtitle)
  }, [subtitle])

  return <ListItem
    size={size}
    overline={overlineSlot}
    title={titleSlot}
    subtitle={subtitleSlot}
    startIcon={startIcon === true ? <IconLoadingBox /> : startIcon}
    endIcon={endIcon === true ? <IconLoadingBox /> : endIcon}
    nonInteractable
  />
}

export default ListItemLoadingSkeleton

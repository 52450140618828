import responsiveHelper, { Matches } from './responsiveHelper'
import React from 'react'
import {
  getCustomBreakpointName,
  checkOnlyBreakpoint,
  checkNotBreakpoint,
  checkMinBreakpoint,
  checkMaxBreakpoint,
  checkCustomBreakpoint,
} from 'lib/window/responsiveUtils'

interface Props {
  only?: App.ScreenSize;
  not?: App.ScreenSize;
  min?: App.ScreenSize;
  max?: App.ScreenSize;
  customMin?: number;
  customMax?: number;
}

interface State {
  matched: boolean;
}

/**
 * Please use CSSBreakpoint instead of this component, unless you really need this one.
 * CSSBreakpoint shows and hides its content with pure CSS and therefore works better with SSR,
 * whereas this component won't evaluate its visibility until JS loads, leading to flashes of
 * incorrectly styled content on page load.
 */
class Breakpoint extends React.PureComponent<React.PropsWithChildren<Props>, State> {
  state: State = {
    matched: false,
  }

  componentDidMount() {
    const { customMax, customMin } = this.props
    if (customMax || customMin) {
      const queryName = getCustomBreakpointName(this.props.customMin, this.props.customMax)
      // @ts-ignore: one of customMax/customMin exists, so it works here
      responsiveHelper.registerMediaQuery(queryName, {
        min: customMin,
        max: customMax,
      })
    }
    responsiveHelper.registerListener(this.updateMatches)
    this.updateMatches(responsiveHelper.nextMatch)
  }

  componentWillUnmount() {
    responsiveHelper.removeListener(this.updateMatches)
  }

  updateMatches = (nextMatches: Matches) => {
    const { only, not, min, max, customMax, customMin } = this.props
    let matched = true
    if (only) {
      matched = matched && checkOnlyBreakpoint(nextMatches, only)
    }

    if (not) {
      matched = matched && checkNotBreakpoint(nextMatches, not)
    }

    if (min) {
      matched = matched && checkMinBreakpoint(nextMatches, min)
    }

    if (max) {
      matched = matched && checkMaxBreakpoint(nextMatches, max)
    }

    if (customMax || customMin) {
      matched = matched && checkCustomBreakpoint(nextMatches, customMin, customMax)
    }

    if (matched !== this.state.matched) {
      this.setState({ matched })
    }
  }

  render() {
    return (
      this.state.matched ? this.props.children : null
    )
  }
}

export default Breakpoint

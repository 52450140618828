import { createSelector } from 'reselect'
import { getVillaViews } from '../view/villa'
import { nonNullable, sum } from 'lib/array/arrayUtils'
import { mergeFees } from 'checkout/lib/utils/payments/fees'

export const getVillaTotals = createSelector(
  getVillaViews,
  (views): App.WithDataStatus<App.Checkout.ItemTotals> => {
    return {
      hasRequiredData: views.hasRequiredData,
      data: {
        price: sum(views.data, data => data.price),
        memberPrice: 0,
        value: sum(views.data, data => data.value ?? 0),
        surcharge: sum(views.data, data => data.surcharge ?? 0),
        taxesAndFees: sum(views.data, data => data.taxesAndFees ?? 0),
        propertiesFees: nonNullable(views.data.flatMap(data => data.propertiesFees)),
        otherFees: mergeFees(nonNullable(views.data.flatMap(data => data.otherFees))),
      },
    }
  },
)

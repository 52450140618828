import { getItineraryUniqueKey } from 'lib/cruises/cruiseUtils'
import { UniqueItineraryItem } from 'components/Cruises/CruiseOfferPage/content/Itinerary/CruiseItinerary'

interface Props {
  itinerary: Array<App.CruiseItineraryItem>;
  showOnlyPorts?: boolean;
  portNameSelected?: string | null;
  itineraryKeySelected?: string | null;
  isFlashOffer?: boolean;
}

function useCruiseUniqueItineraryList({
  itinerary,
  showOnlyPorts,
  portNameSelected,
  itineraryKeySelected,
  isFlashOffer = false,
}: Props) {
  if (isFlashOffer || !itinerary) return []

  const itineraryWithoutRepeatedPorts: Array<App.CruiseItineraryItem> = Object.values(itinerary.reduce((acc, item) => ({
    ...acc,
    [`${item.startDay}_${item.port?.name || 'at_sea'}`]: item,
  }), {}))

  let portNumber = 0
  let lastPortName = ''

  return Object.values(itineraryWithoutRepeatedPorts.reduce<UniqueItineraryItem>((acc, item, index) => {
    if (showOnlyPorts && !item.port) return acc

    const portName = item.port?.name || 'at_sea'
    const key = `${item.startDay}_${portName}`
    const isStart = index === 0
    const isEnd = index === itineraryWithoutRepeatedPorts.length - 1

    // add port circle if port is different from previous item
    const addPortCircle = !!item.port && lastPortName !== item.port?.name
    const itineraryKey = getItineraryUniqueKey(item)

    const isSelected = portNameSelected === portName && (!itineraryKeySelected || itineraryKeySelected === itineraryKey)

    const result = {
      ...acc,
      [key]: {
        portName,
        item,
        portNumber,
        addPortCircle,
        isStart,
        isEnd,
        itineraryKey,
        isSelected,
      },
    }

    if (addPortCircle) {
      portNumber += 1
      lastPortName = item.port?.name || ''
    }

    return result
  }, {}))
}

export default useCruiseUniqueItineraryList

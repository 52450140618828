import moment, { Moment } from 'moment/moment'
import { useMemo } from 'react'
import { useAppSelector } from './reduxHooks'
import { getInclusionsViewableLuxPlusTier } from 'selectors/luxPlusSelectors'
import { extractPkgInclusions } from 'lib/offer/inclusionUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'

interface Options {
  checkIn?: moment.Moment | string;
  checkOut?: moment.Moment | string;
}

export function useOfferInclusions(
  pkg: App.Package | undefined,
  options: Options = {},
): {
    inclusions: Array<App.PackageInclusion>,
    bonusInclusions: Array<App.PackageInclusion>,
    tacticalInclusions: Array<App.PackageInclusion>,
    luxPlusInclusions: Array<App.PackageInclusion>,
  } {
  const { checkIn, checkOut } = options
  const checkInDate = useMemo<Moment | undefined>(() => {
    if (!checkIn) return
    if (typeof checkIn === 'string') return moment(checkIn)
    return checkIn
  }, [checkIn])
  const checkOutDate = useMemo<Moment | undefined>(() => {
    if (!checkOut) return
    if (typeof checkOut === 'string') return moment(checkOut)
    return checkOut
  }, [checkOut])
  const inclusionsViewableLuxPlusTier = useAppSelector(getInclusionsViewableLuxPlusTier)
  const luxPlusEnabled = useAppSelector(state => isLuxPlusEnabled(state))

  const [inclusions, bonusInclusions, luxPlusInclusions, tacticalInclusions] = useMemo(() => {
    return extractPkgInclusions(pkg, { checkIn: checkInDate, checkOut: checkOutDate, luxPlusTier: inclusionsViewableLuxPlusTier })
  }, [checkInDate, checkOutDate, inclusionsViewableLuxPlusTier, pkg])

  if (!luxPlusEnabled) {
    return {
      inclusions,
      bonusInclusions,
      tacticalInclusions,
      luxPlusInclusions: [],
    }
  }

  return {
    inclusions,
    bonusInclusions,
    tacticalInclusions,
    luxPlusInclusions,
  }
}

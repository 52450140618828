import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Action, History, Location, LocationState, UnregisterCallback } from 'history'

import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import type { AppDispatch } from 'src/client/store'

let historyLocations: Array<Location> = []
let pendingReplace: Location | null

export function getCheckoutDepth() {
  return historyLocations.length
}

export function useCheckoutRouteTracker() {
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    return startRouteTracking(history, dispatch)
  }, [dispatch, history])
}

export function startRouteTracking(history: History<LocationState>, dispatch: AppDispatch) {
  try {
    if (history.action === 'POP') {
      restoreTracking(history)
    } else {
      historyLocations = [history.location]
    }
    return trackAndAdjustHistory(history, dispatch)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('route tracking is disabled', e)
    historyLocations = []
  }
}

function restoreTracking(history: History) {
  const lastTrackableLocation = historyLocations[historyLocations.length - 2]
  if (lastTrackableLocation && history.location.pathname === lastTrackableLocation.pathname) {
    historyLocations.splice(historyLocations.length - 1)
  } else {
    logHistoryBackError(history.location)
    throw new Error('restore tracking failed')
  }
}

let unlisten: UnregisterCallback | undefined

function trackAndAdjustHistory(history: History, dispatch: AppDispatch): UnregisterCallback {
  unlisten = history.listen((location: Location, action: Action) => {
    const idxInHistory = historyLocations.findIndex(item => item.pathname == location.pathname)
    switch (action) {
      case 'POP':
        if (idxInHistory === -1) {
          logHistoryBackError(location)
          stopTracking()
        }
        historyLocations.splice(idxInHistory + 1)
        break
      case 'PUSH':
        if (idxInHistory !== -1) {
          // the target page already exists in the history, so we will go back
          // to where it was and do an update/REPLACE there after the going back is finished
          goBack(history, idxInHistory)
          pendingReplace = location
        } else {
          historyLocations.push(location)
        }
        break
      case 'REPLACE':
        historyLocations.splice(-1)
        historyLocations.push(location)
        break
    }

    // do the REPLACE after the history going back is finished
    // to update the page in the history
    if (action == 'POP' && pendingReplace) {
      dispatch(replace(pendingReplace))
      pendingReplace = null
    }
  })
  return stopTracking
}

function stopTracking() {
  if (unlisten) {
    unlisten()
    unlisten = undefined
  }
}

function goBack(history: History, idxInHistory: number) {
  const upSteps = idxInHistory - historyLocations.length
  // -1 means the previous page, so no splice is needed for history
  if (upSteps !== -1) {
    historyLocations.splice(upSteps + 1)
  }
  history.go(upSteps)
}

function logHistoryBackError(location: Location) {
  // eslint-disable-next-line no-console
  console.debug(`unrecognized history back to ${location.pathname}`)
  // eslint-disable-next-line no-console
  console.debug('history', JSON.stringify(historyLocations))
}

import { FETCH_WL_SUBSCRIPTIONS, UPDATE_WL_SUBSCRIPTIONS } from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_REQUEST, API_CALL_FAILURE, API_CALL_SUCCESS } from 'actions/actionConstants'

export const initialWLSubscriptionState: App.WLSubscriptionsState = {
  settings: {
    experiencesFrequency: '',
    travelFrequency: '',
  },
  fetching: false,
  updating: false,
  fetchError: '',
  updateError: '',
}

const apiRequests = reducerSwitch<App.WLSubscriptionsState>({
  [FETCH_WL_SUBSCRIPTIONS]: () => ({
    fetching: true,
    fetchError: '',
    updateError: '',
  }),
  [UPDATE_WL_SUBSCRIPTIONS]: () => ({
    updating: true,
    fetchError: '',
    updateError: '',
  }),
})

const apiSuccesses = reducerSwitch<App.WLSubscriptionsState>({
  [FETCH_WL_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    settings: action.data,
    fetching: false,
    fetchError: '',
  }),
  [UPDATE_WL_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    settings: action.data,
    updating: false,
    updateError: '',
  }),
})

const apiFailures = reducerSwitch<App.WLSubscriptionsState>({
  [FETCH_WL_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    fetching: false,
    fetchError: action.error,
  }),
  [UPDATE_WL_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    updating: false,
    updateError: action.error,
  }),
})

export default createReducer<App.WLSubscriptionsState>(initialWLSubscriptionState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

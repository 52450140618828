import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import React, { ComponentProps } from 'react'
import PriceRowPrice from '../PriceRowPrice'

interface Props extends Pick<ComponentProps<typeof PriceRowPrice>, 'size'> {
}

const SIZES_HEADING_VARIANTS: Record<Props['size'], ComponentProps<typeof TextLoadingBox>['typography']> = {
  S: 'heading6',
  M: 'heading5',
  L: 'heading4',
}

export const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof TextLoadingBox>['typography']> = {
  S: 'caption-medium',
  M: 'caption-large',
  L: 'caption-large',
}

function PriceRowLuxPlusPriceLoadingSkeleton(props: Props) {
  const { size } = props

  return <div>
    <TextLoadingBox typography={SIZES_HEADING_VARIANTS[size]} width="5ch" inline />
    {' '}
    <TextLoadingBox typography={SIZES_CAPTION_VARIANTS[size]} width="12ch" inline />
  </div>
}

export default PriceRowLuxPlusPriceLoadingSkeleton

import DatePickerInput from 'components/Common/Form/Input/DatePickerInput'
import FakeInput from 'components/Common/Form/Input/FakeInput'
import TextInput from 'components/Common/Form/Input/TextInput'
import TimeInput from 'components/Common/Form/Input/TimeInput'
import Group from 'components/utils/Group'
import { addDays, startOfDay } from 'lib/datetime/dateUtils'
import noop from 'lib/function/noop'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import ExperienceTransferBookingHotelSelect from './ExperienceTransferBookingHotelSelect'
import { GoogleAutocompletePlaceItem } from 'api/search'

const StretchedGroup = styled(Group)`
  > * {
    flex: 1;
  }
`

interface Props {
  transfer: App.ExperienceTransferView;
  name: string;
  disableLocations?: boolean;
  onChange?: (transfer: App.ExperienceTransferView) => void;
  experience: App.ExperienceOffer;
}

function ExperienceTransferBookingForm(props: Props) {
  const {
    name,
    transfer,
    disableLocations,
    experience,
    onChange = noop,
  } = props

  const minDate = useMemo(() => {
    const cutoffDays = ((experience.bookingCutoffHours ?? 0) / 24) + 1
    return addDays(startOfDay(new Date()), cutoffDays)
  }, [experience.bookingCutoffHours],
  )

  const onHotelChange = useCallback((option: GoogleAutocompletePlaceItem) => {
    onChange({
      ...transfer,
      hotel: {
        name: option.mainText,
        kmFromAirport: option.distanceMeters ? Math.ceil(option.distanceMeters / 1000) : undefined,
        googlePlaceId: option.placeId,
      },
    })
  }, [onChange, transfer])

  return <>
    <StretchedGroup tabletDirection="horizontal" direction="vertical" gap={12}>
      <input type="hidden" name={`${name}.type`} value={transfer.type} />
      {transfer.type === 'HOTEL-TO-AIRPORT' && <>
        <ExperienceTransferBookingHotelSelect
          label="From (Hotel)"
          required
          name={`${name}.hotel`}
          placeholder="Hotel pick up"
          disabled={disableLocations}
          transfer={transfer}
          onChange={onHotelChange}
          requiredErrorMessage="Please specify your hotel pick up"
          airport={experience?.airport}
        />
        <FakeInput
          label="To (Airport)"
          value={`${experience?.airport?.name} (${experience?.airport?.code})`}
          required
          disabled
        />
      </>}
      {transfer.type == 'AIRPORT-TO-HOTEL' && <>
        <FakeInput
          label="From (Airport)"
          value={`${experience?.airport?.name} (${experience?.airport?.code})`}
          required
          disabled
        />
        <ExperienceTransferBookingHotelSelect
          label="To (Hotel)"
          required
          name={`${name}.hotel`}
          placeholder="Hotel drop off"
          transfer={transfer}
          disabled={disableLocations}
          onChange={onHotelChange}
          airport={experience?.airport}
          requiredErrorMessage="Please specify your hotel drop off"
        />
      </>}
    </StretchedGroup>
    <StretchedGroup tabletDirection="horizontal" direction="vertical" gap={12}>
      <TextInput
        label="Flight number"
        defaultValue={transfer.flightNumber}
        name={`${name}.flightNumber`}
        placeholder="e.g. QF123"
      />
      <DatePickerInput
        label={`${transfer.type === 'AIRPORT-TO-HOTEL' ? 'Arrival' : 'Departure'} date`}
        required
        min={minDate}
        max={experience.expirationDate ? new Date(experience.expirationDate) : undefined}
        defaultValue={transfer.date}
        name={`${name}.date`}
        requiredErrorMessage={`Please provide date of ${transfer.type === 'AIRPORT-TO-HOTEL' ? 'arrival' : 'departure'}`}
      />
      <TimeInput
        label={`${transfer.type === 'AIRPORT-TO-HOTEL' ? 'Arrival' : 'Departure'} time`}
        defaultValue={transfer.time}
        name={`${name}.time`}
        placeholder="Select time"
        step={15}
        requiredErrorMessage={`Please provide time of ${transfer.type === 'AIRPORT-TO-HOTEL' ? 'arrival' : 'departure'}`}
      />
    </StretchedGroup>
  </>
}

export default ExperienceTransferBookingForm

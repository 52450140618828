import {
  CREATE_SEARCH,
} from './actionConstants'
import uuidV4 from 'lib/string/uuidV4Utils'

export function createSearch(action: {searchKey: string, searchId: string}) {
  const { searchKey, searchId } = action

  return {
    type: CREATE_SEARCH,
    key: searchKey,
    searchId,
  }
}

export function setSearchId(searchKey: string, newSearchId?: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const searchId = state.search.searches[searchKey]?.searchId ?? newSearchId ?? uuidV4()

    dispatch(createSearch({ searchKey, searchId }))
  }
}

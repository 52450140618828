import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import SolidFireIcon from 'components/Luxkit/Icons/solid/SolidFireIcon'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'
import React from 'react'

type UrgencyLabelVariant = React.ComponentProps<typeof UrgencyLabel>['variant']

const urgencyLabelTypeToVariant: Utils.PartialRecord<App.UrgencyLabelType, UrgencyLabelVariant> = {
  new: 'positive',
}

interface Props {
  offer: App.TourOffer | App.TourOfferSummary | App.CruiseOffer;
  label: App.OfferUrgencyLabel;
}

function CruiseUrgencyLabel(props: Props) {
  const { offer, label } = props

  switch (label.type) {
    case 'agenthub_exclusive':
      return <PromotionLabel variant="engagement">Agent exclusive</PromotionLabel>
    case 'left':
      return <OfferUrgencyCountdownLabel endDate={label.end!} />
    case 'sale':
      return <PromotionLabel icon={<SolidFireIcon />} variant="premium">{label.message}</PromotionLabel>
    case 'lux_plus_member_price':
      return <LuxPlusLabelWithModal
        type="member-price-offer-tour"
        saveAmount={label.saveAmount}
        offer={offer}
      />
    default:
      const variant = urgencyLabelTypeToVariant[label.type] ?? 'positive'
      return <UrgencyLabel variant={variant}>
        {label.message}
      </UrgencyLabel>
  }
}

export default CruiseUrgencyLabel

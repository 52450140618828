import { VideoData } from 'components/Common/Video/VimeoVideo'
import { AnyAction } from 'redux'

function videoData(data: VideoData) {
  return ({
    videoDealId: data.offerId,
    currentTime: data.currentTime,
    duration: data.duration,
    videoId: data.videoId,
    playSource: data.playSource,
  })
}

export const dealVideoPlayed = (action: AnyAction) => {
  if (action.data.offerId) {
    return {
      hitType: 'deal-video-played',
      videoDetails: videoData(action.data),
    }
  }
}

export const dealVideoPaused = (action: AnyAction) => {
  if (action.data.offerId) {
    return {
      hitType: 'deal-video-paused',
      videoDetails: videoData(action.data),
    }
  }
}

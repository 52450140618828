import { mediaQueryUp } from 'components/utils/breakpoint'
import React, { HTMLAttributes, useMemo, CSSProperties } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

const AspectRatioSizer = styled.div`
  width: 100%;
  aspect-ratio: var(--ar);
  max-width: var(--w);

  ${mediaQueryUp.tablet} {
    aspect-ratio: var(--ar-tablet);
    max-width: var(--w-tablet);
  }

  ${mediaQueryUp.desktop} {
    aspect-ratio: var(--ar-desktop);
    max-width: var(--w-desktop);
  }

  ${mediaQueryUp.largeDesktop} {
    aspect-ratio: var(--ar-large-desktop);
    max-width: var(--w-large-desktop);
  }

  &.fill-height {
    height: 100%;
  }
`

type AspectRatioValue = number | `${number}/${number}` | `${number}:${number}` | 'auto'

function parseAspectRatio(ratio: AspectRatioValue): string {
  if (ratio === 'auto') {
    return 'auto'
  }
  if (typeof ratio === 'number') {
    return String(ratio)
  }
  return ratio.replace(':', '/')
}

function parseWidth(width: number | string) {
  if (typeof width === 'number') {
    return rem(width)
  }
  return width
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  /**
   * @default auto
   */
  ratio?: AspectRatioValue
  /**
   * @default ratio
   */
  tabletRatio?: AspectRatioValue
  /**
   * @default tabletRatio
   */
  desktopRatio?: AspectRatioValue
  /**
   * @default desktopRatio
   */
  largeDesktopRatio?: AspectRatioValue
  /**
   * @default 100%
   */
  width?: number | string
  /**
   * @default width
   */
  tabletWidth?: number | string
  /**
   * @default tabletWidth
   */
  desktopWidth?: number | string
  /**
   * @default desktopWidth
   */
  largeDesktopWidth?: number | string
  /**
   * Force the element to fill the available space vertically
   */
  fillHeight?: boolean
}

/**
 * This component reserves the space set out by the aspect ratios (and widths) given
 * This will often by used to give images space before they load in
 *
 * Please note it will default to full width if widths are not set
 */
function AspectRatio(props: Props) {
  const {
    children,
    className,
    ratio = 'auto',
    tabletRatio,
    desktopRatio,
    largeDesktopRatio,
    width = '100%',
    tabletWidth,
    desktopWidth,
    largeDesktopWidth,
    fillHeight,
    ...rest
  } = props

  const parsedRatios = useMemo(() => {
    return {
      '--ar': parseAspectRatio(ratio),
      '--ar-tablet': parseAspectRatio(tabletRatio ?? ratio),
      '--ar-desktop': parseAspectRatio(desktopRatio ?? tabletRatio ?? ratio),
      '--ar-large-desktop': parseAspectRatio(largeDesktopRatio ?? desktopRatio ?? tabletRatio ?? ratio),
    } as CSSProperties
  }, [desktopRatio, largeDesktopRatio, ratio, tabletRatio])

  const parsedWidths = useMemo(() => {
    return {
      '--w': parseWidth(width),
      '--w-tablet': parseWidth(tabletWidth ?? width),
      '--w-desktop': parseWidth(desktopWidth ?? tabletWidth ?? width),
      '--w-large-desktop': parseWidth(largeDesktopWidth ?? desktopWidth ?? tabletWidth ?? width),
    } as CSSProperties
  }, [desktopWidth, largeDesktopWidth, width, tabletWidth])

  return <AspectRatioSizer
    {...rest}
    className={cn(className, { 'fill-height': fillHeight })}
    style={{ ...parsedRatios, ...parsedWidths }}
  >
    {children}
  </AspectRatioSizer>
}

export default React.memo(AspectRatio)

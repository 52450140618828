import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePricetagAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm14.71 5.78-9.48-9.46A.9992.9992 0 0 0 11.5 2h-6a1.0001 1.0001 0 0 0-.71.29l-2.5 2.49a.9999.9999 0 0 0-.29.71v6c.0037.2648.1073.5184.29.71l9.49 9.5c.1917.1827.4453.2863.71.29a.9993.9993 0 0 0 .71-.29l8.51-8.51a.9984.9984 0 0 0 .29-.71 1.0502 1.0502 0 0 0-.29-.7Zm-9.22 7.81L4 11.09V5.9L5.9 4h5.18l8.5 8.49-7.09 7.1Z"/>
  </SvgIcon>
}

export default LinePricetagAltIcon

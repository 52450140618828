import React from 'react'

import ViewState, { ViewAction, VIEW_INITIAL_STATE } from './ViewState'

import noop from 'lib/function/noop'

const ViewContext = React.createContext<{
  state: ViewState
  dispatch: React.Dispatch<ViewAction>
}>({
  state: VIEW_INITIAL_STATE,
  dispatch: noop,
})

export default ViewContext

import { OFFER_TYPE_HOTEL } from 'constants/offer'
import { dateDifference } from 'lib/datetime/dateUtils'

export function isPackageEligibleForZeroReservation(
  offer: App.AnyOffer,
  pkgReservable?: boolean,
  checkIn?: moment.Moment,
): boolean {
  if (offer.type != OFFER_TYPE_HOTEL || !offer.reserveForZero) { return false }
  const threshold = offer.reserveForZero.minDays
  if (pkgReservable) {
    if (!checkIn) { return true } // It is still potentially eligible
    const diff = dateDifference(checkIn.toDate())
    return diff.days > threshold
  }
  return false
}

import { useEffect, useMemo } from 'react'

import { getSavedCheckoutReduxCartState } from 'storage/checkout'
import { replaceWithRegion } from 'actions/NavigationActions'
import { restoreCheckoutCart } from 'actions/CheckoutActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import uuidV4 from 'lib/string/uuidV4Utils'

export function useRestoreCartState(cartId?: string) {
  const dispatch = useAppDispatch()
  const isCartRestored = useAppSelector(state => state.checkout.isCartRestored)
  const isAbandonedCart = useAppSelector(state => state.checkout.cart.isAbandonedCart)
  const isSpoofed = useAppSelector(state => state.auth.account.isSpoofed)
  const route = useAppSelector(state => state.router.location.pathname)

  const isCheckoutPage = useMemo(() => {
    return route.includes('checkout')
  }, [route])

  useEffect(() => {
    if (!isCheckoutPage) return
    if (isCartRestored && !isAbandonedCart) return

    const { value: cartState, timestamp } = getSavedCheckoutReduxCartState()

    // If a cartId was passed to this function and it is different than the one in memory
    // Use it and restore that cart
    if (cartId && cartState?.transactionKey !== cartId) {
      Analytics.trackClientEvent({
        subject: 'checkout_url',
        action: 'opened',
        category: 'logging',
        type: 'operational',
      })
      dispatch(replaceWithRegion(`/checkout-restore-cart?cartId=${cartId}`))
    } else {
      const isOlderThan30Min = !timestamp || Date.now() - timestamp > 30 * 60 * 1000
      dispatch(restoreCheckoutCart({
        ...cartState,
        ...(cartState && isOlderThan30Min && { transactionKey: uuidV4() }),
      }))
    }
  }, [dispatch, isCartRestored, isAbandonedCart, isCheckoutPage, cartId])

  useEffect(() => {
    if (!isCheckoutPage && isSpoofed) {
      const { value: cartState } = getSavedCheckoutReduxCartState()
      if (cartState) {
        dispatch(restoreCheckoutCart(cartState))
      }
    }
  }, [dispatch, isCheckoutPage, isSpoofed])
}

import { definitions } from '@luxuryescapes/contract-trip'
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import debounce from 'lodash.debounce'

import { cancelAndInvalidate, invalidateTrip, useTrips } from '../api'
import * as TripKeys from '../reactQueryKeys/trips'

import { arrayToMap } from 'lib/array/arrayUtils'
import {
  moveItemToOtherTrip,
  patchTripItem,
  rankTripItem,
} from 'tripPlanner/api/tripItem'
import {
  MoveItemToOtherTripError,
  PatchTripItemError,
  RankTripItemResponse,
  RankTripItemBadRequestError,
  RankTripItemNotFoundError,
} from 'tripPlanner/api/tripItem/types'
import * as Mapper from 'tripPlanner/mappers'
import { FullTrip } from 'tripPlanner/types/common'
import { TripItem } from 'tripPlanner/types/tripItem'
import { applyPatchFields } from '../utils'

/**
 * Returns true if user has any trip items
 */

export function useHasTripItems(): boolean {
  const { data: trips, isFetched } = useTrips()
  return (
    (isFetched && trips?.some((trip) => trip.itemCount > 0)) ?? false
  )
}

export const useMoveItemToOtherTrip = (
  sourceTripId: string,
  tripItemId: string,
  options?: UseMutationOptions<
    { destinationTrip: FullTrip },
    MoveItemToOtherTripError,
    string,
    undefined
  >,
): UseMutationResult<
  { destinationTrip: FullTrip },
  MoveItemToOtherTripError,
  string,
  undefined
> => {
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (destinationTripId) =>
      moveItemToOtherTrip({ sourceTripId, destinationTripId, tripItemId }).then(
        (res) => ({
          destinationTrip: Mapper.fullTrip(res.destinationTrip),
        }),
      ),
    onSettled: (data, error, destinationTripId) => {
      cancelAndInvalidate(queryClient, TripKeys.lists)
      invalidateTrip(queryClient, sourceTripId)
      invalidateTrip(queryClient, destinationTripId)
      options?.onSettled?.(data, error, destinationTripId, undefined)
    },
  })
}

export const usePatchTripItem = (
  options?: UseMutationOptions<
    TripItem,
    PatchTripItemError,
    Parameters<typeof patchTripItem>[0]
  >,
): UseMutationResult<
  TripItem,
  PatchTripItemError,
  Parameters<typeof patchTripItem>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      patchTripItem(params).then((tripItem) =>
        Mapper.tripItem(tripItem, params.tripId),
      ),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
    },
  )
}

const debouncedInvalidateTrip = debounce(invalidateTrip, 10000)

export const useRankTripItem = (
  options?: UseMutationOptions<
    RankTripItemResponse,
    RankTripItemBadRequestError | RankTripItemNotFoundError,
    Parameters<typeof rankTripItem>[0]
  >,
): UseMutationResult<
  RankTripItemResponse,
  RankTripItemBadRequestError | RankTripItemNotFoundError,
  Parameters<typeof rankTripItem>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation((params) => rankTripItem(params), {
    retry: false,
    ...options,
    onSuccess: (data, variables, context) => {
      // Update local rank on success
      const updateMap = arrayToMap(
        data.updates,
        (itemUpdate) => itemUpdate.itemId,
      )
      const trip = queryClient.getQueryData<definitions['fullTrip']>(
        TripKeys.detail(variables.tripId),
      )
      if (trip) {
        const updatedItems = trip.items.map((item) => {
          const update = updateMap.get(item.id)
          return update ? applyPatchFields(item, update) : item
        })
        queryClient.setQueryData<definitions['fullTrip']>(
          TripKeys.detail(variables.tripId),
          { ...trip, items: updatedItems },
        )
      }
      // Don't want to immediately invalidate the trip, because if the user is doing a number
      // of drag and drop operations it would cause excessive re-fetching of the trip.
      // Instead, use a debounce to invalidate the trip after ten seconds.
      debouncedInvalidateTrip(queryClient, variables.tripId)
      options?.onSuccess?.(data, variables, context)
    },
    onError: (data, variables, error) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onError?.(data, variables, error)
    },
  })
}

// eslint-disable-next-line no-restricted-imports
import themeLayout from 'constants/themes/themeLayout'
import { mapObjectToCSSVarValues } from 'lib/theme/themeUtils'

/**
 * What's the point of this empty palette?
 * It's used to generate a flat array of CSS var names.
 *
 * @example `{ primary: { normal: '' } }` will yield `--palette-primary-normal`
 */
const EMPTY_PALETTE: App.ThemePalette<string> = {
  brand: {
    primary: {
      normal: '',
      darkest: '',
      dark: '',
      light: '',
      lightest: '',
      hover: '',
      active: '',
    },
  },
  neutral: {
    default: {
      one: '',
      one20: '',
      two: '',
      three: '',
      four: '',
      five: '',
      five40: '',
      six: '',
      six25: '',
      seven: '',
      eight: '',
      clear: '',
    },
    control: {
      black100: '',
      black25: '',
      white16: '',
      white24: '',
    },
  },
  product: {
    exclusive: {
      foreground: '',
      background: '',
      contrast: '',
    },
    lastMinute: {
      foreground: '',
      background: '',
      contrast: '',
    },
    partner: {
      foreground: '',
      background: '',
      contrast: '',
    },
    premium: {
      foreground: '',
      background: '',
      contrast: '',
    },
    ultralux: {
      foreground: '',
      background: '',
      contrast: '',
      active: '',
      hover: '',
    },
    luxPlus: {
      foreground: '',
      altForeground: '',
      background: '',
      contrast: '',
      active: '',
      altActive: '',
      hover: '',
      altHover: '',
    },
    luxLoyalty: {
      silver: {
        gradient: '',
        contrast: '',
      },
      gold: {
        gradient: '',
        contrast: '',
      },
      platinum: {
        gradient: '',
        contrast: '',
      },
    },
  },
  messaging: {
    success: {
      normalForeground: '',
      lightForeground: '',
      darkForeground: '',
      normalContrast: '',
      lightContrast: '',
      darkContrast: '',
      normalBackground: '',
      lightBackground: '',
      darkBackground: '',
    },
    warning: {
      normalForeground: '',
      lightBackground: '',
      darkForeground: '',
      normalContrast: '',
      lightContrast: '',
      darkContrast: '',
      normalBackground: '',
      lightForeground: '',
      darkBackground: '',
    },
    urgency: {
      normalForeground: '',
      lightForeground: '',
      darkForeground: '',
      normalContrast: '',
      lightContrast: '',
      darkContrast: '',
      normalBackground: '',
      lightBackground: '',
      darkBackground: '',
    },
    critical: {
      normalForeground: '',
      lightForeground: '',
      darkForeground: '',
      normalContrast: '',
      lightContrast: '',
      darkContrast: '',
      normalBackground: '',
      lightBackground: '',
      darkBackground: '',
    },
    favourite: {
      lightForeground: '',
      darkForeground: '',
      normalForeground: '',
      normalContrast: '',
      lightContrast: '',
      darkContrast: '',
      normalBackground: '',
      lightBackground: '',
      darkBackground: '',
    },
  },
  highlight: {
    primary: {
      normalForeground: '',
      lightForeground: '',
      normalContrast: '',
      lightContrast: '',
      normalBackground: '',
      lightBackground: '',
      normalActive: '',
      normalHover: '',
    },
    secondary: {
      normalForeground: '',
      lightForeground: '',
      normalActive: '',
      normalContrast: '',
      lightContrast: '',
      normalBackground: '',
      lightBackground: '',
    },
    tertiary: {
      normalForeground: '',
      lightForeground: '',
      normalContrast: '',
      lightContrast: '',
      normalBackground: '',
      lightBackground: '',
    },
    luxPlus: {
      normalForeground: '',
    },
  },
}
const EMPTY_SHADOWS: App.ThemeShadows<string> = {
  bottom: {
    small: '',
    medium: '',
    large: '',
  },
  flat: {
    small: '',
    large: '',
  },
  top: {
    small: '',
    medium: '',
    large: '',
  },
}

const EMPTY_BORDER_RADII: App.ThemeBorderRadii<string> = {
  XS: '',
  S: '',
  M: '',
  L: '',
  round: '',
}

const palette: App.ThemePalette<App.CSSVarValue> = mapObjectToCSSVarValues(EMPTY_PALETTE, 'palette')
const shadow: App.ThemeShadows<App.CSSVarValue> = mapObjectToCSSVarValues(EMPTY_SHADOWS, 'shadow')
const borderRadius: App.ThemeBorderRadii<App.CSSVarValue> = mapObjectToCSSVarValues(EMPTY_BORDER_RADII, 'border-radius')

const BaseTheme: Pick<App.Theme, 'palette' | 'shadow' | 'layout' | 'borderRadius'> = {
  palette,
  shadow,
  borderRadius,
  layout: themeLayout,
}

export default BaseTheme

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMountainsSunIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 10a3.9999 3.9999 0 0 0 3.9231-4.7804 3.9998 3.9998 0 0 0-5.4539-2.9151A3.9998 3.9998 0 0 0 14 6a4.0001 4.0001 0 0 0 4 4Zm0-6a2 2 0 1 1-.0001 4A2 2 0 0 1 18 4Zm-1.15 8.47a1.0005 1.0005 0 0 0-1.3365-.3469.9994.9994 0 0 0-.3635.3469l-1 1.63-3.29-5.6a1.0004 1.0004 0 0 0-1.3549-.3587A1 1 0 0 0 9.14 8.5l-7 12A1.0002 1.0002 0 0 0 3 22h18a1.0001 1.0001 0 0 0 .85-1.53l-5-8ZM10.45 20H4.74L10 11l2.94 5-1.25 2-1.24 2Zm2.35 0 1.49-2.37.71-1.06 1-1.68L19.2 20h-6.4Z"/>
  </SvgIcon>
}

export default LineMountainsSunIcon

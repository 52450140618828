import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBagAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 6.5h-3v-1a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3Zm-9-1a1.0002 1.0002 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4v-1Zm10 13a1.0001 1.0001 0 0 1-1 1H5a1.0002 1.0002 0 0 1-1-1V13a21.7104 21.7104 0 0 0 8 1.53A21.7506 21.7506 0 0 0 20 13v5.5Zm0-7.69a19.8896 19.8896 0 0 1-16 0V9.5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1.31Z"/>
  </SvgIcon>
}

export default LineBagAltIcon

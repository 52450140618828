import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSunIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m5.64 17-.71.71a1 1 0 0 0 0 1.41.9998.9998 0 0 0 1.41 0l.71-.71A1 1 0 0 0 5.64 17ZM5 12a1 1 0 0 0-1-1H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 12.8946 2.7348 13 3 13h1a1.0002 1.0002 0 0 0 1-1Zm7-7a1 1 0 0 0 1-1V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 3v1a1.0002 1.0002 0 0 0 1 1ZM5.64 7.05a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.29 1 1 0 0 0 0-1.41l-.71-.71a1 1 0 0 0-1.41 1.41l.71.71Zm12 .29a1.0001 1.0001 0 0 0 .7-.29l.71-.71a.9993.9993 0 0 0 .3488-.721.9999.9999 0 0 0-1.0379-1.0378 1.0002 1.0002 0 0 0-.7209.3488l-.64.71a.9998.9998 0 0 0 0 1.41 1 1 0 0 0 .66.29h-.02ZM21 11h-1a1.0001 1.0001 0 0 0 0 2h1a1.0001 1.0001 0 0 0 0-2Zm-9 8a1.0001 1.0001 0 0 0-1 1v1a1.0001 1.0001 0 0 0 2 0v-1a1.0001 1.0001 0 0 0-1-1Zm6.36-2a.9997.9997 0 0 0-1.4765.7355A.9997.9997 0 0 0 17 18.36l.71.71a.9998.9998 0 0 0 1.41 0 .9998.9998 0 0 0 0-1.41l-.76-.66ZM12 6.5a5.5 5.5 0 1 0 5.5 5.5A5.51 5.51 0 0 0 12 6.5Zm0 9a3.5 3.5 0 1 1 .0002-7.0002A3.5 3.5 0 0 1 12 15.5Z"/>
  </SvgIcon>
}

export default LineSunIcon

import SolidBoltAltIcon from 'components/Luxkit/Icons/solid/SolidBoltAltIcon'
import Label from 'components/Luxkit/Label'
import { useOfferBestPrice } from 'hooks/Offers/useOfferBestPrice'
import { sum } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { getOfferExtra } from 'selectors/offerExtraSelector'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'

// Export is only temporary for experiment
export const URGENCY_TAG_THRESHOLD = 5

interface Props {
  offer: App.Offer | App.OfferSummary;
  filters?: App.OfferListFilters;
}

interface MappedStateProps {
  offerExtras?: App.OfferExtra;
}

function OfferTilePricingUrgencyTags(props: Props & MappedStateProps) {
  const {
    offer,
    filters,
    offerExtras,
  } = props

  const numBookedToday = useMemo(() => {
    if (!offerExtras) {
      return 0
    }
    return sum(offerExtras.roomsPurchasesToday, (room) => room.purchases)
  }, [offerExtras])

  const numRoomsPackage = useOfferBestPrice(offer, filters, { disabled: !!filters?.isStream })
  const offerMetaData = useOfferMetaData(offer.id, filters)

  const showBookedTodayUrgencyTag = numBookedToday >= URGENCY_TAG_THRESHOLD

  const availableRooms = numRoomsPackage?.availableRooms ?? offerMetaData?.availableRooms
  const showLowRoomsUrgencyTag = !showBookedTodayUrgencyTag &&
    !!availableRooms &&
    availableRooms !== 0 &&
    availableRooms <= URGENCY_TAG_THRESHOLD &&
    !!filters?.checkIn

  return <>
    {showBookedTodayUrgencyTag && <Label variant="urgency" startIcon={<SolidBoltAltIcon/>} kind="plain">
      Booked {numBookedToday} times today
    </Label>}
    {showLowRoomsUrgencyTag && !!availableRooms && <Label variant="urgency" kind="plain">
      Only {pluralizeToString('room', availableRooms)} left at this price!
    </Label>}
  </>
}

export default connect<MappedStateProps, undefined, Props, App.State>((state, props) => {
  return {
    offerExtras: getOfferExtra(state, props.offer.id),
  }
})(OfferTilePricingUrgencyTags)

import React, { PropsWithChildren } from 'react'

import FlightSearchWidgetStateProvider from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateProvider'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import useFlightSearchContext from 'hooks/FlightSearchWidget/useFlightSearchContext'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'

function TripSearchProvider({ children }: PropsWithChildren<{}>) {
  const { globalSearchDispatch, globalSearchState } = useGlobalSearchContext()
  const { flightSearchDispatch, flightSearchState } = useFlightSearchContext()

  return (
    <GlobalSearchContextProvider
      state={globalSearchState}
      dispatch={globalSearchDispatch}
    >
      <FlightSearchWidgetStateProvider
        state={flightSearchState}
        dispatch={flightSearchDispatch}
      >
        {children}
      </FlightSearchWidgetStateProvider>
    </GlobalSearchContextProvider>
  )
}

export default TripSearchProvider

import cn from 'clsx'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import Pane from 'components/Common/Pane'
import { rem } from 'polished'
import { searchPaneInteractEventName } from '../utils'
import { mediaQueryUp } from 'components/utils/breakpoint'

export const GlobalSearchContainerPaneContent = styled.div`
  padding: ${rem(16)};
`

export const GlobalSearchContainerPaneRoot = styled(Pane)`
  display: none;
  border-radius: ${props => props.theme.borderRadius.XS};
  box-shadow: ${props => props.theme.shadow.bottom.large};
  position: relative;
  width: 100%;

  &.display-mobile {
    display: flex;
    flex-direction: column;
  }

  ${mediaQueryUp.tablet} {
    display: flex;
    flex-direction: column;
  }
`

interface Props extends React.ComponentProps<typeof GlobalSearchContainerPaneRoot> {
  displayMobile?: boolean;
}

function GlobalSearchContainerPane(props: Props) {
  const {
    children,
    className,
    displayMobile,
    ...rest
  } = props

  const onInteract = useCallback(() => {
    document.dispatchEvent(new CustomEvent(searchPaneInteractEventName))
  }, [])

  return <GlobalSearchContainerPaneRoot
    {...rest}
    className={cn(className, { 'display-mobile': displayMobile })}
    onClick={onInteract}
    onFocus={onInteract}
    shadow={false}
  >
    {children}
  </GlobalSearchContainerPaneRoot>
}

export default GlobalSearchContainerPane

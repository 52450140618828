import cn from 'clsx'
import BodyText from 'components/Luxkit/Typography/BodyText'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Clickable from '../Clickable'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import Caption from 'components/Luxkit/Typography/Caption'

const Day = styled(Clickable)`
  min-height: ${rem(56)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.selected {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }

  &.inRange {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        filter: brightness(0.95);
      }
    }
    &:focus {
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, -2px);
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid var(--palette-neutral-default-four));
    }
  }
`

interface Props {
  selected?: boolean;
  inRange?: boolean;
  disabled?: boolean;
  className?: string;
  date: Date;
  onClick?: (date: Date, e: React.MouseEvent<HTMLButtonElement>) => void;
  label?: React.ReactNode;
  reserveLabelSpacing?: boolean;
}

function DateRangePickerDay(props: Props) {
  const {
    date,
    onClick = noop,
    selected,
    inRange,
    disabled,
    label,
    reserveLabelSpacing = false,
    className,
  } = props

  return <Day
    className={cn(className, { selected, inRange })}
    disabled={disabled}
    onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClick?.(date, e)}
  >
    <BodyText variant="medium" weight="bold" data-date={moment(date).format(ISO_DATE_FORMAT)}>{date.getDate()}</BodyText>
    {!label && reserveLabelSpacing && <Caption variant="medium" lineClamp={2} wrap="word-wrap">&nbsp;</Caption>}
    {label && (
      typeof label === 'string' ?
        <Caption variant="medium" lineClamp={2} wrap="word-wrap">{label}</Caption> :
        label
    )}
  </Day>
}

export default DateRangePickerDay

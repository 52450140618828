import React, { useRef } from 'react'
import SearchForm from 'components/Search/SearchForm'
import { useGlobalHotelsSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHotelsSearch'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import config from 'constants/config'
import { connect } from 'react-redux'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import HotelsTabContent from 'components/SearchV2/Components/Mobile/TabContents/HotelsTabContent'
import { convertDatesToString, encodeSearchParams } from 'lib/search/searchUtils'
import { useAppDispatch } from 'hooks/reduxHooks'
import { pushWithRegion } from 'actions/NavigationActions'
import { useLocation } from 'react-router'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { HOTEL_SEARCH_TYPEAHEAD_TYPES } from 'constants/search'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'

interface MappedStateProps {
  filters: App.OfferListFilters
}

function GlobalHotelsSearchTabContent(props: MappedStateProps) {
  const { filters } = props

  const { globalHotelsSearchDispatch, globalHotelsSearchState } = useGlobalHotelsSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    eventAnalytics: {
      contextLocation: 'search-list',
    },
  })
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const formRef = useRef<HTMLFormElement>(null)

  const onSearch = () => {
    if (formRef.current?.reportValidity()) {
      const {
        searchItem,
        occupancies,
        checkinDate,
        checkoutDate,
        flexibleNights,
        isFlexibleDateSelected,
        flexibleMonths,
        userSelectedFlexibleMonths,
      } = globalHotelsSearchState

      const dates = convertDatesToString(ISO_DATE_FORMAT, checkinDate, checkoutDate)
      const queryParams = encodeSearchParams({
        urlSearch: search,
        searchItem,
        filters,
        dates,
        rooms: occupancies,
        isFlexibleWithDate: isFlexibleDateSelected,
        flexibleNights,
        flexibleMonths,
        userSelectedFlexibleMonths,
      }).toString()

      dispatch(pushWithRegion(`/search?${queryParams}`))
    }
  }
  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSearch()
  }

  return <GlobalSearchContextProvider
    state={globalHotelsSearchState}
    dispatch={globalHotelsSearchDispatch}
  >
    {!config.USE_INSPIRATIONAL_SEARCH &&
      <CSSBreakpoint max="mobile">
        <VerticalSpacer as="form" onSubmit={onSearchSubmit} gap={12} ref={formRef}>
          <HotelsTabContent isInPage onSearch={onSearch} />
          <TextButton fit="mobile-full-width" kind="primary" type="submit" size="large">Search</TextButton>
        </VerticalSpacer>
      </CSSBreakpoint>
    }
    <CSSBreakpoint min="tablet">
      <VerticalSpacer gap={12}>
        <SearchForm
          submitOnApply
          typeaheadTypes={HOTEL_SEARCH_TYPEAHEAD_TYPES}
        />
      </VerticalSpacer>
    </CSSBreakpoint>
  </GlobalSearchContextProvider>
}

export default connect<MappedStateProps, undefined, any, App.State>((appState): MappedStateProps => {
  const filters = getUrlOfferListFilters(appState)
  return {
    filters,
  }
})(GlobalHotelsSearchTabContent)

import React, { useCallback } from 'react'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import ListItem from 'components/Luxkit/List/ListItem'

interface Props {
  isSelected: boolean;
  isDisabled: boolean;
  onSelect: () => void;
  onUnselect: () => void;
  departurePort: App.CruiseDepartureRegion;
}

function CruiseDepartureSelectListByRegion({ isSelected, isDisabled, onSelect, onUnselect, departurePort }: Props) {
  const onSelectByRegionClick = useCallback(() => {
    if (isSelected) {
      onUnselect()
    } else {
      onSelect()
    }
  }, [onSelect, onUnselect, isSelected])

  return <ListItem
    data-testid="cruise-departure-select-select-content-list-all-by"
    onClick={onSelectByRegionClick}
    title={<b>All {departurePort.name}</b>}
    disabled={isDisabled}
    endIcon={ <CheckboxButton size="large" checked={isSelected} disabled={isDisabled} />}
  />
}

export default CruiseDepartureSelectListByRegion

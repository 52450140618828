import {
  FETCH_BEST_BACK_PRIZE,
  FETCH_BEST_BACK_OBJECT,
  FETCH_BEST_BACK_USER_STATUS,
  FETCH_NBL_COMPETITION_PRIZE,
  FETCH_NBL_COMPETITION_OBJECT,
  FETCH_NBL_COMPETITION_USER_STATUS,
  FETCH_UK_COMPETITION_PRIZE,
  FETCH_UK_COMPETITION_OBJECT,
  FETCH_UK_COMPETITION_USER_STATUS,
  FETCH_AU_COMPETITION_PRIZE,
  FETCH_AU_COMPETITION_OBJECT,
  FETCH_AU_COMPETITION_USER_STATUS,
  FETCH_INTL_COMPETITION_PRIZE,
  FETCH_INTL_COMPETITION_OBJECT,
  FETCH_INTL_COMPETITION_USER_STATUS,
} from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_REQUEST, API_CALL_FAILURE, API_CALL_SUCCESS, FETCH_CHANNEL_MARKUP } from 'actions/actionConstants'

export const initialMarketingState: App.MarketingState = {
  competition: null,
  fetching: false,
}

// TODO: MAPPER FUNCTION FOR REDUCER
const apiRequests = reducerSwitch<App.MarketingState>({
  [FETCH_BEST_BACK_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_BEST_BACK_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_BEST_BACK_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_NBL_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_NBL_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_NBL_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_UK_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_UK_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_UK_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_AU_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_AU_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_AU_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_INTL_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_INTL_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_INTL_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_CHANNEL_MARKUP]: () => ({
    fetching: true,
  }),
})

const apiSuccesses = reducerSwitch<App.MarketingState>({
  [FETCH_BEST_BACK_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_BEST_BACK_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_BEST_BACK_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_NBL_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_NBL_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_NBL_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_UK_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_UK_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_UK_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_AU_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_AU_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_AU_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_INTL_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_INTL_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_INTL_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),

  [FETCH_CHANNEL_MARKUP]: (state, action) => ({
    fetching: false,
    channelMarkup: action.data,
  }),
})

const apiFailures = reducerSwitch<App.MarketingState>({
  [FETCH_BEST_BACK_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_BEST_BACK_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_BEST_BACK_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_NBL_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_NBL_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_NBL_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_UK_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_UK_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_UK_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_AU_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_AU_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_AU_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_INTL_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_INTL_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_INTL_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),

  [FETCH_CHANNEL_MARKUP]: () => ({
    fetching: false,
    channelMarkup: undefined,
  }),
})

export default createReducer<App.MarketingState>(initialMarketingState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { getTrendingDestinationFilters, type TrendingDestination } from './constants'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import GeoContext from 'contexts/geoContext'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TrendingDestinationsCarousel from './TrendingDestinationsCarousel'
import { useHotelTrendingDestinations } from 'home/hooks/useHotelTrendingDestinations'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import { ANYWHERE_NAME, ANYWHERE_PLACE_ID } from 'constants/search'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchRecommendedDestinations } from 'actions/RecommendationActions'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { lereRecommendedDestinationsToTrendingDestinations } from 'lib/customer/recommendationUtils'
import { sortBy } from 'lib/array/arrayUtils'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

export const DEFAULT_FILTER: App.DestinationSelectedFilters = 'hotel'
const LERE_DESTINATIONS_LIMIT = 12

interface Props {
  title?: React.ReactNode;
  defaultFilter?: App.DestinationSelectedFilters;
  filterOrder?: Record<App.DestinationSelectedFilters, number>;
}

function TrendingDestinations(props: Props) {
  const { title, defaultFilter = DEFAULT_FILTER } = props
  const [selectedFilter, setSelectedFilter] = useState<App.DestinationSelectedFilters>(defaultFilter)

  const { currentRegionCode } = useContext(GeoContext)

  const sortedFilters = useMemo(() =>
    sortBy(getTrendingDestinationFilters(currentRegionCode),
      a => props.filterOrder?.[a.id] ?? Infinity,
      'asc',
    ),
  [currentRegionCode, props.filterOrder],
  )

  const [displayTrendingDestinations, fetching] = useHotelTrendingDestinations(selectedFilter, currentRegionCode)
  const searchMapLink = `/search/map?adults=2&children=none&destinationId=${ANYWHERE_PLACE_ID}&destinationName=${ANYWHERE_NAME}`

  // --------- START: LERE AU/NZ/US/GB/SG Recommended destinations on homepage (AB TEST) ----------
  const dispatch = useAppDispatch()
  const recommendedDestinations = useAppSelector(state => state.recommendations.recommendedDestinations)
  const domainUserId = useAppSelector(state => state.auth.domainUserId)
  const leUserId = useAppSelector(getCurrentUserId)

  const isEligibleRegion = ['AU', 'NZ', 'US', 'GB', 'SG'].includes(currentRegionCode)
  const isHotelFilter = selectedFilter === 'hotel'
  const hasUserId = !!domainUserId || !!leUserId
  const hasRecommendations = recommendedDestinations.state !== 'loading' && recommendedDestinations.destinations.length > 0
  const canTriggerExperiment = isEligibleRegion && isHotelFilter && hasUserId && hasRecommendations
  const recommendedDestinationsExperimentEnabled = useOptimizelyExperiment(OptimizelyExperiments.lereRecommendedDestinations, canTriggerExperiment) === 'on'
  const [trendingDestinationsList, fetchingDestinations] = useMemo<[Array<TrendingDestination>, boolean]>(() => {
    const isLoading = fetching || recommendedDestinations.state === 'loading'
    if (recommendedDestinationsExperimentEnabled) {
      return [lereRecommendedDestinationsToTrendingDestinations(recommendedDestinations.destinations).slice(0, LERE_DESTINATIONS_LIMIT), isLoading]
    }
    return [displayTrendingDestinations, isLoading]
  }, [recommendedDestinationsExperimentEnabled, recommendedDestinations, displayTrendingDestinations, fetching])

  useEffect(() => {
    if (isEligibleRegion && hasUserId) {
      dispatch(fetchRecommendedDestinations(currentRegionCode, domainUserId, leUserId))
    }
  }, [isEligibleRegion, hasUserId, currentRegionCode, domainUserId, leUserId, dispatch])
  // --------- END: LERE AU/NZ/US/GB/SG Recommended destinations on homepage (AB TEST) ------------

  return (
    <SectionContainer gap={32}>
      <LayoutContainer>
        <VerticalSpacer gap={12}>
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="start" gap={16}>
            <Heading variant="heading2">
              {title ?? <>Immerse yourself in trending <i>destinations</i></>}
            </Heading>
            <CSSBreakpoint min="desktop">
              <TextButton kind="tertiary" size="medium" startIcon={<LineMapIcon />} to={searchMapLink} target="_blank">
                View map
              </TextButton>
            </CSSBreakpoint>
          </Group>
          <HorizontalContainerWithFadedOverflow>
            <Group direction="horizontal" gap={4}>
              {sortedFilters.map((filter) => <FilterChip
                selected={filter.id === selectedFilter}
                onClick={() => setSelectedFilter(filter.id)}
                key={filter.id}
                size="medium"
              >
                {filter.title}
              </FilterChip>)}
            </Group>
          </HorizontalContainerWithFadedOverflow>
        </VerticalSpacer>
      </LayoutContainer>
      <TrendingDestinationsCarousel fetchingDestinations={fetchingDestinations} selectedFilter={selectedFilter} trendingDestinations={trendingDestinationsList}/>
    </SectionContainer>
  )
}

export default TrendingDestinations

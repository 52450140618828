import clsx from 'clsx'
import Clickable from 'components/Common/Clickable'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'
import React, { MouseEventHandler, PropsWithChildren, useCallback, ComponentProps } from 'react'

type InheritedCaptionTextBlockProps = Pick<ComponentProps<typeof CaptionTextBlock>, 'startIcon' | 'strikeThrough'>

interface Props extends PropsWithChildren, InheritedCaptionTextBlockProps {
  /**
   * Won't bubble/propagate.
  */
  onInfoIconClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
}

function PriceRowPriceCaption(props: Props) {
  const {
    children,
    onInfoIconClick,
    strikeThrough,
    className,
  } = props

  const handleInfoIconClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()
    onInfoIconClick?.(event)
  }, [onInfoIconClick])

  return <CaptionTextBlock
    className={clsx(PriceRowPriceCaption.name, className)}
    variant="large"
    colour="neutral-two"
    weight="normal"
    format="propercase"
    endIcon={onInfoIconClick && <Clickable onClick={handleInfoIconClick}><SolidInfoCircleIcon /></Clickable>}
    strikeThrough={strikeThrough}
  >
    {children}
  </CaptionTextBlock>
}

export default PriceRowPriceCaption

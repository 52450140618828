import { useState, useRef, useCallback } from 'react'

/** Like `useState`, except it also gives you a ref which always contains the current value. */
export default function useStateWithRef<S>(initialState: S | (() => S)) {
  const [state, _setState] = useState<S>(initialState)
  const ref = useRef<S>(state)

  const setState = useCallback((newState: S | ((prevState: S) => S)) => {
    if (typeof newState === 'function') {
      _setState((prevState: S) => {
        const nextState = (newState as (prevState: S) => S)(prevState)
        ref.current = nextState
        return nextState
      })
    } else {
      _setState(newState)
      ref.current = newState
    }
  }, [])

  return [state, setState, ref] as [S, typeof setState, typeof ref]
}

import { getItineraryCountries } from 'checkout/lib/utils/cruises/itinerary'
import { excludeNullOrUndefined } from 'checkout/utils'
import { CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_VILLA, CHECKOUT_ITEM_TYPE_TOUR_V2, CHECKOUT_ITEM_TYPE_TOUR_V1, CHECKOUT_ITEM_TYPE_CRUISE, CHECKOUT_ITEM_TYPE_FLIGHT } from 'constants/checkout'
import { unique } from 'lib/array/arrayUtils'
import { getTourV2IdForCheckoutItem } from 'lib/checkout/checkoutUtils'
import { findByTwoLetterCode, isValidInsuranceCountry } from 'lib/insurance/insuranceCountries'
import { createSelector } from 'reselect'
import { getFullOffers } from 'selectors/offerSelectors'

const getDestinationCountriesFromCart = createSelector(
  getFullOffers,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.geo.airports,
  (offers, items, airports): Array<string> => {
    return unique(items.map(item => {
      if (item.itemType === CHECKOUT_ITEM_TYPE_BEDBANK) {
        const offer = offers[item.offerId] as App.BedbankOffer
        return offer ? [offer.property.address.countryName] : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL) {
        const offer = offers[item.offerId] as App.Offer
        return offer ? (offer.insuranceCountries?.length ? offer.insuranceCountries : [offer.property?.geoData.country]) : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_VILLA) {
        const offer = offers[item.offerId] as App.VillaOffer
        return offer ? (offer.insuranceCountries?.length ? offer.insuranceCountries : [offer.property?.geoData.country]) : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2) {
        const offer = offers[getTourV2IdForCheckoutItem(item)] as Tours.TourV2Offer
        const departure = offer?.departures[item.purchasableOption.fkDepartureId]
        return departure?.isGuaranteed ? offer.variations[departure.fkVariationId].countriesVisited : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1) {
        const offer = offers[item.offerId] as App.TourOffer
        if (offer) {
          return offer.locations.length > 0 ? offer.locations : [offer.locationHeading]
        }
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_CRUISE) {
        const offer = offers[item.offerId] as App.CruiseOffer
        if (offer) {
          return getItineraryCountries(offer)
        }
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_FLIGHT) {
        const flightItem = item
        const airport = airports.find(airport => airport.code === flightItem.destinationAirportCode)
        return airport?.countryCode ? [findByTwoLetterCode(airport?.countryCode)] : []
      }
    }).flat().filter(excludeNullOrUndefined).filter(isValidInsuranceCountry))
  },
)

export default getDestinationCountriesFromCart

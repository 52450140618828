export function calculateDiscountPercentage(
  price: number,
  value: number,
): number {
  if (value === 0) { return 0 }
  const discountFraction = 1 - (price / value)
  return Math.floor(100 * discountFraction) / 100
}

export function calculateDiscount(price: number, value: number) {
  if (value === 0) { return 0 }
  const discountFraction = 1 - (price / value)
  return Math.floor(100 * discountFraction)
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineClockWithTickIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.6 9.0027a.9993.9993 0 0 0-.6344.4284.9994.9994 0 0 0-.1456.7516 9.0002 9.0002 0 0 1-10.1207 10.7199A9.0001 9.0001 0 1 1 13.82 3.1827a1.02 1.02 0 0 0 .4-2 10.7998 10.7998 0 0 0-2.22-.18 11 11 0 1 0 11 11 10.8018 10.8018 0 0 0-.22-2.2.9993.9993 0 0 0-.7858-.7997 1 1 0 0 0-.3942-.0003Z"/>
    <path fill="currentColor" d="M22.7071 2.543c.3905.3905.3905 1.0236 0 1.4142l-3.5 3.5c-.3905.3905-1.0237.3905-1.4142 0l-1.5-1.5c-.3906-.3906-.3906-1.0237 0-1.4143.3905-.3905 1.0237-.3905 1.4142 0l.7929.793 2.7929-2.793c.3905-.3905 1.0237-.3905 1.4142 0ZM12 6a1 1 0 0 0-1 1v4.4201l-2.1 1.21a.9995.9995 0 0 0-.473 1.1283 1 1 0 0 0 .973.7417 1.001 1.001 0 0 0 .5-.13l2.6-1.5.09-.09.16-.13a.863.863 0 0 0 .1-.16.891.891 0 0 0 .08-.17.6524.6524 0 0 0 .05-.2l.02-.12v-5a1.0002 1.0002 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineClockWithTickIcon

import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import React from 'react'

function BusinessTravellerOfferCreditsInfoModal() {
  return <Modal title="How to earn credits">
    <BodyText variant="large">
      When you book work trips within your travel budget you can now earn Luxury Escapes credits to use for your personal travel.
      <br />
      <br />
      Any credits earned will be available in your Personal profile and will be available to redeem after you have completed your trip &amp; your organisation has confirmed your trip.
    </BodyText>
  </Modal>
}

export default BusinessTravellerOfferCreditsInfoModal

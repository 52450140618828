import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import ListItem from 'components/Luxkit/List/ListItem'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'

const IconContainer = styled.div`
  width: ${rem(48)};
  height: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
  color: ${props => props.theme.palette.highlight.tertiary.normalForeground};
  border-radius: ${props => props.theme.borderRadius.S};
`

interface Props {
  isSelected?: boolean
  onClick?: () => void
}

function CruiseLinesSearchListAll({ isSelected, onClick }: Props) {
  return <ListItem
    data-testid="cruise-line-select-list-all"
    onClick={onClick}
    title={<b>All cruise lines</b>}
    startIcon={<IconContainer>
      <LineShipIcon size="M" />
    </IconContainer>}
    endIcon={<CheckboxButton size="large" checked={isSelected} />}
  />
}

export default CruiseLinesSearchListAll

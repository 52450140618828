import Image from 'components/Common/Image'
import ListItem from 'components/Luxkit/List/ListItem'
import Pill from 'components/Luxkit/Pill'
import useOffer from 'hooks/Offers/useOffer'
import useTourV2PurchasableOptionDetails from 'hooks/TourV2/useTourV2PurchasableOptionDetails'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import React, { useMemo } from 'react'
import { SEARCH_MENU_LIST_ITEM_CLASS_NAME } from './SearchMenuListItem'
import * as Analytics from 'analytics/analytics'
import { findCheapestOfTourV2Offer } from 'lib/tours/tourUtils'

interface Props {
  searchPhrase?: string;
  searchItem: App.SearchItem
  offerId?: string
  ['data-testid']?: string
  selected?: boolean
}

function SearchMenuTourItem(props: Props) {
  const {
    searchItem,
    offerId,
    selected = false,
  } = props
  const id = offerId || searchItem.value.split(':')[1]

  const [offer] = useOffer<Tours.TourV2Offer>(id)

  const {
    cheapestPurchasableOption,
    cheapestVariation,
  } = useMemo(() => {
    if (offer) {
      return findCheapestOfTourV2Offer(offer)
    }
    return {}
  }, [offer])

  const {
    placesCount,
    countriesCount,
    durationInDaysCount,
  } = useTourV2VariationDetails(cheapestVariation)

  const {
    valuedAt,
  } = useTourV2PurchasableOptionDetails(cheapestPurchasableOption) || {}

  const title = offer?.name || searchItem?.format?.mainText
  const handleClick = () => {
    Analytics.trackClientEvent({
      subject: 'click-tour-offer',
      action: 'clicked',
      category: 'logging',
      type: 'operational',
    })
    window.open(`/tour/${id}`, '_blank')
  }

  if (!offer || !cheapestVariation) {
    return null
  }
  return <ListItem
    selected={selected}
    className={SEARCH_MENU_LIST_ITEM_CLASS_NAME}
    startIcon={<Image
      image={cheapestVariation.images[0]}
      height={48}
      width={48}
    />}
    title={title}
    subtitle={<div>
      <p>{durationInDaysCount} · {placesCount} · {countriesCount}</p>
      {valuedAt && <Pill kind="primary" variant="success">
        Save up to {valuedAt.savingPercentage}%
      </Pill>}
    </div>}
    onClick={handleClick}
    data-testid={props['data-testid']}
  />
}

export default SearchMenuTourItem

import { BrowserTracker } from '@snowplow/browser-tracker'
import uuidV4 from 'lib/string/uuidV4Utils'
import { getCookies, setCookie } from 'lib/web/cookieUtils'
import { reportClientError } from 'services/errorReportingService'

/**
 * Constants for cookie setting
 */
const cookieDisabledIndex = 0
const domainUserIdIndex = 1
const createTsIndex = 2
const visitCountIndex = 3
const nowTsIndex = 4
const lastVisitTsIndex = 5
const sessionIdIndex = 6
const previousSessionIdIndex = 7
const firstEventIdIndex = 8
const firstEventTsInMsIndex = 9
const eventIndexIndex = 10

const configVisitorCookieTimeout = 63072000 // 2 years
const configCookiePath = '/'
const configCookieDomain: string | undefined = undefined
const configCookieSameSite = 'None'

function parseIntOr(value: any, defaultValue: number | undefined) {
  const parsed = parseInt(value)
  return isNaN(parsed) ? defaultValue : parsed
}
function parseIntOrUndefined(value?: any) {
  return (value ? parseIntOr(value, undefined) : undefined)
}

/**
 * Parses the cookie values from its string representation.
 *
 * @param id Cookie value as string
 * @param domainUserId Domain user ID to be used in case of empty cookie string
 * @returns Parsed ID cookie tuple
 */
function parseIdCookie(id: string, domainUserId?: string, memorizedSessionId?: string, memorizedVisitCount?: number) {
  const now = new Date(); const nowTs = Math.round(now.getTime() / 1000); let tmpContainer
  if (id) {
    tmpContainer = id.split('.')
    // cookies enabled
    tmpContainer.unshift('0')
  }
  else {
    tmpContainer = [
      // cookies disabled
      '1',
      // Domain user ID
      domainUserId,
      // Creation timestamp - seconds since Unix epoch
      nowTs,
      // visitCount - 0 = no previous visit
      memorizedVisitCount,
      // Current visit timestamp
      nowTs,
      // Last visit timestamp - blank meaning no previous visit
      '',
      // Session ID
      memorizedSessionId,
    ]
  }
  if (!tmpContainer[sessionIdIndex] || tmpContainer[sessionIdIndex] === 'undefined') {
    // session id
    tmpContainer[sessionIdIndex] = uuidV4()
  }
  if (!tmpContainer[previousSessionIdIndex] || tmpContainer[previousSessionIdIndex] === 'undefined') {
    // previous session id
    tmpContainer[previousSessionIdIndex] = ''
  }
  if (!tmpContainer[firstEventIdIndex] || tmpContainer[firstEventIdIndex] === 'undefined') {
    // firstEventId - blank meaning no previous event
    tmpContainer[firstEventIdIndex] = ''
  }
  if (!tmpContainer[firstEventTsInMsIndex] || tmpContainer[firstEventTsInMsIndex] === 'undefined') {
    // firstEventTs - blank meaning no previous event
    tmpContainer[firstEventTsInMsIndex] = ''
  }
  if (!tmpContainer[eventIndexIndex] || tmpContainer[eventIndexIndex] === 'undefined') {
    // eventIndex – 0 = no previous event
    tmpContainer[eventIndexIndex] = 0
  }

  const parsed = [
    tmpContainer[cookieDisabledIndex],
    tmpContainer[domainUserIdIndex],
    parseIntOr(tmpContainer[createTsIndex], nowTs),
    parseIntOr(tmpContainer[visitCountIndex], memorizedVisitCount),
    parseIntOr(tmpContainer[nowTsIndex], nowTs),
    parseIntOrUndefined(tmpContainer[lastVisitTsIndex]),
    tmpContainer[sessionIdIndex],
    tmpContainer[previousSessionIdIndex],
    tmpContainer[firstEventIdIndex],
    parseIntOrUndefined(tmpContainer[firstEventTsInMsIndex]),
    parseIntOr(tmpContainer[eventIndexIndex], 0),
  ]
  return parsed
}

const overrideTrackerIds = (tracker: BrowserTracker, sessionId?: string, domainUserId?: string, sessionIdx?: number) => {
  try {
    const idCookieName = tracker.getCookieName('id') as any as string
    const sessionIdCookieName = tracker.getCookieName('ses') as any as string
    const cookiesDict = getCookies()

    if (!cookiesDict[sessionIdCookieName]) {
      return
    }
    const parsed = parseIdCookie(cookiesDict[idCookieName])

    if (sessionId !== undefined && sessionId !== 'null') {
      parsed[sessionIdIndex] = sessionId
    }

    if (domainUserId !== undefined && domainUserId !== 'null') {
      parsed[domainUserIdIndex] = domainUserId
    }

    if (sessionIdx !== undefined) {
      parsed[visitCountIndex] = sessionIdx
    }

    parsed.shift() // Remove "0" or "1"

    setCookie(
      idCookieName,
      parsed.join('.'),
      configVisitorCookieTimeout,
      configCookiePath,
      configCookieSameSite,
      configCookieDomain,
    )
  } catch (e) {
    reportClientError(e)
  }
}

export default overrideTrackerIds

import { pushWithRegion } from 'actions/NavigationActions'
import Pane from 'components/Common/Pane'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import Pill from 'components/Luxkit/Pill'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import {
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { themeClassName } from 'lib/theme/themeUtils'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { rem } from 'polished'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import LuxPlusLogo from '../Logos/LuxPlusLogo'

const LuxPlusBannerSheet = styled(Pane)`
  background-color: ${props => props.theme.palette.product.luxPlus.background};
  padding: ${rem(32)} ${rem(24)};
`

interface MappedProps {
  givenName: string
}

interface Props extends MappedProps {}

function LuxPlusFreePreviewAutoModal({ givenName }:Props) {
  const { resolve } = useModalElementContext<boolean>()
  const dispatch = useAppDispatch()

  const closeModal = useCallback(() => resolve(true), [resolve])

  const dismissModal = useCallback(() => {
    setLocalStorage(LUXURY_PLUS.DISMISS_FREE_PREVIEW_STORAGE_KEY, 1)
    closeModal()
  }, [closeModal])

  const handleFreePreviewClick = useCallback(() => {
    dismissModal()
    dispatch(pushWithRegion(`/${LUXURY_PLUS_PATHS.DEFAULT_LANDING_PAGE}?${LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM}=true`))
  }, [dismissModal, dispatch])

  return <ModalBase dismissible={false}>
    <ModalBody>
      <LuxPlusBannerSheet className={themeClassName('inverse')}>
        <Group direction="vertical" horizontalAlign="center" gap={12} >
          <Pill kind="primary" variant="success">
            Exclusive Limited Time Offer
          </Pill>
          <Heading variant="heading3" format="propercase" align="center">
            {givenName} you’re invited to experience
          </Heading>
          <LuxPlusLogo height={20} />
        </Group>
      </LuxPlusBannerSheet>
      <ModalContent>
        <BodyText variant="medium">
          We’re giving you the chance to experience a {LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview of our brand-new VIP Travel Club. Discover hidden offers and enjoy a first-hand look at the exclusive year-round member benefits
        </BodyText>
        <br />
        <BodyText variant="medium">
          This exclusive invite is for a limited time, so be sure to accept this offer before time runs out. <TextLink onClick={dismissModal} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
        </BodyText>
      </ModalContent>
    </ModalBody>
    <ModalFooter
        primaryActionProps={{
          children: `Start ${LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview`,
          onClick: handleFreePreviewClick,
        }}
        secondaryActionProps={{
          children: 'Maybe later',
          onClick: dismissModal,
        }}
      />
  </ModalBase>
}

const mapStateToProps = (state: App.State) => ({
  givenName: state.auth.account.givenName || '',
})

export default connect(mapStateToProps)(LuxPlusFreePreviewAutoModal)

import SvgIcon from 'components/Luxkit/SvgIcon'
import React from 'react'

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
  colour?: never;
}

function KrisFlyerIcon(props: Props) {
  return <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path clipRule="evenodd" d="m7.8 21.8 3.3-5.2c.3-.6.3-1.5 0-2L6.4 7C6 6 6.1 5.5 6.4 5l1.2-1.9 5.7 9.4c.6 1 .5 2 0 3l-4 6.2H7.8Z" fill="#F89C1C"/>
    <path clipRule="evenodd" d="M6.8 22.3s3.5-5.3 3.7-5.8a2 2 0 0 0 0-1.8l-5-8.2-1 1.7c-.4.5-.5 1 0 1.8L8 16a2 2 0 0 1 0 2l-4 5.9h10.4c1 0 1.7-.5 2.2-1.2L19 19c.2-.4.7-.7 1-.7h.8l-1.6-2.5h-2.5c-.5 0-1 .4-1.4 1l-2.2 3.4c-.2.3-.4.3-.5.3h-.3c0-.2 3-4.6 3.5-5.6.7-1.2.9-2.8 0-4.3L9.6.1 8.5 1.8c-.3.5-.4 1 0 1.8 0 0 5 8.5 5.3 8.8.6 1 .5 2.3 0 3.2L10 21.8h2.8c1 0 1.2-.4 1.5-.7l2-3c.1-.2.6-1 1.1-1h1.9v.1c-.4.1-.8.4-1 .8-.4.4-2.2 3.2-2.4 3.5-.3.4-.6.8-1.4.8H6.8Z" fill="#F89C1C"/>
  </SvgIcon>
}

export default KrisFlyerIcon

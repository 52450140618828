import React from 'react'
import noop from 'lib/function/noop'
import AssistChip from 'components/Luxkit/Chips/AssistChip'

export interface Option {
  value: string;
  text: string;
  url: string;
}

interface Props {
  onClickHandler?: (option: Option) => void;
  onContextMenuHandler?: (option: Option) => void;
  onImpressionHandler?: (option: Option) => void;
  option: Option;
}

function PopularDestinationButton({
  onClickHandler = noop,
  onContextMenuHandler = noop,
  onImpressionHandler,
  option,
}: Props) {
  React.useEffect(() => {
    onImpressionHandler?.(option)
  }, [onImpressionHandler, option])

  return <AssistChip
    size="medium"
    variant="filled"
    key={option.value}
    rel="nofollow"
    to={option.url}
    onClick={() => onClickHandler(option)}
    onContextMenu={() => onContextMenuHandler(option)}
  >
    {option.text}
  </AssistChip>
}

export default PopularDestinationButton

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMoneyStackIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22 17H2a1.0002 1.0002 0 0 0-.7071 1.7071C1.4804 18.8946 1.7348 19 2 19h20a1.0001 1.0001 0 0 0 0-2Zm0 4H2a1.0002 1.0002 0 0 0-.7071 1.7071C1.4804 22.8946 1.7348 23 2 23h20a1.0001 1.0001 0 0 0 0-2ZM6 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm14-6H4a3 3 0 0 0-3 3v8a2.9998 2.9998 0 0 0 3 3h16a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3Zm1 11a1.0001 1.0001 0 0 1-1 1H4a1.0002 1.0002 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v8Zm-9-7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 4a1.0002 1.0002 0 0 1-.9808-1.195.9998.9998 0 0 1 1.3635-.7289A1.0003 1.0003 0 0 1 13 8a1.0002 1.0002 0 0 1-1 1Zm6-2a1.0002 1.0002 0 0 0-.9808 1.195.9998.9998 0 0 0 1.3635.7289A1.0003 1.0003 0 0 0 19 8a1.0002 1.0002 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineMoneyStackIcon

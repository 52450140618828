import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { urlHashDatePicker } from 'constants/url'
import { EmptyObject } from 'lib/object/objectUtils'
import offerPageURL from 'lib/offer/offerPageURL'
import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import React, { useMemo } from 'react'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  filters?: App.OfferListFilters
}

function BedbankSearchTileUnavailabilityBanner({
  offer,
  filters = EmptyObject,
}: Props) {
  const offerDatePickerUrl = useMemo(() => {
    const searchParams = buildSearchParamsFromFilters({
      ...filters,
      checkIn: undefined,
      checkOut: undefined,
    })
    return offerPageURL(offer, searchParams) + urlHashDatePicker
  }, [filters, offer])

  return <MessageBanner
    kind="informational-primary"
    description={<b>Unavailable for your dates</b>}
    primaryActionButtonProps={{
      children: 'View available dates',
      to: offerDatePickerUrl,
    }}
  />
}

export default BedbankSearchTileUnavailabilityBanner

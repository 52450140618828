import WalledContent from 'components/Common/WalledContent'
import OfferTilePricing from 'components/OfferList/OfferListTiles/Support/OfferTilePricing'
import SearchTileFlightOnlyPricing from 'components/OfferList/OfferListTiles/Support/SearchTileFlightOnlyPricing'
import Group from 'components/utils/Group'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import { EmptyObject } from 'lib/object/objectUtils'
import { calculateDiscountPercentage } from 'lib/payment/calculateDiscount'
import moment from 'moment'
import React, { useMemo } from 'react'
import SearchTileWalledMessage from '../shared/SearchTileWalledMessage'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'

interface Props {
  bestPackage?: App.HotelPackage
  filters?: App.OfferListFilters
  offer: App.HotelOffer | App.HotelOfferSummary
  bestRate?: App.OfferAvailableRate
}

function HotelSearchTilePriceStack({
  bestPackage,
  filters,
  offer,
  bestRate,
}: Props) {
  const hasDates = !!(filters?.checkIn && filters?.checkOut && filters?.rooms?.length)
  const checkInDate = useMemo(() => {
    return filters?.checkIn ? moment(filters.checkIn) : undefined
  }, [filters?.checkIn])

  const directSearchPrices = useDirectSearchPrices({ filters: filters ?? EmptyObject, offerId: offer.id })
  const discountPercentage = useMemo(() => {
    if (offer.isDiscountPillHidden) {
      return undefined
    }

    if (directSearchPrices?.lowestPrice && directSearchPrices?.lowestPriceValue) {
      return calculateDiscountPercentage(directSearchPrices.lowestPrice, directSearchPrices.lowestPriceValue)
    }

    if (bestPackage) {
      return calculateDiscountPercentage(bestPackage.price, bestPackage.value)
    }

    return undefined
  }, [bestPackage, directSearchPrices, offer])

  return <div className={HotelSearchTilePriceStack.name}>
    {!bestPackage && <LoadingIndicator inline ballSize={12} />}
    {bestPackage && <WalledContent
      enforced={offer.walledGarden}
      wall={<SearchTileWalledMessage discountPercentage={discountPercentage} />}
    >
      <Group direction="vertical" gap={8} fullWidth>
        {!offer.bundledWithFlightsOnly && <OfferTilePricing
          offer={offer}
          hasDates={hasDates}
          checkInDate={checkInDate}
          pkg={bestPackage}
          pricing={bestRate}
          align="start"
          filters={filters}
        />}
        {offer.bundledWithFlightsOnly && (
          <SearchTileFlightOnlyPricing
            offer={offer}
            rooms={filters?.rooms}
            hasDates={hasDates}
            pkg={bestPackage}
            pricing={bestRate}
          />
        )}
      </Group>
    </WalledContent>}
  </div>
}

export default HotelSearchTilePriceStack

import { unique } from 'lib/array/arrayUtils'

const titleGenderMap: Record<string, string> = {
  Mr: 'Male',
  Ms: 'Female',
  Mrs: 'Female',
  Miss: 'Female',
  Master: 'Male',
}

const flightsTitleConversionMap: Record<string, string> = {
  Master: 'mstr',
}

type FrequentFlyerProgram = {
  booking_carrier: string,
  program_carrier: string,
  number: string }

interface OrderBaggage {
  id: string;
  departing: { count: number };
  returning: { count: number };
}

export function buildBaggagePayload(
  departingBaggage: App.Checkout.BaggageQuantityMap = {},
  returningBaggage: App.Checkout.BaggageQuantityMap = {},
): Array<OrderBaggage> {
  const ids = unique([
    ...Object.keys(departingBaggage),
    ...Object.keys(returningBaggage),
  ])

  return ids.map((id) => ({
    id,
    departing: {
      count: departingBaggage[id] || 0,
    },
    returning: {
      count: returningBaggage[id] || 0,
    },
  }))
}

function transformTitle(title: string) {
  return flightsTitleConversionMap[title] ?? title.toLowerCase()
}

/**
 * Format the passenger request object
 *
 * @remarks
 * Some pax attributes are mandatory, even though we do not surface them in the traveller detail forms.
 * In these cases, fallback to another form - a sensible choice is that of the primary contact's
 */
export function buildPassengerPayload(
  pax: App.Checkout.FlightPassenger,
  form: App.Checkout.TravellerForm,
  fallbackForm: App.Checkout.TravellerForm,
  baggagePayload: Array<OrderBaggage>,
) {
  const passportFields = form.passportNumber ? {
    passport_number: form.passportNumber,
    country_of_issue: form.countryOfIssue,
    nationality: form.nationality,
    passport_expiry_date: form.passportExpiry,
  } : null

  const title = (pax.type === 'adult' ? form.title : form.minorTitle) || 'Mr'
  const gender = titleGenderMap[title]

  return {
    type: pax.type,
    birth_date: form.dateOfBirth,
    given_name: form.firstName,
    surname: form.lastName,
    ...(form.middleName && { middle_names: [form.middleName] }),
    gender,
    title: transformTitle(title),
    extras: baggagePayload.length > 0 ? { baggage: baggagePayload } : {},
    email: form.email || fallbackForm.email,
    post_code: form.postcode || fallbackForm.postcode,
    ...loyaltyProgramNumbers(form),
    ...(passportFields ?? {}),
    ...(form.usStateOfResidence ? { state_of_residence: form.usStateOfResidence } : {}),
    ...(form.prefix ? { phone: `${form.prefix}${form.phone}` } : { phone: `${fallbackForm.prefix}${fallbackForm.phone}` }),
  }
}

function loyaltyProgramNumbers(form: App.Checkout.TravellerForm) {
  if (!form.qantasFrequentFlyerNo && !form.velocityFrequentFlyerNo) {
    return {}
  }

  const programNumbers: Array<FrequentFlyerProgram> = []

  if (form.qantasFrequentFlyerNo) {
    programNumbers.push({
      booking_carrier: 'QF',
      program_carrier: 'QF',
      number: form.qantasFrequentFlyerNo,
    })
  }

  if (form.velocityFrequentFlyerNo) {
    programNumbers.push({
      booking_carrier: 'VA',
      program_carrier: 'VA',
      number: form.velocityFrequentFlyerNo,
    })
  }
  return { loyalty_program_numbers: { frequent_flyer: programNumbers } }
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineParkingCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M13 7h-3a1 1 0 0 0-1 1v8a1 1 0 1 0 2 0v-2h2a3 3 0 0 0 3-3v-1a3 3 0 0 0-3-3Zm1 4a1.0001 1.0001 0 0 1-1 1h-2V9h2a1 1 0 0 1 1 1v1Zm-2-9a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm0 18a8.0005 8.0005 0 0 1-7.391-4.9385A8 8 0 1 1 12 20Z"/>
  </SvgIcon>
}

export default LineParkingCircleIcon

// A hotel reservation can be either 'instant booking' or 'buy now book later'
export const RESERVATION_TYPE_INSTANT_BOOKING = 'instant_booking'
export const RESERVATION_TYPE_BOOK_LATER = 'buy_now_book_later'
export const RESERVATION_TYPE_BOOK_LATER_DATE_SELECTION = 'bnbl_date_selection'

export const RESERVATION_STATE_PENDING = 'pending'

export const CHANNEL_MANAGER_SYNXIS = 'synxis'

export const DOWNGRADE_TYPE_PACKAGE_UPGRADE = 'package_change'
export const DOWNGRADE_TYPE_EXTEND_DATES = 'extend_dates'
export const DOWNGRADE_TYPE_PACKAGE_AND_DATES_CHANGE = 'package_and_dates_change'
export const DOWNGRADE_TYPE_BNBL = 'bnbl'
export const DOWNGRADE_TYPE_CHANGE_DATES_TACTICAL_AO_HOTEL = 'change_dates_tactical_ao_hotel'
export const DOWNGRADE_TYPE_CHANGE_DATES_BEDBANK = 'change_dates_bedbank'

export const PACKAGE_CHANGE_DOWNGRADE_TYPES = [
  DOWNGRADE_TYPE_PACKAGE_UPGRADE,
  DOWNGRADE_TYPE_EXTEND_DATES,
  DOWNGRADE_TYPE_PACKAGE_AND_DATES_CHANGE,
]

import config from 'constants/config'
import {
  STRIPE_CUSTOM_PAYTO_TYPE,
  STRIPE_PAYMENT_ELEMENT_GIROPAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_IDEAL_TYPE,
  STRIPE_PAYMENT_ELEMENT_METHODS,
  STRIPE_3DS_V2_PAYMENT_TYPE,
  STRIPE_PAYMENT_ELEMENT_CARD_TYPE,
  STRIPE_PAYMENT_ELEMENT_APPLE_PAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_GOOGLE_PAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_AFTERPAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE,
  PaymentMethod,
  StripePaymentElementPaymentMethodType,
  STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_TYPE,
} from 'constants/payment'
import getPaymentMethods, { getPaymentMethodsByRegion } from 'lib/payment/getPaymentMethods'

export interface AccommodationMetaDataObject {
  deal_country: string;
  lead_time?: number;
  deal_destination: string;
  is_refundable?: string;
}

export const paymentElementMethodMap: Utils.PartialRecord<PaymentMethod, StripePaymentElementPaymentMethodType> = {
  [STRIPE_PAYMENT_ELEMENT_CARD_TYPE]: 'card',
  [STRIPE_PAYMENT_ELEMENT_APPLE_PAY_TYPE]: 'apple_pay',
  [STRIPE_PAYMENT_ELEMENT_GOOGLE_PAY_TYPE]: 'google_pay',
  [STRIPE_PAYMENT_ELEMENT_AFTERPAY_TYPE]: 'afterpay_clearpay',
  [STRIPE_PAYMENT_ELEMENT_GIROPAY_TYPE]: 'giropay',
  [STRIPE_PAYMENT_ELEMENT_IDEAL_TYPE]: 'ideal',
  [STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE]: 'klarna',
}

export const getPaymentElementRedirectionURL = (orderId: string, transactionKey: string): string => {
  const regionCode = window.location.pathname.split('/')[1]
  const baseUrl = `${window.location.protocol}//${window.location.host}/${regionCode}`
  return `${baseUrl}/checkout/${transactionKey}/purchase?orderId=${orderId}&`
}

export const isStripe3dsEnabled = (): boolean => config.STRIPE_3DS_ENABLED

export const isStripe3dsV2Enabled = (currencyCode: string): boolean => !!currencyCode && !!config.STRIPE_3DS_V2_ENABLED && getPaymentMethods(currencyCode).includes(STRIPE_3DS_V2_PAYMENT_TYPE)

export const isStripePaymentElementCardEnabled = (regionCode: string, currencyCode: string): boolean => {
  return (
    !!regionCode &&
    !!currencyCode &&
    !!(config.STRIPE_PAYMENT_ELEMENT_ENABLED) &&
    (
      getPaymentMethodsByRegion(regionCode).includes(STRIPE_PAYMENT_ELEMENT_CARD_TYPE) ||
      getPaymentMethods(currencyCode).includes(STRIPE_PAYMENT_ELEMENT_CARD_TYPE)
    )
  )
}

export const isStripePaymentElementBusinessCardEnabled = (regionCode: string, currencyCode: string): boolean => {
  return (
    !!regionCode &&
    !!currencyCode &&
    !!(config.businessTraveller.currentAccountMode === 'business' && config.STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_ENABLED) &&
    (
      getPaymentMethodsByRegion(regionCode).includes(STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_TYPE) ||
      getPaymentMethods(currencyCode).includes(STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_TYPE)
    )
  )
}

export const isStripePaymentElementGiropayEnabled = (regionCode: string): boolean => !!regionCode && !!config.STRIPE_PAYMENT_ELEMENT_GIROPAY_ENABLED && getPaymentMethodsByRegion(regionCode).includes(STRIPE_PAYMENT_ELEMENT_GIROPAY_TYPE)

export const getStripePaymentElementMethods = (regionCode: string): Array<string> => regionCode ? (getPaymentMethodsByRegion(regionCode).filter(method => STRIPE_PAYMENT_ELEMENT_METHODS.includes(method))) : []

export const isStripe3dsForStandaloneEnabled = (): boolean => !!config.STRIPE_3DS_STANDALONE_FLIGHTS_ENABLED

export const isStripe3dsForGiftCardsEnabled = (): boolean => !!config.STRIPE_3DS_GIFT_CARDS_ENABLED

export const isStripeWalletFlightsEnabled = (): boolean => !!config.STRIPE_WALLET_FLIGHTS_ENABLED

export const isStripeCustomPaytoEnabled = (currencyCode: string): boolean => !!currencyCode && !!config.STRIPE_PAYTO_ENABLED && getPaymentMethods(currencyCode).includes(STRIPE_CUSTOM_PAYTO_TYPE)

export const isStripeBedbankRepeatCustomerCheckEnabled = (): boolean => !!config.STRIPE_BEDBANK_REPEAT_CUSTOMER_CHECK_ENABLED

export const prepareInstalmentAdditionalInfoForStripe = (order: App.Order) => {
  try {
    return {
      // Null asserting here, since the bad dereference is being handled by try catch
      product_type: order.instalmentDetails?.items[0].offerType,
    }
  } catch {
    return {}
  }
}

export const isStripePaymentElementIDealEnabled = (regionCode: string): boolean => {
  const idealEnabled = getPaymentMethodsByRegion(regionCode).includes(STRIPE_PAYMENT_ELEMENT_IDEAL_TYPE)
  return !!regionCode && !!config.STRIPE_PAYMENT_ELEMENT_IDEAL_ENABLED && idealEnabled
}

export const isStripePaymentElementKlarnaEnabled = (currencyCode: string): boolean => {
  return !!currencyCode && !!config.STRIPE_PAYMENT_ELEMENT_KLARNA_ENABLED && getPaymentMethods(currencyCode).includes(STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE)
}

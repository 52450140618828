import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePhoneIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19.44 13c-.22 0-.45-.07-.67-.12a9.439 9.439 0 0 1-1.31-.39 2 2 0 0 0-2.48 1l-.22.45a12.1806 12.1806 0 0 1-2.66-2 12.1773 12.1773 0 0 1-2-2.66l.42-.28a2.0002 2.0002 0 0 0 1-2.48 10.3204 10.3204 0 0 1-.39-1.31c-.05-.22-.09-.45-.12-.68a3.0004 3.0004 0 0 0-3-2.49h-3a2.9999 2.9999 0 0 0-3 3.41 18.9998 18.9998 0 0 0 16.52 16.46h.38a3 3 0 0 0 2-.76 2.9997 2.9997 0 0 0 1-2.25v-3a2.9999 2.9999 0 0 0-2.47-2.9Zm.5 6a1.0005 1.0005 0 0 1-.34.75 1.0503 1.0503 0 0 1-.82.25A17 17 0 0 1 4.07 5.22a1.09 1.09 0 0 1 .25-.82.9999.9999 0 0 1 .75-.34h3a1 1 0 0 1 1 .79c.04.2733.09.5433.15.81a11.12 11.12 0 0 0 .46 1.55l-1.4.65a1 1 0 0 0-.49 1.33 14.49 14.49 0 0 0 7 7c.2434.1.5165.1.76 0a1.0016 1.0016 0 0 0 .3372-.2028 1.002 1.002 0 0 0 .2328-.3172l.62-1.4a13.68 13.68 0 0 0 1.58.46c.2666.06.5366.11.81.15a1.0001 1.0001 0 0 1 .79 1l.02 3.12Z"/>
  </SvgIcon>
}

export default LinePhoneIcon

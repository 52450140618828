import { definitions } from '@luxuryescapes/contract-search'
import { pluralizeToString } from 'lib/string/pluralize'

export function tourV2PopularDestinationMap(
  trendingDestination: definitions['toursSearchTrendingDestinations'],
): App.SearchDestination {
  return {
    destinationType: trendingDestination.placeType,
    format: {
      mainText: trendingDestination.destinationName,
      secondaryText: trendingDestination.count > 0 ? `${pluralizeToString('offer', trendingDestination.count)} available` : undefined,
    },
    searchType: 'destination',
    value: trendingDestination.placeIds.join(','),
    imageId: trendingDestination.imageId,
  }
}

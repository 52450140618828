import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import config from 'constants/config'
import { OFFER_TYPE_HOTEL } from 'constants/offer'
import React from 'react'
import Group from 'components/utils/Group'
import TermsAndConditionsLink from '../TermsAndConditionsLink'

function ReserveForZeroPolicyModalContent() {
  return (
    <Group gap={24} direction="vertical">
      <Group gap={8} direction="vertical">
        <Heading variant="heading6">
          Cancellation policy
        </Heading>
        <BodyText variant="medium">
          You can cancel your reservation free of charge within 48 hours of submitting your reservation. After that, you will be automatically charged for the booking as per your selected payment plan. Please visit our terms & conditions below to view refund policies.
        </BodyText>
      </Group>
      <Group gap={8} direction="vertical">
        <Heading variant="heading6">
          Eligibility
        </Heading>
        <BodyText variant="medium">
          Currently only available for {config.productTypeLabelMap[OFFER_TYPE_HOTEL]} hotel deals.
        </BodyText>
      </Group>
      <Group gap={8} direction="vertical">
        <Heading variant="heading6">
          Maximum open reservations
        </Heading>
        <BodyText variant="medium">
          You can only make 2 reservations at one time.
        </BodyText>
      </Group>
      <Group gap={8} direction="vertical">
        <Heading variant="heading6">
          Reservation fee
        </Heading>
        <BodyText variant="medium">
          There is no fee for the 48 hour reservation. After that, any standard booking or service fees applicable to your booking will apply.
        </BodyText>
      </Group>
      <Group gap={8} direction="vertical">
        <Heading variant="heading6">
          Failed payments
        </Heading>
        <BodyText variant="medium">
          Failure to complete payment will result in the cancellation of your booking.
        </BodyText>
      </Group>
      <BodyText variant="medium">
        {config.brandConfig.name} T&C's apply - see <TermsAndConditionsLink variant="black" />.
      </BodyText>
    </Group>
  )
}

export default ReserveForZeroPolicyModalContent

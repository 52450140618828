import TextButton from 'components/Luxkit/Button/TextButton'
import SearchTileCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileCancellationPolicy'
import useOffer from 'hooks/Offers/useOffer'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { useAppSelector } from 'hooks/reduxHooks'
import useMapSearchOfferUrl from 'hooks/Search/useMapSearchOfferUrl'
import { nonNullable } from 'lib/array/arrayUtils'
import { getVillaCapacityText } from 'lib/homesAndVillas/getVillaCapacityText'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { buildSearchParamsKey } from 'lib/search/searchUtils'
import React, { useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import SearchTile from '../SearchTile'
import SearchTileRowOverlineLocation from '../shared/SearchTileRowOverlineLocation'
import HotelSearchTilePriceStack from './HotelSearchTilePriceStack'
import VillaSearchTileFeatures from './VillaSearchTileFeatures'

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary
  filters?: App.OfferListFilters
}

function VillaSearchTile({ offer, filters }: Props) {
  const metaData = useOfferMetaData(offer.id, filters)
  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const isSpecificSearch = !!(checkIn && checkOut && filters?.rooms)

  const [, fetchingOffer] = useOffer<App.Offer>(offer.id, {
    requireSummaryOnly: !isSpecificSearch,
  })

  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])

  const bestMaybeAvailableRate = useAppSelector<App.OfferMaybeAvailableRate | undefined>((state) => state.offer.offerBestPrices[offer.id]?.[searchKey])
  const bestPriceError = useAppSelector<boolean>((state) => !!state.offer.offerPricesErrors[offer.id]?.[searchKey])

  const bestPackage = useMemo<App.HotelPackage | undefined>(() => {
    if (!isSpecificSearch || bestPriceError) {
      return offer.lowestPricePackage
    }
    if (bestMaybeAvailableRate?.available && !fetchingOffer) {
      const bestPricePkg = offer.packages.find(pkg => pkg.uniqueKey === bestMaybeAvailableRate.rate.packageUniqueKey)
      return bestPricePkg ?? offer.lowestPricePackage
    }
  }, [isSpecificSearch, bestPriceError, bestMaybeAvailableRate, fetchingOffer, offer])
  const cancellationPolicyType = bestPackage?.roomRate?.cancellationPolicy?.type

  const locations = useMemo<Array<string>>(() => nonNullable([offer.locationHeading, offer.locationSubheading]), [offer])
  const mapUrl = useMapSearchOfferUrl(offer)

  const capacityText = useMemo(() => getVillaCapacityText(offer), [offer])

  const bestAvailableRate = bestMaybeAvailableRate?.available ? bestMaybeAvailableRate.rate : undefined

  return <SearchTile
    className={VillaSearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    action={<TextButton nonInteractable kind="primary">View offer</TextButton>}
    bookmarkAction={<BookmarkButton offer={offer}/>}
    cancellationPolicyLabel={!!cancellationPolicyType && <SearchTileCancellationPolicy
      cancellationPolicyType={cancellationPolicyType}
      checkInDate={filters?.checkIn}
      timezoneOffset={offer?.property?.timezoneOffset}
      offerType={offer.type}
    />}
    features={<VillaSearchTileFeatures offer={offer} pkg={bestPackage} />}
    images={offer.images}
    overline={<SearchTileRowOverlineLocation locations={locations} mapUrl={mapUrl} />}
    pricePoint={<HotelSearchTilePriceStack offer={offer} filters={filters} bestPackage={bestPackage} bestRate={bestAvailableRate} />}
    rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
    subtitle={capacityText}
    title={offer.property.name}
  />
}

export default VillaSearchTile

import React, { MouseEventHandler, useCallback, useContext } from 'react'
import AdditionalGuestsMessage from './AdditionalGuestsMessage'
import AdditionalGuestsModal from './AdditionalGuestsModal'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import LineExclamationTriangleIcon from 'components/Luxkit/Icons/line/LineExclamationTriangleIcon'
import ModalContext from 'contexts/ModalContext'
import ContactContext from 'contexts/contactContext'

interface Props {
  complex?: boolean;
  heading?: string;
  description: string;
  modalContent?: string;
  triangleWarning?: boolean;
}

function AdditionalGuestsPopup({
  complex,
  heading,
  description,
  modalContent,
  triangleWarning,
}:Props) {
  const currentContact = useContext(ContactContext)
  const showModal = useContext(ModalContext)
  const toggleModalCallback = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.preventDefault()
    showModal(<AdditionalGuestsModal
      description={modalContent}
      currentContact={currentContact}
    />)
  }, [currentContact, modalContent, showModal])

  return <>
    {complex && <MessageBanner
      kind="warning"
      icon={triangleWarning ? <LineExclamationTriangleIcon /> : undefined}
      heading={heading}
      description={description}
      secondaryActionButtonProps={modalContent ? { onClick: toggleModalCallback, children: 'Details' } : undefined}
    />}

    {!complex && <AdditionalGuestsMessage
      message={description}
      onClick={modalContent ? toggleModalCallback : undefined}
    />}
  </>
}

export default AdditionalGuestsPopup

import { useAppSelector } from 'hooks/reduxHooks'
import { getAvailableBookingProtectionBenefits } from 'luxPlus/selectors/benefits/bookingProtection'

function useLuxPlusBookingProtectionAvailableCopy() {
  const luxPlusBookingProtectionBenefitView = useAppSelector(getAvailableBookingProtectionBenefits)
  if (luxPlusBookingProtectionBenefitView.isAvailable && luxPlusBookingProtectionBenefitView.totalMemberMaxSavings) {
    return `Save up to ${luxPlusBookingProtectionBenefitView.luxPlusDiscountPercentage}% on cancellation protection at checkout`
  }
}

export default useLuxPlusBookingProtectionAvailableCopy

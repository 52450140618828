import React from 'react'

import { UserPermissions } from 'tripPlanner/types/common'

export interface TripContextValue {
  tripId: string
  viewAsTemplate: boolean
  isPublicTrip: boolean
  permissions: UserPermissions
  name: string
  startDate?: moment.Moment
  endDate?: moment.Moment
  displayContext: 'savedItemsDrawer' | 'tripPlanner' | 'homepage' | 'search'
}

const TripContext = React.createContext<TripContextValue | null>(null)

export const useTripId = () => React.useContext(TripContext)?.tripId ?? ''

export default TripContext

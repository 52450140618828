import { setGeoCoordsCookie } from 'cookies/geoCoordsCookie'

function getCurrentGeoLocation(): Promise<GeolocationCoordinates> {
  if (!navigator?.geolocation) {
    return Promise.reject('Not supported by client')
  }

  return new Promise<GeolocationCoordinates>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((position) => {
      setGeoCoordsCookie(position.coords.latitude, position.coords.longitude)
      resolve(position.coords)
    }, (reason) => {
      if (reason.PERMISSION_DENIED) {
        reject('We couln\'t determine your location as we don\'t have permission, please allow access.')
      } else if (reason.POSITION_UNAVAILABLE) {
        reject('We couldn\'t determine your location, it looks like positioning services are unavailable.')
      } else if (reason.TIMEOUT) {
        reject('We couldn\'t determine your location, something went wrong.')
      }
    })
  })
}

export default getCurrentGeoLocation

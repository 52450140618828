import React from 'react'
import loadScript from 'lib/window/loadScript'
import config from 'constants/config'
import TextButton from 'components/Luxkit/Button/TextButton'
import SolidAppleIcon from 'components/Luxkit/Icons/solid/SolidAppleIcon'
import { SOCIAL_LOGIN_ICON_BUTTON_PROPS, SOCIAL_LOGIN_TEXT_BUTTON_PROPS } from './SocialLoginButton'
import IconButton from 'components/Luxkit/Button/IconButton'
import * as Analytics from 'analytics/analytics'
import { AppleLoginData } from 'actions/AuthActions'
import { jwtDecode } from 'jwt-decode'

interface Props {
  className?: string;
  isBlocked?: boolean;
  hideText?: boolean;
  onBlockedClick?: () => void;
  onSuccess: (data: AppleLoginData) => void;
  onFailure: (data: any) => void;
  redirectUri: string;
  onPrivateAppleEmailLogin?: (appleLoginData: App.AppleLoginData, email: string) => void
}

function AppleLoginButton(props: Props) {
  const {
    onFailure,
    onSuccess,
    className,
    isBlocked,
    onBlockedClick,
    redirectUri,
    hideText,
    onPrivateAppleEmailLogin,
  } = props

  const onTryLogin = () => {
    Analytics.trackClientEvent({
      subject: 'sign_up_sso_click',
      action: 'apple_auth',
      category: 'logging',
      type: 'operational',
    })
    loadScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js').then(() => {
      window.AppleID.auth.init({
        clientId: config.APPLE_APP_ID,
        scope: 'name email',
        redirectURI: redirectUri,
        state: 'login',
        usePopup: true,
      })

      window.AppleID.auth.signIn().then((data) => {
        const { id_token } = data.authorization
        const body = {
          id_token,
          givenName: data.user?.name.firstName,
          firstName: data.user?.name.lastName,
        }

        const { email }: { email: string } = jwtDecode(id_token)

        if (email.endsWith('@privaterelay.appleid.com') && onPrivateAppleEmailLogin) {
          onPrivateAppleEmailLogin(body, email)
        } else {
          onSuccess(body)
        }
      }).catch(onFailure)
    })
  }

  return <>
    {!hideText && <TextButton
      {...SOCIAL_LOGIN_TEXT_BUTTON_PROPS}
      id="appleid-signin"
      data-color="white"
      data-border="true"
      startIcon={<SolidAppleIcon />}
      className={className}
      onClick={!isBlocked ? onTryLogin : onBlockedClick}
    >
      Apple
    </TextButton>}
    {hideText && <IconButton
      {...SOCIAL_LOGIN_ICON_BUTTON_PROPS}
      id="appleid-signin"
      data-color="white"
      data-border="true"
      className={className}
      onClick={!isBlocked ? onTryLogin : onBlockedClick}
    >
      <SolidAppleIcon />
    </IconButton>}
  </>
}

export default AppleLoginButton

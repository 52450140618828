import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTagIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m21.12 10.71-8.41-8.42A.9989.9989 0 0 0 12 2H3a1 1 0 0 0-1 1v9a.9998.9998 0 0 0 .29.71l8.42 8.41a3 3 0 0 0 4.24 0L21.12 15a3 3 0 0 0 0-4.24v-.05Zm-1.41 2.82-6.18 6.17a.9997.9997 0 0 1-1.41 0L4 11.59V4h7.59l8.12 8.12a.9984.9984 0 0 1 .29.71.9995.9995 0 0 1-.29.7Z"/>
  </SvgIcon>
}

export default LineTagIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M8.5 17a1.0002 1.0002 0 0 0 1-1 1.3596 1.3596 0 0 0 0-.2.6406.6406 0 0 0-.06-.18.7595.7595 0 0 0-.09-.18l-.12-.15a1.0007 1.0007 0 0 0-.33-.21.9998.9998 0 0 0-.6-.08l-.18.06-.18.09a1.5773 1.5773 0 0 0-.15.12l-.12.15a.7595.7595 0 0 0-.09.18.6409.6409 0 0 0-.06.18 1.3596 1.3596 0 0 0 0 .2 1 1 0 0 0 1 1L8.5 17Zm8 0a1.0001 1.0001 0 0 0 1-1 1.3624 1.3624 0 0 0 0-.2.6402.6402 0 0 0-.06-.18.7618.7618 0 0 0-.09-.18l-.12-.15a1.1495 1.1495 0 0 0-.33-.21c-.2435-.1-.5165-.1-.76 0a1.1495 1.1495 0 0 0-.33.21l-.12.15a.7618.7618 0 0 0-.09.18.6402.6402 0 0 0-.06.18 1.3624 1.3624 0 0 0 0 .2c.0011.2623.1053.5137.29.7a.9998.9998 0 0 0 .67.3Zm-3-12h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2Zm5-3h-12a3 3 0 0 0-3 3v12a3.0003 3.0003 0 0 0 2 2.82V21a1 1 0 1 0 2 0v-1h10v1a1.0001 1.0001 0 0 0 2 0v-1.18a3.0003 3.0003 0 0 0 2-2.82V5a3 3 0 0 0-3-3Zm1 15a1.0001 1.0001 0 0 1-1 1h-12a1.0002 1.0002 0 0 1-1-1v-3h14v3Zm0-5h-14V5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7Z"/>
  </SvgIcon>
}

export default LineBusIcon

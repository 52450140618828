import moment from 'moment'
import React, { useMemo } from 'react'
import {
  DurationOption,
  durationOptionsDefault,
} from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker/DurationPicker'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'

// Input string is in the format of: `03-2022,04-2022,1-2023`
export function transformMonthString(flexibleMonths: string): string {
  if (!flexibleMonths) {
    return ''
  }
  const monthsWithYears = flexibleMonths.split(',').sort()
  const length = monthsWithYears.length
  if (length === 1) {
    const [year, month] = monthsWithYears[0].split('-')
    return `${moment(month, 'MM').format('MMM')} ${year}`
  } else {
    const [year1, month1] = monthsWithYears[0].split('-')
    const [year2, month2] = monthsWithYears[1].split('-')
    if (year1 === year2) {
      return `${moment(month1, 'MM').format('MMM')}, ${moment(month2, 'MM').format('MMM')} ${year1}${length > 2 ? ` +${length - 2}` : ''}`
    }
    return `${moment(month1, 'MM').format('MMM')} ${year1}, ${moment(month2, 'MM').format('MMM')} ${year2}${length > 2 ? ` +${length - 2}` : ''}`
  }
}

export function prettyFlexibleDates(flexibleMonths: string = '', flexibleNights?: string, durationOptions: Array<DurationOption> = durationOptionsDefault): string {
  const duration = durationOptions.find((option) => {
    return option.id === flexibleNights
  })
  const monthString = transformMonthString(flexibleMonths)
  let text = duration !== undefined ? `${duration.caption}` : 'I’m flexible'
  if (flexibleMonths?.length > 0) {
    text = `${text} • ${monthString}`
  }
  return text
}

interface Props {
  onDateToggle: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  flexibleMonths?: string
  flexibleNightsId?: string
  durationOptionsCustom?: Array<DurationOption>;
}

function FlexibleDateSearchField({
  className,
  onDateToggle,
  flexibleMonths,
  flexibleNightsId,
  durationOptionsCustom,
}: Props) {
  const text = useMemo(() => prettyFlexibleDates(flexibleMonths, flexibleNightsId, durationOptionsCustom), [durationOptionsCustom, flexibleMonths, flexibleNightsId])
  return (
    <SearchFormField
      className={className}
      label="When?"
      placeholder={text}
      onClick={onDateToggle}
      data-testid="search-flexible-search-trigger-input"
    />
  )
}

export default FlexibleDateSearchField

import getCreditPayableAmount from './getCreditPayableAmount'
import { createSelector } from 'reselect'
import { isUsingCredit } from '../view/generic'

const getCreditPayAmount = createSelector(
  isUsingCredit,
  getCreditPayableAmount,
  (useCredit, amount) => useCredit ? amount : 0,
)

export default getCreditPayAmount

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUserPlusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21 10.5h-1v-1a1.0002 1.0002 0 0 0-1-1 1 1 0 0 0-1 1v1h-1a1.0001 1.0001 0 0 0 0 2h1v1a1.0001 1.0001 0 0 0 2 0v-1h1a1.0001 1.0001 0 0 0 0-2Zm-7.7 1.72A4.9195 4.9195 0 0 0 15 8.5a5 5 0 0 0-10 0 4.9197 4.9197 0 0 0 1.7 3.72A8.0001 8.0001 0 0 0 2 19.5a1 1 0 1 0 2 0 5.9999 5.9999 0 0 1 10.2426-4.2426A5.9996 5.9996 0 0 1 16 19.5a1.0001 1.0001 0 0 0 2 0 8.0005 8.0005 0 0 0-4.7-7.28ZM10 11.5a2.9996 2.9996 0 0 1-2.7716-1.852A3 3 0 1 1 10 11.5Z"/>
  </SvgIcon>
}

export default LineUserPlusIcon

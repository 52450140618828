import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { getImageUrl } from 'lib/image/imageUtils'
import config from 'constants/config'
import { removeRegionCode } from 'lib/string/regionUtils'

interface OpenGraphMetaData {
  description?: string
  image?: string
  imageAlt?: string
  // siteName?: string
  title?: string
  // url?: string
}

type TwitterCardType = 'summary' | 'summary_large_image' /* | 'app' | 'player' */
type TwitterUsername = '@Lux_Escapes' | undefined

interface TwitterMetaData {
  card?: TwitterCardType
  description?: string
  image?: string
  imageAlt?: string
  title?: string
  username?: TwitterUsername
}

interface Props extends RouteComponentProps<any> {
  canonical?: string
  customerPortalURL: string
  description?: string
  hrefLangOverrides?: any
  imageId?: string
  imageUrl?: string
  imageAlt?: string
  noIndex?: boolean
  originalCanonicalURL: string
  regionCode: string
  regionWhitelist?: Array<any>
  title?: string
  twitter?: TwitterMetaData
  defer?: boolean
}

class HeadData extends Component<React.PropsWithChildren<Props>> {
  static defaultProps: Partial<Props> = {
    imageId: config.head.brandOpenGraphImageId,
    twitter: {
      card: 'summary_large_image' as TwitterCardType,
      username: config.BRAND === 'luxuryescapes' ? '@Lux_Escapes' : undefined as TwitterUsername,
    },
  }

  render() {
    const {
      description,
      twitter,
      imageAlt,
      imageId,
      imageUrl,
      title,
      location,
      regionWhitelist,
      hrefLangOverrides,
      customerPortalURL,
      originalCanonicalURL,
      noIndex,
      canonical,
      regionCode,
      defer,
    } = this.props

    const metaTitle = title || config.head.defaultTitle
    const metaDescription = description || config.head.defaultDescription
    const metaImage = imageUrl ?? (imageId && getImageUrl(imageId, { width: 650, format: 'jpeg' }))
    const metaImageAlt = imageAlt || metaDescription

    let finalRegions = config.regions
    if (regionWhitelist) {
      finalRegions = config.regions.filter(region => regionWhitelist.map(code => code.toLowerCase()).includes(region.code))
    }
    const path = removeRegionCode(IS_SSR ? originalCanonicalURL : location.pathname, regionCode)

    const ogMeta: OpenGraphMetaData = {
      title: metaTitle,
      image: metaImage,
      description: metaDescription,
      imageAlt: metaImageAlt,
    }

    const twitterMeta: TwitterMetaData = {
      ...HeadData.defaultProps.twitter,
      title: twitter?.title || metaTitle,
      image: twitter?.image ? getImageUrl(twitter.image, { width: 650, format: 'jpeg' }) : metaImage,
      description: twitter?.description || metaDescription,
      imageAlt: twitter?.imageAlt || metaImageAlt,
    }

    // Small trick to avoid Helmet adding alternate tags that are not canonical in accommodation and bedbank offer pages.
    const pauseAddingAlternateTags = !canonical && (path.includes('/offer/') || path.includes('/partner/'))

    return (
      <Helmet defer={defer}>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle}></meta>
        <meta name="description" content={metaDescription}></meta>
        <link
          rel="canonical"
          href={`${customerPortalURL}/${regionCode.toLowerCase()}${canonical ?? (path)}`}
        />
        {noIndex &&
          <meta name="robots" content="noindex, follow" />
        }
        <meta name="robots" content="max-image-preview:large" />
        {!pauseAddingAlternateTags && finalRegions.map((region) => (
          <link
            key={region.code}
            rel="alternate"
            href={`${customerPortalURL}/${region.code.toLowerCase()}${canonical ?? path}`}
            hrefLang={hrefLangOverrides?.[region.code.toUpperCase()] || region.lang}
          />
        ))}
        <link rel="alternate" href={`${customerPortalURL}/au${path}`} hrefLang="x-default" />

        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content={ogMeta.title}></meta>
        <meta property="og:description" content={ogMeta.description}></meta>
        <meta property="og:image" content={ogMeta.image} itemProp="image"></meta>
        <meta property="og:image:alt" content={ogMeta.imageAlt}></meta>
        <meta property="og:url" content={`${customerPortalURL}/${regionCode.toLowerCase()}${path}`}></meta>
        <meta property="og:site_name" content={config.title}></meta>

        <meta name="twitter:title" content={twitterMeta.title}></meta>
        <meta name="twitter:description" content={twitterMeta.description}></meta>
        <meta name="twitter:card" content={twitterMeta.card}></meta>
        <meta name="twitter:image" content={twitterMeta.image}></meta>
        <meta name="twitter:image:alt" content={twitterMeta.imageAlt}></meta>
        {twitterMeta.username && <meta name="twitter:site" content={twitterMeta.username}></meta>}
      </Helmet>
    )
  }
}

const mapStateToProps = (state: App.State) => ({
  regionCode: state.geo.currentRegionCode,
  customerPortalURL: state.config.customerPortalURL,
  originalCanonicalURL: state.config.originalCanonicalURL,
})

export default connect(mapStateToProps)(withRouter(HeadData))

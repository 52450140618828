import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFileDownloadIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20 8.94a1.307 1.307 0 0 0-.06-.27v-.09a1.0702 1.0702 0 0 0-.19-.28l-6-6a1.071 1.071 0 0 0-.28-.19h-.1a1.099 1.099 0 0 0-.31-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.94Zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1V5.41ZM18 19a1.0001 1.0001 0 0 1-1 1H7a1.0002 1.0002 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v9Zm-4.71-4.71-.29.3V12a1.0001 1.0001 0 0 0-2 0v2.59l-.29-.3a1.0041 1.0041 0 1 0-1.42 1.42l2 2c.0951.091.2072.1624.33.21a.94.94 0 0 0 .76 0 1.0012 1.0012 0 0 0 .33-.21l2-2a1.0041 1.0041 0 1 0-1.42-1.42Z"/>
  </SvgIcon>
}

export default LineFileDownloadIcon

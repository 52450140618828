import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function Line18PlusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 5h1v1a1.0002 1.0002 0 0 0 1.7071.7071.9997.9997 0 0 0 .2929-.707V5h1a1 1 0 1 0 0-2h-1V2a.9997.9997 0 0 0-1-1 1 1 0 0 0-1 1v1h-1a1 1 0 1 0 0 2ZM7 7v10a1 1 0 1 0 2 0V7a1 1 0 0 0-2 0Zm14.6 2a.9993.9993 0 0 0-.6344.4284.9994.9994 0 0 0-.1456.7516 9 9 0 1 1-7-7 1.0199 1.0199 0 0 0 .4-2A10.8001 10.8001 0 0 0 12 1a11 11 0 1 0 11 11 10.8017 10.8017 0 0 0-.22-2.2.9994.9994 0 0 0-.7858-.7997A1.0004 1.0004 0 0 0 21.6 9ZM11 9v1a2.9997 2.9997 0 0 0 .78 2 3.0001 3.0001 0 0 0-.78 2v1a3 3 0 0 0 3 3h1a3 3 0 0 0 3-3v-1a3.0001 3.0001 0 0 0-.78-2 2.9997 2.9997 0 0 0 .78-2V9a3 3 0 0 0-3-3h-1a3 3 0 0 0-3 3Zm5 6a1.0001 1.0001 0 0 1-1 1h-1a.9997.9997 0 0 1-1-1v-1a1.0003 1.0003 0 0 1 1-1h1a1.0001 1.0001 0 0 1 1 1v1Zm0-6v1a1.0001 1.0001 0 0 1-1 1h-1a.9997.9997 0 0 1-1-1V9a1.0001 1.0001 0 0 1 1-1h1a1 1 0 0 1 1 1Z"/>
  </SvgIcon>
}

export default Line18PlusIcon

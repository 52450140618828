import { createSelector } from 'reselect'
import getDestinationCountriesFromCart from './getDestinationCountriesFromCart'
import getDestinationCountriesFromExistingOrder from './getDestinationCountriesFromExistingOrder'

const isDomesticTrip = createSelector(
  (state: App.State) => state.geo.currentRegionName,
  getDestinationCountriesFromCart,
  getDestinationCountriesFromExistingOrder,
  (regionName, destCountriesFromCart, destCountriesFromOrder): boolean => {
    return destCountriesFromCart.length > 0 ?
      destCountriesFromCart.every(countryName => countryName === regionName) :
      destCountriesFromOrder.every(countryName => countryName === regionName)
  },
)

export default isDomesticTrip

import { without } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import uuidV4 from 'lib/string/uuidV4Utils'
import React, { useCallback, useMemo, useState } from 'react'

interface MasterModalState {
  open: boolean;
  ids: Array<string>;
}

interface ModalRegistrationState {
  registerModal: (id?: string) => string;
  unregisterModal: (id: string) => void;
}

export const MasterModalRegistrationContext = React.createContext<ModalRegistrationState>({
  unregisterModal: noop,
  registerModal: () => '',
})

const MasterModalContext = React.createContext<MasterModalState>({
  open: false,
  ids: [],
})

export default MasterModalContext

/**
 * Master/top level modal context provider
 * There should only be 1 of these ever
 */
export function MasterModalProvider(props: React.PropsWithChildren) {
  const [modals, setModals] = useState<Array<string>>(() => [])

  const registerModal = useCallback((id?: string) => {
    const nextId = id ?? uuidV4()
    setModals((currentValues) => [...currentValues, nextId])
    return nextId
  }, [])

  const unregisterModal = useCallback((id: string) => {
    setModals((currentValues) => without(currentValues, id))
  }, [])

  const state = useMemo<MasterModalState>(() => {
    return {
      open: modals.length > 0,
      ids: modals,
    }
  }, [modals])

  const registrationState = useMemo<ModalRegistrationState>(() => {
    return {
      unregisterModal,
      registerModal,
    }
  }, [unregisterModal, registerModal])

  return <MasterModalContext.Provider value={state}>
    <MasterModalRegistrationContext.Provider value={registrationState}>
      {props.children}
    </MasterModalRegistrationContext.Provider>
  </MasterModalContext.Provider>
}

import { makeCSSVar, readCSSVar } from 'lib/theme/themeUtils'

export const themeLayoutCSSVariables: App.ThemeLayout<App.CSSVar> = {
  headerFloatingOffset: makeCSSVar('app', 'header', 'floating', 'offset'),
  headerRelativeOffset: makeCSSVar('app', 'header', 'relative', 'offset'),
  footerFloatingOffset: makeCSSVar('app', 'footer', 'floating', 'offset'),
  footerRelativeOffset: makeCSSVar('app', 'footer', 'relative', 'offset'),
}

const themeLayout: App.ThemeLayout<App.CSSVarValue> = {
  headerFloatingOffset: readCSSVar(themeLayoutCSSVariables.headerFloatingOffset),
  headerRelativeOffset: readCSSVar(themeLayoutCSSVariables.headerRelativeOffset),
  footerFloatingOffset: readCSSVar(themeLayoutCSSVariables.footerFloatingOffset),
  footerRelativeOffset: readCSSVar(themeLayoutCSSVariables.footerRelativeOffset),
}

export default themeLayout

const GROUP_SIZE = 3

/**
  * Format phone number (and optional prefix)
  *
  * @remarks
  * - Groups of size 3
  * - Sanitise: Remove '\s' and '+' before processing
  *
  * @param phoneNumber - input number
  * @returns Formatted phone number string
  */
export function formatPhoneNumber(phoneNumber: string, prefix?: string) {
  const sanitisedInput = phoneNumber.replace(/\s/g, '')
  const numberGroups = prefix ? [`+${prefix.replace(/\+/g, '')}`] : []

  for (let anchor = 0; anchor < sanitisedInput.length; anchor += GROUP_SIZE) {
    const group = sanitisedInput.substring(anchor, anchor + GROUP_SIZE)
    numberGroups.push(group)
  }

  return numberGroups.join(' ')
}

/**
  * Remove all formatting from a phone number
  *
  * @remarks
  * - It is safe to strip the leading '0' (if it exists), national numbers never have a leading '0'
  * - Prefix is not part of this string
  *
  * @param input - input phone number
  * @returns sanitised phone number
  */
export function sanitisePhoneNumber(input: string) {
  const charsArr: Array<string> = []
  for (let i = 0; i < input.length; i++) {
    if (typeof input?.charAt != 'function') { continue }
    const char = input.charAt(i)
    if (i === 0 && char === '0') { continue }
    if (isNaN(parseInt(char))) { continue }
    charsArr.push(char)
  }
  return charsArr.join('')
}

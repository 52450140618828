import React from 'react'
import Caption from 'components/Luxkit/Typography/Caption'
import TextLink from 'components/Luxkit/TextLink'

interface Props {
  message: string;
  onClick?: (e: any) => void;
}

function AdditionalGuestsMessage(props: Props) {
  const { onClick, message } = props
  return <>
    {!!onClick && <TextLink size="small" onClick={onClick}>{message}</TextLink>}
    {!onClick && <Caption variant="medium" as="span" weight="bold">{message}</Caption>}
  </>
}

export default AdditionalGuestsMessage

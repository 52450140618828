import { LOCATION_SEARCH_ERROR_MESSAGE } from 'constants/search'
import React, { useMemo, useCallback } from 'react'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { rem } from 'polished'
import styled from 'styled-components'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import Group from 'components/utils/Group'
import ListSubheader from 'components/Luxkit/List/ListSubheader'
import { usePlacesBySearchPhrase } from 'hooks/usePlace'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import { capitaliseFirstLetterOnly } from 'lib/string/stringUtils'
import SolidBellIcon from 'components/Luxkit/Icons/solid/SolidBellIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { UserPreferenceDestination } from './types'

const CheckboxGroup = styled(Group)`
  padding-inline: ${rem(16)};
`

const concatSubtitleForPlace = (
  place: App.SearchItem,
) => {
  const subtitleArray: Array<string> = []
  if (place.searchType === 'destination' && !!place.destinationType) {
    subtitleArray.push(capitaliseFirstLetterOnly(place.destinationType))
  }
  if (place.format.secondaryText) {
    subtitleArray.push(place.format.secondaryText)
  }

  return subtitleArray.length > 0 ? subtitleArray.join(' · ') : undefined
}

interface Props {
  searchPhrase?: string;
  onSelect: (item: UserPreferenceDestination) => void;
  selectedPlaces: Array<UserPreferenceDestination>;
  includeSelected?: boolean;
}

const placeTypes: Array<App.SearchPlaceType> = [
  'city',
  'multi_city_vicinity',
  'country',
  'multi_city_vicinity',
  'province_state',
  'high_level_region',
]

function SearchPlaceToggleList(props: Props) {
  const {
    searchPhrase = '',
    onSelect,
    selectedPlaces,
    includeSelected = false,
  } = props

  const [searchResults, loading] = usePlacesBySearchPhrase(searchPhrase, {
    disabled: false,
    placeTypes: placeTypes,
    vertical: 'hotel',
    priority: 'next',
    limit: 10,
  })

  const selectedPlaceIds = useMemo(() => new Set(selectedPlaces.map(sp => sp.placeId)), [selectedPlaces])
  const selectedPlaceIdsWithAlert = useMemo(() => new Set(selectedPlaces.filter(sp => sp.isAlertSelected).map(sp => sp.placeId)), [selectedPlaces])

  const onClick = useCallback((searchItem: App.SearchItem) => () => onSelect(
    {
      placeId: searchItem.value,
      isAlertSelected: selectedPlaceIdsWithAlert.has(searchItem.value) || false,
      name: searchItem.format.mainText,
      imageId: searchItem.imageId,
    },
  ), [selectedPlaceIdsWithAlert, onSelect])

  if (searchPhrase.length === 0) {
    return null
  }

  return <div style={{ position: 'relative' }}>
    <LoadingIndicator visible={loading} floating>
      <BodyText variant="large" weight="bold" colour="neutral-one">Loading</BodyText>
    </LoadingIndicator>
    {searchPhrase.length > 0 && !loading && searchResults.length === 0 && <VerticalSpacer gap={4}>
      <ListSubheader colour="neutral-two">
        Sorry, we couldn't find <b>{searchPhrase}</b>.
      </ListSubheader>
      <ListSubheader>{LOCATION_SEARCH_ERROR_MESSAGE}</ListSubheader>
    </VerticalSpacer>}
    <CheckboxGroup direction="vertical" gap={12}>
      {searchPhrase.length > 0 &&
        searchResults.map((searchItem) => (includeSelected || !selectedPlaceIds.has(searchItem.value)) &&
          <CheckboxInput
            key={`${searchItem?.value}-toggle-list`}
            onClick={onClick(searchItem)}
            size="large"
            checked={selectedPlaceIds.has(searchItem.value)}
      >
            <BodyTextBlock
              endIcon={selectedPlaceIdsWithAlert.has(searchItem.value) ? <SolidBellIcon size="XS" /> : null}
              variant="medium"
              weight="bold">
              {searchItem.format.mainText}
            </BodyTextBlock>
            {searchItem.searchType === 'destination' && <Caption colour="neutral-three" variant="medium">{concatSubtitleForPlace(searchItem)}</Caption>}
          </CheckboxInput>)}
    </CheckboxGroup>
  </div>
}

export default SearchPlaceToggleList

import React, { useEffect, useRef } from 'react'

import LineFacebookIcon from 'components/Luxkit/Icons/line/LineFacebookIcon'
import config from 'constants/config'
import { fireInteractionEvent, fireNonInteractionEvent } from 'api/googleTagManager'
import { loadScriptOnReady } from 'lib/window/loadScript'
import TextButton from 'components/Luxkit/Button/TextButton'
import { SOCIAL_LOGIN_ICON_BUTTON_PROPS, SOCIAL_LOGIN_TEXT_BUTTON_PROPS } from './SocialLoginButton'
import IconButton from 'components/Luxkit/Button/IconButton'
import { useAppDispatch } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import { FacebookLoginData } from 'actions/AuthActions'

interface Props {
  className?: string;
  isBlocked?: boolean;
  hideText?: boolean;
  onBlockedClick?: () => void;
  onSuccess: (data: FacebookLoginData) => void;
  customIcon?: JSX.Element;
}

function FacebookLoginButton(props: Props) {
  const {
    isBlocked,
    onBlockedClick,
    onSuccess,
    hideText,
    customIcon,
  } = props

  const mountTime = useRef<number>(new Date().getTime())
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: config.FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: false,
        version: 'v14.0',
      })
    }

    loadScriptOnReady(
      'https://connect.facebook.net/en_US/sdk.js',
      { async: true, defer: true, crossOrigin: 'anonymous' },
    ).then(() => {
      fireNonInteractionEvent({
        category: 'fb-login',
        action: 'load-script',
        label: (new Date().getTime() - mountTime.current).toString(),
      })
    })
  }, [dispatch])

  const onFacebookLogin = (data: any) => {
    if (data && data.status === 'unknown') {
      // The user Probably closed the facebook window without signing in
      // https://developers.facebook.com/docs/facebook-login/web
      return
    }
    onSuccess({
      app_id: config.FACEBOOK_APP_ID,
      email: data.email,
      access_token: data.accessToken,
      givenName: data.first_name,
      surname: data.last_name,
    })
  }

  const onClick = () => {
    Analytics.trackClientEvent({
      subject: 'sign_up_sso_click',
      action: 'facebook_auth',
      category: 'logging',
      type: 'operational',
    })
    fireInteractionEvent({
      category: 'fb-login',
      action: 'clicked',
      label: (new Date().getTime() - mountTime.current).toString(),
    })

    window.FB.login((response: any) => {
      if (response.authResponse) {
        window.FB.api('/me', { fields: 'first_name,last_name,email' }, (me: any) => {
          onFacebookLogin({
            ...me,
            ...response.authResponse,
          })
        })
      } else {
        onFacebookLogin({ status: response.status })
      }
    }, { scope: 'public_profile,email' })
  }

  return <>
    {!hideText && <TextButton
      {...SOCIAL_LOGIN_TEXT_BUTTON_PROPS}
      startIcon={<LineFacebookIcon />}
      onClick={!isBlocked ? onClick : onBlockedClick}
    >
      Facebook
    </TextButton>}
    {hideText && <IconButton
      {...SOCIAL_LOGIN_ICON_BUTTON_PROPS}
      onClick={!isBlocked ? onClick : onBlockedClick}
    >
      {customIcon || <LineFacebookIcon /> }
    </IconButton>}
  </>
}

export default FacebookLoginButton

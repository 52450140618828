import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import SolidExclamationCircleIcon from 'components/Luxkit/Icons/solid/SolidExclamationCircleIcon'
import IconButton from '../../Luxkit/Button/IconButton'
import { SvgIconColour } from 'components/Luxkit/SvgIcon'

const StyledAvatarIconButton = styled(IconButton)`
  flex-shrink: 0;
  line-height: 1em;
  position: relative; /** so that the absolutely positioned badge can sit properly */

  &.size-x-large.shape-circle {
    --button-size: ${rem(80)};
  }
`

const UserInitials = styled.div`
  font-weight: ${props => props.theme.font.primary.weight.bold};
  letter-spacing: -0.5px;

  &.size-small,
  &.size-medium {
    font-size: ${rem(12)};
  }

  &.size-large,
  &.size-x-large {
    font-size: ${rem(32)};
  }
`

const NotificationSection = styled.div`
  position: absolute;
  top: ${rem(-4)};
  right: ${rem(-4)};
`

const RedDotOnSignUp = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
  border-radius: 50%;
  background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
`

const buttonSizes = new Set(['small', 'medium', 'large'])

function checkIsButtonSize(size: string): size is 'small' | 'medium' | 'large' {
  return buttonSizes.has(size)
}

interface Props extends Partial<Omit<ComponentProps<typeof IconButton>, 'shape' | 'children' | 'size'>> {
  firstName?: string;
  lastName?: string;
  hasNotification?: boolean;
  notificationType?: SvgIconColour;
  size?: 'small' | 'medium' | 'large' | 'x-large'
  showRedDot?: boolean;
}

function AvatarCircle({
  className,
  firstName,
  lastName,
  kind = 'primary',
  size = 'medium',
  variant = 'dark',
  hasNotification,
  notificationType,
  showRedDot,
  ...rest
}: Props) {
  const isButtonSize = checkIsButtonSize(size)

  return <StyledAvatarIconButton
    {...rest}
    kind={kind}
    variant={variant}
    shape="circle"
    size={isButtonSize ? size : undefined}
    role="menuitem"
    className={cn(className,
      { [`size-${size}`]: !isButtonSize },
    )}
  >
    {(firstName && lastName) && <UserInitials className={cn(className, `size-${size}`)}>
      {(firstName[0] + lastName[0]).toUpperCase()}
    </UserInitials>}
    {!(firstName && lastName) && <LineUserIcon data-testid="user-icon" />}
    <NotificationSection>
      {hasNotification && <SolidExclamationCircleIcon colour={notificationType ?? 'warning'} size="XS" />}
      {showRedDot && <RedDotOnSignUp />}
    </NotificationSection>
  </StyledAvatarIconButton>
}

export default AvatarCircle

import React, { useCallback, useContext, useRef, useState } from 'react'
import cn from 'clsx'
import { rem } from 'polished'
import styled from 'styled-components'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import SlideDown from 'components/Common/SlideDown'
import Caption from 'components/Luxkit/Typography/Caption'
import TextButton from 'components/Luxkit/Button/TextButton'
import {
  CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL,
  CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL,
} from 'constants/carHire'
import CarHireSearchDriversAgeCategorySelect from '../CarHireSearchBar/CarHireSearchDriversAgeCategorySelect'
import CarHireSearchDrawer from './CarHireSearchDrawer'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { DMY_CASUAL_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { shortTimeHourFormatAmPm } from 'lib/datetime/dateUtils'
import useToggle from 'hooks/useToggle'
import CarHireTimeSelect from '../CarHireSearchBar/CarHireTimeSelect'
import { pushWithRegion } from 'actions/NavigationActions'
import qs from 'qs'

import { useAppDispatch } from 'hooks/reduxHooks'
import CarHireDriversAgeInput from '../CarHireSearchBar/CarHireDriversAgeInput'
import { getCarHireLocationId, getCarHireSearchType } from '../carHireUtils'
import BusinessTravellerSelectCarHireMobile from 'businessTraveller/components/select-traveller/BusinessTravellerSelectCarHireMobile'
import config from 'constants/config'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'
import BusinessTravellerSelectCarHireDisabled from 'businessTraveller/components/select-traveller/BusinessTravellerSelectCarHireDisabled'

export enum searchSteps {
  LOCATION = 'location',
  SECONDARY_LOCATION = 'secondaryLocation',
  DATE = 'date',
  PICKUP_TIME = 'pickUpTime',
  RETURN_TIME = 'returnTime',
}

// Margin doesn't animate on slide down so we're left with
// double gap when this is closed. So fake it with a padding top instead
// this padding will get animated correctly
const SlideWrapper = styled(SlideDown)`
  margin: 0;

  >:first-child {
    padding-top: ${rem(12)};
  }
`

const DriversAgeInput = styled(CarHireDriversAgeInput)`
  width: ${rem(118)};
`

const DriversAgeContainer = styled.div`
  opacity: 1;
  transition: opacity 0.2s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`

function CarHireMobileSearchControls() {
  const {
    searchItem,
    secondarySearchItem,
    checkinDate: pickUpDate,
    checkoutDate: dropOffDate,
    pickUpTime,
    dropOffTime,
    driversAgeCategory,
    driversAge,
  } = useContext(GlobalSearchStateContext)

  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const dispatch = useAppDispatch()

  const pickUpTimeFieldRef = useRef<HTMLButtonElement>(null)
  const dropOffTimeFieldRef = useRef<HTMLButtonElement>(null)
  const needsActualAge = driversAgeCategory === 1 || driversAgeCategory === 3

  const [activeStep, setActiveStep] = useState<searchSteps | null>(null)
  const [showPickUpTimeSelect, toggleShowPickUpTimeSelect] = useToggle(false)
  const [showDropOffTimeSelect, toggleShowDropOffTimeSelect] = useToggle(false)
  const [differentLocations, setDifferentLocations] = useState<boolean>(secondarySearchItem && searchItem?.format.mainText !== secondarySearchItem?.format.mainText)

  const openPickUpSearchStep = useCallback(() => setActiveStep(searchSteps.LOCATION), [setActiveStep])
  const openDropOffSearchStep = useCallback(() => setActiveStep(searchSteps.SECONDARY_LOCATION), [setActiveStep])
  const openDateSearchStep = useCallback(() => setActiveStep(searchSteps.DATE), [setActiveStep])

  const onSelectDriversAge = useCallback((nextCategory: App.CarHireDriverAgeCategory) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_DRIVERS_AGE_CATEGORY, driversAgeCategory: nextCategory })
  }, [searchDispatch])

  const setPickUpTimeDispatch = useCallback((time: string) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_PICKUP_TIME, pickupTime: time })
    toggleShowPickUpTimeSelect()
  }, [searchDispatch, toggleShowPickUpTimeSelect])

  const setDropOffTimeDispatch = useCallback((time: string) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_RETURN_TIME, dropOffTime: time })
    toggleShowDropOffTimeSelect()
  }, [searchDispatch, toggleShowDropOffTimeSelect])

  const onDifferentLocationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.currentTarget.checked
    if (!nextValue) {
      searchDispatch({ type: GlobalSearchStateActions.UNSET_SECONDARY_SEARCH_ITEM })
    }
    setDifferentLocations(nextValue)
  }, [searchDispatch])

  const onSubmit = useCallback(() => {
    const pickUpItem = searchItem
    const dropOffItem = secondarySearchItem ?? searchItem

    const query = qs.stringify({
      pickUpName: pickUpItem.format.mainText,
      dropOffName: dropOffItem.format.mainText,
      pickUpId: getCarHireLocationId(pickUpItem),
      dropOffId: getCarHireLocationId(dropOffItem),
      pickUpDate: pickUpDate.format(ISO_DATE_FORMAT),
      pickUpTime,
      dropOffDate: dropOffDate.format(ISO_DATE_FORMAT),
      dropOffTime,
      ageCategory: driversAgeCategory,
      age: driversAge,
      pickUpSearchType: getCarHireSearchType(pickUpItem),
      dropOffSearchType: getCarHireSearchType(dropOffItem),
    })

    dispatch(pushWithRegion('/search/car-hire', query))
  }, [dispatch, driversAge, driversAgeCategory, dropOffDate, dropOffTime, pickUpDate, pickUpTime, searchItem, secondarySearchItem])

  return (
    <>
      {!!activeStep &&
        <CarHireSearchDrawer
          differentLocations={differentLocations}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          onSubmit={onSubmit}
      />}

      <VerticalSpacer gap={16} as="form" onSubmit={onSubmit}>
        <SearchFormField
          label={differentLocations ?
            CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL :
            CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL}
          placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
          value={searchItem?.format?.mainText}
          onClick={openPickUpSearchStep}
          required
        />

        <SlideWrapper show={differentLocations} animateOpacity>
          <SearchFormField
            label={CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL}
            placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
            value={secondarySearchItem?.format.mainText}
            onClick={openDropOffSearchStep}
            required={differentLocations}
          />
        </SlideWrapper>

        <CheckboxInput
          defaultChecked={differentLocations}
          onChange={onDifferentLocationChange}
        >
          Drop-off at a different location
        </CheckboxInput>

        <VerticalSpacer gap={16}>
          <SearchFormFieldGroup>
            <SearchFormField
              label="Pick-up date"
              value={pickUpDate ? pickUpDate.format(DMY_CASUAL_FORMAT) : undefined}
              placeholder="Start date"
              onClick={openDateSearchStep}
              required
            />
            <div style={{ position: 'relative' }}>
              <SearchFormField
                ref={pickUpTimeFieldRef}
                label="Time"
                placeholder="Start time"
                value={pickUpTime}
                displayValue={pickUpTime ? shortTimeHourFormatAmPm(new Date('1970-01-01T' + pickUpTime + 'Z'), true) : undefined}
                onClick={toggleShowPickUpTimeSelect}
                required
              />
              <CarHireTimeSelect
                open={showPickUpTimeSelect}
                toggleDropDown={toggleShowPickUpTimeSelect}
                dropdownTriggerRef={pickUpTimeFieldRef}
                onChange={setPickUpTimeDispatch}
                selectedTime={pickUpTime}
              />
            </div>
          </SearchFormFieldGroup>

          <SearchFormFieldGroup>
            <SearchFormField
              label="Drop-off date"
              value={dropOffDate ? dropOffDate.format(DMY_CASUAL_FORMAT) : undefined}
              placeholder="End date"
              onClick={openDateSearchStep}
              required
            />
            <div style={{ position: 'relative' }}>
              <SearchFormField
                ref={dropOffTimeFieldRef}
                label="Time"
                placeholder="End time"
                value={dropOffTime}
                displayValue={dropOffTime ? shortTimeHourFormatAmPm(new Date('1970-01-01T' + dropOffTime + 'Z'), true) : undefined}
                onClick={toggleShowDropOffTimeSelect}
                required
              />
              <CarHireTimeSelect
                open={showDropOffTimeSelect}
                toggleDropDown={toggleShowDropOffTimeSelect}
                dropdownTriggerRef={dropOffTimeFieldRef}
                onChange={setDropOffTimeDispatch}
                selectedTime={dropOffTime}
              />
            </div>
          </SearchFormFieldGroup>

          {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerAccountGuard
            accountMode="business"
            employeeRoles={['BUSINESS_ADMIN', 'BUSINESS_MANAGER']}
            loading={<BusinessTravellerSelectLoadingSkeleton />}
            fallback={<BusinessTravellerSelectCarHireDisabled />}
          >
            <BusinessTravellerSelectCarHireMobile />
          </BusinessTravellerAccountGuard>}

          <CarHireSearchDriversAgeCategorySelect
            value={driversAgeCategory}
            onChange={onSelectDriversAge}
          />

          <SlideWrapper show={needsActualAge}>
            <DriversAgeContainer className={cn({ hidden: !needsActualAge })}>
              <VerticalSpacer as="label" gap={8}>
                <Caption variant="large">
                  How old is the driver?
                </Caption>
                <DriversAgeInput />
              </VerticalSpacer>
            </DriversAgeContainer>
          </SlideWrapper>

        </VerticalSpacer>
        <TextButton fit="mobile-full-width" kind="primary" type="submit" size="large">Search</TextButton>
      </VerticalSpacer>
    </>
  )
}

export default CarHireMobileSearchControls

import qs from 'qs'
import request from 'api/requestUtils'
import {
  cruiseLinesMap,
  cruiseFacetsMap,
  cruisePromoBannersMap,
  cruiseOfferMap,
  cruiseLowestPriceByMonthMap,
  cruisesShipDeckMap,
  cruiseBookingInfoMap,
  cruiseDepartureByIdMap,
  cruiseBookingRateListMap,
  cruiseBookingCabinListMap,
  cruiseBookingCabinSelectionMap,
  cruiseBookingCabinPricingMap,
  cruiseBookingRateDetailsListMap,
  cruiseBookingCabinDetailsListMap,
  cruiseShipMap,
} from './mappers/Cruise/cruiseMap'

import { cruiseOfferSummaryMap } from './mappers/Cruise/cruiseOfferSummaryMap'

import { CruisesContract, definitions } from '@luxuryescapes/contract-svc-cruise'
import { offerHasValidDepartures, setCruiseVendorBookingTimer } from 'lib/cruises/cruiseUtils'
import { split } from 'lib/array/arrayUtils'

export namespace CruiseAPI {
  export type CruiseRateListBody = definitions['RATE_LIST_BODY'];
  export type CruiseRateListResponse = definitions['RATE_LIST_RESPONSE'];
  export type CruiseCabinRate = definitions['CABIN_RATE'];

  export type CruiseCabinListBody = definitions['CABIN_LIST_BODY'];
  export type CruiseCabinListResponse = definitions['CABIN_LIST_RESPONSE'];

  export type CruiseRateDetailsListBody = definitions['RATE_CODE_DETAILS_LIST_BODY'];
  export type CruiseRateDetailsListResponse = definitions['RATE_CODE_DETAILS_LIST_RESPONSE'];

  export type CruiseCabinSelectionBody = definitions['CABIN_SELECTION_BODY'];
  export type CruiseCabinSelectionResponse = definitions['CABIN_SELECTION_RESPONSE'];

  export type CruiseCabinPricingBody = definitions['CABIN_PRICING_BODY'];
  export type CruiseCabinPricingResponse = definitions['CABIN_PRICING_RESPONSE'];

  export type CruiseCabinDetailsListBody = definitions['CABIN_DETAILS_LIST_BODY'];
  export type CruiseCabinDetailsListResponse = definitions['CABIN_DETAILS_LIST_RESPONSE'];

  export type CruiseCabinReleaseBody = definitions['CABIN_RELEASE_BODY'];
  export type CruiseCabinReleaseRelease = definitions['CABIN_RELEASE_RESPONSE'];

  export type CruiseShipResponse = definitions['SHIP'];

  export type CruiseConsolidatedPaymentSchedule = definitions['GET_CONSOLIDATED_PAYMENT_SCHEDULE'];

  export type CruiseInclusion = definitions['INCLUSION'];
  export type CruiseInclusionItem = definitions['INCLUSION_ITEM'];

  export type BookingInclusion = definitions['BOOKING_INCLUSION'];
}

const BASE_URI = '/api/cruise/v1'
const BASE_URI_V2 = '/api/cruise/v2'
const DEFAULT_CABIN_HOLD_TIMEOUT = 900 // 15 minutes
const MAX_NUM_OF_PARALLEL_REQUESTS = 3

export async function getCruiseLines(filter?: CruisesContract.OfferParams, region?: string) {
  const queryParams = qs.stringify({
    take: 20,
    offersApproved: true,
    ...(filter || {}),
    ...(region && { region }),
  })

  const response = await request.get<
    App.ApiResponse<Array<CruisesContract.CruiseLine>>
  >(`${BASE_URI}/cruise-lines?${queryParams}`)

  return cruiseLinesMap(response.result)
}

export async function getCruiseSearchFacets(params: App.CruiseSearchFacetParams, region?: string) {
  // destinationIds is an array of strings, but it can also be a string with multiple ids separated by ','
  const destinationIds = params.destinationIds?.flatMap(id => id.split(','))
  const departureIds = params.departureIds?.flatMap(id => id.split(','))
  const cruiseShips = params.cruiseShips?.flatMap(name => name.split(','))
  const durationRange = params.durationRange?.flatMap(duration => duration.split(','))
  const { durationMin, durationMax } = params

  const queryParams = qs.stringify(
    {
      facetTypes: params.facetTypes,
      destinationPlaceIds: destinationIds,
      departurePlaceIds: departureIds,
      arrivalPlaceId: params.destinationId,
      cruiseLines: params.cruiseLines,
      departurePlaceId: params.departurePlaceId,
      departureDate: params.departureDate?.substring(0, 7), // gets YYYY-MM
      departureMonths: params.departureMonths?.filter(Boolean),
      departureStartDate: params.departureStartDate,
      departureEndDate: params.departureEndDate,
      rateCodes: params.rateCodes,
      includedFixedFilters: true,
      ...(durationMin && { durationMin }),
      ...(durationMax && { durationMax }),
      ...(durationRange && { durationRange }),
      ...(region && { region }),
      ...(params.isSpecialOffers && { isSpecialOffers: params.isSpecialOffers }),
      ...(params.isLuxExclusivePromotion && { isLuxExclusivePromotion: params.isLuxExclusivePromotion }),
      ...(params.isLuxPlus && { isLuxPlus: params.isLuxPlus }),
      ...(params.cruiseShips && { cruiseShips }),
      ...(params.minPrice && { minPrice: params.minPrice }),
      ...(params.maxPrice && { maxPrice: params.maxPrice }),
      ...(params.cabinTypes && { cabinTypes: [params.cabinTypes] }),
    },
  )

  const { result } = await request
    .get<App.ApiResponse<Array<App.CruiseSearchFacet>>>(`/api/search/cruise/v1/facet/list?${queryParams}`)

  return cruiseFacetsMap(result)
}

export async function getCruisePromoBanners(region?: string) {
  const queryParams = qs.stringify({
    take: 30,
    active: true,
    region,
  })
  const response = await request.get<
    App.ApiResponse<Array<CruisesContract.PromoBanner>>
  >(`${BASE_URI}/promo-banners?${queryParams}`)

  return cruisePromoBannersMap(response.result)
}

function getCruiseSearchQueryString(params: App.OfferListFilters, region?: string) {
  // destinationIds is an array of strings, but it can also be a string with multiple ids separated by ','
  const destinationIds = params.destinationIds?.flatMap(id => id.split(','))
  const departureIds = params.departureIds?.flatMap(id => id.split(','))
  const durationRange = params.durationRange?.flatMap(duration => duration.split(','))
  const { durationMin, durationMax } = params

  return qs.stringify(
    {
      arrivalPlaceId: params.destinationId,
      departurePlaceId: params.departurePlaceId,
      departureDate: params.departureDate?.substring(0, 7), // gets YYYY-MM
      departureStartDate: params.departureStartDate,
      departureEndDate: params.departureEndDate,
      departureMonths: params.departureMonths?.filter(Boolean),
      cruiseLines: params.cruiseLines,
      rateCodes: params.rateCodes,
      cruiseShips: params.cruiseShips,
      cabinTypes: params.cabinTypes,
      // if a 0 is passed, it's meaningless, so get rid of it
      minPrice: params.minPrice || undefined,
      maxPrice: params.maxPrice || undefined,
      sortBy: params.sortBy,
      campaigns: params.campaigns?.join(','),
      ...(region && { region }),
      ...(durationMin && { durationMin }),
      ...(durationMax && { durationMax }),
      ...(durationRange && { durationRange }),
      ...(params.isSpecialOffers && { isSpecialOffers: params.isSpecialOffers }),
      ...(params.isLuxExclusivePromotion && { isLuxExclusivePromotion: params.isLuxExclusivePromotion }),
      ...(params.isLuxPlus && { isLuxPlus: params.isLuxPlus }),
      destinationName: params.destinationName,
      departureName: params.departureName,
      destinationPlaceIds: destinationIds,
      destinationNames: params.destinationNames,
      departurePlaceIds: departureIds,
      departureNames: params.departureNames,
      priceBy: params.priceBy,
    },
    { arrayFormat: 'indices' },
  )
}

export async function getCruiseSearchList(params: App.OfferListFilters, region?: string) {
  const queryParams = getCruiseSearchQueryString(params, region)
  return request
    .get<App.ApiResponse<Array<string>>>(`/api/search/cruise/v1/list?${queryParams}`)
    .then((response) => response.result.map(id => `cruise:${id}`))
}

export async function getCruiseSearchCount(params: App.OfferListFilters, region?: string) {
  const queryParams = getCruiseSearchQueryString(params, region)
  return request
    .get<App.ApiResponse<Array<string>>>(`/api/search/cruise/v1/count?${queryParams}`)
    .then((response) => response.result)
}

export async function getCruiseOfferSummaryBySailingId(id: string, regionCode: string) {
  const response = await request.get<
    App.ApiResponse<CruisesContract.OfferSummary>
  >(`${BASE_URI}/offers/${id}`)

  return cruiseOfferMap(response.result, regionCode)
}

export async function getCruiseLowestPriceByMonth(
  params: App.LowestPriceByMonthParams,
  region?: string,
) {
  const queryParams = qs.stringify({
    from: params.from,
    until: params.until,
    arrivalPlaceId: params.destinationId,
    departurePlaceId: params.departureId,
    cruiseLines: params.cruiseLines,
    durationMin: params.durationMin,
    durationMax: params.durationMax,
    rateCodes: params.rateCodes,
    ...(region && { region }),
  })

  const response = await request.get<
    App.ApiResponse<Array<CruisesContract.LowestPriceByMonth>>
  >(`/api/search/cruise/v1/lowest-price-by-month?${queryParams}`)

  return cruiseLowestPriceByMonthMap(response.result, params.from)
}

export async function getCruiseOffersBySailingId(ids: Array<string>, regionCode: string) {
  return await Promise.all(ids.map(id =>
    getCruiseOfferSummaryBySailingId(id, regionCode),
  ))
}

export async function getCruiseOffer(id: string, region: string, currencyCode?: string, allowInvalidOffer?: boolean) {
  const params = qs.stringify({
    currencyCode,
    region,
    allowInvalidOffer,
  })

  const response = await request.get<
    App.ApiResponse<CruisesContract.Offer>
  >(`${BASE_URI}/offers/${id.replace('cruise:', '')}?${params}`)

  return cruiseOfferMap(response.result, region)
}

export async function getCruiseOfferSummary(id: string, region: string, currencyCode?: string) {
  const params = qs.stringify({
    currencyCode,
    region,
  })

  const response = await request.get<
    App.ApiResponse<CruisesContract.OfferSummaryResponse>
  >(`${BASE_URI}/offers/${id.replace('cruise:', '')}/summary?${params}`)

  return cruiseOfferSummaryMap(response.result, region)
}

export async function getValidCruiseOffer(id: string, region: string, currencyCode?: string) {
  const offer = await getCruiseOffer(id, region, currencyCode)
  const hasValidDepartures = offerHasValidDepartures(offer)

  if (!hasValidDepartures) {
    throw new Error(`No valid departures found for offer ${id}`)
  }

  return offer
}

export async function getCruiseShipDeck(id: string) {
  const response = await request.get<
    App.ApiResponse<CruisesContract.ShipDeck>
  >(`${BASE_URI}/ship-decks/${id}`)

  return cruisesShipDeckMap(response.result)
}

export async function getCruiseBookingById(bookingId: string, regionCode: string) {
  const response = await request.get<
    App.ApiResponse<CruisesContract.BookingOrderResponse>
  >(`${BASE_URI}/bookings/${bookingId}`, { credentials: 'include' })

  return cruiseBookingInfoMap(response.result, regionCode)
}

export async function getCruiseDepartureById(departureId: string) {
  const response = await request.get<
    App.ApiResponse<CruisesContract.DepartureByIdResponse>
  >(`${BASE_URI}/departures/${departureId}`)

  return cruiseDepartureByIdMap(response.result)
}

// BOOKING FLOW V2
export async function findBookingRateList(params: CruiseAPI.CruiseRateListBody, offer: App.CruiseOffer): Promise<Cruises.BookingRateListResponse> {
  const res = await request.post<App.ApiResponse<CruiseAPI.CruiseRateListResponse>, CruiseAPI.CruiseRateListBody>(
    `${BASE_URI_V2}/booking-flow/rate-list`,
    params,
    { credentials: 'include' },
  )

  if (res.errorToRender) return res.errorToRender

  const cabinRateList = cruiseBookingRateListMap(res.result, offer, params.departureId, params.region)
  setCruiseVendorBookingTimer(cabinRateList.cabinHoldTimeout || DEFAULT_CABIN_HOLD_TIMEOUT)

  return cabinRateList
}

export interface BookingCabinListParams {
  componentIds: Array<string>;
  sessionId: string;
}

export async function findBookingMultipleCabinList(
  params: Omit<CruiseAPI.CruiseCabinListBody, 'componentIds'>,
  componentIds: Array<Array<string>>,
): Promise<Array<Cruises.CruiseBookingCabin>> {
  const componentIdsChunked = split(componentIds, MAX_NUM_OF_PARALLEL_REQUESTS)
  let results: Array<Array<Cruises.CruiseBookingCabin>> = []

  for (let i = 0; i < componentIdsChunked.length; i++) {
    const resultsSubset = await findBookingCabinListSubset(params, componentIdsChunked[i])
      .catch(error => throwErrorIfFirstCabinListResponseFailed(i, error, componentIds))

    results = results.concat(resultsSubset)
  }

  return results.flat()
}

function findBookingCabinListSubset(
  params: Omit<CruiseAPI.CruiseCabinListBody, 'componentIds'>,
  componentIds: Array<Array<string>>,
): Promise<Array<Array<Cruises.CruiseBookingCabin>>> {
  return Promise.all(
    componentIds.map((ids) => findBookingCabinList({
      ...params,
      componentIds: ids,
    })),
  )
}

async function findBookingCabinList(params: CruiseAPI.CruiseCabinListBody): Promise<Array<Cruises.CruiseBookingCabin>> {
  const res = await request.post<App.ApiResponse<CruiseAPI.CruiseCabinListResponse>, CruiseAPI.CruiseCabinListBody>(
    `${BASE_URI_V2}/booking-flow/cabin-list`,
    params,
    { credentials: 'include' },
  )

  return cruiseBookingCabinListMap(res.result)
}

function throwErrorIfFirstCabinListResponseFailed(
  orderOfRequest: number,
  error: App.ApiCallFailure,
  componentIds: Array<Array<string>>,
): Promise<Array<undefined>> | undefined {
  if (orderOfRequest === 0) {
    throw error
  }

  console.warn(`Additional cabin component IDs ${componentIds} are resulting in an error`, error)
  // nothing, ignore, we have category codes at this point and can ignore any additional retrieval failures
  return Promise.resolve([])
}

export async function findBookingRateDetailsList(
  params: CruiseAPI.CruiseRateDetailsListBody,
): Promise<Record<string, Cruises.BookingRateDetails>> {
  const res = await request.post<App.ApiResponse<CruiseAPI.CruiseRateDetailsListResponse>, CruiseAPI.CruiseRateDetailsListBody>(
    `${BASE_URI_V2}/booking-flow/rate-code-details-list`,
    params,
    { credentials: 'include' },
  )

  return cruiseBookingRateDetailsListMap(res.result)
}

export async function findBookingCabinSelection(
  params: CruiseAPI.CruiseCabinSelectionBody,
): Promise<Cruises.CruiseBookingCabinSelection> {
  const res = await request.post<App.ApiResponse<CruiseAPI.CruiseCabinSelectionResponse>, CruiseAPI.CruiseCabinSelectionBody>(
    `${BASE_URI_V2}/booking-flow/cabin-selection`,
    params,
    { credentials: 'include' },
  )

  return cruiseBookingCabinSelectionMap(res.result)
}

export async function findBookingCabinPricing(
  params: CruiseAPI.CruiseCabinPricingBody,
): Promise<Cruises.CruiseBookingCabinPricing> {
  const res = await request.post<App.ApiResponse<CruiseAPI.CruiseCabinPricingResponse>, CruiseAPI.CruiseCabinPricingBody>(
    `${BASE_URI_V2}/booking-flow/cabin-pricing`,
    params,
    { credentials: 'include' },
  )

  return cruiseBookingCabinPricingMap(res.result)
}

export async function cruiseBookingCabinRelease(
  params: CruiseAPI.CruiseCabinReleaseBody,
) {
  await request.post<App.ApiResponse<CruiseAPI.CruiseCabinReleaseRelease>, CruiseAPI.CruiseCabinReleaseBody>(
    `${BASE_URI_V2}/booking-flow/cabin-release`,
    params,
    { credentials: 'include' },
  )

  return {
    success: true,
  }
}

export async function findBookingCabinDetailsListMultiple(
  params: Omit<CruiseAPI.CruiseCabinDetailsListBody, 'cabinNumbers'>,
  cabinNumberGroups: Array<Array<string>>,
): Promise<Cruises.BookingCabinDetailsList> {
  const results = await Promise.all(
    cabinNumberGroups.map((cabinNumbers) => findBookingCabinDetailsList({
      ...params,
      cabinNumbers,
    })),
  )

  return results.reduce((acc, res) => ({ ...acc, ...res }), {})
}

export async function findBookingCabinDetailsList(
  params: CruiseAPI.CruiseCabinDetailsListBody,
): Promise<Cruises.BookingCabinDetailsList> {
  const res = await request.post<App.ApiResponse<CruiseAPI.CruiseCabinDetailsListResponse>, CruiseAPI.CruiseCabinDetailsListBody>(
    `${BASE_URI_V2}/booking-flow/cabin-details-list`,
    params,
    { credentials: 'include' },
  )

  return cruiseBookingCabinDetailsListMap(res.result)
}

export async function findCruiseShip(id: string) {
  const response = await request.get<
    App.ApiResponse<CruiseAPI.CruiseShipResponse>
  >(`${BASE_URI_V2}/ships/${id}`)

  return cruiseShipMap(response.result)
}

export function sendRequestCallbackFormForCruise(data: Cruises.CruiseRequestCallbackForm) {
  return request.post(`${BASE_URI}/customer-enquiry`, data)
}

export function getConsolidatedPaymentSchedule(
  bookingIds: Array<string>,
): Promise<CruiseAPI.CruiseConsolidatedPaymentSchedule> {
  const queryParams = qs.stringify({ bookingIds })

  return request.get<
    App.ApiResponse<CruiseAPI.CruiseConsolidatedPaymentSchedule>
  >(`${BASE_URI_V2}/payment-schedules/consolidated?${queryParams}`, { credentials: 'include' })
    .then((response) => response.result)
}

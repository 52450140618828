import moment from 'moment'

export const UTM_CONFIGS = {
  basic: {
    storageKey: 'utmsV1',
    timeStamp: () => moment().add(7, 'd').valueOf(),
    utms: [
      'campaign',
      'medium',
      'source',
    ],
  },
  advanced: {
    storageKey: 'utmClickID',
    timeStamp: () => moment().add(30, 'minutes').valueOf(),
    utms: [
      'gclid',
      'fbclid',
      'irclickid',
    ],
  },
}

// Formats for moment:
export const DMY_DATE_FORMAT = 'DD/MM/YYYY'
export const US_DATE_FORMAT = 'MM/DD/YYYY'
export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const ISO_DATE_SHORT_FORMAT = 'YYYY-MM'
export const PICKER_DATE_FORMAT = 'ddd, DD MMM YYYY'
export const DMY_CASUAL_FORMAT = 'DD MMM YYYY'
export const DMY_CASUAL_SHORT_FORMAT = 'D MMM YYYY'
export const DMY_CASUAL_LONG_FORMAT = 'DD MMMM YYYY'
export const US_CASUAL_FORMAT = 'MMM DD YYYY'
export const ISO_DMY_TIME_FORMAT = 'YYYY-MM-DD h:mm:ssa'
export const SHORT_DAY_NAME_DAY_MONTH = 'ddd, D MMM'
export const SHORT_DAY_NAME_DAY_MONTH_YEAR = 'ddd, D MMM YYYY'
export const DAY_MONTH_NAME = 'DD MMM'
export const DAY_MONTH_NAME_SHORT = 'D MMM'
export const DAY_MONTH_NAME_YEAR = 'DD MMM, YYYY'
export const DAY_ONLY = 'D'
export const ACCOMMODATION_TIME_FORMAT = 'ddd, DD MMM, YYYY'
export const DATE_TIME_FORMAT = 'ddd, DD MMM, YYYY - h:mma'
export const LONG_MONTH_LONG_YEAR = 'MMMM YYYY'
export const SHORT_MONTH_LONG_YEAR = 'MMM YYYY'
export const SHORT_DATE_TIME_FORMAT = 'ddd D MMM, h:mma'
export const SHORT_TIME_FORMAT_AM_PM = 'hh:mm a'
export const SHORT_TIME_HOUR_FORMAT_AM_PM = 'h:mma'
export const SHORT_TIME_HOUR_FROMAT_AM_PM_SPACE = 'h:mm a'
export const SHORT_TIME_HOUR_FORMAT_AM_PM_UPPERCASE = 'h:mm A'
export const SHORT_TIME_FORMAT_24_HOUR = 'HH:mm'
export const LONG_DATE = 'LL'
export const TIME_DATE_FORMAT = 'h:mma D MMM YYYY'
export const TIME_DAY_MONTH_FORMAT = 'h:mma D MMM'
export const DAY_FORMAT = 'D'
export const LONG_DATE_FORMAT = 'dddd, DD MMMM YYYY'

// Equivalents to above formats, but for Intl.DateTimeFormat instead of moment:

type IF = Intl.DateTimeFormatOptions // Alias just to make the code below less verbose

/**
 * moment format syntax: DD/MM/YYYY
 * @example 03/11/2022
 */
export const INTL_DMY_DATE_FORMAT: IF = {} // This is intl's default format
/** @deprecated With `Intl` we no longer need to distinguish between US and non-US. Replace with `INTL_DMY_DATE_FORMAT` */
export const INTL_US_DATE_FORMAT: IF = {}

/**
 * moment format syntax: ddd, DD MMM YYYY
 * @example Thu, 03 Nov 2022
 */
export const INTL_PICKER_DATE_FORMAT: IF = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' }

/**
 * moment format syntax: DD MMM YYYY
 * @example 03 Nov 2022
 */
export const INTL_DMY_CASUAL_FORMAT: IF = { day: '2-digit', month: 'short', year: 'numeric' }

/**
 * moment format syntax: D MMM YYYY
 * @example 3 Nov 2022
 */
export const INTL_DMY_CASUAL_SHORT_FORMAT: IF = { day: 'numeric', month: 'short', year: 'numeric' }

/** @deprecated With `Intl` we no longer need to distinguish between US and non-US. Replace with `INTL_DMY_CASUAL_FORMAT` */
export const INTL_US_CASUAL_FORMAT: IF = { day: '2-digit', month: 'short', year: 'numeric' }

/**
 * Moment format syntax: ddd, D MMM
 * @example Thu, 3 Nov
 */
export const INTL_SHORT_DAY_NAME_DAY_MONTH: IF = { weekday: 'short', day: 'numeric', month: 'short' }

/**
 * Moment format syntax:
 * @example Thu 3
 */
export const INTL_DATE_SHORT_DAY_NAME: IF = { day: 'numeric', weekday: 'short' }

/**
 * Moment format syntax: ddd, D MMM YYYY
 * @example Thu, 3 Nov 2022
 */
export const INTL_SHORT_DAY_NAME_DAY_MONTH_YEAR: IF = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' }

/**
 * Moment format syntax: ddd, D MMM YYYY
 * @example Thu, 3 Nov 2022
 */
export const INTL_SHORT_WEEKDAY_COMMA_DAY_SHORT_MONTH_LONG_YEAR = INTL_SHORT_DAY_NAME_DAY_MONTH_YEAR

/**
 * Moment format syntax: DD MMM
 * @example 03 Nov
 */
export const INTL_DAY_MONTH_NAME: IF = { day: '2-digit', month: 'short' }

/**
 * Moment format syntax: D MMM
 * @example 3 Nov
 */
export const INTL_DAY_MONTH_NAME_SHORT: IF = { day: 'numeric', month: 'short' }

/**
 * Moment format syntax: D
 * @example 3
 */
export const INTL_DAY_ONLY: IF = { day: 'numeric' }

/**
 * Moment format syntax: ddd, DD MMM, YYYY
 * @example Sat, 30 Nov, 2024
 */
export const INTL_ACCOMMODATION_TIME_FORMAT = INTL_PICKER_DATE_FORMAT

/**
 * Moment format syntax: ddd, DD MMM, YYYY - h:mma
 * @example Thu, 3 Nov 2022, 12:00 am
 */
export const INTL_DATE_TIME_FORMAT: IF = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }

/**
 * Moment format syntax: MMMM YYYY
 * @example November 2022
 */
export const INTL_LONG_MONTH_LONG_YEAR: IF = { month: 'long', year: 'numeric' }

/**
 * Moment format syntax: dddd
 * @example Saturday
 */
export const INTL_LONG_WEEKDAY: IF = { weekday: 'long' }

/**
 * Moment format syntax: MMMM
 * @example November
 */
export const INTL_LONG_MONTH: IF = { month: 'long' }

/**
 * Moment format syntax: MMM
 * @example Nov
 */
export const INTL_SHORT_MONTH: IF = { month: 'short' }

/**
 * Moment format syntax: MMM
 * @example Nov
 */
export const INTL_2_DIGIT_MONTH: IF = { month: '2-digit' }

/**
 * Moment format syntax: MMM YYYY
 * @example Nov 2024
 */
export const INTL_SHORT_MONTH_LONG_YEAR: IF = { month: 'short', year: 'numeric' }

/**
 * Moment format syntax: ddd D MMM, h:mma
 * @example Sat 30 Nov, 12:00am
 */
export const INTL_SHORT_DATE_TIME_FORMAT: IF = { weekday: 'short', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }

/**
 * Moment format syntax: hh:mm a
 * @example 12:00am
 */
export const INTL_SHORT_TIME_FORMAT_AM_PM: IF = { hour: 'numeric', minute: 'numeric' }

/**
 * Moment format syntax: h:mma
 * @example 12:00am
 */
export const INTL_SHORT_TIME_HOUR_FORMAT_AM_PM: IF = { hour: '2-digit', minute: 'numeric' }

/**
 * Moment format syntax: DD MMMM YYYY
 * @example 3 November 2022
 */
export const INTL_LONG_DATE: IF = { day: 'numeric', month: 'long', year: 'numeric' }
/**
 * Moment format syntax: DD MMMM YYYY
 * @example 3 November 2022
 */
export const INTL_DAY_LONG_MONTH_LONG_YEAR = INTL_LONG_DATE

/**
 * Moment format syntax: DD MMMM YYYY, h:mma
 * @example 3 Nov 2022, 12:00 am"
 */
export const INTL_TIME_DATE_FORMAT: IF = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }

/**
 * Moment format syntax: DD MMM, h:mma
 * @example 3 Nov, 12:00 am
 */
export const INTL_TIME_DAY_MONTH_FORMAT: IF = { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }

/**
 * Moment format syntax: YYYY
 * @example 2024
 */
export const INTL_YEAR_ONLY: IF = { year: 'numeric' }

import pin from './map_pin.png'
import pinActive from './map_pin_active.png'

import pinSeeAndDo from './star_pin.png'
import pinSeeAndDoActive from './star_pin_active.png'

import pinMoment from './heart_pin.png'
import pinMomentActive from './heart_pin_active.png'

import pinEatAndDrink from './food_pin.png'
import pinEatAndDrinkActive from './food_pin_active.png'

import luxuryescapes from './le_map_pin.png'
import luxuryescapesActive from './le_map_pin_active.png'

import scoopontravel from './scoopontravel_map_pin.png'
import scoopontravelActive from './scoopontravel_map_pin_active.png'

import pinCruisePill from './cruise_pill_pin.png'
import pinCruisePillActive from './cruise_pill_pin_active.png'

import pinCruisePillIcon from './cruise_pill_icon_pin.png'
import pinCruisePillIconActive from './cruise_pill_icon_pin_active.png'

export default {
  default: {
    normal: pin,
    active: pinActive,
  },
  luxuryescapes: {
    normal: luxuryescapes,
    active: luxuryescapesActive,
  },
  scoopontravel: {
    normal: scoopontravel,
    active: scoopontravelActive,
  },
  'see-and-do': {
    normal: pinSeeAndDo,
    active: pinSeeAndDoActive,
  },
  'eat-and-drink': {
    normal: pinEatAndDrink,
    active: pinEatAndDrinkActive,
  },
  moments: {
    normal: pinMoment,
    active: pinMomentActive,
  },
  'cruise-pill': {
    normal: pinCruisePill,
    active: pinCruisePillActive,
  },
  'cruise-pill-icon': {
    normal: pinCruisePillIcon,
    active: pinCruisePillIconActive,
  },
}

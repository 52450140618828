const LIST_SOURCE_LERE_PREFIX = 'LERE'
export const LIST_SOURCE_LERE_UNKNOWN_MODEL = 'unknown'

export const getLereListSource = (source: string | undefined) => {
  return source ? [
    LIST_SOURCE_LERE_PREFIX,
    source,
  ].join(' - ') : [
    LIST_SOURCE_LERE_PREFIX,
    LIST_SOURCE_LERE_UNKNOWN_MODEL,
  ].join(' - ')
}

export enum LerePersonalisationCategory {
  AbandonedCart = 'abandoned_cart',
  HotLeads = 'hot_leads',
  // Sticky means the offers are from cache only
  StickyAbandonedCarts = 'sticky_abandoned_carts',
  StickyHotLeads = 'sticky_hot_leads',
  RecentlyViewed = 'recently_viewed',
  Alternatives = 'alternatives',
  PeopleLikeMe = 'people_like_me',
  UserPerference= 'preference'
}

export enum LereAggregationMode {
  Combine = 'combine',
  Select = 'select',
  Ensemble = 'ensemble'
}

export const LERE_DEFAULT_USER_ID = '67b2f25b-5770-4bda-947e-3153af74232a'

import React from 'react'

import FlightSearchWidgetMobile from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidgetMobile'
import ModalProvider from 'contexts/ModalProvider'
import { useAppSelector } from 'hooks/reduxHooks'
import { isMultiCityEnabled } from 'selectors/flightsSelectors'

function FlightsTabContent() {
  const multiCityEnabled = useAppSelector(isMultiCityEnabled)

  return <ModalProvider>
    <FlightSearchWidgetMobile showMultiCity={multiCityEnabled} />
  </ModalProvider>
}

export default FlightsTabContent

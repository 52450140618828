import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import { SvgIconProps } from 'components/Luxkit/SvgIcon'
import config from 'constants/config'
import React from 'react'

function BusinessAccountModeIcon(props: SvgIconProps) {
  return <>
    {config.businessTraveller.currentAccountMode === 'business' && <LineBuildingIcon {...props} />}
    {config.businessTraveller.currentAccountMode === 'personal' && <LineUserIcon {...props} />}
  </>
}

export default BusinessAccountModeIcon

import { LOCATION_CHANGE } from 'connected-react-router'
import {
  OFFER_PRODUCT_DETAIL_VIEW,
  PRODUCT_CLICK_VIEW_DETAILS,
  MAP_PROPERTY_PIN_CLICK,
  MAP_OFFER_INFO_CLICK,
  MAP_PIN_OFFER_VIEW,
  DEAL_VIDEO_PLAYED,
  DEAL_VIDEO_PAUSED,
  PACKAGE_VIEW_MORE_DETAILS_CLICK,
  OFFER_SHARE_START,
  OFFER_SHARE_COMPLETE,
  API_CALL_SUCCESS,
  API_CALL_FAILURE,
  API_CALL_REQUEST, BEDBANK_OFFER_PRODUCT_DETAIL_VIEW,
  TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW,
  EXPERIENCE_VIEWED,
  TOUR_V2_OFFER_VARIATION_VIEW,
} from 'actions/actionConstants'

import {
  pageView, loginAttempt,
  loginFail,
  loginSuccess, productDetailView, signupFail,
  signupAttempt,
  signupSuccess,
  productClick,
  userLogout, offerShareButtonClick,
  offerShareComplete, bedbankProductDetailView,
  experienceProductDetailView,
  toursV2ProductDetailView,
  toursV2VariationView,
} from './eventDefinitions'

import {
  mapPropertyPinClick,
  mapOfferInfoClick,
  mapPinOfferView,
} from './mapView'

import {
  dealVideoPaused,
  dealVideoPlayed,
} from './video'

import {
  packageViewMoreDetailsClick,
} from './packageDetails'
import { USER_LOGIN, USER_REGISTRATION, USER_LOGOUT } from 'actions/apiActionConstants'
import { AnyAction } from 'redux'

type EventFunc = (action: AnyAction, prevState: App.State, nextState: App.State) => void
const apiSuccessesMap: Record<string, EventFunc> = {
  [USER_LOGIN]: loginSuccess,
  [USER_REGISTRATION]: signupSuccess,
}

const apiFailureMap: Record<string, EventFunc> = {
  [USER_LOGIN]: loginFail,
  [USER_REGISTRATION]: signupFail,
}

const apiRequestMap: Record<string, EventFunc> = {
  [USER_LOGIN]: loginAttempt,
  [USER_REGISTRATION]: signupAttempt,
  [USER_LOGOUT]: userLogout,
}

const apiSuccessEvents = (action: AnyAction, prevState: App.State, nextState: App.State) => apiSuccessesMap[action.api]?.(action, prevState, nextState)

const apiFailureEvents = (action: AnyAction, prevState: App.State, nextState: App.State) => apiFailureMap[action.api]?.(action, prevState, nextState)

const apiRequestEvents = (action: AnyAction, prevState: App.State, nextState: App.State) => apiRequestMap[action.api]?.(action, prevState, nextState)

/**
 * @deprecated Don't fire analytics as a 'side effect' of the action
 * These can easily be forgotten about as they are not tied to the action itself
 * instead, fire the analytics event deliberately in the action if that's the right place
 */
export const eventsMap = {
  [LOCATION_CHANGE]: pageView,
  [OFFER_PRODUCT_DETAIL_VIEW]: productDetailView,
  [BEDBANK_OFFER_PRODUCT_DETAIL_VIEW]: bedbankProductDetailView,
  [TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW]: toursV2ProductDetailView,
  [TOUR_V2_OFFER_VARIATION_VIEW]: toursV2VariationView,
  [EXPERIENCE_VIEWED]: experienceProductDetailView,
  [PRODUCT_CLICK_VIEW_DETAILS]: productClick,
  [OFFER_SHARE_START]: offerShareButtonClick,
  [OFFER_SHARE_COMPLETE]: offerShareComplete,
  [MAP_PROPERTY_PIN_CLICK]: mapPropertyPinClick,
  [MAP_OFFER_INFO_CLICK]: mapOfferInfoClick,
  [MAP_PIN_OFFER_VIEW]: mapPinOfferView,
  [DEAL_VIDEO_PLAYED]: dealVideoPlayed,
  [DEAL_VIDEO_PAUSED]: dealVideoPaused,
  [PACKAGE_VIEW_MORE_DETAILS_CLICK]: packageViewMoreDetailsClick,
  [API_CALL_SUCCESS]: apiSuccessEvents,
  [API_CALL_FAILURE]: apiFailureEvents,
  [API_CALL_REQUEST]: apiRequestEvents,
}

import { SEARCH_VERTICALS } from 'constants/search'
import React, { useCallback, useEffect, useMemo } from 'react'
import useGlobalSearchVerticalTabsV2 from './Hooks/useGlobalSearchVerticalTabsV2'
import GlobalMobileSearchV2 from './Components/Mobile/GlobalMobileSearchV2'
import { addGTMEvent } from 'api/googleTagManager'
import { searchFormDisplay } from 'analytics/eventDefinitions'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import InHeaderSearch from 'components/App/Header/HeaderMain/InHeaderSearch'
import { connect } from 'react-redux'
import ScrollLock from 'components/Common/ScrollLock'

interface Props {
  openedSearchVertical?: SEARCH_VERTICALS;
  onClose: () => void;
}

interface MappedStateProps {
  memberId: string
}

function GlobalSearchV2Content(props: Props & MappedStateProps) {
  const { onClose, openedSearchVertical, memberId } = props

  const tabs = useGlobalSearchVerticalTabsV2()
  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()

  const selectedTab = useMemo(() => tabs.get(openedSearchVertical), [tabs, openedSearchVertical])

  const onSelectTab = useCallback((key: SEARCH_VERTICALS) => {
    toggleSearchVertical(key)
  }, [toggleSearchVertical])

  useEffect(() => {
    addGTMEvent(searchFormDisplay())
  }, [])

  return (
    <>
      <CSSBreakpoint min="tablet">
        <InHeaderSearch
          closeSearch={onClose}
          memberId={memberId}
          activeSearchVertical={openedSearchVertical}
          tabs={tabs}
          onSelectTab={onSelectTab}
        />
        <ScrollLock isActive={!!openedSearchVertical} />
      </CSSBreakpoint>
      <CSSBreakpoint max="mobile">
        <GlobalMobileSearchV2
          isOpen={!!openedSearchVertical}
          tabs={tabs}
          closeSearch={onClose}
          selectedTab={selectedTab}
          onTabSelect={onSelectTab}
          onSearch={selectedTab?.onSearch}
        />
      </CSSBreakpoint>
    </>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => ({
  memberId: state.auth.account.memberId,
}))(GlobalSearchV2Content)

import React, { useCallback } from 'react'
import { useAppDispatch } from 'hooks/reduxHooks'
import { forgotPassword } from 'actions/AuthActions'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLink from 'components/Luxkit/TextLink'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import noop from 'lib/function/noop'

interface Props {
  user: App.User;
  onModeChange?: (nextMode: App.UiAccountModalMode) => void;
  dismissable?: boolean;
}

function AccountAccessForgetPassword(props: Props) {
  const {
    user,
    onModeChange = noop,
    dismissable,
  } = props

  const dispatch = useAppDispatch()

  const onForgotPassword = useCallback(() => {
    dispatch(forgotPassword(user.email!))
  }, [dispatch, user])

  const onBack = useCallback(() => onModeChange('login'), [onModeChange])

  return (<>
    <ModalHeader
      title="A reset link has been sent to your email"
      onBackButtonClick={onBack}
      dismissible={dismissable}
    />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={24}>
          <BodyText variant="large" weight="bold">{user.email}</BodyText>
          <BodyText variant="medium">
            The reset link will expire in 24 hours. Please remember to check your spam folder.
          </BodyText>
          <BodyText variant="medium" colour="neutral-two">
            Don't see the email?{' '}<TextLink onClick={onForgotPassword}>Resend the email</TextLink>.
          </BodyText>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </>
  )
}

export default AccountAccessForgetPassword

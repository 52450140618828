import React from 'react'
import { usePlaceById } from 'hooks/usePlace'
import { UserPreferenceDestination } from '../types'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import SolidBellRingIcon from 'components/Luxkit/Icons/solid/SolidBellRingIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Caption from 'components/Luxkit/Typography/Caption'

export function DisplayItemWithCheckBox({ destination, onClick }: {destination: UserPreferenceDestination, onClick: (item: UserPreferenceDestination) => void}) {
  const place = usePlaceById(destination.placeId, !!destination.name)

  return (<CheckboxInput
    key={`DisplayItemWithCheckBox-${destination.placeId}`}
    onClick={() => onClick(destination)}
    size="large"
    checked
  >
    <BodyTextBlock
      endIcon={destination.isAlertSelected ? <SolidBellRingIcon size="XS" /> : null}
      variant="medium" weight="bold">
      {place?.name || destination.name}
    </BodyTextBlock>
    <Caption colour="neutral-three" variant="medium">
      {place?.name || destination.name}
    </Caption>
  </CheckboxInput>)
}

import { useMemo } from 'react'

import useBookmarkPartitionedTrips from './useBookmarkPartitionedTrips'
import { normaliseBookmarkId } from './utils'

function useBookmarkDetails(bookmarkId: string) {
  const tripsByBookmarkSource = useBookmarkPartitionedTrips(bookmarkId)

  const linkedTrips = useMemo(
    () => tripsByBookmarkSource.get(true) || [],
    [tripsByBookmarkSource],
  )

  const normalisedBookmarkId = normaliseBookmarkId(bookmarkId)

  const details = useMemo(
    () =>
      linkedTrips.map((trip) => {
        const [bookmarkIdSet] = trip.bookmarkIdSets.filter(
          (set) => normaliseBookmarkId(set.offerId) === normalisedBookmarkId,
        ) // Only one bookmark currently supported
        return { trip, itemId: bookmarkIdSet.itemId }
      }),
    [linkedTrips, normalisedBookmarkId],
  )

  return details[0] || undefined
}

export default useBookmarkDetails

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMapMarkerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a8 8 0 0 0-8 8c0 5.4 7.05 11.5 7.35 11.76a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 10a8.0003 8.0003 0 0 0-8-8Zm0 17.65c-2.13-2-6-6.31-6-9.65a6 6 0 1 1 12 0c0 3.34-3.87 7.66-6 9.65ZM12 6a4 4 0 1 0 0 8.0001A4 4 0 0 0 12 6Zm0 6a2 2 0 0 1-1.9616-2.3902 2.0003 2.0003 0 0 1 2.727-1.4576A1.9998 1.9998 0 0 1 14 10a2 2 0 0 1-2 2Z"/>
  </SvgIcon>
}

export default LineMapMarkerIcon

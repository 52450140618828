import React from 'react'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import ListItem from 'components/Luxkit/List/ListItem'

interface Props {
  isSelected?: boolean;
  onClick?: () => void;
}

function CruiseDepartureSelectListAll({ isSelected, onClick }: Props) {
  return <ListItem
    onClick={onClick}
    title={<b>All departure ports</b>}
    endIcon={<CheckboxButton size="large" checked={isSelected} />}
  />
}

export default CruiseDepartureSelectListAll

import delay from 'lib/utils/delay'
import OrderError from 'lib/../errors/orderError'

interface Arguments {
  validateFunction: (result: any) => any,
  failedStatus?: string,
  apiCall: () => Promise<any>,
  maxTime: number,
  waitTime?: number;
  isPayTo?: boolean;
  logPaymentEvent?: (event: App.PaymentEventLog) => void;
}

const pollOrderService = async({ validateFunction, failedStatus, apiCall, maxTime, waitTime = 1000, isPayTo = false, logPaymentEvent = undefined }: Arguments) => {
  let status = null
  let timeSpent = 0

  while (true) {
    try {
      if (timeSpent > maxTime) {
        if (isPayTo && logPaymentEvent !== undefined) {
          logPaymentEvent({
            event: 'paytoTransactionEnd',
            eventMetadata: {
              status: 'timeout',
              timeSpent: timeSpent / 1000, // Convert to seconds,
            },
            paymentMethod: 'payto',
          })
        }
        throw new OrderError('There was an error processing your order. Please try again.')
      }

      const apiResponse = await apiCall()
      let result
      if ('result' in apiResponse) {
        result = apiResponse.result
      } else {
        result = apiResponse
      }
      status = result.status

      if (status === failedStatus) {
        const orderError = new OrderError(result.message)

        orderError.errors = result.errors
        orderError.refCode = result.ref_code
        throw orderError
      }

      const validateResult = await validateFunction(result)

      if (validateResult) {
        if (isPayTo && logPaymentEvent !== undefined) {
          logPaymentEvent({
            event: 'paytoTransactionEnd',
            eventMetadata: {
              status: 'success',
              timeSpent: timeSpent / 1000, // Convert to seconds,
            },
            paymentMethod: 'payto',
          })
        }
        return validateResult
      }

      await delay(waitTime)
      timeSpent = timeSpent + waitTime
    } catch (error) {
      // If we've thrown an order error fail the poll
      // Continue for all other errors i.e. network
      if (error instanceof OrderError) {
        if (isPayTo && logPaymentEvent !== undefined) {
          logPaymentEvent({
            event: 'paytoTransactionEnd',
            eventMetadata: {
              status: 'failed',
              timeSpent: timeSpent / 1000, // Convert to seconds,
            },
            paymentMethod: 'payto',
          })
        }
        throw error
      }
      await delay(waitTime)
      timeSpent = timeSpent + waitTime
    }
  }
}

export default pollOrderService

import { nonNullable } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getCruiseItems } from '../cruiseSelectors'
import isDomesticTrip from './isDomesticTrip'
import { getInsuranceProductListKey } from 'lib/insurance/insuranceUtils'

const getCheckoutInsuranceProducts = createSelector(
  isDomesticTrip,
  (state: App.State) => getCruiseItems(state).length > 0,
  (state: App.State) => state.insurance.productLists,
  (state: App.State) => state.insurance.products,
  (_: App.State, type?: App.InsuranceProductType) => type,
  (isDomestic, hasCruiseItems, productLists, products, type): Array<App.InsuranceProduct> => {
    const key = getInsuranceProductListKey(type ?? 'travel-insurance', {
      isDomestic,
      isCruise: hasCruiseItems,
    })

    const list = productLists[key]
    return nonNullable(list?.map(id => products[id]) ?? [])
  },
)

export default getCheckoutInsuranceProducts

import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'
import React from 'react'

type Props = {
  offer: App.VillaOffer | App.VillaOfferSummary
  locationHeading?: string
  locationSubheading?: string
}

function VillaLocationCaption(props: Props) {
  const { offer } = props

  return (
    <Group direction="horizontal" gap={4}>
      <Caption colour="neutral-two" variant="large">
        {[offer.locationHeading, offer.locationSubheading].filter((t) => t).join(', ')}
      </Caption>
      <Caption variant="large" colour="neutral-three">
        · Villa
      </Caption>
    </Group>
  )
}

export default VillaLocationCaption

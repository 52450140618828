export function isAndroid(osName: string) {
  return osName.includes('Android')
}

export function isApple(osName: string) {
  return osName === 'iOS' || osName === 'Mac OS'
}

export function isIOS(osName: string) {
  return osName === 'iOS'
}

export function isMobileDevice(deviceInfo: App.DeviceInfo): boolean {
  return deviceInfo.deviceType === 'mobile'
}

/**
 * this selector will also respect in-app navigation via webviews
 */
export function isIOSAppUserAgent(userAgentBrowserName: string) {
  return userAgentBrowserName.includes('mobileapp-ios')
}

/**
 * This selector will also respect in-app navigation via webviews
 */
export function isAndroidAppUserAgent(userAgentBrowserName: string) {
  return userAgentBrowserName.includes('mobileapp-android')
}

/**
 * Returns whether or not this is a web view in the mobile app or not
 * They use custom user agent strings, so we use that to detect it
 * @param userAgent The current user agent
 * @returns Whether or not we are in the app
 */
export function isInMobileApp(userAgent: string): boolean {
  return isIOSAppUserAgent(userAgent) || isAndroidAppUserAgent(userAgent)
}

import React from 'react'

import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 43 43">
      <path d="M34.18,38.63a18.22,18.22,0,0,0,3.88-3.23,23.36,23.36,0,0,0,3.38-4.86,20.23,20.23,0,0,1-4.57,5.71,29,29,0,0,0,3.34-4c-3.81,5.15-15,10-21.61,7.6-.24-.09.08-.09.33-.1s.24,0,.24,0a19.87,19.87,0,0,0,21-18.27V20.28a19.87,19.87,0,0,0-37.76-9C-3.14,21.67,1.23,35.3,12.09,40.69a7.94,7.94,0,0,1-.87-.55,21.21,21.21,0,0,0,6.23,2.41,21.7,21.7,0,0,1-5.36-1.86c5.1,3.06,12.74,2.71,17.93.54-.55.17-1.08.31-1.61.44a21.78,21.78,0,0,1-4.6.83c1.63-.23,3.09-.47,4.6-.83a20.06,20.06,0,0,0,4.67-2c-.17.09-.06,0,.67-.44a16.38,16.38,0,0,0,2-1.41l.1-.09a20.55,20.55,0,0,0,3.39-3.52L38.06,35.4A16,16,0,0,1,34.18,38.63ZM24.85,10.54a3.23,3.23,0,0,1,2.67.41,8.38,8.38,0,0,1,2.55,1.94c1.48,1.9-.07,4.13-1.38,5.64a13.07,13.07,0,0,1-5.33,3.58,3.45,3.45,0,0,1-.49.14c-.44.1-1,.23-1.08.41a4,4,0,0,0,.05,1.71c.11.86.41,2.07,1.39,2.3a2.74,2.74,0,0,0,3.48-3.08,3.68,3.68,0,0,0-.11-.65,2.12,2.12,0,0,1-.22-.61c0-.59.72-.5,1.1-.34,1,.43,1.21.56,1.08,1.55a4.83,4.83,0,0,1-4.67,4.09c-2.8.09-4-2.72-4.26-5.12a15.47,15.47,0,0,1,1.32-7c.73-1.83,1.95-4.19,3.91-4.94ZM15.17,33.36h0a5.07,5.07,0,0,0-2.12.17,2.58,2.58,0,0,0-.76.48,2.83,2.83,0,0,1-.68.44c-.55.23-1-.06-.79-.66a1.8,1.8,0,0,1,.23-.4,2.26,2.26,0,0,0,.22-.37l.06-.13c.1-.24.22-.51.32-.78a30.4,30.4,0,0,0,1.13-4.34c1.3-6,3.67-18,3.67-18a21.55,21.55,0,0,0-2.65,3c-.76,1.22-1.12,4-.79,4.35a1.05,1.05,0,0,1,.21,1.49c-.37.53-1.94-.08-2.56-.62-2.12-1.85-.21-4.09,1-5.52l.07-.09A37.32,37.32,0,0,1,15.53,9.1a11.93,11.93,0,0,0,1-.8,18.53,18.53,0,0,1,.93-2,3.56,3.56,0,0,1,2.33-2c.33-.09,1.11-.29,1.37,0s-.06,1-.22,1.31l0,.07A17.74,17.74,0,0,0,19.77,9c-.67,2.67-1.33,5.31-2,7.93s-1.31,5.27-2,7.89c-.36,1.34-.76,2.67-1.23,4a29.44,29.44,0,0,1-1.44,3.65,14.82,14.82,0,0,1,4.36-.15,22.73,22.73,0,0,0,2.47.08,9.81,9.81,0,0,0,3.22-.59,4.43,4.43,0,0,0,.52-.27h0c.66-.39,1.53-.9,1.68,0a2.9,2.9,0,0,1-1.87,3.24,5.59,5.59,0,0,1-4.37-.38A12.53,12.53,0,0,0,15.17,33.36Z" />
      <path d="M27.2,16.45v0c.53-1,1.51-2.93.7-4s-2.19.73-2.64,1.31l0,0a16.89,16.89,0,0,0-3.34,7.65A9,9,0,0,0,27.2,16.45Z" />
    </SvgIcon>
  )
}

export default LeIcon

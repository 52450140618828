import Group from 'components/utils/Group'
import React from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Divider from 'components/Luxkit/Divider'
import Caption from 'components/Luxkit/Typography/Caption'
import { BookingProtectionContent } from './BookingProtectionComparison'

const Wrapper = styled.div`
  padding: ${rem(16)} ${rem(20)};
  position: relative;
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};
`

const CardHeader = styled(Group)`
  height: ${rem(62)};
`

const LimitedProtectionRow = styled(Group)`
  padding: ${rem(8)} ${rem(0)};
`

interface Props {
  bookingProtectionContent: Array<BookingProtectionContent>
}

function BookingLimitedProtectionCard(props: Props) {
  const {
    bookingProtectionContent,
  } = props

  return (
    <Wrapper>

      <Group gap={12} direction="vertical">
        <CardHeader gap={4} direction="vertical" verticalAlign="start">
          <BodyText variant="medium" weight="bold">
            Luxury Escapes policy
          </BodyText>
          <Caption variant="large">Included</Caption>
        </CardHeader>

        <Group direction="vertical">
          {bookingProtectionContent.map((item, index) => (
            <Group direction="vertical" key={index}>
              {index === 0 && <Divider kind="primary" />}
              <LimitedProtectionRow direction="vertical" gap={8}>
                <Group direction="horizontal" verticalAlign="center" horizontalAlign="space-between">
                  <BodyText variant="small" weight="bold">{item.heading}</BodyText>
                  {item.limitedProtectionIcon}
                </Group>
                <BodyText variant="small">{item.limitedProtectionText}</BodyText>
              </LimitedProtectionRow>
              {index !== bookingProtectionContent.length - 1 && <Divider kind="primary" />}
            </Group>
          ))}
        </Group>

      </Group>
    </Wrapper>
  )
}

export default BookingLimitedProtectionCard

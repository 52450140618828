import Counter from 'components/Luxkit/Counter'
import InputText from 'components/Luxkit/Typography/InputText'
import Group from 'components/utils/Group'
import React from 'react'

interface Props extends React.ComponentProps<typeof Counter> {
  label?: string;
  subLabel?: string;
  direction?: 'horizontal' | 'vertical';
  tabletDirection?: 'horizontal' | 'vertical';
}

function CounterInput(props: Props) {
  const { label, className, subLabel, direction = 'horizontal', tabletDirection = 'vertical', ...counterProps } = props
  return <Group
    as="label"
    className={className}
    direction={direction}
    verticalAlign={direction === 'vertical' ? undefined : 'center'}
    horizontalAlign={direction === 'vertical' ? undefined : 'space-between'}
    gap={12}
    tabletDirection={tabletDirection}
    tabletVerticalAlign={tabletDirection === 'vertical' ? undefined : 'center'}
    tabletHorizontalAlign={tabletDirection === 'vertical' ? 'start' : 'space-between'}
  >
    {(label || subLabel) && <div>
      {label && <InputText variant="label">{label}</InputText>}
      {subLabel && <InputText variant="sub-label">{subLabel}</InputText>}
    </div>}
    <Counter {...counterProps} />
  </Group>
}

export default CounterInput

import React, { useMemo } from 'react'
import CruisePriceDetails from './CruisePriceDetails'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'

interface Props {
  offer: App.Offer | App.OfferSummary
  className?: string;
  variant?: 'default' | 'condensed';
}

function CruiseFlashPriceDetails({ offer, variant = 'default' }: Props) {
  const pkg = offer.lowestPricePackage!
  const propertyFees = pkg.propertyFees
  const shouldShowDiscountPercent = offer.type === OFFER_TYPE_LAST_MINUTE
  const totalPrice = pkg.price + propertyFees
  const totalValue = pkg.value + propertyFees
  const valueOffPercent = getValueOffPercent(totalValue, totalPrice)

  const priceDetails = useMemo<App.Pricing>(() => {
    const shouldShowValue = pkg.value > 0 && totalValue > totalPrice && pkg.shouldDisplayValue &&
      !shouldShowDiscountPercent && (offer.type == OFFER_TYPE_HOTEL || !pkg.roomRate?.inclusionsHideValue)
    const pricing: App.Pricing = {
      price: totalPrice,
      saleUnit: offer.saleUnit,
      fees: [{ type: 'property', amount: propertyFees }],
    }
    if (shouldShowValue) {
      return {
        ...pricing,
        value: Math.ceil(totalValue),
        discountPercent: valueOffPercent,
      }
    }
    return pricing
  }, [offer.saleUnit, offer.type, pkg, propertyFees, shouldShowDiscountPercent, totalPrice, totalValue, valueOffPercent])

  const taxDetails = useMemo(() => {
    return {
      taxesAndFeesAmount: pkg.taxesAndFees,
      taxesPayableAtProperty: !!offer.property?.taxesPayableAtProperty,
    }
  }, [offer.property?.taxesPayableAtProperty, pkg.taxesAndFees])

  return <CruisePriceDetails
    offerId={offer.id}
    isFlash
    taxDetails={taxDetails}
    priceDetails={priceDetails}
    roomOccupancy={pkg.roomOccupancy}
    variant={variant}
  />
}

export default CruiseFlashPriceDetails

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineDumbbellIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m17.48 6.55-2.84-2.84a1.001 1.001 0 0 0-.71-.2958 1.0003 1.0003 0 0 0-.71.2958 1.0002 1.0002 0 0 0 0 1.41l2.12 2.12-8.1 8.1-2.12-2.12a.9998.9998 0 0 0-1.41 0 1.001 1.001 0 0 0-.2958.71 1.0003 1.0003 0 0 0 .2958.71l2.81 2.81 2.81 2.81a1 1 0 0 0 .71.3.9997.9997 0 0 0 .9294-.6165A1.0004 1.0004 0 0 0 10.75 18.85l-2.09-2.09 8.1-8.1 2.12 2.12a1.0004 1.0004 0 1 0 1.41-1.42l-2.81-2.81ZM3.71 17.46a1.0041 1.0041 0 1 0-1.42 1.42l2.83 2.83a1.0003 1.0003 0 0 0 .71.29 1.0005 1.0005 0 0 0 .71-.29 1.0008 1.0008 0 0 0 .2958-.71 1.0003 1.0003 0 0 0-.2958-.71l-2.83-2.83Zm18-12.34-2.83-2.83a1.0037 1.0037 0 0 0-1.0942-.2177 1.003 1.003 0 0 0-.5435.5434 1.0052 1.0052 0 0 0 0 .7686 1.005 1.005 0 0 0 .2177.3257l2.83 2.83a1.001 1.001 0 0 0 .71.2958 1.0003 1.0003 0 0 0 .71-.2958 1.001 1.001 0 0 0 .2958-.71.9994.9994 0 0 0-.2958-.71Z"/>
  </SvgIcon>
}

export default LineDumbbellIcon

import React, { useEffect, useState } from 'react'
import PageBanner from 'components/Luxkit/Banners/PageBanner'

function CookiesDisabledWarning() {
  const [areCookiesEnabled, setCookiesAreEnabled] = useState<boolean>(true)

  useEffect(() => {
    if (window.navigator.cookieEnabled) {
      setCookiesAreEnabled(true)
    } else {
      document.cookie = 'cookietest=1'
      setCookiesAreEnabled(document.cookie.includes('cookietest='))
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    }
  }, [])

  if (areCookiesEnabled) {
    return null
  }

  return <PageBanner
    kind="warning"
    align="center"
    description="Oops! Your browser seems to have cookies disabled. Make sure cookies are enabled or open a new window."
  />
}

export default CookiesDisabledWarning

import Group from 'components/utils/Group'
import { useAppSelector } from 'hooks/reduxHooks'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import useBedbankOfferSearchPricing from 'hooks/useBedbankOfferSearchPricing'
import { EmptyObject } from 'lib/object/objectUtils'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'
import LuxLPPPricingLabel from 'luxPlus/components/LuxLPPPricingLabel'
import { getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import React, { useMemo } from 'react'
import SearchTileStackAmenitiesList from '../shared/SearchTileStackAmenitiesList'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  filters?: App.OfferListFilters
}

function BedbankSearchTileFeatures({ offer, filters = EmptyObject }: Props) {
  const amenities = useMemo<Array<App.OfferInclusion>>(() => {
    return offer.popularFacilities.map(facility => ({
      description: facility,
      id: facility,
      symbol: determineOfferFeatureSymbol(facility),
    }))
  }, [offer.popularFacilities])

  const { offerMemberPrice, offerPrice } = useBedbankOfferSearchPricing(offer, filters)

  const luxPlusLPPEnabled = useAppSelector(getIsLuxPlusLPPEnabled)
  const directSearchPrices = useDirectSearchPrices({ filters, offerId: offer.id })

  return <Group className={BedbankSearchTileFeatures.name} direction="vertical" gap={12} noDisplayWhenEmpty>
    {luxPlusLPPEnabled && <LuxLPPPricingLabel
      offer={offer}
      hasLuxPlusRate={directSearchPrices?.hasLuxPlusRate}
      offerMemberPrice={offerMemberPrice}
      offerPrice={offerPrice}
    />}
    {!!amenities.length && <SearchTileStackAmenitiesList amenities={amenities} />}
  </Group>
}

export default BedbankSearchTileFeatures

import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import React from 'react'
import { Route, Switch } from 'react-router'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import ContactOptionsBanner from '../Header/HeaderBanners/ContactOptionsBanner'
import HeaderTopBanners from '../Header/HeaderTopBanners'

const NO_BANNER_EXACT_ROUTES: Array<string> = [
  '/:regionCode/support',
]

const NO_BANNER_INEXACT_ROUTES: Array<string> = [
  '/:regionCode/trip-planner/trip/:tripId',
  '/:regionCode/trip-planner/public-trip/:tripId',
  '/:regionCode/trip-planner/curated/:tripId',
]

function BannerRoutes() {
  return <>
    {config.SHOW_CONTACT_BANNER && <TripGuard>
      <CSSBreakpoint max="mobile">
        <ContactOptionsBanner />
      </CSSBreakpoint>
    </TripGuard>}
    <Switch>
      <Route exact path={NO_BANNER_EXACT_ROUTES} />
      <Route path={NO_BANNER_INEXACT_ROUTES} />
      <Route component={HeaderTopBanners} />
    </Switch>
  </>
}

export default BannerRoutes

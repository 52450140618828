import React from 'react'
import baseLoadable, { DefaultComponent } from '@loadable/component'

import Loader from 'components/Common/Loading/LoadingIndicator'

export const FallbackLoader = Loader

export default function <T>(func: () => Promise<DefaultComponent<T>>, fallback = <Loader />) {
  return baseLoadable(func, { fallback })
}

import { getHeight } from 'lib/window/getViewport'

/**
 * Scrolls to an element with the given ID
 * @param id The ID of the element to scroll to
 * @param offset The px vertical offset to scroll to from the target element
 */
export function scrollToId(id: string, offset = 0) {
  const element = document.getElementById(id)

  if (element) {
    const elementY = element.getBoundingClientRect().top
    const targetY = window.pageYOffset + elementY + offset
    window.scrollTo(0, targetY)
  }
}

// scrollToHash scrolls the page to the element whose id is equal to the given
// hash param
export function scrollToHash(hashParam: string, top = false) {
  if (!hashParam) {
    return false
  }

  const match = window.location.hash.match('#(.*)')
  const id = match ? match[1] : null

  const el = id ? document.getElementById(id) : null
  if (el) {
    el.scrollIntoView(top) // setting false to accomdate shy nav
    // the bottom of the element will be aligned to the bottom of the visible area of the scrollable ancestor
  }
  return !!el
}

/**
 * Attempts to smart scroll to the nearest bounds as required
 * @param element The element to scroll to
 * @param offset px vertical offset to apply
 */
export function smartScrollIntoViewIfNeeded(element: HTMLElement, offset = 0) {
  const rect = element.getBoundingClientRect()
  const elementTop = rect.top
  const elementHeight = rect.height
  const elementBottom = elementTop + elementHeight

  const pageTop = window.pageYOffset
  const viewportHeight = getHeight()

  const elementIsAboveViewport = elementTop < offset
  const elementIsBelowViewport = elementBottom > (viewportHeight - offset)
  const biggerThanAdjustedViewport = elementHeight > (viewportHeight - 2 * offset)

  const scrollAlignTop = () => {
    const targetY = pageTop + elementTop - offset
    window.scrollTo(0, targetY)
  }
  const scrollAlignBottom = () => {
    const targetY = pageTop + (elementBottom - viewportHeight) + offset
    window.scrollTo(0, targetY)
  }

  if (elementIsAboveViewport && !elementIsBelowViewport) {
    biggerThanAdjustedViewport ? scrollAlignBottom() : scrollAlignTop()
  }

  if (!elementIsAboveViewport && elementIsBelowViewport) {
    biggerThanAdjustedViewport ? scrollAlignTop() : scrollAlignBottom()
  }
}

export function onScrollingEnd(): Promise<void> {
  return new Promise<void>(resolve => {
    let scrolling = false
    const scrollFunc = () => { scrolling = true }
    window.addEventListener('scroll', scrollFunc, { passive: true })
    window.setTimeout(() => {
      window.removeEventListener('scroll', scrollFunc)

      if (scrolling) {
        const scrollEnd = () => {
          window.removeEventListener('scrollend', scrollEnd)
          resolve()
        }
        window.addEventListener('scrollend', scrollEnd)
      } else {
        resolve()
      }
    }, 16)
  })
}

/**
 * Scrolls an element in the centre of the view within its parent
 * @param element The element to scroll to
 */
export async function centreElementInView(
  element: HTMLElement | null | undefined,
  /**
   * @default `element.parentElement`
   */
  scrollElement?: HTMLElement | null,
) {
  if (element) {
    const parentElement = scrollElement ?? element.parentElement
    const parentRect = parentElement?.getBoundingClientRect()
    if (parentElement && parentRect) {
      // check if we're currently scrolling
      await onScrollingEnd()

      parentElement.scrollTo({
        left: element.offsetLeft - (parentRect?.width - element.clientWidth) / 2,
        behavior: 'smooth',
      })
    }
  }
}

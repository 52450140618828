import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import LineMinusIcon from 'components/Luxkit/Icons/line/LineMinusIcon'
import Input from '../../Common/Form/Input/Input'
import LineCheckIcon from '../Icons/line/LineCheckIcon'
import BodyText from '../Typography/BodyText'
import OnlyIfChildren from 'components/Common/OnlyIfChildren/OnlyIfChildren'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const Icon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  right: 0;
  bottom: 0;
  transition: color 0.2s;
  color: transparent;
  pointer-events: none;
  transform: translate3d(-50%, -50%, 0);
  height: ${rem(20)};
  width: ${rem(20)};
`

const Circle = styled.div`
  position: relative;
  height: ${rem(24)};
  width: ${rem(24)};
  flex-shrink: 0;
`

const CheckboxWrapper = styled.label`
  display: flex;
  position: relative;

  > * + * {
    margin-left: ${rem(16)};
  }
`

const CheckboxElement = styled(Input)`
  appearance: none;
  margin: 0;
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: border-color 0.2s, background-color 0.2s, color 0.2s;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.borderRadius.round};

  &::before {
    /* This provides the focus state, outline does not support border radius */
    content: '';
    position: absolute;
    border-radius: ${props => props.theme.borderRadius.round};
    transition: box-shadow 0.2s;
    box-shadow: none;
    pointer-events: none;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
  }

  &:not(:disabled) {
    &:checked {
      border-color: ${props => props.theme.palette.brand.primary.normal};
      background-color: ${props => props.theme.palette.brand.primary.normal};

      & + ${Icon} {
        color: ${props => props.theme.palette.neutral.default.eight};
      }
    }

    &:focus {
      &::before {
        box-shadow: var(${KEYBOARD_MODE_CSS_VAR}, 0 0 0 2px ${props => props.theme.palette.brand.primary.normal});
      }

      &.dirty:invalid::before {
        box-shadow: 0 0 0 2px ${props => props.theme.palette.messaging.critical.normalForeground};
      }
    }

    ${mediaHoverable} {
      &:hover {
        border-color: ${props => props.theme.palette.brand.primary.normal};
      }
    }

    &.dirty:invalid {
      border-color: ${props => props.theme.palette.messaging.critical.normalForeground};
    }
  }

  &:disabled {
    border-color: ${props => props.theme.palette.neutral.default.six};
    background-color: ${props => props.theme.palette.neutral.default.six};

    &:checked + ${Icon} {
      color: ${props => props.theme.palette.neutral.default.four};
    }
  }
`

const TextElement = styled.span`
  display: flex;
  flex-direction: column;
`

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  /** A possible 3rd value for check boxes, this is "sort of checked". */
  indeterminate?: boolean;
  /**
   * This is text that will appear below the main children with a different visual
   * look. Often used as additional descriptions to the main element.
   **/
  subText?: string;
}

const CircleCheckboxInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    children,
    className,
    id,
    indeterminate,
    subText,
    ...inputProps
  } = props

  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isDirty, setDirty] = useState<boolean>(false)

  useImperativeHandle(ref, () => inputRef.current!)
  useEffect(() => {
    // there's no corresponding react prop, have to set manually via ref
    if (inputRef.current && indeterminate) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  const hasChildren = React.Children.toArray(children).length > 0

  return (
    <CheckboxWrapper
      className={className}
      htmlFor={id}
    >
      <Circle>
        <CheckboxElement
          {...inputProps}
          ref={inputRef}
          id={id}
          type="checkbox"
          onDirtyChange={setDirty}
          className={cn(
            { dirty: isDirty },
          )}
        />
        {!indeterminate && <Icon as={LineCheckIcon} />}
        {indeterminate && <Icon as={LineMinusIcon} />}
      </Circle>
      <OnlyIfChildren as={TextElement}>
        {hasChildren && <BodyText variant="large" as="span">{children}</BodyText>}
        {subText && <BodyText variant="medium" colour="neutral-two" as="span">{subText}</BodyText>}
      </OnlyIfChildren>
    </CheckboxWrapper>
  )
})

CircleCheckboxInput.displayName = 'CircleCheckboxInput'

export default CircleCheckboxInput

const ALL_ELEMENT_TOKEN = 'ALL'

/**
 * Check if an element is enabled.
 *
 * (Example activating by region)
 * There are two modes:
 *  - inclusion: "AU,US"      --- a list of included regions
 *  - exclusion: "ALL/AU,US"  --- a list of excluded regions
 *
 * @param featureFlagVal: Value of the feature flag
 * @param element: Element to check if enabled
 * @returns boolean
 */
export function isEnabledForFeature(featureFlagVal: string, element: string): boolean {
  if (!featureFlagVal || !element) { return false }
  if (featureFlagVal === ALL_ELEMENT_TOKEN) { return true }

  const partitions = featureFlagVal.split('/')
  if (partitions.length < 1 || partitions.length > 2) { return false }
  const isInclusion = partitions.length === 1

  if (isInclusion) {
    return partitions[0].split(',').includes(element)
  } else {
    return !partitions[1].split(',').includes(element)
  }
}

export function isFeatureFlagIncluded(featureFlagVal: string | undefined, element: string): boolean {
  const items = featureFlagVal?.split(',') || []
  return items.includes(element)
}

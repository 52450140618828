import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSanitizerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12.9999 12a3.0006 3.0006 0 0 0-2.7716 1.852 2.9995 2.9995 0 0 0 .6503 3.2693 3.0002 3.0002 0 0 0 4.6158-.4546A3 3 0 0 0 15.9999 15a3.0032 3.0032 0 0 0-3-3Zm0 4a.9997.9997 0 0 1-.9807-1.1951.9997.9997 0 0 1 1.3634-.7288 1.0001 1.0001 0 0 1 .6173.9239 1.0012 1.0012 0 0 1-1 1Zm5.8003-8.3999-2.8003-2.1v-2.5h1a1.0002 1.0002 0 0 0 1-1 1 1 0 0 0-1-1H8.6567a4.9665 4.9665 0 0 0-3.5352 1.4643l-.8285.8286a.9997.9997 0 0 0 .705 1.7133.9999.9999 0 0 0 .709-.2993l.8286-.8286A3.022 3.022 0 0 1 8.6566 3H10v2.5L7.1991 7.6A3.0155 3.0155 0 0 0 5.9999 10v12a1 1 0 0 0 1 1h12A1 1 0 0 0 20 22V10a3.0142 3.0142 0 0 0-1.1998-2.3999Zm-6.8003-4.6h2v2h-2V3Zm6 17.9999h-10V10a1.0055 1.0055 0 0 1 .4-.8003L11.3334 7h3.333l2.9332 2.1997a1.0052 1.0052 0 0 1 .4003.8003v11Z"/>
  </SvgIcon>
}

export default LineSanitizerIcon

import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { RouterState } from 'connected-react-router'
import moment from 'moment'
import { type SelfDescribingJson, setUserId } from '@snowplow/browser-tracker'

import { AppStore } from '../../store'
import config from 'constants/config'
import { CART_SCHEMA, PAGE_VIEW_CONTEXT_SCHEMA } from 'constants/analytics'
import { AnalyticsPage } from 'contexts/Analytics/analyticsPageContext'
import {
  buildInsuranceUpsellContext,
  createTourOfferItemContexts,
  experienceOfferEventsMap, getExperienceContext,
  getItemsContextForOffer,
  getItemsContextForCheckout, getPageOfferCategory, offerIdStateHasError,
  mapToSnowplowFilters,
  buildSearchContext,
  buildLuxPlusUpsellContext,
  getAnyItemContextForOffer,
  getOrderItemsContext,
  getOrderFromState,
} from './helpers'
import type {
  ExperienceEventDefinitionKey,
  ExperienceEventEntity,
  ExperienceOfferEventContext,
} from './helpers'
import { reportClientError } from 'services/errorReportingService'
import {
  GAImpressionFieldObject,
  GAProductFieldObject,
  SnowplowImpressionData,
} from 'analytics/eventDefinitions'
import { getPageCategoryAndType } from './pages'
import { nonNullable } from 'lib/array/arrayUtils'
import { getCartId } from 'selectors/checkoutSelectors'
import paymentContext from './eventContexts/paymentContext'
import { SearchEventData } from 'contexts/GlobalSearch/GlobalSearchTracking'
import { SearchAnalyticsLocation, SearchAnalyticsItemType } from 'analytics/mapSnowplowSearchTracking'
import { checkoutStateIsReady } from 'middlewares/analyticsMiddleware'
import { TrackableProduct } from 'components/OfferList/OfferListEventsContext'
import { checkoutUrlPattern } from 'constants/url'
import { luxPlusContext, luxPlusContextOnPurchaseSuccess } from './eventContexts/luxPlusContext'
import { Search_2_0_0 } from '@luxuryescapes/contract-data-event-schemas/lib/com.luxgroup'
import { getBedbankRateContext, getBedbankRateItemContext, getBedbankRateListContext } from 'analytics/snowplow/eventContexts/item/bedbankRoomRateContext'
import { getGoogleClientId, getGoogleSessionId } from './helpers/googleAnalyticsCookies'

export type SnowplowItemContextType = 'flight' | 'experience' | 'cruise' | 'hotel' | 'insurance' | 'bookingProtection' | 'tour' | 'giftCard' | 'luxuryPlus' | 'carHire'
export type SnowplowContextType = 'item' | 'order' | 'checkout' | 'luxPlus'

export interface SnowplowEventOptions {
  itemsToSkip?: Array<SnowplowItemContextType>
  contextsToSkip?: Array<SnowplowContextType>
}

type ItemContextSource = 'order' | 'checkout' | 'offer';

export interface BaseSnowplowEvent {
  context?: Array<SelfDescribingJson> | null;
  // Only use this if you want to explicitly determine where the context handler looks in redux to determine the items data
  // By default is is determined by looking at what page the user is on or what information is on the page which currently works for all but one case
  options?: SnowplowEventOptions;
  itemContextSource?: ItemContextSource;
  itemContextTypesToSkip?: Array<SnowplowItemContextType>;
}
export interface SelfDescribingSnowlplowEvent extends BaseSnowplowEvent {
  event: SelfDescribingJson
}
export interface PageviewSnowlplowEvent extends BaseSnowplowEvent {
  title: string;
}
export type SnowplowEvent = SelfDescribingSnowlplowEvent | PageviewSnowlplowEvent;

let _store: AppStore | undefined

export function registerCartTrackingStore(store: AppStore) {
  _store = store
}

/**
 * Event definitions
 */
export interface SnowplowClientEventParams extends BaseSnowplowEvent {
  subject: string,
  action: string,
  category: string,
  type: 'interaction' | 'nonInteraction' | 'operational',
  context?: Array<SelfDescribingJson>,
  options?: SnowplowEventOptions;
  // 2 fields necessary if the client event is to make it to optimizely
  optimizelyEventId?: string,
  optimizelyEventKey?: string,
}
/**
 * If using clientEvent to send an event to optimizely, you must provide the optimizelyEventId and optimizelyEventKey.
 * If you require a value to be passed, eg. a click position, you must use the syntax `ab-value: n` where n
 * is the number required.
 */
export function clientEvent(params: SnowplowClientEventParams): SnowplowEvent {
  if (params.optimizelyEventId && params.optimizelyEventKey) {
    params.context = [
      ...params.context || [],
      comluxgroup.createOptimizelyEventContext_1_0_0({
        id: params.optimizelyEventId,
        key: params.optimizelyEventKey,
      })]
  }

  return {
    context: params.context,
    options: {
      itemsToSkip: params.options?.itemsToSkip || [],
    },
    event: comluxgroup.createClientEvent_1_0_0({
      subject: params.subject,
      action: params.action,
      type: params.type,
      category: params.category,
    }),
  }
}

export function impressionEvent(
  obj: GAImpressionFieldObject | GAProductFieldObject,
  listId: string,
  listName: string,
  currency: string,
  schema: string,
  listSource?: string,
): SnowplowEvent {
  const data: SnowplowImpressionData = {
    id: obj.id,
    name: obj.name,
    productType: obj.category,
    position: obj.position,
    currency,
    price: obj.price,
    listId,
    listName,
    listSource,
  }
  return {
    event: {
      schema,
      data,
    },
  }
}

export function luxPlusUpsellEvent(
  notSuitable: string,
  yes: string,
  whyNotSuitable?: string,
  isLuxPlusOffered?: string,
  declineReason?: string,
): SnowplowEvent {
  return {
    context: buildLuxPlusUpsellContext(
      notSuitable,
      yes,
      whyNotSuitable,
      isLuxPlusOffered,
      declineReason,
      _store ? _store.getState().auth.account.agentId : undefined,
    ),
    event: comluxgroup.createClientEvent_1_0_0({
      subject: 'luxplus_upsell_reminder',
      type: 'interaction',
      action: 'submit',
      category: 'luxplus',
    }),
  }
}

export function agentInsuranceUpsellEvent(
  notSuitable: string,
  yes: string,
  no: string,
  whyNotSuitable?: string,
  isCovermore?: string,
  isOffered?: string,
  declineReason?: string,
): SnowplowEvent {
  return {
    context: buildInsuranceUpsellContext(
      notSuitable,
      yes,
      no,
      whyNotSuitable,
      isCovermore,
      isOffered,
      declineReason,
      _store ? _store.getState().auth.account.agentId : undefined,
    ),
    event: comluxgroup.createClientEvent_1_0_0({
      subject: 'insurance_upsell_reminder',
      type: 'interaction',
      action: 'submit',
      category: 'checkout',
    }),
  }
}

export function experienceEvent(
  eventDefinitionKey: ExperienceEventDefinitionKey,
  geo: App.GeoState, eventsContexts?: Array<ExperienceEventEntity>,
): SnowplowEvent {
  const event = experienceOfferEventsMap[eventDefinitionKey]
  const eventsContextsWithGeo = eventsContexts?.map<ExperienceOfferEventContext>(eventContext => ({
    ...eventContext,
    data: {
      ...eventContext.data,
      geo,
    },
  })) ?? []
  return {
    context: eventsContextsWithGeo?.length ? getExperienceContext(eventsContextsWithGeo) : [],
    event: comluxgroup.createClientEvent_1_0_0({
      ...event,
      category: 'experiences_offer',
    }),
  }
}

export type AuthState = 'started' | 'succeeded' | 'failed'

export function offerPageHotLeadEvent(
  offer: App.Offer | App.BedbankOffer | Tours.TourV2Offer | App.CruiseOffer,
  lead: App.HotLeadOffer,
  geo: App.GeoState,
): SnowplowEvent | undefined {
  try {
    let contextItems
    const duration = lead.checkOut && lead.checkIn ? moment(lead.checkOut).diff(lead.checkIn, 'days') : undefined
    if (lead.occupancy.length) {
      contextItems = lead.occupancy.map(room => comluxgroup.createItem_1_0_0({
        brand: config.BRAND,
        currency: geo.currentCurrency,
        region: geo.currentRegionCode,
        offerId: offer.id,
        offerType: offer.type,
        travelStart: lead.checkIn,
        travelEnd: lead.checkOut,
        year: lead.year?.toString(),
        month: lead.month?.toString(),
        variant: lead.variant,
        duration,
        childrenAges: room.childrenAge,
        numberOfAdults: room.adults,
        numberOfChildren: room.children,
        numberOfInfants: room.infants,
      }))
    }
    else {
      contextItems = [comluxgroup.createItem_1_0_0({
        brand: config.BRAND,
        currency: geo.currentCurrency,
        region: geo.currentRegionCode,
        offerId: offer.id,
        offerType: offer.type,
        travelStart: lead.checkIn,
        travelEnd: lead.checkOut,
        year: lead.year?.toString(),
        month: lead.month?.toString(),
        variant: lead.variant,
        duration,
      })]
    }

    return {
      context: contextItems,
      event: comluxgroup.createClientEvent_1_0_0({
        subject: 'hot_lead',
        type: 'interaction',
        action: 'sent',
        category: 'offer_page',
      }),
    }
  } catch (e) {
    reportClientError(e)
  }
}

export function searchEventWithContext(
  contextLocation: SearchAnalyticsLocation,
  searchItemCategory: SearchAnalyticsItemType,
  snowplowSearchContextData: comluxgroup.SearchContext_1_0_1,
): SnowplowEvent {
  let placement: Search_2_0_0['placement'] = 'main'
  let type: Search_2_0_0['type']
  if (contextLocation === 'common-search') {
    placement = 'header'
  }

  if (searchItemCategory === 'recent') {
    type = 'recent_search'
  } else if (searchItemCategory === 'popular') {
    type = 'trending_destination'
  } else {
    type = 'standard'
  }

  return {
    context: [
      comluxgroup.createSearchContext_1_0_1({
        ...snowplowSearchContextData,
      }),
    ],
    event: comluxgroup.createSearch_2_0_0({
      type,
      placement,
    }),
  }
}

export function impressionEventWithContext(
  position: number,
  list: string,
  listInstance: string,
  offer: TrackableProduct,
  additionalData: {
    productAvailable?: boolean,
    leadPrice?: number,
    duration?: number,
  } = {},
): SnowplowEvent | undefined {
  const state: App.State | undefined = _store?.getState()
  if (!state) {
    return
  }
  const { productAvailable, leadPrice, duration } = additionalData

  const itemContext = getAnyItemContextForOffer(state, offer, leadPrice, duration) ?? []
  return {
    context: [...itemContext, comluxgroup.createListItem_1_0_0({
      listInstance,
      listName: list,
      itemPosition: position,
    })],
    event: comluxgroup.createProductImpression_1_0_3({ productAvailable }),
    options: {
      contextsToSkip: ['item'],
    },
  }
}

interface BedbankRateContextData {
  ratePosition: number,
  rateListId: string,
  roomPosition: number,
  roomListId: string,
  offer: App.BedbankOffer | App.BedbankOfferSummary,
  rate: App.BedbankRate,
  price: number,
  value: number,
  checkIn: string,
  checkOut: string,
  occupancy: Array<App.Occupants>,
  currency: string,
  isFlightBundle: boolean,
}

export function bedbankRateImpressionEventWithContext(
  contextData: BedbankRateContextData,
): SnowplowEvent | undefined {
  const {
    ratePosition,
    rateListId,
    roomPosition,
    roomListId,
    offer,
    rate,
    price,
    value,
    checkIn,
    checkOut,
    occupancy,
    currency,
    isFlightBundle,
  } = contextData

  const itemContext = getBedbankRateItemContext(
    offer,
    rate,
    price,
    value,
    checkIn,
    checkOut,
    occupancy,
    currency,
  )

  const listContext = getBedbankRateListContext(
    ratePosition,
    rateListId,
    roomPosition,
    roomListId,
  )

  const rateContext = getBedbankRateContext(rate, isFlightBundle)

  return {
    context: [
      ...itemContext,
      ...listContext,
      ...rateContext,
    ],
    event: comluxgroup.createPackageOptionImpression_1_0_0({ }),
    options: {
      contextsToSkip: ['item'], // exclude default item context since we have explicit item context
    },
  }
}

export function productClickEventWithContext(
  position: number,
  list: string,
  listInstance: string,
  offer: TrackableProduct,
  additionalData: {
    leadPrice?: number,
    duration?: number,
  } = {},
): SnowplowEvent | undefined {
  const state: App.State | undefined = _store?.getState()
  if (!state) {
    return
  }
  const { leadPrice, duration } = additionalData

  const itemContext = getAnyItemContextForOffer(state, offer, leadPrice, duration) ?? []
  return {
    context: [...itemContext, comluxgroup.createListItem_1_0_0({
      listInstance,
      listName: list,
      itemPosition: position,
    })],
    event: comluxgroup.createProductClick_1_0_2({}),
    options: {
      contextsToSkip: ['item'],
    },
  }
}

export function searchEvent(subject: string, category: string, data: SearchEventData): SnowplowEvent {
  const { searchItem, checkIn, checkOut, vertical, isFlexibleDates, occupancies, filters } = data
  const baseContext = buildSearchContext()

  const formattedOccupancies = (occupancies ?? []).map(
    occupants => ({ numberOfChildren: (occupants?.infants ?? 0) + (occupants?.children ?? 0), numberOfAdults: occupants.adults }))

  return {
    context: [comluxgroup.createSearch_1_0_2({
      ...baseContext,
      searchType: searchItem?.searchType ?? '',
      verticals: [vertical],
      searchParams: {
        ...baseContext.searchParams,
        checkIn: checkIn ?? null,
        checkOut: checkOut ?? null,
        occupancies: formattedOccupancies,
        isFlexibleDates: Boolean(isFlexibleDates),
        searchText: searchItem?.format?.mainText ?? null,
        filters: filters ? mapToSnowplowFilters(filters) : {},
      },
    })],
    event: comluxgroup.createClientEvent_1_0_0({
      action: 'clicked',
      subject,
      type: 'interaction',
      category,
    }),
  }
}

function getConfirmationPageOrderId(routerState: RouterState): string | undefined {
  return routerState?.location?.query?.orderId
}

/**
 *
 * Use this function to add any global context to Snowplow events that are async
 * The function will be called right before each event is sent and awaited
 */
export async function asyncBaseContext(itemContextSource?: ItemContextSource, options: SnowplowEventOptions = {}): Promise<Array<SelfDescribingJson<Record<string, unknown>>> | null | undefined> {
  if (!_store) {
    return []
  }

  const state: App.State = _store.getState()
  const routerState: RouterState = state.router

  // See if the page is part of the mapped analytics pages
  const pageData = getPageCategoryAndType(routerState.location.pathname)

  // Otherwise, see if it is an offer page
  const pageCategory = await getPageOfferCategory(_store.getState())
  if (!pageData.category && !pageData.type && pageCategory) {
    pageData.category = pageCategory
    pageData.type = 'offer'
  }

  const context: Array<SelfDescribingJson<Record<string, unknown>>> = []

  const promises: Array<Promise<Array<SelfDescribingJson> | undefined>> = []

  // Get items context just when it's an offer or checkout page
  const currentPath = state.routeHistory.currentPath
  const pathParams = state.routeHistory.currentPathParams as { orderId?: string, offerId?: string, id?: string, step?: string }
  const isCheckoutPage = checkoutUrlPattern.test(currentPath)
  const offerId = pathParams.offerId || pathParams.id
  const isOfferPage = !!offerId
  const isOrderPage = !!(pathParams.orderId || getConfirmationPageOrderId(state.router))
  const { itemsToSkip, contextsToSkip } = options

  const contextFunctionMap: Record<ItemContextSource, () => void | number> = {
    checkout: () => promises.push(checkoutStateIsReady().then(() => getItemsContextForCheckout(_store.getState(), itemsToSkip))),
    order: () => promises.push(getOrderItemsContext(_store.getState(), getOrderFromState(_store.getState()))),
    offer: () => {
      const itemsContext = getItemsContextForOffer(state)
      if (itemsContext) context.push(...itemsContext)
      if (offerId && offerIdStateHasError(state, offerId)) {
        pageData.type = 'error'
      }
    },
  }

  if (itemContextSource) {
    contextFunctionMap[itemContextSource]?.()
  } else {
    if (isCheckoutPage && !contextsToSkip?.includes('checkout')) {
      contextFunctionMap.checkout()
    } else if (isOrderPage && !contextsToSkip?.includes('order')) {
      contextFunctionMap.order()
    } else if (isOfferPage && !contextsToSkip?.includes('item')) {
      contextFunctionMap.offer()
    }
  }

  if (!contextsToSkip?.includes('luxPlus')) {
    context.push(luxPlusContext(state))
  }

  const promiseContexts = await Promise.all(promises)
  context.push(...nonNullable(promiseContexts.flat()))
  context.push(
    comluxgroup.createPage_1_0_0({
      type: pageData.type,
      category: pageData.category,
    }),
  )
  return context
}

export function baseContext(): Array<SelfDescribingJson> {
  if (!_store) {
    return []
  }

  const state = _store.getState()

  const authState: App.AuthState = state.auth
  const geoState: App.GeoState = state.geo

  const allExperiments = state.optimizely.optimizelyExperiments
  const triggeredExperiments = Object.entries(allExperiments)
    .filter(([_, decisions]) => decisions?.triggered && decisions?.variationKey)
    .map(([key, decisions]) => ({
      key: `exp_${key}`,
      value: decisions?.variationKey || '',
    }))

  const context = [
    comluxgroup.createBrand_1_0_0({
      brand: config.BRAND,
    }),
    comluxgroup.createRegion_1_0_0({
      region: geoState.currentRegionCode,
    }),
    comluxgroup.createExperimentContext_1_0_0({
      flags: triggeredExperiments,
    }),
    comluxgroup.createUtms_1_0_0({
      source: state.utm.source,
      medium: state.utm.medium,
      campaign: state.utm.campaign,
      term: state.utm.term,
      content: state.utm.content,
    }),
  ]
  if (authState.account.memberId && authState.account.email) {
    setUserId(authState.account.memberId)
    context.push(comluxgroup.createUser_1_0_0({
      id: authState.account.memberId,
      email: authState.account.email,
      first_name: authState.account.givenName,
      is_spoofed: authState.account.isSpoofed,
    }))
    context.push(comluxgroup.createUserPurchaseCount_1_0_0({
      number_of_purchases: authState.account.numberOfPurchases || 0,
    }))
  }
  context.push(comluxgroup.createGoogleAnalytics_1_0_0({
    googleClientId: getGoogleClientId(),
    googleSessionId: getGoogleSessionId(),
  }))
  return context
}

export function signupSuccessEvent(method: App.SignUpAuthSource): SnowplowEvent {
  return {
    event: comluxgroup.createSignupSuccess_1_0_0({
      method,
    }),
  }
}

export function signinSuccessEvent(method: App.AuthSource): SnowplowEvent {
  return {
    event: comluxgroup.createSigninSuccess_1_0_0({
      method,
    }),
  }
}

export function selectPackageUpgradeOptionToCart(
  offer: Tours.TourV2Offer,
  option: string,
  triggerLocation: AnalyticsPage,
  regionCode: string,
  currency: string,
  tourItems: Array<App.Checkout.TourV2Item>,
): SnowplowEvent {
  return {
    context: createTourOfferItemContexts(offer, regionCode, currency, tourItems),
    event: comluxgroup.createClientEvent_1_0_0({
      subject: `tour_upgrade_${option.toLowerCase()}`,
      type: 'interaction',
      action: 'pressed',
      category: triggerLocation,
    }),
  }
}

export function trackPageView(pathTemplate: string, pathParamsObject: any): SnowplowEvent {
  return {
    title: document.title,
    context: [{
      schema: PAGE_VIEW_CONTEXT_SCHEMA,
      data: {
        pathTemplate,
        pathParams: JSON.stringify(pathParamsObject),
      },
    }],
  }
}

export async function purchaseSuccess(order: App.Order): Promise<SnowplowEvent | undefined> {
  try {
    const state = _store?.getState()
    if (!state) { throw new Error('No redux registered for tracking purchase success snowplow events') }
    const cartId = getCartId(state)
    const orderItemContext = await getOrderItemsContext(state, order)
    const luxPlusContext = luxPlusContextOnPurchaseSuccess(state, order)

    return {
      // Skip the built in item context for order and checkout as we are generating our own
      options: {
        contextsToSkip: ['order', 'checkout', 'luxPlus'],
      },
      context: [
        comluxgroup.createCart_1_0_0({
          cartId,
        }),
        ...paymentContext(order.payments),
        ...orderItemContext,
        luxPlusContext,
      ],
      event: comluxgroup.createPurchaseSuccess_1_0_0({}),
    }
  } catch (e) {
    reportClientError(e)
  }
}

export function addToCart(
  bedbankContextData?: {
    ratePosition: number,
    rateListId: string,
    roomPosition: number,
    roomListId: string,
    rate: App.BedbankRate,
    isFlightBundle: boolean,
  },
): SnowplowEvent | undefined {
  try {
    if (!_store) {
      throw new Error('No redux registered for tracking cart snowplow events')
    }
    const state = _store.getState()
    const cartId = getCartId(state)

    const bedbankContext = bedbankContextData ? [
      ...getBedbankRateListContext(
        bedbankContextData.ratePosition,
        bedbankContextData.rateListId,
        bedbankContextData.roomPosition,
        bedbankContextData.roomListId,
      ),
      ...getBedbankRateContext(bedbankContextData.rate, bedbankContextData.isFlightBundle),
    ] : []
    return {
      itemContextSource: 'checkout',
      context: [
        {
          schema: CART_SCHEMA,
          data: {
            cartId,
          },
        },
        ...bedbankContext,
      ],
      event: comluxgroup.createAddToCart_1_0_0({}),
    }
  } catch (e) {
    reportClientError(e)
  }
}

export function promotionViewEvent(name: string): SnowplowEvent {
  return {
    context: [comluxgroup.createPromotion_1_0_0({
      type: 'banner',
      name,
    })],
    event: comluxgroup.createPromotionView_1_0_0({}),
  }
}

export function promotionClickEvent(name: string): SnowplowEvent {
  return {
    context: [comluxgroup.createPromotion_1_0_0({
      type: 'banner',
      name,
    })],
    event: comluxgroup.createPromotionClick_1_0_0({}),
  }
}

interface FlightItemSnowplowContext extends Omit<comluxgroup.Item_1_1_0, 'categoryId' | 'productId'>{
  originAirportCode: string;
  arrivalAirportCode: string;
  departureDate: string;
  arrivalDate?: string;
  pricePerAdult: number;
  airline: string;
  fareType: string;
}

export function createFlightItemContext(data: FlightItemSnowplowContext): SelfDescribingJson {
  return comluxgroup.createItem_1_1_0({
    categoryId: 'flight',
    productId: 'flight',
    currency: data.currency,
    offerId: data.offerId,
    originCity: data.originCity,
    destinationCity: data.destinationCity,
    travelStart: data.departureDate,
    travelEnd: data.arrivalDate,
    duration: data.duration,
    numberOfAdults: data.numberOfAdults,
    numberOfChildren: data.numberOfChildren,
    price: data.pricePerAdult,
    originCountry: data.originCountry,
    destinationCountry: data.destinationCountry,
    metadata: JSON.stringify({
      airline: data.airline,
      originAirportCode: data.originAirportCode,
      arrivalAirportCode: data.arrivalAirportCode,
    }),
    typeId: data.fareType,
  })
}

export function getItemContextForOffer(offer: App.AnyOffer) {
  const state = _store?.getState()

  if (!state) {
    return
  }

  return getAnyItemContextForOffer(state, offer)
}

export function activateCampaignEvent(campaignId: string, experimentId: string, variationId: string): SnowplowEvent {
  return {
    event: comluxgroup.createActivateAbTest_1_0_0({
      campaignId,
      experimentId,
      variationId,
    }),
  }
}

export type SnowplowDestinationContext = {
  destinationId: string;
  destinationName: string;
  tileType: string;
  destinationImageId?: string;
}

export function destinationImpressionEventWithContext(
  position: number,
  listName: string,
  listInstance: string,
  destination: SnowplowDestinationContext,
  filter?: string,
): SnowplowEvent {
  return {
    context: [
      comluxgroup.createListItem_1_0_0({
        listInstance,
        listName: filter ? `${listName}-${filter}` : listName,
        itemPosition: position,
      }),
      comluxgroup.createDestination_1_0_0({
        destinationId: destination.destinationId,
        destinationName: destination.destinationName,
        tileType: destination.tileType,
        destinationImageId: destination.destinationImageId,
      }),
    ],
    event: comluxgroup.createDestinationImpression_1_0_0({}),
  }
}

export function destinationClickEventWithContext(
  position: number,
  listName: string,
  listInstance: string,
  destination: SnowplowDestinationContext,
  filter?: string,
): SnowplowEvent {
  return {
    context: [
      comluxgroup.createListItem_1_0_0({
        listInstance,
        listName: filter ? `${listName}-${filter}` : listName,
        itemPosition: position,
      }),
      comluxgroup.createDestination_1_0_0({
        destinationId: destination.destinationId,
        destinationName: destination.destinationName,
        tileType: destination.tileType,
        destinationImageId: destination.destinationImageId,
      }),
    ],
    event: comluxgroup.createDestinationClick_1_0_0({}),
  }
}

export function setNotificationBannerCookie(slug: string, hash: string) {
  document.cookie = `notification-banner-${slug}=${hash}; expires=Fri, 29 Dec 2028 23:59:59 GMT; path=/; domain=${document.domain}`
}

export function getNotificationBannerHashFromCookie(slug: string) {
  if (typeof document !== 'undefined') {
    const decodedCookie = decodeURIComponent(document.cookie)
    const notificationCookieIndex = decodedCookie.indexOf(`notification-banner-${slug}`)
    if (notificationCookieIndex >= 0) {
      const notificationCookie = decodedCookie.substring(notificationCookieIndex).split(';')[0]
      return notificationCookie.replace(`notification-banner-${slug}=`, '')
    }
  }
}

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import * as Analytics from 'analytics/analytics'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import Group from 'components/utils/Group'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import { ModalSteps } from '../defaults'
import TravelPreferencesImageItemsGrid from '../TravelPreferencesImageItemsGrid/TravelPreferencesImageItemsGrid'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { UserPreferenceHolidayOrBrands } from '../types'
import { deleteUserPreferenceExternalBrand, updateUserPreferenceExternalBrand } from 'actions/userTravelPreferencesActions'

interface Props {
  defaultDisplayExternelBrands?: Array<App.DefaultUserPreferenceExternalBrand>;
  selectedExternelBrands?: Array<App.UserPreferenceExternalBrand>;
  onSetNextStep: (step: ModalSteps, isSaveAction?: boolean) => void;
}

function TravelPreferencesModalHotelBrands(props: Props) {
  const { defaultDisplayExternelBrands, selectedExternelBrands, onSetNextStep } = props

  const dispatch = useAppDispatch()
  const userId = useAppSelector(getCurrentUserId)
  const initialSelectedIds: Set<string> = useMemo(() => new Set(selectedExternelBrands?.map(h => h.externalBrandId) || []), [selectedExternelBrands])
  const displayItems: Array<UserPreferenceHolidayOrBrands> | undefined = useMemo(() => (
    defaultDisplayExternelBrands?.map(i => ({ name: i.externalBrandDisplayName, id: i.externalBrandId, image: i.imageId }))
  ), [defaultDisplayExternelBrands])
  const [selectedHotelBrands, setSelectedHotelBrands] = useState<Set<string>>(new Set([]))

  useEffect(() => {
    if (selectedExternelBrands) {
      setSelectedHotelBrands(new Set(selectedExternelBrands.map(h => h.externalBrandId)))
    }
  }, [selectedExternelBrands])

  const onToggleSelection = useCallback((item: UserPreferenceHolidayOrBrands) => {
    setSelectedHotelBrands(prevSelected => {
      const newSelected = new Set(prevSelected)

      if (newSelected.has(item.id)) {
        newSelected.delete(item.id)
      } else {
        newSelected.add(item.id)
      }

      return newSelected
    })
  }, [])

  const onSkip = useCallback(() => {
    onSetNextStep(ModalSteps.travellers)
  }, [onSetNextStep])

  const onUpdatePreferences = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'hotel_brands',
      action: 'completion',
      type: 'interaction',
      category: 'travel_preferences',
    })

    // compare the current state and redux state, and then send the requests
    for (const id of selectedHotelBrands) {
      if (!initialSelectedIds.has(id)) {
        dispatch(updateUserPreferenceExternalBrand(userId!, id))
      }
    }
    for (const id of initialSelectedIds) {
      if (!selectedHotelBrands.has(id)) {
        dispatch(deleteUserPreferenceExternalBrand(userId!, id))
      }
    }

    onSetNextStep(ModalSteps.travellers, true)
  }, [dispatch, onSetNextStep, selectedHotelBrands, initialSelectedIds, userId])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'hotel_brands',
      action: 'impression',
      type: 'nonInteraction',
      category: 'travel_preferences',
    })
  }, [])

  return (
    <>
      <ModalBody>
        <ModalContent>
          <Group direction="vertical" gap={16}>
            <BodyText variant="medium">Enjoy priority access to exclusive offers that suit your preferences. Select up to five brands.</BodyText>
            <TravelPreferencesImageItemsGrid
              showLabel={false}
              items={displayItems || []}
              onSelect={onToggleSelection}
              selectedId={selectedHotelBrands}
            />
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter primaryActionProps={{ children: 'Save & continue', onClick: onUpdatePreferences }} secondaryActionProps={{ children: 'Skip', onClick: onSkip }} />
    </>
  )
}

const mapStateToProps = (state: App.State) => ({
  defaultDisplayExternelBrands: state.userTravelPreferences.defaultExternalBrands.data,
  selectedExternelBrands: state.userTravelPreferences.externalBrands.data,
})

export default connect(mapStateToProps)(TravelPreferencesModalHotelBrands)

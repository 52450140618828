import { objectEntries } from 'lib/object/objectUtils'

type ScriptAttributes = Partial<HTMLScriptElement>

type LaterLoader = {
  resolver: Promise<void>;
  load: () => Promise<void>;
}

const toLoad: Record<string, LaterLoader> = {}

export default function loadScript(src: string, attributes: ScriptAttributes = {}) {
  return new Promise<void>(function(resolve, reject) {
    if (document == undefined) {
      return reject()
    }

    if (scriptAlreadyLoaded(src, attributes)) {
      setTimeout(resolve)
      return
    }

    const script = document.createElement('script')
    script.src = src
    for (const [key, value] of Object.entries(attributes)) {
      // Note: The attributes we're passing in are generally booleans,
      // but setAttribute insists they should be strings
      script.setAttribute(key, value as string)
    }
    script.onload = () => resolve()
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export function scriptAlreadyLoaded(src: string, attributes: ScriptAttributes) {
  return Array.from(document.querySelectorAll('script'))
    .some(script => scriptMatches(script, src, attributes))
}

function scriptMatches(script: HTMLScriptElement, src: string, attributes: ScriptAttributes) {
  return script.src == src &&
    objectEntries(attributes).every(([key, value]) => typeof key === 'string' && script.getAttribute(key) == value?.toString())
}

export function loadScriptOnReady(
  src: string,
  srcAttributes: Partial<HTMLScriptElement> = { async: true, defer: true },
) {
  if (document.readyState === 'complete') {
    return loadScript(src, srcAttributes)
  } else if (toLoad[src]) {
    return toLoad[src].resolver
  } else {
    const { promise, resolve } = Promise.withResolvers<void>()
    const loader = () => loadScript(src, srcAttributes).then(resolve)

    toLoad[src] = {
      resolver: promise,
      load: loader,
    }

    return promise
  }
}

(() => {
  if (typeof window !== 'undefined') {
    window.onload = () => {
      for (const [, laterLoader] of Object.entries(toLoad)) {
        laterLoader.load()
      }
    }
  }
})()

import React, { Component, ComponentProps } from 'react'
import { connect } from 'react-redux'
import config from 'constants/config'
import GoogleLoginButton from './GoogleLoginButton'
import FacebookLoginButton from './FacebookLoginButton'
import AppleLoginButton from './AppleLoginButton'

import {
  register,
  loginFacebook,
  loginGoogle,
  loginApple,
} from 'actions/AuthActions'

import { modalOpen, modalClose } from 'actions/UiActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import IconButton from 'components/Luxkit/Button/IconButton'
import switchFunc from 'lib/function/switchFunc'
import Modal from 'components/Luxkit/Modal/Modal'

function getInitialState(props: Props) {
  const {
    mode,
    source,
    utm,
  } = props

  switch (source) {
    case 'facebook':
      return {
        utm: 'utm_fb',
        mode: utm.fb || mode || 'login',
      }
    case 'google':
      return {
        utm: 'utm_google',
        mode: utm.google || mode || 'login',
      }
    case 'apple':
      return {
        utm: 'utm_apple',
        mode: utm.apple || mode || 'login',
      }
    default:
      return {}
  }
}

interface Props {
  source: Exclude<App.AuthSource, 'email' | 'otp'>;
  mode: 'login' | 'join';
  customerPortalURL: string;
  regionCode: string;
  utm: App.UTMState;
  modalError: any;
  currentUrl: string;
  authError: any;
  isBlocked?: boolean;
  hideText?: boolean;
  className?: string;
  loginFacebook: (...params: Parameters<typeof loginFacebook>) => void;
  loginGoogle: (...params: Parameters<typeof loginGoogle>) => void;
  loginApple: (...params: Parameters<typeof loginApple>) => void;
  register: (...params: Parameters<typeof register>) => void;
  modalOpen: (...params: Parameters<typeof modalOpen>) => void;
  modalClose: (...params: Parameters<typeof modalClose>) => void;
  renderId?: string;
  customIcon?: JSX.Element;
  onPrivateAppleEmailLogin?: (appleLoginData: App.AppleLoginData, email: string) => void
}

export const SOCIAL_LOGIN_TEXT_BUTTON_PROPS: Omit<ComponentProps<typeof TextButton>, 'children'> = {
  fit: 'full-width',
  kind: 'secondary',
  size: 'medium',
  variant: 'dark',
}

export const SOCIAL_LOGIN_ICON_BUTTON_PROPS: Omit<ComponentProps<typeof IconButton>, 'children'> = {
  kind: 'secondary',
  size: 'large',
  variant: 'dark',
}

interface LoginErrors {
  code: 'popup_blocked_by_browser' | 'access_denied',
}

interface Copy {
  title: string;
  body: string;
}

const getCopy = switchFunc<Copy, LoginErrors['code']>({
  popup_blocked_by_browser: {
    title: 'Popup blocked by your browser',
    body: 'Please make sure that you allow popups on this site',
  },
  access_denied: {
    title: 'Something went wrong',
    body: 'An error occurred while trying to login, please try again later',
  },
})

class SocialLoginButton extends Component<React.PropsWithChildren<Props>> {
  state = getInitialState(this.props)

  redirectUri = (baseUrl = false) => {
    const {
      customerPortalURL,
      currentUrl,
    } = this.props

    return `${customerPortalURL}${baseUrl ? '' : currentUrl}?${this.state.utm}=${this.state.mode}`
  }

  onShowError = (error) => {
    const errorCopy = getCopy(error)
    if (errorCopy) {
      if (error.status === 400) {
        return this.props.modalOpen(errorCopy.title, error.message)
      }

      this.props.modalOpen(errorCopy.title, errorCopy.body)
    }
  }

  onFailure = ({ error }) => {
    this.onShowError(error)
  }

  onSuccess = (data) => {
    if (this.state.mode === 'join') {
      this.props.register(data, this.props.source)
    } else {
      if (this.props.source === 'google') {
        this.props.loginGoogle(data)
      } else if (this.props.source === 'facebook') {
        this.props.loginFacebook(data)
      } else if (this.props.source === 'apple') {
        this.props.loginApple(data)
      }
    }
  }

  render() {
    const {
      isBlocked,
      className,
      modalError,
      modalClose,
      source,
      hideText,
      renderId,
      customIcon,
      onPrivateAppleEmailLogin,
    } = this.props

    return (
      <>
        {source === 'apple' && config.APPLE_APP_ID &&
          <AppleLoginButton
            isBlocked={isBlocked}
            className={className}
            redirectUri={this.redirectUri(true)}
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            hideText={hideText}
            onPrivateAppleEmailLogin={onPrivateAppleEmailLogin}
          />
        }
        {source === 'facebook' && config.FACEBOOK_APP_ID &&
          <FacebookLoginButton
            isBlocked={isBlocked}
            className={className}
            onSuccess={this.onSuccess}
            hideText={hideText}
            customIcon={customIcon}
          />
        }
        {source === 'google' && config.GOOGLE_APP_ID &&
          <GoogleLoginButton
            hideText={hideText}
            renderId={renderId!}
            customIcon={customIcon}
          />
        }
        {modalError.open &&
          <Modal
            isOpen={modalError.open}
            onClose={modalClose}
            title={modalError.heading ?? ''}
          >
            {modalError.message}
          </Modal>
        }
      </>
    )
  }
}

const mapStateToProps = (state: App.State) => ({
  customerPortalURL: state.config.customerPortalURL,
  regionCode: state.geo.currentRegionCode,
  utm: state.utm,
  modalError: state.ui.modal.error,
  currentUrl: state.router.location.pathname,
  authError: state.auth.error,
})

const mapDispatchToProps = {
  loginFacebook,
  loginGoogle,
  loginApple,
  register,
  modalOpen,
  modalClose,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginButton)

import request from 'api/requestUtils'

interface BannerProps {
  regionCode: string;
  location: App.BannerLocation;
  tags?: Array<string>;
}

const bannerLinkMap = (linkTo:string) =>
  (linkTo.startsWith('/') || linkTo.startsWith('?')) ? { to: linkTo } : { href: linkTo }

const bannersMap = (data: any): App.Banner => ({
  name: data.name,
  bannerId: data.bannerId,
  imageId: data.imageId,
  imageAlt: data.imageAlt,
  display: data.display,
  position: data.position,
  ...bannerLinkMap(data.linkTo),
})

export function getBanners({ regionCode, tags = [], location }: BannerProps) {
  const path = `/api/banners?region=${regionCode}&location=${location}&tags=${tags.join(',')}`
  return request.get<App.ApiResponse<Array<any>>>(path).then((data) => data.result.map(bannersMap))
}

const listBannersMap = (data: any): App.ListBanner => ({
  name: data.name,
  bannerId: data.bannerId,
  desktopImageId: data.desktopImageId,
  mobileImageId: data.mobileImageId,
  imageAlt: data.imageAlt,
  position: data.position,
  ...bannerLinkMap(data.linkTo),
})

export function getListBanners({ regionCode, tags = [], location }: BannerProps) {
  const path = `/api/banners/list?region=${regionCode}&location=${location}&tags=${tags.join(',')}`
  return request.get<App.ApiResponse<Array<any>>>(path).then((data) => data.result.map(listBannersMap))
}

import { createSelector } from 'reselect'
import { getBookingProtectionItemsView } from '../view/bookingProtection'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { shouldUseBookingProtectionMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'

export const getBookingProtectionTotals = createSelector(
  getBookingProtectionItemsView,
  shouldUseBookingProtectionMemberPrice,
  (viewWithStatus, checkoutWithDiscountedBookingProtection): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)

    const itemsWithMobileAppDiscount = definedItems.filter(item => item.mobileAppDiscount?.amount)
    const mobileAppDiscountAmount = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, item => item.mobileAppDiscount?.amount ?? 0) : 0
    const mobileAppDiscountPercentage = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, item => item.mobileAppDiscount?.percentage ?? 0) / itemsWithMobileAppDiscount.length : 0

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView =>
          checkoutWithDiscountedBookingProtection ? (itemView.memberPrice ?? 0) : (itemView.price ?? 0),
        ),
        memberPrice: 0,
        value: sum(definedItems, itemView => (itemView.value ?? 0)),
        surcharge: 0,
        taxesAndFees: 0,
        mobileAppDiscount: {
          amount: mobileAppDiscountAmount,
          percentage: mobileAppDiscountPercentage,
        },
      },
    }
  },
)

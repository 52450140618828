import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from './accommodation'
import { getBookingProtectionItemsView } from './bookingProtection'
import { getCarHireItemsView } from './carHire'
import { getExperienceItemsView, getTransferItemsView } from './experience'
import { getFlightItemsView } from './flights'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { getSubscriptionJoinItemView, getLuxPlusSubscriptionItemView } from './luxPlusSubscription'
import { getTourV2ExperienceItemsViews } from './toursv2'
import { getVillaViews } from './villa'

export type AllCheckoutItemViews = ReturnType<typeof getAllItemViews>

export type AllItemViews = {
  accommodationItemsView: App.WithDataStatus<Array<App.Checkout.AccommodationOfferView>>
  experienceItemsView: App.WithDataStatus<Array<App.Checkout.ExperienceItemView>>
  flightItemsView: App.WithDataStatus<Array<App.Checkout.FlightItemView>>
  insuranceItemsView: App.WithDataStatus<Array<App.Checkout.InsuranceItemView>>
  transferItemsView: App.WithDataStatus<Array<App.Checkout.TransferItem>>
  bookingProtectionItemsView: App.WithDataStatus<Array<App.Checkout.BookingProtectionItemView>>
  villaItemsView: App.WithDataStatus<Array<App.Checkout.VillaItemView>>
  carHireItemsView: App.WithDataStatus<Array<App.Checkout.CarHireItemView>>
  subscriptionItemView: App.WithDataStatus<Array<App.Checkout.SubscriptionJoinItemView>>
  tourV2ExperienceItemsView: App.WithDataStatus<Array<App.Checkout.TourV2ExperienceItemView>>
  luxPlusSubscriptionItemView: App.WithDataStatus<Array<App.Checkout.LuxPlusSubscriptionItemView>>
}

const getAllItemViews = createSelector(
  checkoutAccommodationOfferView,
  getExperienceItemsView,
  getFlightItemsView,
  getInsuranceItemsView,
  getTransferItemsView,
  getBookingProtectionItemsView,
  getVillaViews,
  getCarHireItemsView,
  getSubscriptionJoinItemView,
  getTourV2ExperienceItemsViews,
  getLuxPlusSubscriptionItemView,
  (...views):App.WithDataStatus<AllItemViews> => {
    return {
      hasRequiredData: views.every(o => o.hasRequiredData),
      data: {
        accommodationItemsView: views[0],
        experienceItemsView: views[1],
        flightItemsView: views[2],
        insuranceItemsView: views[3],
        transferItemsView: views[4],
        bookingProtectionItemsView: views[5],
        villaItemsView: views[6],
        carHireItemsView: views[7],
        subscriptionItemView: views[8],
        tourV2ExperienceItemsView: views[9],
        luxPlusSubscriptionItemView: views[10],
      },
    }
  },
)

export default getAllItemViews

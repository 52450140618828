import { SecurePaymentActions } from 'actions/SecurePaymentActions'
import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  SECURE_PAYMENT_SET_PROCESSING,
  SECURE_PAYMENT_SET_ERROR,
  SECURE_PAYMENT_SET_MERCHANT_FEE_PAYMENT_TYPE,
  SECURE_PAYMENT_SET_STRIPE_PAYMENT_METHOD,
  SECURE_PAYMENT_SET_INFO,
  SECURE_PAYMENT_SET_PAID,
  SECURE_PAYMENT_SET_LOADING,
  SECURE_PAYMENT_SET_EXPIRED,
  SECURE_PAYMENT_SET_SUCCESS,
} from 'actions/actionConstants'
import {
  CREATE_STRIPE_PAYMENT_METHOD_SECURE_PAYMENT,
} from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialSecurePaymentState: App.SecurePaymentState = {
  status: 'loading',
  stripePaymentMethod: {
    isCreatingStripePaymentMethod: false,
    paymentMethodId: undefined,
    cardBrand: undefined,
    cardFunding: undefined,
  },
  merchantFeePaymentType: undefined,
  data: undefined,
}

const apiRequests = reducerSwitch<App.SecurePaymentState>({
  [CREATE_STRIPE_PAYMENT_METHOD_SECURE_PAYMENT]: (state): App.SecurePaymentState => ({
    ...state,
    stripePaymentMethod: {
      ...state.stripePaymentMethod,
      isCreatingStripePaymentMethod: true,
      cardBrand: undefined,
      cardFunding: undefined,
      paymentMethodId: undefined,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.SecurePaymentState>({
  [CREATE_STRIPE_PAYMENT_METHOD_SECURE_PAYMENT]: (state, action): App.SecurePaymentState => ({
    ...state,
    stripePaymentMethod: {
      ...state.stripePaymentMethod,
      isCreatingStripePaymentMethod: false,
      cardBrand: action.data.paymentMethod.card.brand,
      cardFunding: action.data.paymentMethod.card.funding,
      paymentMethodId: action.data.paymentMethod.id,
    },
  }),
})

const apiFailures = reducerSwitch<App.SecurePaymentState>({
  [CREATE_STRIPE_PAYMENT_METHOD_SECURE_PAYMENT]: (state): App.SecurePaymentState => ({
    ...state,
    stripePaymentMethod: {
      ...state.stripePaymentMethod,
      isCreatingStripePaymentMethod: false,
    },
  }),
})

const securePaymentReducer = createReducer<App.SecurePaymentState, SecurePaymentActions>(initialSecurePaymentState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [SECURE_PAYMENT_SET_STRIPE_PAYMENT_METHOD]: (state, action): App.SecurePaymentState => ({
    ...state,
    stripePaymentMethod: {
      ...state.stripePaymentMethod,
      ...action.paymentMethod,
    },
  }),
  [SECURE_PAYMENT_SET_MERCHANT_FEE_PAYMENT_TYPE]: (state, action): App.SecurePaymentState => ({
    ...state,
    merchantFeePaymentType: action.paymentType,
  }),
  [SECURE_PAYMENT_SET_INFO]: (state, action): App.SecurePaymentState => ({
    ...state,
    status: 'pending',
    data: {
      ...state.data,
      amount: action.data.amount,
      currency: action.data.currency,
      orderId: action.data.orderId,
      isDeposit: action.data.isDeposit,
      isDepositBalance: action.data.isDepositBalance,
      hasLuxPlus: action.data.hasLuxPlus,
      items: action.data.items,
      promoAmount: action.data.promoAmount,
      creditAmount: action.data.creditAmount,
      balanceAmount: action.data.balanceAmount,
      balanceDueDate: action.data.balanceDueDate,
    },
  }),
  [SECURE_PAYMENT_SET_PROCESSING]: (state): App.SecurePaymentState => ({
    ...state,
    status: 'processing',
  }),
  [SECURE_PAYMENT_SET_ERROR]: (state): App.SecurePaymentState => ({
    ...state,
    status: 'error',
  }),
  [SECURE_PAYMENT_SET_PAID]: (state): App.SecurePaymentState => ({
    ...state,
    status: 'paid',
  }),
  [SECURE_PAYMENT_SET_LOADING]: (state): App.SecurePaymentState => ({
    ...state,
    status: 'loading',
  }),
  [SECURE_PAYMENT_SET_EXPIRED]: (state): App.SecurePaymentState => ({
    ...state,
    status: 'expired',
  }),
  [SECURE_PAYMENT_SET_SUCCESS]: (state): App.SecurePaymentState => ({
    ...state,
    status: 'success',
  }),
})

export default securePaymentReducer

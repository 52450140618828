import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCommentsAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 8h-1V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v12a1.0004 1.0004 0 0 0 .62.92A.8395.8395 0 0 0 3 18a1.0005 1.0005 0 0 0 .71-.29l2.81-2.82H8v1.44a3 3 0 0 0 3 3h6.92l2.37 2.38A1.0003 1.0003 0 0 0 21 22a.8395.8395 0 0 0 .38-.08A1.0004 1.0004 0 0 0 22 21V11a3.0001 3.0001 0 0 0-3-3ZM8 11v1.89H6.11a1.0005 1.0005 0 0 0-.71.29L4 14.59V5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v3h-5a3 3 0 0 0-3 3Zm12 7.59-1-1a1.0005 1.0005 0 0 0-.71-.3H11a1.0001 1.0001 0 0 1-1-1V11a1.0001 1.0001 0 0 1 1-1h8a1.0001 1.0001 0 0 1 1 1v7.59Z"/>
  </SvgIcon>
}

export default LineCommentsAltIcon

import config from 'constants/config'
import React, { PropsWithChildren, ReactNode, useMemo } from 'react'
import { connect } from 'react-redux'

interface MappedStateProps {
  businessAccount?: BusinessTraveller.CurrentBusinessAccount
}
interface Props {
  accountMode?: 'business' | 'personal' | 'either'
  employeeRoles?: Array<BusinessTraveller.EmployeeRole>
  loading?: ReactNode
  fallback?: ReactNode
}

function BusinessTravellerAccountGuard(props: PropsWithChildren<Props & MappedStateProps>) {
  const {
    accountMode = 'either',
    employeeRoles: employeeRole,
    children,
    businessAccount,
    fallback,
    loading,
  } = props

  const shouldLetIn = useMemo(() => {
    const allowedAccountMode = accountMode === 'either' ? true : config.businessTraveller.currentAccountMode === accountMode
    const allowedRole = !employeeRole ? true :
      businessAccount?.employee?.roles.some(role => employeeRole.includes(role))

    return allowedAccountMode && allowedRole
  }, [accountMode, employeeRole, businessAccount])

  if (!businessAccount || businessAccount.status === 'pending') {
    return <>{loading}</>
  }

  if (!shouldLetIn || businessAccount.status === 'failed') {
    return <>{fallback}</>
  }

  return <>{children}</>
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => {
  return {
    businessAccount: state.businessTraveller.currentBusinessAccount,
  }
})(BusinessTravellerAccountGuard)

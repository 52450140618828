import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryDown } from 'components/utils/breakpoint'

export const StyledGlobalSearchQuickFilterButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(8)};

  &:empty {
    display: none;
  }
  ${mediaQueryDown.tablet} {
    > *:nth-child(n + 8) {
      display: none;
    }
  }
`

interface Props {
  children: React.ReactNode
}

function GlobalSearchQuickFilterButtonsContainer(props: Props) {
  const { children } = props

  return <StyledGlobalSearchQuickFilterButtonsContainer>
    {children}
  </StyledGlobalSearchQuickFilterButtonsContainer>
}

export default GlobalSearchQuickFilterButtonsContainer

import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import Flag from 'components/Luxkit/Flag'
import LineCommentsIcon from 'components/Luxkit/Icons/line/LineCommentsIcon'
import LineEnvelopeIcon from 'components/Luxkit/Icons/line/LineEnvelopeIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import config from 'constants/config'
import { SUPPORT_EMAIL_FOR_BRAND } from 'constants/support'
import ContactContext from 'contexts/contactContext'
import { useRenderWhatsApp } from 'hooks/useRenderWhatsApp'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import shouldRenderTimely from 'lib/timely/timely'
import React, { ComponentProps, MouseEventHandler, useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'

interface Props extends Pick<ComponentProps<typeof DropdownList>, 'anchorRef' | 'open' | 'onClose'> {
  currentRegionCode: string;
  currentRegionName: string;
  onItemClick: () => void;
  supportAssistantEnabled: boolean;
}

function NavigationHeaderDropdown(props: Props) {
  const {
    currentRegionCode,
    currentRegionName,
    onItemClick,
    supportAssistantEnabled,
    open,
    anchorRef,
    onClose,
  } = props
  const currentContact = useContext(ContactContext)

  const localContact = currentContact.local
  const internationalContact = currentContact.international
  const shouldRenderWhatsApp = useRenderWhatsApp(currentRegionCode)

  const showTimelyPopup = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    onItemClick()
    window.open(config.TIMELY_LINK, 'popup', 'width=600,fullscreen=no,location=no,menubar=no,toolbar=no')
  }, [onItemClick])

  return (
    <DropdownList
      size="S"
      open={open}
      onClose={onClose}
      anchorRef={anchorRef}
      triggerRef={anchorRef}
    >
      <ListItem
        size="medium"
        aria-label={`Local Sales & Support contact - ${currentRegionName}`}
        href={`tel:${localContact.number}`}
        startIcon={config.businessTraveller.isEnabled ? <Flag countryCode={currentRegionCode} /> : <LinePhoneIcon />}
        title="Local Sales & Support"
        onClick={onItemClick}
        rel="nofollow"
        subtitle={localContact.humanReadable}
      />
      {supportAssistantEnabled && <ListItem
        size="medium"
        aria-label={`Luxury Escapes Support Assistant - ${currentRegionName}`}
        to="/support"
        startIcon={<LineCommentsIcon />}
        title="Chat now"
        onClick={onItemClick}
        rel="nofollow"
        subtitle="Luxury Escapes Support Assistant"
      />}
      {shouldRenderWhatsApp && <ListItem
        size="medium"
        title="WhatsApp"
        aria-label={`WhatsApp - ${currentRegionName}`}
        startIcon={<img src={getSVGImageUrl('whatsapp-logo')} width="24" height="24" alt="WhatsApp"/>}
        onClick={onItemClick}
        href={`https://wa.me/${config.WHATS_APP_NUMBER}`}
        target="_blank"
        rel="noopener"
      />}
      {shouldRenderTimely(currentRegionCode) && <ListItem
        size="medium"
        title="Book store appointment"
        aria-label={`Timely - ${currentRegionName}`}
        startIcon={<img src={getSVGImageUrl('logo-LE')} width="24" height="24" alt="support"/>}
        onClick={showTimelyPopup}
        href={config.TIMELY_LINK}
        target="popup"
        rel="noopener"
      />}
      <ListItem
        size="medium"
        startIcon={config.businessTraveller.isEnabled ?
          <img src={getSVGImageUrl('logo-LE')} width="24" height="24" alt="support"/> :
          <LinePhoneIcon />}
        aria-label="International Support contact"
        href={`tel:${internationalContact.number}`}
        title="International Support"
        onClick={onItemClick}
        rel="nofollow"
        subtitle={internationalContact.humanReadable}
      />
      {config.businessTraveller.currentAccountMode === 'business' && <ListItem
        size="medium"
        startIcon={<LineEnvelopeIcon />}
        aria-label="Email"
        href={`mailto:${SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}`}
        title="Email"
        onClick={onItemClick} // closes
        rel="nofollow"
        subtitle={SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}
      />}
    </DropdownList>
  )
}

const mapStateToProps = (state: App.State) => ({
  currentRegionCode: state.geo.currentRegionCode,
  currentRegionName: state.geo.currentRegionName,
  supportAssistantEnabled: isSupportAssistantEnabled(state),
})

export default connect(mapStateToProps)(NavigationHeaderDropdown)

import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { SEARCH_VERTICALS } from 'constants/search'

export const GlobalSearchTabContent = styled.section`  
  &.is-hidden {
    display: none;
  }
`
interface Props {
  tabs: Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTab>
  selectedTabKey?: SEARCH_VERTICALS
}

function GlobalSearchTabContents(props: Props) {
  const { selectedTabKey, tabs } = props
  const keysSeen = useRef<Set<SEARCH_VERTICALS>>(new Set<SEARCH_VERTICALS>([selectedTabKey]))

  useEffect(() => {
    keysSeen.current.add(selectedTabKey)
  }, [selectedTabKey])

  const tabsArray = useMemo(() => [...tabs.entries()], [tabs])

  return <>
    {tabsArray.map(([key, tab]) => <GlobalSearchTabContent className={cn({ 'is-hidden': selectedTabKey !== key })} key={key}>
      {(selectedTabKey === key || keysSeen.current.has(key)) && <tab.Component />}
    </GlobalSearchTabContent>)}
  </>
}

export default GlobalSearchTabContents

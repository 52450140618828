import { definitions } from '@luxuryescapes/contract-trip'
import queryString from 'qs'

import request from 'api/requestUtils'
import { GetCuratedTripsResponse } from 'tripPlanner/api/types'
import { baseUri, getOptions } from 'tripPlanner/api/utils'

export interface CuratedTripFilters {
  lePlaceId?: string
  sourceItemCode?: string
  tripIds?: Array<string>
}

export function getCuratedTrips(
  regionCode: string,
  timezoneOffset: number,
  filters: CuratedTripFilters,
  limit?: number,
  accessToken?: string,
): Promise<Array<definitions['basicTrip']>> {
  const queryParams = queryString.stringify({
    regionCode,
    timezoneOffset,
    limit,
    ...filters,
  })
  const uri = `${baseUri}/templates?${queryParams}`
  return request
    .get<GetCuratedTripsResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function getCuratedTripsWithPagination(
  page: number,
  regionCode: string,
  timezoneOffset: number,
  accessToken?: string,
): Promise<GetCuratedTripsResponse> {
  const uri = `${baseUri}/templates?start=${page}&regionCode=${regionCode}&timezoneOffset=${timezoneOffset}`
  return request
    .get<GetCuratedTripsResponse>(uri, getOptions(accessToken))
    .then((response) => response)
}

import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import Heading from 'components/Luxkit/Typography/Heading'
import { CABIN_CATEGORIES } from 'constants/cruise'
import { sortBy } from 'lib/array/arrayUtils'
import { getCruiseOfferPriceVariation } from 'lib/cruises/cruiseUtils'
import React, { useMemo } from 'react'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'

interface Props {
  offer: App.CruiseOffer;
  lowestPriceByCategory: App.LowestPriceDetailsByCategory | App.LowestPricesByCategory;
}

function CruiseSearchPrices(props: Props) {
  const { offer, lowestPriceByCategory } = props
  const cruisePriceByNight = useCruisePriceByNight()

  const pricePerCategory = useMemo(() => {
    return sortBy(
      Object.entries(lowestPriceByCategory)
        .map(([category, price]) => {
          const categoryIncluded = CABIN_CATEGORIES.some(
            (item) => item.category === category,
          )
          if (categoryIncluded) return price
        })
        .filter(Boolean),
      (price) => price ? price.total : 0,
      'asc',
    )
  }, [lowestPriceByCategory])

  return <>{CABIN_CATEGORIES.map((cabinCategory) => {
    const price = pricePerCategory.find(
      (price) => price?.cabinCategory === cabinCategory.category,
    )
    if (price) {
      const { primaryPrice } =
        getCruiseOfferPriceVariation(offer, price, cruisePriceByNight)

      if (primaryPrice.type === 'night') {
        return (
          <div key={price.cabinCategory}>
            <PriceRowPriceCaption>{price.cabinCategory} from</PriceRowPriceCaption>
            <PriceRowPrice
              size="M"
              price={primaryPrice?.total ?? 0}
              saleUnit={primaryPrice?.type ?? ''}
            />
          </div>
        )
      }

      return (
        <div key={price.cabinCategory}>
          <PriceRowPriceCaption>{price.cabinCategory} from</PriceRowPriceCaption>
          <PriceRowPrice
            size="M"
            price={primaryPrice.total}
            saleUnit={primaryPrice.type}
          />
        </div>
      )
    }
    return (
      <div key={cabinCategory.category}>
        <PriceRowPriceCaption>{cabinCategory.category} from</PriceRowPriceCaption>
        <Heading variant="heading5" colour="neutral-three">
          Unavailable
        </Heading>
      </div>
    )
  })}</>
}

export default CruiseSearchPrices

import React, { ComponentProps, CSSProperties } from 'react'
import TextButton from './TextButton'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import styled from 'styled-components'
import clsx from 'clsx'
import { rem } from 'polished'

const Skeleton = styled(LoadingBox)`
  &.size-small {
    height: ${rem(32)};
    min-width: calc(var(--w) + ${rem(16)})
  }
  &.size-medium {
    height: ${rem(40)};
    min-width: calc(var(--w) + ${rem(24)})
  }
  &.size-large {
    height: ${rem(48)};
    min-width: calc(var(--w) + ${rem(32)})
  }

  &.shape-square {
    border-radius: ${props => props.theme.borderRadius.S};
  }
  &.shape-round {
    border-radius: ${props => props.theme.borderRadius.round};
  }
`

interface Props extends Pick<ComponentProps<typeof TextButton>, 'size' | 'shape'> {
  width: `${number}ch`
  className?: string
}

function TextButtonLoadingSkeleton(props: Props) {
  const { size = 'medium', shape = 'square', width, className } = props

  return <Skeleton
    className={clsx(className, `size-${size}`, `shape-${shape}`)}
    style={{ '--w': width } as CSSProperties}
  />
}

export default TextButtonLoadingSkeleton

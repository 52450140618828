import luxuryEscapesTheme, { luxuryEscapesBorderRadii, luxuryEscapesDefaultPalette, luxuryEscapesInversePalette } from './luxuryescapes'
import { baseThemeShadows } from './shadows'

export const leBusinessTravellerDefaultPalette: App.ThemePalette<string> = {
  ...luxuryEscapesDefaultPalette,
}

export const leBusinessTravellerInversePalette: App.ThemePalette<string> = {
  ...luxuryEscapesInversePalette,
}

export const leBusinessTravellerShadows: App.ThemeShadows<string> = { ...baseThemeShadows }
export const leBusinessTravellerBorderRadii: App.ThemeBorderRadii<string> = { ...luxuryEscapesBorderRadii }

const leBusinessTravellerTheme: App.Theme = {
  ...luxuryEscapesTheme,
}

export default leBusinessTravellerTheme

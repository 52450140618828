import { useMemo } from 'react'

import { normaliseBookmarkId } from './utils'

import { groupBy } from 'lib/array/arrayUtils'
import { useEditableTrips } from 'tripPlanner/hooks/api'
import { BasicTrip } from 'tripPlanner/types/common'

const DEFAULT_TRIPS: Array<BasicTrip> = []

function useBookmarkPartitionedTrips(bookmarkId: string) {
  const { data: trips = DEFAULT_TRIPS } = useEditableTrips()

  const normalisedBookmarkId = normaliseBookmarkId(bookmarkId)

  const partitions = useMemo(() => {
    return groupBy(trips, (trip) =>
      trip.bookmarkIdSets
        .map((set) => normaliseBookmarkId(set.offerId))
        .includes(normalisedBookmarkId),
    )
  }, [trips, normalisedBookmarkId])

  return partitions
}

export default useBookmarkPartitionedTrips

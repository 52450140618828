import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getCarHireReservation } from 'api/carHire'
import { getPlaceByLatLong } from 'api/search'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'

async function mapCarHireOrderItem(state: App.State, orderItem: App.CarHireOrderItem, customerId: string) {
  let fullReservation = orderItem?.idReservation ? state.carHire.reservationInfo[orderItem.idReservation]?.reservation : undefined
  if (!fullReservation && orderItem.idReservation) {
    fullReservation = await getCarHireReservation(orderItem.idReservation, customerId)
  }
  const latPickUp = fullReservation?.pickUp.latitude
  const longPickUp = fullReservation?.pickUp.longitude
  const latDropOff = fullReservation?.dropOff.latitude
  const longDropOff = fullReservation?.dropOff.longitude
  let pickUpPlace: { city: string, country: string } | undefined
  let dropOffPlace: { city: string, country: string } | undefined
  // could also make one request and split it by ',' if needing to make it more efficient
  if (latDropOff && longDropOff) {
    const [dropOffCity, dropOffCountry] = await Promise.all([
      getPlaceByLatLong(latDropOff, longDropOff),
      getPlaceByLatLong(latDropOff, longDropOff, 'country'),
    ])
    dropOffPlace = { city: dropOffCity.name, country: dropOffCountry.name }
  }
  if (latPickUp && longPickUp) {
    const [pickUpCity, pickUpCountry] = await Promise.all([
      getPlaceByLatLong(latPickUp, longPickUp),
      getPlaceByLatLong(latPickUp, longPickUp, 'country'),
    ])
    pickUpPlace = { city: pickUpCity.name, country: pickUpCountry.name }
  }
  return {
    fullReservation,
    dropOffPlace,
    pickUpPlace,
  }
}

async function getCarHireOrderItems(state: App.State, order: App.Order) {
  const promises = order.carHireItems.map(async orderItem => {
    const { fullReservation, dropOffPlace, pickUpPlace } = await mapCarHireOrderItem(state, orderItem, order.customerId)
    const { categoryId, productId } = getCategoryAndProductId('car_hire')
    const travelStart = orderItem?.reservation?.pickUp.date ? moment(orderItem?.reservation?.pickUp.date).format(ISO_DATE_FORMAT) : undefined
    const travelEnd = orderItem?.reservation?.dropOff.date ? moment(orderItem?.reservation?.dropOff.date).format(ISO_DATE_FORMAT) : undefined
    return comluxgroup.createItem_1_1_0({
      offerId: fullReservation?.vendor.name || 'carHire',
      categoryId,
      productId,
      travelStart,
      travelEnd,
      duration: travelStart && travelEnd ? moment(travelEnd).diff(travelStart, 'days') : undefined,
      price: order.total,
      currency: order.currencyCode,
      itemId: orderItem.fkOrderId,
      bookingNumber: order.bookingNumber,
      originCity: pickUpPlace?.city,
      originCountry: pickUpPlace?.country,
      destinationCity: dropOffPlace?.city,
      destinationCountry: dropOffPlace?.country,
      orderId: orderItem.id,
    })
  })
  return await Promise.all(promises)
}

export default getCarHireOrderItems

import { last } from 'lib/array/arrayUtils'

export function setGeoCoordsCookie(latitude: number, longitude: number) {
  document.cookie = `geocoords=${latitude},${longitude}; expires=Fri, 29 Dec 2028 23:59:59 GMT; path=/; domain=${document.domain}`
}

export function getGeoCoords(): { latitude: number, longitude: number } {
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')
  const cookieEntry = cookies.find(val => val.includes('geocoords')) ?? ''
  const cookieValue = last(cookieEntry.split('='))
  const [latitude, longitude] = cookieValue.split(',').filter(Boolean)

  if (latitude && longitude) {
    return {
      latitude: parseInt(latitude),
      longitude: parseInt(longitude),
    }
  }
}

import { createSelector } from 'reselect'
import getAllItemViews from './getAllItemViews'

/**
 * Tells us whether or not all the data has been loaded to represent the checkout
 * i.e. is it "ready" and/or "done"
 */
const isCheckoutReady = createSelector(
  getAllItemViews,
  (state: App.State) => state.checkout.isCartRestored,
  (allViews, restored) => {
    return restored && allViews.hasRequiredData
  },
)

export default isCheckoutReady

import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import TextButton from '../../Luxkit/Button/TextButton'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const ButtonDivider = styled.div`
  color: ${props => props.theme.palette.neutral.default.five};
  padding: 0 ${rem(8)};
`

const ButtonSet = styled(BodyText)`
  display: flex;
  align-items: center;

  ${mediaHoverable} {
    > *:hover:not(/* sc-selector */ ${ButtonDivider}) {
      transition: color 0.2s;
      color: ${props => props.theme.palette.brand.primary.darkest};
    }
  }
`

interface Props {
  className?: string;
  showReset?: boolean;
  showRemove?: boolean;
  onReset?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function OccupancyItemActions(props: Props) {
  const { className, showReset, showRemove, onReset, onRemove } = props

  return (
    <ButtonSet variant="large" className={className}>
      {showReset &&
        <TextButton kind="tertiary" onClick={onReset}>Reset</TextButton>
      }
      {showReset && showRemove && <ButtonDivider>|</ButtonDivider>}
      {showRemove &&
        <TextButton kind="tertiary" onClick={onRemove}>Remove</TextButton>
      }
    </ButtonSet>
  )
}

export default React.memo(OccupancyItemActions)

export function getBoundsFromMarkers(
  markers: Array<App.MapMarker>,
) {
  const bounds = new google.maps.LatLngBounds()

  markers.forEach(p => {
    bounds.extend(new google.maps.LatLng(p.latitude, p.longitude))
  })

  return bounds
}

export function fitMarkersOnMap(
  map: google.maps.Map,
  markers: Array<App.MapMarker>,
  padding?: number,
)
{
  if (markers.length === 0) {
    // whatchu doing passing us no markers to fit!?
    return
  }

  if (markers.length === 1) {
    map.setCenter(new google.maps.LatLng(markers[0].latitude, markers[0].longitude))
    map.setZoom(15)
    return
  }

  const bounds = getBoundsFromMarkers(markers)
  map.fitBounds(bounds, padding)
}

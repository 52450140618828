import React from 'react'

import CruiseBookmarkButton, {
  Props as CruiseButtonProps,
} from './CruiseBookmarkButton'
import HomeAndVillasBookmarkButton, {
  Props as HomeAndVillasButtonProps,
} from './HomeAndVillasBookmarkButton'
import HotelBookmarkButton, {
  Props as HotelButtonProps,
} from './HotelBookmarkButton'
import TourBookmarkButton, {
  Props as TourButtonProps,
} from './TourBookmarkButton'

import {
  isCruiseOffer,
  isHotel,
  isTourV2Offer,
  isVillaOffer,
} from 'lib/offer/offerTypes'

export type Props =
  | CruiseButtonProps
  | HotelButtonProps
  | TourButtonProps
  | HomeAndVillasButtonProps
  | {
      offer:
        | App.AnyOffer
        | App.Offer
        | App.OfferSummary
        | App.BedbankOffer
        | App.BedbankOfferSummary
        | Tours.TourV2Offer
        | Tours.TourV2OfferSummary
      iconOnly?: boolean
    }

function isTourProps(props: Props): props is TourButtonProps {
  return isTourV2Offer(props.offer) || props.offer.type === 'tour'
}

function isCruiseProps(props: Props): props is CruiseButtonProps {
  return isCruiseOffer(props.offer)
}

function isHotelProps(props: Props): props is HotelButtonProps {
  return isHotel(props.offer)
}

function isHomeAndVillaProps(props: Props): props is HomeAndVillasButtonProps {
  return isVillaOffer(props.offer)
}

/**
 * @param props
 * @constructor
 */
function BookmarkButton(props: Props) {
  if (isTourProps(props)) {
    return <TourBookmarkButton {...props} />
  }

  if (isCruiseProps(props)) {
    return <CruiseBookmarkButton {...props} />
  }

  if (isHomeAndVillaProps(props)) {
    return <HomeAndVillasBookmarkButton {...props} />
  }

  if (isHotelProps(props)) {
    return <HotelBookmarkButton {...props} />
  }

  return null // Shouldn't get here - for type checking only
}

export default React.memo(BookmarkButton)

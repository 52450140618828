import LoadingBox from 'components/Common/Loading/LoadingBox'
import LabelLoadingBox from 'components/Luxkit/Label/LabelLoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template:
    'media' ${rem(200)}
    'content' 1fr / 1fr;
  gap: ${rem(16)};
  width: 75vw;

  ${mediaQueryUp.tablet} {
    width: ${rem(320)};
  }
`

const ImageLoadingBox = styled(LoadingBox)`
  height: ${rem(200)};
`

const ButtonLoadingBox = styled(LoadingBox)`
  height: ${rem(40)};
`

interface Props extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'onTransitionEnd'> {}

function CarouselLockedCardLoadingSkeleton(props: Props) {
  const { className, onTransitionEnd } = props

  return <Container className={className} onTransitionEnd={onTransitionEnd}>
    <ImageLoadingBox />
    <Group direction="vertical" verticalAlign="space-between" gap={16}>
      <Group direction="vertical" gap={8}>
        <LabelLoadingBox width={220} />
        <TextLoadingBox typography="heading5" width="100%" lines={2} />
      </Group>
      <ButtonLoadingBox />
    </Group>
  </Container>
}

export default React.memo(CarouselLockedCardLoadingSkeleton)

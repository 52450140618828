import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'

export function traderInformationMap(traderInfo: PublicOfferV2.TraderInformation): App.TraderInformation {
  return {
    name: traderInfo.name,
    email: traderInfo.email,
    phone: traderInfo.phone,
    address: traderInfo.address ? {
      line1: traderInfo.address.line1,
      line2: traderInfo.address.line2,
      line3: traderInfo.address.line3,
      city: traderInfo.address.city,
      countryCode: traderInfo.address.countryCode,
      postalCode: traderInfo.address.postalCode,
      stateProvinceCode: traderInfo.address.stateProvinceCode,
    } : undefined,
    businessRegisterName: traderInfo.businessRegisterName,
    businessRegisterNumber: traderInfo.businessRegisterNumber,
    termsAndConditions: traderInfo.termsAndConditions,
  }
}

import { getGeoRedirect, clearGeoRedirect } from 'storage/geo'
import moment from 'moment'

export default function shouldShowGeoRedirect() {
  const data = getGeoRedirect()

  if (data?.status) {
    if (moment(data.expiry_timestamp).isBefore(moment().unix())) {
      clearGeoRedirect()
      return true
    }

    return false
  }

  return true
}

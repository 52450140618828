import React, { Component, PropsWithChildren } from 'react'
import styled from 'styled-components'
import classnames from 'clsx'

import { PaneSize, PaneSizeCombo, paneSpacing } from '../mixins'

interface RootProps extends React.HTMLAttributes<HTMLDivElement> {
  $sizes: Array<PaneSize>;
  $mobileSizes: Array<PaneSize>;
}

const Root = styled.div<RootProps>`
  ${paneSpacing}
`

interface Props {
  className?: string;
  mobileSize?: PaneSizeCombo;
  size?: PaneSizeCombo;
}

class PaneBody extends Component<PropsWithChildren<Props>> {
  render() {
    const {
      size,
      mobileSize,
      children,
      className,
      ...props
    } = this.props

    const sizes = size ? size.split(/\s+/) as Array<PaneSize> : []
    const mobileSizes = mobileSize ? mobileSize.split(/\s+/) as Array<PaneSize> : []

    return (
      <Root
        $sizes={sizes}
        $mobileSizes={mobileSizes}
        className={classnames('PaneBody', className)}
        {...props}
      >
        {children}
      </Root>
    )
  }
}

export default PaneBody

import { getRegionByCode } from '@luxuryescapes/lib-regions'
import { Location } from 'history'
import Moment from 'moment'
import qs from 'qs'

import {
  queryKeyAdults,
  queryKeyChildren,
  queryKeyDestinationName,
  queryKeyDestinationId,
  queryKeyLandmarkName,
  queryKeyLandmarkId,
  queryKeyPropertyName,
  queryKeyPropertyId,
  queryKeyBounds,
  queryKeyCheckIn,
  queryKeyCheckOut,
  queryKeyPriceLte,
  queryKeyPriceGte,
  queryKeyPages,
  queryKeyPropertyTypes,
  queryKeyLocations,
  queryKeyHolidayTypes,
  queryKeyAmenities,
  queryKeySelectedOfferIds,
  queryKeyFlexibleNights,
  queryKeyFlexibleMonths,
  queryKeyUserSelectedFlexibleMonths,
  queryKeyInclusions,
  queryKeyMapPinCount,
} from 'constants/url'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'

// addRegionToPath returns a new path with region
export function addRegionToPath(path: string, regionCode: string) {
  return '/' + regionCode.toLowerCase() + (path === '/' ? '' : path)
}

export function removeRegionFromPath(path:string) {
  const segments = path.split('/').filter(segment => segment)
  if (getRegionByCode(segments[0])) segments.shift()
  return '/' + segments.join('/')
}

// parseSearchString parses location.search provided by react-router into
// an object. `searchString` is in the form of '?orderId=foo&itemId=bar'
export function parseSearchString(searchString?: string) {
  if (!searchString) {
    return {}
  }

  const queryResult = qs.parse(searchString, { ignoreQueryPrefix: true }) as { [key: string]: string }

  // TODO remove when mobile matches web
  if (queryResult.reservationType === 'instant') {
    queryResult.reservationType = 'instant_booking'
  }

  if (queryResult.reservationType === 'book-later') {
    queryResult.reservationType = 'buy_now_book_later'
  }

  return queryResult
}

function getSourceObj(sourceStr: string) {
  const searchObj = parseSearchString(sourceStr)

  // Preserve le_* query params
  return Object
    .keys(searchObj)
    .filter(key => /^(le_)/.test(key))
    .reduce<Record<string, string>>((acc, val) => {
      acc[val] = searchObj[val]
      return acc
    }, {})
}

// getPathWithCtx returns a new path with region code and search/hash if specified
export function getPathWithCtx(path: string, regionCode: string, location?: Location) {
  const search = path.includes('?') ? path.split('?')[1].split('#')[0] : undefined
  const hash = path.includes('#') ? path.split('#')[1] : undefined
  const onlyPath = path.split('?')[0].split('#')[0]

  let ret = addRegionToPath(onlyPath, regionCode)
  if (search || location?.search) {
    const searchProps = {
      ...qs.parse(search ?? {}),
      ...getSourceObj(location?.search ?? ''),
    }
    ret = `${ret}?${qs.stringify(searchProps)}`
  }
  if (hash) {
    ret = `${ret}#${hash}`
  }

  return ret
}

export function addQueryParamsToUrl(path: string, params: { [param: string]: any }) {
  const { protocol, host, pathname, search } = new URL(path)
  const combinedQuery = {
    ...parseSearchString(search),
    ...params,
  }
  return `${protocol}//${host}${pathname}?${qs.stringify(combinedQuery)}`
}

export function addQueryParamsToPath(path: string, params: { [param: string]: any }) {
  const [pathWithoutHash, hash] = path.split('#')
  const [pathname, search = ''] = pathWithoutHash.split('?')
  const combinedQuery = {
    ...parseSearchString(search),
    ...params,
  }
  const hashString = hash ? `#${hash}` : ''
  return `${pathname}?${qs.stringify(combinedQuery)}${hashString}`
}

export function getQueryParam(search: string, param: string) {
  return new URLSearchParams(search).get(param) || undefined
}

/**
 * Adds a value to the query param, this assumes multiple items for the same parameter name
 * e.g. if '?fruit=banana' and 'apple' is added, it will result in '?fruit=banana,apple'
 * @param search The current search string
 * @param paramName The query parameters name
 * @param paramValue The query parameters value
 */
export function addSearchParamValue(search: string, paramName: string, paramValue: string | number) {
  const searchParams = new URLSearchParams(search)
  const existingParam = (searchParams.get(paramName) ?? '').split(',').filter(Boolean)
  existingParam.push(paramValue.toString())
  searchParams.set(paramName, existingParam.join(','))

  return searchParams.toString()
}

/**
 * Removes a value from the query parameter, will remove from list of items and delete entire name if
 * no items are left
 * @param search The current search string
 * @param paramName The query parameters name
 * @param paramValue The query parameters value
 */
export function removeSearchParamValue(search: string, paramName: string, paramValue: string | number) {
  const searchParams = new URLSearchParams(search)
  const existingParam = (searchParams.get(paramName) ?? '').split(',').filter(Boolean)
  const newParams = existingParam.filter(value => value !== paramValue.toString())
  if (newParams.length === 0) {
    searchParams.delete(paramName)
  } else {
    searchParams.set(paramName, newParams.join(','))
  }

  return searchParams.toString()
}

/**
 * Swaps whether the parameter given is added or removed on whether it already exists in the search query
 * @param search The current search string
 * @param paramName The query parameters name
 * @param paramValue The query parameters value
 */
export function toggleSearchParamValue(search: string, paramName: string, paramValue: string | number) {
  const searchParams = new URLSearchParams(search)
  const existingParam = (searchParams.get(paramName) ?? '').split(',').filter(Boolean)
  if (existingParam.includes(paramValue.toString())) {
    return removeSearchParamValue(search, paramName, paramValue)
  } else {
    return addSearchParamValue(search, paramName, paramValue)
  }
}

/**
 * Sets (replaces) the search parameter with the value given
 * @param search The current search string
 * @param paramName The query parameters name
 * @param paramValue The query parameters value
 */
export function setSearchParamValue(
  search: string,
  paramName: string,
  paramValue: string | number | Array<string> | Array<number>,
) {
  const searchParams = new URLSearchParams(search)
  if (Array.isArray(paramValue)) {
    searchParams.delete(paramName)
    paramValue.forEach(val => searchParams.append(paramName, val.toString()))
  } else {
    searchParams.set(paramName, paramValue.toString())
  }

  return searchParams.toString()
}

/**
 * Extension of setSearchParamValue that takes an array of param objects
 * @param search The current search string
 * @param params Array of query parameters to set with paramName and paramValue
 * @returns The new search string
 */
export function setManySearchParamValues(
  search: string,
  params: Array<{paramName: string, paramValue: string | number | Array<string> | Array<number> | undefined}>,
): string {
  const searchParams = new URLSearchParams(search)
  params.forEach(param => {
    if (param.paramValue === undefined) {
      searchParams.delete(param.paramName)
      return
    }

    if (Array.isArray(param.paramValue)) {
      searchParams.delete(param.paramName)
      param.paramValue.forEach(val => searchParams.append(param.paramName, val.toString()))
    } else {
      searchParams.set(param.paramName, param.paramValue.toString())
    }
  })
  return searchParams.toString()
}

/**
 * Deletes all values for a given parameter key from the search string
 * @param search The current search string
 * @param paramsToRemove List of search string keys to remove
 */
export function deleteSearchParams(search: string, ...paramsToRemove: Array<string>) {
  const searchParams = new URLSearchParams(search)
  paramsToRemove.forEach(param => {
    searchParams.delete(param)
  })

  return searchParams.toString()
}

export function validateDestinationSearchParams(params: Record<string, string>) {
  return !!(
    params[queryKeyAdults] &&
    params[queryKeyChildren] &&
    params[queryKeyDestinationName] &&
    params[queryKeyDestinationId]
  )
}
export function validateLandmarkSearchParams(params: Record<string, string>) {
  return !!(
    params[queryKeyAdults] &&
    params[queryKeyChildren] &&
    params[queryKeyLandmarkName] &&
    params[queryKeyLandmarkId]
  )
}

export function validatePropertySearchParams(params: Record<string, string>) {
  return !!(
    params[queryKeyAdults] &&
    params[queryKeyChildren] &&
    params[queryKeyPropertyName] &&
    params[queryKeyPropertyId]
  )
}

export function validateBoundsSearchParams(params: Record<string, string>) {
  return !!(
    params[queryKeyAdults] &&
    params[queryKeyChildren] &&
    params[queryKeyBounds]
  )
}

export function getSearchItemFromURLSearchParams(urlSearchParams: URLSearchParams): App.SearchItem | undefined {
  const destinationName = urlSearchParams.get('destinationName')
  const destinationId = urlSearchParams.get('destinationId')

  if (destinationId && destinationName) {
    return {
      searchType: 'destination',
      value: destinationId,
      format: {
        mainText: destinationName,
      },
    }
  }

  const propertyName = urlSearchParams.get('propertyName')
  const propertyId = urlSearchParams.get('propertyId')

  if (propertyId && propertyName) {
    return {
      searchType: 'property',
      value: propertyId,
      format: {
        mainText: propertyName,
      },
    }
  }

  const experienceName = urlSearchParams.get('experienceName')
  const experienceId = urlSearchParams.get('experienceId')

  if (experienceId && experienceName) {
    return {
      searchType: 'experience',
      value: experienceId,
      format: {
        mainText: experienceName,
      },
    }
  }

  const landmarkName = urlSearchParams.get('landmarkName')
  const landmarkId = urlSearchParams.get('landmarkId')

  if (landmarkId && landmarkName) {
    return {
      searchType: 'landmark',
      value: landmarkId,
      format: {
        mainText: landmarkName,
      },
    }
  }

  const bounds = urlSearchParams.get('bounds')

  if (bounds) {
    return {
      searchType: 'bounds',
      value: bounds,
      format: {
        mainText: 'Search area',
      },
    }
  }

  return undefined
}

export function getOccupanciesFromURLSearchParams(urlSearchParams: URLSearchParams): Array<App.Occupants> | undefined {
  const roomsAdults: Array<number> = urlSearchParams.get(queryKeyAdults)?.split(',').map(roomAdults => Number(roomAdults)) ?? []

  if (!roomsAdults?.length) return undefined

  const roomsChildrenAges: Array<Array<number>> = urlSearchParams.get(queryKeyChildren)?.split(',')
    .map(roomChildrenAges => roomChildrenAges === 'none' ? [] : roomChildrenAges.split(';').map(childrenAges => Number(childrenAges))) ?? []

  if (roomsChildrenAges?.length !== roomsAdults.length) {
    return roomsAdults.map((adults) => ({ adults }))
  }

  return roomsAdults.map((roomAdults, roomIndex) => {
    const childrenAge = roomsChildrenAges[roomIndex]

    return {
      adults: roomAdults,
      children: childrenAge?.length,
      childrenAge,
    }
  })
}

export function getDestinationIdFromURLSearchParams(urlSearchParams: URLSearchParams): string | undefined {
  return urlSearchParams.get(queryKeyDestinationId) ?? undefined
}

export function getCheckInDateFromURLSearchParams(urlSearchParams: URLSearchParams): Moment.Moment | undefined {
  const checkIn = urlSearchParams.get(queryKeyCheckIn)

  return checkIn ? Moment(checkIn) : undefined
}

export function getCheckOutDateFromURLSearchParams(urlSearchParams: URLSearchParams): Moment.Moment | undefined {
  const checkOut = urlSearchParams.get(queryKeyCheckOut)

  return checkOut ? Moment(checkOut) : undefined
}

export function getPriceLteFromURLSearchParams(urlSearchParams: URLSearchParams): number | undefined {
  return Number(urlSearchParams.get(queryKeyPriceLte))
}

export function getPriceGteFromURLSearchParams(urlSearchParams: URLSearchParams): number | undefined {
  return Number(urlSearchParams.get(queryKeyPriceGte))
}

export function getFlexibleNightsFromURLSearchParams(urlSearchParams: URLSearchParams): string | undefined {
  const flexibleNights = urlSearchParams.get(queryKeyFlexibleNights)

  return flexibleNights ?? undefined
}

export function getFlexibleMonthsFromURLSearchParams(urlSearchParams: URLSearchParams): string | undefined {
  const flexibleMonths = urlSearchParams.get(queryKeyFlexibleMonths)

  return flexibleMonths ?? undefined
}

export function getUserSelectedFlexibleMonthsFromURLSearchParams(urlSearchParams: URLSearchParams): boolean {
  const userSelectedFlexibleMonths = urlSearchParams.get(queryKeyUserSelectedFlexibleMonths)

  return userSelectedFlexibleMonths === 'true'
}

export function getDataSearchOptionIdFromURLSearchParams(urlSearchParams: URLSearchParams): DATE_SEARCH_OPTION_IDS {
  const flexibleNights = urlSearchParams.get(queryKeyFlexibleNights)
  const flexibleMonths = urlSearchParams.get(queryKeyFlexibleMonths)
  if (!!flexibleMonths && !!flexibleNights) {
    return DATE_SEARCH_OPTION_IDS.FLEXIBLE
  }
  const checkIn = urlSearchParams.get(queryKeyCheckIn)
  const checkOut = urlSearchParams.get(queryKeyCheckOut)
  if (!!checkIn && !!checkOut) {
    return DATE_SEARCH_OPTION_IDS.SPECIFIC
  }
  return DATE_SEARCH_OPTION_IDS.ANYTIME
}

export function getPageFromURLSearchParams(urlSearchParams: URLSearchParams): number | undefined {
  return Number(urlSearchParams.get(queryKeyPages))
}

export function getLocationsFromURLSearchParams(urlSearchParams: URLSearchParams): Array<string> {
  return urlSearchParams.get(queryKeyLocations)?.split(',') ?? []
}

export function getHolidayTypesFromURLSearchParams(urlSearchParams: URLSearchParams): Array<string> {
  return urlSearchParams.get(queryKeyHolidayTypes)?.split(',') ?? []
}

export function getAmenitiesFromURLSearchParams(urlSearchParams: URLSearchParams): Array<string> {
  return urlSearchParams.get(queryKeyAmenities)?.split(',') ?? []
}

export function getPropertyTypesFromURLSearchParams(urlSearchParams: URLSearchParams): Array<string> {
  return urlSearchParams.get(queryKeyPropertyTypes)?.split(',') ?? []
}

export function getSelectedOfferIdsFromURLSearchParams(urlSearchParams: URLSearchParams): Array<string> {
  return urlSearchParams.get(queryKeySelectedOfferIds)?.split(',') ?? []
}

export function getInclusionsFromURLSearchParams(urlSearchParams: URLSearchParams): Array<string> {
  return urlSearchParams.get(queryKeyInclusions)?.split(',') ?? []
}

export function getMapPinCountFromURLSearchParams(urlSearchParams: URLSearchParams): number | undefined {
  return Number(urlSearchParams.get(queryKeyMapPinCount))
}

export function propertyToDestinationSearch(search: string, destination?: App.Place) {
  if (!destination) return search
  return setManySearchParamValues(deleteSearchParams(search, 'propertyName', 'propertyId'),
    [
      { paramName: 'destinationName', paramValue: destination.name },
      { paramName: 'destinationId', paramValue: destination.id },
    ],
  )
}

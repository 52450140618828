import { paths } from '@luxuryescapes/contract-business'
import requestUtils from 'api/requestUtils'

type BusinessCardGetResponse = paths['/api/business-traveller/businesses/{businessId}/employees/{employeeId}/cards']['get']['responses']['200']['content']['application/json']

interface Params {
  businessId: string
  employeeId: string
}

async function requestGetBusinessCards(params: Params) {
  const { businessId, employeeId } = params

  try {
    const response = await requestUtils.get<BusinessCardGetResponse>(
      `/api/business-traveller/businesses/${businessId}/employees/${employeeId}/cards?without_brand=1`,
      { credentials: 'include' },
    )
    return response.result
  } catch (error) {
    return Promise.reject(error)
  }
}

export default requestGetBusinessCards

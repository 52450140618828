import HttpStatus from 'components/Common/HttpStatus'
import Error404Page from 'components/Pages/Error404Page'
import loadable from '@loadable/component'
import { buildPath } from 'lib/url/routeUtils'
import React from 'react'
import { match, Route, Switch } from 'react-router'
import {
  BUSINESS_TRAVELLER_BUSINESS_SIGNUP_COMPLETE_ROUTE,
  BUSINESS_TRAVELLER_BUSINESS_SIGNUP_ROUTE,
  BUSINESS_TRAVELLER_EMPLOYEE_SETUP_ROUTE,
  BUSINESS_TRAVELLER_ROOT_ROUTE,
  BUSINESS_TRAVELLER_APPROVE_ORDER_ROUTE,
  BUSINESS_TRAVELLER_FAQ_ROUTE,
} from './businessTravellerRoutes'
import { connect } from 'react-redux'
import { selectLoggedIn } from 'selectors/accountSelectors'
import config from 'constants/config'
import AuthRoute from 'components/App/Routes/AuthRoute'

const BusinessTravellerLandingPage = loadable(() => import(/* webpackChunkName: "BusinessTravellerLandingPage" */ 'businessTraveller/pages/landing/BusinessTravellerLandingPage'))
const BusinessTravellerSalesLandingPage = loadable(() => import(/* webpackChunkName: "BusinessTravellerSalesLandingPage" */ 'businessTraveller/pages/sales-landing/BusinessTravellerSalesLandingPage'))
const BusinessTravellerBusinessSignupPage = loadable(() => import(/* webpackChunkName: "BusinessTravellerBusinessSignupPage" */ 'businessTraveller/pages/business-signup/BusinessTravellerBusinessSignupPage'))
const BusinessTravellerBusinessSignupCompletePage = loadable(() => import(/* webpackChunkName: "BusinessTravellerBusinessSignupCompletePage" */ 'businessTraveller/pages/business-signup-complete/BusinessTravellerBusinessSignupCompletePage'))
const BusinessTravellerEmployeeSetupPage = loadable(() => import(/* webpackChunkName: "BusinessTravellerEmployeeSetupPage" */ 'businessTraveller/pages/employee-setup/BusinessTravellerEmployeeSetupPage'))
const BusinessTravellerApproveOrderPage = loadable(() => import(/* webpackChunkName: "BusinessTravellerApproveOrderPage" */ 'businessTraveller/pages/approve-order/BusinessTravellerApproveOrderPage'))
const BusinessTravellerFaqPage = loadable(() => import(/* webpackChunkName: "BusinessTravellerFaqPage" */ 'businessTraveller/pages/faq/BusinessTravellerFaqPage'))

interface MappedStateProps {
  isLoggedIn?: boolean
}

interface Props {
  match: match
}

function BusinessTravellerPageRoutes(props: Props & MappedStateProps) {
  const {
    isLoggedIn,
    match,
  } = props

  return <Switch>
    <Route path={buildPath(match, BUSINESS_TRAVELLER_ROOT_ROUTE)}>
      {config.businessTraveller.currentAccountMode === 'business' && <Switch>
        {isLoggedIn && <Route exact path={buildPath(match, BUSINESS_TRAVELLER_ROOT_ROUTE)} component={BusinessTravellerLandingPage} />}
        {!isLoggedIn && <Route exact path={buildPath(match, BUSINESS_TRAVELLER_ROOT_ROUTE)} component={BusinessTravellerSalesLandingPage} />}

        {config.BUSINESS_TRAVELLER_ACCOMMODATION_APPROVAL_ENABLED && <AuthRoute
          showPopup
          path={buildPath(match, BUSINESS_TRAVELLER_APPROVE_ORDER_ROUTE)}
          component={BusinessTravellerApproveOrderPage}
        />}

        <Route exact path={buildPath(match, BUSINESS_TRAVELLER_BUSINESS_SIGNUP_ROUTE)} component={BusinessTravellerBusinessSignupPage}/>
        <Route exact path={buildPath(match, BUSINESS_TRAVELLER_BUSINESS_SIGNUP_COMPLETE_ROUTE)} component={BusinessTravellerBusinessSignupCompletePage} />
        <Route exact path={buildPath(match, BUSINESS_TRAVELLER_EMPLOYEE_SETUP_ROUTE)} component={BusinessTravellerEmployeeSetupPage} />
        <Route exact path={buildPath(match, BUSINESS_TRAVELLER_FAQ_ROUTE)} component={BusinessTravellerFaqPage} />

        <Route>
          <HttpStatus code={404}>
            <Error404Page />
          </HttpStatus>
        </Route>
      </Switch>}
    </Route>
    {/*
      Code below will match all non-declared routes
      It will also stop all routes loaded after it from rendering
      if your route isn't rendering on business traveller, make sure it
      is loaded before this component
    */}
    <Route>
      <HttpStatus code={404}>
        <Error404Page />
      </HttpStatus>
    </Route>
  </Switch>
}

export default connect <MappedStateProps, never, Props, App.State>((state) => {
  return {
    isLoggedIn: selectLoggedIn(state),
  }
})(BusinessTravellerPageRoutes)

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAngleLeftIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m11.2899 11.9999 3.54-3.54a.9998.9998 0 0 0 0-1.41.9988.9988 0 0 0-.71-.2957.9994.9994 0 0 0-.71.2957l-4.24 4.24a1.0004 1.0004 0 0 0-.2188 1.0946.999.999 0 0 0 .2188.3254L13.41 17a1.0011 1.0011 0 0 0 .71.29 1 1 0 0 0 .71-.29 1 1 0 0 0 0-1.41l-3.54-3.5901Z"/>
  </SvgIcon>
}

export default LineAngleLeftIcon

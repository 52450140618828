import { pluralizeToString } from 'lib/string/pluralize'
import offerTypeConfig from '../config/offer'
import { PublicOfferV2, components } from '@luxuryescapes/contract-public-offer'
import { min } from 'lib/array/arrayUtils'

import imageMap from './imageMap'

import config from 'constants/config'
import { getTileInclusions, mapTileInclusions } from './hotelOfferMap'
import { saleUnitLongReplacements } from 'constants/offer'

type ContractSchema = components['schemas']
type ReserveForZero = ContractSchema['leHotelOffer']['reserveForZero']

type UrgencyTag = components['schemas']['urgencyTag']
function urgencyTagMap(urgencyTag: UrgencyTag): App.UrgencyTag {
  return {
    type: urgencyTag.type,
    message: urgencyTag.message ?? undefined,
  }
}

function mapSchedule(schedule: PublicOfferV2.Schedule): App.OfferSchedule {
  return {
    start: schedule.start,
    end: schedule.end,
  }
}

function mapReserveForZero(reserveForZero: ReserveForZero): { minDays: number } {
  return {
    minDays: reserveForZero?.min_days ?? 0,
  }
}

export function geoDataMap(geoData: PublicOfferV2.GeoData): App.OfferPropertyGeoData {
  return {
    continentCode: geoData.continentCode,
    country: geoData.country,
    countryCode: geoData.countryCode,
    locality: geoData.locality,
    route: geoData.route,
    streetNumber: geoData.streetNumber,
    administrativeAreaLevel1: geoData.administrativeAreaLevel1,
    administrativeAreaLevel2: geoData.administrativeAreaLevel2,
    administrativeAreaLevel3: geoData.administrativeAreaLevel3,
    administrativeAreaLevel4: geoData.administrativeAreaLevel4,
    administrativeAreaLevel5: geoData.administrativeAreaLevel5,
    placeId: geoData.placeId,
  }
}

type Tour = components['schemas']['v1Tour']
function mapTour(serverTour: Tour): App.PackageTour {
  return {
    id: serverTour.id,
    name: serverTour.name,
    itinerary: serverTour.itinerary,
    reviews: serverTour.reviews ?? [],
    latitude: serverTour.location.latitude,
    longitude: serverTour.location.longitude,
    timezone: serverTour.timezone,
    timezoneOffset: serverTour.timezoneOffset,
    geoData: geoDataMap(serverTour.geoData),
    logoImageId: serverTour.logo?.id,
  }
}

function packagePartnershipMap(serverPartnerships?: Array<PublicOfferV2.PackagePartnership>): App.PackagePartnership | undefined {
  const firstPartnership = serverPartnerships?.[0]

  if (firstPartnership) {
    return {
      bonusPoints: firstPartnership.bonusPoints ?? 0,
      bonusDescription: firstPartnership.bonusDescription || '',
      localRewardConversionRate: firstPartnership.localRewardConversionRate ?? 1,
    }
  }
}

function mapTourPackageOption(
  serverOption: PublicOfferV2.LeTourOption,
  serverOffer: PublicOfferV2.LeTourOffer,
): App.TourPackage {
  const serverPkg = serverOffer.packages[serverOption.fkPackageId]

  return {
    id: serverPkg.id,
    lePackageId: serverPkg.id,
    offerId: serverOffer.id,
    description: serverPkg.inclusions.description,
    sortOrder: serverPkg.sortOrder,
    roomOccupancy: serverPkg.includedGuestsLabel,
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    duration: serverOption.duration,
    price: serverOption.price ?? 0,
    memberPrice: serverOption.luxPlusPrice ?? 0,
    value: serverOption.value ?? 0,
    memberValue: 0,
    taxesAndFees: serverOption.totals?.taxesAndFees ?? 0,
    propertyFees: serverOption.totals?.propertyFees ?? 0,
    bundleDiscountPercent: 0,
    discountPercent: 0,
    surcharge: 0,
    trackingPrice: serverOption.trackingPrice,
    name: serverPkg.name,
    partnership: packagePartnershipMap(serverPkg.partnerships),
    uniqueKey: serverPkg.id,
    tour: mapTour(serverOffer.tour),
    tourOption: {
      id: serverOption.id,
      name: serverOption.name,
    },
    durationLabel: pluralizeToString('Day', serverOption.duration),
    bonusInclusions: [],
    inclusionsAmount: 0,
  }
}

export function tourOfferMap(serverOffer: PublicOfferV2.LeTourOffer, regionCode: string): App.TourOffer {
  const typeConfig = offerTypeConfig.tour
  const packages = serverOffer.options.map(option => mapTourPackageOption(option, serverOffer))

  const mappedTileInclusions = mapTileInclusions(serverOffer.inclusions.tileInclusions)
  const tileInclusions = getTileInclusions(mappedTileInclusions)

  const offer: App.TourOffer = {
    type: typeConfig.type,
    productType: typeConfig.productType,
    walledGarden: typeConfig.walledGarden,
    hasBuyNowBookLater: typeConfig.hasBuyNowBookLater && !serverOffer.bundledWithFlightsOnly,
    hasBuyNowBookLaterPackages: false,
    parentType: typeConfig.parentType,
    typeLabel: typeConfig.typeLabel,
    overlayImageLabel: typeConfig.overlayImageLabel,
    analyticsType: typeConfig.analyticsType,
    id: serverOffer.id,
    name: serverOffer.name,
    experiencesInFlow: serverOffer.experiencesInFlow,
    experiencesCurated: serverOffer.experiencesCurated,
    showOnlyExperiencesCurated: serverOffer.showOnlyExperiencesCurated,
    location: serverOffer.location.description,
    locations: serverOffer.tags.location ?? [],
    description: serverOffer.copy.description ?? '',
    highlights: serverOffer.copy.highlights ?? '',
    holidayTypes: serverOffer.tags.holidayTypes ?? [],
    whatWeLike: serverOffer.copy.whatWeLike,
    facilities: serverOffer.copy.facilities,
    finePrint: serverOffer.copy.finePrint,
    gettingThere: serverOffer.copy.gettingThere,
    insuranceCountries: serverOffer.insurance.countries,
    isSoldOut: serverOffer.isSoldOut ?? false,
    bookByDate: serverOffer.schedules?.bookBy?.end,
    travelToDate: serverOffer.schedules?.travelBy?.end,
    travelFromDate: serverOffer.schedules?.travelBy?.start,
    slug: serverOffer.slug,
    canEarnPartnershipPoints: serverOffer.partnerships.length > 0,
    durationLabel: serverOffer.durationLabel ?? '',
    tileDurationLabel: serverOffer.durationLabel ?? '',
    images: serverOffer.images.map(imageMap),
    image: imageMap(serverOffer.images[0]),
    visibilitySchedules: {
      world: serverOffer.schedules?.listVisibility ? mapSchedule(serverOffer.schedules.listVisibility) : undefined,
      [regionCode]: serverOffer.schedules?.listVisibility ? mapSchedule(serverOffer.schedules.listVisibility) : undefined,
    },
    onlinePurchaseSchedule: serverOffer.schedules?.onlinePurchase ? mapSchedule(serverOffer.schedules.onlinePurchase) : undefined,
    availabilitySchedule: serverOffer.schedules?.availability ? mapSchedule(serverOffer.schedules.availability) : undefined,
    vimeoVideoId: serverOffer.video?.id,
    locationHeading: serverOffer.location.heading,
    locationSubheading: serverOffer.location.subheading,
    noIndex: serverOffer.noIndex,
    saleUnit: serverOffer.saleUnit,
    saleUnitLong: saleUnitLongReplacements[serverOffer.saleUnit] ?? serverOffer.saleUnit,
    urgencyTags: serverOffer.tags.urgency.map(urgencyTagMap),
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    exclusiveExtrasLarge: serverOffer.inclusions.description,
    inclusions: serverOffer.inclusions.description,
    additionalDescription: serverOffer.copy.additionalDescription ?? '',
    tileInclusions,
    tileInclusionsHeading: serverOffer.inclusions.tileHeading,
    daysBeforeCheckInChangesDisallowed: serverOffer.daysBeforeCheckInChangesDisallowed,
    flightPrices: serverOffer.flights?.[0]?.prices ?? {},
    flightDestinationPort: serverOffer.flights?.[0]?.destinationCode,
    flightsMaxArrivalTime: serverOffer.flights?.[0]?.latestDestinationArrivalTime,
    flightsMinReturningDepartureTime: serverOffer.flights?.[0]?.earliestDestinationDepartureTime,
    flightCacheDisabled: serverOffer.flights?.[0]?.cacheDisabled,
    flightsWarningHeadline: serverOffer.flights?.[0]?.warning?.heading,
    flightsWarningPopupBody: serverOffer.flights?.[0]?.warning?.description,
    flightsWarningEnabled: !!serverOffer.flights?.[0]?.warning,
    offerFlightsEnabled: config.FLIGHT_ENABLED && (serverOffer.flights?.length ?? 0) > 0,
    badge: config.BRAND === 'luxuryescapes' ? serverOffer.badge : undefined,
    bundledWithFlightsOnly: serverOffer.bundledWithFlightsOnly,
    whitelistedCarrierCodes: serverOffer.whitelistedCarrierCodes ?? [],
    disableDeposit: serverOffer.disableDeposit,
    depositThresholds: serverOffer.depositThresholds,
    packages,
    lowestPricePackage: min(packages, pkg => pkg.price + (pkg.surcharge ?? 0)),
    defaultOptions: serverOffer.lowestOptions.map((o) => o.id),
    numberOfDateChangesAllowed: serverOffer.numberOfDateChanges ?? 'Unlimited',
    packageUpgradesAllowed: false,
    disableBestPriceGuarantee: serverOffer.disableBestPriceGuarantee,
    minDuration: Math.min(...serverOffer.options.map(o => o.duration)),
    reserveForZero: serverOffer.reserveForZero ? mapReserveForZero(serverOffer.reserveForZero) : undefined,
    startLocation: serverOffer.startLocation,
    endLocation: serverOffer.endLocation,
    vendorName: serverOffer.vendorName,
    vendorVehicle: serverOffer.vendorVehicle,
    preHeader: serverOffer.preheader,
    cabinTypes: serverOffer.cabinTypes,
    locationsVisited: serverOffer.locationsVisited,
    onHoldSettings: serverOffer.tour.onHold,
    latestDepartureDateChangeAllowed: serverOffer.tour.latestDepartureDateChangeAllowed,
    dateChangeSettings: serverOffer.tour.dateChange,
    property: undefined,
    isDiscountPillHidden: false,
    luxPlus: {},
    isAgentHubExclusive: config.agentHub.isEnabled && Boolean(serverOffer.isAgentHubExclusive),
  }

  if (serverOffer.tags.holidayTypes?.includes('Cruises')) {
    offer.productType = 'cruise-flash'
  } else if (serverOffer.tags.campaigns?.includes('Ultra Lux')) {
    offer.productType = 'ultra_lux_tour'
  }

  return offer
}

export interface Recommendation {
  offer_id: string;
  cheapest_date: string;
  check_in_date_price: number;
  check_in_date_value: number;
  lowest_price: number;
}

export function offerRecommendationMap(recommendation: Recommendation): App.Recommendation {
  return {
    offerId: recommendation.offer_id,
    cheapestDate: recommendation?.cheapest_date,
    checkInDatePrice: recommendation?.check_in_date_price,
    checkInDateValue: recommendation?.check_in_date_value,
    lowestPrice: recommendation?.lowest_price,
  }
}

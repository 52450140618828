import config from 'constants/config'
import { useIsMobileDevice } from './useIsMobileDevice'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { useAppSelector } from './reduxHooks'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'

export function useRenderWhatsApp(region: string) {
  const isMobileUserAgent = useIsMobileDevice()
  const supportAssistantEnabled = useAppSelector(isSupportAssistantEnabled)
  // hide whatsapp on desktop for regions where support assistant is enabled
  const hideWhatsApp = supportAssistantEnabled && !isMobileUserAgent

  return config.WHATS_APP_NUMBER && !hideWhatsApp && isEnabledForFeature(config.WHATS_APP_COUNTRIES, region)
}

import TextButton from 'components/Luxkit/Button/TextButton'
import React, { useContext, useRef } from 'react'
import ExperienceSearchCategorySelect from './ExperienceSearchBar/ExperienceSearchCategorySelect'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import { pushWithRegion } from 'actions/NavigationActions'
import { SearchMenuStates } from 'components/Search/type'
import { ANYWHERE_SEARCH_ITEM, SEARCH_VERTICALS } from 'constants/search'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { QueryStringSearchParams, replaceExperienceQueryString } from 'lib/experiences/experienceUtils'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import GlobalSearchTrackingContext from 'contexts/GlobalSearch/GlobalSearchTracking'
import { OFFER_TYPE_EXPERIENCE } from 'constants/offer'
import { EXPERIENCE_LOCATION_SEARCH_PLACEHOLDER_LABEL, EXPERIENCE_LOCATION_SEARCH_TITLE_LABEL, EXPERIENCE_TYPEAHEAD_SEARCH_TYPES } from 'constants/experience'
import SearchLocationInput from 'components/Search/SearchForm/SearchLocationInput/SearchLocationInput'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'

const SearchContainer = styled(CSSBreakpoint)`
  display: grid;
  gap: ${rem(12)};
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) ${rem(112)};
`

const SearchButton = styled(TextButton)`
  height: 100%;
`

interface Props {
  className?: string;
  onSearch?: () => void;
}

function ExperienceSearchForm(props: Props) {
  const { className, onSearch } = props
  const formRef = useRef<HTMLFormElement>(null)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const onSearchEvent = useContext(GlobalSearchTrackingContext)
  const dispatch = useAppDispatch()

  const {
    searchItem,
    experienceCategories,
    activeMenu,
    eventAnalytics,
  } = useContext(GlobalSearchStateContext)
  const toBeSearchedItem = searchItem ?? ANYWHERE_SEARCH_ITEM
  const fetchingExperience = useAppSelector(state => state.experience.fetchingExperiences[toBeSearchedItem.value])

  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (activeMenu === SearchMenuStates.ExperienceCategories && !searchItem) {
      searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: SearchMenuStates.Locations })
      return
    } else if (activeMenu === SearchMenuStates.Locations && !experienceCategories?.length) {
      searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: SearchMenuStates.ExperienceCategories })
      return
    } else {
      searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: SearchMenuStates.Closed })
    }

    const query: QueryStringSearchParams = {
      categories: experienceCategories,
    }

    if (toBeSearchedItem.searchType === 'experience') {
      query.experienceId = toBeSearchedItem.value
      query.experienceName = toBeSearchedItem.format.mainText
    } else {
      query.destinationId = toBeSearchedItem.value
      query.destinationName = toBeSearchedItem.format.mainText
    }

    const queryString = replaceExperienceQueryString(query)

    if (eventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        eventAnalytics.contextLocation,
        eventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItem,
          searchVerticals: new Set([SEARCH_VERTICALS.EXPERIENCES]),
        },
        ),
      ))
    }

    dispatch(pushWithRegion(`/search/experiences?${queryString}`))

    if (onSearchEvent) {
      const params = {
        searchItem,
        vertical: OFFER_TYPE_EXPERIENCE,
      }
      onSearchEvent('submit-button', params)
    }
    onSearch?.()
  }

  return (
    <form className={className} onSubmit={onSearchSubmit} ref={formRef}>
      <SearchContainer min="tablet">
        <SearchLocationInput
          placeTypes={EXPERIENCE_TYPEAHEAD_SEARCH_TYPES}
          label={EXPERIENCE_LOCATION_SEARCH_TITLE_LABEL}
          placeholder={EXPERIENCE_LOCATION_SEARCH_PLACEHOLDER_LABEL}
          searchVertical="experience"
          variant="dropdown"
        />
        <ExperienceSearchCategorySelect />
        <SearchButton kind="primary" type="submit" size="large" disabled={fetchingExperience}>
          Search
        </SearchButton>
      </SearchContainer>
    </form>
  )
}

export default ExperienceSearchForm

import FormatCurrency from 'components/Common/FormatCurrency'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import TextLink from 'components/Luxkit/TextLink'
import Caption from 'components/Luxkit/Typography/Caption'
import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'

interface Props {
  creditValue: number
  size: 'M' | 'L'
  /**
   * Controls whether the messaging to state the credit is an estimate or exact value.
   */
  type: 'exact' | 'estimate'
  onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

function PriceRowBusinessTravellerCredit(props: Props) {
  const {
    creditValue,
    size,
    type,
    onClick,
  } = props

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()

    onClick(event)
  }, [onClick])

  return <Caption
    className={PriceRowBusinessTravellerCredit.name}
    variant={SIZES_CAPTION_VARIANTS[size]}
    weight="bold"
    colour="highlight-secondary"
  >
    <TextLink
      variant="highlight"
      underline={false}
      endIcon={<SolidInfoCircleIcon colour="primary" />}
      onClick={handleClick}
    >
      {type === 'estimate' && <>Earn up to <FormatCurrency value={creditValue} format="roundedDollar" /> credits</>}
      {type === 'exact' && <>Earn <FormatCurrency value={creditValue} format="roundedDollar" /> credits</>}
    </TextLink>
  </Caption>
}

export default PriceRowBusinessTravellerCredit

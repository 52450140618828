import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import config from 'constants/config'
import { TOUR_STYLED_PRODUCT_TYPE_TITLES } from 'constants/tours'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import React, { ComponentProps, useCallback } from 'react'

export type TourStyledSignatureSeriesProductTypeInfoModalResult = 'primary-action' | undefined

type InheritedModalProps = Pick<ComponentProps<typeof Modal>, 'primaryActionText' | 'primaryActionTo'>

interface Props extends InheritedModalProps {
}

function TourStyledSignatureSeriesProductTypeInfoModal({
  primaryActionText,
  primaryActionTo,
}: Props) {
  const modalElement = useModalElementContext<TourStyledSignatureSeriesProductTypeInfoModalResult>()

  const handlePrimaryClick = useCallback(() => {
    modalElement.resolve('primary-action')
  }, [modalElement])

  return <Modal
    size="L"
    title={<>{config.brandConfig.name} Tour Styles</>}
    subtitle={TOUR_STYLED_PRODUCT_TYPE_TITLES.signature_series_tour}
    primaryActionText={primaryActionText}
    primaryActionTo={primaryActionTo}
    onPrimaryActionClick={!primaryActionTo ? handlePrimaryClick : undefined}
    secondaryActionText="Learn more"
    secondaryActionTo="/campaign/signature-series"
  >
    <VerticalSpacer gap={24}>
      <BodyText variant="medium" colour="neutral-two">
        These celebrity-hosted, ultra-exclusive journeys are designed and led by some of Australia’s most-loved personalities. With once-in-a-lifetime itineraries, Luxury Escapes’ Signature Series take you to the world’s top destinations on the adventure of your dreams.
      </BodyText>
      <Group
        direction="vertical"
        tabletDirection="horizontal"
        tabletHorizontalAlign="stretch"
        gap={24}
      >
        <VerticalSpacer gap={4}>
          <BodyText variant="medium" weight="bold" colour="neutral-one">
            One Departure Only
          </BodyText>
          <BodyText variant="medium" colour="neutral-two">
            Each tour has just one departure date, ensuring that every trip is a unique, once-in-a-lifetime experience.
          </BodyText>
        </VerticalSpacer>
        <VerticalSpacer gap={4}>
          <BodyText variant="medium" weight="bold" colour="neutral-one">
            Iconic Itineraries
          </BodyText>
          <BodyText variant="medium" colour="neutral-two">
            Travellers will enjoy iconic bucket-list experiences in each destination, capturing all the highlights alongside exclusive, money-can’t-buy moments that go beyond ordinary travel expectations.
          </BodyText>
        </VerticalSpacer>
        <VerticalSpacer gap={4}>
          <BodyText variant="medium" weight="bold" colour="neutral-one">
            Unmatched Luxury
          </BodyText>
          <BodyText variant="medium" colour="neutral-two">
            All hotels and resorts are meticulously handpicked for their exceptional quality, comfort, location, and service. These properties have been chosen to elevate your overall travel experience.
          </BodyText>
        </VerticalSpacer>
      </Group>
    </VerticalSpacer>
  </Modal>
}

export default TourStyledSignatureSeriesProductTypeInfoModal

import React, { memo } from 'react'
import Image from 'components/Common/Image'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Clickable from 'components/Common/Clickable'

const OfferBadgeLink = styled(Clickable)`
  display: none;
  border-radius: ${props => props.theme.borderRadius.XS};
  overflow: hidden;
  cursor: pointer;

  ${mediaQueryUp.desktop} {
    display: block;
  }
`
interface Props {
  badge: App.OfferBadge;
  className?: string;
  dimensions?: {
    width: number;
    height: number;
  }
}

function OfferBadge(props: Props) {
  const { badge, className, dimensions } = props
  const {
    url = '',
    image,
    tagText,
  } = badge

  const goToLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (url) {
      e.preventDefault()
      window.open(url)
    }
  }

  return (
    <OfferBadgeLink onClick={goToLink} className={className}>
      <Image
        width={dimensions?.width || 130}
        height={dimensions?.height || 130}
        enhancedFormats={false}
        id={image}
        alt={tagText}
      />
    </OfferBadgeLink>
  )
}

export default memo(OfferBadge)

import Modal from 'components/Luxkit/Modal/Modal'
import React, { useCallback, useMemo, useState } from 'react'
import { PhonePrefixCountry } from './PhoneInput'
import PhonePrefixItem from './PhonePrefixItem'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import SearchFormModalTextField from 'components/SearchV2/Components/SearchFormField/SearchFormModalTextField'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  countries: Array<PhonePrefixCountry>;
  selectedCountryCode?: string;
}

function PhoneInputPrefixModal(props: Props) {
  const { countries, selectedCountryCode } = props

  const modalContext = useModalElementContext<PhonePrefixCountry | undefined>()
  const [search, setSearch] = useState<string>('')
  const onSelect = useCallback((prefix: PhonePrefixCountry) => {
    modalContext.resolve(prefix)
  }, [modalContext])

  const hiddenPrefixes = useMemo(() => {
    if (search) {
      const sanitizedPrefix = search.replace('+', '')
      const hiddenCountries = countries.filter(option =>
        !option.name.toLowerCase().includes(sanitizedPrefix.toLowerCase()) &&
        !option.dialCode.startsWith(sanitizedPrefix),
      )
      return new Set(hiddenCountries)
    }
    return new Set()
  }, [countries, search])

  return <Modal title="Country code" height="max">
    <VerticalSpacer gap={24}>
      <SearchFormModalTextField
        onChange={setSearch}
        placeholder="Search by country or code"
        value={search}
        onClear={() => setSearch('')}
      />
      <div>
        {hiddenPrefixes.size === countries.length && <BodyText variant="medium">No matching dial codes</BodyText>}
        {countries.map(country => (
          <PhonePrefixItem
            key={country.iso2}
            selected={selectedCountryCode === country.iso2.toLocaleUpperCase()}
            country={country}
            onSelect={onSelect}
            hidden={hiddenPrefixes.has(country)}
          />
        ))}
      </div>
    </VerticalSpacer>
  </Modal>
}

export default PhoneInputPrefixModal

import { useMemo } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import offerPageURL from 'lib/offer/offerPageURL'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { getImmersiveTripId } from 'tripPlanner/selectors'
import config from 'constants/config'
import { buildSearchParamsFromFilters, buildSuggestedDatesParamsKey, convertFlexibleDatesToTravelDates } from 'lib/search/searchUtils'

function useOfferUrl(
  offer: App.AnyOffer,
  options: { filters?: App.OfferListFilters, bundledOfferId?: string, offerLinkIncludesFilters?: boolean },
): string {
  const { filters, bundledOfferId, offerLinkIncludesFilters } = options
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(filters?.flexibleMonths, filters?.flexibleNights, filters?.rooms)
  const suggestedDates = useAppSelector(state => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))
  const tripId = useAppSelector(state => getImmersiveTripId(state))

  return useMemo(() => {
    let offerUrlSuggestedDates = suggestedDates
    if (!suggestedDates?.checkIn && !suggestedDates?.checkOut && filters?.flexibleNights && filters?.flexibleMonths) {
      const { checkIn, checkOut } = convertFlexibleDatesToTravelDates(filters.flexibleNights as FLEXIBLE_DURATION_RANGE, filters.flexibleMonths)
      offerUrlSuggestedDates = {
        ...suggestedDates,
        checkIn,
        checkOut,
      }
    }
    const searchParams = offerLinkIncludesFilters ? buildSearchParamsFromFilters(filters, offerUrlSuggestedDates) : new URLSearchParams()
    if (config.OPEN_NEW_TAB_OFFER_CLICK && tripId) {
      // Make sure immersive trip header persists when opening offer links in new tab
      searchParams.set('tripId', tripId)
    }
    if (bundledOfferId) {
      searchParams.set('bundledOfferId', bundledOfferId)
    }

    const departureMonths = filters?.departureMonths?.filter(Boolean) ?? []
    if (departureMonths.length > 0) {
      departureMonths.forEach(month => {
        searchParams.append('departureMonths', month)
      })
    }
    return offerPageURL(offer, searchParams)
  }, [filters, offer, bundledOfferId, offerLinkIncludesFilters, tripId, suggestedDates])
}

export default useOfferUrl

import React, { CSSProperties, useContext, useRef } from 'react'
import cn from 'clsx'
import styled, { keyframes } from 'styled-components'
import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const Box = styled.div`
  position: relative;
  background-color: var(--loading-box-bg-color);
  overflow: hidden;

  &.floating {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    will-change: transform;
    background: linear-gradient(90deg, transparent, var(--loading-box-wave-color), transparent);
    opacity: 0.4;
    animation: ${loading} 1.5s infinite;
  }

  &.paused::after {
    animation-play-state: paused;
  }

  &.colour-neutral {
    --loading-box-bg-color: ${props => props.theme.palette.neutral.default.six};
    --loading-box-wave-color: ${props => props.theme.palette.neutral.default.five};
  }
  &.colour-highlight-secondary {
    --loading-box-bg-color: ${props => props.theme.palette.highlight.secondary.lightForeground};
    --loading-box-wave-color: ${props => props.theme.palette.highlight.secondary.normalBackground};
  }
`

interface Props {
  floating?: boolean;
  /**
   * Manually pause the animation being run, use this when the loader is not visible
   * as it isn't free to be running animations eternally!
   * This will overwrite any context version
   */
  paused?: boolean;
  /**
   * @default neutral
   */
  colour?: 'neutral' | 'highlight-secondary'
  className?: string
  style?: CSSProperties
}

function LoadingBox(props: Props) {
  const { className, style, floating, paused, colour = 'neutral' } = props
  const ref = useRef<HTMLDivElement>(null)
  const playState = useContext(LoaderPlayStateContext)

  return <Box
    ref={ref}
    style={style}
    className={cn(className, `colour-${colour}`, { floating, paused: paused || playState === 'paused' })}
  />
}

export default LoadingBox

import { PAYMENT_OPTIONS } from 'constants/payment'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import getDepositAmountBeforeCreditApplied from './getDepositAmountBeforeCreditApplied'
import getCreditBalanceForCheckoutCurrency from 'checkout/selectors/payment/getCreditBalanceForCheckoutCurrency'
import getDepositServiceFee from './getDepositServiceFee'
import getMerchantFeeAmount from './getMerchantFeeAmount'

const getDepositAmount = createSelector(
  getDepositAmountBeforeCreditApplied,
  getDepositServiceFee,
  isSpoofed,
  (state: App.State) => state.checkout.payment.rebookingID,
  (state: App.State) => state.checkout.payment.useCredit,
  getCreditBalanceForCheckoutCurrency,
  (state: App.State) => getMerchantFeeAmount(state, PAYMENT_OPTIONS.DEPOSIT),
  (depositAmountBeforeCreditApplied, serviceFee, isSpoofed, rebookingID, useLECredits, creditBalance, merchantFee): number => {
    let depositTotal = depositAmountBeforeCreditApplied + serviceFee + merchantFee

    if (isSpoofed && rebookingID && useLECredits) {
      depositTotal = creditBalance < depositTotal ? depositTotal - creditBalance : 0
    }

    return floatify(depositTotal)
  },
)

export default getDepositAmount

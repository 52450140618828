import moment from 'moment'
import qs from 'qs'
import { CalendarV2 } from '@luxuryescapes/contract-svc-calendar'
import { connectionBuildCancellationPolicies } from '@luxuryescapes/lib-refunds'
import invariant from 'tiny-invariant'

import request from 'api/requestUtils'
import { arrayToObject, countBy, groupBy, min, splitByWeight, sum, uniqueBy } from 'lib/array/arrayUtils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { isBundleOffer } from 'lib/offer/offerTypes'
import { OFFER_TYPE_VILLA } from 'constants/offer'
import { ServerTaxBreakdown, taxBreakdownMap } from 'api/mappers/taxBreakdownMap'
import { addFlightPrice, determineMemberPricing, maybeWithValue, onlyNonZeroFees } from 'lib/offer/pricing'
import { getChannelMarkupValue } from 'lib/channelMarkup/channelMarkup'

const FLIGHT_MAX_DAYS_IN_FUTURE = 310
const maxFlightDate = moment().add(FLIGHT_MAX_DAYS_IN_FUTURE, 'days').toDate()

function getFormatData(value: Array<any>, maps: { [key: number]: Array<CalendarV2.Maps> }): CalendarV2.RateMaps {
  return maps[value.length]?.reduce<CalendarV2.RateMaps>((acc, key, index) => {
    acc[key] = value[index]
    return acc
  }, {} as CalendarV2.RateMaps)
}

export function mapCalendar(
  serverCalendar: CalendarV2.Package,
  packages: Array<App.Package>,
  offer: App.Offer | App.OfferSummary,
  channelMarkup?: App.ChannelMarkup,
): Array<App.Calendar> {
  const flightCacheActive = serverCalendar.flightCacheActive
  const flightsNotAvailable = serverCalendar.flightsNotAvailable
  const offerPackage = packages.find(pkg => pkg.id === serverCalendar.packageId)
  const markup = getChannelMarkupValue(offer.id, offer.type, channelMarkup)

  // The package should always be found, because `requestCalendar` only requests calendars for the packages that are in the packages array
  invariant(offerPackage, 'Package not found in packages')

  const dates = Object.keys(serverCalendar.dates)

  // current issue with server where returns an empty prices array if no available dates
  if (dates.length === 0) {
    return [{
      packageId: serverCalendar.packageId,
      uniqueKey: offerPackage.uniqueKey,
      months: [],
      cheapestDay: undefined,
      cheapestDayWithFlights: undefined,
      flightCacheActive,
      flightsNotAvailable,
    }]
  }

  const mapsData = serverCalendar.maps.reduce<Record<string, any>>((acc, item) => {
    acc[Object.keys(item).length] = item
    return acc
  }, {})

  return serverCalendar.rates.map<App.Calendar>((roomRateId, index) => {
    const pricesByMonth = groupBy(dates, (day) => {
      const currentDay = moment(day)
      return `${currentDay.month()}-${currentDay.year()}`
    }, (day) => ({ day, value: serverCalendar.dates[day][index] }))

    const uniqueKey = getPackageUniqueKey(serverCalendar.packageId, serverCalendar.duration, roomRateId)

    const months = [...pricesByMonth.entries()].map<App.CalendarMonth>(([monthKey, pricesInfo]) => {
      const day1Date = moment(pricesInfo[0].day)
      const monthIndex = day1Date.month()
      const monthName = day1Date.format('MMMM')
      const yearNum = day1Date.year()
      const yearText = yearNum.toString()

      const days = pricesInfo.map<App.CalendarDay>((pricesData) => {
        const dayRate = getFormatData(pricesData.value, mapsData)
        const currentDay = moment(pricesData.day)
        const checkOut = moment(currentDay).add(serverCalendar.duration, 'days')
        const availableRooms = dayRate.availableRooms
        const blackout = 'blackout' in dayRate ? dayRate.blackout : false

        // Price
        const prices = 'prices' in dayRate ? dayRate.prices.map(price => price * markup) : []
        const price = prices.length > 0 ? sum(prices) : 0
        const memberPrices = 'luxPlusPrices' in dayRate ? dayRate.luxPlusPrices : []
        const memberPrice = memberPrices.length > 0 ? sum(memberPrices) : 0

        const hotelValue = 'value' in dayRate ? dayRate.value : 0
        const hotelMemberValue = 'luxPlusBaseValue' in dayRate ? dayRate.luxPlusBaseValue : 0
        const flightPrice = 'flightPrice' in dayRate ? dayRate.flightPrice : null
        const taxesAndFees = 'taxesAndFees' in dayRate ? dayRate.taxesAndFees * markup : 0
        const propertyFees = 'propertyFees' in dayRate ? dayRate.propertyFees : 0
        const surcharge = 'surcharge' in dayRate ? dayRate.surcharge : 0
        const extraGuestSurcharge = 'extraGuestSurcharge' in dayRate ? dayRate.extraGuestSurcharge : 0
        const dealId = 'dealId' in dayRate ? dayRate.dealId : null
        const hasTactical = 'hasTactical' in dayRate ? dayRate.hasTactical : false
        const hasFlightDeal = 'annotations' in dayRate ? dayRate.annotations.includes('FlightDeal') : false

        let hotelAvailable = !!price
        let hotelMemberAvailable = !!memberPrice

        if (offer.bundledWithFlightsOnly) {
          // bundled with flight deals *must* have a flight price if the cache is active
          // otherwise they are considered sold out (because no flight price = no flights)
          hotelAvailable = hotelAvailable && (!flightCacheActive || (flightCacheActive && !!flightPrice))
          hotelMemberAvailable = hotelMemberAvailable && (!flightCacheActive || (flightCacheActive && !!flightPrice))
        }

        const canRequestDates = !hotelAvailable && !blackout &&
          !!offerPackage.allowDatesRequest && checkOut.isBefore(moment(offer.bookByDate))

        const hotelBasePrice = !hotelAvailable ? 0 : price
        const hotelMemberBasePrice = !hotelMemberAvailable ? 0 : memberPrice

        const hotelTotal = hotelBasePrice + surcharge + extraGuestSurcharge + propertyFees
        const hotelMemberTotal = hotelMemberBasePrice ? hotelMemberBasePrice + surcharge + extraGuestSurcharge + propertyFees : 0

        const packagedTotal = hotelTotal + (flightPrice ?? 0)
        const packagedMemberTotal = hotelMemberTotal ? hotelMemberTotal + (flightPrice ?? 0) : 0

        const pricing: App.Pricing = maybeWithValue({
          price: hotelTotal,
          fees: onlyNonZeroFees([
            { type: 'property', amount: propertyFees },
            { type: 'taxesAndFees', amount: taxesAndFees },
            { type: 'surcharge', amount: surcharge },
            { type: 'extraGuestSurcharge', amount: extraGuestSurcharge },
          ]),
          saleUnit: offer.saleUnit,
        }, hotelValue)

        const memberPricing = determineMemberPricing(pricing, hotelMemberTotal, hotelMemberValue)
        const pricingWithFlights = flightPrice ? addFlightPrice(pricing, flightPrice) : undefined
        const memberPricingWithFlights = (memberPricing && flightPrice) ? addFlightPrice(memberPricing, flightPrice) : undefined

        const day: App.CalendarDay = {
          uniqueKey,
          day: currentDay.date(),
          month: monthName,
          year: currentDay.year().toString(),
          monthKey,
          checkIn: pricesData.day,
          checkOut: checkOut.format('YYYY-MM-DD'),
          canRequestDates,
          taxesAndFees,
          propertyFees,
          roomPrices: prices,
          hotelPrice: hotelBasePrice,
          hotelValue,
          hotelMemberValue,
          hotelTotal,
          packagedTotal,
          extraGuestSurcharge,
          flightPrice: (flightPrice ?? 0),
          surcharge,
          hotelAvailable,
          flightCacheActive,
          roomRateId,
          // TODO: What if the room type ID is not defined?
          roomId: offerPackage.roomType?.id,
          flightsAvailable: checkOut.isBefore(maxFlightDate),
          availableRooms,
          dealId,
          hasTactical,
          memberPrice,
          packagedMemberTotal,
          hotelMemberTotal,
          hotelMemberPrice: hotelMemberBasePrice,
          roomMemberPrices: memberPrices,
          pricing,
          memberPricing,
          pricingWithFlights,
          memberPricingWithFlights,
          hasFlightDeal,
          cheapest: false,
        }
        return day
      })

      return {
        uniqueKey,
        key: monthKey,
        month: monthName,
        year: yearText,
        yearNum,
        monthIndex,
        days,
        cheapestDay: min(days.filter(d => d.hotelAvailable), d => d.hotelTotal),
        cheapestDayWithFlights: min(days.filter(d => d.flightsAvailable && d.hotelAvailable), d => d.packagedTotal),
        hotelAvailable: days.some(d => d.hotelAvailable),
        canRequestDates: days.some(d => d.canRequestDates),
        flightsAvailable: days.some(d => d.flightsAvailable && d.hotelAvailable),
        flightCacheActive,
        hasFlightDeal: days.some(d => d.hasFlightDeal),
      }
    })

    return {
      packageId: serverCalendar.packageId,
      uniqueKey,
      months,
      // @ts-ignore: Array.filter removes all undefined days
      cheapestDay: min(months.map(m => m.cheapestDay).filter(Boolean), d => d.hotelTotal),
      // @ts-ignore: Array.filter removes all undefined days
      cheapestDayWithFlights: min(months.map(m => m.cheapestDayWithFlights).filter(Boolean), d => d.packagedTotal),
      flightCacheActive,
      flightsNotAvailable,
    }
  })
}

interface RequestCalendarParams {
  offer: App.Offer | App.OfferSummary,
  flightOrigin?: string;
  bundledOfferIds?: Array<string>;
  region: string;
  durations: Array<number>;
  minDate?: string;
  packages: Array<App.Package>;
  occupants: Array<App.Occupants>;
  enquiryType?: 'customer' | 'admin';
  channelMarkup?: App.ChannelMarkup;
}

function requestCalendar(params: RequestCalendarParams) {
  const packageIds = params.packages.map((pkg) => pkg.id)
  const query = qs.stringify({
    flightOrigin: params.flightOrigin,
    region: params.region,
    nights: params.durations,
    occupancy: params.occupants.map(occ => {
      // if hide child prices is enabled, we need to remove children and infants from the occupancy only for rates (ARI based) request
      // live availability will still show children and infants hence temporary removal
      if (params.offer?.property?.hideChildPrices && (occ.children !== 0 || occ.infants !== 0)) {
        const temp = { ...occ }
        temp.children = 0
        temp.infants = 0
        temp.childrenAge = []
        return generateOccupancyStringByRoom(temp)
      }
      return generateOccupancyStringByRoom(occ)
    }),
    minDate: params.minDate,
    packageIds: packageIds.join(','),
    enquiryType: params.enquiryType,
    bundledOfferIds: params.bundledOfferIds ? params.bundledOfferIds.join(',') : undefined,
  })

  // compression in header for fix the compression on backend side
  // TODO: after fix remove it and use Accept-Encoding
  return request.get<App.ApiResponse<Array<CalendarV2.Package>>>(`/api/v2/calendar/${params.offer.id}/rates?${query}`, { headers: { compression: 'gzip' } })
    .then((response) => {
      return response.result.flatMap(serverCalendar => mapCalendar(
        serverCalendar,
        params.packages,
        params.offer,
        params.channelMarkup,
      ))
    })
}

export interface CalendarParams {
  flightOrigin?: string;
  flightProvider?: string;
  minDate?: string;
  packages: Array<App.Package>;
  occupants: Array<App.Occupants>;
  enquiryType?: 'customer' | 'admin'
}

export function getCalendars(
  offer: App.Offer | App.OfferSummary,
  region: string,
  params: CalendarParams = { occupants: [], packages: [], enquiryType: 'customer' },
  channelMarkup?: App.ChannelMarkup,
) {
  if (isBundleOffer(offer)) {
    const bundledOffer = arrayToObject(params.packages, pkg => pkg.offerId, pkg => pkg.duration)

    return requestCalendar({
      offer,
      flightOrigin: params.flightOrigin,
      region,
      durations: Object.values(bundledOffer),
      minDate: params.minDate,
      packages: params.packages,
      occupants: params.occupants,
      enquiryType: params.enquiryType,
      bundledOfferIds: Object.keys(bundledOffer),
      channelMarkup,
    })
  } else {
    const queryGroups = groupBy(params.packages, pkg => pkg.duration)

    return Promise.all([...queryGroups.entries()].flatMap(([duration, pkgGroup]) => {
      const uniquePackages = uniqueBy(pkgGroup, pkg => pkg.id)
      const optionCountPerPackage = countBy(pkgGroup, pkg => pkg.id)
      // The number of options (rates) dictates how expensive the calendar fetch will be and how long it will take
      // But we can only fetch per-package at a time. So lets try batch together at max 20 option at a time
      const chunks = splitByWeight(uniquePackages, {
        limit: 20,
        weightFunc: pkg => optionCountPerPackage.get(pkg.id) ?? 1,
        overflowBehaviour: 'next-chunk',
      })

      return chunks.flatMap((chunk) => {
        return requestCalendar({
          offer,
          flightOrigin: params.flightOrigin,
          region,
          durations: [duration],
          minDate: params.minDate,
          packages: chunk,
          occupants: params.occupants,
          enquiryType: params.enquiryType,
          channelMarkup,
        })
      })
    })).then((calendarArrays) => calendarArrays.flat())
  }
}

interface AvailablePriceParams {
  hideExcludesFlights?: boolean;
  timezone: string;
  regionCode: string;
  currencyCode: string;
  offerType?: string;
}

function availablePriceMap(serverOfferBestPrice: CalendarV2.Availability, options: AvailablePriceParams, channelMarkup?: App.ChannelMarkup): App.OfferAvailableRate {
  const markup = getChannelMarkupValue(serverOfferBestPrice.offerId, options.offerType, channelMarkup)
  const taxesAndFees = (serverOfferBestPrice.taxesAndFees ?? 0) * markup
  const propertyFees = serverOfferBestPrice.propertyFees ?? 0
  const prices = serverOfferBestPrice.prices.map(price => price * markup)
  const basePrice = sum(serverOfferBestPrice.prices.map(price => price * markup))
  const memberPrices = serverOfferBestPrice.luxPlusPrices
  const memberPrice = sum(memberPrices)

  const hotelValue = serverOfferBestPrice.value
  const flightPrice = serverOfferBestPrice.flightPrice ?? 0
  const surcharge = serverOfferBestPrice.surcharge ?? 0
  const extraGuestSurcharge = serverOfferBestPrice.extraGuestSurcharge ?? 0

  return {
    offerId: serverOfferBestPrice.offerId,
    duration: serverOfferBestPrice.duration,
    roomPrices: prices,
    roomMemberPrices: memberPrices,
    hotelValue,
    extraGuestSurcharge,
    flightPrice,
    surcharge,
    taxesAndFees,
    propertyFees,
    availableRooms: serverOfferBestPrice.availableRooms,
    packageId: serverOfferBestPrice.packageId,
    // this will make it match our own unique keys
    packageUniqueKey: getPackageUniqueKey(serverOfferBestPrice.packageId, serverOfferBestPrice.duration, serverOfferBestPrice.roomRateId),
    roomRateId: serverOfferBestPrice.roomRateId,
    cancellationPolicies: serverOfferBestPrice.connection ? connectionBuildCancellationPolicies(serverOfferBestPrice.connection.cancellationPolicies, options) : undefined,
    hasTactical: serverOfferBestPrice.hasTactical,
    price: basePrice + surcharge,
    hotelPrice: basePrice,
    memberPriceWithSurcharge: memberPrice + surcharge,
    memberPrice,
    hotelMemberPrice: memberPrice,
    hotelMemberValue: serverOfferBestPrice.luxPlusBaseValue,
  }
}

interface AvailabilityQueryParams extends AvailablePriceParams {
  offerType?: string;
  offerIds: Array<string>;
  checkIn: string;
  checkOut: string;
  rooms: Array<App.Occupants>;
  dynamic?: boolean;
  lowestPrices?: boolean;
  flightOrigin?: string;
  bundledOfferId?: string;
  channelMarkup?: App.ChannelMarkup;
}

export function getAvailableRatesForOffer(params: AvailabilityQueryParams): Promise<Array<App.OfferAvailableRate>> {
  const queryParams = qs.stringify({
    offerIds: params.offerIds.join(','),
    region: params.regionCode,
    checkIn: params.checkIn,
    checkOut: params.checkOut,
    occupancy: params.rooms.map(generateOccupancyStringByRoom),
    dynamic: params.dynamic,
    lowestPrices: params.lowestPrices,
    flightOrigin: params.flightOrigin,
    bundledOfferIds: params.bundledOfferId,
  })

  // compression in header for fix the compression on backend side
  // TODO: after fix remove it and use Accept-Encoding
  try {
    return request.get<App.ApiResponse<Array<CalendarV2.Availability>>>(`/api/v2/calendar/availability?${queryParams}`, { headers: { compression: 'gzip' } })
      .then(resp => resp.result.map(result => availablePriceMap(result, {
        hideExcludesFlights: params.offerType === OFFER_TYPE_VILLA,
        timezone: params.timezone,
        regionCode: params.regionCode,
        currencyCode: params.currencyCode,
        offerType: params.offerType,
      }, params.channelMarkup)))
  } catch (error) {
    if (error && typeof error === 'object' && 'status' in error &&
        (error.status === 404 || error.status === 422)) {
      // Offer has no packages available with these parameters
      return Promise.resolve([])
    }
    throw error
  }
}

interface LowestPriceQueryParams extends AvailablePriceParams {
  offerType: string;
  offerIds: Array<string>;
  checkIn: string;
  checkOut: string;
  rooms: Array<App.Occupants>;
  bundledOfferId?: string;
  channelMarkup?: App.ChannelMarkup;
}

export type LowestPriceResponse = { available: boolean, rate?: undefined, checkIn: string, checkOut: string} | { available: boolean, rate: App.OfferAvailableRate, checkIn: string, checkOut: string }
export async function getLowestPriceForOffer(params: LowestPriceQueryParams): Promise<LowestPriceResponse> {
  const data = await getAvailableRatesForOffer({ ...params, lowestPrices: true, dynamic: false, offerType: params.offerType })

  const rate = data[0]

  if (!rate) {
    return { available: false, checkIn: params.checkIn, checkOut: params.checkOut }
  }

  return ({
    available: true,
    rate,
    checkIn: params.checkIn,
    checkOut: params.checkOut,
  })
}

type ValidCalendar = Omit<App.Calendar, 'uniqueKey'> & { uniqueKey: string }
function isValidCalendar(calendar: App.Calendar): calendar is ValidCalendar {
  return Boolean(calendar.uniqueKey)
}
export async function getCalendarsByOccupancy(
  offer: App.Offer | App.OfferSummary,
  region: string,
  params: CalendarParams,
  channelMarkup?: App.ChannelMarkup,
) {
  const calendars = await getCalendars(offer, region, params, channelMarkup)
  const validCalendars = calendars.filter(isValidCalendar)
  return arrayToObject(validCalendars, (pkgCalendar) => pkgCalendar.uniqueKey)
}

interface getTaxBreakdownParams {
  offerId: string
  packageId: string
  propertyId: string
  roomTypeId: string
  roomRateId: string
  checkIn: string
  checkOut: string
  occupancies: Array<string>
  region: string
  nights: number
  enquiryType: string
  currency_code: string
  origin: string
}

export const getTaxBreakdown = async(
  {
    offerId,
    packageId,
    propertyId,
    roomTypeId,
    roomRateId,
    checkIn,
    checkOut,
    occupancies,
    region,
    nights,
    enquiryType,
    currency_code,
    origin,
  }: getTaxBreakdownParams,
) => {
  let taxBreakdownPerOccupancy: Array<{ occupancy: string; taxBreakdown: Array<App.TaxBreakdown>; }> = []
  try {
    const promises = occupancies.map((occupancy) =>
      request
        .get<App.ApiResponse<ServerTaxBreakdown>>(
          `/api/v2/calendar/${offerId}/${packageId}/rate-taxes?${qs.stringify({
            property_id: propertyId,
            room_type_id: roomTypeId,
            room_rate_id: roomRateId,
            check_in: checkIn,
            check_out: checkOut,
            occupancy,
            region,
            nights,
            enquiry_type: enquiryType,
            currency_code,
            origin,
          })}`,
        )
        .then((resp) => ({
          occupancy,
          taxBreakdown: resp.result.tax_breakdown.map(taxBreakdownMap),
        })),
    )
    taxBreakdownPerOccupancy = await Promise.all(promises)
  } catch (error) {
    if (error && typeof error === 'object' && 'status' in error &&
      (error.status === 404 || error.status === 422)) {
      // NO taxBreakdown is available
      return {}
    }
    throw error
  }
  return taxBreakdownPerOccupancy.reduce((acc, mappedTaxBreakdown) => ({ ...acc, [mappedTaxBreakdown.occupancy]: mappedTaxBreakdown.taxBreakdown }), {})
}

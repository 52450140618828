import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineOvenIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9.3016 6.725c0 .7456-.6044 1.35-1.35 1.35s-1.35-.6044-1.35-1.35.6044-1.35 1.35-1.35 1.35.6044 1.35 1.35ZM13.3484 6.725c0 .7456-.6044 1.35-1.35 1.35-.7455 0-1.35-.6044-1.35-1.35s.6045-1.35 1.35-1.35c.7456 0 1.35.6044 1.35 1.35ZM17.3992 6.725c0 .7456-.6044 1.35-1.35 1.35s-1.35-.6044-1.35-1.35.6044-1.35 1.35-1.35 1.35.6044 1.35 1.35Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M5.1484 9.875c0-.5523.4478-1 1-1h11.6977c.5523 0 1 .4477 1 1v8.1c0 .5523-.4477 1-1 1H6.1484c-.5522 0-1-.4477-1-1v-8.1Zm2 1v6.1h9.6977v-6.1H7.1484Z" clipRule="evenodd"/>
    <path fill="currentColor" fillRule="evenodd" d="M8.5234 13.4766c0-.5523.4477-1 1-1h4.95c.5523 0 1 .4477 1 1 0 .5522-.4477 1-1 1h-4.95c-.5523 0-1-.4478-1-1Z" clipRule="evenodd"/>
    <path fill="currentColor" fillRule="evenodd" d="M2 5c0-1.6568 1.3432-3 3-3h14c1.6569 0 3 1.3432 3 3v14c0 1.6569-1.3431 3-3 3H5c-1.6568 0-3-1.3431-3-3V5Zm3-1c-.5523 0-1 .4477-1 1v14c0 .5523.4477 1 1 1h14c.5523 0 1-.4477 1-1V5c0-.5523-.4477-1-1-1H5Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineOvenIcon

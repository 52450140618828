import { paths } from '@luxuryescapes/contract-customer-communication'

export function mapUserPreferencesOccupancy(res: paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['get']['responses']['200']['content']['application/json']['result']) {
  const occupancy = res.preferences.occupancy
  return occupancy ? {
    ...occupancy,
    rooms: occupancy.rooms.map(({ numOfAdults: adults, childrenAges: childrenAge }) => ({ adults, childrenAge, children: childrenAge.length })),
    groupSizes: occupancy.groupSizes[0],
  } : undefined
}

import { useEffect, useMemo } from 'react'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { getFlightPriceKey } from 'lib/flights/flightUtils'
import useDefaultAirport from './useDefaultAirport'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import { fetchFlightPrice } from 'actions/FlightActions'
import { EmptyObject } from 'lib/object/objectUtils'
import { countOccupantsForFlights } from 'lib/offer/occupancyUtils'

interface FlightPriceOptions {
  enabled?: boolean;
}

function useFlightPrice(
  destinationAirportCode: string | undefined,
  rooms: Array<App.Occupants> = [],
  startDate?: string | moment.Moment,
  endDate?: string | moment.Moment,
  originAirportCode?: string,
  nights?: number,
  options: FlightPriceOptions = EmptyObject,
): [number, boolean] {
  const defaultAirport = useDefaultAirport()
  const origin = originAirportCode ?? defaultAirport?.code
  const dispatch = useAppDispatch()
  const startDateParam = moment.isMoment(startDate) ? startDate.format(ISO_DATE_FORMAT) : startDate
  const endDateParam = moment.isMoment(endDate) ? endDate.format(ISO_DATE_FORMAT) : endDate
  const flightRoom = useMemo(() => countOccupantsForFlights(rooms), [rooms])
  const filterKey = useMemo(() => getFlightPriceKey({
    startDate: startDateParam ?? '',
    endDate: endDateParam ?? '',
    origin: origin ?? '',
    destination: destinationAirportCode ?? '',
    numberOfAdults: flightRoom.adults,
    numberOfChildren: flightRoom.children ?? 0,
    numberOfInfants: flightRoom.infants ?? 0,
    nights: nights ?? 0,
  }), [startDateParam, endDateParam, origin, destinationAirportCode, flightRoom, nights])

  useEffect(() => {
    if (destinationAirportCode && startDateParam && endDateParam && !options.enabled) {
      dispatch(fetchFlightPrice(destinationAirportCode, [flightRoom], startDateParam, endDateParam, origin, nights))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, options.enabled])

  const flightPrice = useAppSelector(state => state.flights.flightPrices[filterKey])
  const fetching = useAppSelector(state => state.flights.flightPricesLoading[filterKey])

  return [flightPrice, !!fetching]
}

export default useFlightPrice

import moment from 'moment'
import useFlightPrice from 'hooks/useFlightPrice'
import { getPromotionDefaultPeriod } from 'lib/hotels/bedbankUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectIsBedbankFlightBookingEnabled } from 'selectors/flightsSelectors'
import { EmptyArray } from 'lib/array/arrayUtils'
import { sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { useMemo } from 'react'

interface BedbankFlightPriceOptions {
  startDate?: string;
  endDate?: string;
  originAirportCode?: string;
  rooms?: Array<App.Occupants>;
}

function useBedbankFlightPrice(
  offer: App.BedbankOffer | App.BedbankOfferSummary | undefined,
  options: BedbankFlightPriceOptions = {},
): number | undefined {
  const period = getPromotionDefaultPeriod()
  const { startDate, endDate, originAirportCode, rooms = EmptyArray } = options

  const bedbankFlightsEnabled = useAppSelector(selectIsBedbankFlightBookingEnabled)
  const occupancyCount = useMemo(() => sumUpOccupancies(rooms), [rooms])
  const flightsEnabled = bedbankFlightsEnabled && !!offer?.flights.flightsEnabled && occupancyCount < 10
  const hasPromotions = (offer?.promotions ?? []).length > 0

  let flightCheckinDate: string = period.startDate
  let flightCheckoutDate: string = period.endDate
  let nights = 1

  if (hasPromotions && !startDate && !endDate) {
    nights = Math.min(...(offer?.promotions ?? []).map(x => x.los))
  } else if (startDate && endDate) {
    flightCheckinDate = startDate
    flightCheckoutDate = endDate
    nights = moment(flightCheckoutDate).diff(flightCheckinDate, 'days')
  }

  const [flightPrice] = useFlightPrice(
    flightsEnabled ? offer?.flights?.airportCode : undefined,
    rooms,
    flightCheckinDate,
    flightCheckoutDate,
    originAirportCode,
    nights,
  )

  return flightPrice || offer?.flights?.flightPrice || 0
}

export default useBedbankFlightPrice

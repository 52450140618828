import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUsersAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12.3 12.22A4.9195 4.9195 0 0 0 14 8.5a5 5 0 0 0-10 0 4.9197 4.9197 0 0 0 1.7 3.72A8.0001 8.0001 0 0 0 1 19.5a1 1 0 1 0 2 0 5.9999 5.9999 0 0 1 10.2426-4.2426A5.9996 5.9996 0 0 1 15 19.5a1.0001 1.0001 0 0 0 2 0 8.0005 8.0005 0 0 0-4.7-7.28ZM9 11.5a2.9996 2.9996 0 0 1-2.7716-1.852A3 3 0 1 1 9 11.5Zm9.74.32a4.9997 4.9997 0 0 0-1.0235-7.5186A5.0003 5.0003 0 0 0 15 3.5a1 1 0 1 0 0 2 3 3 0 0 1 3 3 3.0002 3.0002 0 0 1-1.5 2.59.9996.9996 0 0 0-.5.84.9992.9992 0 0 0 .45.86l.39.26.13.07a7.0002 7.0002 0 0 1 4 6.38 1.0001 1.0001 0 0 0 2 0 9.0001 9.0001 0 0 0-4.23-7.68Z"/>
  </SvgIcon>
}

export default LineUsersAltIcon

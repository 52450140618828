import TextButton from 'components/Luxkit/Button/TextButton'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import TourProductTypeLabel from 'components/Tours/TourProductTypeLabel'
import TourV2UrgencyTags from 'components/Tours/TourV2UrgencyTags'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import useSearchTourVariation from 'hooks/TourV2/useSearchTourVariation'
import { EmptyArray } from 'lib/array/arrayUtils'
import { EmptyObject } from 'lib/object/objectUtils'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import React from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import SearchTile from '../SearchTile'
import SearchTileRowOverlineJourney from '../shared/SearchTileRowOverlineJourney'
import TourV2SearchTileFeatures from './TourV2SearchTileFeatures'
import TourV2SearchTileMeta from './TourV2SearchTileMeta'
import TourV2SearchTilePriceStack from './TourV2SearchTilePriceStack'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary
  filters?: App.OfferListFilters
}

function TourV2SearchTile(props: Props) {
  const { offer, filters = EmptyObject }: Props = props

  const variation = useSearchTourVariation(offer, filters)

  const offerUrl = useOfferUrl(offer, {
    filters,
    offerLinkIncludesFilters: true,
  })

  return <SearchTile
    className={TourV2SearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    action={<TextButton nonInteractable kind="primary">View offer</TextButton>}
    bookmarkAction={<BookmarkButton offer={offer} />}
    features={variation ? <TourV2SearchTileFeatures variation={variation} offer={offer} filters={filters} /> : undefined}
    images={variation?.images ?? EmptyArray}
    labels={<TourV2UrgencyTags offer={offer}/>}
    meta={<TourV2SearchTileMeta variation={variation} offer={offer} filters={filters} />}
    overline={variation?.startLocation && variation?.endLocation ? <SearchTileRowOverlineJourney start={variation.startLocation} end={variation.endLocation} /> : undefined}
    pricePoint={<TourV2SearchTilePriceStack offer={offer} filters={filters} variation={variation} />}
    productLabel={<LabelGroup>
      <TourProductTypeLabel productType={offer.productType} offerUrl={offerUrl} />
    </LabelGroup>}
    rating={isOfferRatingDisplayable(offer.rating, 0) ? offer.rating : undefined}
    title={offer.name}
  />
}

export default TourV2SearchTile

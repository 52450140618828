export const LUXURY_PLUS = {
  PROGRAM_NAME: 'LuxPlus+',
  ANALYTICS_CATEGORY: 'LuxPlus',
  /**
   * The number of days before a user can no longer cancel their membership
   */
  REFUND_ELIGIBILITY_PERIOD_IN_DAYS: 14,
  RENEWAL_GRACE_PERIOD_IN_DAYS: 14,
  FREE_PREVIEW_DURATION: 28,
  DISMISS_FREE_PREVIEW_STORAGE_KEY: 'lux-plus-preview-dismiss',
  FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM: 'lux-plus-free-preview-auto-enroll',
  FREE_PREVIEW_QUERY_PARAM: 'lux-plus-free-preview',
  HIGH_PERCENTAGE_THRESHOLD: 70,
  LOW_PERCENTAGE_THRESHOLD: 50,
  INSURANCE_DISCOUNT_PERCENTAGE: 40,
  MIN_INSURANCE_DISCOUNT_THRESHOLD: 30,
  MAX_INSURANCE_DISCOUNT_THRESHOLD: 300,
} as const

export const LUXURY_PLUS_PATHS = {
  LANDING_PAGE: `lux-plus?${LUXURY_PLUS.FREE_PREVIEW_QUERY_PARAM}=true`,
  DEFAULT_LANDING_PAGE: 'lux-plus',
  ACCOUNT_MANAGEMENT_PAGE: 'account/lux-plus',
  TERMS_AND_CONDITIONS_PAGE: 'lux-plus-terms-and-conditions',
} as const

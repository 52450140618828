import { useCallback, useMemo } from 'react'
import { ANYWHERE_SEARCH_ITEM, SEARCH_VERTICALS } from 'constants/search'
import { useGlobalExperiencesSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalExperiencesSearch'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import ExperiencesTabContent from '../Components/Mobile/TabContents/ExperiencesTabContent'
import config from 'constants/config'
import { pushWithRegion } from 'actions/NavigationActions'
import { replaceExperienceQueryString } from 'lib/experiences/experienceUtils'
import useQueryParams from 'hooks/useQueryParams'
import LineHotAirBalloonIcon from 'components/Luxkit/Icons/line/LineHotAirBalloonIcon'
import { useAppDispatch } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'

function useGlobalSearchVerticalTabExperiences(searchVerticalItem?: App.SearchVerticalItem): App.GlobalSearchVerticalTabV2 {
  const dispatch = useAppDispatch()
  const isEnabled = config.EXPERIENCES_STANDLONE_ENABLED
  const queryParams = useQueryParams()

  const {
    globalExperiencesSearchDispatch: searchDispatch,
    globalExperiencesSearchState: state,
  } = useGlobalExperiencesSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    eventAnalytics: {
      contextLocation: 'common-search',
    },
  }, queryParams)

  const onSearch = useCallback(() => {
    const {
      experienceCategories,
      searchItem,
      eventAnalytics,
    } = state

    if (eventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        eventAnalytics.contextLocation,
        eventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItem,
          searchVerticals: new Set([SEARCH_VERTICALS.EXPERIENCES]),
        },
        ),
      ))
    }

    const toBeSearchedItem = searchItem ?? ANYWHERE_SEARCH_ITEM
    const query = replaceExperienceQueryString({
      destinationId: toBeSearchedItem.value,
      destinationName: toBeSearchedItem.format.mainText,
      categories: experienceCategories,
    })
    dispatch(pushWithRegion(`/search/experiences?${query}`))
  }, [state, dispatch])

  return useMemo(() => ({
    isEnabled: isEnabled && !!searchVerticalItem,
    label: searchVerticalItem?.label ?? 'experiences',
    key: SEARCH_VERTICALS.EXPERIENCES,
    context: { dispatch: searchDispatch, state },
    onSearch,
    ContentComponent: ExperiencesTabContent,
    Icon: LineHotAirBalloonIcon,
  }), [isEnabled, searchVerticalItem, searchDispatch, state, onSearch])
}

export default useGlobalSearchVerticalTabExperiences

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCheckIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18.71 7.21a1.001 1.001 0 0 0-.71-.2958 1.0003 1.0003 0 0 0-.71.2958l-7.45 7.46-3.13-3.14a1.021 1.021 0 0 0-.7277-.2868 1.0215 1.0215 0 0 0-.7173.3118 1.0212 1.0212 0 0 0-.2868.7277A1.0216 1.0216 0 0 0 5.29 13l3.84 3.84a1.001 1.001 0 0 0 .71.2958c.132 0 .2627-.0262.3846-.0769a1.0019 1.0019 0 0 0 .3254-.2189l8.16-8.16a.9999.9999 0 0 0 0-1.47Z"/>
  </SvgIcon>
}

export default LineCheckIcon

import Image from 'components/Common/Image'
import NumberRating from 'components/Common/NumberRating'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { _searchTileContainerQueryUp } from './_SearchTileBase'
import { SearchTileLogo } from './SearchTile'
import SearchTileRowTitle from './shared/SearchTileRowTitle'

const Container = styled(Group)`
  grid-area: content-primary;
`

const RatingContainer = styled.div`
  display: block;

  ${_searchTileContainerQueryUp.tablet} {
    display: none;
  }

  ${_searchTileContainerQueryUp.desktopSmall} {
    display: block;
  }

  ${_searchTileContainerQueryUp.desktop} {
    display: none;
  }
`

const Logo = styled(Image)`
  object-fit: contain;
  height: ${rem(40)};

  ${_searchTileContainerQueryUp.tablet} {
    display: none;
  }

  ${_searchTileContainerQueryUp.desktopSmall} {
    display: block;
  }

  ${_searchTileContainerQueryUp.desktop} {
    display: none;
  }
`

interface Props extends PropsWithChildren {
  cancellationPolicyLabel?: ReactElement | false
  /**
   * Inclusions, amenities, etc.
   */
  features?: ReactElement | false
  labels?: ReactElement | false
  /**
   * Complementary content. _(Sits between header and features)_
   */
  meta?: ReactElement | false
  overline?: ReactElement | false
  rating?: App.OfferRating
  title: ReactElement | string
  subtitle?: string;
  logo?: SearchTileLogo;
}

function _SearchTileContentPrimary({
  cancellationPolicyLabel,
  features,
  labels,
  meta,
  overline,
  rating,
  title,
  subtitle,
  logo,
}: Props) {
  return <Container
    className={_SearchTileContentPrimary.name}
    direction="vertical"
    gap={12}
  >
    <Group direction="vertical" gap={4}>
      <Group direction="horizontal" gap={8} horizontalAlign="space-between" verticalAlign="start">
        <Group direction="vertical" gap={4}>
          {overline}
          <SearchTileRowTitle>{title}</SearchTileRowTitle>
          {subtitle && <BodyText variant="medium" colour="neutral-two">{subtitle}</BodyText>}
        </Group>
        {rating && <RatingContainer>
          <NumberRating
            rating={rating.score}
            total={rating.reviewsTotal}
            variant="small"
            numberPosition="end"
            onlyNumber
          />
        </RatingContainer>}
      </Group>
      {labels}
      {cancellationPolicyLabel}
    </Group>
    {logo && <Group direction="horizontal" horizontalAlign="space-between" gap={8}>
      <div>{meta}</div>
      <Logo image={logo.compact} width={72} dpr={2} />
    </Group>}
    {!logo && meta}
    {features}
  </Container>
}

export default _SearchTileContentPrimary

import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import Pill from 'components/Luxkit/Pill'
import Tooltip from 'components/Luxkit/Tooltip'
import React from 'react'

interface Props {
  /**
   * @example 10 => 10%
   */
  discountPercentage: number
}

function PriceRowValueLastMinuteDiscount(props: Props) {
  const {
    discountPercentage,
  } = props

  return <Tooltip description="Discount calculated on the best rate available for this package as reported to us by the property. Rate excludes the value of any package inclusions – they’re our gift to you.">
    <Pill
      className={PriceRowValueLastMinuteDiscount.name}
      kind="primary"
      variant="success"
      endIcon={<SolidInfoCircleIcon colour="neutral-one" />}
      data-testid="last-minute-discount-percentage-pill"
    >
      {discountPercentage}% off best rate
    </Pill>
  </Tooltip>
}

export default PriceRowValueLastMinuteDiscount

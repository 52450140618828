import React from 'react'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'

export type LocationProps = {
  startLocation?: string | null
  endLocation?: string | null
  typeOfExperience?: string
  variant?: 'single' | 'start_and_end' | 'with_experience'
}

function TourV2Location(props: LocationProps) {
  const { endLocation, startLocation, typeOfExperience, variant = 'single' } = props
  return (
    <Group direction="horizontal" verticalAlign="center" gap={4}>
      {variant === 'single' && (
        <Caption variant="large" colour="neutral-one">
          {startLocation}
        </Caption>
      )}
      {variant === 'start_and_end' && (
        <Caption variant="large" colour="neutral-one">
          Starts in {startLocation}, Ends in {endLocation}
        </Caption>
      )}
      {variant === 'with_experience' && (
        <Caption variant="large" colour="neutral-one">
          {startLocation}
          {typeOfExperience && (
            <Caption as="span" variant="large" colour="neutral-two">
              {` · ${typeOfExperience}`}
            </Caption>
          )}
        </Caption>
      )}
    </Group>
  )
}
export default TourV2Location

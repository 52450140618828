import 'isomorphic-fetch'
import qs from 'qs'
import request from 'api/requestUtils'
import config from 'constants/config'
import { getWhiteLabelAppConfig } from 'lib/whitelabels/whitelabels'

interface CheckAppVersionResult {
  isMajorOrMinor?: boolean;
  isPatch?: boolean;
  storeVersion: string;
  storeLink: string
}

async function checkAppVersion(): Promise<CheckAppVersionResult | undefined> {
  const iOSAppId = config.wlMobileApp?.ios?.appId
  const androidPackageName = config.wlMobileApp?.android?.packageName
  const parentBrand = config.brandConfig.subscriptionParentBrand

  const whiteLabelAppConfig = getWhiteLabelAppConfig()

  const platform = config.MOBILE_APP_PLATFORM || whiteLabelAppConfig.platform
  const clientVersion = config.MOBILE_APP_VERSION || whiteLabelAppConfig.clientVersion

  const url = '/api/mobileconfig/white-label-app-version'

  const query = {
    plataform: platform,
    clientVersion,
    parentBrand,
    appId: '',
  }

  if (platform === 'ios' && iOSAppId) {
    query.appId = iOSAppId
    const response = await request.get<App.ApiResponse<CheckAppVersionResult>>(`${url}?${qs.stringify(query)}`)
    return response.result
  }

  if (platform === 'android' && androidPackageName) {
    query.appId = androidPackageName
    const response = await request.get<App.ApiResponse<CheckAppVersionResult>>(`${url}?${qs.stringify(query)}`)
    return response.result
  }
}

export default checkAppVersion

import FormatCurrency from 'components/Common/FormatCurrency'
import Caption from 'components/Luxkit/Typography/Caption'
import React, { ComponentProps } from 'react'
import FormatPercent from '../utils/Formatters/FormatPercent'

interface Props {
  marginValue: number
  margin?: number
  supplier?: string
  colour?: 'highlight-secondary' | 'critical'
  size: 'M' | 'L'
  /**
   * Controls whether the messaging to state the credit is an estimate or exact value.
   */
  type: 'exact' | 'estimate' | 'ao_estimate' | 'ao_exact' | 'bedbank_exact' | 'bedbank_exact_accom' | 'bedbank_estimate' | 'le_estimate' | 'package_upgrade_comparison_positive'
  currency?: string
}

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

function PriceRowMarginText(props: Props) {
  const {
    marginValue,
    margin,
    supplier,
    colour = 'highlight-secondary',
    size,
    type,
  } = props

  return <Caption
    className={PriceRowMarginText.name}
    variant={SIZES_CAPTION_VARIANTS[size]}
    weight="bold"
    colour={colour}
  >
    {type === 'estimate' && <>Margin value up to: <FormatCurrency value={marginValue} format="roundedDollar" currency="AUD"/></>}
    {type === 'exact' && <>Margin value: <FormatCurrency value={marginValue} format="roundedDollar" currency="AUD"/></>}

    {/* TAO/LPC margin does not have a flat AUD value send from the backend, only the percentage */}
    {type === 'ao_estimate' && <>Margin value up to: <FormatCurrency value={marginValue} format="roundedDollar"/> {margin && `(${margin}%)`} (local currency)</>}
    {type === 'ao_exact' && <>Margin value: <FormatCurrency value={marginValue} format="roundedDollar"/> {margin && `(${margin}%)`} (local currency)</>}

    {type === 'bedbank_exact' && <>Margin: <FormatCurrency value={marginValue} format="roundedDollar" currency="AUD"/> ({margin}%) | ({supplier})</>}
    {type === 'bedbank_exact_accom' && <>Margin: <FormatCurrency value={marginValue} format="roundedDollar" currency="AUD"/> ({margin}%) | ({supplier}) | Accommodation Only</>}
    {type === 'bedbank_estimate' && <>Margin value up to: <FormatCurrency value={marginValue} format="roundedDollar" currency="AUD"/> ({margin}%) | ({supplier})</>}
    {type === 'le_estimate' && <>Estimated margin: <FormatPercent value={margin!} /></>}
    {type === 'package_upgrade_comparison_positive' && <>Earn +<FormatCurrency value={marginValue} format="roundedDollar" currency="AUD"/> more margin with this room upgrade!</>}
  </Caption>
}

export default PriceRowMarginText

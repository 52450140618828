import { pushWithRegion } from 'actions/NavigationActions'
import Pane from 'components/Common/Pane'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import Pill from 'components/Luxkit/Pill'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import {
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { themeClassName } from 'lib/theme/themeUtils'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { rem } from 'polished'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import LuxPlusLogo from '../Logos/LuxPlusLogo'
import LuxPlusBenefitsList from '../LuxPlusBenefitsList'
import { connect } from 'react-redux'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { fetchProductSubscription } from 'actions/LuxPlusActions'

const LuxPlusBannerSheet = styled(Pane)`
  background-color: ${props => props.theme.palette.product.luxPlus.background};
  padding: ${rem(32)} ${rem(24)};
`

interface Props {
  subscription: App.ProductSubscription;
}

function LuxPlusFreePreviewBannerModal({ subscription }: Props) {
  const { resolve } = useModalElementContext<boolean>()
  const dispatch = useAppDispatch()

  const closeModal = useCallback(() => resolve(true), [resolve])

  const dismissModal = useCallback(() => {
    setLocalStorage(LUXURY_PLUS.DISMISS_FREE_PREVIEW_STORAGE_KEY, 1)
    closeModal()
  }, [closeModal])

  const handleFreePreviewClick = useCallback(() => {
    dismissModal()
    dispatch(pushWithRegion(`/${LUXURY_PLUS_PATHS.DEFAULT_LANDING_PAGE}?${LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM}=true`))
  }, [dismissModal, dispatch])

  useEffect(() => {
    dispatch(fetchProductSubscription())
  }, [dispatch])

  return <ModalBase onClose={dismissModal}>
    <ModalHeader
      isOverlay
      onCloseButtonClick={dismissModal} title="VIP Travel Club Preview"
      />
    <ModalBody>
      <LuxPlusBannerSheet className={themeClassName('inverse')}>
        <Group direction="vertical" horizontalAlign="start" gap={12} >
          <Pill kind="primary" variant="success">
            Limited-time offer
          </Pill>
          <LuxPlusLogo height={20} />
          <Heading variant="heading2" format="propercase" align="center">
            VIP Travel Club Preview
          </Heading>
        </Group>
      </LuxPlusBannerSheet>
      <ModalContent>
        <Group direction="vertical" gap={12}>
          <BodyText variant="large">
            Experience the VIP life with a {LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview of {LUXURY_PLUS.PROGRAM_NAME}. Discover hidden offers, view member pricing and more!
          </BodyText>

          <BodyText variant="medium" weight="bold">
            No Credit card required. T&Cs Apply.
          </BodyText>
          <br />
          {subscription.error && <BodyText variant="medium">There was an error loading the details for the program. Please try again later.</BodyText>}
          {subscription.fetching && <LoadingIndicator inline/>}
          {subscription.offers && <LuxPlusBenefitsList kind="short" benefits={subscription.offers[0].benefits}/>}
        </Group>
      </ModalContent>
    </ModalBody>
    <ModalFooter
        primaryActionProps={{
          children: `Start ${LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview`,
          onClick: handleFreePreviewClick,
        }}
        secondaryActionProps={{
          children: 'Find out more',
          to: `/${LUXURY_PLUS_PATHS.LANDING_PAGE}`,
        }}
      />
  </ModalBase>
}

const mapStateToProps = (state: App.State) => ({
  subscription: state.luxPlus.products.subscription,
})

export default connect(mapStateToProps)(LuxPlusFreePreviewBannerModal)

import { useAppSelector } from 'hooks/reduxHooks'
import React, { PropsWithChildren } from 'react'
import { initialGeoState } from 'reducers/geo'
import { getCustomerValue, getSupportContact } from 'selectors/supportContactSelector'

const ContactContext = React.createContext<App.GeoContacts>(initialGeoState.contacts.general)

interface Props {
  contact?: App.GeoContacts;
}

export function ContactProvider(props: PropsWithChildren<Props>) {
  const { contact } = props
  const currentContact = useAppSelector(getSupportContact)
  const customerValue = useAppSelector(getCustomerValue)

  return <ContactContext.Provider value={customerValue ? currentContact : contact ?? currentContact}>
    {props.children}
  </ContactContext.Provider>
}

export default ContactContext

import React, { ComponentProps, PropsWithChildren } from 'react'
import Caption from '../Typography/Caption'
import styled from 'styled-components'
import { rem } from 'polished'

const ListItem = styled.div`
  padding: 0 ${rem(16)};
`

interface Props extends PropsWithChildren, Pick<ComponentProps<typeof Caption>, 'colour'> {}

function ListSubheader(props: Props) {
  const { children, colour } = props

  return <ListItem>
    <Caption variant="large" weight="bold" colour={colour}>{children}</Caption>
  </ListItem>
}

export default ListSubheader

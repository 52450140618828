import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getOrderTravelDuration } from 'lib/order/orderUtils'
import moment from 'moment'
import getOrderDataMemberPricingMetadata from './orderMemberPricingMetadata'

function getBookingProtectionOrderItems(order: App.Order) {
  return order.bookingProtectionItems.map(orderItem => {
    const { earliest, latest } = getOrderTravelDuration(order)
    const luxPlusMetaData = getOrderDataMemberPricingMetadata(orderItem)
    return comluxgroup.createItem_1_1_0(
      {
        offerId: orderItem.quoteId || 'insurance',
        categoryId: 'insurance',
        productId: 'insurance',
        itemId: orderItem.id,
        travelStart: earliest,
        travelEnd: latest,
        duration: earliest && latest ? moment(latest).diff(earliest, 'days') : undefined,
        price: orderItem.total,
        currency: order.currencyCode,
        bookingNumber: order.bookingNumber,
        orderId: order.id,
        metadata: luxPlusMetaData ? JSON.stringify(luxPlusMetaData) : undefined,
      },
    )
  })
}

export default getBookingProtectionOrderItems

import React, { useCallback, useEffect, useRef } from 'react'
import ListItem from 'components/Luxkit/List/ListItem'
import { fillArray } from 'lib/array/arrayUtils'
import moment from 'moment'
import { SHORT_TIME_FORMAT_AM_PM } from 'constants/dateFormats'
import noop from 'lib/function/noop'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'

const minutesInDay = 24 * 60
function generateTimes(step = 60) {
  const count = minutesInDay / step
  const stepsInHour = Math.round(60 / step)
  return fillArray(count).map((index) => {
    const hourPart = Math.floor(index / stepsInHour).toString().padStart(2, '0')
    const minutePart = ((index % stepsInHour) * step).toString().padStart(2, '0')
    const value = `${hourPart}:${minutePart}`
    return {
      value,
      label: moment(value, 'HH:mm').format(SHORT_TIME_FORMAT_AM_PM),
    }
  })
}

const timeOptions = generateTimes()

interface Props {
  open: boolean;
  dropdownTriggerRef: React.MutableRefObject<HTMLButtonElement | null>;
  selectedTime?: string;
  onChange?: (time: string) => void;
  toggleDropDown: () => void;
}

function CarHireTimeSelect(props: Props) {
  const {
    open,
    dropdownTriggerRef,
    selectedTime,
    onChange = noop,
    toggleDropDown,
  } = props

  const dropdownRef = useRef<HTMLDivElement>(null)

  const onTimeSelect = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.dataset.value
    onChange(value)
  }, [onChange])

  useEffect(() => {
    // make sure to open at our selected value
    if (open && selectedTime && dropdownRef.current) {
      const listItem = dropdownRef.current.querySelector<HTMLDivElement>(`[data-value='${selectedTime}']`)
      if (listItem) {
        dropdownRef.current.scrollTo({
          // try center it in the drop down
          top: Math.max(0, listItem.offsetTop - (dropdownRef.current.offsetHeight / 2)),
          behavior: 'instant' as any,
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <DropdownList
      size="S"
      anchorRef={dropdownTriggerRef}
      triggerRef={dropdownTriggerRef}
      open={open}
      onClose={toggleDropDown}
    >
      {timeOptions.map(time => <ListItem
        selected={time.value === selectedTime}
        key={time.value}
        title={time.label}
        data-value={time.value}
        size="medium"
        onClick={onTimeSelect}
      />)}
    </DropdownList>
  )
}

export default CarHireTimeSelect

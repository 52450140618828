import React from 'react'
import { Action } from 'redux'

import ViewContext from './ViewContext'
import ViewState from './ViewState'

interface Props {
  children: React.ReactNode
  dispatch: React.Dispatch<Action>
  state: ViewState
}

export default function ViewContextProvider(props: Props) {
  const { children, dispatch, state } = props

  return (
    <ViewContext.Provider value={{ state, dispatch }}>
      {children}
    </ViewContext.Provider>
  )
}

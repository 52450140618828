import config from 'constants/config'
import { SEARCH_VERTICALS } from 'constants/search'
import { useTourV2SearchEnabled } from 'hooks/useTourV2SearchEnabled'
import { FunctionComponent, useMemo } from 'react'

import { isFlightsCredit, isStandaloneFlightsEnabled } from 'selectors/flightsSelectors'
import tourV2VerticalNameSelector from 'selectors/TourV2/tourV2VerticalNameSelector'
import GlobalHotelsSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalHotelsSearchTabContent'
import GlobalHomesAndVillasSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalHomesAndVillasSearchTabContent'
import GlobalTourSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalTourSearchTabContent'
import GlobalExperiencesSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalExperiencesSearchTabContent'
import GlobalFlightsSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalFlightsSearchTabContent'
import GlobalCruisesSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalCruisesSearchTabContent'
import GlobalCarHireSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalCarHireSearchTabContent'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import LineHomeAltIcon from 'components/Luxkit/Icons/line/LineHomeAltIcon'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineHotAirBalloonIcon from 'components/Luxkit/Icons/line/LineHotAirBalloonIcon'
import { useAppSelector } from 'hooks/reduxHooks'

const SEARCH_VERTICALS_TAB_COMPONENTS: Record<SEARCH_VERTICALS, FunctionComponent> = {
  [SEARCH_VERTICALS.CARHIRE]: GlobalCarHireSearchTabContent,
  [SEARCH_VERTICALS.CRUISES]: GlobalCruisesSearchTabContent,
  [SEARCH_VERTICALS.EXPERIENCES]: GlobalExperiencesSearchTabContent,
  [SEARCH_VERTICALS.FLIGHTS]: GlobalFlightsSearchTabContent,
  [SEARCH_VERTICALS.HOMES_AND_VILLAS]: GlobalHomesAndVillasSearchTabContent,
  [SEARCH_VERTICALS.HOTELS]: GlobalHotelsSearchTabContent,
  [SEARCH_VERTICALS.TOURS]: GlobalTourSearchTabContent,
}

const SEARCH_VERTICALS_TAB_ICONS: Record<SEARCH_VERTICALS, FunctionComponent> = {
  [SEARCH_VERTICALS.CARHIRE]: LineCarIcon,
  [SEARCH_VERTICALS.CRUISES]: LineShipIcon,
  [SEARCH_VERTICALS.EXPERIENCES]: LineHotAirBalloonIcon,
  [SEARCH_VERTICALS.FLIGHTS]: LinePlaneDepartureIcon,
  [SEARCH_VERTICALS.HOMES_AND_VILLAS]: LineHomeAltIcon,
  [SEARCH_VERTICALS.HOTELS]: LineBedIcon,
  [SEARCH_VERTICALS.TOURS]: LineCompassIcon,
}

function useGlobalSearchVerticalTabs(): Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTab> {
  // TODO: this should be single selector @arminmon
  const standaloneFlightsEnabled = useAppSelector((state) => isStandaloneFlightsEnabled(state) || isFlightsCredit(state))
  const toursVerticalName = useAppSelector((state) => tourV2VerticalNameSelector(state)).toLowerCase()
  const toursEnabled = useTourV2SearchEnabled()

  const regionCode = useAppSelector((state) => state.geo.currentRegionCode)
  const isInAvailableCruiseRegion = config.CRUISES_REGIONS.split(',').includes(regionCode)
  const isInAvailableCarHireRegion = config.CARHIRE_REGIONS.split(',').includes(regionCode)

  const enabledSearchVerticals = useMemo<Record<SEARCH_VERTICALS, boolean>>(() => ({
    [SEARCH_VERTICALS.CARHIRE]: config.CARHIRE_ENABLED && isInAvailableCarHireRegion,
    [SEARCH_VERTICALS.CRUISES]: config.CRUISES_STANDLONE_ENABLED && isInAvailableCruiseRegion,
    [SEARCH_VERTICALS.EXPERIENCES]: config.EXPERIENCES_STANDLONE_ENABLED,
    [SEARCH_VERTICALS.FLIGHTS]: standaloneFlightsEnabled,
    [SEARCH_VERTICALS.HOTELS]: true,
    [SEARCH_VERTICALS.TOURS]: toursEnabled,
    [SEARCH_VERTICALS.HOMES_AND_VILLAS]: config.RENTALS_SEARCH_ENABLED,
  }), [isInAvailableCarHireRegion, isInAvailableCruiseRegion, standaloneFlightsEnabled, toursEnabled])

  const globalSearchVerticals = useMemo(() => {
    // credits for switching from Array to Map goes to @itzcull
    const searchVerticals = new Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTab>()

    for (const [searchVertical, searchVerticalItem] of config.searchVerticalItems) {
      if (enabledSearchVerticals[searchVertical]) {
        // please get rid of this `if` once cruises is enabled in all regions
        if (searchVertical === SEARCH_VERTICALS.TOURS) {
          searchVerticals.set(searchVertical, {
            label: toursVerticalName.toLocaleLowerCase(),
            Component: SEARCH_VERTICALS_TAB_COMPONENTS[searchVertical],
            Icon: SEARCH_VERTICALS_TAB_ICONS[searchVertical],
          })
        } else {
          searchVerticals.set(searchVertical, {
            label: searchVerticalItem.label,
            Component: SEARCH_VERTICALS_TAB_COMPONENTS[searchVertical],
            Icon: SEARCH_VERTICALS_TAB_ICONS[searchVertical],
          })
        }
      }
    }

    return searchVerticals
  }, [enabledSearchVerticals, toursVerticalName])

  if (config.searchVerticals) {
    const sortedSearchVerticals = [...globalSearchVerticals].sort((a, b) => config.searchVerticals.indexOf(a[0]) - config.searchVerticals.indexOf(b[0]))
    return new Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTab>(sortedSearchVerticals)
  }

  return globalSearchVerticals
}

export default useGlobalSearchVerticalTabs

import { fetchExperienceCategories, fetchExperienceListFacets } from 'actions/ExperienceActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { arrayToObject, groupBy, sortBy, sum } from 'lib/array/arrayUtils'
import { useEffect, useMemo } from 'react'

export interface CategoryData {
  parent: App.ExperienceItemCategory;
  children: Array<App.ExperienceItemCategory>;
  count: number;
}

function useMostPopulatedSearchExperienceCategories(place?: App.Place): [Array<CategoryData>, boolean] {
  const dispatch = useAppDispatch()
  const categories = useAppSelector(state => state.experience.experienceCategories)
  const searchFacets = useAppSelector(state => state.experience.experienceListFacets)

  useEffect(() => {
    if (!place) {
      return
    }

    dispatch(fetchExperienceListFacets(place.id))
    dispatch(fetchExperienceCategories())
    // eslint-disable-next-line
  }, [place])

  const primaryCategories = useMemo<Array<CategoryData>>(() => {
    if (!place) {
      return []
    }

    // prepare reference data for access in calculations below
    const childToParentCategories = groupBy(categories.filter(cat => cat.parentId), cat => cat.parentId)
    const categoryFacetsById = arrayToObject(
      searchFacets[place.id]?.facets?.category ?? [],
      (cat) => cat.categoryId,
    )

    // we show a tile for each 'parent' category
    const topLevelCategories = categories.filter(cat => cat.level === 'parent')
    // new search facets doesn't return a count at the parent level, so we have to manually
    // add up all child facets to get an accurate count
    const topLevelCategoryFacets = topLevelCategories.map(cat => ({
      cat,
      count: sum(childToParentCategories.get(cat.id) ?? [], childCat => categoryFacetsById[childCat.id]?.count ?? 0),
    }))

    const sortedCategories = sortBy(topLevelCategoryFacets, val => val.count, 'desc')

    return sortedCategories.filter(fac => fac.count !== 0).map(fac => ({
      parent: fac.cat,
      children: childToParentCategories.get(fac.cat.id) ?? [],
      count: fac.count,
    }))
  }, [categories, place, searchFacets])

  const fetching = searchFacets[place?.id]?.fetching ?? true

  return [primaryCategories, fetching]
}

export default useMostPopulatedSearchExperienceCategories

import React, { useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LayoutContainer from 'components/Common/LayoutContainer'
import ContactOptions from 'components/Common/ContactOptions'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'

interface Props {
  className?: string;
}

const Root = styled.div`
  background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
`

const Layout = styled(LayoutContainer)`
  display: flex;
  justify-content: center;
  ${mediaQueryUp.tablet} {
    padding-top: ${rem(4)};
    padding-bottom: ${rem(4)};
  }

`

const ContactOptionsBanner = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className } = props

  const containerRef = useRef<HTMLDivElement>(null)
  useAppHeaderObserver(containerRef)

  useImperativeHandle(ref, () => containerRef.current!)

  return <Root className={className} ref={containerRef}>
    <Layout>
      <ContactOptions />
    </Layout>
  </Root>
})

ContactOptionsBanner.displayName = 'ContactOptionsBanner'

export default ContactOptionsBanner

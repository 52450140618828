import { dummyItemTotalsView, getLEPackageItemTotals } from 'checkout/lib/utils/accommodation/view'
import { isVillaItem } from 'lib/checkout/checkoutUtils'
import { isVillaOffer } from 'lib/offer/offerTypes'
import { createSelector } from 'reselect'
import { nonNullable } from 'lib/array/arrayUtils'
import moment from 'moment'
import { DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import { pluralizeToString } from 'lib/string/pluralize'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'

export const getVillaItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items) => items.filter(isVillaItem),
)

export const getVillaViews = createSelector(
  getVillaItems,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.calendar.calendarsByOccupancy,
  (state: App.State) => state.offer.offerAvailableRatesByOccupancy,
  (items, offers, calendars, rates): App.WithDataStatus<Array<App.Checkout.VillaItemView>> => {
    let hasRequiredData = true
    const views = items.map((item): App.Checkout.VillaItemView | undefined => {
      const offer = offers[item.offerId]

      if (!isVillaOffer(offer)) {
        return undefined
      }

      const key = getPackageUniqueKey(item.packageId, item.duration, item.roomRateId)
      const pkg = offer.packages.find(p => p.uniqueKey === key)
      if (!pkg) {
        return undefined
      }

      const itemTotals = getLEPackageItemTotals(
        offer,
        pkg,
        item.checkIn,
        item.checkOut,
        item.occupancy,
        undefined,
        calendars,
        rates,
      )

      hasRequiredData = !!itemTotals

      return {
        ...(itemTotals ?? dummyItemTotalsView()),
        kind: 'villa',
        item,
        package: pkg,
        offer,
      }
    })

    const data = nonNullable(views)

    return {
      hasRequiredData: hasRequiredData && data.length === items.length,
      data,
    }
  },
)

export const getVillaBreakdownView = createSelector(
  getVillaViews,
  (views): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    const breakdowns = views.data.map((view): App.Checkout.PriceBreakdownView => {
      const occupants = formatOccupantsShort(view.item.occupancy)

      return {
        title: view.offer.name,
        price: view.price,
        memberPrice: view.memberPrice,
        offerType: view.offer.type,
        items: [{
          additionalElements: [],
          additionalInfoText: [],
          itemType: 'villa',
          title: '',
          offerId: view.item.offerId,
          itemId: view.item.itemId,
          price: view.price,
          taxesAndFees: view.taxesAndFees,
          cancellationPolicy: {
            cancellationPolicyDetails: view.package.roomRate?.cancellationPolicy,
            ignoreDynamicCancellationPolicy: view.package.ignoreDynamicCancellationPolicy ?? false,
            useDynamicCancellationPolicies: view.offer?.property?.useDynamicCancellationPolicies ?? false,
            timezoneOffset: view.offer?.property?.timezoneOffset,
            checkInDate: moment(view.item.checkIn),
            checkOutDate: moment(view.item.checkOut),
            occupants: view.item.occupancy,
            offerId: view.item.offerId,
            uniqueKey: view.package.uniqueKey,
          },
        }],
        additionalInfoText: [
          `${moment(view.item.checkIn).format(DMY_CASUAL_SHORT_FORMAT)} → ${moment(view.item.checkOut).format(DMY_CASUAL_SHORT_FORMAT)}`,
          `${pluralizeToString('night', view.item.duration)} (${occupants})`,
        ],
        itemsAlwaysVisible: true,
      }
    })
    return {
      hasRequiredData: true,
      data: breakdowns,
    }
  },
)

import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanRedeemLuxPlusBenefits, isEligibleLuxPlusFreePreview, isFreePreviewLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import useQueryParams from 'hooks/useQueryParams'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { matchPath } from 'react-router'

/**
 * Determines whether to show the UI to sign up for LuxPlus+ free preview
 */
function useLuxPlusFreePreviewUtils(): {
  showFreePreviewSignup: boolean;
  showFreePreviewCarouselSortOrder: boolean;
  shouldAutoEnrolFreePreview: boolean;
  } {
  const freePreviewMember = useAppSelector(isFreePreviewLuxPlusMember)
  const freePreviewEligible = useAppSelector(isEligibleLuxPlusFreePreview)
  const canRedeemLuxPlusBenefits = useAppSelector(checkCanRedeemLuxPlusBenefits)
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isOnHomepage = !!matchPath(currentPath, { path: '/:regionCode', exact: true })
  const queryParams = useQueryParams()
  const isFreePreviewParams = queryParams.get(LUXURY_PLUS.FREE_PREVIEW_QUERY_PARAM) === 'true'
  const canJoinFreePreview = !freePreviewMember && !canRedeemLuxPlusBenefits
  const showFreePreviewCarouselSortOrder = (isFreePreviewParams || freePreviewEligible || freePreviewMember) && !canRedeemLuxPlusBenefits
  const showFreePreviewSignup = (isFreePreviewParams || freePreviewEligible || isOnHomepage) && canJoinFreePreview
  const shouldAutoEnrolFreePreview = queryParams.get(LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM) === 'true' && canJoinFreePreview

  return {
    showFreePreviewSignup,
    showFreePreviewCarouselSortOrder,
    shouldAutoEnrolFreePreview,
  }
}

export default useLuxPlusFreePreviewUtils

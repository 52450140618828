import Modal from 'components/Luxkit/Modal/Modal'
import React from 'react'
import ReserveForZeroPolicyModalContent from './ReserveForZeroPolicyModalContent'

interface Props {
  isOpen?: boolean;
  onClose?: React.MouseEventHandler<HTMLElement>
}

function ReserveForZeroPolicyModal(props: Props) {
  const { isOpen, onClose } = props

  return (
    <Modal
      title="Reserve now for free"
      isOpen={isOpen}
      onClose={onClose}
      height="full"
      tabletHeight="auto"
      subtitle="Reserve a hotel now for free before being charged after 48 hours."
      size="L"
    >
      <ReserveForZeroPolicyModalContent />
    </Modal>
  )
}

export default ReserveForZeroPolicyModal

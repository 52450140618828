import BodyText from 'components/Luxkit/Typography/BodyText'
import React from 'react'

interface Props {
  offer: App.BundleOffer
}

function HotelBundleSearchTileMeta({ offer }: Props) {
  return <BodyText variant="medium">{offer.name}</BodyText>
}

export default HotelBundleSearchTileMeta

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidBrightnessEmptyIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.7 11.3 19.4 9V5.6c0-.6-.4-1-1-1H15l-2.3-2.4c-.4-.4-1-.4-1.4 0L9 4.6H5.6c-.6 0-1 .4-1 1V9l-2.4 2.3c-.4.4-.4 1 0 1.4L4.6 15v3.3c0 .6.4 1 1 1H9l2.3 2.4c.4.4 1 .4 1.4 0l2.3-2.4h3.3c.6 0 1-.4 1-1V15l2.4-2.3c.4-.4.4-1 0-1.4Z"/>
  </SvgIcon>
}

export default SolidBrightnessEmptyIcon

// isPackageDomestic checks if the given package is domestic in the context of the
import { OFFER_TYPE_BED_BANK } from 'constants/offer'

// given regionCode
export const isPackageDomestic = (offer: App.Offer | App.BedbankOffer, pkg: App.Package, regionCode: string): boolean => {
  if (offer.property) {
    return isOfferDomestic(offer, regionCode)
  }

  if (pkg?.tour) {
    return pkg.tour.geoData.countryCode === regionCode
  }

  // If the package neither has property nor tour, the data is malformed. Fallback.
  return false
}

export const isOfferDomestic = (offer: App.Offer | App.BedbankOffer | App.BundledOffer, regionCode: string): boolean => {
  if (offer.type === OFFER_TYPE_BED_BANK) {
    return offer.property.address.countryCode === regionCode
  }
  return offer.property?.geoData.countryCode === regionCode
}

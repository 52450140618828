import PartnershipIcon from 'components/Common/Vendors/Partnership/PartnershipIcon'
import Tooltip from 'components/Luxkit/Tooltip'
import GeoContext from 'contexts/geoContext'
import React, { useContext, useMemo } from 'react'
import IconLabel from './IconLabel'
import Label from './Label'

type Variant = 'default' | 'prominent' | 'icon' | 'icon-prominent';
type LabelType = 'use-only' | 'earn-and-use' | 'earn-points' | 'earn-only';
interface Config extends Omit<React.ComponentProps<typeof Label>, 'children' | 'style'> {
  children: (labelType: LabelType, partnership: App.Partnership, points?: number) => React.ReactNode;
  style?: (partnership: App.Partnership) => React.CSSProperties;
}

const partnershipLabelMap = (partnership: App.Partnership, points?: number): { [key in LabelType]: React.ReactNode } => ({
  'use-only': `Use ${partnership.rewardName}`,
  'earn-and-use': `Earn & Use ${partnership.rewardName}`,
  'earn-points': `Earn ${points ? Math.floor(points).toLocaleString() : 0} ${partnership.rewardName}`,
  'earn-only': `Earn ${partnership.rewardName}`,
})

const variantConfig: Record<Variant, Config> = {
  default: {
    kind: 'plain',
    variant: 'dark',
    startIcon: <PartnershipIcon />,
    children: (labelType, partnership, points) => partnershipLabelMap(partnership, points)[labelType],
  },
  icon: {
    kind: 'secondary',
    variant: 'ghost',
    children: (labelType, partnership, points) => partnershipLabelMap(partnership, points)[labelType],
  },
  'icon-prominent': {
    kind: 'primary',
    variant: 'ghost',
    children: (labelType, partnership, points) => partnershipLabelMap(partnership, points)[labelType],
    // we need to apply the partnerships background colour here
    style: (partnership) => ({
      backgroundColor: partnership.color,
    }),
  },
  prominent: {
    kind: 'primary',
    variant: 'dark',
    startIcon: <PartnershipIcon />,
    children: (labelType, partnership, points) => partnershipLabelMap(partnership, points)[labelType],
    // we need to apply the partnerships background colour here
    style: (partnership) => ({
      backgroundColor: partnership.color,
    }),
  },
}

interface Props {
  /**
   * @default default
   */
  variant?: Variant;
  /**
   * @default true (I have no idea why)
   */
  canEarn?: boolean;
  /**
   * @default true (I have no idea why)
   */
  canUse?: boolean;
  /**
   * The number of points the user will earn
   */
  points?: number;
  className?: string;
}

function PartnershipLabel(props: Props) {
  const { variant = 'default', canEarn = true, canUse = true, className, points } = props
  const { partnership } = useContext(GeoContext)

  const labelType = useMemo<LabelType | undefined>(() => {
    if (partnership) {
      const hasEarn = canEarn && partnership.hasEarn
      const hasUse = canUse && partnership.hasBurn
      if (points) {
        return 'earn-points'
      } else if (hasEarn && hasUse) {
        return 'earn-and-use'
      } else if (hasEarn) {
        return 'earn-only'
      } else if (hasUse) {
        return 'use-only'
      }
    }
  }, [canEarn, canUse, partnership, points])

  const config = useMemo<Config | undefined>(() => {
    if (!partnership) return undefined
    return variantConfig[variant]
  }, [partnership, variant])

  if (!config || !labelType || !partnership) {
    // guess we couldn't do anything
    return null
  }

  if (variant === 'icon' || variant === 'icon-prominent') {
    return <Tooltip
      className={className}
      placement="right"
      description={config.children(labelType, partnership, points)}
    >
      <IconLabel
        {...config}
        style={config.style?.(partnership)}
        icon={<PartnershipIcon />}
      />
    </Tooltip>
  }

  return <Label
    {...config}
    style={config.style?.(partnership)}
    className={className}
    data-testid="partnership-label"
  >
    {config.children(labelType, partnership, points)}
  </Label>
}

export default PartnershipLabel

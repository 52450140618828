import { ValueOf } from 'type-fest'
import { createReducer } from 'lib/redux/reducerUtils'

// NOTE: This state is meant to stay relevant only to the Luxkit modal context.
// No other stateful logic should be handled here.
// If you think something relevant to the Luxkit modal is missing here,
// please consult with the Luxkit maintainers.

export default interface LuxkitModalBehaviourState {
  bodyHasReachedEnd: boolean
  bodyHasReachedStart: boolean
  hasHeader: boolean
  hasFooter: boolean
}

export const MODAL_BEHAVIOUR_INITIAL_STATE: LuxkitModalBehaviourState = {
  bodyHasReachedEnd: true,
  bodyHasReachedStart: true,
  hasHeader: false,
  hasFooter: false,
}

export enum LuxkitModalBehaviourDispatchActions {
  TOGGLE_BODY_HAS_REACHED_END = 'TOGGLE_BODY_HAS_REACHED_END',
  TOGGLE_BODY_HAS_REACHED_START = 'TOGGLE_BODY_HAS_REACHED_START',
  TOGGLE_HEADER = 'TOGGLE_HEADER',
  TOGGLE_FOOTER = 'TOGGLE_FOOTER',
}

export type LuxkitModalBehaviourDispatchAction = ValueOf<Utils.FullActionMap<{
  [LuxkitModalBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_END]: {
    hasReached: LuxkitModalBehaviourState['bodyHasReachedEnd']
  }
  [LuxkitModalBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_START]: {
    hasReached: LuxkitModalBehaviourState['bodyHasReachedStart']
  }
  [LuxkitModalBehaviourDispatchActions.TOGGLE_HEADER]: {
    hasHeader: LuxkitModalBehaviourState['hasHeader']
  }
  [LuxkitModalBehaviourDispatchActions.TOGGLE_FOOTER]: {
    hasFooter: LuxkitModalBehaviourState['hasFooter']
  }
}>>

export const luxkitModalBehaviourStateReducer = createReducer<LuxkitModalBehaviourState, LuxkitModalBehaviourDispatchAction>(
  MODAL_BEHAVIOUR_INITIAL_STATE,
  // @ts-ignore
  {
    [LuxkitModalBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_END]: (_state, action) => ({
      bodyHasReachedEnd: action.hasReached,
    }),
    [LuxkitModalBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_START]: (_state, action) => ({
      bodyHasReachedStart: action.hasReached,
    }),
    [LuxkitModalBehaviourDispatchActions.TOGGLE_HEADER]: (_state, action) => ({
      hasHeader: action.hasHeader,
    }),
    [LuxkitModalBehaviourDispatchActions.TOGGLE_FOOTER]: (_state, action) => ({
      hasFooter: action.hasFooter,
    }),
  },
)

import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LineAngleRightBIcon from 'components/Luxkit/Icons/line/LineAngleRightBIcon'
import { SvgIconProps } from 'components/Luxkit/SvgIcon'

const directions = {
  right: 0,
  down: 90,
  left: 180,
  up: 270,
}

interface Props extends SvgIconProps {
  /**
   * Sets the direction of the arrow
   *
   * @default right
   */
  direction?: 'up' | 'down' | 'left' | 'right';
}

const StyledIcon = styled(LineAngleRightBIcon)`
  transform: rotate(${({ direction }) => direction}deg);
  transition: transform 100ms ease-out;
`

export const CSSArrow = styled.span`
  display: flex;
  align-items: center;
  min-width: ${rem(30)};
  padding-right: ${rem(5)};
  padding-left: ${rem(5)};

  &::after {
    display: block;
    width: ${rem(6)};
    height: ${rem(6)};
    margin-left: ${rem(-8)};
    content: "";
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
  }
`

function Arrow(props: Props) {
  const { direction = 'right', ...rest } = props

  return <StyledIcon
    {...rest}
    direction={directions[direction] || 0}
  />
}

export default Arrow

import cn from 'clsx'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import IconButton from 'components/Luxkit/Button/IconButton'
import noop from 'lib/function/noop'
import Carousel, { DEFAULT_CAROUSEL_GAP } from 'components/Luxkit/Carousel/Carousel'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import LineAngleLeftIcon from '../Icons/line/LineAngleLeftIcon'
import LineAngleRightIcon from '../Icons/line/LineAngleRightIcon'
import { rem } from 'polished'
import LayoutContainer from 'components/Common/LayoutContainer'
import { isNumber } from 'lib/maths/mathUtils'
import { useIsTabletAndSmallerScreen } from 'hooks/useScreenSize'
import { ScrollPosition } from './utils'
import { themeClassName } from 'lib/theme/themeUtils'

const CarouselArrow = styled(IconButton)`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${rem(16)};
  z-index: 1;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s;
  box-shadow: ${props => props.theme.shadow.bottom.medium};

  &.right {
    transform: translateY(-50%);
    left: unset;
    right: ${rem(16)};
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  ${mediaQueryUp.desktop} {
    display: inline-flex;
  }
`

const TheCarousel = styled(Carousel)`
  > * {
    /* Offsets the layout padding - this makes the scroll snap work correct and ignore the padding */
    scroll-padding-left: ${rem(16)};

    ${mediaQueryUp.largeDesktop} {
      /*
        Distance between left side of screen and where the layout container content would start (container - gutter)
        This makes the scroll snap work correctly and ignore the spacer element
      */
      scroll-padding-left: calc(((100vw - var(--scrollbar-width, 0px) - ${rem(1140)}) / 2));
      padding-left: calc(((100vw - var(--scrollbar-width, 0px) - ${rem(1140)}) / 2));
    }
  }
`

const CarouselContainer = styled.div`
  position: relative;

  ${CarouselArrow} {
    opacity: 0;
  }

  &:hover, .input-keyboard & {
    ${CarouselArrow}:not(.hidden) {
      opacity: 0.8;

      &:hover, &:focus {
        opacity: 1;
      }
    }
  }
`

function getScrollPosition(element: HTMLElement, nextLeft?: number): ScrollPosition {
  const scrollEnd = element.scrollWidth - element.offsetWidth
  const actualNextLeft = nextLeft ?? element.scrollLeft
  if (actualNextLeft <= 0) {
    return 'start'
  } else if (actualNextLeft >= scrollEnd) {
    return 'end'
  } else {
    return 'middle'
  }
}

type ArrowDirection = 'left' | 'right'

interface Props extends React.ComponentProps<typeof Carousel>{
  itemsPerArrow?: number;
  onArrowClick?: (e: React.MouseEvent<HTMLButtonElement>, arrow: ArrowDirection) => void;
}

const FullWidthCarousel = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const {
    gap = DEFAULT_CAROUSEL_GAP,
    children,
    pageSize,
    itemsPerArrow = 1,
    onArrowClick = noop,
    onScroll = noop,
    ...carouselProps
  } = props
  const carouselRef = useRef<HTMLDivElement>(null)
  // Where our carousel scroll is. Helps us determine to show arrows among other things
  const [position, setPosition] = useState<ScrollPosition>('start')
  const [showArrows, setShowArrows] = useState<boolean>(!!pageSize && isNumber(pageSize))

  useImperativeHandle(ref, () => carouselRef.current!)

  const requiresArrows = !useIsTabletAndSmallerScreen()
  const onCarouselArrowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (carouselRef.current) {
      const direction = e.currentTarget.dataset.direction as ArrowDirection
      // this carousel travels a number of *items* to the left/right. Items can be of different sizes
      // This means we can't just move a fixed size at a time
      // So lets work out what our current item is, then get appropriate element to scroll to
      const itemElements = Array.from<HTMLElement>(carouselRef.current.children as any)
      // We need to account for how much "blank space" there is at the edge of the screen into our calculations
      // This will always be the offset of the first item
      const edgeOfScreenGap = itemElements[0].offsetLeft

      const scrollPosition = carouselRef.current.scrollLeft + edgeOfScreenGap
      // don't let the next index it go below 0, 0 is our first element. It can't not be at least
      // our first element.
      const currentElementIndex = Math.max(itemElements.findIndex(el => {
        // minus to gap so this element "owns" the left hand side gap of itself
        // otherwise it's 'dead space' that will never match our elements scroll position
        const start = el.offsetLeft - gap
        const end = el.offsetLeft + el.offsetWidth
        return scrollPosition >= start && scrollPosition <= end
      }), 0)

      const nextElementIndex = currentElementIndex + (direction === 'right' ? itemsPerArrow : -itemsPerArrow)
      const nextElement = itemElements[nextElementIndex]
      const nextLeft = nextElement ? nextElement.offsetLeft - edgeOfScreenGap : 0

      setPosition(getScrollPosition(carouselRef.current, nextLeft))
      onArrowClick(e, direction)

      carouselRef.current.scrollTo({
        left: nextLeft,
        behavior: 'smooth',
      })
    }
  }

  const checkShouldShowArrows = useCallback(() => {
    if (carouselRef.current && requiresArrows) {
      // make a best/effecient attempt at trying to work out if we need to show arrows at desktop
      // even though we don't have a page size to work out if we have more items than the page
      const isDifferentSize = carouselRef.current.offsetWidth < carouselRef.current.scrollWidth
      // React.children is not always an accurate measurement of how many elements we want to show
      // e.g. it could be a single fragment (count: 1), or have many falsey values
      // to get around this, lets check how many DOM children we have
      const hasMoreChildren = !!pageSize && isNumber(pageSize) && carouselRef.current.children.length > pageSize

      if (!!pageSize && isNumber(pageSize) && !hasMoreChildren) {
        setShowArrows(false)
        return
      }

      setShowArrows(hasMoreChildren || isDifferentSize)
    }
  }, [pageSize, requiresArrows])

  useEffect(() => {
    // give it an initial check on mount, the onMouseEnter will handle checks after that
    checkShouldShowArrows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMouseEnter = useCallback(() => {
    // use mouseEnter as our event to re-evaluate the position of the carousel
    // just in case the user moved it via some other scroll method
    // this is much more targeted than watching on the 'on scroll' event itself
    // we don't actually show them until they hover anyway!
    if (carouselRef.current && requiresArrows) {
      setPosition(getScrollPosition(carouselRef.current))
      checkShouldShowArrows()
    }
  }, [checkShouldShowArrows, requiresArrows])

  return (
    <LayoutContainer size="full" ref={ref}>
      <CarouselContainer onMouseEnter={onMouseEnter}>
        {showArrows && <CarouselArrow
          kind="secondary"
          variant="dark"
          shape="circle"
          size="large"
          data-direction="left"
          onClick={onCarouselArrowClick}
          className={cn(themeClassName('default'), { hidden: position === 'start' })}
        >
          <LineAngleLeftIcon />
        </CarouselArrow>}
        <TheCarousel
          {...carouselProps}
          onScroll={onScroll}
          ref={carouselRef}
          gutterStyle="overflow"
          gap={gap}
        >
          {children}
        </TheCarousel>
        {showArrows && <CarouselArrow
          kind="secondary"
          variant="dark"
          shape="circle"
          size="large"
          data-direction="right"
          className={cn(themeClassName('default'), 'right', { hidden: position === 'end' })}
          onClick={onCarouselArrowClick}
        >
          <LineAngleRightIcon />
        </CarouselArrow>}
      </CarouselContainer>
    </LayoutContainer>
  )
})

export default React.memo(FullWidthCarousel)

import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import React from 'react'
import CruiseInclusionList from 'components/Cruises/CruiseInclusion/CruiseInclusionList'
interface Props {
  view: CruiseOfferView
  filters?: App.OfferListFilters
}

function CruiseSearchTileFeatures({ view }: Props) {
  return <CruiseInclusionList
    offer={view.offer as App.CruiseOffer}
    promotionDetails={view.promotionDetails}
    evergreenInclusions={view.evergreenInclusions}
    tileInclusions={view.tileInclusions}
    standardInclusions={view.standardInclusions}
    luxPlusInclusions={view.luxPlusInclusions}
  />
}

export default CruiseSearchTileFeatures

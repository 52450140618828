import * as Analytics from 'analytics/analytics'
import { experienceEvent } from 'analytics/snowplow/events'
import { ExperienceEventDefinitionKey, ExperienceEventEntity } from 'analytics/snowplow/helpers'
import GeoContext from 'contexts/geoContext'
import { useCallback, useContext } from 'react'

/**
 * This hooks returns a function that allows you to track some analytic event and enrich
 * it with the current snapshot of the offer state, without waiting for the next re-render.
 *
 * ```
 * const trackEvent = useTrackContextualEvent(...)
 *
 * const someHandler = () => {
 *     dispatch({ type: 'STATE_UPDATE' })
 *     trackEvent('EVENT')
 * }
 *
 * ```
 * @returns A tracking function
 */

function useTrackExperienceOfferEvent() {
  const geo = useContext(GeoContext)
  const trackEvent = useCallback((eventKey: ExperienceEventDefinitionKey, eventEntities?: Array<ExperienceEventEntity>) => {
    Analytics.trackEvent(experienceEvent(eventKey, geo, eventEntities))
  }, [geo])

  return trackEvent
}

export default useTrackExperienceOfferEvent

import Clickable from 'components/Common/Clickable'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import StatusLabel from 'components/Luxkit/Label/StatusLabel'
import React, { MouseEventHandler, useCallback } from 'react'

interface Props {
  /**
   * The percentage the budget is exceeded by.
   */
  exceedingPercentage: number
  onClick: MouseEventHandler<HTMLButtonElement>
}

function PriceRowBusinessTravellerExceededBudget(props: Props) {
  const {
    exceedingPercentage,
    onClick,
  } = props

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()

    onClick(event)
  }, [onClick])

  return <Clickable className={PriceRowBusinessTravellerExceededBudget.name} onClick={handleClick}>
    <StatusLabel variant="pending" endIcon={<SolidInfoCircleIcon />}>
      Exceeds budget by {exceedingPercentage}%
    </StatusLabel>
  </Clickable>
}

export default PriceRowBusinessTravellerExceededBudget

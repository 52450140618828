/**
 * "Hero" style tile. Designed for single item hero carousels.
 * Currently only used on the home page.
 */

import React, { forwardRef, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import classnames from 'clsx'

import offerPageURL from 'lib/offer/offerPageURL'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import {
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
  OFFER_TYPE_VILLA,
} from 'constants/offer'

import { buildSearchParamsFromFilters, buildSuggestedDatesParamsKey } from 'lib/search/searchUtils'
import { TopLevelTileProps } from '../OfferTileTypes'
import config from 'constants/config'
import { getImmersiveTripId } from 'tripPlanner/selectors'
import { connect } from 'react-redux'
import { getSuggestedDates } from 'selectors/offerSelectors'
import noop from 'lib/function/noop'
import { P, match } from 'ts-pattern'
import HeroHotelOfferTile from './HeroHotelOfferTile'
import HeroTourOfferTile from './HeroTourOfferTile'
import NestableClickable from 'components/Common/NestableClickable/NestableClickable'
import { useAppSelector } from 'hooks/reduxHooks'
import HeroCruiseOfferTile from './HeroCruiseOfferTile'
import HeroBedbankOfferTile from './HeroBedbankOfferTile'
import HeroBundleAndSaveOfferTile from './HeroBundleAndSaveOfferTile'
import HeroVillaOfferTile from './HeroVillaOfferTile'
import { isCruiseV1Offer } from 'lib/offer/offerTypes'

const OfferLink = styled(NestableClickable)`
  position: relative;
  display: block;
  height: 100%;

  // Sold out villas return null so need to be hidden
  &:empty {
    display: none;
  }
`

function getTestId(offer: App.AnyOffer) {
  let flightsEnabled: boolean = false
  let onlinePurchaseSchedule: App.OfferSchedule | undefined = undefined

  if (offer.type !== OFFER_TYPE_BED_BANK) {
    const internalOffer = offer as App.Offer

    flightsEnabled = internalOffer.offerFlightsEnabled
    onlinePurchaseSchedule = internalOffer.onlinePurchaseSchedule
  }

  return classnames(
    `offer-${offer.type}`, {
      'offer-with-flights': flightsEnabled,
      [`Purchasable-${offer.type}`]: scheduleIsCurrent(onlinePurchaseSchedule),
    })
}

interface Props extends TopLevelTileProps {
  className?: string;
}

interface MappedStateProps {
  suggestedDates: App.OfferSuggestedDates
}

const HeroOfferTile = forwardRef<HTMLAnchorElement, Props & MappedStateProps>((props, ref) => {
  const {
    offer,
    filters,
    productClick = noop,
    eagerLoadFirstImage,
    offerLinkIncludesFilters = true,
    suggestedDates,
    className,
  } = props

  const tripId = useAppSelector(getImmersiveTripId)
  const offerUrl = useMemo(() => {
    const searchParams = offerLinkIncludesFilters ? buildSearchParamsFromFilters(filters, suggestedDates) : new URLSearchParams()
    if (config.OPEN_NEW_TAB_OFFER_CLICK && tripId) {
      // Make sure immersive trip header persists when opening offer links in new tab
      searchParams.set('tripId', tripId)
    }
    return offerPageURL(offer, searchParams)
  }, [filters, offer, offerLinkIncludesFilters, tripId, suggestedDates])

  const handleClick = useCallback(() => {
    productClick(offer)
  }, [offer, productClick])

  const linkParams = useMemo(() => {
    return {
      onClick: handleClick,
      to: offerUrl,
      'data-testid': getTestId(offer),
      ...(config.OPEN_NEW_TAB_OFFER_CLICK ? { target: '_blank' } : {}),
    }
  }, [offerUrl, offer, handleClick])

  return (<OfferLink {...linkParams} className={className} ref={ref}>
    {match(offer)
      .with({
        parentType: P.union(
          OFFER_TYPE_HOTEL,
          OFFER_TYPE_TOUR,
        ),
      }, (item) => {
        if (item.type === OFFER_TYPE_BUNDLE_AND_SAVE) {
          return (
            <HeroBundleAndSaveOfferTile offer={item as App.BundleOffer | App.BundleOfferSummary} offerUrl={offerUrl}/>
          )
        }

        if (item.type === OFFER_TYPE_BED_BANK) {
          return (
            <HeroBedbankOfferTile offer={item} offerUrl={offerUrl} />
          )
        }

        if (isCruiseV1Offer(item)) {
          return <HeroCruiseOfferTile
            offer={item}
            eagerLoadFirstImage={eagerLoadFirstImage}
            offerUrl={offerUrl}
          />
        }

        if (item.type === OFFER_TYPE_VILLA) {
          return <HeroVillaOfferTile
            offer={item as App.VillaOffer | App.VillaOfferSummary}
            eagerLoadFirstImage={eagerLoadFirstImage}
            offerUrl={offerUrl}
          />
        }

        return (
          <HeroHotelOfferTile
            offer={item}
            eagerLoadFirstImage={eagerLoadFirstImage}
            offerUrl={offerUrl}
          />
        )
      }).with({
        parentType: OFFER_TYPE_TOUR_V2,
      }, (item) => (
        <HeroTourOfferTile
          offer={item}
          eagerLoadFirstImage={eagerLoadFirstImage}
          offerUrl={offerUrl}
        />
      )).with({
        parentType: OFFER_TYPE_CRUISE,
      }, (item) => (
        <HeroCruiseOfferTile
          offer={item}
          eagerLoadFirstImage={eagerLoadFirstImage}
          offerUrl={offerUrl}
        />
      ))
      .otherwise(() => null)
  }
  </OfferLink>
  )
})

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps) => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)
  return {
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
  }
})(HeroOfferTile)

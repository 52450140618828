import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineOneWayFlightIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20.1567 2.8542c-1.3167-1.3167-3.2275-1.015-4.3548.1122l-3.0059 3.006-6.5714-2.494a1 1 0 0 0-1.062.2277L2.689 6.1799a1 1 0 0 0 .2732 1.608l5.4116 2.6067-2.1243 2.1243-2.0124-.8794a1 1 0 0 0-1.1075.2092l-1.8385 1.8385a1 1 0 0 0 0 1.4142l6.6185 6.6185c.3905.3905 1.0237.3905 1.4142 0l1.8385-1.8385a.9998.9998 0 0 0 .2092-1.1075l-.8793-2.0123 2.1243-2.1244.6403 1.3294c.2397.4976.8373.7067 1.3349.467.4976-.2396.7066-.8373.467-1.3349l-1.2519-2.599a.9999.9999 0 0 0-1.608-.2732l-3.6033 3.6032a1 1 0 0 0-.2092 1.1076l.8794 2.0123-.649.649-5.2043-5.2043.649-.649 2.0124.8795a1 1 0 0 0 1.1075-.2093l3.6033-3.6032a1 1 0 0 0-.2732-1.608L5.0997 6.5975l1.02-1.0198 6.5713 2.494a1 1 0 0 0 1.0619-.2279l3.4632-3.4631c.5113-.5114 1.1461-.4926 1.5264-.1123.38.38.3994 1.0146-.1131 1.5274l-3.4201 3.4201a.9998.9998 0 0 0-.2303 1.0553l1.2804 3.4471c.1923.5177.7679.7815 1.2856.5892.5178-.1923.7816-.7679.5892-1.2856l-1.057-2.8455L20.0436 7.21c1.1276-1.128 1.4303-3.0387.1131-4.3558ZM22.9301 18.1269a.9986.9986 0 0 0-.21-.33l-2.5-2.5a1.0041 1.0041 0 1 0-1.42 1.42l.8.79h-3.59a1.0003 1.0003 0 0 0-1 1 1.0001 1.0001 0 0 0 1 1h3.59l-.8.79a1.0019 1.0019 0 0 0-.2189.3254 1.0016 1.0016 0 0 0 0 .7692c.0508.1218.1252.2324.2189.3254a1.0012 1.0012 0 0 0 .71.2958.9994.9994 0 0 0 .71-.2958l2.5-2.5a.9996.9996 0 0 0 .21-.33c.1-.2435.1-.5165 0-.76Z"/>
  </SvgIcon>
}

export default LineOneWayFlightIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSnowFlakeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21 11h-1.59l1.3-1.29a1.0052 1.0052 0 0 0 .2177-1.0943 1.0055 1.0055 0 0 0-.5435-.5434A1.0036 1.0036 0 0 0 19.29 8.29L16.59 11h-2.18l2.3-2.29a1.0052 1.0052 0 0 0 .2177-1.0943 1.0055 1.0055 0 0 0-.5435-.5434A1.0036 1.0036 0 0 0 15.29 7.29L13 9.59V7.41l2.71-2.7a1.0052 1.0052 0 0 0 .2177-1.0943 1.0055 1.0055 0 0 0-.5435-.5434A1.0036 1.0036 0 0 0 14.29 3.29L13 4.59V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 3v1.59l-1.29-1.3a1.0041 1.0041 0 0 0-1.42 1.42L11 7.41v2.18l-2.29-2.3a1.0041 1.0041 0 1 0-1.42 1.42L9.59 11H7.41l-2.7-2.71a1.0041 1.0041 0 0 0-1.42 1.42L4.59 11H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 12.8946 2.7348 13 3 13h1.59l-1.3 1.29a1.001 1.001 0 0 0-.2958.71 1.0003 1.0003 0 0 0 .2958.71 1.001 1.001 0 0 0 .71.2958.9994.9994 0 0 0 .71-.2958L7.41 13h2.18l-2.3 2.29a1.001 1.001 0 0 0-.2958.71 1.0003 1.0003 0 0 0 .2958.71 1.001 1.001 0 0 0 .71.2958.9994.9994 0 0 0 .71-.2958l2.29-2.3v2.18l-2.71 2.7a1.0041 1.0041 0 0 0 1.42 1.42l1.29-1.3V21a1.0001 1.0001 0 0 0 2 0v-1.59l1.29 1.3a.9997.9997 0 0 0 1.42 0 .9997.9997 0 0 0 0-1.42L13 16.59v-2.18l2.29 2.3a.9997.9997 0 0 0 1.42 0 .9997.9997 0 0 0 0-1.42L14.41 13h2.18l2.7 2.71a.9997.9997 0 0 0 1.42 0 .9997.9997 0 0 0 0-1.42L19.41 13H21a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineSnowFlakeIcon

import React, { FormEventHandler, useCallback } from 'react'
import Image from 'components/Common/Image'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import PaneBody from 'components/Common/Pane/PaneBody'
import PaneHeader from 'components/Common/Pane/PaneHeader'
import partnershipUserFields from 'lib/partnership/partnershipUserFields'
import TextInput from 'components/Common/Form/Input/TextInput'
import { getPartnershipFields } from 'selectors/partnershipSelectors'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { linkPartnershipAccount } from 'actions/AuthActions'
import TextButton from '../../Luxkit/Button/TextButton'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'

interface Props {
  partnership: App.Partnership;
  error?: any;
  partnershipFields: Record<string, App.Partnership>;
  dismissable?: boolean;
}

function AccountAccessPartnershipLinkAccount(props: Props) {
  const {
    error,
    partnership,
    partnershipFields,
    dismissable,
  } = props

  const {
    partnershipAccountNumberField,
    otherPartnershipUserFields,
  } = partnershipUserFields(partnership, partnershipFields)

  const hasPartnership = useAppSelector(state => !!state.auth.account.partnerships[partnership.prefix]?.account_id)

  const dispatch = useAppDispatch()

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    const partnershipData = formToObject<any>(event.currentTarget)
    dispatch(linkPartnershipAccount(partnership.prefix, partnershipData))
  }, [dispatch, partnership])

  return (
    <>
      <ModalHeader title={hasPartnership ? 'Your account has been linked' : ''} dismissible={dismissable}/>
      <ModalBody>
        <ModalContent>
          {!hasPartnership && <>
            <PaneHeader size="lg-x">
              <BodyText variant="large" align="center">
                <Image
                  highRes
                  id={partnership.programLogo.id}
                  width={172}
                  alt={`${partnership.programName} logo`}
                />
              </BodyText>
              <br />
              <BodyText variant="large" align="center">
                Link your <BodyText variant="large" as="span" format="titlecase">{partnership.programName}</BodyText> account to earn {partnership.rewardName} on eligible holidays
              </BodyText>
            </PaneHeader>
            <PaneBody size="xl">
              <form onSubmit={onSubmit} data-testid="link-partnership-form">
                <VerticalSpacer gap={24}>
                  {error?.message && <MessageBanner
                    kind="critical"
                    description={error.message}
                  />}
                  {partnershipAccountNumberField && <TextInput
                    aria-label={partnershipAccountNumberField.label}
                    maxLength={partnership.numberMaxLength}
                    name={partnershipAccountNumberField.name}
                    id={partnershipAccountNumberField.name}
                    key={partnershipAccountNumberField.name}
                    defaultValue={partnershipAccountNumberField.value}
                    type="number"
                    required={partnershipAccountNumberField.required}
                    placeholder={partnershipAccountNumberField.label}
                  />}
                  {otherPartnershipUserFields?.map(field => (
                    <TextInput
                      aria-label={field.label}
                      key={field.name}
                      id={field.name}
                      required={field.required}
                      type="text"
                      name={field.name}
                      defaultValue={field.value}
                      placeholder={field.label}
                    />
                  ))}
                  <TextButton
                    kind="primary"
                    type="submit"
                    fit="flex">
                    Save
                  </TextButton>
                </VerticalSpacer>
              </form>
            </PaneBody>
          </>}
        </ModalContent>
      </ModalBody>
    </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    error: state.auth.error,
    partnership: state.geo.partnership,
    partnershipFields: getPartnershipFields(state),
  }
}

export default connect(mapStateToProps)(AccountAccessPartnershipLinkAccount)

import { FetchQuoteParams } from 'actions/InsuranceActions'
import { createSelector } from 'reselect'
import {
  getCheckoutInsuranceDates,
  getCheckoutInsuranceTravellers,
  getCheckoutInsuranceCountries,
} from './insurance'
import getCheckoutInsuranceCoverAmount from './getCheckoutInsuranceCoverAmount'
import { getInsuranceItems } from '../view/insurance'
import { isCruiseCheckout } from '../view/cruise'

/**
 * Retrieves the list of parameters to fetch a quote that can be derived
 * from the checkout state, this includes:
 * - Dates
 * - Travellers
 * - Coverage amount
 * - Destination countries
 */
const getCheckoutInsuranceQuoteParams = createSelector(
  getCheckoutInsuranceDates,
  getCheckoutInsuranceTravellers,
  getCheckoutInsuranceCoverAmount,
  getCheckoutInsuranceCountries,
  (state: App.State) => state.checkout.cart.cartId,
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => getInsuranceItems(state)[0],
  isCruiseCheckout,
  (dates, travellers, coverAmount, destinationCountries, cartId, currency, item, isCruise): Partial<FetchQuoteParams> => {
    return {
      startDate: dates?.startDate,
      endDate: dates?.endDate,
      travellers,
      coverAmount,
      isCruise,
      cartId,
      currency,
      destinationCountries,
      numberOfSeniors: item?.numberOfSeniors ?? 0,
    }
  },
)

export default getCheckoutInsuranceQuoteParams

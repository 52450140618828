import React, { useId } from 'react'
import SearchFloatingPanel, { SearchFloatingPanelProps } from './SearchFloatingPanel'
import TourV2SearchGroupItems from 'components/Search/TourV2/TourV2SearchGroupItems'
import { TourGroupTypesList } from 'components/Search/TourV2/TourV2SearchGroupSelect'

interface Props extends Omit<
  SearchFloatingPanelProps,
  'title' | 'isConfirmButtonHidden' | 'children'
  > {
  onClose: () => void;
}

function TourGroupTypePanel(props: Props) {
  const { onClose } = props
  const formId = useId()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onClose()
  }

  return (
    <SearchFloatingPanel
      {...props}
      title="What type of journey?"
      confirmText="Apply"
    >
      <form onSubmit={onSubmit} id={formId}>
        <TourV2SearchGroupItems groupTypes={TourGroupTypesList} />
      </form>
    </SearchFloatingPanel>
  )
}

export default TourGroupTypePanel

import IconButton from 'components/Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React, { MouseEventHandler, ReactNode, forwardRef } from 'react'
import styled from 'styled-components'
import _DropdownHeader from '../_DropdownHeader'

const Container = styled(_DropdownHeader)`
  display: grid;
  padding: ${rem(16)} ${rem(16)} ${rem(8)};
  grid-template:
    "content dismiss-button" auto
    "extension extension" min-content / 1fr minmax(0, auto);
`

const Content = styled.div`
  grid-area: content;
`

const DismissButton = styled(IconButton)`
  grid-area: dismiss-button;
  transform: translate(${rem(4)}, ${rem(-4)});
  margin-inline-start: ${rem(4)};
`

const Extension = styled.div`
  grid-area: extension;
  padding-top: ${rem(8)};

  &:empty {
    display: none;
  }
`

interface Props {
  /**
   * Controls the size of the title
   */
  size: 'S' | 'M'
  subtitle?: string
  title?: string
  extensionSlot?: ReactNode
  onDismissClick?: MouseEventHandler<HTMLButtonElement> | (() => void)
}

const DropdownListHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    size,
    subtitle,
    title,
    extensionSlot,
    onDismissClick,
  } = props

  return <Container ref={ref}>
    {(!!title || !!subtitle) && <Content>
      {!!title && <Heading variant={size === 'S' ? 'heading6' : 'heading3'}>{title}</Heading>}
      {!!subtitle && <BodyText variant="medium">{subtitle}</BodyText>}
    </Content>}
    {!!extensionSlot && <Extension>{extensionSlot}</Extension>}
    {onDismissClick && <DismissButton
      kind="tertiary"
      size="small"
      shape="square"
      onClick={onDismissClick}
    >
      <LineTimesIcon />
    </DismissButton>}
  </Container>
})

export default DropdownListHeader

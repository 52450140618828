import request from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'
import { agentHubCheckEmailStatusMapper } from './mappers/agentHubMap'

type GetAgentStatusResponse = definitions['userStatusResponse']

async function requestGetAgentStatusByToken(token: string): Promise<App.AgentHubAgentStatus> {
  const response = await request.get<App.ApiResponse<GetAgentStatusResponse>>(`/api/agent/user/status/${token}`)

  return agentHubCheckEmailStatusMapper(response.result)
}

export default requestGetAgentStatusByToken

import { appViewMessageHandler } from 'lib/window/webViewUtils'
import { CreateTripItemBatchResponse } from 'tripPlanner/api/tripItem/types'
import {
  CLOSE_MODAL,
  NATIVE_NAVIGATION,
  NATIVE_NAVIGATION_ERROR,
  TRIP_ITEM_CREATED,
  TRIP_ITEM_DELETED,
} from 'tripPlanner/types/common'

const triggerMobileEvent = (type: string, message: string) => {
  appViewMessageHandler(type, message)
}

export const closeMobileModal = () => {
  triggerMobileEvent(NATIVE_NAVIGATION, CLOSE_MODAL)
}

export const mobileSuccess = (
  isModal: boolean,
  tripId?: string,
  itemId?: string,
) => {
  if (isModal) {
    closeMobileModal()
  } else if (tripId && itemId) {
    triggerMobileEvent(TRIP_ITEM_CREATED, `${tripId} - ${itemId}`)
  }
}

export const mobileDeleteSuccess = (tripId?: string, itemId?: string) => {
  if (tripId && itemId) {
    triggerMobileEvent(TRIP_ITEM_DELETED, `${tripId} - ${itemId}`)
  }
}

export const mobileBatchSuccess = (
  isModal: boolean,
  tripId?: string,
  response?: CreateTripItemBatchResponse,
) => {
  if (tripId && response?.updatedItemIds?.[0]) {
    const itemId = response.updatedItemIds[0]
    mobileSuccess(isModal, tripId, itemId)
  } else {
    mobileSuccess(isModal)
  }
}

export const triggerMobileError = (errorMessage: string) => {
  triggerMobileEvent(NATIVE_NAVIGATION_ERROR, errorMessage)
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMountainsRoadIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m22.2499 16.2613-5.9862-10.641a.9396.9396 0 0 0-1.6374 0l-1.1733 2.086-2.941-5.2275a.9396.9396 0 0 0-1.6374 0L5.63 8.2455l-4.5094 8.0157a.9397.9397 0 0 0 .8187 1.3999h6.599c.0278.9139.3005 1.8459.8138 2.7568.609 1.0805 1.3569 1.7671 1.3884 1.7956a.9378.9378 0 0 0 .6333.2448.9352.9352 0 0 0 .6945-.3066c.349-.3836.3224-.9769-.0612-1.326-.0219-.02-2.178-2.0435-1.4363-4.3015.2624-.7993.8939-1.2078 1.6926-1.7247.8187-.5296 1.7811-1.1526 2.3356-2.3162.1327.1873.2303.3867.2551.5836.0212.1661.0067.4309-.3612.8139-1.0126 1.0563-1.4653 2.1701-1.3459 3.3113.1642 1.5659 1.3611 3.0573 3.7664 4.6923.429.2915 1.0132.1806 1.3047-.2484.2915-.4291.1806-1.0133-.2485-1.3048-1.4629-.9945-2.3895-1.9004-2.7676-2.7028a.938.938 0 0 0 .2418.0327h5.9868a.9383.9383 0 0 0 .8108-.4654.9388.9388 0 0 0 .0085-.9345ZM15.445 7.9965l1.0078 1.7914-1.0654.7823a6.0273 6.0273 0 0 0-.5866-.4678l-.2709-.4812.9151-1.6247ZM9.6933 4.855l1.8368 3.2646-.6363.7818-.6145-.8272a.9413.9413 0 0 0-.749-.3794h-.0049a.9406.9406 0 0 0-.7478.3709l-.983 1.2938-.2932-.6078L9.6933 4.855Zm1.5496 8.3679c-.9.5823-1.9156 1.2399-2.4071 2.5731a.9708.9708 0 0 0-.1527-.0133H3.5459l2.8319-5.0342.3903.8097a.9386.9386 0 0 0 .846.5314.9395.9395 0 0 0 .7478-.3709l1.1538-1.5192.5891.7926a.939.939 0 0 0 1.4823.0328l.932-1.1448.6024 1.0714c-.1969 1.1836-.9132 1.6478-1.8792 2.2726l.0006-.0012Zm4.2015 2.5592a.9532.9532 0 0 0-.0837.0042c.1279-.1969.2903-.3981.4885-.6048.8551-.892.9436-1.7756.8672-2.3604-.0345-.263-.1073-.5121-.206-.7448l.8696-.6387 2.444 4.3445h-4.3796Z"/>
  </SvgIcon>
}

export default LineMountainsRoadIcon

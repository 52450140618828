import { checkCanViewLuxPlusBenefits, checkCanRedeemLuxPlusBenefits, isLuxPlusEnabled, isPendingRenewalLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import {
  API_CALL, CLEAR_LUX_PLUS_MEMBER_SUBSCRIPTION,
  TOGGLE_SHOP_AS_MEMBER,
} from './actionConstants'
import {
  FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION,
  FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION,
  UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION,
  CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW,
  FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY,
  FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD,
} from './apiActionConstants'
import {
  getMemberSubscription,
  getSubscriptionOffer,
  getMemberSubscriptionPoll,
  updateMemberSubscriptionRenewal,
  createMemberFreePreviewPeriod,
  getMemberFreePreviewEligibility,
  MemberSubscriptionUpdateData,
} from 'api/luxPlus'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { getStripeOffSessionCardByRegionAndPurposeId } from 'api/payment'
import { appViewMessageHandler } from 'lib/window/webViewUtils'
import { AppAction } from './ActionTypes'

export function fetchProductSubscription(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (
      !isLuxPlusEnabled(state) ||
      state.luxPlus.products.subscription.offers ||
      state.luxPlus.products.subscription.fetching ||
      state.luxPlus.products.subscription.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION,
      request: () => getSubscriptionOffer(state.geo.currentRegionCode),
    })
  }
}

interface FetchMemberSubscriptionParams {
  refetch?: boolean
  poll?: boolean
  validate?: (result: App.MemberSubscriptionItem) => boolean
}

const checkValidMemberSubscriptionDispatch = (state: App.State, memberId?: string): boolean => {
  if (!memberId || state.auth.account.luxPlus.member.subscription.fetching) {
    return false
  }

  return true
}

export function fetchMemberSubscription(params: FetchMemberSubscriptionParams = {}): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId
    const accessToken = state.auth.accessToken

    const isValidDispatch = checkValidMemberSubscriptionDispatch(state, memberId)
    if (!isValidDispatch) return

    /*
      We want to refetch when a new membership has been bought. We want to prevent fetching
      if service returns an error or if the user account does not have a membership.
     */
    const shouldFetch = !state.auth.account.luxPlus.member.subscription.error && state.auth.account.subscriberTier && !state.auth.account.luxPlus.member.subscription.item && isLuxPlusEnabled(state)
    if (params.refetch || shouldFetch) {
      dispatch({
        type: API_CALL,
        api: FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION,
        request: () => {
          if (params.poll) {
            return getMemberSubscriptionPoll(memberId!, accessToken, params?.validate)
          } else {
            return getMemberSubscription(memberId!, accessToken)
          }
        },
      })
    }
  }
}

export function updateMemberSubscription(data: MemberSubscriptionUpdateData): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId

    const isValidDispatch = checkValidMemberSubscriptionDispatch(state, memberId)
    if (!isValidDispatch) return

    dispatch({
      type: API_CALL,
      api: UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION,
      request: () => updateMemberSubscriptionRenewal(memberId!, data),
    })
  }
}

export function clearMemberSubscription(): AppAction {
  return {
    type: CLEAR_LUX_PLUS_MEMBER_SUBSCRIPTION,
  }
}

export function fetchMemberFreePreviewEligibility(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId
    const freePreview = state.luxPlus.products.freePreview

    if (
      !memberId ||
      !isLuxPlusEnabled(state) ||
      freePreview.fetching ||
      freePreview.error ||
      freePreview.offer
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY,
      request: () => getMemberFreePreviewEligibility(memberId),
    })
  }
}

export function createFreePreviewMember(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId
    const accessToken = state.auth.accessToken
    const subscription = state.auth.account.luxPlus.member.subscription
    const firstName = state.auth.account.givenName
    const headlessMode = state.config.headlessMode
    let joined = false

    if (
      !memberId ||
      !isLuxPlusEnabled(state) ||
      subscription.fetching ||
      subscription.error ||
      checkCanViewLuxPlusBenefits(state)
    ) return

    dispatch({
      type: API_CALL,
      api: CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW,
      request: () => createMemberFreePreviewPeriod({
        customerId: memberId,
        region: state.geo.currentRegionCode,
      }, accessToken)
        .then((result) => {
          joined = true
          showSnackbar(
            `${firstName ? `${firstName}, your` : 'Your'} ${LUXURY_PLUS.FREE_PREVIEW_DURATION}-day free preview for ${LUXURY_PLUS.PROGRAM_NAME} has started. Enjoy!`,
            'success',
          )
          return result
        })
        .catch((err) => {
          showSnackbar(
            'Please try again later or contact us for assistance.',
            'critical',
            { heading: 'Oops! Something went wrong' },
          )

          return err
        })
        .finally(() => {
          if (headlessMode) {
            appViewMessageHandler('luxPlusFreePreview', { joined })
          }
        }),
    })
  }
}

export function fetchLuxPlusPreferredPaymentMethod(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusMemberSubscriptionId = state.auth.account.luxPlus.member.subscription.item?.id
    const canRedeemLuxPlusBenefits = checkCanRedeemLuxPlusBenefits(state)
    const pendingRenewalSubscribedLuxPlusMember = isPendingRenewalLuxPlusMember(state)

    if (!luxPlusMemberSubscriptionId || (!canRedeemLuxPlusBenefits && !pendingRenewalSubscribedLuxPlusMember)) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD,
      request: () => getStripeOffSessionCardByRegionAndPurposeId(state.geo.currentRegionCode, luxPlusMemberSubscriptionId),
    })
  }
}

export function toggleDisableMembership(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const disableMembership = state.auth.account.luxPlus.member.disableMembership
    dispatch({
      type: TOGGLE_SHOP_AS_MEMBER,
      disableMembership: !disableMembership,
    })
  }
}

import config from 'constants/config'
import qs from 'qs'
import {
  TYPE_OF_PAGE_DESTINATION,
  TYPE_OF_PAGE_HOLIDAY,
  TYPE_OF_PAGE_BNBL,
  TYPE_OF_PAGE_CONTENT_HUB,
} from 'constants/landingPage'
import { SITEWIDE_NOTIFICATION_SLUG } from 'constants/content'
import request from 'api/requestUtils'
import { ANYWHERE_PLACE_ID } from 'constants/search'

interface ServerArticleImage {
  code: string;
  title: {
    rendered: string
  };
  media_details: {
    sizes: {
      full: {
        source_url: string
      }
    }
  }
}

function image({ code, title, media_details }: ServerArticleImage) {
  if (code) {
    return {
      src: '',
      alt: '',
    }
  }

  return {
    src: media_details.sizes.full.source_url,
    alt: title.rendered,
  }
}

interface ServerArticle {
  id: string;
  link: string;
  title: {
    rendered: string;
  };
  _embedded: {
    'wp:featuredmedia': [ServerArticleImage];
  };
  slug: string;
}

const magazineHost = 'https://magazine-redux.luxmicro.net/magazine'
function articleMap({ id, link, title, _embedded, slug }: ServerArticle) {
  const [media] = _embedded['wp:featuredmedia']
  // this is the current host for the new magazine, which redirects to LE
  // this URL is what is returned from WP API, replacing that value with LE portal
  return {
    id,
    slug,
    link: link.replace(magazineHost, config.MAGAZINE_PORTAL),
    title: title.rendered,
    image: image(media),
  }
}

function destinationPageMap(page) {
  const { fields } = page
  const search = fields.locations
  return {
    title: fields.title,
    titleSuffix: 'Escapes',
    subTitle: fields.destinationSubtitle,
    metaTitle: fields.metaTitle || '',
    metaDescription: fields.metaDescription || '',
    slug: fields.slug,
    image: fields.headerImage,
    search: config.hasMagazine && search,
    filters: {
      placeIds: fields.placeIds ?? [ANYWHERE_PLACE_ID],
      useUnifiedSearchEndpoint: true,
    },
    content: {
      title: fields.content ? fields.content.title : '',
      description: fields.content ? fields.content.description : '',
      video: fields.content ? fields.content.videoUrl : '',
      subscriptionTitle: `Subscribe to receive exclusive hotel offers to ${page.fields.title} and other amazing destinations`,
      subscriptionButton: 'Sign me up',
    },
    backgroundColour: fields.backGroundColour,
  }
}

function holidayPageMap(page) : App.ContentHolidayPage {
  const { fields } = page
  const search = fields.types
  return {
    title: fields.title,
    titleSuffix: '',
    subTitle: fields.subTitle,
    metaTitle: fields.metaTitle || '',
    metaDescription: fields.metaDescription || '',
    slug: fields.slug,
    image: fields.headerImage,
    types: fields.types,
    search: config.hasMagazine && search,
    hideSignUpOverlay: fields.hideSignUpOverlay || false,
    filters: {
      holidayTypes: search,
    },
    content: {
      title: fields.content ? fields.content.title : '',
      description: fields.content ? fields.content.description : '',
      video: fields.content ? fields.content.videoUrl : '',
      subscriptionTitle: 'Subscribe to receive exclusive hotel offers to amazing destinations',
      subscriptionButton: 'Sign me up',
    },
  }
}

function bnblTypeLandingPageMap(page): App.ContentBNBLPage {
  const { fields } = page
  return {
    header: {
      title: fields.title,
      subTitle: fields.subTitle,
      metaTitle: fields.metaTitle,
      metaDescription: fields.metaDescription,
      image: fields.headerImage,
    },
    empowerment: {
      title: fields.empowermentTitle,
      description: fields.empowermentDescription,
    },
    howItWorks: {
      title: fields.howItWorksTitle,
      description: fields.howItWorksTiles.map(item => ({
        icon: {
          title: item.icon.fields.title,
          url: 'https:' + item.icon.fields.file.url,
        },
        title: item.title,
        text: item.text,
      })),
    },
    plus: {
      title: fields.plusTitle,
    },
    faq: {
      title: fields.faq?.title,
      slug: fields.faq?.slug,
    },
    offerList: {
      title: fields.offerListTitle,
      btnText: fields.offerListButtonText,
      numberOfOffers: fields.numberOfOffers,
    },
    backgroundColour: fields.backGroundColour,
  }
}

function aboutUsPageMap(page) {
  const { fields } = page
  return {
    page_title: fields.pageTitle,
    sections: fields.sections.map(
      item => ({
        link_label: item.linkLabel,
        hash: item.hash,
      }),
    ),
    vision: {
      title: fields.visionTitle,
      section_intro: fields.visionSectionIntro,
      content: {
        title: fields.visionContentTitle,
        steps: fields.visionContentSteps.map(item => ({ step_text: item }),
        ),
      },
    },
    company: {
      title: fields.companyTitle,
      section_intro: fields.companySectionIntro,
      content: fields.companyContent,
    },
    leadership: {
      title: fields.leadershipTitle,
      section_intro: fields.leadershipSectionIntro,
      leaders: fields.leadershipLeaders,
    },
    careers: {
      title: fields.careersTitle,
      section_intro: fields.careersSectionIntro,
      content: fields.careersContent,
      mission: fields.careersMission,
      values: fields.careersValues,
    },
    media: {
      title: fields.mediaTitle,
      content: fields.mediaContent,
      articles: fields.mediaArticles,
    },
  }
}

function getDestinationPage(slug: string) {
  return request.get<App.ApiResponse<any>>(`/api/content/destinationPage/${slug}`)
    .then(data => {
      if (data.result) {
        return destinationPageMap(data.result[0])
      }
    })
}

function getHolidayPage(slug: string) {
  return request.get<App.ApiResponse<any>>(`/api/content/holidayPage/${slug}`)
    .then(data => {
      if (data.result) {
        return holidayPageMap(data.result[0])
      }
    })
}

export function getLandingPage(slug: string, type: string, regionCode: string): Promise<any> {
  switch (type) {
    case TYPE_OF_PAGE_DESTINATION:
      return getDestinationPage(slug)
    case TYPE_OF_PAGE_HOLIDAY:
      return getHolidayPage(slug)
    case TYPE_OF_PAGE_BNBL:
      return getBNBLTypeLandingPage(slug)
    case TYPE_OF_PAGE_CONTENT_HUB:
      return getContentHubPage(slug, regionCode)
    default:
      return Promise.resolve()
  }
}

export function getAboutUsPage() {
  return request.get<App.ApiResponse<any>>('/api/content/aboutUs')
    .then(data => {
      if (data.result) {
        return aboutUsPageMap(data.result[0])
      }
    })
}

export function getLegalPage(slug: string) {
  return request.get<App.ApiResponse<any>>(`/api/content/legalPages/${slug}`)
    .then(data => {
      if (data.result) {
        return {
          title: data.result[0].fields.title,
          pageContent: data.result[0].fields.pageContent.map(page => ({
            title: page.title,
            slug: page.slug,
            tags: page.tags,
            contentTypeId: page.contentTypeId,
            description: page.description,
            answer: page.answer,
          })),
          backgroundColour: data.result[0].fields.backGroundColour,
        }
      }
    })
}

export function getDestinationProductPage(slug: string) {
  return request.get<App.ApiResponse<any>>(`/api/content/pageDestinationProduct/${slug}`)
    .then(data => {
      if (data.result) {
        return {
          pageContent: data.result[0].fields.pageContent.map(page => ({
            title: page.title,
            answer: page.answer,
            slug: page.slug,
            tags: page.tags,
            contentTypeId: page.contentTypeId,
            description: page.description,
          })),
        }
      }
    })
    .catch(() => null)
}

export function getNotificationBanner(slug: string, regionCode: string): Promise<App.NotificationBanner | null | undefined> {
  if (slug === SITEWIDE_NOTIFICATION_SLUG) {
    return request.get<App.ApiResponse<any>>(`/api/content/componentTopBanner?region=${regionCode}&tags=${slug}`)
      .then(data => {
        if (data.result) {
          return data.result[0].fields as App.NotificationBanner
        }
      })
      .catch(e => {
        if (e.status === 404) {
          return null
        }
        throw new Error(e)
      })
  }

  return request.get<App.ApiResponse<any>>(`/api/content/componentTopBanner/${slug}?region=${regionCode}`)
    .then(data => {
      if (data.result) {
        return data.result[0].fields as App.NotificationBanner
      }
    })
    .catch(e => {
      if (e.status === 404) {
        return null
      }
      throw new Error(e)
    })
}

export function getContentHubPage(slug: string, regionCode: string) {
  return request.get<App.ApiResponse<App.ContentHubPage>>(`/api/content/${TYPE_OF_PAGE_CONTENT_HUB}/${slug}?region=${regionCode}`)
    .then(data => {
      const article = data.result[0]

      return {
        ...article,
        backgroundColour: article.backGroundColour,
        sections: article.sections.map(section => {
          if (section.contentTypeId === 'legalPages') {
            return {
              ...section,
              pageContent: section.pageContent.map(item => ({
                type: item.sys.contentType.sys.id,
                fields: item.fields,
              })),
            }
          }
          return section
        }),
      }
    })
}

export function getMagazineArticlesByTags(tags: Array<string>, numberOfPosts = 3) {
  const query = qs.stringify({
    tags: tags.toString(),
    numberOfPosts,
  })

  return request.get<App.ApiResponse<Array<ServerArticle>>>(`/api/content/magazine/posts?${query}`)
    .then(data => data.result.map(articleMap))
}

export function getBNBLTypeLandingPage(slug: string) {
  return request.get<App.ApiResponse<any>>(`/api/content/bnbl/${slug}`)
    .then(data => {
      if (data.result) {
        return bnblTypeLandingPageMap(data.result[0])
      }
    })
}

export function getLETVEpisodes() {
  return request.get<App.ApiResponse<App.MarketingLeTVConfig>>('/api/content/le-tv')
}
type GetLinkBoxProps = {
  slug:string
  region:string
}

export function getLinkBox({
  slug,
  region,
}:GetLinkBoxProps) {
  const query = qs.stringify({
    slug,
    region,
  })
  return request.get<App.ApiResponse<App.ContentLinkBoxContainer>>(`/api/content/linkbox?${query}`)
    .then((data => ({
      brand: data.result.brand,
      region: data.result.region,
      title: data.result.title,
      linkBoxJson: data.result.linkBoxJson,
    })))
}

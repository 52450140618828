import { createReducer } from 'lib/redux/reducerUtils'
import { ValueOf } from 'type-fest'

export default interface DropdownBehaviourState {
  bodyHasReachedEnd: boolean
  bodyHasReachedStart: boolean
  hasHeader: boolean
  hasFooter: boolean
}

export const DROPDOWN_BEHAVIOUR_INITIAL_STATE: DropdownBehaviourState = {
  bodyHasReachedEnd: true,
  bodyHasReachedStart: true,
  hasHeader: false,
  hasFooter: false,
}

export enum DropdownBehaviourDispatchActions {
  TOGGLE_BODY_HAS_REACHED_END = 'TOGGLE_BODY_HAS_REACHED_END',
  TOGGLE_BODY_HAS_REACHED_START = 'TOGGLE_BODY_HAS_REACHED_START',
  TOGGLE_HEADER = 'TOGGLE_HEADER',
  TOGGLE_FOOTER = 'TOGGLE_FOOTER',
}

export type DropdownBehaviourDispatchAction = ValueOf<Utils.FullActionMap<{
  [DropdownBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_END]: {
    hasReached: DropdownBehaviourState['bodyHasReachedEnd']
  }
  [DropdownBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_START]: {
    hasReached: DropdownBehaviourState['bodyHasReachedStart']
  }
  [DropdownBehaviourDispatchActions.TOGGLE_HEADER]: {
    hasHeader: DropdownBehaviourState['hasHeader']
  }
  [DropdownBehaviourDispatchActions.TOGGLE_FOOTER]: {
    hasFooter: DropdownBehaviourState['hasFooter']
  }
}>>

export const dropdownBehaviourStateReducer = createReducer<DropdownBehaviourState, DropdownBehaviourDispatchAction>(
  DROPDOWN_BEHAVIOUR_INITIAL_STATE,
  // @ts-ignore
  {
    [DropdownBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_END]: (_state, action) => ({
      bodyHasReachedEnd: action.hasReached,
    }),
    [DropdownBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_START]: (_state, action) => ({
      bodyHasReachedStart: action.hasReached,
    }),
    [DropdownBehaviourDispatchActions.TOGGLE_HEADER]: (_state, action) => ({
      hasHeader: action.hasHeader,
    }),
    [DropdownBehaviourDispatchActions.TOGGLE_FOOTER]: (_state, action) => ({
      hasFooter: action.hasFooter,
    }),
  },
)

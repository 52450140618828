import {
  INSTALMENT_PAYMENT_REFUND_STATUSES,
  INSTALMENT_STATUS,
} from 'constants/payment'
import { DMY_CASUAL_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { partitionBy } from 'lib/array/arrayUtils'

export const calculateDaysTilNextAttemptedPayment = (instalmentPlan: string, dueDate: string): number => {
  let daysToAdd = 0

  switch (instalmentPlan) {
    case 'weekly':
      daysToAdd = 3
      break
    case 'fortnightly':
      daysToAdd = 5
      break
    case 'monthly':
      daysToAdd = 7
      break
    default:
      break
  }

  const nextAttemptDate = moment(dueDate).add(daysToAdd, 'days').format(ISO_DATE_FORMAT)

  return calculateDaysBetweenDates(nextAttemptDate)
}

export const calculateDaysBetweenDates = (endDate: string, startDate?: string): number => {
  const end = moment(endDate)
  const start = moment(startDate) ?? moment() // use current date if no start date passed in
  return Math.abs(Math.ceil(moment.duration(end.diff(start)).asDays()))
}

const isInitialCreditAppliedInstalment = (instalment: App.Instalment): boolean => {
  return instalment.instalments_number === 0 &&
  instalment.instalment_status === INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN &&
  !!instalment.metadata?.is_credit
}

export const getInitialPaymentAmount = (order: App.Order): App.OrderPayment => {
  for (const payment of order.payments) {
    if (payment.amount === order.instalmentDetails?.initial_payment_amount) {
      return payment
    }
  }
  return order.payments[order.payments.length - 1]
}

export const createInstalmentBreakdown = (order: App.Order): Array<App.Instalment> => {
  const instalmentDetails = order.instalmentDetails
  const initialPayment = getInitialPaymentAmount(order)
  const payments = instalmentDetails?.instalmentPaymentDetails ?? [] as Array<App.Instalment>
  const [refundedPayments, nonRefundedPayments] = partitionBy(payments, payment => INSTALMENT_PAYMENT_REFUND_STATUSES.has(payment.instalment_status))
  const [initialCreditPayments, instalmentPayments] = partitionBy(nonRefundedPayments, isInitialCreditAppliedInstalment)

  // We want to sum instalment amount for all duplicated entries. This occurs when instalments are paid with multiple payment types
  // e.g. stripe and credits. There will be 2 entries in instalmentPayments array. We only want to display 1 row per instalment.
  const instalmentPaymentsByInstalmentNumber = instalmentPayments.reduce((acc: Array<App.Instalment>, val: App.Instalment) => {
    const multiplePayment = acc.find(payment => payment.instalments_number === val.instalments_number)
    if (multiplePayment) {
      multiplePayment.instalment_amount = +multiplePayment.instalment_amount + +val.instalment_amount // instalment_amount type is string but need to add them as numbers
    } else {
      acc.push(val)
    }
    return acc
  }, [])

  const instalmentBreakdown: Array<App.Instalment> = []

  instalmentBreakdown.push({
    id: 0,
    instalments_number: 0,
    instalment_amount: initialPayment.amount,
    instalment_due_date: initialPayment.createdAt,
    instalment_paid_date: initialPayment.createdAt,
    currency: order.currencyCode,
    instalment_status: INSTALMENT_STATUS.INITIAL_PAYMENT_TAKEN,
    due_balance_auto_debit_failed_count: 0,
    created_at: initialPayment.createdAt,
  })

  if (initialCreditPayments.length > 0) {
    instalmentBreakdown.push(...initialCreditPayments.map(i => {
      return {
        ...i,
        instalment_status: INSTALMENT_STATUS.CREDIT_APPLIED,
      } as App.Instalment
    }))
  }

  if (instalmentDetails?.is_active) {
    for (let i = 0; i < instalmentDetails.total_instalments_count; i++) {
      const currentInstalment = instalmentPaymentsByInstalmentNumber[i]
      const lastInstalment = i === instalmentDetails.total_instalments_count - 1

      // if there is a payment and it is not a refund add it otherwise create instalment row
      if (currentInstalment && !INSTALMENT_PAYMENT_REFUND_STATUSES.has(currentInstalment.instalment_status)) {
        instalmentBreakdown.push(currentInstalment)
      } else {
        const lastInstalmentAmount = instalmentDetails.paid_instalments_count === instalmentDetails.total_instalments_count - 1 ? instalmentDetails.balance_amount : instalmentDetails.last_instalment_amount

        instalmentBreakdown.push({
          id: i + 1,
          instalments_number: i + 1,
          instalment_amount: lastInstalment ? lastInstalmentAmount : instalmentDetails.per_instalment_amount,
          instalment_due_date: instalmentDetails.scheduled_instalment_dates[i],
          instalment_paid_date: null,
          currency: order.currencyCode,
          instalment_status: INSTALMENT_STATUS.PAYMENT_UPCOMING,
          due_balance_auto_debit_failed_count: 0,
        })
      }
    }
  } else {
    // if not active order than push all non refunded payments
    instalmentBreakdown.push(...(instalmentPaymentsByInstalmentNumber ?? []))
  }

  // push refunded payment row and sort by date
  if (refundedPayments.length > 0) {
    // set paid date as created date
    instalmentBreakdown.push(...refundedPayments.map(i => {
      return {
        ...i,
        instalment_paid_date: i.created_at,
      } as App.Instalment
    }))
    instalmentBreakdown.sort((a, b) => moment(a.created_at) < moment(b.created_at) ? -1 : 1)
  }
  // loop through array and set ids to correct order after adding refund payment
  instalmentBreakdown.forEach((i, idx) => i.id = idx)

  return instalmentBreakdown
}

export const getNextInstalmentDueDate = (instalmentDetails: App.InstalmentDetails): string => {
  // Get the scheduled date based on how many paid instalments. E.g. When 2 instalments paid, get 3rd element (index 2)
  return moment(instalmentDetails.scheduled_instalment_dates[instalmentDetails.paid_instalments_count]).format(DMY_CASUAL_FORMAT)
}

export const isLastInstalment = (instalmentDetails: App.InstalmentDetails): boolean => {
  return instalmentDetails.paid_instalments_count === instalmentDetails.total_instalments_count - 1
}

export const isInstalmentFullyPaidOff = (instalmentDetails: App.InstalmentDetails): boolean => {
  return (['payment_taken_complete', 'payment_manual_debit_taken_complete'].includes(instalmentDetails.instalment_status))
}

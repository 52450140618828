import ReCAPTCHA from 'react-google-recaptcha'

export enum ReCAPTCHA_ERRORS {
  NOT_INITIATED = 'ReCAPTCHA was not initiated!',
  FAILED = 'ReCAPTCHA failed!',
}

async function handleReCAPTCHA(recaptcha: ReCAPTCHA | null): Promise<string> {
  if (!recaptcha) {
    return Promise.reject(ReCAPTCHA_ERRORS.NOT_INITIATED)
  }

  const recaptchaResponse = await recaptcha.executeAsync()

  if (!recaptchaResponse) {
    return Promise.reject(ReCAPTCHA_ERRORS.FAILED)
  }

  return recaptchaResponse
}

export default handleReCAPTCHA

import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalContext from 'contexts/ModalContext'
import React, { MouseEventHandler, useCallback, useContext } from 'react'

function SearchTileSignUpButton() {
  const showModal = useContext(ModalContext)
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    showModal(<AccountAccessModal />)
  }, [showModal])

  return <TextButton kind="secondary" nonInteractable onClick={handleClick}>
    Sign up for free
  </TextButton>
}

export default SearchTileSignUpButton

import Group from 'components/utils/Group'
import React, { ReactElement } from 'react'

interface Props {
  startSlot: ReactElement | false
  endSlot: ReactElement | false
  className?: string
  'data-testid'?: string
}

function _PriceSummaryRow(props: Props) {
  const { startSlot, endSlot, className, 'data-testid': dataTestId } = props

  return <Group
    className={className}
    data-testid={dataTestId}
    direction="horizontal"
    horizontalAlign="space-between"
    gap={16}
  >
    {startSlot}
    {endSlot}
  </Group>
}

export default _PriceSummaryRow

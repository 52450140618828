import { mediaQueryUp } from 'components/utils/breakpoint'
import { getNextNMonths } from 'lib/search/searchUtils'
import { rem } from 'polished'
import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import ToggleButton from 'components/Luxkit/ToggleButton'
import Group from 'components/utils/Group'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineAngleLeftBIcon from 'components/Luxkit/Icons/line/LineAngleLeftBIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import LineAngleRightBIcon from 'components/Luxkit/Icons/line/LineAngleRightBIcon'

const SelectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${rem(12)};
  align-items: center;
  justify-content: center;

  ${mediaQueryUp.desktop} {
    grid-template-columns: repeat(6, 1fr);
  }
`

interface Props {
  previouslySelectedMonths?: string
  onMonthChange?: (monthOptions: string) => void
  className?: string
  showYearSelector?: boolean
}

export interface MonthOption {
  monthText: string
  monthNumber: string
  year: string
}

const currentYear = new Date().getFullYear()

function MonthPicker(props: Props) {
  const { onMonthChange, previouslySelectedMonths, showYearSelector } = props
  const [monthsSelected, setMonthsSelected] = useState<Set<string>>(new Set(previouslySelectedMonths?.split(',')))
  const [selectedYear, setSelectedYear] = useState<number>(currentYear)

  const months = useMemo(() => getNextNMonths({ monthsRequired: showYearSelector ? 24 : 12 }), [showYearSelector])

  const nextYear = useCallback(() => {
    setSelectedYear(selectedYear + 1)
  }, [selectedYear])

  const prevYear = useCallback(() => {
    setSelectedYear(selectedYear - 1)
  }, [selectedYear])

  const monthOptions = useMemo(() => {
    if (!showYearSelector) return months
    return months.filter((month) => month.year === selectedYear.toString())
  }, [months, selectedYear, showYearSelector])

  const onMonthClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const month = event.currentTarget.dataset.option!
      setMonthsSelected((currentMonthsSelected) => {
        if (currentMonthsSelected.has(month)) {
          const newMonthSelection = new Set(currentMonthsSelected)
          newMonthSelection.delete(month)
          onMonthChange?.(Array.from(newMonthSelection).toString())
          return newMonthSelection
        } else {
          const newMonthSelection = new Set([...currentMonthsSelected, month])
          onMonthChange?.(Array.from(newMonthSelection).filter((month) => month).toString())
          return newMonthSelection
        }
      })
    },
    [onMonthChange],
  )
  if (showYearSelector) {
    return (<Group direction="vertical">
      <Group direction="horizontal" fullWidth horizontalAlign="center" verticalAlign="center">
        <IconButton
        kind="tertiary"
        onClick={prevYear}
        disabled={selectedYear === currentYear}
      >
          <LineAngleLeftBIcon />
        </IconButton>

        <Heading variant="heading6">{selectedYear}</Heading>

        <IconButton
        kind="tertiary"
        onClick={nextYear}
        disabled={selectedYear === (currentYear + 2)}
      >
          <LineAngleRightBIcon />
        </IconButton>
      </Group>
      <SelectionContainer data-testid="flexibleMonthPicker" className={props.className}>
        {monthOptions.map((option) => {
          const selectionId = `${option.year}-${option.monthNumber}`
          const isActive = monthsSelected.has(selectionId)
          return (
            <ToggleButton
            key={selectionId}
            data-testid={`date-option-${selectionId}`}
            data-option={selectionId}
            kind="secondary"
            title={option.monthText}
            isSelected={isActive}
            isMultiSelect
            onClick={onMonthClick}
          />
          )
        })}
      </SelectionContainer>
    </Group>
    )
  }

  return (
    <SelectionContainer data-testid="flexibleMonthPicker" className={props.className}>
      {monthOptions.map((option) => {
        const selectionId = `${option.year}-${option.monthNumber}`
        const isActive = monthsSelected.has(selectionId)
        return (
          <ToggleButton
            key={selectionId}
            data-testid={`date-option-${selectionId}`}
            data-option={selectionId}
            kind="secondary"
            title={option.monthText}
            subtitle={option.year}
            isSelected={isActive}
            isMultiSelect
            onClick={onMonthClick}
          />
        )
      })}
    </SelectionContainer>
  )
}

export default MonthPicker

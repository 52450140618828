import clsx from 'clsx'
import { rem } from 'polished'
import React, { forwardRef, PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  container: search-tile / inline-size;
  display: grid;
  grid-template: 1fr / 1fr;
`

export const _searchTileContainerQueryUp = {
  tablet: `@container search-tile (width >= ${rem(578)})`,
  desktopSmall: `@container search-tile (width >= ${rem(670)})`,
  desktop: `@container search-tile (width >= ${rem(890)})`,
}

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${_searchTileContainerQueryUp.tablet} {
    border-radius: ${props => props.theme.borderRadius.S};
    border: 1px solid ${props => props.theme.palette.neutral.default.five};

    &.state-default {
      border: 1px solid ${props => props.theme.palette.neutral.default.five};
    }
    &.state-highlighted {
      border: 1px solid ${props => props.theme.palette.highlight.secondary.normalForeground};
    }
  }
`

const Layout = styled.div`
  display: grid;
  min-height: ${rem(300)};
  grid-template:
    "hero" ${rem(172)}
    "content" auto / 1fr;

  ${_searchTileContainerQueryUp.tablet} {
    grid-template:
      "hero" ${rem(200)}
      "content" auto / 1fr;
  }

  ${_searchTileContainerQueryUp.desktopSmall} {
    grid-template: "hero content" auto / ${rem(300)} minmax(0, 1fr);
  }

  ${_searchTileContainerQueryUp.desktop} {
    grid-template: "hero content" auto / ${rem(410)} minmax(0, 1fr);
  }
`

const Banner = styled.div`
  &:empty {
    display: none;
  }
`

interface Props extends PropsWithChildren, Utils.PropsWithDataAttributes {
  /**
   * @default default
   */
  state?: 'default' | 'highlighted'
  banner?: ReactElement | false
  className?: string
}

const _SearchTileBase = forwardRef<HTMLDivElement, Props>(function _SearchTileBase(
  {
    state = 'default',
    banner,
    className,
    children,
    ...rest
  },
  ref,
) {
  return <Container {...rest} ref={ref} className={clsx(_SearchTileBase.name, className)}>
    <LayoutWrapper className={`state-${state}`}>
      {!!banner && <Banner>{banner}</Banner>}
      <Layout>
        {children}
      </Layout>
    </LayoutWrapper>
  </Container>
})

export default _SearchTileBase

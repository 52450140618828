// Location name is a full address like 'Exmouth WA 6707, Australia' or just cityname like 'Exmouth'
function getExperienceLocations(locationName: string): { destinationCity: string | null, originCity: string | null, destinationCountry: string | null, originCountry: string | null } {
  if (!locationName) { return {
    destinationCity: null,
    originCity: null,
    destinationCountry: null,
    originCountry: null,
  } }
  const locations = locationName.split(' ')

  if (locations.length > 1) {
    const city = locations[0]
    const country = locations.pop() ?? null

    return {
      destinationCity: city,
      destinationCountry: country,
      originCity: city,
      originCountry: country,
    }
  } else {
    return {
      destinationCity: locationName,
      originCity: locationName,
      destinationCountry: null,
      originCountry: null,
    }
  }
}

export {
  getExperienceLocations,
}

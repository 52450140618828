import { rem } from 'polished'
import styled from 'styled-components'

const BaseNotificationDot = styled.div`
  height: ${rem(6)};
  width: ${rem(6)};
  border-radius: ${(props) => props.theme.borderRadius.round};
`

export default BaseNotificationDot

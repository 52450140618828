import React from 'react'
import SearchForm, { SearchFormRef } from 'components/Search/SearchForm'
import { HOMES_AND_VILLAS_LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL, HOMES_AND_VILLAS_TYPEAHEAD_TYPES } from 'constants/search'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { useGlobalHomesAndVillasSearch } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHomesAndVillasSearch'
import { HOMES_AND_VILLAS_FILTERS } from 'constants/offerList'
// import { useGlobalSearchRestoreFromStorage } from 'hooks/GlobalSearch/useGlobalSearchRestore'

interface Props {
  searchFormRef?: React.Ref<SearchFormRef>
}

function GlobalHomesAndVillasSearchTabContent(props: Props) {
  const { searchFormRef } = props
  // TODO: restoring these vals is yet to be confirmed with the Search product
  // const {
  //   restoredCheckinDate,
  //   restoredCheckoutDate,
  //   restoredOccupancies,
  //   restoredRecentSearchItems,
  // } = useGlobalSearchRestoreFromStorage()

  const { globalHomesAndVillasSearchDispatch, globalHomesAndVillasSearchState } = useGlobalHomesAndVillasSearch({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    // checkinDate: restoredCheckinDate,
    // checkoutDate: restoredCheckoutDate,
    // occupancies: restoredOccupancies,
  })

  return <GlobalSearchContextProvider
    state={globalHomesAndVillasSearchState}
    dispatch={globalHomesAndVillasSearchDispatch}
  >
    <SearchForm
      submitOnApply
      saleUnit="Property"
      filters={HOMES_AND_VILLAS_FILTERS}
      typeaheadTypes={HOMES_AND_VILLAS_TYPEAHEAD_TYPES}
      ref={searchFormRef}
      baseSearchPath="/search/homes-and-villas"
      baseSearchMapPath="/search/homes-and-villas/map"
      locationSearchPlaceholder={HOMES_AND_VILLAS_LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL}
    />
  </GlobalSearchContextProvider>
}

export default GlobalHomesAndVillasSearchTabContent

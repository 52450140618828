import { useAppSelector } from 'hooks/reduxHooks'
import { isCurrentDeviceMobile } from 'selectors/configSelectors'

/**
 * Tells if the current device is a mobile device or not
 * @returns Boolean
 */

export function useIsMobileDevice(): boolean {
  return useAppSelector(isCurrentDeviceMobile)
}

import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import cn from 'clsx'
import { rem } from 'polished'
import config from 'constants/config'
import { connect } from 'react-redux'
import styled from 'styled-components'
import GeoContext from 'contexts/geoContext'
import * as Analytics from 'analytics/analytics'
import { useAppDispatch } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { pushWithRegion } from 'actions/NavigationActions'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useOrdersByDepartureStatus } from 'hooks/useOrdersByDepartureStatus'
import { RESUBSCRIBE_PROMO_CODE_REGIONS } from 'constants/config/region'
import { fetchLESubscriptionsV2, fetchResubscribeModalDismissedAt, fireEmailResubscribePromoCode, updateLESubscriptionsV2, updateResubscribeModalDismissedAt } from 'actions/LESubscriptionsActions'
import Group from 'components/utils/Group'
import Label from 'components/Luxkit/Label'
import TextLink from 'components/Luxkit/TextLink'
import ImageHero from 'components/Common/ImageHero'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Heading from 'components/Luxkit/Typography/Heading'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import FormatCurrency from 'components/Common/FormatCurrency'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import SolidCheckCircleIcon from 'components/Luxkit/Icons/solid/SolidCheckCircleIcon'
import LuxPlusProductPlaceholder from 'luxPlus/components/LuxPlusProductPlaceholder'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'

const StyledModalContent = styled(ModalContent)`
  padding: 0;
`

const LuxPlusHeader = styled(Group)`
  padding: ${rem(48)} ${rem(32)};
  background-color: ${(props) => props.theme.palette.product.luxPlus.background};
`

const Header = styled(ImageHero)`
  max-height: ${rem(192)};
`

const Container = styled(Group)`
  padding: ${rem(20)};
`

const RadioContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const StyledRadioInput = styled(RadioInput)`
  border: 1px solid ${props => props.theme.palette.neutral.default.four};
  padding: ${rem(20)};

  ${mediaQueryUp.desktop} {
    padding: ${rem(24)} ${rem(20)};
  }
`

const RecommendedRadioInput = styled(RadioInput)`
  position: relative;
  border: 1px solid ${props => props.theme.palette.highlight.secondary.normalForeground};
  padding: ${rem(24)} ${rem(20)} ${rem(20)};

  ${mediaQueryUp.desktop} {
    padding: ${rem(24)} ${rem(20)};
  }

  &.is-luxplus {
    border: 1px solid ${props => props.theme.palette.product.luxPlus.background};
  }
`

const StyledLabel = styled(Label)`
  position: absolute;
  top: -${rem(12)};
  left: ${rem(20)};
`

const Footer = styled(Group)`
  padding: ${rem(16)} ${rem(24)} ${rem(24)};
`

interface Props {
  userId?: string;
  canRedeemLuxPlusBenefits: boolean;
}

function EmailResubscribeModal(props: Props) {
  const { userId, canRedeemLuxPlusBenefits } = props
  const dispatch = useAppDispatch()
  const modalContext = useModalElementContext()
  const { currentRegionCode } = useContext(GeoContext)

  const { loading, upcoming, previous } = useOrdersByDepartureStatus()
  const hasNoCancelledEscape = useMemo(() => !!upcoming.length || !!previous.length, [upcoming, previous])
  const showPromoCodeVariant = useMemo(() => hasNoCancelledEscape && RESUBSCRIBE_PROMO_CODE_REGIONS.includes(currentRegionCode), [hasNoCancelledEscape, currentRegionCode])

  const optionsHeadingTitle = useMemo(() => {
    if (showPromoCodeVariant) return 'Unlock this special offer when you subscribe'

    return canRedeemLuxPlusBenefits ? 'Subscribe to get the most out of your LuxPlus+ membership' : 'Subscribe today to get the most out of Luxury Escapes'
  }, [showPromoCodeVariant, canRedeemLuxPlusBenefits])

  const onClose = useCallback(() => {
    if (!userId) return
    dispatch(updateResubscribeModalDismissedAt(userId))
    modalContext.resolve()
  }, [dispatch, modalContext, userId])

  const goToPreferences = useCallback(() => {
    dispatch(pushWithRegion(config.header.emailPreferences.to || ''))
    onClose()
  }, [dispatch, onClose])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!userId) return

    const formValues = formToObject<{ subscribeOption: 'basic' | 'full' }>(e.currentTarget)

    const eventSubject = formValues.subscribeOption === 'basic' ? 'personalised' : 'personalised-weekly'
    Analytics.trackClientEvent({
      subject: canRedeemLuxPlusBenefits ? `${eventSubject}-luxplus` : eventSubject,
      action: showPromoCodeVariant ? 'promo_modal' : '90_day_session_modal',
      type: 'interaction',
      category: 'resubscribe',
    })

    const fullSubscription = formValues.subscribeOption === 'full'
    dispatch(updateLESubscriptionsV2({
      userId,
      subscriptions: {
        sms_subscribed: true,
        email_subscribed: true,
        app_push_subscribed: true,
        my_journey_subscribed: true,
        todays_escapes_subscribed: fullSubscription,
        curated_collection_subscribed: fullSubscription,
        ...(fullSubscription && {
          todays_escapes_cadence: 'WEEKLY',
        }),
      },
    }))

    if (showPromoCodeVariant) {
      dispatch(fireEmailResubscribePromoCode(userId))
    }

    showSnackbar('You’ll now receive personalised offers and recommendations.', 'success', {
      heading: 'You’ve successfully subscribed',
      icon: <SolidCheckCircleIcon />,
    })
    modalContext.resolve()
  }

  useEffect(() => {
    if (userId) {
      dispatch(fetchLESubscriptionsV2(userId))
      dispatch(fetchResubscribeModalDismissedAt(userId))
    }
  }, [dispatch, userId])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'impression',
      action: showPromoCodeVariant ? 'promo_modal' : '90_day_session_modal',
      type: 'nonInteraction',
      category: 'resubscribe',
    })
  }, [showPromoCodeVariant])

  if (!userId) return null

  return (
    <ModalBase onClose={onClose}>
      {loading && <LoadingIndicator floating opaque />}
      <ModalHeader title="" isOverlay={showPromoCodeVariant} dismissible={!showPromoCodeVariant} onCloseButtonClick={onClose} />
      <ModalBody>
        <StyledModalContent>
          <form onSubmit={onSubmit}>
            {showPromoCodeVariant && (
              <Header
                brightness={-10}
                gravity="auto"
                fit="center"
                quality="good"
                mobileAspectRatio="9:4"
                desktopAspectRatio="3:1"
                id="ykvv8uhtmhu5hegw0ejm"
              >
                <Group direction="vertical" gap={12}>
                  <Heading variant="heading6" align="center" colour="neutral-eight">Subscribe to our mailing list and</Heading>
                  <Heading variant="heading1" align="center" colour="neutral-eight">Get <i><FormatCurrency value={50} /> off</i><br />your next booking</Heading>
                </Group>
              </Header>
            )}
            {!showPromoCodeVariant && canRedeemLuxPlusBenefits && (
              <LuxPlusHeader direction="vertical" gap={12} horizontalAlign="center">
                <LuxPlusProductPlaceholder />
                <Heading variant="heading3" colour="neutral-eight" align="center">Don’t miss out on the latest members-only benefits and offers</Heading>
              </LuxPlusHeader>
            )}
            {!showPromoCodeVariant && !canRedeemLuxPlusBenefits && (
              <Header
                brightness={-10}
                gravity="auto"
                fit="center"
                quality="good"
                mobileAspectRatio="9:4"
                desktopAspectRatio="3:1"
                id="cjibv4atdp6qownlqz2b"
              >
                <Heading variant="heading1" align="center" colour="neutral-eight"><i>Our best offers</i><br />delivered to you</Heading>
              </Header>
            )}
            <Container direction="vertical" gap={32}>
              <Group direction="vertical" gap={8}>
                <Heading variant="heading4">{optionsHeadingTitle}</Heading>
                {showPromoCodeVariant && (<BodyText variant="small"><TextLink to={config.contentLinks.termsAndConditions.to} target="_blank" weight="regular">Terms & Conditions</TextLink> apply</BodyText>)}
              </Group>
              <RadioContainer>
                <RecommendedRadioInput className={cn({ 'is-luxplus': canRedeemLuxPlusBenefits })} name="subscribeOption" value="full" defaultChecked>
                  <>
                    <StyledLabel kind="primary" variant={canRedeemLuxPlusBenefits ? 'lux-plus' : 'highlight-secondary'}>
                      Recommended
                    </StyledLabel>
                    {canRedeemLuxPlusBenefits ? <><strong>Never miss an offer</strong><br/>All your LuxPlus+ benefits, updates, and all our best offers</> : <><strong>Never miss an offer</strong><br/>Enjoy all our top offers delivered straight to you</>}
                  </>
                </RecommendedRadioInput>
                <StyledRadioInput name="subscribeOption" value="basic">
                  {canRedeemLuxPlusBenefits ? <><strong>Just personalised offers</strong><br/>LuxPlus+ benefits, updates, and only offers that match your preferences</> : <><strong>Just personalised offers</strong><br/>Only receive offers that match your preferences</>}
                </StyledRadioInput>
              </RadioContainer>
            </Container>
            <Footer direction="vertical" gap={8}>
              <TextButton kind="primary" type="submit">Subscribe</TextButton>
              {showPromoCodeVariant && <TextButton kind="tertiary" onClick={onClose}>No thanks</TextButton>}
              {!showPromoCodeVariant && <TextButton kind="tertiary" onClick={goToPreferences}>Manage preferences</TextButton>}
            </Footer>
          </form>
        </StyledModalContent>
      </ModalBody>
    </ModalBase>
  )
}

const mapStateToProps = (state: App.State) => ({
  userId: state.auth.account.memberId,
  canRedeemLuxPlusBenefits: checkCanRedeemLuxPlusBenefits(state),
})

export default connect(mapStateToProps)(EmailResubscribeModal)

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWineBeerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M2 4c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1v1.5077c0 .5523-.4477 1-1 1s-1-.4477-1-1V5H4v2.4286C4 9.3186 5.7046 11 8 11c.5002 0 .9763-.0818 1.4138-.2297.5232-.1769 1.0907.1038 1.2676.627.1769.5232-.1039 1.0907-.6271 1.2676A6.3277 6.3277 0 0 1 9 12.9229V19h1.5c.5523 0 1 .4477 1 1s-.4477 1-1 1H4c-.5523 0-1-.4477-1-1s.4477-1 1-1h3v-6.078c-2.7797-.4373-5-2.6661-5-5.4934V4Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M12.2616 8.3257A.9998.9998 0 0 1 13 8h7a.9998.9998 0 0 1 .9959 1.0905l-1 11A1 1 0 0 1 19 21h-5a1 1 0 0 1-.9959-.9095l-1-11a1 1 0 0 1 .2575-.7648ZM14.4587 14l.4545 5h3.1736l.4545-5h-4.0826Zm-.1818-2h4.4463l.1818-2h-4.81l.1819 2Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineWineBeerIcon

import { DELETE_USER_PREFERENCES_DESTINATION, DELETE_USER_PREFERENCES_EXTERNAL_BRANDS, DELETE_USER_PREFERENCES_HOLIDAY_TYPES, DELETE_USER_PREFERENCES_OCCUPANCY, FETCH_USER_PREFERENCES_DESTINATION, FETCH_USER_PREFERENCES_DESTINATION_DEFAULT, FETCH_USER_PREFERENCES_EXTERNAL_BRANDS, FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT, FETCH_USER_PREFERENCES_HOLIDAY_TYPES, FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT, FETCH_USER_PREFERENCES_OCCUPANCY, FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT, UPDATE_USER_PREFERENCES_DESTINATION, UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS, UPDATE_USER_PREFERENCES_HOLIDAY_TYPES, UPDATE_USER_PREFERENCES_OCCUPANCY } from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_REQUEST, API_CALL_FAILURE, API_CALL_SUCCESS } from 'actions/actionConstants'
import { EmptyArray } from 'lib/array/arrayUtils'

export const initialUserTravelPreferencesState: App.UserTravelPreferencesState = {
  defaultDestinations: {
    initial: true,
    data: EmptyArray,
  },
  destinations: {
    initial: true,
    data: EmptyArray,
  },
  defaultHolidayTypes: {
    initial: true,
    data: EmptyArray,
  },
  holidayTypes: {
    initial: true,
    data: EmptyArray,
  },
  defaultExternalBrands: {
    initial: true,
    data: EmptyArray,
  },
  externalBrands: {
    initial: true,
    data: EmptyArray,
  },
  defaultOccupancy: {
    initial: true,
    data: {
      rooms: [],
      groupSizes: [],
    },
  },
  occupancy: {
    initial: true,
  },
}

const apiRequests = reducerSwitch<App.UserTravelPreferencesState>({
  [FETCH_USER_PREFERENCES_DESTINATION_DEFAULT]: (state) => ({
    ...state,
    defaultDestinations: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_DESTINATION]: (state) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_DESTINATION]: (state) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: true,
      data: state.destinations.data,
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_DESTINATION]: (state) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: true,
      data: state.destinations.data,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT]: (state) => ({
    ...state,
    defaultHolidayTypes: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_HOLIDAY_TYPES]: (state) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_HOLIDAY_TYPES]: (state) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: true,
      data: state.holidayTypes.data,
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_HOLIDAY_TYPES]: (state) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: true,
      data: state.holidayTypes.data,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT]: (state) => ({
    ...state,
    defaultExternalBrands: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_EXTERNAL_BRANDS]: (state) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS]: (state) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: true,
      data: state.externalBrands.data,
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_EXTERNAL_BRANDS]: (state) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: true,
      data: state.externalBrands.data,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT]: (state) => ({
    ...state,
    defaultOccupancy: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_OCCUPANCY]: (state) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: true,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_OCCUPANCY]: (state) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: true,
      data: state.occupancy?.data,
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_OCCUPANCY]: (state) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: false,
      data: state.occupancy?.data,
      error: undefined,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.UserTravelPreferencesState>({
  [FETCH_USER_PREFERENCES_DESTINATION_DEFAULT]: (state, action) => ({
    ...state,
    defaultDestinations: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_DESTINATION]: (state, action) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_DESTINATION]: (state, action) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: false,
      data: [action.data, ...(state.destinations.data ?? [])],
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_DESTINATION]: (state, action) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: false,
      data: [
        ...(state.destinations.data ?? []).filter(
          item => item.placeId !== action.data.placeId,
        ),
      ],
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT]: (state, action) => ({
    ...state,
    defaultHolidayTypes: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_HOLIDAY_TYPES]: (state, action) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_HOLIDAY_TYPES]: (state, action) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: false,
      data: [...(state.holidayTypes.data ?? []), action.data],
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_HOLIDAY_TYPES]: (state, action) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: false,
      data: [
        ...(state.holidayTypes.data ?? []).filter(
          item => item.holidayTypeId !== action.data.holidayTypeId,
        ),
      ],
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT]: (state, action) => ({
    ...state,
    defaultExternalBrands: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_EXTERNAL_BRANDS]: (state, action) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS]: (state, action) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: false,
      data: [...(state.externalBrands.data ?? []), action.data],
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_EXTERNAL_BRANDS]: (state, action) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: false,
      data: [
        ...(state.externalBrands.data ?? []).filter(
          item => item.externalBrandId !== action.data.externalBrandId,
        ),
      ],
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT]: (state, action) => ({
    ...state,
    defaultOccupancy: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [FETCH_USER_PREFERENCES_OCCUPANCY]: (state, action) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [UPDATE_USER_PREFERENCES_OCCUPANCY]: (state, action) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: false,
      data: action.data,
      error: undefined,
    },
  }),
  [DELETE_USER_PREFERENCES_OCCUPANCY]: (state) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: false,
      data: undefined,
      error: undefined,
    },
  }),
})

const apiFailures = reducerSwitch<App.UserTravelPreferencesState>({
  [FETCH_USER_PREFERENCES_DESTINATION_DEFAULT]: (state, action) => ({
    ...state,
    defaultDestinations: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_USER_PREFERENCES_DESTINATION]: (state, action) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [UPDATE_USER_PREFERENCES_DESTINATION]: (state, action) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: false,
      data: state.destinations.data,
      error: action.error,
    },
  }),
  [DELETE_USER_PREFERENCES_DESTINATION]: (state, action) => ({
    ...state,
    destinations: {
      initial: false,
      fetching: false,
      data: state.destinations.data,
      error: action.error,
    },
  }),
  [FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT]: (state, action) => ({
    ...state,
    defaultHolidayTypes: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_USER_PREFERENCES_HOLIDAY_TYPES]: (state, action) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [UPDATE_USER_PREFERENCES_HOLIDAY_TYPES]: (state, action) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: false,
      data: state.holidayTypes.data,
      error: action.error,
    },
  }),
  [DELETE_USER_PREFERENCES_HOLIDAY_TYPES]: (state, action) => ({
    ...state,
    holidayTypes: {
      initial: false,
      fetching: false,
      data: state.holidayTypes.data,
      error: action.error,
    },
  }),
  [FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT]: (state, action) => ({
    ...state,
    defaultExternalBrands: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_USER_PREFERENCES_EXTERNAL_BRANDS]: (state, action) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS]: (state, action) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: false,
      data: state.externalBrands.data,
      error: action.error,
    },
  }),
  [DELETE_USER_PREFERENCES_EXTERNAL_BRANDS]: (state, action) => ({
    ...state,
    externalBrands: {
      initial: false,
      fetching: false,
      data: state.externalBrands.data,
      error: action.error,
    },
  }),
  [FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT]: (state, action) => ({
    ...state,
    defaultOccupancy: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_USER_PREFERENCES_OCCUPANCY]: (state, action) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: false,
      error: action.error,
    },
  }),
  [UPDATE_USER_PREFERENCES_OCCUPANCY]: (state, action) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: false,
      data: state.occupancy?.data,
      error: action.error,
    },
  }),
  [DELETE_USER_PREFERENCES_OCCUPANCY]: (state, action) => ({
    ...state,
    occupancy: {
      initial: false,
      fetching: false,
      data: state.occupancy?.data,
      error: action.error,
    },
  }),
})

export default createReducer<App.UserTravelPreferencesState>(initialUserTravelPreferencesState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

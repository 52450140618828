import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { rem } from 'polished'
import Calendar from 'components/Common/Calendar'
import { addMonths, endOfMonth, dateIsAfter, dateIsBefore, startOfMonth } from 'lib/datetime/dateUtils'
import { CALENDAR_GA_EVENT_ACTION, CALENDAR_GA_EVENT_LABEL } from 'constants/analytics'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import Heading from '../../Luxkit/Typography/Heading'
import LineAngleLeftBIcon from 'components/Luxkit/Icons/line/LineAngleLeftBIcon'
import LineAngleRightBIcon from 'components/Luxkit/Icons/line/LineAngleRightBIcon'
import useDidChange from 'hooks/useDidChange'
import UserAnalyticsActionContext from 'contexts/userAnalyticsActionContext'
import IconButton from 'components/Luxkit/Button/IconButton'
import VerticalSpacer from '../Spacing/VerticalSpacer'

const Root = styled.div`
  display: flex;
  width: 100%;

  > * {
    flex-grow: 1;

    & + * {
      margin-left: ${rem(52)};
    }
  }
`

const Header = styled.div`
  display: grid;
  grid-template: "prev-button heading next-button" auto / ${rem(28)} 1fr ${rem(28)};
  place-items: center;
`

const HeaderHeading = styled(Heading)`
  grid-area: heading;
`

const LeftArrow = styled(IconButton)`
  grid-area: prev-button;
`

const RightArrow = styled(IconButton)`
  grid-area: next-button;
`

interface Props {
  onDatesChange: (dates: { startDate: moment.Moment; endDate: moment.Moment | null; }) => void;
  endDate?: moment.Moment;
  startDate?: moment.Moment;
  initDate?: moment.Moment;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  startLabel?: string;
  endLabel?: string;
  availableMonths?: Array<string>;
}

function DualDateRangePicker(props: Props) {
  const {
    onDatesChange,
    endDate,
    startDate,
    initDate,
    minDate,
    maxDate,
    className,
    startLabel,
    endLabel,
    availableMonths,
  } = props
  const [index, setIndex] = useState<number>(0)
  const [date, setDate] = useState<Date>(initDate?.toDate() || startDate?.toDate() || new Date())

  const onUserAnalyticsAction = useContext(UserAnalyticsActionContext)

  const onDayClick = useCallback((day: moment.Moment) => {
    if (startDate && !endDate && day.isAfter(startDate)) {
      onDatesChange({ startDate, endDate: day })
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.CheckOut, moment(day).format(ISO_DATE_FORMAT))
    } else {
      onDatesChange({ startDate: day, endDate: null })
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.CheckIn, moment(day).format(ISO_DATE_FORMAT))
    }
  }, [onDatesChange, startDate, endDate, onUserAnalyticsAction])

  const onNextMonth = useCallback(() => {
    if (availableMonths) {
      if (index >= availableMonths.length - 1) return
      setIndex(index + 1)
    }
    setDate(addMonths(date, 1))
    onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.ShiftMonth, CALENDAR_GA_EVENT_LABEL.NextMonth)
  }, [availableMonths, date, index, onUserAnalyticsAction])

  const onPrevMonth = useCallback(() => {
    if (availableMonths) {
      if (!index) return
      setIndex(index - 1)
    }
    setDate(addMonths(date, -1))
    onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.ShiftMonth, CALENDAR_GA_EVENT_LABEL.PreviousMonth)
  }, [availableMonths, date, index, onUserAnalyticsAction])

  const currentDate = useMemo(() => {
    if (availableMonths?.[index]) {
      return moment(availableMonths[index], 'YYYY-MM').toDate()
    }
    return date
  }, [availableMonths, date, index])

  const nextDate = useMemo(() => {
    if (availableMonths?.length) {
      return index < availableMonths.length - 1 ? moment(availableMonths[index + 1], 'YYYY-MM').toDate() : undefined
    }

    return addMonths(date, 1)
  }, [availableMonths, date, index])

  const formattedDate = useCallback((date: Date) => {
    const momentDate = moment(date)
    if (!momentDate.isValid()) return ''
    return `${momentDate.format('MMMM')} ${momentDate.format('YYYY')}`
  }, [])

  const startDateChanged = useDidChange(startDate)
  useEffect(() => {
    if (startDate && startDateChanged &&
      // month on the left === month of the start date
      startDate.month() !== date.getMonth() &&
      // month on the right === month of the start date
      startDate.month() % 12 !== (date.getMonth() + 1) % 12
    ) {
      setDate(startDate.toDate())
    }
  }, [date, startDate, startDateChanged])

  return (<Root className={className}>
    <VerticalSpacer gap={8}>
      <Header>
        <LeftArrow
          kind="tertiary"
          size="small"
          shape="circle"
          outdent="left"
          disabled={dateIsBefore(startOfMonth(currentDate), minDate)}
          onClick={onPrevMonth}
        >
          <LineAngleLeftBIcon />
        </LeftArrow>
        <HeaderHeading variant="heading6" align="center">
          {formattedDate(currentDate)}
        </HeaderHeading>
      </Header>
      <Calendar
        month={currentDate.getMonth()}
        year={currentDate.getFullYear()}
        startDate={startDate?.toDate()}
        minDate={minDate}
        maxDate={maxDate}
        endDate={endDate?.toDate()}
        onDayClick={onDayClick}
        startLabel={startLabel}
        endLabel={endLabel}
      />
    </VerticalSpacer>
    <VerticalSpacer gap={8}>
      <Header>
        {!!nextDate && <HeaderHeading variant="heading6" align="center">
          {formattedDate(nextDate)}
        </HeaderHeading>}
        {!!nextDate && <RightArrow
          kind="tertiary"
          size="small"
          shape="circle"
          outdent="right"
          data-testid="next-month-calendar"
          disabled={dateIsAfter(endOfMonth(nextDate))}
          onClick={onNextMonth}
        >
          <LineAngleRightBIcon />
        </RightArrow>}
      </Header>
      {!!nextDate && <Calendar
        month={nextDate.getMonth()}
        year={nextDate.getFullYear()}
        startDate={startDate?.toDate()}
        minDate={minDate}
        maxDate={maxDate}
        endDate={endDate?.toDate()}
        onDayClick={onDayClick}
        startLabel={startLabel}
        endLabel={endLabel}
      />}
    </VerticalSpacer>
  </Root>
  )
}

export default React.memo(DualDateRangePicker)

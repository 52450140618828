import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import cn from 'clsx'
import zIndex from 'styles/tools/z-index'

export const BackdropElement = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .2s;
  z-index: ${zIndex.backdrop};

  &.visible {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, .5);
  }
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean;
  /**
   * Don't use a portal for this backdrop
   */
  portalless?: boolean;
}

function Backdrop(props: Props) {
  const { portalless, visible, className, ...rest } = props
  const [element, setElement] = useState<HTMLDivElement>()

  useEffect(() => {
    const thisElement = document.createElement('div')
    setElement(thisElement)
    document.body.appendChild(thisElement)

    return () => {
      document.body.removeChild(thisElement)
    }
  }, [])

  if (portalless) {
    return <BackdropElement
      {...rest}
      className={cn(className, { visible })}
    />
  }

  if (!element) {
    return null
  }

  return ReactDOM.createPortal(
    <BackdropElement
      {...rest}
      className={cn(className, { visible })}
    />,
    element,
  )
}

export default React.memo(Backdrop)

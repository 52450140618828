export function getWidth() {
  if (typeof document === 'undefined') {
    return 0
  }

  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}

export function getHeight() {
  if (typeof document === 'undefined') {
    return 0
  }

  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
}

export function getAspectRatio() {
  if (typeof document === 'undefined') {
    return 1
  }
  return getWidth() / getHeight()
}

import React, { ComponentProps, useMemo } from 'react'
import getOfferFeatureIcon from 'lib/offer/getOfferFeatureIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { SvgIconProps } from 'components/Luxkit/SvgIcon'
import getInclusionMinStayText from 'lib/offer/getInclusionMinStayText'

type BodyTextBlockProps = ComponentProps<typeof BodyTextBlock>

interface Props {
  inclusion: App.OfferInclusion;
  isHighlighted?: boolean;
  /**
   * @default medium
   */
  size?: BodyTextBlockProps['variant'];
  format?: BodyTextBlockProps['format'];
  colour?: BodyTextBlockProps['colour'];
  lineClamp?: BodyTextBlockProps['lineClamp'];
  /**
   * @default default
   */
  type?: 'default' | 'lux-plus'
  showMinStayDescription?: boolean
}

function OfferInclusionItem({
  inclusion,
  isHighlighted,
  size = 'medium',
  format,
  colour,
  lineClamp,
  type,
  showMinStayDescription,
}: Props) {
  const Icon = useMemo(() => getOfferFeatureIcon(inclusion.symbol), [inclusion])
  const highlighted = isHighlighted ?? inclusion.isHighlighted
  const iconColour: SvgIconProps['colour'] = type === 'lux-plus' ? 'lux-plus' : highlighted ? 'secondary-normal' : undefined
  const minStayDescription = showMinStayDescription ? getInclusionMinStayText(inclusion) : undefined

  return <BodyTextBlock
    className={OfferInclusionItem.name}
    variant={size}
    format={format}
    colour={colour}
    lineClamp={lineClamp}
    weight={highlighted ? 'bold' : 'normal'}
    startIcon={<Icon colour={iconColour} data-symbol={inclusion.symbol} />}
  >
    {inclusion.description} {minStayDescription}
  </BodyTextBlock>
}

export default OfferInclusionItem

import { useCallback, useMemo, useState } from 'react'
import { last } from 'lib/array/arrayUtils'

interface Props {
  activeStep?: string;
  setActiveStep: React.Dispatch<any>;
  stepsValidation: any;
  onSearch?: () => void;
}

function useStepsController(props: Props) {
  const { activeStep, setActiveStep, stepsValidation, onSearch } = props

  const [searchStepNavHistory, setSearchStepNavHistory] = useState<Array<string | undefined>>([])

  const goToNextStep = useCallback(() => {
    const otherSteps = Object.keys(stepsValidation).filter(step => step !== activeStep)
    const invalidSteps = otherSteps.filter(stepKey => !stepsValidation[stepKey])

    // Add the current step to the step history
    setSearchStepNavHistory([...searchStepNavHistory, activeStep])

    // Set the first invalid tab to activeStep
    setActiveStep(invalidSteps[0])

    if (!invalidSteps.length && onSearch) return onSearch()
  }, [stepsValidation, activeStep, setActiveStep, searchStepNavHistory, onSearch])

  const goToPreviousStep = useCallback(() => {
    setActiveStep(last(searchStepNavHistory))

    searchStepNavHistory.pop()
    setSearchStepNavHistory([...searchStepNavHistory])
  }, [setActiveStep, searchStepNavHistory])

  const closeActiveStep = useCallback(() => {
    setActiveStep(null)
    setSearchStepNavHistory([])
  }, [setActiveStep])

  const isSearchEnabled = useMemo(() => {
    const invalidSteps = Object.keys(stepsValidation).filter(stepKey => !stepsValidation[stepKey])

    return !invalidSteps.length
  }, [stepsValidation])

  return ({ goToNextStep, goToPreviousStep, closeActiveStep, isSearchEnabled })
}

export default useStepsController

import { css } from 'styled-components'
import { rem } from 'polished'

const Typography = css`
  b,
  strong {
    font-weight: ${props => props.theme.font.primary.weight.superBold};
  }

  p {
    margin-top: 0;
    margin-bottom: 0;

    & + & {
      margin-top: ${rem(24)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    //To prevent unsemantic use heading not have sizes
    font-size: inherit;
    font-weight: ${props => props.theme.font.primary.weight.regular};
  }

  ol,
  ul {
    padding-left: 0;
    margin-top: ${rem(24)};
    margin-left: ${rem(20)};
  }

  dl {
    margin: 0;
  }

  dd {
    margin-left: 0;
  }

  ::-webkit-input-placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder,
  ::-moz-placeholder {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  sup, sub {
    line-height: .5;
  }
`

export default Typography

import luxuryescapes from './luxuryescapes'
import cudotravel from './cudotravel'
import dealstravel from './dealstravel'
import treatmetravel from './treatmetravel'
import scoopontravel from './scoopontravel'
import lebusinesstraveller from './lebusinesstraveller'
import leagenthub from './leagenthub'

const allThemes: { [brand: string]: App.Theme } = {
  luxuryescapes,
  cudotravel,
  dealstravel,
  treatmetravel,
  scoopontravel,
  lebusinesstraveller,
  leagenthub,
}

export default allThemes

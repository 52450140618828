import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Group from 'components/utils/Group'
import React from 'react'
import styled from 'styled-components'

/*
  In the full Trustpilot SVG logo the text 'Trustpilot' is not centered with the logo.
  To better align text and logos of other trust indicators, a combination of svg and text is used.
*/
const TrustPilotText = styled(BodyTextBlock)`
  color: #1A1918;
`

function TrustPilotLogoCombined() {
  return <Group gap={2} direction="horizontal" verticalAlign="center">
    <div><svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path d="M21.4791 8.37669L13.2744 8.37526L10.7396 0.572601L8.20506 8.37439L0 8.37304L6.63826 13.1974L4.10319 21.0008L10.7405 16.1786L17.3759 21.0008L14.8415 13.1991L21.4791 8.37669Z" fill="#3ABA72"/>
      <path d="M15.4158 14.9678L14.8412 13.1991L10.7402 16.1786L15.4158 14.9678Z" fill="#1A4D26"/>
    </svg>
    </div>
    <TrustPilotText weight="semi-bold" variant="medium" wrap="no-wrap">Trustpilot</TrustPilotText>
  </Group>
}

export default TrustPilotLogoCombined

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUserIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.71 12.71a5.9998 5.9998 0 0 0-7.19-9.6031 6 6 0 0 0-.23 9.6031 10.0001 10.0001 0 0 0-6.22 8.18A1.0063 1.0063 0 0 0 2.96 22a1.006 1.006 0 0 0 1.11-.89 7.9999 7.9999 0 0 1 15.9 0 .9998.9998 0 0 0 1 .89h.11a1.0006 1.0006 0 0 0 .6666-.3687A1.0003 1.0003 0 0 0 21.96 20.9a9.9994 9.9994 0 0 0-6.25-8.19ZM12 12a4.0002 4.0002 0 0 1-3.9231-4.7804A4 4 0 1 1 12 12Z"/>
  </SvgIcon>
}

export default LineUserIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUniversityIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21 10a1.0003 1.0003 0 0 0 .7072-.2928A.9999.9999 0 0 0 22 9V6a.9988.9988 0 0 0-.6836-.9482l-9-3a1.002 1.002 0 0 0-.6328 0l-9 3A.9989.9989 0 0 0 2 6v3a.9998.9998 0 0 0 1 1h1v7.1843A2.995 2.995 0 0 0 2 20v2a.9997.9997 0 0 0 1 1h18a.9992.9992 0 0 0 .7072-.2928A.9998.9998 0 0 0 22 22v-2a2.9952 2.9952 0 0 0-2-2.8157V10h1Zm-1 11H4v-1a1.001 1.001 0 0 1 1-1h14a1.0011 1.0011 0 0 1 1 1v1ZM6 17v-7h2v7H6Zm4 0v-7h4v7h-4Zm6 0v-7h2v7h-2ZM4 8V6.7207l8-2.667 8 2.667V8H4Z"/>
  </SvgIcon>
}

export default LineUniversityIcon

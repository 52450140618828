import React, { ComponentProps } from 'react'
import CaptionTextBlock from '../TextBlocks/CaptionTextBlock'

type InheritedCaptionTextBlockProps = Pick<ComponentProps<typeof CaptionTextBlock>, 'endIcon'>

interface Props extends InheritedCaptionTextBlockProps {
}

function PriceRowTaxesAndFees(props: Props) {
  const { endIcon } = props

  return <CaptionTextBlock
    className={PriceRowTaxesAndFees.name}
    variant="large"
    weight="normal"
    colour="neutral-three"
    endIcon={endIcon}
  >
    Includes taxes & fees
  </CaptionTextBlock>
}

export default PriceRowTaxesAndFees

import { createReducer } from 'lib/redux/reducerUtils'
import { SET_OPTIMIZELY_EXPERIMENT, SET_OPTIMIZELY_FEATURE_FLAG } from 'actions/actionConstants'

export const initialOptimizelyState: App.OptimizelyState = {
  optimizelyExperiments: {},
  optimizelyFeatureFlags: {},
}

export default createReducer<App.OptimizelyState>(initialOptimizelyState, {
  [SET_OPTIMIZELY_EXPERIMENT]: (state, action) => ({
    optimizelyExperiments: {
      ...state.optimizelyExperiments,
      [action.flagKey]: {
        ...state.optimizelyExperiments[action.flagKey],
        ...action.value,
      },
    },
  }),

  [SET_OPTIMIZELY_FEATURE_FLAG]: (state, action) => ({
    optimizelyFeatureFlags: {
      ...state.optimizelyFeatureFlags,
      [action.flagKey]: action.value,
    },
  }),
})

import React from 'react'

export enum AnalyticsComponent {
  roomOption = 'room_option',
  tripPrimaryButton = 'primary',
  tripHeaderButton = 'header',
}

/**
 * This context is to be used to convey the current component analytics events
 * are being run from.
 *
 * e.g. room_option (for hotels) etc
 */
const AnalyticsComponentContext = React.createContext<AnalyticsComponent | undefined>(undefined)

export default AnalyticsComponentContext

import { isExperienceOffer } from 'lib/offer/offerTypes'

export const tripItemSelectionId = (itemId: string): string => `tripitem-${itemId}`
export const exploreItemSelectionId = (itemId: string): string => `explore-${itemId}`
export const leHotelSelectionId = (itemId: string): string => `hotel-${itemId}`
export const leExperienceSelectionId = (itemId: string): string => `experience-${itemId}`

export const leOfferSelectionId = (offer: App.ExperienceOffer | App.HotelOffer): string => (
  isExperienceOffer(offer) ? leExperienceSelectionId(offer.id) : leHotelSelectionId(offer.id)
)

export const selectionIdRegex = /^(tripitem|explore|hotel|experience)-(.+)$/

export function extractSelectedItemId(selectionId: string | undefined, type: 'tripitem' | 'explore' | 'hotel' | 'experience'): string | undefined {
  const match = selectionId?.match(selectionIdRegex)
  return match && match[1] === type ? match[2] : undefined
}

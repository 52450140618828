import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import { rem } from 'polished'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import BodyText from '../Typography/BodyText'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'

type FilterChipSize = 'small' | 'medium' | 'large'

export const FILTER_CHIP_HEIGHTS: Record<FilterChipSize, number> = {
  small: 28,
  medium: 32,
  large: 36,
}

const Chip = styled(Clickable)`
  --chip-stroke-width: 1px;
  --chip-stroke-color: ${props => props.theme.palette.neutral.default.four};
  box-shadow: inset 0 0 0 var(--chip-stroke-width) var(--chip-stroke-color);
  display: inline-flex;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.round};
  position: relative;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  gap: ${rem(4)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  color: ${props => props.theme.palette.neutral.default.one};
  white-space: nowrap;

  &:disabled {
    --chip-stroke-color: ${props => props.theme.palette.neutral.default.five};
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        --chip-stroke-color: ${props => props.theme.palette.neutral.default.two};
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }

    &.error {
      --chip-stroke-color: ${props => props.theme.palette.messaging.critical.normalForeground};
    }

    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
    }
  }

  &.selected {
    --chip-stroke-width: 2px;
    --chip-stroke-color: ${props => props.theme.palette.neutral.default.one};
    color: ${props => props.theme.palette.neutral.default.one};

    &:disabled {
      --chip-stroke-color: ${props => props.theme.palette.neutral.default.five};
    }

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          --chip-stroke-color: ${props => props.theme.palette.neutral.default.two};
        }
      }

      &.error {
        --chip-stroke-color: ${props => props.theme.palette.messaging.critical.normalForeground};
      }
    }
  }

  &.size-small {
    height: ${rem(FILTER_CHIP_HEIGHTS.small)};
    padding: 0 ${rem(8)};
  }

  &.size-medium {
    height: ${rem(FILTER_CHIP_HEIGHTS.medium)};
    padding: 0 ${rem(12)};
  }

  &.size-large {
    height: ${rem(FILTER_CHIP_HEIGHTS.large)};
    padding: 0 ${rem(12)};
  }

  > svg, img {
    height: ${rem(16)};
    width: ${rem(16)};
  }
`

/**
 * When our text is 'active' it goes bold it grows slightly bigger, causing the content to shift
 * To fix this, always render a body size version of the text to 'reserve' the space
 *
 */
const ReservedSpaceText = styled(BodyText)`
  visibility: hidden;
  position: relative;
`

const VisibleText = styled(BodyText)`
  transition: color 0.2s;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  visibility: visible;
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Determines factors like the height/padding of the chip */
  size: FilterChipSize;
  /** Whether not the chip should show itself visually as selected */
  selected?: boolean;
  /** Whether or not the chip should show itself visually in an error state */
  error?: boolean;
  /** An icon element that will go at the start (often the 'left' side) of the chip */
  startIcon?: React.ReactElement;
  /** An icon element that will go at the end (often the 'right' side) of the chip */
  endIcon?: React.ReactElement;
}

/**
 * - Filter chips use tags or descriptive words to filter content.
 *   - They function as an alternative to checkboxes, buttons and dropdowns.
 *   - Tapping on a filter chip activates it and changes the state to visually show the user that they have made a selection.
 * - Filter chips can either have multiple selections made, or a single selection only.
 *   - This is dependent on use case and context of the content which needs to be filtered.
 *   - Filter chips may also be used with other components, such as below a search field or within larger filter menus.
 * - Filter chips should never be shown when there is only a single option available.
 */
const FilterChip = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, children, size, selected, error, startIcon, endIcon, ...buttonProps } = props

  return (<Chip
    {...buttonProps}
    ref={ref}
    className={cn(
      className,
      `size-${size}`,
      { error, selected },
    )}
  >
    {startIcon}
    <ReservedSpaceText variant={size} weight="bold">
      {children}
      <VisibleText
        variant={size}
        weight={selected ? 'bold' : 'normal'}
      >
        {children}
      </VisibleText>
    </ReservedSpaceText>
    {endIcon}
  </Chip>)
})

export default FilterChip

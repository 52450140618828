import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

function useMountedEffect(effect: EffectCallback, dependencies: DependencyList) {
  const hasMounted = useRef(false)

  useEffect(() => {
    if (hasMounted.current) {
      return effect()
    } else {
      hasMounted.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export default useMountedEffect

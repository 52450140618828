import React, { ComponentProps, useContext } from 'react'
import { getApplyCtaText } from 'components/Search/utils'
import OccupancyPicker from 'components/Common/OccupancyPicker/OccupancyPicker'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import config from 'constants/config'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import Group from 'components/utils/Group'
import FormattedOccupancy from 'components/Common/OccupancyPicker/FormattedOccupancy'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props extends Pick<ComponentProps<typeof DropdownSheet>, 'anchorRef' | 'triggerRef' | 'open' | 'onClose'> {
  rooms: Array<App.Occupants>;
  isLastStep?: boolean;
  saleUnit?: string;
  onRoomChange: (rooms: Array<App.Occupants>) => void;
  onRoomsApply: () => void;
}

function SearchTravellersDropdown(props: Props) {
  const {
    open,
    triggerRef,
    anchorRef,
    rooms,
    onRoomChange,
    onRoomsApply,
    isLastStep,
    saleUnit,
    onClose,
  } = props
  const { disableMultiRoom, disableSaleUnit } = useContext(GlobalSearchStateContext)

  return <DropdownSheet
    size="fill-anchor"
    anchorRef={anchorRef}
    triggerRef={triggerRef}
    open={open}
    primaryActionProps={{
      children: getApplyCtaText(!!isLastStep),
      type: 'button',
      onClick: onRoomsApply,
    }}
    footerStart={<Group direction="horizontal" horizontalAlign="end">
      <BodyText variant="large">
        <FormattedOccupancy
          occupancies={rooms}
          saleUnit={!disableSaleUnit ? saleUnit : undefined}
        />
      </BodyText>
    </Group>}
    onClose={onClose}
  >
    <OccupancyPicker
      saleUnit={saleUnit}
      occupancies={rooms}
      onChange={onRoomChange}
      enableMultiRoom={disableMultiRoom ? false : config.accommodation.allowsMultiRoom}
      disableSaleUnit={disableSaleUnit}
    />
  </DropdownSheet>
}

export default SearchTravellersDropdown

import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import useCruiseOfferView from 'hooks/Cruise/useCruiseOfferView'
import { isCruiseOffer } from 'lib/offer/offerTypes'
import React, { useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import SearchTile, { SearchTileLogo } from '../SearchTile'
import SearchTileRowOverlineJourney from '../shared/SearchTileRowOverlineJourney'
import CruiseSearchTileExtendedPricing from './CruiseSearchTileExtendedPricing'
import CruiseSearchTileFeatures from './CruiseSearchTileFeatures'
import CruiseSearchTileLabels from './CruiseSearchTileLabels'
import CruiseSearchTileMeta from './CruiseSearchTileMeta'
import CruiseSearchTilePriceStack from './CruiseSearchTilePriceStack'
import TextButton from 'components/Luxkit/Button/TextButton'
import { useQsQueryParams } from 'hooks/useQueryParams'

interface Props {
  offer: App.CruiseOffer | App.TourOffer | App.TourOfferSummary
  filters?: App.OfferListFilters
}

function CruiseSearchTile({ offer, filters }: Props) {
  const view = useCruiseOfferView({ offer })

  const logo = useMemo((): SearchTileLogo => ({
    compact: { id: view.cruiseLineImage, title: view.cruiseLineName },
    full: { id: view.cruiseLineImage, title: view.cruiseLineName },
  }), [view])

  const { cabinTypes } = useQsQueryParams<{ cabinTypes?: string }>()
  const hasMoreThanOneAvailableCabinCategoriesPriceVariations = view.availableCabinCategoriesPriceVariations && view.availableCabinCategoriesPriceVariations.size > 1
  const shouldShowExtendedPricing = hasMoreThanOneAvailableCabinCategoriesPriceVariations && !cabinTypes

  return <SearchTile
    className={CruiseSearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    action={<TextButton nonInteractable kind="primary">View offer</TextButton>}
    bookmarkAction={<BookmarkButton offer={offer} />}
    extension={(shouldShowExtendedPricing && isCruiseOffer(view.offer)) && <CruiseSearchTileExtendedPricing view={view} />}
    features={<CruiseSearchTileFeatures view={view} filters={filters} />}
    images={offer.images}
    labels={<CruiseSearchTileLabels view={view} />}
    logo={logo}
    meta={<CruiseSearchTileMeta view={view} />}
    overline={<SearchTileRowOverlineJourney start={view.departureCity} end={view.destinationCity} />}
    pricePoint={<CruiseSearchTilePriceStack view={view} />}
    productLabel={<ProductTypeLabel productType={offer.productType} />}
    title={offer.name}
  />
}

export default CruiseSearchTile

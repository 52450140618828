import React from 'react'
import { OFFER_TRACKING_IN_VIEW_THRESHOLD } from 'constants/offerList'

export function handleSkipInView(
  entry: IntersectionObserverEntry | undefined,
  skipInView: boolean,
  setSkipInView: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (entry) {
    const ratio = entry.intersectionRatio
    if (ratio >= OFFER_TRACKING_IN_VIEW_THRESHOLD && !skipInView) {
      setSkipInView(true)
    }
  }
}

export function calculateHasBeenInView(entry: IntersectionObserverEntry | undefined) {
  if (!entry) return
  return entry.intersectionRatio > 0
}

export function calculateTrackingInView(entry: IntersectionObserverEntry | undefined) {
  if (!entry) return
  return entry.intersectionRatio >= OFFER_TRACKING_IN_VIEW_THRESHOLD
}

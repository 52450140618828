export const ALL_GRAPH_RECOMMENDATION_OFFER_TYPES = ['hotel', 'tactical_ao_hotel', 'last_minute_hotel', 'bedbank', 'bedbank_marriott']
export const DESTINATION_DATA = {
  le_464adf7b911508701f4c4e70eab19a6e: {
    type: 'multi_city_vicinity',
    imageId: 'fkkgb9n9e17jhsm5ikm',
    name: 'Auckland',
  },
  le_d3d9446802a44259755d38e6d163e820: {
    type: 'country',
    imageId: 'oiyl17t2taq5d2fdz64',
    name: 'Australia',
  },
  le_64930b7151b6bc3e289f37d5da62ac9f: {
    type: 'province_state',
    imageId: 'vg4uvt2y5dgkivujuba6',
    name: 'Bali',
  },
  le_ff7aa1f9b4062bfee0f99a0453506f1b: {
    type: 'multi_city_vicinity',
    imageId: '7dx87h3ycow6orkyzsk',
    name: 'Bangkok',
  },
  le_ffe29c71e4cd3f141d8d80f82cbe4e72: {
    type: 'city',
    imageId: 'xgo4zx7duh1v0lv8otap',
    name: 'Batam',
  },
  le_b21d2f86beb192143a04671ac93bfd00: { type: 'city', imageId: 'zj5gyc22v3o45th4awwe', name: 'Bintan' },
  le_b96d145f6c31e35755f4a6841574ba37: {
    type: 'multi_city_vicinity',
    imageId: 'btx29u0kiwh5a6ob0m5',
    name: 'Brisbane',
  },
  le_8701b6eda0e8f334b30b4488214bbf1e: {
    type: 'city',
    imageId: 'uyhm9zdfpjlaopwx2mfy',
    name: 'Cancun',
  },
  le_d7bce4d8dbac13945471c343c4973ba3: {
    type: 'high_level_region',
    imageId: '54h7nft1vhh41svqcid',
    name: 'Caribbean',
  },
  le_3416a75f4cea9109507cacd8e2f2aefc: {
    type: 'country',
    imageId: 'x382u2epn5ub73h8l3',
    name: 'Cook Islands',
  },
  le_f9736cf40a30e0fe71c53004951da2ed: {
    type: 'multi_city_vicinity',
    imageId: 'eoo7x60icwbgndyc1wcge',
    name: 'Dubai',
  },
  le_43baa6762fa81bb43b39c62553b2970d: { type: 'province_state', imageId: 'eoo7x60icwbgndyc1wcge', name: 'Dubai' },
  le_12767faf1bf8cafc7594430586c558c2: {
    type: 'continent',
    imageId: 'iuuawzh6wih8s6hu6ss',
    name: 'Europe',
  },
  le_72b32a1f754ba1c09b3695e0cb6cde7f: {
    type: 'country',
    imageId: 'h1t0c6c9brq83bwhazx',
    name: 'Fiji',
  },
  le_eb163727917cbba1eea208541a643e74: {
    type: 'province_state',
    imageId: 'ponunzljvdk8z69ckkn',
    name: 'Florida',
  },
  le_bac732346917b0a1231354ef55faf00a: {
    type: 'city',
    imageId: 'krvovndggw4zalo1kgo8',
    name: 'Gold Coast',
  },
  le_735b90b4568125ed6c3f678819b6e058: {
    type: 'country',
    imageId: 'la8fpc5ehwxlh9cyd9ne',
    name: 'Greece',
  },
  le_979d472a84804b9f647bc185a877a8b5: {
    type: 'province_state',
    imageId: 'q26a5y2h5aijx52y8nfm',
    name: 'Hawaii',
  },
  le_93db85ed909c13838ff95ccfa94cebd9: {
    type: 'country',
    imageId: 'wbk1yjnf56awezs1lu',
    name: 'Italy',
  },
  le_7647966b7343c29048673252e490f736: {
    type: 'country',
    imageId: 'ri8b3umlvln1k6290g7i',
    name: 'Japan',
  },
  le_f3ad1c493ba61ad63bb43d6947f42d95: {
    type: 'city',
    imageId: 'hotel-destination-khao-lak',
    name: 'Khao Lak',
  },
  le_7af6d6117d13124fd3e00f5cb5f04400: {
    type: 'city',
    imageId: '2nic35dhw8gtasvlz29m',
    name: 'Koh Samui',
  },
  le_b536d75d9b756802546437b33b09b5e8: {
    type: 'city',
    imageId: 'plw9qs3zap66s5p4o6tc',
    name: 'Krabi',
  },
  le_f243f5a39d3b7ef531788ff07ea1ca58: {
    type: 'multi_city_vicinity',
    imageId: 'a9lyvzi0mmiwxy7nsgo',
    name: 'Kuala Lumpur',
  },
  le_2d405b367158e3f12d7c1e31a96b3af3: {
    type: 'province_state',
    imageId: 'search-destination-image-le_2d405b367158e3f12d7c1e31a96b3af3',
    name: 'Langkawi',
  },
  le_324d1d5faac07d0c5ae13c2213b84ea9: {
    type: 'multi_city_vicinity',
    imageId: 'r0zw0yiqmcs9te5d7yr',
    name: 'Las Vegas',
  },
  le_66ff0e7c30438d414ff0a941b759f114: {
    type: 'multi_city_vicinity',
    imageId: '2n47ppzghr6pannc4gm',
    name: 'London',
  },
  le_a3c65c2974270fd093ee8a9bf8ae7d0b: {
    type: 'country',
    imageId: 'jummelnit58g5ld7d8t9',
    name: 'Malaysia',
  },
  le_2723d092b63885e0d7c260cc007e8b9d: {
    type: 'country',
    imageId: 'dapbr82l65bcj5iy6v8',
    name: 'Maldives',
  },
  // le_6fc671d6a9900b46f5b5389ea2188e59: {
  //   type: 'high_level_region',
  //   imageId: undefined,
  //   name: 'Mallorca Island',
  // },
  le_2b44928ae11fb9384c4cf38708677c48: {
    type: 'country',
    imageId: 'ztyqafpx7lywouc2093',
    name: 'Mauritius',
  },
  le_15fa44474c92da27cf2125373356db4c: {
    type: 'multi_city_vicinity',
    imageId: 'asgz0fkt6cf311m98rq',
    name: 'Melbourne',
  },
  le_eb160de1de89d9058fcb0b968dbbbd68: {
    type: 'country',
    imageId: 'rasl0402sq9ft6vloi7b',
    name: 'Mexico',
  },
  le_8337a7365ec44f3d65434a5ea4d73d17: {
    type: 'province_state',
    imageId: '991vrkjubouwiyuerwjyr',
    name: 'New South Wales',
  },
  le_1ada7faf229425b786d87f7d9d5d43f2: {
    type: 'multi_city_vicinity',
    imageId: 'kgdhuqz8qk2dxsqm2rn',
    name: 'New York',
  },
  le_9fc3d7152ba9336a670e36d0ed79bc43: {
    type: 'country',
    imageId: '4ezeb1xkmkcuawwm0cf8',
    name: 'New Zealand',
  },
  le_a55472983efa2e239b315de91576d1d3: {
    type: 'city',
    imageId: 'hotel-destination-nusa-dua',
    name: 'Nusa Dua',
  },
  le_cb3f7087c987ceff5b306d4ff89f5db6: {
    type: 'multi_city_vicinity',
    imageId: '3r1eu1oz4snlmhci35gg',
    name: 'Paris',
  },
  le_d47bb339212ddfab0fadd99d492a5fc3: {
    type: 'province_state',
    imageId: 'u9x09v188fgl4s71edf',
    name: 'Phuket',
  },
  le_dc2afc2240bd3cd5067a740a2cb17399: {
    type: 'city',
    imageId: '9ejcktzro8pey77aew7s',
    name: 'Port Douglas',
  },
  le_3d3621cee6f49e93a34f3f0f654ab41a: {
    type: 'province_state',
    imageId: '68cb38dgpprmupvorvzg',
    name: 'Queensland',
  },
  le_5a4773830c5a9191a6bd6c301d060ad2: {
    type: 'city',
    imageId: '4vhgn44wotbycqrscpm8',
    name: 'Queenstown',
  },
  le_287643040f414c7ab7860c1b3f841149: {
    type: 'city',
    imageId: 'gxat5lulud6a8h60omlo',
    name: 'Rarotonga',
  },
  le_2a79ea27c279e471f4d180b08d62b00a: {
    type: 'country',
    imageId: 'kgp44kj57lap71v2djyt',
    name: 'Samoa',
  },
  le_790d9f1f52e10ef90ede8efcf7f3c078: {
    type: 'city',
    imageId: 'hotel-destination-seminyak',
    name: 'Seminyak',
  },
  le_ce393994e8cf430867408678d1a5a9c8: {
    type: 'city',
    imageId: 'b4u5c9v9eplflpfjohz8',
    name: 'Singapore',
  },
  le_bd4c9ab730f5513206b999ec0d90d1fb: {
    type: 'country',
    imageId: 'b4u5c9v9eplflpfjohz8',
    name: 'Singapore',
  },
  le_7e7757b1e12abcb736ab9a754ffb617a: {
    type: 'country',
    imageId: '7mxbm2s01q38r2900zj',
    name: 'Spain',
  },
  le_5878a7ab84fb43402106c575658472fa: {
    type: 'country',
    imageId: 'sbt77s6ibekmoctipeza',
    name: 'Sri Lanka',
  },
  le_678c61ac5b4f91ca86e112fc50e63766: {
    type: 'multi_city_vicinity',
    imageId: 'yomtdyqjjozztpjzjo',
    name: 'Sydney',
  },
  le_8f85517967795eeef66c225f7883bdcb: {
    type: 'country',
    imageId: 'lids8h5rs9zj2xvkxr',
    name: 'Thailand',
  },
  le_1608659ea7ec15440fa87763b3f5d700: {
    type: 'multi_city_vicinity',
    imageId: '55nbfq3aqpbyhnn16b5',
    name: 'Tokyo',
  },
  le_cedebb6e872f539bef8c3f919874e9d7: {
    type: 'country',
    imageId: 'idn0i6aj221ks5zsyyx',
    name: 'Turkey',
  },
  le_cfecdb276f634854f3ef915e2e980c31: {
    type: 'country',
    imageId: 'bh52x5crz46nt54uxsl',
    name: 'United Kingdom',
  },
  le_757b505cfd34c64c85ca5b5690ee5293: {
    type: 'country',
    imageId: '2agstgb25xcdai5sl2y',
    name: 'United States of America',
  },
  le_5781a2637b476d781eb3134581b32044: {
    type: 'province_state',
    imageId: '0h1arx6nnm0gslh04pba',
    name: 'Victoria',
  },
  le_a597e50502f5ff68e3e25b9114205d4a: {
    type: 'country',
    imageId: '7pmj8f3m1cz7ip3920k',
    name: 'Vietnam',
  },
}

import { NO_BOOKING_PROTECTION_QUOTE_ID } from 'constants/bookingProtection'
import { CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION } from 'constants/checkout'
import uuidV4 from 'lib/string/uuidV4Utils'

export function generateBookingProtectionItem(quote: App.BookingProtectionQuote, amended: boolean = false): App.Checkout.BookingProtectionItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
    quoteId: quote.quoteId,
    amended,
  }
}

/**
 * Returns an item representing the "No booking protection" choice
 */
export function generateNoBookignProtectionItem():App.Checkout.BookingProtectionItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
    quoteId: NO_BOOKING_PROTECTION_QUOTE_ID,
  }
}

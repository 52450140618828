/**
 * A 'noop' or a function that does nothing
 * This allows for a single function to be created to be used everywhere
 * where a 'do nothing' function is needed.
 *
 * An example usage is in defaultProps for react objects that accept an event
 * By using noop you can always safely call them without null checking.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
const noop = (...args: Array<any>) => {}
export default noop

/**
 * A noop function that is also async (returns a promise)
 * @param args
 */
export const noopAsync = async(...args: Array<any>) => {}

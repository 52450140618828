import { fetchCruiseSearchCount } from 'actions/CruiseActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import getObjectKey from 'lib/object/getObjectKey'
import { useEffect, useMemo } from 'react'

const emptyState = {
  fetching: true,
  count: 0,
}

function useCruiseSearchCount(
  params: App.OfferListFilters,
): [number, boolean] {
  const dispatch = useAppDispatch()
  const key = useMemo(() => getObjectKey(params), [params])
  const countState = useAppSelector(state => state.cruise.cruiseSearchCount?.[key] || emptyState)

  useEffect(() => {
    dispatch(fetchCruiseSearchCount(params))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  return [countState.count, countState.fetching || false]
}

export default useCruiseSearchCount

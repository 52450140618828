import request from 'api/requestUtils'

export function getTrendingDestinations(regionCode: string) {
  return request.get<App.ApiResponse<Array<App.TrendingDestination>>>(`/api/search/hotel/v2/places/trending?region=${regionCode}&limit=15`)
    .then(res => res.result)
}

export function getHotelPopularHolidayTypes(regionCode: string) {
  return request.get<App.ApiResponse<Array<{ name: string }>>>(`/api/search/hotel/v1/popular/holiday-types?region=${regionCode}&limit=7`)
    .then(res => res.result)
}

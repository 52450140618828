import { createWithContextAndReduxStateHOC, createWithContextStateHOC } from 'contexts/support/withContextStateHOC'

import OfferPageStateContext from './offerPageStateContext'
import { OfferPageState } from './offerPageStateReducer'

const withOfferPageState = createWithContextStateHOC<OfferPageState>(OfferPageStateContext, 'withOfferPage')

export const withOfferPageAndReduxState = createWithContextAndReduxStateHOC<OfferPageState>(OfferPageStateContext, 'withOfferPage')

export default withOfferPageState

import React, { ComponentProps } from 'react'
import NumberRating from './NumberRating'

type InheritedNumberRatingProps = Pick<ComponentProps<typeof NumberRating>, 'variant' | 'numberPosition' | 'onlyNumber' | 'inlineLabel' | 'hideLink' | 'link' | 'onReviewSubTitleClick'>

interface Props extends InheritedNumberRatingProps {
  rating: App.OfferRating
}

function OfferRating({ rating, ...numberRatingProps }: Props) {
  return <NumberRating
    {...numberRatingProps}
    rating={rating.score}
    total={rating.reviewsTotal}
    ratingSubtitle={rating.reviewsSource === 'google' ? 'From online reviews' : undefined}
  />
}

export default OfferRating

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineRestaurantIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.84 11.63a3.002 3.002 0 0 0 1.169-.2215A3.0023 3.0023 0 0 0 19 10.75l2.83-2.83a1.0002 1.0002 0 0 0-.3254-1.629 1.0003 1.0003 0 0 0-1.0946.219l-2.86 2.82a1.001 1.001 0 0 1-.71.2958 1.0005 1.0005 0 0 1-.71-.2958l3.54-3.53a1.0035 1.0035 0 0 0 .2941-.71 1.0034 1.0034 0 0 0-.2941-.71 1.0041 1.0041 0 0 0-1.42 0l-3.53 3.54a.9998.9998 0 0 1 0-1.41l2.83-2.83a1.0036 1.0036 0 0 0 .2941-.71 1.0034 1.0034 0 0 0-.2941-.71 1.0041 1.0041 0 0 0-1.42 0L13.3 5.09a3 3 0 0 0 0 4.24L12 10.62l-8.27-8.3-.1-.06a.7101.7101 0 0 0-.17-.11l-.18-.07L3.16 2h-.27a.5702.5702 0 0 0-.18 0 .7.7 0 0 0-.17.09l-.16.1h-.07l-.06.1a.9998.9998 0 0 0-.11.17 1.0695 1.0695 0 0 0-.07.19v.11a11 11 0 0 0 3.11 9.34l2.64 2.63-5.41 5.4a.9986.9986 0 0 0-.2958.71 1.0003 1.0003 0 0 0 1.0058 1 1.0005 1.0005 0 0 0 .71-.29l6.07-5.98 2.83-2.83 2-2a3 3 0 0 0 2.11.89Zm-7.65 1.82-2.63-2.64A9.06 9.06 0 0 1 4 5.4l6.61 6.6-1.42 1.45Zm6.24.57A1.0076 1.0076 0 1 0 14 15.44l6.3 6.3c.191.1738.4418.2669.7.26a1.0004 1.0004 0 0 0 1.0058-1 .9998.9998 0 0 0-.2958-.71l-6.28-6.27Z"/>
  </SvgIcon>
}

export default LineRestaurantIcon

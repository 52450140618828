import { selectDateFormattingLocaleForRegion } from 'lib/datetime/dateUtils'
import getObjectKey from 'lib/object/getObjectKey'

export interface ListFormatterOptions {
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/ListFormat#type)
   *
   * @example conjunction => A, B, and C
   * @example disjunction => A, B, or C
   * @example unit => A, B, C
   */
  type: Intl.ListFormatType
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/ListFormat#style)
   *
   * @default long
   */
  style?: Intl.ListFormatStyle
  regionCode?: string
}

const cachedListFormatters = new Map<string, Intl.ListFormat>()
function getListFormatter(options: ListFormatterOptions): Intl.ListFormat {
  const cacheKey = getObjectKey(options)
  const cached = cachedListFormatters.get(cacheKey)
  if (cached) return cached

  const formatter = new Intl.ListFormat(
    options.regionCode ? selectDateFormattingLocaleForRegion(options.regionCode) : undefined,
    options,
  )
  cachedListFormatters.set(cacheKey, formatter)
  return formatter
}

export interface InlineListFormatOptions extends Pick<ListFormatterOptions, 'regionCode' | 'style'> {
}
const defaultInlineListFormatOptions: InlineListFormatOptions = {
  style: 'long',
}
export function formatInlineList(
  list: Array<string>,
  type: ListFormatterOptions['type'],
  options: InlineListFormatOptions = {},
): string {
  const opt = { ...defaultInlineListFormatOptions, ...options, type }
  try {
    const formatter = getListFormatter(opt)
    return formatter.format(list)
  } catch {
    return process.env.NODE_ENV === 'development' ? `UNSUPPORTED LIST FORMAT OPTION: ${JSON.stringify(opt)}` : ''
  }
}

import { useEffect, useState } from 'react'

function useKeyPress(targetKeys: Array<string>) {
  const [keys, setKeys] = useState<Record<string, boolean>>({})

  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      // Key pressed & if its a target key then set it to true
      if (targetKeys.includes(e.key)) {
        e.preventDefault()
        setKeys({
          ...keys,
          [e.key]: true,
        })
      }
    }

    const upHandler = (e: KeyboardEvent) => {
      // Key released & if its a target key then set to false
      if (targetKeys.includes(e.key)) {
        e.preventDefault()
        setKeys({
          ...keys,
          [e.key]: false,
        })
      }
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  })

  return targetKeys.map(targetKey => keys[targetKey] || false)
}

export default useKeyPress

import React from 'react'
import { Helmet } from 'react-helmet-async'
import config from 'constants/config'

function StructuredDataLogo() {
  if (config.BRAND !== 'luxuryescapes') {
    return null
  }

  const structured_data = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.luxuryescapes.com',
    logo: 'https://images.luxuryescapes.com/0elbw578cpcwcypbcj2',
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structured_data)}
      </script>
    </Helmet>
  )
}

export default StructuredDataLogo

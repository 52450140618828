import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  RESET_INSURANCE,
} from 'actions/actionConstants'
import {
  FETCH_INSURANCE_PRODUCTS,
  FETCH_INSURANCE_QUOTE,
  FETCH_INSURANCE_UPDATE_QUOTE,
} from 'actions/apiActionConstants'
import { arrayToObject } from 'lib/array/arrayUtils'
import { AnyAction } from 'redux'

function extractError(error: any) {
  if (typeof error === 'string') {
    return error
  }

  const { errors, message } = error
  if (Array.isArray(errors) && errors.length > 0 && errors[0].message) {
    return errors[0].message
  } else {
    return message
  }
}

export const initialInsuranceState: App.InsuranceState = {
  fetchingQuotes: {},
  fetchQuotesErrors: {},
  productListErrors: {},
  productLists: {},
  fetchingProductLists: {},
  products: {},
  quotes: {},
  updateQuotes: {},
}

const apiRequests = reducerSwitch<App.InsuranceState>({
  [FETCH_INSURANCE_QUOTE]: (state, action) => ({
    fetchingQuotes: {
      ...state.fetchingQuotes,
      [action.key]: true,
    },
  }),
  [FETCH_INSURANCE_PRODUCTS]: (state, action) => ({
    fetchingProductLists: {
      ...state.fetchingProductLists,
      [action.key]: true,
    },
  }),
  [FETCH_INSURANCE_UPDATE_QUOTE]: (state, action) => ({
    updateQuotes: {
      ...state.updateQuotes,
      [action.key]: {
        fetching: true,
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.InsuranceState>({
  [FETCH_INSURANCE_QUOTE]: (state, action) => ({
    fetchingQuotes: {
      ...state.fetchingQuotes,
      [action.key]: false,
    },
    quotes: {
      ...state.quotes,
      [action.key]: action.data,
    },
  }),
  [FETCH_INSURANCE_PRODUCTS]: (state, action: AnyAction & { key: string, data: Array<App.InsuranceProduct>}) => ({
    fetchingProductLists: {
      ...state.fetchingProductLists,
      [action.key]: false,
    },
    productLists: {
      ...state.productLists,
      [action.key]: action.data.map(data => data.id),
    },
    products: {
      ...state.products,
      ...arrayToObject(action.data, data => data.id),
    },
  }),
  [FETCH_INSURANCE_UPDATE_QUOTE]: (state, action) => ({
    updateQuotes: {
      ...state.updateQuotes,
      [action.key]: {
        quote: action.data,
        fetching: false,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.InsuranceState>({
  [FETCH_INSURANCE_QUOTE]: (state, action) => ({
    fetchingQuotes: {
      ...state.fetchingQuotes,
      [action.key]: false,
    },
    fetchQuotesErrors: {
      ...state.fetchQuotesErrors,
      [action.key]: extractError(action.error),
    },
  }),
  [FETCH_INSURANCE_PRODUCTS]: (state, action) => ({
    fetchingProductLists: {
      ...state.fetchingProductLists,
      [action.key]: false,
    },
    productListErrors: {
      ...state.productListErrors,
      [action.key]: extractError(action.error),
    },
  }),
  [FETCH_INSURANCE_UPDATE_QUOTE]: (state, action) => ({
    updateQuotes: {
      ...state.updateQuotes,
      [action.key]: {
        fetching: false,
        error: action.error,
      },
    },
  }),
})

const insuranceReducer = createReducer<App.InsuranceState>(initialInsuranceState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [RESET_INSURANCE]: () => initialInsuranceState,
})

export default insuranceReducer

import React, { useContext } from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import ContactContext from 'contexts/contactContext'
import styled from 'styled-components'
import { rem } from 'polished'
import Image from 'components/Common/Image'
import { mediaQueryUp } from 'components/utils/breakpoint'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const SupportImageContainer = styled.div`
  height: 168px;
  overflow: hidden;

  margin-left: -${rem(20)};
  margin-right: -${rem(20)};

  ${mediaQueryUp.tablet} {
    height: 280px;
    margin-left: -${rem(24)};
    margin-right: -${rem(24)};
  }
`

const SupportImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const IMAGE_ID = 'f2cbkfa3neeqk0suw16u'

function FlightSearchMaxFlightsModal() {
  const modalElement = useModalElementContext<boolean>()
  const currentContact = useContext(ContactContext)
  const contact = currentContact.defaultContact

  const sayYes = () => {
    modalElement.resolve(true)
  }

  const sayNo = () => {
    modalElement.resolve(false)
  }

  return (
    <Modal
      title="Want to book more than 4 routes?"
      primaryActionText="Call 1300 820 853"
      primaryActionHref={`tel:${contact.number}`}
      onPrimaryActionClick={sayYes}
      onSecondaryActionClick={sayNo}
    >

      <VerticalSpacer gap={20}>
        <SupportImageContainer>
          <SupportImage fit="center" id={IMAGE_ID} alt="Support" width={560} dpr={2} />
        </SupportImageContainer>

        <BodyTextBlock variant="large">Our concierge team are available 24 hours, 7 days to assist you. Call us to book more routes.</BodyTextBlock>
      </VerticalSpacer>
    </Modal>
  )
}

export default FlightSearchMaxFlightsModal

import PillLoadingBox from 'components/Luxkit/PillLoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Group from 'components/utils/Group'
import React from 'react'

function PriceRowValueDiscountWithCaptionLoadingSkeleton() {
  return <Group direction="horizontal" gap={4} verticalAlign="center">
    <TextLoadingBox typography="caption-medium" width="12ch" inline />
    {' '}
    <PillLoadingBox width={48} />
  </Group>
}

export default PriceRowValueDiscountWithCaptionLoadingSkeleton

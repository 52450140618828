import React, { useRef, useEffect } from 'react'

/**
 * Synchronise an internal and external (forwarded) ref.
 *
 * Taken from https://non-traditional.dev/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
 */
function useForwardedRef<T>(ref: React.ForwardedRef<T>) {
  const innerRef = useRef<T>(null)

  useEffect(() => {
    if (!ref) return
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  })

  return innerRef
}

export default useForwardedRef

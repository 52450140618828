import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getDepositSettings from 'checkout/selectors/payment/getDepositSettings'
import getDepositRemaining from './getDepositRemaining'

const getDepositServiceFee = createSelector(
  getDepositRemaining,
  getDepositSettings,
  (remaining, depositSettings): number => {
    if (depositSettings?.serviceFee) {
      const { isEnabled, percentage } = depositSettings.serviceFee
      return isEnabled ? floatify(remaining * percentage / 100) : 0
    }
    return 0
  },
)

export default getDepositServiceFee

export const baseThemeShadows : App.ThemeShadows<string> = {
  bottom: {
    small: '0px 0.7px 1.4px rgba(0, 0, 0, 0.07), 0px 1.9px 4px rgba(0, 0, 0, 0.05), 0px 4.5px 10px rgba(0, 0, 0, 0.05)',
    medium: '0px 1px 1.5px rgba(0, 0, 0, 0.07), 0px 1.5px 5px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05), 0px 15px 32px rgba(0, 0, 0, 0.04)',
    large: '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 4px 14px rgba(0, 0, 0, 0.05), 0px 8px 24px rgba(0, 0, 0, 0.05), 0px 24px 35px rgba(0, 0, 0, 0.05)',
  },
  flat: {
    small: '0px 0px 1px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03)',
    large: '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 0px 20px 10px rgba(0, 0, 0, 0.2)',
  },
  top: {
    small: '0px -0.7px 1.4px rgba(0, 0, 0, 0.07), 0px -1.9px 4px rgba(0, 0, 0, 0.05), 0px -4.5px 10px rgba(0, 0, 0, 0.05)',
    medium: '0px -1px 1.5px rgba(0, 0, 0, 0.07), 0px -1.5px 5px rgba(0, 0, 0, 0.05), 0px -5px 10px rgba(0, 0, 0, 0.05), 0px -15px 32px rgba(0, 0, 0, 0.04)',
    large: '0px -1px 5px rgba(0, 0, 0, 0.1), 0px -4px 14px rgba(0, 0, 0, 0.05), 0px -8px 24px rgba(0, 0, 0, 0.05), 0px -24px 35px rgba(0, 0, 0, 0.05)',
  },
}

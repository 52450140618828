import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMicrowaveIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M4.7031 7c0-.5523.4477-1 1-1h7c.5523 0 1 .4477 1 1v7.7778c0 .5523-.4477 1-1 1h-7c-.5523 0-1-.4477-1-1V7Zm2 1v5.7778h5V8h-5Z" clipRule="evenodd"/>
    <path fill="currentColor" d="M18.3984 16c.5523 0 1-.4477 1-1s-.4477-1-1-1c-.5522 0-1 .4477-1 1s.4478 1 1 1Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M2 6c0-1.6569 1.3432-3 3-3h14c1.6569 0 3 1.3431 3 3v10c0 1.5567-1.1856 2.8364-2.7031 2.9855V20c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1H6.7031v1c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1.0145C3.1856 18.8364 2 17.5567 2 16V6Zm12.7031 11V5H5c-.5523 0-1 .4477-1 1v10c0 .5523.4477 1 1 1h9.7031ZM19 17h-2.2969v-7H20v6c0 .5523-.4477 1-1 1ZM16.7031 5v3H20V6c0-.5523-.4477-1-1-1h-2.2969Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineMicrowaveIcon

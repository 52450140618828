import IconButton from 'components/Luxkit/Button/IconButton'
import LineHistoryIcon from 'components/Luxkit/Icons/line/LineHistoryIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import { formatSearchItemSubtitle } from 'components/Search/utils'
import { mediaQueryUp } from 'components/utils/breakpoint'
import React, { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

const ButtonWrapper = styled.div`

  ${mediaQueryUp.tablet} {
    display: none;
  }
`

const IconBlock = styled.div`
  width: ${rem(48)};
  height: ${rem(48)};
  padding: ${rem(12)};
  background-color: ${props => props.theme.palette.neutral.default.seven};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledListItem = styled(ListItem)`
    padding: ${rem(12)} ${rem(12)} ${rem(12)} 0;
    width: 100%;

    ${mediaQueryUp.tablet} {
      position: relative;
      padding: ${rem(12)};
      &:hover ${ButtonWrapper} {
        display: block;
      }
    }

    &.horizontal {
      padding: ${rem(10)} ${rem(14)};
      width: ${rem(325)};
    }
`

interface Props {
  recentSearch: App.FullRecentSearch;
  selected: boolean;
  onClick: (recentSearch: App.FullRecentSearch) => void;
  onDelete: (recentSearch: App.FullRecentSearch) => void;
  iconBackground?: boolean;
  horizontal?: boolean;
}

function ReactSearchSuggestionItem(props: Props) {
  const { recentSearch, onClick, onDelete, selected, iconBackground, horizontal } = props

  const subtitleText = formatSearchItemSubtitle(recentSearch)
  const deleteIconRef = useRef<HTMLButtonElement>(null)
  const handleClick = useCallback((e: React.MouseEvent) => {
    if (deleteIconRef.current?.contains(e.target as any)) {
      onDelete(recentSearch)
    } else {
      onClick(recentSearch)
    }
  }, [onClick, onDelete, recentSearch])

  const icon = useMemo(() => {
    if (!iconBackground) {
      return (<LineHistoryIcon />)
    }

    return (
      <IconBlock>
        <LineHistoryIcon size="M" />
      </IconBlock>
    )
  }, [iconBackground])

  return (
    <StyledListItem
      className={cn({ horizontal, iconBackground })}
      size="medium"
      startIcon={icon}
      onClick={handleClick}
      title={recentSearch.searchItem.format.mainText}
      subtitle={subtitleText}
      selected={selected}
    >
      <ButtonWrapper>
        <IconButton
          size="small"
          kind="tertiary"
          nonInteractable
          ref={deleteIconRef}
        >
          < LineTimesIcon/>
        </IconButton>
      </ButtonWrapper>
    </StyledListItem>
  )
}

export default ReactSearchSuggestionItem

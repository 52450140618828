import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import useCruiseOfferView from 'hooks/Cruise/useCruiseOfferView'
import { rem } from 'polished'
import Group from 'components/utils/Group'
import CruiseTileImageCarousel from './Common/CruiseTileImageCarousel'
import CruiseTileHeader from './Common/CruiseTileHeader'
import CruiseTileDetails from './Common/CruiseTileDetails'
import CruiseTilePrices from './Common/CruiseTilePrices'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import OfferTilePricing from 'components/OfferList/OfferListTiles/Support/OfferTilePricing'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import CruiseTileSearchPrices from './Common/CruiseTileSearchPrices'
import { useLocation } from 'react-router'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import Divider from 'components/Luxkit/Divider'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import useQueryParams from 'hooks/useQueryParams'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import { isCruiseOffer, isCruiseV1Offer } from 'lib/offer/offerTypes'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import Breakpoint from 'components/Common/Breakpoint'

const CruiseTileSheet = styled.div`
  background-color: ${(props) => props.theme.palette.neutral.default.eight};
  border-radius: ${(props) => props.theme.borderRadius.M};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const CruiseTileLayout = styled.div`
  position: relative;
  min-height: ${rem(350)};
  display: grid;
  grid-template:
    "media" ${rem(220)}
    "body" auto / 1fr;

  ${mediaQueryUp.tablet} {
    grid-template: "media body" 1fr / ${rem(280)} 1fr
  }
  ${mediaQueryUp.desktop} {
    grid-template: "media body" 1fr / ${rem(500)} 1fr
  }

  &.small-image{
    ${mediaQueryUp.desktop} {
      grid-template: "media body" 1fr / ${rem(280)} 1fr
    }
  }
`

const CruiseTileMedia = styled(CruiseTileImageCarousel)`
  grid-area: media;
`

const CruiseTileBody = styled.div`
  position: relative;
  grid-area: body;
  padding: ${rem(20)} ${rem(16)};
  display: grid;
  gap: ${rem(16)};
  grid-template:
    "content" auto
    "pricing" auto / 1fr;

  ${mediaQueryUp.tablet} {
    padding: ${rem(20)} ${rem(24)};
  }
  ${mediaQueryUp.desktop} {
    gap: ${rem(24)};
    grid-template: "content pricing" auto / 1fr ${rem(210)};
  }
`

const CruiseTileContent = styled(Group)`
  grid-area: content;
  position: relative;
`

const CruiseTilePricing = styled.div`
  grid-area: pricing;
  display: grid;
  grid-template:
    "cruise-logo" 1fr
    "call-to-action" auto / 1fr;
`

const PricingCruiseLogo = styled(ResponsiveImage)`
  grid-area: cruise-logo;
  place-self: center;
  display: none;

  ${mediaQueryUp.desktop} {
    display: block;
  }
`

const PricingCallToAction = styled(Group)`
  grid-area: call-to-action;
  align-self: end;
`

interface Props {
  offer: App.CruiseOffer | App.TourOffer | App.TourOfferSummary;
  tileType?: 'standalone' | 'search';
  onImageChange?: (idx: number, image?: App.Image) => void;
}

function CruiseTile({ offer, tileType, onImageChange }: Props) {
  const queryParams = useQueryParams()
  const cabinTypes = queryParams.get('cabinTypes') || undefined
  const view = useCruiseOfferView({ offer, cabinTypes })
  const {
    offerTitle,
    cruiseLineName,
    cruiseLineImage,
    offerDescription,
    departureCity,
    destinationCity,
    offerImages,
    isSpecialOffer,
    hasOnlySuitesAvailable,
    shipDetailsLabel,
    tileInclusions,
    itineraryDetailsLabel,
    productType,
    isVelocityEnabled,
    promotionDetails,
    evergreenInclusions,
    highlightedDepartureId,
    earliestDepartureDate,
    laterDates,
    discountPills,
    isFlashOffer,
    itinerary,
    itineraryFlash,
    departureDates,
    standardInclusions,
    luxPlusInclusions,
    isNew,
    isEndingIn,
  } = view

  const isStandalone = tileType === 'standalone'
  const location = useLocation()
  const useSmallImage = location.pathname.includes('search')
  const isSearch = tileType === 'search'
  const cruisePriceByNight = useCruisePriceByNight()
  const isAgentHubExclusive = !isCruiseOffer(offer) && offer.isAgentHubExclusive

  const flashPricePackage = useMemo(() => {
    if (cruisePriceByNight && offer?.lowestPricePackage) {
      return {
        ...offer?.lowestPricePackage,
        price: Math.round(offer.lowestPricePackage?.price / (offer.lowestPricePackage?.duration - 1) || 1),
      }
    }

    return offer?.lowestPricePackage
  }, [offer?.lowestPricePackage, cruisePriceByNight])

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
  }, [dispatchOfferListEvent])

  return <CruiseTileSheet>
    <ProductPaletteProvider product={offer}>
      <CruiseTileLayout className={useSmallImage ? 'small-image' : ''}>

        <TripGuard>
          <CSSBreakpoint min="tablet">
            <FloatingBookmarkContainer>
              <BookmarkButton offer={offer} />
            </FloatingBookmarkContainer>
          </CSSBreakpoint>
        </TripGuard>
        <CruiseTileMedia
          images={offerImages}
          isVelocityEnabled={isVelocityEnabled}
          productType={productType}
          onImageChange={onImageChange}
          onImageLoad={handleImageLoaded}
        />

        <CruiseTileBody>
          <TripGuard>
            <Breakpoint max="mobile">
              <OffsetBookmarkContainer>
                <BookmarkButton offer={offer} />
              </OffsetBookmarkContainer>
            </Breakpoint>
          </TripGuard>

          <CruiseTileContent gap={12} direction="vertical">
            <CruiseTileHeader
              isSpecialOffer={isSpecialOffer}
              offerTitle={offerTitle}
              departureCity={departureCity}
              destinationCity={destinationCity}
              isNew={isNew}
              isEndingIn={isEndingIn}
              cruiseLineImage={cruiseLineImage}
              cruiseLineName={cruiseLineName}
              badge={'badge' in offer ? offer.badge : undefined}
              isAgentHubExclusive={isAgentHubExclusive}
              offer={offer}
            />

            <CruiseTileDetails
              shipDetailsLabel={shipDetailsLabel}
              itinerary={itinerary}
              itineraryDetailsLabel={itineraryDetailsLabel}
              itineraryFlash={itineraryFlash}
              offerDescription={offerDescription}
              promotionDetails={promotionDetails}
              evergreenInclusions={evergreenInclusions}
              tileInclusions={tileInclusions}
              earliestDepartureDate={earliestDepartureDate}
              laterDates={laterDates}
              offerTitle={offerTitle}
              offer={offer}
              departures={'departuresSummary' in offer ? offer.departuresSummary : undefined}
              departureDates={departureDates}
              standardInclusions={standardInclusions}
              luxPlusInclusions={luxPlusInclusions}
            />
          </CruiseTileContent>

          <CruiseTilePricing>
            {cruiseLineImage && (
              <PricingCruiseLogo
                id={cruiseLineImage}
                src={cruiseLineImage}
                width={150}
                quality="eco"
                alt={cruiseLineName}
                role="presentation"
              />
            )}

            <PricingCallToAction
              fullWidth
              gap={16}
              direction="vertical"
              verticalAlign="end"
            >
              {isCruiseOffer(offer) && (
                <CruiseTilePrices
                  view={view}
                  isStandalone={isStandalone}
                  cabinCategoryFromFilter={cabinTypes}
                />
              )}

              {isCruiseV1Offer(offer) && !offer.bundledWithFlightsOnly && offer.lowestPricePackage && (
                <Group direction="horizontal" verticalAlign="end" horizontalAlign="space-between" gap={4}>
                  <OfferTilePricing
                    offer={offer}
                    pkg={flashPricePackage!}
                    isPricePerNight={cruisePriceByNight}
                  />
                  <CSSBreakpoint max="mobile">
                    <TextButton fit="flex" kind="primary" size="medium" nonInteractable>View</TextButton>
                  </CSSBreakpoint>
                </Group>
              )}

              {(isStandalone || offer) && <CSSBreakpoint min="tablet">
                <TextButton fit="desktop-full-width" kind="primary" nonInteractable>View offer</TextButton>
              </CSSBreakpoint>}

              {!isStandalone && !offer && <CSSBreakpoint min="tablet">
                <TextButton fit="flex" kind="primary" nonInteractable>
                  View offer
                </TextButton>
              </CSSBreakpoint>}
            </PricingCallToAction>
          </CruiseTilePricing>
        </CruiseTileBody>
      </CruiseTileLayout>

      {!isFlashOffer && isSearch && !cabinTypes && !hasOnlySuitesAvailable && (
        <>
          <Divider
            direction="horizontal"
            kind="primary"
          />
          <CruiseTileSearchPrices
            offer={offer as App.CruiseOffer}
            discountPills={discountPills}
            promotionDetails={promotionDetails}
            highlightedDepartureId={highlightedDepartureId}
          />
        </>
      )}
    </ProductPaletteProvider>
  </CruiseTileSheet>
}

export default CruiseTile

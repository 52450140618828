import { rem } from 'polished'
import React, { memo, useCallback, useContext, useRef } from 'react'
import styled from 'styled-components'

import { mediaQueryUp } from 'components/utils/breakpoint'
import {
  TOUR_V2_LOCATION_SEARCH_PLACEHOLDER_LABEL,
  TOUR_V2_LOCATION_SEARCH_TITLE_LABEL,
  TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES,
  TOUR_V2_SEARCH_TYPES,
  tourDurationOptions,
} from 'constants/tours'
import TextButton from 'components/Luxkit/Button/TextButton'

import SearchLocationInput from 'components/Search/SearchForm/SearchLocationInput/SearchLocationInput'
import SearchDateInputDesktop from 'components/Search/SearchForm/SearchDateInput/SearchDateInputDesktop'

import { SearchMenuStates } from '../type'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { searchPaneInteractEventName } from 'components/Search/utils'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import useDateSearchProps from 'components/SearchV2/Hooks/useDateSearchProps'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import { useGlobalToursSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalToursSearch'

const ControlsContainer = styled.div`
  position: relative;
  display: grid;
  gap: ${rem(12)};
  grid-template:
    "location-input"
    "date-input"
    "actions" auto / 1fr;

  ${mediaQueryUp.tablet} {
    grid-template: "location-input date-input actions" auto / 1fr 1fr ${rem(112)};
  }
`

const LocationInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: location-input;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: actions;
`

const SearchMobileInput = styled(SearchFormField)`
  ${mediaQueryUp.tablet} {
    display: none;
  }
`

const SearchDesktopInput = styled(SearchLocationInput)`
  display: none;

  ${mediaQueryUp.tablet} {
    display: block;
  }
`

interface Props {
  onSearchButtonClick?: () => void;
  onOpenSearchDrawer?: () => void;
}

function TourV2SearchInputs(props: Props) {
  const {
    onSearchButtonClick,
    onOpenSearchDrawer,
  } = props

  const {
    activeMenu,
    isAnytimeDateSelected,
    dateSearchOptionId,
    searchItem,
  } = useContext(GlobalSearchStateContext)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const { globalToursSearchState } = useGlobalToursSearchContext()

  const setActiveMenu = useCallback((menu: SearchMenuStates) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu })
  }, [globalSearchDispatch])

  const closeMenu = useCallback(() => {
    setActiveMenu(SearchMenuStates.Closed)
  }, [setActiveMenu])

  const handleApply = useCallback((shouldSearch?: boolean) => {
    if (shouldSearch) {
      onSearchButtonClick?.()
    }
    closeMenu()
  }, [closeMenu, onSearchButtonClick])

  const { selectFlexibleDates, selectAnytimeDates } = useDateSearchProps({ onAnytimeDatesSelected: closeMenu })

  const onToggleMenu = useCallback((nextFilter?: SearchMenuStates) => {
    if (!nextFilter || activeMenu === nextFilter) {
      closeMenu()
    } else {
      setActiveMenu(nextFilter)
    }
  }, [activeMenu, closeMenu, setActiveMenu])

  const openLocationMenu = useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()
    document.dispatchEvent(new CustomEvent(searchPaneInteractEventName))
    onToggleMenu(SearchMenuStates.Locations)
  }, [onToggleMenu])

  const openDatePickerMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    document.dispatchEvent(new CustomEvent(searchPaneInteractEventName))
    onToggleMenu(SearchMenuStates.Dates)
  }, [onToggleMenu])

  const inputRef = useRef<HTMLInputElement>(null)
  const controlsContainerRef = useRef<HTMLDivElement>(null)

  return (<ControlsContainer ref={controlsContainerRef}>
    <LocationInputContainer>
      <SearchMobileInput
        label={TOUR_V2_LOCATION_SEARCH_TITLE_LABEL}
        value={searchItem?.format.mainText}
        placeholder={TOUR_V2_LOCATION_SEARCH_PLACEHOLDER_LABEL}
        onClick={onOpenSearchDrawer}
      />
      <SearchDesktopInput
        label={TOUR_V2_LOCATION_SEARCH_TITLE_LABEL}
        placeholder={TOUR_V2_LOCATION_SEARCH_PLACEHOLDER_LABEL}
        searchTypes={TOUR_V2_SEARCH_TYPES}
        placeTypes={TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES}
        variant="dropdown"
        onLocationToggle={openLocationMenu}
        searchVertical="tour"
        inputRef={inputRef}
        controlsContainerRef={controlsContainerRef}
        placeholderItems={globalToursSearchState.popularDestinations}
      />
    </LocationInputContainer>
    <SearchDateInputDesktop
      dropdownAnchorRef={controlsContainerRef}
      isSpecificSearchEnabled={false}
      onDateToggle={openDatePickerMenu}
      isToggled={activeMenu === SearchMenuStates.Dates}
      dateSearchOptionId={dateSearchOptionId}
      anytimeDateSelected={isAnytimeDateSelected}
      selectAnytimeDate={selectAnytimeDates}
      selectFlexibleDates={selectFlexibleDates}
      durationOptionsCustom={tourDurationOptions}
      onDatesApply={handleApply}
      onDatesDecline={closeMenu}
      showFlexibleYearSelector
    />
    <ActionsContainer>
      <TextButton kind="primary" type="submit" size="large" onClick={onSearchButtonClick}>Search</TextButton>
    </ActionsContainer>
  </ControlsContainer>
  )
}

export default memo(TourV2SearchInputs)

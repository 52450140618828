import { SEARCH_STREAMING_PAGE_SIZE } from 'search/initialiseSearchSocket'

export function determineScrollFetching(
  offerList: App.OfferList | undefined,
  newOfferIds: Array<string> = [],
): boolean | undefined {
  const prevOfferIds = offerList?.offerIds
  const currentScrollFetching = offerList?.scrollFetching

  if (!prevOfferIds) return currentScrollFetching

  const newListLength = prevOfferIds.length + newOfferIds.length

  if (prevOfferIds.length < SEARCH_STREAMING_PAGE_SIZE && newListLength >= SEARCH_STREAMING_PAGE_SIZE) {
    return false
  }

  return currentScrollFetching
}

import React, { PropsWithChildren } from 'react'

const LuxPlusLabelContext = React.createContext<App.LuxPlusLabelPage | undefined>(undefined)

interface Props {
  page: App.LuxPlusLabelPage;
}

export function LuxPlusLabelProvider(props: PropsWithChildren<Props>) {
  const { page } = props

  return <LuxPlusLabelContext.Provider value={page}>
    {props.children}
  </LuxPlusLabelContext.Provider>
}

export default LuxPlusLabelContext

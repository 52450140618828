import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import { getDismissed, setDismissed as sessionSetDismissed } from 'luxPlus/storage/renewalBanner'
import useRenewalAction from 'luxPlus/hooks/useRenewalAction'
import { LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'

interface MappedProps {
  pathName: string
}

interface Props extends MappedProps {}

function LuxPlusRenewalBanner({ pathName }: Props) {
  const [dismissed, setDismissed] = useState(getDismissed())
  const handleRenewal = useRenewalAction()

  const dismiss = useCallback(() => {
    setDismissed(true)
    sessionSetDismissed()
  }, [])

  if (dismissed || pathName.includes(LUXURY_PLUS_PATHS.ACCOUNT_MANAGEMENT_PAGE)) return null
  return <PageBanner
    kind="critical"
    onDismiss={dismiss}
    primaryActionButtonProps={{
      onClick: handleRenewal,
      children: 'Renew now',
    }}
    secondaryActionButtonProps={{
      to: `/${LUXURY_PLUS_PATHS.ACCOUNT_MANAGEMENT_PAGE}`,
      children: 'Manage membership',
    }}
    description={<><b>Your LuxPlus+ Membership failed to renew due to to a payment issue.</b> Your benefits are on hold until payment is completed.</>}
  />
}

const mapStateToProps = (state: App.State): MappedProps => ({
  pathName: state.router.location.pathname,
})

export default connect(mapStateToProps)(LuxPlusRenewalBanner)

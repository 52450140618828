import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHistoryIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a10 10 0 0 0-6.88 2.77V3a1 1 0 0 0-2 0v4.5a1 1 0 0 0 1 1h4.5a1 1 0 0 0 0-2h-2.4A8 8 0 1 1 4 12a1 1 0 1 0-2 0A10 10 0 1 0 12 2Zm0 6a1 1 0 0 0-1 1v3a1.0001 1.0001 0 0 0 1 1h2a1.0001 1.0001 0 0 0 0-2h-1V9a1.0002 1.0002 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineHistoryIcon

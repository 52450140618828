export const all = ['recommendations'] as const
export const lists = [...all, 'list'] as const
export const list = (filters: string | Array<string>) => [...lists, { filters }]
export const details = [...all, 'details'] as const
export const detail = (id?: string, checkIn?: string, checkOut?: string) => [
  ...details,
  id,
  {
    checkIn,
    checkOut,
  },
]

export const hotels = [...all, 'hotels'] as const

export const hotelsForDestination = (
  destinationId: string,
  duration: number,
  startDate: string,
) => [...hotels, destinationId, duration, startDate] as const

import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'

export default function formatPropertyAddress(address: PublicOfferV2.PropertyAddressResponse): string {
  const obfuscatedAddress = [address.city, address.stateProvinceCode, address.stateProvinceName, address.postalCode, address.countryName].filter(i => i).join(' ')
  if (address.obfuscationRequired) {
    return obfuscatedAddress
  }

  return `${[address.line1, address.line2].filter(i => i).join(' ')} ${obfuscatedAddress}`
}

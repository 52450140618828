import styled from 'styled-components'
import { rem } from 'polished'

// SearchInputTriggerKeyValue contains key-value pair for the current
// user input for a search parameter
export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${rem(12)};
`

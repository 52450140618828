import { fillArray } from 'lib/array/arrayUtils'

export function generatePassengersFromOccupants(occupancies: App.Occupants): Array<App.Checkout.FlightPassenger> {
  return [
    // these ids need to match traveller form IDs
    // traveller form ids start at 1, so add another to index
    ...fillArray(occupancies.adults).map((index): App.Checkout.FlightPassenger => ({ id: `adult-${index + 1}`, type: 'adult' })),
    ...fillArray(occupancies.children ?? 0).map((index): App.Checkout.FlightPassenger => ({ id: `child-${index + 1}`, type: 'child' })),
    ...fillArray(occupancies.infants ?? 0).map((index): App.Checkout.FlightPassenger => ({ id: `infant-${index + 1}`, type: 'infant' })),
  ]
}

import React, { ComponentProps } from 'react'
import _PriceRowPrice from './_PriceRowPrice'

type InheritedBasePriceRowPrice = Pick<ComponentProps<typeof _PriceRowPrice>, 'data-testid' | 'discountPercentage' | 'discountType' | 'price' | 'saleUnit' | 'signDisplay' | 'size'>
type RequiredBasePriceRowPrice = Required<Pick<ComponentProps<typeof _PriceRowPrice>, 'caption'>>

interface Props extends InheritedBasePriceRowPrice, RequiredBasePriceRowPrice {
}

/**
 * Displays a formatted price with a leading caption and a trailing sale unit.
 */
function PriceRowPriceWithCaption(props: Props) {
  return <_PriceRowPrice {...props} className={PriceRowPriceWithCaption.name} />
}

export default PriceRowPriceWithCaption

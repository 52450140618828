import React, { useCallback, useMemo } from 'react'
import ListItem from 'components/Luxkit/List/ListItem'
import LandmarkIcon from 'components/Luxkit/Icons/custom/LandmarkIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import Label from 'components/Luxkit/Label'
import switchFunc from 'lib/function/switchFunc'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import { escapeRegex } from 'lib/string/regexUtils'
import PriceRowValueDiscount from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscount'
import SearchMenuTourItem from './SearchMenuTourItem'
import Image from 'components/Common/Image/Image'
import styled from 'styled-components'
import { rem } from 'polished'
import { getOfferIdFromSearchItem } from 'components/Search/utils'
import cn from 'clsx'
import Caption from 'components/Luxkit/Typography/Caption'
import useOffer from 'hooks/Offers/useOffer'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { isHotel } from 'lib/offer/offerTypes'
import { SnowplowClientEventParams } from 'analytics/snowplow/events'
import * as Analytics from 'analytics/analytics'
import LineGlobeAltIcon from '../../../Luxkit/Icons/line/LineGlobeAltIcon'
import { getTypeaheadItemType, TypeaheadItemType } from 'lib/search/typeaheadUtils'

enum TypeaheadEventKey {
  FLASH_HOTEL = 'hotel',
  LPC = 'tactical_ao_hotel',
  LPP_PROMO = 'bedbank_promotion',
  LPP = 'bedbank_hotel',
  DESTINATION = 'destination',
}

const TYPEAHEAD_CLICK_TYPE_EVENTS: Partial<Record<TypeaheadItemType, SnowplowClientEventParams>> = {
  [TypeaheadEventKey.FLASH_HOTEL]: {
    subject: 'Flash Typeahead item',
    action: 'They clicked a flash typeahead list item',
    category: 'typeahead',
    type: 'interaction',
    optimizelyEventId: '27818000374',
    optimizelyEventKey: 'search-click-typeahead-hotel',
  },
  [TypeaheadEventKey.LPC]: {
    subject: 'LPC Typeahead item',
    action: 'They clicked a LPC typeahead list item',
    category: 'typeahead',
    type: 'interaction',
    optimizelyEventId: '27835760262',
    optimizelyEventKey: 'search-click-typeahead-tactical_ao_hotel',
  },
  [TypeaheadEventKey.LPP_PROMO]: {
    subject: 'LPP Promo Typeahead item',
    action: 'They clicked a LPP Promo typeahead list item',
    category: 'typeahead',
    type: 'interaction',
    optimizelyEventId: '27804620268',
    optimizelyEventKey: 'search-click-typeahead-bedbank_promotion',
  },
  [TypeaheadEventKey.LPP]: {
    subject: 'LPP Typeahead item',
    action: 'They clicked a LPP typeahead list item',
    category: 'typeahead',
    type: 'interaction',
    optimizelyEventId: '27857810141',
    optimizelyEventKey: 'search-click-typeahead-bedbank_hotel',
  },
  [TypeaheadEventKey.DESTINATION]: {
    subject: 'Destination Typeahead item',
    action: 'They clicked a destination typeahead list item',
    category: 'typeahead',
    type: 'interaction',
    optimizelyEventId: '27842830053',
    optimizelyEventKey: 'search-click-typeahead-destination',
  },
}

const TYPEAHEAD_CLICK_EVENT: SnowplowClientEventParams = {
  subject: 'Overall Typeahead Click',
  action: 'They clicked a typeahead list item',
  category: 'typeahead',
  type: 'interaction',
  optimizelyEventId: '28811920740',
  optimizelyEventKey: 'click-typeahead-list-item',
}

function trackTypeaheadTypeEvent(eventKey: TypeaheadItemType) {
  const eventParams = TYPEAHEAD_CLICK_TYPE_EVENTS[eventKey]
  if (eventParams) {
    Analytics.trackClientEvent(eventParams)
  }
}

const ImageBlock = styled.div`
  flex-shrink: 0;
  width: ${rem(48)};
  height: ${rem(48)};
`

const IconBlock = styled.div`
  width: ${rem(48)};
  height: ${rem(48)};
  padding: ${rem(12)};
  background-color: ${props => props.theme.palette.neutral.default.seven};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledListItem = styled(ListItem)`
  &.horizontal {
    width: ${rem(325)};
  }
`

export const SEARCH_MENU_LIST_ITEM_CLASS_NAME = 'search-menu-list-item'

interface Props {
  /** highlights the portion of word that matches the search phrase */
  searchPhrase?: string;
  searchItem: App.SearchItem
  onClick?: (searchItem: App.SearchItem, mouseEvent?: React.MouseEvent<HTMLElement>) => void
  ['data-testid']?: string
  selected?: boolean
  imageId?: string;
  horizontal?: boolean
  offerId?: string
  searchVertical?: App.SearchPlaceVertical
  useImage?: boolean
  className?: string
}

const searchItemPropertiesSwitch = switchFunc({
  city: { Icon: LineMapMarkerIcon, typeIndicator: 'City' },
  state: { Icon: LineMapMarkerIcon, typeIndicator: 'State' },
  province: { Icon: LineMapMarkerIcon, typeIndicator: 'Province' },
  landmark: { Icon: LandmarkIcon, typeIndicator: 'Landmark' },
  property: { Icon: LineBedIcon, typeIndicator: 'Hotel' },
  experience: { Icon: LineCompassIcon, typeIndicator: null },
  destination: { Icon: LineMapMarkerIcon, typeIndicator: null },
  airport: { Icon: LinePlaneFlyIcon, typeIndicator: null },
  anywhere: { Icon: LineGlobeAltIcon, typeIndicator: 'Anywhere' },
}, { Icon: LineMapMarkerIcon, typeIndicator: null })

function SearchMenuListItem(props: Props) {
  const {
    searchItem,
    onClick,
    searchPhrase,
    selected = false,
    horizontal = false,
    searchVertical,
  } = props

  const useImage = searchVertical === 'tour' || props.useImage

  const [offer] = useOffer(getOfferIdFromSearchItem(searchItem))

  const {
    Icon,
    subtitle,
    title,
    offerType,
    offerDiscount,
    isBedbankPromoOffer,
    hasTactical,
  } = useMemo(() => {
    let iconToSelect = searchItem.searchType === 'destination' ? searchItem.destinationType : searchItem.searchType

    if (searchItem.format.mainText === 'Anywhere') {
      iconToSelect = 'anywhere'
    }

    const properties = searchItemPropertiesSwitch(iconToSelect)

    const { mainText, secondaryText } = searchItem.format
    const { Icon, typeIndicator } = properties

    const subtitleItems: Array<string> = []

    if (typeIndicator) {
      subtitleItems.push(typeIndicator)
    }

    if (
      secondaryText &&
      mainText.localeCompare(secondaryText, 'en', { sensitivity: 'base' }) !== 0
    ) {
      subtitleItems.push(secondaryText)
    }

    return {
      Icon,
      subtitle: subtitleItems.join(' · '),
      title: mainText,
      offerType: searchItem.searchType === 'property' ? searchItem.offerType : null,
      offerDiscount: searchItem.searchType === 'property' ? searchItem.offerDiscount : null,
      isBedbankPromoOffer: searchItem.searchType === 'property' ? searchItem.bedbankHasPromotions : false,
      hasTactical: searchItem.searchType === 'property' ? searchItem.hasTactical : false,
    }
  }, [searchItem])

  const startIcon = useMemo(() => {
    let imageId = props.imageId || searchItem.imageId

    if (offer && isHotel(offer)) {
      imageId = offer.image.id ?? imageId
    }

    if (!useImage) {
      return <Icon />
    }

    return (
      <>
        {!imageId && <IconBlock>
          <Icon />
        </IconBlock>}
        {imageId && <ImageBlock>
          <Image
            fit="center"
            width={68}
            height={92}
            dpr={2}
            id={imageId}
          />
        </ImageBlock>
        }
      </>
    )
  }, [offer, Icon, props.imageId, useImage, searchItem.imageId])

  const discountLabel = useMemo(() => {
    if (hasTactical) {
      return (
        <LabelGroup>
          <Label variant="highlight-secondary" kind="plain">Limited time offer</Label>
          {!!offerDiscount && offerDiscount >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE && <PriceRowValueDiscount
            type="estimate"
            discountPercentage={offerDiscount}
          />}
        </LabelGroup>
      )
    }
    else if (offerType === 'hotel' || offerType === 'tactical_ao_hotel' || isBedbankPromoOffer) {
      return (
        <LabelGroup>
          { (offerType === 'hotel' || isBedbankPromoOffer) && <Label variant="highlight-secondary" kind="plain">Limited time offer</Label>}
          {!!offerDiscount && offerDiscount >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE && <PriceRowValueDiscount
            type="estimate"
            discountPercentage={offerDiscount}
          />}
        </LabelGroup>
      )
    }
  }, [offerType, offerDiscount, isBedbankPromoOffer, hasTactical])

  const subTitleLabel = useMemo(() => {
    if (subtitle && useImage) {
      return (
        <Caption variant="medium" lineClamp={1}>
          {subtitle}
        </Caption>
      )
    }

    return subtitle
  }, [subtitle, useImage])

  const handleClick = useCallback((mouseEvent: React.MouseEvent<HTMLElement>) => {
    Analytics.trackClientEvent(TYPEAHEAD_CLICK_EVENT)

    const type = getTypeaheadItemType(searchItem)
    trackTypeaheadTypeEvent(type)

    onClick?.(searchItem, mouseEvent)
  }, [onClick, searchItem])

  const label = useMemo(() => {
    if (!searchPhrase) {
      return title
    }

    const search = new RegExp(`(${escapeRegex(searchPhrase)})`, 'ig')
    const bits = title.split(search)

    return <>
      {bits.map((textBit, index) => textBit.toLowerCase() === searchPhrase.toLowerCase() ? <b key={index}>{textBit}</b> : textBit, '')}
    </>
  }, [searchPhrase, title])

  if (searchItem.searchType === 'tour') {
    return <SearchMenuTourItem
      searchItem={searchItem}
      searchPhrase={searchPhrase}
      selected={selected}
      data-testid={props['data-testid']}
      offerId={props.offerId}
    />
  }

  return <StyledListItem
    selected={selected}
    className={`${SEARCH_MENU_LIST_ITEM_CLASS_NAME} ${cn({ horizontal, useImage })} ${props.className}`}
    startIcon={startIcon}
    title={label}
    subtitle={
      <VerticalSpacer gap={4}>
        {subTitleLabel}
        {discountLabel}
      </VerticalSpacer>}
    onClick={handleClick}
    titleLineClamp={(horizontal && useImage) ? 1 : 2}
    data-testid={props['data-testid']}
  />
}

export default SearchMenuListItem

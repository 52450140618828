import { excludeNullOrUndefined } from 'checkout/utils'
import { objectEntries } from 'lib/object/objectUtils'

export function mergeFees(otherFares: Array<App.Checkout.OtherFees>): App.Checkout.OtherFees {
  return otherFares.filter(excludeNullOrUndefined).reduce<App.Checkout.OtherFees>((merged, fares) => {
    objectEntries(fares).forEach(([key, value]) => {
      if (!value) {
        return merged
      }

      if (!merged[key]) {
        merged[key] = value
        return merged
      }

      merged[key] += value
    })
    return merged
  }, {})
}

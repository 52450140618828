import config from 'constants/config'
import { getAge } from 'lib/datetime/dateUtils'
import { createSelector } from 'reselect'

export const getDefaultUserDriverCategory = createSelector(
  (state: App.State) => state.auth.account.dob,
  (dateOfBirth): [App.CarHireDriverAgeCategory, number | undefined] => {
    if (dateOfBirth) {
      const years = getAge(dateOfBirth)
      if (years <= 29) {
        return [1, years]
      } else if (years >= 70) {
        return [3, years]
      } else {
        return [2, years]
      }
    }
    // default category
    return [2, undefined]
  },
)

export const isCarHireInsuranceAvailable = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (currentRegionCode) => config.CARHIRE_INSURANCE_ENABLED &&
  config.CARHIRE_INSURANCE_REGIONS.split(',').includes(currentRegionCode),
)

interface FaresBaggageOption {
  key: string;
  providerKey: string;
  quantity: number;
  amount: number;
  carrierCode: string;
}

export interface FlightGroup {
  flights: Array<{
    checkedBaggageOptions: Array<FaresBaggageOption>;
  }>;
}

export const applyTravelFusionComplementaryReducer =
  (complementary: App.Checkout.BaggageQuantityMap) =>
    (acc: App.Checkout.PaxBaggageMap, pax: App.Checkout.FlightPassenger) => {
      if (pax.type === 'infant') { return acc }
      return { ...acc, [pax.id]: complementary }
    }

export function buildBaggageMapFromFares(flightGroupFares: FlightGroup): App.Checkout.BaggageQuantityMap {
  const baggageOpt = flightGroupFares.flights[0].checkedBaggageOptions.find((option: FaresBaggageOption) => !option.amount)
  if (!baggageOpt) { return {} }
  const baggageId = [baggageOpt.providerKey, baggageOpt.quantity, baggageOpt.key].join('~')
  return { [baggageId]: 1 }
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMapIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m21.32 5.05-6-2h-.07a.6998.6998 0 0 0-.14 0h-.43L9 5 3.32 3.05a1 1 0 0 0-.9.14A1 1 0 0 0 2 4v14a.9998.9998 0 0 0 .68.95l6 2a.9998.9998 0 0 0 .62 0l5.7-1.9L20.68 21c.1062.0144.2138.0144.32 0a.94.94 0 0 0 .58-.19A.9997.9997 0 0 0 22 20V6a1.0004 1.0004 0 0 0-.68-.95ZM8 18.61l-4-1.33V5.39l4 1.33v11.89Zm6-1.33-4 1.33V6.72l4-1.33v11.89Zm6 1.33-4-1.33V5.39l4 1.33v11.89Z"/>
  </SvgIcon>
}

export default LineMapIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidCheckCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm4.2 8.3-4.8 4.8c-.4.4-1 .4-1.4 0l-2.2-2.2c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.5 1.5 4.1-4.1c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4Z"/>
  </SvgIcon>
}

export default SolidCheckCircleIcon

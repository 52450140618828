export const all = ['orders'] as const
export const lists = [...all, 'list'] as const
export const list = (filters: string | Array<string>) => [...lists, { filters }]
export const details = [...all, 'details'] as const
export const detail = (id?: string) => [...details, id]

export const detailsByMemberId = (regionCode: string, memberId?: string) => [
  ...lists,
  {
    memberId,
    regionCode,
  },
]

export const flightDetailsOrderAndItem = (orderId: string, itemId: string) =>
  [...all, orderId, 'flightDetails', itemId] as const

export const flightDetailsOrderAndItems = (
  orderId: string,
  itemIds: Array<string>,
) => [...all, orderId, 'flightDetails', { filter: itemIds }] as const

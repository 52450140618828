import { pluralizeToString } from 'lib/string/pluralize'

export const getPriceBundlePackage = (bundledPackages: Array<App.Package>) => {
  return bundledPackages.reduce((acc, item) => {
    return {
      price: acc.price + item.price,
      memberPrice: acc.memberPrice + item.memberPrice,
      value: acc.value + item.value,
      memberValue: acc.memberValue + item.memberValue,
      taxesAndFees: acc.taxesAndFees + item.taxesAndFees,
      propertyFees: acc.propertyFees + item.propertyFees,
      surcharge: acc.surcharge + item.surcharge,
      discountPercent: Number(Number((((acc.memberPrice || acc.price) + (acc.memberPrice || item.price)) / (acc.value + item.value)) * 100).toFixed(2)),
      bundleDiscountPercent: 0,
      durationLabel: pluralizeToString('Night', acc.duration + item.duration),
      duration: acc.duration + item.duration,
      trackingPrice: acc.trackingPrice + (item.trackingPrice ?? 0),
    }
  }, {
    price: 0,
    memberPrice: 0,
    value: 0,
    memberValue: 0,
    taxesAndFees: 0,
    propertyFees: 0,
    surcharge: 0,
    discountPercent: 0,
    bundleDiscountPercent: 0,
    durationLabel: '',
    duration: 0,
    trackingPrice: 0,
  } as Required<App.PackagePrice>)
}

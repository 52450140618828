import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'
import variables, { containerLarge } from 'styles/vendor/bootstrap/variables'

export type LayoutContainerSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  // size is 'old' way to define layout sizes, this no longer matches design definitions
  size?: LayoutContainerSizes;
  // variant attempts to match what design are currently using
  // still work in progress
  variant?: 'medium' | 'large';
  relative?: boolean;
}

const Container = styled.div`
  padding-right: ${rem(variables.gutterWidth / 2)};
  padding-left: ${rem(variables.gutterWidth / 2)};
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  &.relative {
    position: relative;
  }

  &.xs {
    max-width: ${rem(512)};
  }

  &.sm {
    max-width: ${rem(750)};
  }

  &.md {
    max-width: ${rem(990)};
  }

  &.lg {
    max-width: ${containerLarge};
  }

  &.xl {
    max-width: ${rem(1352)};
  }

  /* Variant variations are gutterless, better matching design usages */
  &.variant-large {
    max-width: ${rem(1172)};
  }

  &.variant-medium {
    max-width: ${rem(932)};
  }
`

const LayoutContainer = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { size, className, variant, relative, ...rest } = props

  return <Container
    {...rest}
    ref={ref}
    className={cn(
      className,
      !variant ? size : undefined,
      variant ? `variant-${variant}` : undefined,
      relative ? 'relative' : undefined,
    )}
  />
})

LayoutContainer.defaultProps = {
  size: 'lg',
}

export default LayoutContainer

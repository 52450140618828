import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineEditAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5 18h4.24a1.0005 1.0005 0 0 0 .71-.29l6.92-6.93L19.71 8a1.001 1.001 0 0 0 .2958-.71.9994.9994 0 0 0-.2958-.71l-4.24-4.29a1.001 1.001 0 0 0-.71-.2958 1.0003 1.0003 0 0 0-.71.2958l-2.82 2.83-6.94 6.93a1 1 0 0 0-.29.71V17a1 1 0 0 0 1 1Zm9.76-13.59 2.83 2.83-1.42 1.42-2.83-2.83 1.42-1.42ZM6 13.17l5.93-5.93 2.83 2.83L8.83 16H6v-2.83ZM21 20H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 21.8946 2.7348 22 3 22h18a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineEditAltIcon

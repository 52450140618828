import config from 'constants/config'
import {
  FLYING_BLUE_PAYMENT_TYPE,
} from 'constants/payment'
import { getPaymentMethodsByRegion } from 'lib/payment/getPaymentMethods'

export const isPaymentTypeFlyingBlueEnabled = (regionCode: string): boolean => {
  const flyingBlueEnabled = getPaymentMethodsByRegion(regionCode).includes(FLYING_BLUE_PAYMENT_TYPE)
  return !!regionCode && !!config.PAYMENT_TYPE_FLYING_BLUE_ENABLED && flyingBlueEnabled
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidBoltAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 9h-5V3c0-.3-.2-.6-.4-.8-.4-.3-1.1-.2-1.4.2l-8 10.9999c-.1.2-.2.4-.2.6 0 .6.4 1 1 1h5v6c0 .6.4 1 1 1 .3 0 .6-.2.8-.4l8-11c.1-.2.2-.4.2-.6 0-.6-.4-1-1-1Z"/>
  </SvgIcon>
}

export default SolidBoltAltIcon

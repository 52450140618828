import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Group from 'components/utils/Group'
import React, { useCallback } from 'react'
import noop from 'lib/function/noop'

interface Props {
  options: Array<{ label: string, value: string | number, icon?: React.ReactElement }>;
  value?: string | number;
  onChange?: (option: string) => void;
}

function OfferCardCarouselSectionFilters({
  options,
  value,
  onChange = noop,
}: Props) {
  const onFilterSelect = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const value = e.currentTarget.dataset.value!
    onChange(value)
  }, [onChange])

  return (
    <HorizontalContainerWithFadedOverflow>
      <Group direction="horizontal" gap={8}>
        {options.map(option =>
          <FilterChip
            key={option.value}
            size="medium"
            selected={option.value === value}
            data-value={option.value}
            onClick={onFilterSelect}
            startIcon={option.icon}
          >
            {option.label}
          </FilterChip>,
        )}
      </Group>
    </HorizontalContainerWithFadedOverflow>
  )
}

export default React.memo(OfferCardCarouselSectionFilters)

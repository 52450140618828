export default function dedupeConcat<T>(
  array1: Array<T>,
  array2: Array<T>,
  idFunction: (item: T) => any = a => a,
) {
  const newArray = [...array1]
  array2.forEach(array2Item => {
    const array2ItemId = idFunction(array2Item)
    if (!array1.some(array1Item => idFunction(array1Item) == array2ItemId)) {
      newArray.push(array2Item)
    }
  })
  return newArray
}

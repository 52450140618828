import React from 'react'
import List from 'components/Common/List'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import ExperienceDetailsAboutLocationMap from './ExperienceDetailsAboutLocationMap'
import ExperienceDetailsModalAboutAccordion from './ExperienceDetailsModalAboutAccordion'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

interface Props {
  experienceView: App.ExperienceBookingView;
  pickupPoint?: App.ExperiencePickupPoint;
  showLocation?: boolean;
}

function ExperienceDetailsModalAboutSection(props: Props) {
  const { experienceView, pickupPoint, showLocation = true } = props

  const copy = experienceView.originalExperience.copy
  return (
    <VerticalSpacer gap={24}>
      {experienceView.type === 'channel_manager' && copy.highlights.length > 0 && (
        <VerticalSpacer gap={12}>
          <Heading variant="heading6">Why you should do it!</Heading>
          <List>
            {copy.highlights.map((highlight, idx) => <li key={`${idx}-${highlight}`}>{highlight}</li>)}
          </List>
        </VerticalSpacer>
      )}
      {copy.included && (
        <div>
          <BodyText variant="large" weight="bold">Inclusions</BodyText>
          <MarkdownRender type="compact" content={copy.included} />
        </div>
      )}
      <ExperienceDetailsModalAboutAccordion title="Overview">
        <MarkdownRender content={copy.dealDescription} />
      </ExperienceDetailsModalAboutAccordion>
      {showLocation && <ExperienceDetailsModalAboutAccordion title="Location">
        <VerticalSpacer gap={16}>
          {experienceView?.location &&
            <Heading as="h3" variant="heading3" align="center">
              {experienceView.location}
            </Heading>
          }
          <ExperienceDetailsAboutLocationMap
            experienceView={experienceView}
            pickupPoint={pickupPoint}
          />
        </VerticalSpacer>
      </ExperienceDetailsModalAboutAccordion>}
      {copy.thingsToKnow?.map((item, idx) => (
        <ExperienceDetailsModalAboutAccordion title={item.title} key={`${item.title}-${idx}`}>
          <MarkdownRender content={item.content} type="compact" />
        </ExperienceDetailsModalAboutAccordion>
      ))}
    </VerticalSpacer>
  )
}

export default ExperienceDetailsModalAboutSection

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBedDoubleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20 3.5H4a3 3 0 0 0-3 3v13a1 1 0 0 0 1 1h4a.9995.9995 0 0 0 .83-.45l1.71-2.55h6.92l1.71 2.55a.9998.9998 0 0 0 .83.45h4a1.0001 1.0001 0 0 0 1-1v-13a3 3 0 0 0-3-3Zm1 15h-2.46L16.83 16a.9994.9994 0 0 0-.83-.5H8a1.0006 1.0006 0 0 0-.83.45L5.46 18.5H3v-5h18v5Zm-14-7v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1H7Zm6 0v-1a1.0002 1.0002 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4Zm8 0h-2v-1a3 3 0 0 0-3-3h-2a2.9998 2.9998 0 0 0-2 .78 2.9998 2.9998 0 0 0-2-.78H8a3 3 0 0 0-3 3v1H3v-5a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v5Z"/>
  </SvgIcon>
}

export default LineBedDoubleIcon

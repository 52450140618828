import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import React from 'react'
import styled, { css } from 'styled-components'

const HorizontalDividerCSS = css`
  max-height: 1px;
  height: 1px;
  max-width: 100%;
  width: 100%;
  align-self: auto;
  justify-self: stretch;
  place-self: auto stretch;
`

const VerticalDividerCSS = css`
  max-height: 100%;
  height: 100%;
  max-width: 1px;
  width: 1px;
  align-self: stretch;
  justify-self: auto;
  place-self: stretch auto;
`

// HR has too many implicit styles we just don't want.
// e.g. margins are specified. But if we remove them things like `> * + *` can't target them
// there's little semantic value in hr elements so use a div.
const DividerElement = styled.div`
  &.direction-horizontal {
    ${HorizontalDividerCSS}
  }
  &.direction-vertical {
    ${VerticalDividerCSS}
  }
  ${mediaQueryUp.tablet} {
    &.tablet-direction-horizontal {
      ${HorizontalDividerCSS}
    }
    &.tablet-direction-vertical {
      ${VerticalDividerCSS}
    }
  }
  ${mediaQueryUp.desktop} {
    &.desktop-direction-horizontal {
      ${HorizontalDividerCSS}
    }
    &.desktop-direction-vertical {
      ${VerticalDividerCSS}
    }
  }

  &.kind-primary {
    background-color: ${props => props.theme.palette.neutral.default.five};
  }
  &.kind-secondary {
    background-color: ${props => props.theme.palette.neutral.default.six};
  }
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  kind: 'primary' | 'secondary';
  /**
   * @default horizontal
   */
  direction?: 'horizontal' | 'vertical';
  /**
   * @default direction
   */
  tabletDirection?: 'horizontal' | 'vertical';
  /**
   * @default tabletDirection
   */
  desktopDirection?: 'horizontal' | 'vertical';
  className?: string;
}

function Divider(props: Props) {
  const {
    kind,
    direction = 'horizontal',
    tabletDirection,
    desktopDirection,
    className,
    ...rest
  } = props
  return <DividerElement
    {...rest}
    role="separator"
    aria-orientation={direction}
    className={cn(
      className,
      `kind-${kind}`,
      `direction-${direction}`,
      `tablet-direction-${tabletDirection ?? direction}`,
      `desktop-direction-${desktopDirection ?? tabletDirection ?? direction}`,
    )}
  />
}

export default Divider

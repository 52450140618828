import { css } from 'styled-components'

export const SmallButtonTypography = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: ${props => props.theme.font.secondary.weight.regular};
  font-family: ${props => props.theme.font.secondary.family};
  white-space: nowrap;
`

export const MediumButtonTypography = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${props => props.theme.font.secondary.weight.regular};
  font-family: ${props => props.theme.font.secondary.family};
  white-space: nowrap;
`

export const LargeButtonTypography = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${props => props.theme.font.secondary.weight.regular};
  font-family: ${props => props.theme.font.secondary.family};
  white-space: nowrap;
`

import { buildHotelUrl } from './utils'

export function getTrendingDestinationFilters(countryCode: string): Array<{ id: App.DestinationSelectedFilters, title: string }> {
  if (countryCode == 'AU' || countryCode === 'NZ') {
    return [{ id: 'hotel', title: 'Places to stay' }, { id: 'cruise', title: 'Cruising the seas' }, { id: 'tour', title: 'Guided group tours' }]
  } else {
    return [{ id: 'hotel', title: 'Places to stay' }, { id: 'tour', title: 'Guided group tours' }]
  }
}

const buildTourUrl = (destination: string, destinationId: string) => `/search/tours?destinationId=${destinationId}&destinationName=${destination}`
const buildCruiseUrl = (destination: string, destinationId: string) => `/search/cruises?destinationId=${destinationId}&destinationName=${destination}`

export type TrendingDestination = {
  name: string;
  imageId: string;
  url: string;
  destinationId?: string;
}

export function getTrendingDestinationsForFilter(filterId: App.DestinationSelectedFilters, currentRegionCode: string) {
  // Trending destinations of Australia are the default ones for all other countries
  switch (filterId) {
    case 'hotel':
      return trendingHotelDestinations[currentRegionCode] || trendingHotelDestinations.AU
    case 'cruise':
      return trendingCruiseDestinations[currentRegionCode] || trendingCruiseDestinations.AU
    case 'tour':
      return trendingTourDestinations[currentRegionCode] || trendingTourDestinations.AU
    default:
      return trendingHotelDestinations[currentRegionCode] || trendingTourDestinations.AU
  }
}

type DestinationType = {
  [destinationName: string]: {
    displayName: string;
    urlName?: string;
    destinationId: string;
  }
}

const DESTINATIONS: DestinationType = {
  africa: { displayName: 'Africa', destinationId: 'le_b02e6098244d867ef9e033afaf097d81' },
  antigua: { displayName: 'Antigua and Barbuda', destinationId: 'le_b599e8250e4481aaa405a715419c8179' },
  australia: { displayName: 'Australia', destinationId: 'le_d3d9446802a44259755d38e6d163e820' },
  bali: { displayName: 'Bali', destinationId: 'le_64930b7151b6bc3e289f37d5da62ac9f' },
  bangkok: { displayName: 'Bangkok', destinationId: 'le_ff7aa1f9b4062bfee0f99a0453506f1b' },
  brisbane: { displayName: 'Brisbane', destinationId: 'le_b96d145f6c31e35755f4a6841574ba37' },
  california: { displayName: 'California', destinationId: 'le_7eabe3a1649ffa2b3ff8c02ebfd5659f' },
  china: { displayName: 'China', destinationId: 'le_a5bfc9e07964f8dddeb95fc584cd965d' },
  cook_islands: { displayName: 'Cook Islands', destinationId: 'le_3416a75f4cea9109507cacd8e2f2aefc' },
  dubai: { displayName: 'Dubai', destinationId: 'le_43baa6762fa81bb43b39c62553b2970d' },
  egypt: { displayName: 'Egypt', destinationId: 'le_9a1158154dfa42caddbd0694a4e9bdc8' },
  europe: { displayName: 'Europe', destinationId: 'le_12767faf1bf8cafc7594430586c558c2' },
  fiji: { displayName: 'Fiji', destinationId: 'le_72b32a1f754ba1c09b3695e0cb6cde7f' },
  france: { displayName: 'France', destinationId: 'le_093f65e080a295f8076b1c5722a46aa2' },
  florida: { displayName: 'Florida', destinationId: 'le_eb163727917cbba1eea208541a643e74' },
  gold_coast: { displayName: 'Gold Coast', destinationId: 'le_bac732346917b0a1231354ef55faf00a' },
  greece: { displayName: 'Greece', destinationId: 'le_735b90b4568125ed6c3f678819b6e058' },
  hawaii: { displayName: 'Hawaii', destinationId: 'le_979d472a84804b9f647bc185a877a8b5' },
  india: { displayName: 'India', destinationId: 'le_f033ab37c30201f73f142449d037028d' },
  italy: { displayName: 'Italy', destinationId: 'le_93db85ed909c13838ff95ccfa94cebd9' },
  japan: { displayName: 'Japan', destinationId: 'le_7647966b7343c29048673252e490f736' },
  jordan: { displayName: 'Jordan', destinationId: 'le_8613985ec49eb8f757ae6439e879bb2a' },
  khao_lak: { displayName: 'Khao Lak', destinationId: 'le_f3ad1c493ba61ad63bb43d6947f42d95' },
  maldives: { displayName: 'Maldives', destinationId: 'le_2723d092b63885e0d7c260cc007e8b9d' },
  malaysia: { displayName: 'Malaysia', destinationId: 'le_a3c65c2974270fd093ee8a9bf8ae7d0b' },
  mauritius: { displayName: 'Mauritius', destinationId: 'le_2b44928ae11fb9384c4cf38708677c48' },
  mediterranean: { displayName: 'Mediterranean', destinationId: 'le_93db85ed909c13838ff95ccfa94cebd9' },
  melbourne: { displayName: 'Melbourne', destinationId: 'le_15fa44474c92da27cf2125373356db4c' },
  mexico: { displayName: 'Mexico', destinationId: 'le_eb160de1de89d9058fcb0b968dbbbd68' },
  morocco: { displayName: 'Morocco', destinationId: 'le_a0a080f42e6f13b3a2df133f073095dd' },
  new_zealand: { displayName: 'New Zealand', destinationId: 'le_9fc3d7152ba9336a670e36d0ed79bc43' },
  netherlands: { displayName: 'Netherlands', destinationId: 'le_d1f491a404d6854880943e5c3cd9ca25' },
  phuket: { displayName: 'Phuket', destinationId: 'le_d47bb339212ddfab0fadd99d492a5fc3' },
  queensland: { displayName: 'Queensland', destinationId: 'le_3d3621cee6f49e93a34f3f0f654ab41a' },
  samoa: { displayName: 'Samoa', destinationId: 'le_2a79ea27c279e471f4d180b08d62b00a' },
  singapore: { displayName: 'Singapore', destinationId: 'le_bd4c9ab730f5513206b999ec0d90d1fb' },
  south_africa: { displayName: 'South Africa', destinationId: 'le_9766527f2b5d3e95d4a733fcfb77bd7e' },
  south_america: { displayName: 'South America', destinationId: 'le_9047dd7f7766d8b99048e7f52f9abb2b' },
  south_korea: { displayName: 'South Korea', destinationId: 'le_f4b9ec30ad9f68f89b29639786cb62ef' },
  south_pacific: { displayName: 'South Pacific', destinationId: 'le_a8697c7e6e7988b29a9de094b8d554cc' },
  spain: { displayName: 'Spain', destinationId: 'le_7e7757b1e12abcb736ab9a754ffb617a' },
  sri_lanka: { displayName: 'Sri Lanka', destinationId: 'le_5878a7ab84fb43402106c575658472fa' },
  sydney: { displayName: 'Sydney', destinationId: 'le_678c61ac5b4f91ca86e112fc50e63766' },
  thailand: { displayName: 'Thailand', destinationId: 'le_8f85517967795eeef66c225f7883bdcb' },
  turkey: { displayName: 'Turkey', urlName: 'Türkiye (Turkey)', destinationId: 'le_cedebb6e872f539bef8c3f919874e9d7' },
  uae: { displayName: 'United Arab Emirates', destinationId: 'le_a2557a7b2e94197ff767970b67041697' },
  uk: { displayName: 'UK', urlName: 'United Kingdom', destinationId: 'le_cfecdb276f634854f3ef915e2e980c31' },
  usa: { displayName: 'USA', urlName: 'United States of America', destinationId: 'le_757b505cfd34c64c85ca5b5690ee5293' },
  vietnam: { displayName: 'Vietnam', destinationId: 'le_a597e50502f5ff68e3e25b9114205d4a' },
}

const buildUrl = (offerType: string) => {
  switch (offerType) {
    case 'hotel':
      return buildHotelUrl
    case 'tour':
      return buildTourUrl
    case 'cruise':
      return buildCruiseUrl
    default:
      return buildHotelUrl
  }
}

const buildTrendingDestination = ({ destination, imageId, offerType = 'hotel' }: { destination: string, imageId: string, offerType?: 'hotel' | 'tour' | 'cruise' }) => {
  const { displayName, urlName, destinationId } = DESTINATIONS[destination]
  const url = buildUrl(offerType)(urlName || displayName, destinationId)
  return { name: displayName, destinationId, imageId, url }
}

export const trendingHotelDestinations: { [key: string]: Array<TrendingDestination> | undefined } = {
  AU: [
    buildTrendingDestination({ destination: 'bali', imageId: 'vg4uvt2y5dgkivujuba6' }),
    buildTrendingDestination({ destination: 'queensland', imageId: '7wwkjgk1cn0yafyyek' }),
    buildTrendingDestination({ destination: 'thailand', imageId: 'lids8h5rs9zj2xvkxr' }),
    buildTrendingDestination({ destination: 'melbourne', imageId: 'u4joiwhggaqtgdfvoj9j' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: 'qnj8qzc3dff227vie4xt' }),
    buildTrendingDestination({ destination: 'fiji', imageId: 'h1t0c6c9brq83bwhazx' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'dapbr82l65bcj5iy6v8' }),
    buildTrendingDestination({ destination: 'sydney', imageId: 'u95fdaucycrnml7y5fng' }),
    buildTrendingDestination({ destination: 'europe', imageId: 'n32e56two8igfwfrzvq' }),
    buildTrendingDestination({ destination: 'australia', imageId: '6swob1q88xi45bmcm4lgm' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'uk', imageId: 'bh52x5crz46nt54uxsl' }),
  ],
  NZ: [
    buildTrendingDestination({ destination: 'fiji', imageId: '0r1k024m4f05bp20kzgn' }),
    buildTrendingDestination({ destination: 'new_zealand', imageId: '4ezeb1xkmkcuawwm0cf8' }),
    buildTrendingDestination({ destination: 'bali', imageId: '1to55kpf7i6zrbf1m8l' }),
    buildTrendingDestination({ destination: 'cook_islands', imageId: 'x382u2epn5ub73h8l3' }),
    buildTrendingDestination({ destination: 'gold_coast', imageId: 'krvovndggw4zalo1kgo8' }),
    buildTrendingDestination({ destination: 'sydney', imageId: 'u95fdaucycrnml7y5fng' }),
    buildTrendingDestination({ destination: 'melbourne', imageId: 'u4joiwhggaqtgdfvoj9j' }),
    buildTrendingDestination({ destination: 'thailand', imageId: 'ztss9zt897xooxoarh3' }),
    buildTrendingDestination({ destination: 'singapore', imageId: 'b4u5c9v9eplflpfjohz8' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'zme6trnp3refzciidsk' }),
    buildTrendingDestination({ destination: 'samoa', imageId: 'kgp44kj57lap71v2djyt' }),
    buildTrendingDestination({ destination: 'hawaii', imageId: 'q26a5y2h5aijx52y8nfm' }),
    buildTrendingDestination({ destination: 'queensland', imageId: 'trendingDestination.queensland' }),
  ],
  GB: [
    buildTrendingDestination({ destination: 'greece', imageId: 'la8fpc5ehwxlh9cyd9ne' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'e8tfsm17scdhkl291mt' }),
    buildTrendingDestination({ destination: 'bali', imageId: '1to55kpf7i6zrbf1m8l' }),
    buildTrendingDestination({ destination: 'uk', imageId: 'ro18bawi3c1q4ky0jl' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'thailand', imageId: '1y2qjvneh2zelxpg2fog' }),
    buildTrendingDestination({ destination: 'spain', imageId: '7mxbm2s01q38r2900zj' }),
    buildTrendingDestination({ destination: 'france', imageId: 'n32e56two8igfwfrzvq' }),
    buildTrendingDestination({ destination: 'italy', imageId: '105jinf6w80g48xz4m5i' }),
    buildTrendingDestination({ destination: 'mauritius', imageId: 'ztyqafpx7lywouc2093' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: '7pmj8f3m1cz7ip3920k' }),
    buildTrendingDestination({ destination: 'usa', imageId: 'trendingDestination.usa' }),
  ],
  US: [
    buildTrendingDestination({ destination: 'maldives', imageId: 'dapbr82l65bcj5iy6v8' }),
    buildTrendingDestination({ destination: 'mexico', imageId: 'm4q0xykf28g85c3uv1r' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'bali', imageId: 'vg4uvt2y5dgkivujuba6' }),
    buildTrendingDestination({ destination: 'florida', imageId: 'rasl0402sq9ft6vloi7b' }),
    buildTrendingDestination({ destination: 'australia', imageId: '1x64o7uzz17yf0lu1o' }),
    buildTrendingDestination({ destination: 'italy', imageId: 'wbk1yjnf56awezs1lu' }),
    buildTrendingDestination({ destination: 'fiji', imageId: 'h1t0c6c9brq83bwhazx' }),
    buildTrendingDestination({ destination: 'california', imageId: 'mky1rem3l8kdhe9h78b' }),
    buildTrendingDestination({ destination: 'thailand', imageId: 'lids8h5rs9zj2xvkxr' }),
    buildTrendingDestination({ destination: 'uk', imageId: 'bh52x5crz46nt54uxsl' }),
    buildTrendingDestination({ destination: 'usa', imageId: '2agstgb25xcdai5sl2y' }),
  ],
  SG: [
    buildTrendingDestination({ destination: 'bali', imageId: 'vg4uvt2y5dgkivujuba6' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'dapbr82l65bcj5iy6v8' }),
    buildTrendingDestination({ destination: 'phuket', imageId: 'zeh4dhitct7zlmwprc7' }),
    buildTrendingDestination({ destination: 'khao_lak', imageId: 'qu4sq209xav4bgjlyw5i' }),
    buildTrendingDestination({ destination: 'malaysia', imageId: 'i06o0tyhuxlsyxvnwxro' }),
    buildTrendingDestination({ destination: 'bangkok', imageId: 'i0smaysxpczk77hi55ix' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: '7pmj8f3m1cz7ip3920k' }),
    buildTrendingDestination({ destination: 'singapore', imageId: 'b4u5c9v9eplflpfjohz8' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'australia', imageId: '1x64o7uzz17yf0lu1o' }),
  ],
}

export const trendingTourDestinations: { [key: string]: Array<TrendingDestination> | undefined } = {
  AU: [
    buildTrendingDestination({ destination: 'vietnam', imageId: 'qnj8qzc3dff227vie4xt', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'egypt', imageId: 'm4rcv17j80xrikxq419', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'japan', imageId: '6tkmq2mo1izznyjyfln', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'spain', imageId: 'muntccdhicnwfzt7gffd', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'v2u8uob7mgdu7o6y6njq', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'india', imageId: 'mz1sew47lfvzk0t6h8a7', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: '6swob1q88xi45bmcm4lgm', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'italy', imageId: 'wbk1yjnf56awezs1lu', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'morocco', imageId: 'n8hs8sldgkghrq1tbxg', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'south_america', imageId: 'rgpvhefakcq8rengtq7', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'china', imageId: 'mhzaf5oykblji2972gp', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'sri_lanka', imageId: 'w9v8ovyoltpvlzhsbgwh', offerType: 'tour' }),
  ],
  NZ: [
    buildTrendingDestination({ destination: 'japan', imageId: 'trendingDestination.japan', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: 'trendingDestination.australia', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'egypt', imageId: 'antn11vqwfr0ri531r8n', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'jordan', imageId: 'cus7841371dt5w7bhg', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'spain', imageId: 's58m3u0qykrppcy2dz3', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: 'trendingDestination.vietnam', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'trendingDestination.africa', offerType: 'tour' }),
  ],
  GB: [
    buildTrendingDestination({ destination: 'vietnam', imageId: 'trendingDestination.vietnam', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'trendingDestination.africa', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: 'trendingDestination.australia', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'japan', imageId: 'trendingDestination.japan', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'india', imageId: 'dvjh0wlaml8zrfb73f4q', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'sri_lanka', imageId: 'w9v8ovyoltpvlzhsbgwh', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'egypt', imageId: 'antn11vqwfr0ri531r8n', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'jordan', imageId: 'cus7841371dt5w7bhg', offerType: 'tour' }),
  ],
  US: [
    buildTrendingDestination({ destination: 'egypt', imageId: 'm4rcv17j80xrikxq419', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'japan', imageId: '6tkmq2mo1izznyjyfln', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'v2u8uob7mgdu7o6y6njq', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'india', imageId: 'dvjh0wlaml8zrfb73f4q', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: '1x64o7uzz17yf0lu1o', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: 'qnj8qzc3dff227vie4xt', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'morocco', imageId: 'n8hs8sldgkghrq1tbxg', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'new_zealand', imageId: '4hetryqp8ab10q756sne', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'china', imageId: 'mhzaf5oykblji2972gp', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'sri_lanka', imageId: 'w9v8ovyoltpvlzhsbgwh', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'south_america', imageId: 'rgpvhefakcq8rengtq7', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'turkey', imageId: 'ora3bukxq6ow99lpe3', offerType: 'tour' }),
  ],

}

export const trendingCruiseDestinations: { [key: string]: Array<TrendingDestination> | undefined } = {
  AU: [
    buildTrendingDestination({ destination: 'sydney', imageId: 'u95fdaucycrnml7y5fng', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'brisbane', imageId: 'm8ed3sf51fnes1qvgdm', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'melbourne', imageId: 'u4joiwhggaqtgdfvoj9j', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'mediterranean', imageId: 'wzm1voynao2atve74bkg', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'south_pacific', imageId: 'in1vh7z31ho0u045bak', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'new_zealand', imageId: '4ezeb1xkmkcuawwm0cf8', offerType: 'cruise' }),
  ],
}

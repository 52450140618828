import {
  FlightsFareTypes,
  FlightSortOptions,
  FlightViewTypes,
  getAdultPassengerState,
  getChildPassengerState,
  getInfantPassengerState,
  getPrimaryPassengerState,
  maxFlightInfantAge,
  maxFlightPassengerCount,
  validFlightDateRangeInMonths,
  FLEXIBILITY_INCLUSIONS,
} from 'constants/flight'
import { createSelector } from 'reselect'
import moment from 'moment'
import qs from 'qs'
import { groupBy, last, min, nonNullable, sortByAll, SortField, sum, arrayToObject, unique, max, without } from 'lib/array/arrayUtils'
import switchFunc from 'lib/function/switchFunc'
import { FlightDropdownOption, PriceChanges } from 'components/Flights/types'
import { capitalise, contains } from 'lib/string/stringUtils'
import { ISO_DATE_FORMAT, SHORT_DATE_TIME_FORMAT, SHORT_TIME_FORMAT_24_HOUR } from 'constants/dateFormats'
import { formatShortDateRange } from 'tripPlanner/utils'
import { FlightSearchParams } from 'hooks/useSearchFlights'
import getObjectKey from 'lib/object/getObjectKey'
import { FlightFilters } from 'checkout/Components/InFlowFlightSearch/CheckoutFlightsFilterDrawer'
import { FlightPriceFilterRecommendedType } from 'contexts/Flights/StandaloneFlights/standaloneFlightsStateReducer'
import { BAG_TYPE } from './flightFilterUtils'
import { FlightSearchWidgetFlightItem } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import { isBookingProtectionItem, isFlightItem } from 'lib/checkout/checkoutUtils'

// fareClassEconomy is an economy fare class
export const fareClassEconomy = 'Economy'
// fareClassBusiness is a business fare class
export const fareClassBusiness = 'Business'

// compareFareClasses is a comparison function to compare two fare classes using a total order
// implemented by the fare class.
function compareFareClasses(a: string, b: string) {
  if (a === b) {
    return 0
  }

  if (a === fareClassEconomy && b !== fareClassEconomy) {
    return -1
  }

  return 1
}

export function getDisplayInfoV2(flights: Array<App.FlightV2>) {
  let bestFareClass = fareClassEconomy
  const fareBookingClasses: Record<string, boolean> = {}
  const flightsWithFreeCheckInBaggage: Record<string, boolean> = {}
  let allFlightsHaveTheSameBaggage = true

  const baggageDetails = {
    freeCarryOnBaggageDescription: flights[0].includedCarryOnBaggage?.description,
    freeCheckedInBaggageDescription: flights[0].includedCheckedBaggage?.description,
    freeCheckedInBaggageIncluded: !!flights[0].includedCheckedBaggage,
  }

  for (let i = 0; i < flights.length; i++) {
    const flight = flights[i]

    fareBookingClasses[flight.bookingClass] = true

    if (flight.includedCheckedBaggage) {
      flightsWithFreeCheckInBaggage[i] = true
    }

    if (allFlightsHaveTheSameBaggage && i > 0) {
      if (baggageDetails.freeCarryOnBaggageDescription !== flight.includedCarryOnBaggage?.description ||
        baggageDetails.freeCheckedInBaggageDescription !== flight.includedCheckedBaggage?.description ||
        baggageDetails.freeCheckedInBaggageIncluded !== !!flight.includedCheckedBaggage
      ) {
        allFlightsHaveTheSameBaggage = false
      }
    }

    if (compareFareClasses(flight.fareClass, bestFareClass) > 0) {
      bestFareClass = flight.fareClass
    }
  }

  return {
    hasMultipleFareBookingClasses: Object.keys(fareBookingClasses).length > 1,
    bestFareClass,
    baggageIncluded: Object.keys(flightsWithFreeCheckInBaggage).length === flights.length,
    allFlightsHaveTheSameBaggage,
  }
}

function applyJourneyFilters<T extends App.AnyJourney>(journeys: Array<T>, filters: Array<App.FlightFilter>) {
  return journeys.map(journey => {
    const isHiddenByFilters = !filters.every(filter => {
      const activeOptions = filter.options.filter(option => option.active)
      const isAllOptionActive = activeOptions.find(ao => ao.value === 'all')
      if (isAllOptionActive) {
        return true
      }
      return activeOptions.some(af => filter.fn(journey, af.value))
    })
    return ({
      ...journey,
      hidden: isHiddenByFilters,
    })
  })
}

export const getJourneys = {
  departing: createSelector(
    (state: App.State) => state.flights.journeys,
    (state: App.State) => state.flights.flightSearchV2?.onewayFares,
    (journeys, oneWayFares): Array<App.AnyJourney> => oneWayFares?.[0] ?? journeys,
  ),
  returning: createSelector(
    (state: App.State) => state.flights.journeys,
    (state: App.State) => state.flights.flightSearchV2?.onewayFares,
    (journeys, oneWayFares): Array<App.AnyJourney> => oneWayFares?.[1] ?? journeys,
  ),
}

const getFilteredDepartingJourneys = createSelector(
  (state: App.State) => getJourneys.departing(state),
  (state: App.State) => state.flights.filters,
  (journeys, filters) => applyJourneyFilters(journeys, filters),
)

const flightPriceSort: SortField<App.Journey> = { selector: j => j.cost, direction: 'asc' }

const departingFlightStopoverSort: SortField<App.Journey> = { selector: j => j.departing.flights.length, direction: 'asc' }
const departingFlightTimeSort: SortField<App.Journey> = { selector: j => j.departing.flightTime ? convertTimeObjectToMinutes(j.departing.flightTime) : undefined, direction: 'asc' }
const departingDepartureSort: SortField<App.Journey> = { selector: j => +new Date(`${j.departing.flights[0].departingDate}T${j.departing.flights[0].departingTime}`), direction: 'asc' }
const departingDepartureDescSort: SortField<App.Journey> = { selector: j => +new Date(`${j.departing.flights[0].departingDate}T${j.departing.flights[0].departingTime}`), direction: 'desc' }
const departingArrivalSort: SortField<App.Journey> = { selector: j => +new Date(`${last(j.departing.flights).arrivalDate}T${last(j.departing.flights).arrivalTime}`), direction: 'asc' }

const returningFlightStopoverSort: SortField<App.Journey> = { selector: j => j.returning?.flights.length, direction: 'asc' }
const returningFlightTimeSort: SortField<App.Journey> = { selector: j => j.returning?.flightTime ? convertTimeObjectToMinutes(j.returning.flightTime) : undefined, direction: 'asc' }
const returningDepartureSort: SortField<App.Journey> = { selector: j => j.returning ? +new Date(`${j.returning.flights[0].departingDate}T${j.returning.flights[0].departingTime}`) : undefined, direction: 'asc' }
const returningDepartureDescSort: SortField<App.Journey> = { selector: j => j.returning ? +new Date(`${j.returning.flights[0].departingDate}T${j.returning.flights[0].departingTime}`) : undefined, direction: 'desc' }
const returningArrivalSort: SortField<App.Journey> = { selector: j => j.returning ? +new Date(`${last(j.returning.flights).arrivalDate}T${last(j.returning.flights).arrivalTime}`) : undefined, direction: 'asc' }

export const getDepartingSortList = switchFunc<Array<SortField<App.Journey>>>({
  [FlightSortOptions.Best]: [departingFlightStopoverSort, flightPriceSort, departingFlightTimeSort, departingDepartureSort],
  [FlightSortOptions.Cheapest]: [flightPriceSort, departingFlightStopoverSort, departingFlightTimeSort, departingDepartureSort],
  [FlightSortOptions.Fastest]: [departingFlightTimeSort, flightPriceSort, departingDepartureSort, departingFlightTimeSort],
  [FlightSortOptions.EarliestTakeOff]: [departingDepartureSort, flightPriceSort, departingFlightStopoverSort, departingFlightTimeSort],
  [FlightSortOptions.LatestTakeOff]: [departingDepartureDescSort, flightPriceSort, departingFlightStopoverSort, departingFlightTimeSort],
  [FlightSortOptions.EarliestLanding]: [departingArrivalSort, flightPriceSort, departingFlightStopoverSort, departingFlightTimeSort],
}, [flightPriceSort, departingFlightStopoverSort, departingFlightTimeSort, departingDepartureSort, departingDepartureDescSort, departingArrivalSort])

const flightPriceSortV2: SortField<App.JourneyV2> = {
  selector: j => j.fareType === FlightViewTypes.RETURN ? j.price.all.totalRoundTripPrice : j.price.all.totalFare,
  direction: 'asc',
}

const departingFlightStopoverSortV2: SortField<App.JourneyV2> = { selector: j => j.flightGroup.flights.length, direction: 'asc' }
const departingFlightTimeSortV2: SortField<App.JourneyV2> = { selector: j => convertTimeObjectToMinutes(j.flightGroup.totalFlightDuration), direction: 'asc' }
const departingDepartureSortV2: SortField<App.JourneyV2> = { selector: j => +new Date(`${j.flightGroup.flights[0].departingDate}T${j.flightGroup.flights[0].departingTime}`), direction: 'asc' }
const departingDepartureDescSortV2: SortField<App.JourneyV2> = { selector: j => +new Date(`${j.flightGroup.flights[0].departingDate}T${j.flightGroup.flights[0].departingTime}`), direction: 'desc' }
const departingArrivalSortV2: SortField<App.JourneyV2> = { selector: j => +new Date(`${last(j.flightGroup.flights).arrivalDate}T${last(j.flightGroup.flights).arrivalTime}`), direction: 'asc' }

const returningFlightStopoverSortV2: SortField<App.JourneyV2> = { selector: j => j.flightGroup.flights.length, direction: 'asc' }
const returningFlightTimeSortV2: SortField<App.JourneyV2> = { selector: j => convertTimeObjectToMinutes(j.flightGroup.totalFlightDuration), direction: 'asc' }
const returningDepartureSortV2: SortField<App.JourneyV2> = { selector: j => +new Date(`${j.flightGroup.flights[0].departingDate}T${j.flightGroup.flights[0].departingTime}`), direction: 'asc' }
const returningDepartureDescSortV2: SortField<App.JourneyV2> = { selector: j => +new Date(`${j.flightGroup.flights[0].departingDate}T${j.flightGroup.flights[0].departingTime}`), direction: 'desc' }
const returningArrivalSortV2: SortField<App.JourneyV2> = { selector: j => +new Date(`${last(j.flightGroup.flights).arrivalDate}T${last(j.flightGroup.flights).arrivalTime}`), direction: 'asc' }

export const getDepartingSortListV2 = switchFunc<Array<SortField<App.JourneyV2>>>({
  [FlightSortOptions.Best]: [departingFlightStopoverSortV2, flightPriceSortV2, departingFlightTimeSortV2, departingDepartureSortV2],
  [FlightSortOptions.Cheapest]: [flightPriceSortV2, departingFlightStopoverSortV2, departingFlightTimeSortV2, departingDepartureSortV2],
  [FlightSortOptions.Fastest]: [departingFlightTimeSortV2, flightPriceSortV2, departingDepartureSortV2, departingFlightTimeSortV2],
  [FlightSortOptions.EarliestTakeOff]: [departingDepartureSortV2, flightPriceSortV2, departingFlightStopoverSortV2, departingFlightTimeSortV2],
  [FlightSortOptions.LatestTakeOff]: [departingDepartureDescSortV2, flightPriceSortV2, departingFlightStopoverSortV2, departingFlightTimeSortV2],
  [FlightSortOptions.EarliestLanding]: [departingArrivalSortV2, flightPriceSortV2, departingFlightStopoverSortV2, departingFlightTimeSortV2],
}, [flightPriceSortV2, departingFlightStopoverSortV2, departingFlightTimeSortV2, departingDepartureSortV2, departingDepartureDescSortV2, departingArrivalSortV2])

export const getReturningSortListV2 = switchFunc<Array<SortField<App.JourneyV2>>>({
  [FlightSortOptions.Best]: [returningFlightStopoverSortV2, flightPriceSortV2, returningFlightTimeSortV2, returningDepartureSortV2],
  [FlightSortOptions.Cheapest]: [flightPriceSortV2, returningFlightStopoverSortV2, returningFlightTimeSortV2, returningDepartureSortV2],
  [FlightSortOptions.Fastest]: [returningFlightTimeSortV2, flightPriceSortV2, returningDepartureSortV2, returningFlightTimeSortV2],
  [FlightSortOptions.EarliestTakeOff]: [returningDepartureSortV2, flightPriceSortV2, returningFlightStopoverSortV2, returningFlightTimeSortV2],
  [FlightSortOptions.LatestTakeOff]: [returningDepartureDescSortV2, flightPriceSortV2, returningFlightStopoverSortV2, returningFlightTimeSortV2],
  [FlightSortOptions.EarliestLanding]: [returningArrivalSortV2, flightPriceSortV2, returningFlightStopoverSortV2, returningFlightTimeSortV2],
}, [flightPriceSortV2, returningFlightStopoverSortV2, returningFlightTimeSortV2, returningDepartureSortV2, returningDepartureDescSortV2, returningArrivalSortV2])

export function getJourneysSortList(option: FlightSortOptions, segment: 'departing' | 'returning', isV2?: boolean) {
  if (segment === 'departing') {
    return isV2 ? getDepartingSortListV2(option) : getDepartingSortList(option)
  } else {
    return isV2 ? getReturningSortListV2(option) : getReturningSortList(option)
  }
}

export function getUniqueDepartingFlights(journeys: Array<App.Journey>) {
  const grouped = groupBy(journeys, j => j.departing.journeyKey)
  return Array.from(grouped.values()).map(group => {
    const visibleJourneys = group.filter(j => !j.hidden)
    return min(visibleJourneys.length === 0 ? group : visibleJourneys, val => val.cost)
  })
}

// getDepartingJourneys returns an array of journeys with unique departing journey key.
// Concretely, it de-duplicates journeys by its 'departing' object's 'journeyKey'. Any tie is
// broken by choosing the object from the journey with a lower cost.
export const getDepartingJourneys = createSelector(
  (state: App.State) => getFilteredDepartingJourneys(state),
  (state: App.State) => state.flights.sort,
  (journeys: Array<App.AnyJourney>, sortValue) => {
    if (isJourneyV2Items(journeys)) {
      const visibleJourneys = journeys.filter((journey) => !journey.hidden)
      return sortByAll(nonNullable(visibleJourneys), getDepartingSortListV2(sortValue))
    }
    const uniqueJourneys = getUniqueDepartingFlights(journeys as Array<App.Journey>)

    return sortByAll(nonNullable(uniqueJourneys), getDepartingSortList(sortValue))
  },
)

export const getReturningSortList = switchFunc<Array<SortField<App.Journey>>>({
  [FlightSortOptions.Best]: [returningFlightStopoverSort, flightPriceSort, returningFlightTimeSort, returningDepartureSort],
  [FlightSortOptions.Cheapest]: [flightPriceSort, returningFlightStopoverSort, returningFlightTimeSort, returningDepartureSort],
  [FlightSortOptions.Fastest]: [returningFlightTimeSort, flightPriceSort, returningDepartureSort, returningFlightTimeSort],
  [FlightSortOptions.EarliestTakeOff]: [returningDepartureSort, flightPriceSort, returningFlightStopoverSort, returningFlightTimeSort],
  [FlightSortOptions.LatestTakeOff]: [returningDepartureDescSort, flightPriceSort, returningFlightStopoverSort, returningFlightTimeSort],
  [FlightSortOptions.EarliestLanding]: [returningArrivalSort, flightPriceSort, returningFlightStopoverSort, returningFlightTimeSort],
}, [flightPriceSort, returningFlightStopoverSort, returningFlightTimeSort, returningDepartureSort, returningDepartureDescSort, returningArrivalSort])

export function tripType(journey: App.Journey) {
  return journey.departing.flights[0].departingDisplayNames.departingCountry === journey.returning?.flights[0].departingDisplayNames.departingCountry ?
    'domestic' : 'international'
}

const createPrimary = (): App.Passenger => {
  const passenger = getPrimaryPassengerState()
  return {
    id: 'adult-0',
    type: 'adult',
    ...passenger,
  }
}

const createAdult = (index: number): App.Passenger => {
  const passenger = getAdultPassengerState()
  return {
    id: `adult-${index}`,
    type: 'adult',
    ...passenger,
  }
}

const createChild = (index: number): App.Passenger => {
  const passenger = getChildPassengerState()
  return {
    id: `child-${index}`,
    type: 'child',
    ...passenger,
  }
}

const createInfant = (index: number): App.Passenger => {
  const passenger = getInfantPassengerState()
  return {
    id: `infant-${index}`,
    type: 'infant',
    ...passenger,
  }
}

function createPassengerArray(passengerCreator: (num: number) => App.Passenger, length: number, baseIndex: number): Array<App.Passenger> {
  const arr: Array<App.Passenger> = []
  for (let i = 0; i < length; i++) {
    arr.push(passengerCreator(baseIndex + i))
  }

  return arr
}

/**
 * Build list of pax for the new checkout cart item state
 *
 * @remarks
 * Main difference from "createPassengers()" is that the `id` suffix starts at 1 instead of 0
 *   i.e. 'adult-1'
 */
export function newCheckoutCreatePassengers({ adults, children = 0, infants = 0 }: App.Occupants) {
  const adultPaxList: Array<App.Passenger> = []
  const childPaxList: Array<App.Passenger> = []
  const infantList: Array<App.Passenger> = []

  for (let i = 0; i < adults; i++) { adultPaxList.push(createAdult(i + 1)) }
  for (let i = 0; i < children; i++) { childPaxList.push(createChild(i + 1)) }
  for (let i = 0; i < infants; i++) { infantList.push(createInfant(i + 1)) }

  return [...adultPaxList, ...childPaxList, ...infantList]
}

export function createPassengers({ adults, children, infants }: App.Occupants) {
  const primary = createPrimary()
  const mappedAdults = createPassengerArray(createAdult, adults - 1, 1)
  const mappedChildren = createPassengerArray(createChild, (children ?? 0), adults)
  const mappedInfants = createPassengerArray(createInfant, (infants ?? 0), adults + (children ?? 0))

  return [
    primary,
    ...mappedAdults,
    ...mappedChildren,
    ...mappedInfants,
  ]
}

function restorePassengerWithBaggage(storedPassenger: App.Passenger, index: number) {
  if (storedPassenger.isPrimary) {
    return createPrimary()
  } else if (storedPassenger.type === 'adult') {
    return createAdult(index)
  } else if (storedPassenger.type === 'child') {
    return createChild(index)
  } else {
    return createInfant(index)
  }
}

export function restorePassengersWithBaggage(storedPassengers: Array<App.Passenger>) {
  if (!storedPassengers.some(p => p.isPrimary)) {
    return undefined // Something's screwed up, we're missing a primary passenger
  }

  return storedPassengers.map((passenger, index) => ({
    ...restorePassengerWithBaggage(passenger, index),
    ...passenger,
  }))
}

export function parseContactNumber(number: App.PhoneNumber) {
  // attempt to remove any code from the phone input just in case the user entered
  // the code into the phone input as well as selecting a code
  return `${number.prefix}${number.phone.replace(new RegExp(`^([+]?${number.prefix}|0+)`), '')}`
}

function getPassengerAddedBaggage(passengers: Array<App.Passenger>, journeyId: string): Array<Array<App.AddedBaggage>> {
  return nonNullable(passengers.map(p => {
    if (p?.addedExtras?.baggage?.[journeyId]) {
      return p.addedExtras.baggage[journeyId]
    }
    return []
  }))
}

export function getBaggageCount(passengers: Array<App.Passenger>, journeySegment: App.JourneyFlight) {
  const journeyId = journeySegment.journeyKey
  const passengerAddedBaggage = getPassengerAddedBaggage(passengers, journeyId)
  return sum(passengerAddedBaggage, passengerBaggage => sum(passengerBaggage, o => o.count))
}

export function getBaggageCost(passengers: Array<App.Passenger>, journeySegment: App.JourneyFlight) {
  const journeyId = journeySegment.journeyKey
  const passengerAddedBaggage = getPassengerAddedBaggage(passengers, journeyId)
  return sum(passengerAddedBaggage, (passengerBaggage) => (
    sum(passengerBaggage, selectedOption => {
      const option = journeySegment.extras?.baggage?.find(availableOption => availableOption.id === selectedOption.id)
      return option ? selectedOption.count * option.amount : 0
    })
  ))
}

export function resetFilter(filter: App.FlightFilter) {
  return {
    ...filter,
    options: filter.options.map(o => ({
      ...o,
      active: !!o.isDefault,
    })),
  }
}

export function getFlightCalendarKey(props: { startDate: string; endDate: string; origin: string; destination: string; nights?: number; region: string; currency: string; }) {
  return `${props.startDate}-${props.endDate}-${props.origin}-${props.destination}-${props.nights}-${props.region}-${props.currency}`
}

export function updateFilter(filter: App.FlightFilter, newOption: App.FlightFilterOption, selectedOptionOnly: boolean) {
  return {
    ...filter,
    options: filter.options.map(o => {
      // If "select only" option select => only that should be select
      // If "all" select => de-select all other options
      // If any other option select => de-select "all" option
      // all option & other option can't select at one time

      if (selectedOptionOnly) {
        return {
          ...o,
          active: o === newOption,
        }
      }

      if (newOption.value === 'all') {
        return {
          ...o,
          active: o === newOption ? !o.active : false,
        }
      }

      if (o.value === 'all') {
        return {
          ...o,
          active: false,
        }
      } else if (o === newOption) {
        return {
          ...o,
          active: !o.active,
        }
      }

      return o
    }),
  }
}

export function getSortingLabel(selectedOption?: FlightSortOptions) {
  switch (selectedOption) {
    case FlightSortOptions.EarliestTakeOff:
      return {
        sortingTitle: 'Take-off:',
        sortingLabel: 'Earliest',
      }
    case FlightSortOptions.LatestTakeOff:
      return {
        sortingTitle: 'Take-off:',
        sortingLabel: 'Latest',
      }
    case FlightSortOptions.EarliestLanding:
      return {
        sortingTitle: 'Landing:',
        sortingLabel: 'Earliest',
      }
    default:
      return {
        sortingLabel: 'Other',
      }
  }
}

export function getOtherSortOptions(originCode: string, destinationCode: string): Array<FlightDropdownOption<FlightSortOptions>> {
  const takeOffAirport = originCode
  const landingAirport = destinationCode

  return [{
    value: FlightSortOptions.EarliestTakeOff,
    label: `Earliest take-off (${takeOffAirport})`,
  }, {
    value: FlightSortOptions.LatestTakeOff,
    label: `Latest take-off (${takeOffAirport})`,
  }, {
    value: FlightSortOptions.EarliestLanding,
    label: `Earliest landing (${landingAirport})`,
  }]
}

export function filterAirlinesByCarrier(filters: Array<App.FlightFilter>, carrier: String): App.FlightFilter | undefined {
  const airlineFilter = filters.find(filter => filter.id === 'airline')

  if (airlineFilter) {
    return {
      ...airlineFilter,
      options: airlineFilter.options.map(option => ({
        ...option,
        active: option.value === carrier,
      })),
    }
  }

  return undefined
}

export function calcPriceChanges(departingPriceSnapshot: number, journeyCost: number) {
  const priceChanges: PriceChanges = {
    wentUp: false,
    stayedTheSame: true,
    amount: 0,
  }

  if (journeyCost > departingPriceSnapshot) {
    priceChanges.wentUp = true
    priceChanges.stayedTheSame = false
    priceChanges.amount = (journeyCost - departingPriceSnapshot)
  }

  if (journeyCost < departingPriceSnapshot) {
    priceChanges.wentDown = true
    priceChanges.stayedTheSame = false
    priceChanges.amount = (departingPriceSnapshot - journeyCost)
  }

  if (journeyCost === departingPriceSnapshot) {
    priceChanges.wentUp = false
    priceChanges.wentDown = false
    priceChanges.stayedTheSame = true
    priceChanges.amount = 0
  }

  return priceChanges
}

export function calcPriceDiff(departingPriceSnapshot: number, journeyCost: number): PriceChanges {
  return {
    amount: journeyCost - departingPriceSnapshot,
  }
}

export function getFareClass(flightClass: string) {
  if (contains(flightClass, 'biz') || contains(flightClass, 'business')) {
    return 'Business'
  } else if (contains(flightClass, 'first')) {
    return 'First'
  } else if (contains(flightClass, 'premium')) {
    return 'Premium'
  }
  return 'Economy'
}

export function getAirportDisplayText(airport?: App.AirportLocation) {
  if (!airport) {
    return undefined
  }

  const {
    cityCode,
    isMultiAirport,
    isChildDisplay,
    airportName,
    airportCode,
    cityAirportName,
  } = airport

  const isCityAirport = isMultiAirport && isChildDisplay
  const displayName = isCityAirport ? cityAirportName : airportName
  const displayCode = isCityAirport ? cityCode : airportCode

  return `${displayName} (${displayCode})`
}

export function validateFlightOccupants(occupants: App.Occupants) {
  const totalPassengers = occupants.adults + (occupants?.children ?? 0)
  const totalInfants = occupants?.childrenAge?.filter((age) => age !== -1 && age <= maxFlightInfantAge).length ?? 0
  return totalPassengers > maxFlightPassengerCount || totalInfants > occupants.adults
}

export function mapAirportToAirportLocation(airport: App.Airport): App.AirportLocation {
  return {
    airportCode: airport.code,
    airportName: airport.name,
  }
}

export function isOneWayFlight(fareType: string): boolean {
  return !!fareType && fareType === FlightsFareTypes.ONE_WAY
}

export interface StandaloneFlightSearchParam {
  originAirportCode: string;
  originAirportName: string;
  destinationAirportCode: string;
  destinationAirportName: string;
  departDate: string;
  returnDate: string | null;
  adults: number;
  children: number;
  infants: number;
  /** Comma-separated list of ages */
  childrenAge?: string;
  fareClass: string;
  fareType: 'return' | 'oneWay' | 'multiCity';
}

export function buildStandaloneFlightSearchUrl(params: StandaloneFlightSearchParam) {
  return `/flights-search-results?${qs.stringify(params)}`
}

export function getFlightDealDuration(travelPeriods: Array<App.FlightDealTravelPeriod>) {
  const earliestDate = min(travelPeriods, period => +new Date(period.departureDate))?.departureDate
  const latestDate = max(travelPeriods, period => +new Date(period.arrivalDate))?.arrivalDate

  return formatShortDateRange({ startDate: moment(earliestDate), endDate: moment(latestDate) })
}

export function getOperatingCarrierText(flights: Array<App.Flight>) {
  const differentOperatingFlights = flights.filter(flight => flight.carrier !== flight.operatingCarrier)

  if (differentOperatingFlights.length !== 0) {
    return differentOperatingFlights[0].operatedByText
  }
  return null
}

export function filterAirportRecords(records: Array<App.AirportLocation>, excludingAirport?: App.AirportLocation) {
  if (records) {
    const excludingRecord = records.find(record => record.airportCode === excludingAirport?.airportCode)
    const airportsWithoutSelections = without(records, excludingRecord)
    const groupedAirportRecords = groupBy(airportsWithoutSelections, record => record?.cityCode)

    return nonNullable(Array.from(groupedAirportRecords.entries()).map(groupedAirports =>
      groupedAirports[1].map((airport, index) => {
        if (!airport) return
        return {
          ...airport,
          isCityAirport: index !== 0,
        }
      }),
    ).flat())
  }
  return []
}

export function isJourneyV2Items(items: Array<App.AnyJourney>): items is Array<App.JourneyV2> {
  return isJourneyV2(items?.[0])
}

export function isJourneyV1Items(items: Array<App.AnyJourney>): items is Array<App.Journey> {
  return isJourneyV1(items?.[0])
}

export function isJourneyV2(item?: App.AnyJourney): item is App.JourneyV2 {
  return item?.itemType === JOURNEY_TYPE_V2
}

export function isJourneyV1(item?: App.AnyJourney): item is App.Journey {
  return item?.itemType === JOURNEY_TYPE_V1
}

export function isJourneyV2ReturnFares(item?: App.AnyJourney) {
  return isJourneyV2(item) && item.fareType === FlightViewTypes.RETURN
}

export function isJourneyV2ReturnFaresItems(items: Array<App.AnyJourney>) {
  return items[0]?.fareType === FlightViewTypes.RETURN
}

export function getBaggageCostV2(passengers: Array<App.Passenger>, journey: App.JourneyV2) {
  const journeyId = journey.bookingInfo.journeyKey
  const passengerAddedBaggage = getPassengerAddedBaggage(passengers, journeyId)
  const flight = journey.flightGroup.flights[0]

  return sum(passengerAddedBaggage, (passengerBaggage) => (
    sum(passengerBaggage, selectedOption => {
      const option = flight.checkedBaggageOptions.find(availableOption => availableOption.id === selectedOption.id)
      return option ? selectedOption.count * option.amount : 0
    })
  ))
}

export function getBaggageCountV2(passengers: Array<App.Passenger>, journeyV2: App.JourneyV2) {
  const passengerAddedBaggage = getPassengerAddedBaggage(passengers, journeyV2.bookingInfo.journeyKey)
  return sum(passengerAddedBaggage, passengerBaggage => sum(passengerBaggage, o => o.count))
}

export const JOURNEY_TYPE_V1 = 'journeyV1'
export const JOURNEY_TYPE_V2 = 'journeyV2'

export function getAirlineLogoAndCarriers(carrierNames: string, carrierLogos: string) {
  const uniqueCarrierLogos = unique(carrierLogos.split('|'))
  const uniqueCarrierNames = unique(carrierNames.split('|'))

  return [uniqueCarrierLogos, uniqueCarrierNames]
}

export function getMainAirlineLogo(carrierLogos?: string) {
  return carrierLogos?.split('|')?.[0]
}

export function compareAirportCodes(originAirport: string, destinationAirport: string) {
  const regex = new RegExp('ALL$')
  return originAirport.replace(regex, '') === destinationAirport.replace(regex, '')
}

export function filterSameDayFares(outboundFare: App.JourneyV2, inboundFares: Array<App.JourneyV2>) {
  const lastOutboundFlight = last(outboundFare.flightGroup.flights)
  // 5 Hours is for ticketing time and change overtime after testing it would become 3
  const arrivalTime = moment(lastOutboundFlight.arrivalTime, SHORT_TIME_FORMAT_24_HOUR).add(5, 'hours')

  return inboundFares.filter((fare) => {
    const firstInboundFlight = fare.flightGroup.flights[0]
    const departingTime = moment(firstInboundFlight.departingTime, SHORT_TIME_FORMAT_24_HOUR)
    return departingTime.isAfter(arrivalTime)
  })
}

export function calcTotalDisplayPrice(isDeparting: boolean, journeyPrice: number, departingFarePrice?: number) {
  if (isDeparting) return null
  return journeyPrice + (departingFarePrice ?? 0)
}

export function calcBundledTotalDisplayPrice(journeyPrice: number, departingFarePrice?: number) {
  return journeyPrice + (departingFarePrice ?? 0)
}

export function getJourneyV2IdKey(
  journeyId: string,
  searchId: string,
  fareFamilyId?: string,
) {
  return getObjectKey({
    journeyId,
    searchId,
    fareFamilyId,
  })
}

export interface FlightPriceKey {
  numberOfAdults: number;
  numberOfChildren: number;
  numberOfInfants: number;
  startDate: string;
  endDate: string;
  nights?: number;
  origin?: string;
  destination: string;
}

export function getFlightPriceKey(props: FlightPriceKey) {
  return `${props.numberOfAdults}-${props.numberOfChildren}-${props.numberOfInfants}-${props.startDate}-${props.endDate}-${props.nights}-${props.origin}-${props.destination}`
}

interface FlightKeyParams extends FlightSearchParams {
  currency: string;
  key?: string | number
}

export function getFlightSearchKey(params: FlightKeyParams) {
  const flightsKeys = params.itinerary.map(it => [it.departureDate, it.departureAirport, it.arrivalAirport].join('-'))

  return [
    ...flightsKeys,
    params.currency,
    serialiseFlightsOccupancy(params.occupancies),
    params.fareClass,
    params.maxArrivalTime,
    params.minReturningDepartureTime,
    params.carriers?.join(','),
    params.fareType,
    params.viewType,
    params.key,
    params.filterAirlines?.join(','),
  ].join(':')
}

export function convertTimeObjectToMinutes(timeObject?: { hours: number, minutes: number }) {
  if (!timeObject) {
    return 0
  }
  return timeObject.hours * 60 + timeObject.minutes
}

export function convertTimeStringToMinutes(timeString: string) {
  const [hours, minutes] = timeString.split(':')

  return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
}

export function isFlightDatesBetweenValidRange(startDate: string, endDate: string) {
  const now = moment()
  const duration = moment.duration(moment(startDate).diff(now)).asMonths()
  return (duration < validFlightDateRangeInMonths) && (!moment(startDate).isSame(endDate))
}

export function convertMinutesToHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return { hours, minutes }
}

export function convertTimeV2StringToMinutes(dateTimeString: string) {
  const timeString = last(dateTimeString.split('-'))
  const [hours, minutes] = timeString.split(':')

  return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
}

export function convertMinutesToTimeString(minutes: number) {
  const time = convertMinutesToHoursAndMinutes(minutes)
  const hoursStr = time.hours.toString()
  const minutesStr = time.minutes.toString()
  return `${hoursStr.padStart(2, '0')}:${minutesStr.padStart(2, '0')}`
}

export function getBaggageDescription(
  baggage: Array<Array<App.AddedBaggage>> = [],
  options: Array<App.FlightV2BaggageOption> = [],
): string | undefined {
  const allBags = baggage.flatMap(bags => bags).filter(bag => bag.count !== 0)

  if (allBags.length === 0) {
    return undefined
  }
  const dictCounts = groupBy(allBags, bag => bag.id)
  // everything using this data assumes its an object at the moment (not a map), put it back to an object
  const baggageIdWithCount = arrayToObject(dictCounts.entries(), ([key]) => key, ([_, bags]) => sum(bags, bag => bag.count))
  const optionsById = arrayToObject(options, option => option.id)
  const baggageDescriptions = Object.entries(baggageIdWithCount).map(([id, count]) => `${count}x ${optionsById[id]?.description}`)
  return baggageDescriptions.join(', ')
}

export function getAddedBaggage(flightDetails?: App.FlightOrderItemDetails): App.Checkout.PaxBaggageMap | undefined {
  return flightDetails?.travellers.reduce<App.Checkout.PaxBaggageMap>((acc, traveller, index) => {
    const travellerId = `${traveller.type}_${index}`
    acc[`${travellerId}`] = {}
    traveller.baggage?.forEach((baggageItem) => {
      if (baggageItem.departing_count > 0) {
        acc[travellerId][`${baggageItem.baggage_type}`] = baggageItem.departing_count
      } else if (baggageItem.returning_count > 0) {
        acc[travellerId][`${baggageItem.baggage_type}`] = baggageItem.returning_count
      }
    })
    return acc
  }, {})
}

export function getAddedBaggageFromAddons(addons: Array<App.LegAddon>) {
  return addons
    .filter(addon => addon.type === 'checked_baggage')
    .reduce<Record<string, number>>((acc, addon) => {
      acc[addon.description] = (acc[addon.description] || 0) + addon.quantity
      return acc
    }, {})
}

export function getLegInclusions(inclusions: Array<App.LegInclusion>) {
  return inclusions
    .filter(inclusion => inclusion.status === 'INCLUDED')
}

const defaultTime = {
  hours: 0,
  minutes: 0,
}

export function getFlightV2BestPriceAndTime(journeys:Array<App.JourneyV2>, sortOption: FlightSortOptions): { time: { hours: number, minutes: number }, price:number } {
  const sortedFlight = sortByAll(journeys, getDepartingSortListV2(sortOption))[0]

  return {
    price: sortedFlight?.price.all.totalFare || 0,
    time: sortedFlight?.flightGroup.totalFlightDuration || defaultTime,
  }
}

export function getNextFlightFiltersFromEvent(e: React.ChangeEvent<HTMLInputElement>, filters: FlightFilters) {
  let nextFilter: Record<string, Array<string> | string | undefined> = { ...filters }

  const name = e.target.name.replace('::number', '') as keyof FlightFilters
  const type = e.target.type

  if (e.target.value === 'all') {
    nextFilter[name] = [] as Array<string>
    nextFilter = updateDirectFlightsFilterValues(nextFilter, name)
    return nextFilter
  }

  const existingFilters = filters[name] || []

  if (type === 'checkbox') {
    if (e.target.checked) {
      nextFilter[name] = [...existingFilters, e.target.value]
    } else if (Array.isArray(existingFilters)) {
      nextFilter[name] = without(existingFilters, e.target.value)
    }

    nextFilter = updateDirectFlightsFilterValues(nextFilter, name)

    return nextFilter
  }

  nextFilter[name] = [e.target.value]
  return nextFilter
}

const updateDirectFlightsFilterValues = (filters: FlightFilters, targetName:string) => {
  const nextFilter = { ...filters }

  if (targetName === 'recommended') {
    if (nextFilter.recommended?.includes(FlightPriceFilterRecommendedType.DIRECT_FLIGHTS)) {
      nextFilter.stops = ['0']
    } else {
      nextFilter.stops = []
    }

    if (nextFilter.recommended?.includes(FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG)) {
      nextFilter.bags = [BAG_TYPE.CHECKED]
    } else {
      nextFilter.bags = []
    }
  }

  if (targetName === 'bags') {
    if (nextFilter.bags?.includes(BAG_TYPE.CHECKED)) {
      nextFilter.recommended = [...new Set([...(nextFilter.recommended || []), FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG])]
    } else {
      nextFilter.recommended = nextFilter.recommended?.filter(filter => filter !== FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG) || []
    }
  }

  if (targetName === 'stops') {
    if (nextFilter.stops?.length === 1 && nextFilter.stops?.includes('0')) {
      nextFilter.recommended = [...new Set([...(nextFilter.recommended || []), FlightPriceFilterRecommendedType.DIRECT_FLIGHTS])]
    } else {
      nextFilter.recommended = nextFilter.recommended?.filter(filter => filter !== FlightPriceFilterRecommendedType.DIRECT_FLIGHTS) || []
    }
  }

  return nextFilter
}

export function hasNoFlexibilityFareFeatures(features: Array<App.FareFamilyFeature>) {
  return features.filter(feature => !!feature.type).every(feature => !FLEXIBILITY_INCLUSIONS.has(feature.type!))
}

export function getFlightV2BestPrice(journeys:Array<App.JourneyV2>): number | undefined {
  const sortedFlight = sortByAll(journeys, getDepartingSortListV2(FlightSortOptions.Cheapest))[0]
  return sortedFlight?.price.adult.totalFare
}

export function getFlightV2BestTime(journeys:Array<App.JourneyV2>): App.FlightGroup['totalFlightDuration'] | undefined {
  const sortedFlight = sortByAll(journeys, getDepartingSortListV2(FlightSortOptions.Fastest))[0]

  return sortedFlight?.flightGroup.totalFlightDuration
}

export function isSameFlightTime(timeA?: App.FlightGroup['totalFlightDuration'], timeB?: App.FlightGroup['totalFlightDuration']): boolean {
  return timeA?.hours === timeB?.hours && timeA?.minutes === timeB?.minutes
}

interface CheckoutFlightQueryParams {
  departDate?: string | null;
  returnDate?: string | null;
  originAirportCode?: string | null;
  destinationAirportCode?: string | null;
}

export function getFlightCheckoutQueryParams(url: string = ''): CheckoutFlightQueryParams | undefined {
  const pageParams = new URLSearchParams(window.location.search)
  const query = new URLSearchParams(url.split('?')[1])

  const departDate = query.get('departDate') || pageParams.get('departDate')
  const returnDate = query.get('returnDate') || pageParams.get('returnDate')
  const originAirportCode = query.get('originAirportCode') || pageParams.get('originAirportCode')
  const destinationAirportCode = query.get('destinationAirportCode') || pageParams.get('destinationAirportCode')
  if (departDate && returnDate && originAirportCode && destinationAirportCode) {
    return { departDate, destinationAirportCode, originAirportCode, returnDate }
  }
}

export function getFlightCheckoutQueryParamsFromFlightItem(item: App.Checkout.FlightItem): CheckoutFlightQueryParams | undefined {
  const {
    travelStart: departDate,
    travelEnd: returnDate,
    originAirportCode,
    destinationAirportCode,
  } = item
  if (departDate && returnDate && originAirportCode && destinationAirportCode) {
    return { departDate, destinationAirportCode, originAirportCode, returnDate }
  }
}

const FLIGHTS_SESSION_LAST_SEARCH_KEY = 'flight-session-last-search'

export interface LastSearchSessionData {
  flights: Array<{
    departureAirport?: App.AirportLocation;
    arrivalAirport?: App.AirportLocation;
    departingDate?: string | null;
    returningDate?: string | null;
  }>;
  fareType?: string;
  fareClass?: string;
  occupants?: App.Occupants;
}

interface LastSearchSessionDataResponse extends LastSearchSessionData {
  searchedOn: number;
}

export function setFlightSessionRecentSearch(data: LastSearchSessionData) {
  if (typeof window === 'undefined') return
  localStorage.setItem(FLIGHTS_SESSION_LAST_SEARCH_KEY, JSON.stringify({ ...data, searchedOn: Date.now() }))
}

export function getFlightsRecentSearch(): LastSearchSessionDataResponse | undefined {
  if (typeof window === 'undefined') return
  const recentSearch = localStorage.getItem(FLIGHTS_SESSION_LAST_SEARCH_KEY)

  if (!recentSearch) return
  let data: LastSearchSessionDataResponse | undefined = undefined

  try {
    const stringifiedRecentSearch = decodeURIComponent(recentSearch)
    data = JSON.parse(stringifiedRecentSearch)
  } catch { }

  return data
}

export function getCartFlightItemsIds(cartItems: Array<App.Checkout.AnyItem> = []): Array<string> {
  return cartItems.reduce((acc: Array<string>, item) => {
    if (item.itemType === 'flight') {
      return [...acc, ...item.flights.map(flight => flight.journeyId)].filter(Boolean)
    }

    return acc
  }, [])
}

export function getFlightSummaryDetails(flight: App.Flight) {
  const route = `${capitalise(flight.departingDisplayNames.primary)} to ${capitalise(flight.arrivalDisplayNames.primary)}`
  const departDateTime = moment(`${flight.departingDate} ${flight.departingTime}`).format(SHORT_DATE_TIME_FORMAT)
  const arrivalDateTime = moment(`${flight.arrivalDate} ${flight.arrivalTime}`).format(SHORT_DATE_TIME_FORMAT)

  return {
    route,
    dateTime: `${departDateTime} - ${arrivalDateTime}`,
  }
}

export function isDepartingSegment(segments: Array<string>, activeSegment?: string) {
  if (activeSegment && segments[0]) {
    return activeSegment === segments[0]
  }

  return false
}

export function isReturningSegment(segments: Array<string>, activeSegment?: string) {
  if (activeSegment && segments[1]) {
    return activeSegment === segments[1]
  }

  return false
}

export function serialiseFlightsOccupancy(occupants: Array<App.Occupants>): Array<string> {
  return occupants.map(occupant => {
    const occupancy = [occupant.adults.toString(), (occupant.children || 0).toString(), (occupant.infants || 0).toString()]

    if (occupant.childrenAge?.length) {
      occupancy.push(`${occupant.childrenAge.join(',')}`)
    }

    return occupancy.join('-')
  })
}

export function getFlightSearchQueryparams(items: Array<FlightSearchWidgetFlightItem>, fareType: FlightsFareTypes) {
  if (fareType === FlightsFareTypes.MULTI_CITY && items.length > 1) {
    return {
      flights: items.map(item => {
        const departDate = moment(item.checkinDate).format(ISO_DATE_FORMAT)

        return {
          originAirportCode: item.departureAirport?.airportCode,
          originAirportName: item.departureAirport?.airportName,
          destinationAirportCode: item.arrivalAirport?.airportCode,
          destinationAirportName: item.arrivalAirport?.airportName,
          departDate,
        }
      }),
    }
  }

  const flight = items[0]

  const isReturnFare = fareType === FlightsFareTypes.RETURN
  const departDate = moment(flight.checkinDate).format(ISO_DATE_FORMAT)
  const returnDate = isReturnFare ? moment(flight.checkoutDate).format(ISO_DATE_FORMAT) : null

  return {
    originAirportCode: flight.departureAirport?.airportCode,
    originAirportName: flight.departureAirport?.airportName,
    destinationAirportCode: flight.arrivalAirport?.airportCode,
    destinationAirportName: flight.arrivalAirport?.airportName,
    departingQuotedPrice: flight.checkinQuotedPrice,
    returningQuotedPrice: flight.checkoutQuotedPrice,
    departDate,
    returnDate,
  }
}

export function isStandaloneFlightsCart(items: Array<App.Checkout.AnyItem>) {
  const hasFlight = items.some(isFlightItem)
  const hasBookingProtection = items.some(isBookingProtectionItem)
  const onlyFlights = items.length === 1 && hasFlight
  const flightsAndBooking = items.length === 2 && hasFlight && hasBookingProtection
  return onlyFlights || flightsAndBooking
}

export function getCarrierInfoFromLegs(flights: Array<App.LegFlight>): { message?: string, carrierLogo: string, carrierName: string } {
  const operatingAirlines = flights.map(flight => flight.operatingAirline.name)

  const marketingCarrier = {
    carrierLogo: flights[0]?.marketingAirline.logoUrl,
    carrierName: flights[0]?.marketingAirline.name,
  }

  return getOperatedByMessage(operatingAirlines, marketingCarrier)
}

export function getCarrierInfoFromJourney(journey: App.JourneyV2): { message?: string, carrierLogo: string, carrierName: string } {
  const flights = journey.flightGroup.flights
  const operatingAirlines = flights.map(flight => flight.operatingAirline.name)

  const marketingCarrier = {
    carrierLogo: journey.carrierLogo,
    carrierName: journey.carrierName,
  }

  return getOperatedByMessage(operatingAirlines, marketingCarrier)
}

function getOperatedByMessage(
  operatingAirlines: Array<string>,
  marketingCarrier: { carrierLogo: string, carrierName: string },
): { message?: string, carrierLogo: string, carrierName: string } {
  const uniqueOperatingAirlines = new Set<string>(operatingAirlines)
  const noMarketingAirlines = new Set<string>(operatingAirlines.filter(airline => airline !== marketingCarrier.carrierName))
  const noMarketingAirlinesNames = noMarketingAirlines.values()

  if (uniqueOperatingAirlines.size === 1 && noMarketingAirlines.size === 1) {
    return {
      message: `Operated by ${noMarketingAirlinesNames.next().value}`,
      ...marketingCarrier,
    }
  }

  if (uniqueOperatingAirlines.size === 2 && noMarketingAirlines.size === 1) {
    return {
      message: `Partially operated ${noMarketingAirlinesNames.next().value}`,
      ...marketingCarrier,
    }
  }
  if (noMarketingAirlines.size >= 2) {
    return {
      message: 'Operated by multiple airlines',
      ...marketingCarrier,
    }
  }

  return marketingCarrier
}

export function formatFlightRelativePrice(num: number) {
  const roundedNum = Math.round(num)
  // eslint-disable-next-line no-compare-neg-zero
  return Math.sign(roundedNum) === -0 && roundedNum === 0 ? 0 : roundedNum
}

export function isLegFlight(flight?: App.LegFlight | App.FlightV2): flight is App.LegFlight {
  return !!flight && 'flightUniqueId' in flight
}

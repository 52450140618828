import React, { useRef, useCallback, useContext } from 'react'
import CruiseWhenSelectContent from './CruiseWhenSelectContent'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import CruiseFilterFooter from '../Common/CruiseFilterFooter'
import useToggle from 'hooks/useToggle'
import styled from 'styled-components'
import { CRUISE_FLEXIBLE_DURATION_RANGE } from 'constants/search'

const SearchFilterSelected = styled.div`
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${props => props.theme.borderRadius.XS};
  padding: ${rem(12)};
`

interface Props {
  inputValue: string
  onChange: (values: App.CruiseGlobalFilters) => void
}

function CruiseWhenSelectMobile({ inputValue, onChange }: Props) {
  const dropdownTriggerRef = useRef<HTMLButtonElement>(null)
  const [isOpen, toggleModal] = useToggle(false)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const {
    flexibleMonths,
    durationMax,
    durationMin,
    durationRange,
    checkinDate,
    checkoutDate,
  } = useContext(GlobalSearchStateContext)

  const onClear = useCallback(() => {
    searchDispatch({ type: GlobalSearchStateActions.SET_DURATION_RANGE, min: undefined, max: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: '' })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE, durationRange: [] })
  }, [searchDispatch])

  const onChangeWhen = useCallback((values: App.CruiseGlobalFilters) => {
    const { flexibleMonths, durationMax, durationMin, durationRange, checkinDate, checkoutDate } = values
    searchDispatch({ type: GlobalSearchStateActions.SET_DURATION_RANGE, min: durationMin, max: durationMax })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: flexibleMonths! })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: checkinDate })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: checkoutDate })
    if (durationRange) {
      searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE, durationRange: durationRange as Array<CRUISE_FLEXIBLE_DURATION_RANGE> })
    }
  }, [searchDispatch])

  const handleOnApply = useCallback(() => {
    onChange({
      flexibleMonths,
      durationMax,
      durationMin,
      durationRange,
      checkinDate,
      checkoutDate,
    })
    toggleModal()
  }, [checkinDate, checkoutDate, durationMax, durationMin, durationRange, flexibleMonths, onChange, toggleModal])

  return <>
    <SearchFormField
      data-testid="cruise-when-select-mobile-input"
      onClick={toggleModal}
      ref={dropdownTriggerRef}
      label="When"
      placeholder="Anytime"
      value={inputValue}
    />

    <ModalBase
      mode="drawer"
      isOpen={isOpen}
      onClose={toggleModal}
    >
      <ModalHeader
        title="When"
        onCloseButtonClick={toggleModal}
      />
      <ModalBody>
        <Group direction="vertical" gap={24}>
          <ModalContent>
            <SearchFilterSelected>
              {!!inputValue && <BodyText variant="medium" >{inputValue}</BodyText>}
              {!inputValue && <BodyText variant="medium" colour="neutral-three">Select duration and dates</BodyText>}
            </SearchFilterSelected>
          </ModalContent>

          <CruiseWhenSelectContent onChange={onChangeWhen} drawerMode />
        </Group>
      </ModalBody>
      <ModalFooter>
        <CruiseFilterFooter onApply={handleOnApply} onClear={onClear} />
      </ModalFooter>
    </ModalBase>
  </>
}

export default CruiseWhenSelectMobile

import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import Search from 'home/components/Search/Search'
import GeoContext from 'contexts/geoContext'
import { getHomeOfferListParams } from 'home/helpers'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'
import OhNoRefreshError from 'components/Common/ErrorBoundary/OhNoRefreshError'
import AspectRatio from 'components/utils/AspectRatio'

const HeroContainer = styled(AspectRatio)`
  max-height: ${rem(340)};
  background-color: ${props => props.theme.palette.neutral.default.six};
`

function UnhandledErrorPage() {
  const { currentRegionCode, geoStateName } = useContext(GeoContext)

  const filters = useMemo(() => getHomeOfferListParams({
    region: currentRegionCode,
    subregion: geoStateName,
  }), [currentRegionCode, geoStateName])

  return (
    <div>
      <div>
        <HeroContainer desktopRatio="3:1" largeDesktopRatio="4:1" ratio="4:3">
          <OhNoRefreshError>
            Our team are working on fixing it now. Please try refreshing the page.
          </OhNoRefreshError>
        </HeroContainer>
      </div>
      <Search />
      <OfferCardCarouselSection title={<>Explore our <i>top offers</i></>} filters={filters}/>
    </div>
  )
}

export default React.memo(UnhandledErrorPage)

export function packageOptionsClick(duration: number) {
  return {
    hitType: 'package-options-night-click',
    packageDetails: {
      label: duration,
    },
  }
}

export const packageViewMoreDetailsClick = () => ({
  hitType: 'package-view-more-details-click',
})

export const packageRoomPhotosViewClick = () => ({
  hitType: 'package-room-photos-view-click',
})

import { useCallback, useContext, useId, useMemo } from 'react'
import qs from 'qs'
import { pushWithRegion } from 'actions/NavigationActions'
import { ANYWHERE_SEARCH_ITEM, SEARCH_VERTICALS } from 'constants/search'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { useGlobalCarHireSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalCarHireSearch'
import CarHireTabContent from '../Components/Mobile/TabContents/CarHireTabContent'
import config from 'constants/config'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import useQueryParams from 'hooks/useQueryParams'
import { getCarHireLocationId, getCarHireSearchType } from 'components/CarHire/carHireUtils'
import GeoContext from 'contexts/geoContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'

function useGlobalSearchVerticalTabCarHire(searchVerticalItem?: App.SearchVerticalItem): App.GlobalSearchVerticalTabV2 {
  const dispatch = useAppDispatch()
  const queryParams = useQueryParams()
  const { currentRegionCode } = useContext(GeoContext)

  const {
    globalCarHireSearchDispatch: searchDispatch,
    globalCarHireSearchState: state,
  } = useGlobalCarHireSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    eventAnalytics: {
      contextLocation: 'common-search',
    },
  }, queryParams)

  const {
    searchItem,
    secondarySearchItem,
    pickUpTime,
    dropOffTime,
    checkinDate: pickUpDate,
    checkoutDate: dropOffDate,
    driversAgeCategory,
    driversAge,
    eventAnalytics,
  } = state

  const onSearch = useCallback(() => {
    const pickUpItem = searchItem ?? ANYWHERE_SEARCH_ITEM
    const dropOffItem = secondarySearchItem ?? searchItem

    if (eventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        eventAnalytics.contextLocation,
        eventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItem: pickUpItem,
          secondarySearchItem: dropOffItem,
          searchVerticals: new Set([SEARCH_VERTICALS.CARHIRE]),
          pickUpTime,
          dropOffTime,
          driversAgeCategory,
          checkinDate: pickUpDate,
          checkoutDate: dropOffDate,
        },
        ),
      ))
    }

    const query = qs.stringify({
      pickUpName: pickUpItem.format.mainText,
      dropOffName: dropOffItem.format.mainText,
      pickUpId: getCarHireLocationId(pickUpItem),
      dropOffId: getCarHireLocationId(dropOffItem),
      pickUpDate: pickUpDate.format(ISO_DATE_FORMAT),
      pickUpTime,
      dropOffDate: dropOffDate.format(ISO_DATE_FORMAT),
      dropOffTime,
      ageCategory: driversAgeCategory,
      age: (driversAgeCategory === 1 || driversAgeCategory === 3) ? driversAge : undefined,
      pickUpSearchType: getCarHireSearchType(pickUpItem),
      dropOffSearchType: getCarHireSearchType(dropOffItem),
    })

    dispatch(pushWithRegion('/search/car-hire', query))
  }, [dispatch, driversAge, driversAgeCategory, dropOffDate, dropOffTime, eventAnalytics, pickUpDate, pickUpTime, searchItem, secondarySearchItem])
  const formId = useId()

  const isInAvailableCarHireRegion = config.CARHIRE_REGIONS.split(',').includes(currentRegionCode)

  return useMemo(() => ({
    formId,
    isEnabled: config.CARHIRE_ENABLED && config.CARHIRE_SEARCH_ENABLED && isInAvailableCarHireRegion && !!searchVerticalItem,
    label: searchVerticalItem?.label ?? 'Car hire',
    key: SEARCH_VERTICALS.CARHIRE,
    context: { dispatch: searchDispatch, state },
    onSearch,
    ContentComponent: CarHireTabContent,
    Icon: LineCarIcon,
  }), [formId, isInAvailableCarHireRegion, searchVerticalItem, searchDispatch, state, onSearch])
}

export default useGlobalSearchVerticalTabCarHire

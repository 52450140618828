import { createSelector } from 'reselect'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { getGiftCardItemsView } from 'checkout/selectors/view/giftCard'

export const getGiftCardTotals = createSelector(
  getGiftCardItemsView,
  (viewWithStatus): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => (itemView.price ?? 0)),
        memberPrice: 0,
        value: sum(definedItems, itemView => (itemView.value ?? 0)),
        surcharge: 0,
        taxesAndFees: 0,
      },
    }
  },
)

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWellnessIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22.32 14.5499s-1.13-.37-1.99-.64c.93-2.67.25-5.36.21-5.5-.13-.5-.63-.82-1.14-.73-.08.01-1.77.33-3.49 1.09-.97-2.87-3.05-4.88-3.16-4.99-.39-.37-.99-.37-1.38 0-.11.11-2.19 2.12-3.16 4.98C6.52 8.03 4.8 7.7 4.73 7.68c-.52-.09-1.02.22-1.15.73-.04.14-.71 2.81.21 5.47-.87.27-2.09.67-2.09.67-.5.16-.78.68-.66 1.19.04.15.97 3.77 5.22 4.59.53.1 1.25.18 2.03.18 1.27 0 2.69-.2 3.73-.77 1.04.57 2.46.77 3.73.77.78 0 1.49-.07 2.03-.18 4.25-.82 5.18-4.44 5.22-4.59.12-.51-.16-1.02-.66-1.19h-.02Zm-5.83-3.85c.81-.38 1.65-.65 2.28-.82.12 1.18.11 3.21-1.06 4.78-.74 1-1.85 1.69-2.9 2.17.83-1.38 1.61-3.19 1.61-5.21 0-.3-.02-.6-.05-.89.04-.01.09-.01.13-.03h-.01Zm-4.43-4.7c.9 1.09 2.35 3.23 2.35 5.62s-1.45 4.53-2.35 5.62c-.9-1.09-2.35-3.23-2.35-5.62s1.45-4.53 2.35-5.62ZM5.35 9.88c.66.18 1.56.46 2.4.86h.01c-.03.29-.05.59-.05.88 0 2.02.77 3.83 1.61 5.21-1.05-.48-2.16-1.17-2.9-2.17-1.17-1.57-1.19-3.6-1.06-4.78h-.01Zm-2 6.23c.45-.14.95-.3 1.32-.41.01 0 .02-.01.03-.02.04.06.07.11.11.17.9 1.21 2.16 2.06 3.37 2.65-.59 0-1.14-.06-1.55-.14-1.87-.36-2.82-1.46-3.28-2.25Zm14.03 2.25c-.38.07-.89.12-1.44.13 1.21-.59 2.47-1.44 3.37-2.65.03-.04.06-.09.09-.14.36.11.84.26 1.26.4-.46.79-1.41 1.89-3.28 2.25v.01Z"/>
  </SvgIcon>
}

export default LineWellnessIcon

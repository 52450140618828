import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { getServiceFeeForInitialPayment } from '.'
import getPaymentScheduleDepositAmount from './getPaymentScheduleDepositAmount'

const getPaymentScheduleMerchantFeeEligibleAmount = createSelector(
  getPaymentScheduleDepositAmount,
  getServiceFeeForInitialPayment,
  (depositAmount, serviceFeeForInitialPayment): number => {
    return floatify(depositAmount - serviceFeeForInitialPayment)
  },
)

export default getPaymentScheduleMerchantFeeEligibleAmount

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineNotesIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16 14H8a1.0002 1.0002 0 0 0-.7071 1.7071C7.4804 15.8946 7.7348 16 8 16h8a1.0001 1.0001 0 0 0 0-2Zm0-4h-6a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929h6a1.0001 1.0001 0 0 0 0-2Zm4-6h-3V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 15 3v1h-2V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 3v1H9V3a1 1 0 0 0-2 0v1H4a1 1 0 0 0-1 1v14a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V5a1.0002 1.0002 0 0 0-1-1Zm-1 15a1.0001 1.0001 0 0 1-1 1H6a1.0002 1.0002 0 0 1-1-1V6h2v1a1 1 0 0 0 2 0V6h2v1a1.0002 1.0002 0 0 0 1.7071.7071A1.0002 1.0002 0 0 0 13 7V6h2v1a1.0002 1.0002 0 0 0 1.7071.7071A1.0002 1.0002 0 0 0 17 7V6h2v13Z"/>
  </SvgIcon>
}

export default LineNotesIcon

import { PAGE_VIEW } from 'actions/actionConstants'
import { take } from 'lib/array/arrayUtils'
import { createReducer } from 'lib/redux/reducerUtils'

export const initialRouteHistoryState: App.RouteHistoryState = {
  recentHistory: [],
  prevPath: '',
  currentPath: '',
  currentSearch: '',
  currentPathParams: {},
}

export default createReducer<App.RouteHistoryState>(initialRouteHistoryState, {
  [PAGE_VIEW]: (state, action) => {
    const { pathParams, path, search } = action

    return {
      recentHistory: take([
        state.currentPath + (state.currentSearch ?? ''),
        ...state.recentHistory,
      ], 5),
      prevPath: state.currentPath,
      currentPath: path,
      currentSearch: search,
      currentPathParams: pathParams,
    }
  },
})

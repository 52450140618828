import { rem } from 'polished'
import React, { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'
import { _searchTileContainerQueryUp } from './_SearchTileBase'

const Container = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  padding: ${rem(16)};

  ${_searchTileContainerQueryUp.desktopSmall} {
    padding: ${rem(20)};
  }
`

const ContentContainer = styled.div`
  flex-grow: 1;
  grid-area: content;
  display: grid;
  gap: ${rem(16)};
  grid-template:
    "content-primary" auto
    "content-secondary" min-content / 1fr;

  ${_searchTileContainerQueryUp.tablet} {
    grid-template: "content-primary content-secondary" 1fr / minmax(0, 1fr) ${rem(200)};
  }

  ${_searchTileContainerQueryUp.desktopSmall} {
    grid-template:
      "content-primary" auto
      "content-secondary" min-content / 1fr;
  }

  ${_searchTileContainerQueryUp.desktop} {
    grid-template: "content-primary content-secondary" 1fr / minmax(0, 1fr) ${rem(200)};
  }
`

const Extension = styled.div`
  &:empty {
    display: none;
  }
`

interface Props extends PropsWithChildren {
  /**
   * Supporting content that always sits at the bottom
   */
  extension?: ReactNode
}

function _SearchTileContent({ children, extension }: Props) {
  return <Container>
    <ContentContainer className={_SearchTileContent.name}>
      {children}
    </ContentContainer>
    {!!extension && <Extension>{extension}</Extension>}
  </Container>
}

export default _SearchTileContent

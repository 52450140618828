import React, { useCallback, useMemo } from 'react'
import noop from 'lib/function/noop'
import Image from 'components/Common/Image'
import { escapeRegex } from 'lib/string/regexUtils'
import ListItem from 'components/Luxkit/List/ListItem'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import styled from 'styled-components'
import cx from 'clsx'

const CruiseLineLogo = styled(Image)`
  &.disabled {
    opacity: 0.5;
  }
`

interface Props {
  cruiseLine: App.CruiseSearchFacet
  isSelected?: boolean
  isDisabled?: boolean
  searchTerm?: string
  onClick?: (cruiseLine: string) => void
}

function CruiseLinesSearchListItem(props: Props) {
  const { cruiseLine, searchTerm, isSelected, isDisabled, onClick = noop } = props
  const handleSelectItem = useCallback(() => {
    onClick(cruiseLine.value)
  }, [cruiseLine, onClick])

  const label = useMemo(() => {
    if (!searchTerm) {
      return cruiseLine.name
    }

    const search = new RegExp(`(${escapeRegex(searchTerm)})`, 'ig')
    const bits = cruiseLine.name.split(search)

    return <>
      {bits.map((textBit, index) => textBit.toLowerCase() === searchTerm.toLowerCase() ? <b key={index}>{textBit}</b> : textBit, '')}
    </>
  }, [searchTerm, cruiseLine.name])

  return <ListItem
    onClick={handleSelectItem}
    title={label}
    disabled={isDisabled}
    startIcon={<CruiseLineLogo
      id={cruiseLine.imageIdSm ?? cruiseLine.imageId}
      alt={cruiseLine.name}
      width={48}
      height={32}
      className={cx({ disabled: isDisabled })}
    />}
    endIcon={<CheckboxButton size="large" checked={isSelected} disabled={isDisabled} />}
  />
}

export default CruiseLinesSearchListItem

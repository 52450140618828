import { rem } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  colour?: 'grey' | 'light-grey';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

const StyledHr = styled.hr<Props>`
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top-style: solid;
  border-top-width: 1px;

  ${({ size }) => {
    switch (size) {
      case 'xs': return css`
        margin-top: ${rem(8)};
        margin-bottom: ${rem(8)};
      `
      case 'sm': return css`
        margin-top: ${rem(12)};
        margin-bottom: ${rem(12)};
      `
      case 'md': return css`
        margin-top: ${rem(16)};
        margin-bottom: ${rem(16)};
      `
      case 'lg': return css`
        margin-top: ${rem(24)};
        margin-bottom: ${rem(24)};
      `
      case 'xl': return css`
        margin-top: ${rem(40)};
        margin-bottom: ${rem(40)};
      `
    }
  }}

  ${({ colour }) => {
    switch (colour) {
      case 'grey': return css`
        border-top-color: ${props => props.theme.palette.neutral.default.five};
      `
      case 'light-grey': return css`
        border-top-color: ${props => props.theme.palette.neutral.default.five};
      `
    }
  }}
`

function Hr(props :Props) {
  const { colour, size, className } = props

  return (
    <StyledHr colour={colour} size={size} className={className} />
  )
}

Hr.defaultProps = {
  colour: 'grey',
}

export default Hr

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGiftIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 7h-.35c.2282-.4672.3479-.98.35-1.5a3.49 3.49 0 0 0-6-2.44 3.4903 3.4903 0 0 0-5.4098.4828A3.49 3.49 0 0 0 6 5.5 3.45 3.45 0 0 0 6.35 7H6a3 3 0 0 0-3 3v2a1 1 0 0 0 1 1h1v6a2.9998 2.9998 0 0 0 3 3h8a3 3 0 0 0 3-3v-6h1a1.0001 1.0001 0 0 0 1-1v-2a3 3 0 0 0-3-3Zm-7 13H8a.9997.9997 0 0 1-1-1v-6h4v7Zm0-9H5v-1a1 1 0 0 1 1-1h5v2Zm0-4H9.5A1.5 1.5 0 1 1 11 5.5V7Zm2-1.5a1.5002 1.5002 0 0 1 1.7926-1.4712 1.5002 1.5002 0 0 1 1.1786 1.1786A1.5001 1.5001 0 0 1 14.5 7H13V5.5ZM17 19a1.0001 1.0001 0 0 1-1 1h-3v-7h4v6Zm2-8h-6V9h5a1 1 0 0 1 1 1v1Z"/>
  </SvgIcon>
}

export default LineGiftIcon

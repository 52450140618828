import config from 'constants/config'
import { PAYMENT_OPTIONS } from 'constants/payment'
import { adjustForDecimal } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { isStandaloneExperience } from 'selectors/checkoutSelectors'
import getDepositAmount from '../payment/getDepositAmount'
import getDepositServiceFee from '../payment/getDepositServiceFee'
import getMerchantFeeAmount from '../payment/getMerchantFeeAmount'
import { shouldUseBookingProtectionMemberPrice } from '../view/luxPlusSubscription'
import { getBookingProtectionQuotePrice, getBookingProtectionQuoteMobileAppOnlyPrice, getBookingProtectionQuoteLuxPlusPrice, getBookingProtectionStartDateFromCart, getBookingProtectionOccupantsFromCartItems, getQuoteFromBookingProtectionQuotes, getBookingProtectionCoverAmountFromCart, getBookingProtectionExperienceTickets } from './bookingProtection'

const getBookingProtectionDetails = createSelector(
  (state: App.State) => state.checkout.payment.paymentSelected,
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.checkout.form.travellerForms,
  getBookingProtectionQuotePrice,
  getBookingProtectionQuoteMobileAppOnlyPrice,
  getBookingProtectionQuoteLuxPlusPrice,
  getBookingProtectionCoverAmountFromCart,
  getBookingProtectionStartDateFromCart,
  getBookingProtectionOccupantsFromCartItems,
  getQuoteFromBookingProtectionQuotes,
  getDepositAmount,
  getDepositServiceFee,
  (state: App.State) => getMerchantFeeAmount(state, state.checkout.payment.paymentSelected),
  (state: App.State) => state.checkout.orderFailure,
  shouldUseBookingProtectionMemberPrice,
  (state: App.State) => isStandaloneExperience(state),
  getBookingProtectionExperienceTickets,
  (
    paymentSelected,
    currencyCode,
    travellers,
    quotePrice,
    quoteMobilePrice,
    quoteLuxPlusPrice,
    coverAmount,
    startDate,
    occupants,
    quote,
    depositAmount,
    serviceFee,
    merchantFee,
    orderFailure,
    checkoutWithDiscountedBookingProtection,
    isStandaloneExperience,
    numberOfExperienceTickets,
  ): App.Checkout.BookingProtectionDetails | null => {
    const eventTravelDateTime = startDate?.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZZ')
    let numberOfTickets
    if (!quote || !quotePrice || !coverAmount || !eventTravelDateTime) return null

    const { quoteId } = quote

    const defaultOrMobilePrice = quoteMobilePrice > 0 ? quoteMobilePrice : quotePrice
    const totalValue = checkoutWithDiscountedBookingProtection ? quoteLuxPlusPrice : defaultOrMobilePrice

    if (isStandaloneExperience) {
      numberOfTickets = numberOfExperienceTickets
    } else {
      numberOfTickets = occupants?.adults ?? 0 + (occupants?.children ?? 0) + (occupants?.infants ?? 0)
    }

    return {
      quote_id: quoteId,
      total_value: totalValue,
      total_value_lux_plus: quoteLuxPlusPrice,
      total_value_public: quotePrice,
      total_value_mobile: quoteMobilePrice,
      order_value: coverAmount,
      brand: config.BRAND,
      is_paid_in_full: paymentSelected === PAYMENT_OPTIONS.FULL,
      initial_payment_value: paymentSelected === PAYMENT_OPTIONS.DEPOSIT ? adjustForDecimal(depositAmount - totalValue - serviceFee - merchantFee) : undefined,
      currency_code: currencyCode,
      number_of_tickets: numberOfTickets,
      event_travel_date_time: eventTravelDateTime,
      traveller_details: travellers.map(
        traveller => ({
          first_name: traveller.firstName,
          last_name: traveller.lastName,
        })),
      quote: {
        products: quote.products,
      },
      previousAttemptFailure: orderFailure,
    }
  },
)

export default getBookingProtectionDetails

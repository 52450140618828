import { API_CALL, OFFER_CALENDAR_RECOMMENDATIONS_CLEAR, LERE_REMOVE_HIGH_INTENT, LERE_SAVE_ABANDONED_CART, LERE_SAVE_HOT_LEADS, SET_LERE_VERSION, LERE_DISMISS_ALL_STICKY_ABANDONED_CART, LERE_REMOVE_ABANDONED_CART, LERE_SAVE_DEFAULT_RECOMMENDATION } from 'actions/actionConstants'
import {
  FETCH_CALENDAR_OFFER_RECOMMENDATIONS,
  FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS,
  FETCH_OFFER_BASED_LERE_RECOMMENDATIONS,
  FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME,
  FETCH_LERE_TOP_DEALS,
  FETCH_LERE_HERO_PLANNER,
  FETCH_LERE_HIGH_INTENT,
  FETCH_LERE_OFFER_STATS,
  FETCH_LERE_PERSONALISED_ALTERNATIVES,
  FETCH_LERE_PERSONALISED_PREFERENCES,
  GET_LOCAL_HIGH_INTENT_OFFERS,
  FETCH_LERE_NEARBY_OFFERS,
  FETCH_LERE_TOUR_RECOMMENDATIONS,
  FETCH_LERE_TRENDING_DESTINATIONS,
  FETCH_LERE_PERSONALISED_TOUR_ENQUIRES,
  FETCH_LERE_REGION_RECOMMENDATIONS,
  FETCH_LERE_RECOMMENDED_DESTINATIONS,
} from 'actions/apiActionConstants'
import * as RecommendationService from 'api/recommendations'
import { lereLocationSeededCacheKey, lereDlpParameters } from 'lib/customer/recommendationUtils'
import config from 'constants/config'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import { getCurrentUserId, getRecommendationUserId } from 'selectors/accountSelectors'
import { timeNowInSecond } from 'lib/datetime/time'
import { getAllAbandonedCarts, getRecentlyViewedOffers } from 'selectors/recomendations/highIntentSelectors'
import { getAllHotLeadOffers } from 'selectors/recomendations/hotLeadSelectors'
import { getOfferStatsKey } from 'selectors/recomendations/offerStatsSelectors'
import { FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES } from './apiActionConstants'
import { removeHighIntentOffersFromLocal, getHighIntentOffersFromLocal, saveHighIntentOffersToLocal } from 'components/Recommendations/utils/highIntentLocal'
import { ALL_GRAPH_RECOMMENDATION_OFFER_TYPES } from 'constants/recommendations'
import { set as localStorageSet, get as localStorageGet } from 'lib/storage/expirableLocalStorage'
import { constructAlternativeOffersKey, getAlternativeOfferEnquiry } from 'selectors/recomendations/personalisedAlternatives'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { LERE_DEFAULT_USER_ID } from 'components/Recommendations/common/constant'
import { constructPersonalisedTourKey } from 'selectors/recomendations/personalisedTours'
import { AppAction } from './ActionTypes'

/**
 *
 * @param offerId offer id
 * @param checkIn Check in date in ISO format
 * @param checkOut Check out date in ISO format
 * @returns
 */
export function fetchOfferBasedRecommendations(offerId: string, allowMultiOffersSameProperty: boolean = false, checkIn?: string, checkOut?: string, occupancy?: string): AppAction {
  return async(dispatch, getState) => {
    // if we have one of these 2 params, we need to make sure both, otherwise skip the request
    if (checkIn || checkOut) {
      if (!(checkIn && checkOut)) {
        return
      }
    }

    const state = getState()
    const cacheKey = offerId

    const filterKey = (!!checkIn && !!checkOut && !!occupancy) ? [checkIn, checkOut, occupancy, allowMultiOffersSameProperty].join(';') : allowMultiOffersSameProperty.toString()

    const recommendationsState = state.recommendations.recommendations[cacheKey]

    if (recommendationsState?.filterKey === filterKey) {
      return
    }

    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_BASED_LERE_RECOMMENDATIONS,
      request: async() => {
        const result = await RecommendationService.getRecommendations(offerId, state.geo.currentRegionCode, canViewLuxPlusBenefits, allowMultiOffersSameProperty, checkIn, checkOut, occupancy)
        const { recommendations, lereVersion } = result
        dispatch({ type: SET_LERE_VERSION, lereVersion })

        const recommendationOfferIds = recommendations.map((r) => r.offerId)

        return recommendationOfferIds
      },
      cacheKey,
      filterKey,
    })
  }
}

export function fetchCalendarRecommendations(offerId: string, filters: {
  checkIn?: string;
  availableMonth?: string;
  rooms?: Array<App.Occupants>;
  duration?: number;
}): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)

    dispatch({
      type: API_CALL,
      api: FETCH_CALENDAR_OFFER_RECOMMENDATIONS,
      request: async() => {
        const params = {
          checkIn: filters.checkIn,
          availableMonth: filters.availableMonth,
          rooms: filters.rooms,
          duration: filters.duration,
        }

        const { recommendations, lereVersion } = await RecommendationService.getCalendarRecommendations(offerId, state.geo.currentRegionCode, canViewLuxPlusBenefits, params)
        const recommendationOfferIds = recommendations.map((r) => r.offerId)
        dispatch({ type: SET_LERE_VERSION, lereVersion })
        return {
          offerIds: recommendationOfferIds,
        }
      },
      offerId,
    })
  }
}

export function clearCalendarOfferRecommendations() {
  return {
    type: OFFER_CALENDAR_RECOMMENDATIONS_CLEAR,
  }
}

interface FetchLocationSeededRecommendationsParams {
  destinationId: string
  polygonMode: RecommendationService.PolygonMode
  nearDestinations: boolean
  useCacheIfExists?: boolean
  checkIn?: string
  checkOut?: string
  duration?: number
  useCentroid?: boolean
  offerTypes?: Array<string>
}

export function fetchLocationSeededRecommendations({
  destinationId,
  polygonMode,
  nearDestinations,
  useCacheIfExists = false,
  checkIn,
  checkOut,
  duration,
  useCentroid = true,
  offerTypes,
}: FetchLocationSeededRecommendationsParams): AppAction {
  return async(dispatch, getState) => {
    const destinationKey = lereLocationSeededCacheKey({
      destinationId,
      polygonMode,
      nearDestinations,
      checkIn,
      checkOut,
      duration,
      offerTypes,
    })

    if (useCacheIfExists) {
      const existingRecommendations = getState().recommendations.locationSeededRecommendations[destinationKey]
      if (existingRecommendations && ['loading', 'done'].includes(existingRecommendations.state)) {
        return
      }
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS,
      request: async() => {
        let checkInParam: string | undefined = undefined
        let checkOutParam: string | undefined = undefined
        let durationParam: number | undefined = undefined
        if (checkIn && checkOut) {
          checkInParam = checkIn
          checkOutParam = checkOut
        }
        if (checkIn && duration) {
          checkInParam = checkIn
          durationParam = duration
        }
        const { recommendations, lereVersion } = await RecommendationService.getLocationSeededRecommendations({
          destinationId,
          polygonMode,
          nearDestination: nearDestinations,
          checkInDate: checkInParam,
          checkOutDate: checkOutParam,
          duration: durationParam,
          useCentroid,
          offerTypes,
        })
        dispatch({ type: SET_LERE_VERSION, lereVersion })

        const recommendationOfferIds = recommendations.map((r) => r.offerId)
        return recommendationOfferIds
      },
      destinationKey,
    })
  }
}

// #region DLP - Destination Landing Page

export function fetchDlpMainRecommendations(destinationId: string): AppAction {
  return fetchLocationSeededRecommendations({
    destinationId,
    polygonMode: lereDlpParameters.mainList.polygonMode,
    nearDestinations: lereDlpParameters.mainList.nearDestinations,
  })
}

export function fetchDlpYmalRecommendations(destinationId: string): AppAction {
  return fetchLocationSeededRecommendations({
    destinationId,
    polygonMode: lereDlpParameters.ymal.polygonMode,
    nearDestinations: lereDlpParameters.ymal.nearDestinations,
    offerTypes: ALL_GRAPH_RECOMMENDATION_OFFER_TYPES,
  })
}

// #endregion

export function fetchPeopleLikeMeRecommendations(userId?: string): AppAction {
  return async(dispatch, getState) => {
    const appState = getState()
    const memberId = userId ?? getRecommendationUserId(appState)
    if (!memberId) {
      return
    }
    const personalisedPeopleLikeMeState = appState.recommendations.personalisedPeopleLikeMe
    const region = appState.geo.currentRegionCode

    if (personalisedPeopleLikeMeState.state === 'done') {
      return
    }

    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(appState)

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME,
      request: async() => await RecommendationService.getPeopleLikeMeRecommendations(memberId, region, canViewLuxPlusBenefits),
    })
  }
}

export function fetchTopDeals(region: string): AppAction {
  return async(dispatch, getState) => {
    const state = getState()

    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_TOP_DEALS,
      request: async() => {
        const { offers, lereVersion } = await RecommendationService.getTopDeals(region, canViewLuxPlusBenefits)

        const leOfferIds = offers.filter(o => [OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE]
          .includes(o.offer_type))
          .map(o => o.offer_id)

        return { offerIds: leOfferIds, lereVersion }
      },
    })
  }
}

export function fetchHeroPlanner(): AppAction {
  return async(dispatch, getState) => {
    const heroPlanner = getState().recommendations.heroPlanner

    if (heroPlanner.state === 'done') {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_HERO_PLANNER,
      request: () => RecommendationService.getHeroPlanner(getState().geo.currentRegionCode),
    })
  }
}

export function fetchHighIntentOffers(domainUserId?: string, memberId?: string): AppAction {
  return async(dispatch, getState) => {
    if (!config.LERE_HIGH_INTENT_CAROUSEL_ENABLED) { return }

    const state = getState()
    const highIntent = state.recommendations.highIntent
    const userId = memberId ?? getRecommendationUserId(state)

    if ((!userId && !domainUserId) || (highIntent.lereVersion !== 'local' && highIntent.state === 'done')) { return }

    const region = state.geo.currentRegionCode
    dispatch({
      type: API_CALL,
      api: FETCH_LERE_HIGH_INTENT,
      request: () => RecommendationService.getHighIntentOffers(region, userId ?? LERE_DEFAULT_USER_ID, domainUserId),
    })
  }
}

export function addAbandonedCart(offerId: string, offerType: App.OfferType, total: number): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const userId = getRecommendationUserId(state)
    const domainUserId = state.auth.domainUserId
    if (!!userId && state.checkout.cart.items.length > 0) {
      const cart: App.AbandonedCartRecommendation = {
        ...state.checkout.cart,
        isDismissed: false,
        offerId,
        offerType,
        creationTime: timeNowInSecond(),
        category: 'sticky_abandoned_carts',
        total,
      }

      RecommendationService.saveCartInLereServiceWithDebounce(cart, domainUserId, userId)

      dispatch({
        type: LERE_SAVE_ABANDONED_CART,
        cart,
      })
    }
  }
}

/**
 * Should be called after checkout
 */
export function removeAbandonedCartsAndDismissOthers(offerIds: Array<string>): AppAction {
  return async(dispatch, getState) => {
    if (!offerIds.length) { return }

    dispatch({
      type: LERE_REMOVE_ABANDONED_CART,
      offerIds,
    })
    dispatch({
      type: LERE_DISMISS_ALL_STICKY_ABANDONED_CART,
    })
    const state = getState()
    const userId = getRecommendationUserId(state)
    const domainUserId = state.auth.domainUserId
    if (userId || domainUserId) {
      // this need to be awaited to make sure the AC is removed first
      // before updating the others, else there will be a race condition
      await RecommendationService.removeAbandonedCarts(offerIds, domainUserId, userId)

      const carts = getAllAbandonedCarts(state)
      if (carts.length) {
        RecommendationService.saveAbanonedCarts(carts, domainUserId, userId)
      }
    }
  }
}

export function saveHotLead(hotLead: App.HotLeadOffer): AppAction {
  return (dispatch, getState) => {
    // we always save the state locally
    // only call remote save if user logged in
    dispatch({
      type: LERE_SAVE_HOT_LEADS,
      hotLead,
    })

    const state = getState()
    const userId = getRecommendationUserId(state)
    const domainUserId = state.auth.domainUserId
    saveHighIntentOffersToLocal([hotLead])

    if (userId || domainUserId) {
      // don't need to await for the response
      RecommendationService.saveHotLeadsWithDebounce([hotLead], domainUserId, userId)
    }
  }
}

export function dismissHotLead(hotLead: App.HotLeadOffer): AppAction {
  return async(dispatch) => {
    const dismissedHotLead = {
      ...hotLead,
      isDismissed: true,
    }
    dispatch(saveHotLead(dismissedHotLead))
  }
}

export function removeHighIntentOffers(offerIds: Array<string>, highIntentCategories: Array<App.HighIntentCategory>): AppAction {
  return async(dispatch, getState) => {
    if (!offerIds.length) { return }

    dispatch({
      type: LERE_REMOVE_HIGH_INTENT,
      offerIds,
      highIntentCategories,
    })

    removeHighIntentOffersFromLocal(offerIds)
    const state = getState()
    const userId = getCurrentUserId(state)
    const domainUserId = state.auth.domainUserId
    if (userId || domainUserId) {
      // don't need to await for the response

      if (highIntentCategories.includes('recently_viewed')) {
        RecommendationService.removeRecentlyViewedOffers(offerIds, domainUserId, userId)
      }
      // Hot Leads is the subset of Recent Viewed, will remove both
      if (highIntentCategories.includes('sticky_hot_leads')) {
        RecommendationService.removeHotLeads(offerIds, domainUserId, userId)
        RecommendationService.removeRecentlyViewedOffers(offerIds, domainUserId, userId)
      }
      // Abandoned cart is the subset of Hot Leads and Recent Viewed, will remove all
      if (highIntentCategories.includes('sticky_abandoned_carts')) {
        RecommendationService.removeAbandonedCarts(offerIds, domainUserId, userId)
        RecommendationService.removeHotLeads(offerIds, domainUserId, userId)
        RecommendationService.removeRecentlyViewedOffers(offerIds, domainUserId, userId)
      }
    }
  }
}

export function saveAllHotLeadsAndRecentlyViewed(): AppAction {
  return async(_, getState) => {
    const state = getState()
    const userId = getCurrentUserId(state)
    const domainUserId = state.auth.domainUserId
    if (!userId && !domainUserId) { return }

    const hotLeads = getAllHotLeadOffers(state)
    if (hotLeads.length) {
      RecommendationService.saveHotLeadsWithDebounce(hotLeads, domainUserId, userId)
    }
    const recentlyViewed = getRecentlyViewedOffers(state)
    if (recentlyViewed.length) {
      const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
      RecommendationService.saveRecentlyViewedOffersWithDebounce(recentlyViewed, state.geo.currentRegionCode, canViewLuxPlusBenefits, domainUserId, userId)
    }
  }
}

export function fetchPersonalisedAlternativeOffers(region: string, domainUserId?: string, memberId?: string) : AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const alternatives = state.recommendations.personalisedAlternatives
    const userId = memberId ?? getRecommendationUserId(state)

    if (!userId || alternatives.state === 'done') { return }

    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_PERSONALISED_ALTERNATIVES,
      request: () => RecommendationService.enquirePersonalisedAlternativeRecommendations(userId, region, canViewLuxPlusBenefits, undefined, domainUserId),
    })
  }
}

export function fetchPersonalisedPreferenceOffers(memberId?: string): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const preferences = state.recommendations.personalisedPreferences
    const userId = memberId ?? getRecommendationUserId(state)

    if (!userId || preferences.state === 'done') { return }

    const region = state.geo.currentRegionCode
    dispatch({
      type: API_CALL,
      api: FETCH_LERE_PERSONALISED_PREFERENCES,
      request: () => RecommendationService.getPreferenceRecommendations(userId, region),
    })
  }
}

export function fetchPersonalisedAlternativeOffersWithOfferEnquires(region: string, domainUserId?: string, offerEnquiries?: Array<RecommendationService.AlternativeOfferEnquiry>): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const alternatives = state.recommendations.personalisedAlternativesWithOfferEnquiries
    const finalOfferEnquiries = offerEnquiries || getAlternativeOfferEnquiry(state)
    const alternativeByOffersKey = constructAlternativeOffersKey(finalOfferEnquiries)

    if (finalOfferEnquiries.length === 0 || alternatives[alternativeByOffersKey]?.state === 'done') { return }
    // As we want to support not-login users, we created a user as default for LERE only
    const userId = LERE_DEFAULT_USER_ID
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES,
      key: alternativeByOffersKey,
      request: () => RecommendationService.enquirePersonalisedAlternativeRecommendations(userId, region, canViewLuxPlusBenefits, finalOfferEnquiries, domainUserId),
    })
  }
}

export function fetchOfferStats(offerId: string, region: string): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const offerKey = getOfferStatsKey(offerId, region)
    const offerStats = state.recommendations.offerStats[offerKey]

    // skip API call if already fetching or fetched
    if (!!offerStats && (offerStats.state === 'done' || offerStats.state === 'loading')) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_OFFER_STATS,
      offerKey,
      request: () => RecommendationService.getOfferStats(offerId, region),
    })
  }
}

export function fetchLocalHighIntentOffers(): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const userId = getCurrentUserId(state)
    if (userId) { return }

    dispatch({
      type: GET_LOCAL_HIGH_INTENT_OFFERS,
      offers: getHighIntentOffersFromLocal(),
    })
  }
}

export function fetchLereTrendingDestinations(placeId: string): AppAction {
  return async(dispatch, getState) => {
    const state: App.State = getState()
    if (state.recommendations.trendingDestinations.state === 'done') { return }

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_TRENDING_DESTINATIONS,
      request: () => RecommendationService.getRecommendedTrendingDestinations(placeId),
    })
  }
}

export function getLereNearbyOffersKey(placeId: string, region: string, occupancy?: Array<App.Occupants>, checkIn?: string, checkOut?: string): string {
  if (occupancy && checkIn && checkOut) {
    // occupancy only get used when we have dates
    return `${placeId}-${region}-${RecommendationService.occupancyToStr(occupancy)}-${checkIn}-${checkOut}`
  }
  return `${placeId}-${region}`
}

export function fetchLereNearbyOffers(placeId: string, occupancy?: Array<App.Occupants>, checkIn?: string, checkOut?: string): AppAction {
  return async(dispatch, getState) => {
    const state: App.State = getState()
    const region = state.geo.currentRegionCode

    const key = getLereNearbyOffersKey(placeId, region, occupancy, checkIn, checkOut)
    const fetchState = state.recommendations.nearbyOffers[key]?.state
    if (fetchState && fetchState !== 'error') { return }

    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_NEARBY_OFFERS,
      key,
      request: () => RecommendationService.getNearbyRecommendations(placeId, region, canViewLuxPlusBenefits, occupancy, checkIn, checkOut),
    })
  }
}

const DEFAULT_RECOMMENDATION_KEY = 'lere_default'
const DEFAULT_RECOMMENDATION_TTL = 60 * 60 // 1 hr

export function saveDefaultRecommendation(source: string, offerIds: Array<string>): AppAction {
  return (dispatch) => {
    if (!offerIds.length) { return }

    const defaultRecommendation: App.DefaultRecommendation = { offerIds, source }
    localStorageSet(DEFAULT_RECOMMENDATION_KEY, defaultRecommendation, DEFAULT_RECOMMENDATION_TTL)

    dispatch({
      defaultRecommendation,
      type: LERE_SAVE_DEFAULT_RECOMMENDATION,
    })
  }
}

export function loadDefaultRecommendation(): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const existing = state.recommendations.defaultRecommendation.offerIds

    if (existing.length) { return }

    const defaultRecommendation = localStorageGet<App.DefaultRecommendation>(DEFAULT_RECOMMENDATION_KEY)

    if (defaultRecommendation?.offerIds?.length) {
      dispatch({
        defaultRecommendation,
        type: LERE_SAVE_DEFAULT_RECOMMENDATION,
      })
    }
  }
}

export function getTourKey(
  offerId: string,
  region: string,
  departureYear?: number,
  departureMonth?: number,
) {
  if (!!departureYear && departureMonth !== undefined) {
    return `${offerId}-${region}-${departureYear}-${departureMonth + 1}`
  }
  return `${offerId}-${region}`
}

export function fetchRecommendedTours(
  offerId: string,
  departureYear?: number,
  departureMonth?: number,
  offerType?: Array<Tours.V2ProductType>,
): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const region = state.geo.currentRegionCode

    const key = getTourKey(offerId, region, departureYear, departureMonth)
    const tourState = state.recommendations.tour[key]

    if (tourState?.state == 'done' || tourState?.state == 'loading') { return }

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_TOUR_RECOMMENDATIONS,
      request: () => RecommendationService.getRecommendedTours(offerId, region, departureYear, departureMonth, offerType),
      key,
    })
  }
}

export function fetchPersonalisedTours(
  offerIds: Array<string>,
): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId ?? LERE_DEFAULT_USER_ID
    const region = state.geo.currentRegionCode

    const personalisedTourKey = constructPersonalisedTourKey(region, memberId, offerIds)
    const personalizedTours = state.recommendations.personalisedTours
    const personalizedTour = personalizedTours[personalisedTourKey]
    if (personalizedTour && personalizedTour?.state !== 'loading') return

    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
    dispatch({
      type: API_CALL,
      api: FETCH_LERE_PERSONALISED_TOUR_ENQUIRES,
      key: personalisedTourKey,
      request: () => RecommendationService.enquirePersonalisedTourRecommendations(
        memberId,
        region,
        canViewLuxPlusBenefits,
        offerIds.map(id => ({ offer_id: id })),
      ),
    })
  }
}

export function fetchRegionRecommendations(): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const region = state.geo.currentRegionCode

    dispatch({
      type: API_CALL,
      api: FETCH_LERE_REGION_RECOMMENDATIONS,
      key: 'regionRecommendations',
      request: () => RecommendationService.getRegionRecommendations(region),
    })
  }
}

export function fetchRecommendedDestinations(region: string, domainUserId?: string, leUserId?: string): AppAction {
  return async(dispatch) => {
    dispatch({
      type: API_CALL,
      api: FETCH_LERE_RECOMMENDED_DESTINATIONS,
      key: 'recommendedDestinations',
      request: () => RecommendationService.getRecommendedDestinations(region, domainUserId, leUserId),
    })
  }
}

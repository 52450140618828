import React, { useMemo } from 'react'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2PurchasableOptionDetails from 'hooks/TourV2/useTourV2PurchasableOptionDetails'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { TourV2PurchasableOptionDetails } from 'lib/tours/extractTourV2PurchasableOptionDetails'

const emptyTourV2PurchasableOptionDetails = {} as { [key in keyof TourV2PurchasableOptionDetails]: undefined }

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
  className?: string;
  variant?: 'default' | 'condensed';
}

function TourV2PriceDetails(props: Props) {
  const { offer, variant = 'default' } = props

  const {
    cheapestPurchasableOption,
    cheapestVariation,
  } = useCheapestOfTourV2Offer(offer)

  const {
    durationInDaysCount,
  } = useTourV2VariationDetails(cheapestVariation)

  const {
    roomTypeName,
    pricing,
    memberPricing,
  } = useTourV2PurchasableOptionDetails(cheapestPurchasableOption) || emptyTourV2PurchasableOptionDetails

  const tourV2StartLocation = useMemo(
    () => {
      const variations = Object.values(offer.variations)
      if (variations.length === 0) return ['']

      const variation = variations[0]

      return [variation.startLocation || '']
    },
    [offer],
  )

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <VerticalSpacer gap={8}>
        <div>
          {pricing &&
            <>
              <PriceRowPriceCaption>{durationInDaysCount} from</PriceRowPriceCaption>
              <LuxPlusPriceStack pricing={pricing} memberPricing={memberPricing} size="L" isTour />
            </>
          }
          {pricing?.value && <PriceRowValueDiscountWithCaption
            data-testid="value-box"
            size="L"
            originalValue={Math.ceil(pricing.value)}
            discountPercentage={pricing.discountPercent}
          />}
          <PriceRowCaption>{roomTypeName}</PriceRowCaption>
          {variant === 'default' && <PriceRowTaxesAndFees />}
        </div>
        <PriceRowAgentHubCommission
          size="L"
          productType={offer.productType}
          offerId={offer.id}
          offerLocations={tourV2StartLocation}
        />
      </VerticalSpacer>
      {variant === 'default' && <CSSBreakpoint min="desktop">
        <TextButton kind="primary" nonInteractable>
          View
        </TextButton>
      </CSSBreakpoint>}
      {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
        View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
      </TextButton>}
    </Group>
  )
}

export default TourV2PriceDetails

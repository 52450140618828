import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidShieldCheckIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19.6 3.6c-.2-.2-.5-.3-.8-.2-2.2.5-4.4 0-6.2-1.3-.3-.2-.8-.2-1.1 0-1.9 1.3-4.1 1.8-6.3 1.3-.5-.1-1.1.3-1.2.8v7.7c0 2.9 1.4 5.6 3.8 7.3l3.7 2.6c.3.2.8.2 1.2 0l3.7-2.6c2.4-1.7 3.8-4.4 3.8-7.3V4.4c-.2-.3-.3-.6-.6-.8ZM15 11l-3.4 3.4c-.4.4-1 .4-1.4 0l-1.6-1.6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l.9.9 2.7-2.7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4Z"/>
  </SvgIcon>
}

export default SolidShieldCheckIcon

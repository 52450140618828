import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidBellIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 13.2V10c0-2.9-2.1-5.4-5-5.9V3c0-.6-.4-1-1-1s-1 .4-1 1v1.1c-2.9.5-5 3-5 5.9v3.2c-1.2.4-2 1.5-2 2.8v2c0 .6.4 1 1 1h3.1c.5 2.1 2.7 3.4 4.8 2.9 1.4-.4 2.5-1.5 2.9-2.9H19c.6 0 1-.4 1-1v-2c0-1.3-.8-2.4-2-2.8ZM12 20c-.7 0-1.4-.4-1.7-1h3.5c-.4.6-1.1 1-1.8 1Z"/>
  </SvgIcon>
}

export default SolidBellIcon

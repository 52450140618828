import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import { SOCIAL_LOGIN_TEXT_BUTTON_PROPS, SOCIAL_LOGIN_ICON_BUTTON_PROPS } from './SocialLoginButton'
import IconButton from 'components/Luxkit/Button/IconButton'
import SolidGoogleIcon from 'components/Luxkit/Icons/solid/SolidGoogleIcon'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'

const RelativeTextButton = styled(TextButton)`
  position: relative; /** so that the absolutely positioned span can sit properly */
  overflow: hidden;
`

const RelativeIconButton = styled(IconButton)`
  position: relative; /** so that the absolutely positioned span can sit properly */
  overflow: hidden;
`

const StylesSpan = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.01;
`

interface Props {
  hideText?: boolean;
  renderId: string;
  gsiLoaded: boolean;
  customIcon?: JSX.Element;
}

function GoogleLoginButton(props: Props) {
  const { hideText, renderId, gsiLoaded, customIcon } = props
  const dispatch = useAppDispatch()
  const [hasInitialized, setHasInitialized] = useState<boolean>(false)

  const clickHandler = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'sign_up_sso_click',
      action: 'google_auth',
      category: 'logging',
      type: 'operational',
    })
  }, [])

  useEffect(() => {
    if (!hasInitialized && gsiLoaded) {
      window.google.accounts.id.renderButton(
          document.getElementById(renderId)!,
          {
            type: 'standard',
            theme: 'outline',
            size: 'large',
            text: 'continue_with',
            logo_alignment: 'center',
            click_listener: clickHandler,
            width: hideText ? 100 : 600,
          },
      )
      setHasInitialized(true)
    }
  }, [gsiLoaded, hasInitialized, renderId, hideText, dispatch, clickHandler])

  return <>
    {!hideText && <RelativeTextButton
      {...SOCIAL_LOGIN_TEXT_BUTTON_PROPS}
      startIcon={<SolidGoogleIcon />}
    >
      Google
      <StylesSpan id={renderId}/>
    </RelativeTextButton>}
    {hideText && <RelativeIconButton
      {...SOCIAL_LOGIN_ICON_BUTTON_PROPS}
    >
      <StylesSpan id={renderId}/>
      {customIcon || <SolidGoogleIcon />}
    </RelativeIconButton>}
  </>
}

const mapState = (state: App.State) => ({
  gsiLoaded: state.auth.gsiLoaded,
})

const connector = connect(mapState)

export default connector(GoogleLoginButton)

import Modal from 'components/Luxkit/Modal/Modal'
import VillasPageAmenitiesModalContent from 'components/OfferPage/Villas/VillasPageAmenitiesModalContent'
import ModalContext from 'contexts/ModalContext'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getHotelOfferUrl } from 'lib/offer/offerPageURL'
import React, { useCallback, useContext } from 'react'
import SearchTileStackAmenitiesList from '../shared/SearchTileStackAmenitiesList'

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary
  pkg?: App.HotelPackage
}

function VillaSearchTileFeatures({ offer, pkg }: Props) {
  const amenities = pkg?.roomType.amenities ?? EmptyArray

  const showModal = useContext(ModalContext)
  const openAmenitiesModal = useCallback(() => {
    showModal(
      <Modal
        title={offer.property.name}
        primaryActionTo={getHotelOfferUrl(offer.id, offer.type)}
        primaryActionText="View offer"
      >
        <VillasPageAmenitiesModalContent amenities={amenities} />
      </Modal>,
    )
  }, [showModal, offer, amenities])

  if (!amenities.length) return null

  return <SearchTileStackAmenitiesList
    amenities={amenities}
    onMoreClick={openAmenitiesModal}
  />
}

export default VillaSearchTileFeatures

import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import React, { useCallback, useContext, useMemo } from 'react'
import FlightSearchAirportModalSelect from './FlightSearchAirportModalSelect'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import Modal from 'components/Luxkit/Modal/Modal'

interface Props {
  direction: 'departure' | 'arrival';
  id: string;
}

function FlightSearchAirportModal(props: Props) {
  const { direction, id } = props
  const { resolve } = useModalElementContext<App.AirportLocation | undefined>()

  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const { flights } = state

  const flight = useMemo(() => {
    return flights.find((flight) => flight.id === id)
  }, [flights, id])!

  const prevAirport = useMemo(() => {
    const currentFlightIndex = flights.findIndex(flight => flight.id === id)
    return flights[currentFlightIndex - 1]?.arrivalAirport
  }, [flights, id])

  const isDeparture = direction === 'departure'
  const airport = isDeparture ? flight.departureAirport : flight.arrivalAirport

  const onSelectAirport = useCallback(
    (airport?: App.AirportLocation) => {
      const actionType = isDeparture ? FlightSearchWidgetActions.SET_DEPARTURE_AIRPORT : FlightSearchWidgetActions.SET_ARRIVAL_AIRPORT
      dispatch({ type: actionType, airport, id })

      if (airport) {
        resolve(airport)
      }
    },
    [dispatch, isDeparture, resolve, id],
  )

  return (
    <Modal mode="drawer" title={isDeparture ? 'Flying from?' : 'Flying to?'}>
      <FlightSearchAirportModalSelect
        airport={airport}
        alternateAirport={isDeparture ? flight.arrivalAirport : flight.departureAirport}
        onSelectAirport={onSelectAirport}
        direction={isDeparture ? 'departure' : 'arrival'}
        fallbackAirports={prevAirport?.cityDesc && isDeparture ? [prevAirport] : undefined}
        fallbackHeading={prevAirport && isDeparture ? 'Recent search' : undefined} />
    </Modal>
  )
}

export default FlightSearchAirportModal

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineEyeSlashIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10.94 6.08A6.931 6.931 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.2305 15.2305 0 0 1-.9 1.64 1.0005 1.0005 0 0 0-.16.55.9998.9998 0 0 0 .7403.9555A1.0002 1.0002 0 0 0 20.71 14.69a15.775 15.775 0 0 0 1.21-2.3.9993.9993 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.7709 7.7709 0 0 0-1.4.12 1.0138 1.0138 0 0 0-.6573.4131A1.0143 1.0143 0 0 0 10.94 6.12v-.04ZM3.71 2.29a1.0041 1.0041 0 1 0-1.42 1.42l3.1 3.09a14.6202 14.6202 0 0 0-3.31 4.8 1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.2598 9.2598 0 0 0 5.05-1.54l3.24 3.25a.9997.9997 0 0 0 1.42 0 .9997.9997 0 0 0 0-1.42l-18-18Zm6.36 9.19 2.45 2.45A1.8105 1.8105 0 0 1 12 14a2 2 0 0 1-2-2 1.8087 1.8087 0 0 1 .07-.52ZM12 18c-3.18 0-6.17-2.29-7.9-6a12.0906 12.0906 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.2402 7.2402 0 0 1 12 18Z"/>
  </SvgIcon>
}

export default LineEyeSlashIcon

// import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect, useMemo } from 'react'

import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
// import { getHotelsPopularDestinations, getIsFetchingFeaturedDestinations } from 'selectors/destinationSelectors'
import GlobalSearchState, { GlobalSearchStateActions, GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
// import { fetchFeaturedDestinations } from 'actions/DestinationAlertsActions'
import { FLEXIBLE_DURATION_RANGE, SEARCH_VERTICALS } from 'constants/search'
import { getCheckInDateFromURLSearchParams, getCheckOutDateFromURLSearchParams, getDataSearchOptionIdFromURLSearchParams, getFlexibleMonthsFromURLSearchParams, getFlexibleNightsFromURLSearchParams, getOccupanciesFromURLSearchParams, getSearchItemFromURLSearchParams, getUserSelectedFlexibleMonthsFromURLSearchParams } from 'lib/url/searchUrlUtils'

// hard coded popular destinations for now
const popularDestinations: Array<App.SearchDestination> = [{
  searchType: 'destination',
  value: 'le_43ec517d68b6edd3015b3edc9a11367b',
  format: { mainText: 'Indonesia' },
}, {
  searchType: 'destination',
  value: 'le_8f85517967795eeef66c225f7883bdcb',
  format: { mainText: 'Thailand' },
}, {
  searchType: 'destination',
  value: 'le_d3d9446802a44259755d38e6d163e820',
  format: { mainText: 'Australia' },
}, {
  searchType: 'destination',
  value: 'le_093f65e080a295f8076b1c5722a46aa2',
  format: { mainText: 'France' },
}, {
  searchType: 'destination',
  value: 'le_cfecdb276f634854f3ef915e2e980c31',
  format: { mainText: 'UK' },
}, {
  searchType: 'destination',
  value: 'le_9fc3d7152ba9336a670e36d0ed79bc43',
  format: { mainText: 'New Zealand' },
}]

export function useGlobalHomesAndVillasSearch(
  initialState: GlobalSearchState = GLOBAL_SEARCH_INITIAL_STATE,
  syncWithURLSearchParams?: URLSearchParams,
) {
  const queryParams = useMemo(() => {
    if (syncWithURLSearchParams) {
      return {
        flexibleNights: getFlexibleNightsFromURLSearchParams(syncWithURLSearchParams),
        flexibleMonths: getFlexibleMonthsFromURLSearchParams(syncWithURLSearchParams),
        dateSearchOptionId: getDataSearchOptionIdFromURLSearchParams(syncWithURLSearchParams),
        checkinDate: getCheckInDateFromURLSearchParams(syncWithURLSearchParams),
        checkoutDate: getCheckOutDateFromURLSearchParams(syncWithURLSearchParams),
        occupancies: getOccupanciesFromURLSearchParams(syncWithURLSearchParams),
        searchItem: getSearchItemFromURLSearchParams(syncWithURLSearchParams),
        userSelectedFlexibleMonths: getUserSelectedFlexibleMonthsFromURLSearchParams(syncWithURLSearchParams),
      }
    }
    return undefined
  }, [syncWithURLSearchParams])

  const { globalSearchState, globalSearchDispatch } = useGlobalSearchContext({
    ...initialState,
    disableMultiRoom: true,
    disableSaleUnit: true,
    disableRecentSearches: true,
    occupancies: initialState.occupancies?.length ? initialState.occupancies : GLOBAL_SEARCH_INITIAL_STATE.occupancies,
    searchVerticals: new Set([SEARCH_VERTICALS.HOMES_AND_VILLAS]),
  })

  // sync check in date
  useEffect(() => {
    if (queryParams) {
      const { checkinDate } = queryParams
      if (checkinDate) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: queryParams.checkinDate })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKIN_DATE })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.checkinDate])

  // sync check out date
  useEffect(() => {
    if (queryParams) {
      const { checkoutDate } = queryParams
      if (checkoutDate) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: queryParams.checkoutDate })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.checkoutDate])

  // sync occupancies
  useEffect(() => {
    if (queryParams) {
      const { occupancies } = queryParams
      if (occupancies?.length) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_OCCUPANCIES })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.occupancies])

  // sync search item
  useEffect(() => {
    if (queryParams) {
      const { searchItem } = queryParams
      if (searchItem) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_SEARCH_ITEM })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.searchItem])

  // sync flexible months
  useEffect(() => {
    if (queryParams) {
      const { flexibleMonths } = queryParams
      if (flexibleMonths) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_MONTH_RANGE })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.flexibleMonths])

  // sync flexible nights
  useEffect(() => {
    if (queryParams) {
      const { flexibleNights } = queryParams
      if (flexibleNights) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION, flexibleNights: flexibleNights as FLEXIBLE_DURATION_RANGE })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_DURATION })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.flexibleNights])

  // // sync dateSearchOptionId
  useEffect(() => {
    if (queryParams) {
      const { dateSearchOptionId } = queryParams
      if (dateSearchOptionId) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: dateSearchOptionId })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.dateSearchOptionId])

  // sync userSelectedFlexibleMonths
  useEffect(() => {
    if (queryParams) {
      const { userSelectedFlexibleMonths } = queryParams
      if (userSelectedFlexibleMonths !== undefined) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS, userSelectedFlexibleMonths })
      }
      else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.userSelectedFlexibleMonths])

  // TEMPORARILY COMMENTING OUT UNTIL WE HAVE THE POPULAR DESTINATIONS DATA FOR VILLAS
  // const dispatch = useAppDispatch()

  // const isFetchingFeaturedDestinations = useAppSelector((state) => getIsFetchingFeaturedDestinations(state))
  // const hotelsPopularDestinations = useAppSelector((state) => getHotelsPopularDestinations(state))

  // useEffect(() => {
  //   globalSearchDispatch({ type: GlobalSearchStateActions.UNPOPULATE_POPULAR_DESTINATIONS })
  //   dispatch(fetchFeaturedDestinations())
  // }, [dispatch, globalSearchDispatch])

  useEffect(() => {
    globalSearchDispatch({
      type: GlobalSearchStateActions.POPULATE_POPULAR_DESTINATIONS,
      popularDestinations,
    })
  }, [globalSearchDispatch])

  return {
    globalHomesAndVillasSearchDispatch: globalSearchDispatch,
    globalHomesAndVillasSearchState: globalSearchState,
  }
}

import React from 'react'
import { Route, Switch } from 'react-router'
import NotificationBanner from '../Header/HeaderBanners/NotificationBanner'

const NO_NOTIFICATION_BANNER_ROUTES: Array<string> = [
  '/:regionCode/support',
  '/:regionCode/checkout',
  '/:regionCode/trip-planner/trip/:tripId',
  '/:regionCode/trip-planner/public-trip/:tripId',
  '/:regionCode/trip-planner/curated/:tripId',
]

const HELP_NOTIFICATION_BANNER_ROUTES: Array<string> = [
  '/:regionCode/terms-and-conditions',
  '/:regionCode/account/my-escapes',
  '/:regionCode/account/my-escapes/order/:orderId',
]

function NotificationBannerRoutes() {
  return <Switch>
    <Route path={NO_NOTIFICATION_BANNER_ROUTES} />
    <Route path={HELP_NOTIFICATION_BANNER_ROUTES}>
      <NotificationBanner slug="help-with-banner" />
    </Route>
    <Route component={NotificationBanner} />
  </Switch>
}

export default NotificationBannerRoutes

import clsx from 'clsx'
import { SVG_ICON_SIZE_CSS_VAR, SVG_ICON_SIZES, SvgIconProps } from 'components/Luxkit/SvgIcon'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import LineLuxuryEscapesMonogramIcon from '../line/LineLuxuryEscapesMonogramIcon'

const Logo = styled(LineLuxuryEscapesMonogramIcon)`
  transform: scale(0.66);
`

const Icon = styled.div`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  height: var(${SVG_ICON_SIZE_CSS_VAR}, ${SVG_ICON_SIZES.M});
  width: var(${SVG_ICON_SIZE_CSS_VAR}, ${SVG_ICON_SIZES.M});
  border-radius: 100%;

  &.tier-silver {
    background: ${props => props.theme.palette.product.luxLoyalty.silver.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.contrast};
  }
  &.tier-gold {
    background: ${props => props.theme.palette.product.luxLoyalty.gold.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.contrast};
  }
  &.tier-platinum {
    background: ${props => props.theme.palette.product.luxLoyalty.platinum.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.contrast};
  }
`

interface Props extends Pick<SvgIconProps, 'size' | 'className'> {
  tier: 'silver' | 'gold' | 'platinum'
}

function LuxLoyaltyTierIcon({ tier, size, className }: Props) {
  const mappedSize = typeof size === 'string' ? SVG_ICON_SIZES[size] : typeof size === 'number' ? rem(size) : undefined

  return <Icon
    className={clsx(className, `tier-${tier}`)}
    style={{
      [SVG_ICON_SIZE_CSS_VAR as string]: mappedSize,
    }}>
    <Logo />
  </Icon>
}

export default LuxLoyaltyTierIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidHeartIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22 6.805c-.5-1.9-2.4-3.7-5-3.8-2.5-.1-3.9 1.3-5 2.6-1.1-1.3-2.5-2.7-5-2.6-2.6.1-4.4 1.8-5 3.8-1.1 4.2001 1.5 6.1001 2 6.7001 1.8 1.9 7.2 7.7 7.2 7.7.1.1.2.2.3.2.1.1.3.1.4.1.1 0 .3 0 .4-.1.1-.1.2-.1.3-.2 0 0 5.4-5.8 7.2-7.7.7-.7 3.3-2.5 2.2-6.7Z"/>
  </SvgIcon>
}

export default SolidHeartIcon

import config from 'constants/config'
import { minimumBalances } from 'constants/creditSnackbar'
import { isTooSoonToShowSnackbar } from 'storage/creditsSnackbar'
import { formatDynamicAmount } from 'lib/format/formatCurrencyIntl'
import { contains } from 'lib/string/stringUtils'

export function shouldShowCreditSnackbar(balance: number, currentCurrency: string, pathname: string, userId: string, headless: boolean) {
  return (
    balance >= minimumBalances[currentCurrency] &&
    pathname &&
    !headless &&
    !contains(pathname, '/account/my-escapes/order/') &&
    !contains(pathname, '/booking/confirmation') &&
    !contains(pathname, '/support') &&
    !isTooSoonToShowSnackbar(userId))
}

export function getCreditSnackbar(balance: number, currentCurrency: string, regionCode: string) {
  return {
    heading: `You have ${formatDynamicAmount(balance, currentCurrency, regionCode)} credit to spend!`,
    message: `Use it towards your next ${config.brandConfig.holidaySynonym} before it expires. Apply in checkout.`,
  }
}

import React, { useEffect, useImperativeHandle, useRef } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import useScrollEndsObserver from 'hooks/useScrollEndsObserver'

const FADED_OVERFLOW_WIDTH: number = 48

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  mask-image: linear-gradient(
    to right,
    transparent 0,
    black var(--start-fade-width, 0px),
    black calc(100% - var(--end-fade-width, 0px)),
    transparent 100%
  ); /* NOTE: cannot transition mask-image yet! */
  mask-repeat: no-repeat;
  overflow-x: auto;
  scroll-behavior: smooth;
  isolation: isolate;

  &.has-start-faded {
    --start-fade-width: ${rem(FADED_OVERFLOW_WIDTH)};
  }

  &.has-end-faded {
    --end-fade-width: ${rem(FADED_OVERFLOW_WIDTH)};
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onHasReachedEnd?: (hasReached: boolean) => void
  onHasReachedStart?: (hasReached: boolean) => void
}

const HorizontalContainerWithFadedOverflow = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    className,
    onHasReachedEnd,
    onHasReachedStart,
    ...restOfProps
  } = props

  const containerRef = useRef<HTMLDivElement | null>(null)

  const {
    hasReachedHorizontalEnd,
    hasReachedHorizontalStart,
  } = useScrollEndsObserver(containerRef, children)

  useEffect(() => {
    onHasReachedStart?.(hasReachedHorizontalStart)
  }, [hasReachedHorizontalStart, onHasReachedStart])

  useEffect(() => {
    onHasReachedEnd?.(hasReachedHorizontalEnd)
  }, [hasReachedHorizontalEnd, onHasReachedEnd])

  useImperativeHandle(ref, () => containerRef.current!)

  return <Container
    {...restOfProps}
    ref={containerRef}
    className={cn(className, {
      'has-start-faded': !hasReachedHorizontalStart,
      'has-end-faded': !hasReachedHorizontalEnd,
    })}
  >
    {children}
  </Container>
})

export default HorizontalContainerWithFadedOverflow

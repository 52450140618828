import facilityGroupsMap from './facilityGroupMap'
import formatPropertyAddress from '../lib/formatPropertyAddress'
import getBedGroupsDescriptionsFromRates from '../lib/getBedGroupsDescriptionFromRates'
import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import offerTypeConfig from '../config/offer'
import imageMap from './imageMap'
import config from 'constants/config'
import uuidV4 from 'lib/string/uuidV4Utils'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'

export function bedbankPropertyAddressMap(address: PublicOfferV2.PropertyAddressResponse): App.BedbankPropertyAddress {
  return {
    // @ts-ignore: field might be undefined
    line1: address.line1,
    line2: address.line2,
    // @ts-ignore: field might be undefined
    city: address.city,
    stateProvinceCode: address.stateProvinceCode,
    stateProvinceName: address.stateProvinceName,
    // @ts-ignore: field might be undefined
    postalCode: address.postalCode,
    // @ts-ignore: field might be undefined
    countryCode: address.countryCode,
    obfuscationRequired: address.obfuscationRequired,
    // @ts-ignore: field might be undefined
    countryName: address.countryName,
  }
}

export function bedbankPropertyFinePrintMap(propertyFinePrint: PublicOfferV2.PropertyFinePrint): App.BedbankPropertyFinePrint {
  return {
    checkIn: propertyFinePrint.checkIn,
    checkOut: propertyFinePrint.checkOut,
    fees: propertyFinePrint.fees,
    policies: propertyFinePrint.policies,
    pets: propertyFinePrint.pets,
    safety: propertyFinePrint.safety,
  }
}

export function bedbankPropertyMap(
  serverOffer: PublicOfferV2.BedbankOffer,
): App.BedbankProperty {
  const mapped: App.BedbankProperty = {
    id: serverOffer.id,
    address: bedbankPropertyAddressMap(serverOffer.property.address),
    location: formatPropertyAddress(serverOffer.property.address),
    timezone: serverOffer.property.timezone,
    latitude: serverOffer.property.location.latitude,
    longitude: serverOffer.property.location.longitude,
    finePrint: bedbankPropertyFinePrintMap(serverOffer.propertyFinePrint),
    aboutThisProperty: config.BEDBANK_HIGHLIGHTS_ENABLED ? serverOffer.aboutThisProperty : [],
    phone: serverOffer.property.phone,
    name: serverOffer.name,
    attractionsOverride: serverOffer.copy.attractions,
    finePrintOverride: serverOffer.copy.finePrint,
    highlights: serverOffer.copy.highlights,
    facilitiesOverride: serverOffer.copy.facilities,
    locationTitleOverride: serverOffer.property.locationTitleOverride,
  }

  if (serverOffer.reviewsRating) {
    mapped.rating = {
      score: serverOffer.reviewsRating,
      reviewsTotal: serverOffer.reviewsTotal,
      reviewsSource: serverOffer.reviewsSource,
    }
  }

  return mapped
}

function ageCategoryMap(category: PublicOfferV2.BedbankAgeCategory): App.PackageRoomAgeCategory {
  return {
    name: category.name,
    minimumAge: category.minimumAge,
  }
}

function roomTypeOccupancyMap(roomOccupancy: PublicOfferV2.Occupancy): App.RoomCapacity {
  return {
    adults: roomOccupancy.adults,
    children: roomOccupancy.children,
    infants: roomOccupancy.infants,
  }
}

export function bedbankPackageMap(serverPackage: PublicOfferV2.BedbankPackage): App.BedbankPackage {
  return {
    bedGroupsDescription: serverPackage.rates ? getBedGroupsDescriptionsFromRates(serverPackage.rates) : undefined,
    capacities: {
      ageCategories: serverPackage.capacities.ageCategories.map(ageCategoryMap),
      combinations: serverPackage.capacities.combinations.map(roomTypeOccupancyMap),
    },
    features: serverPackage.features,
    facilityGroups: serverPackage.amenityGroups.map(facilityGroupsMap),
    images: serverPackage.images.map(imageMap) || [],
    name: serverPackage.name,
    description: serverPackage.description,
    roomId: serverPackage.fkRoomTypeId,
    promotionId: serverPackage.promotionId,
    sell: serverPackage.sell ? {
      ...serverPackage.sell,
      discount: 1 - serverPackage.sell.price / serverPackage.sell.value,
    } : undefined,
  }
}

function mapBedbankPromotion(external: Utils.ArrayElement<PublicOfferV2.BedbankOffer['promotions']>): App.BedbankPromotion {
  const mapped: App.BedbankPromotion = {
    finePrint: external.finePrint,
    id: external.id,
    inclusions: external.inclusions.map<App.OfferInclusion>(externalInclusion => ({
      id: uuidV4(),
      description: externalInclusion.text,
      symbol: determineOfferFeatureSymbol(externalInclusion.icon),
      isHighlighted: externalInclusion.isHighlighted,
    })),
    name: external.name,
    periodSchedule: {
      from: external.periodSchedule.from,
      to: external.periodSchedule.to,
    },
    travelPeriod: {
      from: external.travelPeriod.from,
      to: external.travelPeriod.to,
    },
    los: external.los,
    rateInclusionsLong: external.rateInclusionsLong,
    rateInclusionsShort: external.rateInclusionsShort,
    cancellationPolicy: external.cancellationPolicy,
    restrictToRates: external.restrictToRates,
  }

  return mapped
}

export function bedBankOfferMap(
  serverOffer: PublicOfferV2.BedbankOffer,
): App.BedbankOffer {
  const typeConfig = offerTypeConfig[serverOffer.type]
  const property = bedbankPropertyMap(serverOffer)

  const inclusions: Array<App.OfferInclusion> = serverOffer.promotions[0]?.inclusions.map<App.OfferInclusion>((externalInclusion) => {
    return {
      id: uuidV4(),
      description: externalInclusion.text,
      symbol: determineOfferFeatureSymbol(externalInclusion.icon),
      isHighlighted: externalInclusion.isHighlighted,
    }
  })

  return {
    type: typeConfig.type as App.BedbankOfferType,
    parentType: typeConfig.parentType,
    productType: serverOffer.promotions.length ? 'limited_time_special' : typeConfig.productType,
    typeLabel: typeConfig.typeLabel,
    analyticsType: typeConfig.analyticsType as App.BedbankAnalyticsType,
    walledGarden: typeConfig.walledGarden && !serverOffer.promotions.length,
    hidePricing: !!typeConfig.hidePricing,
    popularFacilities: serverOffer.popularFacilities ?? [],
    facilityGroups: serverOffer.amenityGroups,
    id: serverOffer.id,
    logoImageId: serverOffer.logoId,
    // @ts-ignore: when images array is empty, this won't work
    image: serverOffer.images.length && imageMap(serverOffer.images[0]), // This is temporary images will always be available
    images: serverOffer.images.map(imageMap),
    imagesCount: serverOffer.images.length,
    packages: serverOffer.packages.map(bedbankPackageMap),
    name: serverOffer.name,
    slug: serverOffer.slug,
    property,
    saleUnit: 'room',
    saleUnitLong: 'room',
    description: serverOffer.copy.description,
    // @ts-ignore: field might be undefined
    tagline: serverOffer.copy.tagline,
    // @ts-ignore: field might be undefined
    additionalPromoContent: serverOffer.copy.additionalPromoContent,
    metaDescription: serverOffer.copy.metaDescription,
    // @ts-ignore: field might be undefined
    attractions: serverOffer.copy.attractions,
    // @ts-ignore: field might be undefined
    highlights: serverOffer.copy.highlights,
    // @ts-ignore: field might be undefined
    facilities: serverOffer.copy.facilities,
    flights: {
      airportCode: serverOffer.airport,
      flightsEnabled: config.FLIGHT_ENABLED && serverOffer.isFlightEnabled,
      flightPrice: serverOffer.flightPrice,
    },
    promoteAsBundle: serverOffer.promoteAsBundle,
    displayPricingAsPerPerson: serverOffer.displayPricingAsPerPerson,
    amenities: serverOffer.copy.amenities,
    promotions: serverOffer.promotions.map(mapBedbankPromotion),
    sell: serverOffer.sell ? {
      ...serverOffer.sell,
      discount: 1 - serverOffer.sell.price / serverOffer.sell.value,
    } : undefined,
    style: serverOffer.style,
    videos: serverOffer.videos ?? [],
    inclusions,
    luxPlus: {},
  }
}

import { addTotals } from 'checkout/lib/utils/payments/totals'
import { createSelector } from 'reselect'
import { selectEarnableFlightBusinessTravellerCreditsTotal } from '../view/flights'
import { getAccommodationTotals, selectEarnableAccommodationBusinessTravellerCreditsTotal } from './accommodation'
import { getBundleAndSaveTotals } from './bundleAndSave'
import { getCarHireTotals } from './carHire'
import { getExperienceTotals, getTransferTotals } from './experience'
import { getFlightTotals } from './flights'
import { getGiftCardTotals } from './giftCard'
import { getTourExperienceTotals } from './toursv2'
import { getVillaTotals } from './villas'

const getTotalsWithoutInsurance = createSelector(
  getAccommodationTotals,
  getBundleAndSaveTotals,
  getExperienceTotals,
  getTransferTotals,
  getFlightTotals,
  getCarHireTotals,
  getGiftCardTotals,
  getVillaTotals,
  getTourExperienceTotals,
  selectEarnableAccommodationBusinessTravellerCreditsTotal,
  selectEarnableFlightBusinessTravellerCreditsTotal,
  (...itemTotals: Array<App.WithDataStatus<App.Checkout.ItemTotals>>): App.WithDataStatus<App.Checkout.ItemTotals> => {
    return addTotals(itemTotals)
  },
)

export default getTotalsWithoutInsurance

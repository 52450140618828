import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUserCheckIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M13.3 12.22A4.9208 4.9208 0 0 0 15 8.5a5 5 0 0 0-10 0 4.9197 4.9197 0 0 0 1.7 3.72A8.0001 8.0001 0 0 0 2 19.5a1 1 0 1 0 2 0 5.9999 5.9999 0 0 1 10.2426-4.2426A5.9996 5.9996 0 0 1 16 19.5a1.0001 1.0001 0 0 0 2 0 8.0005 8.0005 0 0 0-4.7-7.28ZM10 11.5a2.9996 2.9996 0 0 1-2.7716-1.852A3 3 0 1 1 10 11.5Zm11.71-2.37a1.001 1.001 0 0 0-.71-.2958 1.0003 1.0003 0 0 0-.71.2958l-2 2-.62-.63a.9997.9997 0 0 0-1.42 0 .9998.9998 0 0 0 0 1.41l1.34 1.34a.9998.9998 0 0 0 1.41 0l2.67-2.67a1.0006 1.0006 0 0 0 .3314-.7164.9989.9989 0 0 0-.2914-.7336Z"/>
  </SvgIcon>
}

export default LineUserCheckIcon

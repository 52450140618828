import { AppAction } from 'actions/ActionTypes'
import { API_CALL } from 'actions/actionConstants'
import { BUSINESS_TRAVELLER_API_ACTIONS } from 'reducers/businessTravellerApiActionReducers'
import requestGetEmployees from 'businessTraveller/api/requestGetEmployees'
import { arrayToObject, isEmpty } from 'lib/array/arrayUtils'
import restoreSelectedBusinessTravellerEmployeeIds from './restoreSelectedBusinessTravellerEmployeeIds'

function fetchBusinessEmployees(businessId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const employees = state.businessTraveller.employees.all

    dispatch(restoreSelectedBusinessTravellerEmployeeIds())

    if (isEmpty(employees)) {
      dispatch({
        type: API_CALL,
        api: BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_EMPLOYEES,
        request: () => requestGetEmployees({ businessId }).then(res => {
          return arrayToObject(res, (employee) => employee.id)
        }),
      })
    }
  }
}

export default fetchBusinessEmployees

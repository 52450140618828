export default function luxPlusInclusionFilter(
  pkgLuxPlusInclusions: Array<App.PackageInclusion>,
  commonAndBonusInclusions: Array<App.PackageInclusion>,
): Array<App.PackageInclusion> {
  /*
      If LuxPLus+ inclusions already exist as common or bonus inclusions for regular customers (e.g. daily breakfast, etc),
      we want to allow them to keep those inclusions.

      As a result we prevent duplication in the UI by filtering out those inclusions by comparing the inclusion type.
  */
  const commonAndBonusInclusionsByType = new Set(commonAndBonusInclusions.map(incl => incl.type).filter(Boolean))

  if (!commonAndBonusInclusionsByType.size) return pkgLuxPlusInclusions

  return pkgLuxPlusInclusions.filter((luxPlusInclusion) => !commonAndBonusInclusionsByType.has(luxPlusInclusion.type ?? ''))
}

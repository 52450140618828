import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHappyHourIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3 2c-.5523 0-1 .4477-1 1v5c0 3.5265 2.6077 6.4439 6 6.9291V20H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h8c.5523 0 1-.4477 1-1s-.4477-1-1-1h-3v-5.071a6.9346 6.9346 0 0 0 .4351-.0765c.5407-.1127.8876-.6424.7748-1.183-.1127-.5407-.6424-.8876-1.183-.7748A5.0254 5.0254 0 0 1 9.0018 13H9c-2.7614 0-5-2.2386-5-5h3.75c.5523 0 1-.4477 1-1s-.4477-1-1-1H4V4h5.25c.5523 0 1-.4477 1-1s-.4477-1-1-1H3ZM16.25 5c.5523 0 1 .4477 1 1v1.3358l.4571.457c.3905.3906.3905 1.0238 0 1.4143-.3905.3905-1.0237.3905-1.4142 0L15.25 8.1642V6c0-.5523.4477-1 1-1Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M16.25 13.5c3.1756 0 5.75-2.5744 5.75-5.75S19.4256 2 16.25 2 10.5 4.5744 10.5 7.75s2.5744 5.75 5.75 5.75Zm0-2c2.0711 0 3.75-1.679 3.75-3.75C20 5.679 18.3211 4 16.25 4S12.5 5.679 12.5 7.75c0 2.071 1.6789 3.75 3.75 3.75Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineHappyHourIcon

import { getListName } from 'analytics/enhanced-ecommerce'
import * as Analytics from 'analytics/analytics'
import {
  impressionEventWithContext,
  searchEvent, productClickEventWithContext,
  destinationImpressionEventWithContext,
  destinationClickEventWithContext,
  SnowplowDestinationContext,
} from 'analytics/snowplow/events'
import {
  AdditionalData, OfferListEventHandler,
  OfferListEvents,
  OfferListEventsDispatchAction,
} from 'components/OfferList/OfferListEventsContext'
import {
  OFFER_TYPE_EXPERIENCE,
  OFFER_TYPE_HOTEL,
  PRODUCT_TYPE_TOUR,
  PRODUCT_TYPE_ULTRALUX,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_VILLA,
  PRODUCT_TYPE_BEST_VALUE_HOTEL,
} from 'constants/offer'
import uuidV4 from 'lib/string/uuidV4Utils'
import { useCallback, useMemo } from 'react'
import { SearchEventData } from 'contexts/GlobalSearch/GlobalSearchTracking'

type HomepageListType =
  'tour' |
  'hotel' |
  'best_value_hotel' |
  'ultralux_hotel' |
  'hero' |
  'trending' |
  'experience' |
  'tpfm' |
  'cruise' |
  'trip'|
  'rental' |
  'flights' |
  'luxplus'

export enum EventDataKey {
  ClientAction = 'client-action',
  ProductClick = 'product-click',
  ExperienceClick = 'experience-click',
  ProductImpression = 'product-impression',
  ExperienceImpression = 'experience-impression',
  OfferHover = 'offer-hover',
  FlightClick = 'flight-click',
  FlightImpression = 'flight-impression',
  DestinationImpression = 'destination-impression',
  DestinationClick = 'destination-click',
}

type ClientActionEventData = {
  key: EventDataKey.ClientAction;
  subject: string;
  action: string;
}

type ProductClickEventData = {
  key: EventDataKey.ProductClick;
  offer: App.AnyOffer;
  position: number;
}

type ExperienceClickEventData = {
 key: EventDataKey.ExperienceClick;
 experience: App.ExperienceOffer;
 position: number;
}

type ProductImpressionEventData = {
  key: EventDataKey.ProductImpression;
  offer: App.AnyOffer;
  position: number;
}

type ExperienceImpressionEventData = {
  key: EventDataKey.ExperienceImpression;
  experience: App.ExperienceOffer;
  position: number;
}

type FlightImpressionEventData = {
  key: EventDataKey.FlightImpression;
  offer: App.FlightDeal | App.JourneyV2;
  position: number;
}

type FlightClickEventData = {
  key: EventDataKey.FlightClick;
  offer: App.FlightDeal | App.JourneyV2;
  position: number;
}

type DestinationImpressionData = {
  key: EventDataKey.DestinationImpression;
  destination: SnowplowDestinationContext;
  position: number;
  filter?: string;
}

type DestinationClickData = {
  key: EventDataKey.DestinationClick;
  destination: SnowplowDestinationContext;
  position: number;
  filter?: string;
}

type ClientActionEvent = {
  action: OfferListEvents.clientAction;
  data: ClientActionEventData;
}

type ProductClickEvent = {
  action: OfferListEvents.productClick;
  data: ProductClickEventData;
}

type ExperienceClickEvent = {
  action: OfferListEvents.productClick;
  data: ExperienceClickEventData;
}

type ProductImpressionEvent = {
  action: OfferListEvents.productImpression;
  data: ProductImpressionEventData;
}

type ExperienceImpressionEvent = {
  action: OfferListEvents.productImpression;
  data: ExperienceImpressionEventData;
}

type FlightImpressionEvent = {
  action: OfferListEvents.productImpression;
  data: FlightImpressionEventData;
}

type FlightClickEvent = {
  action: OfferListEvents.productClick;
  data: FlightClickEventData;
}

type DestinationImpressionEvent = {
  action: OfferListEvents.destinationImpression;
  data: DestinationImpressionData;
}

type DestinationClickEvent = {
  action: OfferListEvents.destinationClick;
  data: DestinationClickData;
}

type Event = ClientActionEvent |
  ProductClickEvent |
  ExperienceClickEvent |
  ProductImpressionEvent |
  ExperienceImpressionEvent |
  FlightImpressionEvent |
  FlightClickEvent |
  DestinationImpressionEvent |
  DestinationClickEvent
export type Data = ClientActionEventData |
  ProductClickEventData |
  ExperienceClickEventData |
  ProductImpressionEventData |
  ExperienceImpressionEventData |
  FlightImpressionEventData |
  FlightClickEventData |
  DestinationImpressionData |
  DestinationClickData

/**
  * @remarks
  * We check the key of the data param in order to identify the typescript type of data, as the type Data is a union type so we need to narrow this down
  */
function buildEvent(dispatchAction: OfferListEventsDispatchAction): Event | null {
  if (dispatchAction.type === OfferListEvents.productClick && dispatchAction.key === EventDataKey.ProductClick) {
    return { action: dispatchAction.type, data: { key: dispatchAction.key, offer: dispatchAction.offer as App.AnyOffer, position: dispatchAction.position } }
  } else if (dispatchAction.type === OfferListEvents.productClick && dispatchAction.key === EventDataKey.ExperienceClick) {
    return { action: dispatchAction.type, data: { key: dispatchAction.key, experience: dispatchAction.offer as App.ExperienceOffer, position: dispatchAction.position } }
  } else if (dispatchAction.type === OfferListEvents.clientAction && dispatchAction.key === EventDataKey.ClientAction) {
    return { action: dispatchAction.type, data: { key: dispatchAction.key, subject: dispatchAction.subject, action: dispatchAction.action } }
  } else if (dispatchAction.type === OfferListEvents.productImpression && dispatchAction.key === EventDataKey.ProductImpression) {
    return { action: dispatchAction.type, data: { key: dispatchAction.key, offer: dispatchAction.offer as App.AnyOffer, position: dispatchAction.position } }
  } else if (dispatchAction.type === OfferListEvents.productImpression && dispatchAction.key === EventDataKey.ExperienceImpression) {
    return { action: dispatchAction.type, data: { key: dispatchAction.key, experience: dispatchAction.offer as App.ExperienceOffer, position: dispatchAction.position } }
  } else if (dispatchAction.type === OfferListEvents.productImpression && dispatchAction.key === EventDataKey.FlightImpression) {
    return { action: dispatchAction.type, data: { key: dispatchAction.key, offer: dispatchAction.offer as App.FlightDeal | App.JourneyV2, position: dispatchAction.position } }
  } else if (dispatchAction.type === OfferListEvents.productClick && dispatchAction.key === EventDataKey.FlightClick) {
    return { action: dispatchAction.type, data: { key: dispatchAction.key, offer: dispatchAction.offer as App.FlightDeal | App.JourneyV2, position: dispatchAction.position } }
  } else if (dispatchAction.type === OfferListEvents.destinationImpression && dispatchAction.key === EventDataKey.DestinationImpression) {
    const { type: action, ...data } = dispatchAction
    return { action, data }
  } else if (dispatchAction.type === OfferListEvents.destinationClick && dispatchAction.key === EventDataKey.DestinationClick) {
    const { type: action, ...data } = dispatchAction
    return { action, data }
  }
  return null
}

function useHomepageAnalytics() {
  const version = 'homepage'
  const listParams = useMemo(() => {
    const lp: Partial<Record<HomepageListType, App.OfferListTrackingConfig>> = {
      [OFFER_TYPE_VILLA]: { listId: uuidV4(), listName: getListName('OfferList', version, 'villa-carousel') },
      [OFFER_TYPE_CRUISE]: { listId: uuidV4(), listName: getListName('OfferList', version, 'cruise-carousel') },
      [PRODUCT_TYPE_TOUR]: { listId: uuidV4(), listName: getListName('OfferList', version, 'tour-carousel') },
      [OFFER_TYPE_HOTEL]: { listId: uuidV4(), listName: getListName('OfferList', version, 'hotel-carousel') },
      [PRODUCT_TYPE_ULTRALUX]: { listId: uuidV4(), listName: getListName('OfferList', version, 'ultralux-carousel') },
      [OFFER_TYPE_EXPERIENCE]: { listId: uuidV4(), listName: getListName('OfferList', version, 'experience-carousel') },
      [PRODUCT_TYPE_BEST_VALUE_HOTEL]: { listId: uuidV4(), listName: getListName('OfferList', version, 'best-value-hotel-carousel') },
      tpfm: { listId: uuidV4(), listName: getListName('OfferList', version, 'tpfm-carousel') },
      hero: { listId: uuidV4(), listName: getListName('OfferList', version, 'hero-carousel') },
      trending: { listId: uuidV4(), listName: `${version}-trending-destinations` },
      trip: { listId: uuidV4(), listName: getListName('OfferList', version, 'trip-carousel') },
      flights: { listId: uuidV4(), listName: getListName('OfferList', version, 'flights-carousel') },
    }
    return lp
  }, [version])

  const onListEvent = useCallback((productType: HomepageListType, event: Event, additionalData?: AdditionalData) => {
    const params = listParams[productType]
    const { action, data } = event

    if (data.key === EventDataKey.ProductClick || data.key === EventDataKey.ExperienceClick) {
      Analytics.trackClientEvent({
        subject: 'click-homepage-tile',
        action: 'clicked',
        category: 'logging',
        type: 'operational',
        optimizelyEventId: '25748770730',
        optimizelyEventKey: 'click-homepage-tile',
      })

      Analytics.trackClientEvent({
        subject: 'click-any-offer',
        action: 'clicked',
        category: 'logging',
        type: 'operational',
        optimizelyEventId: '28377200726',
        optimizelyEventKey: 'click-any-offer',
      })
    }

    if (params) {
      // add the version into the source field and we can know which model appied
      params.listSource = additionalData?.listSource

      if (action === OfferListEvents.productClick && data.key === EventDataKey.ProductClick) {
        Analytics.trackEvent(productClickEventWithContext(data.position + 1, params.listName, params.listId, data.offer))
      } else if (action === OfferListEvents.productClick && data.key === EventDataKey.ExperienceClick) {
        Analytics.trackEvent(productClickEventWithContext(data.position + 1, params.listName, params.listId, data.experience))
      } else if (action === OfferListEvents.productClick && data.key === EventDataKey.FlightClick) {
        Analytics.trackEvent(productClickEventWithContext(data.position + 1, params.listName, params.listId, data.offer))
      } else if (action === OfferListEvents.clientAction) {
        Analytics.trackClientEvent({
          subject: data.subject,
          action: data.action,
          category: params.listName,
          type: 'interaction',
        })
      } else if (action === OfferListEvents.productImpression && data.key === EventDataKey.ProductImpression) {
        Analytics.trackEvent(impressionEventWithContext(data.position + 1, params.listName, params.listId, data.offer))
      } else if (action === OfferListEvents.productImpression && data.key === EventDataKey.ExperienceImpression) {
        Analytics.trackEvent(impressionEventWithContext(data.position + 1, params.listName, params.listId, data.experience))
      } else if (action === OfferListEvents.productImpression && data.key === EventDataKey.FlightImpression) {
        Analytics.trackEvent(impressionEventWithContext(data.position + 1, params.listName, params.listId, data.offer))
      } else if (action === OfferListEvents.destinationImpression && data.key === EventDataKey.DestinationImpression) {
        Analytics.trackEvent(destinationImpressionEventWithContext(data.position + 1, params.listName, params.listId, data.destination, data.filter))
      } else if (action === OfferListEvents.destinationClick && data.key === EventDataKey.DestinationClick) {
        Analytics.trackEvent(destinationClickEventWithContext(data.position + 1, params.listName, params.listId, data.destination, data.filter))
      }
    }
  }, [listParams])

  const onTourListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent(PRODUCT_TYPE_TOUR, event)
    }
  }, [onListEvent])

  const onCruiseListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) { onListEvent(OFFER_TYPE_CRUISE, event) }
  }, [onListEvent])

  const onHotelListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent(OFFER_TYPE_HOTEL, event)
    }
  }, [onListEvent])

  const onBestValueHotelListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent('best_value_hotel', event)
    }
  }, [onListEvent])

  const onUltraLuxListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent(PRODUCT_TYPE_ULTRALUX, event)
    }
  }, [onListEvent])

  const onHeroListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent('hero', event)

      if (dispatchAction.type === OfferListEvents.productClick && dispatchAction.key === EventDataKey.ProductClick) {
        Analytics.trackClientEvent({
          subject: 'hero-carousel-offer-clicked',
          action: 'clicked',
          category: 'logging',
          type: 'operational',
          optimizelyEventId: '28571980024',
          optimizelyEventKey: 'hero-carousel-offer-clicked',
        })
      }
    }
  }, [onListEvent])

  const onSearchEvent = useCallback((subject: string, data: SearchEventData) => {
    Analytics.trackEvent(searchEvent(subject, version, data))
  }, [version])

  const onTrendingDestinationsListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent('trending', event)
    }
  }, [onListEvent])

  const onExperienceListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent(OFFER_TYPE_EXPERIENCE, event)
    }
  }, [onListEvent])

  const onTPFMListEvent = useCallback<OfferListEventHandler>((dispatchAction, additionalData) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent('tpfm', event, additionalData)
    }
  }, [onListEvent])

  const onTripOfferListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent('trip', event)
    }
  }, [onListEvent])

  const onFlightListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent('flights', event)
    }
  }, [onListEvent])

  const onLuxPlusListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    const event = buildEvent(dispatchAction)
    if (event) {
      onListEvent('luxplus', event)
    }
  }, [onListEvent])

  return {
    onTourListEvent,
    onCruiseListEvent,
    onHotelListEvent,
    onBestValueHotelListEvent,
    onUltraLuxListEvent,
    onHeroListEvent,
    onSearchEvent,
    onTrendingDestinationsListEvent,
    onExperienceListEvent,
    onTPFMListEvent,
    onTripOfferListEvent,
    onFlightListEvent,
    onLuxPlusListEvent,
  }
}

export default useHomepageAnalytics

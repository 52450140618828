import cn from 'clsx'
import React, { PropsWithChildren, forwardRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { DropdownBehaviourDispatchContext, DropdownBehaviourStateContext } from './DropdownBehaviourContexts'
import { DropdownBehaviourDispatchActions } from './DropdownBehaviourState'

const DropdownHeaderContainer = styled.div`
  grid-area: dropdown-header;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: box-shadow 200ms ease;
  z-index: 2;

  &.has-shadow {
    box-shadow: ${props => props.theme.shadow.bottom.small};
  }
`

interface Props extends PropsWithChildren {
  className?: string
}

const _DropdownHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, className } = props

  const dropdownContext = useContext(DropdownBehaviourStateContext)
  const dropdownDispatch = useContext(DropdownBehaviourDispatchContext)

  useEffect(() => {
    dropdownDispatch({
      type: DropdownBehaviourDispatchActions.TOGGLE_HEADER,
      hasHeader: true,
    })

    return () => {
      dropdownDispatch({
        type: DropdownBehaviourDispatchActions.TOGGLE_HEADER,
        hasHeader: false,
      })
    }
  }, [dropdownDispatch])

  return <DropdownHeaderContainer
    ref={ref}
    className={cn(className, {
      'has-shadow': !dropdownContext.bodyHasReachedStart,
    })}
  >
    {children}
  </DropdownHeaderContainer>
})

export default _DropdownHeader

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFamilyIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M15.0237 12A6 6 0 0 1 21 18a1.0001 1.0001 0 0 0 2 0c0-3.2388-1.9246-6.028-4.6926-7.2864A4.9205 4.9205 0 0 0 20 7a5 5 0 0 0-10 0 4.9203 4.9203 0 0 0 1.6928 3.7137 7.9648 7.9648 0 0 0-.5427.2721c-.4839.2661-.6605.8742-.3944 1.3581.2661.4839.8742.6605 1.3581.3944C12.9692 12.2679 13.9518 12 15 12h.0237Zm-1.6904-2.5056a3 3 0 1 0 3.3334-4.9888 3 3 0 0 0-3.3334 4.9888ZM8.7998 16.8568C9.5405 16.1308 10 15.1191 10 14c0-2.2091-1.7909-4-4-4-2.2091 0-4 1.7909-4 4 0 1.1191.4595 2.1308 1.2002 2.8568C1.8726 17.7557 1 19.2759 1 21c0 .5523.4477 1 1 1s1-.4477 1-1c0-1.6569 1.3431-3 3-3s3 1.3431 3 3c0 .5523.4477 1 1 1s1-.4477 1-1c0-1.7241-.8726-3.2443-2.2002-4.1432ZM4 14c0-1.1046.8954-2 2-2s2 .8954 2 2-.8954 2-2 2-2-.8954-2-2Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineFamilyIcon

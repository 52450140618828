import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAngleDownIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.9999 9.17a.9999.9999 0 0 0-1.41 0l-3.59 3.5399-3.54-3.54a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l4.24 4.24a.9989.9989 0 0 0 1.0946.2189.9966.9966 0 0 0 .3254-.2189l4.29-4.24a.999.999 0 0 0 .2189-1.0945.998.998 0 0 0-.2189-.3255Z"/>
  </SvgIcon>
}

export default LineAngleDownIcon

import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_EXPERIENCE,
  OFFER_TYPE_HOTEL,
  PRODUCT_TYPE_FLIGHT,
  PRODUCT_TYPE_LUX_PLUS,
} from 'constants/offer'
import getObjectKey from 'lib/object/getObjectKey'

export type KeyOptions = App.LuxLoyaltyPointsCalculatorOptions & {
  /**
   * Logged out the tier to calculate points is the default tier but logged in the member tier is used
   * in the svc
   */
  memberId?: string;
}

export function isHotelCalculationWithAdditionalOptions(
  options: App.LuxLoyaltyPointsCalculatorOptions,
): options is App.LuxLoyaltyHotelPointsCalculatorAdditionalOptions {
  return options.productType === OFFER_TYPE_HOTEL || options.productType === OFFER_TYPE_ALWAYS_ON
}

function buildHotelAdditionalOptionsPointsKey(options: App.LuxLoyaltyHotelPointsCalculatorAdditionalOptions): string {
  return getObjectKey(options)
}

function buildDefaultPointsKey(options: App.LuxLoyaltyPointsCalculatorBaseOptions): string {
  return getObjectKey(options)
}

/**
 * @returns formatted points key e.g. "price:100;productType:hotel;offerId:123;packageId:456"
 */
export function buildPointsKey(options: KeyOptions): string {
  if (isHotelCalculationWithAdditionalOptions(options)) {
    return buildHotelAdditionalOptionsPointsKey(options)
  }

  // ... add other verticals as needed

  return buildDefaultPointsKey(options)
}

export function validateCalculatorOptions(options: App.LuxLoyaltyPointsCalculatorOptions): boolean {
  if (
    !Number.isFinite(options.price) ||
    !options.productType ||
    !Number.isFinite(options.margin) ||
    (isHotelCalculationWithAdditionalOptions(options) && (!options.offerId || !options.packageId))
  ) {
    return false
  }

  return true
}

const luxLoyaltyProductTypes: Array<App.LuxLoyaltyProductType> = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_EXPERIENCE,
  PRODUCT_TYPE_LUX_PLUS,
  PRODUCT_TYPE_FLIGHT,
]

export function getOfferLuxLoyaltyProductType(offerType?: App.OfferType): App.LuxLoyaltyProductType | undefined {
  return luxLoyaltyProductTypes.find((type) => type === offerType)
}

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'
import ModalContext from 'contexts/ModalContext'
import MasterModalContext from 'contexts/MasterModalContext'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { fetchTravelPreferencesModalDismissed } from 'actions/LESubscriptionsActions'
import TravelPreferencesModal from 'components/Pages/AccountTravelPreferencesPage/TravelPreferencesModal'
import { hasUserPreferences } from 'selectors/accountSelectors'
import {
  fetchUserPreferenceDefaultDestinations,
  fetchUserPreferenceDefaultExternalBrands,
  fetchUserPreferenceDefaultHolidayTypes,
  fetchUserPreferenceDefaultOccupancy,
  fetchUserPreferenceDestinations,
  fetchUserPreferenceExternalBrands,
  fetchUserPreferenceHolidayTypes,
  fetchUserPreferenceOccupancy,
} from 'actions/userTravelPreferencesActions'
import config from 'constants/config'

const TravelPreferencesModalExcludedRoutes = [
  '/:regionCode/account',
  '/:regionCode/checkout',
  '/:regionCode/checkout-restore-cart',
  '/:regionCode/checkout-gateway',
]

const MODAL_OPENED_TIMEOUT_IN_SECONDS = 10

function useShowTravelPreferencesModal() {
  const [modalRecentlyOpened, setModalRecentlyOpened] = useState<boolean>(false)
  const [loggedInPagesViewed, setLoggedInPagesViewed] = useState<Array<string>>([])
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const modalState = useContext(MasterModalContext)

  const isTravelPreferencesModalEnabled = !!useOptimizelyExperiment(OptimizelyFeatureFlags.croTravelPreferencesModalEnabled)

  const userId = useAppSelector(state => state.auth.account.memberId)
  const hasPreferences = useAppSelector(state => hasUserPreferences(state))
  const fetchingDismissed = useAppSelector(state => state.leSubscriptions.fetchingTravelPreferencesDismissed)
  const hasDismissedModal = useAppSelector(state => state.leSubscriptions.hasDismissedTravelPreferencesModal)

  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isInvalidRoute = useRouteMatch({ path: TravelPreferencesModalExcludedRoutes, exact: false })
  const hasViewedThreePages = useMemo(() => !isInvalidRoute && loggedInPagesViewed.length >= 3, [isInvalidRoute, loggedInPagesViewed])

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserPreferenceDefaultDestinations(userId))
      dispatch(fetchUserPreferenceDestinations(userId))
      dispatch(fetchUserPreferenceDefaultHolidayTypes(userId))
      dispatch(fetchUserPreferenceHolidayTypes(userId))
      dispatch(fetchUserPreferenceDefaultExternalBrands(userId))
      dispatch(fetchUserPreferenceExternalBrands(userId))
      dispatch(fetchUserPreferenceDefaultOccupancy(userId))
      dispatch(fetchUserPreferenceOccupancy(userId))
      dispatch(fetchTravelPreferencesModalDismissed())
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userId && currentPath) {
      setLoggedInPagesViewed([...loggedInPagesViewed, currentPath])
    } else if (!userId && loggedInPagesViewed.length) {
      setLoggedInPagesViewed([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, currentPath])

  // Prevent to open over other modals
  useEffect(() => {
    let openOtherModalTimeout: number

    if (modalState.open) {
      setModalRecentlyOpened(true)
    }

    if (!modalState.open && modalRecentlyOpened) {
      openOtherModalTimeout = window.setTimeout(() => {
        setModalRecentlyOpened(false)
      }, MODAL_OPENED_TIMEOUT_IN_SECONDS * 1000)
    }

    return () => {
      window.clearTimeout(openOtherModalTimeout)
    }
  }, [modalState.open, modalRecentlyOpened])

  const hasDismissed = !fetchingDismissed && hasDismissedModal
  const isModalEnabled = isTravelPreferencesModalEnabled && config.USER_PREFERENCES_MODAL_ENABLED
  const triggerDisplay = isModalEnabled && !modalRecentlyOpened && !modalState.open && hasViewedThreePages

  useEffect(() => {
    if (
      !hasDismissed &&
      !hasPreferences &&
      userId &&
      triggerDisplay
    ) {
      showModal(<TravelPreferencesModal />)
    }
  }, [showModal, triggerDisplay, hasPreferences, hasDismissed, userId])
}

export default useShowTravelPreferencesModal

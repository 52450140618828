import { SherLogActions } from 'actions/SherLogActions'
import { LOG_NETWORK_REQUEST, LOG_NETWORK_RESPONSE } from 'actions/actionConstants'
import { createReducer } from 'lib/redux/reducerUtils'
import uuidV4 from '../lib/string/uuidV4Utils'

export const initialSherLogState: App.SherLogState = {
  traceId: uuidV4(),
  logData: [],
}

const trimList = <T>(list: Array<T>, limit: number): Array<T> => {
  return list.length > limit ? list.slice(limit) : list
}

const sherlogReducer = createReducer<App.SherLogState, SherLogActions>(initialSherLogState, {

  [LOG_NETWORK_REQUEST]: (state, action) => ({
    ...state,
    logData: trimList([...state.logData, action.request], 100),
  }),
  [LOG_NETWORK_RESPONSE]: (state, action) => {
    const logData = state.logData.map((log) => {
      if (log.requestName === action.response.requestName) {
        return {
          ...log,
          responseBody: action.response.responseBody,
          status: action.response.status,
        }
      }
      return log
    })

    return {
      ...state,
      logData: trimList(logData, 100),
    }
  },
})

export default sherlogReducer

import React, { useCallback, useContext, useRef } from 'react'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import TextButton from 'components/Luxkit/Button/TextButton'
import useToggle from 'hooks/useToggle'
import BodyText from 'components/Luxkit/Typography/BodyText'
import HeaderContactOptionsModal from 'components/App/Header/HeaderContactOptions/HeaderContactOptionsModal'
import NavigationHeaderContactDropdown from 'components/App/Header/HeaderMain/NavigationHeader/NavigationHeaderContactDropdown'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import GeoContext from 'contexts/geoContext'
import Group from 'components/utils/Group'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { useIsMobileScreen } from 'hooks/useScreenSize'
import ModalContext from 'contexts/ModalContext'
import ContactContext from 'contexts/contactContext'
import { connect } from 'react-redux'
import { getUpcomingOrders } from 'selectors/orderSelectors'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'
import LineCommentsIcon from 'components/Luxkit/Icons/line/LineCommentsIcon'
import TextLink from 'components/Luxkit/TextLink'
import styled from 'styled-components'
import { rem } from 'polished'
import { NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'

const homepageRegex = /^\/[a-z]{2}$/

const LesaLinkGroup = styled(Group)`
  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
`

interface MappedProps {
  hasUpcomingOrder: boolean
  supportAssistantEnabled: boolean
  path: string
}

function ContactOptions(props: MappedProps) {
  const { hasUpcomingOrder, supportAssistantEnabled, path } = props
  const { value: isDropdownOpen, toggle: toggleDropdown, off: closeDropdown } = useToggle(false)
  const triggerRef = useRef(null)
  const { currentRegionCode } = useContext(GeoContext)
  const isMobile = useIsMobileScreen()
  const showModal = useContext(ModalContext)
  const currentContact = useContext(ContactContext)

  const showLESA = hasUpcomingOrder && supportAssistantEnabled && homepageRegex.test(path)
  const defaultContact = currentContact.defaultContact

  const toggleOpen = useCallback(() => {
    if (isMobile) {
      showModal(<HeaderContactOptionsModal
        supportAssistantFirst={showLESA}
      />)
    } else {
      toggleDropdown()
    }
  }, [isMobile, showLESA, showModal, toggleDropdown])

  const contactTagLine = NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode) ? 'Call us 7 days' : 'Call us 24 hours, 7 days'

  return <Group direction="horizontal" verticalAlign="center" gap={4}>
    {!showLESA && <>
      <BodyText variant="small" wrap="no-wrap">{contactTagLine}</BodyText>
      <TextButton
        ref={triggerRef}
        kind="tertiary"
        onClick={toggleOpen}
        aria-expanded={isDropdownOpen}
        aria-label={contactTagLine + 'contact us'}
        size="small"
        startIcon={<LinePhoneIcon />}
        endIcon={<DropdownChevron open={isDropdownOpen}/>}
      >
        {defaultContact.humanReadable}
      </TextButton>
      <CSSBreakpoint min="tablet">
        <NavigationHeaderContactDropdown
          anchorRef={triggerRef}
          open={isDropdownOpen}
          onClose={closeDropdown}
          onItemClick={toggleOpen}
        />
      </CSSBreakpoint></>}
    {showLESA && <LesaLinkGroup direction="horizontal" gap={12}>
      <BodyText variant="small">Need help with your booking?</BodyText>
      <TextLink
        to="/support"
        aria-expanded={isDropdownOpen}
        aria-label={contactTagLine + 'contact us'}
        size="small"
        startIcon={<LineCommentsIcon />}
      >
        Start a chat 24/7
      </TextLink>
    </LesaLinkGroup>}
  </Group>
}

export default connect((state: App.State) => ({
  hasUpcomingOrder: !!getUpcomingOrders(state).length,
  supportAssistantEnabled: isSupportAssistantEnabled(state),
  path: state.router.location.pathname,
}))(ContactOptions)

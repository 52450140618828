import React, { useCallback, useContext } from 'react'
import SearchTileStackInclusionsList from '../shared/SearchTileStackInclusionsList'
import ModalContext from 'contexts/ModalContext'
import Modal from 'components/Luxkit/Modal/Modal'
import OfferInclusionsList from 'components/Common/Inclusions/OfferInclusionsList'
import useOfferUrl from 'hooks/Offers/useOfferUrl'

interface Props {
  offer: App.BundleOffer
  offerMetaData?: App.OfferListMetaData
  filters?: App.OfferListFilters

}

function HotelBundleSearchTileFeatures({ offer, offerMetaData, filters }: Props) {
  const offerUrl = useOfferUrl(offer, {
    filters,
    bundledOfferId: offerMetaData?.bundledOfferId,
    offerLinkIncludesFilters: true,
  })

  const showModal = useContext(ModalContext)
  const openInclusionsModal = useCallback(() => {
    showModal(<Modal
      title=""
      primaryActionText="View offer"
      primaryActionTo={offerUrl}
    >
      <OfferInclusionsList inclusions={offer.tileInclusions!} />
    </Modal>)
  }, [offer, offerUrl, showModal])

  if (!offer.tileInclusions?.length) return null

  return <SearchTileStackInclusionsList
    inclusions={offer.tileInclusions}
    inclusionsTitle={offer.tileInclusionsHeading}
    onShowMoreClick={openInclusionsModal}
  />
}

export default HotelBundleSearchTileFeatures

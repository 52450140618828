import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineClochIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M10.7808 4a2 2 0 0 0-1.9403 1.515l-.5733 2.2932C5.1597 9.2265 3 12.3611 3 16v2c-.5523 0-1 .4477-1 1s.4477 1 1 1h18c.5523 0 1-.4477 1-1s-.4477-1-1-1v-2c0-3.6389-2.1597-6.7735-5.2672-8.1918l-.5733-2.2933A2 2 0 0 0 13.2192 4h-2.4384Zm2.7195 3.1245L13.2192 6h-2.4384l-.2811 1.1245A9.0597 9.0597 0 0 1 12 7c.5112 0 1.0124.0426 1.5003.1245ZM12 9c-3.866 0-7 3.134-7 7v2h14v-2c0-3.866-3.134-7-7-7Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineClochIcon

// https://github.com/burntcustard/scrollbar-css-var
if (typeof window !== 'undefined') {
  window.addEventListener('load', () => {
    function getScrollbarWidth() {
      const outer = document.createElement('div')
      outer.style.visibility = 'hidden'
      outer.style.width = '100px'
      document.body.append(outer)

      const widthNoScroll = outer.offsetWidth

      // Force scrollbars
      outer.style.overflow = 'scroll'

      // Add innerdiv
      const inner = document.createElement('div')
      inner.style.width = '100%'
      outer.append(inner)

      const widthWithScroll = inner.offsetWidth

      // Remove divs
      outer.remove()

      return widthNoScroll - widthWithScroll
    }

    function setScrollbarVar(value) {
      document.body.style.setProperty('--scrollbar-width', value + 'px')
    }

    function addScrollObserver() {
      const config = {
        threshold: 1.0,
      }

      const handleIntersect = (entries) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio < 1) {
            setScrollbarVar(getScrollbarWidth())
          } else {
            setScrollbarVar(0)
          }
        })
      }

      const bodyScrollObserver = new IntersectionObserver(handleIntersect, config)

      bodyScrollObserver.observe(document.body)
    }

    addScrollObserver()
  })
}

import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import AirportSelectDropdown from './AirportSelectDropdown'
import SearchFormTextField from 'components/SearchV2/Components/SearchFormField/SearchFormTextField'
import noop from 'lib/function/noop'
import useAirportSearch from 'hooks/Flights/useAirportSearch'
import useToggle from 'hooks/useToggle'
import usePopularAirports from 'hooks/Flights/usePopularAirports'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import { getAirportDisplayText } from 'lib/flights/flightUtils'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import styled from 'styled-components'

const TextFieldContainer = styled.div`
  position: relative;
`

interface Props {
  label?: string;
  className?: string;
  required?: boolean;
  onChange?: (text: string) => void;
  onSelectAirport?: (airport: App.AirportLocation) => void;
  open?: boolean;
  airport?: App.AirportLocation;
  alternateAirport?: App.AirportLocation;
  requiredErrorMessage?: string;
  placeholder?: string;
  defaultSearchPhrase?: string;
  fallbackAirports?: Array<App.AirportLocation>;
  fallbackHeading?: string;
  direction: 'departure' | 'arrival';
  readOnly?: boolean;
}

const FlightSearchAirportSelect = forwardRef(function(props: Props, ref) {
  const {
    label,
    className,
    required,
    onSelectAirport = noop,
    onChange = noop,
    airport,
    alternateAirport,
    placeholder = 'Search by city or airport',
    requiredErrorMessage,
    fallbackHeading = 'Popular airports',
    defaultSearchPhrase,
    direction,
    open,
    readOnly,
    fallbackAirports,
  } = props

  const [localOpen, , openDropdown, closeDropdown] = useToggle()
  const finalOpen = !!(open ?? localOpen)
  const popularAirports = usePopularAirports(direction, { excludeAirport: alternateAirport })

  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(defaultSearchPhrase)
  const [airports, loading] = useAirportSearch(searchPhrase, { excludedAirport: alternateAirport })
  const triggerRef = useRef<HTMLInputElement | null>(null)
  const showSearch = !!(searchPhrase && searchPhrase.length > 2)

  const airportList = showSearch ? airports : fallbackAirports || popularAirports
  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.currentTarget.value)
    onChange(e.currentTarget.value)
  }, [onChange])

  const onClear = useCallback(() => {
    setSearchPhrase('')
    onSelectAirport(undefined)
    onChange('')
  }, [onChange, onSelectAirport])

  const onSelect = useCallback((airport: App.AirportLocation) => {
    onSelectAirport(airport)
    triggerRef.current?.blur()
    setSearchPhrase('')
    closeDropdown()
  }, [closeDropdown, onSelectAirport])

  useImperativeHandle(ref, () => {
    return triggerRef.current
  }, [triggerRef])

  const errorMessage = requiredErrorMessage ?? (direction === 'arrival' ? 'Please enter a destination' : 'Please enter a departure')

  if (readOnly) {
    return <SearchFormField
      className={className}
      label={label}
      value={getAirportDisplayText(airport)}
      placeholder={placeholder}
      required={required}
      requiredErrorMessage={errorMessage}
    />
  }

  return (
    <>
      <TextFieldContainer className={className}>
        <SearchFormTextField
          value={searchPhrase}
          displayValue={getAirportDisplayText(airport)}
          label={label}
          onChange={onInputChange}
          onFocus={openDropdown}
          placeholder={placeholder}
          onClear={onClear}
          ref={triggerRef}
          required={required}
          requiredErrorMessage={errorMessage}
        />

        {required && <HiddenInput
          value={airport?.airportCode}
          name={label}
          required={required}
          invalidMessage={errorMessage}
        />}
      </TextFieldContainer>

      <AirportSelectDropdown
        open={finalOpen}
        loading={loading}
        onClose={closeDropdown}
        onSelectAirport={onSelect}
        airports={airportList}
        inputValue={searchPhrase}
        anchorRef={triggerRef}
        triggerRef={triggerRef}
        heading={showSearch ? undefined : fallbackHeading}
      />
    </>
  )
})

export default FlightSearchAirportSelect

import fetchBusinessBudget from 'actions/businessTraveller/fetchBusinessBudget'
import generateBusinessBudgetKey from 'businessTraveller/utils/generateBusinessBudgetKey'
import GeoContext from 'contexts/geoContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useContext, useEffect, useMemo } from 'react'
import config from 'constants/config'
import useQueryParams from 'hooks/useQueryParams'
import { getDestinationIdFromURLSearchParams } from 'lib/url/searchUrlUtils'
import { getLocationDataFromOffer } from 'businessTraveller/utils/getLocationDataFromOffer'
import fetchBusinessAccommodationPolicy from 'actions/businessTraveller/fetchBusinessAccommodationPolicy'
import fetchBusinessApprovalPolicy from 'actions/businessTraveller/fetchBusinessApprovalPolicy'
import { usePlaceByCoords } from 'hooks/usePlace'
import { getCenterCoordinates } from 'lib/search/searchUtils'

type Params = {
  offer?: App.AnyOffer
  offerRequired?: boolean
}

function useBusinessTravellerBusinessBudgetFetcher(params: Params) {
  const {
    offer,
    offerRequired,
  } = params
  const { currentCurrency } = useContext(GeoContext)
  const appDispatch = useAppDispatch()
  const businessBudgets = useAppSelector(state => state.businessTraveller.businessBudgets)
  const businessId = useAppSelector(state => state.businessTraveller.currentBusinessAccount?.business?.id)
  const path = useAppSelector(state => state.router.location.pathname)
  const queryParams = useQueryParams()

  const isMapView = path.includes('/map')
  const mapViewCoords = useMemo(() => {
    if (isMapView) {
      const coords = queryParams.get('bounds')?.split(',')
      // This should always split to 4 coordinates and adding for safety
      if (coords && coords.length === 4) {
        const numCoords = coords.map(parseFloat)
        return getCenterCoordinates(numCoords as [number, number, number, number])
      }
    }
    return []
  }, [isMapView, queryParams])

  const [place] = usePlaceByCoords(mapViewCoords[0], mapViewCoords[1])
  const placeId = getDestinationIdFromURLSearchParams(queryParams) ?? place?.id

  const {
    toBeCalculatedBusinessBudget,
    budgetKey,
  } = useMemo(() => {
    const toBeCalculatedBudget: BusinessTraveller.ToBeCalculatedBusinessBudget = {
      currencyCode: currentCurrency,
      lePlaceId: placeId,
    }

    if (offer) {
      const locationData = getLocationDataFromOffer(offer)
      toBeCalculatedBudget.longitude = locationData.location.longitude
      toBeCalculatedBudget.latitude = locationData.location.latitude
    }

    const key = generateBusinessBudgetKey(toBeCalculatedBudget)

    return {
      toBeCalculatedBusinessBudget: toBeCalculatedBudget,
      budgetKey: key,
    }
  }, [currentCurrency, placeId, offer])

  const budgetData = budgetKey ? businessBudgets[budgetKey] : undefined

  // Since all fields are optional this ensures request has either lePlaceId or long/lat
  const hasValidLocation = toBeCalculatedBusinessBudget.lePlaceId || (toBeCalculatedBusinessBudget.longitude && toBeCalculatedBusinessBudget.latitude)

  useEffect(() => {
    if (offerRequired && offer === undefined) {
      return
    }

    if (config.businessTraveller.currentAccountMode === 'business' && businessId && toBeCalculatedBusinessBudget && hasValidLocation) {
      appDispatch(fetchBusinessAccommodationPolicy(businessId))
      appDispatch(fetchBusinessBudget(businessId, [budgetKey, toBeCalculatedBusinessBudget]))
      if (config.BUSINESS_TRAVELLER_ACCOMMODATION_APPROVAL_ENABLED) {
        appDispatch(fetchBusinessApprovalPolicy(businessId))
      }
    }
  }, [appDispatch, budgetKey, businessId, hasValidLocation, offer, offerRequired, toBeCalculatedBusinessBudget])

  return {
    budgetData,
    budgetKey,
    toBeCalculatedBusinessBudget,
  }
}

export default useBusinessTravellerBusinessBudgetFetcher

/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useMemo, useState } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Pane from 'components/Common/Pane'
import { mediaQueryUp } from 'components/utils/breakpoint'

import { AdultLabel, getMaxOccupancies } from 'lib/offer/occupancyUtils'
import OccupancyItemActions from './OccupancyItemActions'
import OccupancyChildAgeSelect from './OccupancyChildAgeSelect'
import { only2AAllowedForLanson } from 'lib/offer/offerSpecificChangesUtils'
import Counter from '../../Luxkit/Counter'
import Heading from '../../Luxkit/Typography/Heading'
import BodyText from '../../Luxkit/Typography/BodyText'
import cn from 'clsx'
import Group from 'components/utils/Group'

const OccupantsContainer = styled(Pane)`
  background-color: transparent;
  flex: 1;
  padding: ${rem(16)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${props => props.theme.borderRadius.S};
  box-shadow: none;

  ${mediaQueryUp.tablet} {
    display: flex;
    border-radius: 0;
    border: 0;
    padding: ${rem(16)} ${rem(24)};

    &.leftBorder {
      border-left: 1px solid ${props => props.theme.palette.neutral.default.five};
    }

    &.smallView {
      border-left: none;
      padding: ${rem(16)} 0 0;
    }
  }
`

const OccupantsTypesContainer = styled.div`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  column-gap: ${rem(40)};
  row-gap: ${rem(24)};

  ${mediaQueryUp.desktop} {
    flex-direction: row;
  }

  &.vertical-mode {
    flex-direction: column;
  }
`

const OccupantsCountsContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  column-gap: ${rem(40)};
  row-gap: ${rem(24)};

  ${mediaQueryUp.tablet} {
    flex-direction: row;
  }
`

const AgeType = styled.div`
  flex-grow: 1;

  > * + * {
    margin-top: ${rem(4)};
  }
`

const OccupantIncrementType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQueryUp.tablet} {
    display: inline-block;

    & + & {
      margin-top: 0;
    }

    > * + * {
      margin-top: ${rem(4)};
    }
  }
`

const OccupantsIncrementer = styled(Counter)`
  display: inline-flex;

  ${mediaQueryUp.tablet} {
    margin-top: ${rem(12)};
  }
`

const ItemWrapper = styled(Pane)`
  box-shadow: none;
  background-color: transparent;

  ${mediaQueryUp.tablet} {
    display: flex;
    border: 1px solid ${props => props.theme.palette.neutral.default.five};
    border-radius: ${props => props.theme.borderRadius.S};

    &.smallView {
      display: grid;
      border: none;
      border-top: 1px solid ${props => props.theme.palette.neutral.default.five};

      &:first-child {
        border-top: none;
      }
    }
  }
`

const AgeContainer = styled(Group)`
  > * {
    flex-basis: ${rem(140)};
  }
`

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${rem(8)};

  ${mediaQueryUp.tablet} {
    align-items: flex-start;
    padding: ${rem(16)} ${rem(16)};

    &:not(.smallView) {
      min-width: ${rem(130)};
      justify-content: center;
    }

    &.smallView {
      padding: ${rem(16)} 0 0;
      align-items: center;
    }
  }
`

const MobileActions = styled(OccupancyItemActions)`
  ${mediaQueryUp.tablet} {
    display: none;
    &.smallView {
      display: flex;
    }
  }
`

const DesktopActions = styled(OccupancyItemActions)`
  display: none;

  ${mediaQueryUp.tablet} {
    display: flex;
    margin-left: auto;
    justify-self: flex-end;
    align-self: start;
    &.smallView {
      display: none;
    }
  }
`

const ChildrenAges = styled.div`
  min-width: ${rem(120)};
`

export type OccupancyChildVariant = 'none' | 'children-only' | 'date-of-birth' | 'child-age'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'onReset'> {
  title?: string;
  /* occupancy objects don't have an ID so we need to provide one */
  itemId: number;
  occupancy: App.Occupants;
  name?: string;
  maxChildAge?: number;
  maxInfantAge?: number;
  minInfantAge?: number;
  capacities?: Array<App.RoomCapacity>;
  onChange: (itemId: number, nextOccupancy: App.Occupants, eventLabel?: string) => void;
  onRemove?: (itemId: number) => void;
  onReset?: (itemId: number) => void;
  showRemove?: boolean;
  childrenAgeLabel?: string;
  smallView?: boolean;
  adultLabelType?: AdultLabel;
  childVariant?: OccupancyChildVariant;
  /** If this is false, the reset button will only appear when the control is dirty */
  showResetWhenClean?: boolean;
  offerType?: App.OfferType;
  offerId?: string;
  departureDate?: string;
}

function OccupancyItem(props: Props) {
  const {
    maxChildAge = 17,
    maxInfantAge = -1,
    minInfantAge = 0,
    title,
    itemId,
    occupancy,
    onChange,
    onReset,
    onRemove,
    name,
    capacities,
    showRemove,
    childrenAgeLabel = 'at time of check-in',
    smallView = false,
    adultLabelType = 'adult',
    childVariant = 'child-age',
    showResetWhenClean = false,
    offerType,
    offerId,
    departureDate,
    ...rest
  } = props

  const {
    adults,
    children = 0,
    childrenAge = [],
    childrenBirthDate = [],
  } = occupancy

  const childrenRequireDateOfBirth = childVariant === 'date-of-birth'
  const [isDirty, setDirty] = useState<boolean>(false)

  const restrictCapacitiesForOffer = offerId && only2AAllowedForLanson(offerId)

  const maxCapacities = useMemo(
    () => getMaxOccupancies(capacities, occupancy, { maxChildAge, maxInfantAge }),
    [capacities, occupancy, maxChildAge, maxInfantAge],
  )

  const { maxAdults, minAdults } = useMemo(() => {
    const calculatedMaxAdults = restrictCapacitiesForOffer ? 2 : maxCapacities.adults
    const calculatedMinAdults = restrictCapacitiesForOffer ? 2 : 1

    return { maxAdults: calculatedMaxAdults, minAdults: calculatedMinAdults }
  }, [restrictCapacitiesForOffer, maxCapacities.adults])

  const onAdultChange = useCallback((val: number) => {
    setDirty(true)
    const newOccupancies = { ...occupancy, adults: val }
    onChange(itemId, newOccupancies, val > occupancy.adults ? 'add-adult' : undefined)
  }, [occupancy, onChange, itemId])

  const onChildrenChange = useCallback((val: number) => {
    setDirty(true)
    const nextChildrenAges = [...childrenAge]
    const nextChildrenBirthDate = [...childrenBirthDate]
    const isIncrementing = val > nextChildrenAges.length

    if (isIncrementing) {
      nextChildrenAges.push(-1)
      if (childrenRequireDateOfBirth) nextChildrenBirthDate.push(undefined)
    } else {
      nextChildrenAges.pop()
      if (childrenRequireDateOfBirth) nextChildrenBirthDate.pop()
    }

    const children = maxInfantAge >= 0 ?
      nextChildrenAges.filter((age) => age > maxInfantAge).length :
      nextChildrenAges.length

    const newOccupancies = {
      ...occupancy,
      children,
      infants: nextChildrenAges.length - children,
      childrenAge: nextChildrenAges,
      childrenBirthDate: nextChildrenBirthDate,
    }

    onChange(itemId, newOccupancies, isIncrementing ? 'add-kid' : undefined)
  }, [childrenAge, onChange, itemId, occupancy, maxInfantAge, childrenBirthDate, childrenRequireDateOfBirth])

  const onChildrenAgeChange = useCallback((childId: number, age: number, birthDate?: string) => {
    setDirty(true)
    const nextChildrenAges = [...childrenAge]
    nextChildrenAges[childId] = age

    const children = maxInfantAge >= 0 ?
      nextChildrenAges.filter((age) => age > maxInfantAge).length :
      nextChildrenAges.length

    const nextChildrenBirthDate = [...childrenBirthDate]
    if (childrenRequireDateOfBirth) {
      nextChildrenBirthDate[childId] = birthDate
    }

    const newOccupancy = {
      ...occupancy,
      children,
      infants: nextChildrenAges.length - children,
      childrenAge: nextChildrenAges,
      childrenBirthDate: nextChildrenBirthDate,
    }
    onChange(itemId, newOccupancy)
  }, [childrenAge, occupancy, onChange, itemId, maxInfantAge, childrenBirthDate, childrenRequireDateOfBirth])

  const onResetClick = useCallback(() => {
    setDirty(false)
    onReset?.(itemId)
  }, [onReset, itemId])

  const onRemoveClick = useCallback(() => {
    onRemove?.(itemId)
  }, [onRemove, itemId])

  const onlyInfantCanBeAdded = maxCapacities.children === 0 && maxChildAge === 0 && (maxCapacities.infants ?? 0) > 0
  const maxChildrenAge = onlyInfantCanBeAdded ? maxInfantAge : maxChildAge

  const adultLabel = adultLabelType === 'adult' ? 'Adults' : 'Travellers'

  return (
    <ItemWrapper className={cn('occupancy-picker-item', { smallView })} data-testid={`occupancy-item-${itemId}`} {...rest}>
      {title &&
        <TitleSection className={cn({ smallView })}>
          <Heading variant="heading6" format="titlecase" >{title}</Heading>
          <MobileActions
            className={cn({ smallView })}
            showReset={isDirty && !!onReset}
            showRemove={showRemove}
            onReset={onResetClick}
            onRemove={onRemoveClick}
          />
        </TitleSection>
      }
      <OccupantsContainer className={cn('occupancy-picker-item', { smallView, leftBorder: !!title })}>
        <OccupantsTypesContainer className={cn({ 'vertical-mode': childrenRequireDateOfBirth })}>
          <OccupantsCountsContainer>
            <OccupantIncrementType>
              <label>
                <BodyText variant="medium" weight="bold">{adultLabel}</BodyText>
                <BodyText variant="medium" colour="neutral-two">Ages {maxChildAge + 1} and above</BodyText>
              </label>
              <OccupantsIncrementer
                data-testid={`occupancy-item-${itemId}-adults`}
                value={adults}
                onChange={onAdultChange}
                max={maxAdults}
                min={minAdults}
                name={`${name}.adults`}
              />
            </OccupantIncrementType>
            {!restrictCapacitiesForOffer && childVariant !== 'none' && <OccupantIncrementType>
              <label>
                <BodyText variant="medium" weight="bold">Children</BodyText>
                <BodyText variant="medium" colour="neutral-two">Up to {maxChildrenAge} years old</BodyText>
              </label>
              <OccupantsIncrementer
                data-testid={`occupancy-item-${itemId}-children`}
                value={Math.max(childrenAge.length, children)}
                onChange={onChildrenChange}
                max={(maxCapacities.children ?? 0) + (maxCapacities.infants ?? 0)}
                min={0}
                name={`${name}.children`}
              />
            </OccupantIncrementType>}
          </OccupantsCountsContainer>
          {childVariant !== 'children-only' && childrenAge.length > 0 &&
            <AgeType>
              <ChildrenAges>
                <BodyText variant="medium" weight="bold">
                  {childrenRequireDateOfBirth ? "Children's date of birth" : "Children's ages"}
                </BodyText>
                {!!childrenAgeLabel && <BodyText variant="medium" colour="neutral-two">({childrenAgeLabel})</BodyText>}
              </ChildrenAges>

              <AgeContainer direction="horizontal" gap={12} wrap="wrap">
                {childrenAge.map((child, index) => <OccupancyChildAgeSelect
                  key={index}
                  capacities={capacities}
                  occupancy={occupancy}
                  maxChildAge={maxChildAge}
                  maxInfantAge={maxInfantAge}
                  minInfantAge={minInfantAge}
                  childId={index}
                  defaultValue={child === -1 ? undefined : child}
                  onChange={onChildrenAgeChange}
                  data-testid={`occupancy-item-${itemId}-childrenAge-${index}`}
                  name={`${name}.childrenAge[${index}]::number`}
                  childrenRequireDateOfBirth={childrenRequireDateOfBirth}
                  departureDate={departureDate}
                  required
                />)}
              </AgeContainer>
            </AgeType>
          }
        </OccupantsTypesContainer>
        <DesktopActions
          className={cn({ smallView })}
          showReset={(isDirty || showResetWhenClean) && !!onReset}
          showRemove={showRemove}
          onReset={onResetClick}
          onRemove={onRemoveClick}
        />
      </OccupantsContainer>
    </ItemWrapper>
  )
}

export default React.memo(OccupancyItem)

import generateDepartureRoomPriceBreakdown from 'lib/tours/generateDepartureRoomPriceBreakdown'
import { getRoomTypeCapacity } from 'lib/tours/tourUtils'
import { sum } from 'lib/array/arrayUtils'

export function getTourV2ItemViewPrice(offer: Tours.TourV2Offer | undefined, purchasableOption: Tours.TourV2OfferPurchasableOption, occupancy: App.Occupants) {
  const { totalPrice, totalMemberPrice, totalValuedAtPrice } = generateDepartureRoomPriceBreakdown(
    occupancy,
    purchasableOption,
    offer,
  )

  return { totalPrice, totalMemberPrice, totalValuedAtPrice }
}

export function calculateTotalForDepartureByItems(
  departure: Tours.TourV2OfferDeparture,
  tourItems: Array<App.Checkout.TourV2Item>,
  canRedeemLuxPlusBenefits: boolean,
): number {
  const options = departure.options
  return sum(tourItems, (item) => {
    const optionRoomType = options.find((option) => option.roomType === item.purchasableOption.roomType)
    const price = canRedeemLuxPlusBenefits && optionRoomType?.memberPrice ? optionRoomType?.memberPrice : optionRoomType?.price
    const totalRoomPrice = (price ?? 0) * (getRoomTypeCapacity(optionRoomType?.roomType) ?? 0)
    return totalRoomPrice
  })
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBuildingIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14 8h1a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2Zm0 4h1a1.0001 1.0001 0 0 0 0-2h-1a1.0001 1.0001 0 0 0 0 2ZM9 8h1a1 1 0 1 0 0-2H9a1 1 0 0 0 0 2Zm0 4h1a1.0001 1.0001 0 0 0 0-2H9a1.0002 1.0002 0 0 0-.7071 1.7071C8.4804 11.8946 8.7348 12 9 12Zm12 8h-1V3a1.0002 1.0002 0 0 0-1-1H5a1 1 0 0 0-1 1v17H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 21.8946 2.7348 22 3 22h18a1.0001 1.0001 0 0 0 0-2Zm-8 0h-2v-4h2v4Zm5 0h-3v-5a1.0001 1.0001 0 0 0-1-1h-4a1.0002 1.0002 0 0 0-1 1v5H6V4h12v16Z"/>
  </SvgIcon>
}

export default LineBuildingIcon

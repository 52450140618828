import LinePlusIcon from 'components/Luxkit/Icons/line/LinePlusIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'
import { unique } from 'lib/array/arrayUtils'
import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const Location = styled(Group)`
  column-gap: ${rem(4)};
`

interface Props {
  bundledOffers: Array<App.BundledOffer>;
  showHotel?: boolean;
}

export default function BundleAndSaveLocation({ bundledOffers, showHotel }: Props) {
  const bundledOfferCountries = bundledOffers.map(bundledOffer => bundledOffer.locationSubheading)
  const allOffersWithinSameCountry = unique(bundledOfferCountries).length === 1

  const locations = useMemo(() => {
    if (allOffersWithinSameCountry) {
      return bundledOffers.map(bundledOffer => bundledOffer.locationHeading)
    } else {
      return Array.from(
        new Set(bundledOffers.map(bundledOffer =>
          [bundledOffer.locationHeading, bundledOffer.locationSubheading]
            .filter(t => t)
            .join(', '))).values())
    }
  }, [allOffersWithinSameCountry, bundledOffers])

  return (
    <Location direction="horizontal" verticalAlign="center" wrap="wrap">
      {allOffersWithinSameCountry && (
        <>
          {locations.map((location, index) => (
            <React.Fragment key={`same-countries-${location}`}>
              {index > 0 && <LinePlusIcon colour="neutral-one" size={12} />}
              <Caption wrap="no-wrap" variant="large" colour="neutral-one">
                {location}
                {index + 1 === locations.length && ','}
              </Caption>
            </React.Fragment>
          ))}
          <Caption variant="large" colour="neutral-one">
            {bundledOfferCountries[0]}
          </Caption>
        </>
      )}
      {!allOffersWithinSameCountry && (
        <>
          {locations.map((location, index) => (
            <React.Fragment key={`different-countries-${location}`}>
              {index > 0 && <LinePlusIcon colour="neutral-one" size={12} />}
              <Caption wrap="no-wrap" variant="large" colour="neutral-one">
                {location}
              </Caption>
            </React.Fragment>
          ))}
        </>
      )}
      {showHotel && (
        <Caption variant="large" colour="neutral-three" as="span"> · Hotel</Caption>
      )}
    </Location>
  )
}

import LoadingBox from 'components/Common/Loading/LoadingBox'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const LoaderContainer = styled.div`
  width: 75vw;
  min-height: ${rem(520)};
  display: grid;
  gap: ${rem(16)};
  grid-template-rows: ${rem(200)} 1fr;

  ${mediaQueryUp.tablet} {
    width: ${rem(320)};
  }

  ${mediaQueryUp.desktop} {
    grid-template-rows: ${rem(280)} 1fr;
    width: ${rem(512)};
  }
`

const TagsLoadingBox = styled(LoadingBox)`
  height: ${rem(18)};
  width: ${rem(143)};
`

const PricingLoadingBoxTop = styled(LoadingBox)`
  height: ${rem(18)};
  width: ${rem(132)};
`

const PricingLoadingBoxBottom = styled(LoadingBox)`
  height: ${rem(18)};
  width: ${rem(128)};
  margin-top: ${rem(4)};
`

const ViewButtonLoadingBox = styled(LoadingBox)`
  height: ${rem(40)};
  width: ${rem(80)};
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function CarouselCardMediumLoadingSkeleton(props: Props) {
  return (
    <LoaderContainer {...props}>
      <LoadingBox />
      <Group direction="vertical" verticalAlign="space-between" gap={12}>
        <VerticalSpacer gap={8}>
          <TextLoadingBox typography="body-medium" width="60%" />
          <TextLoadingBox typography="body-large" width="100%" />
          <TagsLoadingBox />
        </VerticalSpacer>
        <Group direction="horizontal" horizontalAlign="space-between">
          <div>
            <PricingLoadingBoxTop/>
            <PricingLoadingBoxBottom/>
          </div>
          <ViewButtonLoadingBox/>
        </Group>
      </Group>
    </LoaderContainer>
  )
}

export default React.memo(CarouselCardMediumLoadingSkeleton)

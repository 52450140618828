import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;

  > * {
    flex: 1;
    border-radius: 0;

    &:not(:first-child, .swap) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child, .swap) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & + * {
      margin-left: -1px; /* to overlap border */
    }

    &:focus-within {
      z-index: 1; /* so that the overlapped border can be visible */
    }
  }
`

interface Props extends PropsWithChildren {
  className?: string;
}

const SearchFormFieldGroup = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <Container {...props} ref={ref} />
  )
})

SearchFormFieldGroup.displayName = 'SearchFormFieldGroup'

export default SearchFormFieldGroup

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import CruiseModalItineraryDetails from 'components/Cruises/CruiseTiles/Common/CruiseModalItineraryDetails'
import CruiseModalPrices from 'components/Cruises/CruiseTiles/Common/CruiseModalPrices/CruiseModalPrices'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import ModalContext from 'contexts/ModalContext'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import { isCruiseOffer } from 'lib/offer/offerTypes'
import React, { MouseEventHandler, useCallback, useContext } from 'react'

interface Props {
  view: CruiseOfferView
  filters?: App.OfferListFilters
}

function CruiseSearchTileMeta({ view, filters }: Props) {
  const showModal = useContext(ModalContext)
  const hasItinerary = view.itinerary || view.itineraryFlash

  const onViewItinerary = useCallback<MouseEventHandler<HTMLAnchorElement>>(event => {
    event.preventDefault()
    event.stopPropagation()

    showModal(<CruiseModalItineraryDetails
      itineraryFlash={view.itineraryFlash}
      offerTitle={view.offerTitle}
      itinerary={view.itinerary}
      offer={view.offer}
    />)
  }, [view, showModal])

  const onMoreDatesClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(event => {
    event.preventDefault()
    if (isCruiseOffer(view.offer)) {
      showModal(<CruiseModalPrices
        offer={view.offer}
        offerTitle={view.offerTitle}
        departures={view.offer.departuresSummary}
        startDateFilter={filters?.checkIn}
        endDateFilter={filters?.checkOut}
        monthsFilter={filters?.flexibleMonths}
      />)
    }
  }, [view, showModal, filters])

  return <VerticalSpacer gap={8}>
    <div>
      <BodyText variant="medium">
        {view.shipDetailsLabel}
      </BodyText>
      <Group direction="horizontal" gap="0 8" wrap="wrap">
        <BodyText variant="medium">
          {view.itineraryDetailsLabel}
        </BodyText>
        {hasItinerary && <TextLink size="medium" onClick={onViewItinerary}>
          View itinerary
        </TextLink>}
      </Group>
    </div>
    {view.earliestDepartureDate && <BodyText variant="medium" weight="bold">
      Departing on {view.earliestDepartureDate}
      {view.laterDates.length > 0 && <>
        {!isCruiseOffer(view.offer) && ' and more'}
        {isCruiseOffer(view.offer) && <>
          {' and '}<TextLink size="medium" onClick={onMoreDatesClick}>more</TextLink>
        </>}
      </>}
    </BodyText>}
  </VerticalSpacer>
}

export default CruiseSearchTileMeta

import { sortBy } from 'lib/array/arrayUtils'
import { dateDifference } from 'lib/datetime/dateUtils'
import { extractAbsoluteDate } from './tourV2OfferMapper'
import { Order } from '@luxuryescapes/contract-svc-order'
import uuidV4 from 'lib/string/uuidV4Utils'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'

// This is a temporary type generated from the network response
// It should be removed once the Order contract manages to include it properly
interface ApiOrderTourItemItineraryItem {
  accommodation: string
  duration: number
  meals: Array<string>
  start_day: number
  text: string
  title: string
}

interface ApiOrderTourItemRecommendedOffers {
  startLE: Array<string>
  endLE: Array<string>
  startBedbank: Array<string>
  endBedbank: Array<string>
}

interface PackageOption {
  inclusions: Array<{
    categoryIcon: string;
    inclusionBody: string;
    isHighlight: boolean;
  }>;
  packageOption: string;
}

interface ApiOrderTourItem {
  _links: {
    order: {
      href: string
    }
  }
  booking_number: string
  countries_visited: Array<string>
  departure_id: string
  description: string
  end_date: string
  end_location: string
  fk_order_id: string
  id: string
  itineraries: Array<ApiOrderTourItemItineraryItem>
  reservation_id: string
  rooms: Array<{ type: string }>
  season_id: string
  season_name: string
  start_date: string
  start_location: string
  status: string
  total: number
  member_price?: number | null
  public_price?: number
  subscriber_tier?: string
  tour_id: string
  tour_name: string
  tour_option_id: string
  transaction_key: string
  travellers: Array<{
    date_of_birth: string
    first_name: string
    is_lead_passenger: boolean
    last_name: string
    type: string
    room_type: string | null
    room_number: number | null
    departure_airport: string | null
  }>
  type: 'tour'
  vendor_id: string
  hotelRecommendations: ApiOrderTourItemRecommendedOffers | null
  packageOption: PackageOption | null
  product_type: Tours.V2ProductType
}

export function tourV2OrderItemMap(
  orderItem: ApiOrderTourItem,
): Tours.TourV2OrderItem {
  const mapped: Tours.TourV2OrderItem = {
    itemId: orderItem.id,
    tourId: orderItem.tour_id,
    tourOptionId: orderItem.tour_option_id,
    orderId: orderItem.fk_order_id,
    departureId: orderItem.departure_id,
    transactionKey: orderItem.transaction_key,
    reservationId: orderItem.reservation_id,
    bookingId: orderItem.booking_number,
    status: orderItem.status as 'completed' | 'cancelled',
    total: orderItem.total,
    memberPrice: orderItem.member_price,
    publicPrice: orderItem.public_price,
    subscriberTier: orderItem.subscriber_tier,
    offer: { type: 'tour_v2' },
    tour: {
      seasonId: orderItem.season_id,
      tourOptionTitle: orderItem.season_name, // TODO: simplify and convert to variation
      startLocation: orderItem.start_location,
      endLocation: orderItem.end_location,
      title: orderItem.tour_name,
      duration: dateDifference(new Date(orderItem.end_date), new Date(orderItem.start_date)).days,
      countriesVisited: orderItem.countries_visited ?? [],
      rooms: orderItem.rooms ?? [],
      itinerary: sortBy(
        orderItem.itineraries?.map(tourV2OrderItemItineraryItemMap) ?? [],
        (itineraryItem) => itineraryItem.startDay, 'asc',
      ),
      travellers: orderItem.travellers.map(traveller => ({
        firstName: traveller.first_name,
        lastName: traveller.last_name,
        type: traveller.type,
        isLeadPassenger: traveller.is_lead_passenger,
        roomType: traveller.room_type,
        roomNumber: traveller.room_number,
        departureAirport: traveller.departure_airport,
      })),
      startDate: extractAbsoluteDate(orderItem.start_date),
      endDate: extractAbsoluteDate(orderItem.end_date),
      hotelRecommendations: orderItem.hotelRecommendations,
    },
    vendorId: orderItem.vendor_id,
    productType: orderItem.product_type,
  }

  if (orderItem.packageOption) {
    mapped.packageOption = {
      packageType: orderItem.packageOption.packageOption.toLowerCase() as Tours.V2OfferPackageType,
      packageOption: orderItem.packageOption.packageOption ?? '',
      inclusions: orderItem.packageOption.inclusions?.map<App.OfferInclusion>(item => ({
        id: uuidV4(),
        description: item.inclusionBody,
        symbol: determineOfferFeatureSymbol(item.categoryIcon?.split(',')[0]),
        isHighlighted: item.isHighlight,
      })) ?? [],
    }
  }

  return mapped
}

export function tourV2OrderItemItineraryItemMap(
  itineraryItem: ApiOrderTourItemItineraryItem,
): Tours.TourV2OfferItineraryItem {
  return {
    accommodation: itineraryItem.accommodation,
    description: itineraryItem.text,
    duration: itineraryItem.duration,
    locationsVisited: [], // NOT PROVIDED
    meals: itineraryItem.meals ?? [],
    startDay: itineraryItem.start_day,
    title: itineraryItem.title,
    locationsVisitedDetails: [], // NOT PROVIDED
    specialsEvents: [], // NOT PROVIDED
    experiences: [], // NOT PROVIDED
  }
}

export function tourV2OrderOptionalExperienceItemMap(
  experienceItem: Order.TourOptionalExperience,
): Tours.TourV2OrderExperienceItem {
  return {
    id: experienceItem.optional_experience_id,
    name: experienceItem.name,
    description: experienceItem.description ?? undefined,
    itineraryId: experienceItem.itinerary_id,
    dayNumber: experienceItem.day_number,
    duration: experienceItem.duration ?? undefined,
    status: experienceItem.status,
    price: experienceItem.price,
    total: experienceItem.total,
    timeSlot: experienceItem.time_slot ?? undefined,
    count: experienceItem.count,
    location: experienceItem.location,
    date: moment(experienceItem.date).format(ISO_DATE_FORMAT),
  }
}

import { useCallback, useMemo } from 'react'
import { SEARCH_VERTICALS } from 'constants/search'
import config from 'constants/config'
import { useGlobalHomesAndVillasSearch } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHomesAndVillasSearch'
import HomesAndVillasTabContent from '../Components/Mobile/TabContents/HomesAndVillasTabContent'
import useGlobalSearchVerticalTabBooking from './useGlobalSearchVerticalTabBooking'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import useQueryParams from 'hooks/useQueryParams'
import LineHomeAltIcon from 'components/Luxkit/Icons/line/LineHomeAltIcon'

function useGlobalSearchVerticalTabHomesAndVillas(searchVerticalItem?: App.SearchVerticalItem): App.GlobalSearchVerticalTabV2 {
  const queryParams = useQueryParams()

  const {
    globalHomesAndVillasSearchDispatch,
    globalHomesAndVillasSearchState,
  } = useGlobalHomesAndVillasSearch(GLOBAL_SEARCH_INITIAL_STATE, queryParams)

  const {
    onSearch,
    context,
  } = useGlobalSearchVerticalTabBooking({
    globalSearchDispatch: globalHomesAndVillasSearchDispatch,
    globalSearchState: globalHomesAndVillasSearchState,
    baseSearchPath: '/search/homes-and-villas',
    baseSearchMapPath: '/search/homes-and-villas/map',
  })

  const onRequestSearch = useCallback(() => {
    return onSearch()
  }, [onSearch])

  return useMemo(() => ({
    // Disabling from global search until we have enough inventory
    isEnabled: config.RENTALS_SEARCH_ENABLED && !!searchVerticalItem,
    label: searchVerticalItem?.label ?? 'Homes & Villas',
    key: SEARCH_VERTICALS.HOMES_AND_VILLAS,
    context,
    onSearch: onRequestSearch,
    ContentComponent: HomesAndVillasTabContent,
    Icon: LineHomeAltIcon,
  }), [context, onRequestSearch, searchVerticalItem])
}

export default useGlobalSearchVerticalTabHomesAndVillas

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { rem } from 'polished'
import styled from 'styled-components'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import {
  formatDate,
  getOccupancyDescription,
} from 'components/SearchV2/Utils/GlobalSearchV2'
import DateFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/DateFloatingPanel'
import TravellersFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/TravellersFloatingPanel'
import LocationFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/LocationFloatingPanel'
import SearchTrendingDestinations from 'components/Search/SearchForm/SearchTrendingDestinations'
import useDateSearchProps from 'components/SearchV2/Hooks/useDateSearchProps'
import useStepsController from 'components/SearchV2/Hooks/useStepsController'
import { DATE_SEARCH_OPTION_IDS, HOTEL_SEARCH_TYPEAHEAD_TYPES, SEARCH_VERTICALS } from 'constants/search'
import { prettyFlexibleDates } from 'components/Search/SearchForm/SearchDateInput/FlexibleDateSearchField'
import config from 'constants/config'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import { connect } from 'react-redux'
import { getRecentSearches } from 'components/Search/utils'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import BusinessTravellerSelectHotelsMobile from 'businessTraveller/components/select-traveller/BusinessTravellerSelectHotelsMobile'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    &:not(:first-child) {
      margin-top: ${rem(8)};
    }
  }
`

const StyledTrendingDestinations = styled(SearchTrendingDestinations)`
  margin-top: ${rem(24)};
`

export enum searchSteps {
  LOCATION = 'location',
  DATE = 'date',
  GUESTS = 'guests',
}

interface Props {
  closeSearch?: () => void
  onSearch: () => void
  isInPage?: boolean; // i.e. not part of drawer
}

interface MappedStateProps {
  memberId?: string
  travellerEmployees: Array<BusinessTraveller.EmployeeFromMeEndpoint>
}

function HotelsTabContent(props: Props & MappedStateProps) {
  const { closeSearch, onSearch, isInPage, memberId, travellerEmployees } = props

  const {
    searchItem,
    checkinDate,
    checkoutDate,
    occupancies,
    areRoomErrorsShown,
    flexibleMonths,
    flexibleNights,
    dateSearchOptionId,
    userSelectedFlexibleMonths,
  } = useContext(GlobalSearchStateContext)

  const { openedSearchVertical, option } = useGlobalSearchURLHashVertical()

  const isDateSelectOpen = openedSearchVertical === SEARCH_VERTICALS.HOTELS && option === 'dateSelect'

  const recentSearches = getRecentSearches(memberId)

  const isBusinessTravellerActive = (config.businessTraveller.isEnabled && config.businessTraveller.currentAccountMode === 'business')
  const DATE_INPUT_PLACEHOLDER = isBusinessTravellerActive ? 'Select date' : 'Anytime'

  const stepsValidation = useMemo(
    () => ({
      [searchSteps.LOCATION]: searchItem,
      [searchSteps.DATE]:
      // if business traveller is active and they are a business account, then
      // invalidate this step so that they have to choose dates in booking flow
        (!isBusinessTravellerActive && (dateSearchOptionId === DATE_SEARCH_OPTION_IDS.ANYTIME || (checkinDate && checkoutDate) || flexibleMonths)) ||
        (isBusinessTravellerActive && checkinDate && checkoutDate),
      [searchSteps.GUESTS]:
        (!areRoomErrorsShown && !isBusinessTravellerActive) ||
        (isBusinessTravellerActive && travellerEmployees.length > 0),
    }),
    [searchItem, isBusinessTravellerActive, dateSearchOptionId, checkinDate, checkoutDate, flexibleMonths, areRoomErrorsShown, travellerEmployees],
  )

  const flexibleSearchSelectionText =
    prettyFlexibleDates(userSelectedFlexibleMonths ? flexibleMonths : undefined, flexibleNights)

  const [activeStep, setActiveStep] = useState<searchSteps>()
  const { goToNextStep, goToPreviousStep, closeActiveStep, isSearchEnabled } =
    useStepsController({ activeStep, setActiveStep, stepsValidation, onSearch })

  const openLocationSearchStep = useCallback(
    () => setActiveStep(searchSteps.LOCATION),
    [setActiveStep],
  )
  const openDateSearchStep = useCallback(
    () => setActiveStep(searchSteps.DATE),
    [setActiveStep],
  )
  const openGuestsSearchStep = useCallback(
    () => setActiveStep(searchSteps.GUESTS),
    [setActiveStep],
  )

  useEffect(() => {
    if (isDateSelectOpen) {
      openDateSearchStep()
    }
  }, [isDateSelectOpen, openDateSearchStep])

  const { checkin, checkout } = useMemo(
    () => ({
      checkin: formatDate(checkinDate),
      checkout: formatDate(checkoutDate),
    }),
    [checkinDate, checkoutDate],
  )

  const guestsDescription = useMemo(
    () => getOccupancyDescription(occupancies),
    [occupancies],
  )
  const dateSearchProps = useDateSearchProps({
    onAnytimeDatesSelected: closeActiveStep,
  })

  return (
    <ContentWrapper>
      <LocationFloatingPanel
        typeaheadTypes={HOTEL_SEARCH_TYPEAHEAD_TYPES}
        isOpen={activeStep === searchSteps.LOCATION}
        onClose={closeActiveStep}
        onConfirm={openDateSearchStep}
        onGoBack={goToPreviousStep}
        typeaheadVertical="hotel"
        recentSearches={recentSearches}
      />

      <DateFloatingPanel
        {...dateSearchProps}
        isOpen={activeStep === searchSteps.DATE}
        onClose={closeActiveStep}
        onConfirm={goToNextStep}
        onGoBack={goToPreviousStep}
        confirmText={isSearchEnabled ? 'Search' : 'Apply'}
      />

      {!isBusinessTravellerActive && <TravellersFloatingPanel
        isOpen={activeStep === searchSteps.GUESTS}
        onClose={closeActiveStep}
        onConfirm={goToNextStep}
        onGoBack={goToPreviousStep}
        confirmText={isSearchEnabled ? 'Search' : 'Apply'}
      />}

      <FieldsWrapper>
        <SearchFormField
        label="Where"
        placeholder="Search destination, landmark or hotel"
        value={searchItem?.format.mainText}
        onClick={openLocationSearchStep}
      />

        <SearchFormFieldGroup>
          {dateSearchOptionId === DATE_SEARCH_OPTION_IDS.FLEXIBLE && (
            <SearchFormField
          label="When"
          onClick={openDateSearchStep}
          placeholder="Anytime"
          value={flexibleSearchSelectionText}
          />
          )}
          {dateSearchOptionId !== DATE_SEARCH_OPTION_IDS.FLEXIBLE && (
            <>
              <SearchFormField
              label="Check in"
              placeholder={DATE_INPUT_PLACEHOLDER}
              value={checkin}
              onClick={openDateSearchStep}
            />
              <SearchFormField
              label="Check out"
              placeholder={DATE_INPUT_PLACEHOLDER}
              value={checkout}
              onClick={openDateSearchStep}
            />
            </>
          )}
        </SearchFormFieldGroup>
        {isBusinessTravellerActive && <BusinessTravellerAccountGuard
          accountMode="business"
          employeeRoles={['BUSINESS_ADMIN', 'BUSINESS_MANAGER']}
          loading={<BusinessTravellerSelectLoadingSkeleton />}
          fallback={<SearchFormField
            label="Traveller"
            value={guestsDescription}
            onClick={openGuestsSearchStep}
            hasError={areRoomErrorsShown}
            disabled
          />}
        >
          <BusinessTravellerSelectHotelsMobile
            activeStep={activeStep}
            onBusinessTravellerSelectClick={openGuestsSearchStep}
            gotoNextStep={goToNextStep}
          />
        </BusinessTravellerAccountGuard>}
        {!isBusinessTravellerActive && <SearchFormField
          label="Guests"
          value={guestsDescription}
          onClick={openGuestsSearchStep}
          hasError={areRoomErrorsShown}
        />}

      </FieldsWrapper>
      {!isBusinessTravellerActive && !isInPage &&
        <StyledTrendingDestinations
        titleColour="neutral-two"
        onClick={closeSearch}
      />}
    </ContentWrapper>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState) => {
  return {
    memberId: appState.auth.account.memberId,
    travellerEmployees: selectSelectedTravellerEmployees(appState),
  }
})(HotelsTabContent)

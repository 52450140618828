import { groupBy } from 'lib/array/arrayUtils'
import { isReservationAvailableForRoom } from 'lib/checkout/cartReservationUtils'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'

// bnbl constants, enums, types

export const DEFAULT_OCCUPANCY_FOR_BNBL: App.Occupants = { adults: 1 }

export enum BnblSteps {
  HOME = 'home',
  GIFT = 'gift',
  CHANGE_DURATION = 'change-duration',
  CHANGE_PACKAGE = 'change-package',
}

export type CheckboxStateForSwitchablePackageDurations = {
  [duration: string]: boolean;
}

export type BnblSwitchablePackageDuration = {
  isSelected: boolean;
  package: App.Package;
  capacityValid: boolean;
}

export type BnblTotalPackagesPrices = {
  hotelPrice: number;
  priceFrom: number;
  hotelValue: number;
  shouldDisplayValue: boolean;
  hotelMemberPrice: number;
  memberPriceFrom: number;
}

// bnbl modal prop utils

export function getModalTitle(step: BnblSteps) {
  switch (step) {
    case BnblSteps.HOME: {
      return 'Buy now, choose dates later'
    }
    case BnblSteps.GIFT: {
      return 'Give this package as a gift'
    }
    case BnblSteps.CHANGE_DURATION: {
      return 'How long do you want to stay?'
    }
    case BnblSteps.CHANGE_PACKAGE: {
      return 'Choose your package'
    }
  }
}

export function getOnBack(
  step: BnblSteps,
  setStep: (step: BnblSteps) => void,
  setSelectedPackageUniqueKey: () => void,
  isGift: boolean,
) {
  switch (step) {
    case BnblSteps.HOME:
    case BnblSteps.GIFT:
      return undefined
    case BnblSteps.CHANGE_DURATION:
    case BnblSteps.CHANGE_PACKAGE:
      if (isGift) {
        return () => {
          setStep(BnblSteps.GIFT)
          setSelectedPackageUniqueKey()
        }
      }
      return () => {
        setStep(BnblSteps.HOME)
        setSelectedPackageUniqueKey()
      }
  }
}

// change bnbl package duration utils

const getAllPackageOptions = (offer: App.Offer, currentPackageUniqueKey: string) => {
  return offer.packages.map((packageOption) => {
    return {
      capacityValid: packageOption.roomType && isReservationAvailableForRoom(DEFAULT_OCCUPANCY_FOR_BNBL, packageOption.roomRate.capacities, offer),
      isSelected: packageOption.uniqueKey === currentPackageUniqueKey,
      package: packageOption,
    }
  })
}

export function bnblSwitchablePackageDurations(offer: App.Offer, currentPackage: App.Package): Array<BnblSwitchablePackageDuration> {
  const allPackageOptions = getAllPackageOptions(offer, currentPackage.uniqueKey)
  const allPackageOptionsGroupedByDuration = groupBy(allPackageOptions, o => o.package.duration)
  const switchableOptions = Array.from(allPackageOptionsGroupedByDuration.values()).map(options => {
    // try find the different night version of the currently selected room
    const matchingRoomOption = options.find(o => o.capacityValid && o.package.roomType.id === currentPackage.roomType.id)
    // always take the package that's selected if there is one
    // otherwise try match the room option, then the first one that's valid or just the first one if none are valid
    return options.find(o => o.isSelected) || matchingRoomOption || options.find(o => o.capacityValid) || options[0]
  })
  return switchableOptions
}

export function getInitialCheckboxStateForSwitchablePackageDurations(switchablePackageOptions: Array<BnblSwitchablePackageDuration>): CheckboxStateForSwitchablePackageDurations {
  return switchablePackageOptions.reduce((switchablePackageOptions, switchablePackageOption) => {
    const { package: { duration }, isSelected } = switchablePackageOption
    return {
      ...switchablePackageOptions,
      [String(duration)]: isSelected,
    }
  }, {})
}

export function getBnblPackageViewByNumberOfRooms(
  packageView: OfferPackageOptionView,
  numberOfRooms: number,
): OfferPackageOptionView {
  const hotelPrice = packageView.hotelPrice * numberOfRooms
  const hotelMemberPrice = packageView.hotelMemberPrice * numberOfRooms
  const hotelValue = packageView.hotelValue * numberOfRooms
  const taxesAndFees = packageView.taxesAndFees * numberOfRooms
  const propertyFees = packageView.propertyFees * numberOfRooms
  const hotelDiscountPercent = Math.round((hotelValue - hotelPrice) * 100 / hotelValue)

  return {
    ...packageView,
    hotelPrice,
    hotelMemberPrice,
    hotelValue,
    taxesAndFees,
    propertyFees,
    hotelDiscountPercent,
  }
}

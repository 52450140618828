import React, { useMemo } from 'react'

import OnboardingTooltip, {
  OnboardingTooltipMessage,
  OnboardingTooltipProps,
} from 'tripPlanner/Tripkit/Onboarding/OnboardingTooltip'
import { BasicTrip } from 'tripPlanner/types/common'

const onboardingMessages = (trip: BasicTrip): Array<OnboardingTooltipMessage> => {
  return [
    {
      title: `Offer saved to "${trip.name}"`,
      content: 'We\'ve added your first saved item to your trip',
    },
  ]
}

type Props = Omit<
  OnboardingTooltipProps,
  | 'messages'
  | 'skipLabel'
  | 'backgroundOpacity'
  | 'isScrollLocked'
  | 'autoDismissTimeout'
> & {
  trip: BasicTrip
}

export default function FirstTripItemOnboardingTooltip(props: Props) {
  const { trip } = props
  const messages = useMemo(() => onboardingMessages(trip), [trip])
  return (
    <OnboardingTooltip
      {...props}
      messages={messages}
      skipLabel=""
      backgroundOpacity={0}
      isScrollLocked={false}
      autoDismissTimeout={8000}
      showTopArrow
      className="flatShadow"
    />
  )
}

import React, { ComponentProps } from 'react'
import PriceRowCaption from './PriceRowCaption'
import TextLoadingBox from '../Typography/TextLoadingBox'

interface Props extends Partial<Pick<ComponentProps<typeof TextLoadingBox>, 'width'>> {
}

function PriceRowPriceCaptionLoadingSkeleton(props: Props) {
  const { width = '10ch' } = props
  return <PriceRowCaption>
    <TextLoadingBox width={width} />
  </PriceRowCaption>
}

export default PriceRowPriceCaptionLoadingSkeleton

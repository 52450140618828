import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHourglassIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18.9917 21.002h-1v-1.667a4.9975 4.9975 0 0 0-.2996-1.6777.958.958 0 0 0-.0359-.0846 4.9758 4.9758 0 0 0-.6645-1.2367l-1.3999-1.8672a3.0193 3.0193 0 0 1-.6001-1.8008v-1.0098a3.0211 3.0211 0 0 1 .8784-2.121l.6572-.6573a4.9448 4.9448 0 0 0 1.3963-2.8384c.0006-.014.008-.0255.008-.0396l-.0028-.0137a5.012 5.012 0 0 0 .0629-.6435V3.002h1a1 1 0 1 0 0-2h-14a1 1 0 0 0 0 2h1v2.3427c.0071.2157.0281.4306.0629.6435l-.0028.0138c0 .014.0074.0256.008.0396A4.9457 4.9457 0 0 0 7.456 8.8798l.6572.6573a3.021 3.021 0 0 1 .8784 2.121v1.0098a3.0222 3.0222 0 0 1-.5996 1.8008l-1.4009 1.8671a4.9829 4.9829 0 0 0-.6644 1.2372.968.968 0 0 0-.0355.0837 5.003 5.003 0 0 0-.2996 1.6782v1.667h-1a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929h14a1.0001 1.0001 0 0 0 0-2Zm-11-16v-2h8v2h-8Zm.8784 2.4639a2.97 2.97 0 0 1-.3769-.4639h6.997a2.9758 2.9758 0 0 1-.3769.4639l-.6573.6572a4.96 4.96 0 0 0-1.4218 2.8789h-2.085a4.9606 4.9606 0 0 0-1.4219-2.879l-.6572-.6571Zm1.122 8.2021a5.0373 5.0373 0 0 0 .9884-2.666h2.0224a5.0333 5.0333 0 0 0 .9888 2.666l1.0001 1.334H8.9913l1.0009-1.334Zm5.9996 5.334h-8v-1.667a2.9533 2.9533 0 0 1 .0272-.333h7.9456c.0153.1104.0244.2216.0272.333v1.667Z"/>
  </SvgIcon>
}

export default LineHourglassIcon

import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { eventsMap } from 'analytics/eventsMap'
import { createGenesysMiddleware } from 'analytics/genesys'
import { routerMiddleware } from 'connected-react-router'
import config from 'constants/config'
import userMiddleware from 'middlewares/userMiddleware'
import { createMiddleware } from 'redux-beacon'
import buildStore from './store'
import * as interactionStudio from './analytics/interactionStudio'
import { SET_UTM } from 'actions/actionConstants'
import { loadUtmsFromLocalStorage, saveUtmsToLocalStorage } from 'storage/utms'
import { UTM_CONFIGS } from 'constants/utms'
import { analyticsListenerMiddleware } from 'middlewares/analyticsMiddleware'
import analyticsPageViewMiddleware from 'middlewares/analyticsPageViewMiddleware'

function initialiseStore(history: any, initialState: App.State) {
  const googleTagManagerMiddleware = createMiddleware(
    eventsMap,
    GoogleTagManager(),
  )

  const interactionStudioMiddleware = createMiddleware(
    interactionStudio.events(),
    interactionStudio.target(),
  )
  const middlewares = [
    userMiddleware,
    routerMiddleware(history),
    googleTagManagerMiddleware,
    createGenesysMiddleware(),
    analyticsListenerMiddleware.middleware,
    analyticsPageViewMiddleware.middleware,
  ]

  if (config.analytics.interactionStudio) {
    middlewares.push(interactionStudioMiddleware)
  }

  const store = buildStore(
    history,
    initialState,
    middlewares,
  )

  const removeKeysFromUtms = (keys: Array<string>, utms: App.UTMState) => {
    for (const key of keys) {
      delete utms[key]
    }
  }

  const getUtmsByKeys = (keys: Array<string>, utms: App.UTMState): App.UTMState => {
    const newUtms: App.UTMState = {}
    for (const key of keys) {
      if (key in utms) {
        newUtms[key] = utms[key]
      }
    }
    return newUtms
  }

  const hasInitialStateUtms = Object.keys(initialState.utm).length

  let utms = {}
  // If there is any utm in the initial state
  if (hasInitialStateUtms) {
    if (UTM_CONFIGS.basic.utms.some((utm) => initialState.utm[utm])) {
      utms = { ...initialState.utm }
      removeKeysFromUtms(UTM_CONFIGS.advanced.utms, utms)
      // If there is any basic utm in the url we will use the url utms
      // Want to reset the update the expiry date of the utms
      saveUtmsToLocalStorage(utms, UTM_CONFIGS.basic.storageKey, UTM_CONFIGS.basic.timeStamp())
    } else {
      // If there is no basic utm in the url we try to load localStorage and update all non basic utms
      utms = {
        // loadUtmsFromLocalStorage will take care of removing outdated utms
        ...loadUtmsFromLocalStorage(UTM_CONFIGS.basic.storageKey),
        ...initialState.utm,
      }
      removeKeysFromUtms(UTM_CONFIGS.advanced.utms, utms)
      saveUtmsToLocalStorage(utms, UTM_CONFIGS.basic.storageKey)
    }

    if (UTM_CONFIGS.advanced.utms.some((utm) => initialState.utm[utm])) {
      const adUtms = getUtmsByKeys(UTM_CONFIGS.advanced.utms, initialState.utm)
      // If there is any advanced utm in the url we will use the url utms
      // Want to reset the update the expiry date of the utms
      saveUtmsToLocalStorage(adUtms, UTM_CONFIGS.advanced.storageKey, UTM_CONFIGS.advanced.timeStamp())
      utms = { ...utms, ...adUtms }
    } else {
      const adUtms = { ...loadUtmsFromLocalStorage(UTM_CONFIGS.advanced.storageKey) }
      saveUtmsToLocalStorage(adUtms, UTM_CONFIGS.advanced.storageKey)
      utms = { ...utms, ...adUtms }
    }
  } else {
    // if there a no new utms in the initial state we will load utms from local storage
    // loadUtmsFromLocalStorage will take care of removing outdated utms
    const basicUtms = loadUtmsFromLocalStorage(UTM_CONFIGS.basic.storageKey)
    const advancedUtms = loadUtmsFromLocalStorage(UTM_CONFIGS.advanced.storageKey)
    saveUtmsToLocalStorage(basicUtms, UTM_CONFIGS.basic.storageKey)
    saveUtmsToLocalStorage(advancedUtms, UTM_CONFIGS.advanced.storageKey)
    utms = { ...basicUtms, ...advancedUtms }
  }

  store.dispatch({
    type: SET_UTM,
    utm: utms,
  })

  return store
}

export default initialiseStore

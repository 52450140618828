import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineArrowLeftIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17 10.9999H9.41l3.3-3.29a1.0041 1.0041 0 1 0-1.42-1.42l-5 5a1.0004 1.0004 0 0 0-.21.33 1 1 0 0 0 0 .76.9994.9994 0 0 0 .21.33l5 5a.9987.9987 0 0 0 .71.2958.9997.9997 0 0 0 .71-.2958.9998.9998 0 0 0 0-1.42l-3.3-3.29H17a.9997.9997 0 0 0 1-1 .9999.9999 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineArrowLeftIcon

import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { getAccommodationPayable } from './accommodation'
import getPayableTotal from './getPayableTotal'
import getAccommodationDepositAmount from './getAccommodationDepositAmount'

const getDepositAmountBeforeCreditApplied = createSelector(
  getPayableTotal,
  getAccommodationPayable,
  getAccommodationDepositAmount,
  (payableTotal, accommodationPayable, depositAmount): number => {
    return floatify(payableTotal - accommodationPayable + depositAmount)
  },
)

export default getDepositAmountBeforeCreditApplied

import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId, getHotelOrTourOffer } from 'analytics/snowplow/helpers/itemCategorisation'

function getTourV1CheckoutItem(state: App.State, item: App.Checkout.LETourV1Item) {
  const offerId = item.offerId
  const offer = getHotelOrTourOffer(state, offerId) as App.Offer
  const { categoryId, productId } = getCategoryAndProductId('tour', offer)
  const offerLeadPrice = offer?.lowestPricePackage?.price
  const offerLeadDuration = offer?.lowestPricePackage?.duration ? String(offer?.lowestPricePackage?.duration) : String(item.duration)
  const offerLeadUnit = offer?.saleUnit
  const [originCity, originCountry] = offer?.startLocation ? offer.startLocation.split(', ') : []
  const [destinationCity, destinationCountry] = offer?.endLocation ? offer.endLocation.split(', ') : []
  const offerPackage = offer.packages.find(p => p.id === item.packageId)

  return comluxgroup.createItem_1_1_0(
    {
      offerId,
      categoryId,
      productId,
      value: offerPackage?.value,
      price: offerPackage?.price,
      typeId: offerPackage?.roomType?.id,
      packageId: item.packageId,
      travelStart: item.startDate,
      travelEnd: item.endDate,
      duration: item.duration,
      numberOfAdults: item.occupancy.adults,
      numberOfChildren: item.occupancy.children,
      numberOfInfants: item.occupancy.infants,
      childrenAges: item.occupancy.childrenAge,
      offerLeadPrice,
      offerLeadDuration,
      offerLeadUnit,
      originCity,
      originCountry,
      destinationCity,
      destinationCountry,
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
    },
  )
}

export default getTourV1CheckoutItem

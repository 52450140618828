import { CHECKOUT_ITEM_TYPE_TOUR_V2 } from 'constants/checkout'
import uuidV4 from 'lib/string/uuidV4Utils'
import { groupBy } from 'lib/array/arrayUtils'
import { countTravellersAsOccupants } from 'lib/offer/occupancyUtils'

export function itemsAreTourV2(
  items: Array<App.Checkout.AccommodationItem>,
): items is Array<App.Checkout.TourV2Item> {
  return items[0].itemType === CHECKOUT_ITEM_TYPE_TOUR_V2
}

export function generateTourItemsFromOriginalItems(
  orderTourItems: Array<Tours.TourV2OrderItem>,
  tourSnapshot: Tours.TourV2OfferSnapshot,
): Array<App.Checkout.TourV2Item> {
  return orderTourItems.flatMap<App.Checkout.TourV2Item>(item => {
    const travellersByRooms = Array.from(groupBy(
      item.tour.travellers,
      (traveller) => traveller.roomNumber,
    ).values())
    return travellersByRooms.map((travellers: Array<Tours.TourV2OrderItemTravellerDetails>) => {
      return {
        itemId: uuidV4(),
        transactionKey: uuidV4(),
        startDate: item.tour.startDate,
        endDate: item.tour.endDate,
        occupancy: countTravellersAsOccupants(travellers),
        duration: item.tour.duration,
        image: { id: tourSnapshot.imageId },
        isOriginalItem: true,
        originalTotal: item.total,
        itemType: CHECKOUT_ITEM_TYPE_TOUR_V2,
        offerId: item.tourId,
        purchasableOption: {
          fkDepartureId: item.departureId,
          fkSeasonId: item.tour.seasonId,
          fkTourId: item.tourId,
          roomType: travellers[0].roomType as Tours.V2OfferRoomType,
          fkVariationId: item.tourOptionId ?? '',
          inventoryLeft: 0,
          fullPrice: 0,
          price: 0,
          memberPrice: 0,
          packageType: item.packageOption?.packageOption,
        },
      }
    })
  })
}

export function updateTourV2CheckoutItemForDeparture(
  tourItem: App.Checkout.TourV2Item,
  departure: Tours.TourV2OfferDeparture,
  purchasableOption: Tours.TourV2OfferPurchasableOption,
  purchasableOptionConfirmed?: boolean,
): App.Checkout.TourV2Item {
  const { startDate, endDate } = departure
  return {
    ...tourItem,
    startDate,
    endDate,
    purchasableOption,
    duration: departure.duration,
    total: purchasableOption.price * tourItem.occupancy.adults,
    memberTotal: purchasableOption.memberPrice * tourItem.occupancy.adults,
    purchasableOptionConfirmed,
    isOriginalItem: false,
  }
}

export function resetItemsFromOriginalItem(
  tourItem: App.Checkout.TourV2Item,
  orderTourItem: Tours.TourV2OrderItem,
): App.Checkout.TourV2Item {
  const resetItem = {
    ...tourItem,
    startDate: orderTourItem.tour.startDate,
    endDate: orderTourItem.tour.endDate,
    isOriginalItem: true,
    purchasableOption: {
      ...tourItem.purchasableOption,
      fkDepartureId: orderTourItem.departureId,
      fullPrice: 0,
      price: 0,
      memberPrice: 0,
    },
  }
  return resetItem
}

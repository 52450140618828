import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'

const Root = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;

  > li {
    &::before {
      position: relative;
      display: inline-block;
    }
  }


  &.spaced {
    > li + li {
      margin-top: ${rem(16)};
    }
  }
`

const Unstyled = styled(Root)`
  margin-left: 0;
`

const StyledUl = styled(Root)`
  > li {
    text-indent: ${rem(-2)};

    &::before {
      position: relative;
      right: ${rem(14)};
      display: inline-block;
      content: "•";
    }
  }
`

const StyledOl = styled(Root)`
  counter-reset: item;

  > li {
    text-indent: ${rem(-4)};

    &::before {
      right: ${rem(12)};
      color: ${props => props.theme.palette.brand.primary.normal};
      text-align: right;
      content: counter(item);
      counter-increment: item;
    }
  }
`

interface Props extends React.HTMLAttributes<HTMLUListElement> {
  type?: 'unstyled' | 'ul' | 'ol';
  spaced?: boolean;
}

function List(props: Props) {
  const {
    className,
    children,
    type,
    spaced,
    ...otherProps
  } = props

  let Tag = StyledUl
  if (type === 'ol') {
    Tag = StyledOl
  } else if (type === 'unstyled') {
    Tag = Unstyled
  }

  return <Tag {...otherProps} className={cn(className, { spaced })}>{children}</Tag>
}

export default List

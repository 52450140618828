import { Interceptor } from 'api/requestUtils'
import config from 'constants/config'
import { isTargetEndpoint } from '../../lib/sherlog/targetEndpoints'
import { logNetworkResponse } from '../../actions/SherLogActions'

export default function sherLogResponseLogger(
  logger: Utils.ActionDispatcher<typeof logNetworkResponse>,
): Interceptor {
  return (f, response) => f.then(result => {
    if (isTargetEndpoint(response.url)) {
      logger({
        requestName: response.url.replace(`${config.SCHEME}://${config.API_HOST}`, ''),
        responseBody: result.result,
        status: response.status,
      })
    }
    return result
  })
}

import requestUtils, { authOptions } from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'
import { agentHubAccountMapper } from './mappers/agentHubMap'

type GetAccountAgentResponse = definitions['userSimpleSchema']

async function requestGetAccountAgent(token?: string): Promise<App.AgentHubAccount> {
  const response = await requestUtils.get<App.ApiResponse<GetAccountAgentResponse>>('/api/agent/me', authOptions(token))
  return agentHubAccountMapper(response.result)
}

export default requestGetAccountAgent

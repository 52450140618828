import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineToasterIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M16.4867 14.0273c-1.3733 0-2.5101 1.1016-2.5101 2.4895 0 1.3878 1.1368 2.4894 2.5101 2.4894s2.5101-1.1016 2.5101-2.4894c0-1.3879-1.1368-2.4895-2.5101-2.4895Zm-.5101 2.4895c0-.2574.2153-.4895.5101-.4895s.5101.2321.5101.4895c0 .2573-.2153.4894-.5101.4894s-.5101-.2321-.5101-.4894Z" clipRule="evenodd"/>
    <path fill="currentColor" d="M9.082 14.041h.847c.5522 0 1-.4477 1-1s-.4478-1-1-1H6.4023c-.5522 0-1 .4477-1 1s.4478 1 1 1h.6797v3.946c0 .5523.4478 1 1 1 .5523 0 1-.4477 1-1v-3.946Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M4.4023 4.8914C4.4023 3.2945 5.697 2 7.2938 2h9.4138c1.5963 0 2.8903 1.294 2.8903 2.8903 0 1.2667-.8225 2.3738-2.0093 2.7528v.5551C20.0726 8.4895 22 10.6017 22 13.164v5.8356c0 1.6568-1.3431 3-3 3H5c-1.6568 0-3-1.3432-3-3V13.164c0-2.5579 1.9207-4.6671 4.3985-4.9642v-.5589c-1.1799-.3834-1.9962-1.4874-1.9962-2.7495Zm11.1863 1.959V8.164h-7.19V6.8505a1 1 0 0 0-.7864-.977l-.5089-.1112A.8914.8914 0 0 1 7.2938 4h9.4138a.8903.8903 0 0 1 .1891 1.7602l-.5205.113a1 1 0 0 0-.7876.9773ZM7 10.164c-1.6569 0-3 1.3431-3 3v5.8356c0 .5523.4477 1 1 1h14c.5523 0 1-.4477 1-1V13.164c0-1.6569-1.3431-3-3-3H7Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineToasterIcon

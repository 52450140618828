import React, { useCallback } from 'react'
import SwitchButton from 'components/Common/SwitchButton'
import { toggleMultiCartItemMode } from 'actions/CheckoutActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import { connect } from 'react-redux'
import Group from '../../../../utils/Group'
import BodyText from '../../../../Luxkit/Typography/BodyText'

interface MappedProps {
  isMultiItemMode: boolean;
}

function MultiCartItemModeToggle({
  isMultiItemMode,
}: MappedProps) {
  const dispatch = useAppDispatch()

  const onToggleMultiItemMode = useCallback(() => {
    dispatch(toggleMultiCartItemMode())
  }, [dispatch])

  return (
    <Group direction="horizontal" gap={8} verticalAlign="center">
      <BodyText variant="medium" colour="neutral-one"> Add to cart</BodyText>
      <SwitchButton
        toggled={isMultiItemMode}
        onToggle={onToggleMultiItemMode}
      />
    </Group>
  )
}

function mapStateToProps(state: App.State) {
  return {
    isMultiItemMode: state.checkout.cart.isMultiItemMode,
  }
}

export default connect(mapStateToProps)(MultiCartItemModeToggle)

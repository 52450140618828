import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineArrowRightIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17.92 11.6199a1.0012 1.0012 0 0 0-.21-.33l-5-5a1.0037 1.0037 0 0 0-.71-.294 1.0041 1.0041 0 0 0-.71 1.714l3.3 3.29H7a1.0002 1.0002 0 0 0-.7071 1.7071.9997.9997 0 0 0 .7071.2929h7.59l-3.3 3.29a1.0012 1.0012 0 0 0-.2958.71.999.999 0 0 0 .2958.71.9987.9987 0 0 0 .71.2958.9997.9997 0 0 0 .71-.2958l5-5a1.0002 1.0002 0 0 0 .21-.33c.1-.2434.1-.5165 0-.76Z"/>
  </SvgIcon>
}

export default LineArrowRightIcon

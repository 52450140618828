import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGlassIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19.75 2.33A1.0003 1.0003 0 0 0 19 2H5a1.0001 1.0001 0 0 0-.75.33 1 1 0 0 0-.25.78l1.8 16.22a3 3 0 0 0 3 2.67h6.42a2.9998 2.9998 0 0 0 3-2.67L20 3.11a.999.999 0 0 0-.25-.78ZM16.2 19.11a.9998.9998 0 0 1-1 .89H8.79a.9998.9998 0 0 1-1-.89L6.78 10h10.44l-1.02 9.11ZM17.44 8H6.56l-.44-4h11.76l-.44 4Z"/>
  </SvgIcon>
}

export default LineGlassIcon

import watch from 'redux-watch'
import { AppStore } from 'src/client/store'
import {
  saveCheckoutReduxCartState,
  saveCheckoutReduxFormState,
  saveCheckoutReduxPaymentState,
} from 'storage/checkout'

export function watchCheckout(store: AppStore) {
  store.subscribe(
    watch(store.getState, 'checkout')(async(newState: App.CheckoutState, oldState: App.CheckoutState) => {
      if (newState.cart !== oldState.cart && !newState.cart.isAbandonedCart) {
        const cart = {
          ...newState.cart,
          lastSaveTimestamp: Date.now(),
        }
        saveCheckoutReduxCartState(cart)
      }
      if (newState.form !== oldState.form) {
        saveCheckoutReduxFormState(newState.form)
      }
      if (newState.payment !== oldState.payment) {
        if (newState.promo?.code) {
          newState.payment.promoCode = newState.promo?.code
        }
        saveCheckoutReduxPaymentState(newState.payment)
      }
    }),
  )
}

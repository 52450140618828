import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import { getTourV2SavingsWithLuxPlus } from 'lib/tours/tourUtils'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'
import React, { useMemo } from 'react'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
  variant: 'earlyAccess' | 'memberPrice';
}

function TourV2LuxPlusUrgencyTag(props: Props) {
  const { offer, variant } = props

  const { cheapestPurchasableOption } = useCheapestOfTourV2Offer(offer)
  const cheapestPurchasableOptionSaving = cheapestPurchasableOption ? getTourV2SavingsWithLuxPlus(cheapestPurchasableOption) : undefined

  const nonCheapestPurchasableOptionSaving = useMemo(() => {
    if (!cheapestPurchasableOptionSaving) { // the cheapest purchasable option doesn't offer LuxPlus benefits
      const purchasableOption = offer?.purchasableOptions.find((purchasableOption) => getTourV2SavingsWithLuxPlus(purchasableOption))
      if (purchasableOption) {
        return getTourV2SavingsWithLuxPlus(purchasableOption)
      }
    }
  }, [cheapestPurchasableOptionSaving, offer?.purchasableOptions])

  const savingsWithLuxPlus = cheapestPurchasableOptionSaving || nonCheapestPurchasableOptionSaving

  let labelType: App.LuxPlusLabelType = 'member-price-offer-tour'
  if (!savingsWithLuxPlus) {
    labelType = 'options-available-tour'
  }

  if (variant === 'earlyAccess') {
    labelType = 'early-access-offer'
  }

  return <LuxPlusLabelWithModal
    hideSaveAmountPlus
    offer={offer}
    saveAmount={savingsWithLuxPlus}
    type={labelType}
  />
}

export default TourV2LuxPlusUrgencyTag

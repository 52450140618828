import React from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { SvgIconProps } from '../SvgIcon'
import LineAngleRightIcon from '../Icons/line/LineAngleRightIcon'

const RotatingChevron = styled(LineAngleRightIcon)`
  flex-shrink: 0;
  /* turn it downwards when closed */
  transform: rotate(90deg);
  transition: transform 0.2s ease-out;

  &.open {
    /* turn it upwards when open */
    transform: rotate(-90deg);
  }

  &.right {
    transform: rotate(0);
  }

  &.left {
    transform: rotate(180deg);
  }

  &.up {
    transform: rotate(-90deg);
  }

  &.down {
    transform: rotate(90deg);
  }
`

interface Props extends SvgIconProps {
  open?: boolean;
  direction?: 'up' | 'right' | 'left' | 'down';
}

function DropdownChevron(props: Props) {
  const { className, open, direction, ...rest } = props
  return <RotatingChevron {...rest} className={cn(className, direction, { open })} />
}

export default DropdownChevron

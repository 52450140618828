import React from 'react'

interface Props {
  width: string;
  height: string;
}

function GoogleLongLogo({ width, height }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 74 25" fill="none">
      <g clipPath="url(#clip0_3065_140311)">
        <path d="M31.3766 12.8207C31.3766 16.2908 28.6619 18.8479 25.3304 18.8479C21.9989 18.8479 19.2842 16.2908 19.2842 12.8207C19.2842 9.32613 21.9989 6.79352 25.3304 6.79352C28.6619 6.79352 31.3766 9.32613 31.3766 12.8207ZM28.7298 12.8207C28.7298 10.6522 27.1565 9.16852 25.3304 9.16852C23.5043 9.16852 21.9309 10.6522 21.9309 12.8207C21.9309 14.9674 23.5043 16.4729 25.3304 16.4729C27.1565 16.4729 28.7298 14.9647 28.7298 12.8207Z" fill="#EA4335"/>
        <path d="M44.3756 12.8207C44.3756 16.2908 41.6609 18.8479 38.3294 18.8479C34.9979 18.8479 32.2832 16.2908 32.2832 12.8207C32.2832 9.32884 34.9979 6.79352 38.3294 6.79352C41.6609 6.79352 44.3756 9.32613 44.3756 12.8207ZM41.7289 12.8207C41.7289 10.6522 40.1555 9.16852 38.3294 9.16852C36.5033 9.16852 34.9299 10.6522 34.9299 12.8207C34.9299 14.9674 36.5033 16.4729 38.3294 16.4729C40.1555 16.4729 41.7289 14.9647 41.7289 12.8207Z" fill="#FBBC05"/>
        <path d="M57.0407 7.15765V17.9783C57.0407 22.4294 54.4157 24.2473 51.3124 24.2473C48.3912 24.2473 46.6331 22.2935 45.97 20.6957L48.2744 19.7365C48.6847 20.7174 49.6901 21.875 51.3097 21.875C53.2961 21.875 54.5271 20.6495 54.5271 18.3424V17.4756H54.4347C53.8423 18.2066 52.701 18.8451 51.2608 18.8451C48.2472 18.8451 45.4863 16.2201 45.4863 12.8424C45.4863 9.44026 48.2472 6.79352 51.2608 6.79352C52.6983 6.79352 53.8396 7.4321 54.4347 8.14134H54.5271V7.16037H57.0407V7.15765ZM54.7146 12.8424C54.7146 10.7201 53.2988 9.16852 51.4972 9.16852C49.6711 9.16852 48.1412 10.7201 48.1412 12.8424C48.1412 14.943 49.6711 16.4729 51.4972 16.4729C53.2988 16.4729 54.7146 14.943 54.7146 12.8424Z" fill="#4285F4"/>
        <path d="M61.1845 0.815002V18.478H58.603V0.815002H61.1845Z" fill="#34A853"/>
        <path d="M71.2448 14.8043L73.2991 16.1739C72.6361 17.1548 71.0382 18.8451 68.2774 18.8451C64.8535 18.8451 62.2964 16.1983 62.2964 12.8179C62.2964 9.23364 64.8752 6.79071 67.9812 6.79071C71.1089 6.79071 72.6388 9.27984 73.1388 10.6249L73.4132 11.3097L65.3562 14.6467C65.973 15.8559 66.9323 16.4728 68.2774 16.4728C69.6252 16.4728 70.56 15.8097 71.2448 14.8043ZM64.9214 12.6358L70.3073 10.3994C70.0111 9.64669 69.1198 9.12223 68.0708 9.12223C66.7257 9.12223 64.8535 10.3097 64.9214 12.6358Z" fill="#EA4335"/>
        <path d="M9.63296 11.2529V8.69586H18.2498C18.334 9.14152 18.3775 9.66869 18.3775 10.2393C18.3775 12.1578 17.8531 14.5301 16.1629 16.2203C14.5188 17.9323 12.4183 18.8453 9.63568 18.8453C4.47807 18.8453 0.141113 14.6442 0.141113 9.48662C0.141113 4.32902 4.47807 0.12793 9.63568 0.12793C12.4889 0.12793 14.5215 1.24749 16.0487 2.70673L14.2444 4.51108C13.1493 3.48391 11.6656 2.68499 9.63296 2.68499C5.86666 2.68499 2.92101 5.72032 2.92101 9.48662C2.92101 13.2529 5.86666 16.2883 9.63296 16.2883C12.0759 16.2883 13.4672 15.3073 14.3585 14.416C15.0813 13.6931 15.5569 12.6605 15.7444 11.2502L9.63296 11.2529Z" fill="#4285F4"/>
      </g>
      <defs>
        <clipPath id="clip0_3065_140311">
          <rect width="73.913" height="25" fill="white" transform="translate(0.043457 0.00012207)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default GoogleLongLogo

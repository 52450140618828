import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGolfIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11.2841 17.8713c-.1816-.7161.2622-1.4524.9884-1.6239.7162-.1816 1.4525.2622 1.624.9885.1815.7161-.2623 1.4524-.9885 1.6239-.7161.1815-1.4525-.2623-1.6239-.9885Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M17.4164 3.0137v10.9539c3.2378.7665 5.0836 2.1383 5.0836 3.8429 0 2.915-5.2853 4.4381-10.5 4.4381S1.5 20.7255 1.5 17.8105c0-2.915 5.2853-4.438 10.5-4.438 1.0893 0 2.4308.1008 3.3991.2118v-2.7435L9.0346 7.9561c-.343-.1513-.575-.4943-.5951-.8675-.0202-.3833.1715-.7363.5043-.928l6.9597-4.0244a1.002 1.002 0 0 1 1.0086 0 1.012 1.012 0 0 1 .5043.8775Zm-2.0173 1.745L11.647 6.9272l3.7521 1.6946V4.7586ZM3.5072 17.8104c0 .8574 3.2176 2.4208 8.4827 2.4208 5.2651 0 8.4827-1.5634 8.4827-2.4208 0-.3328-.8069-1.1599-3.0663-1.7752v.8271c0 .5547-.4539 1.0086-1.0086 1.0086-.5548 0-1.0087-.4539-1.0087-1.0086v-1.2507c-.938-.111-2.3299-.2219-3.3991-.2219-5.2651 0-8.4827 1.5634-8.4827 2.4207Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineGolfIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSwimmerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17 9.28a2.0001 2.0001 0 0 0 1.4142-3.4142 1.9997 1.9997 0 0 0-3.0771.303 1.9998 1.9998 0 0 0 .2487 2.5254A2 2 0 0 0 17 9.28ZM2.71 13.92c.1982.0634.3892.1472.57.25a4.0602 4.0602 0 0 0 4.1 0 2.6207 2.6207 0 0 1 2.56 0l.21.1a4.14 4.14 0 0 0 3.87-.13 2.6208 2.6208 0 0 1 2.56 0 4.2496 4.2496 0 0 0 2.08.56 3.9997 3.9997 0 0 0 2-.56 3.009 3.009 0 0 1 .57-.25.9989.9989 0 0 0 .7471-.4192.9998.9998 0 0 0-.4107-1.4927A1.0004 1.0004 0 0 0 20.71 12a4.8211 4.8211 0 0 0-1 .44 2.1002 2.1002 0 0 1-2.1 0 4.6197 4.6197 0 0 0-4.54 0 2.5103 2.5103 0 0 1-.29.12L14.34 11a.7433.7433 0 0 0 .09-.15.9946.9946 0 0 0 .12-.16 1.2819 1.2819 0 0 0 0-.19 1.0577 1.0577 0 0 0 0-.19 1.1268 1.1268 0 0 0 0-.18 1.0574 1.0574 0 0 0 0-.19 1.5113 1.5113 0 0 0-.1-.17.6981.6981 0 0 0-.1-.16l-3.07-3.33a1.1 1.1 0 0 0-.28-.22.7998.7998 0 0 0-.21 0 .5298.5298 0 0 0-.17 0A.89.89 0 0 0 10.2 6L6.66 7.32a1 1 0 0 0-.6 1.28 1 1 0 0 0 1.28.6l2.93-1.07 2 2.12-1.9 1.9a4.6212 4.6212 0 0 0-3.94.28 2.1002 2.1002 0 0 1-2.1 0 4.82 4.82 0 0 0-1-.44 1.0003 1.0003 0 0 0-1.3431 1.0502A1.0002 1.0002 0 0 0 2.75 13.9l-.04.02Zm18 3.09a4.8211 4.8211 0 0 0-1 .44 2.1002 2.1002 0 0 1-2.1 0 4.6197 4.6197 0 0 0-4.54 0 2.1093 2.1093 0 0 1-2.12 0 4.6198 4.6198 0 0 0-4.54 0 2.1002 2.1002 0 0 1-2.1 0 4.82 4.82 0 0 0-1-.44 1.0003 1.0003 0 0 0-1.3431 1.0502 1.0002 1.0002 0 0 0 .7631.8598c.1982.0634.3892.1472.57.25a4.0602 4.0602 0 0 0 4.1 0 2.6207 2.6207 0 0 1 2.56 0 4.15 4.15 0 0 0 4.12 0 2.6208 2.6208 0 0 1 2.56 0 4.2496 4.2496 0 0 0 2.08.56 3.9997 3.9997 0 0 0 2-.56 3.009 3.009 0 0 1 .57-.25 1.0032 1.0032 0 0 0 .5939-.4862 1.0024 1.0024 0 0 0 .0761-.7638 1.0041 1.0041 0 0 0-.4862-.5939A1.0042 1.0042 0 0 0 20.71 17v.01Z"/>
  </SvgIcon>
}

export default LineSwimmerIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCookingPanIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6.044 3.2976s-.0069.007.0008-.0009c.3884-.3926 1.0216-.396 1.4142-.0076.3894.3851.396 1.0109.0174 1.4042a.7014.7014 0 0 0-.016.0186 1.6184 1.6184 0 0 0-.0946.1261c-.0832.122-.1942.3131-.284.5672-.1734.4906-.2876 1.2666.116 2.3232.6196 1.6228.2705 2.9586-.2548 3.8696a4.848 4.848 0 0 1-.776 1.0071 4.3577 4.3577 0 0 1-.2926.2665 3.1564 3.1564 0 0 1-.1266.0994l-.0113.0083-.0045.0032-.002.0014-.0017.0013c-.45.3202-1.0743.215-1.3945-.235-.3186-.4477-.2161-1.068.2281-1.3897l.0026-.0019a1.2466 1.2466 0 0 0 .035-.0281c.035-.0289.0903-.077.157-.1434a2.8484 2.8484 0 0 0 .4539-.5881c.2794-.4846.4877-1.1914.119-2.157-.585-1.5318-.4531-2.7983-.1333-3.7033.1569-.4437.3543-.7885.5178-1.028a3.612 3.612 0 0 1 .2139-.2838 2.6077 2.6077 0 0 1 .0995-.112l.0098-.0103.0042-.0042.0018-.002.0009-.0008Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M3 15.2168c-.5523 0-1 .4477-1 1 0 3.1991 2.0181 4.5527 3.3566 4.7704.053.0086.1067.0129.1605.0129h7.9485a.9996.9996 0 0 0 .3883-.0784c1.5403-.6489 2.2751-1.9748 2.5765-3.0908H21c.5523 0 1-.4477 1-1s-.4477-1-1-1h-4.4798a1.0004 1.0004 0 0 0-.9227-.6141H3Zm1.105 2h10.4207c-.1591.6735-.5529 1.402-1.2818 1.7833H5.6131c-.385-.091-1.215-.5108-1.508-1.7833Z" clipRule="evenodd"/>
    <path fill="currentColor" d="m9.6495 3.2976-.0009.0009-.0019.0019-.004.0042-.01.0103a2.6077 2.6077 0 0 0-.0995.112 3.612 3.612 0 0 0-.2138.2837c-.1636.2396-.361.5844-.5178 1.0281-.3198.905-.4517 2.1715.1332 3.7033.3688.9656.1604 1.6724-.119 2.157a2.8488 2.8488 0 0 1-.4538.5881 2.3486 2.3486 0 0 1-.157.1434 1.2572 1.2572 0 0 1-.035.0281l-.0026.0019c-.4442.3217-.5468.942-.2282 1.3897.3202.45.9446.5552 1.3946.235l.0017-.0013.002-.0014.0045-.0032.0113-.0083a2.9005 2.9005 0 0 0 .1266-.0994 4.3577 4.3577 0 0 0 .2925-.2665 4.849 4.849 0 0 0 .7761-1.0071c.5252-.911.8744-2.2468.2547-3.8696-.4035-1.0566-.2893-1.8326-.1159-2.3232.0898-.254.2008-.4452.284-.5672a1.6118 1.6118 0 0 1 .0945-.1261c.0082-.0098.0138-.016.0161-.0186.3786-.3933.3719-1.019-.0174-1.4042-.3926-.3884-1.0258-.385-1.4142.0076l-.0008.0009ZM13.2597 3.2967c.3884-.3926 1.0215-.396 1.4142-.0076.3893.3851.396 1.0109.0174 1.4042-.0023.0026-.0079.0088-.0161.0186a1.6118 1.6118 0 0 0-.0945.1261c-.0833.122-.1942.3131-.284.5672-.1734.4906-.2876 1.2666.1159 2.3232.6197 1.6228.2705 2.9586-.2547 3.8696-.2555.443-.5478.78-.7761 1.0071a4.3389 4.3389 0 0 1-.2926.2665 3.0819 3.0819 0 0 1-.1265.0994l-.0113.0083-.0045.0032-.002.0014-.0009.0007-.0009.0006c-.4499.3202-1.0743.215-1.3945-.235-.3186-.4477-.2161-1.068.2282-1.3897l.0025-.0019a1.2329 1.2329 0 0 0 .0351-.0281 2.3693 2.3693 0 0 0 .157-.1434 2.8474 2.8474 0 0 0 .4538-.5881c.2794-.4846.4877-1.1914.119-2.157-.585-1.5318-.4531-2.7983-.1332-3.7033.1568-.4437.3542-.7885.5177-1.028a3.635 3.635 0 0 1 .2139-.2838 2.7402 2.7402 0 0 1 .0995-.112l.0099-.0103.0041-.0042.0019-.002.0017-.0017Z"/>
  </SvgIcon>
}

export default LineCookingPanIcon

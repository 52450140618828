import { API_CALL_SUCCESS } from 'actions/actionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import { FETCH_RELATED_TRAVEL_DESTINATIONS } from 'actions/apiActionConstants'

export const initialDestinationProductPageState: App.DestinationProductPageState = {
  relatedTravelDestinations: {},
}

const apiSuccesses = reducerSwitch<App.DestinationProductPageState>({
  [FETCH_RELATED_TRAVEL_DESTINATIONS]: (state, action) => ({
    relatedTravelDestinations: {
      ...state.relatedTravelDestinations,
      [action.location]: action.data,
    },
  }),
})

const destinationProductPageReducer = createReducer<App.DestinationProductPageState>(initialDestinationProductPageState, {
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

export default destinationProductPageReducer

import { KNOWN_SEARCH_VERTICALS, SEARCH_VERTICALS } from 'constants/search'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

interface SearchVerticalToggleOptions {
  withDateSelect?: boolean
  search?: string
}

export default function useGlobalSearchURLHashVertical() {
  const history = useHistory()
  const location = useLocation()
  const { openedSearchVertical, option } = useMemo<{openedSearchVertical: SEARCH_VERTICALS | undefined, option: string}>(() => {
    if (!location.hash.startsWith('#search_')) {
      return { openedSearchVertical: undefined, option: undefined }
    }
    const [, searchVerticalFromHash, option] = location.hash.split('_')
    if (!KNOWN_SEARCH_VERTICALS.has(searchVerticalFromHash)) {
      return { openedSearchVertical: undefined, option: undefined }
    }
    return { openedSearchVertical: searchVerticalFromHash as SEARCH_VERTICALS, option }
  }, [location.hash])

  const toggleSearchVertical = useCallback((
    searchVertical: SEARCH_VERTICALS | 'none',
    options?: SearchVerticalToggleOptions,
  ) => {
    if (searchVertical === 'none') {
      history.replace({ ...location, hash: '' })
    } else {
      let hash = `search_${searchVertical}`
      let search = location.search
      if (options?.withDateSelect) {
        hash += '_dateSelect'
      }
      if (options?.search) {
        search = options.search
      }
      history.replace({ ...location, hash, search })
    }
  }, [history, location])

  return { openedSearchVertical, toggleSearchVertical, option }
}

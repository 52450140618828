import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineExclamationCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 7a1 1 0 0 0-1 1v4a1.0001 1.0001 0 0 0 2 0V8a1.0002 1.0002 0 0 0-1-1Zm.92 8.62a.758.758 0 0 0-.09-.18l-.12-.15a.9995.9995 0 0 0-1.09-.21 1.1495 1.1495 0 0 0-.33.21A1.0003 1.0003 0 0 0 11 16a1.001 1.001 0 0 0 .08.38.8999.8999 0 0 0 .54.54.94.94 0 0 0 .76 0 .8999.8999 0 0 0 .54-.54A1.001 1.001 0 0 0 13 16a1.3624 1.3624 0 0 0 0-.2.6388.6388 0 0 0-.08-.18ZM12 2a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm0 18a8.0005 8.0005 0 0 1-7.391-4.9385A8 8 0 1 1 12 20Z"/>
  </SvgIcon>
}

export default LineExclamationCircleIcon

import {
  getInsuranceCountries,
  getInsuranceCountryNames,
  getInsuranceCountryNameByTwoLetterCode,
} from '@luxuryescapes/lib-regions'
import { INSURANCE_VALID_COUNTRIES } from 'constants/config/region'
import { arrayToObject } from 'lib/array/arrayUtils'

export const countryNameList = getInsuranceCountryNames()
type InsuranceCountry = ReturnType<typeof getInsuranceCountries>[0]
const countries: Array<InsuranceCountry> = getInsuranceCountries()
const countriesByName = arrayToObject(countries, country => country.name)

export function findByName(name: string): InsuranceCountry | undefined {
  return countriesByName[name]
}

export function findByTwoLetterCode(code: string): string | undefined {
  return getInsuranceCountryNameByTwoLetterCode(code)
}

export function isValidInsuranceCountry(country: string | undefined): boolean {
  if (!country) return false
  const result = findByName(country)
  return !!result
}

export function isValidInsuranceRegion(region: string): boolean {
  return INSURANCE_VALID_COUNTRIES.includes(region)
}

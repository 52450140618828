import React, { FormEventHandler, useCallback, useMemo } from 'react'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import useToggle from 'hooks/useToggle'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Group from 'components/utils/Group'
import TextInput from 'components/Common/Form/Input/TextInput'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import Select from 'components/Common/Form/Input/Select'
import { getData as getCountries } from 'country-list'
import TextArea from 'components/Common/Form/Input/TextArea'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { connect } from 'react-redux'
import { formToObject } from 'lib/forms/formToObject'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { sendRequestCallbackFormForCruise } from 'api/cruises'
import { PICKER_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { replaceWithRegion } from 'actions/NavigationActions'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { useAppDispatch } from 'hooks/reduxHooks'

const FormFieldsContainer = styled.div`
  margin-top: ${rem(16)};
  ${mediaQueryUp.desktop} {
    display: grid;
    grid-column-gap: ${rem(12)};
    grid-template-columns: 1fr 1fr;

    .full-width-column {
      grid-column: span 2;
    }
  }
`

interface Props {
  firstName?: string;
  lastName?: string;
  phone?: string;
  phonePrefix?: string;
  email?: string;
  countryCode?: string;
  customerId?: string;
  offers?: { [offerId: string]: App.CruiseOffer }
  offerId?: string;
  departureDate?: string;
}

function CruiseRequestCallbackFormModal({
  firstName,
  lastName,
  phone,
  phonePrefix,
  email,
  countryCode,
  customerId,
  offers,
  offerId,
  departureDate,
}: Props) {
  const dispatch = useAppDispatch()
  const modalState = useModalElementContext<unknown>()
  const [isOpen, setIsOpen] = useToggle(true)

  const offer = useMemo(() => {
    if (!offers) return null

    return offerId ? offers[offerId] : Object.values(offers)[0]
  }, [offers, offerId])

  const sailingOfInterest = useMemo(() => {
    if (offer && departureDate) {
      return `${offer?.name} - ${moment(departureDate).format(PICKER_DATE_FORMAT)}`
    }

    return ''
  }, [departureDate, offer])

  const sendRequest = useCallback<FormEventHandler<HTMLFormElement>>(async(e) => {
    e.preventDefault()

    const requestForm: {
      firstName: string
      lastName: string
      customerEmail: string
      customerPhone: { prefix: string; phone: string }
      customerCountry: string
      variation: string
      paxAndCabinsMessage: string
      additionalMessage: string
      offerId: string
      customerId: string
    } = formToObject(e.currentTarget)

    const fullForm: Cruises.CruiseRequestCallbackForm = {
      customerId,
      sailingOfInterest,
      offerId: offer?.id || '',
      customerName: `${requestForm.firstName} ${requestForm.lastName}`,
      customerPhone: `+${requestForm.customerPhone.prefix} ${requestForm.customerPhone.phone}`,
      customerEmail: requestForm.customerEmail,
      customerCountry: requestForm.customerCountry,
      paxAndCabinsMessage: requestForm.paxAndCabinsMessage,
      additionalMessage: requestForm.additionalMessage,
    }
    const response = await sendRequestCallbackFormForCruise(fullForm)
    modalState.resolve(response)
    showSnackbar('Your enquiry has been sent.', 'success')
    setIsOpen()
    const offerPageUrl = `/cruises/${offer?.slug}`
    dispatch(replaceWithRegion(offerPageUrl))
  }, [customerId, sailingOfInterest, offer?.id, offer?.slug, modalState, setIsOpen, dispatch])

  return <ModalBase height="auto" isOpen={isOpen} onClose={setIsOpen}>
    <form onSubmit={sendRequest}>
      <ModalHeader
        onCloseButtonClick={setIsOpen}
        subtitle="Please let us know your details and our Cruise Concierge will call you to arrange your multi-cabin booking."
        title="Request a call back"/>
      <ModalBody>
        <ModalContent>
          <FormFieldsContainer>
            <HiddenInput name="offerId" value={offer?.id} />
            {customerId && <HiddenInput name="customerId" value={customerId} />}
            <TextInput
              label="First name"
              name="firstName"
              required
              placeholder="First name"
              defaultValue={firstName}
            />
            <TextInput
              label="Last name"
              name="lastName"
              required
              placeholder="Last name"
              defaultValue={lastName}
            />
            <TextInput
              className="full-width-column"
              type="email"
              label="Email address"
              name="customerEmail"
              required
              defaultValue={email}
            />
            <PhoneInput
              label="Mobile number"
              name="customerPhone"
              id="mobileNumber"
              required
              defaultPhoneNumber={phone}
              defaultPhonePrefix={phonePrefix}
              prefixCountry={countryCode}
            />
            <Select
              label="Your home country"
              name="customerCountry"
              defaultValue={countryCode}
              required
              noValidationSpacing
            >
              {getCountries().map(country =>
                <option key={country.code} value={country.code}>{country.name}</option>,
              )}
            </Select>
            <Select
              className="full-width-column"
              label="Which cruise are you interested in?"
              name="variation"
              defaultValue={offer?.id}
              required
              disabled={!!offer?.id}>
              <option key={offer?.id} value={offer?.id}>{sailingOfInterest}</option>,
            </Select>
            <TextArea
              className="full-width-column"
              name="paxAndCabinsMessage"
              required
              resize="vertical"
              label="How many passengers and cabins are you wanting to book?"
              placeholder=""
              maxLength={150}
            />
            <TextArea
              className="full-width-column"
              name="additionalMessage"
              noValidationSpacing
              resize="vertical"
              label="Any other questions or comments?"
              placeholder="eg. Do I need a vaccine? I wish to travel with children ..."
              maxLength={150}
            />
          </FormFieldsContainer>
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <Group direction="horizontal" horizontalAlign="end" verticalAlign="center">
          <TextButton type="submit" kind="primary">Submit Request</TextButton>
        </Group>
      </ModalFooter>
    </form>
  </ModalBase>
}

const mapStateToProps = (state: App.State) => {
  return {
    firstName: state.auth.account.givenName,
    lastName: state.auth.account.surname,
    phone: state.auth.account.phone,
    phonePrefix: state.auth.account.phonePrefix,
    email: state.auth.account.email,
    countryCode: state.geo.currentRegionCode,
    customerId: state.auth.account.memberId,
    offers: state.cruise.cruiseOffers,
  }
}
export default connect(mapStateToProps)(CruiseRequestCallbackFormModal)

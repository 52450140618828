export default function uuidV4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0; const v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

const nonIncrementCharIndices = new Set([8, 13, 14, 18, 19, 23])

/**
 * Increment a UUID by 1.
 * Used to deterministically generate several unique UUIDs from one UUID.
 * Not quite a perfect implementation - there are 2 bits that get ignored
 * because they reside within the same hex char as the UUID variant field.
 * But it's good enough for our needs.
 */
export function incrementUuidV4(uuid: string) {
  if (uuid.length !== 36) { throw new Error('Invalid UUID') }
  const chars = uuid.split('')

  for (let i = 35; i >= 0; i--) {
    if (nonIncrementCharIndices.has(i)) { continue }

    const val = (parseInt(chars[i], 16) + 1) % 16
    chars[i] = val.toString(16)

    if (val > 0) {
      break
    }
  }

  return chars.join('')
}

export const OfferContextMap = {
  UPGRADE_MODAL_OPENED: 'UPGRADE_MODAL_OPENED',
  UPGRADE_MODAL_REJECTED: 'UPGRADE_MODAL_REJECTED',
  UPGRADE_MODAL_CLOSED: 'UPGRADE_MODAL_CLOSED',
  UPGRADE_MODAL_ACCEPTED: 'UPGRADE_MODAL_ACCEPTED',
  BOOK_NOW_PRESSED: 'BOOK_NOW_PRESSED',
  BUY_NOW_CHOOSE_DATES_LATER_PRESSED: 'BUY_NOW_CHOOSE_DATES_LATER_PRESSED',
  DURATION_PRESSED: 'DURATION_PRESSED',
  OCCUPANCY_FILTERS_APPLIED: 'OCCUPANCY_FILTERS_APPLIED',
  OCCUPANCY_FILTERS_PRESSED: 'OCCUPANCY_FILTERS_PRESSED',
  OCCUPANCY_FILTERS_CLOSED: 'OCCUPANCY_FILTERS_CLOSED',
  CAPACITY_HOTEL_FILTER_OPENED: 'CAPACITY_HOTEL_FILTER_OPENED',
  CALENDAR_MONTH_SELECTED: 'CALENDAR_MONTH_SELECTED',
  CALENDAR_CHECK_IN_SELECTED: 'CALENDAR_CHECK_IN_SELECTED',
  CALENDAR_TOGGLE_PRESSED: 'CALENDAR_TOGGLE_PRESSED',
  CALENDAR_FILTER_OPENED: 'CALENDAR_FILTER_OPENED',
  CALENDAR_DATE_CHANGED: 'CALENDAR_DATE_CHANGED',
  CALENDAR_CLOSED: 'CALENDAR_CLOSED',
  CALENDAR_FILTERS_APPLY: 'CALENDAR_FILTERS_APPLY',
  CALENDAR_HOTEL_TOGGLED: 'CALENDAR_HOTEL_TOGGLED',
  CALENDAR_SUB_FILTER_OPENED: 'CALENDAR_SUB_FILTER_OPENED',
  CALENDAR_SUB_FILTER_CLOSED: 'CALENDAR_SUB_FILTER_CLOSED',
  VIEW_PACKAGE_OPTIONS_PRESSED: 'VIEW_PACKAGE_OPTIONS_PRESSED',
  AIRPORT_FILTER_SELECTED: 'AIRPORT_FILTER_SELECTED',
  AIRPORT_FILTER_CLOSED: 'AIRPORT_FILTER_CLOSED',
  AIRPORT_FILTER_PRESSED: 'AIRPORT_FILTER_PRESSED',
  CHECK_AVAILABILITY_PRESSED: 'CHECK_AVAILABILITY_PRESSED',
  HOTEL_NAME_SELECTED: 'HOTEL_NAME_SELECTED',
  DURATION_FILTER_PRESSED: 'DURATION_FILTER_PRESSED',
  DURATION_FILTER_CLOSED: 'DURATION_FILTER_CLOSED',
  DURATION_FILTER_SELECTED: 'DURATION_FILTER_SELECTED',
  VIEW_TOUR_OPTIONS_PRESSED: 'VIEW_TOUR_OPTIONS_PRESSED',
  VIEW_TOUR_OPTIONS_EXTENDED_PRESSED: 'VIEW_TOUR_OPTIONS_EXTENDED_PRESSED',
  VIEW_TOUR_OPTIONS_EXTENDED_ALT_PRESSED: 'VIEW_TOUR_OPTIONS_EXTENDED_ALT_PRESSED',
} as const
export type OfferContextEvent = typeof OfferContextMap[keyof typeof OfferContextMap]

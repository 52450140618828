import IsomorphicMemoryStorage from 'lib/storage/IsomorphicMemoryStorage'

let storageProvider: IsomorphicMemoryStorage | Storage
// getStorage returns session storage if the current browser supports it, or
// a memoryStorage otherwise
// https://gist.github.com/philfreo/68ea3cd980d72383c951
function getStorage() {
  if (storageProvider) {
    return storageProvider
  }

  try {
    if (typeof sessionStorage === 'object') {
      // safari in private mode doesn't allow session storage but we only know this
      // after we've tried to set something. So set try set it and make sure that works
      // before deciding to use session storage as our storage method
      sessionStorage.setItem('sessionStorage', '1')
      sessionStorage.removeItem('sessionStorage')
      storageProvider = sessionStorage
      return storageProvider
    }
  } catch {
    // guess session storage isn't supported, fallback to a memory storage
  }

  storageProvider = new IsomorphicMemoryStorage()
  return storageProvider
}

export function set(key: string, value: any) {
  const storage = getStorage()

  return storage.setItem(key, JSON.stringify(value))
}

export function get(key: string) {
  const storage = getStorage()

  const sessionStorageValue = storage.getItem(key)

  return JSON.parse(sessionStorageValue)
}

export function remove(key: string) {
  const storage = getStorage()

  storage.removeItem(key)
}

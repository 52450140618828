import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePaletteIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7.42 15.54a.9999.9999 0 0 0 0 1.41 1.001 1.001 0 0 0 .71.2958 1.0013 1.0013 0 0 0 .71-.2958 1 1 0 0 0 0-1.41 1.001 1.001 0 0 0-.71-.2958.9994.9994 0 0 0-.71.2958Zm0-8.49a1 1 0 0 0 0 1.41 1.0002 1.0002 0 0 0 1.42 0 1 1 0 0 0 0-1.41 1 1 0 0 0-1.42 0Zm4.95 10a.9994.9994 0 0 0-.9239.6173 1.0006 1.0006 0 0 0 .7288 1.3635.9998.9998 0 0 0 1.1951-.9808 1.0002 1.0002 0 0 0-1-1.05v.05Zm-6-6a.9998.9998 0 0 0-.707 1.7071 1.0006 1.0006 0 0 0 1.0897.2168A1.0004 1.0004 0 0 0 7.37 12.05a1.0003 1.0003 0 0 0-.6054-.9694A.9987.9987 0 0 0 6.37 11v.05Zm6-6a.9995.9995 0 0 0-.9239.6173 1.0005 1.0005 0 0 0 .7288 1.3635A1 1 0 0 0 13.37 6.05a.9989.9989 0 0 0-.6054-.9694A.9998.9998 0 0 0 12.37 5v.05Zm3.54 2.05a.9997.9997 0 0 0-.2194 1.0905 1.0002 1.0002 0 0 0 .9244.6187A1 1 0 0 0 17.32 7.1a1 1 0 0 0-1.41-.05v.05Zm6.3 0a10.9996 10.9996 0 0 0-9.8444-6.079A11 11 0 1 0 14.36 22.84a3.8691 3.8691 0 0 0 2.5-1.65 4.1986 4.1986 0 0 0 .6258-1.5337A4.198 4.198 0 0 0 17.47 18a5.6606 5.6606 0 0 1-.1-1 5.0002 5.0002 0 0 1 3-4.56 3.8393 3.8393 0 0 0 2.06-2.25 3.9999 3.9999 0 0 0-.22-3.11v.02Zm-1.7 2.44a1.9004 1.9004 0 0 1-1 1.09A6.9998 6.9998 0 0 0 15.37 17c.0015.4701.0484.9389.14 1.4a2.16 2.16 0 0 1-.31 1.65 1.7892 1.7892 0 0 1-1.21.8 8.717 8.717 0 0 1-1.62.15 8.9998 8.9998 0 0 1-8.397-5.7498A9.0003 9.0003 0 0 1 3.37 11.72 9.05 9.05 0 0 1 11.85 3h.51a8.9998 8.9998 0 0 1 8.06 5 1.9997 1.9997 0 0 1 .09 1.52v.02ZM12.37 11a.9994.9994 0 0 0-.9239.6173 1.0006 1.0006 0 0 0 .7288 1.3635A.9998.9998 0 0 0 13.37 12a.9997.9997 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LinePaletteIcon

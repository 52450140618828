import React from 'react'
import styled from 'styled-components'

/*
  This component is to be used as a 'wrapper' for things that are clickable
  without imparting any styles to it.
*/
const ClickableElement = styled.button`
  align-items: stretch;
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, Omit<React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>, 'type'> {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
}

/*
  Issues were had with specificity: when directly using the styled component
  the components styles are *added* to the other component that uses this rather than
  inheriting them as a base class. This causes problems as the styles for this component
  can appear much later in the stylesheet and override others

  To make sure styled components uses clickable as a 'base class' we have to
  wrap it up as a full component
*/
const BaseClickable = React.forwardRef<HTMLButtonElement, Props>(function({ type = 'button', ...props }: Props, ref) {
  return <ClickableElement {...props} type={type} ref={ref} />
})

BaseClickable.displayName = 'BaseClickable'

export default BaseClickable

export default function getInclusionMinStayText(inclusion: App.OfferInclusion | App.PackageInclusion) {
  const minDuration = inclusion?.minDuration
  const maxDuration = inclusion?.maxDuration

  if (minDuration && minDuration > 1 && minDuration !== maxDuration) {
    return `(${inclusion.minDuration}+ nights)`
  }

  return ''
}

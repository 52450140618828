import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCalendarIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 4h-2V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 15 3v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1.0001 1.0001 0 0 1-1 1H5a1.0002 1.0002 0 0 1-1-1v-7h16v7Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1.0002 1.0002 0 0 0 1.7071.7071A1.0002 1.0002 0 0 0 17 7V6h2a1 1 0 0 1 1 1v3Z"/>
  </SvgIcon>
}

export default LineCalendarIcon

import React, { useCallback } from 'react'
import cn from 'clsx'
import { rem } from 'polished'
import styled from 'styled-components'
import Image from 'components/Common/Image'
import Clickable from 'components/Common/Clickable'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import { UserPreferenceHolidayOrBrands } from '../types'
import BodyText from 'components/Luxkit/Typography/BodyText'

const Item = styled(Clickable)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${rem(4)};
`

const ImageContainer = styled.div`
  border: ${rem(2)} solid transparent;
  transition: border-color 0.2s;
  &.bordered {
    border-color: ${props => props.theme.palette.neutral.default.one};
  }
`

const FloatingCheckbox = styled(CheckboxButton)`
  position: absolute;
  top: ${rem(0)};
  right: ${rem(0)};
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }
`

function UserPreferenceItem({
  item,
  isSelected,
  onSelect,
  showLabel,
}: {
  item: UserPreferenceHolidayOrBrands,
  isSelected: boolean,
  onSelect: (item: UserPreferenceHolidayOrBrands) => void,
  showLabel: boolean,
}) {
  const onClick = useCallback((item: UserPreferenceHolidayOrBrands) => () => onSelect(item), [onSelect])
  return (<Item
    key={item.id}
    onClick={onClick(item)}
  >

    <ImageContainer className={cn({ bordered: isSelected })}>
      <Image fit="center" id={item.image} alt={item.name} width={192} height={124} />
    </ImageContainer>

    {showLabel && <BodyText colour="neutral-two" weight="bold" variant="medium">{item.name}</BodyText>}

    <FloatingCheckbox size="large" checked className={cn({ visible: isSelected })}/>
  </Item>)
}

export default UserPreferenceItem

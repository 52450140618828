import React from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  inputValue: string;
}

function NoAirportsOption({ inputValue }: Props) {
  return (
    <div>
      <BodyText variant="medium" colour="neutral-two" wrap="truncate">
        Sorry, we couldn't find{' '}
        <BodyText variant="medium" weight="bold" as="span">
          &lsquo;{inputValue}&rsquo;.
        </BodyText>
      </BodyText>
      <BodyText variant="medium" colour="neutral-two">
        Check your spelling, or try searching for a new airport.
      </BodyText>
    </div>
  )
}

export default NoAirportsOption

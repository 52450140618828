export const OFFER_TYPE_HOTEL = 'hotel'
export const OFFER_TYPE_HOTEL_SLUG = 'hotels'
export const OFFER_TYPE_HOTEL_ANALYTICS = 'hotel'
export const OFFER_TYPE_VILLA = 'rental'
export const OFFER_TYPE_VILLA_SLUG = 'villas'
export const OFFER_TYPE_VILLA_ANALYTICS = 'rental'
export const OFFER_TYPE_TOUR = 'tour'
export const OFFER_TYPE_TOUR_ANALYTICS = 'tour'
export const OFFER_TYPE_TOUR_V2: Tours.V2ParentOfferType = 'tour_v2'
export const OFFER_TYPE_TOUR_V2_EXPERIENCE: Tours.V2ExperienceOfferType = 'tour_experience'
export const OFFER_TYPE_TOUR_V2_ANALYTICS = 'tourV2'
export const OFFER_TYPE_TOUR_V1_ANALYTICS = 'tourV1'
export const OFFER_TYPE_TOUR_SLUG = 'tours'
export const OFFER_TYPE_CRUISE = 'cruise'
export const OFFER_TYPE_EXPERIENCE = 'experience'
export const OFFER_TYPE_EXPERIENCE_SLUG = 'experiences'
export const OFFER_TYPE_EXPERIENCE_CHANNEL_MANAGER = 'channel_manager'
export const OFFER_TYPE_EXPERIENCE_ALWAYS_ON = 'always_on'
export const OFFER_TYPE_EXPERIENCE_FLASH = 'flash'
export const OFFER_TYPE_EXPERIENCE_ADDON = 'addon'
export const OFFER_TYPE_EXPERIENCE_TRANSFER = 'transfer'
export const OFFER_TYPE_FLIGHT = 'flight'
export const OFFER_TYPE_CRUISE_SLUG = 'cruises'
export const OFFER_TYPE_LAST_MINUTE = 'last_minute_hotel'
export const OFFER_TYPE_LAST_MINUTE_ANALYTICS = 'lastMinuteHotel'
export const OFFER_TYPE_LAST_MINUTE_SLUG = 'last-minute'
export const OFFER_TYPE_ALWAYS_ON = 'tactical_ao_hotel'
export const OFFER_TYPE_ALWAYS_ON_ANALYTICS = 'tacticalAOHotel'
export const OFFER_TYPE_BUNDLE_AND_SAVE = 'bundle_and_save'
export const OFFER_TYPE_BUNDLE_AND_SAVE_ANALYTICS = 'bundle_and_save'
export const OFFER_TYPE_BUNDLE_AND_SAVE_SLUG = 'luxbundles'
export const OFFER_TYPE_BED_BANK = 'bedbank_hotel'
export const OFFER_TYPE_BED_BANK_ANALYTICS = 'bedbankHotel'
export const OFFER_TYPE_ADDON = 'addon'
export const OFFER_TYPE_CARHIRE_SLUG = 'car-hire'

export const PRODUCT_TYPE_TOUR = 'tour'
export const PRODUCT_TYPE_TOUR_V2 = 'direct_tour'
export const PRODUCT_TYPE_TOUR_V2_CONNECTION = 'connection_tour'
export const PRODUCT_TYPE_TOUR_V2_PARTNER = 'partner_tour'
export const PRODUCT_TYPE_ULTRALUX = 'ultralux_hotel'
export const PRODUCT_TYPE_ULTRALUX_SLUG = 'ultra-lux'
export const PRODUCT_TYPE_HOME_AND_VILLAS_SLUG = 'homes-and-villas'
export const PRODUCT_TYPE_ULTRALUX_CRUISE = 'ultra_lux_tour'
export const PRODUCT_TYPE_BEST_VALUE_HOTEL = 'best_value_hotel'
export const PRODUCT_TYPE_LUX_PLUS = 'lux_plus'
export const PRODUCT_TYPE_FLIGHT = 'flight'
export const PRODUCT_TYPE_INSURANCE = 'insurance'

export const LE_CONTRACTED_HOTEL_TYPES: Array<App.OfferType> = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_ALWAYS_ON,
]

export const EXPERIENCE_SEARCH_TYPES: Array<App.ExperienceProductType> = [
  OFFER_TYPE_EXPERIENCE_CHANNEL_MANAGER,
  OFFER_TYPE_EXPERIENCE_ALWAYS_ON,
  OFFER_TYPE_EXPERIENCE_FLASH,
  OFFER_TYPE_EXPERIENCE_ADDON,
]

export const HOTEL_SEARCH_TYPES: Array<App.OfferType> = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_ALWAYS_ON,
]

export const ALL_HOTEL_TYPES: Array<App.OfferType> = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_VILLA,
]

export const OFFER_DYNAMIC_PRICING_TYPES: Array<App.OfferType> = [OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_VILLA]

export const MAX_ROOM_LIMIT = 8
export const DEFAULT_MAX_INT = 999
export const MAX_ADULTS = 14

export const MAX_NUM_GUESTS = DEFAULT_MAX_INT
export const MAX_STAY = DEFAULT_MAX_INT
export const MAX_LOS = MAX_STAY
export const MAX_NUM_ROOMS = DEFAULT_MAX_INT

// bandaid hardcoding offer ids - please see Jaime B. for more info
export const GATED_OFFERS = [
  '0062y00000C2GfDAAV',
  '006RF00000D5fizYAB',
]

// We don't actually get a 'long' version of the sale unit
// from the server, so add to this list as we find other
// types that that need extending
export const saleUnitLongReplacements: Record<string, string> = {
  'apt.': 'apartment',
  apt: 'apartment',
}
export const BOOK_BUTTON_RESERVE_COPY_ALLOWED_OFFER_TYPES: Array<App.OfferType> = [OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK]
/**
 * Priority value of a rate plan board type
 * Higher is more important
 */
export const boardTypePriorities: Record<App.RatePlanBoardCode, number> = {
  roomonly: 0,
  breakfast: 1,
  halfboard: 2,
  fullboard: 3,
  allinclusive: 4,
  LE: 5,
}

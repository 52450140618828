import { Experiences as ExperiencesContract } from '@luxuryescapes/contract-svc-experience'
import config from 'constants/config'
import { last, sortBy } from 'lib/array/arrayUtils'
import findClosestAirport from 'lib/flights/findClosestAirport'

function formatContent(text: string, h = 2) {
  return text.split('\n').reduce((prev, line) => {
    return prev + (line.startsWith('#') ? `${'#'.repeat(h)}${line}\n` : `${line}\n`)
  }, '')
}

function thingsToKnowSplitted(thingsToKnow: string): Array<App.ExperienceMarkdownSplitted> {
  return thingsToKnow.split('\n').reduce((prev, line) => {
    // checks if it is an H2 to construct the return object with title or content
    if (line.startsWith('## ')) prev.push({ title: line.split('## ')[1], content: '' })
    else prev[prev.length - 1].content += formatContent(line, 2)
    return prev
  }, [] as Array<App.ExperienceMarkdownSplitted>)
}

function redemptionLocationMap(location: ExperiencesContract.RedemptionLocation) {
  return {
    id: location.id,
    name: location.address,
    latitude: location.latitude,
    longitude: location.longitude,
  }
}

function getDiscountPercent(discount, grossPrice) {
  if (grossPrice > 0 && discount > 0) return Math.ceil((discount * 100) / grossPrice)
  return 0
}

export function experienceCategoryMap(serverCategory: any): App.ExperienceItemCategory {
  return {
    name: serverCategory.label,
    id: serverCategory.id,
    level: serverCategory.level === 0 ? 'parent' : 'child',
    parentId: serverCategory.parent ?? undefined,
    imageId: serverCategory.imageId ?? '4nz573bb82uubd28k0k',
  }
}

function getExperienceProductType(experience: ExperiencesContract.Offer): App.ExperienceProductType {
  if (experience.features.transfer) {
    return 'transfer'
  }

  return experience.offerType
}

interface ExperienceMapParams {
  experience: ExperiencesContract.Offer,
  airports?: Array<App.Airport>,
  currentRegionCode: string,
}

const providerNameMap: { [key in ExperiencesContract.Provider]: App.ExperienceOffer['provider']} = {
  LED: 'led',
  Rezdy: 'rez',
  Derbysoft: 'dby',
}

function getGiftableType(experience: ExperiencesContract.Offer): App.GiftableTypes | undefined {
  const offerGiftingAvailable = experience.giftingAvailable

  if (config.GIFT_EXPERIENCE_GIFT_CARDS) {
    return offerGiftingAvailable ? 'offer' : 'gift-card'
  }

  return undefined
}

export function experienceMap({
  experience,
  airports = [],
}: ExperienceMapParams): App.ExperienceOffer {
  const categories = experience.categories.map(experienceCategoryMap)
  const productType = getExperienceProductType(experience)
  const mapped: App.ExperienceOffer = {
    id: experience.id,
    parentType: 'experience',
    type: 'experience',
    analyticsType: 'experience',
    productType,
    name: experience.title,
    slug: experience.slug,
    bookingType: experience.bookingType,
    status: experience.status,
    provider: providerNameMap[experience.provider],
    location: {
      // @ts-ignore: location field might be undefined
      name: experience.location?.description,
      // @ts-ignore: location field might be undefined
      latitude: experience.location?.latitude,
      // @ts-ignore: location field might be undefined
      longitude: experience.location?.longitude,
      // @ts-ignore: location field might be undefined
      timezone: experience.location?.timezone,
    },
    airport: productType === 'transfer' ? findClosestAirport(airports, experience.location?.latitude, experience.location?.longitude) : undefined,
    // @ts-ignore: name field might be undefined
    redemptionLocations: experience.redemptionLocations?.map(redemptionLocationMap) ?? [],
    images: sortBy(experience.images ?? [], (image) => image.isHero ? 0 : 1, 'asc').map(image => {
      let url: string | undefined = image.urls[0].url
      let id
      // service often passes image URLs that are actually from our service and have the ID at the end
      // so lets extract that ID and use it instead so we can manipulate sizes
      if (url?.includes(config.IMAGE_HOST)) {
        id = last(url.split('/')).split('.')[0]
        url = undefined
      }
      return {
        id,
        url,
        title: image.description,
      }
    }),
    video: experience.video,
    pricing: {
      price: experience.pricing.salesPrice,
      value: experience.pricing.value,
      discountPercent: experience.pricing.discountPercentage,
      saleUnit: experience.pricing.ticketUnitLabel ?? 'total',
      fees: experience.pricing.taxesAndFees ? [{
        type: 'taxesAndFees',
        amount: experience.pricing.taxesAndFees,
      }] : [],
      mobileAppDiscountAmount: experience.pricing.appDiscountAmount,
      mobileAppDiscountPercentage: experience.pricing.appDiscountPercentage,
    },
    price: experience.baseSalesPrice.amount,
    discount: experience.discountPercentage,
    grossPrice: experience.grossPrice.amount,
    taxesAndFees: experience.taxesAndFees?.amount,
    pickupOptional: !experience.pickupMandatory,
    hasCalendar: experience.bookingType === 'CALENDAR-TIMESLOTS' || experience.bookingType === 'CALENDAR-NO-TIMESLOTS',
    hasTimeslots: experience.bookingType === 'CALENDAR-TIMESLOTS',
    primaryCategory: categories.find(cat => cat.level === 'parent') || categories[0],
    categories,
    cancellationPolicies: (experience.cancellationInfo.refundPolicies ?? []).map(policy => ({
      id: policy.id,
      periods: policy.periods,
      periodLabel: policy.periodLabel,
      type: policy.type,
      value: policy.value,
    })),
    languages: experience.languages.map(lang => ({
      name: lang.name,
      id: lang.code,
    })),
    duration: {
      // @ts-ignore: duration field might be undefined
      from: experience.duration?.min,
      // @ts-ignore: duration field might be undefined
      to: experience.duration?.max,
    },
    voucher: {
      // @ts-ignore: Array.filter removes undefined values
      typesAccepted: [
        experience.voucherAccess?.byPrinted ? 'printed' : undefined,
        experience.voucherAccess?.byMixed ? 'mixed' : undefined,
        experience.voucherAccess?.byMobile ? 'digital' : undefined,
      ].filter(Boolean),
      required: !!experience.voucherAccess?.isRequired,
    },
    pickupPoints: (experience.pickupPoints ?? []).map(experiencePickupPointMap),
    leExclusive: config.BRAND === 'luxuryescapes' && !!experience.leExclusive,
    copy: {
      cancellationInfo: experience.cancellationInfo,
      availability: experience.operationalDays ?? undefined,
      // @ts-ignore: field might be null
      description: experience.shortDescription,
      highlights: experience.highlights?.map(highlight => highlight.label) ?? [],
      safetyInformation: experience.safetyInformation?.measures.filter(measure => measure.isActive)
        .map(measure => measure.copy?.description ?? measure.name) ?? [],
      meetingPoint: experience.meetingPoint,
      whereText: experience.where ?? undefined,
      included: experience.included ? formatContent(experience.included, 3) : undefined,
      thingsToKnow: experience.thingsToKnow ? thingsToKnowSplitted(experience.thingsToKnow) : [],
      dealDescription: experience.dealDescription ? formatContent(experience.dealDescription, 3) : undefined,
    },

    noIndex: !experience.searchEngineMarketingEnabled,
    vendor: experience.vendor,
    ticketUnitLabel: experience.ticketUnitLabel ?? undefined,
    expirationDate: experience.expirationDate ? new Date(experience.expirationDate).toISOString() : undefined,
    bookByDate: experience.bookByDate ? new Date(experience.bookByDate).toISOString() : undefined,
    allowBuyNowBookLater: experience.allowBuyNowBookLater,
    bookingCutoffHours: experience.vendorBookingCutoff,
    giftType: getGiftableType(experience),
    ticketed: experience.ticketed,
    ticketModes: experience.ticketModes,
    hideTimeSlots: experience.hideTimeSlots,
    luxPlus: {},
    ...(experience.features?.transfer && {
      features: {
        transfer: {
          preferredTransfer: experience.features?.transfer?.preferredTransfer,
        },
      },
    }),
  }

  if (experience.reviewsRating) {
    mapped.rating = {
      score: experience.reviewsRating,
      reviewsTotal: experience.reviewsTotal,
      reviewsSource: experience.reviewsSource,
    }
  }

  return mapped
}

export function experienceAvailabilityDatesMap(
  date: ExperiencesContract.DateAvailability,
): App.ExperienceAvailabilityDate {
  return {
    day: date.day,
    soldOut: date.soldOut,
  }
}

export function experienceAvailabilityTimesMap(
  groups: Array<ExperiencesContract.TimeGroups>,
  experienceId: string,
): Array<App.ExperienceAvailabilityTimeSlot> {
  return groups.map(group => group.slots.map((slot): App.ExperienceAvailabilityTimeSlot => ({
    time: slot.time,
    groupId: group.featureCode,
    purchaseLimit: slot.purchaseLimit,
    tickets: slot.tickets.map((ticket) => ({
      name: group.name ? `${group.name} - ${ticket.fareType}` : ticket.fareType,
      fareType: ticket.fareType,
      groupId: group.featureCode,
      id: ticket.identifier,
      productId: ticket.productId,
      date: ticket.date,
      type: ticket.type,
      pickupId: ticket.pickupId,
      // a min buy of 1 actually means *if they are buying it, it has to at least be 1*
      // but we need to also give the option of not buying it, which is therefore 0
      purchaseLimit: {
        min: ticket.purchaseLimit.min === 1 ? 0 : ticket.purchaseLimit.min,
        max: ticket.purchaseLimit.max,
        maxReason: ticket.purchaseLimit.maxReason,
      },
      participantsPerUnit: ticket.participantsPerUnit,
      customerTicketPurchasedAmount: ticket.customerTicketPurchasedAmount,
      price: ticket.salesPrices[0].amount,
      discount: getDiscountPercent(ticket.discount.amount, ticket.salesPrices[0].amount + ticket.discount.amount),
      discounts: {
        base: {
          amount: ticket.discounts.base.amount,
          percentage: ticket.discounts.base.percentage,
        },
        app: {
          amount: ticket.discounts.app.amount,
          percentage: ticket.discounts.app.percentage,
        },
      },
      taxesAndFees: ticket?.taxesAndFees?.amount,
      bookByDate: ticket?.bookByDate,
      rateEndDate: ticket?.rateEndDate,
      isExtra: ticket?.isExtra,
      experienceId,
    })),
  }))).flat()
}

export function experienceTransferTimesMap(
  groups: Array<ExperiencesContract.TimeGroups>,
  experienceId: string,
): Array<App.ExperienceTransferOption> {
  return groups.flatMap(group =>
    group.slots.flatMap((slot) =>
      slot.tickets.map((ticket): App.ExperienceTransferOption => {
        const bookingOptions = ticket.bookingOptions ?? {}
        return {
          id: ticket.identifier,
          name: group.name ? `${group.name} - ${ticket.fareType}` : ticket.fareType,
          price: ticket.salesPrices[0].amount,
          memberPrice: 0,
          // TODO temporarily leaving it blank to check why it was removed from
          // svc-experiences but here wasn't changed
          description: '',
          experienceId,
          purchaseLimit: {
            min: ticket.purchaseLimit.min === 1 ? 0 : ticket.purchaseLimit.min,
            max: ticket.purchaseLimit.max,
            maxReason: ticket.purchaseLimit.maxReason,
          },
          maxPassengers: bookingOptions.maxPassengers ?? 0,
          maxCheckedBags: bookingOptions.maxCheckedBags ?? 0,
          maxCarryOnBags: bookingOptions.maxCarryOnBags ?? 0,
          hasChildSeats: bookingOptions.childSeatTypes?.includes('INFANT-SEAT' as any) ?? false,
          hasBoosterSeats: bookingOptions.childSeatTypes?.includes('BOOSTER-SEAT' as any) ?? false,
          maxTransferDistance: bookingOptions.distanceCovered ?? 100,
          transferTypes: bookingOptions.transferTypes ?? [],
          taxesAndFees: ticket.taxesAndFees?.amount,
          bookByDate: ticket.bookByDate,
          rateEndDate: ticket.rateEndDate,
          discounts: {
            base: {
              amount: ticket.discounts.base.amount ?? 0,
              percentage: ticket.discounts.base.percentage ?? 0,
            },
            app: {
              amount: ticket.discounts.app.amount ?? 0,
              percentage: ticket.discounts.app.percentage ?? 0,
            },
          },
        }
      }),
    ),
  )
}

export function experiencePickupPointMap(
  pickupPoint: ExperiencesContract.PickupPoint,
): App.ExperiencePickupPoint {
  return {
    id: pickupPoint.id,
    name: pickupPoint.description ?? undefined,
    latitude: pickupPoint.latitude ?? undefined,
    longitude: pickupPoint.longitude ?? undefined,
  }
}

const fieldTypeMap = {
  string: 'text',
}

export function experienceCustomerFieldMap(
  field: ExperiencesContract.BookingCustomerInfo,
): App.ExperienceFormField {
  return {
    label: field.label,
    name: field.name,
    required: field.required,
    type: fieldTypeMap[field.type] ?? field.type,
    options: field.options?.map(option => ({
      label: option.label,
      value: option.value,
    })) ?? [],
  }
}

export function experienceVoucherMap(voucher: ExperiencesContract.Voucher): App.ExperienceVoucher {
  return {
    id: voucher.id,
    expiryDate: voucher.expiryDate,
    voucherCode: voucher.bookingNumber,
    offer: {
      vendorName: voucher.offer?.vendorName,
      barcodeType: voucher.offer?.barcodeType,
      inclusions: voucher.offer?.inclusionsRendered,
      conditions: voucher.offer?.conditionsRendered,
      howToRedeem: voucher.offer?.howToRedeemRendered,
      voucherCodeRequirements: voucher.offer?.voucherCodeRequirements,
      showOnlyVoucherValue: voucher.offer?.showOnlyVoucherValue,
    },
    customer: voucher.customer ? {
      name: voucher.customer.name,
      email: voucher.customer?.email,
      phone: voucher.customer?.phone,
      surname: voucher.customer?.surname,
    } : undefined,
    location: {
      name: voucher.redemptionLocation?.name,
      city: voucher.redemptionLocation?.city,
      phone: voucher.redemptionLocation?.phone,
      state: voucher.redemptionLocation?.state,
      address: voucher.redemptionLocation?.address,
      postcode: voucher.redemptionLocation?.postcode,
    },
    offerPackage: {
      name: voucher.offerPackage?.name,
      description: voucher.offerPackage?.description,
    },
    qrCodeUrl: voucher.qrCodeUrl,
    policies: voucher.policies || '',
    orderNumber: voucher.orderNumber,
    providerItemId: voucher.providerItemId,
    fareType: voucher.fareType,
    ...(voucher.voucherAmount && { voucherAmount: voucher.voucherAmount }),
    ...(voucher.voucherIndex && { voucherIndex: voucher.voucherIndex }),
  }
}

import cn from 'clsx'
import AspectRatio from 'components/utils/AspectRatio'
import FormatCurrency from 'components/Common/FormatCurrency'
import Image from 'components/Common/Image'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import TabButton from 'components/Luxkit/Tabs/TabButton'
import TabButtonsGroup from 'components/Luxkit/Tabs/TabButtonsGroup'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import React, { useCallback, useEffect, useId, useState } from 'react'
import styled from 'styled-components'
import ExperienceDetailsAboutLocationMap from '../ExperienceDetailsModal/About/ExperienceDetailsAboutLocationMap'
import TextButton from 'components/Luxkit/Button/TextButton'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { fetchTransferOptions } from 'actions/ExperienceActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import ExperienceTransferMultipleBooking from './ExperienceTransferMultipleBooking'
import ExperienceTransferSingleBooking from './ExperienceTransferSingleBooking'
import { rem } from 'polished'
import ExpereinceDetailsModalAboutSection from '../ExperienceDetailsModal/About/ExpereinceDetailsModalAboutSection'

const FadingElement = styled.div`
  display: none;

  &.visible {
    display: block;
  }

  ${mediaQueryUp.tablet} {
    display: block;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;

    &.visible {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }
  }
`

const FooterPrice = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(8)};

  ${mediaQueryUp.tablet} {
    display: block;
  }
`

const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${rem(8)};
`

const TabElement = styled.div`
  display: block;
  height: unset;
  opacity: 1;
  transition: opacity 0.2s;

  &[hidden] {
    display: block;
    height: 0;
    opacity: 0;
    margin: 0;
    transition: opacity 0.2s, height 0s 0.2s;
    overflow: hidden;
  }
`

const ExperienceDetailsAboutLocationMapSection = styled.div`
  margin-top: ${rem(32)};
`

export interface TransferBookingModalResolve {
  remove?: boolean;
  transfers?: Array<App.ExperienceTransferView>;
}

interface Props {
  experience: App.ExperienceBookingView;
  defaultTransfer?: App.ExperienceTransferView;
  defaultToAirportTransfer?: App.ExperienceTransferView;
  defaultToHotelTransfer?: App.ExperienceTransferView;
}

function ExperienceTransferBookingModal(props: Props) {
  const { experience, defaultTransfer, defaultToAirportTransfer, defaultToHotelTransfer } = props
  const [activeTab, setActiveTab] = useState<'details' | 'about'>('details')
  const modalElement = useModalElementContext<{ transfers?: Array<App.ExperienceTransferView>; remove?: boolean }>()
  const [price, setPrice] = useState<{total: number; isFromPrice: boolean}>({ total: 0, isFromPrice: true })
  const formId = useId()

  const dispatch = useAppDispatch()

  const transferOptions = useAppSelector(state => state.experience.experienceTransferOptions[experience.id]?.transfers)

  useEffect(() => {
    dispatch(fetchTransferOptions(experience.id))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience.id])

  const onSubmit = useCallback((transfers: Array<App.ExperienceTransferView>) => {
    modalElement.resolve({ transfers })
  }, [modalElement])

  const onRemove = () => {
    modalElement.resolve({ remove: true })
  }

  const onPriceChange = useCallback((total: number, isFromPrice: boolean) => setPrice({ total, isFromPrice }), [])
  const canRemove = !!(defaultTransfer?.option ?? defaultToAirportTransfer?.option ?? defaultToHotelTransfer?.option)

  return <ModalBase size="L" height="max">
    <ModalHeader title={experience.title} />
    <ModalBody>
      {experience.originalExperience.images.length > 0 && <AspectRatio ratio="16:8">
        <Image
          image={experience.originalExperience.images[0]}
          aspectRatio="16:8"
          fit="center"
        />
      </AspectRatio>}
      <ModalContent>
        <VerticalSpacer gap={24}>
          <TabButtonsGroup fit="grow">
            <TabButton isActive={activeTab === 'details'} size="large" onClick={() => setActiveTab('details')}>
              Transfer details
            </TabButton>
            <TabButton isActive={activeTab === 'about'} size="large" onClick={() => setActiveTab('about')}>
              About
            </TabButton>
          </TabButtonsGroup>
          <TabElement
            as={VerticalSpacer}
            gap={24}
            hidden={activeTab !== 'details'}
          >
            {!transferOptions && <div>
              <LoadingIndicator inline visible>Finding available transfers...</LoadingIndicator>
            </div>}
            {transferOptions && <>
              {defaultTransfer && <ExperienceTransferSingleBooking
                defaultTransfer={defaultTransfer}
                experience={experience}
                transferOptions={transferOptions}
                onPriceChange={onPriceChange}
                onSubmit={onSubmit}
                formId={formId}
              />}
              {!defaultTransfer && <ExperienceTransferMultipleBooking
                defaultToHotelTransfer={defaultToHotelTransfer}
                defaultToAirportTransfer={defaultToAirportTransfer}
                experience={experience}
                transferOptions={transferOptions}
                onPriceChange={onPriceChange}
                onSubmit={onSubmit}
                formId={formId}
              />}
            </>}
          </TabElement>
          <TabElement hidden={activeTab !== 'about'}>
            <ExpereinceDetailsModalAboutSection
              experienceView={experience}
              showLocation={false}
            />
            <ExperienceDetailsAboutLocationMapSection>
              <ExperienceDetailsAboutLocationMap experienceView={experience} />
            </ExperienceDetailsAboutLocationMapSection>
          </TabElement>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    <ModalFooter>
      <Group direction="horizontal" horizontalAlign="space-between" >
        <FooterPrice>
          <FadingElement as={Caption} className={cn({ visible: price.isFromPrice })} variant="large" colour="neutral-two">
            From
          </FadingElement>
          <Group direction="horizontal" verticalAlign="center">
            <Heading variant="heading4" as="span">
              <FormatCurrency value={price.total} />{' '}
              {!price.isFromPrice && <BodyText variant="medium" as="span">total</BodyText>}
            </Heading>
          </Group>
        </FooterPrice>
        <FooterActions>
          <FadingElement
            as={TextButton}
            aria-hidden={!canRemove}
            className={cn({ visible: canRemove })}
            fit={canRemove ? 'mobile-full-width' : undefined}
            kind="tertiary"
            size="large"
            onClick={onRemove}
          >
            Remove
          </FadingElement>
          <TextButton
            kind="primary"
            size="large"
            type="submit"
            fit={canRemove ? 'mobile-full-width' : undefined}
            form={formId}
          >
            {canRemove ? 'Update booking' : 'Add to booking'}
          </TextButton>
        </FooterActions>
      </Group>
    </ModalFooter>
  </ModalBase>
}

export default ExperienceTransferBookingModal

import { last } from 'lib/array/arrayUtils'
import { TripGooglePlaceAutocompletePrediction } from 'tripPlanner/api/places/types'
import { Place, PlaceGeo, TripMetadata } from 'tripPlanner/types/common'

export const isSamePlace = (place1: Place, place2: Place): boolean =>
  'id' in place1 && 'id' in place2 && place1.id === place2.id

export const getUniquePredictions = <
  T extends
    | TripGooglePlaceAutocompletePrediction
    | google.maps.places.AutocompletePrediction,
>(
    predictions: Array<T>,
    limit = 6,
  ): Array<T> => {
  const uniquePredictions: Array<T> = []
  const seenIds: Set<string> = new Set()

  for (const prediction of predictions) {
    if (!seenIds.has(prediction.place_id)) {
      uniquePredictions.push(prediction)
      seenIds.add(prediction.place_id)
    }
  }
  return uniquePredictions.slice(0, limit)
}

export const getCountryCodes = (data: TripMetadata | undefined): Array<string> => {
  if (!data?.locations) {
    return []
  }

  const countryCodes = data.locations
    .map((loc) => loc.country?.leCountryCode?.toLowerCase() || undefined)
    .filter((code, index, array) => code && array.indexOf(code) === index)
  return countryCodes as Array<string>
}

export function getPlaceShortLabel(place: PlaceGeo) {
  if (place.type === 'country') {
    return place.lePlaceName
  }
  if (place.shortName && place.countryCode) {
    return `${place.shortName}, ${place.countryCode}`
  }
  const placeNameParts = place.lePlaceName.split(/,\s*/)
  if (placeNameParts.length > 1) {
    return `${placeNameParts[0]}, ${last(placeNameParts)}`
  }
  return place.lePlaceName
}

export function getDestinationsLabel(
  destinations: Array<PlaceGeo>,
  numberOfPlacesToShow: number,
): string {
  let label = destinations
    .slice(0, numberOfPlacesToShow)
    .map(getPlaceShortLabel)
    .join(' • ')
  if (destinations.length > numberOfPlacesToShow) {
    label += ` + ${destinations.length - numberOfPlacesToShow} more`
  }
  return label
}

import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import noop from 'lib/function/noop'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'

const Field = styled.div`
  position: relative;
`

interface Props {
  className?: string;
  onChange: (text: string) => void;
  onClear?: () => void;
  required?: boolean;
  requiredErrorMessage?: string;
  placeholder?: string;
  value?: string;
  searchPhrase?: string;
}

function SearchFormModalTextField(props: Props) {
  const {
    value = '',
    placeholder,
    className,
    onChange,
    onClear = noop,
    required,
    requiredErrorMessage,
    searchPhrase,
  } = props
  const localInputRef = useRef<HTMLInputElement>(null)

  const canClear = !!(searchPhrase || value)
  const onExtraActionClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
    if (localInputRef.current) {
      if (canClear) {
        localInputRef.current.value = ''
        localInputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
        onClear()
      } else {
        localInputRef.current.focus()
      }
    }
  }, [canClear, onClear])

  return <Field className={className}>
    {!!required && <HiddenInput
      value={value ? 'filled' : undefined}
      required
      invalidMessage={requiredErrorMessage}
    />}
    <TextInputOnly
      noValidationSpacing
      placeholder={placeholder}
      ref={localInputRef}
      value={searchPhrase ?? value}
      onChange={e => onChange(e.currentTarget.value)}
      endIcon={<IconButton kind="tertiary" onClick={onExtraActionClick}>
        {canClear && <LineTimesIcon />}
        {!canClear && <LineSearchIcon />}
      </IconButton>
      }
    />
  </Field>
}

export default SearchFormModalTextField

import React from 'react'
import TourV2SearchControls from 'components/Search/TourV2/TourV2SearchControls'
import { useGlobalToursSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalToursSearch'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'

function GlobalTourSearchTabContent() {
  const { globalToursSearchDispatch, globalToursSearchState } = useGlobalToursSearchContext()

  return <GlobalSearchContextProvider
    state={globalToursSearchState}
    dispatch={globalToursSearchDispatch}
  >
    <TourV2SearchControls />
  </GlobalSearchContextProvider>
}

export default GlobalTourSearchTabContent

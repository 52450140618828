import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

const paymentMap: { [key: string]: comluxgroup.Payment_1_0_0['type'] } = {
  credit: 'credit',
  braintree: 'braintree',
  bridgerpay: 'bridgerpay',
  gocardless: 'gocardless',
  razorpay: 'razorpay',
  stripe: 'stripe',
  velocity: 'velocity',
  promo: 'promo',
  manual: 'manual',
}

const mapPaymentTypeToSnowplow = (type: string): comluxgroup.Payment_1_0_0['type'] => {
  return paymentMap[type] ? paymentMap[type] : 'manual'
}

const pspMap: { [key: string]: comluxgroup.Payment_1_0_0['serviceProvider'] } = {
  afterpay: 'afterpay',
  amazonpay: 'amazonpay',
  applepay: 'applepay',
  card: 'card',
  credit: 'credit',
  debit: 'debit',
  emi: 'emi',
  giropay: 'giropay',
  googlepay: 'googlepay',
  hoolah: 'hoolah',
  link: 'link',
  netbanking: 'netbanking',
  upi: 'upi',
}

const mapServiceProvider = (pspName: string): comluxgroup.Payment_1_0_0['serviceProvider'] => {
  return pspMap[pspName]
}

const paymentContext = (payments: Array<App.OrderPayment>) => {
  return payments.map(payment => comluxgroup.createPayment_1_0_0({
    amount: payment.amount,
    currency: payment.currencyCode,
    type: mapPaymentTypeToSnowplow(payment.type),
    promo_code: payment.code_name,
    serviceProvider: payment.pspName ? mapServiceProvider(payment.pspName) : undefined,
  }))
}

export default paymentContext

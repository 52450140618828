import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react'
import moment from 'moment'
import { CALENDAR_GA_EVENT_ACTION } from 'constants/analytics'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import useDidChange from 'hooks/useDidChange'
import UserAnalyticsActionContext from 'contexts/userAnalyticsActionContext'
import DatePicker from './DatePicker'
import clsx from 'clsx'
import DateRangePickerDay from './DateRangePickerDay'

type DayRender = Exclude<React.ComponentProps<typeof DatePicker>['dayRender'], undefined>

interface Props {
  onDatesChange: (dates: { startDate: moment.Moment; endDate?: moment.Moment; }) => void;
  endDate?: moment.Moment;
  startDate?: moment.Moment;
  initDate?: moment.Moment;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  className?: string;
  startLabel?: string;
  endLabel?: string;
  type?: 'single' | 'dual';
  dayRender?: DayRender;
}

function DateRangePicker(props: Props) {
  const {
    onDatesChange,
    startDate,
    endDate,
    initDate,
    minDate: minDateMoment,
    maxDate: maxDateMoment,
    className,
    startLabel,
    endLabel,
    type,
    dayRender,
  } = props

  const minDate = useMemo(() => minDateMoment?.toDate(), [minDateMoment])
  const maxDate = useMemo(() => maxDateMoment?.toDate(), [maxDateMoment])

  const defaultFocusedDated = useMemo(() => {
    if (startDate) {
      return startDate.toDate()
    } else if (initDate && minDate && initDate.toDate() < minDate) {
      return minDate
    } else if (initDate) {
      return initDate.toDate()
    } else if (minDate) {
      return minDate
    } else {
      return new Date()
    }
  }, [startDate, initDate, minDate])

  const [date, setDate] = useState<Date>(defaultFocusedDated)

  const onUserAnalyticsAction = useContext(UserAnalyticsActionContext)

  const onDayClick = useCallback((clickedDate: Date) => {
    const day = moment(clickedDate)
    if (startDate && !endDate && day.isAfter(startDate)) {
      onDatesChange({ startDate, endDate: day })
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.CheckOut, day.format(ISO_DATE_FORMAT))
    } else {
      onDatesChange({ startDate: day, endDate: undefined })
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.CheckIn, day.format(ISO_DATE_FORMAT))
    }
  }, [onDatesChange, startDate, endDate, onUserAnalyticsAction])

  const startDateChanged = useDidChange(startDate)
  const minDateChanged = useDidChange(minDate)

  useEffect(() => {
    if (startDate && startDateChanged && startDate.month() !== date.getMonth()) {
      setDate(startDate.toDate())
    } else if (minDate && minDateChanged && minDate > date) {
      setDate(minDate)
    }
  }, [date, startDate, startDateChanged, minDate, minDateChanged])

  const defaultDayRender = useCallback<DayRender>((day, { disabled }) => {
    const dayMoment = moment(day)
    const inRange = startDate && endDate && dayMoment.isBetween(startDate, endDate, 'day', '()')
    const isStart = startDate && dayMoment.isSame(startDate, 'day')
    const isEnd = endDate && dayMoment.isSame(endDate, 'day')
    let label: string | undefined
    if (isStart) {
      label = startLabel
    } else if (isEnd) {
      label = endLabel
    }
    return <DateRangePickerDay
      key={day.getTime()}
      className={clsx({ inRange })}
      date={day}
      disabled={disabled}
      onClick={onDayClick}
      selected={startDate && dayMoment.isSame(startDate, 'day') || endDate && dayMoment.isSame(endDate, 'day')}
      label={label}
      reserveLabelSpacing={!!(startLabel || endLabel)}
    />
  }, [endDate, endLabel, onDayClick, startDate, startLabel])

  return (
    <DatePicker
      defaultValue={defaultFocusedDated}
      min={minDate}
      max={maxDate}
      className={className}
      onDayClick={onDayClick}
      type={type}
      dayRender={dayRender ?? defaultDayRender}
    />
  )
}

export default React.memo(DateRangePicker)

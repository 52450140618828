export const INCLUSIONS_TITLE_STANDARD = 'standard inclusions'
/**
 * Marketing change/update - with LuxPlus+ being introduced, bonus inclusions are now called "extra inclusions"
 * and LuxPlus+ inclusions are now referred to as "bonus inclusions" 😢
 */
export const INCLUSIONS_TITLE_BONUS = 'extra inclusions'
/**
 * Now referred to as "bonus inclusions"
 */
export const INCLUSIONS_TITLE_LUXPLUS = 'bonus inclusions'

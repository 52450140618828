import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import React, { ChangeEventHandler, ComponentProps, useCallback, useState } from 'react'
import BusinessTravellerSelectContent from './BusinessTravellerSelectContent'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

interface Props extends Pick<ComponentProps<typeof BusinessTravellerSelectContent>, 'onTravellerSelect'> {
}

function BusinessTravellerSelectModal(props: Props) {
  const { onTravellerSelect } = props

  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const modalElementContext = useModalElementContext()

  const dismiss = useCallback(() => {
    modalElementContext.resolve()
  }, [modalElementContext])

  return <Modal
    mode="drawer"
    title="Who is going?"
    headerExtension={<TextInputOnly
      value={searchTerm}
      type="text"
      placeholder="Search traveller name"
      onChange={handleSearchTermChange}
      endIcon={<LineSearchIcon />}
      noValidationSpacing
    />}
    primaryActionText="Continue"
    onPrimaryActionClick={dismiss}
  >
    <BusinessTravellerSelectContent
      searchTerm={searchTerm}
      onTravellerSelect={onTravellerSelect}
    />
  </Modal>
}

export default BusinessTravellerSelectModal

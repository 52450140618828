import wait from 'lib/promise/wait'

interface Config<BatchResponse, SingleResponse, SingleArg, AccumulatedArgs, Params = undefined> {
  /**
   * Set in milliseconds, defines how much time should be elapsed from the first request
   * waiting for consecutive requests.
   * defaults to 32ms (2 frames at 60 fps worth of time)
   */
  waitTime?: number
  /**
   * This function will be invoked to batch consecutive requests.
   *
   * @param accumulatedArgs previously accumulated single arguments
   * @param singleArg currently passed single argument
   * @returns a batch of single arguments
   */
  argsReducerFn: (accumulatedArgs: AccumulatedArgs, singleArg: SingleArg) => AccumulatedArgs,
  /**
   * This function will be invoked to make the batch request call
   *
   * @param accumulatedArgs accumulated arguments
   * @param params extra params for single requests
   * @returns response of the batch request
   */
  batchRequestFn: (accumulatedArgs: AccumulatedArgs, params: Params) => Promise<BatchResponse>,
  /**
   * This function will be invoked to get a single value from the batch response
   *
   * @param singleArg original single argument
   * @param batchResponse response of the batch request
   * @returns single value from the batch response
   */
  singleValueFn: (singleArg: SingleArg, batchResponse: BatchResponse) => SingleResponse
}

function createBatchedPromise<BatchResponse, SingleResponse, SingleArg, AccumulatedArgs, Params = undefined>(
  config: Config<BatchResponse, SingleResponse, SingleArg, AccumulatedArgs, Params>,
): (singleArg: SingleArg, params: Params) => Promise<SingleResponse> {
  const {
    waitTime = 32,
    argsReducerFn,
    batchRequestFn,
    singleValueFn,
  } = config

  let accumulatedArgs: AccumulatedArgs
  let delayedPromise: Promise<BatchResponse> | undefined

  return async function(singleArg: SingleArg, params?: Params) {
    accumulatedArgs = argsReducerFn(accumulatedArgs, singleArg)

    if (!delayedPromise) {
      delayedPromise = wait(waitTime).then(async() => {
        const batchedArgs = accumulatedArgs
        // clear the accumulated arguments for the next batch
        accumulatedArgs = undefined
        delayedPromise = undefined

        return await batchRequestFn(batchedArgs, params)
      })
    }

    return delayedPromise.then((batchResponse) => singleValueFn(singleArg, batchResponse))
  }
}

// credits for the initial concept go to @zediah
export default createBatchedPromise

import IsomorphicMemoryStorage from 'lib/storage/IsomorphicMemoryStorage'
import { sortBy, arrayToObject } from 'lib/array/arrayUtils'

let storageProvider: Storage | IsomorphicMemoryStorage
// getStorage returns localStorage if the current browser supports it, or
// a memoryStorage otherwise
// https://gist.github.com/philfreo/68ea3cd980d72383c951
function getStorage() {
  if (storageProvider) {
    return storageProvider
  }

  try {
    if (typeof localStorage === 'object') {
      // safari in private mode doesn't allow local storage but we only know this
      // after we've tried to set something. So set try set it and make sure that works
      // before deciding to use local storage as our storage method
      localStorage.setItem('localStorage', '1')
      localStorage.removeItem('localStorage')
      storageProvider = localStorage
      return storageProvider
    }
  } catch {
    // guess local storage isn't supported, fallback to a memory storage
  }

  storageProvider = new IsomorphicMemoryStorage()
  return storageProvider
}

export function set(key: string, value: any) {
  const storage = getStorage()

  try {
    return storage.setItem(key, JSON.stringify(value))
  } catch (err) {
    if (storage === localStorage && err instanceof DOMException && (err.name === 'QuotaExceededError' || err.name === 'QUOTA_EXCEEDED_ERR')) {
      // Quota exceeded, But why? rethrow the error with the keys, the associated size of value per key
      // and the current key and size of value that was attempted to be set.
      const calculateSizeInKB = (str: string) => {
        // returns the byte length of an utf8 string
        // https://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript
        let s = str.length
        for (let i = str.length - 1; i >= 0; i--) {
          const code = str.charCodeAt(i)
          if (code > 0x7f && code <= 0x7ff) s++
          else if (code > 0x7ff && code <= 0xffff) s += 2
          if (code >= 0xDC00 && code <= 0xDFFF) i-- // trail surrogate
        }
        return (s / 1024).toFixed(4) // returns the size in KB
      }
      const keys = Object.keys(localStorage)
      const keysWithSizes = arrayToObject(keys, (key) => key, (key) => parseFloat(calculateSizeInKB(localStorage.getItem(key) ?? '0')))
      const sortedKeysBySize = sortBy(keys, (key) => keysWithSizes[key], 'desc')
      const logKeysWithSizes = sortedKeysBySize.map(key => `${keysWithSizes[key]} KB: ${key}`).join('\n')

      const currentValueSize = `${calculateSizeInKB(JSON.stringify(value))} KB`
      throw new DOMException(`Original message: ${err.message}.\nCurrent Key being set: ${key}, Value Size: ${currentValueSize}.\nCurrent Keys with Sizes:\n${logKeysWithSizes}`, err.name)
    } else {
      throw err
    }
  }
}

export function get(key: string) {
  const storage = getStorage()

  const localStorageValue = storage.getItem(key)

  return JSON.parse(localStorageValue)
}

export function remove(key: string) {
  const storage = getStorage()

  storage.removeItem(key)
}

export function removeItemsWithSubstringInKey(substring: string) {
  const storage = getStorage()
  const keys = Object.keys(storage)
  for (const key of keys) {
    if (key.includes(substring)) {
      storage.removeItem(key)
    }
  }
}

export function hasKeyWithSubstring(substring: string) {
  const storage = getStorage()
  const keys = Object.keys(storage)
  return keys.find(key => key.includes(substring))
}

import { dummyItemTotalsView, getLEPackageItemTotals } from 'checkout/lib/utils/accommodation/view'
import { DAY_MONTH_NAME_SHORT, DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { sum, unique, sortBy } from 'lib/array/arrayUtils'
import { formatOccupantsAsGuests } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import moment from 'moment'
import { createSelector } from 'reselect'
import offerPageURL from 'lib/offer/offerPageURL'
import { isBundleAndSaveItem } from 'lib/checkout/checkoutUtils'

export const getBundleAndSaveItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.BundleAndSaveItem> => {
    return items.filter(isBundleAndSaveItem)
  },
)

export const getBundleAndSaveItemViews = createSelector(
  getBundleAndSaveItems,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.calendar.calendarsByPackageKey,
  (state: App.State) => state.offer.offerAvailableRatesByOccupancy,
  (
    items,
    offerState,
    calendarsByPackageKey,
    availableRatesByOccupancy,
  ): App.WithDataStatus<Array<App.Checkout.BundleAndSaveView>> => {
    const offerIds = unique(items.map(item => item.offerId))
    const offers = offerIds.map(id => offerState[id]).filter(Boolean)

    // make sure we have our offer ids loaded
    if (offers.length === offerIds.length) {
      const views = items.map((item): App.Checkout.BundleAndSaveView => {
        const bundleOffer = offerState[item.offerId] as App.BundleOffer
        const bundlePackage = bundleOffer.bundlePackages[item.packageId]

        const sortedPkgOptions = sortBy(bundlePackage.options, (option) => {
          return moment(item.dates[option.fkOfferId].checkIn).toDate().getTime()
        }, 'asc')

        return {
          item,
          ...(bundleOffer ? { offerPageUrl: offerPageURL(bundleOffer) } : {}),
          bundleOffer,
          bundlePackage,
          occupancy: item.occupancy,
          hotels: sortedPkgOptions.map((option) => {
            const dates = item.dates[option.fkOfferId]
            const duration = dates.duration ?? moment(dates.checkOut).diff(dates.checkIn, 'days')
            const pkg = bundleOffer.packages.find(p =>
              p.lePackageId === option.lePackageId &&
              p.roomRate?.id === option.fkRoomRateId &&
              p.duration === duration,
            )!

            const itemTotals = getLEPackageItemTotals(
              bundleOffer.bundledOffers[option.fkOfferId],
              pkg,
              dates.checkIn,
              dates.checkOut,
              item.occupancy,
              calendarsByPackageKey,
              undefined,
              availableRatesByOccupancy,
            ) ?? dummyItemTotalsView()

            return {
              ...itemTotals,
              price: dates.newPrice ?? itemTotals.price,
              surcharge: dates.newSurcharge ?? itemTotals.surcharge,
              extraGuestSurcharge: dates.newExtraGuestSurcharge ?? itemTotals.extraGuestSurcharge,
              checkIn: dates.checkIn,
              checkOut: dates.checkOut,
              duration,
              offer: bundleOffer.bundledOffers[option.fkOfferId],
              package: pkg,
            }
          }),
        }
      })

      return {
        hasRequiredData: true,
        data: views,
      }
    }

    return {
      hasRequiredData: false,
      data: [],
    }
  },
)

function getCancellationPolicyBreakdown(
  view: App.Checkout.BundleAndSaveView,
  hotelView: App.Checkout.BundleAndSaveHotelView,
): App.Checkout.CancellationPolicyBreakdownView | undefined {
  switch (hotelView.offer.type) {
    case 'hotel':
      return {
        cancellationPolicyDetails: hotelView.package.roomRate?.cancellationPolicy,
        checkInDate: moment(hotelView.checkIn),
        checkOutDate: moment(hotelView.checkIn).clone().add(hotelView.duration, 'days'),
        occupants: view.occupancy,
      }
    case 'tactical_ao_hotel':
      return {
        cancellationPolicyDetails: hotelView.package.roomRate?.cancellationPolicy,
        checkInDate: moment(hotelView.checkIn),
        checkOutDate: moment(hotelView.checkIn).clone().add(hotelView.duration, 'days'),
        occupants: view.occupancy,
        offerId: hotelView.offer.id,
        ignoreDynamicCancellationPolicy: hotelView.package.ignoreDynamicCancellationPolicy ?? false,
        useDynamicCancellationPolicies: hotelView.offer.property?.useDynamicCancellationPolicies ?? false,
        timezoneOffset: hotelView.offer.property?.timezoneOffset,
        uniqueKey: hotelView.package.uniqueKey,
      }
    default:
      return undefined
  }
}

export const getBundleAndSaveBreakdownView = createSelector(
  getBundleAndSaveItemViews,
  (views): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (views.data.length === 0) { return { hasRequiredData: views.hasRequiredData, data: [] } }

    const allHotelViews = views.data.flatMap(data => data.hotels)
    const numberOfNights = pluralizeToString('night', sum(allHotelViews.map(view => view.duration)))
    const numberOfHotels = pluralizeToString('hotel', allHotelViews.length)
    const guests = formatOccupantsAsGuests(...views.data.map(view => view.occupancy))

    const bundleAndSaveBreakdown: App.Checkout.PriceBreakdownView = {
      title: 'Accommodation',
      price: sum(allHotelViews.map(view => (view.price ?? 0) + (view.surcharge ?? 0) - view.taxesAndFees)),
      memberPrice: sum(allHotelViews.map(view => (view.memberPrice ?? 0) + (view.surcharge ?? 0) - view.taxesAndFees)),
      additionalInfoText: [
        `${numberOfNights} (${numberOfHotels}, ${guests})`,
      ],
      items: views.data.flatMap<App.Checkout.BundleAndSaveItemBreakdownView>(view => {
        return view.hotels.map(hotel => {
          return {
            title: hotel.offer.property?.name || '',
            offerPageUrl: view.offerPageUrl,
            itemId: view.item.itemId,
            offerId: hotel.offer.id,
            itemType: view.item.itemType,
            price: (hotel.price ?? 0) + (hotel.surcharge ?? 0) - hotel.taxesAndFees,
            memberPrice: hotel.memberPrice > 0 ? (hotel.memberPrice ?? 0) + (hotel.surcharge ?? 0) - hotel.taxesAndFees : 0,
            surcharge: (hotel.surcharge ?? 0),
            additionalInfoText: [
              `${moment(hotel.checkIn).format(DAY_MONTH_NAME_SHORT)} - ${moment(hotel.checkOut).format(DMY_CASUAL_FORMAT)} (${pluralizeToString('night', hotel.duration)})`,
              hotel.package.name,
            ],
            cancellationPolicy: getCancellationPolicyBreakdown(view, hotel),
            additionalElements: [],
          }
        })
      }),
    }

    return {
      hasRequiredData: true,
      data: [bundleAndSaveBreakdown],
    }
  },
)

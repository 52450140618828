import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import InputText from 'components/Luxkit/Typography/InputText'

const TextInputContainer = styled.label`
  display: block;
`

const LabelText = styled(InputText)`
  display: block;

  & + * {
    margin-top: ${rem(4)};
  }
`

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  label?: React.ReactNode;
  required?: boolean;
  optionalIndicatorEnabled?: boolean;
}

const InputLabelWrap = React.forwardRef<HTMLLabelElement, Props>((props, ref) => {
  const {
    label,
    children,
    required,
    optionalIndicatorEnabled = true,
    ...labelProps
  } = props

  return (
    <TextInputContainer {...labelProps} ref={ref}>
      {label && <LabelText variant="label">
        {label}
        {!required && optionalIndicatorEnabled && <InputText as="span" variant="sub-label">
          {' '}(optional)
        </InputText>}
      </LabelText>}
      {children}
    </TextInputContainer>
  )
})

InputLabelWrap.displayName = 'InputLabelWrap'

export default InputLabelWrap

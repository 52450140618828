import { set, get } from 'lib/storage/isomorphicLocalStorage'

export const loadUtmsFromLocalStorage = (STORAGE_KEY: string):App.UTMState => {
  const localStorageUtms = get(STORAGE_KEY)
  const now = Date.now()

  if (now > +localStorageUtms?.expiry_timestamp) {
    clearUtm(STORAGE_KEY)
    return {}
  }

  return localStorageUtms
}

export const saveUtmsToLocalStorage = (utms: App.UTMState, STORAGE_KEY: string, timestamp?: number) => {
  return set(STORAGE_KEY, {
    ...utms,
    expiry_timestamp: timestamp ?? get(STORAGE_KEY)?.expiry_timestamp,
  })
}

export function clearUtm(STORAGE_KEY: string) {
  return set(STORAGE_KEY, {})
}

import config from 'constants/config'
import { OptimizelyExperiments } from 'constants/optimizely'
import { isValidInsuranceRegion } from 'lib/insurance/insuranceCountries'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import isDomesticTrip from '../view/isDomesticTrip'
import { isBookingProtectionItem, isFlightItem } from 'lib/checkout/checkoutUtils'
import isBookingProtectionAvailable from './isBookingProtectionAvailable'

const cartOnlyHasFlightsOrBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.every(item => isFlightItem(item) || isBookingProtectionItem(item)),
)

const isBookingProtectionEnabled = createSelector(
  isBookingProtectionAvailable,
  isDomesticTrip,
  cartOnlyHasFlightsOrBookingProtection,
  (state: App.State) => state.geo.currentRegionCode,
  isSpoofed,
  (state: App.State) => !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.refundProtectForInternationalEnabled),
  (state: App.State) => state.checkout.cart.postPurchase,
  (isBookingProtectionAvailable, isADomesticTrip, cartOnlyHasFlightsOrBookingProtection, currentRegionCode, isSpoofed, bookingProtectionInternationABTest, postPurchase): boolean => {
    if (postPurchase === 'insurance') return false
    if (!config.BOOKING_PROTECTION_ENABLED) return false
    if (!isBookingProtectionAvailable) return false
    // Booking Protection is available on all standalone flights
    if (cartOnlyHasFlightsOrBookingProtection) return true
    // Booking protection is not available for international trips from AU region when not spoofed and not in ON variant of AB test
    if (!isADomesticTrip && !isSpoofed && currentRegionCode === 'AU' && !bookingProtectionInternationABTest) return false
    // Booking protection is not available for all trips within regions where Insurance is enabled except AU
    if (isValidInsuranceRegion(currentRegionCode) && currentRegionCode !== 'AU') return false
    return true
  },
)

export default isBookingProtectionEnabled

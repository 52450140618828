import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHotAirBalloonIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11.7427.9999h-.4856a8.4874 8.4874 0 0 0-5.7683 14.4887l3.1852 3.1852v1.8742a2.4474 2.4474 0 0 0 2.4472 2.4472h.7574a2.4472 2.4472 0 0 0 2.4472-2.4472v-1.8742l3.1852-3.1852A8.4874 8.4874 0 0 0 11.7427.9999Zm-4.9235 13.168a6.6124 6.6124 0 0 1-1.853-5.7671A6.6132 6.6132 0 0 1 8.5866 3.544a15.761 15.761 0 0 0-1.0488 5.9432 19.0331 19.0331 0 0 0 1.7383 7.1278l-2.457-2.4472Zm5.6518 6.3801a.5926.5926 0 0 1-.573.5827h-.7574a.5925.5925 0 0 1-.573-.5827v-1.3304h1.9422l-.0388 1.3304Zm-.9711-3.5639A19.0327 19.0327 0 0 1 9.412 9.4873c0-4.1078 1.3498-6.6326 2.0879-6.6326.738 0 2.0878 2.5248 2.0878 6.6326a19.0812 19.0812 0 0 1-2.0878 7.4968Zm4.6807-2.8162-2.4569 2.4472a19.0333 19.0333 0 0 0 1.7383-7.1278 15.7635 15.7635 0 0 0-1.0488-5.9431 6.6127 6.6127 0 0 1 3.6204 4.8566 6.6137 6.6137 0 0 1-1.853 5.7671Z"/>
  </SvgIcon>
}

export default LineHotAirBalloonIcon

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import SolidExclamationCircleIcon from 'components/Luxkit/Icons/solid/SolidExclamationCircleIcon'
import { pluralizeToString } from 'lib/string/pluralize'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import getLPCRegionLOS from 'lib/offer/getLPCRegionLOS'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import FrontPageTileFlightPricing from './FrontPageTileFlightPricing'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'
import { unique } from 'lib/array/arrayUtils'

export const StyledExclamationIcon = styled(SolidExclamationCircleIcon)`
  position: relative;
  margin-left: ${rem(5)};
  top: ${rem(3)};
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  pkg: App.Package;
}

function HeroOfferTilePricing(props: Props) {
  const {
    offer,
    pkg,
  } = props

  const {
    totalPrice,
    totalMemberPrice,
    totalValue,
    memberPriceOrPrice,
    valueOffPercent,
    saleUnit,
  } = useOfferPackagePrice(pkg, offer)

  const shouldShowRate = valueOffPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  const shouldShowDiscountPercent = offer.type === OFFER_TYPE_LAST_MINUTE
  const shouldShowValue = pkg.value > 0 && memberPriceOrPrice && totalValue > memberPriceOrPrice && pkg.shouldDisplayValue &&
    !shouldShowDiscountPercent && (offer.type == OFFER_TYPE_HOTEL || !pkg.roomRate?.inclusionsHideValue) &&
    !offer.isDiscountPillHidden

  let staysFrom = offer.minDuration
  const LPCRegionLOS = getLPCRegionLOS(offer)
  if (LPCRegionLOS && offer.packages.some(p => p.duration === LPCRegionLOS)) {
    staysFrom = LPCRegionLOS
  }
  const showStaysFromLabel = staysFrom && offer.type === OFFER_TYPE_ALWAYS_ON && LPCRegionLOS && staysFrom < pkg.duration

  const durationText = useMemo(() => {
    if (offer.type === 'rental') {
      return `${pkg.durationLabel.toLocaleLowerCase()} from`
    }
    if (offer.tileDurationLabel) {
      return `${offer.tileDurationLabel} from`
    }
    if (LPCRegionLOS) {
      return `${pkg.duration} nights from`
    }
    return 'From'
  }, [LPCRegionLOS, offer.tileDurationLabel, offer.type, pkg.duration, pkg.durationLabel])

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end" gap={4}>
      <VerticalSpacer gap={8}>
        <Group direction="vertical">
          <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
          <LuxPlusPriceStack
            price={totalPrice}
            memberPrice={totalMemberPrice}
            size="L"
            saleUnit={saleUnit}
            testid="hero-price"
            offerConfig={offer.luxPlus}
          />
          <PriceRowTaxesAndFees />
          {shouldShowValue && <PriceRowValueDiscountWithCaption
            size="L"
            originalValue={Math.ceil(totalValue)}
            discountPercentage={shouldShowRate ? valueOffPercent : undefined}
          />}
          {staysFrom && showStaysFromLabel && <PriceRowCaption>
            Stays from {pluralizeToString('night', staysFrom)} available
          </PriceRowCaption>}
          {!!pkg.roomOccupancy && offer.type === 'tour' && <PriceRowCaption>
            {pkg.roomOccupancy}
          </PriceRowCaption>}
        </Group>
        <PriceRowAgentHubCommission
          size="L"
          productType={offer.productType}
          offerId={offer.id}
          offerLocations={offerLocations}
          vendorName={vendorName}
        />
        <FrontPageTileFlightPricing offer={offer} />
      </VerticalSpacer>
      <CSSBreakpoint min="desktop">
        <TextButton kind="primary">
          View offer
        </TextButton>
      </CSSBreakpoint>
    </Group>
  )
}

export default HeroOfferTilePricing

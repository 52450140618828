import React from 'react'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineEyeIcon from 'components/Luxkit/Icons/line/LineEyeIcon'
import LineEyeSlashIcon from 'components/Luxkit/Icons/line/LineEyeSlashIcon'
import TextInput from './TextInput'
import useToggle from 'hooks/useToggle'
import styled from 'styled-components'
import { rem } from 'polished'

const TextInputWithSpace = styled(TextInput)`
  input {
    padding-right: ${rem(42)};
  }
`

const ShowHideButton = styled(IconButton)`
  position: absolute;
  right: ${rem(4)};
  top: 50%;
  transform: translateY(-50%);
`

interface Props extends Omit<React.ComponentProps<typeof TextInput>, 'type'> {}

const PasswordInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [hide, toggleHide] = useToggle(true)
  const type = hide ? 'password' : 'text'

  return (
    <TextInputWithSpace
      {...props}
      ref={ref}
      type={type}
    >
      <ShowHideButton
        kind="tertiary"
        variant="dark"
        onClick={toggleHide}
        disabled={props.disabled}
      >
        {hide ? <LineEyeIcon /> : <LineEyeSlashIcon />}
      </ShowHideButton>
    </TextInputWithSpace>
  )
})

export default PasswordInput

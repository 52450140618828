import { AnyAction, combineReducers } from 'redux'
import {
  ACCOUNT_ACCESS_SHOW_LOGIN,
  ACCOUNT_ACCESS_SHOW_EMAIL_JOIN,
  ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD,
  ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK,
  SHOW_BRIDGER_PAY_PAYMENT_MODAL,
  ADD_PAYMENT_PROVIDER,
  BOOKING_DATE_WARNING_MODAL_OPEN,
  BEDBANK_HOTEL_PRICE_CHANGE_MODAL_OPEN,
  JOURNEY_SOLD_OUT_MODAL_OPEN,
  FLIGHTS_SESSION_EXPIRED_MODAL_OPEN,
  SHOW_BRIDGERPAY_PAYMENT_FAILED_MODAL,
  SHOW_CUSTOMER_SIGNATURE_MODAL,
  CANT_PROCESS_ORDER_ONLINE_MODAL_OPEN,
  GENERIC_ORDER_ERROR_MODAL_OPEN,
  AUTH_MODAL_OPEN,
  QUOTE_EMAIL_MODAL_OPEN,
  MODAL_OPEN,
  MODAL_CLOSE,
  API_CALL_SUCCESS,
  TOUR_V2_PRICE_CHANGE_MODAL_OPEN,
  JOURNEY_PRICE_CHANGE_MODAL_OPEN,
  HOTEL_PRICE_CHANGE_MODAL_OPEN,
  BUNDLE_PRICE_CHANGE_MODAL_OPEN,
  SHOW_CHECKOUT_CART_MODE_MODAL,
  ACCOUNT_ACCESS_SET_MODE,
  SHOW_PAY_TO_AUTHORISATION_MODAL,
  SHOW_SECURE_PAYMENT_MODAL,
  CONFIRMATION_EMAIL_MODAL_OPEN as SKYCHECK_MODAL_OPEN,
  SHOW_EXPERIENCE_CANCELLATION_MODAL,
  CART_LINK_MODAL_OPEN,
  BEDBANK_SOLD_OUT_MODAL_OPEN,
} from 'actions/actionConstants'
import { createReducer } from 'lib/redux/reducerUtils'
import {
  USER_LOGIN,
  USER_REGISTRATION,
  USER_LOGOUT,
} from 'actions/apiActionConstants'

const initialModalState: App.UiModalState = {
  open: false,
  heading: '',
  message: '',
  modalType: undefined,
  fareUrls: [],
  fareRuleParams: undefined,
  error: {
    open: false,
    heading: '',
    message: '',
  },
  errors: [],
  packageId: undefined,
  cost: undefined,
  refCode: undefined,
  cartItemId: undefined,
  priceChange: undefined,
  price: 0,
  surcharge: 0,
  extraGuestSurcharge: 0,
  paymentProvider: undefined,
  showConfirmationOptions: false,
}

function modalType(action: AnyAction): App.ModalType | undefined {
  switch (action.type) {
    case ACCOUNT_ACCESS_SHOW_LOGIN:
    case ACCOUNT_ACCESS_SHOW_EMAIL_JOIN:
    case ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD:
    case ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK:
      return 'accountAccess'
    case SHOW_BRIDGER_PAY_PAYMENT_MODAL:
      return 'bridgerPayPayment'
    case BOOKING_DATE_WARNING_MODAL_OPEN:
      return 'bookingDateWarning'
    case HOTEL_PRICE_CHANGE_MODAL_OPEN:
      return 'hotelPriceChange'
    case BUNDLE_PRICE_CHANGE_MODAL_OPEN:
      return 'bundlePriceChange'
    case BEDBANK_HOTEL_PRICE_CHANGE_MODAL_OPEN:
      return 'bedbankHotelPriceChange'
    case TOUR_V2_PRICE_CHANGE_MODAL_OPEN:
      return 'tourV2PriceChange'
    case JOURNEY_PRICE_CHANGE_MODAL_OPEN:
      return 'journeyPriceChange'
    case JOURNEY_SOLD_OUT_MODAL_OPEN:
      return 'journeySoldOut'
    case FLIGHTS_SESSION_EXPIRED_MODAL_OPEN:
      return 'flightsSessionExpired'
    case SHOW_BRIDGERPAY_PAYMENT_FAILED_MODAL:
      return 'bridgerPaymentFailed'
    case CANT_PROCESS_ORDER_ONLINE_MODAL_OPEN:
      return 'cantProcessOrderOnline'
    case GENERIC_ORDER_ERROR_MODAL_OPEN:
      return 'genericOrderError'
    case QUOTE_EMAIL_MODAL_OPEN:
      return 'quoteEmail'
    case SKYCHECK_MODAL_OPEN:
      return 'skyCheck'
    case SHOW_CUSTOMER_SIGNATURE_MODAL:
      return 'customerSignature'
    case SHOW_CHECKOUT_CART_MODE_MODAL:
      return 'checkoutCartMode'
    case SHOW_PAY_TO_AUTHORISATION_MODAL:
      return 'payToAuthorisation'
    case SHOW_SECURE_PAYMENT_MODAL:
      return 'securePayment'
    case SHOW_EXPERIENCE_CANCELLATION_MODAL:
      return 'experienceCancellation'
    case CART_LINK_MODAL_OPEN:
      return 'cartLink'
    case BEDBANK_SOLD_OUT_MODAL_OPEN:
      return 'bedbankSoldOut'
    default:
      return undefined
  }
}

function modalReducer(state = initialModalState, action: AnyAction): App.UiModalState {
  switch (action.type) {
    case ADD_PAYMENT_PROVIDER:
      return {
        ...state,
        ...initialModalState,
        paymentProvider: action.paymentProvider,
      }
    case ACCOUNT_ACCESS_SHOW_LOGIN:
    case ACCOUNT_ACCESS_SHOW_EMAIL_JOIN:
    case ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD:
    case ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK:
    case SHOW_PAY_TO_AUTHORISATION_MODAL:
    case SHOW_SECURE_PAYMENT_MODAL:
    case SHOW_CHECKOUT_CART_MODE_MODAL:
    case SHOW_EXPERIENCE_CANCELLATION_MODAL:
      return {
        ...state,
        ...initialModalState,
        open: true,
        modalType: modalType(action),
        dismissable: action.dismissable,
      }
    case SHOW_BRIDGER_PAY_PAYMENT_MODAL:
      return {
        ...state,
        ...initialModalState,
        open: true,
        modalType: modalType(action),
        message: action.url,
      }
    case AUTH_MODAL_OPEN:
      return {
        ...initialModalState,
        open: true,
        heading: action.data.heading,
        message: action.data.message,
        modalType: modalType(action),
      }
    case BOOKING_DATE_WARNING_MODAL_OPEN:
      return {
        ...initialModalState,
        modalType: modalType(action),
        error: {
          open: true,
        },
      }
    case JOURNEY_SOLD_OUT_MODAL_OPEN:
      return {
        ...initialModalState,
        modalType: modalType(action),
        open: true,
      }
    case BEDBANK_SOLD_OUT_MODAL_OPEN:
      return {
        ...initialModalState,
        modalType: modalType(action),
        open: true,
      }
    case FLIGHTS_SESSION_EXPIRED_MODAL_OPEN:
      return {
        ...initialModalState,
        modalType: modalType(action),
        open: true,
      }
    case GENERIC_ORDER_ERROR_MODAL_OPEN:
      return {
        ...initialModalState,
        modalType: modalType(action),
        open: true,
        errors: action.data.errors,
      }
    case CANT_PROCESS_ORDER_ONLINE_MODAL_OPEN:
      return {
        ...initialModalState,
        modalType: modalType(action),
        open: true,
        refCode: action.data.refCode,
      }
    case SHOW_BRIDGERPAY_PAYMENT_FAILED_MODAL:
      return {
        ...initialModalState,
        modalType: modalType(action),
        paymentProvider: action.data.paymentProvider,
        error: {
          open: true,
        },
      }
    case QUOTE_EMAIL_MODAL_OPEN:
      return {
        ...initialModalState,
        open: true,
        modalType: modalType(action),
      }
    case SKYCHECK_MODAL_OPEN:
      return {
        ...initialModalState,
        open: true,
        modalType: modalType(action),
      }
    case CART_LINK_MODAL_OPEN:
      return {
        ...initialModalState,
        open: true,
        modalType: modalType(action),
      }
    case MODAL_OPEN:
      return {
        ...initialModalState,
        modalType: modalType(action),
        error: {
          open: true,
          heading: action.data.heading,
          message: action.data.message,
        },
      }
    case MODAL_CLOSE:
      return {
        ...initialModalState,
      }
    case API_CALL_SUCCESS:
      if (action.api === USER_LOGIN || action.api === USER_REGISTRATION) {
        return { ...initialModalState }
      }
      return state
    case SHOW_CUSTOMER_SIGNATURE_MODAL:
      return {
        ...initialModalState,
        open: true,
        modalType: modalType(action),
        showConfirmationOptions: !!action.showConfirmationOptions,
      }
    case HOTEL_PRICE_CHANGE_MODAL_OPEN:
    case BUNDLE_PRICE_CHANGE_MODAL_OPEN:
    case BEDBANK_HOTEL_PRICE_CHANGE_MODAL_OPEN:
    case TOUR_V2_PRICE_CHANGE_MODAL_OPEN:
    case JOURNEY_PRICE_CHANGE_MODAL_OPEN:
      return {
        ...initialModalState,
        open: true,
        modalType: modalType(action),
        priceChange: action.data.priceChange,
      }
    default:
      return state
  }
}

const initialAccountModalState: App.UiAccountModalState = {
  mode: undefined,
}

export const accountAccessReducer = createReducer<App.UiAccountModalState>(initialAccountModalState, {
  [API_CALL_SUCCESS]: (state, action) => {
    if (action.api === USER_LOGOUT) {
      return { mode: 'login' }
    }
    return state
  },
  [ACCOUNT_ACCESS_SHOW_LOGIN]: (state, action) => {
    return { mode: action.mode || 'login' }
  },
  [ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD]: () => ({ mode: 'forgotPassword' }),
  [ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK]: () => ({ mode: 'linkPartnership' }),
  [ACCOUNT_ACCESS_SET_MODE]: (state, action) => ({ mode: action.mode }),
})

const uiReducer = combineReducers<App.UiState>({
  modal: modalReducer,
  accountAccess: accountAccessReducer,
})

export default uiReducer

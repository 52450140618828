import cx from 'clsx'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ListItemLoadingSkeleton from 'components/Luxkit/List/ListItemLoadingSkeleton'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { sortBy, without } from 'lib/array/arrayUtils'
import { getCruiseExtraFilters, getGlobalFacetFilters } from 'lib/cruises/cruiseUtils'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import ListItemAll from './CruiseLineSelectContentListAll'
import ListItem from './CruiseLineSelectContentListItem'
import useQueryParams from 'hooks/useQueryParams'
import { omitKeys } from 'lib/object/objectUtils'

const CruiseLineListWrapper = styled.div`
  display: grid;
  row-gap: ${rem(12)};
  column-gap: ${rem(40)};
  grid-template-columns: repeat(auto-fit, minmax(${rem(288)}, 1fr));
`

interface Props {
  onSelect?: (cruiseLines: Array<string>) => void;
  onSetShowAll: (value: boolean) => void;
  inputSearchRef?: React.RefObject<HTMLInputElement>;
  searchTerm?: string;
  showAll: boolean;
  drawerMode?: boolean;
}

function CruiseLineSelectContentList(props: Props) {
  const queryParams = useQueryParams()
  const { searchTerm, showAll, onSetShowAll, inputSearchRef, drawerMode, onSelect = noop } = props

  const cruiseExtraFilters = getCruiseExtraFilters(queryParams)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const globalFilters = useContext(GlobalSearchStateContext)
  const { cruiseLines: cruiseLinesSelected = [] } = globalFilters
  const facetFilters = getGlobalFacetFilters(globalFilters)

  const [facets] = useCruiseSearchFacets({ facetTypes: ['cruise_lines'] })
  const [filteredCruiseLines, fetching] = useCruiseSearchFacets({
    facetTypes: ['cruise_lines'],
    ...omitKeys(['cruiseLines'], { ...cruiseExtraFilters, ...facetFilters }),
  })

  const sortFacets = useCallback((facets: Array<{ cruiseLine: App.CruiseSearchFacet, isDisabled: boolean}>) => {
    return sortBy(facets, (facet) => {
      return facet.cruiseLine.count || 0
    }, 'desc')
  }, [])

  const mapFacets = useCallback((facets: Array<App.CruiseSearchFacet>) => {
    return facets
      .filter((cruiseLine) => !searchTerm || cruiseLine.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((cruiseLine) => ({
        cruiseLine,
        isDisabled: false,
      }))
  }, [searchTerm])

  const cruiseLinesList = useMemo(() => {
    if (filteredCruiseLines.length && !searchTerm) {
      const filteredFacetsCruiseLines = mapFacets(filteredCruiseLines)
      const facetsCruiseLines = facets
        .filter((cruiseLine) => !filteredFacetsCruiseLines.some((filteredCruiseLine) => filteredCruiseLine.cruiseLine.value === cruiseLine.value))
        .map((cruiseLine) => ({
          cruiseLine,
          isDisabled: true,
        }))
      const sortedFacets = sortFacets(filteredFacetsCruiseLines)
      return [...sortedFacets, ...facetsCruiseLines]
    }

    const cruiseLinesFacets = mapFacets(facets)
    return sortFacets(cruiseLinesFacets)
  }, [filteredCruiseLines, searchTerm, mapFacets, facets, sortFacets])

  useEffect(() => {
    if (inputSearchRef?.current) {
      inputSearchRef.current.focus({ preventScroll: true })
    }
  }, [inputSearchRef])

  const handleSelectVendor = useCallback((cruiseLine: string) => {
    let nextCruiseLines: Array<string> = []

    const alreadyExists = cruiseLinesSelected.includes(cruiseLine)
    if (alreadyExists) {
      nextCruiseLines = without(cruiseLinesSelected, cruiseLine)
    } else {
      nextCruiseLines = [...cruiseLinesSelected, cruiseLine]
    }

    searchDispatch({ type: GlobalSearchStateActions.SET_CRUISE_LINES, cruiseLines: nextCruiseLines })
    onSelect(nextCruiseLines)
    onSetShowAll(false)
  }, [cruiseLinesSelected, searchDispatch, onSelect, onSetShowAll])

  const onSelectAll = useCallback(() => {
    searchDispatch({
      type: GlobalSearchStateActions.SET_CRUISE_LINES,
      cruiseLines: [],
    })
    onSelect([])
    onSetShowAll(!showAll)
  }, [searchDispatch, onSelect, onSetShowAll, showAll])

  return <VerticalSpacer gap={16}>
    {fetching && <>
      <ListItemLoadingSkeleton subtitle />
      <ListItemLoadingSkeleton subtitle />
      <ListItemLoadingSkeleton subtitle />
    </>}

    {!fetching && <CruiseLineListWrapper data-testid="cruise-line-list-wrapper" className={cx({ drawer: drawerMode })}>
      {!searchTerm && <ListItemAll isSelected={showAll} onClick={onSelectAll} />}

      {cruiseLinesList.map(({ cruiseLine, isDisabled }) => <ListItem
        key={cruiseLine.imageId}
        cruiseLine={cruiseLine}
        searchTerm={searchTerm}
        isSelected={cruiseLinesSelected.includes(cruiseLine.value ?? '') || showAll}
        isDisabled={isDisabled}
        onClick={handleSelectVendor}
      />)}
    </CruiseLineListWrapper>}
  </VerticalSpacer>
}

export default CruiseLineSelectContentList

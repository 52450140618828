import { useAppSelector } from 'hooks/reduxHooks'
import React, { PropsWithChildren } from 'react'
import { initialGeoState } from 'reducers/geo'

const GeoContext = React.createContext<App.GeoState>(initialGeoState)

export function GeoProvider(props: PropsWithChildren) {
  const geo = useAppSelector((state) => state.geo)

  return <GeoContext.Provider value={geo}>
    {props.children}
  </GeoContext.Provider>
}

export default GeoContext

import React, { useCallback, useRef, useContext, useState, useMemo } from 'react'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import IconButton from 'components/Luxkit/Button/IconButton'
import Group from 'components/utils/Group'
import CruiseLinesSearchList from './CruiseLineSelectContentList'
import CruiseFilterFooter from '../Common/CruiseFilterFooter'
import useToggle from 'hooks/useToggle'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'

interface Props {
  cruiseLineNames: Array<string>
  onChange: (values: App.CruiseGlobalFilters) => void
  drawerMode?: boolean
  handlerSearchTerm: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlerClearSearchTerm: () => void
  searchTerm: string
}

function CruiseLineSelectMobile(props: Props) {
  const {
    cruiseLineNames,
    onChange,
    drawerMode,
    handlerSearchTerm,
    handlerClearSearchTerm,
    searchTerm,
  } = props
  const dropdownTriggerRef = useRef<HTMLButtonElement>(null)
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [isOpen, toggleModal] = useToggle(false)
  const [showAll, setShowAll] = useState(false)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const { cruiseLines } = useContext(GlobalSearchStateContext)

  const handleOnApply = useCallback(() => {
    onChange({ cruiseLines })
    toggleModal()
  }, [onChange, cruiseLines, toggleModal])

  const onSelect = useCallback((cruiseLines: Array<string>) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_CRUISE_LINES, cruiseLines })
  }, [searchDispatch])

  const onClear = useCallback(() => {
    searchDispatch({ type: GlobalSearchStateActions.SET_CRUISE_LINES, cruiseLines: [] })
    setShowAll(false)
    handlerClearSearchTerm()
  }, [handlerClearSearchTerm, searchDispatch])

  const cruiseLineLabel = useMemo(() => {
    if (isOpen) return searchTerm

    const [firstName] = cruiseLineNames
    const more = cruiseLineNames.length > 1 ? `+${cruiseLineNames.length - 1}` : ''
    return firstName && `${firstName} ${more}`
  }, [cruiseLineNames, isOpen, searchTerm])

  return <>
    <SearchFormField
      data-testid="cruise-line-select-mobile-input"
      onClick={toggleModal}
      ref={dropdownTriggerRef}
      label="Cruise lines"
      placeholder={isOpen ? 'Search cruise lines' : 'All cruise lines'}
      value={cruiseLineLabel}
      displayValue={cruiseLineLabel}
    />

    <ModalBase
      mode="drawer"
      isOpen={isOpen}
      onClose={toggleModal}
    >
      <ModalHeader
        title="Cruise lines"
        onCloseButtonClick={toggleModal}
      />
      <ModalBody>
        <ModalContent>
          <Group direction="vertical" gap={16}>
            <TextInputOnly
              ref={inputSearchRef}
              noValidationSpacing
              placeholder="Search cruise lines"
              value={searchTerm}
              onChange={handlerSearchTerm}
              endIcon={searchTerm.length > 0 ?
                <IconButton kind="tertiary" onClick={handlerClearSearchTerm}><LineTimesIcon /></IconButton> :
                <LineSearchIcon />
              }
            />
            <CruiseLinesSearchList
              inputSearchRef={inputSearchRef}
              searchTerm={searchTerm}
              showAll={showAll}
              onSetShowAll={setShowAll}
              onSelect={onSelect}
              drawerMode={drawerMode}
            />
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <CruiseFilterFooter
          onApply={handleOnApply}
          onClear={onClear}
        />
      </ModalFooter>
    </ModalBase>
  </>
}

export default CruiseLineSelectMobile

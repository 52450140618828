import React, { useCallback, useState } from 'react'

import EditTripDetailsGeneric from '../EditTripDetails/EditTripDetailsGeneric'

import { createTripEvent } from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import { EmptyArray } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import { useEditableTrips } from 'tripPlanner/hooks/api'
import { BasicTrip, FullTrip } from 'tripPlanner/types/common'

type SettingInteractedCallback = Exclude<
  React.ComponentProps<typeof EditTripDetailsGeneric>['onSettingInteracted'],
  undefined
>

interface Props {
  trip: FullTrip
  onClose?: (trip: FullTrip) => void
  isInBookmarkingFlow: boolean
}

function AdditionalDetails({
  trip,
  onClose: onCloseProp = noop,
  isInBookmarkingFlow,
}: Props) {
  const { data } = useEditableTrips()
  const trips: Array<BasicTrip> = data ?? EmptyArray
  const isFirstTrip = trips.length === 1

  const [adultsWereSet, setAdultsWereSet] = useState(false)
  const [childrenWereSet, setChildrenWereSet] = useState(false)

  const fireEvent = useCallback(
    (
      element: Parameters<typeof createTripEvent>[0],
      action: Parameters<typeof createTripEvent>[2],
    ) => {
      fireInteractionEvent(
        createTripEvent(
          element,
          'global',
          action,
          isFirstTrip,
          isInBookmarkingFlow,
        ),
      )
    },
    [isFirstTrip, isInBookmarkingFlow],
  )

  const onSettingInteracted = useCallback<SettingInteractedCallback>(
    (type, action) => {
      if (type === 'destination' && action === 'set') {
        fireEvent('modal_location', 'select')
      } else if (type === 'destination' && action === 'remove') {
        fireEvent('modal_location', 'remove')
      } else if (type === 'dates' && action === 'open') {
        fireEvent('modal_date', 'search')
      } else if (type === 'dates' && action === 'set') {
        fireEvent('modal_date', 'select')
      } else if (type === 'adults' && action === 'set') {
        // Wait until modal is closed to send events for these, so we don't spam
        // loads of events as the user adjusts the traveller counts.
        setAdultsWereSet(true)
      } else if (type === 'children' && action === 'set') {
        setChildrenWereSet(true)
      }
    },
    [fireEvent],
  )

  const onClose = useCallback(
    (trip: FullTrip, saved: boolean) => {
      onCloseProp(trip)
      if (adultsWereSet) {
        fireEvent('modal_adults', 'select')
      }
      if (childrenWereSet) {
        fireEvent('modal_children', 'select')
      }
      fireEvent('modal_additional_info', saved ? 'save' : 'dismiss')
    },
    [adultsWereSet, childrenWereSet, fireEvent, onCloseProp],
  )

  return (
    <EditTripDetailsGeneric
      trip={trip}
      includeNameField={false}
      title="Success! Let’s add more details to your trip"
      subtitle="By sharing details about your trip, we can personalise recommendations for things to do, see and experience."
      saveLabel="Save trip details"
      cancelLabel="Maybe later"
      onClose={onClose}
      onSettingInteracted={onSettingInteracted}
      fillInDetailsFromContext
    />
  )
}

export default AdditionalDetails

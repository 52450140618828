import React, { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { updateAddPhonePromptedAt, updateUserDetails } from 'actions/AuthActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import LegalText from './AccountAccessLegalText'
import Group from 'components/utils/Group'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import { AccountAccessModalResult } from './AccountAccessModal'
import * as Analytics from 'analytics/analytics'

interface PhoneNum {
  phone: string;
  prefix: string;
}

interface AddPhoneForm {
  phoneNum: PhoneNum;
}

interface Props {
  error?: any;
  regionCode?: string;
  phone?: string;
  dismissable?: boolean;
}

function AccountAccessAddPhone(props: Props) {
  const {
    regionCode,
    phone,
    dismissable,
  } = props

  const termsCheckboxRef = useRef<HTMLInputElement>(null)
  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const dispatch = useAppDispatch()
  const [hasDismissed, setHasDismissed] = useState(false)

  const onAddPhone = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    const joinData = formToObject<AddPhoneForm>(event.currentTarget)
    dispatch(updateUserDetails({
      phone: joinData.phoneNum.phone,
      phonePrefix: joinData.phoneNum.prefix,
    },
    ))
  }, [dispatch])

  const onClose = useCallback(() => {
    if (hasDismissed) {
      return modalContext.resolve({ loggedIn: true })
    }

    setHasDismissed(true)
  }, [modalContext, hasDismissed])

  useEffect(() => {
    dispatch(updateAddPhonePromptedAt(new Date()))
    Analytics.trackClientEvent({
      subject: 'add_phone_number',
      action: 'impression',
      category: 'logging',
      type: 'operational',
    })
  }, [dispatch])

  useEffect(() => {
    if (phone?.length) {
      Analytics.trackClientEvent({
        subject: 'add_phone_number',
        action: 'interaction',
        category: 'logging',
        type: 'operational',
      })
      modalContext.resolve({ loggedIn: true })
    }
  }, [modalContext, phone])

  return (
    <>
      <ModalHeader
        title={hasDismissed ? 'Are you sure?' : 'Secure your account with a mobile number'}
        subtitle={!hasDismissed ? 'Link your mobile to your account for added security and a smoother, faster login experience.' : undefined}
        dismissible={dismissable}
        onCloseButtonClick={onClose}
      />
      <ModalBody>
        <ModalContent>
          <form name="joinForm" onSubmit={onAddPhone}>
            <Group direction="vertical" gap={12}>
              {hasDismissed && (
                <MessageBanner
                  kind="warning"
                  icon={<LineInfoCircleIcon />}
                  description="Adding your phone number is highly recommended to help keep your account safe"
                />
              )}
              <PhoneInput
                id="phoneNum"
                name="phoneNum"
                label="Phone number"
                prefixCountry={regionCode}
                required
              />
              <CheckboxInput
                ref={termsCheckboxRef}
                name="agreeTandC"
                data-testid="terms-and-privacy-agree-checkbox"
                required
              >
                <LegalText modalType="promptAddPhone"/>
              </CheckboxInput>
              <TextButton
                kind="primary"
                fit="flex"
                type="submit"
                size="large"
              >
                {hasDismissed ? 'Secure my account' : 'Add mobile number'}
              </TextButton>
              {hasDismissed && (
                <TextButton
                  kind="secondary"
                  fit="flex"
                  size="large"
                  onClick={onClose}
                >
                  Don’t add number
                </TextButton>
              )}
            </Group>
          </form>
        </ModalContent>
      </ModalBody>
    </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    error: state.auth.error,
    regionCode: state.geo.currentRegionCode,
    phone: state.auth.account.phone,
  }
}

export default connect(mapStateToProps)(AccountAccessAddPhone)

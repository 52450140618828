import cn from 'clsx'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import { mediaQueryUp } from 'components/utils/breakpoint'
import React from 'react'
import styled from 'styled-components'
import { TAB_BUTTON_FOCUS_RING_WIDTH } from './TabButton'

const Scroller = styled(HorizontalContainerWithFadedOverflow)`
  box-shadow: inset 0 -1px 0 ${props => props.theme.palette.neutral.default.five};
  /*
    This provides room for things like focus states to overflow
    top and bottom without making the actual element any bigger
  */
  padding: ${TAB_BUTTON_FOCUS_RING_WIDTH};

  > * {
    margin-top: -${TAB_BUTTON_FOCUS_RING_WIDTH};
    margin-bottom: -${TAB_BUTTON_FOCUS_RING_WIDTH};

    &:first-child {
      margin-left: -${TAB_BUTTON_FOCUS_RING_WIDTH};
    }

    &:last-child {
      margin-right: -${TAB_BUTTON_FOCUS_RING_WIDTH};
    }
  }


  > * {
    flex-shrink: 0;
  }

  &.fit-auto > * {
    flex-grow: 0;
  }
  &.fit-grow > * {
    flex-grow: 1;
  }

  ${mediaQueryUp.tablet} {
    &.fit-tablet-auto > * {
      flex-grow: 0;
    }
    &.fit-tablet-grow > * {
      flex-grow: 1;
    }
  }
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Describes how the tab children should be laid out
   * Grow will spread the tabs across the whole width of the group
   * Auto will make them only take up the room required per tab
   */
  fit?: 'auto' | 'grow'
  /**
   * @default fit
   */
  tabletFit?: 'auto' | 'grow'
}

const TabButtonsGroup = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    fit = 'auto',
    tabletFit,
    ...restOfProps
  } = props

  return <div {...restOfProps}>
    <Scroller
      ref={ref}
      className={cn(`fit-${fit}`, `fit-tablet-${tabletFit ?? fit}`)}
    >
      {children}
    </Scroller>
  </div>
})

TabButtonsGroup.displayName = 'TabButtonsGroup'

export default TabButtonsGroup

import loadable from '@loadable/component'
import * as React from 'react'
import { match, RouteComponentProps, Switch } from 'react-router'

import TripGuard from './components/TripGuard/TripGuard'
import RootWrapper from './pages/RootWrapper'
import Trip404Page from './pages/Trip404Page'
import { TripTab } from './utils/tabs'
import TripTabRedirect from './pages/TripTabRedirect'

import AuthRoute from 'components/App/Routes/AuthRoute'
import Route from 'components/App/Routes/TrackedRoute'
import WebViewRoutes from 'tripPlanner/pages/WebViews/WebViewRoutes'

const MyTripsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "MyTripsPage" */ 'tripPlanner/pages/myTrips/MyTripsPage'
    ),
)

const CuratedTripsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CuratedTripsPage" */ 'tripPlanner/pages/curatedTrips/CuratedTripsPage'
    ),
)

const PublicTripParent = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerPublicTripParent" */ 'tripPlanner/pages/publicTrip/PublicTripParent'
    ),
)

const TripPlannerAboutPage = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerAboutPage" */ 'tripPlanner/pages/about/TripPlannerAboutPage'
    ),
)

const TripParent = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerTripParent" */ 'tripPlanner/pages/trip/TripParent'
    ),
)

const CuratedTripParent = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerCuratedTripParent" */ 'tripPlanner/pages/curatedTrip/CuratedTripParent'
    ),
)

const AcceptInvite = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerAcceptInvite" */ './pages/trip/AcceptInvite'
    ),
)

const CreateFromOrder = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerCreateFromOrder" */
      './pages/trip/CreateFromOrder'
    ),
)

const HiddenSettings = loadable(
  () =>
    import(
      /* webpackChunkName: "HiddenSettings" */
      './pages/hiddenSettings/HiddenSettings'
    ),
)

const pageRoute = (match: match, path: string) =>
  `${match.path}${path}`

function TripPlannerRoutes({ match }: Pick<RouteComponentProps<{ regionCode: string }>, 'match'>) {
  const tabs: Array<Lowercase<TripTab>> = ['summary', 'itinerary', 'explore', 'documents']

  return (
    <TripGuard fallback={<Trip404Page />}>
      <RootWrapper>
        <Switch>
          <Route
            exact
            path={pageRoute(match, '/trip-planner/about')}
            component={TripPlannerAboutPage}
          />
          <Route
            exact
            path={pageRoute(match, '/trip-planner/curated')}
            component={CuratedTripsPage}
          />
          <AuthRoute
            showPopup
            exact
            path={pageRoute(match, '/trip-planner')}
            component={MyTripsPage}
          />
          <AuthRoute
            showPopup
            exact
            path={pageRoute(
              match,
              `/trip-planner/trip/:tripId/:tab(${tabs.join('|')})`,
            )}
            component={TripParent}
          />
          <Route
            exact
            path={pageRoute(
              match,
              '/trip-planner/public-trip/:tripId/:tab(summary|itinerary)',
            )}
            component={PublicTripParent}
          />
          <AuthRoute
            showPopup
            exact
            path={pageRoute(
              match,
              '/trip-planner/trip/:tripId/accept-invite/:inviteId',
            )}
            component={AcceptInvite}
          />
          <Route
            exact
            path={pageRoute(match, '/trip-planner/curated/:tripId/summary')}
            component={CuratedTripParent}
          />
          <AuthRoute
            showPopup
            exact
            path={pageRoute(match, '/trip-planner/create')}
            component={CreateFromOrder}
          />
          <AuthRoute
            showPopup
            exact
            path={pageRoute(match, '/trip-planner/hidden-settings')}
            component={HiddenSettings}
          />
          <Route path={pageRoute(match, '/trip-planner/webView')}>
            <WebViewRoutes match={match} />
          </Route>
          <Route
            exact
            path={pageRoute(match, '/trip-planner/trip/:tripId')}
            component={TripTabRedirect}
          />
          <Trip404Page />
        </Switch>
      </RootWrapper>
    </TripGuard>
  )
}

export default TripPlannerRoutes

import React, { useCallback, useContext } from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TextLink from 'components/Luxkit/TextLink'
import ModalContext from 'contexts/ModalContext'
import CruiseModalItineraryDetails from 'components/Cruises/CruiseTiles/Common/CruiseModalItineraryDetails'
import CruiseModalPrices from 'components/Cruises/CruiseTiles/Common/CruiseModalPrices/CruiseModalPrices'
import useCruiseOfferView from 'hooks/Cruise/useCruiseOfferView'
interface Props {
  offer: App.CruiseOffer | App.TourOffer | App.TourOfferSummary;
  variant?: 'defualt' | 'condensed';
}

function CruiseDescription({ offer, variant }: Props) {
  const showModal = useContext(ModalContext)
  const departures = 'departuresSummary' in offer ? offer.departuresSummary : undefined

  const {
    earliestDepartureDate,
    itinerary,
    itineraryFlash,
    isFlashOffer,
    itineraryDetailsLabel,
    laterDates,
    cruiseLineName,
    shipName,
    offerTitle,
  } = useCruiseOfferView({ offer })

  const openItineraryModal = useCallback(async(e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (isFlashOffer) {
      await showModal<boolean>(<CruiseModalItineraryDetails itineraryFlash={itineraryFlash} offerTitle={offerTitle} offer={offer}/>)
    } else if (itinerary) {
      await showModal<boolean>(<CruiseModalItineraryDetails itinerary={itinerary} offerTitle={offerTitle} offer={offer}/>)
    }
  }, [isFlashOffer, itinerary, showModal, itineraryFlash, offerTitle, offer])

  const openPricesModal = useCallback(async(e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isFlashOffer) {
      await showModal<boolean>(<CruiseModalPrices
        offerTitle={offerTitle}
        offer={offer}
        departures={departures}/>)
    }
  }, [departures, isFlashOffer, offer, offerTitle, showModal])

  if (variant === 'condensed') {
    return <BodyText variant="medium" colour="neutral-two">
      {itineraryDetailsLabel}
    </BodyText>
  }

  return (
    <Group direction="vertical" gap={8}>
      <BodyText variant="medium" colour="neutral-two">
        {cruiseLineName} · {shipName}
      </BodyText>
      <Group direction="horizontal" gap={8}>
        <BodyText variant="medium" colour="neutral-two">
          {itineraryDetailsLabel}
        </BodyText>

        {(!!itinerary || isFlashOffer) && (
          <TextLink weight="regular" size="caption" onClick={openItineraryModal} data-testid="cruise-tile-details-itinerary-modal">
            <CSSBreakpoint min="mobile">View itinerary</CSSBreakpoint>
          </TextLink>
        )}
      </Group>

      <BodyText variant="medium" weight="bold" colour="neutral-two">
        {earliestDepartureDate && `Departing on ${earliestDepartureDate}`}{' '}
        {isFlashOffer && !!laterDates.length && <TextLink>
          and more
        </TextLink>}
        {!isFlashOffer && !!laterDates.length && <TextLink onClick={openPricesModal} data-testid="cruise-tile-details-prices-modal">
          and more
        </TextLink>}
      </BodyText>
    </Group>
  )
}

export default CruiseDescription

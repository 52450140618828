import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUtensilsIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 2a1 1 0 0 0-1 1v5.46l-1 .67V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 15 3v6.13l-1-.67V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 12 3v6a1.0006 1.0006 0 0 0 .45.83L15 11.54V21a1.0001 1.0001 0 0 0 2 0v-9.46l2.55-1.71A.9996.9996 0 0 0 20 9V3a1.0002 1.0002 0 0 0-1-1ZM9 2a5 5 0 0 0-5 5v6a1 1 0 0 0 1 1h3v7a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1ZM8 12H6V7a2.9999 2.9999 0 0 1 2-2.83V12Z"/>
  </SvgIcon>
}

export default LineUtensilsIcon

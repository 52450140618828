import { excludeNullOrUndefined } from 'checkout/utils'
import { findByTwoLetterCode } from 'lib/insurance/insuranceCountries'

export const getItineraryCountries = (offer: App.CruiseOffer) => {
  return offer.itinerary?.flatMap(itinerary => {
    const countryCode = itinerary.port?.countryCode
    if (countryCode) {
      return findByTwoLetterCode(countryCode)
    }
  }).filter(excludeNullOrUndefined)
}

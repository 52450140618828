import { useInlineListFormatter } from 'hooks/useListFormatters'
import { ListFormatterOptions } from 'lib/format/formatList'
import React, { memo } from 'react'

interface Props extends Pick<ListFormatterOptions, 'type' | 'style'> {
  list: Array<string>
}

function FormatInlineList(props: Props) {
  const { list, type, ...options } = props
  const inlineListFormatter = useInlineListFormatter(type, options)
  return <>{inlineListFormatter(list)}</>
}

export default memo(FormatInlineList)

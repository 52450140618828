import React from 'react'
import { RouteProps } from 'react-router'
import AuthRouteComponent from './AuthRouteComponent'
import TrackedRoute from './TrackedRoute'

interface Props extends RouteProps {
  showPopup?: boolean;
}

function AuthRoute(props: Props) {
  const {
    component,
    children,
    render,
    showPopup,
    ...rest
  } = props

  return <TrackedRoute {...rest}>
    {(routeProps) => <AuthRouteComponent
      {...routeProps}
      component={component}
      render={render}
      children={children}
      showPopup={showPopup}
    />}
  </TrackedRoute>
}

export default AuthRoute

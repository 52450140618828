import React, { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import HeadData from 'components/Common/HeadData/HeadData'
import ModalProvider from 'contexts/ModalProvider'
import useDidChange from 'hooks/useDidChange'
import TourProvider from 'tripPlanner/components/OnboardingTour/TourProvider'
import { DEFAULT_TRIP_IMAGE } from 'tripPlanner/config'
import HiddenSummariesContextProvider from 'tripPlanner/contexts/HiddenSummariesContext'
import TripSearchProvider from 'tripPlanner/contexts/Search/TripSearchProvider'
import ViewContextProvider from 'tripPlanner/contexts/View/ViewContextProvider'
import { ViewStateActions } from 'tripPlanner/contexts/View/ViewState'
import useViewState from 'tripPlanner/hooks/View/useViewState'

interface Props extends PropsWithChildren<{}> {}

function RootWrapper({ children }: Props) {
  const location = useLocation()
  const { state: viewState, dispatch: viewDispatch } = useViewState()

  const locationChanged = useDidChange(location)

  useEffect(() => {
    if (viewState.isModalOpen && locationChanged) {
      viewDispatch({
        type: ViewStateActions.MODAL_CLOSE,
      })
    }
  }, [viewState.isModalOpen, viewDispatch, locationChanged])

  return (
    <TripSearchProvider>
      <ViewContextProvider dispatch={viewDispatch} state={viewState}>
        <ModalProvider>
          <ModalProvider>
            <HiddenSummariesContextProvider>
              <TourProvider>
                <HeadData
                  title="Trip Planner"
                  description="Easily plan your perfect trip, all in one place"
                  imageUrl={DEFAULT_TRIP_IMAGE}
                />
                {children}
              </TourProvider>
            </HiddenSummariesContextProvider>
          </ModalProvider>
        </ModalProvider>
      </ViewContextProvider>
    </TripSearchProvider>
  )
}

export default RootWrapper

import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import React, { RefObject, useCallback, useContext, useMemo, useState } from 'react'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import CruiseLineMobileInput from './CruiseLineSelectMobile'
import CruiseLineSelectDesktop from './CruiseLineSelectDesktop'

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void
  dropdownAnchorRef: RefObject<HTMLElement>
  drawerMode?: boolean
}

function CruiseLineSelect(props: Props) {
  const { onChange, drawerMode, dropdownAnchorRef } = props
  const [searchTerm, setSearchTerm] = useState('')
  const { cruiseLines: searchCruiseLines = [] } = useContext(GlobalSearchStateContext)
  const [cruiseLines] = useCruiseSearchFacets({ facetTypes: ['cruise_lines'] })

  const cruiseLineNames = useMemo(() => {
    return cruiseLines
      .filter(({ value }) => searchCruiseLines.includes(value as string))
      .map(({ name }) => name)
  }, [searchCruiseLines, cruiseLines])

  const handlerSearchTerm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [])

  const handlerClearSearchTerm = useCallback(() => {
    setSearchTerm('')
  }, [])

  return (
    <div>
      <CSSBreakpoint max="mobile">
        <CruiseLineMobileInput
          onChange={onChange}
          cruiseLineNames={cruiseLineNames}
          handlerSearchTerm={handlerSearchTerm}
          handlerClearSearchTerm={handlerClearSearchTerm}
          searchTerm={searchTerm}
        />
      </CSSBreakpoint>

      <CSSBreakpoint min="tablet">
        {drawerMode && <CruiseLineMobileInput
          onChange={onChange}
          cruiseLineNames={cruiseLineNames}
          drawerMode={drawerMode}
          handlerSearchTerm={handlerSearchTerm}
          handlerClearSearchTerm={handlerClearSearchTerm}
          searchTerm={searchTerm}
        />}

        {!drawerMode && <CruiseLineSelectDesktop
          onChange={onChange}
          cruiseLineNames={cruiseLineNames}
          dropdownAnchorRef={dropdownAnchorRef}
          handlerSearchTerm={handlerSearchTerm}
          handlerClearSearchTerm={handlerClearSearchTerm}
          searchTerm={searchTerm}
        />}
      </CSSBreakpoint>
    </div>
  )
}

export default CruiseLineSelect

import React, { useContext, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'

const ballCycle = keyframes`
  0% { transform: translateX(25%) translateY(-50%) scale(0); }
  25% { transform: translateX(25%) translateY(-50%) scale(1); }
  50% { transform: translateX(250%) translateY(-50%) scale(1); }
  75% { transform: translateX(475%) translateY(-50%) scale(1); }
  100% { transform: translateX(475%) translateY(-50%) scale(0); }
`

const numBalls = 4
const period = 3.1

const Ball = styled.div`
  position: absolute;
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.brand.primary.normal};
  top: 50%;
  animation: ${ballCycle} ${period}s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;

  &.one {
    animation-delay: ${-period / numBalls}s;
  }

  &.two {
    animation-delay: ${-2 * period / numBalls}s;
  }

  &.three {
    animation-delay: ${-3 * period / numBalls}s;
  }

  &.four {
    animation-delay: ${-4 * period / numBalls}s;
  }
`

const Root = styled.div<{ size: number }>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => rem(size * 6)};
  height: ${({ size }) => rem(size * 2)};

  &.paused > div {
    animation-play-state: paused;
  }
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  paused?: boolean;
}

function LoadingAnimation(props: Props) {
  const { size = 16, paused, className, ...rest } = props

  const playState = useContext(LoaderPlayStateContext)
  const ballStyle = useMemo(() => ({ height: rem(size), width: rem(size) }), [size])

  return (
    <Root
      {...rest}
      size={size}
      className={cn(
        className,
        { paused: paused || playState === 'paused' },
      )}>
      <Ball style={ballStyle} className="one" />
      <Ball style={ballStyle} className="two" />
      <Ball style={ballStyle} className="three" />
      <Ball style={ballStyle} className="four" />
    </Root>
  )
}

export default React.memo(LoadingAnimation)

import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { arrayToObject, nonNullable, unique } from 'lib/array/arrayUtils'
import React, { useContext, useMemo } from 'react'
import { connect } from 'react-redux'

interface Props extends Omit<React.ComponentProps<typeof SearchFormField>, 'label' | 'placeholder' | 'value'> {
  categories: Array<App.ExperienceItemCategory>;
}

const ExperienceSearchCategoryField = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { categories, ...rest } = props
  const { experienceCategories } = useContext(GlobalSearchStateContext)

  const categoriesById = useMemo(() => arrayToObject(categories, cat => cat.id), [categories])
  const value = useMemo(() => {
    const categories = nonNullable(experienceCategories?.map(id => categoriesById[id]) ?? [])
    const parentCategories = categories.map(cat => categoriesById[cat.parentId] ?? cat)
    // we display the names of the parent categories selected, but the data is of the child
    // so we have to remap them back
    return unique(parentCategories).map(cat => cat.name).join(', ')
  }, [categoriesById, experienceCategories])

  return <SearchFormField
    {...rest}
    ref={ref}
    label="Type of experience"
    placeholder="e.g Food & Wine, Live Events, Attractions..."
    value={value}
  />
})

function mapStateToProps(state: App.State) {
  return {
    categories: state.experience.experienceCategories,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ExperienceSearchCategoryField)

import { actions, definitions } from '@luxuryescapes/contract-svc-agent'
import getAllItemViews from 'checkout/selectors/view/getAllItemViews'
import { OFFER_TYPE_BED_BANK, OFFER_TYPE_TOUR_V2, OFFER_TYPE_CRUISE, OFFER_TYPE_TOUR } from 'constants/offer'
import { sum, unique } from 'lib/array/arrayUtils'

type ServerAgentAccount = definitions['userSimpleSchema']
type ServerAgencyDetails = definitions['userAgencyResponse']
type ServerAgentEmailStatus = definitions['userStatusResponse']
type ServerAgentCommission = actions['calculateCartCommission']['responses']['200']['content']['application/json']['result']

interface BreakdownViewItem {
  price?: number
  total?: number
  memberTotal?: number
  memberPrice?: number
  surcharge?: number
  taxesAndFees?: number
  otherFees?: App.Checkout.OtherFees
}

interface BreakdownViewItemTourV2 extends BreakdownViewItem {
  item: App.Checkout.TourV2Item
}

interface CalculateCartCommissionBodyItem {
  offerId: string
  type: string
  price: number
  location: Array<string>
  vendor: string
}

interface CalculateCartCommissionBody {
  cartId: string;
  items: Array<CalculateCartCommissionBodyItem>;
  total: number;
}

const isTourItem = (item: BreakdownViewItem): item is BreakdownViewItemTourV2 => 'item' in item
const sumViewItemTotal = (item: BreakdownViewItem | BreakdownViewItemTourV2): number => {
  const itemPrice = item.price ?? 0
  const itemTotalPrice = isTourItem(item) ? item.item?.total ?? itemPrice : itemPrice
  return itemTotalPrice + (item.surcharge ?? 0) + (item.otherFees && 'extraGuestSurcharge' in item.otherFees ? item.otherFees?.extraGuestSurcharge ?? 0 : 0) }
const sumViewItemsTotal = (items: Array<BreakdownViewItem>): number => sum(items, (i) => sumViewItemTotal(i))

export function agentHubCheckEmailStatusMapper(response: ServerAgentEmailStatus): App.AgentHubAgentStatus {
  return {
    status: response.status ?? '',
    agentId: response.agent_id ?? '',
    action: response.action,
    ...(response.email && { email: response.email }),
    ...(response.firstName && { firstName: response.firstName }),
    ...(response.surname && { surname: response.surname }),
    ...(response.phone && { phone: response.phone }),
    ...(response.phone_prefix && { phonePrefix: response.phone_prefix }),
  }
}

export function agentHubAccountMapper(response: ServerAgentAccount): App.AgentHubAccount {
  return {
    agentId: response.id,
    name: response.name || '',
    phone: response.phone || '',
    email: response.email || '',
    affiliation: response.affiliation,
    agencyId: response.agencyId || '',
    agencyName: response.agencyName || '',
    status: response.status as any,
    agencyLogoId: response.agencyLogoId,
    agentTier: response.tier,
  }
}

export function agentHubAgencyMapper(response: ServerAgencyDetails): App.AgentHubAgency {
  return {
    id: response.id,
    name: response.name,
    agencyIdentifier: response.businessCodeValue,
    agencyIdentifierType: response.businessCodeType,
    address: response.address,
    city: response.city,
    state: response.state,
    postcode: response.postcode,
    country: response.country,
    affiliation: response.affiliation,
    brand: response.brand,
  }
}

const getCommissionItems = (state: App.State): Array<CalculateCartCommissionBodyItem> => {
  const resultItems: Array<CalculateCartCommissionBodyItem> = []
  const { accommodationItemsView, tourV2ExperienceItemsView, experienceItemsView, transferItemsView } = getAllItemViews(state).data

  if (experienceItemsView.data.length > 0) {
    for (const item of experienceItemsView.data) {
      resultItems.push({
        offerId: item.id,
        type: 'experience',
        price: item.price,
        location: [item.location || ''],
        vendor: '',
      })
    }
  }

  for (const item of transferItemsView.data || []) {
    resultItems.push({
      offerId: item.experienceId,
      type: 'transfer',
      price: item.transfer.option?.price || 0,
      location: [],
      vendor: '',
    })
  }

  if (accommodationItemsView.data.length > 0) {
    for (const item of accommodationItemsView.data) {
      if (item.offerType === OFFER_TYPE_TOUR) {
        const locations = unique((item?.offer?.locations || []).concat(item?.offer?.location || []))

        resultItems.push({
          offerId: item.offerId,
          type: item.offer?.productType || item.offerType,
          price: sumViewItemsTotal(item.itemViews),
          location: locations,
          vendor: item.offer?.vendorName || '',
        })

        continue
      }

      if (item.offerType === OFFER_TYPE_BED_BANK) {
        const locations = [
          item?.offer?.property?.address.city || '',
          item?.offer?.property?.address.stateProvinceName || '',
          item.offer?.property?.address.countryName || '']
          .filter(Boolean)

        resultItems.push({
          type: item.offer?.productType || '',
          offerId: item.offerId,
          price: sumViewItemsTotal(item.itemViews),
          location: locations,
          vendor: '',
        })

        continue
      }

      if (item.offerType === OFFER_TYPE_TOUR_V2) {
        const tourV2StartLocation = () => {
          const variations = item.itemViews.map((x) => x.variation)
          if (variations.length === 0) return ['']
          const variation = variations[0]
          return [variation?.startLocation || '']
        }

        const optionalExperiences = tourV2ExperienceItemsView?.data || []
        const optionalExperiencesTotalPrice = optionalExperiences.reduce((acc, curr) => acc + curr.item.purchasableOption.fullPrice, 0)

        resultItems.push({
          type: item.offer?.productType || '',
          offerId: item.offerId,
          price: optionalExperiencesTotalPrice + sumViewItemsTotal(item.itemViews),
          location: tourV2StartLocation(),
          vendor: '',
        })

        continue
      }

      if (item.offerType === OFFER_TYPE_CRUISE) {
        resultItems.push({
          type: item.offer?.productType || '',
          offerId: item.offerId,
          price: sumViewItemsTotal(item.itemViews),
          location: [item.location],
          vendor: '',
        })

        continue
      }

      const locations = unique((item?.offer?.locations || []).concat(item?.offer?.location || []))

      resultItems.push({
        offerId: item.offerId,
        type: item.offer?.productType || item.offerType,
        price: sumViewItemsTotal(item.itemViews),
        location: locations,
        vendor: item.offer?.vendorName || '',
      })
    }
  }

  return resultItems
}

export const stateToCommissionOrder = (state: App.State): CalculateCartCommissionBody => {
  const items = getCommissionItems(state)
  return {
    items: getCommissionItems(state),
    cartId: state.checkout.cart.cartId,
    total: sum(items, (i) => i.price || 0),
  }
}

export const agentHubCommissionMap = (serverCommission: ServerAgentCommission): App.AgentHubCommission => {
  return {
    promoCode: serverCommission.promoCode,
    itemsCommission: Object.fromEntries(
      Object.entries(serverCommission.itemsCommission).map(([key, value]) => [
        key,
        {
          commissionPercentage: value.commissionPercentage,
          itemDiscount: value.itemDiscount,
        },
      ]),
    ),
  }
}

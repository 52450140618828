import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ListItem from 'components/Luxkit/List/ListItem'
import Typography from 'components/Luxkit/Typography/Typography'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { rem } from 'polished'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import { without } from 'lib/array/arrayUtils'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'

const Divider = styled.hr`
  height: 1px;
  position: relative;
  border: none;
  margin-bottom: 0;
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    left: ${rem(-8)};
    right: ${rem(-8)};
    height: 1px;
    background-color: ${props => props.theme.palette.neutral.default.six};
  }
`

interface Props {
  groupTypes: Array<Tours.TourItemGroupType>;
}

function TourV2SearchGroupItems(props: Props) {
  const { groupTypes } = props
  const { tourGroupTypes = [] } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const toggleTourGroupType = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const groupId = e.currentTarget.dataset.groupid as Tours.TourGroupType
    const newTourGroupTypes = tourGroupTypes?.includes(groupId) ? without(tourGroupTypes, groupId) : [...tourGroupTypes, groupId]
    searchDispatch({
      type: GlobalSearchStateActions.SET_TOUR_GROUP_TYPE,
      tourGroupTypes: newTourGroupTypes,
    })
  }, [searchDispatch, tourGroupTypes])

  const onSelectAllGroup = useCallback(() => {
    searchDispatch({ type: GlobalSearchStateActions.SELECT_ALL_TOUR_GROUP_TYPE })
  }, [searchDispatch])

  return <VerticalSpacer gap={8}>
    <ListItem
      title="All group types"
      subtitle="Inspire me with all ways to travel"
      startIcon={<LineCompassIcon />}
      onClick={onSelectAllGroup}
      type="submit"
    />
    <Divider />
    {groupTypes.map(group => <ListItem
      key={group.id}
      title={group.name}
      subtitle={<Typography>{group.subtitle}</Typography>}
      data-groupid={group.id}
      startIcon={group.icon}
      endIcon={<CheckboxButton
        data-testid={group.id}
        checked={tourGroupTypes?.includes(group.id)}
        size="large"
      />}
      onClick={toggleTourGroupType}
    />)}
  </VerticalSpacer>
}

export default TourV2SearchGroupItems

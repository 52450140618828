const zIndex = {
  min: 1,
  backdrop: 1,
  balloon: 2,
  offerFilters: 2,
  stickyHeaderBanner: 2,
  floatingBookmarkButton: 3,
  balloonArrow: 3,
  searchLocationSuggestions: 4,
  searchSubMenu: 4,
  searchMenu: 5,
  stickySocialWidget: 9,
  tooltip: 10,
  supportAssistantChatWidget: 15,
  stickyFooter: 20,
  stickySubHeader: 99,
  stickyHeader: 100,
  stickyPromptCard: 101,
  subHeader: 103,
  floatingPanel: 104,
  header: 104,
  menu: 105,
  draggingItem: 106,
  spoofingWarning: 299,
  modal: 300,
  menuInModal: 301,
  notification: 301,
} as const

export default zIndex

/**
 * These products labels can be for the entire app at the time of
 * initialising `constants/config`
 */

export const LE_FLASH_HOTEL_PRODUCT_LABEL = 'Limited Time LUX Exclusive'
export const FLASH_HOTEL_PRODUCT_LABEL = 'Limited Time Exclusive'

export const LE_TAO_HOTEL_PRODUCT_LABEL = 'LUX Premium Collection'
export const TAO_HOTEL_PRODUCT_LABEL = 'Premium Collection'

export const LE_TOUR_PRODUCT_LABEL = null
export const TOUR_PRODUCT_LABEL = null

export const LE_TOUR_V2_PRODUCT_LABEL = null
export const TOUR_V2_PRODUCT_LABEL = null

export const LE_CRUISE_PRODUCT_LABEL = null
export const CRUISE_PRODUCT_LABEL = null

export const LE_RENTAL_PRODUCT_LABEL = null
export const RENTAL_PRODUCT_LABEL = null

export const LE_LME_HOTEL_PRODUCT_LABEL = null
export const LME_HOTEL_PRODUCT_LABEL = 'Last Minute Escape'

export const LE_LPP_PRODUCT_LABEL = 'LUX Partner Property'
export const LPP_PRODUCT_LABEL = 'Partner Property'

export const LUX_BUNDLE_LABEL = 'Bundle & Save'

export const LE_PARTNER = 'LUX Trusted Partner'
export const LE_EXCLUSIVE = 'LUX Exclusive'

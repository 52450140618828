import { registerPageView } from 'actions/AnalyticsActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { useEffect } from 'react'

import { Route, useRouteMatch } from 'react-router'

interface Props extends React.ComponentProps<typeof Route> {}

function TrackedRoute(props: Props) {
  const dispatch = useAppDispatch()
  const match = useRouteMatch(props)

  useEffect(() => {
    if (match) {
      dispatch(registerPageView({
        template: match.path,
        params: match.params,
        path: match.url,
        search: props.location?.search,
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    match?.url,
  ])

  return <Route {...props} />
}

export default TrackedRoute

import request from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-customer-communication'
import { mapUserPreferencesOccupancy } from './mappers/userPreferencesMap'

type fetchUserPreferenceDestinationDefaultResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations/defaults']['get']['responses']['200']['content']['application/json']['result']
type fetchUserPreferenceDestinationResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations']['get']['responses']['200']['content']['application/json']['result']
type updateUserPreferenceDestinationRequest = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations']['put']['parameters']['body']['payload'];
type updateUserPreferenceDestinationResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations']['put']['responses']['200']['content']['application/json']['result']
type deleteUserPreferenceDestinationResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations/{placeId}']['delete']['responses']['200']['content']['application/json']['result']
type fetchUserPreferenceHolidayTypesDefaultResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types/defaults']['get']['responses']['200']['content']['application/json']['result']
type fetchUserPreferenceHolidayTypesResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types']['get']['responses']['200']['content']['application/json']['result']
type updateUserPreferenceHolidayTypesRequest = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types']['put']['parameters']['body']['payload'];
type updateUserPreferenceHolidayTypesResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types']['put']['responses']['200']['content']['application/json']['result']
type deleteUserPreferenceHolidayTypesResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types/{holidayTypeId}']['delete']['responses']['200']['content']['application/json']['result']
type fetchUserPreferenceExternalBrandsDefaultResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands/defaults']['get']['responses']['200']['content']['application/json']['result']
type fetchUserPreferenceExternalBrandsResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands']['get']['responses']['200']['content']['application/json']['result']
type updateUserPreferenceExternalBrandsRequest = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands']['put']['parameters']['body']['payload'];
type updateUserPreferenceExternalBrandsResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands']['put']['responses']['200']['content']['application/json']['result']
type deleteUserPreferenceExternalBrandsResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands/{externalBrandId}']['delete']['responses']['200']['content']['application/json']['result']
type fetchUserPreferenceOccupancyDefaultResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy/schema']['get']['responses']['200']['content']['application/json']['result']
type fetchUserPreferenceOccupancyResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['get']['responses']['200']['content']['application/json']['result']
type updateUserPreferenceOccupancyRequest = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['put']['parameters']['body']['payload'];
type updateUserPreferenceOccupancyResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['put']['responses']['200']['content']['application/json']['result']
type deleteUserPreferenceOccupancyResponse = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['delete']['responses']['200']['content']['application/json']['result']

// user preference endpoints
export function fetchUserPreferenceDestinationDefault(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceDestinationDefaultResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/destinations/defaults`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destinations)
}

export function fetchUserPreferenceDestination(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceDestinationResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/destinations`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destinations)
}

export function updateUserPreferenceDestination(userId: string, data: updateUserPreferenceDestinationRequest, brand: string) {
  return request.put<App.ApiResponse<updateUserPreferenceDestinationResponse>, updateUserPreferenceDestinationRequest>(`/api/customer-communication/v2/users/${userId}/preferences/destinations?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destination)
}

export function deleteUserPreferenceDestination(userId: string, placeId: string) {
  return request.delete<App.ApiResponse<deleteUserPreferenceDestinationResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/destinations/${placeId}`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destination)
}

export function fetchUserPreferenceHolidayTypesDefault(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceHolidayTypesDefaultResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types/defaults`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayTypes)
}

export function fetchUserPreferenceHolidayTypes(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceHolidayTypesResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayTypes)
}

export function updateUserPreferenceHolidayTypes(userId: string, data: updateUserPreferenceHolidayTypesRequest, brand: string) {
  return request.put<App.ApiResponse<updateUserPreferenceHolidayTypesResponse>, updateUserPreferenceHolidayTypesRequest>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayType)
}

export function deleteUserPreferenceHolidayTypes(userId: string, holidayTypeId: string) {
  return request.delete<App.ApiResponse<deleteUserPreferenceHolidayTypesResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types/${holidayTypeId}`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayType)
}

export function fetchUserPreferenceExternalBrandsDefault(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceExternalBrandsDefaultResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands/defaults`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrands)
}

export function fetchUserPreferenceExternalBrands(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceExternalBrandsResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrands)
}

export function updateUserPreferenceExternalBrands(userId: string, data: updateUserPreferenceExternalBrandsRequest, brand: string) {
  return request.put<App.ApiResponse<updateUserPreferenceExternalBrandsResponse>, updateUserPreferenceExternalBrandsRequest>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrand)
}

export function deleteUserPreferenceExternalBrands(userId: string, externalBrandId: string) {
  return request.delete<App.ApiResponse<deleteUserPreferenceExternalBrandsResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands/${externalBrandId}`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrand)
}

export function fetchUserPreferenceOccupancyDefault(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceOccupancyDefaultResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy/schema`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.occupancy)
}

export function fetchUserPreferenceOccupancy(userId: string) {
  return request.get<App.ApiResponse<fetchUserPreferenceOccupancyResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy`, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferencesOccupancy(res.result))
}

export function updateUserPreferenceOccupancy(userId: string, data: updateUserPreferenceOccupancyRequest, brand: string) {
  return request.put<App.ApiResponse<updateUserPreferenceOccupancyResponse>, updateUserPreferenceOccupancyRequest>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferencesOccupancy(res.result))
}

export function deleteUserPreferenceOccupancy(userId: string) {
  return request.delete<App.ApiResponse<deleteUserPreferenceOccupancyResponse>>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy`, {
    credentials: 'include',
  },
  ).then((res) => res.result)
}

import { mediaQueryUp } from 'components/utils/breakpoint'
import useScrollEndsObserver from 'hooks/useScrollEndsObserver'
import { stopPropagation } from 'lib/web/eventHandlerUtils'
import { rem } from 'polished'
import React, { PropsWithChildren, forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { LuxkitModalBehaviourDispatchContext } from './context/LuxkitModalBehaviourContexts'
import { LuxkitModalBehaviourDispatchActions } from './context/LuxkitModalBehaviourState'
import { StyledModalContent } from './ModalContent'

export const StyledModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  overflow-y: auto;
  scroll-padding-top: ${rem(20)};
  scroll-padding-bottom: ${rem(20)};

  ${mediaQueryUp.tablet} {
    scroll-padding-top: ${rem(40)};
    scroll-padding-bottom: ${rem(24)};
  }

  > ${StyledModalContent} {
    flex-grow: 1;
  }
`

interface ModalBodyProps {
  onHasReachedEnd?: (hasReached: boolean) => void
  onHasReachedStart?: (hasReached: boolean) => void
}

const ModalBody = forwardRef<HTMLDivElement, PropsWithChildren<ModalBodyProps>>((props, ref) => {
  const {
    children,
    onHasReachedEnd,
    onHasReachedStart,
  } = props

  const modalBehaviourDispatch = useContext(LuxkitModalBehaviourDispatchContext)

  const bodyRef = useRef<HTMLDivElement | null>(null)

  const {
    hasReachedVerticalEnd,
    hasReachedVerticalStart,
  } = useScrollEndsObserver(bodyRef, children)

  useImperativeHandle(ref, () => bodyRef.current!)

  useEffect(() => {
    modalBehaviourDispatch({
      type: LuxkitModalBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_END,
      hasReached: hasReachedVerticalEnd,
    })
    onHasReachedEnd?.(hasReachedVerticalEnd)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReachedVerticalEnd, onHasReachedEnd])

  useEffect(() => {
    modalBehaviourDispatch({
      type: LuxkitModalBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_START,
      hasReached: hasReachedVerticalStart,
    })
    onHasReachedStart?.(hasReachedVerticalStart)
  }, [modalBehaviourDispatch, hasReachedVerticalEnd, hasReachedVerticalStart, onHasReachedStart])

  return <StyledModalBody ref={bodyRef} onClick={stopPropagation}>
    {children}
  </StyledModalBody>
})

ModalBody.displayName = 'ModalBody'

export default ModalBody

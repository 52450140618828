import requestUtils, { authOptions } from 'api/requestUtils'
import { BusinessResponses, definitions } from '@luxuryescapes/contract-business'
import { unique } from 'lib/array/arrayUtils'

export enum BUSINESS_ACCOUNT_FETCH_ERRORS {
  NO_BUSINESS_ASSOCIATED = 'This account is not associated with any business!',
  OTHER = 'Something went wrong!',
  INVITATION_PENDING = 'This invitation is pending.',
  INVITATION_EXPIRED = 'This invitation has expired.',
  EMPLOYEE_DEACTIVATED = 'This account has been deactivated.',
}

interface AccountBusinessResponse {
  business: BusinessTraveller.Business
  employee: BusinessTraveller.Employee
  employees: Array<BusinessTraveller.EmployeeFromMeEndpoint>
  employeeOnboardingStatus: BusinessTraveller.EmployeeOnboardingStatus
  displayFlightBudgetNotification: BusinessTraveller.DisplayFlightBudgetNotification
}

function mapBusinessTravellerBusinessAccount(result: definitions['employeeSchema']): AccountBusinessResponse {
  const business: BusinessTraveller.Business = {
    country: result.business.country,
    id: result.business.id,
    industry: result.business.industry as BusinessTraveller.BusinessIndustries,
    name: result.business.name,
    employeeCount: result.business.employeeCount as BusinessTraveller.BusinessEmployeeCountOptions,
    businessIdentifier: result.business.businessIdentifier,
    businessIdentifierType: result.business.businessIdentifierType,
    isCreditEnabled: result.business.offersCredits,
    frequentFlyerItems: result.business.frequentFlyerItems,
  }

  const employee: BusinessTraveller.Employee = {
    customerId: result.customerId,
    id: result.id,
    email: result.email,
    position: result.position,
    firstName: result.firstName,
    lastName: result.lastName,
    roles: unique(result.roles),
    onboardingFlags: result.onboardingFlags ?? [],
    employeeFrequentFlyer: result.employeeFrequentFlyer,
  }
  const employeeOnboardingStatus = result.onboardingStatus
  const displayFlightBudgetNotification = result.displayFlightBudgetNotification
  const employees = result.employees ? result.employees.map(employee => {
    return {
      customerId: employee.customerId,
      email: employee.email,
      id: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      employeeFrequentFlyer: employee.employeeFrequentFlyer,
    }
  }) : []

  return { business, employee, employees, employeeOnboardingStatus, displayFlightBudgetNotification }
}

function mappedEmployeeErrorMessage(error: string) {
  switch (error) {
    case 'Invitation pending':
      return BUSINESS_ACCOUNT_FETCH_ERRORS.INVITATION_PENDING
    case 'Invitation expired':
      return BUSINESS_ACCOUNT_FETCH_ERRORS.INVITATION_EXPIRED
    case 'Employee deactivated':
      return BUSINESS_ACCOUNT_FETCH_ERRORS.EMPLOYEE_DEACTIVATED
    default:
      return BUSINESS_ACCOUNT_FETCH_ERRORS.OTHER
  }
}

async function requestGetAccountBusiness(options: { accessToken?: string, includeEmployees?: boolean }) {
  try {
    const { accessToken, includeEmployees } = options
    const includeEmployeesParam = includeEmployees ? '?includeEmployees=true' : ''
    const response = await requestUtils.get<BusinessResponses.MeListIndex>(`/api/business-traveller/me${includeEmployeesParam}`, authOptions(accessToken))

    if (!response.result.length) {
      return Promise.reject(BUSINESS_ACCOUNT_FETCH_ERRORS.NO_BUSINESS_ASSOCIATED)
    }

    const serverData = response.result[0]
    if ('error' in serverData) {
      return Promise.reject(mappedEmployeeErrorMessage(serverData.error))
    }
    return mapBusinessTravellerBusinessAccount(serverData)
  } catch {
    return Promise.reject(BUSINESS_ACCOUNT_FETCH_ERRORS.OTHER)
  }
}

export default requestGetAccountBusiness

import styled from 'styled-components'

import BaseNotificationDot from './BaseNotificationDot'

const PrimaryNotificationDot = styled(BaseNotificationDot)`
  background-color: ${(props) =>
    props.theme.palette.highlight.secondary.normalBackground};
`

export default PrimaryNotificationDot

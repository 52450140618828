import { PRIMARY_TRAVELLER_FORM_ID } from 'constants/checkout'
import { arrayToObject } from 'lib/array/arrayUtils'
import { getAttribute } from 'lib/object/objectUtils'

interface FormPayload {
  [fieldId: string]: string
}

interface Item {
  form: App.Checkout.TravellerForm,
  schema: App.Checkout.TravellerFormSchema,
}

interface TravellerInformationPayload {
  customer: FormPayload | null;
  participants?: Array<FormPayload>
}

function buildCustomerPayload(item: Item): FormPayload {
  return Object.keys(item.schema.properties).reduce<{[key: string]: string}>((customer, property) => {
    let value = getAttribute(item.form, property)

    // key phonePrefix doesn't exist on the form schema
    if (property === 'phonePrefix') {
      value = item.form?.prefix
    }

    if (value) {
      const keys = [property]
      keys.forEach((key: string) => {
        customer[key] = value
      })
    }

    return customer
  }, {})
}

function buildParticipantPayload(item: Item): FormPayload {
  const fields = Object.keys(item.schema.properties)

  return fields.reduce((acc, field) => {
    const value = item.form[field]

    return { ...acc, [field]: value }
  }, {})
}

export function buildTravellerInfo(
  schema: App.Checkout.FormSchema,
  forms: Array<App.Checkout.TravellerForm>,
): TravellerInformationPayload {
  const formsMap = arrayToObject(forms, form => form.id, form => form)

  const customerItem = {
    form: formsMap[PRIMARY_TRAVELLER_FORM_ID],
    schema: schema.properties[PRIMARY_TRAVELLER_FORM_ID],
  }

  const participantItems = Object.entries<App.Checkout.TravellerFormSchema>(schema.properties)
    .reduce((acc, [key, schema]) => {
      if (!schema.isParticipant) { return acc }
      return [...acc, { form: formsMap[key], schema }]
    }, [] as Array<Item>)

  const participantPayloadItems = participantItems.map(buildParticipantPayload)

  return {
    customer: buildCustomerPayload(customerItem),
    ...(participantPayloadItems.length > 0 ? { participants: participantPayloadItems } : {}),
  }
}

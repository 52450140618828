import { AppAction } from 'actions/ActionTypes'
import { BUSINESS_TRAVELLER_ACTIONS } from 'reducers/businessTravellerActionReducer'

function setSelectedBusinessTravellersAndGuests(employeeIds: Array<string> | undefined, guestCount?: number): AppAction {
  return (dispatch) => {
    dispatch({
      type: BUSINESS_TRAVELLER_ACTIONS.SET_BUSINESS_TRAVELLER,
      employeeIds,
      guestCount,
    })
  }
}

export default setSelectedBusinessTravellersAndGuests

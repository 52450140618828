import React from 'react'
import { rem } from 'polished'
import styled from 'styled-components'

import Pane from 'components/Common/Pane'
import PaneBody from 'components/Common/Pane/PaneBody'
import LineEnvelopeIcon from 'components/Luxkit/Icons/line/LineEnvelopeIcon'
import TextLink from '../../Luxkit/TextLink'
import BodyText from '../../Luxkit/Typography/BodyText'
import SupportNumberButton from '../../Luxkit/Button/SupportNumberButton'

const StyledPane = styled(Pane)`
  margin-top: ${rem(24)};
  border-radius: ${props => props.theme.borderRadius.M};
`

const StyledBody = styled(PaneBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > * + * {
    margin-top: ${rem(12)};
  }
`

interface Props {
  heading?: string;
  description?: string;
  contactNumber?: App.GeoContact;
  email?: string;
  refCode?: string;
  onSupportEmailClick?: () => void;
  onSupportNumberClick?: () => void;
  waiverMessage?: string;
}

function HelpPane({ heading = 'Need help? Call our support team', description, contactNumber, email, refCode, onSupportEmailClick, onSupportNumberClick, waiverMessage }: Props) {
  return <StyledPane type="light">
    <StyledBody size="md">
      <BodyText variant="large" weight="bold">{heading}</BodyText>
      <BodyText variant="medium">
        {description || 'Our customer service team are available anytime, 24 hours, 7 days a week'}
      </BodyText>
      <SupportNumberButton onClick={() => onSupportNumberClick?.()} currentContact={contactNumber} />
      {email && <TextLink onClick={() => onSupportEmailClick?.()} startIcon={<LineEnvelopeIcon />} variant="black" href={`mailto:${email}`} rel="nofollow">{email}</TextLink>}
      {refCode && <div>
        <BodyText variant="large" align="center" weight="bold">Reference Code</BodyText>
        <BodyText variant="large">{refCode}</BodyText>
      </div>}
      {waiverMessage && <BodyText variant="small" align="center">{waiverMessage}</BodyText>}
    </StyledBody>
  </StyledPane>
}

export default React.memo(HelpPane)

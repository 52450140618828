import { fetchChats } from 'actions/supportAssistant/supportAssistantActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { sortBy } from 'lib/array/arrayUtils'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { getUpcomingOrders } from 'selectors/orderSelectors'

export const DEFAULT_CHAT: App.Chat = {
  id: 'default',
  title: 'New chat',
  status: 'ACTIVE',
  createdAt: new Date().toString(),
  updatedAt: new Date().toString(),
}

export const emptyChat: App.SupportAssistantChatsState = {
  chats: [DEFAULT_CHAT],
  fetching: false,
  error: undefined,
}

function useChats(): [Array<App.Chat>, boolean | undefined, any] {
  const dispatch = useAppDispatch()
  const chats = useAppSelector(state => state.supportAssistant.chats)
  const ordersFetched = useAppSelector(state => state.orders.ordersFetched)
  const upcoming = useAppSelector(state => getUpcomingOrders(state))

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const chatDeepLink = queryParams.get('list-orders') === 'true' ? 'list-orders' : 'none'
  const chatDeepLinkKey: App.ChatDeepLinks = chatDeepLink

  const realChats = chats && chats.chats?.length > 0 ? chats : emptyChat

  const sortedRealChats = useMemo(() => {
    const sortedChats = sortBy(realChats.chats, chat => +(new Date(chat.updatedAt)), 'desc')

    return sortedChats
  }, [realChats.chats])

  useEffect(() => {
    if (ordersFetched) {
      dispatch(fetchChats(upcoming, chatDeepLinkKey))
    }

    const removeDeepLinkParam = () => {
      const queryParams = new URLSearchParams(location.search)

      queryParams.delete('list-orders')

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`

      window.history.replaceState({}, '', newUrl)
    }

    removeDeepLinkParam()
  }, [chatDeepLinkKey, dispatch, location.search, ordersFetched, upcoming])

  return [
    sortedRealChats,
    realChats.fetching,
    realChats.error,
  ]
}

export default useChats

import { createSelector } from '@reduxjs/toolkit'

export const selectCurrentChat = createSelector(
  (state: App.State) => state.supportAssistant.chats,
  (state: App.State) => state.supportAssistant.currentChatId,
  (
    chats,
    currentChatId,
  ): App.Chat | undefined => {
    return chats?.chats?.find(chat => {
      if (currentChatId) {
        return chat.id === currentChatId
      } else {
        return chat.status === 'ACTIVE'
      }
    })
  },
)

export const selectOutageMessage = createSelector(
  (state: App.State) => state.supportAssistant.outageMessage,
  (
    outageMessage,
  ): string | undefined => {
    return outageMessage
  },
)

import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'

/**
 * getDiscountTotal returns the total dollar amount the promo code is worth for this order.
 * Note: commission is basically a promo code too
 *
 * Old Cart uses the clients-side logic below for the promo calculation
 * New Checkout uses Server-Side promo (or PromoV2) calculations via making a POST request to /api/promo/discount with a 'Discount Request Order'
 */
const getDiscountTotal = createSelector(
  (state: App.State) => state.checkout.promo,
  (state: App.State) => state.checkout.commission,
  (promotion, commission) => {
    const promoTotal = promotion?.discountTotal || 0
    const commissionTotal = commission?.discountTotal || 0

    return floatify(promoTotal + commissionTotal)
  },
)

export default getDiscountTotal

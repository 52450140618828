import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_BED_BANK, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2, OFFER_TYPE_CRUISE, OFFER_TYPE_VILLA } from 'constants/offer'

export function excludeNullOrUndefined<T>(item: T | undefined | null): item is T {
  return Boolean(item)
}

export function getBreakdownTitle(offer: App.Checkout.AccommodationOfferView): string {
  switch (offer.offerType) {
    case OFFER_TYPE_HOTEL:
    case OFFER_TYPE_LAST_MINUTE:
    case OFFER_TYPE_BED_BANK:
    case OFFER_TYPE_ALWAYS_ON:
    case OFFER_TYPE_VILLA:
      return 'Accommodation'
    case OFFER_TYPE_TOUR:
      return 'Cruise'
    case OFFER_TYPE_TOUR_V2:
      return 'Tour'
    case OFFER_TYPE_CRUISE:
      return 'Cruise'
    default:
      return 'Package'
  }
}

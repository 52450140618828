import qs from 'qs'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'

import { pushWithRegion } from 'actions/NavigationActions'

import TourV2SearchInputs from './TourV2SearchInputs'
import Heading from 'components/Luxkit/Typography/Heading'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineEditAltIcon from 'components/Luxkit/Icons/line/LineEditAltIcon'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { ANYWHERE_SEARCH_ITEM, SEARCH_VERTICALS } from 'constants/search'
import Clickable from 'components/Common/Clickable/BaseClickable'
import { RouteComponentProps, withRouter } from 'react-router'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'

import { prettyFlexibleDates } from 'components/Search/SearchForm/SearchDateInput/FlexibleDateSearchField'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import { tourDurationOptions } from 'constants/tours'

import GlobalSearchTrackingContext from 'contexts/GlobalSearch/GlobalSearchTracking'
import { OFFER_TYPE_TOUR } from 'constants/offer'
import { getTourGroupTypeSearchMap } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalToursSearch'
import { useAppDispatch } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'

const EditContainer = styled(Clickable)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${rem(2)};
`

const StyledDescriptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: ${rem(8)};
`

interface Props extends RouteComponentProps {
  editMenuOnMobile?: boolean;
  searchItem?: App.SearchItem
  onSearch?: (searchItem: App.SearchItem) => void
}

function TourV2SearchControls(props: Props) {
  const {
    onSearch,
    editMenuOnMobile = false,
  } = props
  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const onSearchEvent = useContext(GlobalSearchTrackingContext)

  const openSearchDrawer = useCallback(() => { toggleSearchVertical(SEARCH_VERTICALS.TOURS) }, [toggleSearchVertical])

  const dispatch = useAppDispatch()

  const { searchItem, flexibleNights, flexibleMonths, userSelectedFlexibleMonths, tourGroupTypes = [], eventAnalytics, dateSearchOptionId } = useContext(GlobalSearchStateContext)

  const handleSearchButtonClick = useCallback(() => {
    const toBeSearchedItem = searchItem ?? ANYWHERE_SEARCH_ITEM

    const tourGroupTypesToQuery = getTourGroupTypeSearchMap(tourGroupTypes)

    const query = qs.stringify({
      destinationId: toBeSearchedItem.value,
      destinationName: toBeSearchedItem.format.mainText,
      flexibleNights: flexibleNights ?? undefined,
      flexibleMonths: flexibleMonths ?? undefined,
      ...tourGroupTypesToQuery,
    })

    if (eventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        eventAnalytics.contextLocation,
        eventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItem,
          searchVerticals: new Set([SEARCH_VERTICALS.TOURS]),
          dateSearchOptionId,
          flexibleNights,
        }),
      ))
    }

    dispatch(pushWithRegion(`/search/tours?${query}`))

    onSearch?.(toBeSearchedItem)
    if (onSearchEvent) {
      const params = {
        searchItem,
        vertical: OFFER_TYPE_TOUR,
      }
      onSearchEvent('submit-button', params)
    }
  }, [searchItem, tourGroupTypes, flexibleNights, flexibleMonths, eventAnalytics, dateSearchOptionId, dispatch, onSearch, onSearchEvent])

  return <>
    {!editMenuOnMobile && <TourV2SearchInputs
      onSearchButtonClick={handleSearchButtonClick}
      onOpenSearchDrawer={openSearchDrawer}
    />}

    {editMenuOnMobile && <CSSBreakpoint min="tablet">
      <TourV2SearchInputs onSearchButtonClick={handleSearchButtonClick} onOpenSearchDrawer={openSearchDrawer} />
    </CSSBreakpoint>}
    {editMenuOnMobile && <CSSBreakpoint max="mobile">
      <EditContainer onClick={openSearchDrawer}>
        <StyledDetailsSection>
          {searchItem && <Heading variant="heading6">{searchItem.format.mainText}</Heading>}
          <StyledDescriptionsSection>
            {(flexibleNights || flexibleMonths) &&
              <BodyText colour="neutral-two" variant="medium">{prettyFlexibleDates(userSelectedFlexibleMonths ? flexibleMonths : undefined, flexibleNights, tourDurationOptions)}</BodyText>
            }
          </StyledDescriptionsSection>
        </StyledDetailsSection>
        <TextButton kind="tertiary" endIcon={<LineEditAltIcon />} nonInteractable>Edit</TextButton>
      </EditContainer>
    </CSSBreakpoint>}
  </>
}

export default withRouter(TourV2SearchControls)

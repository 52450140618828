import { SET_UTM } from 'actions/actionConstants'
import { createReducer } from 'lib/redux/reducerUtils'

export const initialUtmState:App.UTMState = {
  source: '',
  medium: '',
}

const utmReducer = createReducer<App.UTMState>(initialUtmState, {
  [SET_UTM]: (state, action) => (action.utm),
})

export default utmReducer

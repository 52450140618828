import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePlaneFlyIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M23 9.32a1.06 1.06 0 0 0-.1-.76 4.9292 4.9292 0 0 0-3.0012-2.3019 4.9296 4.9296 0 0 0-3.7488.502L14 8 9 5.65a1 1 0 0 0-.92 0l-3 1.73a1.0001 1.0001 0 0 0-.5.84 1 1 0 0 0 .46.87l3.3 2.0799-1.74 1-4.78.58a1.0003 1.0003 0 0 0-.8343.7026A.9998.9998 0 0 0 1.29 14.5l3.54 3.06a2.9997 2.9997 0 0 0 3.55.44L22.5 9.93a1.0009 1.0009 0 0 0 .5-.61Zm-15.53 7a1.0001 1.0001 0 0 1-1.2-.18l-1.9-1.63 2.73-.33a1 1 0 0 0 .38-.13l3.36-1.93a1.0004 1.0004 0 0 0 .5-.85 1.0007 1.0007 0 0 0-.47-.86l-3.3-2.09 1.1-.63 5 2.32a.9991.9991 0 0 0 .92 0l2.56-1.48a3.0001 3.0001 0 0 1 3.36.29l-13.04 7.5Z"/>
  </SvgIcon>
}

export default LinePlaneFlyIcon

import { calculateTotalJourneyBaggageCost } from 'checkout/lib/utils/flights/extras'
import { mergeFees } from 'checkout/lib/utils/payments/fees'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getFlightItems, getFlightItemsView } from 'checkout/selectors/view/flights'
import { VIRGIN_AUSTRALIA } from 'constants/flight'
import config from 'constants/config'

export const getFlightTotals = createSelector(
  getFlightItemsView,
  (flightItemsWithStatus): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = flightItemsWithStatus.data.filter(excludeNullOrUndefined)

    const itemTotals: App.Checkout.ItemTotals = {
      price: sum(definedItems, itemView => itemView.price || itemView.quotedFare || 0),
      memberPrice: 0,
      value: sum(definedItems, itemView => itemView.value || 0),
      surcharge: 0,
      taxesAndFees: 0,
      otherFees: {
        ...mergeFees(definedItems.map(itemView => itemView.otherFees).filter(excludeNullOrUndefined)),
        flightBaggageTotal: sum(definedItems, itemView => calculateTotalJourneyBaggageCost(itemView)),
      },
    }

    if (config.businessTraveller.currentAccountMode === 'business') {
      itemTotals.businessTravellerCredits = sum(definedItems, itemView => itemView.businessTravellerCredits || 0)
    }

    return {
      hasRequiredData: flightItemsWithStatus.hasRequiredData,
      data: itemTotals,
    }
  },
)

/**
 * Total cost of all flights provided by Virgin Australia
 */
export const getVirginFlightTotals = createSelector(
  getFlightItemsView,
  (flightItemsViewWithStatus): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = flightItemsViewWithStatus.data
      .filter(excludeNullOrUndefined)
      .filter(view => view.validatingCarrierName === VIRGIN_AUSTRALIA)

    return {
      hasRequiredData: flightItemsViewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => itemView.price || itemView.quotedFare || 0),
        memberPrice: 0,
        value: sum(definedItems, itemView => itemView.value || 0),
        surcharge: 0,
        taxesAndFees: 0,
        otherFees: {
          ...mergeFees(definedItems.map(itemView => itemView.otherFees).filter(excludeNullOrUndefined)),
          flightBaggageTotal: sum(definedItems, itemView => calculateTotalJourneyBaggageCost(itemView)),
        },
      },
    }
  },
)

export const getCartFlightJourneys = createSelector(
  getFlightItems,
  (state: App.State) => state.flights.journeysById,
  (flightItems, journeys): Array<App.Journey> => {
    // @ts-ignore
    return flightItems.map(item => journeys[item.searchId] as App.Journey).filter(Boolean)
  },
)

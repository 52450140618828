import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Tile = styled(Pane)`
  display: grid;
  grid-template-rows: ${rem(230)} auto;

  ${mediaQueryUp.tablet} {
    grid-template-columns: ${rem(313)} 1fr;
    grid-template-rows: ${rem(260)};
  }

  ${mediaQueryUp.desktop} {
    grid-template-columns: ${rem(400)} 1fr;
  }
`

const BottomContent = styled.div`
  align-self: flex-end;
`

const Content = styled.div`
  padding: ${rem(16)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${rem(24)};

  ${mediaQueryUp.tablet} {
    max-height: ${rem(262)};
    padding: ${rem(24)} ${rem(24)} ${rem(16)};
  }
`

function BedbankOfferTileLoadingSkeleton() {
  return (<Tile type="clean">
    <LoadingBox />
    <Content>
      <VerticalSpacer gap={8}>
        <VerticalSpacer gap={4}>
          <TextLoadingBox typography="body-medium" width={140} />
          <TextLoadingBox typography="heading5" width={240} />
        </VerticalSpacer>
        <TextLoadingBox typography="body-small" lines={2} width="100%" />
      </VerticalSpacer>
      <BottomContent>
        <LoadingBox style={{ height: rem(36), borderRadius: 4, width: rem(140) }} />
      </BottomContent>
    </Content>
  </Tile>)
}

export default React.memo(BedbankOfferTileLoadingSkeleton)

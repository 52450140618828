import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineRefundIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M5.12 4.77A10 10 0 0 1 12 2 10 10 0 1 1 2 12a1 1 0 1 1 2 0 8.0002 8.0002 0 0 0 11.8122 7.0643A8 8 0 1 0 6.22 6.5h2.4a1 1 0 0 1 0 2h-4.5a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v1.77Zm6.3951 3.7054c0-.3716.3089-.6756.6999-.6756.391 0 .6999.304.6999.6756v.4144c.5485.1682 1.0219.514 1.3538.9675l.0065.0089c.0913.1404.1544.3503.0937.5369-.047.1447-.1422.294-.2916.396-.1397.0955-.3055.1566-.5195.1018-.1839-.0472-.3357-.1462-.4379-.3021-.2157-.3146-.5482-.4966-.9049-.4966-.2709 0-.5391.0741-.734.2173-.1882.1383-.3156.345-.3156.6437 0 .1907.0757.3544.2551.4978.1875.1499.4911.2785.9403.367l.0074.0014c.9593.2269 1.5301.6024 1.8685 1.0257.3379.4225.4246.869.4621 1.1951l.0013.0113v.0115c0 1.0251-.7457 1.8847-1.7495 2.1163v.3349c0 .3716-.3089.6756-.6999.6756-.391 0-.6999-.304-.6999-.6756v-.4507c-.6967-.2094-1.2519-.6767-1.5527-1.3317l-.0048-.0105-.0036-.0111c-.0488-.15-.0488-.3396 0-.4896.0292-.0897.0837-.1703.1452-.2333.0594-.0609.1357-.116.2219-.1472.3649-.1551.7518.0604.8954.3547l.0042.0085.0033.0089c.1467.3909.5315.6386.9553.6386.2709 0 .5391-.0741.734-.2173.1882-.1382.3156-.345.3156-.6437 0-.1885-.0737-.3428-.2531-.4824-.1863-.1452-.4855-.2723-.9271-.3786h-.0341l-.0213-.0047c-.942-.2082-1.5062-.5745-1.8325-.9912-.325-.4149-.3946-.8551-.3952-1.1759-.0368-1.033.7156-1.89 1.7138-2.1537v-.3379Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineRefundIcon

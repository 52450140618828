// @ts-nocheck Code is from stack overflowy, so library-like code

const arr = x => Array.from(x)
const num = x => Number(x) || 0
const isEmpty = xs => xs.length === 0
const take = n => xs => xs.slice(0, n)
const drop = n => xs => xs.slice(n)
const reverse = xs => xs.slice(0).reverse()
const comp = f => g => x => f(g(x))
const not = x => !x
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))]

const a = [
  '', 'one', 'two', 'three', 'four',
  'five', 'six', 'seven', 'eight', 'nine',
  'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
  'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen',
]

const b = [
  '', '', 'twenty', 'thirty', 'forty',
  'fifty', 'sixty', 'seventy', 'eighty', 'ninety',
]

const g = [
  '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
  'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion',
]

const thousand = (group, i) => group === '' ? group : `${group} ${g[i]}`
// this part is really nasty still
// it might edit this again later to show how Monoids could fix this up
const makeGroup = ([ones, tens, huns]) => [
  num(huns) === 0 ? '' : a[huns] + ' hundred ',
  num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
  a[tens + ones] || a[ones],
].join('')

export function numToWords(n: number | string) {
  if (typeof n === 'number') { return numToWords(String(n)) }
  else if (n === '0') { return 'zero' }

  else {
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(' ')
  }
}

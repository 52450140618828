type AccessModalActionType = 'sign_up_appear' | 'sign_up_details' | 'sign_in_password_or_otp' | 'sign_in_password' | 'sign_in_otp' | 'sign_in_existing_account_found' | undefined

const modeToActionMapping = new Map<App.UiAccountModalMode | undefined, AccessModalActionType>([
  ['login', 'sign_up_appear'],
  ['join', 'sign_up_details'],
  ['loginPassword', 'sign_in_password'],
  ['loginPasswordOTP', 'sign_in_password_or_otp'],
  ['loginOTP', 'sign_in_otp'],
  ['joinExistingAccount', 'sign_in_existing_account_found'],
])

export function getAccessModalAction(
  accountAccessMode: App.UiAccountModalMode | undefined,
): AccessModalActionType {
  return modeToActionMapping.get(accountAccessMode)
}

import {
  FETCH_HOTEL_POPULAR_HOLIDAY_TYPES,
  FETCH_PLACE,
  FETCH_PLACE_ANCESTORS,
  FETCH_PLACE_BY_COORDS,
  FETCH_PLACE_BY_NAME,
  FETCH_POPULAR_DESTINATIONS,
  FETCH_TOURS_POPULAR_DESTINATIONS,
  FETCH_TRENDING_DESTINATIONS,
  FETCH_MAX_SAVINGS,
  FETCH_POPULAR_FILTERS,
  FETCH_SPECIFC_PLACES,
  FETCH_DESTINATION_IMAGE,
} from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS } from 'actions/actionConstants'
import { arrayToObject } from 'lib/array/arrayUtils'
import { ApiAction } from 'middlewares/apiMiddleware'

const initialState: App.DestinationState = {
  featuredDestinations: [],
  fetchingFeaturedDestinations: false,
  popularFilters: {
    locations: [],
    holidayTypes: [],
    amenities: [],
    inclusions: [],
    orderBy: {},
  },
  fetchingPopularFilters: false,
  areFeaturedDestinationsLoaded: false,
  locationToPlace: {},
  coordsToPlace: {},
  placesLoading: {},
  places: {},
  placeAncestors: {},
  fetchingAncestors: {},
  popularDestinations: {
    domestic: { fetching: false },
    international: { fetching: false },
  },
  trendingDestinations: [],
  fetchingTrendingDestinations: false,
  fetchingHotelPopularHolidayTypes: false,
  hotelPopularHolidayTypes: [],
  fetchingMaxSavings: false,
  maxSavings: {
    cruise: {},
    homesAndVillas: {},
    hotel: {},
    tour: {},
  },
  specificPlaces: {},
  fetchingSpecificPlaces: {},
  fetchingDestinationImage: {},
}

const apiRequests = reducerSwitch<App.DestinationState>({
  [FETCH_POPULAR_FILTERS]: () => ({
    fetchingPopularFilters: true,
  }),
  [FETCH_PLACE_BY_NAME]: (state, action) => ({
    placesLoading: {
      ...state.placesLoading,
      [action.location]: true,
    },
  }),
  [FETCH_PLACE]: (state, action) => ({
    placesLoading: {
      ...state.placesLoading,
      [action.id]: true,
    },
  }),
  [FETCH_PLACE_BY_COORDS]: (state, action) => ({
    placesLoading: {
      ...state.placesLoading,
      [action.key]: true,
    },
  }),
  [FETCH_POPULAR_DESTINATIONS]: (state, action) => ({
    popularDestinations: {
      ...state.popularDestinations,
      [action.destinationsType]: { fetching: true },
    },
  }),
  [FETCH_TRENDING_DESTINATIONS]: () => ({
    fetchingTrendingDestinations: true,
  }),
  [FETCH_MAX_SAVINGS]: () => ({
    fetchingMaxSavings: true,
  }),
  [FETCH_HOTEL_POPULAR_HOLIDAY_TYPES]: () => ({
    fetchingHotelPopularHolidayTypes: true,
  }),
  [FETCH_TOURS_POPULAR_DESTINATIONS]: (state) => ({
    tours: {
      popularDestinations: state.tours?.popularDestinations ?? [],
      isFetchingPopularDestinations: true,
    },
  }),
  [FETCH_PLACE_ANCESTORS]: (state, action: ApiAction<Array<App.Place>>) => ({
    fetchingAncestors: {
      ...state.fetchingAncestors,
      [action.id]: true,
    },
  }),
  [FETCH_SPECIFC_PLACES]: (state, action) => ({
    fetchingSpecificPlaces: {
      ...state.fetchingSpecificPlaces,
      [action.placeId]: true,
    },
  }),
  [FETCH_DESTINATION_IMAGE]: (state, action) => ({
    fetchingDestinationImage: {
      ...state.fetchingDestinationImage,
      [action.placeId]: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.DestinationState>({
  [FETCH_POPULAR_FILTERS]: (state, action) => ({
    popularFilters: action.data,
    fetchingPopularFilters: false,
  }),
  [FETCH_PLACE_BY_NAME]: (state, action) => ({
    locationToPlace: {
      ...state.locationToPlace,
      [action.location]: action.data,
    },
    placesLoading: {
      ...state.placesLoading,
      [action.location]: false,
    },
  }),
  [FETCH_PLACE_BY_COORDS]: (state, action) => ({
    coordsToPlace: {
      ...state.coordsToPlace,
      [action.key]: action.data,
    },
    placesLoading: {
      ...state.placesLoading,
      [action.key]: false,
    },
  }),
  [FETCH_PLACE]: (state, action) => ({
    places: {
      ...state.places,
      [action.id]: action.data,
    },
    placesLoading: {
      ...state.placesLoading,
      [action.id]: false,
    },
  }),
  [FETCH_PLACE_ANCESTORS]: (state, action: ApiAction<Array<App.Place>>) => ({
    placeAncestors: {
      ...state.placeAncestors,
      [action.id]: action.data.map(place => place.id),
    },
    fetchingAncestors: {
      ...state.fetchingAncestors,
      [action.id]: false,
    },
    places: {
      ...state.places,
      ...arrayToObject(action.data, place => place.id),
    },
  }),
  [FETCH_POPULAR_DESTINATIONS]: (state, action) => ({
    popularDestinations: {
      ...state.popularDestinations,
      [action.destinationsType]: { destinations: action.data, fetching: false },
    },
  }),
  [FETCH_TRENDING_DESTINATIONS]: (state, action) => ({
    trendingDestinations: action.data,
    fetchingTrendingDestinations: false,
  }),
  [FETCH_MAX_SAVINGS]: (state, action) => ({
    fetchingMaxSavings: false,
    maxSavings: {
      ...state.maxSavings,
      [action.filter]: {
        ...state.maxSavings[action.filter],
        ...arrayToObject(action.data,
          (val: { placeId: string, saveUpTo: number}) => val.placeId,
          (val: { placeId: string, saveUpTo: number}) => val.saveUpTo,
        ),
      },
    },
  }),
  [FETCH_HOTEL_POPULAR_HOLIDAY_TYPES]: (state, action) => ({
    hotelPopularHolidayTypes: action.data.map(d => d.name),
    fetchingHotelPopularHolidayTypes: false,
  }),
  [FETCH_TOURS_POPULAR_DESTINATIONS]: (state, action) => ({
    tours: {
      isFetchingPopularDestinations: false,
      popularDestinations: action.data,
    },
  }),
  [FETCH_SPECIFC_PLACES]: (state, action) => ({
    fetchingSpecificPlaces: {
      ...state.fetchingSpecificPlaces,
      [action.placeId]: false,
    },
    specificPlaces: {
      ...state.specificPlaces,
      [action.placeId]: action.data,
    },
  }),
  [FETCH_DESTINATION_IMAGE]: (state, action) => ({
    fetchingDestinationImage: {
      ...state.fetchingDestinationImage,
      [action.placeId]: false,
    },
    places: {
      ...state.places,
      [action.placeId]: {
        ...state.places[action.placeId],
        imageId: action.data,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.DestinationState>({
  [FETCH_POPULAR_FILTERS]: () => ({
    fetchingPopularFilters: false,
  }),
  [FETCH_PLACE]: (state, action) => ({
    places: {
      ...state.places,
      [action.place]: null,
    },
    placesLoading: {
      ...state.placesLoading,
      [action.place]: false,
    },
  }),
  [FETCH_PLACE_BY_NAME]: (state, action) => ({
    locationToPlace: {
      ...state.locationToPlace,
      [action.location]: null,
    },
    placesLoading: {
      ...state.placesLoading,
      [action.location]: false,
    },
  }),
  [FETCH_PLACE_BY_COORDS]: (state, action) => ({
    locationToPlace: {
      ...state.locationToPlace,
      [action.key]: null,
    },
    placesLoading: {
      ...state.placesLoading,
      [action.key]: false,
    },
  }),
  [FETCH_POPULAR_DESTINATIONS]: (state, action) => ({
    popularDestinations: {
      ...state.popularDestinations,
      [action.destinationsType]: { destinations: [], fetching: false },
    },
  }),
  [FETCH_TRENDING_DESTINATIONS]: () => ({
    fetchingTrendingDestinations: false,
  }),
  [FETCH_MAX_SAVINGS]: () => ({
    fetchingMaxSavings: false,
  }),
  [FETCH_HOTEL_POPULAR_HOLIDAY_TYPES]: () => ({
    fetchingHotelPopularHolidayTypes: false,
  }),
  [FETCH_TOURS_POPULAR_DESTINATIONS]: () => ({
    tours: {
      popularDestinations: [],
      isFetchingPopularDestinations: false,
    },
  }),
  [FETCH_PLACE_ANCESTORS]: (state, action: ApiAction<Array<App.Place>>) => ({
    fetchingAncestors: {
      ...state.fetchingAncestors,
      [action.id]: false,
    },
  }),
  [FETCH_SPECIFC_PLACES]: (state, action) => ({
    fetchingSpecificPlaces: {
      ...state.fetchingSpecificPlaces,
      [action.placeId]: false,
    },
    specificPlaces: {
      ...state.specificPlaces,
      [action.placeId]: [],
    },
  }),
  [FETCH_DESTINATION_IMAGE]: (state, action) => ({
    fetchingDestinationImage: {
      ...state.fetchingDestinationImage,
      [action.placeId]: false,
    },
    places: {
      ...state.places,
      [action.placeId]: {
        ...state.places[action.placeId],
        imageId: undefined,
      },
    },
  }),
})

const destinationReducer = createReducer<App.DestinationState>(initialState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

export default destinationReducer

import FormatCurrency from 'components/Common/FormatCurrency'
import SecureWithDepositTagAndTooltip from 'components/Common/SecureWithDeposit/SecureWithDepositTagAndTooltip'
import CruisesDiscountTsAndCsModal from 'components/Cruises/CruisesDiscountTsAndCsModal'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import Pill from 'components/Luxkit/Pill'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import ModalContext from 'contexts/ModalContext'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import { useQsQueryParams } from 'hooks/useQueryParams'
import React, { useCallback, useContext } from 'react'

interface Props {
  view: CruiseOfferView
}

function CruiseSearchTilePricing({ view }: Props) {
  const {
    disableDeposits,
    promotionDetails,
    cabinCategory,
    lowestPrice,
    hasOnlySuitesAvailable,
    discountPills,
  } = view
  const { cabinTypes } = useQsQueryParams<{ cabinTypes?: string }>()

  const showModal = useContext(ModalContext)

  const openDiscountTermsAndConditionsModal = useCallback(() => {
    showModal(<CruisesDiscountTsAndCsModal />)
  }, [showModal])

  return <div>
    {!disableDeposits && <CSSBreakpoint min="desktop">
      {promotionDetails?.deposit && (
        <Pill
          kind="tertiary"
          variant="primary"
          startIcon={<LineClockDollarIcon />}
        >
          Secure with a {promotionDetails.deposit.formattedValue} deposit
        </Pill>
      )}
      {!promotionDetails?.deposit && <SecureWithDepositTagAndTooltip offerType="cruise" />}
    </CSSBreakpoint>}
    {(cabinTypes || hasOnlySuitesAvailable) && <>
      {cabinCategory && <PriceRowPriceCaption>{cabinCategory} from</PriceRowPriceCaption>}
      {lowestPrice && <PriceRowPrice
        size="L"
        price={lowestPrice.primaryPrice.total}
        saleUnit={lowestPrice.primaryPrice.type}
      />}
      {lowestPrice?.secondaryPrice && <PriceRowCaption>
        <FormatCurrency value={lowestPrice.secondaryPrice.total} /> /{lowestPrice.secondaryPrice.type} · Twin Share
      </PriceRowCaption>}
      {!lowestPrice?.secondaryPrice && discountPills?.price && discountPills.discountPercentage && <PriceRowValueDiscountWithCaption
          size="M"
          originalValue={discountPills.price}
          discountPercentage={discountPills.discountPercentage}
          onInfoIconClick={openDiscountTermsAndConditionsModal}
        />}
      {!lowestPrice?.secondaryPrice && <PriceRowCaption>Twin Share</PriceRowCaption>}
      <PriceRowTaxesAndFees />
    </>}
  </div>
}

export default CruiseSearchTilePricing

export function finaliseInsuranceItems(
  insuranceItems: Array<App.Checkout.InsuranceTravellerFormSchema>,
  flightItems: Array<App.Checkout.FlightSchemaRequestItem>,
) {
  const thereAreInsuranceAndFlightItems = flightItems.length && insuranceItems.length

  if (!thereAreInsuranceAndFlightItems) {
    return insuranceItems
  }

  const { isFlightsEqualTravellers } = flightItems.reduce<{total: number | null; isFlightsEqualTravellers: boolean}>((acc, curr) => {
    const { adults: currentAdults, children: currentChildren, infants: currentInfants } = countTravellers(curr.occupants)
    const currentTotal = currentAdults + currentChildren + currentInfants

    if (acc.total === null) {
      return {
        total: currentTotal,
        isFlightsEqualTravellers: true,
      }
    }

    if (!acc.isFlightsEqualTravellers) {
      return {
        total: currentTotal,
        isFlightsEqualTravellers: false,
      }
    }

    return {
      total: currentTotal,
      isFlightsEqualTravellers: currentTotal === acc.total,
    }
  }, { total: null, isFlightsEqualTravellers: true })

  if (insuranceItems[0].travellers.length === flightItems[0].occupants.length && isFlightsEqualTravellers) {
    return insuranceItems.map(item => ({ ...item, travellers: flightItems[0].occupants }))
  }

  const flightsTravellers = getFlightsTravellers(flightItems)

  return insuranceItems.map(item => {
    const { travellers: insuranceTravellers } = item

    const { adults: insuranceAdults, children: insuranceChildren, infants: insuranceInfants } = countTravellers(insuranceTravellers)
    const { adults: flightAdults, children: flightChildren, infants: flightInfants } = countTravellers(flightsTravellers)

    const finalChildrenAndInfants = calculateFinalChildrenAndInfants(insuranceChildren, insuranceInfants, flightInfants, flightChildren)

    const finalAdultsTravellers = new Array(insuranceAdults > flightAdults ? insuranceAdults : flightAdults).fill({ type: 'adult' })
    const finalChildrenTravellers = new Array(finalChildrenAndInfants.children).fill({ type: 'child' })
    const finalInfantsTravellers = new Array(finalChildrenAndInfants.infants).fill({ type: 'infant' })

    return {
      ...item,
      travellers: [...finalAdultsTravellers, ...finalChildrenTravellers, ...finalInfantsTravellers],
    }
  })
}

function getFlightsTravellers(flightItems: Array<App.Checkout.FlightSchemaRequestItem>) {
  return flightItems.map(item => item.occupants).reduce((acc, curr) => {
    const { adults: accAdults, children: accChildren, infants: accInfants } = countTravellers(acc)
    const { adults: currAdults, children: currChildren, infants: currInfants } = countTravellers(curr)

    const adults = accAdults > currAdults ? accAdults : currAdults
    const children = accChildren > currChildren ? accChildren : currChildren
    const infants = accInfants > currInfants ? accInfants : currInfants

    return [
      ...new Array(adults).fill({ type: 'adult' }),
      ...new Array(children).fill({ type: 'child' }),
      ...new Array(infants).fill({ type: 'infant' }),
    ]
  }, flightItems[0].occupants)
}

function countTravellers(travellers: Array<App.Checkout.TravellerFormSchemaOccupant>) {
  const adults = travellers.filter(traveller => traveller.type === 'adult').length
  const children = travellers.filter(traveller => traveller.type === 'child').length
  const infants = travellers.filter(traveller => traveller.type === 'infant').length

  return {
    adults,
    children,
    infants,
  }
}

function calculateFinalChildrenAndInfants(
  insuranceChildren: number,
  insuranceInfants: number,
  flightInfants: number,
  flightChildren: number,
) {
  const totalInsurance = insuranceChildren + insuranceInfants
  const totalFlight = flightInfants + flightChildren

  if (totalInsurance === totalFlight) {
    return {
      children: flightChildren,
      infants: flightInfants,
    }
  }

  if (insuranceInfants > 0 && flightChildren > 0) {
    return {
      children: flightChildren,
      infants: insuranceInfants > flightChildren ? insuranceInfants - flightChildren : flightInfants,
    }
  }

  if (insuranceChildren > 0 && flightInfants > 0) {
    return {
      children: insuranceChildren > flightInfants ? insuranceChildren - flightInfants : flightChildren,
      infants: flightInfants,
    }
  }

  return {
    children: flightChildren || insuranceChildren,
    infants: flightInfants || insuranceInfants,
  }
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineArrowsSwapHorizontalIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 20 20">
    <path fill="currentColor" d="M10.3744 5.637H5.5666l.838-.8319a1.0603 1.0603 0 0 0 .308-.7477c0-.2804-.1108-.5494-.308-.7477A1.049 1.049 0 0 0 5.6608 3c-.279 0-.5465.1114-.7437.3097L2.2985 5.9425a1.054 1.054 0 0 0-.22.3475 1.0584 1.0584 0 0 0 0 .8004 1.054 1.054 0 0 0 .22.3475l2.6187 2.6328a1.0438 1.0438 0 0 0 .7437.3115 1.0438 1.0438 0 0 0 .7437-.3115 1.054 1.054 0 0 0 .2292-.3427 1.058 1.058 0 0 0-.2292-1.1527l-.838-.832h4.8078c.2778 0 .5442-.111.7406-.3084a1.0557 1.0557 0 0 0 .3068-.7447c0-.2793-.1103-.5472-.3068-.7447a1.0444 1.0444 0 0 0-.7406-.3084ZM17.7015 12.5603c.0953.1002.1701.2183.2199.3475.1048.2564.1048.544 0 .8004a1.0528 1.0528 0 0 1-.2199.3475l-2.6187 2.6328a1.0471 1.0471 0 0 1-.3408.2305 1.0424 1.0424 0 0 1-.8057 0 1.0471 1.0471 0 0 1-.3408-.2305 1.0576 1.0576 0 0 1 0-1.4954l.8379-.832H9.6256a1.0446 1.0446 0 0 1-.7407-.3084 1.0561 1.0561 0 0 1-.3067-.7447c0-.2793.1103-.5471.3067-.7446a1.0447 1.0447 0 0 1 .7407-.3085h4.8078l-.8379-.8319a1.0604 1.0604 0 0 1-.3081-.7478 1.06 1.06 0 0 1 .3081-.7477 1.0486 1.0486 0 0 1 .7436-.3097c.279 0 .5465.1114.7437.3097l2.6187 2.6328Z"/>
  </SvgIcon>
}

export default LineArrowsSwapHorizontalIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWifiIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 15a2.9998 2.9998 0 0 0-2.9423 3.5852 3.0001 3.0001 0 0 0 5.4367 1.0815 2.9998 2.9998 0 0 0-.373-3.7881A3 3 0 0 0 12 15Zm0 4a.9998.9998 0 0 1-.9238-.6174.9995.9995 0 0 1 .2167-1.0897 1.0002 1.0002 0 0 1 1.5386.1515.9998.9998 0 0 1-.1244 1.2627A1 1 0 0 1 12 19Zm0-8a7.06 7.06 0 0 0-4.95 2.05.9999.9999 0 0 0 0 1.41 1.0002 1.0002 0 0 0 1.41 0 4.9997 4.9997 0 0 1 7.08 0c.1847.1884.4363.2962.7.3a1.0007 1.0007 0 0 0 .9713-.5922A.9993.9993 0 0 0 17 13.05 7.0604 7.0604 0 0 0 12 11Zm0-4a11.0799 11.0799 0 0 0-7.78 3.22 1.0037 1.0037 0 0 0-.294.71 1.004 1.004 0 0 0 1.714.71 8.9999 8.9999 0 0 1 12.72 0 1.0011 1.0011 0 0 0 .71.29 1.0004 1.0004 0 0 0 1.0058-1 .9994.9994 0 0 0-.2958-.71A11.0796 11.0796 0 0 0 12 7Zm10.61.39a14.9999 14.9999 0 0 0-21.22 0 1.0041 1.0041 0 0 0 1.42 1.42 13 13 0 0 1 18.38 0 1.0001 1.0001 0 0 0 1.42 0 .9994.9994 0 0 0 .2189-1.0946.9978.9978 0 0 0-.2189-.3254Z"/>
  </SvgIcon>
}

export default LineWifiIcon

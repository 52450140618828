import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineStarIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22 9.67a1 1 0 0 0-.86-.67l-5.69-.83L12.9 3a1.0006 1.0006 0 0 0-.9-.564 1 1 0 0 0-.9.564L8.55 8.16 2.86 9a1 1 0 0 0-.81.68.9998.9998 0 0 0 .25 1l4.13 4-1 5.68a1.0001 1.0001 0 0 0 .4 1 1.0003 1.0003 0 0 0 1.05.07L12 18.76l5.1 2.68a.9287.9287 0 0 0 .46.12 1 1 0 0 0 .59-.19 1 1 0 0 0 .4-1l-1-5.68 4.13-4A.9995.9995 0 0 0 22 9.67Zm-6.15 4a.9997.9997 0 0 0-.29.89l.72 4.19-3.76-2a1.0005 1.0005 0 0 0-.94 0l-3.76 2 .72-4.19a1 1 0 0 0-.29-.89l-3-3 4.21-.61a.9996.9996 0 0 0 .76-.55L12 5.7l1.88 3.82a.9996.9996 0 0 0 .76.55l4.21.61-3 2.99Z"/>
  </SvgIcon>
}

export default LineStarIcon

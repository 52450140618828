// @ts-nocheck

/**
 * MemoryStorage is a drop-in replacement for localStorage for environments
 * in which localStorage is not supported (i.e. Safari private browsing sessions)
 * It implements the Web Storate API https://www.w3.org/TR/webstorage/#storage-0
 *
 * NOTE: On the server side, this will always do nothing.
 * The server should never use an inbuilt 'memory cache' as it is a shared context between many clients
 */
export default class IsomorphicMemoryStorage {
  items = {}
  _length = 0

  // noop
  set length(val: number) {}

  get length() {
    if (IS_SSR) {
      return 0
    }

    return this._length
  }

  getItem(key: string) {
    if (IS_SSR) {
      return null
    }
    return this.items[key] || null
  }

  setItem(key: string, val: any) {
    if (IS_SSR) {
      return
    }

    if (!this.items[key]) {
      this._length++
    }

    this.items[key] = val
  }

  removeItem(key: string) {
    if (IS_SSR) {
      return
    }

    if (this.items[key]) {
      this._length--
    }

    delete this.items[key]
  }

  clear() {
    if (IS_SSR) {
      return
    }

    this.items = {}
    this._length = 0
  }

  // Assume ordered hash map
  key(n: number) {
    if (IS_SSR) {
      return undefined
    }
    return Object.keys(this.items)[n]
  }
}

import { get, remove, set } from 'lib/storage/versionedLocalStorageUtils'

// Redux state
// Increment version when we change the state structure in an incompatible way.
const REDUX_SELECTED_TRAVELLER_STATE_KEY = 'BusinessTravellerSelectedTraveller'
const REDUX_SELECTED_TRAVELLER_STATE_VERSION = 2

type BusinessTravellerSelectedTravellerState = {
  employeeIds?: Array<string>
  guestCount?: number
} | undefined

export function saveBusinessTravellerSelectedTravellerState(employeeIds: Array<string>, guestCount?: number) {
  set(REDUX_SELECTED_TRAVELLER_STATE_KEY, REDUX_SELECTED_TRAVELLER_STATE_VERSION, { employeeIds, guestCount: guestCount ?? 0 })
}

export function getBusinessTravellerSelectedTravellerState(): BusinessTravellerSelectedTravellerState {
  return get(REDUX_SELECTED_TRAVELLER_STATE_KEY, REDUX_SELECTED_TRAVELLER_STATE_VERSION)
}

export function clearBusinessTravellerSelectedTraveller() {
  remove(REDUX_SELECTED_TRAVELLER_STATE_KEY)
}

import LuxLoyaltyTierIcon from 'components/Luxkit/Icons/luxLoyalty/LuxLoyaltyTierIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Plural from 'components/utils/Formatters/Plural'
import { rem } from 'polished'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: ${rem(8)};
`

interface Props {
  tier: 'silver' | 'gold' | 'platinum'
  earnablePoints: number
  size: 'S' | 'M'
}

export const SIZES_BODY_TEXT_VARIANTS: Record<Props['size'], ComponentProps<typeof BodyText>['variant']> = {
  S: 'medium',
  M: 'large',
}

function PriceRowLuxLoyaltyPoints({ tier, earnablePoints, size }: Props) {
  return <Container>
    <LuxLoyaltyTierIcon size="S" tier={tier} />
    <BodyText variant={SIZES_BODY_TEXT_VARIANTS[size]} colour="neutral-one">
      Earn <Plural singular="point" count={earnablePoints} withCount />
    </BodyText>
  </Container>
}

export default PriceRowLuxLoyaltyPoints

import { formatOccupantsShort } from 'lib/offer/occupancyUtils'

function buildPriceElements(
  prices: Array<App.CruisePassengerPricing> | Array<Cruises.BookingCabinPassengerPricing>,
  type: string,
): Array<App.Checkout.ItemBreakdownElement> {
  return prices.map((pricing: App.CruisePassengerPricing | Cruises.BookingCabinPassengerPricing, index) => {
    const { total } = pricing
    const { amount } = total
    return {
      id: `passenger-${type}-${index}`,
      left: {
        type: 'text',
        value: `1 ${type}`,
      },
      right: {
        type: 'price',
        value: amount,
      },
    } as App.Checkout.ItemBreakdownElement
  })
}

function buildCabinCategoryDetails(itemView: App.Checkout.CruiseAccommodationItemView): string {
  const [categoryInfo] = itemView?.cabinCategory?.cabinCategoryInfo || []
  const categoryDetails: Array<string> = []

  if (categoryInfo?.name) {
    categoryDetails.push(categoryInfo.name)
  }

  if (itemView.item.cabinCode) {
    categoryDetails.push(itemView.item.cabinCode)
  }

  if (itemView.item.cabinNumber) {
    categoryDetails.push(itemView.item.cabinNumber)
  }

  return categoryDetails.join(' · ')
}

export function buildCruiseSummaryBreakdown(
  itemView: App.Checkout.CruiseAccommodationItemView,
) {
  const rateCodeDescription = itemView?.cabinRate?.rateCodeDescription
  let additionalElements: Array<App.Checkout.ItemBreakdownElement> = []

  if (itemView.cabinSelection) {
    const { cabinPricingData, cabinSelection } = itemView
    const numAdults = itemView.occupancy.adults
    const numChildren = itemView.occupancy.children ?? 0
    const numInfants = itemView.occupancy.infants ?? 0

    const passengerPricing = cabinPricingData?.cabinPricing?.pricing.passengerPricing
    const pricingList = passengerPricing || cabinSelection?.pricing?.passengerPricing || []

    const adultPrices = pricingList.slice(0, numAdults)
    const childPrices = pricingList.slice(numAdults, numAdults + numChildren)
    const infantPrices = pricingList.slice(numAdults + numChildren, numAdults + numChildren + numInfants)

    additionalElements = [
      ...buildPriceElements(adultPrices, 'Adult'),
      ...buildPriceElements(childPrices, 'Child'),
      ...buildPriceElements(infantPrices, 'Infant'),
    ]
  }

  const additionalInfoText = [
    buildCabinCategoryDetails(itemView),
    rateCodeDescription ? `Rate: ${rateCodeDescription ?? ''}` : '',
    !itemView.cabinSelection ? `${formatOccupantsShort(itemView.occupancy)}` : '',
  ]

  return {
    additionalElements,
    additionalInfoText,
  }
}

import { CANCELLATION_POLICIES, CANCELLATION_POLICY_DAYS, FORTY_FIVE_DAYS_BEFORE_CHECK_IN, SEVEN_DAYS_AFTER_PURCHASE, TWENTY_ONE_DAYS_BEFORE_CHECK_IN } from 'constants/cancellationPolicies'
import { dateIsAfter, dateIsBefore, addDays } from 'lib/datetime/dateUtils'
import moment from 'moment'

const ALL_FLEXIBLE_CANCELLATION_POLICIES: Array<App.CancellationPolicyType> = [
  'partner-policy-prior-to-check-in-twenty-one-days',
  'flexible-cancellation-forty-five-days',
]

export function isConnectionCancellationPolicy(cancellationPolicy: App.OrderCancellationPolicyTypes): cancellationPolicy is App.OrderConnectionCancellationPolicy {
  return 'policyCode' in cancellationPolicy
}

export function isCancellationPolicy(cancellationPolicy: App.OrderCancellationPolicyTypes): cancellationPolicy is App.OrderCancellationPolicy {
  return 'type' in cancellationPolicy
}

export function isCreditsOnlyCancellationPolicy(cancellationPolicy: App.OrderCancellationPolicyTypes | undefined): boolean {
  return !!(
    cancellationPolicy &&
    isCancellationPolicy(cancellationPolicy) &&
    cancellationPolicy.type === 'credit-only-prior-to-check-in-fourteen-days'
  )
}

export const isFlexibleCancellationPolicy = (
  cancellationPolicy: App.OrderCancellationPolicyTypes | undefined,
) => {
  return !!cancellationPolicy && isCancellationPolicy(cancellationPolicy) &&
    !!cancellationPolicy.type &&
    ALL_FLEXIBLE_CANCELLATION_POLICIES.includes(cancellationPolicy.type)
}

export function isWithinChangeOfMindPolicy(purchaseDate: string | Date, checkIn: string | Date) {
  // Change of mind policy is 7 days after purchase AND 14 days before check in.
  // e.g. if you purchase 12 days before check in, no change of mind
  // purchase 15 days before check in, you get 1 day change of mind
  // purchase 21 days before check in, you get 7 days change of mind
  // etc
  return dateIsAfter(addDays(new Date(purchaseDate), 7), new Date()) &&
    dateIsBefore(addDays(new Date(), 14), new Date(checkIn))
}

export function getFlexibleCancellationDays(cancellationPolicyType?: App.CancellationPolicyType): number {
  return cancellationPolicyType === CANCELLATION_POLICIES.FLEXIBLE_CANCELLATION_FORTY_FIVE_DAYS ?
    FORTY_FIVE_DAYS_BEFORE_CHECK_IN :
    TWENTY_ONE_DAYS_BEFORE_CHECK_IN
}

function isFreeLpcCancellationPolicy(
  cancellationPolicy: App.OfferCancellationPolicy,
  checkIn: moment.Moment | undefined,
  propertyTimezoneOffset?: number,
): 'free' | undefined {
  const days = CANCELLATION_POLICY_DAYS[cancellationPolicy.type!]
  if (!days) return
  const startBasedOnCheckIn = checkIn ? ((propertyTimezoneOffset ? checkIn.utcOffset(propertyTimezoneOffset).subtract(days, 'days') : checkIn.subtract(days, 'days'))) : undefined
  const startBasedOnToday = propertyTimezoneOffset ? moment().utcOffset(propertyTimezoneOffset).add(SEVEN_DAYS_AFTER_PURCHASE, 'days') : moment().add(SEVEN_DAYS_AFTER_PURCHASE, 'days')
  let startDate: moment.Moment | undefined
  if (cancellationPolicy.type === 'post-purchase-seven-days') {
    if (!startBasedOnCheckIn) {
      startDate = startBasedOnToday
    } else {
      startDate = startBasedOnToday.isBefore(startBasedOnCheckIn) ? startBasedOnToday : startBasedOnCheckIn
    }
  } else {
    startDate = startBasedOnCheckIn
  }
  const refundable = (!startDate || startDate?.isAfter(moment()))
  if (refundable) return 'free'
}

export function isFreeOrFlexibleLpcCancellation(
  cancellationPolicy: App.OfferCancellationPolicy | undefined,
  checkIn: moment.Moment | undefined,
  propertyTimezoneOffset?: number,
):'flexible' | 'free' | undefined {
  if (!cancellationPolicy?.type || cancellationPolicy.type == 'non-refundable') return
  const isFlexible = isFlexibleCancellationPolicy(cancellationPolicy)
  if (isFlexible) return 'flexible'
  return isFreeLpcCancellationPolicy(cancellationPolicy, checkIn, propertyTimezoneOffset)
}

import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import moment from 'moment'
import React, { useCallback, useContext } from 'react'

import DualDateRangePicker from 'components/Common/Calendar/DualDateRangePicker'
import SearchDatesPicker from 'components/SearchV2/Components/Mobile/DateSearchFloatingPanel/SearchDatePicker'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'

interface Props {
  type: 'single' | 'dual';
  availableMonths?: Array<string>;
  onChange: (value: App.CruiseGlobalFilters) => void;
}

const today = new Date()
const max = moment(today).add(2, 'year').endOf('year').toDate()

function CruiseWhenSelectCalendar(props: Props) {
  const {
    type,
    availableMonths,
    onChange,
  } = props

  const {
    checkinDate: startDate,
    checkoutDate: endDate,
  } = useContext(GlobalSearchStateContext)

  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const onDayClick = useCallback((dates: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }) => {
    const start = dates.startDate
    const end = dates.endDate
    let checkinCheckout = {}
    if (start && end) {
      searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: startDate })
      searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: end })

      // when selecting a date range, we want to reset the flexible months
      searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: '' })
      checkinCheckout = { checkinDate: startDate, checkoutDate: end }
    } else {
      searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: start })
      searchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE })
      checkinCheckout = { checkinDate: start, checkoutDate: undefined }
    }

    const isCruiseSearch = window.location.pathname.includes('search/cruise')
    if (isCruiseSearch) {
      onChange(checkinCheckout)
    }
  }, [onChange, searchDispatch, startDate])

  return <div data-testid="cruise-when-select-calendar">
    {type === 'single' && <SearchDatesPicker
      availableMonths={availableMonths}
      onDatesChange={onDayClick}
      minDate={today}
      maxDate={max}
      startDate={startDate || null}
      endDate={endDate || null}
      type="single"
    />}

    {type === 'dual' && <DualDateRangePicker
      availableMonths={availableMonths}
      onDatesChange={onDayClick}
      minDate={today}
      maxDate={max}
      startDate={startDate || null}
      endDate={endDate || null}
    />}
  </div>
}

export default CruiseWhenSelectCalendar

import { getBundleAndSaveMaxChildAge, getBundleAndSaveMaxInfantAge } from 'lib/offer/offerUtils'
import { isShallowEqual, max, min, nonNullable } from 'lib/array/arrayUtils'
import { MAX_ADULTS } from 'constants/offer'
import { maxFlightChildAge, maxFlightInfantAge } from 'constants/flight'
import { pluralizeToString } from 'lib/string/pluralize'
import { validateFlightOccupants } from 'lib/flights/flightUtils'
import config from 'constants/config'
import uuidV4 from 'lib/string/uuidV4Utils'

interface CountOccupantsOptions {
  maxChildAge?: number;
  maxInfantAge?: number;
}

export type AdultLabel = 'adult' | 'traveller'

export const initialRooms: Array<App.RoomOccupants> = [{
  roomId: uuidV4(),
  adults: 2,
  children: 0,
  infants: 0,
  childrenAge: [],
}]

export function generateRoomOccupants({
  roomId = uuidV4(),
  adults = 2,
  children = 0,
  infants = 0,
  childrenAge = [],
}: Partial<App.RoomOccupants> = {}) : App.RoomOccupants {
  return ({
    roomId,
    adults,
    children,
    infants,
    childrenAge,
  })
}

export function occupancyMatchDefault(occupancy: Array<App.Occupants>) {
  return occupancy.length === 1 &&
    occupancy[0].adults === config.search.defaultOccupants.adults &&
    occupancy[0].children === config.search.defaultOccupants.children &&
    occupancy[0].infants === config.search.defaultOccupants.infants
}

/**
 * Sum up all occupancies given into a single aggregated occupancy item
 **/
export function countOccupants(
  occupancies: Array<App.Occupants>,
  options: CountOccupantsOptions = {},
): Required<App.Occupants> {
  return occupancies.reduce<Required<App.Occupants>>((acc, occupancy) => {
    // In some places we're using a simple count for children,
    // in other places we're using an array of child ages
    let extraAdults = 0
    const occupancyChildrenAges = occupancy.childrenAge ?? []
    let childrenAndInfantAges = occupancyChildrenAges
    let children = occupancy.children ?? childrenAndInfantAges.length ?? 0
    if (options?.maxChildAge && options.maxChildAge > 0 && occupancyChildrenAges.length > 0) {
      const filteredChildrenAges = childrenAndInfantAges.filter(age => age <= (options.maxChildAge ?? maxFlightChildAge))
      children = filteredChildrenAges.length
      extraAdults = childrenAndInfantAges.length - children
      childrenAndInfantAges = filteredChildrenAges
    }

    let infants = occupancy.infants ?? 0
    if ((options.maxInfantAge ?? maxFlightInfantAge) >= 0 && occupancyChildrenAges.length > 0) {
      children = childrenAndInfantAges.filter(age => age > (options.maxInfantAge ?? maxFlightInfantAge)).length
      infants = childrenAndInfantAges.length - children
    }

    acc.adults = acc.adults + (occupancy.adults ?? 0) + extraAdults
    acc.children = (acc.children ?? 0) + children
    acc.infants = (acc.infants ?? 0) + infants
    acc.childrenAge = acc.childrenAge?.concat(childrenAndInfantAges)
    return acc
  }, {
    adults: 0,
    children: 0,
    infants: 0,
    childrenAge: [],
    childrenBirthDate: [],
  })
}

export function sumUpOccupancies(
  occupancies: Array<App.Occupants> = [],
  target: Set<App.OccupantType> = new Set(['adults', 'children', 'infants']),
  options: CountOccupantsOptions = {},
): number {
  let total = 0

  const {
    adults,
    children,
    infants,
  } = countOccupants(occupancies, options)

  if (target.has('adults')) total += adults
  if (target.has('children')) total += (children ?? 0)
  if (target.has('infants')) total += (infants ?? 0)

  return total
}

// TODO: move the following funcs into a separate location relevant to Tours.V2OrderItemTravellerDetails space
export function countTravellers(travellers: Array<Tours.V2OfferSnapshotPassenger>) {
  const travellersCount: Record<string, number> = {}

  for (let i = 0; i < travellers.length; ++i) {
    travellersCount[travellers[i].type] = (travellersCount[travellers[i].type] || 0) + 1
  }

  return travellersCount
}

export function countTravellersAsOccupants(travellers: Array<Tours.V2OfferSnapshotPassenger | Tours.TourV2OrderItemTravellerDetails>): App.Occupants {
  return {
    adults: travellers.filter(t => t.type === 'adult').length,
    children: travellers.filter(t => t.type === 'child').length,
    infants: travellers.filter(t => t.type === 'infant').length,
    childrenAge: [],
  }
}

export function formatTourTravellers(travellers: Array<Tours.V2OfferSnapshotPassenger>) {
  const travellersCount = countTravellers(travellers)
  const travellerTypes = Object.keys(travellersCount)
  const travellerTypesStr = travellerTypes.map(type => pluralizeToString(type, travellersCount[type]))
  return travellerTypesStr.join(', ')
}

export function countOccupantsForProperty(occupancies: Array<App.Occupants>, property?: App.OfferProperty) {
  return countOccupants(occupancies, { maxChildAge: property?.maxChildAge, maxInfantAge: property?.maxInfantAge })
}

export function countOccupantsForBundleAndSave(occupancies: Array<App.Occupants>, offer: App.BundleOffer) {
  const maxChildAge = getBundleAndSaveMaxChildAge(offer)
  const maxInfantAge = getBundleAndSaveMaxInfantAge(offer)
  return countOccupants(occupancies, { maxChildAge, maxInfantAge })
}
// END OF TODO

/**
 * Consolidates all occupancies into a single record and transforms childrens ages based on
 * the known flight infant/child ages
 * @param occupancies Set of occupancies to count
 * @returns Consolidated occupancy with all sumed up
 */
export function countOccupantsForFlights(occupancies: Array<App.Occupants>) {
  return countOccupants(occupancies, { maxInfantAge: maxFlightInfantAge, maxChildAge: maxFlightChildAge })
}

export function getMaxOccupancies(
  capacities: Array<App.RoomCapacity> = [{ adults: 14, children: 13, infants: 13 }],
  existingOccupants: App.Occupants,
  options: CountOccupantsOptions = {},
) {
  const totalOccupants = countOccupants([existingOccupants], options)
  const compatibleCapacities = capacities.filter(
    capacity => (
      totalOccupants.adults <= capacity.adults &&
      (totalOccupants.children ?? 0) <= capacity.children &&
      // infants can be counted as children, but children cannot be counted as infants
      // so infants can take the occupant of the children one
      (totalOccupants.infants ?? 0) + (totalOccupants.children ?? 0) <= capacity.infants + capacity.children
    ),
  )
  if (!compatibleCapacities.length) {
    return {
      adults: totalOccupants.adults,
      children: totalOccupants.children,
      infants: totalOccupants.infants,
    }
  }

  const maxOccupancy = max(compatibleCapacities, c => c.total ?? (c.adults + c.children + c.infants)) || compatibleCapacities[0]
  const occupancyLimit = min([MAX_ADULTS, maxOccupancy.total ?? maxOccupancy.adults + maxOccupancy.children + maxOccupancy.infants]) ?? MAX_ADULTS
  const totalAdultsChildrenAndInfants = totalOccupants.adults + totalOccupants.children + totalOccupants.infants

  if (totalAdultsChildrenAndInfants < occupancyLimit) {
    return {
      adults: max(compatibleCapacities, c => c.adults)?.adults ?? MAX_ADULTS,
      children: max(compatibleCapacities, c => c.children)?.children ?? 0,
      infants: max(compatibleCapacities, c => c.infants)?.infants ?? 0,
    }
  }

  return {
    adults: totalOccupants.adults,
    children: totalOccupants.children,
    infants: totalOccupants.infants,
  }
}

/**
 * Returns the largest guest count from the list of capacities
 * @param capacities The capacities to check through
 */
export function getMaxGuestCount(capacities: Array<App.RoomCapacity> = []) {
  if (!capacities?.length) return 0
  const maxGuest = max(capacities, cap => cap.adults + cap.children + cap.infants)
  return maxGuest ? maxGuest.adults + maxGuest.children + maxGuest.infants : 0
}

/**
 * Returns the min and max age limits for ChildAgeSelect following the max room capacity
 * @param capacities Set of available capacities for the room
 * @param existingOccupants Current occupants objets for the room
 * @param childIndex Selector position inside existingOccupants.childrenAge
 * @param options Object that contains maxChildAge and maxInfantAge
 * @returns a object with the max and min age limits for age selector
 */
export function getChildrenAgesLimits(
  capacities: Array<App.RoomCapacity> = [{ adults: MAX_ADULTS, children: 13, infants: 13 }],
  existingOccupants: App.Occupants,
  childIndex: number,
  maxInfantAge: number,
  maxChildAge: number,
) {
  // AgeSelect from GlobalSearch doesn't use maxInfantAge or capacities
  // So we just return the max as maxChildAge
  if (maxInfantAge === -1 || !capacities.length) return { max: maxChildAge, min: 0 }

  const totalOccupants = countOccupants([existingOccupants], { maxInfantAge, maxChildAge })
  const compatibleCapacities = capacities.filter(capacity => (
    totalOccupants.adults <= capacity.adults
  ))
  if (!compatibleCapacities.length) {
    return { max: maxChildAge, min: 0 }
  }

  const maxChildCapacities = {
    children: max(compatibleCapacities, c => c.children)?.children ?? 0,
    infants: max(compatibleCapacities, c => c.infants)?.infants ?? 0,
  }

  const selectedAge = totalOccupants.childrenAge[childIndex]
  const unselectedAges = totalOccupants.childrenAge.filter(age => age === -1).length
  const isChildrenSelected = selectedAge > maxInfantAge
  const isInfantSelected = selectedAge <= maxInfantAge && selectedAge !== -1

  // If a children is already selected, the max limit needs to be maxChildAge if user
  // wants to change the age. The same happens with the min limit being 0 if a infant
  // is already selected. Otherwise, the limits will works with the available capacity.
  // If no longer have capacity for select Children, max limit will be maxInfantAge and just
  // infants can be selected. In the same way, if no longer have capacity for select infants
  // the min limit will be (maxInfantAge + 1) for just allow select children.
  const maxAgeLimit = isChildrenSelected || totalOccupants.children < maxChildCapacities.children ? maxChildAge : maxInfantAge
  const minAgeLimit = isInfantSelected || (totalOccupants.infants - unselectedAges) < maxChildCapacities.infants ? 0 : maxInfantAge + 1

  return { max: maxAgeLimit, min: minAgeLimit }
}

/**
 * Aggregates and formats occupancies into a format that describes
 * the totals of each type of guest.
 *
 * This function only works for English language. All usages should
 * eventually be converted to use the translated version.
 *
 * @param occupants The occupancies to format
 * @returns Formatted string of occupants
 */
export function formatOccupantsShort(occupants?: Array<App.Occupants> | App.Occupants, options?: CountOccupantsOptions, adultLabel: AdultLabel = 'adult') {
  const filteredOccupants = nonNullable(Array.isArray(occupants) ? occupants : [occupants])
  const { adults, children, infants } = countOccupants(filteredOccupants, options)

  if (!occupants || (adults === 0 && children === 0 && infants === 0)) {
    return ''
  }

  return [
    pluralizeToString(adultLabel, adults),
    (children ?? 0) > 0 ? pluralizeToString('child', (children ?? 0)) : '',
    (infants ?? 0) > 0 ? pluralizeToString('infant', (infants ?? 0)) : '',
  ].filter(Boolean).join(', ')
}

/**
 * Formats the occupancys based on the age requirements of a given property
 * @param occupants The occupancies to format
 * @param property The property to get max age data for
 * @returns
 */
export function formatOccupantsForProperty(occupants: Array<App.Occupants> | App.Occupants, property?: CountOccupantsOptions) {
  return formatOccupantsShort(occupants, property ? { maxChildAge: property.maxChildAge, maxInfantAge: property.maxInfantAge } : undefined)
}

/**
 * Aggregates and formats occupancies into a simplified format of
 * adults if only adults, or total number of guests when children/infants
 * @param occupants The occupancies to format
 * @returns Formatted string of occupants
 */
export function formatOccupantsAsGuests(...occupants: Array<App.Occupants>) {
  const { adults, children, infants } = countOccupants(occupants)

  if (children || infants) {
    return pluralizeToString('guest', adults + (children ?? 0) + (infants ?? 0))
  } else {
    return pluralizeToString('adult', adults)
  }
}

export function formatOccupantsForSelectionBox(isLeTour:boolean = false, ...occupants: Array<App.Occupants>) {
  const { adults, children, infants } = countOccupants(occupants)

  if (children || infants) {
    return pluralizeToString('guest', adults + (children ?? 0) + (infants ?? 0))
  } else {
    return pluralizeToString(isLeTour ? 'traveller' : 'adult', adults)
  }
}

/**
 * Tour V1 occupancy must be one adult per "room"
 */
export function isValidTourV1Occupancy(occupants: Array<App.Occupants>) {
  return occupants.every(occ => (
    occ.adults === 1 && (occ.children ?? 0) === 0 && (occ.infants ?? 0) === 0
  ))
}

export function isValidAccommodationSearchOccupancy(occupants: Array<App.Occupants>, maxAge: number) {
  return occupants.every(occ => occ.childrenAge?.every(age => (age >= 0) && (age <= maxAge)))
}

export function isValidFlightsOccupants(occupants: App.Occupants) {
  const areChildAgesValid = occupants.childrenAge?.every(i => (i >= 0) && (i <= maxFlightChildAge))

  return !validateFlightOccupants(occupants) && areChildAgesValid
}

export function areOccupantsEqual(
  ...occupants: Array<App.Occupants>
): boolean {
  const first = occupants[0]
  return !!first && occupants.every(occ => (
    occ.adults === first.adults &&
    occ.children === first.children &&
    occ.infants === first.infants &&
    isShallowEqual(occ.childrenAge ?? [], first.childrenAge ?? [])
  ))
}

function mergeOccupantsReducer(a: App.Occupants, b: App.Occupants): App.Occupants {
  return {
    adults: a.adults + b.adults,
    children: (a?.children ?? 0) + (b?.children ?? 0),
    infants: (a?.infants ?? 0) + (b?.infants ?? 0),
    childrenAge: [...(a?.childrenAge ?? []), ...(b?.childrenAge ?? [])],
  }
}

export function mergeOccupants(occupants_list: Array<App.Occupants>): App.Occupants {
  const [first, ...rest] = occupants_list
  return rest.reduce(mergeOccupantsReducer, first)
}

export function getOccupanciesFromReservations(...reservations: Array<App.OrderReservation>): App.Occupants {
  return reservations.reduce((acc, reservation) => {
    return {
      adults: acc.adults + reservation.adults,
      children: acc.children + (reservation.children || 0),
      infants: acc.infants + (reservation.infants || 0),
    }
  }, {
    adults: 0,
    children: 0,
    infants: 0,
  })
}

/**
 * Ensures that every occupants object passed is 'valid'
 * We consider it an invalid object when there is a child who has not had their age set yet
 */
export function isValidOccupancy(...occupants: Array<App.Occupants>) {
  return occupants.every(occupancy => occupancy.childrenAge?.every(age => age !== -1) ?? true)
}

import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getAirportsSearch } from 'api/flights'
import { last, sum } from 'lib/array/arrayUtils'
import { MappedPlace, mapCityLevelPlace } from 'lib/search/mappingUtils'
import { getPlaceByLatLong } from 'api/search'
import { SelfDescribingJson } from '@snowplow/browser-tracker'
import { createFlightItemContext } from 'analytics/snowplow/events'
import moment from 'moment'
import { FlightsFareTypes } from 'constants/flight'

async function getFlightCheckoutItems(state: App.State, item: App.Checkout.FlightItem) {
  const searchFares = Object.values(state.flights.searchV2Flights)?.find(flights => flights?.searchId === item.searchId)?.fares
  if (!searchFares) return []

  const journeys = item.flights.map((flight, index) => searchFares[index]?.find(fare => fare.id === flight.journeyId))

  const existingJourneys = journeys.filter(journey => journey?.id)
  if (existingJourneys.length !== item.flights.length || journeys.length === 0) return []

  const firstJourney = journeys[0]?.flightGroup
  const firstJourneyFirstFlight = firstJourney?.flights[0]
  const firstJourneyLastFlight = last(firstJourney?.flights || [])
  const lastJourney = last(journeys)?.flightGroup
  const lastJourneyLastFlight = last(lastJourney?.flights || [])

  if (!firstJourneyFirstFlight || !lastJourneyLastFlight || !firstJourneyLastFlight) return []

  const price = sum(journeys, journey => journey?.price.all.totalFare || 0)
  const { categoryId, productId } = getCategoryAndProductId(item.itemType)

  const isReturnOrOneWay = [FlightsFareTypes.RETURN, FlightsFareTypes.ONE_WAY].includes(item.fareType as FlightsFareTypes)

  return [comluxgroup.createItem_1_1_0({
    offerId: 'flight',
    categoryId,
    productId,
    duration: getFlightDurationInDays(
      firstJourneyFirstFlight.departingDate,
      lastJourneyLastFlight.arrivalDate,
    ),
    travelStart: firstJourneyFirstFlight.departingDate,
    travelEnd: lastJourneyLastFlight.arrivalDate,
    numberOfAdults: item.occupants.adults,
    numberOfChildren: item.occupants.children,
    numberOfInfants: item.occupants.infants,
    childrenAges: item.occupants.childrenAge,
    currency: state.geo.currentCurrency,
    destinationCountry: isReturnOrOneWay ? firstJourneyLastFlight.arrivalCountry : lastJourneyLastFlight.arrivalCountry,
    originCountry: firstJourneyFirstFlight.departureCountry,
    originCity: firstJourneyFirstFlight.departureCity,
    destinationCity: isReturnOrOneWay ? firstJourneyLastFlight.arrivalCity : lastJourneyLastFlight.arrivalCity,
    typeId: item.fareType.toLowerCase(),
    itemId: item.itemId,
    metadata: JSON.stringify({
      originAirportCode: firstJourneyFirstFlight.departureAirport,
      arrivalAirportCode: isReturnOrOneWay ? firstJourneyFirstFlight.arrivalAirport : lastJourneyLastFlight.arrivalAirport,
    }),
    price,
  })]
}

export default getFlightCheckoutItems

function getFlightDurationInDays(departureDate?: string, arrivalDate?: string) {
  // default to 1 for one way
  const calculatedDuration = departureDate && arrivalDate ? moment(arrivalDate).diff(departureDate, 'days') : 1
  return calculatedDuration && calculatedDuration > 0 ? calculatedDuration : 1
}

async function getCityByAirportName(departingAirportName: string, arrivalAirportName: string) {
  const [departingAirportData, arrivalAirportData] = await Promise.all([getAirportsSearch(departingAirportName), getAirportsSearch(arrivalAirportName)])

  const departingLongitude = departingAirportData[0]?.lg
  const departingLatitude = departingAirportData[0]?.lt
  const arrivalLongitude = arrivalAirportData[0]?.lg
  const arrivalLatitude = arrivalAirportData[0]?.lt

  const getCitiesPromises: Array<Promise<MappedPlace | undefined> | undefined> = [undefined, undefined]

  if (departingLongitude && departingLatitude) {
    getCitiesPromises[0] = getPlaceByLatLong(departingLatitude, departingLongitude).then(mapCityLevelPlace)
  }

  if (arrivalLongitude && arrivalLatitude) {
    getCitiesPromises[1] = getPlaceByLatLong(arrivalLatitude, arrivalLongitude).then(mapCityLevelPlace)
  }

  const places = await Promise.all(getCitiesPromises)

  return {
    originCity: places[0]?.city,
    destinationCity: places[1]?.city,
  }
}

export async function journeyToSnowplowItemContext(journey: App.JourneyV2, currency: string, occupancy: App.Occupants): Promise<SelfDescribingJson> {
  const firstFlight = journey.flightGroup.flights[0]
  const lastFlight = last(journey.flightGroup.flights)

  let cities: {
    originCity: string | undefined,
    destinationCity: string | undefined
  } = { originCity: undefined, destinationCity: undefined }

  if (firstFlight?.departureAirportName && lastFlight?.arrivalAirportName) {
    try {
      cities = await getCityByAirportName(firstFlight?.departureAirportName, lastFlight?.arrivalAirportName)
    } catch { }
  }

  const flightItemContext = createFlightItemContext({
    offerId: 'flight',
    originCity: cities?.originCity,
    destinationCity: cities?.destinationCity,
    airline: journey.carrier,
    arrivalAirportCode: lastFlight.arrivalAirport,
    departureDate: firstFlight.departingDate,
    duration: getFlightDurationInDays(firstFlight.departingDate, lastFlight.arrivalDate),
    fareType: journey.fareType.toLowerCase(),
    numberOfAdults: occupancy.adults,
    numberOfChildren: occupancy.children,
    numberOfInfants: occupancy.infants,
    childrenAges: occupancy.childrenAge,
    originAirportCode: firstFlight.departureAirport,
    pricePerAdult: journey.price.adult.totalFare!,
    arrivalDate: lastFlight.arrivalDate,
    destinationCountry: lastFlight.arrivalCountry,
    originCountry: firstFlight.departureCountry,
    currency,
  })

  return flightItemContext
}

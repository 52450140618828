import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { sum } from 'lib/array/arrayUtils'
import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import moment from 'moment'

export type DisplayTotals = {
  original: {
    price: number;
    memberPrice: number;
    value: number;
    discount: number;
    memberDiscount: number;
    flightBundlePrice: number;
    flightBundleMemberPrice: number;
    flightBundleValue: number;
    discountWithFlight: number;
    memberDiscountWithFlight: number;
  }
  totals: {
    price: number;
    memberPrice: number;
    value: number;
    flightBundlePrice: number;
    flightBundleMemberPrice: number;
    flightBundleValue: number;
  }
}

export const getBedbankPriceAndValue =
  (hotelOnlyRates: Array<App.BedbankRate>, roomId: string | undefined):
  {price?: number, memberPrice?: number, value?: number} => {
    const rate = hotelOnlyRates.find(rate => !roomId || rate.roomId === roomId)
    if (!rate) {
      return {}
    }
    const propertyFees = rate.totals.propertyFees ?? 0
    const inclusivePrice = rate.totals.inclusive + propertyFees
    const inclusiveMemberPrice = rate.totals.memberInclusive && rate.totals.memberInclusive > 0 ? rate.totals.memberInclusive + propertyFees : 0
    return {
      price: inclusivePrice,
      memberPrice: inclusiveMemberPrice,
      value: rate.value,
    }
  }

export function getBedbankFacilitiesLimit(tagline: boolean, promotionsLength: number) {
  let limitNumberOfFacilities = 5
  if (tagline) limitNumberOfFacilities = limitNumberOfFacilities - 3
  if (promotionsLength) limitNumberOfFacilities = Math.round(limitNumberOfFacilities - (promotionsLength * 1.5))
  limitNumberOfFacilities = limitNumberOfFacilities > 0 ? limitNumberOfFacilities : 0

  return limitNumberOfFacilities
}

// Promo utils
export default function getBedbankPromoExtraValue(data: {
  value: number,
  price: number,
  minLos: number,
  duration: number,
}): number {
  return ((data.value - data.price) / data.minLos) * data.duration
}

export const filterPromotionsByTravelPeriod = (promo: App.BedbankPromotion, checkIn: string, checkOut: string) => {
  const travelPeriodFrom = new Date(promo.travelPeriod.from)
  const travelPeriodTo = new Date(promo.travelPeriod.to)
  const checkInDate = new Date(checkIn)
  const checkOutDate = new Date(checkOut)

  return travelPeriodFrom <= checkInDate && travelPeriodTo >= checkInDate && travelPeriodFrom <= checkOutDate && travelPeriodTo >= checkOutDate
}

export const getPromotionDefaultPeriod = () => {
  return {
    startDate: moment().utc().add(1, 'day').format(ISO_DATE_FORMAT),
    endDate: moment().utc().add(500, 'days').format(ISO_DATE_FORMAT),
  }
}

export const displayPricingAs = (perPerson?: boolean, perNight?: boolean): 'night' | 'person' | undefined => {
  if (perPerson) return 'person'
  if (perNight) return 'night'
}

export const getCalendarDisplayTotalPrice = ({
  price,
  flightPrice,
  adultsNumber,
  roomsNumber,
  withFlights,
  displayPricingPer,
  nights,
}: {
  price: number;
  flightPrice: number;
  adultsNumber: number;
  roomsNumber: number;
  withFlights: boolean;
  displayPricingPer: 'night' | 'person' | undefined;
  nights: number;
}): {
  total: number;
  saleUnit: string;
} => {
  let total = price
  let saleUnit = 'total'
  if (withFlights) {
    total = total + flightPrice
  }
  if (displayPricingPer === 'night') {
    total = price / nights
    saleUnit = '/night'
    if (withFlights) {
      total = total + (flightPrice / nights)
    }
  }
  if (displayPricingPer === 'person') {
    total = price / roomsNumber / 2
    saleUnit = '/person'
    if (withFlights) {
      total = total + (flightPrice / adultsNumber)
    }
  }
  return {
    total: Math.round(total),
    saleUnit,
  }
}

export const getDisplayTotalPricePerNight = (displayTotals: DisplayTotals, nights: number):DisplayTotals => {
  const { original, totals } = displayTotals
  return {
    original: {
      ...original,
      price: safeDivideAndCeil(original.price, nights),
      memberPrice: original.memberPrice > 0 ? safeDivideAndCeil(original.memberPrice, nights) : 0,
      value: safeDivideAndCeil(original.value, nights),
      flightBundlePrice: safeDivideAndCeil(original.flightBundlePrice, nights),
      flightBundleValue: safeDivideAndCeil(original.flightBundleValue, nights),
    },
    totals: {
      ...totals,
      price: safeDivideAndCeil(totals.price, nights),
      memberPrice: totals.memberPrice > 0 ? safeDivideAndCeil(totals.memberPrice, nights) : 0,
      value: safeDivideAndCeil(totals.value, nights),
      flightBundlePrice: safeDivideAndCeil(original.flightBundlePrice, nights),
      flightBundleValue: safeDivideAndCeil(original.flightBundleValue, nights),
    },
  }
}

export const getDisplayTotalMemberPricePerNight = (displayTotals: DisplayTotals, nights: number):DisplayTotals => {
  const { original, totals } = displayTotals
  return {
    original: {
      ...original,
      price: safeDivideAndCeil(original.price, nights),
      memberPrice: original.memberPrice > 0 ? safeDivideAndCeil(original.memberPrice, nights) : 0,
      value: safeDivideAndCeil(original.value, nights),
      flightBundlePrice: safeDivideAndCeil(original.flightBundlePrice, nights),
      flightBundleValue: safeDivideAndCeil(original.flightBundleValue, nights),
    },
    totals: {
      ...totals,
      price: safeDivideAndCeil(totals.price, nights),
      memberPrice: totals.memberPrice > 0 ? safeDivideAndCeil(totals.memberPrice, nights) : 0,
      value: safeDivideAndCeil(totals.value, nights),
      flightBundlePrice: safeDivideAndCeil(original.flightBundlePrice, nights),
      flightBundleValue: safeDivideAndCeil(original.flightBundleValue, nights),
    },
  }
}

export const getDisplayTotalPrice = ({
  hotelPrice,
  hotelMemberPrice,
  bundlePrice,
  bundleMemberPrice,
  hotelValue,
  bundleValue,
  hotelDiscount,
  hotelMemberDiscount,
  rooms,
  flightPrice,
  displayPricingAsPerPerson,
  checkIn,
}: {
  rooms: Array<App.Occupants>;
  hotelPrice?: number;
  hotelMemberPrice?: number;
  hotelValue?: number;
  bundlePrice?: number;
  bundleMemberPrice?: number;
  bundleValue?: number;
  hotelDiscount?: number;
  hotelMemberDiscount?: number;
  flightPrice?: number;
  displayPricingAsPerPerson?: boolean;
  checkIn?: string | moment.Moment;
}): DisplayTotals => {
  const adults = sum(rooms, room => room.adults)
  const originHotelPrice = hotelPrice || 0
  const originBundlePrice = bundlePrice || originHotelPrice
  const originHotelMemberPrice = hotelMemberPrice || 0
  const originBundleMemberPrice = bundleMemberPrice || originHotelMemberPrice
  const originHotelValue = hotelValue || 0
  const originHotelDiscount = hotelDiscount ?? (originHotelValue > 0 ? Number(((originHotelValue - originHotelPrice) / originHotelValue).toFixed(2)) : 0)
  const originHotelMemberDiscount = hotelMemberDiscount ?? (originHotelValue > 0 && originHotelMemberPrice > 0 ? Number(((originHotelValue - originHotelMemberPrice) / originHotelValue).toFixed(2)) : 0)
  const originBundleValue = bundleValue || originHotelValue
  const originFlightPrice = flightPrice || 0
  const originHotelPriceWithFlight = originBundlePrice + originFlightPrice
  const originHotelMemberPriceWithFlight = originBundleMemberPrice > 0 ? originBundleMemberPrice + originFlightPrice : 0
  const originHotelValueWithFlight = originBundleValue + originFlightPrice

  const discountWithFlight = originHotelValueWithFlight > 0 ?
    Number(((originHotelValueWithFlight - originHotelPriceWithFlight) / originHotelValueWithFlight).toFixed(2)) : 0
  const memberDiscountWithFlight = originHotelValueWithFlight > 0 ?
    Number(((originHotelValueWithFlight - originHotelMemberPriceWithFlight) / originHotelValueWithFlight).toFixed(2)) : 0

  const originalPrices = {
    price: originHotelPrice,
    memberPrice: originHotelMemberPrice,
    value: originHotelValue,
    flightBundlePrice: originHotelPriceWithFlight,
    flightBundleMemberPrice: originHotelMemberPriceWithFlight,
    flightBundleValue: originHotelValueWithFlight,
    discount: originHotelDiscount,
    memberDiscount: originHotelMemberDiscount,
    discountWithFlight,
    memberDiscountWithFlight,
  }

  if (checkIn && displayPricingAsPerPerson) {
    return {
      original: originalPrices,
      totals: {
        price: originalPrices.price / adults,
        memberPrice: originalPrices.memberPrice > 0 ? originalPrices.memberPrice / adults : 0,
        value: originalPrices.value / adults,
        flightBundlePrice: originalPrices.flightBundlePrice / adults,
        flightBundleMemberPrice: originalPrices.flightBundleMemberPrice > 0 ? originalPrices.flightBundleMemberPrice / adults : 0,
        flightBundleValue: originalPrices.flightBundleValue / adults,
      },
    }
  } else if (!checkIn && displayPricingAsPerPerson) {
    return {
      original: originalPrices,
      totals: {
        price: originalPrices.price / rooms.length / 2,
        memberPrice: originalPrices.memberPrice > 0 ? originalPrices.memberPrice / rooms.length / 2 : 0,
        value: originalPrices.value / rooms.length / 2,
        flightBundlePrice: originalPrices.flightBundlePrice / rooms.length / 2,
        flightBundleMemberPrice: originalPrices.flightBundleMemberPrice > 0 ? originalPrices.flightBundleMemberPrice / rooms.length / 2 : 0,
        flightBundleValue: originalPrices.flightBundleValue / rooms.length / 2,
      },
    }
  }

  return {
    original: originalPrices,
    totals: {
      price: originalPrices.price,
      memberPrice: originalPrices.memberPrice,
      value: originalPrices.value,
      flightBundlePrice: originalPrices.flightBundlePrice,
      flightBundleMemberPrice: originalPrices.flightBundleMemberPrice,
      flightBundleValue: originalPrices.flightBundleValue,
    },
  }
}

export const getBedbankRatesCheckInRange = (
  monthKey: string,
  duration: number,
) => {
  const startOfMonth = moment(monthKey).startOf('month').format(ISO_DATE_FORMAT)
  const endOfMonth = moment(monthKey).endOf('month').format(ISO_DATE_FORMAT)

  let start = moment(startOfMonth).format(ISO_DATE_FORMAT)
  let end = moment(startOfMonth).add(duration, 'days').format(ISO_DATE_FORMAT)
  const selectedMonth: Array<Record<string, string>> = []

  while (moment(start).isSameOrBefore(endOfMonth)) {
    selectedMonth.push({
      checkIn: start,
      checkOut: end,
    })
    start = moment(start).add(1, 'days').format(ISO_DATE_FORMAT)
    end = moment(end).add(1, 'days').format(ISO_DATE_FORMAT)
  }

  return selectedMonth
}

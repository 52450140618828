import React from 'react'
import LuxLoyaltyPointsListItem, { BaseLuxLoyaltyPointsListItemProps } from './LuxLoyaltyPointsListItem'
import LuxLoyaltyCopy, { LuxLoyaltyCopyProps } from './LuxLoyaltyCopy'

interface Props extends Omit<LuxLoyaltyCopyProps, 'tier'>, BaseLuxLoyaltyPointsListItemProps {}

function LuxLoyaltyCopyListItem(props: Props) {
  const { size, tier, type, earnablePoints, statusCreditsEarned } = props
  return <LuxLoyaltyPointsListItem
    size={size}
    tier={tier}
  >
    <LuxLoyaltyCopy
      tier={tier}
      type={type}
      earnablePoints={earnablePoints}
      statusCreditsEarned={statusCreditsEarned}
    />
  </LuxLoyaltyPointsListItem>
}

export default LuxLoyaltyCopyListItem

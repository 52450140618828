import React, { FormEventHandler, useCallback, useContext, useEffect, useRef } from 'react'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { forgotPassword, getUserKeyFromPhone, login, updateUserDetails } from 'actions/AuthActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import TextLink from 'components/Luxkit/TextLink'
import ReCAPTCHA from 'react-google-recaptcha'
import config from 'constants/config'
import BodyText from 'components/Luxkit/Typography/BodyText'
import PasswordInput from 'components/Common/Form/Input/PasswordInput'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import noop from 'lib/function/noop'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { AccountAccessModalResult } from './AccountAccessModal'
import * as Analytics from 'analytics/analytics'
import HelpPane from 'components/Common/HelpPane'
import ContactContext from 'contexts/contactContext'
import Caption from 'components/Luxkit/Typography/Caption'
import { useIsMobileScreen } from 'hooks/useScreenSize'

interface LoginForm {
  password: string;
}

interface Props {
  error?: any;
  user: App.User;
  isStoreMode: boolean;
  onModeChange?: (mode: App.UiAccountModalMode) => void;
  dismissable?: boolean;
}

function AccountAccessJoinExistingAccount(props: Props) {
  const {
    error,
    user,
    isStoreMode,
    onModeChange = noop,
    dismissable,
  } = props

  const dispatch = useAppDispatch()
  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const nativeInputRef = useRef<HTMLInputElement>(null)
  const reRef = useRef<ReCAPTCHA>(null)
  const loggedIn = useAppSelector(selectLoggedIn)
  const currentContact = useContext(ContactContext)
  const contactNumber = currentContact.defaultContact
  const isMobile = useIsMobileScreen()

  const currentPathName = useAppSelector(state => state.router.location.pathname)
  const currentSearch = useAppSelector(state => state.router.location.search)
  const callbackPath = currentPathName + currentSearch

  useEffect(() => {
    if (loggedIn) {
      if (user.phoneNumber) {
        dispatch(updateUserDetails({
          phone: user.phoneNumber.phone,
          phonePrefix: user.phoneNumber.prefix,
        },
        ))
      }
      modalContext?.resolve({ loggedIn: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'sign_in_existing_account_found',
      action: 'impression',
      category: 'logging',
      type: 'nonInteraction',
    })

    if (!isMobile) {
      nativeInputRef.current?.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onForgotPassword = useCallback(() => {
    if (user.email) {
      // forgot password only works with an email, not phone number (for now?)
      dispatch(forgotPassword(user.email, callbackPath))
      onModeChange('forgotPassword')
    }
  }, [dispatch, onModeChange, user, callbackPath])

  const onLogin = useCallback<FormEventHandler<HTMLFormElement>>(async(event) => {
    event.preventDefault()
    const loginData = formToObject<LoginForm>(event.currentTarget)
    let recaptchaData
    if (reRef.current) {
      recaptchaData = await reRef.current.executeAsync()
    }
    dispatch(login(user, loginData.password, recaptchaData))
  }, [dispatch, user])

  const onBack = useCallback(() => {
    // go back to join and swap back to the phone number user
    // (we're currently on the 'email' user they entered for registration)
    onModeChange('join', getUserKeyFromPhone(user.phoneNumber!))
  }, [onModeChange, user])

  return (<>
    <ModalHeader
      title="Existing account found"
      onBackButtonClick={onBack}
      dismissible={dismissable}
    />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={24} as="form" onSubmit={onLogin} name="loginForm">
          {error?.status === 500 && <MessageBanner kind="critical" description="Something went wrong. Please try again." />}
          {error?.message && error.status !== 500 && <MessageBanner kind="critical" description={error.message} />}
          <VerticalSpacer gap={16}>
            <BodyText variant="large">
              You already have an account registered under <b>{user.email}</b>. Please enter your password to log in.
            </BodyText>
            <fieldset>
              <PasswordInput
                label="Password"
                id="password"
                required
                name="password"
                placeholder="Password"
                requiredErrorMessage="Password is required"
                ref={nativeInputRef}
              />
              <Group direction="vertical" gap={16} horizontalAlign="center">
                <TextButton
                  kind="primary"
                  size="large"
                  type="submit"
                  fit="flex"
                >
                  Log in with password
                </TextButton>
                {!isStoreMode && <TextLink
                  onClick={onForgotPassword}
                  size="medium"
                >
                  Forgot your password?
                </TextLink>}
              </Group>
              {error?.status === 500 && (
                <HelpPane contactNumber={contactNumber} />
              )}
            </fieldset>
            {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={reRef} />}
            {user.phoneNumber && <Caption variant="medium" colour="neutral-three">
              By continuing with login, your phone number will be updated with the one you entered during the initial sign-up process.
            </Caption>}
          </VerticalSpacer>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    error: state.auth.error,
    isStoreMode: state.config.storeMode,
  }
}

export default connect(mapStateToProps)(AccountAccessJoinExistingAccount)

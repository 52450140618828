export const all = (regionCode: string) => ['offers', regionCode] as const
export const lists = (regionCode: string) =>
  [...all(regionCode), 'list'] as const
export const list = (regionCode: string, filters: string | Array<string>) => [
  ...lists(regionCode),
  { filters },
]
export const collections = (regionCode: string) =>
  [...all(regionCode), 'list'] as const
export const collection = (
  regionCode: string,
  filters: string | Array<string>,
) => [...collections(regionCode), { filters }]
export const details = (regionCode: string) =>
  [...all(regionCode), 'details'] as const

// ToDo: Perhaps find a better way to do this?
export const detail = (
  regionCode: string,
  id?: string,
  flightOrigin?: string,
) => [...details(regionCode), id, flightOrigin] as const

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise'
import { capitalise } from 'lib/string/stringUtils'
import { OFFER_TYPE_CRUISE } from 'constants/offer'
import { mapUrgencyTags, formatOfferName, getCruiseProductType } from './cruiseMap'
import { cruiseInclusionsMap } from './cruiseInclusionMap'
import { dateDifference } from 'lib/datetime/dateUtils'
import { sortBy, groupBy } from 'lib/array/arrayUtils'
import { formatRoundedAmount } from 'lib/format/formatCurrencyIntl'
import config from 'constants/config'
import uuidV4 from 'lib/string/uuidV4Utils'

export function cruiseOfferSummaryMap(cruise: CruisesContract.OfferSummaryResponse, regionCode: string): App.CruiseOffer {
  const [lowestPrice] = sortBy(cruise.prices, (price) => price.totalAmount, 'asc')
  const lowestDeparturePrice = {
    currencyCode: lowestPrice.currencyCode,
    cabinCategory: capitalise(lowestPrice.cabinType),
    adultFare: lowestPrice.totalAmount,
    total: lowestPrice.totalAmount,
    value: lowestPrice.totalAmount,
    rateCode: lowestPrice.rateCode,
  }

  const mainDepartureDetails = mainDepartureDetailsMap(cruise, regionCode)
  const lowestDeparturePricesByCategory = lowestDeparturePricesByCategoryMap(cruise, regionCode)
  const departuresSummary = departureSummaryMap(cruise.departures)
  const offerInclusions = cruise.inclusions as Array<Cruises.OfferInclusion>
  const hasMemberInclusions = !!mainDepartureDetails.luxPlusInclusionsByTier

  return {
    id: cruise.id,
    productType: getCruiseProductType(cruise, mainDepartureDetails),
    cruiseLine: cruiseLineMap(cruise.vendor),
    analyticsType: OFFER_TYPE_CRUISE,
    name: formatOfferName(cruise.name),
    slug: cruise.id,
    destinationDescription: cruise.destinationDescription ?? undefined,
    description: cruise.description ?? undefined,
    disableDeposits: !config.DEPOSITS_ENABLED || !cruise.depositAvailable,
    type: OFFER_TYPE_CRUISE,
    parentType: OFFER_TYPE_CRUISE,
    departures: {},
    departuresSummary,
    duration: cruise.duration,
    departurePort: cruise.port.departure.name,
    departureDates: cruise.availableDepartureDates,
    returnPort: cruise.port.arrival.name,
    ship: shipMap(cruise.ship),
    images: cruise.images.map((image) => ({
      id: image.imageId,
    })),
    itinerary: itineraryMap(cruise.itineraries),
    lowestDeparturePricesByCategory,
    lowestDeparturePrice,
    mainDepartureDetails,
    evergreenInclusions: cruise.evergreenInclusions?.map<App.EvergreenInclusion>((serverEvergreenInclusion) => {
      return {
        id: uuidV4(),
        description: serverEvergreenInclusion.inclusion,
        cabinCategories: serverEvergreenInclusion.cabinCategories,
        cabinTypes: serverEvergreenInclusion.cabinTypes,
        shipId: serverEvergreenInclusion.shipId,
      }
    }) ?? [],
    urgencyTags: mapUrgencyTags(),
    luxPlus: {
      hasMemberInclusions,
    },
    hasPromotions: cruise.promotions.length > 0,
    offerInclusions,
  }
}

function departureSummaryMap(departures: CruisesContract.OfferSummaryResponse['departures']) {
  const departuresMap = groupBy(departures, (departure) => departure.departureDate.slice(0, -3))
  return Object.fromEntries(departuresMap.entries()) as Record<string, Array<App.CruiseDepartureSummary>>
}

function cruiseLineMap(cruiseLine: CruisesContract.OfferSummaryResponse['vendor']): App.CruiseLine {
  return {
    id: cruiseLine.id,
    name: cruiseLine.name,
    imageId: cruiseLine.imageId ?? undefined,
  }
}

function shipMap(ship: CruisesContract.OfferSummaryResponse['ship']): App.CruiseShip {
  return {
    id: ship.id,
    name: ship.name,
    images: [],
    shipInfo: [{
      title: 'Ship Info',
      description: ship.description,
      dataTypeName: 'Summary',
    }],
  }
}

function itineraryMap(itineraryItems: CruisesContract.OfferSummaryResponse['itineraries']) {
  return itineraryItems.map((itinerary) => ({
    title: (itinerary.port?.name || itinerary.description || 'At sea').split(',')[0],
    startDay: itinerary.dayNumber,
    description: itinerary.description ?? '',
    departureTime: itinerary.departureTime ?? '',
    startTime: '',
    endTime: itinerary.arrivalTime ?? '',
    port: itinerary.port ? {
      name: itinerary.port.name || '',
      city: itinerary.port.city || '',
      countryCode: itinerary.port.countryCode ?? '',
      nearestAirportCode: '',
      address: '',
      stateProvince: '',
      latitude: itinerary.port.latitude,
      longitude: itinerary.port.longitude,
    } : null,
  })).sort((a, b) => a.startDay - b.startDay)
}

function mainDepartureDetailsMap(cruise: CruisesContract.OfferSummaryResponse, regionCode: string): App.CruiseDepartureDetails {
  const [lowestPrice] = sortBy(cruise.prices, (price) => price.totalAmount, 'asc')
  const lowestOverallPriceDetails = lowestOverallPriceDetailsMap(cruise, regionCode)
  const lowestPriceDetailsByCategory = lowestPriceDetailsByCategoryMap(cruise)
  const offerInclusions = cruise.inclusions as Array<Cruises.OfferInclusion>

  const { luxPlusInclusionsByTier, standardInclusions, inclusionDetails } = cruiseInclusionsMap(
    offerInclusions ?? [],
    lowestPrice.departureId,
    lowestOverallPriceDetails.rateCode!,
    lowestOverallPriceDetails.total,
    regionCode,
    true,
  )

  return {
    id: lowestPrice.departureId,
    lowestOverallPriceDetails,
    lowestPriceDetailsByCategory,
    departureDate: cruise.mainDepartureDate,
    externalId: null,
    arrivalDate: '',
    luxPlusInclusionsByTier,
    standardInclusions,
    inclusionDetails,
  }
}

function lowestOverallPriceDetailsMap(cruise: CruisesContract.OfferSummaryResponse, regionCode: string) {
  const [lowestPrice] = sortBy(cruise.prices, (price) => price.totalAmount, 'asc')

  const discountPills = lowestPrice.discountPill ? {
    price: lowestPrice.discountPill.totalAmount,
    discountPercentage: lowestPrice.discountPill.percentage,
    discountPrice: lowestPrice.discountPill.discountPrice,
  } : null

  const promotion = offerPromotionMap(cruise, regionCode)

  return {
    total: lowestPrice.totalAmount,
    value: lowestPrice.totalAmount,
    adultFare: lowestPrice.totalAmount,
    cabinCategory: capitalise(lowestPrice.cabinType),
    isTaxIncluded: true,
    isNccfIncluded: true,
    rateCode: lowestPrice.rateCode,
    currencyCode: lowestPrice.currencyCode,
    discountPills,
    promotion,
  }
}

function lowestDeparturePricesByCategoryMap(cruise: CruisesContract.OfferSummaryResponse, regionCode: string) {
  const promotion = offerPromotionMap(cruise, regionCode)

  return cruise.prices.reduce(
    (acc, cur) => {
      const departureId = cur.departureId
      const departurePrices = acc[departureId] || {}
      const cabinType = capitalise(cur.cabinType)

      departurePrices[cabinType] = {
        cabinCategory: cabinType,
        total: cur.totalAmount,
        value: cur.totalAmount,
        adultFare: cur.totalAmount,
        isTaxIncluded: true,
        isNccfIncluded: true,
        currencyCode: cur.currencyCode,
        rateCode: cur.rateCode,
        promotion,
        discountPills: cur.discountPill ? {
          price: cur.discountPill.totalAmount,
          discountPercentage: cur.discountPill.percentage,
          discountPrice: cur.discountPill.discountPrice,
        } : null,
      }

      return {
        ...acc,
        [departureId]: departurePrices,
      }
    }, {})
}

function lowestPriceDetailsByCategoryMap(cruise: CruisesContract.OfferSummaryResponse) {
  return cruise.prices.reduce(
    (acc, cur) => {
      const cabinType = capitalise(cur.cabinType)

      return {
        ...acc,
        [cabinType]: {
          total: cur.totalAmount,
          value: cur.totalAmount,
          adultFare: cur.totalAmount,
          isTaxIncluded: true,
          isNccfIncluded: true,
          currencyCode: cur.currencyCode,
          rateCode: cur.rateCode,
          cabinCategory: cabinType,
          promotion: null,
          discountPills: null,
        },
      }
    }, {})
}

function offerPromotionMap(cruise: CruisesContract.OfferSummaryResponse, regionCode: string): App.CruisePromotionDetails | undefined {
  const [promotion] = sortBy(cruise.promotions, (promotion) => new Date(promotion.endDate).getTime(), 'asc')

  const deposit = promotion?.deposit ? {
    amount: promotion.deposit.totalAmount,
    currencyCode: promotion.deposit.currencyCode,
    formattedValue: formatRoundedAmount(promotion.deposit.totalAmount, promotion.deposit.currencyCode, regionCode),
    type: promotion.deposit.type as 'PERCENTAGE' | 'VALUE',
  } : null

  const onBoardCredit = promotion?.onBoardCredit ? {
    amount: promotion.onBoardCredit.totalAmount,
    currencyCode: promotion.onBoardCredit.currencyCode,
    formattedValue: formatRoundedAmount(promotion.onBoardCredit.totalAmount, promotion.onBoardCredit.currencyCode, regionCode),
  } : null

  return promotion ? {
    startDate: promotion.startDate,
    endDate: promotion.endDate,
    isNew: dateDifference(new Date(), new Date(promotion.startDate)).days < 7,
    isEndingIn: dateDifference(new Date(promotion.endDate), new Date()).days,
    leExclusive: promotion.leExclusive,
    sellingPoints: promotion.sellingPoints,
    deposit,
    onBoardCredit,
  } : undefined
}

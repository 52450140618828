import Heading from 'components/Luxkit/Typography/Heading'
import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
}

function SearchTileRowTitle({ children }: Props) {
  return <Heading variant="heading5" lineClamp={3} className={SearchTileRowTitle.name}>
    {children}
  </Heading>
}

export default SearchTileRowTitle

import clsx from 'clsx'
import React, { ComponentProps, forwardRef, PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import Label from './Label/Label'

const Container = styled.div`
  display: grid;
  --sheet-border-width: 1px;
  grid-template-columns: [border-start] var(--sheet-border-width) [content-start] minmax(0px, 1fr) [content-end] var(--sheet-border-width) [border-end];
  grid-template-rows:
    [label-start]
    auto
    [border-start]
    var(--sheet-border-width)
    auto
    [label-end content-start]
    minmax(0px, 1fr)
    [content-end]
    var(--sheet-border-width)
    [border-end];
`

const LabelContainer = styled.div`
  grid-column: border;
  grid-row: label;

  &.placement-start { justify-self: start; }
  &.placement-center { justify-self: center; }
  &.placement-end { justify-self: end; }
`

const Border = styled.div`
  grid-column: border;
  grid-row: border;
  pointer-events: none;
  border-width: var(--sheet-border-width);
  border-style: solid;
  border-radius: ${props => props.theme.borderRadius.M};

  &.kind-highlight { border-color: ${props => props.theme.palette.highlight.secondary.normalForeground}; }
  &.kind-highlight-tertiary { border-color: ${props => props.theme.palette.highlight.tertiary.normalBackground}; }
  &.kind-lux-plus { border-color: ${props => props.theme.palette.product.luxPlus.foreground}; }
`

const Content = styled.div`
  grid-column: content;
  grid-row: content;
`

interface Props extends PropsWithChildren, Utils.PropsWithDataAttributes {
  kind: 'highlight' | 'lux-plus' | 'highlight-tertiary'
  /**
   * If string is provided, it'll be wrapped by the proper label component.
   * For more complex scenarios, manually pass the appropriate label component.
   */
  label: string | ReactElement
  /**
   * @default start
   */
  labelPlacement?: 'start' | 'center' | 'end'
  className?: string
}

const KINDS_LABEL_VARIANTS: Record<Props['kind'], ComponentProps<typeof Label>['variant']> = {
  highlight: 'highlight-secondary',
  'highlight-tertiary': 'highlight-tertiary',
  'lux-plus': 'lux-plus',
}

const LabeledSheet = forwardRef<HTMLDivElement, Props>(function LabeledSheet(
  {
    children,
    kind,
    label,
    labelPlacement = 'start',
    className,
    ...rest
  },
  ref,
) {
  const isStringChildren = typeof label === 'string'

  return <Container
    {...rest}
    ref={ref}
    className={clsx(LabeledSheet.name, className)}
  >
    <Border
      aria-hidden="true"
      className={clsx(`kind-${kind}`)}
    />
    <LabelContainer className={clsx(`placement-${labelPlacement}`)}>
      {isStringChildren && <Label kind="primary" variant={KINDS_LABEL_VARIANTS[kind]}>{label}</Label>}
      {!isStringChildren && label}
    </LabelContainer>
    <Content>{children}</Content>
  </Container>
})

export default LabeledSheet

import React, { useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import TextButton from 'components/Luxkit/Button/TextButton'
import { getNotificationType, hasAccountNotification } from 'selectors/accountSelectors'
import FormatCurrency from 'components/Common/FormatCurrency'
import AvatarCircle from 'components/Common/AvatarCircle/AvatarCircle'
import Caption from 'components/Luxkit/Typography/Caption'
import { isFlightsCredit } from 'selectors/flightsSelectors'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import NavigationHeaderTripsMenu from './NavigationHeaderTripsMenu'
import Group from 'components/utils/Group'
import BusinessTravellerAccountButtonMenu from 'businessTraveller/components/business-account/BusinessTravellerAccountButtonMenu'
import config from 'constants/config'
import { useRestoreCartState } from 'checkout/hooks/useRestoreCartState'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import AgentHubAccountMenu from 'agentHub/components/AgentHubAccountMenu'
import AgentHubCustomerViewSwitch from 'agentHub/components/AgentHubCustomerViewSwitch'
import { SvgIconColour } from 'components/Luxkit/SvgIcon'
import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import ModalContext from 'contexts/ModalContext'
import LineSuitcaseIcon from 'components/Luxkit/Icons/line/LineSuitcaseIcon'
import * as Analytics from 'analytics/analytics'
import MyCartMenu from './MyCartMenu'
import AddToCartModal from 'components/Common/Modals/AddToCartModal/AddToCartModal'
import { isLuxLoyaltyEnabled } from 'luxLoyalty/selectors/featureToggles'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import HeaderNotification from '../HeaderNotification'
import Plural from 'components/utils/Formatters/Plural'
import { LUX_LOYALTY_TIER_DISPLAY_NAMES } from 'luxLoyalty/constants/luxLoyaltyContent'

interface Props {
  isLoggedIn: boolean;
  className?: string;
  hasNotification: boolean;
  firstName?: string;
  lastName?: string;
  balance: number;
  canRedeemLuxPlusBenefits: boolean;
  notificationType: SvgIconColour;
  luxLoyaltyAccount: App.LuxLoyaltyAccount;
  isLuxLoyaltyEnabled: boolean;
}

function NavigationHeader(props: Props) {
  const {
    isLoggedIn,
    className,
    hasNotification,
    firstName,
    lastName,
    balance,
    canRedeemLuxPlusBenefits,
    notificationType,
    luxLoyaltyAccount,
    isLuxLoyaltyEnabled,
  } = props

  useRestoreCartState()

  const showModal = useContext(ModalContext)
  const showLogin = useCallback(() => {
    showModal(<AccountAccessModal />)
  }, [showModal])

  const onMyEscapesClick = () => {
    Analytics.trackClientEvent({
      subject: 'my_escapes',
      action: 'click',
      category: 'logging',
      type: 'interaction',
    })
  }

  return (
    <Group direction="horizontal" gap={8} className={className}>
      <TripGuard>
        <NavigationHeaderTripsMenu />
      </TripGuard>
      {isLoggedIn && <>
        {config.agentHub.isEnabled && <AgentHubCustomerViewSwitch />}
        {config.UNIVERSAL_CHECKOUT_MULTI_CART_ITEMS_ENABLED && <>
          <MyCartMenu />
          <AddToCartModal/>
        </>
        }
        {config.agentHub.isEnabled && <AgentHubAccountMenu />}
        {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerAccountButtonMenu />}
        {!config.agentHub.isEnabled && !config.businessTraveller.isEnabled && (
          <TextButton
            startIcon={<LineSuitcaseIcon colour="primary"/>}
            kind="tertiary"
            variant="dark"
            onClick={onMyEscapesClick}
            to="/account/my-escapes"
          >
            My Escapes
          </TextButton>
        )}
        {config.NOTIFICATION_CENTRE_ENABLED && <HeaderNotification />}
        {/* TODO - remove/update. This is a temp UI implementation */}
        {isLuxLoyaltyEnabled && !!luxLoyaltyAccount && <div>
          <Caption variant="medium" weight="bold">{LUX_LOYALTY.PROGRAM_NAME}</Caption>
          <Caption variant="medium" format="titlecase">
            {LUX_LOYALTY_TIER_DISPLAY_NAMES[luxLoyaltyAccount.tier]}
          </Caption>
          {luxLoyaltyAccount.points > 0 && <Caption variant="medium">
            <Plural singular="Point" count={luxLoyaltyAccount.points} />
          </Caption>}
        </div>}
        {config.businessTraveller.currentAccountMode !== 'business' && config.header.standAloneAccount && (
          <ProductPaletteProvider palette={canRedeemLuxPlusBenefits ? 'lux-plus' : 'default'}>
            <TextButton
              kind="tertiary"
              to="/account"
              aria-label="Account"
              startIcon={<AvatarCircle
                size="small"
                firstName={firstName}
                lastName={lastName}
                hasNotification={hasNotification}
                nonInteractable
                notificationType={notificationType}
              />}
            >
              <Group direction="vertical" horizontalAlign="start">
                <Caption variant="medium" weight="bold">
                  {canRedeemLuxPlusBenefits ? LUXURY_PLUS.PROGRAM_NAME : 'Account'}
                </Caption>
                {balance > 0 &&
                  <Caption variant="medium">
                    <FormatCurrency value={balance} format="dynamicDollar" />
                  </Caption>
                }
              </Group>
            </TextButton>
          </ProductPaletteProvider>
        )}
      </>}

      {!isLoggedIn && (
        <TextButton
          kind="primary"
          onClick={showLogin}
          role="menuitem"
          name="signup"
        >
          Log in
        </TextButton>
      )}
    </Group>
  )
}

function mapStateToProps(state: App.State) {
  return {
    hasNotification: hasAccountNotification(state),
    balance: state.auth.account.balance,
    isFlightsCredit: isFlightsCredit(state),
    firstName: state.auth.account.givenName,
    lastName: state.auth.account.surname,
    canRedeemLuxPlusBenefits: checkCanRedeemLuxPlusBenefits(state),
    notificationType: getNotificationType(state),
    luxLoyaltyAccount: state.luxLoyalty.account.data,
    isLuxLoyaltyEnabled: isLuxLoyaltyEnabled(state),
  }
}

export default connect(mapStateToProps)(NavigationHeader)

import { PaymentScheduleActions } from 'actions/PaymentScheduleActions'
import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS, CLEAR_PAYMENT_SCHEDULE } from 'actions/actionConstants'
import { FETCH_PAYMENT_SCHEDULE } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialPaymentScheduleState: App.PaymentScheduleState = {
  eligible: false,
  payment_schedules: [],
}

const apiRequests = reducerSwitch<App.PaymentScheduleState>({
  [FETCH_PAYMENT_SCHEDULE]: (_) => ({
    eligible: false,
    payment_schedules: [],
  }),
})

const apiSuccesses = reducerSwitch<App.PaymentScheduleState>({
  [FETCH_PAYMENT_SCHEDULE]: (_, action) => ({
    eligible: action.data.eligible,
    payment_schedules: action.data.payment_schedules,
  }),
})

const apiFailures = reducerSwitch<App.PaymentScheduleState>({
  [FETCH_PAYMENT_SCHEDULE]: (_) => ({
    eligible: false,
    payment_schedules: [],
  }),
})

const paymentScheduleReducer = createReducer<App.PaymentScheduleState, PaymentScheduleActions>(initialPaymentScheduleState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CLEAR_PAYMENT_SCHEDULE]: () => initialPaymentScheduleState,
})

export default paymentScheduleReducer

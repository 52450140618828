import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCheckCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m14.72 8.79-4.29 4.3-1.65-1.65a.9992.9992 0 0 0-.721-.3488A1 1 0 0 0 7.37 12.85l2.35 2.36a1.0003 1.0003 0 0 0 .71.29 1.0003 1.0003 0 0 0 .7-.29l5-5a.9994.9994 0 0 0 .2189-1.0946A1.001 1.001 0 0 0 16.13 8.79a1 1 0 0 0-1.41 0ZM12 2a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm0 18a8.0005 8.0005 0 0 1-7.391-4.9385A8 8 0 1 1 12 20Z"/>
  </SvgIcon>
}

export default LineCheckCircleIcon

import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { shouldUseInsuranceMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'

export const getInsuranceTotals = createSelector(
  getInsuranceItemsView,
  shouldUseInsuranceMemberPrice,
  (viewWithStatus, checkoutWithDiscountedInsurance): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)

    const itemsWithMobileAppDiscount = definedItems.filter((item) => item.mobileAppDiscount?.amount)
    const mobileAppDiscountAmount = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, ({ mobileAppDiscount }) => mobileAppDiscount?.amount ?? 0) : 0
    const mobileAppDiscountPercentage = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, ({ mobileAppDiscount }) => mobileAppDiscount?.percentage ?? 0) / itemsWithMobileAppDiscount.length : 0

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView =>
          checkoutWithDiscountedInsurance ? (itemView.memberPrice ?? 0) : (itemView.price ?? 0),
        ),
        memberPrice: 0,
        value: sum(definedItems, itemView => (itemView.value ?? 0)),
        surcharge: 0,
        taxesAndFees: 0,
        ...(mobileAppDiscountAmount > 0 && {
          mobileAppDiscount: {
            amount: mobileAppDiscountAmount,
            percentage: mobileAppDiscountPercentage,
          },
        }),
      },
    }
  },
)

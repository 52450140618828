import { createSelector } from 'reselect'
import { SUPPORT_INDIAN_MARKET_EMAIL, SUPPORT_INDIAN_MARKET_PREFIX, SUPPORT_EMAIL_FOR_BRAND } from 'constants/support'
import { PRODUCT_TYPE_TOUR, PRODUCT_TYPE_ULTRALUX } from 'constants/offer'
import { ULTRA_LUX_TOUR } from 'constants/tours'
import { getPaymentTotalsByDate } from 'lib/payment/getPurchaseTotals'
import getCheckoutTotal from 'checkout/selectors/payment/getCheckoutTotal'
import memoize from 'lib/memoize/memoize'
import config from 'constants/config'
import { isSupportAssistantEnabled } from './featuresSelectors'
import { getCruiseItems } from 'checkout/selectors/cruiseSelectors'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { CONTACT_GENERAL_REGIONS_EXCLUDE_LIST, PRIORITY_SUPPORT_NUMBER_REGIONS, USE_MID_VALUE_CONTACT_TYPE_REGIONS, US_AMOUNT_REGIONS_LIST } from 'constants/config/region'
import { matchPath } from 'react-router'
import { getCurrentTourCheckoutStep } from 'components/App/Header/HeaderCheckout/StepsHeaderCheckout/steps/tours'

const existingOrderTotalCalculation = memoize((order: App.Order) => {
  const propertyFees = order.bedbankItems?.length > 0 ? order.bedbankItems[0].offer.packages[0].rate.totals.propertyFees : 0
  const { grandTotal, refundedTotal, creditsTotal } = getPaymentTotalsByDate(order)
  const total = (creditsTotal + grandTotal) - refundedTotal
  return total + (propertyFees ?? 0)
})

const HIGH_VALUE_CART_AMOUNT = 4999
const HIGH_VALUE_CART_AMOUNT_US = 3999
const MID_VALUE_CART_AMOUNT = 2999
const MID_VALUE_CART_AMOUNT_US = 1999

export interface SelectorParams {
  email?: string
}

const getLastViewedOffer = createSelector(
  (state: App.State) => state.recommendations.highIntent?.lastViewed?.offerId,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.tourV2Offers,
  (lastOfferId, offers, tourV2Offers): Tours.TourV2Offer | App.Offer | null => {
    if (!lastOfferId) return null

    return tourV2Offers[lastOfferId] || offers[lastOfferId]
  },
)

export const getSupportEmailForCurrentRegion = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (region) => region === SUPPORT_INDIAN_MARKET_PREFIX && config.BRAND === 'luxuryescapes' ?
    SUPPORT_INDIAN_MARKET_EMAIL :
    SUPPORT_EMAIL_FOR_BRAND[config.BRAND],
)

const getMyEscapesPageOrderId = createSelector(
  (state: App.State) => state.router.location,
  (location) => {
    const myEscapesMatch = matchPath<{ orderId: string}>(location.pathname, '/:regionCode/account/my-escapes/order/:orderId')
    if (myEscapesMatch) {
      return myEscapesMatch.params.orderId
    }

    const confirmationMatch = matchPath(location.pathname, '/:regionCode/booking/confirmation')
    if (confirmationMatch) {
      return new URLSearchParams(location.search).get('orderId') ?? ''
    }
    return ''
  },
)

export const getPrioritySupportContact = createSelector(
  getCheckoutTotal,
  (state: App.State) => state.orders.orders[getMyEscapesPageOrderId(state)],
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.geo.contacts,
  (state: App.State) => state.router.location,
  (state: App.State) => getLastViewedOffer(state), // last viewed offer will be current one for the offer page
  (state: App.State) => state.checkout.cart.postPurchase === 'change-dates' || state.checkout.cart.postPurchase === 'select-date',
  (state: App.State) => getCruiseItems(state).length > 0,
  (state: App.State) => isSupportAssistantEnabled(state),
  (state: App.State) => checkCanViewLuxPlusBenefits(state),
  (state: App.State) => !!getCurrentTourCheckoutStep(state),
  (
    grandTotal,
    existingOrder,
    currentRegionCode,
    contacts,
    location,
    currentOffer,
    isModifyBooking,
    isCruiseItem,
    supportAssistantEnabled,
    canViewLuxPlusBenefits,
    isTourCheckout,
  ): App.GeoContacts => {
    const isPurchasePage = location.pathname.includes('checkout') && location.pathname.includes('purchase')
    const isExistingOrder = location.pathname.includes('my-escapes/order') || location.pathname.includes('booking/confirmation')
    const existingOrderTotal = existingOrder ? existingOrderTotalCalculation(existingOrder) : 0
    const isHighValueOrder = isExistingOrder || isModifyBooking ? existingOrderTotal + grandTotal >= HIGH_VALUE_CART_AMOUNT : grandTotal >= HIGH_VALUE_CART_AMOUNT
    const isMidValueOrder = isExistingOrder || isModifyBooking ? existingOrderTotal + grandTotal >= MID_VALUE_CART_AMOUNT : grandTotal >= MID_VALUE_CART_AMOUNT
    const isToursVertical = location.pathname.match(/.*\/[a-z]{2}\/(?:search\/)?(tours)\??\.*/) !== null
    const isUltraLuxVertical = location.pathname.match(/.*\/[a-z]{2}\/(ultra-lux)\??\.*/) !== null
    const isCruiseVertical = location.pathname.match(/.*\/[a-z]{2}\/(cruises)\??\.*/) !== null
    const isCruiseCheckout = location.pathname.match(/.*\/[a-z]{2}\/checkout\/cruises\/?\.*/) !== null
    const isCruiseCabinLocation = location.pathname.match(/.*\/[a-z]{2}\/checkout\/cabin-location\/?\.*/) !== null
    const isCruiseDetails = location.pathname.match(/.*\/[a-z]{2}\/cruises\/\.*/) !== null
    const isTourDetails = location.pathname.match(/.*\/[a-z]{2}\/tour\/\.*/) !== null
    const isCruiseSetAsTour = currentOffer && currentOffer.productType === PRODUCT_TYPE_TOUR && currentOffer.holidayTypes?.includes('Cruises')
    const ultraLuxProductTypes = [ULTRA_LUX_TOUR, PRODUCT_TYPE_ULTRALUX]
    const isSupportPage = location.pathname.match(/.*\/[a-z]{2}\/(support)\??\.*/) !== null

    const getContactWithFallback = (contactType: App.GeoContactType) => {
      return contacts[contactType] || contacts.general
    }

    if (isSupportPage && supportAssistantEnabled) {
      return getContactWithFallback('supportAssistant')
    }

    if (!CONTACT_GENERAL_REGIONS_EXCLUDE_LIST.includes(currentRegionCode)) return contacts.general

    if (isToursVertical || isTourDetails || isTourCheckout) {
      return getContactWithFallback('tours')
    }

    if (isUltraLuxVertical) {
      return getContactWithFallback('ultraLux')
    }

    if (isCruiseVertical || isCruiseDetails || isCruiseSetAsTour || isCruiseCheckout || isCruiseCabinLocation || (isCruiseItem && isPurchasePage)) {
      return getContactWithFallback('cruises')
    }

    const highAmount = US_AMOUNT_REGIONS_LIST.includes(currentRegionCode) ? HIGH_VALUE_CART_AMOUNT_US : HIGH_VALUE_CART_AMOUNT
    const midValueAmount = US_AMOUNT_REGIONS_LIST.includes(currentRegionCode) ? MID_VALUE_CART_AMOUNT_US : MID_VALUE_CART_AMOUNT
    if (currentOffer && ((currentOffer as App.Offer).lowestPricePackage?.price ?? 0) > highAmount) {
      return getContactWithFallback('highValue')
    }

    if (currentOffer && ultraLuxProductTypes.includes(currentOffer.productType)) {
      return getContactWithFallback('ultraLux')
    }

    if (canViewLuxPlusBenefits) {
      return getContactWithFallback('luxPlus')
    }

    if (currentOffer && ((currentOffer as App.Offer).lowestPricePackage?.price ?? 0) > midValueAmount && USE_MID_VALUE_CONTACT_TYPE_REGIONS.includes(currentRegionCode)) {
      return getContactWithFallback('midValue')
    }

    if (isHighValueOrder) {
      return getContactWithFallback('highValue')
    }

    if (isMidValueOrder) {
      return getContactWithFallback('midValue')
    }

    return contacts.general
  },
)

export const getCustomerValue = createSelector(
  (state: App.State) => state.auth.customerValue,
  (state: App.State) => state.router.location,
  (customerValue, location): App.CustomerContactValueType | undefined => {
    const isAUOfferPage = location.pathname.match(/.*\/au\/(offer|tour|cruises|partner)\/\.*/) !== null
    return customerValue && isAUOfferPage ? customerValue : undefined
  },
)

export const getSupportContact = createSelector(
  (state: App.State) => state.geo.contacts,
  (state: App.State) => getPrioritySupportContact(state),
  (state: App.State) => state.router.location,
  (state: App.State) => isSupportAssistantEnabled(state),
  (state: App.State) => checkCanViewLuxPlusBenefits(state),
  (state: App.State) => getCustomerValue(state),
  (contacts, priorityContact, location, supportAssistantEnabled, canViewLuxPlusBenefits, customerValue): App.GeoContacts => {
    if (config.BRAND !== 'luxuryescapes') return contacts.general

    const isCheckoutV1Page = location.pathname.includes('booking')
    const isCheckoutV2Page = location.pathname.includes('checkout')
    const isCheckoutPage = isCheckoutV1Page || isCheckoutV2Page
    const isOfferPage = location.pathname.match(/.*\/[a-z]{2}\/(offer|tour|cruises|partner)\/\.*/) !== null
    const isToursOrUltraLuxPage = location.pathname.match(/.*\/[a-z]{2}\/(ultra-lux|tours|cruises)\??\.*/) !== null
    const isToursSearchPage = location.pathname.match(/.*\/[a-z]{2}\/(search)\/(tours)\??\.*/) !== null
    const isSupportAssistant = location.pathname.match(/.*\/[a-z]{2}\/(support)\??\.*/) !== null && supportAssistantEnabled
    if (customerValue) {
      return contacts[customerValue] ?? contacts.general
    }

    return (isOfferPage || isCheckoutPage || isToursOrUltraLuxPage || isToursSearchPage || isSupportAssistant || canViewLuxPlusBenefits) ? priorityContact : contacts.general
  },
)

export const getSupportContactNumber = createSelector(
  (state: App.State) => state.geo.currentContact.local.humanReadable,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => checkCanViewLuxPlusBenefits(state),
  (humanReadable, region, canViewLuxPlusBenefits): string => {
    if (PRIORITY_SUPPORT_NUMBER_REGIONS.includes(region)) {
      if (canViewLuxPlusBenefits) return config.PRIORITY_SUPPORT_NUMBER_LUXURY_PLUS_AU
      return config.PRIORITY_SUPPORT_NUMBER_HIGH_VALUE
    }

    return humanReadable
  },
)

export const getLuxPlusSupportContactNumber = createSelector(
  (state: App.State) => state.geo.contacts,
  (contacts) => {
    return {
      number: contacts.luxPlus?.defaultContact.number,
      numberHumanReadable: contacts.luxPlus?.defaultContact.humanReadable,
    }
  },
)

import React from 'react'
import styled from 'styled-components'
import ImageWithReservedSpace from 'components/Common/ImageWithReservedSpace'
import clsx from 'clsx'
import { rem } from 'polished'
import ResponsiveImage from '../ResponsiveImage'

const Root = styled.div`
  position: relative;
  color: ${props => props.theme.palette.neutral.default.eight};
  min-height: 1px;
  overflow: hidden;

  &.fixed-height {
    height: ${rem(500)};
    position: relative;

    img,
    picture {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface Props extends React.ComponentProps<typeof ImageWithReservedSpace> {
  fixedHeight?: boolean
}

const defaultRatios: Pick<Props, 'mobileAspectRatio' | 'tabletAspectRatio' | 'desktopAspectRatio' | 'largeDesktopAspectRatio'> = {
  mobileAspectRatio: '1',
  tabletAspectRatio: '1.6',
  desktopAspectRatio: '2.1',
  largeDesktopAspectRatio: '2.5',
}

function noRatiosGiven(props: Props) {
  return !props.mobileAspectRatio && !props.tabletAspectRatio && !props.desktopAspectRatio && !props.largeDesktopAspectRatio
}

function ImageHero(props: Props) {
  const { children, className, fixedHeight, ...imageProps } = props
  return (
    <Root className={clsx(className, { 'fixed-height': fixedHeight })}>
      {fixedHeight && <ResponsiveImage
        { ...imageProps}
        aria-hidden="true"
      />}
      {!fixedHeight && <div>
        <ImageWithReservedSpace
          aria-hidden="true"
          {...imageProps}
          {...(noRatiosGiven(props) ? defaultRatios : {})}
        />
      </div>}
      <Content>
        {children}
      </Content>
    </Root>
  )
}

export default React.memo(ImageHero)

import {
  CREATE_SEARCH,
} from 'actions/actionConstants'
import { createReducer } from 'lib/redux/reducerUtils'

export const initialSearchState: App.SearchState = {
  searches: {},
}

export default createReducer<App.SearchState>(initialSearchState, {
  [CREATE_SEARCH]: (state, action) => {
    const { key, searchId } = action

    return {
      searches: {
        ...state.searches,
        [key]: {
          searchId,
        },
      },
    }
  },
})

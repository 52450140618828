import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineLockAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 13a1.0001 1.0001 0 0 0-1 1v3a1.0001 1.0001 0 0 0 2 0v-3a1.0001 1.0001 0 0 0-1-1Zm5-4V7A5 5 0 0 0 7 7v2a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-7a3.0002 3.0002 0 0 0-3-3ZM9 7a3 3 0 1 1 6 0v2H9V7Zm9 12a1.0001 1.0001 0 0 1-1 1H7a1.0002 1.0002 0 0 1-1-1v-7a1 1 0 0 1 1-1h10a1.0001 1.0001 0 0 1 1 1v7Z"/>
  </SvgIcon>
}

export default LineLockAltIcon

import { useReducer } from 'react'
import flightSearchWidgetStateReducer, { FlightSearchWidgetState, initialState as FLIGHT_SEARCH_INITIAL_STATE } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'

function useFlightSearchContext(initialState: FlightSearchWidgetState = FLIGHT_SEARCH_INITIAL_STATE) {
  const [flightSearchState, flightSearchDispatch] = useReducer(flightSearchWidgetStateReducer, initialState)

  return { flightSearchState, flightSearchDispatch }
}

export default useFlightSearchContext

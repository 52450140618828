import ExperienceSearchCategoryResults from 'components/Experiences/ExperiencesSearch/ExperienceSearchBar/ExperienceSearchCategoryResults'
import { rem } from 'polished'
import React, { useId } from 'react'
import styled from 'styled-components'
import SearchFloatingPanel from './SearchFloatingPanel'
import noop from 'lib/function/noop'

const CategorySearchItems = styled(ExperienceSearchCategoryResults)`
  margin: 0 -${rem(12)};
`

interface Props {
  open?: boolean;
  onClose?: () => void;
}

function ExperienceCategoryPanel(props: Props) {
  const { open, onClose = noop } = props
  const formId = useId()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onClose()
  }

  return (
    <SearchFloatingPanel
      isOpen={open}
      onClose={onClose}
      onGoBack={onClose}
      onConfirm={onClose}
      title="What are you interested in?"
      confirmText="Apply"
    >
      <form onSubmit={onSubmit} id={formId}>
        <CategorySearchItems />
      </form>
    </SearchFloatingPanel>
  )
}

export default ExperienceCategoryPanel

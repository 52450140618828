import { useContext, useEffect, useMemo } from 'react'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import GlobalSearchState, { GlobalSearchStateActions, GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { SEARCH_VERTICALS } from 'constants/search'
import { useGlobalSearchResultsTracking } from '../useGlobalSearchTracking'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'
import { CRUISE_OFFER_TYPES } from 'constants/cruise'
import { SearchResult, SearchResultBucket } from 'api/interactionStudio'
import { useAppSelector } from 'hooks/reduxHooks'
import { getSearchItemFromURLSearchParams } from 'lib/url/searchUrlUtils'
import GeoContext from 'contexts/geoContext'
import config from 'constants/config'
import Places from 'constants/places'
import getOfferListKey from 'lib/offer/offerListKey'

export const popularDestinations: Array<App.SearchItem> = [
  {
    value: Places.Anywhere.id,
    format: {
      mainText: 'All destinations',
    },
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'Australia',
    },
    value: 'le_d3d9446802a44259755d38e6d163e820',
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'New Zealand',
    },
    value: 'le_9fc3d7152ba9336a670e36d0ed79bc43',
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'Hawaii',
    },
    value: 'le_979d472a84804b9f647bc185a877a8b5',
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'Spain',
    },
    value: 'le_7e7757b1e12abcb736ab9a754ffb617a',
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'Italy',
    },
    value: 'le_93db85ed909c13838ff95ccfa94cebd9',
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'Singapore',
    },
    value: 'le_bd4c9ab730f5513206b999ec0d90d1fb',
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'United States of America',
    },
    value: 'le_757b505cfd34c64c85ca5b5690ee5293',
    searchType: 'destination',
  },
  {
    format: {
      mainText: 'Fiji',
    },
    value: 'le_72b32a1f754ba1c09b3695e0cb6cde7f',
    searchType: 'destination',
  },
]

export function useGlobalCruisesSearchContext(
  initialState: GlobalSearchState = GLOBAL_SEARCH_INITIAL_STATE,
  syncWithURLSearchParams?: URLSearchParams,
) {
  const queryParams = useMemo(() => {
    if (syncWithURLSearchParams) {
      return {
        searchItem: getSearchItemFromURLSearchParams(syncWithURLSearchParams),
      }
    }
    return undefined
  }, [syncWithURLSearchParams])

  const { globalSearchState, globalSearchDispatch } = useGlobalSearchContext({
    ...initialState,
    popularDestinations,
    isLoadingPopularDestinations: false,
    searchVerticals: new Set([SEARCH_VERTICALS.CRUISES]),
  })

  // sync search item
  useEffect(() => {
    if (queryParams) {
      const { searchItem } = queryParams
      if (searchItem) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_SEARCH_ITEM })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.searchItem])

  return {
    globalCruisesSearchDispatch: globalSearchDispatch,
    globalCruisesSearchState: globalSearchState,
  }
}

export function useGlobalCruisesSearchResultsTracking(
  globalCruisesSearchState: GlobalSearchState,
  cruisesIds: Array<string>,
  areResultsFetching: boolean,
) {
  const filters = useAppSelector(getUrlOfferListFilters)
  const searchParams = { ...filters, offerTypes: CRUISE_OFFER_TYPES }
  const offerListKey = getOfferListKey(searchParams)

  const trackableResults = useMemo<Array<SearchResult>>(() => {
    const results: Array<SearchResult> = cruisesIds.map((id) => {
      const kind = SearchResultBucket.CRUISE_TRIP

      return { id, kind }
    })

    return results
  }, [cruisesIds])

  useGlobalSearchResultsTracking(
    { ...globalCruisesSearchState, occupancies: undefined, isFlexibleDateSelected: undefined },
    offerListKey,
    trackableResults,
    areResultsFetching,
  )
}

export const useCruiseSearchEnabled = () => {
  const { currentRegionCode } = useContext(GeoContext)
  return useMemo(() =>
    config.CRUISES_REGIONS?.split(',').includes(currentRegionCode),
  [currentRegionCode])
}

import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import React from 'react'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import HeroTourOfferTilePricing from '../Support/HeroTourOfferTilePricing'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import TextLink from 'components/Luxkit/TextLink'
import Group from 'components/utils/Group'
import TourV2UrgencyTags from 'components/Tours/TourV2UrgencyTags'
import useTourV2Details from 'hooks/TourV2/useTourV2Details'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'

const DetailContainer = styled(Group)`
  height: 100%;
  padding-top: ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)} ${rem(32)} ${rem(32)} ${rem(32)};
  }
`

const DetailSegment = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: ${rem(8)};
`

const DetailsCaptionContainer = styled(BodyText)`
  display: flex;

  > * + *::before {
    color: ${props => props.theme.palette.neutral.default.one};
    content: '·';
    margin: 0 ${rem(4)};
    speak-as: bullets;
    speak: none;
  }
`

const HeaderActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`

const MinHeightGroup = styled(Group)`
  min-height: ${rem(150)};
  flex-grow: 1;
`

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
}

function HeroTourOfferDetails(props: Props) {
  const { offer } = props
  const { cheapestVariation } = useCheapestOfTourV2Offer(offer)
  const {
    startLocation,
    endLocation,
    hasTheSameStartAndEndLocation,
    placesCount,
    countriesCount,
    durationInDaysCount,
  } = useTourV2VariationDetails(cheapestVariation)

  const { hasMoreVariations, hasAlternativeRoute, upgradesAvailable } = useTourV2Details(offer)

  return (
    <DetailContainer direction="vertical" gap={16}>
      <CSSBreakpoint min="desktop">
        <Group direction="horizontal" horizontalAlign="space-between">
          {isOfferRatingDisplayable(offer.rating) && <OfferRating variant="medium" rating={offer.rating} />}
          <TripGuard>
            <HeaderActions>
              <BookmarkButton offer={offer} />
            </HeaderActions>
          </TripGuard>
        </Group>
      </CSSBreakpoint>
      <DetailSegment data-testid="OfferInfoBar">
        <MinHeightGroup direction="vertical" gap={4} horizontalAlign="start">
          <TourV2UrgencyTags offer={offer}/>
          <Caption variant="large" lineClamp={3} colour="neutral-one">
            {hasTheSameStartAndEndLocation && <>Starts and ends in {endLocation}{' '}</>}
            {!hasTheSameStartAndEndLocation && <>Starts in {startLocation}, Ends in {endLocation}{' '}</>}
          </Caption>
          <Heading variant="heading6" lineClamp={3} colour="neutral-one">{offer.name}</Heading>
          <Caption variant="large" lineClamp={1} weight="bold">{offer.brand.name}</Caption>
          {isOfferRatingDisplayable(offer.rating) && <CSSBreakpoint max="tablet">
            <OfferRating variant="xs" rating={offer.rating} />
          </CSSBreakpoint>}
          <DetailsCaptionContainer variant="medium" colour="neutral-one">
            <div>{durationInDaysCount}</div>
            {placesCount && <div>{placesCount}</div>}
            {countriesCount && <div>{countriesCount}</div>}
          </DetailsCaptionContainer>
          {hasAlternativeRoute && <TextLink size="medium">
            Alternative route available
          </TextLink>}
          {!hasAlternativeRoute && hasMoreVariations && <TextLink size="medium">
            More options available
          </TextLink>}
          {!hasMoreVariations && upgradesAvailable && <TextLink size="medium">
            Upgrades available
          </TextLink>}
        </MinHeightGroup>
        <Group direction="vertical" gap={8}>
          <HeroTourOfferTilePricing offer={offer} />
        </Group>
      </DetailSegment>
    </DetailContainer>
  )
}

export default HeroTourOfferDetails

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGlassMartiniIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 20h-5v-5.06A9.0002 9.0002 0 0 0 21 6a8.7215 8.7215 0 0 0-.67-3.39 1.0003 1.0003 0 0 0-.22-.32L20 2.21a.9218.9218 0 0 0-.21-.13.94.94 0 0 0-.28-.08H4.5a.9399.9399 0 0 0-.29.06A2.1197 2.1197 0 0 0 4 2.2l-.12.09a1 1 0 0 0-.22.32A8.7201 8.7201 0 0 0 3 6a8.9998 8.9998 0 0 0 8 8.94V20H6a1.0001 1.0001 0 0 0-.7071 1.7071 1 1 0 0 0 .707.2929H18a1.0003 1.0003 0 0 0 1-1 1.0001 1.0001 0 0 0-1-1ZM5 6a6.91 6.91 0 0 1 .29-2h13.42A6.9144 6.9144 0 0 1 19 6a6.9997 6.9997 0 0 1-2.0503 4.9497 6.9997 6.9997 0 0 1-9.8995 0A6.9998 6.9998 0 0 1 5 6Z"/>
  </SvgIcon>
}

export default LineGlassMartiniIcon

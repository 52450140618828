import { StandaloneFlightMenu } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { FlightsFareTypes } from 'constants/flight'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
interface Props {
  className?: string;
  id: string;
  departingFieldRef?: MutableRefObject<HTMLButtonElement | null> | ((node: HTMLButtonElement | null) => void);
  arrivalFieldRef?: | MutableRefObject<HTMLButtonElement | null> | ((node: HTMLButtonElement | null) => void);
  onDepartingClick?: () => void;
  onArrivalClick?: () => void;
}

function FlightSearchDateSelect(props: Props) {
  const {
    id,
    departingFieldRef,
    arrivalFieldRef,
    onArrivalClick,
  } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const currentFlight = useMemo(() => {
    return state.flights.find((flight) => flight.id === id)
  }, [state.flights, id])

  const { checkinDate, checkoutDate } = currentFlight!

  const { fareType } = state

  const setActiveMenu = useCallback(
    (activeMenu?: StandaloneFlightMenu, id?: string) => {
      dispatch({
        type: FlightSearchWidgetActions.SET_ACTIVE_MENU, id, activeMenu,
      })
    },
    [dispatch],
  )

  const handleDepartingClick = useCallback(() => {
    setActiveMenu(StandaloneFlightMenu.date, id)
  }, [setActiveMenu, id])

  const handleReturningClick = useCallback(() => {
    setActiveMenu(StandaloneFlightMenu.date, id)
    onArrivalClick?.()
  }, [setActiveMenu, onArrivalClick, id])

  useEffect(() => {
    if (fareType.value === FlightsFareTypes.ONE_WAY) {
      dispatch({
        type: FlightSearchWidgetActions.SET_CHECKOUT_DATE,
        date: null,
      })
    }
  }, [dispatch, fareType.value])

  return (
    <SearchFormFieldGroup>
      <SearchFormField
        ref={departingFieldRef}
        label="Departing"
        placeholder="Select date"
        required
        requiredErrorMessage="Please select travel dates"
        value={checkinDate?.format(DMY_CASUAL_FORMAT)}
        onClick={handleDepartingClick}
        name={`departing-${id}`}
      />

      {fareType.value === FlightsFareTypes.RETURN && (
        <SearchFormField
          ref={arrivalFieldRef}
          label="Returning"
          placeholder="Select date"
          required
          requiredErrorMessage="Please select travel dates"
          value={checkoutDate?.format(DMY_CASUAL_FORMAT)}
          onClick={handleReturningClick}
          name={`returning-${id}`}
        />
      )}
    </SearchFormFieldGroup>
  )
}

export default FlightSearchDateSelect

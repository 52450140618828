import React, { ComponentProps, useCallback } from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import styled from 'styled-components'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import Modal from 'components/Luxkit/Modal/Modal'
import TourV2ItemInclusions from 'components/OfferPage/TourV2/TourV2ItemInclusions'
import Group from 'components/utils/Group'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

const CaptionContainer = styled(Group)`
  > * + *::before {
    content: '·';
    margin: 0 ${rem(4)};
    speak-as: bullets;
    speak: none;
  }
`

type InheritedModalProps = Pick<ComponentProps<typeof Modal>, 'primaryActionText' | 'primaryActionTo'>

interface Props extends InheritedModalProps {
  variation: Tours.TourV2OfferVariation
}

export type TourV2AllInclusionsModalResult = 'primary-action' | undefined

function TourV2AllInclusionsModal({
  variation,
  primaryActionText,
  primaryActionTo,
}: Props) {
  const modalElement = useModalElementContext<TourV2AllInclusionsModalResult>()

  const handlePrimaryClick = useCallback(() => {
    modalElement.resolve('primary-action')
  }, [modalElement])

  const {
    durationInDaysCount,
    countriesCount,
    placesCount,
    hasTheSameStartAndEndLocation,
    startLocation,
    endLocation,
    inclusionItems,
  } = useTourV2VariationDetails(variation)

  return <Modal
    title={variation.name}
    subtitle={<CaptionContainer direction="horizontal" verticalAlign="center" wrap="wrap">
      <BodyText variant="medium" colour="neutral-two">
        {hasTheSameStartAndEndLocation && <span>Starts and ends in <b>{endLocation}</b></span>}
        {!hasTheSameStartAndEndLocation && <span>Starts in <b>{startLocation}</b>. Ends in <b>{endLocation}</b></span>}
      </BodyText>
      <BodyText variant="medium">{durationInDaysCount}</BodyText>
      {placesCount && <BodyText variant="medium">{placesCount}</BodyText>}
      {countriesCount && <BodyText variant="medium">{countriesCount}</BodyText>}
    </CaptionContainer>}
    primaryActionText={primaryActionText}
    primaryActionTo={primaryActionTo}
    onPrimaryActionClick={!primaryActionTo ? handlePrimaryClick : undefined}
  >
    <Group direction="vertical" gap={8}>
      <BodyText weight="bold" variant="large">Highlights included</BodyText>
      {inclusionItems && <TourV2ItemInclusions inclusions={inclusionItems} gap={4} />}
    </Group>
  </Modal>
}

export default TourV2AllInclusionsModal

import React, { useEffect, useRef } from 'react'

export default function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: (e: MouseEvent) => void,
  enabled: boolean = true,
) {
  const callbackRef = useRef<any>(null)

  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    if (enabled) {
      const handleClick = (e: MouseEvent) => {
        if (
          e.target &&
          // if they clicked inside the element, don't trigger
          !ref.current?.contains(e.target as HTMLElement) &&
          // make sure our element we're detecting was actually visible
          // can't "click outside" an invisible element
          // note: not supported by safari
          (ref.current?.checkVisibility?.() ?? true)
        ) {
          callbackRef.current(e)
        }
      }

      window.addEventListener('click', handleClick, true)

      return () => {
        window.removeEventListener('click', handleClick, true)
      }
    }
  }, [ref, enabled])
}

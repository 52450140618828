import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getOfferById } from 'api/offer'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { isBedbankOffer } from 'lib/offer/offerTypes'
import moment from 'moment'

async function getBedbankHotelOrderItems(state: App.State, order: App.Order) {
  return await Promise.all(order.bedbankItems.map(async bedbankItem => {
    let fullOffer = state.offer.offers[bedbankItem.offer.id]
    if (!fullOffer) {
      fullOffer = await getOfferById(bedbankItem.offer.id, { region: order.regionCode, currentCurrency: order.currencyCode }) as App.Offer
    }
    const { categoryId, productId } = getCategoryAndProductId(bedbankItem.offer.parentType, fullOffer)
    const offerLeadPrice = order.hasFlight ? fullOffer.lowestPriceBundlePackage?.price : fullOffer.lowestPricePackage?.price
    const geo = fullOffer.property?.geoData
    let destinationCity = geo?.locality
    let destinationCountry = geo?.country
    let destinationRegion = geo?.administrativeAreaLevel1
    if (!geo && isBedbankOffer(fullOffer)) {
      const bedbankOffer = fullOffer as App.BedbankOffer | App.BedbankOfferSummary
      destinationCity = bedbankOffer.property.address.city
      destinationCountry = bedbankOffer.property.address.countryName
      destinationRegion = bedbankOffer.property.address.stateProvinceName
    }
    return comluxgroup.createItem_1_1_0(
      {
        offerId: bedbankItem.offer.id,
        categoryId,
        productId,
        duration: bedbankItem.duration || moment(bedbankItem.checkOut).diff(bedbankItem.checkIn, 'days'),
        travelStart: bedbankItem.checkIn.format(ISO_DATE_FORMAT),
        travelEnd: bedbankItem.checkOut.format(ISO_DATE_FORMAT),
        price: bedbankItem.total,
        offerLeadPrice,
        propertyFees: bedbankItem.propertyFees,
        offerLeadDuration: fullOffer.lowestPricePackage?.duration ? String(fullOffer.lowestPricePackage?.duration) : undefined,
        offerLeadUnit: fullOffer.saleUnit,
        currency: order.currencyCode,
        destinationCity,
        destinationCountry,
        destinationRegion,
        itemId: bedbankItem.orderId,
        bookingNumber: order.bookingNumber,
        orderId: order.id,
      },
    )
  }))
}

export default getBedbankHotelOrderItems

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMultiplyIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m13.41 12 6.3-6.29a1.0041 1.0041 0 1 0-1.42-1.42L12 10.59l-6.29-6.3a1.0041 1.0041 0 0 0-1.42 1.42l6.3 6.29-6.3 6.29a1.001 1.001 0 0 0-.2958.71 1.0003 1.0003 0 0 0 .2958.71 1.001 1.001 0 0 0 .71.2958.9994.9994 0 0 0 .71-.2958l6.29-6.3 6.29 6.3a.9997.9997 0 0 0 1.42 0 .9997.9997 0 0 0 0-1.42L13.41 12Z"/>
  </SvgIcon>
}

export default LineMultiplyIcon

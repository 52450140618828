import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

import {
  DELETE_REVIEW,
  FETCH_REVIEW_SUMMARY,
  FETCH_REVIEWS,
  FETCH_REVIEWS_ORDER,
  UPLOAD_REVIEW_PHOTO,
  UPSERT_REVIEW,
} from 'actions/apiActionConstants'

import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  USER_REVIEW_SET_PHOTO_UPLOAD_PERCENTAGE,
  USER_REVIEW_SET_REVIEW_STAGE,
} from 'actions/actionConstants'

export const initialReviewState: App.ReviewState = {
  reviews: [],
  reviewStage: 'initial',
  isReviewDeleted: false,
  totalReviews: 0,
  fetchingReviews: false,
  fetchingReviewCreate: false,
  reviewCreateError: undefined,
  reviewDeleteError: undefined,
  reviewsByOrder: {},
  reviewsByOrderLoading: {},
  reviewPhotosUpload: {},
  fetchingReviewSummary: false,
  reviewSummary: undefined,
}

const apiRequests = reducerSwitch<App.ReviewState>({
  [FETCH_REVIEWS]: () => ({
    fetchingReviews: true,
  }),
  [FETCH_REVIEWS_ORDER]: (state, action) => ({
    isReviewDeleted: false,
    reviewsByOrderLoading: {
      ...state.reviewsByOrderLoading,
      [action.orderId]: true,
    },
  }),
  [UPSERT_REVIEW]: () => ({
    fetchingReviewCreate: true,
    reviewCreateError: undefined,
    isReviewDeleted: false,
  }),
  [DELETE_REVIEW]: (state, action) => ({
    isReviewDeleted: false,
    reviewDeleteError: undefined,
    reviewsByOrderLoading: {
      ...state.reviewsByOrderLoading,
      [action.orderId]: true,
    },
  }),
  [UPLOAD_REVIEW_PHOTO]: (state, action) => ({
    reviewPhotosUpload: {
      ...state.reviewPhotosUpload,
      [action.uploadId]: {
        isUploading: true,
        error: undefined,
      },
    },
  }),
  [FETCH_REVIEW_SUMMARY]: () => ({
    fetchingReviewSummary: true,
    reviewSummary: undefined,
  }),
})

const apiSuccesses = reducerSwitch<App.ReviewState>({
  [FETCH_REVIEWS]: (_, action) => {
    return {
      fetchingReviews: false,
      reviews: action.data.reviews,
      totalReviews: action.data.total,
    } },
  [FETCH_REVIEWS_ORDER]: (state, action) => ({
    reviewsByOrder: {
      ...state.reviewsByOrder,
      [action.orderId]: action.data.reviews,
    },
    reviewsByOrderLoading: {
      ...state.reviewsByOrderLoading,
      [action.orderId]: false,
    },
  }),
  [UPSERT_REVIEW]: (state, action) => ({
    reviewCreateError: undefined,
    fetchingReviewCreate: false,
    reviewStage: action.reviewStage ? action.reviewStage : state.reviewStage,
    reviewsByOrder: {
      ...state.reviewsByOrder,
      [action.data.orderId]: [action.data], // TODO improve when there's more then one review per order
    },
  }),
  [DELETE_REVIEW]: (state, action) => {
    const newReviewsByOrder = { ...state.reviewsByOrder }
    const newReviewsByOrderLoading = { ...state.reviewsByOrderLoading }

    delete newReviewsByOrder[action.orderId]
    delete newReviewsByOrderLoading[action.orderId]

    return {
      reviewsByOrder: newReviewsByOrder,
      reviewsByOrderLoading: newReviewsByOrderLoading,
      isReviewDeleted: true,
    }
  },
  [UPLOAD_REVIEW_PHOTO]: (state, action) => ({
    reviewPhotosUpload: {
      ...state.reviewPhotosUpload,
      [action.uploadId]: {
        ...state.reviewPhotosUpload[action.uploadId],
        isUploading: false,
        imageId: action.data.imageId,
      },
    },
  }),
  [FETCH_REVIEW_SUMMARY]: (_, action) => ({
    fetchingReviewSummary: false,
    reviewSummary: action.data.result,
  }),
})

const apiFailures = reducerSwitch<App.ReviewState>({
  [FETCH_REVIEWS]: () => ({
    fetchingReviews: false,
  }),
  [FETCH_REVIEWS_ORDER]: (state, action) => ({
    reviewsByOrderLoading: {
      ...state.reviewsByOrderLoading,
      [action.orderId]: false,
    },
  }),
  [UPSERT_REVIEW]: (_, action) => ({
    fetchingReviewCreate: false,
    reviewCreateError: action.error,
  }),
  [DELETE_REVIEW]: (state, action) => ({
    isReviewDeleted: false,
    reviewDeleteError: action.error,
    reviewsByOrderLoading: {
      ...state.reviewsByOrderLoading,
      [action.orderId]: false,
    },
  }),
  [UPLOAD_REVIEW_PHOTO]: (state, action) => {
    return ({
      reviewPhotosUpload: {
        ...state.reviewPhotosUpload,
        [action.uploadId]: {
          ...state.reviewPhotosUpload[action.uploadId],
          isUploading: false,
          error: action.error,
        },
      },
    })
  },
  [FETCH_REVIEW_SUMMARY]: () => ({
    fetchingReviewSummary: false,
  }),
})

export default createReducer<App.ReviewState>(initialReviewState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [USER_REVIEW_SET_PHOTO_UPLOAD_PERCENTAGE]: (state, action) => ({
    reviewPhotosUpload: {
      ...state.reviewPhotosUpload,
      [action.uploadId]: {
        ...state.reviewPhotosUpload[action.uploadId],
        percentage: action.percentage,
      },
    },
  }),
  [USER_REVIEW_SET_REVIEW_STAGE]: (_, action) => ({
    reviewStage: action.reviewStage,
  }),
})

import React from 'react'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { isLuxLoyaltyEnabled } from 'luxLoyalty/selectors/featureToggles'
import { connect } from 'react-redux'
import PriceRowLuxLoyaltyPoints from 'components/Luxkit/PricePoints/LuxLoyalty/PriceRowLuxLoyaltyPoints'
import { getLuxLoyaltyDefaultTier } from 'luxLoyalty/selectors/utils'

interface MappedProps {
  luxLoyaltyEnabled: boolean;
  loyaltyAccount?: App.LuxLoyaltyAccount;
  defaultTier: App.LuxLoyaltyTier;
}

interface Props extends MappedProps {
  pointsCalculation: App.LuxLoyaltyPointsCalculator[string];
  /**
   * @default the base or starting program tier.
   * The user's LuxLoyalty account tier will be applied if it exits.
   * If needed, the tier can be overridden by passing a different value (e.g. for tier upgrades).
   */
  tier?: React.ComponentProps<typeof PriceRowLuxLoyaltyPoints>['tier'];
  /**
   * @default 'S'
   */
  size?: React.ComponentProps<typeof PriceRowLuxLoyaltyPoints>['size'];
}

function LuxLoyaltyPoints(props: Props) {
  const {
    luxLoyaltyEnabled,
    loyaltyAccount,
    pointsCalculation,
    size = 'S',
    tier,
    defaultTier,
  } = props

  const fetching = !!pointsCalculation?.fetching
  const err = pointsCalculation?.error
  const points = pointsCalculation?.data?.points ?? 0

  if (!luxLoyaltyEnabled) return null
  if (fetching) {
    return <TextLoadingBox
      width="10ch"
      lines={1}
      typography="body-large"
    />
  }
  if (err || (!fetching && !points)) return null
  return <PriceRowLuxLoyaltyPoints
    tier={tier ?? loyaltyAccount?.tier ?? defaultTier}
    earnablePoints={points}
    size={size}
  />
}

const mapStateToProps = (state: App.State): MappedProps => ({
  luxLoyaltyEnabled: isLuxLoyaltyEnabled(state),
  loyaltyAccount: state.luxLoyalty.account.data,
  defaultTier: getLuxLoyaltyDefaultTier(state),
})

export default connect(mapStateToProps)(LuxLoyaltyPoints)

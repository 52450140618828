import GeoContext from 'contexts/geoContext'
import { addRegionToPath } from 'lib/url/searchUrlUtils'
import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import styled from 'styled-components'

/*
  This component is to be used as a 'wrapper' for things that are clickable
  and link to another URL without imparting any styles to it.
*/
const ClickableElement = styled.a`
  display: block;
`

const clickMoveTolerance = 3

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, Omit<React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>, 'type'> {
  to?: string;
  preventSwipeClick?: boolean;
  activeClassName?: string;
}

const ClickableLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
  const {
    to,
    onMouseDown,
    onClick,
    preventSwipeClick,
    target,
    rel,
    ...rest
  } = props

  const lastMouseDown = useRef({ x: -clickMoveTolerance, y: -clickMoveTolerance })
  const { currentRegionCode } = useContext(GeoContext)

  const linkTo = useMemo(() => addRegionToPath(to ?? '', currentRegionCode), [currentRegionCode, to])

  const onLinkClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!e.defaultPrevented) {
      if (preventSwipeClick) {
        const mouseMovementX = Math.abs(e.screenX - lastMouseDown.current.x)
        const mouseMovementY = Math.abs(e.screenY - lastMouseDown.current.y)
        if (mouseMovementX > clickMoveTolerance || mouseMovementY > clickMoveTolerance) {
          e.preventDefault()
          return
        }
      }
      onClick?.(e)
    }
  }, [onClick, preventSwipeClick])

  const onLinkMouseDown = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    onMouseDown?.(e)

    lastMouseDown.current = { x: e.screenX, y: e.screenY }
  }, [onMouseDown])

  return <ClickableElement
    {...rest}
    onClick={onLinkClick}
    onMouseDown={preventSwipeClick ? onLinkMouseDown : onMouseDown}
    as={to ? NavHashLink : undefined}
    to={linkTo}
    target={target ?? undefined}
    rel={rel ?? (target === '_blank' ? 'noopener' : undefined)}
    ref={ref}
  />
})

ClickableLink.displayName = 'ClickableLink'
export default React.memo(ClickableLink)

import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import TextLink from 'components/Luxkit/TextLink'
import ModalContext from 'contexts/ModalContext'
import TourExperienceModal from 'components/OfferPage/TourV2/Itinerary/TourExperienceModal'
import { OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import { withOfferPageAndReduxState } from 'contexts/OfferPage/withOfferPageState'
import { sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { TOUR_V2_DEFAULT_OCCUPANCIES } from 'constants/tours'

const Root = styled(Group)`
  padding: ${rem(20)};
  border: 1px solid ${props => props.theme.palette.neutral.default.six};
`

interface MappedProps {
  selectedOccupancies?: Array<App.Occupants>
}

interface Props {
  tourExperience: Tours.TourExperience
  title: string
}

function TourItineraryExperienceCard(props: Props & MappedProps) {
  const { tourExperience, title, selectedOccupancies = TOUR_V2_DEFAULT_OCCUPANCIES } = props
  const countOccupants = useMemo(() => sumUpOccupancies(selectedOccupancies), [selectedOccupancies])

  const showModal = useContext(ModalContext)
  const toggleModal = useCallback(() => {
    showModal(<TourExperienceModal tourExperience={tourExperience} title={title} countOccupants={countOccupants} />)
  }, [showModal, tourExperience, title, countOccupants])

  return <Root direction="horizontal" horizontalAlign="space-between" gap={16} wrap="wrap">
    <Heading variant="heading6">{tourExperience.name}</Heading>
    <Group fullWidth direction="horizontal" horizontalAlign="space-between">
      <PriceRowPrice size="S" price={tourExperience.price} saleUnit="person" />
      <TextLink size="small" onClick={toggleModal}>View details</TextLink>
    </Group>
  </Root>
}

const mapOfferPageStateToProps = (offerPageState: OfferPageState): MappedProps => {
  return {
    selectedOccupancies: offerPageState?.rooms,
  }
}

export default withOfferPageAndReduxState(mapOfferPageStateToProps)(TourItineraryExperienceCard)

import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const PromptIcon = styled.svg`
  width: ${rem(98)};
  height: ${rem(80)};
`

const Circle = styled.circle`
  fill: ${props => props.theme.palette.highlight.primary.lightBackground};
`

const StarsPath = styled.path`
  fill: ${props => props.theme.palette.highlight.primary.normalForeground};
`

const TagPath = styled.path`
  fill: ${props => props.theme.palette.neutral.default.eight};
  stroke: ${props => props.theme.palette.neutral.default.two};

`

function AccountAccessPromptIcon() {
  return <PromptIcon fill="none" viewBox="0 0 98 80">
    <Circle cx="49" cy="40" r="40" />
    <StarsPath d="m69 40.7-18-18a2 2 0 0 0-1.5-.7H30.1c-1.2 0-2.1.9-2.1 2.1v19.4c0 .6.2 1 .6 1.5l18.1 18a6.2 6.2 0 0 0 9 0l13.4-13.3a6.2 6.2 0 0 0 0-9ZM39.9 37a3.3 3.3 0 0 1-3.2-3.2c0-1.7 1.5-3.2 3.2-3.2 1.7 0 3.3 1.5 3.3 3.2 0 1.7-1.6 3.3-3.3 3.3Z"/>
    <TagPath d="M56 57.5c-1.7 2-4.9 2-6.9 0L32.3 40.7l-.2-.3-.1-.4V22c0-.4.1-.6.3-.7 0-.2.3-.3.7-.3h18.4l.3.3 16.8 16.8a4.8 4.8 0 0 1 0 7L56 57.5ZM38 31a4 4 0 0 0 4 4 4 4 0 0 0 4-4 4 4 0 0 0-4-4 4 4 0 0 0-4 4Z"/>
    <StarsPath d="M31.3 68.4c.1-.4-.2-.9-.6-.9l-4.8-.7-2.1-4.3c-.1-.2-.2-.3-.4-.3-.4-.3-.9-.1-1.1.3l-2.1 4.3-4.8.7c-.2 0-.4 0-.5.3-.3.3-.3.8 0 1.1l3.4 3.3-.8 4.8v.5c.3.4.8.6 1.3.3l4.2-2.2 4.3 2.2.4.1h.1c.5 0 .8-.5.7-1l-.8-4.7 3.4-3.4c.2 0 .2-.2.2-.4ZM83.3 11.4c.1-.4-.2-.9-.6-.9l-4.8-.7-2.1-4.3c-.1-.2-.2-.3-.4-.3-.4-.3-.9-.1-1.1.3l-2.1 4.3-4.8.7c-.2 0-.4 0-.5.3-.3.3-.3.8 0 1.1l3.4 3.3-.8 4.8v.5c.3.4.8.6 1.3.3l4.2-2.2 4.3 2.2.4.1h.1c.5 0 .8-.5.7-1l-.8-4.7 3.4-3.4c.2 0 .2-.2.2-.4ZM97 16c0-.2-.2-.5-.4-.5l-2.8-.4-1.3-2.6-.3-.2c-.2-.2-.5 0-.7.2l-1.2 2.6-2.8.4c-.2 0-.3 0-.3.2-.3.2-.3.4 0 .7l2 2-.5 2.8v.3c.2.3.5.4.8.2l2.5-1.3 2.5 1.3h.4c.3 0 .4-.3.4-.6l-.5-2.8 2-2c.2 0 .2-.2.2-.3ZM90.3 3.8c0-.2-.2-.4-.3-.4L87.8 3l-1-2-.1-.1c-.2-.1-.4 0-.5.1l-1 2-2.1.3h-.2c-.2.2-.2.5 0 .6l1.5 1.5-.4 2.1V8c.2.2.4.2.6.1l1.9-1 2 1h.1c.3 0 .4-.2.4-.4l-.4-2.1L90.1 4l.2-.2ZM8.3 65.8c0-.2-.2-.4-.4-.4L6 65l-1-2-.2-.1c-.2-.1-.4 0-.5.1l-1 2-2.1.3H.9c-.2.2-.2.5 0 .6l1.5 1.5-.4 2.2v.2c.2.2.4.2.6.1l1.9-1 2 1h.1c.3 0 .4-.2.4-.4l-.4-2.1L8.1 66l.2-.2ZM19 55c0-.2-.1-.5-.4-.5l-2.9-.4-1.3-2.6-.2-.2c-.2-.1-.5 0-.6.2l-1.3 2.6-2.9.4-.3.1c-.2.3-.2.5 0 .8l2.1 2-.5 2.8v.3c.2.3.5.4.8.2l2.5-1.4 2.6 1.4H16.9c.3 0 .5-.3.4-.6l-.5-2.8 2-2c.1 0 .2-.1.2-.3Z" />
  </PromptIcon>
}

export default AccountAccessPromptIcon

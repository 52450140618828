/**
 * "Search" style tile. Not that it isn't just used on the search results page,
 * but also search-ish places such as destination product pages.
 */

import * as Analytics from 'analytics/analytics'
import { getItemContextForOffer, SnowplowClientEventParams } from 'analytics/snowplow/events'
import classnames from 'clsx'
import Clickable from 'components/Common/Clickable'
import BedbankSearchTile from 'components/SearchKit/SearchTiles/accommodations/BedbankSearchTile'
import HotelBundleSearchTile from 'components/SearchKit/SearchTiles/accommodations/HotelBundleSearchTile'
import HotelSearchTile from 'components/SearchKit/SearchTiles/accommodations/HotelSearchTile'
import VillaSearchTile from 'components/SearchKit/SearchTiles/accommodations/VillaSearchTile'
import CruiseSearchTile from 'components/SearchKit/SearchTiles/cruises/CruiseSearchTile'
import TourV2SearchTile from 'components/SearchKit/SearchTiles/tours/TourV2SearchTile'
import config from 'constants/config'
import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL,
} from 'constants/offer'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import { useAppSelector } from 'hooks/reduxHooks'
import noop from 'lib/function/noop'
import getOfferListKey from 'lib/offer/offerListKey'
import { isBedbankOffer, isBundleOffer, isCruiseOffer, isCruiseV1Offer, isLEHotel, isTourV2Offer, isVillaOffer } from 'lib/offer/offerTypes'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import React, { forwardRef, useCallback } from 'react'
import styled from 'styled-components'
import { TopLevelTileProps } from '../OfferTileTypes'
import SearchTileEventsContext, { SearchTileEvents, SearchTileEventsHandler } from './SearchTileEventsContext'

const offerSoldOutClickEvent: SnowplowClientEventParams = {
  subject: 'search-sold-out-offer',
  action: 'clicked',
  category: 'logging',
  type: 'operational',
  optimizelyEventId: '29823620233',
  optimizelyEventKey: 'search-click-sold-out-offer',
}

const offerTypeClickEvents: Record<string, SnowplowClientEventParams> = {
  [OFFER_TYPE_ALWAYS_ON]: {
    subject: 'search-lpc-offer',
    action: 'clicked',
    category: 'logging',
    type: 'operational',
    optimizelyEventId: '25348110190',
    optimizelyEventKey: 'search-click-lpc-offer',
  },
  [OFFER_TYPE_HOTEL]: {
    subject: 'search-flash-offer',
    action: 'clicked',
    category: 'logging',
    type: 'operational',
    optimizelyEventId: '27798670454',
    optimizelyEventKey: 'search-click-flash-offer',
  },
  [OFFER_TYPE_BED_BANK]: {
    subject: 'search-lpp-offer',
    action: 'clicked',
    category: 'logging',
    type: 'operational',
    optimizelyEventId: '29828090090',
    optimizelyEventKey: 'search-click-lpp-offer',
  },
}

const OfferLink = styled(Clickable)`
  position: relative;
`

function getTestId(offer: App.AnyOffer) {
  let flightsEnabled
  let onlinePurchaseSchedule

  if (offer.type !== OFFER_TYPE_BED_BANK) {
    const internalOffer = offer as App.Offer

    flightsEnabled = internalOffer.offerFlightsEnabled
    onlinePurchaseSchedule = internalOffer.onlinePurchaseSchedule
  }

  return classnames(
    `offer-${offer.type}`, {
      'offer-with-flights': flightsEnabled,
      [`Purchasable-${offer.type}`]: scheduleIsCurrent(onlinePurchaseSchedule),
    })
}

interface Props extends TopLevelTileProps {
  className?: string;
  position?: number;
}

const OfferSearchTile = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    offer,
    filters,
    productClick = noop,
    className,
  } = props

  const offerListKey = getOfferListKey(filters ?? {})
  const posAdjusted = useAppSelector(state => state.offer.offerListAdjustedOffers[offerListKey]?.[offer.id])

  const offerMetaData = useOfferMetaData(offer.id, filters)
  const offerUrl = useOfferUrl(offer, {
    filters,
    bundledOfferId: offerMetaData?.bundledOfferId,
    offerLinkIncludesFilters: true,
  })

  const handleClick = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'search-offer',
      action: 'clicked',
      category: 'logging',
      type: 'operational',
      optimizelyEventId: '25273391378',
      optimizelyEventKey: 'search-click-offer',
    })

    const offerTypeClickEvent = offerTypeClickEvents[offer.type]
    if (offerTypeClickEvent) {
      Analytics.trackClientEvent(offerTypeClickEvent)
    }

    if (posAdjusted === 'sold-out') {
      Analytics.trackClientEvent(offerSoldOutClickEvent)
    }

    productClick?.(offer)
  }, [offer, posAdjusted, productClick])

  const onSearchEventHandler = useCallback<SearchTileEventsHandler>((dispatchAction) => {
    const itemContext = getItemContextForOffer(offer)
    switch (dispatchAction.type) {
      case SearchTileEvents.clickAlternativeDateViewOffer:
        Analytics.trackClientEvent({
          subject: 'search-alternative-dates',
          action: 'clicked',
          category: 'logging',
          type: 'operational',
          context: itemContext,
        })
        break
      case SearchTileEvents.viewAlternativeDatesBanner:
        Analytics.trackClientEvent({
          subject: 'search-alternative-dates',
          action: 'impression',
          category: 'logging',
          type: 'operational',
          context: itemContext,
        })
        break
    }
  }, [offer])

  return <SearchTileEventsContext.Provider value={onSearchEventHandler}>
    <ProductPaletteProvider product={offer}>
      <OfferLink
        onClick={handleClick}
        to={offerUrl}
        data-testid={getTestId(offer)}
        target={config.OPEN_NEW_TAB_OFFER_CLICK ? '_blank' : undefined }
        className={className}
        ref={ref}
      >
        {isLEHotel(offer) && <HotelSearchTile
          offer={offer}
          filters={filters}
        />}
        {isTourV2Offer(offer) && <TourV2SearchTile
          offer={offer}
          filters={filters}
        />}
        {isBedbankOffer(offer) && <BedbankSearchTile
          offer={offer}
          filters={filters}
        />}
        {isVillaOffer(offer) && <VillaSearchTile
          offer={offer}
          filters={filters}
        />}
        {(isCruiseV1Offer(offer) || isCruiseOffer(offer)) && <CruiseSearchTile
          offer={offer}
          filters={filters}
        />}
        {isBundleOffer(offer) && <HotelBundleSearchTile
          offer={offer}
          filters={filters}
        />}
      </OfferLink>
    </ProductPaletteProvider>
  </SearchTileEventsContext.Provider>
})

export default OfferSearchTile

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidGoogleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.8996 7.4727c-1.0046-.9909-2.2825-1.4954-3.7057-1.4954-2.5248 0-4.6619 1.759-5.4242 4.1227-.1939.6-.304 1.2409-.304 1.9s.1101 1.3.304 1.9c.7623 2.3636 2.8994 4.1227 5.4242 4.1227 1.3042 0 2.4146-.3545 3.2827-.9545 1.0266-.7091 1.7096-1.7682 1.9343-3.0182h-5.217v-3.8682h9.1298c.1146.6546.1763 1.3364.1763 2.0455 0 3.0455-1.0575 5.6091-2.8905 7.35C17.0056 21.1046 14.8112 22 12.1939 22c-3.7894 0-7.0677-2.2409-8.6628-5.5091C2.8746 15.1409 2.5 13.6137 2.5 12c0-1.6136.3745-3.1409 1.031-4.4909C5.1263 4.241 8.4046 2 12.194 2c2.6129 0 4.8073.991 6.4861 2.6045l-2.7804 2.8682Z"/>
  </SvgIcon>
}

export default SolidGoogleIcon

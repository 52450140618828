import { useCallback, useMemo } from 'react'
import { ANYWHERE_SEARCH_ITEM, SEARCH_VERTICALS } from 'constants/search'
import ToursTabContent from '../Components/Mobile/TabContents/ToursTabContent'
import { useGlobalToursSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalToursSearch'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { pushWithRegion } from 'actions/NavigationActions'
import qs from 'qs'
import { useTourV2SearchEnabled } from 'hooks/useTourV2SearchEnabled'
import useQueryParams from 'hooks/useQueryParams'
import tourV2VerticalNameSelector from 'selectors/TourV2/tourV2VerticalNameSelector'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'

function useGlobalSearchVerticalTabTours(searchVerticalItem?: App.SearchVerticalItem): App.GlobalSearchVerticalTabV2 {
  const dispatch = useAppDispatch()
  const toursVerticalName = useAppSelector(tourV2VerticalNameSelector)
  const isEnabled = useTourV2SearchEnabled()

  const queryParams = useQueryParams()

  const {
    globalToursSearchDispatch: searchDispatch,
    globalToursSearchState: state,
  } = useGlobalToursSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    eventAnalytics: {
      contextLocation: 'common-search',
    },
  }, queryParams)

  const onSearch = useCallback(() => {
    const {
      flexibleNights,
      searchItem,
      dateSearchOptionId,
      eventAnalytics,
    } = state

    if (eventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        eventAnalytics.contextLocation,
        eventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItem,
          searchVerticals: new Set([SEARCH_VERTICALS.TOURS]),
          dateSearchOptionId,
          flexibleNights,
        }),
      ))
    }

    const toBeSearchedItem = state.searchItem ?? ANYWHERE_SEARCH_ITEM
    const tourGroupTypesToQuery = (state.tourGroupTypes ?? []).reduce((acc, value) => {
      return { ...acc, [value]: true }
    }, {})
    const query = qs.stringify({
      destinationId: toBeSearchedItem.value,
      destinationName: toBeSearchedItem.format.mainText,
      flexibleNights: state.flexibleNights ?? undefined,
      flexibleMonths: state.flexibleMonths ?? undefined,
      ...tourGroupTypesToQuery,
    })
    dispatch(pushWithRegion(`/search/tours?${query}`))
  }, [state, dispatch])

  return useMemo(() => ({
    isEnabled: isEnabled && !!searchVerticalItem,
    label: toursVerticalName,
    key: SEARCH_VERTICALS.TOURS,
    context: { dispatch: searchDispatch, state },
    onSearch,
    ContentComponent: ToursTabContent,
    Icon: LineCompassIcon,
  }), [isEnabled, searchVerticalItem, toursVerticalName, searchDispatch, state, onSearch])
}

export default useGlobalSearchVerticalTabTours

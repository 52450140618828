import React from 'react'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from './GlobalSearchContexts'
import GlobalSearchState, { GlobalSearchAction } from './GlobalSearchState'

interface Props {
  children: React.ReactNode
  dispatch: React.Dispatch<GlobalSearchAction>
  state: GlobalSearchState
}

export default function GlobalSearchContextProvider(props: Props) {
  const { children, dispatch, state } = props

  return <GlobalSearchStateContext.Provider value={state}>
    <GlobalSearchDispatchContext.Provider value={dispatch}>
      {children}
    </GlobalSearchDispatchContext.Provider>
  </GlobalSearchStateContext.Provider>
}

import React, { useContext } from 'react'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import TextButton from './TextButton'
import ContactContext from 'contexts/contactContext'

interface Props {
  currentContact?: App.GeoContact;
  onClick?: () => void;
}

function SupportNumberButton({ currentContact, onClick }: Props) {
  const geoContact = useContext(ContactContext)
  const contact = currentContact ?? geoContact.defaultContact

  return <TextButton
    onClick={() => onClick?.()}
    kind="secondary"
    variant="dark"
    startIcon={<LinePhoneIcon />}
    href={`tel:${contact.number}`}
    rel="nofollow"
  >
    {contact.humanReadable}
  </TextButton>
}

export default SupportNumberButton

import { flightSearchQueryStringWithoutAirportNames } from 'tripPlanner/components/Bookmark/BookmarkButton/utils'

/**
 * The `bookmarkId` for a flight search item is its search query string.
 * Unfortunately the airport names in the query string can vary depending on
 * how the search was initiated, so we need to normalise the source before
 * matching up items by removing the airport names if they exist.
 */
export function normaliseBookmarkId(bookmarkId: string) {
  if (
    bookmarkId.startsWith('?') &&
    bookmarkId.includes('originAirportName') &&
    bookmarkId.includes('destinationAirportName')
  ) {
    return flightSearchQueryStringWithoutAirportNames(bookmarkId)
  }
  return bookmarkId
}

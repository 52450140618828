import { ImageParams } from 'components/Common/ResponsiveImage'
import { ImageChangeHandler, ImageLoadHandler } from 'components/Luxkit/Carousel/BaseImageCarousel'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import React, { PropsWithChildren, ReactElement, useCallback, useContext } from 'react'
import styled from 'styled-components'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Container = styled.div`
  grid-area: hero;
`

const imageParams: ImageParams = {
  quality: 'best',
  fit: 'center',
  mobileWidth: '578px',
  tabletWidth: '670px',
  desktopWidth: '410px',
}

interface Props extends PropsWithChildren {
  bookmarkAction?: ReactElement
  images: Array<App.Image>
  productLabel?: ReactElement | false
  onImageChange?: ImageChangeHandler;
  onImageLoad?: ImageLoadHandler;
}

function _SearchTileHero({
  bookmarkAction,
  images,
  productLabel,
  onImageChange,
  onImageLoad,
}: Props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleImageLoaded = useCallback<ImageLoadHandler>((index, image) => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
    onImageLoad?.(index, image)
  }, [dispatchOfferListEvent, onImageLoad])

  return <Container className={_SearchTileHero.name}>
    <ImageCarousel
      topLeft={productLabel}
      topRight={bookmarkAction ? <TripGuard>{bookmarkAction}</TripGuard> : undefined}
      images={images}
      arrowBehaviour="interaction-visible"
      imageParams={imageParams}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />
  </Container>
}

export default _SearchTileHero

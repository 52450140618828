import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

const getOrderPaymentOption = (order: App.Order): comluxgroup.Order_1_0_0['paymentOption'] => {
  const paymentHasInstallment = !!order.payments.find(payment => payment?.instalmentDetails?.total_amount)
  const paymentHasDeposit = !!order.payments.find(payment => payment?.depositDetails?.total_amount)
  const paymentIsDeferred = !!order.payments.find(payment => payment?.isDeferred)
  if (order.instalmentDetails || paymentHasInstallment) return 'instalment'
  if (order.depositDetails || paymentHasDeposit) return 'deposit'
  if (paymentIsDeferred) return 'deferred'
  return 'full'
}

const orderContext = (order: App.Order) => {
  return comluxgroup.createOrder_1_0_0({
    numberOfItems: order.itemsCount,
    paymentOption: getOrderPaymentOption(order),
    orderId: order.id,
    amount: order.total,
    currency: order.currencyCode,
  })
}

export default orderContext

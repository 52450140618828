import React, { useState } from 'react'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import { SEARCH_VERTICALS } from 'constants/search'
import LayoutContainer from 'components/Common/LayoutContainer'
import GlobalSearchTabsPane from 'components/Search/GlobalSearch/GlobalSearchTabsPane'
import styled from 'styled-components'
import { rem } from 'polished'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const SearchContainer = styled(LayoutContainer)`
  position: relative;
  bottom: ${rem(56)};
  margin-bottom: -${rem(56)};
  padding-bottom: ${rem(20)};
`

function Search() {
  const tabs = useGlobalSearchVerticalTabs()

  const [selectedTab, setSelectedTab] = useState<SEARCH_VERTICALS>([...tabs.keys()][0])

  return (
    <CSSBreakpoint min="desktop">
      <SearchContainer>
        <GlobalSearchTabsPane tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
      </SearchContainer>
    </CSSBreakpoint>
  )
}

export default React.memo(Search)

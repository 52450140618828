import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  HIDDEN_SUMMARIES_LOCAL_STORAGE_KEY,
  MINIMISED_SUMMARIES_LOCAL_STORAGE_KEY,
} from 'tripPlanner/config'

export const HiddenSummariesContext = createContext<{
  isHidden?:(tripId: string, key: string) => boolean
  setHidden?: (tripId: string, key: string, value: boolean) => void
  isMinimised?: (tripId: string, key: string) => boolean
  setMinimised?: (tripId: string, key: string, value: boolean) => void
  unhideAllSummaries?: (tripId: string) => void
  minimisedSummaries?: TripHiddenSummary
  hasHiddenSummaries?: boolean
    }>({})

export const useHiddenSummaries = () => {
  return useContext(HiddenSummariesContext)
}

const summaryKey = (name: string) => name.toLowerCase().replace(/\s/g, '')

const HIDDEN_KEY = HIDDEN_SUMMARIES_LOCAL_STORAGE_KEY
const MINIMISED_KEY = MINIMISED_SUMMARIES_LOCAL_STORAGE_KEY
const DEFAULT_SUMMARIES: TripHiddenSummary = {}

type HiddenSummary = Record<string, boolean>
type TripHiddenSummary = Record<string, HiddenSummary>

function HiddenSummariesContextProvider({ children }: React.PropsWithChildren) {
  const [minimisedSummaries, setMinimisedSummaries] =
    useState<TripHiddenSummary>(DEFAULT_SUMMARIES)
  const [hiddenSummaries, setHiddenSummaries] = useState<TripHiddenSummary>({})

  // Initialise
  useEffect(() => {
    const storedHiddenSummaries = localStorage.getItem(HIDDEN_KEY)
    const storedMinimisedSummaries = localStorage.getItem(MINIMISED_KEY)

    setHiddenSummaries(
      storedHiddenSummaries ?
        JSON.parse(storedHiddenSummaries) :
        DEFAULT_SUMMARIES,
    )
    setMinimisedSummaries(
      storedMinimisedSummaries ?
        JSON.parse(storedMinimisedSummaries) :
        DEFAULT_SUMMARIES,
    )
  }, [])

  const isHidden = useCallback(
    (tripId: string, key: string) =>
      !!hiddenSummaries[tripId]?.[summaryKey(key)],
    [hiddenSummaries],
  )

  const setHidden = useCallback(
    (tripId: string, key: string, value: boolean) => {
      const newHiddenSummaries: TripHiddenSummary = {
        ...hiddenSummaries,
        [tripId]: hiddenSummaries[tripId] ?? {},
      }

      newHiddenSummaries[tripId][summaryKey(key)] = value
      setHiddenSummaries(newHiddenSummaries)
      localStorage.setItem(HIDDEN_KEY, JSON.stringify(newHiddenSummaries))
    },
    [hiddenSummaries],
  )

  const unhideAllSummaries = useCallback(() => {
    setHiddenSummaries(DEFAULT_SUMMARIES)
    localStorage.setItem(HIDDEN_KEY, JSON.stringify(DEFAULT_SUMMARIES))
  }, [])

  const isMinimised = useCallback(
    (tripId: string, key: string) =>
      !!minimisedSummaries[tripId]?.[summaryKey(key)],
    [minimisedSummaries],
  )

  const setMinimised = useCallback(
    (tripId: string, key: string, value: boolean) => {
      // TODO: do we need to clone this?
      const newMinimisedSummaries: TripHiddenSummary = {
        ...minimisedSummaries,
        [tripId]: minimisedSummaries[tripId] ?? {},
      }

      newMinimisedSummaries[tripId][summaryKey(key)] = value
      setMinimisedSummaries(newMinimisedSummaries)
      localStorage.setItem(MINIMISED_KEY, JSON.stringify(newMinimisedSummaries))
    },
    [minimisedSummaries],
  )

  const value = useMemo(() => ({
    isHidden,
    setHidden,
    isMinimised,
    setMinimised,
    unhideAllSummaries,
    minimisedSummaries,
    hasHiddenSummaries: hiddenSummaries !== DEFAULT_SUMMARIES,
  }), [hiddenSummaries, isHidden, isMinimised, minimisedSummaries, setHidden, setMinimised, unhideAllSummaries])

  return (
    <HiddenSummariesContext.Provider
      value={value}
    >
      {children}
    </HiddenSummariesContext.Provider>
  )
}
export default HiddenSummariesContextProvider

export default function facilityGroupsMap(group): App.FacilityGroup {
  return {
    name: group.name,
    values: group.values.map(facilityValueMap),
  }
}

function facilityValueMap(facility): App.FacilityValue {
  return {
    name: facility.name,
  }
}

import React from 'react'
import AspectRatio from 'components/utils/AspectRatio'
import ResponsiveImage from '../ResponsiveImage'
import styled from 'styled-components'

const RelativeAspectRatio = styled(AspectRatio)`
  position: relative;
`

interface Props extends React.ComponentProps<typeof ResponsiveImage> {
}

const ImageWithReservedSpace = React.forwardRef<HTMLImageElement, Props>(function(props: Props, ref) {
  const {
    alt,
    children,
    id,
    loading,
    onLoad,
    ...rest
  } = props

  return (
    <RelativeAspectRatio {...rest}>
      <ResponsiveImage
        { ...rest}
        loading={loading}
        id={id}
        ref={ref}
        alt={alt}
        onLoad={onLoad}
      />
      {children}
    </RelativeAspectRatio>
  )
})

ImageWithReservedSpace.displayName = 'ImageWithReservedSpace'

export default ImageWithReservedSpace

import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchFlightDeal } from 'actions/FlightActions'

type FlightDealDetails = [
  deal: App.FlightDeal | undefined,
  fetching: boolean,
  error: any,
]

function useFlightDeal(id: string | undefined): FlightDealDetails {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchFlightDeal(id))
    }
  }, [dispatch, id])

  const flightDeal = useAppSelector((state) => id ? state.flights.flightDeals[id] : undefined)

  return [
    flightDeal?.deal,
    !!flightDeal?.fetching,
    flightDeal?.error,
  ]
}

export default useFlightDeal

import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getTotalsWithoutInsurance from '../payment/getTotalsWithoutInsurance'

const getInsuranceCoverAmountFromCart = createSelector(
  getTotalsWithoutInsurance,
  (totals): number | undefined => {
    if (totals.hasRequiredData) {
      const {
        paidPrice = 0,
        price = 0,
        surcharge = 0,
        otherFees = {},
      } = totals.data

      return paidPrice + price + surcharge + sum(Object.values(otherFees))
    }
  },
)

export default getInsuranceCoverAmountFromCart

import React, { useContext, useMemo } from 'react'
import { EmptyArray } from 'lib/array/arrayUtils'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import DateFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/DateFloatingPanel'
import useDateSearchProps from 'components/SearchV2/Hooks/useDateSearchProps'
import useStepsController from 'components/SearchV2/Hooks/useStepsController'
import LocationFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/LocationFloatingPanel'
import {
  CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL,
  CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_SEARCH_TYPES,
} from 'constants/carHire'
import { searchSteps } from './CarHireMobileSearchControls'

const activeDateToggleButton = {
  id: DATE_SEARCH_OPTION_IDS.SPECIFIC,
  label: 'Specific dates',
}

interface Props {
  activeStep: searchSteps
  setActiveStep: (step: searchSteps) => void
  onSubmit: () => void
  differentLocations: boolean
}

function getLocationTitle(activeStep: searchSteps, differentLocation: boolean) {
  if (!differentLocation) {
    return CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL
  }
  if (activeStep === searchSteps.LOCATION) {
    return CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL
  }
  return CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL
}

function CarHireSearchDrawer(props: Props) {
  const { activeStep, setActiveStep, onSubmit, differentLocations } = props
  const {
    searchItem,
    secondarySearchItem,
    checkinDate: pickUpDate,
    checkoutDate: dropOffDate,
  } = useContext(GlobalSearchStateContext)

  const dateSearchProps = useDateSearchProps({ onAnytimeDatesSelected: () => { } })

  const stepsValidation = useMemo(() => ({
    [searchSteps.LOCATION]: searchItem,
    ...differentLocations && { [searchSteps.SECONDARY_LOCATION]: differentLocations && secondarySearchItem },
    [searchSteps.DATE]: pickUpDate && dropOffDate,
  }),
  [searchItem, differentLocations, secondarySearchItem, pickUpDate, dropOffDate])

  const {
    goToNextStep,
    goToPreviousStep,
    closeActiveStep,
  } = useStepsController({ activeStep, setActiveStep, stepsValidation, onSearch: onSubmit })

  return (
    <>
      <LocationFloatingPanel
        isOpen={activeStep === searchSteps.LOCATION || activeStep === searchSteps.SECONDARY_LOCATION}
        onClose={closeActiveStep}
        onGoBack={goToPreviousStep}
        onConfirm={goToNextStep}
        isSecondaryLocation={activeStep === searchSteps.SECONDARY_LOCATION}
        searchTypes={CAR_HIRE_SEARCH_TYPES}
        typeaheadVertical="car_hire"
        placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
        title={getLocationTitle(activeStep, differentLocations)}
        confirmButtonDisabled={false}
      />
      <DateFloatingPanel
        {...dateSearchProps}
        isOpen={activeStep === searchSteps.DATE}
        onClose={closeActiveStep}
        onConfirm={closeActiveStep}
        onGoBack={goToPreviousStep}
        confirmText="Apply"
        dateToggleButtons={EmptyArray}
        activeDateToggleButton={activeDateToggleButton}
        title="When do you want to hire?"
        label="day"
        includeLastDayOnCount
      />
    </>
  )
}

export default CarHireSearchDrawer

import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

function getGiftCardOrderItems(order: App.Order) {
  const { giftCardItems } = order
  return giftCardItems.map(orderItem => {
    return comluxgroup.createItem_1_1_0(
      {
        offerId: 'gift-card',
        categoryId: 'gift_card',
        productId: 'gift_card',
        itemId: orderItem.itemId,
        currency: order.currencyCode,
        price: orderItem.total,
        value: orderItem.total,
        offerLeadPrice: orderItem.total,
        bookingNumber: order.bookingNumber,
        orderId: order.id,
      },
    )
  })
}
export default getGiftCardOrderItems

import loadable from '@loadable/component'
import * as React from 'react'
import { match, Route, Switch } from 'react-router'

import AuthRoute from 'components/App/Routes/AuthRoute'
import Trip404Page from 'tripPlanner/pages/Trip404Page'

const AddCustomFlight = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewAddCustomFlight" */ 'tripPlanner/pages/WebViews/components/AddCustomFlight/AddCustomFlight'
    ),
)

const AddItemsToTripView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewAddItems" */ 'tripPlanner/pages/WebViews/components/AddItemsToTripView/AddItemsToTripView'
    ),
)

const FlightSearchView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewFlightSearch" */ 'tripPlanner/pages/WebViews/components/FlightSearchView/FlightSearchView'
    ),
)

const MapView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewMap" */ 'tripPlanner/pages/WebViews/components/MapView/MapView'
    ),
)

const PublicMapView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerPublicMapView" */ 'tripPlanner/pages/WebViews/components/MapView/PublicMapView'
    ),
)

const SearchExperienceWebView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewExperience" */ 'tripPlanner/pages/WebViews/components/SearchExperienceView/SearchExperienceView'
    ),
)

const SearchLEBookingView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewLEBooking" */ 'tripPlanner/pages/WebViews/components/SearchLEBookingView/SearchLEBookingView'
    ),
)

const SearchTourOrCruiseView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewTours" */ 'tripPlanner/pages/WebViews/components/SearchTourOrCruiseView/SearchTourOrCruiseView'
    ),
)

const ShareTripWebView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewShareTrip" */ 'tripPlanner/pages/WebViews/components/ShareTripView/ShareTripView'
    ),
)

const EditCustomFlight = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewAddCustomFlight" */ 'tripPlanner/pages/WebViews/components/EditCustomFlight/EditCustomFlight'
    ),
)

const TourAndCruiseItemSummaryView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerWebViewTourAndCruiseItemSummary" */ 'tripPlanner/pages/WebViews/components/TourAndCruiseItemSummaryView/TourAndCruiseItemSummaryView'
    ),
)

const ExperienceItemSummaryView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerExperienceItemSummary" */ 'tripPlanner/pages/WebViews/components/ExperienceItemSummaryView/ExperienceItemSummaryView'
    ),
)

const CopyTemplateView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerCopyTemplateView" */ 'tripPlanner/pages/WebViews/components/CopyTemplateView/CopyTemplateView'
    ),
)

const SavedLandingView = loadable(
  () =>
    import(
      /* webpackChunkName: "TripPlannerSavedLandingView" */ 'tripPlanner/pages/WebViews/components/SavedViewWebView/SavedViewWebView'
    ),
)

const pageRoute = (match: { path: string }, path: string) =>
  `${match.path}${path}`

interface Props {
  match: match
}

function WebViewRoutes({ match }: Props) {
  return (
    <Switch>
      <AuthRoute
        path={pageRoute(
          match,
          '/trip-planner/webView/:tripId/search-experience',
        )}
        component={SearchExperienceWebView}
      />
      <AuthRoute
        path={pageRoute(match, '/trip-planner/webView/:tripId/share-trip')}
        component={ShareTripWebView}
      />
      <AuthRoute
        path={pageRoute(match, '/trip-planner/webView/:tripId/search-flights')}
        component={FlightSearchView}
      />
      <AuthRoute
        path={pageRoute(
          match,
          '/trip-planner/webView/:tripId/search-tours-or-cruises',
        )}
        component={SearchTourOrCruiseView}
      />
      <AuthRoute
        path={pageRoute(
          match,
          '/trip-planner/webView/:tripId/search-le-booking',
        )}
        component={SearchLEBookingView}
      />
      <AuthRoute
        path={pageRoute(match, '/trip-planner/webView/:tripId/add-to-trip')}
        component={AddItemsToTripView}
      />
      <AuthRoute
        path={pageRoute(match, '/trip-planner/webView/:tripId/map')}
        component={MapView}
      />
      <AuthRoute
        path={pageRoute(match, '/trip-planner/webView/:tripId/custom-flight')}
        component={AddCustomFlight}
      />
      <AuthRoute
        path={pageRoute(
          match,
          '/trip-planner/webView/:tripId/edit-custom-flight/:itemId',
        )}
        component={EditCustomFlight}
      />
      <AuthRoute
        path={pageRoute(
          match,
          '/trip-planner/webView/:tripId/tour-cruise-summary/:itemId',
        )}
        component={TourAndCruiseItemSummaryView}
      />
      <AuthRoute
        path={pageRoute(
          match,
          '/trip-planner/webView/:tripId/experience-summary/:itemId',
        )}
        component={ExperienceItemSummaryView}
      />
      <AuthRoute
        path={pageRoute(
          match,
          '/trip-planner/webView/:templateId/copy-template',
        )}
        component={CopyTemplateView}
      />
      <AuthRoute
        path={pageRoute(match, '/trip-planner/webView/saved')}
        component={SavedLandingView}
      />
      <Route
        path={pageRoute(match, '/trip-planner/webView/:tripId/public-map')}
        component={PublicMapView}
      />
      <Trip404Page />
    </Switch>
  )
}

export default WebViewRoutes

import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  ADD_CHAT_MESSAGE,
  ADD_DEFAULT_CHAT,
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  SET_CHAT_OUTAGE_MESSAGE,
  SET_CURRENT_CHAT_ID,
  UPDATE_CHAT,
  UPDATE_CHAT_TITLE,
} from 'actions/actionConstants'
import {
  CREATE_NEW_CHAT,
  FETCH_CHAT_MESSAGES,
  FETCH_CHAT_WELCOME_MESSAGE,
  FETCH_CHATS,
  POST_MESSAGE_FEEDBACK,
  USER_LOGOUT,
} from 'actions/apiActionConstants'
import { DEFAULT_CHAT } from 'supportAssistant/hooks/useChats'

export const initialSupportAssistantState: App.SupportAssistantState = {
  chats: { chats: [DEFAULT_CHAT] },
  welcomeMessage: { message: undefined },
  currentChatId: 'default',
  messages: {},
  messagesLoading: {},
  messagesError: {},
  outageMessage: undefined,
}

const apiRequests = reducerSwitch<App.SupportAssistantState>({
  [FETCH_CHATS]: () => ({
    chats: {
      chats: [],
      fetching: true,
    },
  }),
  [CREATE_NEW_CHAT]: (state) => ({
    chats: {
      chats: [
        ...state.chats.chats,
      ],
      fetching: true,
    },
  }),
  [FETCH_CHAT_WELCOME_MESSAGE]: () => ({
    welcomeMessage: {
      message: undefined,
      fetching: true,
    },
  }),
  [FETCH_CHAT_MESSAGES]: (state, action) => ({
    messages: {
      ...state.messages,
      [action.chatId]: undefined,
    },
    messagesLoading: {
      ...state.messagesLoading,
      [action.chatId]: true,
    },
    messagesError: {
      ...state.messagesError,
      [action.chatId]: undefined,
    },
  }),
  [POST_MESSAGE_FEEDBACK]: (state, action) => ({
    messages: {
      ...state.messages,
    },
    messagesLoading: {
      ...state.messagesLoading,
      [action.chatId]: true,
    },
    messagesError: {
      ...state.messagesError,
      [action.chatId]: undefined,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.SupportAssistantState>({
  [FETCH_CHATS]: (state, action) => ({
    chats: {
      chats: action.data.chats,
      fetching: false,
      error: undefined,
    },
    currentChatId: action.data.currentChatId,
  }),
  [CREATE_NEW_CHAT]: (state, action) => {
    // if the new chat created does not have a customerId, the user is logged out
    // So we only want to save the new chat.
    const chats = !action.data.chat.customerId ?
        [action.data.chat] :
        [
          ...state.chats.chats,
          action.data.chat,
        ]

    return {
      chats: {
        chats,
        fetching: false,
        error: undefined,
      },
      messages: {
        ...state.messages,
        [action.data.chat.id]: action.data.messages,
      },
      currentChatId: action.data.chat.id,
    }
  },
  [FETCH_CHAT_WELCOME_MESSAGE]: (state, action) => ({
    welcomeMessage: {
      message: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [FETCH_CHAT_MESSAGES]: (state, action) => ({
    messages: {
      ...state.messages,
      [action.data.chatId]:
        action.data.messages,
    },
    messagesLoading: {
      ...state.messagesLoading,
      [action.data.chatId]: action.data.messagesLoading,
    },
    messagesError: {
      ...state.messagesError,
      [action.data.chatId]: undefined,
    },
  }),
  [POST_MESSAGE_FEEDBACK]: (state, action) => {
    const updatedMessages = state.messages[action.data.chatId]?.map((message) => {
      return message.id === action.data.messageId ?
          { ...message, feedback: action.data.sentiment } :
        message
    })
    return {
      messages: {
        ...state.messages,
        [action.data.chatId]: updatedMessages,
      },
      messagesLoading: {
        ...state.messagesLoading,
        [action.data.chatId]: false,
      },
      messagesError: {
        ...state.messagesError,
        [action.data.chatId]: undefined,
      },
    }
  },
  [USER_LOGOUT]: () => initialSupportAssistantState,
})

const apiFailures = reducerSwitch<App.SupportAssistantState>({
  [FETCH_CHATS]: (state, action) => ({
    chats: {
      chats: [],
      fetching: false,
      error: action.error?.message ?? action.error,
    },
  }),
  [CREATE_NEW_CHAT]: (state, action) => ({
    chats: {
      chats: [
        ...state.chats.chats,
      ],
      fetching: false,
      error: action.error?.message ?? action.error,

    },
  }),
  [FETCH_CHAT_WELCOME_MESSAGE]: (state, action) => ({
    welcomeMessage: {
      message: undefined,
      fetching: true,
      error: action.error?.message ?? action.error,
    },
  }),
  [FETCH_CHAT_MESSAGES]: (state, action) => ({
    messages: {
      ...state.messages,
      [action.data.chatId]: undefined,
    },
    messagesLoading: {
      ...state.messagesLoading,
      [action.data.chatId]: false,
    },
    messagesError: {
      ...state.messagesError,
      [action.data.chatId]: action.error?.message ?? action.error,
    },
  }),
  [POST_MESSAGE_FEEDBACK]: (state, action) => ({
    messages: {
      ...state.messages,
    },
    messagesLoading: {
      ...state.messagesLoading,
      [action.data.chatId]: false,
    },
    messagesError: {
      ...state.messagesError,
      [action.data.chatId]: action.error?.message ?? action.error,
    },
  }),
})

export default createReducer<App.SupportAssistantState>(initialSupportAssistantState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [ADD_CHAT_MESSAGE]: (state, action) => ({
    messages: {
      ...state.messages,
      [action.chatId]: [
        ...(state.messages[action.chatId] || []),
        action.message,
      ],
    },
  }),
  [ADD_DEFAULT_CHAT]: (state, action) => ({
    chats: {
      chats: [action.chat],
      fetching: false,
      error: undefined,
    },
    currentChatId: action.chat.id,
  }),
  [UPDATE_CHAT]: (state, action) => {
    const updatedChats = state.chats.chats.map((chat) => chat.id === action.chat.id ?
      action.chat :
      chat,
    )

    return {
      chats: {
        chats: updatedChats,
        fetching: false,
        error: undefined,
      },
    }
  },
  [UPDATE_CHAT_TITLE]: (state, action) => {
    const updatedChats = state.chats.chats.map((chat) =>
      chat.id === action.chatId ?
          { ...chat, title: action.title } :
        chat,
    )
    return {
      chats: {
        chats: updatedChats,
        fetching: false,
        error: undefined,
      },
    }
  },
  [SET_CURRENT_CHAT_ID]: (state, action) => ({
    currentChatId: action.chatId,
  }),
  [SET_CHAT_OUTAGE_MESSAGE]: (state, action) => ({
    outageMessage: action.error,
  }),
})

/// <reference path="../../../../typings/app.d.ts" />
/// <reference path="../../../../typings/utils.d.ts" />
/// <reference path="../../../../typings/global.d.ts" />
/// <reference path="../../../../typings/styled.d.ts" />

import { fireGenesysCustomEvent } from 'actions/AnalyticsActions'
import { fetchUpsellableOrders } from 'actions/OrderActions'
import {
  fetchUpsellOrAvailableCreditSnackbar,
} from 'actions/UpsellAndAvailableCreditSnackbar/UpsellAndAvailableCreditSnackbarActions'
import { upsellSnackbarDismissed } from 'actions/UpsellAndAvailableCreditSnackbar/utils'
import { OPEN_CHAT_WINDOW } from 'actions/actionConstants'
import HeadData from 'components/Common/HeadData'
import HideLiveChat from 'components/Common/HideLiveChat'
import GeoRedirectModal from 'components/Common/Modals/GeoRedirectModal'
import PartnershipIcon from 'components/Common/Vendors/Partnership/PartnershipIcon'
import AppSnackbar from 'components/Luxkit/Snackbar/AppSnackbar'
import loadable from 'components/utils/lazyImport'
import config from 'constants/config'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import useCheckAppVersion from 'hooks/useCheckAppVersion'
import { haveOrdersBeenFetched } from 'lib/order/orderUtils'
import debounce from 'lodash.debounce'
import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { selectLoggedIn, shouldShowGeoRedirect } from 'selectors/accountSelectors'
import { shouldShowContinueToApp } from 'selectors/featuresSelectors'
import { useAppDispatch } from 'hooks/reduxHooks'
import styled from 'styled-components'
import { useKeepTripIdOnReload } from 'tripPlanner/hooks/useKeepTripIdOnReload'
import ContinueToAppDrawer from './ContinueToAppDrawer'
import GoogleAuth from './GoogleAuth'
import StructuredDataLogo from './StructuredDataLogo'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { fetchLocalHighIntentOffers, loadDefaultRecommendation, saveAllHotLeadsAndRecentlyViewed } from 'actions/RecommendationActions'
import AccountAccessSSRModal from 'components/Account/AccountAccess/AccountAccessSSRModal'
import ModalContext from 'contexts/ModalContext'
import useShowLogin from 'home/hooks/useShowLogin'
import useLuxPlusFreePreviewAutoModal from 'luxPlus/hooks/useLuxPlusFreePreviewAutoModal'
import useShowEmailResubscribeModal from 'home/hooks/useShowEmailResubscribeModal'
import useShowTravelPreferencesModal from 'home/hooks/useShowTravelPreferencesModal'
import useLuxLoyaltyJoinModal from 'luxLoyalty/hooks/useLuxLoyaltyJoinModal'
import { fetchLuxLoyaltyProgramConfig } from 'actions/LuxLoyaltyActions'

const BusinessTravellerAccountErrorModal = loadable(() => import(/* webpackChunkName: "BusinessTravellerAccountErrorModal" */'businessTraveller/components/blocking-modal/BusinessTravellerAccountErrorModal'), <div />)

const AppMain = styled.main`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`

// Partnership icon has defs in it that need to be not display: none at any point in time
// on various pages, sometimes a display: none def comes before a visible one
// cause the partnership icon to disappear (as it's defs are display none)
// We fix this by always rendering one at the top of the page that is not display: none
const InvisiblePartnershipIcon = styled(PartnershipIcon)`
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0,0,0,0);
  pointer-events: none;
  user-select: none;
`

interface Props {
  showGeoModal: boolean;
  isNotLoggedIn: boolean;
  upsellableOrdersFetched: boolean;
  memberId?: string;
  shouldShowContinueToApp: boolean;
  isStoreMode: boolean;
  isVelocityPartnership: boolean;
}

function App(props: PropsWithChildren<Props>) {
  const {
    isVelocityPartnership,
    children,
    showGeoModal,
    isNotLoggedIn,
    upsellableOrdersFetched,
    memberId,
    shouldShowContinueToApp,
    isStoreMode,
  } = props
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)

  useCheckAppVersion()
  useShowLogin()
  useShowEmailResubscribeModal()
  useShowTravelPreferencesModal()

  useOptimizelyExperiment(OptimizelyExperiments.pricePerNight)

  useEffect(() => {
    const handleResize = debounce(() => {
      // https://cloudfour.com/thinks/a-bashful-button-worth-8-million/
      document.documentElement.style.setProperty('--vh', `calc(${window.innerHeight}px * 0.01)`)
    }, 100)

    handleResize()

    window.addEventListener('resize', handleResize)

    // @ts-ignore
    if (window.Genesys) {
      // @ts-ignore
      window.Genesys('subscribe', 'Messenger.opened', function() {
        dispatch(fireGenesysCustomEvent({
          type: OPEN_CHAT_WINDOW,
        }))
      })
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchOrdersForUpsellSnackbar = (!!memberId && !upsellSnackbarDismissed(memberId) && !upsellableOrdersFetched) && config.UPSELL_SNACKBAR_ENABLED
    // Only fetch orders when a user is logged in and they have not recently dimissed the upsell snackbar.
    if (fetchOrdersForUpsellSnackbar) {
      dispatch(fetchUpsellableOrders())
    }
  }, [dispatch, memberId, upsellableOrdersFetched])

  const loggedIn = useRef(!!memberId)
  useEffect(() => {
    // if user just logged in from anonymous
    if (!!memberId && !loggedIn.current) {
      // we only want to do this once when user login
      loggedIn.current = true
      dispatch(saveAllHotLeadsAndRecentlyViewed())
    }
  }, [dispatch, memberId])

  useEffect(() => {
    const fetchSnackbar = upsellableOrdersFetched || (!!memberId && upsellSnackbarDismissed(memberId))
    /*
      Only calculate which snackbar to display when either;
      1. Orders have been fetched and reservations added to orders.
      2. Or, the snackbar has recently been dismissed.
      In this case, orders won't be fetched as the upsell snackbar won't be displayed until the upsellSnackbar cookie has expired.
    */
    if (fetchSnackbar) {
      dispatch(fetchUpsellOrAvailableCreditSnackbar())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, upsellableOrdersFetched])

  useEffect(() => {
    if (showGeoModal) {
      showModal(<GeoRedirectModal />)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGeoModal])

  useEffect(() => {
    dispatch(fetchLocalHighIntentOffers())
    dispatch(loadDefaultRecommendation())
  }, [dispatch])

  useKeepTripIdOnReload()
  useLuxPlusFreePreviewAutoModal()
  useLuxLoyaltyJoinModal()

  useEffect(() => {
    dispatch(fetchLuxLoyaltyProgramConfig())
  }, [dispatch])

  return (<>
    { !isVelocityPartnership && <InvisiblePartnershipIcon /> }
    <GoogleAuth isLoggedIn={!isNotLoggedIn} />
    <AppSnackbar />
    <AppMain role="main">
      <HeadData />
      <StructuredDataLogo />
      {children}
      <AccountAccessSSRModal />
      {config.businessTraveller.currentAccountMode === 'business' &&
        <BusinessTravellerAccountErrorModal/>
      }
      {shouldShowContinueToApp && <ContinueToAppDrawer />}
      {(config.DISABLE_LIVECHAT || isStoreMode) && <HideLiveChat />}
    </AppMain>
  </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    showGeoModal: shouldShowGeoRedirect(state),
    isNotLoggedIn: state.auth.initComplete && !selectLoggedIn(state),
    upsellableOrdersFetched: haveOrdersBeenFetched(state, 'upsellable'),
    memberId: state.auth.account.memberId,
    shouldShowContinueToApp: shouldShowContinueToApp(state),
    isStoreMode: state.config.storeMode,
    isVelocityPartnership: state.geo.partnership?.code === VIRGIN_VELOCITY_PARTNERSHIP_CODE,
  }
}

export default connect(mapStateToProps)(App)

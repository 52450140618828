export const UI_SIZES = [
  'xxl',
  'xl',
  'lg',
  'md',
  'sm',
  'xs',
]

export type UISizes = typeof UI_SIZES[number]

export const MODAL_STYLES = {
  overlay: {
    backgroundColor: '#ecefef',
  },
  content: {
    WebkitOverflowScrolling: 'touch',
    overflow: 'auto',
    backgroundColor: '#f9f9f9',
    bottom: 0,
    left: 0,
    margin: 'auto',
    padding: 0,
    right: 0,
    top: 0,
    border: 0,
  },
}

export const UI_SIZES_PLUS = [
  ...UI_SIZES,
  'xl-x',
  'xl-y',
  'lg-x',
  'lg-y',
  'md-x',
  'md-y',
  'sm-x',
  'sm-y',
  'xs-x',
  'xs-y',
  'xl-y md',
  'lg-x md',
  'xl-x lg',
  'xl-x md',
]

export const PREVENT_SCROLL = 'prevent-double-scroll'

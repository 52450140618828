import { rem } from 'polished'
import styled from 'styled-components'

import zIndex from 'styles/tools/z-index'
import { mediaQueryUp } from '../../../../client/components/utils/breakpoint'

const FloatingBookmarkContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  top: ${rem(16)};
  left: ${rem(16)};
  right: ${rem(16)};
  z-index: ${zIndex.floatingBookmarkButton};
  margin-left: auto;

  &.slim-tile {
    top: ${rem(8)};
    ${mediaQueryUp.tablet} {
      top: 0;
      right: 0;
    }
  }
`

export default FloatingBookmarkContainer

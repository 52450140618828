import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSkiLiftIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M13.7505 3c0-.5523-.4477-1-1-1s-1 .4477-1 1v5c0 .5523.4477 1 1 1s1-.4477 1-1V3ZM6.3315 8.592c.5015-.2314 1.0956-.0125 1.327.489l1.1435 2.478 3.681-1.0224a1 1 0 0 1 1.2676.9635v5.6844l6.2676-1.7411a1 1 0 0 0 .7324-.9635V13.5c0-.5523.4477-1 1-1s1 .4477 1 1v.9798a3 3 0 0 1-2.1971 2.8906l-7.5129 2.0869a.9376.9376 0 0 1-.0436.0121l-8.9787 2.4941c-.5322.1478-1.0834-.1637-1.2312-.6959-.1478-.5321.1637-1.0833.6959-1.2311l8.2676-2.2966v-1.4215l-2.4985.6996c-1.9033.5329-3.9105-.4042-4.724-2.2055L2.089 9.4115c-.2273-.5032-.0036-1.0955.4998-1.3229.5033-.2273 1.0956-.0035 1.323.4998l2.439 5.4009c.4068.9007 1.4104 1.3692 2.362 1.1027l3.0378-.8505v-1.4259l-3.2326.8979a1 1 0 0 1-1.1756-.5445l-1.4998-3.25c-.2314-.5015-.0124-1.0956.489-1.327ZM4.2505 5.5c0-1.1046.8955-2 2-2 1.1046 0 2 .8954 2 2s-.8954 2-2 2c-1.1045 0-2-.8954-2-2Z"/>
  </SvgIcon>
}

export default LineSkiLiftIcon

import React, { PropsWithChildren } from 'react'
import Caption from '../Typography/Caption'
import clsx from 'clsx'

interface Props extends PropsWithChildren {
  className?: string
}

function PriceRowCaption(props: Props) {
  const {
    children,
    className,
  } = props

  return <Caption
    className={clsx(PriceRowCaption.name, className)}
    variant="large"
    weight="normal"
    colour="neutral-three"
  >
    {children}
  </Caption>
}

export default PriceRowCaption

import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LuxLoyaltyProductLogo from './LuxLoyaltyProductLogo'

const LogoWrapper = styled.div<{ height: number }>`
  display: inline-flex;
  gap: ${rem(6)};
  align-items: flex-start;

  * > .brand-logo {
    height: ${props => rem(props.height)};
  }

  * > .program-logo {
    height: ${props => rem(props.height - 6)};
  }
`

interface Props extends Omit<ComponentProps<typeof LuxLoyaltyProductLogo>, | 'height'> {
  /**
   * @default 24
   */
  height?: number
}

function LuxLoyaltyLogoWrapper(props: Props) {
  const {
    height = 24,
    className,
    ...rest
  } = props

  return <LogoWrapper
    className={className}
    height={height}
  >
    <LuxLoyaltyProductLogo {...rest} />
  </LogoWrapper>
}

export default LuxLoyaltyLogoWrapper

import config from 'constants/config'
import { TIMELY_ENABLED_REGIONS } from 'constants/config/region'

function shouldRenderTimely(region: string): boolean {
  // should be shown only for AU as we have physical store only in AU
  // LE only
  return config.TIMELY_LINK && config.BRAND === 'luxuryescapes' && TIMELY_ENABLED_REGIONS.includes(region)
}

export default shouldRenderTimely

import React, { useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import TextArea from 'components/Common/Form/Input/TextArea'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { formToObject } from 'lib/forms/formToObject'
import config from 'constants/config'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Heading from 'components/Luxkit/Typography/Heading'

const RadioWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};

  > * + * {
    border-top: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  }
`

const RefundReason = styled(VerticalSpacer)`
  padding: ${rem(20)};
`

const StyledRadioButtonInput = styled(RadioInput)`
  width: 100%;
`

interface Props {
  onNext: (reason: string, comment?: string) => void;
  isReserveForZeroBooking?: boolean;
  defaultReason?: string;
  defaultComment?: string;
  step: number;
  maxSteps: number;
}

const OTHER_OPTION:string = 'Other (please specify)'
const CHEAPER_OPTION:string = 'Found a cheaper deal'

export const REFUND_REASONS: Array<string> = [
  CHEAPER_OPTION,
  'Change in travel plans',
  'Personal reasons',
  'Financial constraints',
  'Travel restrictions',
  'Better loyalty program or rewards',
  OTHER_OPTION,
]

function HotelCancellationReason(props: Props) {
  const { onNext, step, maxSteps, isReserveForZeroBooking, defaultReason, defaultComment } = props
  const [selectedReason, setSelectedReason] = useState<string | undefined>(defaultReason)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const values = formToObject<{ reason: string, comment?: string }>(e.currentTarget)
    onNext(values.reason, values.comment)
  }

  return (<form onSubmit={onSubmit}>
    <ModalHeader
      title={`Cancel ${isReserveForZeroBooking ? 'reservation' : 'booking'}`}
      subtitle={`Step ${step} of ${maxSteps}`}
    />
    <ModalBody>
      <ModalContent>
        <Group direction="vertical" gap={24}>
          <BodyText variant="large">
            Before canceling your booking, please select a reason; your feedback will help us{' '}
            enhance the {config.title} experience.
          </BodyText>
          <Heading variant="heading6">
            Select your reason for cancelling
          </Heading>
          <RadioWrapper>
            {REFUND_REASONS.map((option) => (
              <RefundReason data-testid="refund-reason" key={option} gap={8}>
                <StyledRadioButtonInput
                  checked={selectedReason === option}
                  value={option}
                  name="reason"
                  required
                  onChange={() => setSelectedReason(option)}
                >
                  {option}
                </StyledRadioButtonInput>
                {selectedReason === OTHER_OPTION && option === OTHER_OPTION && (
                  <TextArea
                    placeholder="Specify reason"
                    name="comment"
                    data-testid="other-reason"
                    defaultValue={defaultReason === OTHER_OPTION ? defaultComment : undefined}
                    required
                    noValidationSpacing
                  />
                )}
                {selectedReason === CHEAPER_OPTION && option === CHEAPER_OPTION && (
                  <TextArea
                    placeholder="Who had the cheaper deal?"
                    name="comment"
                    defaultValue={defaultReason === CHEAPER_OPTION ? defaultComment : undefined}
                    required
                    noValidationSpacing
                  />
                )}
              </RefundReason>
            ))}
          </RadioWrapper>
        </Group>
      </ModalContent>
    </ModalBody>
    <ModalFooter
      primaryActionProps={{
        'data-testid': 'cancel-booking-confirm-button',
        type: 'submit',
        children: 'Continue',
      }}
    />
  </form>)
}

export default HotelCancellationReason

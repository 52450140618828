import LineArrowsSwapHorizontalIcon from 'components/Luxkit/Icons/line/LineArrowsSwapHorizontalIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { SHORT_DAY_NAME_DAY_MONTH_YEAR } from 'constants/dateFormats'
import { FlightsFareTypes } from 'constants/flight'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import React, { useContext } from 'react'

function FlightsDatesModalFooter() {
  const { flights, fareType } = useContext(FlightSearchWidgetStateContext)
  const { checkinDate, checkoutDate } = flights[0]

  return <Group direction="horizontal" gap={12} verticalAlign="center" horizontalAlign="center">
    <BodyText
      variant="medium"
      weight="bold"
    >
      {!checkinDate && 'Select departure'}
      {checkinDate?.format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}
    </BodyText>
    {fareType.value === FlightsFareTypes.RETURN && <>
      <LineArrowsSwapHorizontalIcon size="XS" />
      <BodyText
        variant="medium"
        weight="bold"
        colour={!checkoutDate && !checkinDate ? 'neutral-four' : undefined}
      >
        {!checkoutDate && 'Select return'}
        {checkoutDate?.format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}
      </BodyText>
    </>}
  </Group>
}

export default FlightsDatesModalFooter

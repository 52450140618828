import { JOURNEY_TYPE_V2 } from 'lib/flights/flightUtils'
import { Models as FlightModels } from '@luxuryescapes/lib-flights-types'
import { mapFareFamily } from './flightFareFamilyMap'

export function journeyV2Map(
  fare: FlightModels.Search.Fare,
  fareFamilies: FlightModels.Search.IFareFamilies = {},
): App.JourneyV2 {
  const fareFamily = fareFamilies[fare.fareFamiliesRef ?? '']

  return {
    id: fare.id,
    provider: fare.provider as App.FlightProviders,
    name: fare.name,
    fareBasisCode: fare.fareBasisCode,
    fareType: fare.fareType as App.FlightViewType,
    carrier: fare.carrier,
    carrierName: fare.carrierName,
    carrierLogo: fare.carrierLogo,
    displayName: fare.displayName,
    cabin: fare.cabin ?? '',
    bookingInfo: fare.bookingInfo,
    pairedFareId: fare.pairedFareId,
    price: fare.price as any,
    flightGroup: mapFlightGroup(fare.flightGroups[0]),
    currencyCode: fare.currencyCode,
    currencySymbol: fare.currencySymbol,
    isInboundCarrierSameFilter: fare.isInboundCarrierSameFilter,
    itemType: JOURNEY_TYPE_V2,
    links: fare.links,
    fareFamily: fareFamily ? mapFareFamily(fareFamily) : undefined,
    upsellFares: fare.upsellFares,
    overrideFlightTilePriceWithUpsellFarePrice: fare.overrideFlightTilePriceWithUpsellFarePrice,
    baggagePurchaseEnabled: fare.baggagePurchaseEnabled,
    flightDeal: fare.flightDeal,
    fareFamiliesRef: fare.fareFamiliesRef,
  }
}

function mapBaggage(baggage: FlightModels.Search.BaggageOption): App.FlightV2BaggageOption {
  return {
    id: baggage.id,
    quantity: baggage.quantity,
    description: baggage.description,
    carrierCode: baggage.carrierCode,
    amount: baggage.amount,
    pieceDescription: baggage.pieceDescription,
    includedInFare: baggage.includedInFare,
    passengerType: baggage.passengerType,
    currency: baggage.currency,
    providerKey: baggage.providerKey,
  }
}

function mapFlight(flight: FlightModels.Search.Flight): App.FlightV2 {
  const carryOnBaggageOptions = flight.carryOnBaggageOptions.map(mapBaggage)
  const checkedBaggageOptions = flight.checkedBaggageOptions.map(mapBaggage)
  return {
    id: flight.flightUniqueId,
    arrivalAirport: flight.arrivalAirport,
    arrivalAirportName: flight.arrivalAirportName,
    arrivalDateTime: `${flight.arrivalDate} ${flight.arrivalTime}`,
    departureAirport: flight.departureAirport,
    departureAirportName: flight.departureAirportName,
    departureDateTime: `${flight.departingDate} ${flight.departingTime}`,
    departureTerminal: flight.departureTerminal,
    arrivalTerminal: flight.arrivalTerminal,
    arrivalCity: flight.arrivalCity,
    departureCity: flight.departureCity,
    alliance: flight.alliance,
    duration: {
      hours: flight.flightTime?.hours!,
      minutes: flight.flightTime?.minutes!,
    },
    departureCountry: flight.departureCountry,
    arrivalCountry: flight.arrivalCountry,
    flyTime: flight.flyTime,
    aircraftType: flight.aircraftType,
    capacity: flight.capacity,
    flightNumber: flight.flightNumber,
    marketingAirline: flight.marketingAirline,
    operatingAirline: flight.operatingAirline,
    fareClass: flight.fareClass,
    flightBookingKey: flight.flightBookingKey,
    seatsSelectionAvailable: flight.seatsSelectionAvailable,
    carryOnBaggageOptions,
    checkedBaggageOptions,
    includedCarryOnBaggage: carryOnBaggageOptions.find(option => option.includedInFare),
    includedCheckedBaggage: checkedBaggageOptions.find(option => option.includedInFare),
    status: flight.status,
    itemType: 'flightV2',
    hasStopover: ((flight.stopoverTime?.hours ?? 0) + (flight.stopoverTime?.minutes ?? 0)) > 0,
    stopoverTime: {
      hours: flight.stopoverTime?.hours ?? 0,
      minutes: flight.stopoverTime?.minutes ?? 0,
    },
    departingTime: flight.departingTime,
    departingDate: flight.departingDate,
    arrivalTime: flight.arrivalTime,
    arrivalDate: flight.arrivalDate,
    bookingClass: flight.bookingClass,
    seatSummary: {
      selectionAvailable: flight.seatSummary.selectionAvailable,
      selectionPolicy: flight.seatSummary.selectionPolicy,
    },
  }
}

function mapFlightGroup(flightGroup: FlightModels.Search.FlightGroup): App.FlightGroup {
  return {
    id: flightGroup.flightGroupId,
    flightGroupNumber: flightGroup.flightGroupNumber,
    flights: flightGroup.flights.map(mapFlight),
    totalStopOverTime: {
      hours: flightGroup.totalStopOverTime?.hours ?? 0,
      minutes: flightGroup.totalStopOverTime?.minutes ?? 0,
    },
    totalTravelDuration: {
      hours: flightGroup.totalTravelDuration.hours,
      minutes: flightGroup.totalTravelDuration.minutes,
    },
    totalFlightDuration: {
      hours: flightGroup.totalFlightDuration.hours,
      minutes: flightGroup.totalFlightDuration.minutes,
    },
    numberOfStops: flightGroup.numberOfStops,
  }
}

import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'

export const StyledModalContent = styled.div`
  padding: ${rem(20)};

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)};
  }

  &:empty {
    display: none;
  }
`

interface ModalContentProps extends PropsWithChildren {
  className?: string
}

/**
 * @description This component is the wrapper for modal content that applies proper paddings.
 * It should never come before `ModalHeader.tsx` or after `ModalFooter.tsx`.
 * @see `Modal.tsx` for usage
 * */
function ModalContent(props: ModalContentProps) {
  const { children, className } = props

  return <StyledModalContent className={className}>
    {children}
  </StyledModalContent>
}

export default ModalContent

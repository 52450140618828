import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import useListTruncator from 'hooks/useListTruncator'
import React, { ComponentProps, MouseEventHandler, ReactElement, useCallback } from 'react'
import SearchTileRowInclusionItem from './SearchTileRowInclusionItem'

type InheritedSearchTileRowInclusionItemProps = Pick<ComponentProps<typeof SearchTileRowInclusionItem>, 'lineClamp' | 'isHighlighted'>

interface Props extends InheritedSearchTileRowInclusionItemProps {
  inclusionsTitle?: string | ReactElement
  inclusions: Array<App.OfferInclusion>
  /**
   * The number of items to display before the rest are truncated.
   *
   * If set to zero, nothing will be displayed.
   */
  truncateAfter?: number
  onShowMoreClick: () => void
}

function SearchTileStackInclusionsList({
  lineClamp,
  isHighlighted,
  inclusionsTitle,
  inclusions,
  truncateAfter,
  onShowMoreClick,
}: Props) {
  const [alwaysVisibleInclusions, collapsibleInclusions] = useListTruncator(truncateAfter, inclusions)

  const handleMoreClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault()
    onShowMoreClick()
  }, [onShowMoreClick])

  if (!alwaysVisibleInclusions.length) return null

  return <Group className={SearchTileStackInclusionsList.name} direction="vertical" gap={4}>
    {!!inclusionsTitle && <BodyText variant="medium" weight="bold">{inclusionsTitle}</BodyText>}
    {alwaysVisibleInclusions.map((inclusion) => <SearchTileRowInclusionItem
      key={inclusion.id}
      inclusion={inclusion}
      isHighlighted={isHighlighted}
      lineClamp={lineClamp}
    />)}
    {!!collapsibleInclusions.length && <TextLink size="medium" onClick={handleMoreClick}>+{collapsibleInclusions.length} more</TextLink>}
  </Group>
}

export default SearchTileStackInclusionsList

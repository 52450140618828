import React from 'react'
import LuxPlusInclusions from 'luxPlus/components/LuxPlusInclusions'

interface Props {
  luxPlusInclusions: Array<App.PackageInclusion>;
  offer: App.CruiseOffer;
}

function CruiseLuxPlusInclusionList({ luxPlusInclusions, offer } : Props) {
  const inclusionsWithPerCabinDescription = luxPlusInclusions.map((inclusion) => ({
    ...inclusion,
    description: `${inclusion.description} per cabin`,
  }))

  return <>
    {!!luxPlusInclusions.length && <LuxPlusInclusions
      luxPlusInclusions={inclusionsWithPerCabinDescription}
      showMinStayDescription
      isModalPrimaryActionHidden
      accommodationOffer={offer}
    />}
  </>
}

export default CruiseLuxPlusInclusionList

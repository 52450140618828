import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineListUlAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3.5 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm4 2h14a1 1 0 1 0 0-2h-14a1 1 0 0 0 0 2Zm0 3a.9998.9998 0 0 0-.7071 1.7071A.9998.9998 0 0 0 8.5 12a1 1 0 0 0-1-1Zm4 5a1 1 0 1 0-.0002 2 1 1 0 0 0 .0002-2Zm10-5h-10a1.0001 1.0001 0 0 0 0 2h10a1.0001 1.0001 0 0 0 0-2Zm0 5h-6a1.0001 1.0001 0 0 0 0 2h6a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineListUlAltIcon

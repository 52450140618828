import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTvRetroIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 6h-3.59l2.3-2.29a1.0052 1.0052 0 0 0 .2177-1.0943 1.0055 1.0055 0 0 0-.5435-.5434 1.0036 1.0036 0 0 0-1.0942.2176L12 5.54l-1.17-2a1.0038 1.0038 0 0 0-1.37-.37 1.0033 1.0033 0 0 0-.37 1.37L10 6H6a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3v1a.9998.9998 0 0 0 1 1 .9997.9997 0 0 0 1-1v-1h8v1a1 1 0 1 0 2 0v-1a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3Zm1 10.9999a1 1 0 0 1-1 1H6a.9997.9997 0 0 1-1-1v-8a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8Z"/>
  </SvgIcon>
}

export default LineTvRetroIcon

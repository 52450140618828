import OfferInclusionItem from 'components/Common/Inclusions/OfferInclusionItem'
import Group from 'components/utils/Group'
import React from 'react'

interface Props {
  showHighlight?: boolean
  inclusions: Array<App.OfferInclusion>
  truncateLength?: boolean
  /**
   * @default 8
   */
  gap?: 0 | 2 | 4 | 8 | 12
}

function TourV2ItemInclusions(props: Props) {
  const { inclusions, showHighlight, truncateLength, gap = 8 } = props

  return <Group direction="vertical" gap={gap}>
    {inclusions.map((inclusion) => <OfferInclusionItem
      key={inclusion.id}
      inclusion={inclusion}
      isHighlighted={showHighlight && inclusion.isHighlighted}
      lineClamp={truncateLength ? 1 : undefined}
    />)}
  </Group>
}

export default TourV2ItemInclusions

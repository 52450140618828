import cn from 'clsx'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const FlagImg = styled.img`
  flex-shrink: 0;
  border-radius: ${props => props.theme.borderRadius.round};

  &.size-XS  {
    height: ${rem(16)};
    width: ${rem(16)};
  }

  &.size-S {
    height: ${rem(20)};
    width: ${rem(20)};
  }

  &.size-M {
    height: ${rem(24)};
    width: ${rem(24)};
  }

  &.size-L {
    height: ${rem(32)};
    width: ${rem(32)};
  }

  &.size-XL {
    height: ${rem(40)};
    width: ${rem(40)};
  }
`

interface Props extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'height' | 'width'> {
  /** Case insensitive country code for the flag you want, e.g. AU (au) or NZ (nz) */
  countryCode: string;
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL';
}

function Flag(props: Props) {
  const {
    className,
    countryCode,
    size = 'M',
    ...imgProps
  } = props

  return <FlagImg
    {...imgProps}
    className={cn(className, `size-${size}`)}
    src={getSVGImageUrl(`round-flag-${countryCode.toUpperCase()}`)}
    alt={imgProps.alt || `${countryCode} flag`}
  />
}

export default Flag

import { createSelector } from 'reselect'
import getCheckoutInsuranceProducts from './getCheckoutInsuranceProducts'
import getCheckoutInsuranceQuotes from './getCheckoutInsuranceQuotes'
import isInsuranceSupported from '../request/isInsuranceSupported'

const getIsCheckoutInsuranceFetching = createSelector(
  isInsuranceSupported,
  getCheckoutInsuranceProducts,
  getCheckoutInsuranceQuotes,
  (insuranceSupported, products, quotes): boolean => {
    if (!insuranceSupported) {
      return false
    }

    // Insurance is supported, we're expecting *some* products
    return products.length === 0 || quotes.length === 0
  },
)

export default getIsCheckoutInsuranceFetching

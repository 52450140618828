import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWashingMachineIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5.0078 6.0059c0-.5523.4477-1 1-1h1c.5523 0 1 .4477 1 1 0 .5522-.4477 1-1 1h-1c-.5523 0-1-.4478-1-1ZM10 5.0059c-.5523 0-1 .4477-1 1 0 .5522.4477 1 1 1h1c.5523 0 1-.4478 1-1 0-.5523-.4477-1-1-1h-1Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M17.1958 15.3121a5.4906 5.4906 0 0 0 .3042-1.8082c0-3.0376-2.4624-5.5-5.5-5.5s-5.5 2.4624-5.5 5.5 2.4624 5.5 5.5 5.5c2.4031 0 4.4462-1.5412 5.1949-3.6891l.0019-.0015-.001-.0012Zm-8.6947-1.8983a3.5497 3.5497 0 0 0-.0011.0901c0 1.933 1.567 3.5 3.5 3.5a3.4877 3.4877 0 0 0 2.3888-.9419c-1.2454-.2148-2.0665-1.0191-2.6636-1.604-.0418-.0409-.0825-.0808-.1222-.1193-.7224-.7014-1.2143-1.1043-2.0282-1.1043-.4375 0-.7107.0451-.8747.0925-.1367.0396-.188.0778-.199.0869Zm.8312-2.1757a7.674 7.674 0 0 1 .2425-.0037c1.6884 0 2.6976.9667 3.4213 1.6693.7797.7569 1.2141 1.1666 1.9238 1.2094.2422.0146.409.018.5245.0153a3.52 3.52 0 0 0 .0556-.6245c0-1.933-1.567-3.5-3.5-3.5-1.0688 0-2.0257.4791-2.6677 1.2342Z" clipRule="evenodd"/>
    <path fill="currentColor" fillRule="evenodd" d="M2 5c0-1.6568 1.3432-3 3-3h13.9999c1.6568 0 3 1.3432 3 3v14c0 1.6569-1.3432 3-3 3H5c-1.6569 0-3-1.3431-3-3V5Zm3-1c-.5523 0-1 .4477-1 1v14c0 .5523.4477 1 1 1h13.9999c.5522 0 1-.4477 1-1V5c0-.5523-.4478-1-1-1H5Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineWashingMachineIcon

export const isPayFullyNowAvailable = (offer: App.CarHireOffer) => {
  return !!offer.rateOptions.find((option) => !!option.payNowAmount && !option.payOnArrivalAmount)
}

export const isPayFullyOnArrivalAvailable = (offer: App.CarHireOffer) => {
  return !!offer.rateOptions.find((option) => !option.payNowAmount && !!option.payOnArrivalAmount)
}

export const isPayPartiallyAvailable = (offer: App.CarHireOffer) => {
  return !!offer.rateOptions.find((option) => !!option.payNowAmount && !!option.payOnArrivalAmount)
}

export const getRateOptionByRateType = (offer: App.CarHireOffer, rateOptionType: App.CarHireRateOptionTypes) => {
  return offer.rateOptions.find((option) => {
    return (rateOptionType === 'payFullyNow' && !!option.payNowAmount && !option.payOnArrivalAmount) ||
      (rateOptionType === 'payFullyOnArrival' && !option.payNowAmount && !!option.payOnArrivalAmount) ||
      (rateOptionType === 'payPartiallyNow' && !!option.payNowAmount && !!option.payOnArrivalAmount)
  })
}

export const getRateOptionType = (option: App.CarHireOffer['rateOptions'][number]): App.CarHireRateOptionTypes => {
  if (!!option.payNowAmount && !option.payOnArrivalAmount) return 'payFullyNow'
  if (!option.payNowAmount && !!option.payOnArrivalAmount) return 'payFullyOnArrival'
  return 'payPartiallyNow'
}

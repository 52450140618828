import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import HeadData from 'components/Common/HeadData'
import Heading from 'components/Luxkit/Typography/Heading'
import ImageHero from 'components/Common/ImageHero'
import Group from 'components/utils/Group'
import Search from 'home/components/Search/Search'
import GeoContext from 'contexts/geoContext'
import { getHomeOfferListParams } from 'home/helpers'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'

const HeroContainer = styled(ImageHero)`
  max-height: ${rem(400)};
`

function Error404PageWithOffers() {
  const { currentRegionCode, geoStateName } = useContext(GeoContext)

  const filters = useMemo(() => getHomeOfferListParams({
    region: currentRegionCode,
    subregion: geoStateName,
  }), [currentRegionCode, geoStateName])

  return (
    <div className="Error404Page T-Error404Page">
      <HeadData
        title="Page Not Found"
        description="404 - page not found"
      />
      <HeroContainer
        alt="Oops! We can’t find that page"
        aria-hidden="true"
        brightness={-40}
        gravity="auto"
        fit="center"
        quality="good"
        mobileAspectRatio="4:3"
        desktopAspectRatio="3:1"
        largeDesktopAspectRatio="4:1"
        id="hc71fr4ynk0ljlqnw399"
      >
        <Group data-testid="404-error-page" direction="vertical" horizontalAlign="center" gap={8}>
          <Heading variant="heading1" align="center" colour="neutral-eight">Oops! We can’t find that page.</Heading>
          <Heading variant="heading4" align="center" colour="neutral-eight">Let’s get you back on your journey.</Heading>
        </Group>
      </HeroContainer>
      <Search />
      <OfferCardCarouselSection title={<>Explore our <i>top offers</i></>} filters={filters}/>
    </div>
  )
}

export default React.memo(Error404PageWithOffers)

import { itemsAreLE } from 'checkout/lib/utils/accommodation/cart'
import { groupBy, last, max, min, sum } from 'lib/array/arrayUtils'
import {
  isAccommodationItem,
  isBNBLLEHotelItem,
  isBedbankItem,
  isCruiseItem,
  isInstantBookingHotelItem,
  isInstantBookingLEHotelItem,
  isLEHotelItem,
  isTourV1Item,
  isTourV2Item,
  isCruiseV1,
  isInstantLEHotelPostPurchaseItem,
  isBNBLHotelSetDatesItem,
} from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import offerPageURL from 'lib/offer/offerPageURL'
import { pluralizeToString } from 'lib/string/pluralize'
import { getConfidenceLabels, getLabels } from 'checkout/lib/utils/accommodation/label'
import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import { excludeNullOrUndefined } from 'checkout/utils'
import { getDepositAmountPercentage } from 'checkout/lib/utils/accommodation/deposit'
import { isSalesforceTourV2 } from 'lib/offer/offerUtils'
import { getTourV2ItemView } from 'checkout/lib/utils/tours/view'
import { getLEPackageItemView } from 'checkout/lib/utils/accommodation/view'
import { getBedbankRateKey, itemsAreBedbank } from 'checkout/lib/utils/bedbank/cart'
import { getBedbankItemView } from 'checkout/lib/utils/bedbank/view'
import { getDefaultDepositAmountPercentage, getDepositServiceFeeConfig } from '../featureConfig/deposit'
import { getFlightTotals } from 'checkout/selectors/payment/flights'
import { checkoutWithMemberPrice, isMemberOrHasSubscriptionInTheCart } from 'checkout/selectors/view/luxPlusSubscription'
import {
  LE_CONTRACTED_HOTEL_TYPES,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
} from 'constants/offer'
import { getCruiseBreakdownView } from 'checkout/lib/utils/cruises/view'
import { getCheckoutCruiseOfferView } from './cruise'
import { getTourItems, getTourV2BreakdownView, getTourV2ExperienceItems, getTourV2Items } from './toursv2'
import { getHotelBreakdownView } from './hotels'

import { getFlightItems } from './flights'
import { getFullOffers } from 'selectors/offerSelectors'
import { itemsAreTourV2 } from 'checkout/lib/utils/tours/cart'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import config from 'constants/config'
import {
  CHECKOUT_ITEM_TYPE_CRUISE,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  POST_PURCHASE_TOUR_CHANGE_DATES,
  POST_PURCHASE_TOUR_EXPERIENCE,
} from 'constants/checkout'
import { getCheckoutLuxPlusTier } from '../checkoutLuxPlusSelectors'
import { CheckoutPageId } from 'checkout/constants/pages'
import { countTravellersAsOccupants } from 'lib/offer/occupancyUtils'
import { getExperienceItems, getTransferItemsView } from './experience'
import { getVillaItems } from './villa'
import { getCarHireItems } from './carHire'
import { diffInDays } from 'lib/datetime/dateUtils'
import { getDefaultAirport } from 'selectors/flightsSelectors'
import { isLEOffer } from 'lib/offer/offerTypes'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { getCommissionData } from './agentHub'
import moment from 'moment'
import { getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'

const isPostPurchaseAncillaryPayment = createSelector(
  (state) => state.checkout.cart.postPurchase,
  (postPurchase) => postPurchase === 'deposit-balance' ||
  postPurchase === 'instalment-balance' ||
  postPurchase === 'instalment' ||
  postPurchase === 'reserve-balance' ||
  postPurchase === POST_PURCHASE_TOUR_EXPERIENCE,
)

const hidePrices = createSelector(
  isPostPurchaseAncillaryPayment,
  (state: App.State) => state.checkout.cart.postPurchase === POST_PURCHASE_TOUR_CHANGE_DATES,
  (state: App.State) => state.router.location.pathname,
  (isAncillaryPayment, isPostPurchaseTourDateChange, pathname) => isAncillaryPayment || isPostPurchaseTourDateChange || last(pathname.split('/')) === CheckoutPageId.LuxPlus,
)

export function checkoutAccommodationGroupingKey(item: App.Checkout.AccommodationItem): string {
  if (isBNBLLEHotelItem(item)) {
    return `${item.offerId}-bnbl-${item.duration}`
  }

  else if (isTourV2Item(item)) {
    return `${item.offerId}-${item.purchasableOption.fkDepartureId}`
  }

  else if (isCruiseItem(item)) {
    return `${item.offerId}-${item.departureId}`
  }

  else if (item.itemType === 'tourV1') {
    return `${item.offerId}-${item.startDate}-${item.endDate}`
  }

  else {
    return `${item.offerId}-${item.checkIn}-${item.checkOut}`
  }
}

const getCartAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.AccommodationItem> => items.filter(isAccommodationItem),
)

const getOrderAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.checkout.cart.postPurchase,
  (existingOrder, postPurchase): Array<App.Checkout.AccommodationItem> => {
    if (existingOrder) {
      return [
        ...existingOrder.items.flatMap<App.Checkout.AccommodationItem>((item) => {
          if ('roomRateId' in item.package && item.reservationType === 'instant_booking' && item.reservation) {
            return [{
              itemId: item.id,
              transactionKey: item.transactionKey,
              offerId: item.offer.id,
              duration: item.duration || 0,
              itemType: 'hotel',
              packageId: item.package.id,
              roomRateId: item.package.roomRateId,
              reservationType: item.reservationType,
              checkIn: item.reservation.startDate,
              checkOut: item.reservation.endDate,
              occupancy: {
                adults: item.reservation.adults,
                children: item.reservation.children,
                infants: item.reservation.infants,
                childrenAge: item.reservation.childrenAge,
              },
            }]
          } else {
            return []
          }
        }),
        ...existingOrder.bedbankItems.flatMap<App.Checkout.BedbankHotelItem>((item) => {
          return item.rooms.map(room => ({
            itemId: item.id,
            sessionId: '',
            roomId: item.roomTypeId,
            checkIn: moment(item.checkIn).format(ISO_DATE_FORMAT),
            checkOut: moment(item.checkIn).format(ISO_DATE_FORMAT),
            roomRateId: item.roomRateId,
            isFlightBundle: room.isFlightBundle,
            bedGroupId: '',
            transactionKey: item.transactionKey,
            offerId: item.offer.id,
            duration: item.duration,
            newPrice: item.total,
            itemType: 'bedbankHotel',
            occupancy: room.occupancy,
          }))
        }),
        ...existingOrder.tourItems.flatMap<App.Checkout.TourV2Item>(item => {
          const isPostPurchaseTourOptionalExperience = postPurchase === POST_PURCHASE_TOUR_EXPERIENCE
          const travellersByRooms = Array.from(groupBy(
            item.tour.travellers,
            (traveller) => traveller.roomNumber,
          ).values())
          return travellersByRooms.map((travellers: Array<Tours.TourV2OrderItemTravellerDetails>, index: number) => {
            // as tour item is the total of all travellers, we only want to show the total price on the first item
            const total = index === 0 ? item.total : 0
            return {
              startDate: item.tour.startDate,
              endDate: item.tour.endDate,
              occupancy: countTravellersAsOccupants(travellers),
              duration: item.tour.duration,
              image: item.tour.images?.[0] ?? {},
              itemId: item.itemId,
              itemType: CHECKOUT_ITEM_TYPE_TOUR_V2,
              offerId: item.tourId,
              transactionKey: item.transactionKey,
              purchasableOption: {
                fkDepartureId: item.departureId,
                fkSeasonId: item.tour.seasonId,
                fkTourId: item.tourId,
                roomType: travellers[0].roomType as Tours.V2OfferRoomType,
                fkVariationId: item.tourOptionId ?? '',
                inventoryLeft: 0, // not stored on the order item
                fullPrice: isPostPurchaseTourOptionalExperience ? 0 : total,
                price: isPostPurchaseTourOptionalExperience ? 0 : total,
                memberPrice: isPostPurchaseTourOptionalExperience ? 0 : item.memberPrice,
                packageType: item.packageOption?.packageOption,
              },
            } as App.Checkout.TourV2Item
          })
        }),
      ]
    }

    return []
  },
)
export const getAccommodationItems = createSelector(
  getCartAccommodationItems,
  getOrderAccommodationItems,
  isPostPurchaseAncillaryPayment,
  (accommodationItemsFromCart, accommodationItemsFromOrder, isAncillaryPayment): Array<App.Checkout.AccommodationItem> => {
    return isAncillaryPayment ? accommodationItemsFromOrder : accommodationItemsFromCart
  },
)

export const getTourV2CheckoutItems = createSelector(
  getAccommodationItems,
  (items): Array<App.Checkout.TourV2Item> => items.filter(isTourV2Item),
)

export const getInsuranceEnabledCheckoutItems = createSelector(
  getAccommodationItems,
  (state: App.State) => state.offer.offers,
  (items, leOffers): Array<App.Checkout.InstantBookingItem> => {
    return items
      .filter(isInstantBookingHotelItem)
      .filter(item => isEnabledForFeature(config.UNIVERSAL_CHECKOUT_INSURANCE_ENABLED_LIST, item.itemType))
      .filter(item => {
        if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1 || item.itemType === CHECKOUT_ITEM_TYPE_CRUISE) {
          return isCruiseV1(item, leOffers)
        }
        return true
      })
  },
)

export const getLeAccommodationItems = createSelector(
  getAccommodationItems,
  (items): Array<App.Checkout.LEHotelItem> => items.filter(isLEHotelItem),
)

export const getLeInstantPostPurchaseItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InstantBookingLEHotelPostPurchaseItem> => items.filter(isInstantLEHotelPostPurchaseItem),
)

export const getBNBLHotelPostPurchaseItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.BNBLLEHotelSetDatesItem> => items.filter(isBNBLHotelSetDatesItem),
)

export const getLeInstanceBookingItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InstantBookingLEHotelItem> => items.filter(isInstantBookingLEHotelItem),

)

export const getBedbankItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.BedbankHotelItem> => items.filter(isBedbankItem),
)

const groupedAccommodationItemMap = createSelector(
  getAccommodationItems,
  (items): Map<string, Array<App.Checkout.AccommodationItem>> => {
    return groupBy(items, checkoutAccommodationGroupingKey)
  },
)

function pkgMatch(item: App.Checkout.LEAccommodationItem) {
  return (pkg: App.Package): boolean => {
    return pkg.id === item.packageId && pkg.duration === item.duration
  }
}

export const checkoutAccommodationOfferView = createSelector(
  groupedAccommodationItemMap,
  getDepositServiceFeeConfig,
  getCheckoutCruiseOfferView,
  getDefaultDepositAmountPercentage,
  getFullOffers,
  (state: App.State) => state.offer.bedbankOfferRates,
  (state: App.State) => state.calendar.calendarsByOccupancy,
  (state: App.State) => state.offer.offerAvailableRatesByOccupancy,
  (state: App.State) => state.businessTraveller.offersCredits,
  getFlightItems,
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.checkout.cart.existingOrder,
  getCheckoutLuxPlusTier,
  checkoutWithMemberPrice,
  // This is temporary until LPP member pricing is fully released
  getIsLuxPlusLPPEnabled,
  (
    itemMap,
    serviceFee,
    cruiseView,
    defaultDepositAmountPercentage,
    offers,
    bedbankRatesByOffer,
    calendarsByOccupancy,
    availableRatesByOccupancy,
    offerCredits,
    flightItems,
    postPurchase,
    existingOrder,
    luxPlusTier,
    checkoutWithMemberPrice,
    luxPlusLPPEnabled,
  ): App.WithDataStatus<Array<App.Checkout.AccommodationOfferView>> => {
    let hasRequiredData = true
    const offerViews = Array.from(itemMap.values()).map(items => {
      const offerId = items[0].offerId
      const isBundledWithFlightItems = items.some(item => flightItems.some(flightItem => flightItem.bundledItemIds?.includes(item.itemId)))
      if (itemsAreLE(items)) {
        const offer = offers[offerId] as App.Offer
        const firstItem = items[0]

        // The items could be different packages, but since they're grouped by duration/dates,
        // we know the items in each have the same duration. So we can look up the duration label
        // of the first item.
        const durationLabel = firstItem.itemType === 'tourV1' ?
          pluralizeToString('day', firstItem.duration) :
          offer?.packages?.find?.(pkgMatch(firstItem))?.durationLabel ?? pluralizeToString('night', firstItem.duration)

        const itemViewsWithStatuses = items.map(item => getLEPackageItemView(
          item,
          calendarsByOccupancy,
          availableRatesByOccupancy,
          checkoutWithMemberPrice,
          offer,
          offerCredits,
          postPurchase,
          existingOrder,
          luxPlusTier,
        ))
        const itemViews = itemViewsWithStatuses.map(i => i.data)
        hasRequiredData = hasRequiredData && itemViewsWithStatuses.every(i => i.hasRequiredData)

        const offerView: App.Checkout.LEAccommodationOfferView = {
          offerId,
          offer,
          ...(offer ? { offerPageUrl: offerPageURL(offer) } : {}),
          ...getLabels(offer),
          bedGroups: [],
          confidenceLabels: getConfidenceLabels(offer),
          duration: firstItem.duration,
          startDate: (firstItem as any).startDate ?? (firstItem as any).checkIn,
          endDate: (firstItem as any).endDate ?? (firstItem as any).checkOut,
          image: offer?.image,
          location: getLocationString(offer),
          ...(offer?.property?.geoData && {
            locationCountry: offer.property.geoData?.country ?? undefined,
            locationCountryCode: offer.property.geoData?.countryCode ?? undefined,
          }),
          occupancy: items.map(item => (item as any).occupancy).filter(excludeNullOrUndefined),
          offerType: offer?.type,
          // operator?: string;
          reservationType: (firstItem as any).reservationType ?? 'instant_booking',
          urgencyLabels: offer?.urgencyTags ?? [],
          saleUnit: offer?.saleUnitLong,
          durationLabel: durationLabel.toLocaleLowerCase(),
          offerLoaded: !!offer,
          itemViews,
          propertyTimezone: offer?.property?.timezone ?? '',
          bundledWithFlightsOnly: offer?.bundledWithFlightsOnly,
          designation: 'accommodation',
          depositType: 'percentage',
          depositAmount: getDepositAmountPercentage(offer, defaultDepositAmountPercentage),
          serviceFee,
          isBundled: isBundledWithFlightItems,
        }

        return offerView
      }
      else if (itemsAreBedbank(items)) {
        const offer = offers[offerId] as App.BedbankOffer
        const firstItem = items[0]

        const groupedItems = groupBy(items, (item) => item.sessionId ?? 'none')

        let bedbankOfferRate: App.BedbankRate | undefined
        const itemViewsWithStatusesAndBedGroups = items.map(item => {
          const groupData = item.sessionId ? groupedItems.get(item.sessionId) ?? [] : []
          const occupancy = groupData.length > 0 ? groupData.map(item => item.occupancy) : [item.occupancy]
          const rateKey = getBedbankRateKey(occupancy, firstItem.checkIn, firstItem.checkOut)
          const bedbankOfferRates = bedbankRatesByOffer[offerId]?.[rateKey]
          const bedbankRatesFetched = bedbankRatesByOffer[offerId] && (rateKey in bedbankRatesByOffer[offerId])
          bedbankOfferRate = bedbankOfferRates?.find(rate => rate.id === item.roomRateId && rate.isFlightBundle === item.isFlightBundle)

          return {
            bedGroups: bedbankOfferRate?.bedGroups ?? [],
            itemViewsWithStatuses: getBedbankItemView(item, luxPlusLPPEnabled, offer, bedbankOfferRate, offerCredits, postPurchase, existingOrder, bedbankRatesFetched),
          }
        })
        const itemViews = itemViewsWithStatusesAndBedGroups.map(i => i.itemViewsWithStatuses.data)
        const bedGroups = itemViewsWithStatusesAndBedGroups.flatMap(i => i.bedGroups)
        hasRequiredData = hasRequiredData && itemViewsWithStatusesAndBedGroups.every(i => i.itemViewsWithStatuses.hasRequiredData)

        const offerView: App.Checkout.BedbankAccommodationOfferView = {
          offerId,
          offer,
          ...(offer ? { offerPageUrl: offerPageURL(offer) } : {}),
          ...getLabels(offer),
          bedGroups,
          confidenceLabels: [],
          duration: firstItem.duration,
          startDate: firstItem.checkIn,
          endDate: firstItem.checkOut,
          image: offer?.image,
          location: getLocationString(offer),
          ...(offer?.property && {
            locationCountry: offer.property.address.countryName,
            locationCountryCode: offer.property.address.countryCode,
          }),
          occupancy: items.map(item => item.occupancy).filter(excludeNullOrUndefined),
          offerType: offer?.type,
          reservationType: 'instant_booking',
          urgencyLabels: [],
          saleUnit: offer?.saleUnitLong,
          durationLabel: `${firstItem.duration} nights`,
          offerLoaded: !!offer,
          itemViews,
          propertyTimezone: offer?.property?.timezone,
          bundledWithFlightsOnly: false,
          designation: 'accommodation',
          isBundled: isBundledWithFlightItems,
          hasMobilePromotion: !!bedbankOfferRate?.mobilePromotion,
        }

        return offerView
      } else if (itemsAreTourV2(items)) {
        const offer = offers[offerId] as Tours.TourV2Offer
        const firstItem = items[0]
        const { startDate, endDate, duration, image, purchasableOption } = firstItem

        const itemViewsWithStatuses = items.filter(isTourV2Item).map(
          (item, index) => getTourV2ItemView(item, index, offer, postPurchase, existingOrder),
        )
        const itemViews = itemViewsWithStatuses.map(i => i.data)
        hasRequiredData = hasRequiredData && itemViewsWithStatuses.every(i => i.hasRequiredData)
        const variationId = offer?.variations[purchasableOption.fkVariationId] ?
          purchasableOption.fkVariationId :
          Object.values(offer?.variations ?? {}).find(variation => variation.fkTourOptionId === purchasableOption.fkVariationId)?.id
        const offerView: App.Checkout.TourV2AccommodationOfferView = {
          offerId,
          ...(offer ? { offerPageUrl: offerPageURL(offer) } : {}),
          ...getLabels(offer, variationId),
          bedGroups: [],
          confidenceLabels: [],
          duration,
          startDate,
          endDate,
          image,
          location: getLocationString(offer, variationId),
          occupancy: items.map(item => item.occupancy).filter(excludeNullOrUndefined),
          offerType: 'tour_v2',
          reservationType: 'instant_booking',
          urgencyLabels: [],
          saleUnit: 'room',
          durationLabel: pluralizeToString('day', duration),
          offerLoaded: !!offer,
          itemViews,
          propertyTimezone: 'GMT',
          operatorName: offer ? `Operated by: ${offer.operatedBy}` : '',
          offer,
          designation: 'Tour',
          depositType: offer?.depositType,
          depositAmount: offer?.depositAmount ?? undefined,
          serviceFee: isSalesforceTourV2(offer) ? serviceFee : undefined,
          isBundled: isBundledWithFlightItems,
        }

        return offerView
      }
    })

    hasRequiredData = hasRequiredData && cruiseView.hasRequiredData
    const result = [...offerViews, ...cruiseView.data].filter(excludeNullOrUndefined)

    return {
      hasRequiredData,
      data: result,
    }
  },
)

export const getAccommodationBreakdownView = createSelector(
  checkoutAccommodationOfferView,
  getFlightTotals,
  hidePrices,
  (state: App.State) => state.checkout.cart.postPurchase,
  isMemberOrHasSubscriptionInTheCart,
  getCommissionData,
  (offerViewsWithStatus, flightsTotals, hidePrice, postPurchase, isMemberOrHasSubscriptionInTheCart, commissionData): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (!offerViewsWithStatus.hasRequiredData) { return { hasRequiredData: false, data: [] } }

    const breakdownViews = offerViewsWithStatus.data.map<App.Checkout.PriceBreakdownView | null>(offerView => {
      switch (offerView.offerType) {
        case OFFER_TYPE_CRUISE:
          return getCruiseBreakdownView(offerView, hidePrice)
        case OFFER_TYPE_TOUR_V2:
          return getTourV2BreakdownView(offerView, hidePrice, postPurchase, commissionData)
        case OFFER_TYPE_ALWAYS_ON:
        case OFFER_TYPE_LAST_MINUTE:
        case OFFER_TYPE_TOUR: // Not enabled yet
        case OFFER_TYPE_BED_BANK:
        case OFFER_TYPE_HOTEL:
          return getHotelBreakdownView(offerView, flightsTotals, hidePrice, isMemberOrHasSubscriptionInTheCart, commissionData)
        default:
          return null
      }
    }).filter(excludeNullOrUndefined)

    return {
      hasRequiredData: true,
      data: breakdownViews,
    }
  },
)

export const getHotelTotalPrice = createSelector(
  getAccommodationBreakdownView,
  getFlightTotals,
  (views, flightsTotal) => {
    return sum(views.data.filter(view => view.productType && LE_CONTRACTED_HOTEL_TYPES.includes(view.productType)), view => view.price) - flightsTotal.data.price
  })

export const getAccommodationOccupantsFromCart = createSelector(
  getAccommodationItems,
  (items): App.Occupants | undefined => {
    if (items.length) {
      return items.reduce((acc, item) => {
        if ('occupancy' in item && item.occupancy) {
          return {
            adults: acc.adults + item.occupancy.adults,
            children: acc.children + (item.occupancy.children || 0),
            infants: acc.infants + (item.occupancy.infants || 0),
          }
        }
        return acc
      }, { adults: 0, children: 0, infants: 0 })
    }
  },
)

export const getAccommodationStartDate = createSelector(
  getAccommodationItems,
  (items): string | undefined => {
    const startDates = items
      .map(item => {
        if (isInstantBookingLEHotelItem(item) || isBedbankItem(item)) {
          return item.checkIn
        }
        if (isTourV1Item(item) || isTourV2Item(item)) {
          return item.startDate
        }
      }).filter(excludeNullOrUndefined)
    return min(startDates, (date) => new Date(date))
  },
)

export const getAccommodationEndDate = createSelector(
  getAccommodationItems,
  (items): string | undefined => {
    const startDates = items
      .map(item => {
        if (isInstantBookingLEHotelItem(item) || isBedbankItem(item)) {
          return item.checkOut
        }
        if (isTourV1Item(item) || isTourV2Item(item)) {
          return item.endDate
        }
      }).filter(excludeNullOrUndefined)

    return max(startDates, (date) => new Date(date))
  },
)

export const isCheckoutAccommodationItemsOnly = createSelector(
  checkoutAccommodationOfferView,
  getExperienceItems,
  getFlightItems,
  getTransferItemsView,
  getVillaItems,
  getCarHireItems,
  getTourV2Items,
  getTourItems,
  getTourV2ExperienceItems,
  (accommodationViews, experienceItems, flightItems, transferItems, villaItems, carHireItems, tourV2Items, tourItems, tourV2ExperienceItems): boolean => {
    return !!accommodationViews.data.length &&
      !experienceItems.length &&
      !flightItems.length &&
      !transferItems.data.length &&
      !villaItems.length &&
      !carHireItems.length &&
      !tourV2Items.length &&
      !tourItems.length &&
      !tourV2ExperienceItems.length &&
      !flightItems.length
  },
)

export const canShowAddFlightsForHotelItems = createSelector(
  checkoutAccommodationOfferView,
  getDefaultAirport,
  (accommodationViews, defaultUserAirport): boolean => {
    if (accommodationViews.hasRequiredData && !accommodationViews.data.length) return false

    return accommodationViews.data.every(view => view.offerType === OFFER_TYPE_HOTEL) &&
      accommodationViews.data.every(view => view.itemViews.every(itemView => isInstantBookingLEHotelItem(itemView.item))) &&
      accommodationViews.data.every(view => view.startDate && diffInDays(new Date(), new Date(view.startDate)) <= 330) &&
      accommodationViews.data.every(view => isLEOffer(view.offer) && view.offer.flightDestinationPort !== defaultUserAirport?.code)
  },
)

import { EmptyArray } from 'lib/array/arrayUtils'
import { useMemo } from 'react'

function splitList<T>(list: Array<T>, at: number): [leading: Array<T>, trailing: Array<T>] {
  return [
    list.slice(0, at),
    list.slice(at),
  ]
}

function useListTruncator<T>(
  /**
   * The number of items to display before the rest are truncated.
   *
   * If set to zero, everything will be truncated.
   */
  truncateAfter: number | undefined,
  list: Array<T>,
): [remainder: Array<T>, truncated: Array<T>] {
  return useMemo(() => {
    if (truncateAfter === undefined || !list.length || list.length <= truncateAfter) {
      return [list, EmptyArray]
    }
    if (truncateAfter === 0) {
      return [EmptyArray, EmptyArray]
    }
    return splitList(list, truncateAfter)
  }, [list, truncateAfter])
}

export default useListTruncator

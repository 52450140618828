import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { PICKER_DATE_FORMAT } from 'constants/dateFormats'
import moment, { Moment } from 'moment'
import React from 'react'

interface Props {
  checkInDate?: Moment;
  checkOutDate?: Moment;
}

function FormattedFlightSearchDate(props: Props) {
  const { checkInDate, checkOutDate } = props

  return <Group direction="horizontal" gap={12} verticalAlign="center">
    <BodyText variant="medium" weight="semi-bold">{moment(checkInDate).format(PICKER_DATE_FORMAT)}</BodyText>
    <LineArrowRightIcon />
    <BodyText variant="medium" weight="semi-bold">{moment(checkOutDate).format(PICKER_DATE_FORMAT)}</BodyText>
  </Group>
}

export default FormattedFlightSearchDate

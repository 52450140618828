import React, { ComponentProps } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import _Button from './BaseButton'

const StyledBaseButton = styled(_Button)`
  width: var(--button-size);

  > svg:first-of-type {
    flex-shrink: 0;
    height: var(--button-icon-size, auto);
    width: var(--button-icon-size, auto);
  }

  &.shape-square {
    --button-border-radius: ${props => props.theme.borderRadius.S};
  }

  &.shape-circle {
    --button-border-radius: ${props => props.theme.borderRadius.round};
  }

  &.size-small {
    --button-size: ${rem(28)};
    --button-icon-size: ${rem(20)};
    --button-outdent: ${rem(4)};
  }

  &.size-medium {
    --button-size: ${rem(40)};
    --button-icon-size: ${rem(20)};
    --button-outdent: ${rem(8)};
  }

  &.size-large {
    --button-size: ${rem(48)};
    --button-icon-size: ${rem(24)};
    --button-outdent: ${rem(12)};
  }

  &.outdent-left {
    transform: translateX(calc(var(--button-outdent) * -1));
  }

  &.outdent-right {
    transform: translateX(var(--button-outdent));
  }
`

export const ICON_BUTTON_SHAPES = ['square', 'circle'] as const
export const ICON_BUTTON_DEFAULT_SHAPE = ICON_BUTTON_SHAPES[0]
export const ICON_BUTTON_SIZES = ['small', 'medium', 'large'] as const
export const ICON_BUTTON_DEFAULT_SIZE = ICON_BUTTON_SIZES[1]

interface Props extends ComponentProps<typeof _Button> {
  /** Place the icon this button should show here */
  children: React.ReactNode
  /**
   * Outdent, or the inverse of indent, removes the 'padding' around the buttons in the direct chosen
   * so they can visually align with the 'edge'. Mostly used for tertiary buttons that have no border/background
   * */
  outdent?: 'left' | 'right'
  /** The general shape of the button, square will have a minor border radius, circle will be fully round */
  shape?: typeof ICON_BUTTON_SHAPES[number]
  /** Determines the height/width of the button as well as the size of icons if they are provided */
  size?: typeof ICON_BUTTON_SIZES[number]
}

const IconButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    className,
    outdent,
    shape = ICON_BUTTON_DEFAULT_SHAPE,
    size = ICON_BUTTON_DEFAULT_SIZE,
    ...rest
  } = props
  return <StyledBaseButton
    {...rest}
    ref={ref}
    className={cn(
      className,
      `size-${size}`,
      `shape-${shape}`,
      outdent ? `outdent-${outdent}` : undefined,
    )}
  />
})

IconButton.displayName = 'IconButton'

export default IconButton

import cn from 'clsx'
import { DurationOption } from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker/DurationPicker'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import config from 'constants/config'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import moment from 'moment'
import React, { RefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import FlexibleDateSearchField from './FlexibleDateSearchField'
import SearchDateDropDown from './SearchDateDropDown'

interface Props {
  dropdownAnchorRef: RefObject<HTMLElement>;
  onDateToggle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDatesApply: () => void;
  onDatesDecline: () => void;
  isToggled: boolean;
  anytimeDateSelected?: boolean;
  selectAnytimeDate?: (shouldApply: boolean) => void;
  selectSpecificDates?: () => void;
  selectFlexibleDates?: () => void;
  dateSearchOptionId?: DATE_SEARCH_OPTION_IDS;
  durationOptionsCustom?: Array<DurationOption>;
  isSpecificSearchEnabled?: boolean;
  className?: string;
  showFlexibleYearSelector?: boolean;
  closeMenu?: () => void
}

function SearchDateInputDesktop(props: Props) {
  const {
    dropdownAnchorRef,
    onDateToggle,
    isToggled,
    onDatesApply,
    onDatesDecline,
    anytimeDateSelected,
    selectAnytimeDate,
    selectSpecificDates,
    selectFlexibleDates,
    dateSearchOptionId,
    durationOptionsCustom,
    isSpecificSearchEnabled = true,
    className,
    showFlexibleYearSelector,
    closeMenu,
  } = props
  const { checkinDate, checkoutDate, flexibleMonths, flexibleNights, userSelectedFlexibleMonths } = useContext(GlobalSearchStateContext)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  /**
   * When user only select checkin date, and close the dropdown, dismiss that value
   */
  const toggleDates = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isToggled) {
      onDateToggle(e)
    }
  }, [isToggled, onDateToggle])

  useEffect(() => {
    if (!isToggled) {
      if (!checkinDate) {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKIN_DATE })
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE })
      }
      if (flexibleMonths && flexibleNights) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: DATE_SEARCH_OPTION_IDS.FLEXIBLE })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggled])

  const datePlaceholder = config.businessTraveller.currentAccountMode === 'business' ? 'Select date' : 'Anytime'

  const checkInFieldRef = useRef<HTMLButtonElement>(null)
  const checkOutFieldRef = useRef<HTMLButtonElement>(null)
  const checkInAndOutFieldsRefs = useMemo(() => [checkInFieldRef, checkOutFieldRef], [])

  return (
    <>
      {dateSearchOptionId === DATE_SEARCH_OPTION_IDS.ANYTIME && <SearchFormField
        label="When?"
        placeholder="Anytime"
        onClick={onDateToggle}
      />}
      {dateSearchOptionId === DATE_SEARCH_OPTION_IDS.SPECIFIC && <SearchFormFieldGroup>
        <SearchFormField
          ref={checkInFieldRef}
          className={cn({ 'focus-within': isToggled && !checkoutDate && !checkinDate })}
          label="Check in"
          onClick={toggleDates}
          placeholder={datePlaceholder}
          value={checkinDate ? moment(checkinDate).format(DMY_CASUAL_FORMAT) : undefined}
        />
        <SearchFormField
          ref={checkOutFieldRef}
          className={cn({ 'focus-within': isToggled && checkinDate && !checkoutDate })}
          label="Check out"
          onClick={toggleDates}
          value={checkoutDate ? moment(checkoutDate).format(DMY_CASUAL_FORMAT) : undefined}
          placeholder={datePlaceholder}
        />
      </SearchFormFieldGroup>
      }
      {dateSearchOptionId === DATE_SEARCH_OPTION_IDS.FLEXIBLE && <FlexibleDateSearchField
        className={className}
        onDateToggle={onDateToggle}
        flexibleMonths={userSelectedFlexibleMonths ? flexibleMonths : undefined }
        flexibleNightsId={flexibleNights}
        durationOptionsCustom={durationOptionsCustom}
      />}
      <SearchDateDropDown
        anchorRef={dropdownAnchorRef}
        triggerRef={checkInAndOutFieldsRefs}
        open={isToggled}
        onDatesApply={onDatesApply}
        onDatesDecline={onDatesDecline}
        anytimeDateSelected={anytimeDateSelected}
        selectAnytimeDate={selectAnytimeDate}
        selectSpecificDates={selectSpecificDates}
        selectFlexibleDates={selectFlexibleDates}
        dateSearchOptionId={dateSearchOptionId}
        durationOptionsCustom={durationOptionsCustom}
        isSpecificSearchEnabled={isSpecificSearchEnabled}
        showFlexibleYearSelector={showFlexibleYearSelector}
        closeMenu={closeMenu}
      />
    </>
  )
}

export default SearchDateInputDesktop

import { rem } from 'polished'
import React, { Dispatch, useEffect, useRef, ChangeEvent } from 'react'
import styled from 'styled-components'

import FirstTime from '../Copy'

import TextInput from 'components/Common/Form/Input/TextInput'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'
import { MAX_TRIP_NAME_CHARS } from 'tripPlanner/config'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: ${rem(8)};

  .error-count {
    align-self: start;
  }
`

interface NameTripProps {
  setErrorMsg: Dispatch<React.SetStateAction<string>>
  setTripName: Dispatch<React.SetStateAction<string>>
  onSubmit?: (name: string) => void
  firstTime: boolean
  tripName: string
  errorMsg: string
}

function NameTrip({
  setErrorMsg,
  setTripName,
  onSubmit,
  firstTime,
  tripName,
  errorMsg,
}: NameTripProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg('')
    setTripName(e.currentTarget.value)
  }

  useEffect(() => {
    // Ideally we'd just put a ref on the input itself, but it's
    // based on an old class component, so too difficult.
    containerRef.current?.querySelector('input')?.focus()
  }, [])

  return (
    <Container ref={containerRef}>
      {firstTime && <FirstTime />}
      <Group direction="vertical" gap={4}>
        <TextInput
          placeholder="e.g. Bali Trip"
          maxLength={MAX_TRIP_NAME_CHARS}
          label="Trip name"
          onChange={onInputChange}
          data-testid="modal-trip-input"
          value={tripName}
          onKeyDown={(e) => {
            if (e.code === 'Enter' && tripName.length > 0) {
              onSubmit?.(tripName)
            }
          }}
          noValidationMessage
          noValidationSpacing
          required
        />
        <Group direction="horizontal" horizontalAlign="space-between">
          <Caption as="span" variant="medium" colour="critical">
            {errorMsg}
          </Caption>
          <Caption as="span" variant="large" colour="neutral-three">
            {tripName.length}/{MAX_TRIP_NAME_CHARS}
          </Caption>
        </Group>
      </Group>
    </Container>
  )
}

export default NameTrip

import { rem } from 'polished'
import styled from 'styled-components'

export const NewBadge = styled.span`
  margin-left: ${rem(4)};
  background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  padding: 0 ${rem(2)};
  border-radius: ${props => props.theme.borderRadius.S};
  font-weight: ${props => props.theme.font.primary.weight.bold};
  /* This has not been designed to match luxkit yet, and needs specific values */
  font-size: 10px;
  line-height: 13px;
  vertical-align: text-top;
`

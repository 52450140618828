import { toggleCustomerView } from 'actions/AgentHubActions'
import SwitchButton from 'components/Common/SwitchButton'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import React, { useCallback } from 'react'

function AgentHubCustomerViewSwitch() {
  const customerViewEnabled = useAppSelector(state => state.agentHub.customerView)
  const dispatch = useAppDispatch()

  const onToggle = useCallback(() => {
    dispatch(toggleCustomerView())
  }, [dispatch])

  return <SwitchButton
    label="Customer view"
    toggled={customerViewEnabled}
    onToggle={onToggle}
  />
}

export default AgentHubCustomerViewSwitch

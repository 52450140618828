import React, { RefObject } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import CruiseLocationDesktopInput from './CruiseLocationDesktopInput'
import CruiseLocationMobileInput from './CruiseLocationMobileInput'

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void
  /** Use when this component is inside a drawer modal */
  drawerMode?: boolean
  dropdownAnchorRef: RefObject<HTMLElement>
}

function CruiseLocationFilter({ drawerMode, onChange, dropdownAnchorRef }: Props) {
  return <div>
    <CSSBreakpoint max="mobile">
      <CruiseLocationMobileInput
        onChange={onChange}
      />
    </CSSBreakpoint>

    <CSSBreakpoint min="tablet">
      {drawerMode && <CruiseLocationMobileInput
        onChange={onChange}
      />}

      {!drawerMode && <CruiseLocationDesktopInput
        dropdownAnchorRef={dropdownAnchorRef}
        onChange={onChange}
      />}
    </CSSBreakpoint>
  </div>
}

export default CruiseLocationFilter

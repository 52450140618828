export enum OptimizelyExperiments {
  paymentsFlightsMerchantFees = 'payments_flight_merchant_fees',
  tripExplore = 'trip_explore',
  cruiseNewBookingFlow = 'cruise_new_cruisebank_booking_flow_may_2024',
  flightPreFillRecentSearch = 'flights_pre_fill_recent_search',
  isSupportAssistantEnabled = 'opex_support_assistant_customer_pool',
  paymentsHotelsDepositsAu = 'payments_hotels_deposits_au_v2',
  paymentsHotelsDepositsNonAu = 'payments_hotels_deposits_non_au',
  pricePerNight = 'cro_price_per_night_non_au_nz',
  lereSearchModelV2 = 'lere_search_model_v2',
  bedbankChangeDatesEnabled = 'opex_bedbank_change_dates',
  searchClusterMapPins = 'search_cluster_map_pins',
  supportAssistantWidgetEnabled = 'opex_lesa_widget',
  expediaStyleLoginPrompt = 'expedia_style_login_prompt',
  emailChangeEnabled = 'opex_email_change',
  signUpModalTitleEnabled = 'cro_sign_up_modal_without_save_up_to_70',
  croAppPromoInSgHk = 'cro_app_promo_in_sg_hk',
  refundProtectForInternationalEnabled = 'cro_refund_protect_vs_insurance_international',
  croBedbankOptionSortOrder = 'cro_bedbank_option_sort_order',
  superSlimTilesForOfferListPage = 'super_slim_tiles_for_offer_list_page',
  croHotelFlightsToggleV2Enabled = 'cro_hotel_flights_toggle_v2',
  croOneClickAirportTransfers = 'cro_one_click_airport_transfers_3_0',
  lereRegionRecommendations = 'lere_region_recommendation',
  offerPageSecondChanceModal = 'offer_page_second_chance_modal',
  searchPageSecondChanceModal = 'search_page_second_chance_modal',
  croBookNowButtonOfferPageCopy = 'cro_book_now_button_on_offer_page_copy',
  specificPaidLandingPage = 'specific_paid_landing_page',
  croOfferEndingSoonUrgencyTag = 'cro_offer_ending_soon_urgency_tag',
  lereRecommendedDestinations = 'lere_recommended_destinations',
  croPhoneNumberRequiredOnSignUp = 'cro_phone_number_required_on_sign_up',
  searchSlimMapViewTiles = 'search_slim_map_tiles',
  searchHotelOfferImpressions='search_hotel_offer_impressions',
  searchHotelClickOffer='search_click_hotel_offer'
}

export enum OptimizelyFeatureFlags {
  luxLoyaltyFeatureToggle = 'lux_loyalty_feature_toggle',
  luxPlusFeatureToggle = 'luxplus_feature_toggle',
  luxPlusToursToggle = 'luxplus_tours_toggle',
  luxPlusDiscountedInsuranceBenefit = 'lux_plus_discounted_insurance_benefit',
  luxPlusDiscountedBookingProtectionBenefit = 'lux_plus_discounted_booking_protection_benefit',
  luxPlusLppPricingEnabled = 'luxplus_lpp_pricing_enabled',
  luxPlusCruiseInclusionsEnabled = 'lux_plus_cruise_inclusions',
  isGoCardlessEnabled = 'payments_go_cardless',
  allowPhoneNumberLogin = 'allow_phone_number_login',
  promoAppBanner = 'app_banner_targeted_delivery',
  CSAgentsAutoQuote = 'cro_cs_agents_auto_quote',
  tourOptionalExtras = 'tours_optional_extras',
  tourReviewsEnabled = 'cro_tour_reviews',
  toursRequestAgentCallbackEnabled = 'csx_tours_request_agent_callback',
  hotelsAndVillasRequestAgentCallbackEnabled = 'csx_hotels_and_villas_request_agent_callback',
  cancellationOutsidePolicyEnabled = 'opex_cancellation_outside_refund_policy',
  tourOptionalExtraPostPurchase = 'tour_optional_extra_post_purchase',
  searchStreamingLE = 'search_streaming_le',
  searchStreaming = 'search_streaming',
  searchStreamingSortByPrice = 'search_streaming_sort_by_price',
  isCruiseMultiCabinBookingEnabled = 'cruise_multi_cabin_booking',
  flashExtendDatesEnabled = 'opex_flash_extend_dates',
  upsellTilesFeatureToggle = 'cro_upsell_on_homepage_and_my_escapes',
  croNewFlightsSaleCarouselEnabled = 'cro_flights_sale_carousel',
  csxOutsidePolicyChangeDatesRequestEnabled = 'csx_outside_policy_change_dates_request',
  croTravelPreferencesModalEnabled = 'cro_travel_preferences_modal',
  standaloneAirportTransfersEnabled = 'csx_standalone_airport_transfer_flow',
  croTravellerCompanionsEnabled = 'cro_traveller_companions',
  croUseCfmrInsuranceBookingProtection = 'cro_use_cfmr_insurance_booking_protection',
}

export const SUPPORT_DEFAULT_EMAIL = 'support@luxuryescapes.com'
export const SUPPORT_INDIAN_MARKET_EMAIL = 'support-india@luxuryescapes.com'
export const SUPPORT_INDIAN_MARKET_PREFIX = 'IN'

export const SUPPORT_EMAIL_FOR_BRAND = {
  luxuryescapes: 'support@luxuryescapes.com',
  cudotravel: 'support@cudo.com.au',
  dealstravel: 'support@deals.com.au',
  scoopontravel: 'support@scoopon.com.au',
  treatmetravel: 'support@treatme.co.nz',
  lebusinesstraveller: 'support@lebusinesstraveller.com',
  leagenthub: 'support@luxuryescapes.com',
}

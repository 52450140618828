import React, { PropsWithChildren } from 'react'

import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import config from 'constants/config'

interface Props {
  inverse?: boolean
  fallback?: React.ReactNode
}

function TripGuard(props: PropsWithChildren<Props>) {
  const { children, fallback = null, inverse } = props

  if (!!config.ENABLE_TRIP_PLANNER === !!inverse) {
    return <>{fallback}</>
  }

  return (
    // Trip is often embedded in various components and should never
    // 'inherit' another components palette - it is a separate app. Enforce this
    // by default the default palette
    <ProductPaletteProvider palette="default">
      {children}
    </ProductPaletteProvider>
  )
}

export default TripGuard

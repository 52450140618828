import React, { useCallback, useContext, useRef, ComponentProps } from 'react'
import { StandaloneFlightMenu } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchTravellerSelectContent from '../FlightTravellerSelect/FlightSearchTravellerSelectContent'
import FlightSearchTravellerSelectField from '../FlightTravellerSelect/FlightSearchTravellerSelectField'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'

interface Props extends Pick<ComponentProps<typeof DropdownSheet>, 'anchorRef'> {
  className?: string;
  disabled?: boolean;
}

function FlightSearchTravellerSelect(props: Props) {
  const { className, anchorRef, disabled } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const { activeMenu } = state

  const setActiveMenu = useCallback((activeMenu?: StandaloneFlightMenu) => {
    dispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU, activeMenu })
  }, [dispatch])

  const onApply = useCallback(() => {
    setActiveMenu()
  }, [setActiveMenu])

  const onCancel = useCallback(() => {
    setActiveMenu()
  }, [setActiveMenu])

  const handleOpen = useCallback(() => {
    setActiveMenu(activeMenu === 'occupants' ? undefined : StandaloneFlightMenu.occupants)
  }, [setActiveMenu, activeMenu])

  const triggerRef = useRef<HTMLButtonElement>(null)

  return (
    <div className={className}>
      <FlightSearchTravellerSelectField
        onClick={handleOpen}
        ref={triggerRef}
        disabled={!!disabled}
        data-testid="travellers"
      />

      <DropdownSheet
        size="M"
        open={activeMenu === StandaloneFlightMenu.occupants}
        onClose={onCancel}
        anchorRef={anchorRef}
        triggerRef={triggerRef}
        primaryActionProps={{
          'data-testid': 'apply-flight-search-changes',
          type: 'submit',
          children: 'Apply',
          onClick: onApply,
        }}
      >
        <FlightSearchTravellerSelectContent />
      </DropdownSheet>
    </div>
  )
}

export default FlightSearchTravellerSelect

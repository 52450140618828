import FormatCurrency from 'components/Common/FormatCurrency'
import OfferInclusionItem from 'components/Common/Inclusions/OfferInclusionItem'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LabeledSheet from 'components/Luxkit/LabeledSheet'
import TextLink from 'components/Luxkit/TextLink'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { INCLUSIONS_TITLE_LUXPLUS } from 'constants/inclusions'
import useListTruncator from 'hooks/useListTruncator'
import { isLEOffer } from 'lib/offer/offerTypes'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { rem } from 'polished'
import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import LuxPlusLabelWithModal from './LuxPlusLabelWithModal'

const List = styled(VerticalSpacer)`
  padding: ${rem(8)};
`

interface MappedProps {
  subscription: App.ProductSubscription;
  isLuxPlusEnabled: boolean;
}

type InheritedOfferInclusionItemProps = Pick<ComponentProps<typeof OfferInclusionItem>, 'showMinStayDescription'>

interface Props extends InheritedOfferInclusionItemProps {
  luxPlusInclusions: Array<App.OfferInclusion>
  accommodationOffer?: App.Offer | App.OfferSummary | App.CruiseOffer;
  hideUpsellModal?: boolean;
  isModalPrimaryActionHidden?: boolean;
  /**
   * The number of items to display before the rest are truncated.
   *
   * If set to zero, nothing will be displayed.
   */
  truncateAfter?: number
  onShowMoreClick?: () => void
}

function LuxPlusInclusions({
  luxPlusInclusions,
  showMinStayDescription,
  isLuxPlusEnabled,
  accommodationOffer,
  subscription,
  hideUpsellModal,
  isModalPrimaryActionHidden,
  truncateAfter,
  onShowMoreClick,
}: Props & MappedProps) {
  const [alwaysVisibleInclusions, collapsibleInclusions] = useListTruncator(truncateAfter, luxPlusInclusions)
  const handleMoreClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault()
    onShowMoreClick?.()
  }, [onShowMoreClick])

  if (!isLuxPlusEnabled || !alwaysVisibleInclusions.length) return null

  return <LabeledSheet
    data-testid="lux-plus-bonus-inclusions"
    kind="lux-plus"
    label={
      <>
        <CSSBreakpoint not="tablet">
          <LuxPlusLabelWithModal
            type="bonus-inclusions"
            inclusions={luxPlusInclusions}
            offer={accommodationOffer}
            hideUpsellModal={hideUpsellModal}
            isModalPrimaryActionHidden={isModalPrimaryActionHidden}
          />
        </CSSBreakpoint>
        <CSSBreakpoint only="tablet">
          <LuxPlusLabelWithModal
            type="only-logo"
            offer={accommodationOffer}
            inclusions={luxPlusInclusions}
            hideUpsellModal={hideUpsellModal}
            isModalPrimaryActionHidden={isModalPrimaryActionHidden}
            modalTitle={`Join ${LUXURY_PLUS.PROGRAM_NAME} to enjoy ${INCLUSIONS_TITLE_LUXPLUS} for this offer and more!`}
            modalSubTitle={<>
              Become a member of our VIP travel club for <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" />{' '}
              per year to unlock {INCLUSIONS_TITLE_LUXPLUS} when you book{' '}
              {isLEOffer(accommodationOffer) ? <b>{accommodationOffer?.property?.name}</b> : 'this hotel'}.
            </>}
          />
        </CSSBreakpoint>
      </>
    }
  >
    <List gap={4} data-testid="luxury-plus-inclusions">
      {alwaysVisibleInclusions.map((inclusion) => <OfferInclusionItem
        key={inclusion.id}
        inclusion={inclusion}
        type="lux-plus"
        showMinStayDescription={showMinStayDescription}
      />)}
      {(onShowMoreClick && !!collapsibleInclusions.length) && <TextLink size="medium" onClick={handleMoreClick}>+{collapsibleInclusions.length} more</TextLink>}
    </List>
  </LabeledSheet>
}

export default connect<MappedProps, {}, Props, App.State>((state: App.State) => {
  return {
    isLuxPlusEnabled: isLuxPlusEnabled(state),
    subscription: state.luxPlus.products.subscription,
  }
})(LuxPlusInclusions)

import React, { useMemo } from 'react'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import { getBundleValueUp } from 'lib/bundleAndSave/getBundleValueUp'
import { pluralizeToString } from 'lib/string/pluralize'
import { sum, unique } from 'lib/array/arrayUtils'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'

interface Props {
  offer: App.BundleOffer | App.BundleOfferSummary;
  pkg: App.BundlePackageWithPrice;
}

function HeroBundleAndSaveOfferTilePricing(props: Props) {
  const { offer, pkg } = props
  const {
    totalPrice,
    saleUnit,
    totalValue,
    valueOffPercent,
  } = useOfferPackagePrice(pkg, offer)

  const { shouldShowDiscountPercent, shouldShowValue } = getBundleValueUp(offer, pkg)

  const isDiscountPillHidden = offer.isDiscountPillHidden

  const duration = useMemo(() => {
    return sum(pkg.packages, (p) => p.duration)
  }, [pkg.packages])

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <Group direction="vertical">
        <PriceRowPriceCaption>
          {pluralizeToString('night', duration)}, 2 hotels from
        </PriceRowPriceCaption>
        <PriceRowPrice
          size="L"
          price={Math.ceil(totalPrice)}
          saleUnit={saleUnit}
        />
        <PriceRowTaxesAndFees />
        {shouldShowValue && <PriceRowValueDiscountWithCaption
          size="L"
          originalValue={Math.ceil(totalValue)}
          discountPercentage={shouldShowDiscountPercent && !isDiscountPillHidden ? valueOffPercent : undefined}
        />}
      </Group>
      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerId={offer.id}
        offerLocations={offerLocations}
        vendorName={vendorName}
      />
      <CSSBreakpoint min="desktop">
        <TextButton kind="primary">
          View offer
        </TextButton>
      </CSSBreakpoint>
    </Group>
  )
}

export default HeroBundleAndSaveOfferTilePricing

import { useDecimalFormatter } from 'hooks/useNumberFormatters'
import React, { memo } from 'react'

type InheritedDecimalFormatterOptions = NonNullable<Parameters<typeof useDecimalFormatter>[0]>

interface Props extends InheritedDecimalFormatterOptions {
  value: number
}

/**
 * Formats a given number into readable string. **(eg. 10000 => 10,000)**
 *
 * _Note: The hook version is `useDecimalFormatter` as `hooks/useNumberFormatters`._
 */
function FormatDecimal(props: Props) {
  const { value, ...options } = props
  const decimalFormat = useDecimalFormatter(options)

  return <>{decimalFormat(value)}</>
}

export default memo(FormatDecimal)

import { mediaQueryUp } from 'components/utils/breakpoint'
import styled from 'styled-components'

const HeroOfferTileWrapper = styled.div`
  display: grid;
  position: relative;
  height: 100%;
  grid-template-rows: min-content 1fr;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 67% 1fr;
    grid-template-rows: 1fr;
  }
`
export default HeroOfferTileWrapper

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineEnvelopeSendIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16 10.5H8a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-5a3 3 0 0 0-3-3Zm-.42 2-2.88 2.88a1 1 0 0 1-1.4 0L8.42 12.5h7.16Zm1.42 6a1.0001 1.0001 0 0 1-1 1H8a1.0002 1.0002 0 0 1-1-1v-4.59l2.88 2.87a2.9392 2.9392 0 0 0 2.12.89 2.9999 2.9999 0 0 0 2.12-.88L17 13.91v4.59Zm-8-12h3.59l-.3.29a1.001 1.001 0 0 0-.2958.71.9994.9994 0 0 0 .2958.71 1.001 1.001 0 0 0 .71.2958 1.0003 1.0003 0 0 0 .71-.2958l2-2a1.0007 1.0007 0 0 0 .21-.33c.1-.2435.1-.5165 0-.76a1.0008 1.0008 0 0 0-.21-.33l-2-2a1.0041 1.0041 0 0 0-1.42 1.42l.3.29H9a1 1 0 0 0 0 2Z"/>
  </SvgIcon>
}

export default LineEnvelopeSendIcon

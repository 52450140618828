import request, { authOptions } from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-svc-membership'
import asyncPoll from 'lib/promise/asyncPoll'
import { SUPPORTED_BENEFIT_TYPES } from 'luxPlus/constants/benefits'

const BASE_URI = '/api/membership'

/**
  * This section is all about getting and the subscription offer and mapping it to be used in the customer portal.
*/
type SubscriptionOfferResponse = paths['/api/membership/subscription-offers']['get']['responses'][200]['content']['application/json']
type SubscriptionOffer = SubscriptionOfferResponse['result'][0]
type SubscriptionOfferBenefits = SubscriptionOffer['benefits'][0]

function isSupportedBenefit(serverBenefit: SubscriptionOfferBenefits): boolean {
  return SUPPORTED_BENEFIT_TYPES.includes(serverBenefit.type)
}

function mapBaseBenefitsToLocal(serverBenefit: SubscriptionOfferBenefits): App.OfferSubscriptionBenefitItem {
  return {
    type: serverBenefit.type,
    title: serverBenefit.title,
    shortTitle: serverBenefit.shortTitle,
    description: serverBenefit.description,
    usedAmount: 0,
    allocatedAmount: serverBenefit.allocatedAmount,
  }
}

function mapServerSubscriptionToLocal(serverSubscription: SubscriptionOffer): App.SubscriptionOffer {
  return {
    type: 'subscription',
    id: serverSubscription.id,
    price: serverSubscription.price,
    region: serverSubscription.region,
    benefits: serverSubscription.benefits.filter(isSupportedBenefit).map(mapBaseBenefitsToLocal),
    tier: serverSubscription.tier ?? 'base',
    joinFee: serverSubscription.joiningFeeValue,
    joinFeeWaived: serverSubscription.joiningFee === 0,
    attachmentBands: (serverSubscription.attachmentBands ?? []) as Array<[number, number]>,
  }
}

export async function getSubscriptionOffer(region: string): Promise<Array<App.SubscriptionOffer>> {
  const params = new URLSearchParams({ region })
  const offer = await request.get<SubscriptionOfferResponse>(`/api/membership/subscription-offers?${params}`)
  return offer.result.map(mapServerSubscriptionToLocal)
}

/**
  * This section is all about getting and the customer subscriptions offer and mapping it to be used in the customer portal.
*/
type CustomerSubscriptionResponse = paths['/api/membership/customer-subscriptions']['get']['responses'][200]['content']['application/json']
type CustomerSubscription = CustomerSubscriptionResponse['result']
type CustomerSubscriptionBenefits = CustomerSubscription['currentBenefits'][0]
type RedeemedBenefit = CustomerSubscription['subscriptionPeriods'][0]['redeemedBenefits'][0]

function mapCustomerSubscriptionBenefitsToLocal(serverBenefit: CustomerSubscriptionBenefits): App.MemberSubscriptionBenefit {
  return {
    ...mapBaseBenefitsToLocal(serverBenefit),
    allocatedAmount: serverBenefit.allocatedAmount,
    usedAmount: serverBenefit.usedAmount,
    unit: serverBenefit.unit,
  }
}

function mapRedeemedBenefitToLocal(redeemedBenefit: RedeemedBenefit): App.RedeemedMembershipBenefit {
  return {
    id: redeemedBenefit.id,
    type: redeemedBenefit.type as App.LuxPlusBenefitType,
    subtype: redeemedBenefit.subtype ?? undefined,
    status: redeemedBenefit.status,
    description: redeemedBenefit.description ?? undefined,
    adjustment: redeemedBenefit.adjustment,
    unit: redeemedBenefit.unit,
    orderId: redeemedBenefit.orderId,
    itemType: redeemedBenefit.itemType,
    itemId: redeemedBenefit.itemId,
    updatedAt: redeemedBenefit.updatedAt,
  }
}

function mapCustomerSubscription(serverCustomerSubscription: CustomerSubscription): App.MemberSubscriptionItem {
  const activePeriod = serverCustomerSubscription.subscriptionPeriods.find(period => period.status === 'ACTIVE')
  return {
    id: serverCustomerSubscription.id,
    status: serverCustomerSubscription.status,
    region: serverCustomerSubscription.region,
    benefits: serverCustomerSubscription.currentBenefits.filter(isSupportedBenefit).map(mapCustomerSubscriptionBenefitsToLocal),
    renewalStatus: serverCustomerSubscription.renewalStatus,
    memberSince: serverCustomerSubscription.memberSince,
    expiryDate: serverCustomerSubscription.expiryDate,
    tier: serverCustomerSubscription.tier,
    redeemedBenefits: serverCustomerSubscription.subscriptionPeriods.flatMap(period => period.redeemedBenefits.map(mapRedeemedBenefitToLocal)),
    activeSubscription: activePeriod ? {
      id: activePeriod.id,
      redeemedBenefits: activePeriod.redeemedBenefits.map(mapRedeemedBenefitToLocal),
      offerId: activePeriod.subscriptionOffer.id,
      price: activePeriod.subscriptionOffer.price,
      startDate: activePeriod.startDate,
    } : undefined,
  }
}

/**
 * Retrieves the member subscription item for a given customer ID.
 *
 * @param {string} customerId - The ID of the customer.
 * @returns {Promise<App.MemberSubscriptionItem>} - A promise that resolves to the member subscription item.
 */
export async function getMemberSubscription(customerId: string, accessToken?: string): Promise<App.MemberSubscriptionItem> {
  const params = new URLSearchParams({ customerId })
  const subscription = await request.get<CustomerSubscriptionResponse>(`${BASE_URI}/customer-subscriptions?${params}`, authOptions(accessToken))
  return mapCustomerSubscription(subscription.result)
}

/**
 * This function is used to retrieve the member subscription item by polling until the status is not 'PENDING'.
 * It should only be used when purchasing a new membership to get the newly purchased subscription.
 *
 * @param {string} customerId - The ID of the customer.
 * @returns {Promise<App.MemberSubscriptionItem>} - A promise that resolves to the member subscription item.
 */
export async function getMemberSubscriptionPoll(customerId: string, accessToken?: string, validate?: (result: App.MemberSubscriptionItem) => boolean): Promise<App.MemberSubscriptionItem> {
  return await asyncPoll<App.MemberSubscriptionItem>({
    validateFunction: async(result) => {
      if (validate) return validate(result)
      return result.status !== 'CONFIRMED_AWAITING_PAYMENT'
    },
    apiCall: () => getMemberSubscription(customerId, accessToken),
    maxTime: 10000,
    pollInterval: 1000,
  })
}

export interface MemberSubscriptionUpdateData {
  op: App.MemberSubscriptionOperation
  reason?: {
    category: string
    explanation?: string
  }
}

interface UpdateMemberSubscriptionRenewalPayload extends MemberSubscriptionUpdateData{
  customerId: string
}

export async function updateMemberSubscriptionRenewal(customerId: string, data: MemberSubscriptionUpdateData): Promise<App.MemberSubscriptionItem> {
  const payload: UpdateMemberSubscriptionRenewalPayload = { customerId, ...data }
  const subscription = await request.put<CustomerSubscriptionResponse, UpdateMemberSubscriptionRenewalPayload>(`${BASE_URI}/customer-subscriptions?without_brand=1`, payload, { credentials: 'include' })
  return mapCustomerSubscription(subscription.result)
}

type FreePreviewEligibilityResponse = paths['/api/membership/preview-eligible-customers']['get']['responses'][200]['content']['application/json']

/**
 * This function is used to check if a member is eligible for a free preview.
 *
 * @param {string} customerId - The ID of the customer.
 * @returns {Promise<App.FreePreviewOffer>} - A promise that resolves to a boolean indicating if the member is eligible for a free preview.
 */
export async function getMemberFreePreviewEligibility(customerId: string, accessToken?: string): Promise<App.FreePreviewOffer> {
  const params = new URLSearchParams({ customerId })
  const freePreviewResponse = await request.get<FreePreviewEligibilityResponse>(`${BASE_URI}/preview-eligible-customers?${params}`, authOptions(accessToken))
  return freePreviewResponse.result
}

interface CreateFreePreviewMemberPayload {
  customerId: string;
  region: string;
}

/**
 * This function is used to create a free preview period for a member.
 *
 * @param {CreateFreePreviewMemberPayload} payload - The payload containing the customer ID, brand and region.
 * @returns {Promise<App.MemberSubscriptionItem>} - A promise that resolves to the member subscription item.
 */
export async function createMemberFreePreviewPeriod(payload: CreateFreePreviewMemberPayload, accessToken?: string): Promise<App.MemberSubscriptionItem> {
  const freePreviewPeriod = await request.post<CustomerSubscriptionResponse, CreateFreePreviewMemberPayload>(`${BASE_URI}/preview-periods`, payload, authOptions(accessToken))
  return mapCustomerSubscription(freePreviewPeriod.result)
}

import IconLoadingBox from 'components/Luxkit/Icons/IconLoadingBox'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { fillArray } from 'lib/array/arrayUtils'
import { randomIntegerBetween } from 'lib/maths/mathUtils'
import React, { useMemo } from 'react'

interface Props {
  /**
   * @default 6
   */
  benefitsCounts?: number
}

function LuxPlusBenefitsListLoadingSkeleton(props: Props) {
  const { benefitsCounts = 6 } = props

  const lineWidths = useMemo(() => {
    return fillArray(benefitsCounts, () => `${randomIntegerBetween(6, 10) * 4}ch` as const)
  }, [benefitsCounts])

  return <>
    {lineWidths.map((width, index) => <BodyTextBlock
      key={`${index}-${width}`}
      variant="medium"
      startIcon={<IconLoadingBox />}
    >
      <TextLoadingBox width={width} />
    </BodyTextBlock>)}
  </>
}

export default LuxPlusBenefitsListLoadingSkeleton

import React, { useMemo } from 'react'
import Heading from 'components/Luxkit/Typography/Heading'
import { LUX_BUNDLE_LABEL } from 'constants/productLabel'

interface Props {
  offer: App.BundleOffer;
}

function BundleOfferLocation(props: Props) {
  const bundledOffers = useMemo(() => Object.values(props.offer.bundledOffers), [props.offer.bundledOffers])

  const offerPropertiesTitle = useMemo(
    () => props.offer.location || bundledOffers.map(offer => offer.property.name).join(' + ')
    , [props.offer.location, bundledOffers])

  return (
    <Heading as="span" variant="heading6" colour="highlight-secondary">{LUX_BUNDLE_LABEL}:{' '}
      <Heading as="span" variant="heading6" >
        {offerPropertiesTitle}
      </Heading>
    </Heading>
  )
}

export default BundleOfferLocation

import BodyText from 'components/Luxkit/Typography/BodyText'
import React, { useContext } from 'react'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import TextLink from 'components/Luxkit/TextLink'
import ContactContext from 'contexts/contactContext'

interface Props {
  useLinkButton?: boolean
}

function CruiseSoldoutStillInterested(props: Props) {
  const { useLinkButton } = props
  const currentContact = useContext(ContactContext)
  const contact = currentContact.defaultContact

  return <Group direction="vertical" gap={12}>
    <div>
      <BodyText variant="medium" weight="bold">Still interested?</BodyText>
      <Group direction="horizontal" gap={12}>
        <BodyText variant="medium">Call us to be added to the waitlist</BodyText>

        {useLinkButton && <TextLink
          startIcon={<LinePhoneIcon />}
          size="medium"
          href={`tel:${contact.number}`}
        >
          {contact.humanReadable}
        </TextLink>}
      </Group>
    </div>

    {!useLinkButton && <TextButton
      kind="primary"
      href={`tel:${contact.number}`}
      startIcon={<LinePhoneIcon />}
    >
      Call {contact.humanReadable}
    </TextButton>}
  </Group>
}

export default CruiseSoldoutStillInterested

import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import ModalSheet from 'components/Luxkit/Modal/ModalSheet'
import ModalBackdrop from 'components/Luxkit/Modal/ModalBackdrop'
import ScrollLock from 'components/Common/ScrollLock'
import AccountAccessModalContent from './AccountAccessModalContent'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { modalClose, setAccountAccessMode } from 'actions/UiActions'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import { ModalElementContext, ModalElementContextValue } from 'contexts/ModalProvider'
import noop from 'lib/function/noop'
import * as Analytics from 'analytics/analytics'
import { getAccessModalAction } from 'lib/analytics/getAccessModalAction'

interface Props {
  utm: App.UTMState;
  modal: App.UiModalState;
  accountAccess: App.UiAccountModalState;
}

function AccountAccessSSRModal(props: Props) {
  const {
    accountAccess,
    modal,
    utm,
  } = props

  const dispatch = useAppDispatch()
  const { mode } = accountAccess
  const open = modal.open && modal.modalType === 'accountAccess'
  const loading = useAppSelector(state => state.auth.processing)
  const dismissable = utm.join !== '2' && modal.dismissable

  const onModeChange = useCallback((mode: App.UiAccountModalMode) => {
    dispatch(setAccountAccessMode(mode))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = useCallback(() => {
    if (!dismissable) return
    const action = getAccessModalAction(mode)
    if (action) {
      Analytics.trackClientEvent({
        subject: 'sign_up_dismiss',
        action,
        category: 'logging',
        type: 'operational',
      })
    }
    dispatch(modalClose())
  }, [dismissable, dispatch, mode])

  const contextValue = useMemo((): ModalElementContextValue<any> => ({
    open,
    afterClose: noop,
    resolve: closeModal,
  }), [closeModal, open])

  return (
    <>
      <ScrollLock isActive={open} />
      {open &&
        <ModalElementContext.Provider value={contextValue}>
          <ModalBackdrop mode="default" onClick={closeModal}>
            <ModalSheet
              mode="default"
              height="auto"
              tabletHeight="auto"
              size="S"
            >
              <LoadingIndicator visible={loading} floating />
              <AccountAccessModalContent
                mode={mode}
                dismissable={dismissable}
                onModeChange={onModeChange}
              />
            </ModalSheet>
          </ModalBackdrop>
        </ModalElementContext.Provider>
      }
    </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    accountAccess: state.ui.accountAccess,
    modal: state.ui.modal,
    utm: state.utm,
  }
}

export default connect(mapStateToProps)(AccountAccessSSRModal)

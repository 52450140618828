import { arrayToObject } from 'lib/array/arrayUtils'
import memoize from 'lib/memoize/memoize'

export const getCalenderDaysByCheckIn = memoize((data: App.Calendar) => arrayToObject(
  data.months.flatMap(m => m.days),
  date => date.checkIn,
))

export function getAvailableDayFromCalendar(
  checkIn: string,
  calenderData: App.Calendar,
  withFlights?: boolean,
) {
  const newDay = getCalenderDaysByCheckIn(calenderData)[checkIn]
  if (newDay) {
    const useFlights = withFlights && calenderData.flightCacheActive
    if (newDay.hotelAvailable && (!useFlights || newDay.flightsAvailable)) {
      return newDay
    }
  }
}

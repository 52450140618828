import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePrintIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7 10a.9998.9998 0 0 0-.7071 1.7071A.9998.9998 0 0 0 8 11a1 1 0 0 0-1-1Zm12-4h-1V3a1.0002 1.0002 0 0 0-1-1H7a1 1 0 0 0-1 1v3H5a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h1v3a1 1 0 0 0 1 1h10a1.0001 1.0001 0 0 0 1-1v-3h1a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3ZM8 4h8v2H8V4Zm8 16H8v-4h8v4Zm4-5a1.0001 1.0001 0 0 1-1 1h-1v-1a1.0001 1.0001 0 0 0-1-1H7a1.0002 1.0002 0 0 0-1 1v1H5a1.0002 1.0002 0 0 1-1-1V9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v6Z"/>
  </SvgIcon>
}

export default LinePrintIcon

import Group from 'components/utils/Group'
import React, { ComponentProps } from 'react'
import PriceRowLuxPlusNonMemberPrice from './PriceRowLuxPlusNonMemberPrice'
import PriceRowLuxPlusPriceBox, { PRICE_ROW_LUX_PLUS_PRICE_BOX_LABEL_HEIGHT } from './PriceRowLuxPlusPriceBox'
import PriceStackLuxPlusNonMemberPrice from './PriceStackLuxPlusNonMemberPrice'
import styled from 'styled-components'
import { rem } from 'polished'

const SpacedPriceStackLuxPlusNonMemberPrice = styled(PriceStackLuxPlusNonMemberPrice)`
  margin-top: ${rem(PRICE_ROW_LUX_PLUS_PRICE_BOX_LABEL_HEIGHT / 2)};
`

type InheritedPriceRowLuxPlusNonMemberPriceProps = Pick<ComponentProps<typeof PriceRowLuxPlusNonMemberPrice>, 'nonMemberPrice'>
type InheritedPriceRowLuxPlusPriceBoxProps = Pick<ComponentProps<typeof PriceRowLuxPlusPriceBox>, 'memberPrice' | 'saleUnit'>

interface Props extends InheritedPriceRowLuxPlusNonMemberPriceProps, InheritedPriceRowLuxPlusPriceBoxProps {
  memberPriceSignDisplay?: ComponentProps<typeof PriceRowLuxPlusPriceBox>['signDisplay']
  nonMemberPriceSignDisplay?: ComponentProps<typeof PriceRowLuxPlusNonMemberPrice>['signDisplay']
  size: 'S' | 'M' | 'L'
  orientation: 'horizontal' | 'vertical'
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center' | 'end'
  tabletHorizontalAlign?: 'start' | 'center' | 'end'
  desktopHorizontalAlign?: 'start' | 'center' | 'end'
}

const SIZES_NON_MEMBER_SIZE: Record<Props['size'], ComponentProps<typeof PriceRowLuxPlusNonMemberPrice>['size']> = {
  S: 'M',
  M: 'M',
  L: 'L',
}

const ORIENTATIONS_GAP: Record<Props['orientation'], ComponentProps<typeof Group>['gap']> = {
  horizontal: 8,
  vertical: 4,
}

function PriceStackLuxPlusProminentPrice(props: Props) {
  const {
    nonMemberPrice,
    nonMemberPriceSignDisplay,
    orientation,
    memberPrice,
    memberPriceSignDisplay,
    saleUnit,
    size,
    horizontalAlign,
    tabletHorizontalAlign,
    desktopHorizontalAlign,
    ...rest
  } = props

  return <Group
    className={PriceStackLuxPlusProminentPrice.name}
    direction={orientation}
    verticalAlign="center"
    gap={ORIENTATIONS_GAP[orientation]}
    horizontalAlign={horizontalAlign}
    tabletHorizontalAlign={tabletHorizontalAlign}
    desktopHorizontalAlign={desktopHorizontalAlign}
  >
    <PriceRowLuxPlusPriceBox
      {...rest}
      memberPrice={memberPrice}
      size={size}
      saleUnit={saleUnit}
      horizontalAlign={horizontalAlign}
      signDisplay={memberPriceSignDisplay}
    />
    {orientation === 'vertical' && <PriceRowLuxPlusNonMemberPrice
      nonMemberPrice={nonMemberPrice}
      signDisplay={nonMemberPriceSignDisplay}
      size={SIZES_NON_MEMBER_SIZE[size]}
    />}
    {orientation === 'horizontal' && <SpacedPriceStackLuxPlusNonMemberPrice
      nonMemberPrice={nonMemberPrice}
      signDisplay={nonMemberPriceSignDisplay}
      size={SIZES_NON_MEMBER_SIZE[size]}
    />}
  </Group>
}

export default PriceStackLuxPlusProminentPrice

import { paths } from '@luxuryescapes/contract-svc-lux-loyalty'
import { checkHasLuxLoyaltyAccount, isLuxLoyaltyEnabled } from 'luxLoyalty/selectors/featureToggles'
import {
  API_CALL,
} from './actionConstants'
import {
  ENROL_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
  FETCH_LUX_LOYALTY_POINTS_CALCULATION,
  FETCH_LUX_LOYALTY_PROGRAM_CONFIG,
} from './apiActionConstants'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { appViewMessageHandler } from 'lib/window/webViewUtils'
import {
  enrolLuxLoyaltyAccountRequest,
  fetchLuxLoyaltyAccountEligibilityRequest,
  fetchLuxLoyaltyAccountRequest,
  fetchLuxLoyaltyEarnPointsCalculationRequest,
  fetchLuxLoyaltyProgramConfigRequest,
} from 'api/luxLoyalty'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { AppAction } from './ActionTypes'
import { isLoyaltyProductTypeWithAdditionalCalculationOptions, luxLoyaltyServerProductTypeMapper } from 'api/mappers/luxLoyaltyMap'
import { isHotelCalculationWithAdditionalOptions, validateCalculatorOptions } from 'luxLoyalty/lib/pointsCalculation'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'

interface EnrolLuxLoyaltyAccount {
  accountMemberId?: string;
  token?: string;
  enableSnackbar?: boolean;
}

export function enrolLuxLoyaltyAccount({ accountMemberId, token, enableSnackbar }: EnrolLuxLoyaltyAccount): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = accountMemberId ?? state.auth.account.memberId
    const givenName = state.auth.account.givenName
    const headlessMode = state.config.headlessMode
    const hasLuxLoyaltyAccount = checkHasLuxLoyaltyAccount(state)
    const luxLoyaltyAccount = state.luxLoyalty.account

    let enrolled = false

    if (
      !isLuxLoyaltyEnabled(state) ||
      !memberId ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyAccount.fetching ||
      luxLoyaltyAccount.error
    ) return

    dispatch({
      type: API_CALL,
      api: ENROL_LUX_LOYALTY_ACCOUNT,
      request: () => enrolLuxLoyaltyAccountRequest({
        customerId: memberId,
      }, token)
        .then((result) => {
          enrolled = true

          if (enableSnackbar) {
            showSnackbar(
              `${givenName ? `${givenName}, you` : 'You'} have successfully joined ${LUX_LOYALTY.PROGRAM_NAME}!`,
              'success',
              {
                action: {
                  label: 'Learn more',
                  to: `/${LUX_LOYALTY.LANDING_PAGE}`,
                },
              },
            )
          }

          return result
        })
        .catch((err) => {
          if (enableSnackbar) {
            showSnackbar(
              'Please try again later or contact us for assistance.',
              'critical',
              { heading: 'Oops! Something went wrong' },
            )
          }

          throw err
        })
        .finally(() => {
          // Send message to mobile WebView
          if (headlessMode) {
            appViewMessageHandler('luxLoyalty', { enrolled })
          }
        }),
    })
  }
}

export function fetchLuxLoyaltyAccount(token?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const hasLuxLoyaltyAccount = checkHasLuxLoyaltyAccount(state)
    const luxLoyaltyAccount = state.luxLoyalty.account

    if (
      !isLuxLoyaltyEnabled(state) ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyAccount.fetching ||
      luxLoyaltyAccount.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_ACCOUNT,
      request: () => fetchLuxLoyaltyAccountRequest(token),
    })
  }
}

export function fetchLuxLoyaltyAccountEligibility(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId
    const hasLuxLoyaltyAccount = checkHasLuxLoyaltyAccount(state)
    const luxLoyaltyEligibility = state.luxLoyalty.eligibilityTier

    if (
      !isLuxLoyaltyEnabled(state) ||
      !memberId ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyEligibility.fetching ||
      luxLoyaltyEligibility.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
      request: () => fetchLuxLoyaltyAccountEligibilityRequest(memberId),
    })
  }
}

export function fetchLuxLoyaltyProgramConfig(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxLoyaltyProgramConfig = state.luxLoyalty.programConfig

    if (
      !isLuxLoyaltyEnabled(state) ||
      !!luxLoyaltyProgramConfig.data ||
      luxLoyaltyProgramConfig.fetching ||
      luxLoyaltyProgramConfig.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_PROGRAM_CONFIG,
      request: () => fetchLuxLoyaltyProgramConfigRequest(),
    })
  }
}

export function fetchLuxLoyaltyPointsCalculation(pointsKey: string, options: App.LuxLoyaltyPointsCalculatorOptions): AppAction {
  const { price, memberPrice, productType, margin } = options

  let packageId: string | undefined
  let offerId: string | undefined
  const hotelPointsCalculation = isHotelCalculationWithAdditionalOptions(options)

  if (hotelPointsCalculation) {
    offerId = options.offerId
    packageId = options.packageId
  }

  return (dispatch, getState) => {
    const state = getState()
    const pointsCalculation = state.luxLoyalty.pointsCalculator[pointsKey]
    const currentCurrency = state.geo.currentCurrency
    const serverLoyaltyProductType = luxLoyaltyServerProductTypeMapper(productType)
    const luxPlusEnabled = isLuxPlusEnabled(state)
    const calculationPrice = luxPlusEnabled && !!memberPrice ? memberPrice : price

    if (
      !isLuxLoyaltyEnabled(state) ||
      !!pointsCalculation?.data ||
      pointsCalculation?.fetching ||
      pointsCalculation?.error ||
      !pointsKey ||
      !serverLoyaltyProductType ||
      !validateCalculatorOptions(options)
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_POINTS_CALCULATION,
      pointsKey,
      request: () => fetchLuxLoyaltyEarnPointsCalculationRequest({
        pointsKey,
        price: calculationPrice,
        currency: currentCurrency,
        margin,
        customerId: state.auth.account.memberId,
        productDetails: getServerProductDetails(options.productType!, {
          offerId,
          packageId,
        }),
      }),
    })
  }
}

interface AdditionalDetails {
  offerId?: string
  packageId?: string
}
type CalculateLuxLoyaltyPointsPayload = paths['/api/lux-loyalty/points/calculate-earn']['post']['parameters']['body']['payload']

function getServerProductDetails(productType: App.LuxLoyaltyProductType, additionalDetails: AdditionalDetails): CalculateLuxLoyaltyPointsPayload['productDetails'] {
  const loyaltyProductType = luxLoyaltyServerProductTypeMapper(productType)!

  if (isLoyaltyProductTypeWithAdditionalCalculationOptions(loyaltyProductType)) {
    return {
      loyaltyProductType,
      offerId: additionalDetails.offerId ?? '',
      packageId: additionalDetails.packageId ?? '',
    }
  }

  return {
    loyaltyProductType,
  }
}

// @ts-nocheck - I will fix `useOpenModal.tsx` & `ViewState.tsx` in a separate PR
import { ComponentProps, ComponentType } from 'react'
import { ValueOf } from 'type-fest'

import Modalbase from 'components/Luxkit/Modal/ModalBase'
import { createReducer } from 'lib/redux/reducerUtils'

const BLANK_VIEW = { component: () => null, props: {} }

export type View<P extends {}> = {
  component: ComponentType<P>
  props: P
  config?: Pick<
    ComponentProps<typeof Modalbase>,
    'height' | 'tabletHeight' | 'size'
  >
}
export default interface ViewState {
  isModalOpen: boolean
  viewStack: Array<View<{}>>
  currentView: View<{}>
  hasManyViews: boolean
}

export enum ViewStateActions {
  MODAL_OPEN = 'MODAL_OPEN',
  MODAL_CLOSE = 'MODAL_CLOSE',
  MODAL_GO_BACK = 'MODAL_GO_BACK',
  MODAL_RESET = 'MODAL_RESET',
}

export type ViewAction = ValueOf<
  Utils.FullActionMap<{
    [ViewStateActions.MODAL_OPEN]: {
      view: View<{}>
    }
    [ViewStateActions.MODAL_CLOSE]: {}
    [ViewStateActions.MODAL_RESET]: {}
    [ViewStateActions.MODAL_GO_BACK]: {}
  }>
>

export const VIEW_INITIAL_STATE: ViewState = {
  isModalOpen: false,
  hasManyViews: false,
  currentView: BLANK_VIEW,
  viewStack: [],
}

export const viewStateReducer = createReducer<ViewState, ViewAction>(
  VIEW_INITIAL_STATE,
  {
    [ViewStateActions.MODAL_OPEN]: ({ viewStack }, action) => {
      const newStack = [...viewStack, action.view]
      return {
        isModalOpen: true,
        currentView: action.view,
        viewStack: newStack,
        hasManyViews: newStack.length > 1,
      }
    },
    [ViewStateActions.MODAL_CLOSE]: () => ({
      isModalOpen: false,
    }),
    [ViewStateActions.MODAL_RESET]: () => ({
      currentView: BLANK_VIEW,
      viewStack: [],
      hasManyViews: false,
    }),
    [ViewStateActions.MODAL_GO_BACK]: (state) => {
      const newStack = Array.from(state.viewStack)
      newStack.pop()

      return {
        viewStack: newStack,
        hasManyViews: newStack.length > 1,
        currentView: newStack[newStack.length - 1],
      }
    },
  },
)

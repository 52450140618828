import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getInsuranceBreakdownView from './getInsuranceBreakdownView'

const getInsuranceTotalPrice = createSelector(
  getInsuranceBreakdownView,
  (views) => sum(views.data, view => view.price),
)

export default getInsuranceTotalPrice

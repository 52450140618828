import { useMemo } from 'react'
import { getTourTags, LuxPlusLabelTagOptions } from 'lib/tours/tourUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'

function useTourTags(
  tour: Tours.TourV2Offer | Tours.TourV2OfferSummary,
  luxPlusLabels: LuxPlusLabelTagOptions = 'all',
  departureId?: string,
) {
  const isLuxPlusToursAvailable = useAppSelector(state => isLuxPlusToursEnabled(state))
  return useMemo(() => getTourTags(tour, isLuxPlusToursAvailable, luxPlusLabels, departureId), [tour, isLuxPlusToursAvailable, luxPlusLabels, departureId])
}

export default useTourTags

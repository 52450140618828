import clsx from 'clsx'
import Pill from 'components/Luxkit/Pill'
import React from 'react'
import styled from 'styled-components'

const DiscountPill = styled(Pill)`
  max-width: fit-content;

  @media print {
    display: none;
  }
`

interface Props {
  /**
   * Controls whether the messaging to state the saving is an estimate or exact value.
   * @default estimate
   */
  type?: 'exact' | 'estimate'
  /**
   * @example 10 => 10%
   */
  discountPercentage: number
  className?: string
  'data-testid'?: string
}

function PriceRowValueDiscount(props: Props) {
  const {
    type = 'estimate',
    discountPercentage,
    className,
    'data-testid': dataTestId,
  } = props

  return <DiscountPill
    kind="primary"
    variant="success"
    className={clsx(PriceRowValueDiscount.name, className)}
    data-testid={dataTestId}
  >
    {type === 'estimate' ? 'Save up to' : 'Save'} {discountPercentage}%
  </DiscountPill>
}

export default PriceRowValueDiscount

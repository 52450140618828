import React, { useMemo } from 'react'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import { getBundleValueUp } from 'lib/bundleAndSave/getBundleValueUp'
import { pluralizeToString } from 'lib/string/pluralize'
import { sum, unique } from 'lib/array/arrayUtils'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'

interface Props {
  offer: App.BundleOffer | App.BundleOfferSummary;
  pkg: App.BundlePackageWithPrice;
  variant: 'default' | 'condensed';
}

function BundleAndSavePriceDetails(props: Props) {
  const { offer, pkg, variant } = props

  const {
    saleUnit,
    totalPrice,
    totalValue,
    valueOffPercent,
  } = useOfferPackagePrice(pkg, offer)

  const { shouldShowDiscountPercent, shouldShowValue } = getBundleValueUp(offer, pkg)

  const isDiscountPillHidden = offer.isDiscountPillHidden

  const duration = useMemo(() => {
    return sum(pkg.packages, (p) => p.duration)
  }, [pkg.packages])

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <VerticalSpacer gap={8}>
        <Group direction="vertical">
          <PriceRowPriceCaption>
            {pluralizeToString('night', duration)}, 2 hotels from
          </PriceRowPriceCaption>
          <PriceRowPrice
            size="L"
            price={Math.ceil(totalPrice)}
            saleUnit={saleUnit}
          />
          {shouldShowValue && <PriceRowValueDiscountWithCaption
            size="L"
            originalValue={Math.ceil(totalValue)}
            discountPercentage={shouldShowDiscountPercent && !isDiscountPillHidden ? valueOffPercent : undefined}
          />}
          {variant === 'default' && <PriceRowTaxesAndFees />}
        </Group>
        <PriceRowAgentHubCommission
          size="L"
          productType={offer.productType}
          offerId={offer.id}
          offerLocations={offerLocations}
          vendorName={vendorName}
        />
      </VerticalSpacer>
      <CSSBreakpoint min="desktop">
        {variant === 'default' && <CSSBreakpoint min="desktop">
          <TextButton kind="primary" nonInteractable>
            View
          </TextButton>
        </CSSBreakpoint>}
        {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
          View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
        </TextButton> }
      </CSSBreakpoint>
    </Group>
  )
}

export default BundleAndSavePriceDetails

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineClockFiveIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8.0004 8.0004 0 0 1-7.391-4.9385A8 8 0 1 1 11 20Zm1-8.07V7a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 10 7v5.11a1.0002 1.0002 0 0 0 .11.59l1.5 2.6a1.0034 1.0034 0 0 0 1.74-1L12 11.93Z"/>
  </SvgIcon>
}

export default LineClockFiveIcon

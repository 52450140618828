import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWindIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4 0h7a3 3 0 0 0 0-6 3.06 3.06 0 0 0-1.5.4 1 1 0 0 0-.37 1.37.9996.9996 0 0 0 1.0005.488A.9997.9997 0 0 0 14 5.13a1.0897 1.0897 0 0 1 .5-.13 1 1 0 1 1 0 2h-7a1 1 0 0 0 0 2Zm-4 4h7a1.0001 1.0001 0 0 0 0-2h-7a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929Zm17-4a1.0002 1.0002 0 0 0 .9808-1.195.9998.9998 0 0 0-1.3635-.7289A1.0003 1.0003 0 0 0 19.5 8a1.0002 1.0002 0 0 0 1 1Zm-2 2h-4a1.0001 1.0001 0 0 0 0 2h4a1.0001 1.0001 0 0 1 0 2 1.0001 1.0001 0 0 0 0 2 3 3 0 0 0 0-6Zm-6 4h-4a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929h4a1.0001 1.0001 0 0 1 0 2 1.089 1.089 0 0 1-.5-.13.9992.9992 0 0 0-.7744-.1274 1.0003 1.0003 0 0 0-.7225 1.2499A1 1 0 0 0 11 20.6a3.0597 3.0597 0 0 0 1.5.4 3 3 0 0 0 0-6Zm-8 0h-1a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929h1a1.0002 1.0002 0 0 0 .7071-1.7071A1.0002 1.0002 0 0 0 4.5 15Z"/>
  </SvgIcon>
}

export default LineWindIcon

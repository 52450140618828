import BodyText from 'components/Luxkit/Typography/BodyText'
import FormatInlineList from 'components/utils/Formatters/FormatInlineList'
import React, { Fragment } from 'react'

interface Props {
  offersLocations: Array<Array<string>>
}

function HotelBundleSearchTileOverlineLocations({ offersLocations }: Props) {
  return <BodyText variant="small" colour="neutral-two" className={HotelBundleSearchTileOverlineLocations.name}>
    {offersLocations.map((locations, index) => <Fragment key={index}>
      {index !== 0 && ' & '}
      <FormatInlineList list={locations} type="unit" />
    </Fragment>)}

  </BodyText>
}

export default HotelBundleSearchTileOverlineLocations

import { getBundlePackagesWithPrice } from 'lib/bundleAndSave/getBundlePackagesWithPrice'
import { getPackageUniqueKey } from '../offer/offerUtils'
import { logNewRelic } from '../../services/newRelic'

export function getBundlePackagesFilteredByRate(offer: App.BundleOffer, rate: App.OfferAvailableRate) {
  const packageAvailable = offer.packages.find(pkg => pkg.uniqueKey === rate.packageUniqueKey)
  const restPackages = offer.packages.filter(pkg => pkg.offerId !== packageAvailable?.offerId)

  return getBundlePackagesWithPrice({
    ...offer,
    packages: !packageAvailable ? offer.packages : [...restPackages, {
      ...packageAvailable,
      price: rate.hotelPrice,
      surcharge: rate.surcharge,
      value: rate.hotelValue,
      taxesAndFees: rate.taxesAndFees,
      propertyFees: rate.propertyFees,
    }],
  })
}

export function getBundlePackagesFilteredBySearchPrices(offer: App.BundleOffer, searchPrices: App.OfferListMetadataPricing) {
  const packageAvailable = !!searchPrices.lowestPricePackageId && !!searchPrices.duration && !!searchPrices.lowestPriceRoomRateId ?
    offer.packages.find(pkg => pkg.uniqueKey === getPackageUniqueKey(searchPrices.lowestPricePackageId!, searchPrices.duration!, searchPrices.lowestPriceRoomRateId!)) :
    undefined

  const restPackages = offer.packages.filter(pkg => pkg.offerId !== packageAvailable?.offerId)

  if (!searchPrices.lowestPrice) {
    // this should never happen
    logNewRelic('No search price found for bundle offer', { id: offer.id, ...searchPrices }, 'error')
    return getBundlePackagesWithPrice({
      ...offer,
      packages: offer.packages,
    })
  }

  return getBundlePackagesWithPrice({
    ...offer,
    packages: !packageAvailable ? offer.packages : [...restPackages, {
      ...packageAvailable,
      price: searchPrices.lowestPrice ?? 0,
      value: searchPrices.lowestPriceValue ?? 0,
      taxesAndFees: searchPrices.lowestPriceTaxes ?? 0,
      propertyFees: searchPrices.lowestPricePropertyFees ?? 0,
    }],
  })
}

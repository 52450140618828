import WalledContent from 'components/Common/WalledContent'
import React from 'react'
import SearchTilePrimaryActionButton from '../shared/SearchTilePrimaryActionButton'
import SearchTileSignUpButton from '../shared/SearchTileSignUpButton'

interface Props {
  offer: App.HotelOffer | App.HotelOfferSummary
  offerMetaData?: App.OfferListMetaData
}

function HotelSearchTileAction({ offer, offerMetaData }: Props) {
  return <WalledContent
    enforced={offer.walledGarden}
    wall={<SearchTileSignUpButton />}
  >
    {!offerMetaData?.extendedAvailability && <SearchTilePrimaryActionButton />}
  </WalledContent>
}

export default HotelSearchTileAction

import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseBorderRadii, whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const scooponTravelDefaultPalette: App.ThemePalette<string> = {
  ...whiteLabelBaseDefaultPalette,
}

export const scooponTravelShadows: App.ThemeShadows<string> = { ...baseThemeShadows }
export const scooponTravelBorderRadii: App.ThemeBorderRadii<string> = { ...whiteLabelBaseBorderRadii }

const scooponTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default scooponTravelTheme

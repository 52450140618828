import * as Actions from '../actions/actionConstants'
import { FETCH_GEO_AIRPORTS, FETCH_GEO_DATA } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialGeoState: App.GeoState = {
  partnership: undefined,
  currentRegionHasFlights: false,
  fetchRegionError: undefined,
  currentRegionName: '',
  currentRegionCode: '',
  currentCurrency: '',
  currentContact: undefined,
  insuranceProductName: '',
  flightsSupportEmail: 'flights@luxuryescapes.com',
  latitude: undefined,
  longitude: undefined,
  mainAirport: undefined,
  originAirports: [],
  airports: [],
  geoCountryCode: undefined,
  geoStateName: undefined,
  isStrikethroughPricingRegion: false,
  initialised: false,
  flightError: undefined,
  contacts: {} as App.GeoContactsTypes,
}

const apiRequests = reducerSwitch<App.GeoState>({
  [FETCH_GEO_DATA]: () => ({
    fetchRegionError: undefined,
  }),
})

const apiSuccesses = reducerSwitch<App.GeoState>({
  [FETCH_GEO_DATA]: (state, action) => ({
    ...action.data,
    initialised: true,
  }),
  [FETCH_GEO_AIRPORTS]: (state, action) => ({
    airports: action.data.airports,
    originAirports: action.data.originAirports,
    mainAirport: action.data.mainAirport,
  }),
})

const apiFailures = reducerSwitch<App.GeoState>({
  [FETCH_GEO_DATA]: (state, action) => ({
    fetchRegionError: action.error,
    initialised: action.initialiseOnFailure,
  }),
  [FETCH_GEO_AIRPORTS]: (state, action) => ({
    airports: [],
    originAirports: [],
    flightError: action.error,
  }),
})

const geoReducer = createReducer<App.GeoState>(initialGeoState, {
  [Actions.API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [Actions.API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [Actions.API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [Actions.UPDATE_USER_LAT_LONG]: (state, action) => ({
    latitude: action.latitude,
    longitude: action.longitude,
  }),
})

export default geoReducer

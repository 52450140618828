import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { rem } from 'polished'
import noop from 'lib/function/noop'
import styled from 'styled-components'
import cx from 'clsx'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import Heading from 'components/Luxkit/Typography/Heading'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import { CRUISE_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { getCruiseExtraFilters, getGlobalFacetFilters } from 'lib/cruises/cruiseUtils'
import useQueryParams from 'hooks/useQueryParams'
import { omitKeys } from 'lib/object/objectUtils'

const HowLongGrid = styled.div`
  display: flex;
  gap: ${rem(12)};
  flex-wrap: wrap;
  justify-content: center;
`

interface CruiseDurationOption {
  name: string;
  duration: CRUISE_FLEXIBLE_DURATION_RANGE;
  min: number | undefined;
  max: number | undefined;
}

export const CruiseDurationOptions = [
  { name: 'Any duration', duration: CRUISE_FLEXIBLE_DURATION_RANGE.ANY_DURATION, min: undefined, max: undefined },
  { name: '1 - 4 Nights', duration: CRUISE_FLEXIBLE_DURATION_RANGE.SHORT_STAY, min: 1, max: 4 },
  { name: '5 - 8 Nights', duration: CRUISE_FLEXIBLE_DURATION_RANGE.MEDIUM_STAY, min: 5, max: 8 },
  { name: '9 - 14 Nights', duration: CRUISE_FLEXIBLE_DURATION_RANGE.LONG_STAY, min: 9, max: 14 },
  { name: '15+ Nights', duration: CRUISE_FLEXIBLE_DURATION_RANGE.VERY_LONG_STAY, min: 15, max: undefined },
]

interface Props {
  onSelect?: () => void;
  onChange?: (value: App.CruiseGlobalFilters) => void;
  drawerMode?: boolean;
}

function CruiseWhenSelectContentHowLong(props: Props) {
  const queryParams = useQueryParams()
  const { drawerMode, onSelect = noop, onChange = noop } = props
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const { durationRange } = useContext(GlobalSearchStateContext)
  const [selectedItems, setSelectedItems] = useState<Array<CRUISE_FLEXIBLE_DURATION_RANGE>>(durationRange as unknown as Array<CRUISE_FLEXIBLE_DURATION_RANGE>)

  const cruiseExtraFilters = getCruiseExtraFilters(queryParams)
  const globalFilters = useContext(GlobalSearchStateContext)
  const facetFilters = getGlobalFacetFilters(globalFilters)
  const [filteredFacets] = useCruiseSearchFacets({
    facetTypes: ['cruise_durations'],
    ...omitKeys(['durationRange'], { ...cruiseExtraFilters, ...facetFilters }),
  })

  const duration = useMemo(() => {
    return filteredFacets.length ? filteredFacets.map((facet) => {
      return {
        [CruiseDurationOptions[1].name]: facet.shortDuration,
        [CruiseDurationOptions[2].name]: facet.mediumDuration,
        [CruiseDurationOptions[3].name]: facet.longDuration,
        [CruiseDurationOptions[4].name]: facet.veryLongDuration,
      }
    })[0] : undefined
  }, [filteredFacets])

  const handlerSearch = useCallback((option: CruiseDurationOption) => {
    const isAnyDuration = option.name === 'Any duration'
    const isSelected = selectedItems.includes(option.duration)
    const isAnyDurationSelected = selectedItems.includes(CruiseDurationOptions[0].duration)

    let newSeletedItems = selectedItems.filter(item => item !== CruiseDurationOptions[0].duration)
    if (isAnyDuration && isAnyDurationSelected) {
      newSeletedItems = []
    } else if (isAnyDuration && !isAnyDurationSelected) {
      newSeletedItems = [CruiseDurationOptions[0].duration]
    } else if (isSelected) {
      newSeletedItems = newSeletedItems.filter(item => item !== option.duration)
    } else {
      newSeletedItems = [...newSeletedItems, option.duration]
    }
    searchDispatch({
      type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE,
      durationRange: newSeletedItems,
    })
    onSelect()
    setSelectedItems(newSeletedItems)
    const isCruiseSearch = window.location.pathname.includes('search/cruise')
    if (isCruiseSearch) {
      onChange({ durationRange: newSeletedItems })
    }
  }, [onChange, onSelect, searchDispatch, selectedItems])

  useEffect(() => {
    if (!durationRange.length) {
      setSelectedItems([])
    }
  }, [durationRange])

  return <VerticalSpacer gap={12} data-testid="cruise-when-select-how-long">
    <Heading align="center" variant="heading6">How long?</Heading>
    <HowLongGrid className={cx({ drawer: drawerMode })}>
      <FilterChip
        data-testid="cruise-when-select-how-long-chip"
        size="medium"
        key="Any duration"
        onClick={() => handlerSearch(CruiseDurationOptions[0])}
        selected={selectedItems.includes(CRUISE_FLEXIBLE_DURATION_RANGE.ANY_DURATION)}>
        Any duration
      </FilterChip>
      {CruiseDurationOptions.slice(1).map((option) => {
        return <FilterChip
          data-testid="cruise-when-select-how-long-chip"
          disabled={!duration?.[option.name] || duration?.[option.name]! === 0}
          size="medium"
          key={option.name}
          onClick={() => handlerSearch(option)}
          selected={durationRange?.length > 0 && selectedItems?.some(item => item === option.duration)}>
          {option.name}
        </FilterChip>
      })}
    </HowLongGrid>
  </VerticalSpacer>
}

export default CruiseWhenSelectContentHowLong

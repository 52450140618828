import FormatCurrency from 'components/Common/FormatCurrency'
import React, { ComponentProps, ReactElement } from 'react'
import BodyText from '../Typography/BodyText'
import _PriceSummaryRow from './_PriceSummaryRow'
import TextLoadingBox from '../Typography/TextLoadingBox'

type InheritedBasePriceSummaryRowProps = Pick<ComponentProps<typeof _PriceSummaryRow>, 'data-testid'>

interface Props extends InheritedBasePriceSummaryRowProps {
  /**
   * @default default
   */
  kind?: 'default' | 'lux-plus'
  /**
   * Textual content at the start.
   */
  label: string | ReactElement
  /**
   * Formatted price at the end.
   */
  discountValue?: number
  /**
   * Puts the value in a loading state.
   */
  discountValueLoading?: boolean
  discountStrikethrough?: boolean
  /**
   * @default preciseDollar
   */
  discountValueFormat?: ComponentProps<typeof FormatCurrency>['format']
}

const KINDS_BODY_TEXT_COLOURS: Record<NonNullable<Props['kind']>, ComponentProps<typeof BodyText>['colour']> = {
  default: 'success-dark',
  'lux-plus': 'lux-plus',
}

function PriceSummaryRowItemDescriptionDiscount(props: Props) {
  const {
    kind = 'default',
    label,
    discountValue,
    discountValueLoading,
    discountStrikethrough,
    discountValueFormat = 'preciseDollar',
    'data-testid': dataTestId,
  } = props

  const hidePromoAmount = discountValue == undefined || discountValue == 0

  return <_PriceSummaryRow
    data-testid={dataTestId}
    className={PriceSummaryRowItemDescriptionDiscount.name}
    startSlot={<BodyText variant="medium" weight="bold" colour={KINDS_BODY_TEXT_COLOURS[kind]} lineClamp={4}>
      {label}
    </BodyText>}
    endSlot={!hidePromoAmount && <BodyText
      variant="medium"
      weight="normal"
      colour={KINDS_BODY_TEXT_COLOURS[kind]}
      wrap="no-wrap"
      strikeThrough={discountStrikethrough}
    >
      {!discountValueLoading && <FormatCurrency value={discountValue} format={discountValueFormat} />}
      {discountValueLoading && <TextLoadingBox width="8ch" />}
    </BodyText>}
  />
}

export default PriceSummaryRowItemDescriptionDiscount

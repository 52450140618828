import { last, take, unique } from 'lib/array/arrayUtils'

export const recentlyViewedExperiencesCookieKey = 'recentlyViewedExperiences'

export function addRecentlyViewedExperience(experienceId: string) {
  const existingIds = getRecentlyViewedExperiences()
  const nextIds = take(unique([experienceId, ...existingIds]), 5)
  document.cookie = `${recentlyViewedExperiencesCookieKey}=${nextIds}; expires=Fri, 29 Dec 2028 23:59:59 GMT; path=/; domain=${document.domain}`
}

export function getRecentlyViewedExperiences(): Array<string> {
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')
  const cookieEntry = cookies.find(val => val.includes(recentlyViewedExperiencesCookieKey)) ?? ''
  const cookieValue = last(cookieEntry.split('='))
  return cookieValue.split(',').filter(Boolean)
}

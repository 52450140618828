import React, { ChangeEventHandler, ComponentProps, FocusEventHandler, FormEventHandler, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import noop from 'lib/function/noop'
import TextInput from './TextInput'
import { DMY_DATE_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'
import HiddenInput from './HiddenInput'

// Checks if the new string is equal to the old string with something added to the end
function didAppend(oldString = '', newString = '') {
  return (
    newString.length > oldString.length &&
    newString.startsWith(oldString)
  )
}

interface Props extends Omit<ComponentProps<typeof TextInput>, 'min' | 'max' | 'onChange' | 'pattern'> {
  onChange?: (date: string | undefined, isoDate: string | undefined, event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>, isoDate?: string) => void;
  getCustomError?: (msg: string) => void
  min?: Date;
  max?: Date;
  /**
   * in ISO format
   */
  value?: string;
  /**
   * in ISO format
   */
  defaultValue?: string;
  displayFormat?: 'DD/MM/YYYY' | 'MM/DD/YYYY' | 'D MMM YYYY';
}

function getInitialDisplayTextValue(props: Props) {
  const initialValue = props.value || props.defaultValue
  const displayFormat = props.displayFormat ?? DMY_DATE_FORMAT
  if (initialValue) {
    const value = moment(initialValue)
    if (value.isValid()) {
      return value.format(displayFormat)
    }
  }
  return ''
}

const DateInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    min,
    max,
    required,
    placeholder = DMY_DATE_FORMAT,
    displayFormat = DMY_DATE_FORMAT,
    name,
    value,
    defaultValue,
    getInvalidMessage,
    getCustomError,
    onBlur = noop,
    onInvalid = noop,
    onChange = noop,
    ...rest
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => inputRef.current!)

  const [displayText, setDisplayText] = useState<string>(getInitialDisplayTextValue(props))

  const isControlled = value !== undefined

  // when it's an uncontrolled input, we need to manually sync the display text (what the user has input) to the hidden input value
  const actualValue = useMemo(() => {
    if (isControlled) {
      return value
    }

    const possibleValue = moment(displayText, displayFormat, true)
    if (possibleValue.isValid()) {
      return possibleValue.format(ISO_DATE_FORMAT)
    }
  }, [displayFormat, displayText, isControlled, value])

  const invalidMessageGetter = useCallback((input: HTMLInputElement) => {
    let message = getInvalidMessage?.(input)

    if (!message && input.validity.patternMismatch) {
      message = `Enter date as ${displayFormat}`
    }

    if (message) {
      getCustomError?.(message)
    }

    return message
  }, [displayFormat, getCustomError, getInvalidMessage])

  const validate = useCallback((toValidate: string) => {
    if (toValidate.length === 0 && !required) {
      return true
    } else if (toValidate.length !== 10) {
      return false
    }

    const m = moment(toValidate, displayFormat, true)

    if (!inputRef.current) {
      return false
    }

    if (!m.isValid()) {
      inputRef.current.setCustomValidity('Invalid date')
      return false
    }

    if (min && m.isBefore(min)) {
      inputRef.current.setCustomValidity(`Must be after ${moment(min).format(displayFormat)}`)
      return false
    }

    if (max && m.isAfter(max)) {
      inputRef.current.setCustomValidity(`Must be before ${moment(max).format(displayFormat)}`)
      return false
    }

    inputRef.current.setCustomValidity('')
    return true
  }, [displayFormat, max, min, required])

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>((event) => {
    const currentValue = event.currentTarget.value
    const isValid = !!currentValue && validate(displayText)
    const isoDate = isValid ? moment(currentValue, displayFormat).format(ISO_DATE_FORMAT) : undefined
    onBlur(event, isoDate)
  }, [displayFormat, displayText, onBlur, validate])

  const handleInvalid = useCallback<FormEventHandler<HTMLInputElement>>((event) => {
    validate(displayText)
    onInvalid(event)
  }, [displayText, onInvalid, validate])

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const currentValue = event.currentTarget.value
    const isValid = validate(currentValue)

    setDisplayText((currDisplayText) => {
      let nextDisplayText = currentValue

      if (didAppend(currDisplayText, nextDisplayText)) {
        if (nextDisplayText.match(/^\d\d(\/\d\d)?$/)) {
          nextDisplayText = nextDisplayText + '/'
        }

        if (nextDisplayText.match(/\/{2,}/)) {
          nextDisplayText = nextDisplayText.replace(/\/{2,}/g, '/')
        }
      }

      return nextDisplayText.substring(0, 10)
    })

    // empty value is valid, but it's not a date
    if (currentValue && isValid) {
      // legitimate date has been input, notify listeners
      const isoDate = moment(currentValue, displayFormat).format(ISO_DATE_FORMAT)
      onChange(currentValue, isoDate, event)
    } else {
      // not a legitimate date, therefore we have changed to 'empty'
      onChange(undefined, undefined, event)
    }
  }, [displayFormat, onChange, validate])

  useEffect(() => {
    if (value) {
      // values must be of ISO format, if it is - update our display value to match
      const nextValue = moment(value, ISO_DATE_FORMAT, true)
      if (nextValue.isValid()) {
        // legitimate value passed in that was different than before
        // update our input text
        setDisplayText(nextValue.format(displayFormat))
      }
    } else if (value !== undefined) {
      // value was changed to be either null or empty string
      // that means the input should be emptied
      setDisplayText('')
    }
    // value is undefined - it is now (or still is) an uncontrolled input.
    // do nothing with the display text
  }, [value, displayFormat])

  useEffect(() => {
    if (displayText) {
      validate(displayText)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TextInput
    {...rest}
    ref={inputRef}
    required={required}
    type="text"
    placeholder={placeholder}
    onChange={handleChange}
    onBlur={handleBlur}
    onInvalid={handleInvalid}
    value={displayText}
    pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}|[0-9]{1,2}\s[a-zA-Z]{3}\s[0-9]{4}"
    getInvalidMessage={invalidMessageGetter}
  >
    {/*
      Text input displays our "formatted" value which could be different formats
      The hidden input will store the value in a consistent format (ISO Format - YYYY/MM/DD)
    */}
    <HiddenInput
      name={name}
      defaultValue={defaultValue}
      value={actualValue}
    />
  </TextInput>
})

export default DateInput

import React, { MouseEventHandler, useCallback, useContext } from 'react'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import Clickable from 'components/Common/Clickable'
import { searchResultPolicyClick } from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import BedbankCancellationPolicyModal from './BedbankCancellationPolicyModal'
import BedbankPropertyCancellationPoliciesBase from 'components/Common/CancellationPolicies/Bedbank/BedbankPropertyCancellationPoliciesBase'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import ModalContext from 'contexts/ModalContext'
import { BedbankCancellationPolicyWithInfo } from '../../../lib/search/hotels'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  rate: App.BedbankRate | App.OrderBedbankRate | BedbankCancellationPolicyWithInfo;
  showNonCancellation: boolean;
  isConfirmation?: boolean;
  className?: string;
}

function BedbankCancellationPolicy(props: Props) {
  const { rate, isConfirmation, showNonCancellation } = props

  const showModal = useContext(ModalContext)
  const openModal = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    addGTMEvent(searchResultPolicyClick())
    const modalHeading = rate.partiallyRefundable ? 'Partially refundable' :
      rate.refundable ? 'Free cancellation' :
        'Non-refundable and no date changes permitted'

    showModal(<BedbankCancellationPolicyModal
      heading={modalHeading}
      policyDescriptions={rate.cancellationPolicy.description ?? []}
    />)
  }, [rate, showModal])

  const shouldShowCancellationPolicy = rate.partiallyRefundable || (rate.refundable && !isConfirmation) || (!rate.partiallyRefundable && !rate.refundable && showNonCancellation)

  if (!shouldShowCancellationPolicy) return null

  return <Clickable onClick={openModal}>
    {rate.partiallyRefundable && <BodyTextBlock endIcon={<SolidInfoCircleIcon />} variant="medium">
      Partially refundable
    </BodyTextBlock>}
    {rate.refundable && !isConfirmation && <BedbankPropertyCancellationPoliciesBase cancellationPolicy={rate.cancellationPolicy} />}
    {!rate.partiallyRefundable && !rate.refundable && showNonCancellation && <BodyTextBlock endIcon={<SolidInfoCircleIcon />} variant="medium">
      Non-refundable
    </BodyTextBlock>}
  </Clickable>
}

export default BedbankCancellationPolicy

import React, { useCallback, useState } from 'react'

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter, {
  ModalFooterActionProps,
} from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import noop from 'lib/function/noop'
import DateRangePicker from 'tripPlanner/Tripkit/Common/DateRangePicker'

interface Props {
  title?: string
  initialStartDate?: moment.Moment
  initialEndDate?: moment.Moment
  onChange: (startDate: moment.Moment, endDate?: moment.Moment) => void
  onRemove?: () => void
  onCancel?: () => void
  onBack?: () => void
  isSubmitting?: boolean
  errorMsg?: string
  onRequestClearError?: () => void
  doneLabel?: string
  showTripDates?: boolean
  startLabel?: string
  endLabel?: string
}

function MobileSelectDates(props: Props) {
  const {
    title = 'Add dates',
    initialStartDate,
    initialEndDate,
    onChange,
    onRemove,
    onCancel,
    onBack,
    isSubmitting,
    errorMsg,
    onRequestClearError = noop,
    doneLabel = 'Add dates',
    showTripDates,
    startLabel,
    endLabel,
  } = props

  const [startDate, setStartDate] = useState<moment.Moment | undefined>(
    initialStartDate,
  )
  const [endDate, setEndDate] = useState<moment.Moment | undefined>(
    initialEndDate,
  )

  const [showNoDatesError, setShowNoDatesError] = useState(false)

  const errorToShow =
    errorMsg || (showNoDatesError ? 'Please select dates' : undefined)

  const onUpdate = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (startDate) {
        setShowNoDatesError(false)
        onChange(startDate, endDate)
      } else {
        setShowNoDatesError(true)
      }
    },
    [startDate, onChange, endDate],
  )

  const onClickRemove = useCallback(() => {
    setShowNoDatesError(false)
    onRemove?.()
  }, [onRemove])

  const onDatesChange = useCallback(
    (dates: { startDate: moment.Moment; endDate?: moment.Moment }) => {
      setStartDate(dates.startDate)
      setEndDate(dates.endDate)
      onRequestClearError()
    },
    [onRequestClearError],
  )

  const onClickCancel = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  const clearDatesActionProps: ModalFooterActionProps = {
    children: 'Clear dates',
    onClick: onClickRemove,
    disabled: isSubmitting,
  }

  const cancelActionProps: ModalFooterActionProps = {
    children: 'Cancel',
    onClick: onClickCancel,
    disabled: isSubmitting,
  }

  return (
    <form onSubmit={onUpdate}>
      <ModalHeader
        title={title}
        onBackButtonClick={onBack}
        onCloseButtonClick={onCancel}
      />
      <ModalBody>
        <ModalContent>
          <VerticalSpacer gap={16}>
            <DateRangePicker
              onDatesChange={onDatesChange}
              startDate={startDate}
              endDate={endDate}
              allowSameDay
              showTripDates={showTripDates}
              startLabel={startLabel}
              endLabel={endLabel}
            />
            {errorToShow && (
              <MessageBanner kind="critical" description={errorToShow} />
            )}
          </VerticalSpacer>
        </ModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: doneLabel,
          type: 'submit',
          disabled: isSubmitting,
        }}
        secondaryActionProps={
          onRemove ? clearDatesActionProps : cancelActionProps
        }
        isSecondaryActionHidden={onRemove && !startDate}
      />
    </form>
  )
}

export default MobileSelectDates

import React, { PropsWithChildren } from 'react'

const LabelCountContext = React.createContext<number | undefined>(undefined)

interface Props {
  maxLabels: number;
}

export function LabelCountProvider(props: PropsWithChildren<Props>) {
  const { maxLabels } = props

  return <LabelCountContext.Provider value={maxLabels}>
    {props.children}
  </LabelCountContext.Provider>
}

export default LabelCountContext

import { getTotalRoomsString } from 'components/Search/utils'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import React, { useMemo } from 'react'

interface Props {
  /**
   * @default adult
   */
  adultLabel?: 'adult' | 'traveller'
  occupancies: Array<App.Occupants | App.RoomOccupants>
  /**
   * @default room
   */
  saleUnit?: string
}

function FormattedOccupancy(props: Props) {
  const { adultLabel = 'adult', occupancies, saleUnit = 'room' } = props
  const totalOccupantsString = useMemo(() => formatOccupantsShort(occupancies, undefined, adultLabel), [occupancies, adultLabel])
  const totalRoomsString = useMemo(() => !saleUnit ? '' : getTotalRoomsString(occupancies, saleUnit), [occupancies, saleUnit])

  return <>
    <strong>{totalOccupantsString}</strong> {totalRoomsString}
  </>
}

export default FormattedOccupancy

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidBellRingIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M18 10v3.2c1.2.4 2 1.5 2 2.8v2c0 .6-.4 1-1 1h-3.2c-.4 1.4-1.5 2.5-2.9 2.9-2.1.5-4.3-.8-4.8-2.9H5c-.6 0-1-.4-1-1v-2c0-1.3.8-2.4 2-2.8V10c0-2.9 2.1-5.4 5-5.9V3c0-.6.4-1 1-1s1 .4 1 1v1.1c2.9.5 5 3 5 5.9ZM7.12 2.371c.3473.4293.281 1.059-.1484 1.4064-1.3734 1.1114-2.37 2.6664-2.7734 4.444a7.9747 7.9747 0 0 0-.1494.8884c-.0606.549-.5548.9448-1.1038.8841-.549-.0607-.9447-.5549-.884-1.1038a9.9748 9.9748 0 0 1 .1868-1.1114c.5053-2.2272 1.7527-4.1698 3.4657-5.556.4294-.3475 1.059-.281 1.4064.1483Zm9.76 0c.3474-.4293.9771-.4957 1.4064-.1483 1.713 1.3862 2.9604 3.3288 3.4658 5.556a9.998 9.998 0 0 1 .1868 1.1114c.0607.549-.3351 1.0431-.8841 1.1038-.5489.0607-1.0431-.3351-1.1038-.884a8.0025 8.0025 0 0 0-.1493-.8886c-.4034-1.7775-1.4-3.3325-2.7735-4.4439-.4293-.3474-.4957-.977-.1483-1.4064Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default SolidBellRingIcon

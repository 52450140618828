import { Request } from 'express'

import { getCookies } from 'lib/web/cookieUtils'
import Snowplow from 'constants/snowplow'

// This function uses either the request object (in the case of being used server-side)
// or the getCookies function if used client-side
export function getDomainUserId(req?: Request): string | undefined {
  if (IS_SSR && !req) return undefined

  const cookies = req ? req.cookies as Record<string, string> : getCookies()

  // If a custom domain user id cookie is set, use that
  const customDomainUserIdCookie = Object.entries(cookies).find((entry: Array<string>) => {
    return entry[0] === Snowplow.domainUserIdCookie
  })?.[1]
  if (customDomainUserIdCookie) return customDomainUserIdCookie

  // Otherwise use the default snowplow cookie
  const snowplowDomainUserIdCookie = Object.entries(cookies).find((entry: Array<string>) => {
    return entry[0].startsWith('_sp_id.')
  })?.[1]

  // which is sets of characters separated by dots which is why we need just the 1st UUID part
  return snowplowDomainUserIdCookie?.split('.')[0]
}

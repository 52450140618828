import React, { ComponentProps, PropsWithChildren } from 'react'
import styled from 'styled-components'
import SolidLockAltIcon from 'components/Luxkit/Icons/solid/SolidLockAltIcon'
import { themeClassName } from 'lib/theme/themeUtils'
import AspectRatio from 'components/utils/AspectRatio'

const ImageWrapper = styled(AspectRatio)`
  position: relative;
  overflow: hidden;
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  display: grid;
  place-items: center;
  backdrop-filter: blur(12px);
`

interface Props extends PropsWithChildren, Pick<ComponentProps<typeof AspectRatio>, 'ratio' | 'tabletRatio' | 'desktopRatio' | 'largeDesktopRatio'> {

}

function LuxPlusHiddenOfferImageContainer(props: Props) {
  const {
    children,
    ratio,
    tabletRatio,
    desktopRatio,
    largeDesktopRatio,
  } = props

  return <ImageWrapper
    ratio={ratio}
    tabletRatio={tabletRatio}
    desktopRatio={desktopRatio}
    largeDesktopRatio={largeDesktopRatio}
  >
    {children}
    <Overlay>
      <SolidLockAltIcon colour="neutral-eight" size="XL" className={themeClassName('default')} />
    </Overlay>
  </ImageWrapper>
}

export default LuxPlusHiddenOfferImageContainer

import { components } from '@luxuryescapes/contract-trip/dist/server'

import { assertUnreachable } from '..'

import { FareClass } from 'constants/flight'
import { BookmarkRequest } from 'tripPlanner/api/bookmark/types'
import { LEFlightItem, LETripItem } from 'tripPlanner/types/tripItem'

const defaultOccupancy = {
  adults: 2,
  childrenAge: [] as Array<number>,
}

export function itemToPublicTripsBookmarkPayload(
  item: LETripItem,
): BookmarkRequest['items'][number] {
  switch (item.type) {
    case 'CRUISE':
      if (item.savedItemData.offerType === 'cruise') {
        return {
          type: 'cruise_v2',
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
        }
      } else {
        return {
          type: item.savedItemData.offerType,
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
        }
      }
    case 'EXPERIENCE':
      return {
        type: 'experience',
        code: item.savedItemData.experienceId,
        price: item.savedItemData.price,
        value: item.savedItemData.value,
      }
    case 'ACCOMMODATION':
      if (item.savedItemData.offerType === 'bedbank_hotel') {
        return {
          type: 'bedbank_hotel',
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
        }
      } else {
        return {
          type: item.savedItemData.offerType,
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
          durationInDays: item.savedItemData.durationInDays,
        }
      }
    case 'TOUR':
      return {
        type: item.savedItemData.offerType,
        code: item.savedItemData.offerId,
        price: item.savedItemData.price,
        value: item.savedItemData.value,
      }
    case 'FLIGHT':
      return {
        type: 'flight',
        journeys: buildJourneys(item),
        occupancy: defaultOccupancy,
        adultPrice: item.savedItemData.adultPrice ?? 0,
      }
    default:
      assertUnreachable(item)
  }
}

export const buildJourneys = (
  item: LEFlightItem,
): components['schemas']['flightBookmark']['journeys'] => {
  if (item.startDate) {
    const startDate = item.startDate.format('yyyy-MM-DD')
    const fareClass = item.savedItemData.fareClass ?? 'economy'
    return [
      {
        startDate,
        originAirportCode: item.originAirportCode,
        destinationAirportCode: item.destinationAirportCode,
        class: fareClass as FareClass,
        flightNumber: item.flightNumber,
        startTime: item.startTime?.format('HH:mm'),
      },
    ]
  }
  throw new Error('There should be a flight start date')
}

import React, { ComponentProps, PropsWithChildren, CSSProperties } from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import styled from 'styled-components'
import SvgIcon, { SVG_ICON_SIZES, SVG_ICON_SIZE_CSS_VAR, determineSvgIconSize } from '../SvgIcon'

export const StyledIconLoadingBox = styled(LoadingBox)`
  height: var(${SVG_ICON_SIZE_CSS_VAR}, ${SVG_ICON_SIZES.M});
  width: var(${SVG_ICON_SIZE_CSS_VAR}, ${SVG_ICON_SIZES.M});

  &.shape-circle {
    border-radius: ${props => props.theme.borderRadius.round};
  }
`

interface Props extends Pick<ComponentProps<typeof SvgIcon>, 'size'>, Pick<ComponentProps<typeof LoadingBox>, 'colour'> {
  /**
   * @default circle
   */
  shape?: 'circle' | 'square'
}

function IconLoadingBox(props: PropsWithChildren<Props>) {
  const { shape = 'circle', size, colour } = props

  const mappedSize = determineSvgIconSize(size)

  return <StyledIconLoadingBox
    colour={colour}
    className={`shape-${shape}`}
    style={{
      [SVG_ICON_SIZE_CSS_VAR]: mappedSize,
    } as CSSProperties}
  />
}

export default IconLoadingBox

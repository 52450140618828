import { sha256 } from '../lib/utils/createHashUtils'

import { AppStore } from 'src/client/store'
import { BuyerType } from 'reducers/analytics'
import { getUserOrderCount } from 'api/order'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'

export async function watchAnalytics(store: AppStore, dataLayer: any) {
  await setDataLayerDetails(store, dataLayer)
}

async function setDataLayerDetails(store: AppStore, dataLayer: any) {
  const state = store.getState() as App.State
  const { auth, geo, utm, config } = state
  let userPurchaseCount = { total_purchase_count: 0 }
  if (auth.account.memberId) userPurchaseCount = (await getUserOrderCount(auth.account.memberId))
  const details = {
    hitType: 'dataLayer-initialized',
    event: 'dataLayer-initialized',
    pageDetails: {
      siteVersion: '2.0',
    },
    sessionDetails: {
      loggedIn: auth.account.memberId ? 'loggedIN' : 'loggedOUT',
      selectedLocation: geo.currentRegionCode,
      utm,
      headlessMode: config.headlessMode,
      startLoggedIn: !!auth.account.memberId,
    },
    userDetails: {
      userID: auth.account.memberId || '',
      userEmailRaw: auth.account.email || '',
      userEmail: sha256(auth.account.email) || '',
      personContactID: auth.account.personContactId || '',
      buyerType: userPurchaseCount.total_purchase_count > 0 ? BuyerType.existing : BuyerType.new,
      userCountry: auth.account.countryCode,
      utmSource: auth.account.utmSource || '',
      utmMedium: auth.account.utmMedium || '',
      utmCampaign: auth.account.utmCampaign || '',
      utmContent: auth.account.utmContent || '',
      utmTerm: auth.account.utmTerm || '',
      utmAdgroup: auth.account.utmAdgroup || '',
      isMember: checkCanViewLuxPlusBenefits(state),
    },
  }
  dataLayer.push(details)
}

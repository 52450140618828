import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUnlockAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 13a1.0001 1.0001 0 0 0-1 1v3a1 1 0 1 0 2 0v-3a1.0003 1.0003 0 0 0-1-1Zm5-4H9V7a3 3 0 0 1 5.12-2.13 3.08 3.08 0 0 1 .78 1.38 1.0008 1.0008 0 0 0 .4609.6127A1.0016 1.0016 0 0 0 16.84 5.75a5.0902 5.0902 0 0 0-1.31-2.29A5.0001 5.0001 0 0 0 7 7v2a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3Zm1 10a1 1 0 0 1-1 1H7a.9997.9997 0 0 1-1-1v-7a1.0001 1.0001 0 0 1 1-1h10a1.0001 1.0001 0 0 1 1 1v7Z"/>
  </SvgIcon>
}

export default LineUnlockAltIcon

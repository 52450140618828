import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineClockIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8.0004 8.0004 0 0 1-7.391-4.9385A8 8 0 1 1 11 20Zm3.1-7.37L12 11.42V7a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 10 7v5.12a.6495.6495 0 0 0 .05.2.891.891 0 0 0 .08.17.863.863 0 0 0 .1.16l.16.13.09.09 2.6 1.5a.9998.9998 0 0 0 .5.13 1 1 0 0 0 .5-1.87h.02Z"/>
  </SvgIcon>
}

export default LineClockIcon

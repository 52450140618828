import ModalContext from 'contexts/ModalContext'
import React, { useCallback, useContext } from 'react'
import LuxPlusLabel from './LuxPlusLabel'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import LuxPlusBenefitsModal from './modals/LuxPlusBenefitsModal'
import LuxPlusLabelContext from 'contexts/LuxPlusLabelContext'

interface MappedProps {
  canViewLuxPlusBenefits: boolean;
}

interface Props extends MappedProps {
  offer?: App.Offer | App.OfferSummary | Tours.TourV2Offer | Tours.TourV2OfferSummary | App.CruiseOffer;
  saveAmount?: number;
  description?: string;
  type: App.LuxPlusLabelModalType;
  hideSaveAmountPlus?: boolean;
  inclusions?: Array<App.PackageInclusion> | Array<App.OrderLuxPlusInclusion>;
  tileInclusions?: Array<App.OfferInclusion>;
  modalTitle?: string;
  modalSubTitle?: React.ReactNode;
  hideUpsellModal?: boolean;
  isModalPrimaryActionHidden?: boolean;
}

function LuxPlusLabelWithModal(props: Props) {
  const {
    offer,
    canViewLuxPlusBenefits,
    saveAmount,
    description,
    type,
    hideSaveAmountPlus = false,
    inclusions,
    tileInclusions,
    modalTitle,
    modalSubTitle,
    hideUpsellModal,
    isModalPrimaryActionHidden,
  } = props

  const showModal = useContext(ModalContext)
  const providerPage = useContext(LuxPlusLabelContext)

  const toggleModal = useCallback(() => {
    showModal(<LuxPlusBenefitsModal
      type={type}
      saveAmount={saveAmount}
      offer={offer}
      hideSaveAmountPlus={hideSaveAmountPlus}
      providerPage={providerPage}
      inclusions={inclusions}
      tileInclusions={tileInclusions}
      title={modalTitle}
      subTitle={modalSubTitle}
      isPrimaryActionHidden={isModalPrimaryActionHidden}
    />)
  }, [
    hideSaveAmountPlus,
    inclusions,
    tileInclusions,
    modalSubTitle,
    modalTitle,
    offer,
    providerPage,
    saveAmount,
    showModal,
    type,
    isModalPrimaryActionHidden,
  ])

  return (
    <LuxPlusLabel
      saveAmount={saveAmount}
      description={description}
      type={type}
      onClick={(canViewLuxPlusBenefits || hideUpsellModal) ? undefined : toggleModal}
      hideSaveAmountPlus={hideSaveAmountPlus}
    />
  )
}

const mapStateToProps = (state: App.State):MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
})

export default connect(mapStateToProps)(LuxPlusLabelWithModal)

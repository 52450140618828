import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineChildWithPlusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11 3c-.7044 0-1.036.4475-1.0647.879-.0148.2221.0503.4487.212.6405.1592.1887.4624.4017 1.0171.4941.5448.0908.9128.606.822 1.1508-.0908.5448-.606.9128-1.1508.822-.9453-.1576-1.7046-.5696-2.2173-1.1777a2.889 2.889 0 0 1-.6652-2.208C5.046 4.799 3 7.6606 3 11c0 4.4183 3.5817 8 8 8 .3398 0 .6741-.0211 1.0019-.062.5481-.0684 1.0478.3204 1.1162.8684.0684.5481-.3205 1.0478-.8685 1.1162A10.0962 10.0962 0 0 1 11 21C5.4771 21 1 16.5228 1 11 1 5.4771 5.4771 1 11 1"/>
    <path fill="currentColor" d="M11.0033 1C16.5246 1.0018 21 5.4782 21 11c0 .4301-.0272.8543-.0801 1.2709-.0696.5479-.5701.9357-1.118.8661s-.9356-.5701-.8661-1.118A8.0897 8.0897 0 0 0 19 11c0-4.4183-3.5817-8-8-8M8 10c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1ZM15 9c0 .5523-.4477 1-1 1s-1-.4477-1-1 .4477-1 1-1 1 .4477 1 1ZM8.6 12.7c-.4418-.3314-1.0686-.2418-1.4.2-.3314.4418-.2418 1.0686.2 1.4 1.1292.8469 2.3345 1.325 3.6 1.325 1.2655 0 2.4708-.4781 3.6-1.325.4418-.3314.5314-.9582.2-1.4-.3314-.4418-.9582-.5314-1.4-.2-.8708.6531-1.6655.925-2.4.925-.7345 0-1.5292-.2719-2.4-.925ZM20 16c0-.5523-.4477-1-1-1s-1 .4477-1 1v2h-2c-.5523 0-1 .4477-1 1s.4477 1 1 1h2v2c0 .5523.4477 1 1 1s1-.4477 1-1v-2h2c.5523 0 1-.4477 1-1s-.4477-1-1-1h-2v-2Z"/>
  </SvgIcon>
}

export default LineChildWithPlusIcon

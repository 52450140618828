import { useCallback, useMemo, useState } from 'react'

type ToggleObject = [boolean, () => void, () => void, () => void]
  & { value: boolean, toggle: () => void, on: () => void, off: () => void }

/**
 * Togglable boolean state hook
 *
 * @param initial initial value, or function to generate initial value
 * @remarks
 * - Control toggle state
 * - Control show/hide
 *
 * @returns A tuple [value, toggleCallback, onCallback, offCallback] which can also be treated as an object with properties value, toggle, on, off
 */
export default function useToggle(initial: boolean | (() => boolean) = false): ToggleObject {
  const [value, setValue] = useState<boolean>(initial)

  const toggle = useCallback(() => setValue(value => !value), [])
  const on = useCallback(() => setValue(true), [])
  const off = useCallback(() => setValue(false), [])

  const returnVal = [value, toggle, on, off] as ToggleObject
  returnVal.value = value
  returnVal.toggle = toggle
  returnVal.on = on
  returnVal.off = off
  return returnVal
}

type MultiValueToggle<T> = [
  arrayValues: Array<T>,
  toggle: (...values: Array<T>) => void,
  on: (...values: Array<T>) => void,
  off: (...values: Array<T>) => void,
  set: (values: Array<T>) => void,
  setCopy: Set<T>,
] & {
  arrayValues: Array<T>,
  toggle: (...values: Array<T>) => void,
  on: (...values: Array<T>) => void,
  off: (...values: Array<T>) => void,
  set: (values: Array<T>) => void,
  setCopy: Set<T>,
}

/**
 * A hook that will maintain a set of values that are included/not included
 * Use this when to "toggle" values on/off in a list of possible values
 */
export function useMultiValueToggle<T>(initialValues: Array<T> = []): MultiValueToggle<T> {
  const [values, setValues] = useState(new Set(initialValues))

  const toggle = useCallback((...values: Array<T>) => {
    setValues((oldSet) => {
      const toggledSet = new Set([...oldSet.values()])
      values.forEach((value) => {
        if (toggledSet.has(value)) {
          toggledSet.delete(value)
        } else {
          toggledSet.add(value)
        }
      })
      return toggledSet
    })
  }, [])

  const on = useCallback((...values: Array<T>) => setValues((oldSet) => {
    if (oldSet.size && values.every(v => oldSet.has(v))) {
      return oldSet
    }
    const nextSet = new Set([...oldSet.values()])
    values.forEach(v => nextSet.add(v))
    return nextSet
  }), [])

  const off = useCallback((...values: Array<T>) => setValues((oldSet) => {
    if (!oldSet.size || values.every(v => !oldSet.has(v))) {
      return oldSet
    }
    const nextSet = new Set([...oldSet.values()])
    values.forEach(v => nextSet.delete(v))
    return nextSet
  }), [])

  const set = useCallback((values: Array<T>) => {
    setValues(new Set(values))
  }, [])

  // array will be the common use case, so provide it as the first value
  const arrayValues = useMemo(() => Array.from(values.values()), [values])
  // provide a copy so someone can't mutate our local state
  const setCopy = useMemo(() => new Set(values), [values])

  const returnValue = [arrayValues, toggle, on, off, set, setCopy] as MultiValueToggle<T>
  returnValue.arrayValues = arrayValues
  returnValue.toggle = toggle
  returnValue.on = on
  returnValue.off = off
  returnValue.set = set
  returnValue.setCopy = setCopy
  return returnValue
}

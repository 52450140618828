import { excludeNullOrUndefined } from 'checkout/utils'
import { createSelector } from 'reselect'
import { getAccommodationBreakdownView } from './accommodation'
import { getBookingProtectionBreakdownView } from './bookingProtection'
import { getBundleAndSaveBreakdownView } from './bundleAndSave'
import { getCarHireBreakdownView } from './carHire'
import { getCustomOfferBreakdownView } from './customOffer'
import { getExperienceBreakdownView, getTransfersBreakdownView } from './experience'
import { getFlightBreakdownView } from './flights'
import { getGiftCardBreakdownView } from './giftCard'
import getInsuranceBreakdownView from './getInsuranceBreakdownView'
import { getLuxPlusBreakdownView } from './luxPlusSubscription'
import { getTourExperienceV2BreakdownView } from './toursv2'
import { getVillaBreakdownView } from './villa'

const getAllBreakdownViews = createSelector(
  getAccommodationBreakdownView,
  getTourExperienceV2BreakdownView,
  getVillaBreakdownView,
  getFlightBreakdownView,
  getExperienceBreakdownView,
  getTransfersBreakdownView,
  getInsuranceBreakdownView,
  getCarHireBreakdownView,
  getBundleAndSaveBreakdownView,
  getGiftCardBreakdownView,
  getCustomOfferBreakdownView,
  getLuxPlusBreakdownView,
  getBookingProtectionBreakdownView,
  (...views): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    const allViews = views.flatMap(view => view.data).filter(excludeNullOrUndefined)
    return {
      hasRequiredData: views.every(o => o.hasRequiredData),
      data: allViews,
    }
  },
)

export default getAllBreakdownViews

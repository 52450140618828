import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  CHECKOUT_PROCESS_SUCCESS,
} from 'actions/actionConstants'
import {
  FETCH_CAR_HIRE_OFFERS,
  FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION,
  FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE,
  FETCH_CAR_HIRE_RESERVATION,
  FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO,
  FETCH_CAR_HIRE_DESTINATION_PRICE_INFO,
  FETCH_CAR_HIRE_CHEAPEST_OFFER,
}
  from 'actions/apiActionConstants'

export const initialCarHireState: App.CarHireState = {
  offerLists: {},
  reservationInfo: {},
  offerImportantInformationLists: {},
  offerInsuranceQuoteLists: {},
  reservationInsuranceInfo: {},
  destinationsPriceInfoList: {},
  cheapestOfferLists: {},
}

const apiRequests = reducerSwitch<App.CarHireState>({
  [FETCH_CAR_HIRE_OFFERS]: (state, action) => ({
    offerLists: {
      ...state.offerLists,
      [action.key]: {
        pickUp: { date: '', dateChanged: false },
        dropOff: { date: '', dateChanged: false },
        vehicles: [],
        fetching: true,
        timestamp: undefined,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_CHEAPEST_OFFER]: (state, action) => ({
    cheapestOfferLists: {
      ...state.cheapestOfferLists,
      [action.key]: {
        offerSummary: null,
        fetching: true,
        timestamp: undefined,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION]: (state, action) => ({
    offerImportantInformationLists: {
      ...state.offerImportantInformationLists,
      [action.key]: {
        data: [],
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE]: (state, action) => ({
    offerInsuranceQuoteLists: {
      ...state.offerInsuranceQuoteLists,
      [action.key]: {
        data: null,
        fetching: true,
        timestamp: undefined,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_RESERVATION]: (state, action) => ({
    reservationInfo: {
      ...state.reservationInfo,
      [action.key]: {
        reservation: null,
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO]: (state, action) => ({
    reservationInsuranceInfo: {
      ...state.reservationInsuranceInfo,
      [action.key]: {
        data: {},
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_DESTINATION_PRICE_INFO]: (state, action) => ({
    destinationsPriceInfoList: {
      ...state.destinationsPriceInfoList,
      [action.key]: {
        destinations: [],
        fetching: true,
        error: undefined,
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.CarHireState>({
  [FETCH_CAR_HIRE_OFFERS]: (state, action) => ({
    offerLists: {
      ...state.offerLists,
      [action.key]: {
        ...action.data,
        fetching: false,
        error: undefined,
        timestamp: Date.now(),
      },
    },
    fetchingAvailableVehicles: false,
  }),
  [FETCH_CAR_HIRE_CHEAPEST_OFFER]: (state, action) => ({
    cheapestOfferLists: {
      ...state.cheapestOfferLists,
      [action.key]: {
        offerSummary: action.data,
        fetching: false,
        error: undefined,
        timestamp: Date.now(),
      },
    },
  }),
  [FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION]: (state, action) => ({
    offerImportantInformationLists: {
      ...state.offerImportantInformationLists,
      [action.key]: {
        data: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE]: (state, action) => ({
    offerInsuranceQuoteLists: {
      ...state.offerInsuranceQuoteLists,
      [action.key]: {
        data: action.data,
        fetching: false,
        error: undefined,
        timestamp: Date.now(),
      },
    },
  }),
  [FETCH_CAR_HIRE_RESERVATION]: (state, action) => ({
    reservationInfo: {
      ...state.reservationInfo,
      [action.key]: {
        reservation: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO]: (state, action) => ({
    reservationInsuranceInfo: {
      ...state.reservationInsuranceInfo,
      [action.key]: {
        data: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_CAR_HIRE_DESTINATION_PRICE_INFO]: (state, action) => ({
    destinationsPriceInfoList: {
      ...state.destinationsPriceInfoList,
      [action.key]: {
        destinations: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.CarHireState>({
  [FETCH_CAR_HIRE_OFFERS]: (state, action) => ({
    offerLists: {
      ...state.offerLists,
      [action.key]: {
        pickUp: { date: '', dateChanged: false },
        dropOff: { date: '', dateChanged: false },
        vehicles: [],
        fetching: false,
        timestamp: (action.error?.status || 500) < 500 ? 0 : Date.now(),
        error: action.error?.message ?? action.error,
      },
    },
  }),
  [FETCH_CAR_HIRE_CHEAPEST_OFFER]: (state, action) => ({
    cheapestOfferLists: {
      ...state.cheapestOfferLists,
      [action.key]: {
        offerSummary: null,
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION]: (state, action) => ({
    offerImportantInformationLists: {
      ...state.offerImportantInformationLists,
      [action.key]: {
        data: [],
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE]: (state, action) => ({
    offerInsuranceQuoteLists: {
      ...state.offerInsuranceQuoteLists,
      [action.key]: {
        data: null,
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_CAR_HIRE_RESERVATION]: (state, action) => ({
    reservationInfo: {
      ...state.reservationInfo,
      [action.key]: {
        reservation: null,
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO]: (state, action) => ({
    reservationInsuranceInfo: {
      ...state.reservationInsuranceInfo,
      [action.key]: {
        data: {},
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_CAR_HIRE_DESTINATION_PRICE_INFO]: (state, action) => ({
    destinationsPriceInfoList: {
      ...state.destinationsPriceInfoList,
      [action.key]: {
        destinations: [],
        fetching: false,
        error: action.error,
      },
    },
  }),
})

export default createReducer<App.CarHireState>(initialCarHireState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CHECKOUT_PROCESS_SUCCESS]: () => ({
    offerLists: {},
    reservationInsuranceInfo: {},
    reservationInfo: {},
    offerInsuranceQuoteLists: {},
  }),
})

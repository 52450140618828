interface LocationData {
  location: { latitude: string, longitude: string }
}

// this is a business function at the moment because it only considers hotel offers
export function getLocationDataFromOffer(offer: App.AnyOffer): LocationData {
  let locationData: LocationData = { location: { latitude: '', longitude: '' } }
  // not parsing placeid
  // we do not offer bundle_and_save
  switch (offer.type) {
    case 'hotel':
      locationData = { location: { latitude: offer.property?.latitude.toString() || '', longitude: offer.property?.longitude.toString() || '' } }
      break
    case 'last_minute_hotel':
      locationData = { location: { latitude: offer.property?.latitude.toString() || '', longitude: offer.property?.longitude.toString() || '' } }
      break
    case 'tactical_ao_hotel':
      locationData = { location: { latitude: offer.property?.latitude.toString() || '', longitude: offer.property?.longitude.toString() || '' } }
      break
    case 'bedbank_hotel':
      locationData = { location: { latitude: offer.property.latitude.toString(), longitude: offer.property.longitude.toString() } }
      break
  }

  return locationData
}

// module keyCode.js provides javascript keyboardEvent's keycode

export const KEYCODE_ESC = 27
export const KEYCODE_UP = 38
export const KEYCODE_DOWN = 40
export const KEYCODE_HOME = 36
export const KEYCODE_END = 35
export const KEYCODE_ENTER = 13
export const KEYCODE_SPACE = 32
export const KEYCODE_BACKSPACE = 8
export const KEYCODE_DELETE = 46

import OfferTilePricing from 'components/OfferList/OfferListTiles/Support/OfferTilePricing'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import { useQsQueryParams } from 'hooks/useQueryParams'
import { isCruiseOffer } from 'lib/offer/offerTypes'
import React from 'react'
import CruiseSearchTilePricing from './CruiseSearchTilePricing'
import CruiseSearchTilePricingInfo from './CruiseSearchTilePricingInfo'

interface Props {
  view: CruiseOfferView;
}

function CruiseSearchTilePriceStack({ view }: Props) {
  const isPricePerNight = useCruisePriceByNight()
  const hasMoreThanOneAvailableCabinCategoriesPriceVariations = view.availableCabinCategoriesPriceVariations && view.availableCabinCategoriesPriceVariations.size > 1
  const { cabinTypes } = useQsQueryParams<{ cabinTypes?: string }>()

  if (isCruiseOffer(view.offer)) {
    if (hasMoreThanOneAvailableCabinCategoriesPriceVariations && !cabinTypes) {
      return <CruiseSearchTilePricingInfo view={view} />
    }
    return <CruiseSearchTilePricing view={view} />
  }

  return <OfferTilePricing
    offer={view.offer}
    isPricePerNight={isPricePerNight}
    pkg={view.offer.lowestPricePackage}
  />
}

export default CruiseSearchTilePriceStack

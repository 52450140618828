export const PROVIDER_COVER_GENIUS = 'cover_genius'
export const PROVIDER_NIB = 'nib'

export const PRODUCT_ID_COMPREHENSIVE = 'COMPREHENSIVE_TRIP_PROTECTION'
export const PRODUCT_ID_OVERSEA_MEDICAL = 'OVERSEAS_MEDICAL_AND_EVACUATION_PROTECTION'
export const PRODUCT_ID_CANCELLATION_AND_BAGGAGE = 'CANCELLATION_AND_BAGGAGE_PROTECTION'
export const PRODUCT_ID_NO_PROTECTION = 'NO_PROTECTION'
export const PRODUCT_ID_FULL_PROTECTION_CRUISE_DOMESTIC = 'FULL_PROTECTION_CRUISE_DOMESTIC'
export const PRODUCT_ID_FULL_PROTECTION_CRUISE = 'FULL_PROTECTION_CRUISE'
export const PRODUCT_ID_CUSTOMISE = 'CUSTOMISE_PROTECTION'
export const PRODUCT_ID_CUSTOMISE_DOMESTIC = 'CUSTOMISE_PROTECTION_DOMESTIC'
/** These insurance product ids are used for the 'customised' options  */
export const CustomisableProductIds = new Set([PRODUCT_ID_CUSTOMISE, PRODUCT_ID_CUSTOMISE_DOMESTIC])

export const INSURANCE_CANCELLATION_REASON_UPGRADED = 'insurance_upgraded'

export const CRUISE_INSURANCE_LIMIT_AGE = 80

export const CUSTOM_AMOUNT_COVERAGE_OPTIONS = [1000, 2000, 3000, 4000, 5000, 6000, 8000, 10000, 15000, 20000, 30000]

export const CUSTOM_AMOUNT_COVERAGE_MULTIPLIER = 1.7

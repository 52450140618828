import { getPlural, pluralizeToString } from 'lib/string/pluralize'

export function HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(saleUnit: string) {
  return `There are no ${getPlural(saleUnit)} available for your selected dates.`
}

export function HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES() {
  return 'We found a limited time deal for your hotel. Choose dates and book now before it ends!'
}

export function HIGHLIGHT_MESSAGE_STAY_MIN_NIGHTS(minNumNights: number) {
  return `Stay for at least ${pluralizeToString('night', minNumNights)} to enjoy this special offer`
}

import { activateOptimizelyExperiment, setOptimizelyTriggeredCookie } from 'lib/optimizely/optimizelyUtils'
import { SET_OPTIMIZELY_EXPERIMENT, SET_OPTIMIZELY_FEATURE_FLAG } from './actionConstants'
import * as Analytics from 'analytics/analytics'
import { activateCampaignEvent } from 'analytics/snowplow/events'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import { AppAction } from './ActionTypes'

export function setOptimizelyExperiment(flagKey: OptimizelyExperiments): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const experimentState = state.optimizely.optimizelyExperiments[flagKey]
    const experimentValues = await activateOptimizelyExperiment(flagKey)
    if (experimentState && experimentValues && experimentState.variation && experimentValues.variationKey) {
      const variationId = experimentState.variation[experimentValues.variationKey]

      if (experimentValues.triggered) {
        setOptimizelyTriggeredCookie(flagKey)
      }

      // This Snowplow event is vital as we use it to trigger users into the Optimizely
      // report through their api.  It only has to be sent once per user per experiment.
      if (
        !!variationId &&
        experimentState.campaignId &&
        experimentState.experimentId &&
        !experimentState.triggered
      ) {
        Analytics.trackEvent(activateCampaignEvent(
          experimentState.campaignId,
          experimentState.experimentId,
          variationId,
        ))
      }

      if (
        experimentState.variationKey !== experimentValues.variationKey ||
        experimentState.triggered !== experimentValues.triggered
      ) {
        dispatch({
          type: SET_OPTIMIZELY_EXPERIMENT,
          flagKey,
          value: experimentValues,
        })
      }
    }
  }
}

export function setOptimizelyFeatureFlag(flagKey: OptimizelyFeatureFlags): AppAction {
  return async(dispatch, getState) => {
    const state = getState()
    const experimentValues = await activateOptimizelyExperiment(flagKey)

    if (state.optimizely.optimizelyFeatureFlags[flagKey] !== experimentValues?.enabled) {
      dispatch({
        type: SET_OPTIMIZELY_FEATURE_FLAG,
        flagKey,
        value: experimentValues?.enabled,
      })
    }
  }
}

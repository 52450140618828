import { API_CALL } from 'actions/actionConstants'
import { AppAction } from 'actions/ActionTypes'
import { FETCH_TOURS_POPULAR_DESTINATIONS } from 'actions/apiActionConstants'
import { reqToursPopularDestinations } from 'api/search'

export function fetchToursPopularDestinations(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (
      !state.destination.tours?.isFetchingPopularDestinations &&
      (!state.destination.tours?.popularDestinations || state.destination.tours?.popularDestinations.length === 0)
    ) {
      dispatch({
        type: API_CALL,
        api: FETCH_TOURS_POPULAR_DESTINATIONS,
        request: () => reqToursPopularDestinations(state.geo.currentRegionCode),
      })
    }
  }
}

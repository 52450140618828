import fetchAccountBusiness from 'actions/businessTraveller/fetchBusinessAccount'
import fetchCalculatedBusinessCredits from 'actions/businessTraveller/fetchCalculatedBusinessCredits'
import { generateOfferCreditKey } from 'businessTraveller/utils/generateOfferCreditKey'
import { getLocationDataFromOffer } from 'businessTraveller/utils/getLocationDataFromOffer'
import GeoContext from 'contexts/geoContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useContext, useEffect, useMemo } from 'react'

interface Params {
  cost: number
  numberOfNights: number
  offer: App.AnyOffer
}

function useBusinessTravellerOfferCreditFetcher(params: Params) {
  const {
    cost,
    numberOfNights,
    offer,
  } = params
  const locationData = getLocationDataFromOffer(offer)
  const { currentCurrency } = useContext(GeoContext)
  const appDispatch = useAppDispatch()
  const offersCredits = useAppSelector(state => state.businessTraveller.offersCredits)
  const businessId = useAppSelector(state => state.businessTraveller.currentBusinessAccount?.business?.id)

  const {
    offerCreditKey,
    toBeCalculatedBusinessCreditItem,
  } = useMemo(() => {
    const toBeCalculatedBusinessCreditItem: BusinessTraveller.ToBeCalculatedBusinessCreditItem = {
      itemPolicyType: 'hotel',
      numberOfNights,
      cost,
      currencyCode: currentCurrency,
      location: locationData.location,
    }

    return {
      offerCreditKey: generateOfferCreditKey(toBeCalculatedBusinessCreditItem, offer),
      toBeCalculatedBusinessCreditItem,
    }
  }, [numberOfNights, cost, currentCurrency, locationData.location, offer])

  useEffect(() => {
    appDispatch(fetchAccountBusiness())
  }, [appDispatch])

  useEffect(() => {
    if (businessId) {
      appDispatch(fetchCalculatedBusinessCredits(
        [offerCreditKey, toBeCalculatedBusinessCreditItem],
        businessId,
      ))
    }
  }, [offerCreditKey, toBeCalculatedBusinessCreditItem, businessId, appDispatch])

  const offerCreditData = offersCredits?.[offerCreditKey]

  return {
    offerCreditData,
    offerCreditKey,
    toBeCalculatedBusinessCreditItem,
  }
}

export default useBusinessTravellerOfferCreditFetcher

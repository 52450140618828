import DeprecatedAutoTooltipContent from 'components/Common/DeprecatedAutoTooltipContent'
import { rem } from 'polished'
import React, { RefObject, useRef } from 'react'
import styled from 'styled-components'
import SearchInputTrigger from '../SearchInputTrigger'
import Content from './Content'
import SearchTravellersDropdown from './SearchTravellersDropdown'

const SearchTravellersInput = styled(SearchInputTrigger)`
  max-height: ${rem(50)};
`

interface Props {
  dropdownAnchorRef: RefObject<HTMLElement>
  isToggled: boolean;
  onTravellersToggle:(e: React.MouseEvent<HTMLButtonElement>) => void;
  rooms: Array<App.Occupants>;
  onRoomChange: (rooms: Array<App.Occupants>) => void;
  onRoomsApply: () => void;
  closeMenu: () => void;
  isLastStep?: boolean;
  error: boolean;
  saleUnit?: string
  disabled?: boolean
}

function SearchTravellersInputDesktop(props: Props) {
  const {
    dropdownAnchorRef,
    rooms,
    onTravellersToggle,
    isToggled,
    onRoomChange,
    onRoomsApply,
    closeMenu,
    isLastStep,
    error,
    saleUnit,
    disabled,
  } = props

  const inputRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <SearchTravellersInput
        ref={inputRef}
        onClick={onTravellersToggle}
        isToggled={isToggled}
        error={error}
        type="occupancy"
        disabled={!!disabled}
      >
        <Content />
        <DeprecatedAutoTooltipContent open={error} position="above-left" alignment="left" type="error">
          Please select children's ages
        </DeprecatedAutoTooltipContent>
      </SearchTravellersInput>

      <SearchTravellersDropdown
        anchorRef={dropdownAnchorRef}
        triggerRef={inputRef}
        rooms={rooms}
        open={isToggled}
        onRoomChange={onRoomChange}
        onRoomsApply={onRoomsApply}
        onClose={closeMenu}
        isLastStep={isLastStep}
        saleUnit={saleUnit}
      />
    </>
  )
}

export default SearchTravellersInputDesktop

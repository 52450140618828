import React, { useCallback, useEffect } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import * as Analytics from 'analytics/analytics'
import ImageHero from 'components/Common/ImageHero'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { ModalSteps } from '../defaults'

const Content = styled(Group)`
  padding: 0 ${rem(20)};
`

const Header = styled(ImageHero)`
  max-height: ${rem(168)};
`

interface Props {
  onSetNextStep: (step: ModalSteps, isSaveAction?: boolean) => void;
  onClose: () => void;
}

function TravelPreferencesModalMobileInitial(props: Props) {
  const { onSetNextStep, onClose } = props

  const onSetTravelPreferences = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'intro_modal',
      action: 'completion',
      type: 'interaction',
      category: 'travel_preferences',
    })
    onSetNextStep(ModalSteps.holidayTypes)
  }, [onSetNextStep])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'intro_modal',
      action: 'impression',
      type: 'nonInteraction',
      category: 'travel_preferences',
    })
  }, [])

  return (
    <>
      <ModalBody>
        <Group direction="vertical" gap={20}>
          <Header
            fixedHeight
            brightness={-30}
            gravity="auto"
            fit="center"
            quality="good"
            aspectRatio="5:2"
            id="sei91ly3rbrcugig5179"
          >
            <Group direction="vertical" gap={4}>
              <Heading variant="heading2" align="center" colour="neutral-eight">Want a <i>personalised</i></Heading>
              <Heading variant="heading2" align="center" colour="neutral-eight">experience?</Heading>
            </Group>
          </Header>
          <Content direction="vertical" gap={8}>
            <BodyText variant="large" colour="neutral-two">Set your travel preferences to see tailored recommendations, plus a faster search and booking experience.</BodyText>
          </Content>
        </Group>
      </ModalBody>
      <ModalFooter
        actionsLayout="stacked"
        primaryActionProps={{ children: 'Set travel preferences', onClick: onSetTravelPreferences }}
        secondaryActionProps={{ children: 'Skip for now', onClick: onClose }}
      />
    </>
  )
}

export default TravelPreferencesModalMobileInitial

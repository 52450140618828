import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineDiningCreditIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M2.869 2.2917a1 1 0 0 0-1.6832.4902l.9762.2169c-.9762-.217-.9761-.2173-.9762-.217l-.0004.0018-.0005.002-.0012.0055-.0033.016a6.3708 6.3708 0 0 0-.042.2266 9.5536 9.5536 0 0 0-.0809.6156 11.38 11.38 0 0 0-.017 2.0575c.1364 1.6384.689 3.7738 2.4143 5.4991l.0055.0054 3.5647 3.5102-5.5702 5.5702c-.3905.3905-.3905 1.0237 0 1.4142.3906.3905 1.0237.3905 1.4143 0l8.2929-8.2929 1.866 1.8661c.3905.3905 1.0237.3905 1.4142 0 .3906-.3905.3906-1.0237 0-1.4142l-1.866-1.8661 1.3358-1.3358.1286.1287c1.1716 1.1716 3.0711 1.1716 4.2427 0l2.5858-2.5858c.3905-.3905.3905-1.0237 0-1.4142-.3906-.3905-1.0237-.3905-1.4143 0l-2.5857 2.5858c-.3906.3905-1.0237.3905-1.4143 0l-.1286-.1287 3.5429-3.5429c.3905-.3905.3905-1.0237 0-1.4142-.3906-.3905-1.0237-.3905-1.4143 0L13.912 7.8346l-.1287-.1287c-.3906-.3905-.3906-1.0237 0-1.4142l2.5858-2.5858c.3905-.3905.3905-1.0237 0-1.4142-.3906-.3905-1.0237-.3905-1.4143 0l-2.5857 2.5858c-1.1716 1.1716-1.1716 3.071 0 4.2426l.1286.1287-1.3357 1.3358-8.293-8.2929Zm.1645 3.249a9.0577 9.0577 0 0 1-.0187-.2748l6.733 6.7329-1.3085 1.3085-3.5728-3.5182c-1.2727-1.2743-1.7196-2.8876-1.833-4.2483Z" clipRule="evenodd"/>
    <path fill="currentColor" d="M19.8552 11.4288c.5522 0 1 .4477 1 1v.4287h1.1429c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2.8574a.588.588 0 0 0-.1494.024c-.0658.0188-.1201.0451-.1592.0733a.2321.2321 0 0 0-.0751.0844c-.0163.0316-.045.104-.045.247 0 .143.0287.2154.045.247a.2321.2321 0 0 0 .0751.0844c.0391.0282.0934.0545.1592.0733a.588.588 0 0 0 .1494.024h1.4287c.3981 0 .9731.1112 1.4772.4742.5552.3997.9515 1.0571.9515 1.9545s-.3963 1.5548-.9515 1.9545c-.3932.2831-.8296.4131-1.1914.4566v.4463c0 .5523-.4478 1-1 1-.5523 0-1-.4477-1-1v-.4287H17.712c-.5523 0-1-.4477-1-1s.4477-1 1-1h2.8574a.5887.5887 0 0 0 .1494-.024c.0658-.0188.1201-.0451.1592-.0733a.2313.2313 0 0 0 .0751-.0844c.0163-.0316.045-.104.045-.247 0-.143-.0287-.2154-.045-.247a.2321.2321 0 0 0-.0751-.0844c-.0391-.0282-.0934-.0545-.1592-.0733a.5887.5887 0 0 0-.1494-.024h-1.4287c-.3982 0-.9731-.1112-1.4773-.4742-.5551-.3997-.9514-1.0571-.9514-1.9545s.3963-1.5548.9514-1.9545c.3934-.2832.8298-.4131 1.1918-.4566v-.4463c0-.5523.4477-1 1-1Z"/>
  </SvgIcon>
}

export default LineDiningCreditIcon

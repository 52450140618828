import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFastMailIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M4.79 10.5h-2a1.0002 1.0002 0 0 0-.707 1.7071.9997.9997 0 0 0 .707.2929h2a.9997.9997 0 0 0 1-1 1 1 0 0 0-1-1Zm16.78-2.84V7.6a3 3 0 0 0-2.37-1.1h-7.93a2.9999 2.9999 0 0 0-2 .74A2.93 2.93 0 0 0 8.31 9l-.88 5a3 3 0 0 0 .66 2.45 2.9992 2.9992 0 0 0 2.29 1.07h7.94a2.9998 2.9998 0 0 0 3-2.48l.88-5a3 3 0 0 0-.63-2.38Zm-2.74.84-3.4 2.76c-.201.162-.4569.24-.7141.2177a1.0004 1.0004 0 0 1-.6659-.3377L11.72 8.5h7.11Zm.48 6.17a.9994.9994 0 0 1-.3468.5996.9994.9994 0 0 1-.6532.2304h-7.93a.999.999 0 0 1-.76-.36.9997.9997 0 0 1-.22-.81l.8-4.53 2.35 2.66a2.9997 2.9997 0 0 0 4.14.35L20.13 10l-.82 4.67ZM5.79 6.5h-3a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineFastMailIcon

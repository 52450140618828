/**
 * Selector for media where the primary input mechanism can conveniently hover over elements.
 * _(eg. presence of a pointer device)_
 *
 * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover#hover)
 *
 * @example
 * ```tsx
 * const HoverableElement = styled(Element)`
 *   ${mediaHoverable} {
 *     &:hover {
 *       property: value;
 *     }
 *   }
 * `
 * ```
 */
export const mediaHoverable = '@media (hover: hover)'

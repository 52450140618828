import { queryKeySelectedOfferIds } from 'constants/url'
import useQueryParams from 'hooks/useQueryParams'
import { encodeOfferIds } from 'lib/search/searchUtils'
import { useMemo } from 'react'

function useMapSearchOfferUrl(...offers: Array<App.AnyOffer | App.OfferListMetaData>): string {
  const urlSearchParams = useQueryParams()

  const url = useMemo(() => {
    const nextParams = new URLSearchParams(urlSearchParams)
    nextParams.set(queryKeySelectedOfferIds, encodeOfferIds(offers))
    return `/search/map?${nextParams.toString()}`
  }, [offers, urlSearchParams])

  return url
}

export default useMapSearchOfferUrl

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTreesIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.5 5a4.8597 4.8597 0 0 0-2.5.69 5.9997 5.9997 0 0 0-6.6822-3.486A6 6 0 0 0 2.5 8v3.9999a6.0002 6.0002 0 0 0 5 5.91v3.09a1.0002 1.0002 0 0 0 1 1 1 1 0 0 0 1-1v-3.09a6.079 6.079 0 0 0 2.78-1.26 5.0001 5.0001 0 0 0 3.22 2.25v2.1a1 1 0 0 0 2 0v-2.1a4.9996 4.9996 0 0 0 4-4.9v-4a5 5 0 0 0-5-5Zm-5 5v3.9999c.0096.191.0296.3813.06.57a3.9996 3.9996 0 0 1-2.06 1.3v-2.87a1.0001 1.0001 0 0 0-1.7071-.7071 1.0001 1.0001 0 0 0-.2929.7071v2.86a3.9999 3.9999 0 0 1-3-3.86v-4a4 4 0 0 1 6.83-2.84 3.9396 3.9396 0 0 1 1.06 2A4.9997 4.9997 0 0 0 11.5 10Zm8 3.9999a3.0002 3.0002 0 0 1-2 2.82v-3.82a1.0003 1.0003 0 0 0-1-1 1.0001 1.0001 0 0 0-1 1v3.82a3.0001 3.0001 0 0 1-2-2.82v-4a3 3 0 0 1 6 0v4Z"/>
  </SvgIcon>
}

export default LineTreesIcon

import { getExperienceMobileAppDiscountTotal, getExperienceItemsView, getTransferMobileAppDiscountTotal, getTransferItemsView } from 'checkout/selectors/view/experience'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { isExperienceItem } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'

export const isStandaloneExperiences = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.every(isExperienceItem),
)

export const getExperienceTotals = createSelector(
  getExperienceItemsView,
  getExperienceMobileAppDiscountTotal,
  (viewWithStatus, mobileAppDiscountTotals): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)

    const taxesAndFees = sum(definedItems.map(item => item.ticketViews.map(view => view.taxesAndFees ? view.taxesAndFees * view.count : view.taxesAndFees)).flat())

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => itemView.price),
        memberPrice: 0,
        value: sum(definedItems, itemView => itemView.value ?? 0),
        surcharge: 0,
        taxesAndFees,
        mobileAppDiscount: mobileAppDiscountTotals,
      },
    }
  },
)

export const getTransferTotals = createSelector(
  getTransferItemsView,
  getTransferMobileAppDiscountTotal,
  (viewWithStatus, mobileAppDiscountTotals): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => itemView.transfer.option?.price ?? 0),
        memberPrice: 0,
        value: sum(definedItems, itemView => itemView.transfer.option?.price ?? 0),
        surcharge: 0,
        taxesAndFees: sum(definedItems, itemView => itemView.transfer.option?.taxesAndFees ?? 0),
        mobileAppDiscount: mobileAppDiscountTotals,
      },
    }
  },
)

import { jwtDecode } from 'jwt-decode'

export function extractTokenFields(accessToken: string): { agentId?: string } {
  try {
    const decodedAccessToken = jwtDecode<any>(accessToken)
    return {
      agentId: decodedAccessToken.aud,
    }
  } catch {
    // it means we don't have a valid token and was not possible to decode it
    return {}
  }
}

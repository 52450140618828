import { Price } from 'api/flights'
import { sum } from 'lib/array/arrayUtils'

export function getOtherFees(
  targetItem: App.Checkout.FlightItem,
  pricing?: Array<Price>,
) {
  const fees: App.Checkout.FlightFees = {
    flightBookingFee: 0,
    flightServiceFee: 0,
    flightCreditFee: 0,
    flightAtolFee: 0,
  }
  if (pricing) {
    return pricing.reduce((allFees, price) => {
      switch (price.type) {
        case 'atol_fee':
          allFees.flightAtolFee = price.amount
          break
        case 'service_fee':
          allFees.flightServiceFee = price.amount
          break
        case 'flights_credit_fee':
          allFees.flightCreditFee = targetItem.isFlightCredit ? price.amount : 0
          break
        case 'booking_fee':
          allFees.flightBookingFee = !targetItem.isFlightCredit ? price.amount : 0
          break
      }
      return allFees
    }, fees)
  } else {
    return fees
  }
}

export function computeFeesTotal(fees: Partial<App.Checkout.FlightFees>): number {
  return fees ? sum([
    fees?.flightCreditFee ?? 0,
    fees?.flightBookingFee ?? 0,
    fees?.flightAtolFee ?? 0,
    fees?.flightServiceFee ?? 0,
  ]) : 0
}

import React from 'react'
import CaptionTextBlock from '../TextBlocks/CaptionTextBlock'
import FormatCurrency from 'components/Common/FormatCurrency'
import Caption from '../Typography/Caption'
import LinePlaneDepartureIcon from '../Icons/line/LinePlaneDepartureIcon'

interface Props {
  product: 'hotel' | 'tour'
  price: number | undefined
  /**
   * Appends the price with "/person"
   *
   * Wouldn't have any effect if `price` is not provided.
   */
  pricePerPerson?: boolean
  'data-testid'?: string
}

const PRODUCTS_TITLES: Record<Props['product'], string> = {
  hotel: 'Hotel',
  tour: 'Tour',
}

function PriceRowFlightPackage(props: Props) {
  const {
    product,
    price,
    pricePerPerson,
    'data-testid': dataTestId,
  } = props

  return <CaptionTextBlock
    data-testid={dataTestId}
    className={PriceRowFlightPackage.name}
    variant="large"
    colour="neutral-two"
    weight="normal"
    wrap="pre-wrap"
    startIcon={<LinePlaneDepartureIcon />}
  >
    {PRODUCTS_TITLES[product]} + flights{' '}
    {!!price && <>
      from{' '}
      <Caption
        as="span"
        variant="large"
        colour="neutral-one"
        weight="bold"
      >
        <FormatCurrency value={price} format="roundedDollar" />*
      </Caption>
      {pricePerPerson && ' /person'}
    </>}
    {price === undefined && 'package available'}
  </CaptionTextBlock>
}

export default PriceRowFlightPackage

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

export const KEYBOARD_MODE_CSS_VAR = '--global-keyboard-mode'
export const MOUSE_MODE_CSS_VAR = '--global-mouse-mode'
export const TOUCH_MODE_CSS_VAR = '--global-touch-mode'

export enum InputModeType {
  keyboard = 'keyboard',
  mouse = 'mouse',
  touch = 'touch',
}
const InputModeContext = React.createContext<InputModeType>(InputModeType.mouse)

export function InputModeProvider(props: PropsWithChildren) {
  const [currentInputMode, setInputMode] = useState<InputModeType>(InputModeType.mouse)
  const inputModeRef = useRef<InputModeType>()

  useEffect(() => {
    const setKeyboardMode = () => {
      if (inputModeRef.current !== InputModeType.keyboard) {
        inputModeRef.current = InputModeType.keyboard
        setInputMode(InputModeType.keyboard)
        document.documentElement.style.removeProperty(KEYBOARD_MODE_CSS_VAR)
        document.documentElement.style.setProperty(MOUSE_MODE_CSS_VAR, 'none')
        document.documentElement.style.setProperty(TOUCH_MODE_CSS_VAR, 'none')
        document.body.classList.add('input-keyboard')
        document.body.classList.remove('input-mouse')
        document.body.classList.remove('input-touch')
      }
    }

    const setMouseMode = () => {
      if (inputModeRef.current !== InputModeType.mouse) {
        inputModeRef.current = InputModeType.mouse
        setInputMode(InputModeType.mouse)
        document.documentElement.style.removeProperty(MOUSE_MODE_CSS_VAR)
        document.documentElement.style.setProperty(KEYBOARD_MODE_CSS_VAR, 'none')
        document.documentElement.style.setProperty(TOUCH_MODE_CSS_VAR, 'none')
        document.body.classList.remove('input-keyboard')
        document.body.classList.add('input-mouse')
        document.body.classList.remove('input-touch')
      }
    }

    const setTouchMode = () => {
      if (inputModeRef.current !== InputModeType.touch) {
        inputModeRef.current = InputModeType.touch
        setInputMode(InputModeType.touch)
        document.documentElement.style.removeProperty(TOUCH_MODE_CSS_VAR)
        document.documentElement.style.setProperty(KEYBOARD_MODE_CSS_VAR, 'none')
        document.documentElement.style.setProperty(MOUSE_MODE_CSS_VAR, 'none')
        document.body.classList.remove('input-keyboard')
        document.body.classList.remove('input-mouse')
        document.body.classList.add('input-touch')
      }
    }

    setMouseMode()

    window.addEventListener('keydown', setKeyboardMode)
    window.addEventListener('mousedown', setMouseMode)
    window.addEventListener('touchstart', setTouchMode)

    return () => {
      window.removeEventListener('keydown', setKeyboardMode)
      window.removeEventListener('mousedown', setMouseMode)
      window.removeEventListener('touchstart', setTouchMode)
    }
  }, [])

  return <InputModeContext.Provider value={currentInputMode}>
    {props.children}
  </InputModeContext.Provider>
}

export default InputModeContext

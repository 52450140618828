import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { addMonths, endOfMonth, dateIsAfter, dateIsBefore, startOfMonth } from 'lib/datetime/dateUtils'
import { rem } from 'polished'
import cn from 'clsx'

import Heading from 'components/Luxkit/Typography/Heading'
import Clickable from 'components/Common/Clickable'
import moment from 'moment'
import BasicCalendar from 'components/Common/Calendar/BasicCalendar'
import LineAngleLeftBIcon from 'components/Luxkit/Icons/line/LineAngleLeftBIcon'
import LineAngleRightBIcon from 'components/Luxkit/Icons/line/LineAngleRightBIcon'
import SearchDatePickerDay from './SearchDatePickerDay'
import noop from 'lib/function/noop'

const Root = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: ${rem(24)};
  }
`

const DatePicker = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  > * {
    flex-grow: 1;
  }
`

const CalendarWrapper = styled.div`
  &.left-spacing {
    margin-left: ${rem(52)};
  }
`

const MonthChangeElement = styled(Clickable)`
  position: absolute;
  top: ${rem(0)};
  transition: color 0.2s;

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }
`

const LeftArrow = styled(MonthChangeElement)`
  left: 0;
`

const RightArrow = styled(MonthChangeElement)`
  right: 0;
`

const Calendar = styled(BasicCalendar)`
  margin-top: ${rem(32)};
`

interface Props {
  onDatesChange?: (dates: { startDate: moment.Moment; endDate: moment.Moment; }) => void;
  endDate?: moment.Moment;
  startDate?: moment.Moment;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  type: 'single' | 'dual';
  availableMonths?: Array<string>;
}

function SearchDatesPicker(props: Props) {
  const {
    onDatesChange = noop,
    endDate,
    startDate,
    minDate,
    maxDate,
    className,
    type,
    availableMonths,
  } = props

  const [indexMonth, setIndexMonth] = useState<number>(0)
  const [date, setDate] = useState<Date>(startDate?.toDate() || new Date())
  const showDualCalendar = type === 'dual'

  const onDayClick = useCallback((selectedDay: Date) => {
    const day = moment(selectedDay)

    if (startDate && !endDate && day.isAfter(startDate)) {
      onDatesChange({ startDate, endDate: day })
    } else {
      onDatesChange({ startDate: day, endDate: null })
    }
  }, [onDatesChange, startDate, endDate])

  const onPrevMonth = useCallback(() => {
    if (availableMonths) {
      if (!indexMonth) return
      setIndexMonth(indexMonth - 1)
    }
    setDate(addMonths(date, -1))
  }, [availableMonths, date, indexMonth])

  const onNextMonth = useCallback(() => {
    if (availableMonths) {
      if (indexMonth >= availableMonths.length - 1) return
      setIndexMonth(indexMonth + 1)
    }
    setDate(addMonths(date, 1))
  }, [availableMonths, date, indexMonth])

  const getCalendar = (month: number) => {
    return (
      <Calendar
        month={month}
        year={currentDate.getFullYear()}
        dayRender={(day: Date) => {
          const dayTime = day.getTime()
          const dateOfDay = moment(day)
          const selected = startDate?.valueOf() === dayTime || endDate?.valueOf() === dayTime
          const disabled = dayTime < minDate?.valueOf()! || dayTime > maxDate?.valueOf()!
          const isInRange = (!selected && startDate && endDate) ? dateOfDay.isAfter(startDate) && dateOfDay.isBefore(endDate) : false
          const dataTestId = `day-${dateOfDay.format('YYYY-MM-DD')}`

          return <SearchDatePickerDay
            key={dayTime}
            day={day}
            dataTestId={dataTestId}
            selected={selected}
            disabled={disabled}
            isInRange={isInRange}
            onClick={onDayClick}
          />
        }}
      />
    )
  }

  const currentDate = useMemo(() => {
    if (availableMonths?.[indexMonth]) {
      return moment(availableMonths[indexMonth], 'YYYY-MM').toDate()
    }
    return date
  }, [availableMonths, date, indexMonth])

  const nextDate = useMemo(() => {
    if (availableMonths) {
      if (indexMonth < availableMonths.length - 1) {
        return moment(availableMonths[indexMonth + 1], 'YYYY-MM').toDate()
      }
      // if we're at the last month, we want to show the next month
      return moment(availableMonths[indexMonth], 'YYYY-MM').add(1, 'month').toDate()
    }
    return addMonths(date, 1)
  }, [availableMonths, date, indexMonth])

  return (
    <Root>
      <DatePicker className={className}>
        <CalendarWrapper>
          <Heading variant="heading6" align="center">
            {moment(currentDate).format('MMMM')} {moment(currentDate).format('YYYY')}
          </Heading>
          {getCalendar(currentDate.getMonth())}
        </CalendarWrapper>

        {showDualCalendar &&
          <CalendarWrapper className={cn({ 'left-spacing': true })}>
            <Heading variant="heading6" align="center">
              {moment(nextDate).format('MMMM')} {moment(nextDate).format('YYYY')}
            </Heading>
            {getCalendar(nextDate.getMonth())}
          </CalendarWrapper>
        }

        <LeftArrow onClick={onPrevMonth} disabled={dateIsBefore(startOfMonth(currentDate), minDate)}>
          <LineAngleLeftBIcon size="S" />
        </LeftArrow>
        <RightArrow onClick={onNextMonth} data-testid="next-month-calendar" disabled={dateIsAfter(endOfMonth(addMonths(currentDate, 1)))}>
          <LineAngleRightBIcon size="S" />
        </RightArrow>
      </DatePicker>
    </Root>
  )
}

export default SearchDatesPicker

import { Request } from 'express'

import { getCookies } from 'lib/web/cookieUtils'
import config from 'constants/config'
const googleAnalyticsSessionCookie = config.GOOGLE_SESSION_COOKIE
const googleAnalyticsClientCookie = '_ga'

// This function uses either the request object (in the case of being used server-side)
// or the getCookies function if used client-side
export function getGoogleSessionId(req?: Request): string | null {
  const cookies = req ? req.cookies as Record<string, string> : getCookies()
  if (googleAnalyticsSessionCookie && googleAnalyticsSessionCookie in cookies) {
    const cookie = cookies[googleAnalyticsSessionCookie]
    const cookieArray = cookie.split('.')
    if (cookieArray.length >= 3) {
      return cookieArray[2]
    }
  }
  return null
}

export function getGoogleClientId(req?: Request): string | null {
  const cookies = req ? req.cookies as Record<string, string> : getCookies()
  if (googleAnalyticsClientCookie in cookies) {
    const cookie = cookies[googleAnalyticsClientCookie]
    const cookieArray = cookie.split('.')
    if (cookieArray.length >= 4) {
      return cookieArray[2] + '.' + cookieArray[3]
    }
  }
  return null
}

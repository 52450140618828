import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import Group from 'components/utils/Group'
import useInterval from 'hooks/useInterval'
import { pluralizeToString } from 'lib/string/pluralize'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import useHasMounted from 'hooks/useHasMounted'

const FixedWidthCountdown = styled.div`
  &.fixed-width {
    width: 13ch;
  }
`

const DEFAULT_DAYS_TO_BOOK = 4

interface Props {
  className?: string;
  daysToBook?: number
  endDate: string;
  showHourOnly?: boolean;
  isLuxPlusEarlyAccess?: boolean;
  showEndsSoonLabel?: boolean;
}

function OfferUrgencyCountdownLabel(props: Props) {
  const { endDate, daysToBook = DEFAULT_DAYS_TO_BOOK, showHourOnly = false, isLuxPlusEarlyAccess = false, showEndsSoonLabel = false, ...rest } = props
  const [now, setNow] = useState<moment.Moment>(moment())
  const luxPlusEnabled = useAppSelector(state => isLuxPlusEnabled(state))

  const hasMounted = useHasMounted()

  const countdownMessage = useMemo(() => {
    const end = moment(endDate)

    const leftToTheEnd = moment.duration(end.diff(now))
    const roundedEndDays = Math.round(leftToTheEnd.asDays())
    const endHours = leftToTheEnd.asHours()

    if (endHours > 24) {
      if (isLuxPlusEarlyAccess && luxPlusEnabled) {
        return `Priority access ends in ${pluralizeToString('day', roundedEndDays)}`
      }

      if (roundedEndDays <= daysToBook) {
        return `Offer ends in ${pluralizeToString('day', roundedEndDays)}`
      }

      if (roundedEndDays > daysToBook && showEndsSoonLabel) {
        return 'Offer ends soon'
      }
    }

    if (endHours <= 24 && leftToTheEnd.asSeconds() > 0) {
      const timeLeft = moment.utc(leftToTheEnd.asMilliseconds())
      // round up the hour
      if (showHourOnly) timeLeft.add({ minutes: 59, seconds: 59 })
      const timeLeftString = showHourOnly ?
        timeLeft.format('HH[h]') :
        timeLeft.format('HH[h] : mm[m] : ss[s]')
      return <Group data-testid="UrgencyLabelCountdown" direction="horizontal">{isLuxPlusEarlyAccess ? 'Priority access ends' : 'Offer ends'} in&nbsp;<FixedWidthCountdown className={cn({ 'fixed-width': !showHourOnly })}>{timeLeftString}</FixedWidthCountdown></Group>
    }
  }, [endDate, now, isLuxPlusEarlyAccess, luxPlusEnabled, daysToBook, showHourOnly, showEndsSoonLabel])

  // only update per minute if we only show hr
  const updateInterval = showHourOnly ? 60000 : 1000

  useInterval(() => setNow(moment()), updateInterval)

  if (!countdownMessage) {
    return null
  }

  return (
    <UrgencyLabel {...rest} variant="critical2">
      {hasMounted && countdownMessage}
    </UrgencyLabel>
  )
}

export default OfferUrgencyCountdownLabel

import { useMemo } from 'react'
import extractTourV2PurchasableOptionDetails, { TourV2PurchasableOptionDetails } from 'lib/tours/extractTourV2PurchasableOptionDetails'

function useTourV2PurchasableOptionDetails(
  purchasableOption?: Tours.TourV2OfferPurchasableOption,
): TourV2PurchasableOptionDetails | undefined {
  return useMemo(() => {
    if (!purchasableOption) {
      return undefined
    }

    return extractTourV2PurchasableOptionDetails(purchasableOption)
  }, [purchasableOption])
}

export default useTourV2PurchasableOptionDetails

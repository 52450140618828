import { pluralizeToString } from 'lib/string/pluralize'
import getLPCRegionLOS from './getLPCRegionLOS'

export default function getOfferDurationText(
  offer: App.Offer | App.OfferSummary,
  pkg: App.Package,
): string {
  if (offer.type === 'rental' && offer.lowestPricePackage) {
    return `${offer.lowestPricePackage.durationLabel.toLocaleLowerCase()} from`
  }
  if (pkg.duration) {
    return `${pluralizeToString('night', pkg.duration)} from`
  }
  if (offer.tileDurationLabel) {
    return `${offer.tileDurationLabel.toLocaleLowerCase()} from`
  }
  const LPCRegionLOS = getLPCRegionLOS(offer)
  if (LPCRegionLOS) {
    return `${pluralizeToString('night', LPCRegionLOS)} from`
  }
  return 'From'
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineKettleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M6.2032 4.4372C6.4734 3.023 7.7102 2 9.15 2h4.4286c1.4361 0 2.6708 1.0177 2.9449 2.4274l.1516.7796h.4977c1.3838 0 2.588.9465 2.915 2.291l1.8839 7.7455c.1305.5367-.1987 1.0775-.7354 1.2081-.5366.1305-1.0774-.1988-1.208-.7354l-1.8838-7.7455a1 1 0 0 0-.9717-.7637h-.1088l1.2499 6.4278c.1673.8604-.0493 1.6926-.5185 2.3369.6133.5493.9992 1.3473.9992 2.2353v.7926c0 1.6569-1.3432 3-3 3h-8.822c-1.6568 0-3-1.3431-3-3v-.8615c0-.8687.378-1.6492.9784-2.1859-.4574-.639-.6682-1.459-.5062-2.3075l.3429-1.7952a4.486 4.486 0 0 1-.07-.0843c-.1487-.1835-.3013-.3999-.4513-.6298-.3008-.4609-.6232-1.0254-.9241-1.5985-.3017-.5747-.5897-1.1732-.82-1.7065-.2214-.5129-.4176-1.0289-.5-1.4138A1 1 0 0 1 3 5.207h3.0562l.147-.7698Zm-.529 2.7698H4.4328c.195.439.4315.926.6804 1.4003.072.137.1444.272.2167.4035l.3445-1.8038Zm2.0362 0h7.3161l1.3241 6.8095c.1183.608-.3386 1.1736-.9533 1.1905h-8.034c-.6134-.0168-1.07-.5801-.954-1.1872L7.7104 7.207Zm6.9272-2H8.0924l.0753-.3946A1 1 0 0 1 9.15 4h4.4286a1 1 0 0 1 .9816.8091l.0774.398Zm-7.246 12.0004a2.6721 2.6721 0 0 1-.0491-.0004h-.4388a.9311.9311 0 0 0-.931.9311v.8615c0 .5523.4477 1 1 1h8.8219c.5522 0 1-.4477 1-1v-.7926c0-.5523-.4478-1-1-1h-.3765a2.6717 2.6717 0 0 1-.0491.0004H7.3916Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineKettleIcon

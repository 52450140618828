function generateDepartureRoomPriceBreakdown(
  occupants: App.Occupants,
  purchasableOption: Tours.TourV2OfferPurchasableOption,
  offer?: Tours.TourV2Offer,
): Tours.V2OfferDeparturePriceBreakdown {
  let totalPrice = 0
  let totalMemberPrice = 0
  let totalValuedAtPrice = 0

  const variation = offer?.variations[purchasableOption.fkVariationId]
  const roomTypePrice = offer?.roomTypePricing[purchasableOption.fkRoomTypePricingId ?? '']

  const minChildAge = variation?.minChildPriceAge ?? 0
  const maxChildAge = variation?.maxChildPriceAge ?? 17
  const valuedAt = purchasableOption.valuedAt
  const pricePerAdult = purchasableOption.price
  const memberPricePerAdult = purchasableOption.memberPrice
  const pricePerChild = roomTypePrice?.childPrice ?? 0
  const maxChildDiscount = roomTypePrice?.maxChildDiscounts ?? 0
  let appliedChildDiscounts = 0
  const adultsPriceBreakdown: Array<number> = []
  const adultsMemberPriceBreakdown: Array<number> = []
  const childrenPriceBreakdown: Array<number> = []

  // adults pricing
  for (let count = 0; count < occupants.adults; count++) {
    adultsPriceBreakdown.push(pricePerAdult)
    adultsMemberPriceBreakdown.push(Number(memberPricePerAdult))
    totalPrice += pricePerAdult
    totalMemberPrice += Number(memberPricePerAdult)
    if (valuedAt) totalValuedAtPrice += valuedAt
  }

  // children pricing
  if (occupants.children && occupants.childrenAge) {
    for (let count = 0; count < occupants.children; count++) {
      const childAge = occupants.childrenAge[count]
      if (
        pricePerChild &&
        appliedChildDiscounts < maxChildDiscount &&
        childAge >= minChildAge &&
        childAge <= maxChildAge
      ) {
        childrenPriceBreakdown.push(pricePerChild)
        appliedChildDiscounts++
        totalPrice += pricePerChild
      } else { // use adult price if no discount left
        childrenPriceBreakdown.push(pricePerAdult)
        totalPrice += pricePerAdult
      }
      if (valuedAt) totalValuedAtPrice += valuedAt
    }
  }

  return {
    accommodableRoomType: purchasableOption.roomType,
    adultsPriceBreakdown,
    adultsMemberPriceBreakdown,
    appliedChildDiscounts,
    childrenPriceBreakdown,
    maxChildDiscount,
    pricePerAdult,
    memberPricePerAdult: Number(memberPricePerAdult),
    pricePerChild,
    totalPrice,
    totalMemberPrice,
    totalValuedAtPrice: valuedAt ? totalValuedAtPrice : undefined,
  }
}

export default generateDepartureRoomPriceBreakdown

import React, { ComponentProps, MouseEventHandler, useCallback, useContext } from 'react'
import Caption from '../../Typography/Caption'
import FormatCurrency from 'components/Common/FormatCurrency'
import Clickable from 'components/Common/Clickable'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import styled from 'styled-components'
import { rem } from 'polished'
import Pill from 'components/Luxkit/Pill'
import ModalContext from 'contexts/ModalContext'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import GeoContext from 'contexts/geoContext'

const STRIKETHROUGH_EXPLANATION_TEXT = 'The discount is calculated by comparing the total value of this offer and the retail price of all inclusions in each package to the offer\'s standard rate for the package. The strikethrough price is illustrative of the maximum discount. See Offer Details for full information.'

interface Props {
  originalValue: number
  /**
   * Controls whether the messaging to state the saving is an estimate or exact value.
   * @default estimate
   */
  discountType?: 'exact' | 'estimate'
  /**
   * @example 10 => -10%
   */
  discountPercentage?: number
  size: 'M' | 'L'
  'data-testid'?: string
  /**
   * Won't bubble/propagate.
   */
  onInfoIconClick?: MouseEventHandler<HTMLButtonElement>
}

const InlineClickable = styled(Clickable)`
  display: inline-flex;
`

const CaptionBlock = styled(Caption)`
  display: flex;
  flex-direction: row;
  gap: ${rem(4)};
  align-items: center;
`

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

function PriceRowValueDiscountWithCaption(props: Props) {
  const {
    originalValue,
    discountType = 'estimate',
    discountPercentage,
    size,
    'data-testid': dataTestId,
    onInfoIconClick,
  } = props

  const geo = useContext(GeoContext)
  const showStrikethroughPricing = geo.isStrikethroughPricingRegion
  const showModal = useContext(ModalContext)

  const handleInfoIconClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()
    if (showStrikethroughPricing && !onInfoIconClick) {
      showModal(<Modal title="Discount calculation">
        <BodyText variant="medium">
          {STRIKETHROUGH_EXPLANATION_TEXT}
        </BodyText>
      </Modal>)
    } else {
      onInfoIconClick?.(event)
    }
  }, [showStrikethroughPricing, onInfoIconClick, showModal])

  return <CaptionBlock
    data-testid={dataTestId}
    className={PriceRowValueDiscountWithCaption.name}
    variant={SIZES_CAPTION_VARIANTS[size]}
    colour="neutral-one"
    weight="bold"
    wrap="no-wrap"
  >

    {showStrikethroughPricing && <><s><FormatCurrency value={originalValue} format="roundedDollar" /></s>
      {!!discountPercentage && <Pill kind="primary" variant="success">
        -{discountPercentage}%
      </Pill>}
    </>
    }

    {!showStrikethroughPricing && <>
      {discountType === 'estimate' && <span>Valued up to <FormatCurrency value={originalValue} format="roundedDollar" /></span>}
      {discountType === 'exact' && <span>Valued at <FormatCurrency value={originalValue} format="dynamicDollar" /></span>}
      {!!discountPercentage && <Pill kind="primary" variant="success">
        -{discountPercentage}%
      </Pill>}
    </>
    }

    {(onInfoIconClick || showStrikethroughPricing) && <InlineClickable onClick={handleInfoIconClick}>
      <SolidInfoCircleIcon />
    </InlineClickable>}
  </CaptionBlock>
}

export default PriceRowValueDiscountWithCaption

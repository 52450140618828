import AspectRatio from 'components/utils/AspectRatio'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Tile = styled(Pane)`
  display: grid;
  grid-template-columns: ${rem(220)} 1fr;

  ${mediaQueryUp.desktop} {
    grid-template-columns: ${rem(500)} 1fr;
  }
`

const BottomContent = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
  align-items: flex-end;

  ${mediaQueryUp.tablet} {
    flex-direction: column;
    gap: ${rem(36)};
    align-items: inherit;
  }
`

const Content = styled.div`
  padding: ${rem(16)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${rem(24)};


  ${mediaQueryUp.tablet} {
    display: grid;
    max-height: ${rem(262)};
    padding: ${rem(20)} ${rem(24)} ${rem(20)} ${rem(28)};
    gap: ${rem(24)};
    grid-template-columns: 1fr ${rem(172)};
  }
`

const ImageBox = styled(AspectRatio)`
  position: relative;
`

interface Props {
  className?: string;
}

function CruiseOfferTileLoadingSkeleton(props: Props) {
  const { className } = props

  return (<Tile type="clean" className={className}>
    <ImageBox ratio="16:9">
      <LoadingBox floating />
    </ImageBox>
    <Content>
      <VerticalSpacer gap={8}>
        <VerticalSpacer gap={4}>
          <TextLoadingBox typography="body-medium" width={180} />
          <TextLoadingBox typography="heading4" width={240} />
          <TextLoadingBox typography="body-small" width={140} />
          <TextLoadingBox typography="body-small" width={140} />
          <TextLoadingBox typography="body-medium" width={180} />
        </VerticalSpacer>
        <TextLoadingBox typography="body-small" lines={4} width="100%" />
        <TextLoadingBox typography="body-medium" width={180} />
      </VerticalSpacer>
      <BottomContent>
        <LoadingBox style={{ height: rem(30), borderRadius: 4, width: '100%' }} />
        <div>
          <TextLoadingBox typography="caption-small" width={100} />
          <TextLoadingBox typography="heading6" width="100%" />
          <TextLoadingBox typography="caption-small" width={100} />
          <LoadingBox style={{ height: rem(36), borderRadius: 4, width: '100%' }} />
        </div>
      </BottomContent>
    </Content>
  </Tile>)
}

export default React.memo(CruiseOfferTileLoadingSkeleton)

import BodyText from 'components/Luxkit/Typography/BodyText'
import getOfferFeatureIcon from 'lib/offer/getOfferFeatureIcon'
import { rem } from 'polished'
import React, { ComponentProps, useMemo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template: "icon description" auto / auto minmax(0px, 1fr);
  gap: ${rem(4)};
`

type InheritedBodyTextProps = Pick<ComponentProps<typeof BodyText>, 'lineClamp'>

interface Props extends InheritedBodyTextProps {
  inclusion: App.OfferInclusion
  /**
   * @default `inclusion.isHighlighted``
   */
  isHighlighted?: boolean
}

function SearchTileRowInclusionItem({
  inclusion,
  lineClamp,
  isHighlighted,
}: Props) {
  const Icon = useMemo(() => getOfferFeatureIcon(inclusion.symbol), [inclusion])
  const highlighted = isHighlighted === undefined ? inclusion.isHighlighted : isHighlighted

  return <Container>
    <Icon
      size="S"
      colour={highlighted ? 'secondary-normal' : undefined}
    />
    <BodyText
      variant="medium"
      weight={highlighted ? 'bold' : undefined}
      lineClamp={lineClamp}
    >
      {inclusion.description}
    </BodyText>
  </Container>
}

export default SearchTileRowInclusionItem

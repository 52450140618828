import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCommentsIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20.61 19.1901a7.0009 7.0009 0 0 0 1.2585-2.8386 7.0016 7.0016 0 0 0-1.5195-5.8617 7.0005 7.0005 0 0 0-2.479-1.8697 7.9994 7.9994 0 0 0-4.1117-5.686 7.9996 7.9996 0 0 0-9.8911 1.9048A7.9999 7.9999 0 0 0 3.68 14.9101l-1.39 1.38a.9996.9996 0 0 0-.21 1.09.9997.9997 0 0 0 .92.62h5.69a6.9998 6.9998 0 0 0 6.31 4h6a1 1 0 0 0 .92-.62 1 1 0 0 0-.21-1.09l-1.1-1.1ZM8 15.0001c.0014.3348.0281.6691.08 1H5.41l.35-.34a1.0003 1.0003 0 0 0 0-1.42 5.9305 5.9305 0 0 1-1.76-4.24 6 6 0 0 1 6-6 5.9398 5.9398 0 0 1 5.65 4H15a7.0002 7.0002 0 0 0-7 7Zm10.54 5 .05.05H15a4.9999 4.9999 0 0 1-1.8975-9.6223 4.9998 4.9998 0 0 1 6.0676 1.8502 4.9997 4.9997 0 0 1-.6301 6.3121.9997.9997 0 0 0-.3.7.9988.9988 0 0 0 .3.71Z"/>
  </SvgIcon>
}

export default LineCommentsIcon

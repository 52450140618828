import React, { PropsWithChildren } from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import styled from 'styled-components'
import { PILL_HEIGHT } from './Pill'
import { rem } from 'polished'

const PillBox = styled(LoadingBox)`
  height: ${PILL_HEIGHT};
  border-radius: ${props => props.theme.borderRadius.round};
`

interface Props {
  /**
   * in pixels
   */
  width: number
}

function PillLoadingBox(props: PropsWithChildren<Props>) {
  const { width } = props

  return <PillBox style={{ width: rem(width) }} />
}

export default PillLoadingBox

import AspectRatio from 'components/utils/AspectRatio'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Group from 'components/utils/Group'

const Content = styled(Pane)`
  padding-top: ${rem(16)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${rem(24)};

  ${mediaQueryUp.desktop} {
    height: 100%;
    padding: ${rem(24)} ${rem(24)} ${rem(16)};
  }
`

const Root = styled.div`
  display: grid;
  position: relative;
  height: 100%;
  grid-template-rows: min-content 1fr;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 67% 1fr;
    grid-template-rows: 1fr;
    min-height: ${rem(364)};
  }
`

const MainImageSegment = styled.div`
  display: grid;
  position: relative;
`

interface Props {
  className?: string;
}

function HeroOfferLoadingTile(props: Props) {
  const { className } = props
  return (
    <ProductPaletteProvider palette="default">
      <Root className={className}>
        <MainImageSegment>
          <AspectRatio ratio="3:2" tabletRatio="16:9" desktopRatio="19:9">
            <LoadingBox floating />
          </AspectRatio>
        </MainImageSegment>
        <CSSBreakpoint max="tablet">
          <Content paletteType="inverse">
            <VerticalSpacer gap={8}>
              <TextLoadingBox typography="caption-small" width={180} />
              <TextLoadingBox typography="body-medium" width={140} />
              <TextLoadingBox typography="heading5" width={240} />
              <TextLoadingBox typography="body-medium" width="100%" />
              <TextLoadingBox typography="body-large" width={140} />
            </VerticalSpacer>
            <Group direction="horizontal" horizontalAlign="space-between">
              <div>
                <TextLoadingBox typography="caption-small" width={140} />
                <TextLoadingBox typography="heading3" width={100} />
                <TextLoadingBox typography="caption-small" width={140} />
              </div>
              <LoadingBox style={{ height: rem(36), borderRadius: 4, width: rem(90), alignSelf: 'flex-end' }} />
            </Group>
            <TextLoadingBox typography="body-large" width={180} />
          </Content>
        </CSSBreakpoint>
        <CSSBreakpoint min="desktop">
          <Content paletteType="default">
            <VerticalSpacer gap={8}>
              <Group direction="horizontal" horizontalAlign="space-between">
                <LoadingBox style={{ height: rem(32), width: rem(100) }} />
                <LoadingBox style={{ height: rem(32), width: rem(50) }} />
              </Group>
              <TextLoadingBox typography="caption-small" width={180} />
              <TextLoadingBox typography="body-medium" width={140} />
              <TextLoadingBox typography="heading5" width={240} />
              <TextLoadingBox typography="body-medium" width="100%" />
              <TextLoadingBox typography="body-large" width={140} />
            </VerticalSpacer>
            <VerticalSpacer gap={8}>
              <Group direction="horizontal" horizontalAlign="space-between">
                <div>
                  <TextLoadingBox typography="caption-small" width={140} />
                  <TextLoadingBox typography="heading3" width={100} />
                  <TextLoadingBox typography="caption-small" width={140} />
                </div>
                <LoadingBox style={{ height: rem(36), borderRadius: 4, width: rem(90), alignSelf: 'flex-end' }} />
              </Group>
              <TextLoadingBox typography="body-large" width={180} />
            </VerticalSpacer>
          </Content>
        </CSSBreakpoint>
      </Root>
    </ProductPaletteProvider>
  )
}

export default React.memo(HeroOfferLoadingTile)

import { formatCurrency } from 'lib/format/formatCurrencyIntl'

export function getAvailableLuxPlusInclusionsForMembers(
  luxPlusInclusionsByTier: App.LuxPlusInclusionsByTier | undefined,
  inclusionsViewableLuxPlusTier: string,
  isMemberOrHasSubscriptionInTheCart: boolean,
): Array<App.PackageInclusion> {
  if (!isMemberOrHasSubscriptionInTheCart || !luxPlusInclusionsByTier) {
    return []
  }

  return luxPlusInclusionsByTier[inclusionsViewableLuxPlusTier] || []
}

export function buildEvergreenPackageInclusions(
  evergreenInclusions: Array<App.EvergreenInclusion>,
): Array<App.PackageInclusion> {
  return evergreenInclusions.map((inclusion) => ({
    id: inclusion.id,
    description: inclusion.description,
    symbol: 'key',
    isHighlighted: true,
  }))
}

export function buildLegacyPackageInclusions(
  promotionDetails: App.CruisePromotionDetails | null,
  regionCode: string,
): Array<App.PackageInclusion> {
  const inclusions: Array<App.PackageInclusion> = []

  if (promotionDetails?.onBoardCredit) {
    const value = formatCurrency(promotionDetails.onBoardCredit.amount, promotionDetails.onBoardCredit.currencyCode, regionCode)
    inclusions.push({
      id: 'onBoardCredit',
      description: `Up to ${value} onboard credit available`,
    })
  }

  if (promotionDetails?.sellingPoints) {
    promotionDetails.sellingPoints.forEach((point) => {
      inclusions.push({ id: point, description: point })
    })
  }

  return inclusions
}

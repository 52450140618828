import React, { useRef, useState } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import HiddenInput from '../Common/Form/Input/HiddenInput'
import InputText from './Typography/InputText'
import LineMinusIcon from './Icons/line/LineMinusIcon'
import LinePlusIcon from './Icons/line/LinePlusIcon'
import { rem } from 'polished'
import noop from 'lib/function/noop'
import IconButton from 'components/Luxkit/Button/IconButton'

const CounterContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${rem(6)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${props => props.theme.borderRadius.S};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  position: relative;
  transition: border 0.2s;

  &.invalid {
    border-color: ${props => props.theme.palette.messaging.critical.normalForeground};
  }

  > * + * {
    &:not(input) {
      margin-left: ${rem(8)};
    }
  }
`

const CounterText = styled(InputText)`
  min-width: ${rem(24)};
  transition: color 0.2s;

  &.disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }
`

interface Props {
  defaultValue?: number;
  value?: number;
  min?: number;
  max?: number;
  name?: string;
  onChange?: (value: number, inputElement: HTMLInputElement | null) => void;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
  invalidMessage?: string;
}

function Counter(props: Props) {
  const {
    className,
    name,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    defaultValue,
    value,
    onChange = noop,
    disabled,
    'data-testid': testId,
    invalidMessage,
    ...inputProps
  } = props
  const [isDirty, setDirty] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [internalValue, setValue] = useState<number>(defaultValue ?? 0)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const isControlled = value !== undefined
  const onDecrement = () => {
    if (isControlled) {
      onChange(value - 1, inputRef.current)
      return
    }

    setValue(prevValue => {
      const nextValue = prevValue - 1

      if (nextValue <= max) {
        onChange(nextValue, inputRef.current)
        return nextValue
      }

      return prevValue
    })
  }

  const onIncrement = () => {
    if (isControlled) {
      onChange(value + 1, inputRef.current)
      return
    }

    setValue(prevValue => {
      const nextValue = prevValue + 1

      if (nextValue <= max) {
        onChange(nextValue, inputRef.current)
        return nextValue
      }
      return prevValue
    })
  }

  const onErrorUpdate = (message?: string) => {
    setError(!!message)
  }

  const actualValue = isControlled ? value : internalValue

  return (<CounterContainer
    className={cn(className, { invalid: (isDirty && error) })}
    data-testid={testId}
  >
    <IconButton
      kind="tertiary"
      size="small"
      disabled={disabled || actualValue <= min}
      variant="dark"
      onClick={onDecrement}
      data-testid={`${testId}-decrement`}
    >
      <LineMinusIcon />
    </IconButton>
    <CounterText align="center" variant="text-regular" className={cn({ disabled })}>{actualValue}</CounterText>
    <IconButton
      kind="tertiary"
      size="small"
      disabled={disabled || actualValue >= max}
      variant="dark"
      onClick={onIncrement}
      data-testid={`${testId}-increment`}
    >
      <LinePlusIcon />
    </IconButton>
    <HiddenInput
      {...inputProps}
      type="number"
      ref={inputRef}
      min={min}
      max={max}
      value={actualValue}
      onDirtyChange={setDirty}
      onErrorUpdate={onErrorUpdate}
      name={`${name}::number`}
      invalidMessage={invalidMessage}

    />
  </CounterContainer>)
}

export default React.memo(Counter)

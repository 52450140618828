import { ValueOf } from 'type-fest'
import { createContext } from 'react'
import noop from 'lib/function/noop'

export enum SearchTileEvents {
  clickAlternativeDateViewOffer,
  viewAlternativeDatesBanner,
}

export type SearchTileEventsDispatchAction = ValueOf<Utils.FullActionMap<{
  [SearchTileEvents.clickAlternativeDateViewOffer]: {}
  [SearchTileEvents.viewAlternativeDatesBanner]: {}
}>>

export type SearchTileEventsHandler = (
  dispatchAction: SearchTileEventsDispatchAction,
) => void

const SearchTileEventsContext = createContext<SearchTileEventsHandler>(noop)

export default SearchTileEventsContext

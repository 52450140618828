import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import React, { useCallback, useEffect, useState } from 'react'
import GlobalSearchV2Content from './GlobalSearchV2Content'

function GlobalSearchV2() {
  // Rendering all the tabs can be quite expensive on first load and the user may not ever interact with us
  // so track our first open and hide it until we've been opened at least once
  const { openedSearchVertical, toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const [hasSearchOpened, setSearchHasOpened] = useState<boolean>(!!openedSearchVertical)

  const closeSearch = useCallback(() => { toggleSearchVertical('none') }, [toggleSearchVertical])

  useEffect(() => {
    if (openedSearchVertical) {
      setSearchHasOpened(true)
    }
  }, [openedSearchVertical])

  if (!hasSearchOpened) {
    return null
  }

  return <GlobalSearchV2Content
    openedSearchVertical={openedSearchVertical}
    onClose={closeSearch}
  />
}

export default GlobalSearchV2

// Based on the following docs from Google
// https://developers.google.com/tag-manager/enhanced-ecommerce
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce

// Note some examples assume the app is server rendered.

export const pushResetEcommerce = () => {
  if (typeof dataLayer === 'undefined') {
    return
  }
  dataLayer.push({ ecommerce: null })
}

type ListPrefix = 'LERE' | 'OfferList' | 'TripPlanner' | 'CruisePromo' | 'CarHire'

export function getListName(prefix: ListPrefix, pageSource: string, uiTouchpiont: string) {
  return [
    prefix,
    pageSource,
    uiTouchpiont,
  ].join(' - ')
}

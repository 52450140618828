export const all = ['trips'] as const
export const lists = [...all, 'list', true] as const
export const listsLoggedOut = [...all, 'list', false] as const
export const details = [...all, 'details'] as const
export const detail = (id: string) => [...details, id] as const
export const collaborator = (id: string) =>
  [...details, 'collaborators', id] as const
export const flightSchedule = (flightCode: string, isoDate: string) =>
  ['flight', flightCode, isoDate] as const
export const summary = (id: string) => [...all, 'summary', id] as const
export const lastViewedTime = [...all, 'lastViewedTime'] as const
export const user = (email: string) => [...all, 'user', email] as const
export const publicId = (tripId: string) => [...all, 'public', tripId] as const
export const lowStockItems = (tripId: string) =>
  [...all, tripId, 'lowStockItems'] as const
export const tripItemsList = [...all, 'tripItemsList', true] as const
export const tripItemsListLoggedOut = [...all, 'tripItemsList', false] as const

import request, { authOptions } from 'api/requestUtils'
import { actions, definitions } from '@luxuryescapes/contract-svc-agent'
import qs from 'qs'
import { agentHubCommissionMap } from './mappers/agentHubMap'

type GetAgentHubCommissionPromoCodeBody = definitions['calculateCartCommissionBody']['cart']
type GetAgentHubCommissionPromoResponse = actions['calculateCartCommission']['responses']['200']['content']['application/json']['result']

export async function getAgentHubCommissionPromoCode(regionCode: string, cart: GetAgentHubCommissionPromoCodeBody) {
  const query = qs.stringify({
    region: regionCode,
  })

  const response = await request.post<App.ApiResponse<GetAgentHubCommissionPromoResponse>, {}>(
    `/api/agent/calculate-cart-commission?${query}`,
    { cart },
    authOptions(),
  )

  return agentHubCommissionMap(response.result)
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSightseeingIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22.0772 11.4068c-.0198-.0495-.0396-.1088-.0693-.1583l-.0593-.0791c-.0396-.0594-.0792-.1089-.1188-.1584l-2.6714-3.73c-.6332-.9697-1.682-1.5435-2.8297-1.5435-1.6226 0-2.9781 1.1279-3.3047 2.6615-.0494 0-.089-.01-.1385 0-.3067.0594-.6035.0791-.93.0791-.2771 0-.5541-.0296-.8212-.0791h-.1583C10.7385 6.7467 9.3434 5.5 7.6416 5.5c-1.1477 0-2.2064.5739-2.8297 1.5336l-2.6714 3.7301c-.0396.0495-.0792.1088-.1188.1583l-.0593.0792s-.0495.1088-.0693.1583c-.3958.6925-.6431 1.4841-.6431 2.335 0 2.6021 2.1174 4.7096 4.7096 4.7096 1.3753 0 2.5923-.5937 3.463-1.5336.8014-.2474 1.6226-.376 2.4735-.3661.841 0 1.682.1484 2.4736.4057h.0494c.8608 1.0488 2.1569 1.7216 3.6114 1.7216 2.6022 0 4.7195-2.1174 4.7195-4.7097 0-.8509-.2474-1.6424-.6431-2.335l-.0297.0198Zm-5.7584-3.8983c.564 0 1.0785.287 1.3753.7619l.0198.0198s0 .0197.0099.0296l.5343.7421c-.0792 0-.1584-.0198-.2474-.0198-1.2467 0-2.3746.4947-3.2156 1.2862l-.089-1.2862c.0494-.8509.7519-1.5138 1.6127-1.5138v-.0198Zm-4.393 2.523h.0693c.3265 0 .6629-.0297.9894-.0791l.1583 2.2657h-2.3746l.1583-2.2756c.3265.0593.653.089.9894.0989l.0099-.0099ZM7.6218 7.281c.8608 0 1.5633.6629 1.6128 1.5138l-.089 1.2862c-.841-.7915-1.969-1.2862-3.2157-1.2862-.0791 0-.1583.0197-.2374.0197l.5343-.742s0-.0198.0099-.0297l.0197-.0198a1.6143 1.6143 0 0 1 1.3753-.7618l-.0099.0198ZM5.93 16.4627c-1.6226 0-2.9484-1.3159-2.9484-2.9484 0-.5838.178-1.1181.465-1.5732l.1385-.188c.5343-.7124 1.3852-1.1873 2.345-1.1873 1.6226 0 2.9484 1.3258 2.9484 2.9485 0 1.6226-1.3258 2.9484-2.9485 2.9484Zm4.6503-1.5929.0791-1.0983h2.5923l.0792 1.1279a8.9292 8.9292 0 0 0-1.4545-.1187c-.4353 0-.8608.0396-1.2961.0891Zm7.4206 1.8304c-1.6226 0-2.9485-1.3159-2.9485-2.9485 0-1.6325 1.316-2.9484 2.9485-2.9484.9597 0 1.8106.465 2.3449 1.1774l.1385.188c.287.4551.4651.9894.4651 1.5731 0 1.6227-1.3259 2.9485-2.9485 2.9485v.0099Z"/>
  </SvgIcon>
}

export default LineSightseeingIcon

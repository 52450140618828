import { computeAccommodationOfferTotalsView } from 'checkout/lib/utils/accommodation/priceView'
import { addTotals } from 'checkout/lib/utils/payments/totals'
import { createSelector } from 'reselect'
import { sum } from 'lib/array/arrayUtils'
import { checkoutAccommodationOfferView } from '../view/accommodation'
import { checkoutWithMemberPrice, isMemberOrHasSubscriptionInTheCart } from '../view/luxPlusSubscription'
import { floatify } from 'lib/maths/mathUtils'
import { isPostPurchaseTourChangeDates } from 'checkout/selectors/tourV2Selectors'

export const getAccommodationTotals = createSelector(
  checkoutAccommodationOfferView,
  checkoutWithMemberPrice,
  isMemberOrHasSubscriptionInTheCart,
  isPostPurchaseTourChangeDates,
  (offerViewsWithStatus, checkoutWithMemberPrice, isMemberOrHasSubscriptionInTheCart, isPostPurchaseTourDateChange): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const result = offerViewsWithStatus.data.map(view => computeAccommodationOfferTotalsView(view, { checkoutWithMemberPrice, isMemberOrHasSubscriptionInTheCart, isPostPurchaseTourDateChange }))
    return {
      hasRequiredData: offerViewsWithStatus.hasRequiredData,
      data: addTotals(result).data,
    }
  },
)

export const getAccommodationTotal = createSelector(
  getAccommodationTotals,
  (totals): number => {
    const { price = 0, surcharge = 0, otherFees = {} } = totals.data
    return price + surcharge + sum(Object.values(otherFees))
  },
)

export const getDueAtProperty = createSelector(
  getAccommodationTotals,
  (totals): number => totals.data.otherFees?.propertyFees || 0,
)

export const getAccommodationPayable = createSelector(
  getAccommodationTotals,
  getDueAtProperty,
  (accommodationTotal, dueAtProperty): number => {
    const { price = 0, surcharge = 0, otherFees = {} } = accommodationTotal.data
    return floatify(price + surcharge + sum(Object.values(otherFees)) - dueAtProperty)
  },
)

export const selectEarnableAccommodationBusinessTravellerCreditsTotal = createSelector(
  checkoutAccommodationOfferView,
  (
    offerViewsWithStatus,
  ): App.WithDataStatus<App.Checkout.ItemTotals> => {
    let price = 0
    for (const offerView of offerViewsWithStatus.data) {
      for (const itemView of offerView.itemViews) {
        price += itemView.businessTravellerCredits ?? 0
      }
    }

    return {
      hasRequiredData: offerViewsWithStatus.hasRequiredData,
      data: {
        price,
        memberPrice: 0,
        taxesAndFees: 0,
      },
    }
  },
)

import { createSelector } from 'reselect'
import { sum, uniqueBy } from 'lib/array/arrayUtils'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import { getFlightTotals } from 'checkout/selectors/payment/flights'
import luxPlusInclusionFilter from 'lib/offer/luxPlusInclusionFilter'
import { isSpoofed } from 'selectors/featuresSelectors'
import { getIsLuxPlusLPPEnabled } from '../featureToggle'

export const getAvailableAccommodationBenefits = createSelector(
  checkoutAccommodationOfferView,
  (state: App.State) => state.luxPlus.products.subscription,
  getFlightTotals,
  isSpoofed,
  getIsLuxPlusLPPEnabled,
  (accommodationOfferView, productSubscription, flightTotal, isSpoofed, luxPlusLPPEnabled): App.Checkout.LuxPlusAccommodationBenefits => {
    let totalPrice = 0
    let totalMemberPrice = 0
    let hasSavingsGreaterThanSubscriptionFee = false

    const hotelViews = accommodationOfferView.data.flatMap<App.Checkout.LEAccommodationItemView>(view => {
      return view.itemViews.filter(itemView => itemView.kind === 'le')
    })

    const bedbankViews = accommodationOfferView.data.flatMap<App.Checkout.BedbankAccommodationItemView>(view => {
      return view.itemViews.filter(itemView => itemView.kind === 'bedbank')
    })

    const toursV2Views = accommodationOfferView.data.flatMap<App.Checkout.TourV2AccommodationItemView>(view => {
      return view.itemViews.filter(itemView => itemView.kind === 'tourV2')
    })

    const isBundle = accommodationOfferView.data.some(view => view.isBundled)
    const flightsPrice = flightTotal.data.price
    const isTourV2 = toursV2Views.length > 0

    if (hotelViews.length > 0) {
      totalPrice = sum(hotelViews, view => view.price + (view.surcharge ?? 0) + (view.otherFees?.propertyFees ?? 0))
      totalMemberPrice = sum(hotelViews, view => view.memberPrice > 0 ? view.memberPrice + (view.surcharge ?? 0) + (view.otherFees?.propertyFees ?? 0) : 0)
    }

    if (bedbankViews.length > 0 && luxPlusLPPEnabled) {
      totalPrice = sum(bedbankViews, view => view.price + (view.surcharge ?? 0) + (view.otherFees?.propertyFees ?? 0))
      totalMemberPrice = sum(bedbankViews, view => view.memberPrice > 0 ? view.memberPrice + (view.surcharge ?? 0) + (view.otherFees?.propertyFees ?? 0) : 0)
    }

    if (isTourV2) {
      totalPrice = sum(toursV2Views, view => view.price * (view.occupancy?.adults ?? 1))
      totalMemberPrice = sum(toursV2Views, view => Number(view.memberPrice) * (view.occupancy?.adults ?? 1))
    }

    if (isBundle) {
      totalPrice = totalPrice + flightsPrice
      totalMemberPrice = totalMemberPrice > 0 ? totalMemberPrice + flightsPrice : 0
    }

    const hasEarlyAccess = accommodationOfferView.data.some(view => view.offer?.luxPlus?.access === 'earlyAccess')
    const isMemberOnly = accommodationOfferView.data.some(view => view.offer?.luxPlus?.access === 'memberOnly')
    const membershipRequired = hasEarlyAccess || isMemberOnly
    const offersWithJoinFeeWaived = productSubscription?.offers?.filter(offer => offer.joinFeeWaived)
    const defaultOffer = offersWithJoinFeeWaived?.[0]

    if ((totalMemberPrice > 0) && ((totalPrice - totalMemberPrice) > (defaultOffer?.price ?? 0))) {
      hasSavingsGreaterThanSubscriptionFee = true
    }

    let hasLuxPlusInclusions = false
    const subscriptionViews = offersWithJoinFeeWaived?.map<App.Checkout.LuxPlusCheckoutBenefitsSubscriptionViewWithInclusions>(offer => {
      const inclusions = hotelViews.flatMap(view => {
        const luxPlusInclusions = view.pkg?.luxPlusInclusionsByTier?.[offer.tier] ?? []
        return luxPlusInclusionFilter(luxPlusInclusions, [...view.bonusInclusions, ...view.inclusions])
      })

      if (inclusions.length) {
        hasLuxPlusInclusions = true
      }

      return {
        inclusions: uniqueBy(inclusions, incl => incl.description),
        offer,
      }
    }) ?? []

    return {
      totalPrice,
      totalMemberPrice,
      hasEarlyAccess,
      isMemberOnly,
      membershipRequired: membershipRequired && !isSpoofed,
      subscriptionViews,
      inclusions: subscriptionViews.flatMap(view => view.inclusions) ?? [],
      isBundledWithFlights: isBundle,
      isTourWithMemberPrice: isTourV2 && totalMemberPrice > 0,
      hasSavingsGreaterThanSubscriptionFee,
      hasLuxPlusInclusions,
      totalMemberPriceSavings: totalMemberPrice > 0 ? totalPrice - totalMemberPrice : 0,
    }
  })

import { LOG_NETWORK_REQUEST, LOG_NETWORK_RESPONSE } from './actionConstants'
import { ValueOf } from 'type-fest'

type Actions = Utils.FullActionMap<{}>

export type SherLogActions = ValueOf<Actions>

export function logNetworkRequest(req: { requestName: string, requestBody: any }) {
  const { requestName, requestBody } = req

  return {
    type: LOG_NETWORK_REQUEST,
    request: { requestName, requestBody, requestTimestamp: new Date().toISOString() },
  }
}

export function logNetworkResponse(res: { requestName: string, responseBody: any, status: number }) {
  const { requestName, responseBody, status } = res

  return {
    type: LOG_NETWORK_RESPONSE,
    response: { requestName, responseBody, status, requestTimestamp: new Date().toISOString() },
  }
}

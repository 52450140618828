import React, { Component, PropsWithChildren } from 'react'
import styled from 'styled-components'
import classnames from 'clsx'

import { PaneSize, paneSpacing } from '../mixins'

interface RootProps extends React.HTMLAttributes<HTMLElement> {
  $sizes: Array<PaneSize>;
  $mobileSizes: Array<PaneSize>;
}

const Root = styled.header<RootProps>`
  ${paneSpacing}
`

interface Props {
  className?: string;
  mobileSize?: string;
  size?: string;
}

/** @deprecated */
class PaneHeader extends Component<PropsWithChildren<Props>> {
  render() {
    const {
      size,
      mobileSize,
      children,
      className,
      ...props
    } = this.props

    const sizes = size ? size.split(/\s+/) : []
    const mobileSizes = mobileSize ? mobileSize.split(/\s+/) : []

    return (
      <Root
        $sizes={sizes as Array<PaneSize>}
        $mobileSizes={mobileSizes as Array<PaneSize>}
        className={classnames('PaneHeader', className)}
        {...props}
      >
        {children}
      </Root>
    )
  }
}

export default PaneHeader

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidStarCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M2 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12Zm15.5111-2.0785c.2998 0 .5396.3598.4797.6595 0 .1199-.06.2399-.1799.2998l-2.4581 2.3982.5995 3.4173c.06.3597-.1798.6595-.4796.7195h-.1199c-.1199 0-.2398 0-.2998-.06l-3.0576-1.6187-3.0577 1.6187c-.2997.1799-.6595.06-.8393-.2398-.06-.1199-.06-.2398-.06-.3597l.5996-3.4174-2.4581-2.3981c-.2398-.2399-.2398-.5996 0-.8394.06-.1199.1798-.1799.3597-.1799L9.957 9.442l1.4988-3.1176c.1799-.2998.5396-.4196.8393-.2398.12.06.1799.12.2399.2398l1.5588 3.1176 3.4173.4796Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default SolidStarCircleIcon

import { DepositType } from 'constants/checkout'
import { sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { floatify } from 'lib/maths/mathUtils'

export function getDepositAmountPercentage(offer: App.Offer, defaultDepositAmountPercentage: number) {
  return offer?.depositThresholds?.percentage ?
    offer.depositThresholds.percentage :
    offer?.depositAmount ?? defaultDepositAmountPercentage
}

export function isDepositPercentageDefault(offer: App.Offer) {
  return offer?.depositThresholds?.percentage ?
    false : !offer?.depositAmount
}

export function getAccommodationDepositSettings(
  view: App.Checkout.AccommodationOfferView,
  defaultDepositAmountPercentage: number,
): App.Checkout.DepositSettings {
  const { depositType, depositAmount, serviceFee } = view

  if (depositType == DepositType.FLAT) {
    const numOfOccupancy = sumUpOccupancies(view.occupancy)
    return {
      depositType,
      flatAmount: depositAmount,
      numOfOccupancy,
      serviceFee,
    }
  }

  return {
    depositType: DepositType.PERCENTAGE,
    percentage: depositAmount || defaultDepositAmountPercentage,
    serviceFee,
  }
}

export function computeAccommodationDepositAmount(
  payableAmount: number,
  settings: App.Checkout.DepositSettings,
): number {
  const { depositType, percentage, flatAmount, numOfOccupancy } = settings
  const result = (depositType == DepositType.FLAT) ?
      (flatAmount ?? 0) * (numOfOccupancy ?? 0) :
    payableAmount * (percentage ?? 0) / 100
  return floatify(result)
}

import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getPlaceByLatLong } from 'api/search'
import { mapCityLevelPlace } from 'lib/search/mappingUtils'

async function getCarHireCheckoutItem(state: App.State, item: App.Checkout.CarHireItem) {
  const dropOff = item.offer.dropOff
  const pickUp = item.offer.pickUp
  const [destination, origin] = await Promise.all([
    mapCityLevelPlace((await getPlaceByLatLong(dropOff.latitude, dropOff.longitude))),
    mapCityLevelPlace((await getPlaceByLatLong(pickUp.latitude, pickUp.longitude))),
  ])

  const { categoryId, productId } = getCategoryAndProductId(item.offer.parentType)

  return comluxgroup.createItem_1_1_0({
    offerId: item.offer.id,
    categoryId,
    productId,
    travelStart: item.pickUpDate,
    travelEnd: item.dropOffDate,
    duration: item.offer.duration,
    price: item.totalPrice,
    offerLeadPrice: item.offer.pricePerDay,
    offerLeadUnit: 'day',
    offerLeadDuration: String(item.offer.duration),
    currency: state.geo.currentCurrency,
    destinationCity: destination?.city,
    destinationRegion: destination?.region,
    destinationCountry: destination?.country,
    originCity: origin?.city,
    originRegion: origin?.region,
    originCountry: origin?.country,
    itemId: item.itemId,
  })
}

export default getCarHireCheckoutItem

// eslint-disable-next-line no-restricted-imports
import { themeLayoutCSSVariables } from 'constants/themes/themeLayout'
import { sum } from 'lib/array/arrayUtils'
import React, { createContext, PropsWithChildren } from 'react'

export type ElementPosition = 'static' | 'sticky' | 'fixed' | 'hidden'

type LayoutSection = 'header' | 'footer'

interface ObservedRects {
  relative: Map<string, DOMRect>
  floating: Map<string, DOMRect>
}

const observedLayoutRects: Record<LayoutSection, ObservedRects> = {
  header: { relative: new Map(), floating: new Map() },
  footer: { relative: new Map(), floating: new Map() },
}

function syncOffsets(
  layoutSection: LayoutSection,
  elementKey: string,
  rect: DOMRect | undefined,
  position: ElementPosition,
): [relativeOffset: number, floatingOffset: number] {
  const observedRects = observedLayoutRects[layoutSection]

  observedRects.floating.delete(elementKey)
  observedRects.relative.delete(elementKey)

  if (rect && position !== 'hidden') {
    if (position === 'sticky') {
      observedRects.floating.set(elementKey, rect)
    } else {
      observedRects.relative.set(elementKey, rect)
    }
  }

  return [
    sum(Array.from(observedRects.relative.values()), r => r.height),
    sum(Array.from(observedRects.floating.values()), r => r.height),
  ]
}

function appLayoutHandler(
  layoutSection: 'header' | 'footer',
  elementKey: string,
  rect: DOMRect | undefined,
  /** @default static */
  position: ElementPosition = 'static',
): void {
  const [relativeOffset, floatingOffset] = syncOffsets(layoutSection, elementKey, rect, position)
  switch (layoutSection) {
    case 'header': {
      document.documentElement.style.setProperty(themeLayoutCSSVariables.headerFloatingOffset, `${floatingOffset}px`)
      document.documentElement.style.setProperty(themeLayoutCSSVariables.headerRelativeOffset, `${relativeOffset}px`)
      break
    }
    case 'footer': {
      document.documentElement.style.setProperty(themeLayoutCSSVariables.footerFloatingOffset, `${floatingOffset}px`)
      document.documentElement.style.setProperty(themeLayoutCSSVariables.footerRelativeOffset, `${relativeOffset}px`)
      break
    }
  }
}

const AppLayoutContext = createContext<typeof appLayoutHandler>(appLayoutHandler)

export function AppLayoutContextProvider(props: PropsWithChildren) {
  return <AppLayoutContext.Provider {...props} value={appLayoutHandler} />
}

export default AppLayoutContext

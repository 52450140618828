import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHomeAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m20 8-6-5.26a3 3 0 0 0-4 0L4 8a3 3 0 0 0-1 2.26V19a2.9998 2.9998 0 0 0 3 3h12a3 3 0 0 0 3-3v-8.75A3.0008 3.0008 0 0 0 20 8Zm-6 12h-4v-5a.9997.9997 0 0 1 1-1h2a1.0003 1.0003 0 0 1 1 1v5Zm5-1a1.0001 1.0001 0 0 1-1 1h-2v-5a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v5H6a1.0001 1.0001 0 0 1-1-1v-8.75a1 1 0 0 1 .34-.75l6-5.25a1 1 0 0 1 1.32 0l6 5.25a1.0006 1.0006 0 0 1 .34.75V19Z"/>
  </SvgIcon>
}

export default LineHomeAltIcon

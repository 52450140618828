import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineClockDollarIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10.9 4c-3.9212 0-7.1 3.1787-7.1 7.1 0 3.9212 3.1788 7.1 7.1 7.1a7.189 7.189 0 0 0 .8886-.055.9.9 0 0 1 .2228 1.7861A8.9693 8.9693 0 0 1 10.9 20C5.9847 20 2 16.0153 2 11.1c0-4.9154 3.9847-8.9 8.9-8.9 3.3521 0 6.27 1.8535 7.787 4.5873a.9.9 0 0 1-1.574.8733C15.9006 5.4756 13.572 4 10.9 4Z"/>
    <path fill="currentColor" d="M17.7143 10.0761a.9811.9811 0 0 0-.9809.981v.5904h-.0667a2.5524 2.5524 0 0 0 0 5.1048h2.0952a.5909.5909 0 0 1 .4176.1729.5909.5909 0 0 1 0 .8351.591.591 0 0 1-.4176.173h-2.619a.067.067 0 0 1-.0667-.0667.981.981 0 1 0-1.9619 0 2.0287 2.0287 0 0 0 2.0286 2.0286h.5905v.5904a.981.981 0 1 0 1.9619 0v-.5904h.0666a2.5524 2.5524 0 0 0 0-5.1048h-2.0952a.5901.5901 0 0 1-.4175-.173.5901.5901 0 0 1 0-.835.5906.5906 0 0 1 .4175-.173h2.619a.0668.0668 0 0 1 .0667.0667.981.981 0 0 0 1.9619 0 2.0286 2.0286 0 0 0-2.0286-2.0286h-.5904v-.5904a.9814.9814 0 0 0-.2873-.6937.9814.9814 0 0 0-.6937-.2873ZM11.1429 5.4857a1 1 0 0 0-1 1v4h-2a1 1 0 1 0 0 2h3a1.0001 1.0001 0 0 0 1-1v-5a1.0002 1.0002 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineClockDollarIcon

import { rem } from 'polished'
import cn from 'clsx'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import Clickable from '../../Common/Clickable'
import BodyText from '../Typography/BodyText'
import Caption from '../Typography/Caption'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import Group from 'components/utils/Group'

const Item = styled(Clickable)`
  position: relative;
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: ${props => props.theme.borderRadius.S};
  align-items: center;
  box-shadow: none;
  gap: ${rem(12)};
  color: ${props => props.theme.palette.neutral.default.one};
  padding-inline: ${rem(16)};

  &.size-small {
    padding-block: ${rem(4)};
  }

  &.size-medium {
    padding-block: ${rem(8)};
  }

  &.size-large {
    padding-block: ${rem(12)};
  }

  &.selected {
    background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:not(:disabled, .nonInteractable) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }

    &:focus {
      box-shadow: var(${KEYBOARD_MODE_CSS_VAR}, 0 0 0 2px ${props => props.theme.palette.neutral.default.five} inset);
    }
  }
`

const ItemContent = styled(Group)`
  overflow: hidden;
  flex-grow: 1;
`

const CenterContent = styled.div`
  width: 100%;
`

interface Props extends Omit<React.ComponentProps<typeof Clickable>, 'title' | 'children'> {
  /** An icon element that will go at the start (often the 'left' side) of the List item. */
  startIcon?: React.ReactNode
  /** An icon element that will go at the end (often the 'right' side) of the List item. */
  endIcon?: React.ReactNode
  /**
   * Sets the text above the title.
   */
  overline?: React.ReactNode
  /**
   * Allows applying format to the overline text.
   *
   * @default 'uppercase'
   */
  overlineFormat?: ComponentProps<typeof Caption>['format']
  /**
   * Sets the main label.
   *
   * This is the most prominent text.
   **/
  title: React.ReactNode
  /** Allows applying format to the title text. */
  titleFormat?: ComponentProps<typeof BodyText>['format']
  /**
   * @default 2
   */
  titleLineClamp?: ComponentProps<typeof BodyText>['lineClamp']
  titleColour?: ComponentProps<typeof BodyText>['colour']
  /**
   * Sets the text beneath the title.
   *
   * This is for less important text, and is visually less prominent.
   **/
  subtitle?: React.ReactNode
  /** Allows applying format to the subtitle text. */
  subtitleFormat?: ComponentProps<typeof Caption>['format']
  subtitleLineClamp?: ComponentProps<typeof Caption>['lineClamp']
  /** Whether the list item should be in a selected state or not */
  selected?: boolean
  className?: string
  /**
   * Determines the height/padding of the item, the text within it
   * as well as the size of icons if they are provided.
   **/
  size?: 'small' | 'medium' | 'large'
  /**
   * Children will be placed in the 'slot' specified in Figma.
   */
  children?: React.ReactNode
  /**
   * Disables the visual interactability feedbacks such as hover and focus.
   *
   * Turns the component into a `div`.
   */
  nonInteractable?: boolean
}

// Luxkit note:
// Have yet to implement the radio button and checkbox version of this control
function ListItem(props: Props) {
  const {
    startIcon,
    endIcon,
    overline,
    overlineFormat = 'uppercase',
    title,
    titleFormat,
    titleLineClamp = 2,
    titleColour,
    subtitle,
    subtitleFormat,
    subtitleLineClamp,
    className,
    selected,
    size = 'large',
    children,
    nonInteractable,
    disabled,
    ...buttonProps
  } = props

  const captionSize = size === 'large' ? 'large' : 'medium'

  return (
    <Item
      {...buttonProps}
      disabled={disabled}
      as={nonInteractable ? ('div' as any) : undefined}
      className={cn(className, `size-${size}`, { selected, nonInteractable })}
    >
      {startIcon}
      <ItemContent direction="horizontal" horizontalAlign="space-between" verticalAlign="center" gap={12}>
        <CenterContent>
          {overline && <Caption variant="small" weight="bold" format={overlineFormat}>
            {overline}
          </Caption>}
          <BodyText
            variant={size}
            lineClamp={titleLineClamp}
            format={titleFormat}
            colour={titleColour}
          >
            {title}
          </BodyText>
          {subtitle && <Caption
            variant={captionSize}
            format={subtitleFormat}
            lineClamp={subtitleLineClamp}
            colour={disabled ? 'neutral-three' : 'neutral-two'}
          >
            {subtitle}
          </Caption>}
        </CenterContent>
        {children}
      </ItemContent>
      {endIcon}
    </Item>
  )
}

export default ListItem

import React from 'react'
import { getSVGImageUrl } from 'lib/image/imageUtils'

interface Props {
  width?: number;
  height?: number;
}

function VelocityIcon({ width = 14, height = 14 }: Props) {
  return <img src={getSVGImageUrl('VFF_currency_symbol_RGB_red')} width={width} height={height} alt="velocity-new" />
}

export default VelocityIcon

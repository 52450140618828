import { createBrowserHistory } from 'history'
import { pathnameHasRegionCode } from 'lib/string/regionUtils'
import { addRegionToPath } from 'lib/url/searchUrlUtils'

function initialiseHistory(regionCode: string) {
  const history = createBrowserHistory()
  // Trust the SSR region code, if the region code isn't in the url - replace it in
  if (!pathnameHasRegionCode(history.location.pathname, regionCode)) {
    const nextLocation = { ...history.location }
    nextLocation.pathname = addRegionToPath(nextLocation.pathname, regionCode)
    history.replace(nextLocation)
  }

  return history
}

export default initialiseHistory

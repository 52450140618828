import { definitions, paths } from '@luxuryescapes/contract-svc-lux-loyalty'
import { arrayToObject, sortBy } from 'lib/array/arrayUtils'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_CRUISE, OFFER_TYPE_EXPERIENCE, OFFER_TYPE_HOTEL, PRODUCT_TYPE_FLIGHT, PRODUCT_TYPE_INSURANCE, PRODUCT_TYPE_LUX_PLUS } from 'constants/offer'

type ServerLuxLoyaltyAccount = definitions['accountSummary']
type ServerLuxLoyaltyProgramConfiguration = definitions['programConfiguration']
type ServerLuxLoyaltyPointsCalculation = paths['/api/lux-loyalty/points/calculate-earn']['post']['responses']['200']['content']['application/json']['result']
type CalculateLuxLoyaltyPointsPayload = paths['/api/lux-loyalty/points/calculate-earn']['post']['parameters']['body']['payload']
type ServerLoyaltyProductType = CalculateLuxLoyaltyPointsPayload['productDetails']['loyaltyProductType']

export function luxLoyaltyAccountMapper(response: ServerLuxLoyaltyAccount | null): App.LuxLoyaltyAccount | undefined {
  if (!response) return undefined

  return {
    points: response.points,
    tier: response.tier,
    statusCredits: response.statusCredits,
    ibsMemberId: response.ibsMemberId,
    statusReviewDate: response.statusReviewDate ?? undefined,
    totalCreditsForTierRetention: response.totalCreditsForTierRetention ?? undefined,
    totalCreditsForTierUpgrade: response.totalCreditsForTierUpgrade ?? undefined,
  }
}

export function luxLoyaltyProgramConfigMapper(response: ServerLuxLoyaltyProgramConfiguration): App.LuxLoyaltyProgramConfig {
  return {
    programName: response.programName,
    currencyName: response.currencyName,
    currencyNameAbbreviation: response.currencyNameAbbreviation,
    statusCreditsName: response.statusCreditsName,
    tierLabel: response.tierLabel,
    tiersConfigs: arrayToObject(response.tiers, tier => tier.id),
    tiersOrder: sortBy(response.tiers, (tier) => tier.sortingOrder, 'asc').map((tier) => tier.id),
  }
}

export function luxLoyaltyPointsCalculatorDataMapper(response: ServerLuxLoyaltyPointsCalculation): App.LuxLoyaltyPointsCalculatorData {
  return {
    points: response.totalBasePoints,
    statusCredits: response.totalStatusCredits,
  }
}

/**
 * TODO - this is a temp implementation until the product types have been finalised
 */
export function luxLoyaltyServerProductTypeMapper(
  productType: App.LuxLoyaltyPointsCalculatorOptions['productType'],
): ServerLoyaltyProductType | undefined {
  if (!productType) return

  const productTypeMap: Record<
    Exclude<App.LuxLoyaltyPointsCalculatorOptions['productType'], undefined>,
    ServerLoyaltyProductType
  > = {
    [OFFER_TYPE_HOTEL]: 'hotel_flash',
    [OFFER_TYPE_ALWAYS_ON]: 'hotel_lpc',
    [OFFER_TYPE_BED_BANK]: 'hotel_lpp_eps',
    [OFFER_TYPE_CRUISE]: 'cruise',
    [OFFER_TYPE_EXPERIENCE]: 'experience',
    [PRODUCT_TYPE_LUX_PLUS]: 'lux_plus',
    [PRODUCT_TYPE_FLIGHT]: 'flight',
    [PRODUCT_TYPE_INSURANCE]: 'insurance',
  }

  return productTypeMap[productType]
}

/**
 * TODO - this is a temp implementation until the product types have been finalised
 */
export function isLoyaltyProductTypeWithAdditionalCalculationOptions(productType: ServerLoyaltyProductType): productType is 'hotel_flash' | 'hotel_lpc' {
  return productType === 'hotel_flash' || productType === 'hotel_lpc'
}

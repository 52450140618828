import moment from 'moment'

export const scheduleStatuses = {
  PENDING: 'pending',
  CURRENT: 'current',
  EXPIRED: 'expired',
  INVALID: 'invalid',
}

interface Schedule extends App.OfferSchedule {
  checkIn?: string
}

function scheduleStatus(schedule: Schedule) {
  const now = moment(schedule.checkIn)
  const start = moment(schedule.start)
  const end = moment(schedule.end)

  if (now.isBefore(start)) {
    return scheduleStatuses.PENDING
  }
  else if (now.isAfter(end)) {
    return scheduleStatuses.EXPIRED
  }
  else {
    return scheduleStatuses.CURRENT
  }
}

export function scheduleIsCurrent(schedule?: App.OfferSchedule) {
  return !!schedule && scheduleStatus(schedule) === scheduleStatuses.CURRENT
}

export function hasSellableSchedule(schedules?: Array<App.ReservationSchedule>) {
  const schds = (schedules ?? []).filter(schedule => schedule.type === 'sell')
  if (schds.length > 0) {
    return schds.some(schedule => scheduleStatus({
      start: schedule.activePeriod.from!,
      end: schedule.activePeriod.to!,
    }) === scheduleStatuses.CURRENT)
  }
  return true // sellable if no sell schedules to restrict
}

export function hasTravellableSchedules(
  schedules: Array<App.ReservationSchedule> | undefined,
  checkIn: string,
  type?: 'sell' | 'tactical',
) {
  const schds = (schedules ?? []).filter(schedule => schedule.type === (type ?? 'sell'))
  if (schds.length > 0) {
    return schds.some(schedule => scheduleStatus({
      start: schedule.travelPeriod.from!,
      end: schedule.travelPeriod.to!,
      checkIn,
    }) === scheduleStatuses.CURRENT)
  }
  return true // travellable if no travel schedules to restrict
}

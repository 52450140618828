export const nullStartDates: {
  startDate: undefined,
  startTime: undefined,
  startUtcDateTime: undefined,
} = {
  startDate: undefined,
  startTime: undefined,
  startUtcDateTime: undefined,
}

export const nullEndDates: {
  endDate: undefined,
  endTime: undefined,
  endUtcDateTime: undefined,
} = {
  endDate: undefined,
  endTime: undefined,
  endUtcDateTime: undefined,
}

export const nullBookedDetails: {
  isBooked: false,
  confirmationItemCodes: undefined,
  confirmationCode: undefined,
  confirmationUrl: undefined,
} = {
  isBooked: false,
  confirmationItemCodes: undefined,
  confirmationCode: undefined,
  confirmationUrl: undefined,
}

import React, { SVGAttributes } from 'react'
import styled from 'styled-components'

const TextPath = styled.path`
  &.colour-default { fill: ${props => props.theme.palette.product.luxPlus.altForeground}; }
  &.colour-inherit { fill: currentColor }
`

const PlusPath = styled.path`
  fill: ${props => props.theme.palette.highlight.luxPlus.normalForeground};
`

interface Props extends SVGAttributes<SVGSVGElement> {
  type?: 'default' | 'plus-only'
  /**
   * @default default
   */
  colour?: 'default' | 'inherit'
}

function LuxPlusLogo({ type = 'default', colour = 'default', ...rest }: Props) {
  if (type === 'plus-only') {
    return <svg {...rest} viewBox="0 0 46 10">
      <TextPath
        className={`colour-${colour}`}
        d="M32.9271 10C32.1771 10 31.4502 9.89352 30.7465 9.68056C30.0521 9.46759 29.5012 9.18518 29.0938 8.83333L29.7188 7.43055C30.1169 7.74537 30.603 8.00463 31.1771 8.20833C31.7604 8.40278 32.3437 8.5 32.9271 8.5C33.6493 8.5 34.1863 8.38426 34.5382 8.15278C34.8993 7.9213 35.0799 7.61574 35.0799 7.23611C35.0799 6.95833 34.978 6.73148 34.7743 6.55555C34.5799 6.37037 34.3299 6.22685 34.0243 6.125C33.7187 6.02315 33.3021 5.90741 32.7743 5.77778C32.0336 5.60185 31.4317 5.42593 30.9687 5.25C30.515 5.07407 30.1215 4.80093 29.7882 4.43056C29.4641 4.05093 29.3021 3.54167 29.3021 2.90278C29.3021 2.36574 29.4456 1.87963 29.7326 1.44444C30.0289 1 30.4687 0.648148 31.0521 0.388889C31.6447 0.12963 32.3669 0 33.2187 0C33.8113 0 34.3947 0.0740742 34.9688 0.222223C35.5428 0.370371 36.0382 0.583334 36.4549 0.861112L35.8854 2.26389C35.4595 2.01389 35.015 1.82407 34.5521 1.69444C34.0891 1.56481 33.64 1.5 33.2049 1.5C32.4919 1.5 31.9595 1.62037 31.6076 1.86111C31.265 2.10185 31.0938 2.4213 31.0938 2.81944C31.0938 3.09722 31.191 3.32407 31.3854 3.5C31.5891 3.67593 31.8438 3.81481 32.1493 3.91667C32.4549 4.01852 32.8715 4.13426 33.3993 4.26389C34.1215 4.43055 34.7141 4.60648 35.1771 4.79167C35.64 4.96759 36.0336 5.24074 36.3576 5.61111C36.691 5.98148 36.8576 6.48148 36.8576 7.11111C36.8576 7.64815 36.7095 8.13426 36.4132 8.56944C36.1262 9.00463 35.6863 9.35185 35.0938 9.61111C34.5012 9.87037 33.7789 10 32.9271 10Z"
        clipRule="evenodd"
      />
      <TextPath
        className={`colour-${colour}`}
        d="M22.8567 10C21.5141 10 20.4678 9.625 19.7178 8.875C18.9678 8.11574 18.5928 7.0324 18.5928 5.625V0.138885H20.3983V5.55555C20.3983 7.46296 21.2224 8.41666 22.8706 8.41666C24.5094 8.41666 25.3289 7.46296 25.3289 5.55555V0.138885H27.1067V5.625C27.1067 7.0324 26.7317 8.11574 25.9817 8.875C25.2409 9.625 24.1993 10 22.8567 10Z"
        clipRule="evenodd"
      />
      <TextPath
        className={`colour-${colour}`}
        d="M10.2197 0.138885H12.0253V8.33333H17.1086V9.86111H10.2197V0.138885Z"
        clipRule="evenodd"
      />
      <TextPath
        className={`colour-${colour}`}
        d="M4 0.138885C4.84259 0.138885 5.57407 0.277774 6.19444 0.555553C6.82407 0.83333 7.30555 1.23148 7.63889 1.75C7.97222 2.26852 8.13889 2.88426 8.13889 3.59722C8.13889 4.30092 7.97222 4.91666 7.63889 5.44444C7.30555 5.96296 6.82407 6.36111 6.19444 6.63889C5.57407 6.91666 4.84259 7.05555 4 7.05555H1.80555V9.86111H0V0.138885H4ZM3.91667 5.52777C4.7037 5.52777 5.30093 5.36111 5.70833 5.02777C6.11574 4.69444 6.31944 4.21759 6.31944 3.59722C6.31944 2.97685 6.11574 2.5 5.70833 2.16666C5.30093 1.83333 4.7037 1.66666 3.91667 1.66666H1.80555V5.52777H3.91667Z"
        clipRule="evenodd"
      />
      <PlusPath d="M45.1259 5.69444H42.6676V8.125H41.1815V5.69444H38.7231V4.30556H41.1815V1.875H42.6676V4.30556H45.1259V5.69444Z" />
    </svg>
  }

  return <svg {...rest} viewBox="0 0 74 10">
    <TextPath
      className={`colour-${colour}`}
      d="M61.464 10c-.75 0-1.477-.106-2.18-.32-.695-.212-1.246-.495-1.653-.847l.625-1.402a5.06 5.06 0 0 0 1.458.777 5.506 5.506 0 0 0 1.75.292c.722 0 1.26-.116 1.611-.347.361-.232.542-.537.542-.917a.858.858 0 0 0-.306-.68 1.974 1.974 0 0 0-.75-.431 16.894 16.894 0 0 0-1.25-.347 14.978 14.978 0 0 1-1.805-.528 3.057 3.057 0 0 1-1.18-.82c-.325-.38-.487-.888-.487-1.527 0-.537.144-1.023.43-1.459.297-.444.737-.796 1.32-1.055C60.182.129 60.904 0 61.756 0c.592 0 1.175.074 1.75.222.574.148 1.07.361 1.486.64l-.57 1.402c-.426-.25-.87-.44-1.333-.57a4.987 4.987 0 0 0-1.347-.194c-.713 0-1.246.12-1.597.361-.343.24-.514.56-.514.958 0 .278.097.505.291.681.204.176.459.315.764.417.306.102.722.217 1.25.347.722.167 1.315.342 1.778.528.463.176.856.449 1.18.82.334.37.5.87.5 1.5 0 .536-.148 1.022-.444 1.457-.287.436-.727.783-1.32 1.042-.592.26-1.314.389-2.166.389Zm-10.07 0c-1.343 0-2.39-.375-3.14-1.125-.75-.76-1.124-1.843-1.124-3.25V.139h1.805v5.417c0 1.907.824 2.86 2.472 2.86 1.64 0 2.459-.953 2.459-2.86V.139h1.778v5.486c0 1.407-.375 2.49-1.125 3.25-.741.75-1.783 1.125-3.125 1.125ZM40.562.139h-1.805V9.86h6.889V8.333h-5.084V.14Zm-8.025 0c.842 0 1.574.139 2.194.417.63.277 1.111.675 1.445 1.194.333.519.5 1.134.5 1.847 0 .704-.167 1.32-.5 1.847-.334.519-.815.917-1.445 1.195-.62.278-1.352.417-2.194.417h-2.195V9.86h-1.805V.14h4Zm-.084 5.389c.787 0 1.385-.167 1.792-.5.408-.334.611-.81.611-1.43 0-.621-.203-1.098-.61-1.431-.408-.334-1.006-.5-1.793-.5h-2.11v3.86h2.11Zm-10.072.055 3.139 4.278h1.194l-3.708-5.028L26.45.14h-1.111L22.422 4.11 19.492.14h-1.18l3.457 4.722-3.694 5h1.18l3.126-4.278ZM12.197 9.944c-1.26 0-2.24-.36-2.945-1.083-.703-.722-1.055-1.773-1.055-3.153V.138h1.028v5.529c0 1.13.254 1.972.764 2.527.509.556 1.245.834 2.208.834.972 0 1.713-.278 2.222-.834.51-.555.764-1.398.764-2.527V.139h1v5.57c0 1.379-.352 2.43-1.056 3.152-.694.722-1.67 1.083-2.93 1.083ZM1.027.14H0V9.86h6.472v-.889H1.028V.14Z"
      clipRule="evenodd"
    />
    <PlusPath d="M73.662 5.694h-2.458v2.431h-1.486v-2.43H67.26v-1.39h2.458v-2.43h1.486v2.43h2.459v1.39Z" />
  </svg>
}

export default LuxPlusLogo

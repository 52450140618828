import { isBNBLLEHotelItem, isCruiseItem, isTourV2Item } from 'lib/checkout/checkoutUtils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { itemsAreBedbank } from '../bedbank/cart'
import { itemsAreCruise } from '../cruises/cart'
import { itemsAreTourV2 } from '../tours/cart'

export function checkoutAccommodationGroupingKey(item: App.Checkout.AccommodationItem): string {
  if (isBNBLLEHotelItem(item)) {
    return `${item.offerId}-bnbl-${item.duration}`
  }

  else if (isTourV2Item(item)) {
    return `${item.offerId}-${item.purchasableOption.fkDepartureId}`
  }

  else if (isCruiseItem(item)) {
    return `${item.offerId}-${item.departureId}`
  }

  else if (item.itemType === 'tourV1') {
    return `${item.offerId}-${item.startDate}-${item.endDate}`
  }

  else {
    return `${item.offerId}-${item.checkIn}-${item.checkOut}`
  }
}

export function getItemUniqueKey(item: App.Checkout.AccommodationItem) {
  if (item.itemType === 'bedbankHotel') return item.roomId
  if (item.itemType === 'tourV1') return item.packageId
  if (isTourV2Item(item)) return item.purchasableOption.fkDepartureId
  if (isCruiseItem(item)) return item.departureId
  return getPackageUniqueKey(item.packageId, item.duration, item.roomRateId)
}

export function getPackageId(item: App.Checkout.AccommodationItem) {
  if (item.itemType === 'bedbankHotel') return item.roomId
  if (isTourV2Item(item)) return item.purchasableOption.fkDepartureId
  if (isCruiseItem(item)) return item.departureId

  return item.packageId
}

export function itemsAreLE(
  items: Array<App.Checkout.AccommodationItem>,
): items is Array<App.Checkout.LEAccommodationItem> {
  return !itemsAreBedbank(items) && !itemsAreTourV2(items) && !itemsAreCruise(items)
}

import React from 'react'
import Tooltip from 'components/Luxkit/Tooltip'
import { OFFER_TYPE_TOUR } from 'constants/offer'
import SecureWithDepositTag from './SecureWithDepositTag'
import { isTourV2OfferType } from 'lib/offer/offerTypes'

interface Props {
  offerType: App.OfferType;
  depositAmountPercentage?: number;
}

function SecureWithDepositTagAndTooltip(props: Props) {
  const { offerType, depositAmountPercentage } = props
  const tagTooltip = offerType === OFFER_TYPE_TOUR || isTourV2OfferType(offerType) ?
    'Some departures available for you to secure with a deposit' : 'Some packages available for you to secure with a deposit'

  return (
    <Tooltip
      placement="bottom"
      description={tagTooltip}
    >
      <SecureWithDepositTag depositAmountPercentage={depositAmountPercentage}/>
    </Tooltip>
  )
}

export default React.memo(SecureWithDepositTagAndTooltip)

import React, { useEffect } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import config from 'constants/config'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${rem(24)} 0;
`
interface Props {
  tab: App.GlobalSearchVerticalTabV2;
  closeSearch: () => void;
  onSearch?: () => void;
  showSearch: (show: boolean) => void;
  onSearchValidate: (isValid: boolean) => void;
  isInPage?: boolean;
  className?: string;
}

function GlobalMobileSearchTabContentContainer(props: Props) {
  const { tab, closeSearch, onSearch, onSearchValidate, showSearch, isInPage, className } = props
  const { context, contextProvider } = tab

  const { checkinDate, checkoutDate } = context.state as GlobalSearchState

  useEffect(() => {
    if (config.businessTraveller && config.businessTraveller.currentAccountMode === 'business') {
      onSearchValidate(!!(checkinDate && checkoutDate))
    } else {
      onSearchValidate(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDate, checkinDate])

  const Provider = contextProvider ?? GlobalSearchContextProvider
  return (
    <ContentContainer className={className}>
      <Provider state={context.state} dispatch={context.dispatch}>
        {tab?.ContentComponent &&
          <tab.ContentComponent
            isInPage={isInPage}
            closeSearch={closeSearch}
            onSearch={onSearch}
            showSearch={showSearch}
            formId={tab.formId}
          />
        }
      </Provider>
    </ContentContainer>
  )
}

export default GlobalMobileSearchTabContentContainer

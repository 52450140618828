import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineEnvelopeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm-.41 2-5.88 5.88a.9997.9997 0 0 1-1.42 0L5.41 6h13.18ZM20 17a1.0001 1.0001 0 0 1-1 1H5a1.0002 1.0002 0 0 1-1-1V7.41l5.88 5.88a3 3 0 0 0 4.24 0L20 7.41V17Z"/>
  </SvgIcon>
}

export default LineEnvelopeIcon

import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import SolidTimesCircleIcon from '../Icons/solid/SolidTimesCircleIcon'
import BodyText from '../Typography/BodyText'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'

export const INPUT_CHIP_HEIGHT = 28

const RemoveIcon = styled(SolidTimesCircleIcon)``

const Chip = styled(Clickable)`
  display: inline-flex;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.round};
  box-shadow: inset 0 0 0 var(--chip-stroke-width) var(--chip-stroke-color);
  position: relative;
  z-index: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  gap: ${rem(4)};
  height: ${rem(INPUT_CHIP_HEIGHT)};
  padding: 0 ${rem(8)};
  white-space: nowrap;

  > ${RemoveIcon} {
    transition: color 0.2s;
  }

  &.variant-outline {
    --chip-stroke-width: 1px;
    --chip-stroke-color: ${props => props.theme.palette.neutral.default.four};
    background-color: transparent;
    color: ${props => props.theme.palette.neutral.default.one};

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          --chip-stroke-color: ${props => props.theme.palette.neutral.default.two};
          color: ${props => props.theme.palette.neutral.default.two};

          &:not(.error) {
            background-color: ${props => props.theme.palette.neutral.default.seven};
          }
        }
      }
      &:hover {
        > ${RemoveIcon} {
          color: ${props => props.theme.palette.neutral.default.two};
        }
      }

      &.error {
        --chip-stroke-color: ${props => props.theme.palette.messaging.critical.normalForeground};

        > ${RemoveIcon} {
          color: ${props => props.theme.palette.neutral.default.one};
        }
      }

      &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }
    }

    &:disabled {
      color: ${props => props.theme.palette.neutral.default.four};

      > ${RemoveIcon} {
        color: ${props => props.theme.palette.neutral.default.four};
      }
    }
  }

  &.variant-filled {
    --chip-stroke-width: 1px;
    --chip-stroke-color: ${props => props.theme.palette.neutral.default.four};
    background-color: ${props => props.theme.palette.neutral.default.seven};
    color: ${props => props.theme.palette.neutral.default.one};

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          --chip-stroke-color: ${props => props.theme.palette.neutral.default.two};
          background-color: ${props => props.theme.palette.neutral.default.six};
          color: ${props => props.theme.palette.neutral.default.two};
        }
      }
      &:hover {
        > ${RemoveIcon} {
          color: ${props => props.theme.palette.neutral.default.two};
        }
      }

      &.error {
        --chip-stroke-color: ${props => props.theme.palette.messaging.critical.normalForeground};
        background-color: ${props => props.theme.palette.neutral.default.eight};

        > ${RemoveIcon} {
          color: ${props => props.theme.palette.neutral.default.one};
        }
      }

      &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }
    }

    &:disabled {
      --chip-stroke-color: ${props => props.theme.palette.neutral.default.five};
      background-color: ${props => props.theme.palette.neutral.default.seven};
      color: ${props => props.theme.palette.neutral.default.four};

      > ${RemoveIcon} {
        color: ${props => props.theme.palette.neutral.default.four};
      }
    }
  }

  > svg, img {
    height: ${rem(16)};
    width: ${rem(16)};
  }
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Determines the core visual representation of the chip */
  variant: 'filled' | 'outline';
  /** Whether or not the chip should show itself visually in an error state */
  error?: boolean;
  /** An icon element that will go at the start (often the 'left' side) of the chip */
  startIcon?: React.ReactElement;
}

/**
 * Input chips display a complex piece of information in a compact form.
 * These are typically selections the user has made when performing a larger scale task.
 *
 * Examples of where an input chip may be used include search.
 * When allowing a user to choose multiple inputs from a wider list of content, an input chip is helpful in presenting to the user what they have selected, and typically provide them with the ability to remove their selections.
 *
 * _**Note**: The end icon for input chips can ONLY be the times-circle solid icon. This is to ensure the user always has a way to delete their selection._
 * _However it is possible to show/hide this icon._
 *
 * Behaviour:
 * - Scrolling:
 *   - Chips can be scrolled horizontally in a carousel format, allowing there to be a significant reduction of vertical space.
 *   - This is especially useful when needing to call out other information directly below. In some cases, chips are able to also wrap across multiple lines if all options are needed to be visible.
 *   - However, if the chips will extend over three lines it is recommended to use the carousel format instead.
 * - Interaction:
 *   - If the chip is tapped this will remove the chip from the list.
 *   - The user will need to re-enter it if they wish to add it back.
 * - Showing / hiding chips:
 *   - Ideally, if a chip is unavailable to interact with, then they should be removed from view.
 *   - Chips should only be present if they can be interacted on.
 *   - There is one exception to this rule, where a chip may be shown in the disabled state if it will be able to be changed to an enabled state due to another interaction or action.
 */
function InputChip(props: Props) {
  const { children, className, variant, error, startIcon, ...buttonProps } = props

  return (<Chip
    {...buttonProps}
    className={cn(
      className,
      `variant-${variant}`,
      { error },
    )}
  >
    {startIcon}
    <BodyText variant="small">{children}</BodyText>
    <RemoveIcon colour="neutral-one" />
  </Chip>)
}

export default InputChip

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBoxIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20.49 7.52a.1893.1893 0 0 1 0-.08.172.172 0 0 1 0-.07v-.09l-.06-.15a.4805.4805 0 0 0-.09-.11l-.09-.08h-.05l-3.94-2.49-3.72-2.3a.8499.8499 0 0 0-.29-.15h-.08a.82.82 0 0 0-.27 0h-.1a1.1307 1.1307 0 0 0-.33.13L4 6.78l-.09.07-.09.08-.1.07-.05.06-.06.15v.15a.6895.6895 0 0 0 0 .2v8.73a1.0003 1.0003 0 0 0 .47.85l7.5 4.64.15.06h.08a.8609.8609 0 0 0 .52 0h.08l.15-.06L20 17.21a1.0001 1.0001 0 0 0 .47-.85V7.63s.02-.07.02-.11ZM12 4.17l1.78 1.1-5.59 3.46-1.79-1.1L12 4.17Zm-1 15-5.5-3.36V9.42l5.5 3.4v6.35Zm1-8.11-1.91-1.15 5.59-3.47 1.92 1.19-5.6 3.43Zm6.5 4.72L13 19.2v-6.38l5.5-3.4v6.36Z"/>
  </SvgIcon>
}

export default LineBoxIcon

import { usePercentFormatter } from 'hooks/useNumberFormatters'
import React, { memo } from 'react'

type InheritedPercentFormatterOptions = NonNullable<Parameters<typeof usePercentFormatter>[0]>

interface Props extends InheritedPercentFormatterOptions {
  /**
   * @example 0.5 => 50%
   */
  value: number
}

/**
 * Formats a given number into readable percentage. **(eg. 0.5 => 50%)**
 *
 * _Note: The hook version is `usePercentFormatter` as `hooks/useNumberFormatters`._
 */
function FormatPercent(props: Props) {
  const { value, ...options } = props
  const percentFormat = usePercentFormatter(options)

  return <>{percentFormat(value)}</>
}

export default memo(FormatPercent)

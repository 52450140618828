import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSpaCreditIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M20.5257 6.9065c-.13-.5-.63-.82-1.14-.73-.08.01-1.77.33-3.49 1.09-.9464-2.8002-2.9496-4.7818-3.1485-4.9786l-.0115-.0114c-.39-.37-.99-.37-1.38 0l-.0115.0114c-.1994.1972-2.2022 2.1784-3.1485 4.9686-1.515-.6544-3.0543-.9874-3.4071-1.0637a2.1622 2.1622 0 0 1-.073-.0163c-.52-.09-1.02.22-1.15.73-.04.14-.71 2.81.21 5.47-.87.27-2.09.67-2.09.67-.5.16-.78.68-.66 1.19l.0011.0039c.0529.1994 1.0003 3.7722 5.219 4.5861.53.1 1.25.18 2.03.18 1.27 0 2.69-.2 3.73-.77l.0914-.0291c.5794.2878 1.1921.4915 1.8156.6205.5408.112 1.07-.2357 1.1819-.7765.112-.5408-.2357-1.07-.7765-1.182a5.9046 5.9046 0 0 1-.4434-.1096l.921-1.4333c.83-1.38 1.61-3.19 1.61-5.21 0-.3-.02-.6-.05-.89.0139-.0035.0291-.0057.0446-.008.029-.0044.0593-.009.0854-.022h-.01c.801-.3758 1.6313-.644 2.2589-.8143.0137.0944.0289.1997.0457.3178.0781.5468.5847.9267 1.1314.8486.5467-.0781.9266-.5847.8485-1.1314-.1256-.8791-.168-1.1482-.2548-1.487l-.0015-.0056.0218-.018Zm-6.13 3.21c0-2.39-1.45-4.53-2.35-5.62-.9 1.09-2.35 3.23-2.35 5.62s1.45 4.53 2.35 5.62c.9-1.09 2.35-3.23 2.35-5.62Zm-6.66-.88h.01c-.03.29-.05.59-.05.88 0 2.02.77 3.83 1.61 5.21-1.05-.48-2.16-1.17-2.9-2.17-1.1691-1.5688-1.19-3.597-1.0603-4.7773.6591.18 1.5544.4593 2.3903.8573Zm-3.05 4.94c-.01.01-.02.02-.03.02-.252.0749-.5642.173-.8798.2722-.148.0464-.2966.0932-.4402.1378.46.79 1.41 1.89 3.28 2.25.41.08.96.14 1.55.14-1.21-.59-2.47-1.44-3.37-2.65-.02-.03-.0375-.0575-.055-.085-.0175-.0275-.035-.055-.055-.085Z" clipRule="evenodd"/>
    <path fill="currentColor" d="M19.8589 11.429c.5523 0 1 .4477 1 1v.4287h1.1429c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2.8574a.5934.5934 0 0 0-.1494.024c-.0658.0188-.1201.0451-.1592.0733-.0355.0256-.0584.0521-.0751.0845-.0163.0316-.045.1039-.045.2469 0 .143.0287.2154.045.247a.2313.2313 0 0 0 .0751.0844c.0391.0282.0934.0545.1592.0733a.5887.5887 0 0 0 .1494.024h1.4287c.3982 0 .9731.1112 1.4773.4742.5551.3997.9514 1.0571.9514 1.9545s-.3963 1.5548-.9514 1.9545c-.3933.2832-.8296.4131-1.1915.4566v.4463c0 .5523-.4477 1-1 1s-1-.4477-1-1v-.4287h-1.1432c-.5523 0-1-.4477-1-1s.4477-1 1-1h2.8574a.588.588 0 0 0 .1494-.024c.0658-.0188.1201-.0451.1593-.0732a.232.232 0 0 0 .075-.0845c.0163-.0316.045-.104.045-.247 0-.1429-.0287-.2153-.045-.2469a.232.232 0 0 0-.075-.0845c-.0392-.0282-.0935-.0544-.1593-.0732a.5896.5896 0 0 0-.1494-.0241h-1.4287c-.3981 0-.9731-.1112-1.4772-.4742-.5552-.3997-.9515-1.0571-.9515-1.9545s.3963-1.5548.9515-1.9545c.3933-.2832.8297-.4131 1.1917-.4566v-.4463c0-.5523.4477-1 1-1Z"/>
  </SvgIcon>
}

export default LineSpaCreditIcon

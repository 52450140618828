import React from 'react'
import styled from 'styled-components'
import LuxPlusLogo from './Logos/LuxPlusLogo'
import clsx from 'clsx'
import { themeClassName } from 'lib/theme/themeUtils'

const LogoWrapper = styled.div`
  background-color: ${props => props.theme.palette.product.luxPlus.background};
  color: ${props => props.theme.palette.product.luxPlus.contrast};
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  className?: string
}

function LuxPlusProductPlaceholder({ className }: Props) {
  return (
    <LogoWrapper className={clsx(className, themeClassName('inverse'))}>
      <LuxPlusLogo height={22} />
    </LogoWrapper>
  )
}

export default LuxPlusProductPlaceholder

import React, { useReducer } from 'react'
import SearchWidget from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import FlightSearchWidgetStateProvider from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateProvider'
import flightSearchWidgetStateReducer, { initialState } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import { getFlightsRecentSearch } from 'lib/flights/flightUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { isMultiCityEnabled } from 'selectors/flightsSelectors'

function GlobalFlightsSearchTabContent() {
  const [state, stateDispatch] = useReducer(flightSearchWidgetStateReducer, {
    ...initialState,
  })

  const multiCityEnabled = useAppSelector(isMultiCityEnabled)

  return <FlightSearchWidgetStateProvider state={state} dispatch={stateDispatch}>
    <SearchWidget searchData={{ lastSearch: getFlightsRecentSearch() }} placement="common-search" showMultiCity={multiCityEnabled} />
  </FlightSearchWidgetStateProvider>
}

export default GlobalFlightsSearchTabContent

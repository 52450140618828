import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCompassIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm1 17.93V19a1.0001 1.0001 0 0 0-2 0v.93A8 8 0 0 1 4.07 13H5a1.0002 1.0002 0 0 0 .7071-1.7071A1.0002 1.0002 0 0 0 5 11h-.93A8 8 0 0 1 11 4.07V5a1.0002 1.0002 0 0 0 1.7071.7071A1.0002 1.0002 0 0 0 13 5v-.93A8 8 0 0 1 19.93 11H19a1.0001 1.0001 0 0 0 0 2h.93A8 8 0 0 1 13 19.93Zm2.14-12.38-5 2.12a1 1 0 0 0-.52.52l-2.12 5a1.0002 1.0002 0 0 0 .21 1.1c.1846.1884.4362.2962.7.3a.9302.9302 0 0 0 .4-.09l5-2.12a1 1 0 0 0 .52-.52l2.12-5a.9996.9996 0 0 0-.2143-1.0957A1 1 0 0 0 15.14 7.55Zm-2.49 5.1-2.28 1 1-2.28 2.28-1-1 2.28Z"/>
  </SvgIcon>
}

export default LineCompassIcon

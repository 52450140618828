import TourV2AllInclusionsModal from 'components/OfferPage/TourV2/TourVariations/TourV2AllInclusionsModal'
import ModalContext from 'contexts/ModalContext'
import React, { useCallback, useContext } from 'react'
import SearchTileStackInclusionsList from '../shared/SearchTileStackInclusionsList'
import useOfferUrl from 'hooks/Offers/useOfferUrl'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary
  variation: Tours.TourV2OfferVariation
  filters?: App.OfferListFilters
}

function TourV2SearchTileFeatures({ offer, variation, filters }: Props) {
  const offerUrl = useOfferUrl(offer, {
    filters,
    offerLinkIncludesFilters: true,
  })

  const showModal = useContext(ModalContext)
  const onViewAll = useCallback(() => {
    showModal(<TourV2AllInclusionsModal
      variation={variation}
      primaryActionText="View offer"
      primaryActionTo={offerUrl}
    />)
  }, [offerUrl, showModal, variation])

  if (!variation.inclusionItems.length) {
    return null
  }

  return <SearchTileStackInclusionsList
    inclusions={variation.inclusionItems}
    isHighlighted={false}
    truncateAfter={3}
    lineClamp={2}
    onShowMoreClick={onViewAll}
  />
}

export default TourV2SearchTileFeatures

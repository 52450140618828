import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import Heading from 'components/Luxkit/Typography/Heading'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import LineExclamationCircleIcon from 'components/Luxkit/Icons/line/LineExclamationCircleIcon'
import TextButton from '../../Luxkit/Button/TextButton'
import TextLink from '../../Luxkit/TextLink'

const ErrorContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${rem(32)} ${rem(12)};

  > * + * {
    margin-top: ${rem(20)};
  }
`
const StyledHeading = styled(Heading)`
  max-width: ${rem(700)};
`

const StyledSubtitle = styled(Subtitle)`
  max-width: ${rem(700)};
`

interface Props {
  className?: string;
  onRefresh?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  variant: 'light' | 'dark';
}

function OhNoRefreshError(props: PropsWithChildren<Props>) {
  const { children, onRefresh, className, variant } = props

  const colour = variant === 'dark' ? 'neutral-one' : 'neutral-eight'
  return (
    <ErrorContainer className={className}>
      <LineExclamationCircleIcon size="XL"/>
      <StyledHeading variant="heading2" colour={colour} align="center" forwardedAs="h1">
        Oh no, an error has occurred
      </StyledHeading>
      <StyledSubtitle variant="subtitle2" colour={colour} align="center" forwardedAs="h2">
        {children}
        {!children && <>
          Our team are working on fixing it now.{' '}
          Please try refreshing the page or visiting our{' '}
          <TextLink variant={variant === 'dark' ? 'default' : 'ghost'} href="/" target="_blank" rel="noopener nofollow">homepage</TextLink> for our latest offers.
        </>
        }
      </StyledSubtitle>
      <TextButton
        kind="primary"
        variant={variant === 'dark' ? 'default' : 'ghost'}
        onClick={onRefresh}
        fit="mobile-full-width"
      >
        Refresh
      </TextButton>
    </ErrorContainer>
  )
}

OhNoRefreshError.defaultProps = {
  onRefresh: () => window.location.reload(),
  variant: 'dark',
}

export default OhNoRefreshError

export const CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL = 'Pick-up & Drop-off location'
export const CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL = 'Drop-off location'
export const CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL = 'Pick-up location'

export const CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL = 'Search for an airport or city'

export const CAR_HIRE_SEARCH_TYPES: Array<App.SearchType> = ['airport', 'destination']

export const CAR_HIRE_LOCATION_SEARCH_TYPEAHEAD_TYPES: Array<App.SearchPlaceType> = ['car_hire_location']

export const CAR_HIRE_AVAILABILITY_CACHE_EXPIRATION_MS = 15 * 60 * 1000 // 15 minutes
export const CAR_HIRE_INSURANCE_CACHE_EXPIRATION_MS = 90 * 60 * 1000 // 90 minutes

export interface CarHireFilterCategory {
  id: string;
  label: string;
  min: number;
  max: number;
}

export const CarHireLuggageCategories: Array<CarHireFilterCategory> = [{
  id: '1',
  label: '0 - 2 bags',
  min: 0,
  max: 2,
}, {
  id: '2',
  label: '3 - 4 bags',
  min: 3,
  max: 4,
}, {
  id: '3',
  label: '5 + bags',
  min: 5,
  max: Number.MAX_SAFE_INTEGER,
}]

export const CarHireSeatCategories: Array<CarHireFilterCategory> = [{
  id: '1',
  label: '1 - 4',
  min: 1,
  max: 4,
}, {
  id: '2',
  label: '5 - 6',
  min: 5,
  max: 6,
}, {
  id: '3',
  label: '7 +',
  min: 7,
  max: Number.MAX_SAFE_INTEGER,
}]

export const driverCategories: Array<{ id: App.CarHireDriverAgeCategory, label: string }> = [
  { id: 1, label: '18 - 29' },
  { id: 2, label: '30 - 69' },
  { id: 3, label: '70+' },
]

/**
 * The sidebar (desktop) version doesn't have all the keys
 */
export const CarHireSearchSidebarFilterKeys = [
  'luggage',
  'supplier',
  'fuelType',
  'seats',
  'transmission',
  'pickUpType',
  'mileage',
  'paymentSchedule',
]

export const CarHireSearchFilterKeys = [
  'luggage',
  'supplier',
  'fuelType',
  'seats',
  'transmission',
  'pickUpType',
  'mileage',
  'carTypes',
  'paymentSchedule',
]

export const CarHireFilterKeyToLabel = new Map<string, string>([
  ['luggage', 'Luggage capacity'],
  ['supplier', 'Supplier'],
  ['fuelType', 'Fuel type'],
  ['seats', 'Number of seats'],
  ['transmission', 'Transmission'],
  ['pickUpType', 'Pick-up location'],
  ['mileage', 'Mileage'],
  ['carTypes', 'Car types'],
  ...Array.from(
    new Map<App.CarHireRateOptionTypes, string>([
      ['payFullyNow', 'Pay in full'],
      ['payPartiallyNow', 'Pay deposit'],
      ['payFullyOnArrival', 'Pay at pickup'],
    ]).entries(),
  ),
])

export const pickupTypeLabelMap = new Map<App.CarHirePickupType, string>([
  ['office', 'Pick-up at rental office'],
  ['shuttle', 'Pick-up by free shuttle'],
  ['terminal', 'Pick-up in terminal'],
])

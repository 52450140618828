import React, { MouseEventHandler, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Pill from 'components/Luxkit/Pill'
import { mediaQueryUp } from 'components/utils/breakpoint'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import Group from 'components/utils/Group'
import CruiseSearchPrices from './CruiseSearchPrices'
import ModalContext from 'contexts/ModalContext'
import CruisesDiscountTsAndCsModal from 'components/Cruises/CruisesDiscountTsAndCsModal'
import Clickable from 'components/Common/Clickable'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import PriceRowValueDiscount from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscount'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import config from 'constants/config'

const Container = styled(Group)`
  padding: ${rem(20)};
`

const PriceContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  gap: ${rem(16)};

  ${mediaQueryUp.desktop} {
    grid-template-columns: 1fr ${rem(210)};
  }
`

const CabinPricesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(12)};

  ${mediaQueryUp.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`

interface Props {
  offer: App.CruiseOffer;
  discountPills: App.CruiseDiscountPillDetails | null;
  promotionDetails: App.CruisePromotionDetails | null;
  highlightedDepartureId: string | null;
}

function CruiseTileSearchPrices(Props: Props) {
  const { offer, discountPills, promotionDetails, highlightedDepartureId } = Props
  const { lowestDeparturePricesByCategory, mainDepartureDetails } = offer
  const { lowestPriceDetailsByCategory } = mainDepartureDetails
  const lowestDeparturePriceByCategory = highlightedDepartureId ? lowestDeparturePricesByCategory[highlightedDepartureId] : null

  const showModal = useContext(ModalContext)
  const openDiscountModal = useCallback<MouseEventHandler<HTMLOrSVGElement>>((event) => {
    event.preventDefault()
    showModal(<CruisesDiscountTsAndCsModal />)
  }, [showModal])

  return <Container direction="vertical" horizontalAlign="stretch" gap={16} data-testid="cruise-tile-search-prices">
    {config.DEPOSITS_TAGS_ENABLED && !offer.disableDeposits && <CSSBreakpoint max="tablet">
      <Group direction="horizontal">
        {promotionDetails?.deposit && <Pill
          kind="tertiary"
          variant="primary"
          startIcon={<LineClockDollarIcon />}
        >
          Secure with a {promotionDetails.deposit.formattedValue} deposit
        </Pill>}
        {!promotionDetails?.deposit && <Pill
          kind="tertiary"
          variant="primary"
          startIcon={<LineClockDollarIcon />}
        >
          Deposits available
        </Pill>}
      </Group>
    </CSSBreakpoint>}
    <PriceContainer>
      <CabinPricesContainer>
        <CruiseSearchPrices
          offer={offer}
          lowestPriceByCategory={lowestDeparturePriceByCategory || lowestPriceDetailsByCategory}
        />
      </CabinPricesContainer>
      <Group direction="horizontal" verticalAlign="center" horizontalAlign="space-between">
        <Group
          direction="vertical"
          gap={4}
          tabletDirection="horizontal"
          tabletGap={16}
          desktopDirection="vertical"
          desktopGap={4}
          horizontalAlign="start"
          verticalAlign="center"
        >
          {discountPills?.discountPercentage && <Group direction="horizontal" verticalAlign="center" gap={4}>
            <PriceRowValueDiscount
              type="estimate"
              discountPercentage={discountPills.discountPercentage}
            />
            <Clickable onClick={openDiscountModal}><SolidInfoCircleIcon size="XS" /></Clickable>
          </Group>}
          <PriceRowCaption>All prices include taxes & fees</PriceRowCaption>
        </Group>
        <CSSBreakpoint max="mobile">
          <TextButton kind="primary">View</TextButton>
        </CSSBreakpoint>
      </Group>
    </PriceContainer>
  </Container>
}

export default CruiseTileSearchPrices

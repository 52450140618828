import React from 'react'
import CarouselCardMediumLoadingSkeleton from './OfferCardMedium/CarouselCardMediumLoadingSkeleton'
import CarouselCardSmallLoadingSkeleton from './OfferCardSmall/CarouselCardSmallLoadingSkeleton'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'small' | 'medium'
}

function CarouselCardLoadingSkeleton(props: Props) {
  const { size = 'small', ...cardProps } = props
  if (size === 'medium') {
    return <CarouselCardMediumLoadingSkeleton {...cardProps} />
  } else if (size === 'small') {
    return <CarouselCardSmallLoadingSkeleton {...cardProps} />
  }

  return null
}

export default CarouselCardLoadingSkeleton

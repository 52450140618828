import React, { useCallback, useMemo, MouseEventHandler } from 'react'
import AvatarCircle from 'components/Common/AvatarCircle/AvatarCircle'
import ListItem from 'components/Luxkit/List/ListItem'
import Group from 'components/utils/Group'
import { connect } from 'react-redux'
import ListSubheader from 'components/Luxkit/List/ListSubheader'
import useBusinessTravellerEmployeeQuery from 'businessTraveller/hooks/useEmployeeQuery'
import { EmptyArray, sortBy } from 'lib/array/arrayUtils'
import Divider from 'components/Luxkit/Divider'
import CounterInput from 'components/Common/Form/Input/CounterInput'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import { maxFlightPassengerCount } from 'constants/flight'

interface EmployeeOption {
  id: string
  customerId: string | undefined
  firstName: string
  lastName: string
  email: string
  isSelected: boolean
}

interface MappedStateProps {
  businessAccount?: BusinessTraveller.CurrentBusinessAccount
}

interface Props {
  searchTerm?: string
  travellerSelectState: {
    selectedTravellers: Array<string>
    guestCount: number
  }
  setTravellerSelectState: (state: {selectedTravellers: Array<string>, guestCount: number}) => void
}

function BusinessTravellerSelectContent(props: Props & MappedStateProps) {
  const { businessAccount, searchTerm = '', travellerSelectState, setTravellerSelectState } = props
  const { employees } = useBusinessTravellerEmployeeQuery()

  const employeeOptions = useMemo<Array<EmployeeOption>>(() => {
    if (!employees) {
      return []
    }

    const selectedIds = new Set(travellerSelectState.selectedTravellers)
    const options: Array<EmployeeOption> = Object.values(employees).map<EmployeeOption>((employee) => {
      return {
        id: employee.id,
        customerId: employee.customerId,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        isSelected: selectedIds.has(employee.id),
      }
    })

    return sortBy(options, (employee) => employee.firstName + employee.lastName, 'asc')
  }, [employees, travellerSelectState.selectedTravellers])

  const searchActive = !!searchTerm

  const filteredEmployeeOptions = useMemo<Array<EmployeeOption>>(() => {
    if (employeeOptions.length && searchTerm) {
      return employeeOptions.filter((employee) => {
        return employee.firstName.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()) ||
        employee.lastName.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase())
      })
    }
    return EmptyArray
  }, [employeeOptions, searchTerm])

  const handleEmployeeClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    const employeeId = event.currentTarget.dataset.employeeid

    if (employeeId) {
      if (!travellerSelectState.selectedTravellers.includes(employeeId) && travellerSelectState.selectedTravellers.length + travellerSelectState.guestCount >= maxFlightPassengerCount) {
        showSnackbar(
          'You can only select up to 9 travellers and guests.',
          'warning',
        )
        return
      }

      const updatedSelectedTravellers = travellerSelectState.selectedTravellers.includes(employeeId) ?
        travellerSelectState.selectedTravellers.filter(id => id !== employeeId) :
          [...travellerSelectState.selectedTravellers, employeeId]
      setTravellerSelectState({
        ...travellerSelectState,
        selectedTravellers: updatedSelectedTravellers,
      })
    }
  }, [travellerSelectState, setTravellerSelectState])

  const handleGuestCountChange = useCallback<(value: number, inputElement: HTMLInputElement | null) => void>((value) => {
    if (travellerSelectState.selectedTravellers.length + value > 9) {
      showSnackbar(
        'You can only select up to 9 travellers and guests.',
        'warning',
      )
      return
    }
    setTravellerSelectState({
      ...travellerSelectState,
      guestCount: value,
    })
  }, [travellerSelectState, setTravellerSelectState])

  const isBusinessAccountSelected = useMemo<boolean>(() => {
    return !!travellerSelectState.selectedTravellers.find(id => id === businessAccount?.employee?.id)
  }, [businessAccount?.employee?.id, travellerSelectState.selectedTravellers])

  const showFilteredEmployees = filteredEmployeeOptions.length > 0
  const showNoResults = searchActive && !showFilteredEmployees

  return (
    <Group direction="vertical" gap={12}>
      {!showFilteredEmployees && !showNoResults && (
        <>
          {!!businessAccount?.employee?.id && (
            <ListItem
              startIcon={
                <AvatarCircle
                  firstName={businessAccount?.employee?.firstName}
                  lastName={businessAccount?.employee?.lastName}
                  nonInteractable
                />
              }
              title={`${businessAccount?.employee?.firstName} ${businessAccount?.employee?.lastName}`}
              subtitle={businessAccount?.employee?.email}
              data-employeeid={businessAccount.employee.id}
              onClick={handleEmployeeClick}
              endIcon={<CheckboxButton checked={isBusinessAccountSelected} />}
            />
          )}
          <ListItem
            startIcon={
              <AvatarCircle
                nonInteractable
              />
            }
            title="Guest traveller"
            subtitle="For one-off bookings"
          >
            <CounterInput
              tabletDirection="horizontal"
              min={0}
              defaultValue={0}
              max={9}
              onChange={handleGuestCountChange}
              value={travellerSelectState.guestCount}
            />
          </ListItem>
          <Divider kind="primary" />
          <ListSubheader>
            {businessAccount?.business?.name}'s team members ({employeeOptions.length})
          </ListSubheader>
          <div>
            {employeeOptions
              ?.filter((employeeOption) => employeeOption.id !== businessAccount?.employee?.id)
              .map((employeeOption) => (
                <ListItem
                  key={employeeOption.id}
                  startIcon={
                    <AvatarCircle
                      firstName={employeeOption.firstName}
                      lastName={employeeOption.lastName}
                      nonInteractable
                    />
                  }
                  title={`${employeeOption.firstName} ${employeeOption.lastName}`}
                  subtitle={employeeOption.email}
                  data-employeeid={employeeOption.id}
                  onClick={handleEmployeeClick}
                  endIcon={<CheckboxButton checked={employeeOption.isSelected} />}
                />
              ))}
          </div>
        </>
      )}
      {showFilteredEmployees && (
        <div>
          {filteredEmployeeOptions.map((employeeOption) => <ListItem
            key={employeeOption.id}
            startIcon={<AvatarCircle
              firstName={employeeOption.firstName}
              lastName={employeeOption.lastName}
              nonInteractable
            />}
            title={`${employeeOption.firstName} ${employeeOption.lastName}`}
            subtitle={employeeOption.email}
            data-employeeid={employeeOption.id}
            onClick={handleEmployeeClick}
            endIcon={<CheckboxButton checked={employeeOption.isSelected} />}
          />)}
        </div>
      )}
      {showNoResults && <ListSubheader>No results</ListSubheader>}
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => {
  return {
    businessAccount: state.businessTraveller.currentBusinessAccount,
  }
})(BusinessTravellerSelectContent)

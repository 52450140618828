import qs from 'qs'
import {
  API_CALL,
  RESET_REVELEX_CALLS,
  RESET_CABIN_AVAILABILITY,
  SAVE_CABIN_OPTION,
  CRUISE_SET_SEARCH_FACETS,
  RESET_BOOKING_CABIN_SELECTION,
  SET_BOOKING_INTEREST_IN_CONNECTED_CABINS,
} from './actionConstants'
import { arrayToObject, sortBy } from 'lib/array/arrayUtils'

import { expireCruiseTimer, getCruiseFilters, resetCruiseTimer } from 'lib/cruises/cruiseUtils'
import { getCruiseCheckoutPath } from 'lib/url/pathsUtils'
import {
  getNextCheckoutStepId,
  getNextCabinStepId,
  getCabinStepIdFromCruiseItem,
  getCurrentCruiseCheckoutPage,
  clearNextCheckoutStepsData,
  getSteps,
} from 'checkout/lib/utils/cruises/steps'

import { cruiseFacetsMap } from 'api/mappers/Cruise/cruiseMap'
import { CruisesContract } from '@luxuryescapes/contract-svc-cruise'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import { CheckoutPageId } from 'checkout/constants/pages'
import {
  FETCH_CRUISE_OFFER,
  FETCH_CRUISES_LIST_RECOMMENDATION,
  FETCH_CRUISE_SHIP_DECK,
  FETCH_CRUISE_SHIP,
  FETCH_CRUISE_SEARCH_COUNT,
  FETCH_CRUISE_SEARCH_FACETS,
  FETCH_CRUISE_BOOKING_RATE_LIST,
  FETCH_CRUISE_BOOKING_CABIN_LIST,
  FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST,
  FETCH_CRUISE_BOOKING_CABIN_SELECTION,
  FETCH_CRUISE_BOOKING_CABIN_PRICING,
  FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST,
  FETCH_CRUISE_BOOKING_CABIN_RELEASE,
  FETCH_CRUISE_BOOKING_BY_ID,
  FETCH_CRUISE_DEPARTURE_BY_ID,
  FETCH_CRUISE_LINES,
  FETCH_CRUISE_PROMO_BANNERS,
  FETCH_CRUISE_LOWEST_PRICE_BY_MONTH,
  RESET_SESSION_ID,
  FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE,
} from './apiActionConstants'
import { getCruiseItems } from 'checkout/selectors/cruiseSelectors'
import {
  CruiseAPI,
  findBookingRateList,
  findBookingMultipleCabinList,
  findBookingRateDetailsList,
  findBookingCabinSelection,
  findBookingCabinPricing,
  cruiseBookingCabinRelease,
  findBookingCabinDetailsListMultiple,
  getCruiseBookingById,
  getCruiseSearchFacets,
  getCruiseDepartureById,
  getCruiseLines,
  getCruiseLowestPriceByMonth,
  getCruiseOffer,
  getCruiseSearchList,
  getCruiseShipDeck,
  getCruisePromoBanners,
  getCruiseSearchCount,
  findCruiseShip,
  getConsolidatedPaymentSchedule,
} from 'api/cruises'
import getObjectKey from 'lib/object/getObjectKey'
import { pushWithRegion, replaceWithRegion } from 'actions/NavigationActions'
import { updateCheckoutItem } from 'actions/CheckoutActions'
import {
  generateCabinTravelerKey,
  checkIfNeedsToReleaseCabin,
  groupCabinListByNumber,
  getRateListByCruiseItem,
  getCabinListByCruiseItem,
  getBookingCabinTypes,
} from 'checkout/lib/utils/cruises/booking'
import { CRUISE_AVAILABLE_REGIONS } from 'constants/config/region'
import { AppAction } from './ActionTypes'
import { SKIP_STEP_FOR_CABIN_TYPES } from 'constants/cruise'
import { checkCanRedeemLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'

export function fetchCruiseLines(filter?: CruisesContract.OfferParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const region = state.geo.currentRegionCode

    if (region && !CRUISE_AVAILABLE_REGIONS.has(region.toUpperCase())) return

    if (!state.cruise.cruiseLines.length && !state.cruise.fetchingCruiseLines) {
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_LINES,
        request: () => getCruiseLines(filter, region),
      })
    }
  }
}

export function fetchCruiseSearchListRecommendation(): AppAction {
  return {
    type: API_CALL,
    api: FETCH_CRUISES_LIST_RECOMMENDATION,
    request: () => getCruiseSearchList({}),
  }
}

export function fetchCruiseOffer(offerId: string, allowInvalidOffer?: boolean): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const currency = state.geo.currentCurrency
    const regionCode = state.geo.currentRegionCode

    if (state.cruise.cruiseOffersLoading[offerId] ||
      state.cruise.cruiseOffers[offerId]) {
      return
    }

    dispatch({
      offerId,
      type: API_CALL,
      api: FETCH_CRUISE_OFFER,
      request: () => getCruiseOffer(offerId, regionCode, currency, allowInvalidOffer),
    })
  }
}

export function fetchCruiseSearchCount(params: App.OfferListFilters): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const key = getObjectKey(params)
    if (state.cruise.cruiseSearchCount?.[key]) {
    //   already have it or are currently fetching it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_SEARCH_COUNT,
      request: () => getCruiseSearchCount(params, state.geo.currentRegionCode),
      key,
    })
  }
}

export function fetchCruiseSearchFacets(params: App.CruiseSearchFacetParams): AppAction {
  return (dispatch, getState) => {
    const key = getObjectKey(params)
    const state = getState()

    if (state.cruise.cruiseFacets[key]) {
      // already have it or are currently fetching it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_SEARCH_FACETS,
      request: () => getCruiseSearchFacets(params, state.geo.currentRegionCode),
      key,
    })
  }
}

export function fetchCruiseLowestPriceByMonth(
  params: App.LowestPriceByMonthParams,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const key = getObjectKey(params)
    if (state.cruise.cruiseLowestPricesByMonth[key]) {
      // we're already fetching or have already fetched it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_LOWEST_PRICE_BY_MONTH,
      request: () => getCruiseLowestPriceByMonth(params, state.geo.currentRegionCode),
      key,
    })
  }
}

export function fetchCruiseShipDeck(deckId: string): AppAction {
  return (dispatch, getState: () => App.State) => {
    const state = getState()

    if (state.cruise.deckSelections && deckId in state.cruise.deckSelections) { return }

    dispatch({
      deckId,
      type: API_CALL,
      api: FETCH_CRUISE_SHIP_DECK,
      request: () => getCruiseShipDeck(deckId),
    })
  }
}

export function fetchCruisePromoBanners(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.cruise.cruisePromoBanners?.length && !state.cruise.fetchingCruisePromoBanners) {
      const region = state.geo.currentRegionCode
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_PROMO_BANNERS,
        request: () => getCruisePromoBanners(region),
      })
    }
  }
}

export function saveCabinOptions(cruiseItemId: string) {
  return {
    type: SAVE_CABIN_OPTION,
    cruiseItemId,
  }
}

export function resetSessionId() {
  return {
    type: RESET_SESSION_ID,
  }
}

export function resetCabinRates() {
  return {
    type: RESET_REVELEX_CALLS,
  }
}

export function resetCabinAvailability() {
  return {
    type: RESET_CABIN_AVAILABILITY,
  }
}

export function resetBookingCabinSelection() {
  return {
    type: RESET_BOOKING_CABIN_SELECTION,
  }
}

export function updateBookingItemData(data: Partial<App.Checkout.CruiseItem>): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const cruiseItems = getCruiseItems(state)
    const {
      currentStepId,
      selectedCabinItemId,
      isAllCabinsSelected,
    } = getCurrentCruiseCheckoutPage(state.router.location.pathname, cruiseItems)

    const updateAllItems = (
      isAllCabinsSelected &&
      // In the cabin location step we need to select each cabin individually
      currentStepId !== CheckoutPageId.CruiseCabinLocation
    )

    if (updateAllItems) {
      cruiseItems.forEach((item) => {
        const newItem = clearNextCheckoutStepsData({ ...item, ...data }, currentStepId)
        dispatch(updateCheckoutItem(newItem))
      })
    } else {
      const currentItem = cruiseItems.find((item) => item.itemId === selectedCabinItemId)
      if (currentItem) {
        const newItem = clearNextCheckoutStepsData({ ...currentItem, ...data }, currentStepId)
        dispatch(updateCheckoutItem(newItem))
      }
    }
  }
}

// When selecting a step data, we need to reset all subsequent steps data, to ensure data integrity
export function resetNextStepsData(cruiseItem: App.Checkout.CruiseItem, currentStepId: CheckoutPageId): AppAction {
  return (dispatch) => {
    const dataByStep = [
      { step: CheckoutPageId.CruiseTravelers, data: ['occupancy'] },
      { step: CheckoutPageId.CruiseCabinType, data: ['cabinType'] },
      { step: CheckoutPageId.CruiseCabinCategory, data: ['cabinCodes'] },
      { step: CheckoutPageId.CruiseCabinLocation, data: ['cabinNumber', 'cabinCode', 'deckId'] },
      { step: CheckoutPageId.CruiseCabinPackage, data: ['componentId', 'cabinPreferences'] },
    ]

    const currentStepIndex = dataByStep.findIndex((step) => step.step === currentStepId)
    if (currentStepIndex === -1) return

    let newItem = { ...cruiseItem }

    const nextSteps = dataByStep.slice(currentStepIndex + 1)
    nextSteps.forEach((step) => {
      step.data.forEach((key) => {
        const value = key === 'occupancy' ? { adults: 0, children: 0, infants: 0 } : undefined
        newItem = { ...newItem, [key]: value }
      })
    })

    dispatch(updateCheckoutItem(newItem))
  }
}

export function selectAvailableCabinType(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const rateListMap = state.cruise.multiBooking.rateList
    const cruiseOffers = state.cruise.cruiseOffers

    const cruiseItems = getCruiseItems(state)

    cruiseItems.forEach((cruiseItem) => {
      const offer = cruiseOffers[cruiseItem.offerId]
      const rateListData = getRateListByCruiseItem(cruiseItem, rateListMap)

      if (offer && rateListData) {
        const cabinTypes = getBookingCabinTypes(rateListData, offer)
        const cabinTypesAvailable = cabinTypes.filter((cabinType) => !!cabinType.lowestRatePrice)

        if (cabinTypesAvailable.length === 1 && SKIP_STEP_FOR_CABIN_TYPES.includes(cabinTypesAvailable[0].name)) {
          const newItem: App.Checkout.CruiseItem = {
            ...cruiseItem,
            cabinType: cabinTypesAvailable[0].name,
            cabinCodes: undefined,
            cabinCode: undefined,
            deckId: undefined,
            cabinNumber: undefined,
            componentId: undefined,
          }

          dispatch(updateCheckoutItem(newItem))
        }
      }
    })
  }
}

export function navigateToNextStepCruiseCheckout(shouldReplaceWithRegion?: boolean): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)
    const canRedeemLuxPlusBenefits = checkCanRedeemLuxPlusBenefits(state)
    const cruiseItems = getCruiseItems(state)
    const steps = getSteps(luxPlusEnabled, canRedeemLuxPlusBenefits, cruiseItems)
    const isMultiCabin = cruiseItems.length > 1
    const { currentStepId, selectedCabinItemId, isAllCabinsSelected } = getCurrentCruiseCheckoutPage(state.router.location.pathname, cruiseItems)
    const nextCheckoutStepId = getNextCheckoutStepId(cruiseItems, currentStepId, selectedCabinItemId, isAllCabinsSelected, steps)
    const nextCabinStepId = getNextCabinStepId(cruiseItems, selectedCabinItemId, nextCheckoutStepId, isAllCabinsSelected)

    const url = isMultiCabin ?
      getCruiseCheckoutPath(nextCheckoutStepId, state.checkout.cart.cartId, nextCabinStepId) :
      getCruiseCheckoutPath(nextCheckoutStepId, state.checkout.cart.cartId)

    if (shouldReplaceWithRegion) {
      dispatch(replaceWithRegion(url))
    } else {
      dispatch(pushWithRegion(url))
    }
  }
}

export function navigateToCruiseCheckoutStep(checkoutStepId: CheckoutPageId, shouldReplaceWithRegion?: boolean): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const cruiseItems = getCruiseItems(state)
    const isMultiCabin = cruiseItems.length > 1

    const [firstItem] = cruiseItems
    const cabinStepId = getCabinStepIdFromCruiseItem(cruiseItems, firstItem?.itemId)

    const url = isMultiCabin ?
      getCruiseCheckoutPath(checkoutStepId, state.checkout.cart.cartId, cabinStepId) :
      getCruiseCheckoutPath(checkoutStepId, state.checkout.cart.cartId)

    if (shouldReplaceWithRegion) {
      dispatch(replaceWithRegion(url))
    } else {
      dispatch(pushWithRegion(url))
    }
  }
}

export function fetchCruiseBookingById(bookingId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const { cruiseBookingInfoLoading, cruiseBookingInfo } = state.cruise
    const regionCode = state.geo.currentRegionCode

    if (cruiseBookingInfo[bookingId] || cruiseBookingInfoLoading?.[bookingId]) return

    dispatch({
      id: bookingId,
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_BY_ID,
      request: () => getCruiseBookingById(bookingId, regionCode),
    })
  }
}

export function fetchCruiseDepartureById(departureId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const { cruiseDepartureLoading, cruiseDeparture } = state.cruise

    if (cruiseDeparture[departureId] || cruiseDepartureLoading?.[departureId]) return

    dispatch({
      departureId,
      type: API_CALL,
      api: FETCH_CRUISE_DEPARTURE_BY_ID,
      request: () => getCruiseDepartureById(departureId),
    })
  }
}

export function addFlashCruiseFacets(
  params: App.CruiseSearchFacetParams,
  facets: Array<App.CruiseSearchFacet>,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const key = getObjectKey(params)
    const oldFacets = state.cruise.cruiseFacets[key]?.facets || []

    const newFacets = cruiseFacetsMap(facets)
    const uniqueFacets = [...newFacets, ...oldFacets].reduce((acc, facet) => {
      const { category } = facet
      const name = facet.name.trim()
      let key = `${name}_${category}`

      // ignore the destination/departure full name to generate the group key. ex: "Sydney, Australia" will be "Sydney"
      if (['destination_ports', 'departure_ports'].includes(category) && !facet.isTrend) {
        key = `${name.split(',')[0]}_${category}`
      }

      const exists = acc[key]
      if (exists) {
        if (facet.category === 'cruise_prices') {
          acc[key] = {
            ...facet,
            max: Math.max(exists.max || 0, facet.max || 0),
            min: Math.min(exists.min || 0, facet.min || 0),
            maxPerNight: Math.max(exists.maxPerNight || 0, facet.maxPerNight || 0),
            minPerNight: Math.min(exists.minPerNight || 0, facet.minPerNight || 0),
          }
        } else {
          acc[key] = { ...facet, flashOfferCount: exists.flashOfferCount }
        }
      } else {
        acc[key] = facet
      }

      return acc
    }, {} as Record<string, App.CruiseSearchFacet>)

    const sortedFacets = sortBy(Object.values(uniqueFacets), (facet) => {
      if (facet.category === 'cruise_lines') {
        return facet.order || oldFacets.length + 1
      }

      if (facet.category === 'departure_ports') {
        return facet.order || 0
      }

      // flash offer facets should be at the end
      return facet.flashOfferCount ? 2 : 1
    }, 'asc')

    dispatch({
      type: CRUISE_SET_SEARCH_FACETS,
      key,
      data: sortedFacets,
    })
  }
}

export function pushWithCruiseFilters(
  path: string,
  globalSearch: GlobalSearchState,
  queryParams?: URLSearchParams,
): AppAction {
  return (dispatch) => {
    const filters = getCruiseFilters(globalSearch, queryParams)
    const query = qs.stringify(filters, { arrayFormat: 'repeat' })
    dispatch(pushWithRegion(path, query))
  }
}

// BOOKING FLOW V2
function getCruiseRegion(state: App.State) {
  return state.geo.currentRegionCode as Cruises.Region
}

function getRegionParams(state: App.State) {
  return {
    currencyCode: state.geo.currentCurrency,
    region: state.geo.currentRegionCode as 'au' | 'AU' | 'nz' | 'NZ',
  }
}

export function fetchBookingRateList(cruiseItem: App.Checkout.CruiseItem, offer: App.CruiseOffer): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const rateListData = getRateListByCruiseItem(cruiseItem, state.cruise.multiBooking.rateList)
    if (rateListData.loading) return

    // Expire timer
    expireCruiseTimer()

    // Release cabin if needed
    if (checkIfNeedsToReleaseCabin(cruiseItem)) {
      dispatch(fetchBookingCabinRelease(cruiseItem))
    }

    const payload = {
      ...getRegionParams(state),
      departureId: cruiseItem.departureId,
      travelers: {
        adult: cruiseItem.occupancy.adults,
        child: (cruiseItem.occupancy.children ?? 0) + (cruiseItem.occupancy.infants ?? 0),
        childBirthDate: (cruiseItem.occupancy.childrenBirthDate ?? []) as Array<string>,
      },
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_RATE_LIST,
      cartItemId: cruiseItem.itemId,
      request: () => findBookingRateList(payload, offer),
    })
  }
}

export function fetchBookingCabinList(
  sessionId: string,
  cruiseItem: App.Checkout.CruiseItem,
  componentIds: Array<Array<string>>,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const cabinList = getCabinListByCruiseItem(cruiseItem, state.cruise.multiBooking.cabinList)
    if (cabinList.loading || cabinList.error) return

    const params = { ...getRegionParams(state), sessionId }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_CABIN_LIST,
      travelerKey: generateCabinTravelerKey(cruiseItem),
      request: async() => {
        const newCabins = await findBookingMultipleCabinList(params, componentIds)
        const cabinByNumber = groupCabinListByNumber(cabinList.cabins, newCabins)
        const cabinById = arrayToObject(newCabins, newCabin => newCabin.categoryId)
        const erroredCabinComponentIds = componentIds.flat().filter(componentId => !cabinById[componentId])

        return {
          cabins: Object.values(cabinByNumber),
          cabinByNumber,
          erroredCabinComponentIds,
        }
      },
    })
  }
}

export function fetchBookingCabinDetailsList(
  cruiseItem: App.Checkout.CruiseItem,
  cabinNumberGroups: Array<Array<string>>,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const region = getCruiseRegion(state)
    const sessionId = cruiseItem.sessionId as string

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST,
      travelerKey: generateCabinTravelerKey(cruiseItem),
      cabinNumbers: cabinNumberGroups.flat(),
      request: () => findBookingCabinDetailsListMultiple({ sessionId, region }, cabinNumberGroups),
    })
  }
}

export function fetchBookingRateDetailsList(cruiseItem: App.Checkout.CruiseItem, componentId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const region = getCruiseRegion(state)
    const sessionId = cruiseItem.sessionId as string

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST,
      cartItemId: cruiseItem.itemId,
      componentId,
      request: () => findBookingRateDetailsList({
        sessionId,
        region,
        componentIds: [componentId],
      }),
    })
  }
}

export function fetchBookingCabinSelection(
  cruiseItem: App.Checkout.CruiseItem,
  params: Omit<CruiseAPI.CruiseCabinSelectionBody, 'region' | 'currencyCode'>,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const region = getCruiseRegion(state)
    const cruiseItemId = cruiseItem.itemId
    const decks = state.cruise.cruiseOffers[cruiseItem.offerId]?.ship.decks || []
    const deckName = decks.find((deck) => deck.externalId === cruiseItem.deckId)?.name || ''

    const currencyCode = state.geo.currentCurrency
    resetCruiseTimer()

    if (!state.cruise.multiBooking.cabinSelection[cruiseItemId]?.loading) {
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_BOOKING_CABIN_SELECTION,
        cruiseItemId,
        request: () => findBookingCabinSelection({ ...params, region, currencyCode, deckName }),
      })
    }
  }
}

export function fetchBookingCabinPricing(
  cruiseItem: App.Checkout.CruiseItem,
  params: Omit<CruiseAPI.CruiseCabinPricingBody, 'region' | 'currencyCode'>,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const cruiseItemId = cruiseItem.itemId
    const region = getCruiseRegion(state)
    const currencyCode = state.geo.currentCurrency

    if (!state.cruise.multiBooking.cabinPricing[cruiseItemId]?.loading) {
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_BOOKING_CABIN_PRICING,
        cruiseItemId,
        request: () => findBookingCabinPricing({ ...params, region, currencyCode }),
      })
    }
  }
}

export function fetchBookingCabinRelease(cruiseItem: App.Checkout.CruiseItem): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const { itemId: cruiseItemId, sessionId, bookingId, departureId } = cruiseItem
    const region = getCruiseRegion(state)

    if (!sessionId || !bookingId) return

    expireCruiseTimer()
    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_CABIN_RELEASE,
      cruiseItemId,
      request: () => cruiseBookingCabinRelease({
        sessionId,
        bookingId,
        region,
        departureId,
      }),
    })
  }
}

export function fetchCruiseShip(id: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.cruise.ships[id]) return

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_SHIP,
      request: () => findCruiseShip(id),
      shipId: id,
    })
  }
}

export function selectCruiseCabinItem(cruiseItem: App.Checkout.CruiseItem | null): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const cruiseItems = getCruiseItems(state)
    const { currentStepId } = getCurrentCruiseCheckoutPage(state.router.location.pathname, cruiseItems)
    let url = getCruiseCheckoutPath(currentStepId, state.checkout.cart.cartId, 'all')

    if (cruiseItem) {
      const cabinStepId = getCabinStepIdFromCruiseItem(cruiseItems, cruiseItem.itemId)
      url = getCruiseCheckoutPath(currentStepId, state.checkout.cart.cartId, cabinStepId)
    }

    dispatch(pushWithRegion(url))
  }
}

export function setBookingInterestInConnectedCabins(data: boolean): AppAction {
  return {
    type: SET_BOOKING_INTEREST_IN_CONNECTED_CABINS,
    data,
  }
}

export function fetchCruiseConsolidatedPaymentSchedule(bookingIds: Array<string>): AppAction {
  return {
    type: API_CALL,
    api: FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE,
    request: () => getConsolidatedPaymentSchedule(bookingIds),
  }
}

import React from 'react'

import config from 'constants/config'
import TextLink from 'components/Luxkit/TextLink'

interface Props extends React.ComponentProps<typeof TextLink> {}

export default function TermsAndConditionsLink(props: Props) {
  return <TextLink
    {...props}
    weight="regular"
    to={config.contentLinks.termsAndConditions.to}
    href={config.contentLinks.termsAndConditions.href}
    target="_blank"
    rel="noopener noreferrer"
  >
    Terms & Conditions
  </TextLink>
}

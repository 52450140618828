import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTennisBallIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a10.0001 10.0001 0 0 0-7.35 16.76l.09.1A10.0007 10.0007 0 0 0 12 21.9831a10.0009 10.0009 0 0 0 7.26-3.1231l.09-.1A10.0002 10.0002 0 0 0 12 2ZM5.61 16.79a7.9301 7.9301 0 0 1 0-9.58 6 6 0 0 1 0 9.58ZM12 20a7.91 7.91 0 0 1-5-1.77A8.0002 8.0002 0 0 0 7 5.77a7.95 7.95 0 0 1 10 0A7.9992 7.9992 0 0 0 14.0187 12 8.0002 8.0002 0 0 0 17 18.23 7.91 7.91 0 0 1 12 20Zm6.39-3.21A6.0008 6.0008 0 0 1 16.0033 12 5.9994 5.9994 0 0 1 18.39 7.21a7.93 7.93 0 0 1 0 9.58Z"/>
  </SvgIcon>
}

export default LineTennisBallIcon

// getPackageFromOffer finds and returns the object for the package
// model from a given offer detail by the package's `idSalesforceExternal` and `numberOfNights`
export default function getPackageFromOffer(
  offerDetails: App.Offer,
  packageId: string,
  numberOfNights?: number,
) {
  return offerDetails.packages.find(
    pkg => pkg.id === packageId &&
    // tours do not have flexible nights
    (!numberOfNights || pkg.duration === numberOfNights),
  )
}

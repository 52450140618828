import React from 'react'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import LineExclamationTriangleIcon from 'components/Luxkit/Icons/line/LineExclamationTriangleIcon'
import Group from 'components/utils/Group'
import styled from 'styled-components'

const WarningDescription = styled(Group)`
  text-align: start;
`

function LuxPlusWarningMessageBanner() {
  return <PageBanner
    kind="warning"
    align="center"
    description={<WarningDescription direction="horizontal" verticalAlign="center" gap={8}>
      <LineExclamationTriangleIcon />
      We are currently experiencing issues with your{' '}
      {LUXURY_PLUS.PROGRAM_NAME} membership. Our team is on it. Please check back later.
    </WarningDescription>}
  >
  </PageBanner>
}

export default LuxPlusWarningMessageBanner

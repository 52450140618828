import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import React, { ChangeEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import BusinessTravellerMultiSelectContent from './BusinessTravellerMultiSelectContent'
import { selectSelectedTravellerEmployees, selectTravellerCount } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { resetCheckoutForm } from 'actions/CheckoutActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import setSelectedBusinessTravellersAndGuests from 'actions/businessTraveller/setSelectedBusinessTravellerEmployeesAndGuests'
import { connect } from 'react-redux'
import { clearFormStateSnapshot } from 'storage/checkout'
import { saveBusinessTravellerSelectedTravellerState } from 'businessTraveller/storage/businessTravellerStorage'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import { getBusinessTravellerSelectEnabled } from 'businessTraveller/utils/getBusinessTravellerSelectEnabled'

interface MappedStateProps {
  travellerEmployees: Array<BusinessTraveller.EmployeeFromMeEndpoint>
  selectedTravellerEmployeeIds?: Array<string>
  selectedTravellerGuestCount?: number
  selectedTravellerCount: number
  businessAccount?: BusinessTraveller.CurrentBusinessAccount
}

function BusinessTravellerSelectModal(props: MappedStateProps) {
  const { selectedTravellerCount, selectedTravellerEmployeeIds, selectedTravellerGuestCount, businessAccount } = props
  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const [travellerSelectState, setTravellerSelectState] = useState<{selectedTravellers: Array<string>, guestCount: number}>({
    selectedTravellers: props.selectedTravellerEmployeeIds ?? [],
    guestCount: props.selectedTravellerGuestCount ?? 0,
  })

  const appDispatch = useAppDispatch()
  const flightsDispatch = useContext(FlightSearchWidgetDispatchContext)
  const handleSelectTravellerApply = useCallback(() => {
    if (travellerSelectState.selectedTravellers.length === 0 && travellerSelectState.guestCount === 0) {
      showSnackbar('Please select at least one traveller or guest.', 'warning')
      return
    }
    // Because we are now saving the traveller form, we need to make sure its cleared when a new traveller is selected.
    appDispatch(resetCheckoutForm())
    clearFormStateSnapshot()
    // Update employee list and guest count in redux state
    appDispatch(setSelectedBusinessTravellersAndGuests(travellerSelectState.selectedTravellers, travellerSelectState.guestCount))
    // Update App.Occupants in GlobalSearchContext
    const occupants: App.Occupants = {
      adults: selectedTravellerCount,
      children: 0,
      infants: 0,
      childrenAge: [],
    }
    flightsDispatch({ type: FlightSearchWidgetActions.SET_OCCUPANTS, occupants })
    // Save to localstorage so persist on page refresh
    saveBusinessTravellerSelectedTravellerState(travellerSelectState.selectedTravellers, travellerSelectState.guestCount)
  }, [appDispatch, flightsDispatch, travellerSelectState, selectedTravellerCount])

  const modalElementContext = useModalElementContext()

  const dismiss = useCallback(() => {
    modalElementContext.resolve()
    handleSelectTravellerApply()
  }, [modalElementContext, handleSelectTravellerApply])

  // Quick fix to keep occupants in sync with the selected travellers when the component mounts
  useEffect(() => {
    flightsDispatch({
      type: FlightSearchWidgetActions.SET_OCCUPANTS,
      occupants: { // use redux state passed in props, not the local state
        adults: (selectedTravellerEmployeeIds ?? []).length + (selectedTravellerGuestCount ?? 0) || 1,
        children: 0,
        infants: 0,
        childrenAge: [],
      },
    })
  }, [flightsDispatch, selectedTravellerEmployeeIds, selectedTravellerGuestCount])

  useEffect(() => {
    // Set a default traveller, only for users that are travellers, not admins or managers (OPERX-263)
    const businessTravellerSelectEnabled = getBusinessTravellerSelectEnabled(businessAccount)
    if (!businessTravellerSelectEnabled && businessAccount?.employee?.id && !selectedTravellerEmployeeIds?.length && !selectedTravellerGuestCount) {
      saveBusinessTravellerSelectedTravellerState([businessAccount.employee.id])
      setTravellerSelectState({
        selectedTravellers: [businessAccount.employee.id],
        guestCount: 0,
      })
    }
  }, [selectedTravellerEmployeeIds, selectedTravellerGuestCount, businessAccount?.employee?.id, businessAccount])

  return <Modal
    mode="drawer"
    title="Who is going?"
    headerExtension={<TextInputOnly
      value={searchTerm}
      type="text"
      placeholder="Search traveller name"
      onChange={handleSearchTermChange}
      endIcon={<LineSearchIcon />}
      noValidationSpacing
    />}
    primaryActionText="Continue"
    onPrimaryActionClick={dismiss}
  >
    <BusinessTravellerMultiSelectContent
      searchTerm={searchTerm}
      travellerSelectState={travellerSelectState}
      setTravellerSelectState={setTravellerSelectState}
    />
  </Modal>
}

export default connect<MappedStateProps, {}, {}, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
    selectedTravellerEmployeeIds: state.businessTraveller.selectedTravellerEmployeeIds,
    selectedTravellerGuestCount: state.businessTraveller.selectedTravellerGuestCount,
    businessAccount: state.businessTraveller.currentBusinessAccount,
    selectedTravellerCount: selectTravellerCount(state),
  }
})(BusinessTravellerSelectModal)

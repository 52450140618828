import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAngleRightBIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.54 11.29 9.88 5.64a.9998.9998 0 0 0-1.42 0 1 1 0 0 0 0 1.41l4.95 5L8.46 17a1.0002 1.0002 0 0 0 0 1.41 1 1 0 0 0 .71.3.999.999 0 0 0 .71-.3l5.66-5.65a.9995.9995 0 0 0 .3219-.735 1 1 0 0 0-.3219-.735Z"/>
  </SvgIcon>
}

export default LineAngleRightBIcon

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import TextInput from 'components/Common/Form/Input/TextInput'
import InputText from 'components/Luxkit/Typography/InputText'
import { MAX_TRIP_NAME_CHARS } from 'tripPlanner/config'

const CharCount = styled(InputText)`
  position: absolute;
  bottom: -4px;
  transform: translateY(100%);
  right: 0px;
`

interface Props {
  value: string
  onChange: (value: string) => void
  label?: string
  autoSelect?: boolean
  autoFocus?: boolean
  disabled?: boolean
  'data-testid'?: string
}

function TripNameField({
  label,
  value,
  onChange,
  autoFocus,
  autoSelect,
  disabled,
  'data-testid': testId,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (inputRef.current) {
      if (autoSelect) {
        inputRef.current.select()
      } else if (autoFocus) {
        inputRef.current.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextInput
      label={label}
      required
      ref={inputRef}
      onChange={(e) => {
        onChange(e.currentTarget.value)
        if (errorMsg) {
          setErrorMsg('')
        }
      }}
      manualError
      invalidErrorMessage={errorMsg}
      maxLength={MAX_TRIP_NAME_CHARS}
      data-testid={testId}
      value={value}
      disabled={disabled}
    >
      <CharCount variant="caption" colour="neutral-three">
        {value.length}/{MAX_TRIP_NAME_CHARS}
      </CharCount>
    </TextInput>
  )
}

export default TripNameField

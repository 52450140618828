import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineDelayIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 5.0027h-1a1 1 0 1 1 0-2h1v-1a1.0002 1.0002 0 0 1 1.7071-.7071.9997.9997 0 0 1 .2929.7071v1h1a1 1 0 1 1 0 2h-1v1a.9997.9997 0 0 1-1 1 1 1 0 0 1-1-1v-1Z"/>
    <path fill="currentColor" d="M21.2402 9.1538a.9993.9993 0 0 1 .3598-.151 1 1 0 0 1 1.18.8c.1484.7238.2221 1.461.22 2.1999A11.0001 11.0001 0 0 1 9.854 22.7913a10.9995 10.9995 0 0 1-8.6426-8.6426A11 11 0 0 1 12 1.0027a10.7998 10.7998 0 0 1 2.22.18 1.0197 1.0197 0 1 1-.4 2 9 9 0 1 0 7 7 .9993.9993 0 0 1 .1456-.7516.9989.9989 0 0 1 .2746-.2773Z"/>
    <path fill="currentColor" d="M12 6a1 1 0 0 0-1 1v4.4201l-2.1 1.21a.9995.9995 0 0 0-.473 1.1283 1 1 0 0 0 .973.7417 1.001 1.001 0 0 0 .5-.13l2.6-1.5.09-.09.16-.13a.863.863 0 0 0 .1-.16.891.891 0 0 0 .08-.17.6524.6524 0 0 0 .05-.2l.02-.12v-5a1.0002 1.0002 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineDelayIcon

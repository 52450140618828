import type { SelfDescribingJson } from '@snowplow/browser-tracker'

export function appViewMessageHandler(handler: string, dataToSend: any, analyticsContexts?: Array<SelfDescribingJson>) {
  // iOS JS bridge
  if (typeof window.webkit?.messageHandlers?.[handler]?.postMessage === 'function') {
    const message = JSON.stringify(dataToSend)
    window.webkit.messageHandlers[handler].postMessage({
      message,
      analyticsContexts,
    })
  }
  // Android JS bridge
  else if (typeof window.appInterface?.postMessage === 'function') {
    let data = dataToSend

    // Android <10.8.2 have a bug which requires `dataToSend` be in certain structure.
    // This is a workaround to prevent such issue.
    // Also, this event does NOT require additional data.
    if (dataToSend === 'doNativeAuthenticate') {
      data = undefined
    }

    const message = JSON.stringify({
      event: handler,
      dataToSend: data,
      analyticsContexts,
    })
    window.appInterface.postMessage(message)

    // Backwards compatibility for Android
    // Need to send this message as a string for users who never upgraded to newest versions
    if (handler === 'authenticateUser') {
      window.appInterface.postMessage(dataToSend)
    }
  }
}

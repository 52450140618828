import { css } from 'styled-components'

export default css`
.prevent-double-scroll {
    overflow: hidden;
    padding-right: var(--scrollbar-width);
    @supports (-webkit-overflow-scrolling: touch) {
      width: 100%;
      height: 100vh;
    }
    /* This is to prevent double scroll when modal is open */
    /* if modal breaks apply changes from here https://github.com/reactjs/react-modal/issues/191#issuecomment-302172285 */
  }
`

import React, { useRef } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LayoutContainer from 'components/Common/LayoutContainer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import config from 'constants/config'
import Logo from 'components/App/Header/Logo'
import { mediaQueryUp } from 'components/utils/breakpoint'
import getStaticPagesByType from 'lib/utils/getStaticPagesByType'
import TextLink from 'components/Luxkit/TextLink'
import { useHeaderStateObserver } from 'contexts/HeaderStateContext'
import Group from 'components/utils/Group'

const Root = styled.div`
  border-top: 1px solid ${props => props.theme.palette.neutral.default.six};
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.tablet} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }
`

const Content = styled(LayoutContainer)`
  @media print {
    a[href]::after {
      content: none !important;
    }
  }
`

const FooterLogo = styled(Logo)`
  width: ${rem(144)};
  height: unset;
`

const pageTypes = [
  'privacyPolicy',
  'termsAndConditions',
  'refundPolicy',
]

function MinimalFooter() {
  const currentYear = new Date().getFullYear()

  const helpItems = getStaticPagesByType(pageTypes, true)
    // Streamline the T&Cs label
    .map(item => (item.type === 'termsAndConditions') ? { ...item, text: 'Terms & Conditions' } : item)

  const footerDiv = useRef<HTMLDivElement>(null)
  useHeaderStateObserver('tripPlannerFooter', footerDiv)

  return <Root ref={footerDiv}>
    <Content size="xl">
      <Group
        direction="vertical"
        desktopDirection="horizontal"
        horizontalAlign="center"
        desktopHorizontalAlign="start"
        verticalAlign="center"
        gap={40}
      >
        <FooterLogo />
        <Group direction="vertical" desktopDirection="horizontal" desktopHorizontalAlign="space-between" gap={8}>
          <BodyText variant="medium">Copyright © 2017 - {currentYear} {config.title}.</BodyText>
          <Group direction="horizontal" gap={12} wrap="wrap" horizontalAlign="center">
            {helpItems.map(item => <TextLink
              key={item.text}
              variant="black"
              size="medium"
              weight="regular"
              to={item.url.to}
              href={item.url.href}
              target="_blank"
            >
              {item.text}
            </TextLink>)}
          </Group>
        </Group>
      </Group>
    </Content>
  </Root>
}

export default MinimalFooter

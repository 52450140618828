import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAwardIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m20.87 17.25-2.71-4.68A6.8987 6.8987 0 0 0 19 9.25a7.0002 7.0002 0 0 0-7-7 7 7 0 0 0-7 7 6.9 6.9 0 0 0 .84 3.32l-2.71 4.68a1 1 0 0 0 .87 1.5h2.87l1.46 2.46a1.001 1.001 0 0 0 .18.22.9998.9998 0 0 0 .69.28h.14a.9996.9996 0 0 0 .73-.49L12 17.9l1.93 3.35a1 1 0 0 0 .73.48h.14a.9999.9999 0 0 0 .7-.28.8694.8694 0 0 0 .17-.21l1.46-2.46H20a1.0003 1.0003 0 0 0 .87-.5.9995.9995 0 0 0 0-1.03ZM9.19 18.78l-.89-1.49a1 1 0 0 0-.85-.49H5.72l1.43-2.48a7.0002 7.0002 0 0 0 3.57 1.84l-1.53 2.62ZM12 14.25a5.0001 5.0001 0 0 1-.9754-9.904A4.9998 4.9998 0 0 1 17 9.25a4.9997 4.9997 0 0 1-5 5Zm4.55 2.55a1.0009 1.0009 0 0 0-.85.49l-.89 1.49-1.52-2.65a7.0599 7.0599 0 0 0 3.56-1.84l1.43 2.48-1.73.03Z"/>
  </SvgIcon>
}

export default LineAwardIcon

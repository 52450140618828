import { Interceptor } from 'api/requestUtils'

export default function unauthorisedInterceptor(actionDispatch: () => void): Interceptor {
  return (f, response) => f.catch(error => {
    if (response.status === 401) {
      actionDispatch()
    }
    throw error
  })
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineReceiptIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9 12H7a1.0002 1.0002 0 0 0-.7071 1.7071C6.4804 13.8946 6.7348 14 7 14h2a1.0001 1.0001 0 0 0 .7071-1.7071A1.0001 1.0001 0 0 0 9.0001 12Zm-1-2h4a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2Zm1 6H7a1.0002 1.0002 0 0 0-.7071 1.7071C6.4804 17.8946 6.7348 18 7 18h2a1.0001 1.0001 0 0 0 .7071-1.7071A1.0001 1.0001 0 0 0 9.0001 16Zm12-4h-3V3a1 1 0 0 0-1.5-.87l-3 1.72-3-1.72a1 1 0 0 0-1 0l-3 1.72-3-1.72A1 1 0 0 0 2 3v16a2.9998 2.9998 0 0 0 3 3h14a3 3 0 0 0 3-3v-6a.9997.9997 0 0 0-1-1ZM5 20a1.0002 1.0002 0 0 1-1-1V4.73l2 1.14a1.08 1.08 0 0 0 1 0l3-1.72 3 1.72a1.08 1.08 0 0 0 1 0l2-1.14V19c.0027.3411.0636.6793.18 1H5Zm15-1a.9997.9997 0 0 1-1 1 1.0001 1.0001 0 0 1-1-1v-5h2v5Zm-6.44-2.83a.7618.7618 0 0 0-.18-.09.602.602 0 0 0-.19-.06.9995.9995 0 0 0-.9.27A1.0501 1.0501 0 0 0 12 17c-.0016.13.0222.2591.07.38.0544.1215.1287.2331.22.33.0972.0883.2088.1594.33.21a.94.94 0 0 0 .76 0 1.1495 1.1495 0 0 0 .33-.21A.9993.9993 0 0 0 14 17a1.0501 1.0501 0 0 0-.29-.71 1.5735 1.5735 0 0 0-.15-.12Zm.14-3.88a1.0006 1.0006 0 0 0-.8973-.2635 1.0007 1.0007 0 0 0-.7227.5935 1.0007 1.0007 0 0 0 .0929.9347A1 1 0 0 0 13 14a1.0001 1.0001 0 0 0 1-1 1.001 1.001 0 0 0-.08-.38.9107.9107 0 0 0-.22-.33Z"/>
  </SvgIcon>
}

export default LineReceiptIcon

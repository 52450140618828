import { useMemo } from 'react'
import { findCheapestOfTourV2Offer } from 'lib/tours/tourUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'

type CheapestOfTourV2Offer = ReturnType<typeof findCheapestOfTourV2Offer>

function useCheapestOfTourV2Offer(
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary,
  selectedVariationId?: Tours.TourV2OfferVariation['id'],
): CheapestOfTourV2Offer
function useCheapestOfTourV2Offer(
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary | undefined,
  selectedVariationId?: Tours.TourV2OfferVariation['id'],
): CheapestOfTourV2Offer | undefined
function useCheapestOfTourV2Offer(
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary | undefined,
  selectedVariationId?: Tours.TourV2OfferVariation['id'],
): CheapestOfTourV2Offer | undefined {
  const isLuxPlusToursAvailable = useAppSelector((state) => isLuxPlusToursEnabled(state))
  return useMemo(() =>
    offer ?
      findCheapestOfTourV2Offer(offer, selectedVariationId, isLuxPlusToursAvailable) :
      undefined,
  [offer, selectedVariationId, isLuxPlusToursAvailable])
}

export default useCheapestOfTourV2Offer

export const experienceLocationCookieKey = 'experienceLocation'

export function setExperienceLocationCookie(
  placeType: App.ExperiencePlaceType,
  placeId: string = '',
  userSelected: boolean = false,
) {
  document.cookie = `${experienceLocationCookieKey}=${placeType}:${placeId}:${userSelected}; expires=Fri, 29 Dec 2028 23:59:59 GMT; path=/; domain=${document.domain}`
}

export function clearExperienceLocationCookie() {
  document.cookie = `${experienceLocationCookieKey}=''; expires=Wed, 1 Jan 2020 23:59:59 GMT; path=/; domain=${document.domain}`
}

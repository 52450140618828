import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import TextButton from 'components/Luxkit/Button/TextButton'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import BedbankTwinShare from 'components/OfferList/OfferListTiles/Support/BedbankTwinShare'
import BedbankMarginText from 'components/OfferPage/Bedbank/BedbankMargin'
import config from 'constants/config'
import { useAppSelector } from 'hooks/reduxHooks'
import useBedbankDiscount from 'hooks/useBedbankDiscount'
import useBedbankOfferSearchPricing from 'hooks/useBedbankOfferSearchPricing'
import { EmptyObject } from 'lib/object/objectUtils'
import { getHotelOfferUrl } from 'lib/offer/offerPageURL'
import { buildSuggestedDatesString } from 'lib/search/searchUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import React, { useContext, useEffect, useMemo } from 'react'
import SearchTileWalledMessage from '../shared/SearchTileWalledMessage'
import BedbankSearchTileWalledContent from './BedbankSearchTileWalledContent'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  filters?: App.OfferListFilters
}

function BedbankSearchTilePriceStack({
  offer,
  filters = EmptyObject,
}: Props) {
  const {
    bundleMemberPrice,
    bundlePrice,
    bundlePropertyFees,
    bundleTaxesAndFees,
    discountPercentage,
    displayMargin,
    flightPrice,
    hotelMemberPrice,
    hotelNights,
    hotelPrice,
    hotelRate,
    isFlexibleSearch,
    margin,
    marginAud,
    nightsToDisplay,
    offerMemberPrice,
    offerPrice,
    promoteAsBundle,
    propertyFees,
    rooms,
    saleUnit,
    shownRate,
    suggestedDates,
    supplier,
    taxesAndFees,
    totalPrices,
    value,
  } = useBedbankOfferSearchPricing(offer, filters)

  const luxPlusLPPEnabled = useAppSelector(getIsLuxPlusLPPEnabled)

  const offerLocations: Array<string> = useMemo(() => {
    const locations = [
      offer.property?.address.city,
      offer.property?.address.stateProvinceName || '',
      offer.property?.address.countryName]
      .filter(Boolean)

    return locations
  }, [offer])

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const offerUrl = useMemo(() => getHotelOfferUrl(offer.id, offer.type), [offer])

  useEffect(() => {
    dispatchOfferListEvent({
      type: OfferListEvents.pricingCalculated,
      leadPrice: offerPrice,
      duration: hotelNights ?? 0,
    })
  }, [dispatchOfferListEvent, offerPrice, hotelNights])

  const discount = useBedbankDiscount(hotelRate?.discount, hotelRate?.memberDiscount)

  const directSearchPrices = useDirectSearchPrices({ filters, offerId: offer.id })

  return <BedbankSearchTileWalledContent
    wall={<SearchTileWalledMessage discountPercentage={discount} />}
    offer={offer}
    filters={filters}
  >
    {!!offerPrice && <>
      <PriceRowPriceCaption>
        {isFlexibleSearch && buildSuggestedDatesString(suggestedDates.checkIn, suggestedDates.checkOut)}
        {!isFlexibleSearch && `${pluralizeToString('night', nightsToDisplay)}`}
        {promoteAsBundle && ' + flights'}
        {' '}from
      </PriceRowPriceCaption>
      <LuxPlusPriceStack
        size="L"
        price={offerPrice}
        memberPrice={offerMemberPrice}
        saleUnit={saleUnit}
      />
      {offer.displayPricingAsPerPerson && <BedbankTwinShare rooms={rooms} />}
      {discountPercentage >= 5 && <PriceRowValueDiscountWithCaption
        size="M"
        originalValue={value}
        discountPercentage={discountPercentage}
      />}
    </>}

    {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
      type="estimate"
      offer={offer}
      totalCost={promoteAsBundle ? totalPrices.original.flightBundlePrice : totalPrices.original.price}
      numberOfNights={hotelNights}
    />}

    <PriceRowAgentHubCommission
      size="L"
      productType={offer.productType}
      offerId={offer.id}
      offerLocations={offerLocations}
    />

    {displayMargin && <BedbankMarginText
      margin={margin!}
      marginAud={marginAud!}
      supplier={supplier!}
      type="bedbank_estimate"
    />}
    {(directSearchPrices || shownRate && hotelNights) && (<OfferPriceDetailsRow
      trigger="price-row"
      triggerSize="M"
      offer={offer}
      rooms={rooms}
      propertyFees={promoteAsBundle ? bundlePropertyFees : propertyFees}
      taxesAndFees={promoteAsBundle ? bundleTaxesAndFees : taxesAndFees}
      hotelPrice={promoteAsBundle ? bundlePrice : hotelPrice}
      flightPrice={promoteAsBundle ? flightPrice : undefined}
      hotelMemberPrice={promoteAsBundle ? bundleMemberPrice : hotelMemberPrice}
      showMemberPrice={luxPlusLPPEnabled && hotelMemberPrice > 0}
      duration={hotelNights}
      cta={<TextButton kind="primary" fit="flex" to={offerUrl}>
        View Offer
      </TextButton>}
      context="search"
      hasMobilePromotion={shownRate?.mobilePromotion}
    />)}
  </BedbankSearchTileWalledContent>
}

export default BedbankSearchTilePriceStack

import { Matches } from 'components/Common/Breakpoint/responsiveHelper'
import { skip, take } from 'lib/array/arrayUtils'
import { breakpointSizes } from 'components/utils/breakpoint'
import { objectKeys } from 'lib/object/objectUtils'

// Returns a list of all breakpoints from order of xs -> xl
const allBreakpoints = objectKeys(breakpointSizes)

export function getCustomBreakpointName(customMin?: number, customMax?: number) {
  let name = ''
  if (customMin) {
    name += `min-${customMin}`
  }
  if (customMax) {
    name += `max-${customMin}`
  }
  return name
}

export function checkOnlyBreakpoint(nextMatches: Matches, match: App.ScreenSize) {
  return !!nextMatches[match]
}

export function checkNotBreakpoint(nextMatches: Matches, match: App.ScreenSize) {
  return !nextMatches[match]
}

export function checkMinBreakpoint(nextMatches: Matches, match: App.ScreenSize) {
  // minimum requires from value given => last
  const toCheck = skip(allBreakpoints, allBreakpoints.indexOf(match))
  return toCheck.some(bp => nextMatches[bp])
}

export function checkMaxBreakpoint(nextMatches: Matches, match: App.ScreenSize) {
  const toCheck = take(allBreakpoints, allBreakpoints.indexOf(match) + 1)
  return toCheck.some(bp => nextMatches[bp])
}

export function checkCustomBreakpoint(nextMatches: Matches & { [key: string]: boolean }, customMin?: number, customMax?: number) {
  const queryName = getCustomBreakpointName(customMin, customMax)
  return !!nextMatches[queryName]
}

import { useEffect, useMemo } from 'react'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import { getHotelsTrendingDestinations, getIsFetchingFeaturedDestinations } from 'selectors/destinationSelectors'
import GlobalSearchState, { GlobalSearchStateActions, GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { FLEXIBLE_DURATION_RANGE, SEARCH_VERTICALS } from 'constants/search'
import { fetchTrendingDestinations } from 'actions/DestinationAlertsActions'
import { useGlobalSearchResultsTracking } from '../useGlobalSearchTracking'
import { SearchResultBucket } from 'api/interactionStudio'
import { detectOfferTypeFromId } from 'lib/offer/offerUtils'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { buildDestinationSearchParamsKey } from 'lib/search/searchUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { uniqueBy } from 'lib/array/arrayUtils'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { getCheckInDateFromURLSearchParams, getCheckOutDateFromURLSearchParams, getDataSearchOptionIdFromURLSearchParams, getFlexibleMonthsFromURLSearchParams, getFlexibleNightsFromURLSearchParams, getOccupanciesFromURLSearchParams, getSearchItemFromURLSearchParams, getUserSelectedFlexibleMonthsFromURLSearchParams } from 'lib/url/searchUrlUtils'
import { getRecentSearches, isRecentSearchWithinTimeLimit } from 'components/Search/utils'
import moment from 'moment'
import config from 'constants/config'

export function useGlobalHotelsSearchContext(
  initialState: GlobalSearchState = GLOBAL_SEARCH_INITIAL_STATE,
  syncWithURLSearchParams?: URLSearchParams,
) {
  const queryParams = useMemo(() => {
    if (syncWithURLSearchParams && syncWithURLSearchParams.size > 0) {
      return {
        flexibleNights: getFlexibleNightsFromURLSearchParams(syncWithURLSearchParams),
        flexibleMonths: getFlexibleMonthsFromURLSearchParams(syncWithURLSearchParams),
        dateSearchOptionId: getDataSearchOptionIdFromURLSearchParams(syncWithURLSearchParams),
        checkinDate: getCheckInDateFromURLSearchParams(syncWithURLSearchParams),
        checkoutDate: getCheckOutDateFromURLSearchParams(syncWithURLSearchParams),
        occupancies: getOccupanciesFromURLSearchParams(syncWithURLSearchParams),
        searchItem: getSearchItemFromURLSearchParams(syncWithURLSearchParams),
        userSelectedFlexibleMonths: getUserSelectedFlexibleMonthsFromURLSearchParams(syncWithURLSearchParams),
      }
    }
    return undefined
  }, [syncWithURLSearchParams])

  const memberId = useAppSelector(state => state.auth.account.memberId)

  const recentSearches = getRecentSearches(memberId)
  const recentSearchPrefill = useMemo((): Partial<GlobalSearchState> => {
    if (recentSearches.length === 0) {
      return {}
    }

    const [lastSearch] = recentSearches
    const isWithinPast14Days = isRecentSearchWithinTimeLimit(lastSearch.dateAdded, 14, 'days')
    if (!isWithinPast14Days) {
      return {}
    }

    // it's difficult, but if a customer somehow makes a search with 0 adults, they can get stuck having 0 adults prefilled
    const prefillOccupancy = lastSearch?.rooms[0]?.adults ? lastSearch.rooms : [config.search.defaultOccupants]

    if (lastSearch.dates.checkIn && lastSearch.dates.checkOut) {
      return {
        checkinDate: moment(lastSearch.dates.checkIn),
        checkoutDate: moment(lastSearch.dates.checkOut),
        searchItem: lastSearch.searchItem,
        occupancies: prefillOccupancy,
      }
    }

    if (lastSearch.dates.flexibleMonths && lastSearch.dates.flexibleDuration) {
      return {
        flexibleMonths: lastSearch.dates.flexibleMonths,
        flexibleNights: lastSearch.dates.flexibleDuration as FLEXIBLE_DURATION_RANGE,
        userSelectedFlexibleMonths: true,
        searchItem: lastSearch.searchItem,
        occupancies: prefillOccupancy,
      }
    }

    return {
      searchItem: lastSearch.searchItem,
      occupancies: prefillOccupancy,
    }
  }, [recentSearches])

  const searchState = useMemo(() => {
    if (syncWithURLSearchParams) {
      return {
        ...initialState,
        searchVerticals: new Set([SEARCH_VERTICALS.HOTELS]),
      }
    } else {
      return {
        ...initialState,
        ...recentSearchPrefill,
        searchVerticals: new Set([SEARCH_VERTICALS.HOTELS]),
      }
    }
  }, [initialState, recentSearchPrefill, syncWithURLSearchParams])

  const { globalSearchState, globalSearchDispatch } = useGlobalSearchContext(searchState)

  // sync check in date
  useEffect(() => {
    if (queryParams) {
      const { checkinDate } = queryParams
      if (checkinDate) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: checkinDate })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKIN_DATE })
      }
    } else if (recentSearchPrefill?.checkinDate) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: recentSearchPrefill?.checkinDate })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.checkinDate])

  // sync check out date
  useEffect(() => {
    if (queryParams) {
      const { checkoutDate } = queryParams
      if (checkoutDate) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: checkoutDate })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE })
      }
    } else if (recentSearchPrefill?.checkoutDate) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: recentSearchPrefill?.checkoutDate })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.checkoutDate])

  // sync occupancies
  useEffect(() => {
    if (queryParams) {
      const { occupancies } = queryParams
      if (occupancies?.length) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_OCCUPANCIES })
      }
    } else if (recentSearchPrefill?.occupancies) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies: recentSearchPrefill?.occupancies })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.occupancies])

  // sync search item
  useEffect(() => {
    if (queryParams) {
      const { searchItem } = queryParams
      if (searchItem) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_SEARCH_ITEM })
      }
    } else if (recentSearchPrefill?.searchItem) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem: recentSearchPrefill?.searchItem })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.searchItem])

  // sync flexible months
  useEffect(() => {
    if (queryParams) {
      const { flexibleMonths } = queryParams
      if (flexibleMonths) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_MONTH_RANGE })
      }
    } else if (recentSearchPrefill?.flexibleMonths) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: recentSearchPrefill?.flexibleMonths })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.flexibleMonths])

  // sync flexible nights
  useEffect(() => {
    if (queryParams) {
      const { flexibleNights } = queryParams
      if (flexibleNights) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION, flexibleNights: flexibleNights as FLEXIBLE_DURATION_RANGE })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_DURATION })
      }
    } else if (recentSearchPrefill?.flexibleNights) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION, flexibleNights: recentSearchPrefill?.flexibleNights })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.flexibleNights])

  // // sync dateSearchOptionId
  useEffect(() => {
    if (queryParams) {
      const { dateSearchOptionId } = queryParams
      if (dateSearchOptionId) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: dateSearchOptionId })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.dateSearchOptionId])

  // sync userSelectedFlexibleMonths
  useEffect(() => {
    if (queryParams) {
      const { userSelectedFlexibleMonths } = queryParams
      if (userSelectedFlexibleMonths !== undefined) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS, userSelectedFlexibleMonths })
      }
      else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS })
      }
    }
    else if (recentSearchPrefill?.userSelectedFlexibleMonths) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS, userSelectedFlexibleMonths: recentSearchPrefill?.userSelectedFlexibleMonths })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.userSelectedFlexibleMonths])

  const dispatch = useAppDispatch()

  const isFetchingFeaturedDestinations = useAppSelector(getIsFetchingFeaturedDestinations)
  const hotelsPopularDestinations = useAppSelector(getHotelsTrendingDestinations)

  useEffect(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.UNPOPULATE_POPULAR_DESTINATIONS })
    dispatch(fetchTrendingDestinations())
  }, [dispatch, globalSearchDispatch])

  useEffect(() => {
    if (!isFetchingFeaturedDestinations) {
      globalSearchDispatch({
        type: GlobalSearchStateActions.POPULATE_POPULAR_DESTINATIONS,
        popularDestinations: hotelsPopularDestinations,
      })
    }
  }, [isFetchingFeaturedDestinations, hotelsPopularDestinations, globalSearchDispatch])

  return {
    globalHotelsSearchDispatch: globalSearchDispatch,
    globalHotelsSearchState: globalSearchState,
  }
}

export function useGlobalHotelsSearchResultsTracking(
  globalHotelsSearchState: GlobalSearchState,
  offerLists: Array<App.OfferList>,
  offerListKey: string,
  nearbyOfferLists: Array<App.OfferList> = [],
) {
  const otherAvailabilityKey = useMemo(() => buildDestinationSearchParamsKey(
    globalHotelsSearchState.searchItem?.value,
    globalHotelsSearchState.checkinDate?.format(ISO_DATE_FORMAT),
    globalHotelsSearchState.checkoutDate?.format(ISO_DATE_FORMAT),
    globalHotelsSearchState.occupancies)
  , [globalHotelsSearchState])

  const numberOfResultIgnoreAvailability = useAppSelector(state => {
    const allOffers = state.offer.searchResultMetadata.offerAvailabilityFromSearchTarget[otherAvailabilityKey] ?? {}

    return Object.keys(allOffers).length
  })

  const searchResults = useMemo(() => {
    const mainListResults = offerLists.flatMap(list => list.offerIds).map(id => {
      const type = detectOfferTypeFromId(id)
      if (type === OFFER_TYPE_BED_BANK) {
        return { id, kind: SearchResultBucket.BEDBANK_HOTEL }
      } else {
        return { id, kind: SearchResultBucket.LE_HOTEL }
      }
    })
    const nearbyResults = nearbyOfferLists.flatMap(list => list.offerIds).map(id => {
      const type = detectOfferTypeFromId(id)
      if (type === OFFER_TYPE_BED_BANK) {
        return { id, kind: SearchResultBucket.BEDBANK_HOTEL_NEARBY }
      } else {
        return { id, kind: SearchResultBucket.LE_HOTEL_NEARBY }
      }
    })

    // prioritise main results over nearby, hence it's first
    return uniqueBy(mainListResults.concat(nearbyResults), res => res.id)
  }, [offerLists, nearbyOfferLists])

  useGlobalSearchResultsTracking(
    globalHotelsSearchState,
    offerListKey,
    searchResults,
    offerLists.some(list => list.fetching) || nearbyOfferLists.some(list => list.fetching),
    numberOfResultIgnoreAvailability,
  )
}

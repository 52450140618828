import { css } from 'styled-components'

const Print = css`
  @media screen {
    #printModal {
      display: none;
    }
  }

  @media print {
    .hidden-print {
      display: none !important;
    }

    *,
    *:before,
    *:after {
      color: #000 !important; // Black prints faster: h5bp.com/s
      text-shadow: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    abbr[title]:after {
      content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    .navbar {
      display: none;
    }
    .btn,
    .dropup > .btn {
      > .caret {
        border-top-color: #000 !important;
      }
    }
    .label {
      border: 1px solid #000;
    }

    .table {
      border-collapse: collapse !important;

      td,
      th {
        background-color: #fff !important;
      }
    }
    .table-bordered {
      th,
      td {
        border: 1px solid #ddd !important;
      }
    }

    body.hidden-print-modal > *:not(#printModal) {
      display: none !important;
    }

    #printModal, #printModal * {
      visibility: visible;
    }
    #printModal {
      position:absolute;
      left:0;
      top:0;
    }
  }
`

export default Print

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUltraLuxDiamondIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m22.1949 9.0442-3.3243-4.6548A.9309.9309 0 0 0 18.1139 4H5.7459a.9304.9304 0 0 0-.7362.3618L1.4189 9.0166a.9297.9297 0 0 0 .027 1.1695l9.8422 11.6154a.9299.9299 0 0 0 .7099.3289h.0096a.9301.9301 0 0 0 .7123-.3433l9.4408-11.6154a.9302.9302 0 0 0 .0348-1.1275h-.0006ZM11.9824 19.7439 3.3511 9.5572l2.852-3.697H17.635l2.6343 3.688L11.983 19.744h-.0006Z"/>
  </SvgIcon>
}

export default LineUltraLuxDiamondIcon

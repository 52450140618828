/** Get current element padding for each side */
export function getElementPadding(element: HTMLElement): {
  top: number
  right: number
  bottom: number
  left: number
} {
  const style = window.getComputedStyle(element)
  return {
    top: parseFloat(style.paddingTop),
    right: parseFloat(style.paddingRight),
    bottom: parseFloat(style.paddingBottom),
    left: parseFloat(style.paddingLeft),
  }
}

/** Gets the inner width of an element (excluding padding). Weirdly there's no built-in method for this. */
export function getElementContentWidth(element: HTMLElement): number {
  const padding = getElementPadding(element)
  return element.clientWidth - padding.left - padding.right
}

export type ScrollPosition = 'start' | 'middle' | 'end'

export function getHorizontalScrollPosition(element: HTMLElement): ScrollPosition {
  const scrollEnd = element.scrollWidth - element.offsetWidth
  // sometimes we end up with 0.5px scroll positions but have whole number scrollEnd values
  // particularly at the end of a carousel.
  // In this cases, we still want it to to be at the "end", so round it up
  const nextLeft = Math.ceil(element.scrollLeft)
  if (nextLeft <= 0) {
    return 'start'
  } else if (nextLeft >= scrollEnd) {
    return 'end'
  } else {
    return 'middle'
  }
}

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import Caption from '../../Luxkit/Typography/Caption'
import { endOfMonth } from 'lib/datetime/dateUtils'
import { fillArray } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import DatePickerDay from './DatePickerDay'

const CalendarContainer = styled.div`
  > * + * {
    margin-top: ${rem(4)};
  }
`

const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7 , 1fr);
  align-content: center;
  justify-content: center;
  text-align: center;

  &.fixed-height {
    grid-template-rows: repeat(6, 1fr);
  }
`

const WeekHeader = styled(Caption)`
  display: grid;
  grid-template-columns: repeat(7 , 1fr);
`

const EmptyDay = styled.div`
  min-height: ${rem(50)};
`

interface Props {
  className?: string;
  // month and year this calendar is for
  month: number;
  year: number;
  onDayClick?: (day: Date) => void;
  dayRender?: (day: Date) => React.ReactNode;
  fixedHeight?: boolean;
}

function BasicCalendar(props: Props) {
  const {
    className,
    month,
    year,
    dayRender,
    onDayClick,
    fixedHeight,
  } = props

  const [daysInMonth, emptyDays] = useMemo(() => {
    const monthDate = new Date(year, month)
    return [
      fillArray(endOfMonth(monthDate).getDate(), day => new Date(year, month, day + 1)),
      fillArray(monthDate.getDay()),
    ]
  }, [month, year])

  return (
    <CalendarContainer className={className}>
      <WeekHeader variant="medium" align="center" colour="neutral-three">
        <abbr title="Sunday">S</abbr>
        <abbr title="Monday">M</abbr>
        <abbr title="Tuesday">T</abbr>
        <abbr title="Wednesday">W</abbr>
        <abbr title="Thursday">T</abbr>
        <abbr title="Friday">F</abbr>
        <abbr title="Saturday">S</abbr>
      </WeekHeader>
      <Calendar className={cn({ 'fixed-height': fixedHeight })}>
        {emptyDays.map((val) => <EmptyDay key={val + 50}/>)}
        {daysInMonth.map((day) => dayRender?.(day) ?? <DatePickerDay key={day.getTime()} date={day} onClick={onDayClick} />)}
      </Calendar>
    </CalendarContainer>
  )
}

BasicCalendar.defaultProps = {
  onDayClick: noop,
  fixedHeight: true,
}

export default BasicCalendar

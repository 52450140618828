import React, { useCallback } from 'react'
import Group from 'components/utils/Group'
import moment from 'moment'
import { SHORT_DAY_NAME_DAY_MONTH } from 'constants/dateFormats'
import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import LineCheckCircleIcon from 'components/Luxkit/Icons/line/LineCheckCircleIcon'
import LinePriceTagAltIcon from 'components/Luxkit/Icons/line/LinePricetagAltIcon'
import Label from 'components/Luxkit/Label/Label'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import Caption from 'components/Luxkit/Typography/Caption'
import Arrow from 'components/utils/Arrows/Arrow'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const ItemContainer = styled.div`
  padding: ${rem(12)};
  cursor: pointer;

  ${mediaHoverable} {
    &:hover {
      background-color: ${props => props.theme.palette.neutral.default.seven};
    }
  }
`
const DepartureContent = styled.div`
  width: ${rem(95)};
`

interface Props {
  onClick: (departureDate: string | null) => void;
  departureDate: string | null;
  arrivalDate?: string;
  price?: number;
  isCheapest?: boolean;
  hasSelectedDates?: boolean;
}

function CruiseModalPricesItem({
  onClick,
  departureDate,
  arrivalDate,
  price,
  isCheapest,
  hasSelectedDates,
}: Props) {
  const handleClick = useCallback(() => {
    onClick(departureDate)
  }, [departureDate, onClick])

  const formattedDepartureDate = moment(departureDate).format(SHORT_DAY_NAME_DAY_MONTH)
  const formattedArrivalDate = arrivalDate && moment(arrivalDate).format(SHORT_DAY_NAME_DAY_MONTH)

  return <ItemContainer onClick={handleClick} data-testid="cruise-modal-price-item-click">
    <Group direction="horizontal" horizontalAlign="space-between">
      <Group direction="vertical" horizontalAlign="start" verticalAlign="center" gap={4}>
        <Group direction="horizontal" horizontalAlign="space-between" gap={8}>
          {isCheapest && <PromotionLabel
            variant="default"
            icon={<LinePriceTagAltIcon />}
          >Cheapest</PromotionLabel>}

          {hasSelectedDates && <Label
            kind="plain"
            variant="highlight-secondary"
            startIcon={<LineCheckCircleIcon />}
          >Selected dates</Label>}
        </Group>

        <Group direction="horizontal" verticalAlign="center">
          <DepartureContent>
            {formattedDepartureDate}
          </DepartureContent>

          {!!formattedArrivalDate && <Group direction="horizontal" verticalAlign="center" gap={12}>
            <LineArrowRightIcon/>
            {formattedArrivalDate}
          </Group>}
        </Group>
      </Group>
      <Group direction="horizontal" verticalAlign="center" horizontalAlign="end" gap={8}>
        {!!price && <Group direction="vertical" gap={4}>
          <Caption align="end" variant="medium">From</Caption>
          <PriceRowPrice
            size="S"
            price={price}
            saleUnit=""
          />
        </Group>}
        <Arrow/>
      </Group>
    </Group>
  </ItemContainer>
}

export default CruiseModalPricesItem

import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import React from 'react'

const termsAndConditionsText =
  `The savings percentage amounts are a comparison between the current lowest available fare and the current
  highest available fare on the same category, regardless of promotion. The savings percentage displayed reflects
  the highest available discount on any category on that sailing. Each fare level has its own terms and conditions.
  Prices displayed under the categories of inside, oceanview/outside, balcony & suite include all applicable discounts,
  savings & taxes and are per person prices based on twin occupancy in the cabin.`

function CruisesDiscountTsAndCsModal() {
  return <Modal title="Terms and Conditions">
    <BodyText variant="large">{termsAndConditionsText}</BodyText>
  </Modal>
}

export default CruisesDiscountTsAndCsModal

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFridgeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M8.1445 4.8184c.5523 0 1 .4477 1 1v.9483c0 .5523-.4477 1-1 1s-1-.4477-1-1v-.9483c0-.5523.4478-1 1-1ZM9.1445 12.4961c0-.5523-.4477-1-1-1s-1 .4477-1 1v.9483c0 .5523.4478 1 1 1 .5523 0 1-.4477 1-1v-.9483Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M7 2C5.3431 2 4 3.3431 4 5v13.1035c0 1.2856.8088 2.3824 1.9453 2.8093v.0877c0 .5523.4477 1 1 1 .5175 0 .9432-.3931.9948-.897h7.3933c.0515.5039.4772.897.9947.897.5236 0 .9531-.4023.9964-.9147 1.503-.1629 2.673-1.436 2.673-2.9823V9.6722a1.0185 1.0185 0 0 0 0-.1608V5c0-1.6568-1.3432-3-3-3H7Zm10.9975 6.5918V5c0-.5523-.4477-1-1-1H7c-.5523 0-1 .4477-1 1v3.5918h11.9975ZM6 10.5918v7.5117c0 .5522.4477 1 1 1h9.9975c.5523 0 1-.4478 1-1v-7.5117H6Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineFridgeIcon

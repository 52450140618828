import noop from 'lib/function/noop'

type WaitForValueState<T> = {
  resolve: (value: T) => void;
  pendingResolvers: Array<(value: T) => void>;
}

/**
 * Creates an object that allows waiting for a value to be resolved.
 *
 * **NB Current implementation of the interface doesn't fit 100% and can be improved upon but ran out of time**
 *
 * @template T - The type of the value to wait for.
 * @returns {{
 *   awaitForValue: () => Promise<T>,
 *   resetAwaitedValue: () => void,
 *   resolveWithValue: (value: T) => void
 * }} - An object containing methods to await a value multiple times.
 */
export function waitForValue<T>(): {
  awaitForValue: () => Promise<T>;
  resetAwaitedValue: () => void;
  resolveWithValue: (value: T) => void;
  } {
  let currentPromise: Promise<T>
  const state: WaitForValueState<T> = {
    resolve: noop,
    pendingResolvers: [],
  }

  const initializeWaiter = (resolverState: WaitForValueState<T>) => {
    return new Promise<T>((resolve) => {
      resolverState.resolve = resolve
    })
  }

  /**
   * Resets the awaited value by creating a new promise so the value can be awaited again
   */
  const resetAwaitedValue = () => {
    state.pendingResolvers.push(state.resolve)
    currentPromise = initializeWaiter(state)
  }

  /**
   * Resolves the current value, resolving awaitForValue promise and any stacked pending promises
   *
   * @param {T} value
   */
  const resolveWithValue = (value: T) => {
    state.resolve(value)
    state.pendingResolvers.forEach(func => func(value))
    state.pendingResolvers = []
  }

  // init first promise
  currentPromise = initializeWaiter(state)

  return {
    awaitForValue: () => currentPromise,
    resetAwaitedValue,
    resolveWithValue,
  }
}

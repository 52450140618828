import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import useListTruncator from 'hooks/useListTruncator'
import { rem } from 'polished'
import React, { MouseEventHandler, ReactElement, useCallback } from 'react'
import styled from 'styled-components'
import SearchTileRowInclusionItem from './SearchTileRowInclusionItem'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${rem(160)}, 1fr));
  gap: ${rem(4)};
`

interface Props {
  amenities: Array<App.OfferInclusion>
  amenitiesTitle?: string | ReactElement
  onMoreClick?: () => void
}

function SearchTileStackAmenitiesList({
  amenities,
  amenitiesTitle,
  onMoreClick,
}: Props) {
  const [visible, hidden] = useListTruncator(6, amenities)

  const handleMoreClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault()
    onMoreClick?.()
  }, [onMoreClick])

  if (!visible.length) return null

  return <Group className={SearchTileStackAmenitiesList.name} direction="vertical" gap={4}>
    {!!amenitiesTitle && <BodyText variant="medium" weight="bold">{amenitiesTitle}</BodyText>}
    <Container>
      {visible.map((amenity) => <SearchTileRowInclusionItem
      key={amenity.id}
      inclusion={amenity}
      lineClamp={1}
    />)}
    </Container>
    {(!!hidden.length && onMoreClick) && <TextLink size="medium" onClick={handleMoreClick}>+{hidden.length} more</TextLink>}
  </Group>
}

export default SearchTileStackAmenitiesList

import request from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-customer-communication'
import { mapUserPreferences, mapUserPreferencesV2 } from './mappers/LESubscriptionsMap'

type fetchLEUserSubscriptionsResponse = paths['/api/customer-communication/v1/subscriptions/users/{userId}']['get']['responses']['200']['content']['application/json']['result']
type fetchLEUserSubscriptionsV2Response = paths['/api/customer-communication/v2/subscriptions/users/{userId}']['get']['responses']['200']['content']['application/json']['result']
type updateLEUserSubscriptionsResponse = paths['/api/customer-communication/v1/subscriptions/users']['post']['responses']['200']['content']['application/json']['result'];
type updateLEUserSubscriptionsRequest = paths['/api/customer-communication/v1/subscriptions/users']['post']['parameters']['body']['payload'];
type updateLEUserSubscriptionsV2Response = paths['/api/customer-communication/v2/subscriptions/users']['patch']['responses']['200']['content']['application/json'];
type updateLEUserSubscriptionsV2Request = paths['/api/customer-communication/v2/subscriptions/users']['patch']['parameters']['body']['payload'];
type fetchResubscribeModalDismissedDateResponse = paths['/api/customer-communication/v1/modals/resubscribe/users/{userId}']['get']['responses']['200']['content']['application/json']['result'];
type updateResubscribeModalDismissedDateResponse = paths['/api/customer-communication/v1/modals/resubscribe/users/{userId}/actions/{action}']['put']['responses']['200']['content']['application/json'];
type fetchTravelPreferencesDismissResponse = paths['/api/customer-communication/v2/users/{userId}/interactions/travel']['get']['responses']['200']['content']['application/json']['result'];

export function fetchLEUserSubscriptions(userId: string) {
  return request.get<App.ApiResponse<fetchLEUserSubscriptionsResponse>>(`/api/customer-communication/v1/subscriptions/users/${userId}`, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferences(res.result))
}

export function fetchLEUserSubscriptionsV2(userId: string) {
  return request.get<App.ApiResponse<fetchLEUserSubscriptionsV2Response>>(`/api/customer-communication/v2/subscriptions/users/${userId}`, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferencesV2(res.result.subscriptions))
}

export function updateLEUserSubscriptions(data: any) {
  const response = request.post<updateLEUserSubscriptionsResponse, updateLEUserSubscriptionsRequest>('/api/customer-communication/v1/subscriptions/users', data, {
    credentials: 'include',
  },
  ).then(() => (data.subscriptions))

  return response
}

export function updateLEUserSubscriptionsV2(data: any) {
  return request.patch<updateLEUserSubscriptionsV2Response, updateLEUserSubscriptionsV2Request>('/api/customer-communication/v2/subscriptions/users', data, {
    credentials: 'include',
  },
  ).then(res => mapUserPreferencesV2(res.result.subscriptions))
}

export function fetchResubscribeModalDismissedDate(userId: string) {
  return request.get<App.ApiResponse<fetchResubscribeModalDismissedDateResponse>>(`/api/customer-communication/v1/modals/resubscribe/users/${userId}`, {
    credentials: 'include',
  }).then(res => res.result.resubscribeModal.lastDismissedAt || null)
}

export function updateResubscribeModalDismissedDate(userId: string) {
  return request.put(`/api/customer-communication/v1/modals/resubscribe/users/${userId}/actions/dismissed`, {}, {
    credentials: 'include',
  }).then((res: updateResubscribeModalDismissedDateResponse) => res.result.resubscribeModal.lastDismissedAt)
}

export function sendEmailResubscribePromoCode(userId: string) {
  return request.post('/api/customer-communication/v2/events', {
    userId,
    eventType: 'resubscribed-via-modal',
  }, { credentials: 'include' })
}

export function fetchTravelPreferencesModalDismissedDate(userId: string) {
  return request.get<App.ApiResponse<fetchTravelPreferencesDismissResponse>>(`/api/customer-communication/v2/users/${userId}/interactions/travel`, {
    credentials: 'include',
  }).then(res => res.result.isTravelPrefViewed)
}

export function updateTravelPreferencesModalDismissedDate(userId: string, isTravelPrefViewed: boolean) {
  return request.put(`/api/customer-communication/v2/users/${userId}/interactions/travel`, { isTravelPrefViewed }, {
    credentials: 'include',
  })
}

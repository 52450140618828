import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGlassMartiniAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.78 3.62a.9996.9996 0 0 0 .12-1.05A1.0007 1.0007 0 0 0 21 2H3a1 1 0 0 0-.9.57 1 1 0 0 0 .12 1.05L11 14.6V20H5.25a1.0002 1.0002 0 0 0-.7071 1.7071 1 1 0 0 0 .707.2929H18.75a1.0001 1.0001 0 0 0 0-2H13v-5.4l8.78-10.98ZM5.08 4h13.84l-2.4 3h-9L5.08 4ZM12 12.65 9.08 9h5.84L12 12.65Z"/>
  </SvgIcon>
}

export default LineGlassMartiniAltIcon

import { useAppSelector } from 'hooks/reduxHooks'
import { EmptyObject } from 'lib/object/objectUtils'
import getOfferListKey from 'lib/offer/offerListKey'

function useOfferMetaData(offerId: string, filters: App.OfferListFilters = EmptyObject): App.OfferListMetaData | undefined {
  const offerMetaDataKey = getOfferListKey(filters)

  return useAppSelector(state => state.offer.searchResultMetadata.offerMetaData[offerMetaDataKey]?.[offerId])
}

export default useOfferMetaData

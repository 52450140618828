import App from 'components/App/App'
import { createSelector } from 'reselect'

export function getOfferStatsKey(offerId: string, region: string) {
  return `${offerId}_${region}`
}

const getOfferStats = createSelector(
  (state: App.State) => state.recommendations.offerStats,
  (_: App.State, offerId: string, region: string) => getOfferStatsKey(offerId, region),
  (allOfferStats, offerKey) => {
    const keyState = allOfferStats[offerKey]
    if (!!keyState && keyState.state === 'done') {
      return keyState.stats
    }
  },
)

export const getOfferValue = createSelector(
  getOfferStats,
  (offerStats?: App.RecommendationOfferStats) => {
    if (!!offerStats && offerStats.offerValue !== null) {
      return offerStats.offerValue
    }
  },
)

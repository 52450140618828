import React from 'react'
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height'

interface Props extends Omit<AnimateHeightProps, 'height'> {
  show?: boolean;
  children: React.ReactNode;
  className?: string;
}

const SlideDown = React.forwardRef<HTMLDivElement, Props>(({ show, ...props }, ref) => (
  <AnimateHeight
    {...props}
    height={show ? 'auto' : 0}
    ref={ref}
  />
))

SlideDown.displayName = 'SlideDown'

export default SlideDown

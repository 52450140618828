import TextButton from 'components/Luxkit/Button/TextButton'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Heading from 'components/Luxkit/Typography/Heading'
import { LUX_BUNDLE_LABEL } from 'constants/productLabel'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { nonNullable } from 'lib/array/arrayUtils'
import React, { useMemo } from 'react'
import SearchTile from '../SearchTile'
import HotelBundleSearchTileFeatures from './HotelBundleSearchTileFeatures'
import HotelBundleSearchTileHighlightBanner from './HotelBundleSearchTileHighlightBanner'
import HotelBundleSearchTileMeta from './HotelBundleSearchTileMeta'
import HotelBundleSearchTileOverlineLocations from './HotelBundleSearchTileOverlineLocations'
import HotelBundleSearchTilePricePoint from './HotelBundleSearchTilePricePoint'

interface Props {
  offer: App.BundleOffer
  filters?: App.OfferListFilters
}

function HotelBundleSearchTile({
  offer,
  filters,
}: Props) {
  const offersLocations = useMemo<Array<Array<string>>>(() => {
    return Object.values(offer.bundledOffers).map((offer) => nonNullable([offer.locationHeading, offer.locationSubheading]))
  }, [offer.bundledOffers])

  const title = useMemo(() => {
    if (offer.location) return offer.location
    const offers = Object.values(offer.bundledOffers)
    return offers.map((offer) => offer.property.name).join(' + ')
  }, [offer])

  const showHighlight = !!(filters?.propertyId && !filters?.searchNearby && offer.property?.id === filters.propertyId.split(':')[1])

  const offerMetaData = useOfferMetaData(offer.id, filters) as App.BundleOfferListMetaData

  return <SearchTile
    className={HotelBundleSearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    state="highlighted"
    action={<TextButton nonInteractable kind="primary">View offer</TextButton>}
    banner={showHighlight && <HotelBundleSearchTileHighlightBanner offer={offer} filters={filters} offerMetaData={offerMetaData} />}
    features={<HotelBundleSearchTileFeatures offer={offer} />}
    images={offer.images}
    meta={<HotelBundleSearchTileMeta offer={offer} />}
    overline={<HotelBundleSearchTileOverlineLocations offersLocations={offersLocations} />}
    pricePoint={<HotelBundleSearchTilePricePoint offer={offer} filters={filters} offerMetaData={offerMetaData} />}
    productLabel={<ProductTypeLabel productType={offer.productType} />}
    title={<><Heading variant="heading5" colour="highlight-secondary" as="span">{LUX_BUNDLE_LABEL}: </Heading> {title}</>}
  />
}

export default HotelBundleSearchTile

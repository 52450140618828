import { PreProcessor } from 'api/requestUtils'
import config from 'constants/config'
import { AppStore } from 'src/client/store'

export default function tokenLoader(store: AppStore): PreProcessor {
  return (params) => {
    if (params.options?.credentials === 'include') {
      const s = store.getState()
      if (
        // make sure it's an LE end point
        config.ENDPOINT === params.endpoint &&
        // Make sure we have a token
        s.auth.accessToken &&
        // Don't overwrite authorisation header if someone has manually set it
        !(params.options.headers as any)?.Authorization
      ) {
        params.options.headers = {
          ...params.options.headers,
          Authorization: `Bearer ${s.auth.accessToken}`,
        }
      }
    }
    return params
  }
}

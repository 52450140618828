export function convertJourneyV2ToJourneyFlight(journey: App.JourneyV2): App.JourneyFlight {
  return {
    journeyKey: journey.bookingInfo.journeyKey,
    flights: journey.flightGroup.flights.map(flight => flightMap(flight, journey.links)),
    flightTime: journey.flightGroup.totalFlightDuration,
    extras: baggageOptionsMap(journey.flightGroup.flights[0]),
    providerSearchId: journey.bookingInfo.providerSearchId,
    priceBreakdown: {
      adults: {
        cost: journey.price.adult.totalFare!,
        tax: journey.price.adult.totalTax!,
      },
      children: {
        cost: 0,
        tax: 0,
      },
      infants: {
        cost: 0,
        tax: 0,
      },
    },
    provider: journey.provider,
    carrierName: journey.carrierName,
    carrierLogo: journey.carrierLogo,
    cost: journey.price.all.totalFare,
    tax: journey.price.all.totalTax,
  }
}

function flightMap(flight: App.FlightV2, links: Array<Record<string, string>>): App.Flight {
  const { operatingAirline, marketingAirline } = flight
  const operatingByDifferentAirline = operatingAirline.code !== marketingAirline.code
  const operatedByText = operatingByDifferentAirline ? `Operated by ${operatingAirline.name}` : ''

  return {
    _links: {
      fare_rules: {
        href: links.find(link => link.key === 'fareRules')?.value,
      },
    },
    departingAirport: flight.departureAirport,
    departingAirportName: flight.departureAirportName,
    departingDisplayNames: {
      departingCountry: flight.departureCountry,
      primary: flight.departureAirportName,
      secondary: '',
    },
    departingCountry: flight.departureCountry,
    departingDate: flight.departingDate,
    departingTime: flight.departingTime,

    arrivalAirport: flight.arrivalAirport,
    arrivalAirportName: flight.arrivalAirportName,
    arrivalDisplayNames: {
      primary: flight.arrivalAirportName,
      secondary: '',
    },
    arrivalCountry: flight.arrivalCountry,
    arrivalDate: flight.arrivalDate,
    arrivalTime: flight.arrivalTime,
    operatedByText,
    carrier: flight.marketingAirline.code,
    carrierName: flight.marketingAirline.name,
    flightNumber: flight.flightNumber,
    aircraftType: flight.aircraftType,
    layoverTime: flight.stopoverTime,
    freeCarryOnBaggageDescription: flight.includedCarryOnBaggage?.description,
    freeCheckedInBaggageDescription: flight.includedCheckedBaggage?.description,
    freeCheckedInBaggageIncluded: !!flight.includedCheckedBaggage,
    bookingClass: flight.flightBookingKey,
    fareClass: flight.fareClass,
    fareBasis: '', // flight.fare_basis,
    productClass: '', // flight.product_class,
    operatingCarrier: flight.operatingAirline.code,
    duration: flight.duration,
  }
}

export function baggageOptionsMap(flight: App.FlightV2): App.JourneyAddons {
  return {
    baggage: mapBaggageToAddon(flight.checkedBaggageOptions),
    carryOnBaggage: mapBaggageToAddon(flight.carryOnBaggageOptions),
  }
}

function mapBaggageToAddon(options: Array<App.FlightV2BaggageOption>): Array<App.JourneyAddon> {
  return options
    .filter(option => !option.includedInFare)
    .map(option => ({
      id: option.id,
      description: option.description,
      quantity: option.quantity,
      amount: option.amount,
      ...((option.passengerType == 'ADT' || option.passengerType === 'CHD') && {
        [option.passengerType === 'ADT' ? 'adult' : 'child']: {
          cost: option.amount,
          tax: 0,
          max: 1,
        },
      }),
    }))
}

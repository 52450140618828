import { HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES, HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES } from 'components/OfferList/OfferListTiles/constants'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import useBestPriceBundleAndSavePackage from 'hooks/useBestPriceBundleAndSavePackage'
import { EmptyObject } from 'lib/object/objectUtils'
import React, { useMemo } from 'react'
import SearchTileHighlightBanner from '../shared/SearchTileHighlightBanner'

interface Props {
  offer: App.BundleOffer
  offerMetaData: App.BundleOfferListMetaData
  filters?: App.OfferListFilters
}

function HotelBundleSearchTileHighlightBanner({ offer, offerMetaData, filters }: Props) {
  const directSearchPrices = useDirectSearchPrices({ filters: filters ?? EmptyObject, offerId: offer.id })
  const {
    bestPricePackage,
    bestPriceError,
    fetching,
    available,
    useBestPrice,
  } = useBestPriceBundleAndSavePackage(offer, offerMetaData.bundledOfferId, filters, directSearchPrices, offerMetaData)

  const description = useMemo(() => {
    const isSearchAvailable = !!offerMetaData.available
    if (offer.isSoldOut || !bestPricePackage) {
      return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
    }
    if (useBestPrice) {
    // If direct search prices are available, we can show the expanded tile (using search pricing)
      if (directSearchPrices && isSearchAvailable) {
        return HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES()
      } else if (directSearchPrices && !isSearchAvailable) {
        return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
      } else if (fetching) {
        return undefined
      } else if (available || bestPriceError) {
        return HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES()
      } else {
        return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
      }
    } else {
      return HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES()
    }
  }, [available, bestPriceError, bestPricePackage, directSearchPrices, fetching, offer, offerMetaData, useBestPrice])

  if (!description) return null

  return <SearchTileHighlightBanner description={description} />
}

export default HotelBundleSearchTileHighlightBanner

import request from 'api/requestUtils'

export interface VelocityMemberProfile {
  membershipId: string;
  pointBalance: number;
  firstName: string;
  lastName: string;
}

export interface VelocityBurnPayload {
  orderId: string;
  accessToken: string;
  membershipId: string;
}

export async function getVelocityMemberProfile(accessToken: string): Promise<VelocityMemberProfile> {
  const response = await request.post<App.ApiResponse<VelocityMemberProfile>, Record<string, string>>(
    '/api/payment/velocity/member/profile', { accessToken }, { credentials: 'include' })
  return response.result
}

export function completeVelocityBurn(burnPayload: VelocityBurnPayload) {
  return request.post<App.ApiResponse<any>, VelocityBurnPayload>('/api/payment/velocity/member/completePayment', burnPayload, { credentials: 'include' })
}

import { createSelector } from 'reselect'
import getCheckoutInsuranceQuoteParams from '../request/getCheckoutInsuranceQuoteParams'
import { getInsuranceItems } from './insurance'
import { getInsuranceQuoteKey } from 'lib/insurance/insuranceUtils'
import { arrayToObject, nonNullable } from 'lib/array/arrayUtils'

const getCheckoutSelectedQuotes = createSelector(
  (state: App.State) => getInsuranceItems(state),
  getCheckoutInsuranceQuoteParams,
  (state: App.State) => state.insurance.quotes,
  (items, params, quotes): Record<string, App.InsuranceQuote> => {
    const allQuotes = nonNullable(items.map(item => {
      if (item.productId) {
        const key = getInsuranceQuoteKey(item.productId, {
          ...params,
          coverAmount: item.coverageAmount || params.coverAmount,
          customPolicyIds: item.policyIds,
          type: item.insuranceType === 'cfmr' ? 'booking-protection' : 'travel-insurance',
        })

        const quote = quotes[key]
        if (quote) {
          return { item, quote }
        }
      }
    }))

    // now turn it into a item id => quote
    return arrayToObject(allQuotes,
      record => record.item.itemId,
      record => record.quote,
    )
  },
)

export default getCheckoutSelectedQuotes

import { fetchOffersById } from 'actions/OfferActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useStableObjectReference from 'hooks/useStableObjectReference'
import { useEffect, useMemo } from 'react'
import { getAllOffers } from 'selectors/offerSelectors'

/**  */
function useOffers<T extends App.OfferBase = App.AnyOffer>(offerIds: Array<string>): [Array<T>, boolean] {
  const dispatch = useAppDispatch()

  const stableOfferIds = useStableObjectReference(offerIds)

  useEffect(() => {
    dispatch(fetchOffersById(stableOfferIds))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableOfferIds])

  const allOffers = useAppSelector(getAllOffers)
  const offersLoading = useAppSelector(state => state.offer.offersLoading)
  const offersFetching = useMemo(() => stableOfferIds.some(id => offersLoading[id]), [offersLoading, stableOfferIds])

  const offers = useMemo((): Array<T> =>
    stableOfferIds.map<any>(id => allOffers[id]).filter(Boolean)
  , [stableOfferIds, allOffers])

  return [
    offers,
    offersFetching,
  ]
}

export default useOffers

import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { MouseEventHandler, PropsWithChildren, forwardRef } from 'react'
import styled from 'styled-components'

const Container = styled(Clickable)`
  position: relative;
  display: flex;
  align-items: center;
  max-height: ${rem(48)};
  justify-content: center;
  transition: background-color .2s;
  width: 100%;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${props => props.theme.borderRadius.XS};

  &.dateType {
    ${mediaQueryUp.tablet} {
      flex: 33;
    }

    ${mediaQueryUp.desktop} {
      flex: 27;
    }
  }

  &.occupancyType {
    ${mediaQueryUp.tablet} {
      flex: 25;
    }

    ${mediaQueryUp.desktop} {
      flex: 23;
    }
  }

  &.error {
    background: ${props => props.theme.palette.messaging.critical.lightBackground};
  }

  &:disabled {
    background-color: ${props => props.theme.palette.neutral.default.six};
  }

  ${mediaQueryUp.tablet} {
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
`

const Content = styled.span`
  display: flex;
  align-items: center;
  height: ${rem(48)};
  width: 100%;
  padding: ${rem(4)};

  ${mediaQueryUp.tablet} {
    display: inline-flex;
  }
`

interface Props extends PropsWithChildren {
  className?: string;
  isToggled?: boolean;
  error?: boolean;
  type?: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>
}

const SearchInputTrigger = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, onClick, isToggled, error, type, className, disabled } = props

  let dataTestId = ''
  if (type) {
    dataTestId = `search-${type}-input`
  }

  return (
    <Container
      ref={ref}
      onClick={onClick}
      className={cn(className, {
        focused: isToggled,
        error,
        [`${type}Type`]: type,
      })}
      data-testid={dataTestId}
      disabled={!!disabled}
    >
      <Content>
        {children}
      </Content>
    </Container>
  )
})

export default SearchInputTrigger

import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Clickable from 'components/Common/Clickable'
import Image from 'components/Common/Image'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { DEFAULT_TRIP_IMAGE } from 'tripPlanner/config'
import { BasicTrip } from 'tripPlanner/types/common'

const Panel = styled(Clickable)`
  display: grid;
  width: 100%;
  grid-template: 1fr / ${rem(48)} 1fr;
  align-items: center;
  padding: ${rem(12)};
  gap: ${rem(12)};
  border-radius: ${(props) => props.theme.borderRadius.S};
  transition: background-color 0.2s;
  background-color: ${(props) => props.theme.palette.neutral.default.eight};

  &:hover {
    background-color: ${props => props.theme.palette.neutral.default.seven};
  }
`

const RoundedImage = styled(Image)`
  border-radius: ${(props) => props.theme.borderRadius.S};
`

interface Props {
  trip: BasicTrip
  onSelectTrip: (trip: BasicTrip) => void
}

function TripPanel({ trip, onSelectTrip }: Props) {
  const image = useMemo(
    (): App.Image => ({
      id: trip.imageId,
      url: trip.imageUrl || DEFAULT_TRIP_IMAGE,
    }),
    [trip],
  )

  return (
    <Panel onClick={() => onSelectTrip(trip)}>
      <RoundedImage height={48} width={48} image={image} fit="center" />
      <div>
        <BodyText variant="large">{trip.name}</BodyText>
        {trip.startDate && trip.endDate && (
          <BodyTextBlock
            variant="small"
            colour="neutral-one"
            startIcon={<LineCalendarIcon size="XS" />}
          >
            {trip.startDate.format('D MMM YYYY')} -{' '}
            {trip.endDate.format('D MMM YYYY')}
          </BodyTextBlock>
        )}
        {!trip.startDate && !trip.endDate && (
          <BodyTextBlock
            variant="small"
            colour="neutral-three"
            startIcon={<LineCalendarIcon size="XS" />}
          >
            No dates added
          </BodyTextBlock>
        )}
      </div>
    </Panel>
  )
}

export default TripPanel

import React from 'react'
import { formatDate } from '@luxuryescapes/lib-refunds'

import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  cancellationPolicy: App.BedbankCancellationPolicy;
}

function BedbankPropertyCancellationPoliciesBase({ cancellationPolicy }: Props) {
  const hasRateCancellationPolicy = !!cancellationPolicy.policies.length

  return <BodyText variant="medium">
    <BodyText variant="medium" wrap="no-wrap" weight="bold" as="span" colour="highlight-secondary">Free cancellation</BodyText>
    {hasRateCancellationPolicy && <BodyText variant="medium" as="span" className="cancellation-date">
      {' '}until {formatDate.formattedShortDateByTimezone(cancellationPolicy.policies[0]?.start, cancellationPolicy.policies[0]?.timezone)}
    </BodyText>}{' '}(hotel only){' '}
    <SolidInfoCircleIcon size="XS" />
  </BodyText>
}

export default BedbankPropertyCancellationPoliciesBase

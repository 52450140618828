import {
  FETCH_REFER_EARN_OPTIONS,
  FETCH_REFERRAL_LOGS,
  USER_LOGOUT,
  FETCH_REFERRAL_CODE,
  FETCH_REFERRAL_VALUE,
  SEND_MULTIPLE_REFERRAL_REQUESTS,
  FETCH_LETV_QUESTIONS,
  SEND_LETV_ANSWERS,
  FETCH_LETV_PRIZE,
  FETCH_LETV_USER_STATUS,
} from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_REQUEST, API_CALL_FAILURE, API_CALL_SUCCESS } from 'actions/actionConstants'

const initialState: App.ReferState = {
  earnOptions: [],
  referralLogs: null,
  referralCode: null,
  referralValue: null,
  fetching: false,
  referralRequestsSent: null,
  utm: null,
  leTV: null,
}

const apiRequests = reducerSwitch<App.ReferState>({
  [FETCH_REFER_EARN_OPTIONS]: () => ({
    fetching: true,
  }),

  [FETCH_REFERRAL_LOGS]: () => ({
    fetching: true,
  }),

  [FETCH_REFERRAL_CODE]: () => ({
    fetching: true,
  }),

  [FETCH_REFERRAL_VALUE]: () => ({
    fetching: true,
  }),

  [SEND_MULTIPLE_REFERRAL_REQUESTS]: () => ({
    fetching: true,
  }),

  [FETCH_LETV_QUESTIONS]: () => ({
    fetching: true,
  }),

  [SEND_LETV_ANSWERS]: () => ({
    fetching: true,
  }),

  [FETCH_LETV_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_LETV_USER_STATUS]: () => ({
    fetching: true,
  }),
})

const apiSuccesses = reducerSwitch<App.ReferState>({
  [FETCH_REFER_EARN_OPTIONS]: (state, action) => ({
    earnOptions: action.data.result.earn_options,
    fetching: false,
  }),

  [FETCH_REFERRAL_LOGS]: (state, action) => ({
    referralLogs: action.data.result,
    fetching: false,
  }),

  [FETCH_REFERRAL_CODE]: (state, action) => ({
    referralCode: action.data.result.details.referral_code,
    utm: action.data.result.meta_data,
    fetching: false,
  }),

  [FETCH_REFERRAL_VALUE]: (state, action) => ({
    referralValue: action.data.result,
    fetching: false,
  }),

  [SEND_MULTIPLE_REFERRAL_REQUESTS]: (state, action) => ({
    fetching: false,
    referralRequestsSent: action.data,
  }),

  [FETCH_LETV_QUESTIONS]: (state, action) => ({
    fetching: false,
    leTV: { ...state.leTV, questions: action.data.result },
  }),

  [SEND_LETV_ANSWERS]: (state, action) => ({
    fetching: false,
    leTV: { ...state.leTV, answers: action.data },
  }),

  [FETCH_LETV_PRIZE]: (state, action) => ({
    fetching: false,
    leTV: { ...state.leTV, prize: action.data },
  }),

  [FETCH_LETV_USER_STATUS]: (state, action) => ({
    fetching: false,
    leTV: { ...state.leTV, userStatus: action.data.result },
  }),

  [USER_LOGOUT]: () => ({
    referralLogs: null,
    referralCode: null,
    utm: null,
    referralRequestsSent: null,
    leTV: null,
  }),
})

const apiFailures = reducerSwitch<App.ReferState>({
  [FETCH_REFER_EARN_OPTIONS]: () => ({
    earnOptions: [],
    fetching: false,
  }),

  [FETCH_REFERRAL_LOGS]: () => ({
    fetching: false,
    referralLogs: null,
  }),

  [FETCH_REFERRAL_VALUE]: () => ({
    fetching: false,
    referralValue: null,
  }),

  [FETCH_LETV_QUESTIONS]: (state) => ({
    fetching: false,
    leTV: { ...state.leTV, questions: null },
  }),

  [SEND_LETV_ANSWERS]: (state, action) => ({
    fetching: false,
    leTV: { ...state.leTV, answers: action },
  }),

  [FETCH_LETV_PRIZE]: (state) => ({
    fetching: false,
    leTV: { ...state.leTV, prize: null },
  }),

  [FETCH_LETV_USER_STATUS]: (state) => ({
    fetching: false,
    leTV: { ...state.leTV, userStatus: null },
  }),

  [SEND_MULTIPLE_REFERRAL_REQUESTS]: () => ({
    fetching: false,
    referralRequestsSent: null,
  }),

})

export default createReducer<App.ReferState>(initialState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React, { useMemo } from 'react'
import TourV2Location from './TourV2Location'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import TourV2PriceDetails from './TourV2PriceDetails'
import TourV2UrgencyTags from 'components/Tours/TourV2UrgencyTags'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { TOUR_STYLED_PRODUCT_TYPE_TITLES } from 'constants/tours'
import { isTourProductTypeStyled } from 'lib/tours/tourUtils'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
  onClick: () => void;
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
}

function TourV2OfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props
  const { cheapestPurchasableOption, cheapestVariation } = useCheapestOfTourV2Offer(offer)
  const variationDetails = useTourV2VariationDetails(cheapestVariation)

  const providerName = useMemo<string>(() => {
    return [
      offer.operatedBy,
      isTourProductTypeStyled(offer.productType) ? TOUR_STYLED_PRODUCT_TYPE_TITLES[offer.productType] : undefined,
    ].filter(Boolean).join(' · ')
  }, [offer])

  if (!cheapestPurchasableOption || !cheapestVariation) {
    return null
  }

  const {
    startLocation,
    endLocation,
    hasTheSameStartAndEndLocation,
    countriesCount,
    durationInDaysCount,
    placesCount,
  } = variationDetails

  const updatedEndLocation = hasTheSameStartAndEndLocation ? startLocation : endLocation

  const isUltraLux = isUltraLuxOffer(offer)

  return (
    <ProductPaletteProvider product={offer}>
      {size === 'medium' && <CarouselCardMedium
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        title={offer.name}
        rating={isOfferRatingDisplayable(offer.rating, 0) ? offer.rating : undefined}
        description={`${durationInDaysCount} · ${placesCount} · ${countriesCount}`}
        providerName={providerName}
        image={cheapestVariation.images[0]}
        location={<TourV2Location startLocation={startLocation} endLocation={updatedEndLocation} variant="start_and_end" />}
        priceDetails={<TourV2PriceDetails offer={offer} variant="condensed" />}
        to={offerUrl}
        urgencyLabels={<LabelGroup>
          {isUltraLux && <ProductTypeLabel productType={offer.productType} />}
          <TourV2UrgencyTags offer={offer} />
        </LabelGroup>}
        onClick={onClick}
      />}
      {size === 'small' && <CarouselCardSmall
        className={className}
        rating={isOfferRatingDisplayable(offer.rating, 0) ? offer.rating : undefined}
        bookmarkButton={<BookmarkButton offer={offer} />}
        description={`${durationInDaysCount} · ${placesCount} · ${countriesCount}`}
        image={cheapestVariation.images[0]}
        location={<TourV2Location startLocation={startLocation} endLocation={updatedEndLocation} variant="start_and_end" />}
        title={offer.name}
        priceDetails={<TourV2PriceDetails offer={offer} />}
        to={offerUrl}
        productType={offer.productType}
        urgencyLabels={<TourV2UrgencyTags offer={offer} />}
        onClick={onClick}
      />}
    </ProductPaletteProvider>
  )
}

export default TourV2OfferCard

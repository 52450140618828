import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import TripPanel from './TripPanel'

import Clickable from 'components/Common/Clickable'
import IconButton from 'components/Luxkit/Button/IconButton'
import LinePlusIcon from 'components/Luxkit/Icons/line/LinePlusIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { sortBy } from 'lib/array/arrayUtils'
import { BasicTrip } from 'tripPlanner/types/common'

interface Props {
  trips: Array<BasicTrip>
  onCreateSelect?: () => void
  onSelectTrip: (trip: BasicTrip) => void
}

const Panel = styled(Clickable)`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${rem(12)};
  gap: ${rem(12)};
  border-radius: ${(props) => props.theme.borderRadius.S};
  transition: background-color 0.2s;
  background-color: ${(props) => props.theme.palette.neutral.default.eight};

  &:hover {
    background-color: ${props => props.theme.palette.neutral.default.seven};
  }
`

function TripSelect({ trips, onCreateSelect, onSelectTrip }: Props) {
  const sortedTrips = useMemo(() => {
    return sortBy(trips, (trip) => trip.interactedAt, 'desc')
  }, [trips])

  return (
    <div data-testid="save-to-trip-modal">
      <Group direction="vertical" verticalAlign="start">
        <Group direction="vertical">
          <BodyText variant="large">
            Choose which trip you would like to save this offer to.
          </BodyText>
          {onCreateSelect && (
            <Panel onClick={onCreateSelect}>
              <IconButton
                size="large"
                kind="secondary"
                shape="square"
                nonInteractable
                variant="dark"
              >
                <LinePlusIcon />
              </IconButton>
              <BodyText variant="large">Create new trip</BodyText>
            </Panel>
          )}
        </Group>
        {sortedTrips.map((trip) => (
          <TripPanel key={trip.id} onSelectTrip={onSelectTrip} trip={trip} />
        ))}
      </Group>
    </div>
  )
}

export default TripSelect

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineShieldPlusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14 11h-1v-1a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 10v1h-1a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929h1v1a1.0001 1.0001 0 0 0 2 0v-1h1a1.0001 1.0001 0 0 0 0-2Zm5.6309-7.3501a1.0081 1.0081 0 0 0-.836-.2026 7.9808 7.9808 0 0 1-6.2226-1.2671.999.999 0 0 0-1.1446 0 7.9755 7.9755 0 0 1-6.2226 1.267A.9998.9998 0 0 0 4 4.4263v7.4571a9.0188 9.0188 0 0 0 3.7686 7.3237l3.6504 2.607a1.0003 1.0003 0 0 0 1.1621 0l3.6504-2.6074A9.0173 9.0173 0 0 0 20 11.8833v-7.457a1.0004 1.0004 0 0 0-.3691-.7764ZM18 11.8833a7.0165 7.0165 0 0 1-.7773 3.2019 7.0171 7.0171 0 0 1-2.1534 2.4939L12 19.771l-3.0693-2.1914A7.0173 7.0173 0 0 1 6 11.8833V5.583a9.9547 9.9547 0 0 0 6-1.3911 9.9547 9.9547 0 0 0 6 1.3911v6.3003Z"/>
  </SvgIcon>
}

export default LineShieldPlusIcon

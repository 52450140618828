import React from 'react'
import styled from 'styled-components'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import { pluralizeToString } from 'lib/string/pluralize'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { useOfferRatePrice } from 'hooks/Offers/useOfferPrice'

const Root = styled.div`
`

interface Props {
  rate?: App.BedbankRate;
  offer: App.BedbankOffer | App.BedbankOfferSummary;
}

function HeroBedbankPriceDetails(props: Props) {
  const {
    rate,
    offer,
  } = props

  const ratePrices = useOfferRatePrice(offer, rate)
  const hotelNights = rate?.nights ?? offer?.sell?.los
  if (!ratePrices) {
    return null
  }

  const {
    totalPrice,
    saleUnit,
    totalValue,
    valueOffPercent,
  } = ratePrices

  return <Root>
    <PriceRowPriceCaption>
      {pluralizeToString('night', hotelNights!)} from
    </PriceRowPriceCaption>
    <PriceRowPrice size="L" price={totalPrice} saleUnit={saleUnit} />
    <PriceRowTaxesAndFees />
    {valueOffPercent > 0 && <PriceRowValueDiscountWithCaption
      size="M"
      originalValue={totalValue}
      discountPercentage={valueOffPercent}
    />}
  </Root>
}

export default HeroBedbankPriceDetails

import { createSelector } from 'reselect'
import { isInsuranceItem, isInsuranceProtectionItem, isNoProtectionInsuranceItem } from 'lib/checkout/checkoutUtils'
import { findInsuranceUpgradeSourceItem } from 'lib/insurance/insuranceHelpers'

export const getInsuranceItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InsuranceItem> => items.filter(isInsuranceProtectionItem),
)

export const getNoProtectionInsuranceItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InsuranceItem> => items.filter(isNoProtectionInsuranceItem),
)

// It includes the no-protection insurance item
export const getAllInsuranceItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InsuranceItem> => items.filter(isInsuranceItem),
)

export const getInsuranceUpgradeSourceItem = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (existingOrder, insuranceItems) => {
    const activeInsuranceItem = insuranceItems?.find(item => item.status === 'completed')

    if (!activeInsuranceItem || !existingOrder) {
      return null
    }

    const insuranceUpgradeSourceItem = findInsuranceUpgradeSourceItem(activeInsuranceItem, existingOrder)
    return insuranceUpgradeSourceItem
  },
)

import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { SVG_ICON_SIZE_CSS_VAR, StyledSvgIcon } from '../SvgIcon'
import { _TooltipTriggerElement } from '../_TooltipTrigger'
import { StyledIconLoadingBox } from '../Icons/IconLoadingBox'

export const TYPOGRAPHY_LINE_HEIGHT_CSS_VAR = '--text-line-height'
export const TYPOGRAPHY_ICON_SIZE_CSS_VAR = '--text-icon-size'

export const TypographyElement = styled.div`
  line-height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});

  ${StyledSvgIcon}, ${StyledIconLoadingBox} {
    ${SVG_ICON_SIZE_CSS_VAR}: var(${TYPOGRAPHY_ICON_SIZE_CSS_VAR}, 1em);
  }

  > ${StyledSvgIcon}, > ${StyledIconLoadingBox} {
    vertical-align: middle;
  }

  ${_TooltipTriggerElement} {
    display: inline-flex;
  }

  &.wrap-no-wrap {
    white-space: nowrap;
  }

  &.wrap-word-wrap {
    word-break: break-word;
  }

  &.wrap-letter-wrap {
    word-break: break-all;
  }

  &.wrap-truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.wrap-pre-wrap {
    white-space: pre-wrap;
  }

  &.wrap-pretty {
    text-wrap: pretty;
  }

  &.format-lowercase {
    text-transform: lowercase;
  }

  &.format-titlecase {
    text-transform: capitalize;
  }

  &.format-uppercase {
    text-transform: uppercase;
  }

  &.format-propercase {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &.align-start {
    text-align: left;
    text-align: start;
  }

  &.align-end {
    text-align: right;
    text-align: end;
  }

  &.align-center {
    text-align: center;
  }

  &.align-justify {
    text-align: justify;
  }

  ${mediaQueryUp.tablet} {
    &.align-on-tablet-start {
      text-align: left;
      text-align: start;
    }

    &.align-on-tablet-end {
      text-align: right;
      text-align: end;
    }

    &.align-on-tablet-center {
      text-align: center;
    }

    &.align-on-tablet-justify {
      text-align: justify;
    }
  }

  ${mediaQueryUp.desktop} {
    &.align-on-desktop-start {
      text-align: left;
      text-align: start;
    }

    &.align-on-desktop-end {
      text-align: right;
      text-align: end;
    }

    &.align-on-desktop-center {
      text-align: center;
    }

    &.align-on-desktop-justify {
      text-align: justify;
    }
  }

  &.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    -webkit-line-clamp: var(--line-clamp);
    line-clamp: var(--line-clamp);

    ${mediaQueryUp.tablet} {
      -webkit-line-clamp: var(--line-clamp-tablet, var(--line-clamp));
      line-clamp: var(--line-clamp-tablet, var(--line-clamp));
    }

    ${mediaQueryUp.desktop} {
      -webkit-line-clamp: var(--line-clamp-desktop, var(--line-clamp-tablet, var(--line-clamp)));
      line-clamp: var(--line-clamp-desktop, var(--line-clamp-tablet, var(--line-clamp)));
    }
  }
`

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
  format?: 'lowercase' | 'titlecase' | 'uppercase' | 'propercase';
  wrap?: 'no-wrap' | 'word-wrap' | 'letter-wrap' | 'truncate' | 'pre-wrap' | 'pretty';
  align?: 'start' | 'center' | 'end' | 'justify'
  tabletAlign?: 'start' | 'center' | 'end' | 'justify'
  desktopAlign?: 'start' | 'center' | 'end' | 'justify'
  lineClamp?: number;
  tabletLineClamp?: number;
  desktopLineClamp?: number;
  as?: React.ElementType | keyof JSX.IntrinsicElements,
  forwardedAs?: React.ElementType | keyof JSX.IntrinsicElements,
}

const Typography = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    format,
    wrap,
    align,
    tabletAlign,
    desktopAlign,
    lineClamp,
    tabletLineClamp,
    desktopLineClamp,
    style: styleProp,
    ...rest
  } = props

  let style = styleProp
  if (lineClamp) {
    style = {
      ...styleProp,
      '--line-clamp': lineClamp,
      '--line-clamp-tablet': tabletLineClamp,
      '--line-clamp-desktop': desktopLineClamp,
    } as React.CSSProperties
  }

  return <TypographyElement
    {...rest}
    ref={ref}
    style={style}
    className={cn(
      className,
      align ? `align-${align}` : undefined,
      tabletAlign ? `align-on-tablet-${tabletAlign}` : undefined,
      desktopAlign ? `align-on-desktop-${desktopAlign}` : undefined,
      format ? `format-${format}` : undefined,
      wrap ? `wrap-${wrap}` : undefined,
      (lineClamp || tabletLineClamp || desktopLineClamp) ? 'line-clamp' : undefined,
    )}
  />
})

Typography.displayName = 'Typography'

export default Typography

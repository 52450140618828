import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWifiRouterIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M8.9 17a1.0006 1.0006 0 0 0-.9239.6173 1.0002 1.0002 0 0 0 1.3066 1.3066A1.0004 1.0004 0 0 0 9.9 18a1 1 0 0 0-1-1Zm-3 0a1.0006 1.0006 0 0 0-.9239.6173 1.0002 1.0002 0 0 0 1.3066 1.3066A1.0004 1.0004 0 0 0 6.9 18a1 1 0 0 0-1-1Zm6 0a1.0002 1.0002 0 0 0-.9808 1.1951 1.0002 1.0002 0 0 0 1.8123.3605A1 1 0 0 0 11.9 17ZM15 8.5a1 1 0 0 1 1.73 0 1 1 0 0 0 1.36.37.9997.9997 0 0 0 .41-1.37 2.9999 2.9999 0 0 0-5.2 0 1.0003 1.0003 0 0 0 1.1037 1.3426A.9999.9999 0 0 0 15 8.5Zm7-3a6.9994 6.9994 0 0 0-6.06-3.4962A7 7 0 0 0 9.88 5.5.9999.9999 0 0 0 10.7 7a1.0003 1.0003 0 0 0 .87-.5 5.0008 5.0008 0 0 1 4.33-2.4998A4.9999 4.9999 0 0 1 20.23 6.5a1.0006 1.0006 0 0 0 .999.4962.9998.9998 0 0 0 .371-.1262 1.001 1.001 0 0 0 .4875-.601A.9991.9991 0 0 0 22 5.5ZM17.9 14h-1v-3a.9997.9997 0 0 0-1-1 1.0003 1.0003 0 0 0-1 1v3h-10a3 3 0 0 0-3 3v2a2.9998 2.9998 0 0 0 3 3h13c.7957 0 1.5587-.316 2.1213-.8787A2.9995 2.9995 0 0 0 20.9 19v-2a2.9997 2.9997 0 0 0-3-3Zm1 5a.9997.9997 0 0 1-1 1h-13a.9997.9997 0 0 1-1-1v-2a1 1 0 0 1 1-1h13a1.0001 1.0001 0 0 1 1 1v2Z"/>
  </SvgIcon>
}

export default LineWifiRouterIcon

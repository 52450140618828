import React, { RefObject } from 'react'
import CruiseLineSelect from './CruiseLineSelect/CruiseLineSelect'
import CruiseWhenSelect from './CruiseWhenSelect/CruiseWhenSelect'
import CruiseDestinationFilter from './CruiseLocationFilter/CruiseLocationFilter'
import noop from 'lib/function/noop'
import CruiseDepartureSelect from 'components/Cruises/SearchPage/Filters/Inputs/CruiseDepartureSelect/CruiseDepartureSelect'

interface Props {
  onChange?: (values: App.CruiseGlobalFilters) => void;
  /**
   * Necessary for the non-`drawerMode` to work
   */
  dropdownAnchorRef?: RefObject<HTMLElement>;
  drawerMode?: boolean;
  isCruiselineLandingPage?: boolean
}

function CruiseSearchInputs(props: Props) {
  const { drawerMode, onChange = noop, dropdownAnchorRef, isCruiselineLandingPage } = props
  return <>
    <CruiseDestinationFilter
      onChange={onChange}
      drawerMode={drawerMode}
      dropdownAnchorRef={dropdownAnchorRef!}
    />

    <CruiseDepartureSelect
      onChange={onChange}
      drawerMode={drawerMode}
      dropdownAnchorRef={dropdownAnchorRef!}
    />

    {!isCruiselineLandingPage && <CruiseLineSelect
      onChange={onChange}
      dropdownAnchorRef={dropdownAnchorRef!}
      drawerMode={drawerMode}
    />}
    <CruiseWhenSelect
      onChange={onChange}
      dropdownAnchorRef={dropdownAnchorRef!}
      drawerMode={drawerMode}
    />
  </>
}

export default CruiseSearchInputs

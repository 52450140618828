export enum OrderItemType {
  ACCOMMODATION = 'accommodation',
}

export const UpsellableOrderItemTypes = [
  OrderItemType.ACCOMMODATION,
]

export enum BookingDetailsV2PageTypes {
  CONFIRMATION_PAGE = 'booking-confirmation',
  MY_ESCAPES_ORDER_PAGE = 'my-escapes-order',
}

import request, { authOptions } from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'
import { agentHubAccountMapper } from './mappers/agentHubMap'

type MarkAgentAsOnboardedResponse = definitions['userSimpleSchema']

async function requestPostMarkAgentAsOnboarded(agentId: string) {
  const response = await request.post<App.ApiResponse<MarkAgentAsOnboardedResponse>, {}>(
    `/api/agent/user/${agentId}/onboarded`,
    {},
    authOptions(),
  )

  return agentHubAccountMapper(response.result)
}

export default requestPostMarkAgentAsOnboarded

import React from 'react'
import { PolymorphicComponentProps } from 'styled-components'
import { StyledTarget } from 'styled-components/dist/types'

function childrenAreEmpty(children: React.ReactNode): boolean {
  return Array.isArray(children) ?
    children.every(childrenAreEmpty) :
      !children
}

const OnlyIfChildren = React.forwardRef(function OnlyIfChildrenInner<AsTarget extends StyledTarget<'web'> = 'div'>(
  props: PolymorphicComponentProps<'web', {}, AsTarget, void>,
  ref: any,
) {
  const {
    as: Component = 'div',
    children,
    ...restProps
  } = props

  // The `any` here isn't ideal, but the dynamic prop typing makes things tricky
  return childrenAreEmpty(children) ? null : <Component {...restProps} ref={ref}>{children}</Component>
})

export default OnlyIfChildren

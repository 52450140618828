import Flag from 'components/Luxkit/Flag'
import React, { ComponentProps } from 'react'
import { PhonePrefixCountry } from './PhoneInput'
import ListItem from 'components/Luxkit/List/ListItem'
import styled from 'styled-components'

const HidableListItem = styled(ListItem)`
  &[hidden] {
    display: none;
  }

`

interface Props extends Pick<ComponentProps<typeof ListItem>, 'size'> {
  onSelect: (country: PhonePrefixCountry) => void;
  selected?: boolean;
  country: PhonePrefixCountry;
  hidden?: boolean;
}

function PhonePrefixItem(props: Props) {
  const { country, selected, onSelect, hidden, size = 'small' } = props

  const onClick = () => {
    onSelect(country)
  }

  return <HidableListItem
    size={size}
    title={`${country.name} (+${country.dialCode})`}
    startIcon={<Flag countryCode={country.iso2.toUpperCase()} />}
    selected={selected}
    onClick={onClick}
    hidden={hidden}
  />
}

export default React.memo(PhonePrefixItem)

import { fetchPlaceAncestorsById, fetchPlaceByGeoCoords, fetchPlaceById, fetchPlaceByName } from 'actions/PlaceActions'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector, useAppDispatch } from './reduxHooks'
import { getTypeaheadSuggestions } from 'api/search'
import GeoContext from 'contexts/geoContext'
import debounce from 'debounce-promise'

export function usePlaceById(placeId = '', disable: boolean = false) {
  const place = useAppSelector(state => state.destination.places[placeId])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!disable && placeId) {
      dispatch(fetchPlaceById(placeId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId, disable])

  return disable ? undefined : place
}

export function usePlaceByCoords(latitude?: number, longitude?: number, levels?: Array<App.PlaceType>): [App.Place | undefined, boolean] {
  const placeKey = `${latitude}-${longitude}`
  const place = useAppSelector(state => state.destination.coordsToPlace[placeKey])
  const fetching = useAppSelector(state => !!state.destination.placesLoading[placeKey])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(fetchPlaceByGeoCoords(latitude, longitude, { levels }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeKey])

  return [place, fetching]
}

export function usePlaceByName(name = ''): App.Place | undefined {
  const place = useAppSelector(state => state.destination.locationToPlace[name])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (name) {
      dispatch(fetchPlaceByName(name))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])
  return place
}

const EmptyArray: Array<string> = []
export function usePlaceAncestorsById(placeId = ''): [Array<string>, boolean] {
  const ancestorIds = useAppSelector(state => state.destination.placeAncestors[placeId] ?? EmptyArray)
  const fetching = useAppSelector(state => !!state.destination.fetchingAncestors[placeId])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (placeId) {
      dispatch(fetchPlaceAncestorsById(placeId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId])

  return [ancestorIds, fetching]
}

interface PlaceBySearchPhraseOptions {
  placeTypes?: Array<App.SearchPlaceType>;
  searchTypes?: Array<App.SearchType>;
  vertical?: App.SearchPlaceVertical;
  disabled?: boolean;
  priority?: App.SearchPlacePriority;
  limit?: number;
  canViewLuxPlusBenefits?: boolean;
}

const EmptyObject = {}
export function usePlacesBySearchPhrase(
  phrase: string,
  options: PlaceBySearchPhraseOptions = EmptyObject,
): [Array<App.SearchItem>, boolean, boolean] {
  const { currentRegionCode } = useContext(GeoContext)
  const [fetching, setFetching] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [results, setResults] = useState<Array<App.SearchItem>>([])
  const optionsRef = useRef<PlaceBySearchPhraseOptions>(options)
  optionsRef.current = options

  const getDebouncedPredictions = useMemo(
    () =>
      debounce((searchPhrase: string) => {
        if (searchPhrase.length >= 1) {
          return getTypeaheadSuggestions(searchPhrase, currentRegionCode, {
            types: optionsRef.current.placeTypes,
            searchTypes: optionsRef.current.searchTypes,
            vertical: optionsRef.current.vertical,
            priority: optionsRef.current.priority,
            limit: optionsRef.current.limit,
            canViewLuxPlusBenefits: optionsRef.current.canViewLuxPlusBenefits,
          })
            .then((searchItems) => {
              setResults(searchItems)
              setError(false)
            })
            .catch(() => setError(true))
            .finally(() => setFetching(false))
        } else {
          // search not long enough, means no results, so return empty result
          return Promise.resolve([])
        }
      }, 500),
    [currentRegionCode],
  )

  useEffect(() => {
    if (!options.disabled) {
      setFetching(true)
      getDebouncedPredictions(phrase)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phrase, options.disabled])

  return [results, fetching, error]
}

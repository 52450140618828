import React from 'react'
import { RouteComponentProps } from 'react-router'

import RedirectWithStatus from 'components/Common/RedirectWithStatus'
import { addRegionToPath } from 'lib/url/searchUrlUtils'
import { getTripURL } from 'tripPlanner/utils'

function TripTabRedirect({ match }: RouteComponentProps<{ regionCode: string, tripId: string }>) {
  return <RedirectWithStatus
    code={301}
    to={addRegionToPath(getTripURL(match.params.tripId), match.params.regionCode)}
  />
}

export default TripTabRedirect

import request, { authOptions } from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'
import { agentHubAccountMapper } from './mappers/agentHubMap'

export type GetAgentStatusResponse = definitions['userSimpleSchema']

async function requestGetAgentAccountById(agentId: string): Promise<App.AgentHubAccount> {
  const response = await request.get<App.ApiResponse<GetAgentStatusResponse>>(`/api/agent/user/${agentId}/pending`, authOptions())
  return agentHubAccountMapper(response.result)
}

export default requestGetAgentAccountById

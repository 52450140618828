import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

function getGiftCardCheckoutItem(state: App.State, item: App.Checkout.GiftCardItem) {
  return comluxgroup.createItem_1_1_0(
    {
      offerId: 'gift-card',
      categoryId: 'gift_card',
      productId: 'gift_card',
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      price: item.amount,
      value: item.amount,
      offerLeadPrice: item.amount,
    },
  )
}
export default getGiftCardCheckoutItem

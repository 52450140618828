import React from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import HelpPane from 'components/Common/HelpPane'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  className?: string;
  heading: string
  isOpen?: boolean
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void
  policyDescriptions: Array<string>
}

function BedbankCancellationPolicyModal(props: Props) {
  const { heading, isOpen, onClose, policyDescriptions } = props
  return <Modal
    title={heading}
    isOpen={isOpen}
    onClose={onClose}
  >
    {policyDescriptions.map(line => <BodyText variant="large" as="p" key={line}>{ line }</BodyText>)}
    <HelpPane />
  </Modal>
}

export default BedbankCancellationPolicyModal

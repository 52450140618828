import { getAutocompleteSuggestions, GoogleAutocompletePlaceItem } from 'api/search'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

const SEARCH_DEBOUNCE_MS = 500

type AutocompleteParams = { latitude?: number, longitude?: number, radius?: number }
export default function useExperiencesPlaceAutocomplete(textInput: string, params: AutocompleteParams) {
  const [predictions, setPredictions] = useState<Array<GoogleAutocompletePlaceItem>>([])
  const [isLoading, setIsLoading] = useState(false)

  const debouncedGetAutocompleSuggestions = useMemo(() => debounce((textInput: string, params: AutocompleteParams) => {
    getAutocompleteSuggestions(textInput, {
      origin: `${params?.latitude},${params?.longitude}`,
      location: `${params?.latitude},${params?.longitude}`,
      radius: params.radius,
    }).then(setPredictions)
      .finally(() => setIsLoading(false))
  }, SEARCH_DEBOUNCE_MS), [])

  useEffect(() => {
    if (!params.latitude || !params.longitude || !textInput) return
    setIsLoading(true)
    debouncedGetAutocompleSuggestions(textInput, params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInput, params?.latitude, params?.longitude])

  return {
    predictions,
    isLoading,
  }
}

import { Brand, CartItem, CartRequestV2 } from 'api/cart'
import config from 'constants/config'
import { arrayToMap, last, sum } from 'lib/array/arrayUtils'
import { isBedbankItem, isCruiseItem, isFlightItem, isGiftCardItem, isLEHotelItem, isTourV1Item } from 'lib/checkout/checkoutUtils'
import { slimifyGeo } from 'lib/geo/geoUtils'
import { getDomainUserId } from 'analytics/snowplow/helpers/domainUserId'
import { createSelector } from 'reselect'
import moment from 'moment'
import { getCartId } from 'selectors/checkoutSelectors'
import { getUtmParams } from 'storage/checkout'
import getAllItemViews, { AllItemViews } from 'checkout/selectors/view/getAllItemViews'
import { isMemberOrHasSubscriptionInTheCart } from './view/luxPlusSubscription'

type GeneralItemTypes = App.Checkout.BookingProtectionItemView | App.Checkout.ExperienceItemView | App.Checkout.InsuranceItemView | App.Checkout.LuxPlusSubscriptionItemView | App.Checkout.SubscriptionJoinItemView

const CRUISE_DEFAULT_OCCUPANCY:App.Occupants = {
  adults: 2,
  children: 0,
  infants: 0,
}

const getCruiseOccupancy = (occupancy: App.Occupants) => {
  if (!occupancy.adults &&
    !occupancy.children &&
    !occupancy.infants)
  {
    return CRUISE_DEFAULT_OCCUPANCY
  }
  return occupancy
}

interface MappedCartItemFromView {
  itemId: string;
  offerType: 'bedbank_hotel' | 'cruise' | App.LEContractedOfferType | 'tour_v2';
  offerSlug?: string;
  totalPrice: number;
  totalMemberPrice?: number;
  value: number | undefined;
  price: number;
  surcharge: number | undefined;
  extraGuestSurcharge: number | undefined;
  propertyFees: number | undefined;
  travelStart?: string;
  travelEnd?: string;
  duration?: number;
}

function tourChargeableOccupancies(occupants: App.Occupants) {
  return occupants.adults + (occupants.children || 0)
}

function cruiseChargeableOccupancies(occupants: App.Occupants) {
  return occupants.adults + (occupants.children || 0)
}

function getAccommodationItemCartPricing(item: App.Checkout.LEAccommodationItemView | App.Checkout.BedbankAccommodationItemView | App.Checkout.TourV2AccommodationItemView | App.Checkout.CruiseAccommodationItemView) {
  const additionalFees = item.surcharge || 0
  const value = item.value
  const price = item.price
  let totalNonMember = item.price + additionalFees
  let totalMember = item.memberPrice > 0 ? item.memberPrice + additionalFees : undefined
  if (item.kind === 'tourV2') {
    totalNonMember = (item.price * tourChargeableOccupancies(item.occupancy)) + additionalFees
    totalMember = item.memberPrice > 0 ? (item.memberPrice * tourChargeableOccupancies(item.occupancy)) + additionalFees : undefined
  }
  if (item.kind === 'cruise' && totalNonMember === 0) {
    const cruiseOccupancy = getCruiseOccupancy(item.occupancy)
    totalNonMember = (item.departure?.lowestOverallPriceDetails?.adultFare || 0) * cruiseChargeableOccupancies(cruiseOccupancy)
  }
  return {
    value,
    price,
    surcharge: item.surcharge,
    extraGuestSurcharge: item.extraGuestSurcharge,
    propertyFees: item.propertiesFees ? sum(item.propertiesFees, fee => fee.propertyFees) : 0,
    itemId: item.item.itemId,
    totalPrice: totalNonMember,
    totalMemberPrice: totalMember,
  }
}

function getAccommodationItemsCartPricing(items: Array<App.Checkout.AccommodationOfferView>) {
  return items.map(item => item.itemViews.map(accommodationItem => (
    {
      offerType: item.offerType,
      offerSlug: item.offer?.slug,
      ...getAccommodationItemCartPricing(accommodationItem),
    }
  )).filter(isMappedCartItemWithTotal))
}

function getFlightTravelFields(item: App.Checkout.FlightItemView): Pick<MappedCartItemFromView, 'travelStart' | 'travelEnd' | 'duration'> {
  const travelStart = item.flights[0]?.journeyFlight?.flights?.[0].departingDate
  const travelEnd = item.flights[1]?.journeyFlight?.flights ? last(item.flights[1]?.journeyFlight?.flights).arrivalDate : undefined
  return { travelStart, travelEnd, duration: travelStart && travelEnd ? moment(travelEnd).diff(travelStart, 'days') : 1 }
}

function getFlightItemsCartPricing(items: Array<App.Checkout.FlightItemView>) {
  return items.map(item => {
    const calculatedPrice = getFlightTotal(item)
    const totalPrice = calculatedPrice > 0 ? calculatedPrice : item.quotedFare || 0
    return {
      ...getFlightTravelFields(item),
      itemId: item.itemId,
      totalPrice,
    }
  })
}

function getFlightTotal(item: App.Checkout.FlightItemView) {
  return item.price +
  (item.otherFees?.flightAtolFee ?? 0) +
  (item.otherFees?.flightBaggageTotal ?? 0) +
  (item.otherFees?.flightBookingFee ?? 0) +
  (item.otherFees?.flightCreditFee ?? 0) +
  (item.otherFees?.flightServiceFee ?? 0)
}

function generalItemMapper(item: GeneralItemTypes) {
  return { itemId: item.itemId, totalPrice: item.price }
}

function getGeneralItemsCartPricing(items: Array<GeneralItemTypes>) {
  return items.map(generalItemMapper)
}

function getInsuranceAndBookingProtectionItemsCartPricing(items: Array<App.Checkout.InsuranceItemView | App.Checkout.BookingProtectionItemView>) {
  return items.map(item => ({
    ...generalItemMapper(item),
    totalMemberPrice: item.memberPrice,
  }),
  )
}

const isMappedCartItemWithTotal = (item: MappedCartItemFromView | undefined): item is MappedCartItemFromView => Boolean(item)

function getItemViewsFlat(allItemView: App.WithDataStatus<AllItemViews>): Map<string, MappedCartItemFromView> {
  return arrayToMap([
    ...getAccommodationItemsCartPricing(allItemView.data.accommodationItemsView.data),
    ...getFlightItemsCartPricing(allItemView.data.flightItemsView.data),
    ...allItemView.data.transferItemsView.data.map(item => ({ itemId: item.itemId, totalPrice: item.transfer.option?.price || 0 })),
    // car hire addons / protection are not currently added to the cart items and therefore not included in the item total
    ...allItemView.data.carHireItemsView.data.map(item => ({ itemId: item.item.itemId, totalPrice: item.price })),
    ...allItemView.data.villaItemsView.data.map(item => ({ itemId: item.item.itemId, totalPrice: item.price })),
    ...getInsuranceAndBookingProtectionItemsCartPricing(allItemView.data.bookingProtectionItemsView.data),
    ...getGeneralItemsCartPricing(allItemView.data.experienceItemsView.data),
    ...getInsuranceAndBookingProtectionItemsCartPricing(allItemView.data.insuranceItemsView.data),
    ...getGeneralItemsCartPricing(allItemView.data.subscriptionItemView.data),
    ...getGeneralItemsCartPricing(allItemView.data.luxPlusSubscriptionItemView.data),
    ...allItemView.data.tourV2ExperienceItemsView.data.map(item => ({ itemId: item.item.itemId, totalPrice: item.price })),
  ].flat(), item => item.itemId)
}

function mapCheckoutItemToCart(item: App.Checkout.AnyItem, viewItem: MappedCartItemFromView | undefined, luxPlusMemberPriceApplies: boolean): CartItem {
  const nonMemberTotal = viewItem?.totalPrice || 0
  const memberTotal = viewItem?.totalMemberPrice
  const total = luxPlusMemberPriceApplies && memberTotal ? memberTotal : nonMemberTotal
  const totals = {
    total,
    ...memberTotal && memberTotal > 0 ? {
      memberTotal,
      nonMemberTotal,
    } : {},
  }
  const {
    offerSlug,
    offerType = 'hotel',
    propertyFees,
    surcharge,
    value,
    price,
    duration,
    extraGuestSurcharge,
    travelStart,
    travelEnd,
  } = viewItem || {}
  if (isGiftCardItem(item)) return { ...item, total: item.amount }
  if (isFlightItem(item)) return { ...item, ...totals, travelStart, travelEnd, duration }
  if (isLEHotelItem(item) || isBedbankItem(item)) return { ...item, ...totals, offerSlug, offerType, propertyFees, surcharge, value, price, extraGuestSurcharge }
  // includes cruise v1 as they are tours v1
  if (isTourV1Item(item)) return { ...item, ...totals, value, price }
  if (isCruiseItem(item)) return { ...item, ...totals, occupancy: getCruiseOccupancy(item.occupancy) }
  return { ...item, ...totals }
}

const getCartItemTotal = (item:CartItem) => 'total' in item ? item.total || 0 : 0

function getCartTotal(items: Array<CartItem>) {
  return sum(items.map(getCartItemTotal))
}

/**
 *
 * Creates payload for cart service as per ServiceCart contract based on the state of the items
 * Combines the state.checkout.cart.items with the item views to add total amounts as this is now required in service cart
 * for v3 of restore cart
 *
 */
export const cartServicePayloadSelector = createSelector(
  getAllItemViews,
  (state: App.State) => state.utm,
  (state: App.State) => state.geo,
  (state: App.State) => state.checkout.cart.items,
  getCartId,
  isMemberOrHasSubscriptionInTheCart,
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.checkout.processing,
  (itemViews, utm, geo, cartItems, cartId, luxPlusMemberPriceApplies, postPurchase, isProcessing): { transactionKey: string, payload: CartRequestV2 } | undefined => {
    // We don't want to send carts through if we're currently processing the order (i.e. purchasing it)
    if (isProcessing) return
    // We don't need carts for post purchase changes, it's not really something a user can "return" to
    if (postPurchase) return
    if (!itemViews.hasRequiredData) return
    if (!cartItems.length) return
    const flatItems = getItemViewsFlat(itemViews)
    const items:Array<CartItem> = cartItems.map(item => mapCheckoutItemToCart(item, flatItems.get(item.itemId), luxPlusMemberPriceApplies))
    if (!items.length) return
    return {
      transactionKey: cartId,
      payload: {
        brand: config.BRAND as Brand,
        domainUserId: getDomainUserId(),
        utm_params: getUtmParams(utm),
        geo: slimifyGeo(geo),
        total: getCartTotal(items),
        items,
      },
    }
  },
)

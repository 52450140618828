import { useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { fetchBestPriceForOffer } from 'actions/OfferActions'
import { getOffersBestPrices, OfferBestPriceData } from 'selectors/offerSelectors'
import { fetchRatesForBedbankOffersById } from 'actions/BedbankOfferActions'
import { isValidOccupancy } from 'lib/offer/occupancyUtils'

function useOffersBestPrices(
  offers: Array<App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary>,
  filters: App.OfferListFilters,
): Record<string, OfferBestPriceData>;
function useOffersBestPrices(
  offer: App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary,
  filters: App.OfferListFilters,
): OfferBestPriceData;
function useOffersBestPrices(
  offerOrOffers: App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary |
    Array<App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary>,
  filters: App.OfferListFilters,
) {
  const inputIsArray = Array.isArray(offerOrOffers)
  const offers = useMemo(
    () => inputIsArray ? offerOrOffers : [offerOrOffers],
    [inputIsArray, offerOrOffers],
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (filters.checkIn && filters.checkOut && filters.rooms && isValidOccupancy(...filters.rooms)) {
      for (const offer of offers) {
        if (offer.type !== OFFER_TYPE_BED_BANK) {
          dispatch(fetchBestPriceForOffer(offer, {
            checkIn: filters.checkIn,
            checkOut: filters.checkOut,
            occupants: filters.rooms,
          }))
        }
      }

      const allBedbankOffers = offers.filter(offer => offer.type === OFFER_TYPE_BED_BANK) as Array<App.BedbankOffer>
      if (allBedbankOffers.length) {
        dispatch(fetchRatesForBedbankOffersById(allBedbankOffers.map(o => o.id), filters.rooms, filters.checkIn, filters.checkOut))
      }
    }
  }, [dispatch, filters.checkIn, filters.checkOut, filters.rooms, offers])

  const bestPrices = useAppSelector((state) => getOffersBestPrices(state, { offers, filters }))
  return inputIsArray ? bestPrices : bestPrices[offerOrOffers.id]
}

export default useOffersBestPrices

import { searchShowOnMapClick } from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import FormatInlineList from 'components/utils/Formatters/FormatInlineList'
import React, { MouseEventHandler, useCallback } from 'react'

interface Props {
  locations: Array<string>
  mapUrl?: string
}

function SearchTileRowOverlineLocation({ locations, mapUrl }: Props) {
  const mapViewClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.stopPropagation()
    addGTMEvent(searchShowOnMapClick())
  }, [])

  return <BodyText variant="small" colour="neutral-two" className={SearchTileRowOverlineLocation.name}>
    <FormatInlineList list={locations} type="unit" />
    {!!mapUrl && <>{' '}&middot; <TextLink weight="regular" to={mapUrl} onClick={mapViewClick}>
      Show on map
    </TextLink></>}
  </BodyText>
}

export default SearchTileRowOverlineLocation

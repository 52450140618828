import { useMemo } from 'react'

import { buildSuggestedDatesParamsKey, isSearchStreamingSupported } from 'lib/search/searchUtils'
import { calculateDiscount } from 'lib/payment/calculateDiscount'
import { checkCanViewLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { getSumExtraGuestsSurcharge } from 'lib/checkout/cartReservationUtils'
import { logNewRelic } from '../../services/newRelic'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import { useAppSelector } from 'hooks/reduxHooks'
import useOfferMetaData from '../Offers/useOfferMetaData'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'

interface Props {
  offer: App.Offer | App.OfferSummary
  pkg?: App.Package
  rate?: App.OfferAvailableRate
  duration?: number
  filters?: App.OfferListFilters
  isPerNightPricingTestEnabled?: boolean
  useRealSearchPrices?: boolean // use prices directly from search api
}

interface Return {
  totalPrice: number
  totalMemberPrice?: number
  totalValueBase: number
  discountPercent: number
  hotelPrice: number
  hotelMemberPrice: number
  showMemberPrice: boolean
  propertyFees: number
  showPerNightPricing?: boolean
  suggestedDates?: App.OfferSuggestedDates
}

export const useDirectSearchPrices = ({ filters, offerId }: {filters: App.OfferListFilters, offerId: string}): App.OfferListMetadataPricing | undefined => {
  const allowSortByPrice = !!useOptimizelyExperiment(OptimizelyFeatureFlags.searchStreamingSortByPrice)
  const offerMetadata = useOfferMetaData(offerId, filters)
  if (isSearchStreamingSupported(filters, { allowSortByPrice })) return offerMetadata?.pricing
}

export const useSearchPrices = ({ offer, pkg, rate, duration, filters, useRealSearchPrices = false }: Props): Return => {
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const offerMetadata = useOfferMetaData(offer.id, filters)
  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const isPerNightPricingTestEnabled = !!useAppSelector((state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(filters?.flexibleMonths, filters?.flexibleNights, filters?.rooms)
  const suggestedDates = useAppSelector((state) => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))
  const isCruise = (offer.holidayTypes ?? []).map(i => i.toLowerCase()).includes('cruises')
  const showPerNightPricing = isPerNightPricingTestEnabled && !isCruise
  const isFlexibleSearch = filters?.flexibleMonths || filters?.flexibleNights
  const rateOrPkg = (rate ?? pkg)
  const baseMemberPrice = rateOrPkg?.memberPrice || 0
  const showMemberPrice = luxPlusEnabled && baseMemberPrice > 0
  const rateOrPkgMemberPrice = rate?.memberPriceWithSurcharge ?? pkg?.memberPrice
  const rooms = useMemo(() => filters?.rooms || [], [filters?.rooms])
  const priceMultiplier = rate ? 1 : (rooms.length || 1)
  const propertyFees = (rate?.propertyFees ?? pkg?.propertyFees ?? 0) * priceMultiplier
  const extraGuestSurcharges = useMemo(() => getSumExtraGuestsSurcharge(rooms, offer, pkg), [pkg, rooms, offer])
  const hotelPrice = ((rateOrPkg?.price ?? 0) + (pkg?.surcharge ?? 0)) * priceMultiplier + extraGuestSurcharges
  const hotelMemberPrice = showMemberPrice ? ((rateOrPkgMemberPrice ?? 0 + (pkg?.surcharge ?? 0)) * priceMultiplier + extraGuestSurcharges) : 0
  const totalPrice = isFlexibleSearch && suggestedDates?.price ? suggestedDates?.price : hotelPrice + propertyFees
  const pkgValueOrMemberValue = canViewLuxPlusBenefits ? pkg?.memberValue : pkg?.value
  const totalValueBase = rate?.hotelValue ?? suggestedDates?.value ?? (pkgValueOrMemberValue ?? 0 * (rooms.length || 1) + extraGuestSurcharges)
  const discountPercent = pkg?.discountPercent || calculateDiscount(showMemberPrice && canViewLuxPlusBenefits ? hotelMemberPrice : hotelPrice, totalValueBase)

  const totalMemberPrice = useMemo(() => {
    if (showMemberPrice) {
      let memberPrice:number
      if (isFlexibleSearch && suggestedDates?.memberPrice) {
        memberPrice = suggestedDates.memberPrice
      } else {
        memberPrice = hotelMemberPrice + propertyFees
      }
      return memberPrice && showPerNightPricing ? Math.ceil(memberPrice / (duration || 1)) : memberPrice
    }
  }, [showMemberPrice, isFlexibleSearch, suggestedDates, showPerNightPricing, duration, hotelMemberPrice, propertyFees])

  if (useRealSearchPrices) {
    if (!offerMetadata?.pricing) {
      logNewRelic('No pricing found for offer from search', { offerId: offer.id, filters }, 'error')
      return {
        totalPrice: 0,
        totalMemberPrice: 0,
        totalValueBase: 0,
        discountPercent: 0,
        hotelPrice: 0,
        hotelMemberPrice: 0,
        showMemberPrice: false,
        propertyFees: 0,
        showPerNightPricing,
        suggestedDates,
      }
    }

    const fallbackPrice = offer.lowestPricePackage
    const pricing = offerMetadata?.pricing

    const discountPercent = (() => {
      if (showMemberPrice && canViewLuxPlusBenefits && pricing.lowestMemberPrice !== undefined && pricing.lowestPriceValue !== undefined) {
        return calculateDiscount(pricing.lowestMemberPrice, pricing.lowestPriceValue)
      }
      if (pricing.lowestPrice && pricing.lowestPriceValue !== undefined) {
        return calculateDiscount(pricing.lowestPrice, pricing.lowestPriceValue)
      }
      return calculateDiscount(fallbackPrice?.price ?? 0, fallbackPrice?.value ?? 0)
    })()

    const prices = {
      totalPrice: pricing.lowestPrice ?? fallbackPrice?.price ?? 0,
      totalMemberPrice: pricing?.lowestMemberPrice ?? fallbackPrice?.memberPrice ?? 0,
      totalValueBase: pricing?.lowestPriceValue ?? fallbackPrice?.value ?? 0,
      discountPercent,
      hotelPrice: pricing.lowestPrice ?? fallbackPrice?.price ?? 0,
      hotelMemberPrice: pricing.lowestMemberPrice ?? fallbackPrice?.memberPrice ?? 0,
      showMemberPrice,
      propertyFees: pricing.lowestPricePropertyFees ?? fallbackPrice?.propertyFees ?? 0,
      showPerNightPricing,
      suggestedDates,
    }

    return prices
  }

  return {
    totalPrice,
    totalMemberPrice,
    totalValueBase,
    discountPercent,
    hotelPrice,
    hotelMemberPrice,
    showMemberPrice,
    propertyFees,
    showPerNightPricing,
    suggestedDates,
  }
}

import React from 'react'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import CarHireSearchBar from 'components/CarHire/CarHireSearchBar/CarHireSearchBar'
import { useGlobalCarHireSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalCarHireSearch'
import CarHireMobileSearchControls from 'components/CarHire/CarHireMobileSearchControls/CarHireMobileSearchControls'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

function GlobalCarHireSearchTabContent() {
  const { globalCarHireSearchDispatch, globalCarHireSearchState } = useGlobalCarHireSearchContext()

  return <GlobalSearchContextProvider
    state={globalCarHireSearchState}
    dispatch={globalCarHireSearchDispatch}
  >
    <CSSBreakpoint min="tablet">
      <CarHireSearchBar />
    </CSSBreakpoint>
    <CSSBreakpoint max="mobile">
      <CarHireMobileSearchControls />
    </CSSBreakpoint>
  </GlobalSearchContextProvider>
}

export default GlobalCarHireSearchTabContent

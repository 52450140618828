import { CHECKOUT_ITEM_TYPE_EXPERIENCE, CHECKOUT_ITEM_TYPE_TRANSFER } from 'constants/checkout'
import { EXPERIENCE_ANY_DATE } from 'constants/experience'
import { isExperienceTicket } from 'lib/experiences/experienceUtils'
import uuidV4 from 'lib/string/uuidV4Utils'

interface TicketPrimitive {
  ticket: App.ExperienceItemTicket | App.PackageAddonItem,
  count: number;
}

export interface ExperienceDetails {
  date?: string;
  time?: string;
  language?: string;
  pickupPoint?: string;
  redemptionLocation?: string;
  ticketMode?: string;
}

export function exceedsTicketAvailability(count: number, availability: number) {
  if (availability === -1) { return false } // -1 means infinite availability
  return count > availability
}

export function generateExperienceCheckoutTicket(item: TicketPrimitive): App.Checkout.ExperienceItemTicket {
  const ticket = item.ticket
  if (isExperienceTicket(ticket)) {
    return {
      ticketId: ticket.id,
      name: ticket.name,
      count: item.count,
      date: ticket.date,
      orderItemIds: ticket.orderItemIds,
      unavailable: exceedsTicketAvailability(item.count, ticket.purchaseLimit.max),
    }
  } else {
    return {
      ticketId: ticket.idSalesforceExternal,
      name: ticket.fareTypeText,
      count: item.count,
    }
  }
}

interface ExperienceItemOptions {
  isBookingBNBL?: boolean;
  cartItem?: App.Checkout.ExperienceItem,
  isBuyNowBookLater?: boolean;
  isGift?: boolean;
}

export function generateExperienceCheckoutItem(
  experience: App.ExperienceOffer,
  details: ExperienceDetails,
  tickets: Array<TicketPrimitive>,
  options: ExperienceItemOptions = {},
): App.Checkout.ExperienceItem {
  const { cartItem, isBookingBNBL, isBuyNowBookLater, isGift } = options
  const item: App.Checkout.ExperienceItem = {
    itemId: cartItem?.itemId || uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_EXPERIENCE,
    bookingType: experience.bookingType,
    tickets: tickets.map(generateExperienceCheckoutTicket),
    experienceId: experience.id,
    leExclusive: experience.leExclusive,
    date: details.date ?? EXPERIENCE_ANY_DATE,
    isBuyNowBookLater: isBuyNowBookLater ?? false,
    isGift: !!isGift,
    isBookingBNBL,
    time: details.time,
    languageId: details.language,
    pickupPointId: details.pickupPoint,
    redemptionLocationId: details.redemptionLocation,
    ticketModeKey: details.ticketMode,
  }

  return item
}

export function generateTransferCheckoutItem(
  experience: App.ExperienceOffer,
  transfer: App.ExperienceTransferView,
  cartItem?: App.Checkout.TransferItem,
): App.Checkout.TransferItem {
  const item: App.Checkout.TransferItem = {
    itemId: cartItem?.itemId || uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_TRANSFER,
    transfer,
    experienceId: experience.id,
    offerType: experience.productType,
  }

  return item
}

import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Clickable from 'components/Common/Clickable'
import Heading from 'components/Luxkit/Typography/Heading'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import SlideDown from 'components/Common/SlideDown'
import useToggle from 'hooks/useToggle'

const InnerContent = styled.div`
  margin-top: ${rem(12)};
`

const ClickableTitle = styled(Clickable)`
  width: 100%;
  display: flex;

  > * + * {
    margin-left: ${rem(8)};
  }
`

interface Props {
  onClick?: (id: string) => void;
  onOpenChange?: (nextOpen: boolean) => void;
  isOpen?: boolean;
  defaultOpen?: boolean;
  className?: string;
  title: string;
  children: React.ReactNode;
}

function ExperienceDetailsModalAboutAccordion({
  title,
  children,
  onClick,
  className,
  isOpen,
  defaultOpen,
  onOpenChange,
}: Props) {
  const [uncontrolledOpen, toggleUncontrolledOpen] = useToggle(defaultOpen)
  const isControlled = isOpen !== undefined

  const onTitleClick = () => {
    if (!isControlled) {
      onOpenChange?.(!uncontrolledOpen)
      toggleUncontrolledOpen()
    }
    onClick?.(title)
  }

  const actuallyOpen = isControlled ? isOpen : uncontrolledOpen

  return (
    <div className={className}>
      <ClickableTitle onClick={onTitleClick}>
        <DropdownChevron
          size="S"
          direction={actuallyOpen ? 'down' : 'right'}
          open={actuallyOpen}
          colour="primary"
        />
        <Heading variant="heading6">{title}</Heading>
      </ClickableTitle>
      <SlideDown show={actuallyOpen} animateOpacity>
        <InnerContent>
          {children}
        </InnerContent>
      </SlideDown>
    </div>
  )
}

export default ExperienceDetailsModalAboutAccordion

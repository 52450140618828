import React from 'react'
import Caption from '../Typography/Caption'
import FormatCurrency from 'components/Common/FormatCurrency'

interface Props {
  value: number
  /**
   * @default roundedDollar
   */
  priceFormat?: React.ComponentProps<typeof FormatCurrency>['format']
}

function PriceRowSale(props: Props) {
  const { value, priceFormat = 'roundedDollar' } = props

  return <Caption
    as="s"
    className={PriceRowSale.name}
    variant="large"
    colour="neutral-two"
  >
    Was <FormatCurrency value={value} format={priceFormat} />
  </Caption>
}

export default PriceRowSale

export interface MappedPlace {
  city: string;
  region: string;
  country: string;
}

export const mapCityLevelPlace = (place: App.Place): MappedPlace | undefined => {
  const canonicalName = place.canonicalName?.split(', ')
  if (!canonicalName || canonicalName.length < 3) return
  return {
    city: canonicalName?.[0],
    region: canonicalName?.[1],
    country: canonicalName?.[2],
  }
}

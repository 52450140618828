import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHairDryerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M5.5977 7.3909c0-1.5453 1.277-2.7561 2.8-2.7561s2.8 1.2108 2.8 2.756c0 1.5454-1.277 2.7562-2.8 2.7562s-2.8-1.2109-2.8-2.7561Zm2.8-.7561c-.4653 0-.8.3616-.8.756 0 .3945.3347.7562.8.7562.4652 0 .8-.3617.8-.7561 0-.3945-.3348-.7561-.8-.7561Z" clipRule="evenodd"/>
    <path fill="currentColor" fillRule="evenodd" d="M4.1998 2.9585C5.3862 2.1836 6.7532 2 7.6078 2c.4629 0 1.1875.0955 2.0072.2273.8447.1357 1.8686.3234 2.9699.5322.6281.119 1.2814.245 1.9438.3727 1.6735.3225 3.4055.6563 4.933.9188C20.9072 4.2994 22 5.5468 22 7.0403v.7953c0 1.423-.997 2.6564-2.4035 2.9348-1.604.3176-4.1712.8168-6.5345 1.237-.9791.174-1.9273.3353-2.7598.4646v5.011c0 .9363-.5545 1.7431-1.353 2.1099v1.4069c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1.4383a2.3196 2.3196 0 0 1-1.152-1.2948L3.062 10.6428C2.4116 9.8031 2 8.7163 2 7.3902c0-2.2004.9483-3.6142 2.1998-4.4317ZM5.2936 4.633C4.6125 5.0777 4 5.859 4 7.3903c0 1.2055.495 2.0204 1.1664 2.5561.701.5593 1.626.8341 2.4414.8341.3032 0 .962-.0723 1.9024-.2112.9145-.1351 2.029-.3226 3.2017-.5311 1.2273-.2182 2.5117-.4582 3.6865-.6828v-3.829c-.7553-.1426-1.5205-.2901-2.2693-.4344a653.535 653.535 0 0 0-1.9167-.3675c-1.0985-.2082-2.0993-.3915-2.9146-.5226C8.4575 4.067 7.8865 4 7.6078 4c-.6313 0-1.5682.1457-2.3143.633Zm13.1048 1.2622V8.968c.29-.0569.5613-.1103.8097-.1595.4592-.091.7919-.494.7919-.973v-.7952c0-.4929-.3648-.9302-.877-1.0182a117.9078 117.9078 0 0 1-.7246-.127ZM7.6078 12.7805c.1854 0 .4212-.0149.6944-.0411v4.7436a.3207.3207 0 0 1-.6225.1083l-1.8204-5.074c.5885.1781 1.1861.2632 1.7485.2632Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineHairDryerIcon

import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { getPlural } from 'lib/string/pluralize'
import { dateIsAfter, dateDifference } from 'lib/datetime/dateUtils'
import DateInput from 'components/Common/Form/Input/DateInput'

interface Props extends Partial<React.ComponentProps<typeof DateInput>>{
  format?: 'DD/MM/YYYY' | 'MM/DD/YYYY',
  travellerType?: string,
  refDate?: Date,
}

const FUTURE_ERROR_MSG = 'Cannot be in the future'
const MAX_SENSIBLE_AGE = 150

const isFutureDate = (date: Date) => { return dateIsAfter(date, new Date()) }
const yearDifferenceFromNow = (date: Date | undefined, refDate: Date) => {
  const difference = dateDifference(refDate, date)
  return Math.round(difference.days / 365)
}
const monthDifferenceFromNow = (date: Date | undefined, refDate: Date) => {
  const difference = dateDifference(refDate, date)
  return Math.round(difference.days / 30)
}

function DateOfBirthInput(props: Props) {
  const { min, max, format = 'DD/MM/YYYY', travellerType = 'adult', ...rest } = props

  const validationBounds = useMemo(() => {
    const refDate = props.refDate || new Date()
    return {
      upperAgeBound: yearDifferenceFromNow(min, refDate),
      lowerAgeBound: yearDifferenceFromNow(max, refDate),
      upperMonthsBound: monthDifferenceFromNow(min, refDate),
      lowerMonthsBound: monthDifferenceFromNow(max, refDate),
    }
  }, [max, min, props.refDate])

  const getInvalidMessage = useCallback((e: HTMLInputElement): string | undefined => {
    if (e.validationMessage === 'Invalid date') { return e.validationMessage }
    if (e.validity.patternMismatch) { return undefined }

    const inputDateValue = moment(e.value, format).toDate()
    if (isFutureDate(inputDateValue)) { return FUTURE_ERROR_MSG }

    const { upperAgeBound, lowerAgeBound, upperMonthsBound, lowerMonthsBound } = validationBounds

    let message: string | undefined
    if (e.validity.customError && e.validationMessage) {
      // Error message for traveller that is an adult or a child (with adult pricing)
      if (travellerType === 'adult' || (travellerType === 'child' && upperAgeBound === MAX_SENSIBLE_AGE)) {
        const isTooYoung = e.validationMessage.startsWith('Must be before')
        message = isTooYoung ? `Must be over ${lowerAgeBound} years old` : 'Invalid Date'
      } else if (lowerMonthsBound > 0 && lowerMonthsBound < 12 && upperAgeBound) {
        message = `Must be between ${lowerMonthsBound} ${getPlural('month', lowerMonthsBound)}-${upperAgeBound} years old`
      } else if (lowerAgeBound || upperAgeBound) {
        message = `Must be between ${lowerAgeBound}-${upperAgeBound} years old`
      } else {
        message = `Must be between ${lowerMonthsBound}-${upperMonthsBound} months old`
      }
    }
    return message
  }, [format, travellerType, validationBounds])

  return (
    <DateInput
      {...rest}
      min={min}
      max={max}
      displayFormat={format}
      getInvalidMessage={getInvalidMessage}
    />
  )
}

export default DateOfBirthInput

import { useCallback, useEffect, useState } from 'react'

import checkAppVersion from 'components/utils/checkAppVersion'
import config from 'constants/config'

function useCheckAppVersion() {
  const [shouldCheckAppVersion, setShouldCheckAppVersion] = useState(true)
  const [isPageVisible, setIsPageVisible] = useState(true)

  const check = useCallback(async() => {
    if (config.MOBILE_APP_CUSTOMISATIONS || !!window.whiteLabelAppConfig) {
      if (shouldCheckAppVersion) {
        const result = await checkAppVersion()

        // For major or minor updates, force update
        if (result?.isMajorOrMinor) {
          alert(`A new version (${result.storeVersion}) of the app is available. Please update to keep enjoying our great offers.`)
          window.location.href = result.storeLink
        } else if (result?.isPatch) { // For patch updates, recommend an update with a confirmation dialog
          setShouldCheckAppVersion(false)
          if (confirm(`A new version (${result.storeVersion}) of the app is available. Please update to keep enjoying our great offers.`)) {
            return window.location.href = result.storeLink
          }
        } else {
          setShouldCheckAppVersion(false)
        }
      }
    }
  }, [shouldCheckAppVersion, setShouldCheckAppVersion])

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (isPageVisible) {
      check()
    }
  }, [check, isPageVisible])
}

export default useCheckAppVersion

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineDryerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6.0063 5.0056c-.5523 0-1 .4477-1 1s.4477 1 1 1h1c.5523 0 1-.4477 1-1s-.4477-1-1-1h-1ZM9.0016 6.0056c0-.5523.4477-1 1-1h1c.5522 0 1 .4477 1 1s-.4478 1-1 1h-1c-.5523 0-1-.4477-1-1Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M12 8.0333c-3.0376 0-5.5 2.4625-5.5 5.5 0 3.0376 2.4624 5.5 5.5 5.5s5.5-2.4624 5.5-5.5c0-3.0375-2.4624-5.5-5.5-5.5Zm-3.5 5.5c0-1.933 1.567-3.5 3.5-3.5s3.5 1.567 3.5 3.5-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5Z" clipRule="evenodd"/>
    <path fill="currentColor" fillRule="evenodd" d="M5 2C3.3432 2 2 3.3432 2 5v14c0 1.6569 1.3432 3 3 3h14c1.6569 0 3-1.3431 3-3V5c0-1.6568-1.3431-3-3-3H5ZM4 5c0-.5523.4477-1 1-1h14c.5523 0 1 .4477 1 1v14c0 .5523-.4477 1-1 1H5c-.5523 0-1-.4477-1-1V5Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineDryerIcon

import OfferInclusionsList from 'components/Common/Inclusions/OfferInclusionsList'
import Modal from 'components/Luxkit/Modal/Modal'
import ModalContext from 'contexts/ModalContext'
import { getHotelOfferUrl } from 'lib/offer/offerPageURL'
import React, { useCallback, useContext } from 'react'
import SearchTileStackInclusionsList from '../shared/SearchTileStackInclusionsList'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
}

function BedbankLTSSearchTileFeatures(props: Props) {
  const { offer } = props

  const showModal = useContext(ModalContext)
  const openAllInclusionsModal = useCallback(() => {
    showModal(<Modal
      title={offer.property.name}
      primaryActionTo={getHotelOfferUrl(offer.id, offer.type)}
      primaryActionText="View offer"
    >
      <OfferInclusionsList inclusions={offer.inclusions!} />
    </Modal>)
  }, [showModal, offer])

  if (!offer.inclusions?.length) return null

  return <SearchTileStackInclusionsList
    inclusions={offer.inclusions}
    truncateAfter={7}
    onShowMoreClick={openAllInclusionsModal}
  />
}

export default BedbankLTSSearchTileFeatures

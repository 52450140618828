import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineExcursionIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m21.8271 20.4392-1.1896-1.487c.793-.8923 1.2887-1.9828 1.2887-3.2715 0-1.2888-.4957-2.4784-1.3879-3.3706V2.9914c0-.2974-.1983-.5949-.3965-.7931C19.8444 2 19.547 2 19.2496 2.099l-5.2542 1.8836L8.7412 2.099c-.0991 0-.1983 0-.2974-.0991h-.3965L2.5948 3.9827C2.2974 4.181 2 4.5775 2 4.9741v13.9781c0 .2974.1983.5948.3965.793.1983.0992.3966.1983.5949.1983.099 0 .1982 0 .2974-.0991l5.155-1.8836 5.5516 1.5862c.7931.5948 1.8836.9913 2.974.9913.7931 0 1.4871-.1983 2.0819-.4957l1.1896 1.5862c.1983.1983.4957.3965.7931.3965.1983 0 .3965-.0991.5948-.1982.3965-.3966.4957-.9914.1983-1.3879Zm-3.2715-16.06v6.7413c-.4957-.1983-1.0905-.2975-1.5862-.2975-.6939 0-1.2887.0992-1.8835.3966V5.668l3.4697-1.2888ZM3.9827 5.668l3.4697-1.2888v11.9954l-3.4697 1.2888V5.668ZM9.336 4.3792l3.7672 1.388v6.8403c-.5948.8922-1.0905 1.8836-1.0905 3.0732 0 .4957.0991.8922.1982 1.3879l-2.8749-.7931V4.3792Zm4.8577 11.3015c0-1.487 1.2887-2.7758 2.7757-2.7758 1.4871 0 2.7758 1.2888 2.7758 2.7758 0 1.487-1.2887 2.7758-2.7758 2.7758-1.487 0-2.7757-1.1897-2.7757-2.7758Z"/>
  </SvgIcon>
}

export default LineExcursionIcon

import requestUtils from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-business'

type BusinessApprovalPolicyResponse = paths['/api/business-traveller/businesses/{businessId}/approval-policies']['get']['responses'][200]['content']['application/json']

type BusinessApprovalPolicyResult = paths['/api/business-traveller/businesses/{businessId}/approval-policies']['get']['responses'][200]['content']['application/json']['result']

function mapPolicies(policies: BusinessApprovalPolicyResult): Array<BusinessTraveller.ApprovalPolicy> {
  const mappedBudget: Array<BusinessTraveller.ApprovalPolicy> = policies.map((policy) => {
    return {
      id: policy.id,
      name: policy.name,
      hotelScope: policy.hotelScope,
      hotelPriceBuffer: policy.hotelPriceBuffer,
      flightScope: policy.flightScope,
      flightPriceBuffer: policy.flightPriceBuffer,
      travellers: policy.travellers,
      approvers: policy.approvers,
    }
  })
  return mappedBudget
}

interface Params {
  businessId: string;
}

async function requestGetPolicy(params: Params) {
  const { businessId } = params

  try {
    const policies = await requestUtils.get<BusinessApprovalPolicyResponse>(
      `/api/business-traveller/businesses/${businessId}/approval-policies`,
      { credentials: 'include' },
    )

    const mappedPolicies = mapPolicies(policies.result)

    // Currently only 1 approval policy for MVP
    return mappedPolicies.length ? mappedPolicies[0] : undefined
  } catch (error) {
    return Promise.reject(error)
  }
}

export default requestGetPolicy

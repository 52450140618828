import moment from 'moment'

import { CreateFlightRequestBody, getAirportLocation } from '../common'

import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { FlightsClassTypes, FlightsFareTypes } from 'constants/flight'
import { min } from 'lib/array/arrayUtils'
import { isJourneyV2Items, isJourneyV2ReturnFares } from 'lib/flights/flightUtils'
import { flightSearchQueryStringToBookmarkId } from 'tripPlanner/components/Bookmark/BookmarkButton/utils'
import { SavedFlightSearchData } from 'tripPlanner/types/tripItem'
import { convertPlaceFromAirport } from 'tripPlanner/utils'

export type BookmarkableFareType =
  | FlightsFareTypes.ONE_WAY
  | FlightsFareTypes.RETURN

export const bookmarkableFareTypes = new Set<BookmarkableFareType>([
  FlightsFareTypes.ONE_WAY,
  FlightsFareTypes.RETURN,
])

interface Params {
  originAirport: App.Airport
  destinationAirport: App.Airport
  departDate: string
  returnDate: string
  flightSearchQueryString: string
  flights: Array<App.Journey>
  flightsV2: Array<App.JourneyV2>
  currentRegionCode: string
  fareType: BookmarkableFareType
  fareClass: string
  passengers: App.Occupants
  currencyCode: string
  templateId?: string
  templateItemId?: string
}

export async function createFlightItemPayload({
  originAirport,
  destinationAirport,
  departDate,
  returnDate,
  flightSearchQueryString,
  flights,
  flightsV2,
  currentRegionCode,
  fareType,
  fareClass,
  passengers,
  currencyCode,
  templateId,
  templateItemId,
}: Params): Promise<CreateFlightRequestBody> {
  if (!originAirport || !destinationAirport) {
    throw new Error(
      'Can not save flight without both departure and arrival airports',
    )
  }

  let adultPrice: number | undefined
  let totalPrice: number

  if (flightsV2 && flightsV2.length > 0) {
    const cheapestJourney = min(
      flightsV2,
      (journey) => journey.price.all.totalFare,
    )
    adultPrice = cheapestJourney?.price.adult.totalFare
    totalPrice = cheapestJourney?.price.all.totalFare!
  } else {
    const cheapestJourney = min(flights, (flight) => flight.cost)!
    adultPrice = cheapestJourney.priceBreakdown?.adults.cost
    totalPrice = cheapestJourney.cost
  }

  const itemCode = flightSearchQueryStringToBookmarkId(flightSearchQueryString)

  const flightSearchExtraData: SavedFlightSearchData = {
    originAirportCode: originAirport.code,
    originAirportName: originAirport.name,
    destinationAirportCode: destinationAirport.code,
    destinationAirportName: destinationAirport.name,
    departDate,
    returnDate,
    fareClass: fareClass || FlightsClassTypes.ECONOMY,
    fareType,
    travellerCounts: passengers,
    adultPrice,
    totalPrice,
    currencyCode,
    regionCode: currentRegionCode,
  }

  const [departureAirportLocation, arrivalAirportLocation] = await Promise.all([
    getAirportLocation(originAirport.code),
    getAirportLocation(destinationAirport.code),
  ])

  const departureAirport = {
    code: originAirport.code,
    name: originAirport.name,
    latitude: departureAirportLocation?.lt,
    longitude: departureAirportLocation?.lg,
  }
  const arrivalAirport = {
    code: destinationAirport.code,
    name: destinationAirport.name,
    latitude: arrivalAirportLocation?.lt,
    longitude: arrivalAirportLocation?.lg,
  }

  // Flight bookmark represents the flight search, not the individual flights.
  // So we still have just one item regardless of whether it's one-way or return.
  const payload: CreateFlightRequestBody = {
    type: 'FLIGHT',
    isBooked: false,
    sourceType: 'LE_Flight',
    startDate: moment(departDate).format(ISO_DATE_FORMAT),
    startTime: undefined,
    endDate:
      fareType === 'return' ?
        moment(returnDate).format(ISO_DATE_FORMAT) :
        undefined,
    endTime: undefined,
    startPlace: {
      ...convertPlaceFromAirport(departureAirport),
      region: undefined,
    },
    endPlace: {
      ...convertPlaceFromAirport(arrivalAirport),
      region: undefined,
    },
    originAirportCode: originAirport.code,
    destinationAirportCode: destinationAirport.code,
    savedItemData: flightSearchExtraData,
    code: itemCode,
    templateId,
    templateItemId,
  }

  return payload
}

export function getMinJourneyPrice(
  journeys: Array<App.Journey> | Array<App.JourneyV2>,
): {
  adultPrice: number | undefined
  totalPrice: number
} {
  if (isJourneyV2Items(journeys)) {
    const cheapestJourney = min(
      journeys,
      (journey) => journey.price.all.totalFare,
    )
    if (!cheapestJourney) {
      return {
        adultPrice: undefined,
        totalPrice: 0,
      }
    }
    const isReturn = isJourneyV2ReturnFares(cheapestJourney)
    return {
      adultPrice: isReturn ? cheapestJourney.price.adult.totalRoundTripPrice : cheapestJourney.price.adult.totalFare,
      totalPrice: isReturn ? cheapestJourney.price.all.totalRoundTripPrice : cheapestJourney.price.all.totalFare,
    }
  } else {
    const cheapestJourney = min(journeys, (flight) => flight.cost)!
    return {
      adultPrice: cheapestJourney.priceBreakdown?.adults.cost,
      totalPrice: cheapestJourney.cost,
    }
  }
}

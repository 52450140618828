import LoadingBox from 'components/Common/Loading/LoadingBox'
import Pane from 'components/Common/Pane'
import TextButtonLoadingSkeleton from 'components/Luxkit/Button/TextButtonLoadingSkeleton'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import LabelLoadingBox from 'components/Luxkit/Label/LabelLoadingBox'
import PriceRowLuxPlusPriceLoadingSkeleton from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusPriceLoadingSkeleton'
import PriceRowFlightPackageLoadingSkeleton from 'components/Luxkit/PricePoints/PriceRowFlightPackageLoadingSkeleton'
import PriceRowPriceCaptionLoadingSkeleton from 'components/Luxkit/PricePoints/PriceRowPriceCaptionLoadingSkeleton'
import PriceRowPriceLoadingSkeleton from 'components/Luxkit/PricePoints/PriceRowPriceLoadingSkeleton'
import PriceRowTaxesAndFeesLoadingSkeleton from 'components/Luxkit/PricePoints/PriceRowTaxesAndFeesLoadingSkeleton'
import PriceRowValueDiscountWithCaptionLoadingSkeleton from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaptionLoadingSkeleton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Sheet = styled(Pane)`
  display: grid;
  grid-template:
    "media" ${rem(230)}
    "content" auto / 1fr;

  ${mediaQueryUp.tablet} {
    grid-template:
      "media" ${rem(360)}
      "content" auto / 1fr;
  }

  ${mediaQueryUp.desktop} {
    grid-template: "media content" 1fr / 67% 1fr;
  }
`

const Media = styled.div`
  grid-area: media;
  display: grid;
  grid-template: 1fr / 1fr;

  ${mediaQueryUp.desktop} {
    grid-template:
      1fr
      ${rem(120)} / repeat(3, 1fr);
    gap: ${rem(8)};
  }
`

const MainImage = styled(LoadingBox)`
  grid-column: 1 / -1;
`

const ThumbImage = styled(LoadingBox)`
  display: none;

  ${mediaQueryUp.desktop} {
    display: block;
  }
`

const Content = styled.div`
  grid-area: content;
  padding: ${rem(20)};

  ${mediaQueryUp.desktop} {
    padding: ${rem(32)};
  }
`

const Logo = styled(LoadingBox)`
  height: ${rem(60)};
  width: ${rem(90)};

  ${mediaQueryUp.desktop} {
    height: ${rem(100)};
    width: ${rem(150)};
  }
`

const Cta = styled(TextButtonLoadingSkeleton)`
  width: ${rem(120)};

  ${mediaQueryUp.desktop} {
    width: auto;
  }
`

interface Props {
  className?: string;
}

function FrontPageOfferTileLoadingSkeleton(props: Props) {
  const { className } = props

  return <Sheet type="clean" axis="vertical" className={className}>
    <Media>
      <MainImage />
      <ThumbImage />
      <ThumbImage />
      <ThumbImage />
    </Media>
    <Content>
      <Group direction="vertical" horizontalAlign="stretch" gap={20}>
        <Group direction="vertical" horizontalAlign="stretch" gap={8}>
          <LabelGroup desktopHorizontalAlign="center">
            <LabelLoadingBox width={200} />
            <LabelLoadingBox width={72} />
            <LabelLoadingBox width={120} />
          </LabelGroup>
          <Group
            direction="horizontal"
            desktopDirection="vertical-reverse"
            gap={16}
            desktopGap={4}
            horizontalAlign="space-between"
            desktopHorizontalAlign="center"
            verticalAlign="center"
          >
            <Group direction="vertical" desktopHorizontalAlign="center">
              <TextLoadingBox typography="subtitle3" width="16ch" />
              <TextLoadingBox typography="heading6" width="20ch" />
            </Group>
            <Logo />
          </Group>
          <BodyText variant="large" desktopAlign="center">
            <TextLoadingBox lines={2} width="100%" />
            <TextLoadingBox width="12ch" />
          </BodyText>
          <BodyText variant="medium" desktopAlign="center">
            <TextLoadingBox width="24ch" />
          </BodyText>
          <Caption variant="large" desktopAlign="center">
            <TextLoadingBox width="18ch" />
          </Caption>
          <Caption variant="large" desktopAlign="center">
            <TextLoadingBox width="20ch" />
          </Caption>
        </Group>
        <Group
          direction="horizontal"
          desktopDirection="vertical"
          gap={8}
          desktopGap={16}
          horizontalAlign="space-between"
          desktopHorizontalAlign="stretch"
          verticalAlign="end"
        >
          <Group direction="vertical" horizontalAlign="start" desktopHorizontalAlign="center">
            <PriceRowPriceCaptionLoadingSkeleton />
            <PriceRowPriceLoadingSkeleton size="L" />
            <PriceRowLuxPlusPriceLoadingSkeleton size="L" />
            <PriceRowValueDiscountWithCaptionLoadingSkeleton />
            <PriceRowTaxesAndFeesLoadingSkeleton />
            <Caption variant="medium">
              <TextLoadingBox width="8ch" />
            </Caption>
          </Group>
          <Cta width="4ch" />
        </Group>
        <Group direction="vertical" horizontalAlign="start" desktopHorizontalAlign="center">
          <PriceRowFlightPackageLoadingSkeleton />
        </Group>
      </Group>
    </Content>
  </Sheet>
}

export default React.memo(FrontPageOfferTileLoadingSkeleton)

import React, { useCallback, useContext, useMemo } from 'react'
import { matchPath } from 'react-router'

import { useHasTripItems } from '../api/tripItem'

import { tripSavedSnackbarEvent } from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import SolidHeartIcon from 'components/Luxkit/Icons/solid/SolidHeartIcon'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import ModalContext from 'contexts/ModalContext'
import OfferPageStateContext from 'contexts/OfferPage/offerPageStateContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import noop from 'lib/function/noop'
import SelectDifferentTrip from 'tripPlanner/components/Bookmark/Common/SelectDifferentTrip'
import { setFirstTripItemHasJustBeenAdded } from 'tripPlanner/reducers/actions'
import { ItemType } from 'tripPlanner/types/tripItem'
import { getTripURL } from 'tripPlanner/utils'

const VIEW_LABEL = 'View'
const CHANGE_LABEL = 'Change'

type SnackbarAction = Exclude<
  Parameters<typeof showSnackbar>[2],
  undefined
>['action']

export default function useBookmarkSnackbarHandlers() {
  const isAlreadyOnTrip = useMemo(
    () =>
      !!matchPath('/trip/:tripId/:tab', {
        exact: false,
      }),
    [],
  )

  const showModal = useContext(ModalContext)
  const globalSearchState = useContext(GlobalSearchStateContext)
  const offerPageState = useContext(OfferPageStateContext)

  const showRemoveSuccessSnackbar = useCallback(
    (tripId: string, tripName: string, onViewClick = noop) => {
      showSnackbar(`Removed from "${tripName}"`, 'neutral', {
        action: !isAlreadyOnTrip ?
            {
              label: VIEW_LABEL,
              to: getTripURL(tripId),
              onAction: () => {
                onViewClick()
              },
            } :
          undefined,
        icon: <LineHeartIcon colour="neutral-one" />,
      })
    },
    [isAlreadyOnTrip],
  )

  const showRemoveErrorSnackbar = useCallback(
    (tripName: string) =>
      showSnackbar(`Failed to remove from "${tripName}"`, 'critical'),
    [],
  )
  const hasTripItems = useHasTripItems()
  const dispatch = useAppDispatch()
  const showSaveSuccessSnackbar = useCallback(
    (
      tripId: string,
      tripName: string,
      itemId: string,
      itemType?: ItemType,
      didAutoSelectTrip = false,
      onViewClick = noop,
    ) => {
      if (hasTripItems) {
        let action: SnackbarAction | undefined
        let analyticsAction: Parameters<typeof tripSavedSnackbarEvent>[1]

        if (isAlreadyOnTrip) {
          analyticsAction = 'no_action'
          action = undefined
        } else if (didAutoSelectTrip && itemType) {
          analyticsAction = 'change'
          action = {
            label: CHANGE_LABEL,
            onAction: () => {
              showModal(
                // Need to forward the contexts and modals don't have access
                // We need access in the trip settings modal
                <GlobalSearchStateContext.Provider value={globalSearchState}>
                  <OfferPageStateContext.Provider value={offerPageState}>
                    <SelectDifferentTrip
                      tripId={tripId}
                      tripItemId={itemId}
                      tripItemType={itemType}
                    />
                  </OfferPageStateContext.Provider>
                </GlobalSearchStateContext.Provider>,
              )
              fireInteractionEvent(
                tripSavedSnackbarEvent('click', analyticsAction),
              )
            },
          }
        } else {
          analyticsAction = 'view'
          action = {
            label: VIEW_LABEL,
            to: getTripURL(tripId, itemId),
            testId: 'save-success-snackbar-trip-link',
            onAction: () => {
              fireInteractionEvent(
                tripSavedSnackbarEvent('click', analyticsAction),
              )
              onViewClick()
            },
          }
        }
        fireInteractionEvent(tripSavedSnackbarEvent('view', analyticsAction))
        showSnackbar(`Saved to "${tripName}"`, 'neutral', {
          action,
          icon: <SolidHeartIcon colour="favourite" />,
        })
      } else {
        dispatch(setFirstTripItemHasJustBeenAdded())
      }
    },
    [
      dispatch,
      globalSearchState,
      hasTripItems,
      isAlreadyOnTrip,
      offerPageState,
      showModal,
    ],
  )

  const showSaveErrorSnackbar = useCallback(
    (tripName?: string) =>
      showSnackbar(
        tripName ? `Failed to save to "${tripName}"` : 'Failed to save item',
        'critical',
      ),
    [],
  )

  return {
    showRemoveSuccessSnackbar,
    showRemoveErrorSnackbar,
    showSaveSuccessSnackbar,
    showSaveErrorSnackbar,
  }
}

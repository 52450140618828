import React from 'react'
import FlightSearchWidgetDispatchContext from './flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from './flightSearchWidgetStateContext'
import { FlightSearchWidgetDispatchAction, FlightSearchWidgetState } from './flightSearchWidgetStateReducer'

interface Props {
  state: FlightSearchWidgetState;
  dispatch: React.Dispatch<FlightSearchWidgetDispatchAction>;
  children: React.ReactNode;
}

export function FlightSearchWidgetStateProvider(props: Props) {
  return <FlightSearchWidgetStateContext.Provider value={props.state}>
    <FlightSearchWidgetDispatchContext.Provider value={props.dispatch}>
      {props.children}
    </FlightSearchWidgetDispatchContext.Provider>
  </FlightSearchWidgetStateContext.Provider>
}

export default FlightSearchWidgetStateProvider

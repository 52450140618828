import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import Caption from 'components/Luxkit/Typography/Caption'
import React, { useEffect } from 'react'
import RecentSearchSuggestions from '../SearchForm/SearchLocationInput/RecentSearchSuggestions'
import SearchMenuListItem from '../SearchForm/SearchMenu/SearchMenuListItem'
import SearchPlaceSelectList from './SearchPlaceSelectList'
import styled from 'styled-components'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import { LOCATION_SEARCH_ERROR_MESSAGE } from 'constants/search'
import { rem } from 'polished'

const PlaceControl = styled(VerticalSpacer)`
  position: relative;
  width: 100%;
`

const SearchItemContainer = styled.div`
  margin-top: ${rem(8)};
`

const StyledRecentSearchSuggestions = styled(RecentSearchSuggestions)`
  padding: 0 ${rem(16)};
`

interface Props {
  className?: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: App.SearchItem;
  required?: boolean;
  placeholder: string;
  localInputRef: React.RefObject<HTMLInputElement>;
  searchPhrase: string;
  onClearClick: () => void;
  finalOpen: boolean;
  showPlaceholder: boolean;
  hasRecentSearchItems: boolean;
  recentSearches: Array<App.FullRecentSearch>;
  selectedDropdown: number | undefined;
  onRecentSearchDelete: (item: App.FullRecentSearch) => void;
  onRecentSearchClick: (item: App.FullRecentSearch) => void;
  placeholderTitle?: string;
  onLocationSelect: (item?: App.SearchItem) => void;
  placeTypes?: Array<App.SearchPlaceType>;
  searchTypes?: Array<App.SearchType>;
  searchVertical?: App.SearchPlaceVertical;
  overrideSearchItems?: Array<App.SearchItem>;
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  placeholderItems: Array<App.SearchItem>;
  placeholdersSearches: Array<App.SearchItem>;
  handleResultsChange?: (results: Array<App.SearchItem>, searchPhrase: string) => void;
  showHotelImagesLayout?: boolean;
}

function SearchPlaceSelectInline(props: Props) {
  const {
    className,
    onKeyDown,
    value,
    required,
    placeholder,
    localInputRef,
    searchPhrase,
    showPlaceholder,
    hasRecentSearchItems,
    recentSearches,
    selectedDropdown,
    onRecentSearchDelete,
    onRecentSearchClick,
    placeholderTitle,
    onLocationSelect,
    placeTypes,
    searchTypes,
    searchVertical,
    overrideSearchItems,
    setSearchPhrase,
    onClearClick,
    placeholderItems,
    placeholdersSearches,
    handleResultsChange,
  } = props

  useEffect(() => {
    localInputRef.current?.focus({ preventScroll: true })
  }, [localInputRef])

  return (<PlaceControl gap={12} className={className} onKeyDown={onKeyDown}>
    {!!required && <HiddenInput
    value={value ? 'filled' : undefined}
    required
    invalidMessage={LOCATION_SEARCH_ERROR_MESSAGE}
  />}
    <TextInputOnly
    noValidationSpacing
    placeholder={placeholder}
    ref={localInputRef}
    defaultValue={value?.format.mainText}
    onChange={e => setSearchPhrase(e.currentTarget.value)}
    endIcon={searchPhrase.length > 0 ?
      <IconButton kind="tertiary" onClick={onClearClick}><LineTimesIcon /></IconButton> :
      <LineSearchIcon />
    }
  />
    {searchVertical === 'hotel' &&
      <>
        {showPlaceholder && <VerticalSpacer gap={12}>
          {hasRecentSearchItems && <StyledRecentSearchSuggestions
            recentSearches={recentSearches}
            onDelete={onRecentSearchDelete}
            selectedDropdown={selectedDropdown}
            onRecentSearchClick={onRecentSearchClick}
            iconBackground
            horizontal={false}
          />}
          {placeholderTitle && <Caption variant="large" weight="bold">{placeholderTitle}</Caption>}
          <SearchItemContainer>
            {placeholdersSearches.map(item => <SearchMenuListItem
            key={item.value}
            searchItem={item}
            onClick={onLocationSelect}
            imageId={item.imageId}
            searchVertical={searchVertical}
            useImage
          />)}
          </SearchItemContainer>
        </VerticalSpacer>}
        {!showPlaceholder && <SearchPlaceSelectList
        searchPhrase={searchPhrase}
        onResultsChange={handleResultsChange}
        onChange={onLocationSelect}
        placeTypes={placeTypes}
        searchTypes={searchTypes}
        vertical={searchVertical}
        overrideSearchItems={overrideSearchItems}
        useImage
      />}
      </>
    }
    {searchVertical !== 'hotel' &&
      <>
        {showPlaceholder && <VerticalSpacer gap={12}>
          {hasRecentSearchItems && <>
            <StyledRecentSearchSuggestions
              recentSearches={recentSearches}
              onDelete={onRecentSearchDelete}
              selectedDropdown={selectedDropdown}
              onRecentSearchClick={onRecentSearchClick}
            />
            <Divider kind="primary" />
          </>}
          {placeholderTitle && <Caption variant="large" weight="bold">{placeholderTitle}</Caption>}
          <div>
            {placeholderItems.map(item => <SearchMenuListItem
              key={item.value}
              searchItem={item}
              onClick={onLocationSelect}
              searchVertical={searchVertical}
              useImage={searchVertical === 'tour'}
            />)}
          </div>
        </VerticalSpacer>}
        {!showPlaceholder && <SearchPlaceSelectList
          searchPhrase={searchPhrase}
          onResultsChange={handleResultsChange}
          onChange={onLocationSelect}
          placeTypes={placeTypes}
          searchTypes={searchTypes}
          vertical={searchVertical}
          overrideSearchItems={overrideSearchItems}
          useImage={searchVertical === 'tour'}
        />}
      </>
    }
  </PlaceControl>
  )
}

export default SearchPlaceSelectInline

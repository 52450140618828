import React, { useCallback, useContext, useEffect, useRef } from 'react'

import TextInput from 'components/Common/Form/Input/TextInput'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import IconButton from 'components/Luxkit/Button/IconButton'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import SearchMenuListItem from 'components/Search/SearchForm/SearchMenu/SearchMenuListItem'
import {
  GlobalSearchDispatchContext,
  GlobalSearchStateContext,
} from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useGlobalSearchTypeahead from 'hooks/GlobalSearch/useGlobalSearchTypeahead'
import useToggle from 'hooks/useToggle'
import noop from 'lib/function/noop'
import { TRIP_PLACE_TYPEAHEAD_TYPES } from 'tripPlanner/config/search'

interface PlaceSelection {
  id: string
  name: string
}

interface Props {
  label?: string
  inputValue: string
  onInputChange: (value: string) => void
  placeSelection?: PlaceSelection
  onSelect: (value: PlaceSelection) => void
  onClear?: () => void
  autoFocus?: boolean
  autoSelect?: boolean
  disabled?: boolean
  isInvalidState?: boolean
}

function TripPlaceFieldDesktop({
  label,
  inputValue,
  onInputChange,
  onSelect,
  onClear: onClearProp = noop,
  autoFocus,
  autoSelect,
  disabled,
  isInvalidState,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { value: isActive, on: activate, off: deactivate } = useToggle()

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const searchState = useContext(GlobalSearchStateContext)
  const { suggestedSearchItems } = searchState

  const { isLoading } = useGlobalSearchTypeahead({
    search: inputValue,
    typeaheadTypes: TRIP_PLACE_TYPEAHEAD_TYPES,
  })

  // Don't want to active the dropdown on the initial focus
  const shouldActivateOnFocus = useRef(false)

  useEffect(() => {
    if (inputRef.current) {
      if (autoSelect) {
        inputRef.current.select()
      } else if (autoFocus) {
        inputRef.current.focus()
      }
    }
    shouldActivateOnFocus.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClear = useCallback(() => {
    globalSearchDispatch({
      type: GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS,
      searchItems: [],
    })
    onInputChange('')
    deactivate()
    onClearProp()
  }, [globalSearchDispatch, onInputChange, deactivate, onClearProp])

  const onInputChangeInternal = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value
      if (value.length === 0) {
        onClear()
      } else {
        onInputChange(value)
        activate()
      }
    },
    [onInputChange, activate, onClear],
  )

  const onSelectItem = useCallback(
    (changedSearchItem: App.SearchItem) => {
      onSelect({
        id: changedSearchItem.value,
        name: changedSearchItem.format.mainText,
      })
      deactivate()
    },
    [onSelect, deactivate],
  )

  const hasInput = inputValue.length > 0
  const onInputFocus = useCallback(() => {
    if (hasInput && shouldActivateOnFocus.current) {
      activate()
    }
  }, [activate, hasInput])

  const isEmptyResult =
    hasInput && suggestedSearchItems.length === 0 && !isLoading

  const showInvalidStateMessage = !isActive && isInvalidState

  return (
    <div>
      <TextInput
        label={label}
        ref={inputRef}
        placeholder="Search by city"
        value={inputValue}
        onChange={onInputChangeInternal}
        onFocus={onInputFocus}
        disabled={disabled}
        endIcon={
          hasInput && (
            <IconButton kind="tertiary" onClick={onClear} aria-label="Clear">
              <LineTimesIcon />
            </IconButton>
          )
        }
        manualError={showInvalidStateMessage}
        invalidErrorMessage={
          showInvalidStateMessage ?
            'Please select a location from the dropdown' :
            undefined
        }
      />
      <DropdownList
        size="M"
        triggerRef={inputRef}
        anchorRef={inputRef}
        open={isActive}
        onClose={deactivate}
        placement="bottom-start"
      >
        {isLoading && <LoadingIndicator inline />}
        {!isLoading &&
          suggestedSearchItems.map((item) => (
            <SearchMenuListItem
              key={item.value}
              searchItem={item}
              onClick={onSelectItem}
            />
          ))}
        {isEmptyResult && (
          <VerticalSpacer gap={4}>
            <BodyText variant="medium" colour="neutral-two">
              Sorry, we couldn't find <b>{inputValue}</b>.
            </BodyText>
            <Caption variant="medium">
              Check your spelling, or try a new location.
            </Caption>
          </VerticalSpacer>
        )}
      </DropdownList>
    </div>
  )
}

export default TripPlaceFieldDesktop

import { ANYWHERE_PLACE_ID, DATE_SEARCH_OPTION_IDS, FLEXIBLE_DURATION_RANGE, SEARCH_VERTICALS } from 'constants/search'
import { countOccupants } from 'lib/offer/occupancyUtils'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { FlightsClassTypes, FlightsFareTypes } from 'constants/flight'
import uuidV4 from 'lib/string/uuidV4Utils'

export type VerticalType = 'car_hire' | 'hotels' | 'tours' | 'cruises' | 'ultralux' | 'experiences' | 'flights';
export type SearchAnalyticsLocation = 'search-list' | 'common-search'
export type SearchAnalyticsItemType = 'recent' | 'popular' | 'standard' | undefined

const VerticalTypeMapping: Record<SEARCH_VERTICALS, VerticalType> = {
  [SEARCH_VERTICALS.CARHIRE]: 'car_hire',
  [SEARCH_VERTICALS.HOTELS]: 'hotels',
  [SEARCH_VERTICALS.TOURS]: 'tours',
  [SEARCH_VERTICALS.CRUISES]: 'cruises',
  [SEARCH_VERTICALS.EXPERIENCES]: 'experiences',
  [SEARCH_VERTICALS.FLIGHTS]: 'flights',
  [SEARCH_VERTICALS.HOMES_AND_VILLAS]: 'hotels', // Temporary until we add tracking for homes-and-villas
}

const getDestinationType = (searchItem: App.SearchItem | undefined): comluxgroup.SearchContext_1_0_1['destinationType'] => {
  if (searchItem?.searchType === 'property') {
    return 'property'
  } else if (searchItem?.searchType === 'landmark') {
    return 'landmark'
  } else if (searchItem?.value === ANYWHERE_PLACE_ID) {
    return 'anywhere'
  } else {
    return 'location'
  }
}

function getDateType(
  checkinDate?: moment.Moment | string,
  checkoutDate?: moment.Moment | string,
  flexibleNights?: boolean | FLEXIBLE_DURATION_RANGE,
  flexibleMonths?: string | null,
  durationRange?: string | Array<any> | null,
  dateSearchOptionId?: DATE_SEARCH_OPTION_IDS,
): comluxgroup.SearchContext_1_0_1['dateType'] {
  if (checkinDate && checkoutDate) {
    return 'specific'
  } else if (flexibleNights || (flexibleMonths || durationRange?.length) || dateSearchOptionId === DATE_SEARCH_OPTION_IDS.FLEXIBLE) {
    return 'flexible'
  } else {
    return 'anytime'
  }
}

function getDriverAge(driversAgeCategory?: App.CarHireDriverAgeCategory): comluxgroup.SearchContext_1_0_1['driverAge'] {
  if (driversAgeCategory === 1) {
    return '18-29'
  } else if (driversAgeCategory === 2) {
    return '30-69'
  } else if (driversAgeCategory === 3) {
    return '70+'
  } else {
    return undefined
  }
}

function isValidFlightDate(dateStr?: string): boolean {
  if (!dateStr) {
    return false
  }

  const date = Date.parse(dateStr)
  return !isNaN(date)
}

export const mapGlobalSearchContextToSnowplowSearchEvent = (context: Partial<GlobalSearchState>): comluxgroup.SearchContext_1_0_1 => {
  const {
    searchItem,
    searchItems,
    secondarySearchItem,
    secondarySearchItems,
    occupancies,
    searchVerticals,
    flexibleNights,
    flexibleMonths,
    durationRange,
    checkinDate,
    checkoutDate,
    dateSearchOptionId,
    cruiseLines,
    pickUpTime,
    dropOffTime,
    driversAgeCategory,
    eventAnalytics,
  } = context

  const searchId = eventAnalytics?.searchId ?? uuidV4()
  const vertical = searchVerticals ? [...searchVerticals][0] : SEARCH_VERTICALS.HOTELS
  const hasOrigin = vertical === SEARCH_VERTICALS.CARHIRE || vertical === SEARCH_VERTICALS.FLIGHTS || vertical === SEARCH_VERTICALS.CRUISES

  const occupants = occupancies ? countOccupants(occupancies) : undefined
  const driverAge = getDriverAge(driversAgeCategory)
  const checkIn = moment(checkinDate).format(ISO_DATE_FORMAT)
  const checkOut = moment(checkoutDate).format(ISO_DATE_FORMAT)

  const dateType = getDateType(checkinDate, checkoutDate, flexibleNights, flexibleMonths, durationRange, dateSearchOptionId)

  const destinationType = getDestinationType(searchItem)
  const originInfo = vertical !== SEARCH_VERTICALS.CRUISES ? [searchItem] : (searchItems || [])
  const departureInfo = vertical !== SEARCH_VERTICALS.CRUISES ? [secondarySearchItem] : (secondarySearchItems || [])
  const originLocations = originInfo.map(item => item?.format.mainText).filter(text => text !== undefined)
  const originLocationIds = originInfo.map(item => item?.value).filter(id => id !== undefined)
  const departureLocations = departureInfo.map(item => item?.format.mainText).filter(text => text !== undefined)
  const departureLocationIds = departureInfo.map(item => item?.value).filter(id => id !== undefined)

  return {
    adults: occupants?.adults,
    children: occupants?.children,
    rooms: occupancies?.length || undefined,
    dateType,
    startDate: checkinDate ? checkIn : undefined,
    endDate: checkoutDate ? checkOut : undefined,
    vertical: VerticalTypeMapping[vertical],
    durationType: (durationRange?.length ? durationRange[0] : (flexibleNights === '1-30' ? 'any' : flexibleNights)) || undefined,
    destinationType,
    origins: hasOrigin ? originLocations : undefined,
    originIds: hasOrigin ? originLocationIds : undefined,
    destinations: hasOrigin && (secondarySearchItem || secondarySearchItems) ? departureLocations : originLocations,
    destinationIds: hasOrigin && (secondarySearchItem || secondarySearchItems) ? departureLocationIds : originLocationIds,
    cruiseLines: cruiseLines?.length ? cruiseLines : undefined,
    pickUpTime,
    dropOffTime,
    driverAge,
    searchId,
  }
}

export function mapRecentSearchDataToSnowplowSearchEvent(
  recentSearch: App.FullRecentSearch,
  searchVerticals: Set<SEARCH_VERTICALS>,
): comluxgroup.SearchContext_1_0_1 {
  const {
    searchItem,
    rooms,
    dates,
  } = recentSearch
  const vertical = searchVerticals ? [...searchVerticals][0] : SEARCH_VERTICALS.HOTELS
  const occupants = countOccupants(recentSearch.rooms)
  const isFlexible = !!dates.flexibleMonths || !!dates.flexibleDuration
  const dateType = getDateType(dates?.checkIn, dates?.checkOut, isFlexible)
  const destinationType = getDestinationType(searchItem)

  return {
    adults: occupants.adults,
    children: occupants.children,
    rooms: rooms?.length || 0,
    dateType,
    startDate: dates?.checkIn || undefined,
    endDate: dates?.checkOut || undefined,
    vertical: VerticalTypeMapping[vertical],
    durationType: (dates?.flexibleDuration === '1-30' ? 'any' : dates?.flexibleDuration) || undefined,
    destinationType,
    destinations: [searchItem?.format.mainText],
    destinationIds: [searchItem?.value],
    searchId: uuidV4(),
  }
}

type flightType = 'return' | 'one_way' | 'multi_city'

function mapFlightFareType(fareType?: FlightsFareTypes): flightType {
  switch (fareType) {
    case FlightsFareTypes.ONE_WAY:
      return 'one_way'

    case FlightsFareTypes.RETURN:
      return 'return'

    case FlightsFareTypes.MULTI_CITY:
      return 'multi_city'

    default:
      // For backward compatibility
      return 'return'
  }
}

interface FlightSearchSearchData {
  searchVerticals: Set<SEARCH_VERTICALS>;
  originAirportCode?: string;
  originAirportName?: string;
  destinationAirportCode?: string;
  destinationAirportName?: string;
  departDate: string;
  returnDate?: string;
  occupancies: App.Occupants;
  fareClass?: FlightsClassTypes;
  fareType?: FlightsFareTypes;
}

export function mapFlightSearchDataToSnowplowSearchEvent(data: FlightSearchSearchData): comluxgroup.SearchContext_1_0_1 {
  const {
    searchVerticals,
    originAirportCode,
    originAirportName,
    destinationAirportCode,
    destinationAirportName,
    departDate,
    returnDate,
    occupancies,
    fareClass,
    fareType,
  } = data
  return {
    adults: occupancies?.adults,
    children: occupancies?.children,
    startDate: isValidFlightDate(departDate) ? departDate : undefined,
    endDate: isValidFlightDate(returnDate) ? returnDate : undefined,
    vertical: VerticalTypeMapping[(searchVerticals ? [...searchVerticals][0] : SEARCH_VERTICALS.FLIGHTS)],
    destinationType: 'location',
    origins: originAirportName ? [originAirportName] : [],
    originIds: originAirportCode ? [originAirportCode] : [],
    destinations: (destinationAirportName ? [destinationAirportName] : []),
    destinationIds: (destinationAirportCode ? [destinationAirportCode] : []),
    flightClass: fareClass,
    flightType: mapFlightFareType(fareType),
    searchId: uuidV4(),
  }
}

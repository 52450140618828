import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useToggle from 'hooks/useToggle'
import React, { useCallback, useContext, useRef } from 'react'
import FormattedCruiseLineSearchSummary from '../CruiseLineSelect/FormattedCruiseLineSearchSummary'
import CruiseWhenSelectContent from './CruiseWhenSelectContent'

interface Props {
  inputValue: string
  onChange: (values: App.CruiseGlobalFilters) => void
  dropdownAnchorRef: React.RefObject<HTMLElement>
}

function CruiseWhenSelectDesktop({ inputValue, onChange, dropdownAnchorRef }: Props) {
  const [isOpen, toggleModal, , closeModal] = useToggle(false)
  const { flexibleMonths, durationRange, durationMax, durationMin, checkinDate, checkoutDate } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const onApply = useCallback(() => {
    onChange({
      flexibleMonths,
      durationMax,
      durationMin,
      checkinDate,
      checkoutDate,
      durationRange,
    })
    closeModal()
  }, [onChange, flexibleMonths, durationMax, durationMin, checkinDate, checkoutDate, durationRange, closeModal])

  const onClear = useCallback(() => {
    searchDispatch({ type: GlobalSearchStateActions.SET_DURATION_RANGE, min: undefined, max: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: '' })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE, durationRange: [] })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: undefined })
  }, [searchDispatch])

  const formFieldRef = useRef<HTMLButtonElement>(null)

  return <>
    <SearchFormField
      data-testid="cruise-when-select-input"
      ref={formFieldRef}
      onClick={toggleModal}
      label="When"
      placeholder="Anytime"
      value={inputValue}
    />

    <DropdownSheet
      data-testid="cruise-when-select-dropdown"
      size="fill-anchor"
      anchorRef={dropdownAnchorRef}
      triggerRef={formFieldRef}
      open={isOpen}
      onClose={closeModal}
      primaryActionProps={{
        'data-testid': 'cruise-filter-footer-apply',
        children: 'Apply',
        onClick: onApply,
      }}
      secondaryActionProps={{
        'data-testid': 'cruise-filter-footer-clear-all',
        kind: 'tertiary',
        children: 'Reset all',
        onClick: onClear,
      }}
      footerStart={<FormattedCruiseLineSearchSummary />}
    >
      <CruiseWhenSelectContent onChange={onChange} />
    </DropdownSheet>
  </>
}

export default CruiseWhenSelectDesktop

import React, { useCallback } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import removeSpace from 'lib/string/removeSpaceUtils'
import noop from 'lib/function/noop'
import TextButton from '../../Luxkit/Button/TextButton'

const ToggleItem = styled(TextButton)`
  flex: 1;
  min-width: ${rem(130)};
`

export interface ButtonToggleItem {
  id: string;
  label: string;
  [key: string]: any;
}

interface Props {
  item: ButtonToggleItem;
  active?: boolean;
  onItemClick?: (item: ButtonToggleItem, e?: React.MouseEvent) => void;
}

function ButtonGroupToggleItem(props: Props) {
  const { item, active, onItemClick = noop } = props

  const onToggleClick = useCallback((e: React.MouseEvent) => {
    onItemClick(item, e)
  }, [onItemClick, item])

  return <ToggleItem
    kind="secondary"
    key={item.id}
    isSelected={active}
    onClick={onToggleClick}
    data-testid={`ButtonGroupToggle-${removeSpace(item.label)}`}
  >
    {item.label}
  </ToggleItem>
}

export default ButtonGroupToggleItem

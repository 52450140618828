import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHeartCardIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10 14.7c-.2 0-.4 0-.5-.1-.2-.1-.3-.2-.4-.3 0 0-2.2-2.3-2.9-3.1C5 10.2 4.7 9 5.1 7.6 5.4 6.3 6.6 5.4 8 5.3c.9 0 1.5.3 2 .6.5-.4 1.1-.7 2-.6 1.4 0 2.6.9 2.9 2.2.4 1.4 0 2.6-1 3.6-.8.8-2.9 3.1-2.9 3.1-.2.2-.3.3-.5.3-.1.1-.3.2-.5.2ZM8.1 7.3c-.6 0-1 .4-1.1.8-.3.9.1 1.3.4 1.6l.1.1c.5.5 1.8 1.9 2.4 2.6.7-.7 1.9-2 2.4-2.6l.1-.1c.5-.3.9-.7.6-1.6-.1-.4-.5-.7-1.1-.8-.5 0-.8.2-1.2.7l-.7.9-.8-.9c-.4-.4-.6-.7-1.1-.7Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M20.3 6.3c.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7v10c0 1.3-.5 2.6-1.5 3.5-.9 1-2.2 1.5-3.5 1.5H7c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h10c.8 0 1.6-.3 2.1-.9.6-.6.9-1.3.9-2.1V7c0-.3.1-.5.3-.7ZM18 5v10c0 .8-.3 1.6-.9 2.1-.5.6-1.3.9-2.1.9H5c-.8 0-1.6-.3-2.1-.9-.6-.5-.9-1.3-.9-2.1V5c0-.8.3-1.6.9-2.1C3.5 2.4 4.2 2 5 2h10c.8 0 1.6.3 2.1.9.6.5.9 1.3.9 2.1ZM4 5v10c0 .3.1.5.3.7.2.2.4.3.7.3h10c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7V5c0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3H5c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineHeartCardIcon

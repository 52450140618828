import { reportClientError } from 'services/errorReportingService'
import { ERROR_REPORTED } from './actionConstants'
import { AppAction } from './ActionTypes'

export function ReportError(error: Error | any, extras: any): AppAction {
  return (dispatch) => {
    reportClientError(error, extras).then(errorId => {
      dispatch({ type: ERROR_REPORTED, errorId })
    })
  }
}

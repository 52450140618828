import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import ListItem from 'components/Luxkit/List/ListItem'
import ListItemLoadingSkeleton from 'components/Luxkit/List/ListItemLoadingSkeleton'
import LineGuidedtoursIcon from 'components/Luxkit/Icons/line/LineGuidedtoursIcon'
import LineHotAirBalloonIcon from 'components/Luxkit/Icons/line/LineHotAirBalloonIcon'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import LineTheatreIcon from 'components/Luxkit/Icons/line/LineTheatreIcon'
import LineTicketIcon from 'components/Luxkit/Icons/line/LineTicketIcon'
import LineWellnessIcon from 'components/Luxkit/Icons/line/LineWellnessIcon'
import Typography from 'components/Luxkit/Typography/Typography'
import { AnythingCategory } from 'constants/experience'
import { getPlaceByRegionCode } from 'constants/places'
import GeoContext from 'contexts/geoContext'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { without } from 'lib/array/arrayUtils'
import React, { useCallback, useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import useMostPopulatedSearchExperienceCategories from '../../hooks/useMostPopulatedSearchExperienceCategories'
import ExperienceAnythingCategoryIcon from './ExperienceAnythingCategoryIcon'
import Divider from 'components/Luxkit/Divider'

const parentCategoryChildText: Record<number, string> = {
  // Wine & dining
  1: 'Fine dining, restaurant & bars, food tours, wine country trips and more.',
  // Top activities
  9: 'Hot air ballooning, watersports, cruises, photoshoots and more.',
  // Indulge yourself
  31: 'Spa & massage, hot springs, wellness and more.',
  // Attractions & tickets
  18: 'Theme & water parks, museums, galleries, zoos, historical sites and more.',
  // Live events
  27: 'Concerts, theatre, live sports and more.',
  // Airport lounges
  35: 'Relax at the airport lounge, book a transfer and more.',
  // Lux exclusive
  40: 'The best of the best.',
  // Day tours
  48: 'Guided tours, private tours, hop-on-hop-off buses, walking tours and more.',
}

/**
 * Map of parent categoriy ids to the icon for them
 */
const parentCategoryToIconMap: Record<number, JSX.Element> = {
  // Wine & dining
  1: <LineRestaurantIcon />,
  // Top activities
  9: <LineHotAirBalloonIcon />,
  // Indulge yourself
  31: <LineWellnessIcon />,
  // Attractions & tickets
  18: <LineTicketIcon />,
  // Live events
  27: <LineTheatreIcon />,
  // Airport lounges
  35: <LinePlaneDepartureIcon />,
  // Lux exclusive
  40: <LineStarIcon />,
  // Day tours
  48: <LineGuidedtoursIcon />,
}

interface Props {
  categories: Array<App.ExperienceItemCategory>;
  className?: string;
}

function ExperienceSearchCategoryResults(props: Props) {
  const { categories, className } = props
  const { experienceCategories = [] } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const { currentRegionCode } = useContext(GeoContext)
  const regionPlace = getPlaceByRegionCode(currentRegionCode)

  const parentCategories = useMemo(() => categories.reduce<Record<number, Array<number>>>((acc, category) => {
    if (category.parentId) {
      if (!acc[category.parentId]) {
        acc[category.parentId] = []
      }
      acc[category.parentId].push(category.id)
    }
    return acc
  }, {}), [categories])

  const toggleCategory = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const categoryId = parseInt(e.currentTarget.dataset.catid!)
    let nextCategories: Array<number> = []
    if (parentCategories[categoryId]?.every(c => experienceCategories.includes(c))) {
      nextCategories = without(experienceCategories, ...parentCategories[categoryId])
    } else {
      nextCategories = [...parentCategories[categoryId], ...experienceCategories]
    }

    searchDispatch({
      type: GlobalSearchStateActions.SET_EXPERIENCE_CATEGORIES,
      categories: without(nextCategories, AnythingCategory.id),
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps -- searchDispatch is stable
  }, [experienceCategories, parentCategories])

  const [trendingCategoryData, fetching] = useMostPopulatedSearchExperienceCategories(regionPlace)
  const categoryItems = useMemo(() => trendingCategoryData.map(data => data.parent), [trendingCategoryData])

  const parentCategoriesStatus = useMemo<Record<string, 'full' | 'partial'>>(() => {
    const currentChildIds = new Set(experienceCategories)
    const categoriesStatuses: Record<string, 'full' | 'partial'> = {}
    for (const parentId in parentCategories) {
      const children = parentCategories[parentId]
      if (children.every(c => currentChildIds.has(c))) {
        categoriesStatuses[parentId] = 'full'
      } else if (children.some(c => currentChildIds.has(c))) {
        categoriesStatuses[parentId] = 'partial'
      }
    }
    return categoriesStatuses
  }, [experienceCategories, parentCategories])

  const onSelectAnything = useCallback(() => {
    searchDispatch({
      type: GlobalSearchStateActions.SET_EXPERIENCE_CATEGORIES,
      categories: [AnythingCategory.id],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- searchDispatch is stable
  }, [])

  return <VerticalSpacer gap={8} className={className}>
    <ListItem
      title="Anything"
      subtitle="Inspire me with all experience categories"
      startIcon={<ExperienceAnythingCategoryIcon />}
      onClick={onSelectAnything}
      type="submit"
    />
    <Divider kind="primary" />
    {fetching && <>
      <ListItemLoadingSkeleton subtitle startIcon />
      <ListItemLoadingSkeleton subtitle startIcon />
      <ListItemLoadingSkeleton subtitle startIcon />
    </>}
    {!fetching && categoryItems.map(cat => <ListItem
      key={cat.id}
      title={cat.name}
      subtitle={<Typography wrap="truncate">{parentCategoryChildText[cat.id]}</Typography>}
      data-catid={cat.id}
      startIcon={parentCategoryToIconMap[cat.id]}
      endIcon={<CheckboxButton
        checked={parentCategoriesStatus[cat.id] === 'full'}
        indeterminate={parentCategoriesStatus[cat.id] === 'partial'}
        size="large"
      />}
      onClick={toggleCategory}
    />)}
  </VerticalSpacer>
}

function mapStateToProps(state: App.State) {
  return {
    categories: state.experience.experienceCategories,
  }
}

export default connect(mapStateToProps)(ExperienceSearchCategoryResults)

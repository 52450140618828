import { PreProcessor } from 'api/requestUtils'
import { isTargetEndpoint } from '../../lib/sherlog/targetEndpoints'
import { logNetworkRequest } from '../../actions/SherLogActions'

export default function sherLogRequestLogger(
  logger: Utils.ActionDispatcher<typeof logNetworkRequest>,
): PreProcessor {
  return (params) => {
    const { path, options } = params

    if (isTargetEndpoint(path, options.method)) {
      logger({
        requestName: path,
        requestBody: options.body,
      })
    }

    return params
  }
}

import React from 'react'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import Pill from 'components/Luxkit/Pill'
import config from 'constants/config'

interface Props {
  testId?: string;
  depositAmountPercentage?: number;
}
function SecureWithDepositTag(props: Props) {
  const { testId, depositAmountPercentage } = props
  if (!config.DEPOSITS_TAGS_ENABLED) {
    return null
  }

  return (
    <Pill data-testid={testId} kind="tertiary" variant="primary" startIcon={<LineClockDollarIcon />}>
      Secure with a {depositAmountPercentage ? `${depositAmountPercentage}% ` : ''}deposit
    </Pill>
  )
}
export default React.memo(SecureWithDepositTag)

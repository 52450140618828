import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${rem(8)};
  margin-bottom: ${rem(32)};
  margin-top: ${rem(20)};
`

const FeatureContainer = styled(Group)`
  width: ${rem(148)};
`

function FirstTime() {
  return (
    <Container>
      <Group direction="vertical" gap={20}>
        <BodyText variant="large" weight="bold">
          What are trips?
        </BodyText>
        <BodyText variant="large">
          Trips are how you can group your saved offers. Your trip allows you to
          organise your favourite Luxury Escapes offers and easily plan your
          next adventure.
        </BodyText>
        <Group direction="vertical" tabletDirection="horizontal" gap={24}>
          <Group direction="horizontal" gap={24}>
            <FeatureContainer direction="vertical" gap={12}>
              <LineHeartIcon size="L" />
              <BodyText variant="medium">
                Save your favourite Luxury Escapes offers
              </BodyText>
            </FeatureContainer>
            <FeatureContainer direction="vertical" gap={12}>
              <LineMapIcon size="L" />
              <BodyText variant="medium">
                Map it out to visualise your itinerary
              </BodyText>
            </FeatureContainer>
          </Group>
          <Group direction="horizontal" gap={24}>
            <FeatureContainer direction="vertical" gap={12}>
              <LineRestaurantIcon size="L" />
              <BodyText variant="medium">
                Discover top local attractions and sights
              </BodyText>
            </FeatureContainer>
            <FeatureContainer direction="vertical" gap={12}>
              <LinePlaneDepartureIcon size="L" />
              <BodyText variant="medium">
                Book flights, hotels, car hire & more
              </BodyText>
            </FeatureContainer>
          </Group>
        </Group>
      </Group>
    </Container>
  )
}

export default FirstTime

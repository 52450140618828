import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import React, { useCallback, useContext, useMemo } from 'react'
import { getCarHireAgeCategory } from '../carHireUtils'

interface Props {
  className?: string;
}

function CarHireDriversAgeInput(props: Props) {
  const {
    driversAgeCategory,
    driversAge,
  } = useContext(GlobalSearchStateContext)

  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const needsActualAge = driversAgeCategory === 1 || driversAgeCategory === 3
  const validDefault = useMemo(() => {
    if (getCarHireAgeCategory(driversAge) === driversAgeCategory) {
      return driversAge
    }
  }, [driversAgeCategory, driversAge])

  const onDriversAgeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const nextAge = isNaN(e.currentTarget.valueAsNumber) ? undefined : e.currentTarget.valueAsNumber
    searchDispatch({ type: GlobalSearchStateActions.SET_DRIVERS_AGE, driversAge: nextAge })
  }, [searchDispatch])

  return <TextInputOnly
    type="number"
    name="age"
    className={props.className}
    min={needsActualAge ? driversAgeCategory === 1 ? 18 : 70 : undefined}
    max={needsActualAge ? driversAgeCategory === 1 ? 29 : 100 : undefined}
    required={needsActualAge}
    noValidationMessage
    noValidationSpacing
    onChange={onDriversAgeChange}
    defaultValue={validDefault}
    key={driversAgeCategory}
  />
}

export default CarHireDriversAgeInput

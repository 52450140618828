import optimizely, { Client, OptimizelyUserContext, setLogger, enums } from '@optimizely/optimizely-sdk'

import { getDomainUserId } from 'analytics/snowplow/helpers/domainUserId'
import { isLEStaffOrAdmin, selectLoggedIn } from 'selectors/accountSelectors'
import { reportClientError } from 'services/errorReportingService'
import config from 'constants/config'
import { AppStore } from 'src/client/store'
import { deleteCookie, getCookies } from 'lib/web/cookieUtils'
import moment from 'moment'
import { isCurrentDeviceMobile } from 'selectors/configSelectors'

let client: Client | null
export let userContext: OptimizelyUserContext
let optimizelyResolver: (ready: boolean) => void

/**
 * The following code is used to wait for Optimizely to be initialized and ready
 *
 * The optimizelyResolver variable is used to make the resolver of the subsequent
 * promise accessible to any other function in this file
 * The optimizelyIsReady variable is used to wait on another promise, by making
 * its resolver public to the local file, it can be resolved as soon as something else
 * calls the optimizelyResolver (with true or false in our case)
**/

export const optimizelyIsReady = new Promise<boolean>((resolve) => {
  optimizelyResolver = resolve
})

function getClient(datafile: string) {
  if (!client) {
    client = optimizely.createInstance({
      datafile,
    })

    setLogger({
      log: (level, message) => {
        if (level !== enums.LOG_LEVEL.ERROR) return

        if (message.includes('is not in datafile')) return
        if (message.includes('Datafile is invalid')) return

        reportClientError(new Error(message))
      },
    })
  }

  return client
}

/**
 * Unfortunately we've been setting triggered cookies with
 * very large expiry dates, causing them to build up.
 * This function will clean up any that are older than we want
 */
function cleanOldTriggeredCookies() {
  const existingCookies = getCookies()
  Object.entries(existingCookies).forEach(([name, value]) => {
    if (name.endsWith('_triggered')) {
      // found an optimizely cookie, check the date
      const actualExpiry = moment(value.substring(0, 10)).add(2, 'months')
      if (moment().isAfter(actualExpiry)) {
        deleteCookie(name)
      }
    }
  })
}

/**
 * Init function
*/
export function init(store: AppStore) {
  if (!config.OPTIMIZELY_SDK_KEY) {
    optimizelyResolver(false)
    return
  }

  cleanOldTriggeredCookies()

  let datafile
  if (typeof window.__OPTIMIZELY_DATAFILE__ !== 'undefined') {
    datafile = JSON.parse(window.__OPTIMIZELY_DATAFILE__)
  }

  const state = store.getState()

  const userId = getDomainUserId()
  const inStoreMode = state.config.storeMode
  const isSpoofed = state.auth.account.isSpoofed
  const regionCode = state.geo.currentRegionCode
  const utmSource = state.utm.source || ''
  const utmMedium = state.utm.medium || ''
  const utmCampaign = state.utm.campaign || ''
  const utmTerm = state.utm.term || ''
  const utmContent = state.utm.content || ''

  const optimizelyEnabled = userId && !inStoreMode

  if (!optimizelyEnabled || !datafile) {
    optimizelyResolver(false)
    return
  }

  const optimizelyClient = getClient(datafile)

  if (!optimizelyClient) {
    optimizelyResolver(false)
    return
  }

  const attributes = {
    userId,
    isSpoofed,
    leEmailDomain: isLEStaffOrAdmin(state),
    regionCode,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    deviceName: isCurrentDeviceMobile(state) ? 'Mobile' : 'Desktop',
    isLoggedIn: selectLoggedIn(state),
  }

  return optimizelyClient.onReady().then((result) => {
    if (result.success) {
      userContext = optimizelyClient.createUserContext(userId, attributes)!
      optimizelyResolver(true)
    } else {
      optimizelyResolver(false)
    }
  })
}

import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import useDefaultAirport from 'hooks/useDefaultAirport'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { isDepositAvailableOnOffer, isDepositEnabledForOfferType } from 'lib/payment/depositsUtils'
import CurrencyContext from 'contexts/currencyContext'
import SecureWithDepositTagAndTooltip from 'components/Common/SecureWithDeposit/SecureWithDepositTagAndTooltip'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'
import { useAppSelector } from 'hooks/reduxHooks'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { unique } from 'lib/array/arrayUtils'

const Pricing = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    align-items: center;
  }
`

interface Props {
  offer: App.Offer | App.OfferSummary;
}

function FrontPageTileFlightOnlyPricing(props: Props) {
  const { offer } = props
  const defaultAirport = useDefaultAirport()
  const currentCurrency = useContext(CurrencyContext)

  const flightPrice = offer.flightPrices[defaultAirport?.code]

  const prices = useOfferPackagePrice(offer.lowestPricePackage!, offer, { flightPrice })
  const isPerNightPricingTestEnabled = !!useAppSelector((state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  if (!flightPrice) {
    return (
      <Pricing>
        <CaptionTextBlock variant="medium" weight="bold" startIcon={<LinePlaneFlyIcon />}>
          Hotel + Flights packages
        </CaptionTextBlock>
      </Pricing>
    )
  }

  const totalPrice = prices.totalPriceWithFlights || 0
  const totalValue = prices.totalValue + (prices.flightPrice || 0)
  const showValue = offer.lowestPricePackage?.shouldDisplayValue && totalValue > totalPrice

  const showSecureWithDepositTag = isDepositEnabledForOfferType(offer.type) &&
    isDepositAvailableOnOffer(offer, currentCurrency, null, (offer.lowestPricePackage?.price ?? 0))

  return (
    <Pricing>
      { showSecureWithDepositTag && <SecureWithDepositTagAndTooltip offerType={offer.type} /> }
      <CaptionTextBlock variant="medium" weight="bold" startIcon={<LinePlaneFlyIcon />}>
        Hotel + Flights
      </CaptionTextBlock>
      <PriceRowPriceCaption>{offer.tileDurationLabel} from</PriceRowPriceCaption>
      <PriceRowPrice
        data-testid="search-price"
        size="L"
        price={Math.ceil(totalPrice)}
        saleUnit={isPerNightPricingTestEnabled ? prices.saleUnit : 'package'}
      />
      <PriceRowCaption>2 adults</PriceRowCaption>
      {showValue && <PriceRowValueDiscountWithCaption
        size="M"
        originalValue={Math.ceil(totalValue)}
      />}
      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerId={offer.id}
        offerLocations={offerLocations}
        vendorName={vendorName}
      />
    </Pricing>
  )
}

export default FrontPageTileFlightOnlyPricing

import requestUtils from 'api/requestUtils'
import { BusinessResponses } from '@luxuryescapes/contract-business'

async function requestCheckEmailAssociation(email: string) {
  try {
    const response = await requestUtils.get<BusinessResponses.EmployeeIndex>(`/api/business-traveller/employees?email=${encodeURIComponent(email)}`)

    const employee = response.result
    if (employee.status !== 'ACTIVATED') {
      return Promise.reject({ message: 'This email is not registered.' })
    }
    return true
  } catch {
    return Promise.reject({ message: 'This email is not registered.' })
  }
}

export default requestCheckEmailAssociation

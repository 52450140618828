import { sum } from 'lib/array/arrayUtils'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getCheckoutTotals from './getCheckoutTotals'

const getCheckoutTotal = createSelector(
  getCheckoutTotals,
  (checkoutTotals): number => {
    const { price, surcharge, otherFees } = checkoutTotals.data

    // need to adjust values to account for value possibly being undefined
    const adjPrice = price ?? 0
    const adjSurcharge = surcharge ?? 0
    const adjOtherFees = otherFees ? sum(Object.values(otherFees)) : 0
    return floatify(adjPrice + adjSurcharge + adjOtherFees)
  },
)

export default getCheckoutTotal

import { css } from 'styled-components'

const Reset = css`
  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }



  html {
    box-sizing: border-box;
    font-size: 80%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media (min-width: 370px) {
      font-size: 100%;
    }

    &:focus-within {
      scroll-behavior: smooth;
    }
  }

  body {
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    font-family: ${props => props.theme.font.primary.family};
    font-size: 1em;
    font-weight: ${props => props.theme.font.primary.weight.regular};
    line-height: 1;
    color: ${props => props.theme.palette.neutral.default.one};
    background-color: ${props => props.theme.palette.neutral.default.eight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    border: 0;
  }
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  input::-webkit-date-and-time-value {
    height: 1.5em;
    padding: 0;
    text-align: left;
  }

  input:-webkit-datetime-edit {
    padding: 0;
  }

  input[type="radio"],
  input[type="checkbox"],
  input[type="number"] {
    margin: 0;
  }

  input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  img {
    vertical-align: middle;
  }

  picture {
    display: block;
  }

  &:focus,
  &:active {
    outline: none;
  }

  button {
    &[disabled] {
      cursor: not-allowed;
    }
  }

  a,
  button {
    appearance: none;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    border-radius: 0;
    color: inherit;
    text-decoration: none;
    text-align: inherit;
    font-weight: inherit;
    cursor: pointer;
  }

  abbr[title] {
    text-decoration: none;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    margin-inline-start: unset;
    margin-inline-end: unset;
    padding-block-start: unset;
    padding-inline-start: unset;
    padding-inline-end: unset;
    padding-block-end: unset;
    min-inline-size: unset;
  }

  legend {
    padding-inline-end: unset;
    padding-inline-start: unset;
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`

export default Reset

import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { SEARCH_VERTICALS } from 'constants/search'
import { arrayToObject, groupBy, sum, without } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import moment, { Moment } from 'moment'

const REVERSE_SEARCH_VERTICALS = arrayToObject(Object.keys(SEARCH_VERTICALS), (key) => SEARCH_VERTICALS[key], (key) => key)

export function formatDate(date?: string | Moment, format: string = DMY_CASUAL_FORMAT) {
  return date ? moment(date).format(format) : undefined
}

export const getOccupancyDescription = (occupancies: Array<App.Occupants>, includeOccupancyCount: boolean = true, saleUnit: string = 'room') => {
  const hasChildren = occupancies.some(occ => occ.childrenAge?.length)
  const occupancyCount = sum(occupancies, occ => occ.adults + (occ.childrenAge?.length ?? 0))

  let description = `${pluralizeToString(hasChildren ? 'guest' : 'adult', occupancyCount)}`
  if (includeOccupancyCount) description += ` (${pluralizeToString(saleUnit, occupancies.length)})`
  return description
}

export const filterAirportRecords = (records: Array<App.AirportLocation>,
  departureAirport: App.AirportLocation,
  arrivalAirport: App.AirportLocation) => {
  if (records) {
    const originRecord = records.find(record => record.airportCode === departureAirport?.airportCode)
    const departureRecord = records.find(record => record.airportCode === arrivalAirport?.airportCode)
    const airportsWithoutSelections = without(records, originRecord, departureRecord)
    const groupedAirportRecords = groupBy(airportsWithoutSelections, record => record.cityCode)

    return [...groupedAirportRecords.entries()].map(groupedAirports =>
      groupedAirports[1].map((airport, index) => ({
        ...airport,
        isCityAirport: index !== 0,
      })),
    ).flat()
  }
  return []
}

export const getPathTabName = (pathName: string, searchParams: string) => {
  // Villas & Unique Stays search page flow
  const propertyTypes = new URLSearchParams(searchParams).get('propertyTypes')
  if (propertyTypes === 'UNIQUE_STAYS') return 'villas-and-unique-stays'

  // Tours, Experiences search page flow
  const searchTabPathName = pathName.replace('search/', '')

  // Flights search page flow
  if (searchTabPathName === 'flights-search-results') return 'flights'

  // All header pages flow
  if (searchTabPathName in REVERSE_SEARCH_VERTICALS) return searchTabPathName

  // Else any other page, return hotels search page flow
  return 'hotels'
}

import React, { ComponentProps, forwardRef } from 'react'
import TextInputOnly from './TextInputOnly'
import InputLabelWrap from './InputLabelWrap'

interface Props extends Omit<ComponentProps<typeof TextInputOnly>, 'ref'> {
  label?: React.ReactNode;
  optionalIndicatorEnabled?: boolean;
  uppercase?: boolean;
}

const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    style,
    optionalIndicatorEnabled,
    hidden,
    ...inputProps
  } = props

  return (
    <InputLabelWrap
      label={label}
      style={style}
      className={className}
      required={inputProps.required}
      optionalIndicatorEnabled={optionalIndicatorEnabled}
      hidden={hidden}
    >
      <TextInputOnly {...inputProps} ref={ref} />
    </InputLabelWrap>
  )
})

export default TextInput

import React, { useMemo } from 'react'
import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import OfferBadge from 'components/Common/OfferBadge'
import AspectRatio from 'components/utils/AspectRatio'
import { ImageParams } from 'components/Common/ResponsiveImage'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import HeroOfferTileWrapper from './HeroOfferTileWrapper'
import HeroBundleAndSaveOfferDetails from './HeroBundleAndSaveOfferDetails'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'

interface Props {
  offer: App.BundleOffer | App.BundleOfferSummary;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
}

const imageParams: ImageParams = {
  mobileAspectRatio: '3:2',
  tabletAspectRatio: '16:9',
  desktopAspectRatio: '19:9',
  mobileWidth: '80vw',
  desktopWidth: '67vw',
  largeDesktopWidth: '764px',
  quality: 'good',
}

function HeroBundleAndSaveOfferTile(props: Props) {
  const {
    offer,
    eagerLoadFirstImage,
  } = props

  const isMobileDevice = useIsMobileDevice()
  // Swiping through the offer tiles is tough when the image carousel can also swipe. So turn off the image carousel for mobile devices
  const images = useMemo(() => isMobileDevice ? [offer.images[0]] : offer.images, [isMobileDevice, offer.images])
  return (
    <ProductPaletteProvider product={offer}>
      <HeroOfferTileWrapper>
        <AspectRatio ratio="3:2" tabletRatio="16:9" desktopRatio="19:9" fillHeight>
          <ImageCarousel
            images={images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            topLeft={<OfferPartnershipLabel offer={offer} />}
            topRight={offer.badge && <OfferBadge badge={offer.badge} />}
          />
        </AspectRatio>
        <HeroBundleAndSaveOfferDetails offer={offer} />
      </HeroOfferTileWrapper>
    </ProductPaletteProvider>
  )
}

export default HeroBundleAndSaveOfferTile

import React, { useCallback, useMemo } from 'react'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import qs from 'qs'
import Divider from 'components/Luxkit/Divider'
import offerPageURL from 'lib/offer/offerPageURL'
import { pushWithRegion } from 'actions/NavigationActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import moment from 'moment/moment'
import CruiseSoldoutDepartureCard from 'components/OfferPage/Cruises/CruiseSoldoutDepartureCard'
import CruiseSoldoutStillInterested from 'components/OfferPage/Cruises/CruiseSoldoutStillInterested'
import CruiseModalPricesItem from 'components/Cruises/CruiseTiles/Common/CruiseModalPrices/CruiseModalPricesItem'
import { sortBy, groupBy } from 'lib/array/arrayUtils'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import Modal from 'components/Luxkit/Modal/Modal'

interface Props {
  offerTitle: string;
  offer?: App.CruiseOffer | App.Offer | App.OfferSummary;
  departures?: Record<string, Array<App.CruiseDepartureSummary>>;
  startDateFilter?: moment.Moment | string,
  endDateFilter?: moment.Moment | string,
  monthsFilter?: string,
}

function getGroupedDeparturesByYear(
  departureList: Array<App.CruiseDepartureSummary>,
  startDateFilter?: moment.Moment | string,
  endDateFilter?: moment.Moment | string,
  monthsFilter?: string,
) {
  const departureListSorted = sortBy(departureList, ({ departureDate }) => new Date(departureDate).getTime(), 'asc')
  const groupedDataByYear = groupBy(departureListSorted, ({ departureDate }) => new Date(departureDate).getFullYear())
  const cheapestDeparturePrice = sortBy(departureList, ({ lowestPriceTotal }) => lowestPriceTotal, 'asc')[0]?.lowestPriceTotal
  const months = monthsFilter?.split(',') || []

  return Array.from(groupedDataByYear.entries()).map(([year, departures]) => {
    const departuresData = departures.map(departure => {
      const isCheapest = departure.lowestPriceTotal === cheapestDeparturePrice
      let hasSelectedDates = false

      if (startDateFilter && endDateFilter) {
        hasSelectedDates = hasSelectedDates || (
          moment(departure.departureDate).isSameOrAfter(startDateFilter) &&
          moment(departure.departureDate).isSameOrBefore(endDateFilter)
        )
      }

      if (months.length) {
        hasSelectedDates = hasSelectedDates || months.includes(moment(departure.departureDate).format('YYYY-MM'))
      }

      return { ...departure, isCheapest, hasSelectedDates }
    })

    // set isCheapest to false when all offers has the same price
    const everyDeparturesSamePrice = departuresData.every(({ lowestPriceTotal }) => lowestPriceTotal === cheapestDeparturePrice)
    if (everyDeparturesSamePrice) {
      departuresData.forEach((departure) => {
        departure.isCheapest = false
      })
    }

    return {
      year,
      departures: departuresData,
    }
  })
}

function CruiseModalPrices({
  offerTitle,
  offer,
  departures,
  startDateFilter,
  endDateFilter,
  monthsFilter,
}: Props) {
  const dispatch = useAppDispatch()
  const { resolve } = useModalElementContext()

  const onClickModal = useCallback((departureDate: string) => {
    resolve()

    const url = offerPageURL(offer)
    const month = moment(departureDate).format('YYYY-MM')
    dispatch(pushWithRegion(url, qs.stringify({
      departureMonths: month,
      scrollToPackages: true,
    })))
  }, [resolve, dispatch, offer])

  const departureList = useMemo(() => Object.values(departures || {}).flat(), [departures])

  const departuresByYear = useMemo(
    () => getGroupedDeparturesByYear(departureList, startDateFilter, endDateFilter, monthsFilter),
    [departureList, startDateFilter, endDateFilter, monthsFilter],
  )

  const hasValidDepartures = departureList.length > 0

  return <Modal
    height="auto"
    title={`Dates & Pricing for ${offerTitle}`}
    >
    <Group direction="vertical" gap={32} data-testid="cruise-modal-prices-offer">
      {departuresByYear.map(({ year, departures }) => <Group direction="vertical" gap={8} key={year}>
        <Heading variant="heading6">{year}</Heading>

        <div>
          {departures.map((departure, index) => <div key={index}>
            <CruiseModalPricesItem
              departureDate={departure.departureDate}
              arrivalDate={departure.arrivalDate}
              price={departure.lowestPriceTotal}
              isCheapest={departure.isCheapest}
              hasSelectedDates={departure.hasSelectedDates}
              onClick={onClickModal}
            />
            {index !== departures.length - 1 && <Divider kind="secondary"/>}
          </div>)}
        </div>
      </Group>)}

      {!hasValidDepartures && <>
        <CruiseSoldoutDepartureCard />
        <CruiseSoldoutStillInterested useLinkButton />
      </>}
    </Group>
  </Modal>
}

export default CruiseModalPrices

import React, { PropsWithChildren } from 'react'
import Caption from '../Typography/Caption'
import Label from './Label'

type Variant = 'positive' | 'speed' | 'warning' | 'critical1' | 'critical2'

const variantConfig: Record<Variant, React.ComponentProps<typeof Label>> = {
  positive: {
    kind: 'tertiary',
    variant: 'ghost',
  },
  speed: {
    kind: 'tertiary',
    variant: 'ghost',
  },
  warning: {
    kind: 'tertiary',
    variant: 'ghost',
  },
  critical1: {
    kind: 'plain',
    variant: 'urgency',
  },
  critical2: {
    kind: 'primary',
    variant: 'urgency',
  },
}

interface Props {
  variant: Variant;
  className?: string;
  icon?: React.ReactNode;
}

const UrgencyLabel = React.forwardRef<HTMLSpanElement, PropsWithChildren<Props>>((props, ref) => {
  const { variant, children, icon, ...rest } = props

  return <Label
    {...rest}
    {...variantConfig[variant]}
    startIcon={icon}
    ref={ref}
  >
    <Caption variant={variant === 'critical1' ? 'medium' : 'small'} weight="bold">
      {children}
    </Caption>
  </Label>
})

UrgencyLabel.displayName = 'UrgencyLabel'

export default UrgencyLabel

import { paths } from '@luxuryescapes/contract-search'

type GetSpecificPlacesResult = paths['/api/search/place/v1/specific-places/{id}']['get']['responses']['200']['content']['application/json']['result']
export function mapSpecificPlace(externalSpecificPlace: Utils.ArrayElement<GetSpecificPlacesResult>): App.SpecificPlace {
  const mappedSpecificItem: App.SpecificPlace = {
    name: externalSpecificPlace.name,
    caption: externalSpecificPlace.caption,
    image: externalSpecificPlace.image,
    placeId: externalSpecificPlace.placeId,
  }
  return mappedSpecificItem
}

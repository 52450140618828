import config from 'constants/config'
import { API_CALL } from './actionConstants'
import {
  DELETE_USER_PREFERENCES_DESTINATION,
  DELETE_USER_PREFERENCES_EXTERNAL_BRANDS,
  DELETE_USER_PREFERENCES_HOLIDAY_TYPES,
  DELETE_USER_PREFERENCES_OCCUPANCY,
  FETCH_USER_PREFERENCES_DESTINATION,
  FETCH_USER_PREFERENCES_DESTINATION_DEFAULT,
  FETCH_USER_PREFERENCES_EXTERNAL_BRANDS,
  FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT,
  FETCH_USER_PREFERENCES_HOLIDAY_TYPES,
  FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT,
  FETCH_USER_PREFERENCES_OCCUPANCY,
  FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT,
  UPDATE_USER_PREFERENCES_DESTINATION,
  UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS,
  UPDATE_USER_PREFERENCES_HOLIDAY_TYPES,
  UPDATE_USER_PREFERENCES_OCCUPANCY,
} from './apiActionConstants'
import * as userTravelPreferencesService from 'api/userTravelPreferences'

export function fetchUserPreferenceDefaultDestinations(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_DESTINATION_DEFAULT,
    request: () => userTravelPreferencesService.fetchUserPreferenceDestinationDefault(userId),
  }
}

export function fetchUserPreferenceDestinations(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_DESTINATION,
    request: () => userTravelPreferencesService.fetchUserPreferenceDestination(userId),
  }
}

export function updateUserPreferenceDestination(userId: string, placeId: string) {
  const brand = config.BRAND
  return {
    type: API_CALL,
    api: UPDATE_USER_PREFERENCES_DESTINATION,
    request: () => userTravelPreferencesService.updateUserPreferenceDestination(
      userId,
      {
        preferences: {
          destination: {
            placeId,
            isAlertSelected: undefined,
          },
        },
      },
      brand,
    ),
  }
}

export function deleteUserPreferenceDestination(userId: string, placeId: string) {
  return {
    type: API_CALL,
    api: DELETE_USER_PREFERENCES_DESTINATION,
    request: () => userTravelPreferencesService.deleteUserPreferenceDestination(userId, placeId),
  }
}

export function fetchUserPreferenceDefaultHolidayTypes(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT,
    request: () => userTravelPreferencesService.fetchUserPreferenceHolidayTypesDefault(userId),
  }
}

export function fetchUserPreferenceHolidayTypes(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_HOLIDAY_TYPES,
    request: () => userTravelPreferencesService.fetchUserPreferenceHolidayTypes(userId),
  }
}

export function updateUserPreferenceHolidayType(userId: string, holidayTypeId: string) {
  const brand = config.BRAND
  const payload = {
    preferences: {
      holidayType: {
        holidayTypeId,
      },
    },
  }
  return {
    type: API_CALL,
    api: UPDATE_USER_PREFERENCES_HOLIDAY_TYPES,
    request: () => userTravelPreferencesService.updateUserPreferenceHolidayTypes(userId, payload, brand),
  }
}

export function deleteUserPreferenceHolidayType(userId: string, holidayTypeId: string) {
  return {
    type: API_CALL,
    api: DELETE_USER_PREFERENCES_HOLIDAY_TYPES,
    request: () => userTravelPreferencesService.deleteUserPreferenceHolidayTypes(userId, holidayTypeId),
  }
}

export function fetchUserPreferenceDefaultExternalBrands(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT,
    request: () => userTravelPreferencesService.fetchUserPreferenceExternalBrandsDefault(userId),
  }
}

export function fetchUserPreferenceExternalBrands(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_EXTERNAL_BRANDS,
    request: () => userTravelPreferencesService.fetchUserPreferenceExternalBrands(userId),
  }
}

export function updateUserPreferenceExternalBrand(userId: string, externalBrandId: string) {
  const brand = config.BRAND
  const payload = {
    preferences: {
      externalBrand: {
        externalBrandId,
      },
    },
  }
  return {
    type: API_CALL,
    api: UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS,
    request: () => userTravelPreferencesService.updateUserPreferenceExternalBrands(userId, payload, brand),
  }
}

export function deleteUserPreferenceExternalBrand(userId: string, externalBrandId: string) {
  return {
    type: API_CALL,
    api: DELETE_USER_PREFERENCES_EXTERNAL_BRANDS,
    request: () => userTravelPreferencesService.deleteUserPreferenceExternalBrands(userId, externalBrandId),
  }
}

export function fetchUserPreferenceDefaultOccupancy(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT,
    request: () => userTravelPreferencesService.fetchUserPreferenceOccupancyDefault(userId),
  }
}

export function fetchUserPreferenceOccupancy(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_USER_PREFERENCES_OCCUPANCY,
    request: () => userTravelPreferencesService.fetchUserPreferenceOccupancy(userId),
  }
}

export function updateUserPreferenceOccupancy(userId: string, rooms: Array<{numOfAdults: number, childrenAges: Array<number>}>, groupSizes: Array<'GROUP' | 'COUPLE' | 'SOLO' | 'FAMILY'>) {
  const brand = config.BRAND
  const payload = {
    preferences: {
      occupancy: {
        rooms,
        groupSizes,
      },
    },
  }
  return {
    type: API_CALL,
    api: UPDATE_USER_PREFERENCES_OCCUPANCY,
    request: () => userTravelPreferencesService.updateUserPreferenceOccupancy(userId, payload, brand),
  }
}

export function deleteUserPreferenceOccupancy(userId: string) {
  return {
    type: API_CALL,
    api: DELETE_USER_PREFERENCES_OCCUPANCY,
    request: () => userTravelPreferencesService.deleteUserPreferenceOccupancy(userId),
  }
}

import { computeAccommodationDepositAmount } from 'checkout/lib/utils/accommodation/deposit'
import { DepositType } from 'constants/checkout'
import { floatify } from 'lib/maths/mathUtils'

export function computeDepositAmount(
  payable: number,
  settings?: App.Checkout.DepositSettings,
): number {
  return settings ? computeAccommodationDepositAmount(payable, settings) : 0
}

export function computeCruiseDepositAmount(
  payable: number,
  settings?: App.Checkout.DepositSettings,
): number {
  if (!settings) return 0

  const { depositType, percentage, flatAmount } = settings
  return (depositType == DepositType.FLAT) ? floatify(flatAmount ?? 0) : floatify(payable * (percentage ?? 0) / 100)
}

import config from 'constants/config'
import { AppStore } from './store'
import { init as initialiseTrackers } from 'analytics/analytics'

function initialiseAppsFlyer() {
  if (!config.MOBILE_APP_CUSTOMISATIONS) return

  import(/* webpackChunkName: "AppsflyerCapacitorPlugin" */ 'appsflyer-capacitor-plugin')
    .then(appsFlyerModule => {
      const { AppsFlyer } = appsFlyerModule

      const afConfig = {
        appID: config.MOBILE_APP_AF_APP_ID,
        devKey: config.MOBILE_APP_AF_DEV_KEY,
        isDebug: config.MOBILE_APP_AF_DEBUG,
        waitForATTUserAuthorization: 10, // for iOS 14 and higher
        registerOnDeepLink: true,
        registerConversionListener: true,
        registerOnAppOpenAttribution: false,
        useReceiptValidationSandbox: true, // iOS only
        useUninstallSandbox: true, // iOS only
      }

      AppsFlyer.initSDK(afConfig)
        .catch(e => {
          console.error('Error in AppFlyer Initialization', e)
        })
    },
    )
    .catch(e => {
      console.error('Error importing AppsFlyer module', e)
    })
}

export default function initialiseAnalytics(store: AppStore) {
  initialiseTrackers(store)
  initialiseAppsFlyer()
}

import moment from 'moment'
import { DMY_CASUAL_FORMAT, ISO_DATE_SHORT_FORMAT } from 'constants/dateFormats'
import { sortBy } from 'lib/array/arrayUtils'

export function getOfferDeparturesSortedByDate(
  departuresSummary?: Record<string, Array<App.CruiseDepartureSummary>>,
): Array<App.CruiseDepartureSummary> {
  const departureList = Object.values(departuresSummary || {}).flat()
  return sortBy(
    departureList,
    ({ departureDate }) => moment(departureDate).unix(),
    'asc',
  )
}

export function getHighlightedDepartureDate(
  mainDepartureDate?: string,
  departuresSummary?: Record<string, Array<App.CruiseDepartureSummary>>,
  startDateFilter?: moment.Moment,
  endDateFilter?: moment.Moment,
  flexibleMonths?: string,
): {
  highlightedDepartureId?: string
  highlightedDepartureDate?: string
  otherDepartureDates: Array<string>
} {
  const departureList = getOfferDeparturesSortedByDate(departuresSummary)
  if (!departureList.length) {
    return {
      highlightedDepartureId: undefined,
      highlightedDepartureDate: mainDepartureDate,
      otherDepartureDates: [],
    }
  }

  let highlightedDeparture = departureList.find(({ departureDate }) => departureDate === mainDepartureDate) || departureList[0]

  if ((startDateFilter && endDateFilter) || flexibleMonths) {
    const months = flexibleMonths?.split(',') || []

    const departuresInDateFilter = departureList.filter((departure) => {
      const dateMoment = moment(departure.departureDate)
      const isInDateRange = !!startDateFilter && !!endDateFilter && (
        dateMoment.isSameOrAfter(startDateFilter) &&
        dateMoment.isSameOrBefore(endDateFilter)
      )

      const isInMonthFilter = (
        months.length > 0 &&
        months.includes(dateMoment.format(ISO_DATE_SHORT_FORMAT))
      )

      return isInMonthFilter || isInDateRange
    })

    const mainDepartureInDateFilter = departuresInDateFilter.find(({ departureDate }) => departureDate === mainDepartureDate)
    if (mainDepartureInDateFilter) {
      // Highlight the main departure if it is in the date filter
      highlightedDeparture = mainDepartureInDateFilter
    }
    else if (departuresInDateFilter.length) {
      // Highlight the first departure in the date filter
      highlightedDeparture = departuresInDateFilter[0]
    }
    else {
      // Highlight the nearest departure to the start date filter
      const filterDate = startDateFilter || moment(months[0])
      const nearestDeparture = sortBy(
        departureList,
        ({ departureDate }) => Math.abs(moment(departureDate).diff(filterDate)),
        'asc',
      )[0]

      highlightedDeparture = nearestDeparture || highlightedDeparture
    }
  }

  const otherDepartureDates = departureList
    .filter(({ id }) => id !== highlightedDeparture.id)
    .map(({ departureDate }) => moment(departureDate).format(DMY_CASUAL_FORMAT))

  return {
    highlightedDepartureId: highlightedDeparture.id,
    highlightedDepartureDate: moment(highlightedDeparture.departureDate).format(DMY_CASUAL_FORMAT),
    otherDepartureDates,
  }
}

export function checkHasOnlySuitesAvailable(highlightedDepartureId: string | undefined, offer: App.CruiseOffer): boolean {
  if (!highlightedDepartureId) return false
  const lowestDeparturePricesByCategory = offer.lowestDeparturePricesByCategory[highlightedDepartureId]
  if (!lowestDeparturePricesByCategory) return false

  const keys = Object.keys(lowestDeparturePricesByCategory)
  return keys.length === 1 && lowestDeparturePricesByCategory[keys[0]].cabinCategory === 'Suite'
}

import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

function getLuxPlusCheckoutItem(state: App.State, item: App.Checkout.LuxPlusSubscriptionItem) {
  return comluxgroup.createItem_1_1_0(
    {
      offerId: item.offerId,
      categoryId: 'luxury_plus',
      productId: 'luxury_plus',
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      price: item.amount,
      value: item.amount,
      offerLeadPrice: item.amount,
    },
  )
}
export default getLuxPlusCheckoutItem

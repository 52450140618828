import React, { useMemo, useContext } from 'react'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { sortBy, groupBy } from 'lib/array/arrayUtils'
import styled from 'styled-components'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import moment from 'moment'
import { DMY_CASUAL_FORMAT, SHORT_MONTH_LONG_YEAR } from 'constants/dateFormats'
import { CruiseDurationOptions } from './CruiseWhenSelectHowLong'
import CruiseWhenSelectDesktop from './CruiseWhenSelectDesktop'
import CruiseWhenSelectMobile from './CruiseWhenSelectMobile'

const CruiseWhenWrapper = styled.div`
  overflow: hidden;
`

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void
  dropdownAnchorRef: React.RefObject<HTMLElement>
  drawerMode?: boolean
}

function CruiseWhenSelect(props: Props) {
  const { drawerMode, onChange, dropdownAnchorRef } = props
  const { flexibleMonths, checkinDate, checkoutDate, durationRange } = useContext(GlobalSearchStateContext)

  const monthsLabel = useMemo(() => {
    const departureMonths = (flexibleMonths ?? '').split(',')
    const departureMonthDates = sortBy(
      departureMonths.filter(Boolean)
        .map((monthItem) => {
          const [year, month] = monthItem.split('-')
          return moment(`${year}-${month}-01`)
        })
      , (date) => date.valueOf(),
      'asc',
    )

    const departureMonthsByYear = groupBy(departureMonthDates, (date) => date.format('YYYY'))

    return Array.from(departureMonthsByYear.values())
      .map((months) => {
        const [first, ...rest] = months
        const firstMonth = first.format(SHORT_MONTH_LONG_YEAR)
        return rest.length ? `${firstMonth} +${rest.length}` : firstMonth
      }).join(', ')
  }, [flexibleMonths])

  const inputValue = useMemo(() => {
    const [firstDuration, ...durations] = CruiseDurationOptions.filter(opt => durationRange.includes(opt.duration))
    const valueDuration = firstDuration ? `${firstDuration.name}${durations.length ? ` +${durations.length}` : ''}` : null
    const dateRangeValues = !!checkinDate && !!checkoutDate && `${checkinDate.format(DMY_CASUAL_FORMAT)} - ${checkoutDate.format(DMY_CASUAL_FORMAT)}`

    return [valueDuration, dateRangeValues || monthsLabel].filter(Boolean).join(' · ')
  }, [checkinDate, checkoutDate, durationRange, monthsLabel])

  return <CruiseWhenWrapper>
    <CSSBreakpoint max="mobile">
      <CruiseWhenSelectMobile
        onChange={onChange}
        inputValue={inputValue}
      />
    </CSSBreakpoint>

    <CSSBreakpoint min="tablet">
      {drawerMode && <CruiseWhenSelectMobile
        onChange={onChange}
        inputValue={inputValue}
      />}

      {!drawerMode && <CruiseWhenSelectDesktop
        onChange={onChange}
        inputValue={inputValue}
        dropdownAnchorRef={dropdownAnchorRef}
      />}
    </CSSBreakpoint>
  </CruiseWhenWrapper>
}

export default CruiseWhenSelect

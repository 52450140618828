import OfferLabels from 'components/Common/Labels/OfferLabels'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import OfferBadgeLabel from 'components/Common/OfferBadgeLabel/OfferBadgeLabel'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import OfferTileLoggedInButtons from '../Support/OfferTileLoggedInButtons'
import React from 'react'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import { OFFER_TYPE_TOUR } from 'constants/offer'
import HeroOfferTileBundlePricing from '../Support/HeroHotelOfferTileBundlePricing'
import HeroOfferTilePricing from '../Support/HeroHotelOfferTilePricing'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import WalledContent from 'components/Common/WalledContent'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'

const DetailContainer = styled(Group)`
  height: 100%;
  padding-top: ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)} ${rem(32)} ${rem(32)} ${rem(32)};
  }
`

const HeaderActions = styled.div`
  display: grid;
  grid-template: "review save";
`

const SaveButtonWrapper = styled.div`
  grid-area: save;
  justify-self: end;
`

const ReviewWrapper = styled.div`
  grid-area: review;
`

const MinHeightGroup = styled(Group)`
  min-height: ${rem(300)};
  flex-grow: 1;
`

interface Props {
  offer: App.Offer | App.OfferSummary;
}

function HeroHotelOfferDetails(props: Props) {
  const { offer } = props
  const {
    property,
    lowestPricePackage,
  } = offer
  const location = offer.type === OFFER_TYPE_TOUR ? offer.location : [offer.locationHeading, offer.locationSubheading].filter(t => t).join(', ')
  const pricingSection = (
    <>
      {offer.bundledWithFlightsOnly && lowestPricePackage && (
        <HeroOfferTileBundlePricing offer={offer} pkg={lowestPricePackage} />
      )}
      {!offer.bundledWithFlightsOnly && lowestPricePackage && (
        <HeroOfferTilePricing
          offer={offer}
          pkg={lowestPricePackage}
        />
      )}
    </>
  )

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    discountPercentage={Math.round(lowestPricePackage?.discountPercent || 0)}
    align="none"
    fullWidth
  />

  return (
    <DetailContainer direction="vertical" gap={16}>
      <CSSBreakpoint min="desktop">
        <HeaderActions>
          {isOfferRatingDisplayable(offer.property?.rating) && <ReviewWrapper>
            <OfferRating variant="medium" rating={offer.property.rating} />
          </ReviewWrapper>}
          <TripGuard>
            <SaveButtonWrapper>
              <BookmarkButton offer={offer} />
            </SaveButtonWrapper>
          </TripGuard>
        </HeaderActions>
      </CSSBreakpoint>
      <MinHeightGroup direction="vertical" gap={16} tabletGap={24} verticalAlign="space-between">
        <Group direction="vertical" gap={4} horizontalAlign="start">
          <Caption variant="large" colour="neutral-one">
            {location}<Caption variant="large" colour="neutral-three" as="span"> · Hotel</Caption>
          </Caption>
          <LabelGroup>
            <OfferLabels offer={offer} />
            {offer.badge && <OfferBadgeLabel badge={offer.badge} />}
          </LabelGroup>
          <Heading variant="heading6" lineClamp={3}>{property?.name ?? offer.name}</Heading>
          <ProductTypeLabel productType={offer.productType} kind="plain" />
          {isOfferRatingDisplayable(offer.property?.rating) && <CSSBreakpoint max="tablet">
            <OfferRating
              inlineLabel
              variant="xs"
              rating={offer.property.rating}
            />
          </CSSBreakpoint>}
          {property && <BodyText variant="medium" colour="neutral-one" lineClamp={3}>
            {offer.name}
          </BodyText>}
        </Group>
        <Group direction="vertical" gap={8} >
          <WalledContent enforced={offer.walledGarden} wall={wall} >
            {pricingSection}
          </WalledContent>
        </Group>
      </MinHeightGroup>
    </DetailContainer>
  )
}

export default HeroHotelOfferDetails

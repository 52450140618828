import React, { ComponentProps, ComponentType, Fragment } from 'react'
import styled from 'styled-components'
import { fillArray } from 'lib/array/arrayUtils'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from './Typography'
import { rem } from 'polished'
import Heading from './Heading'
import Subtitle from './Subtitle'
import BodyText from './BodyText'
import Caption from './Caption'
import { EmptyObject } from 'lib/object/objectUtils'

const TextBox = styled(LoadingBox)`
  display: inline-block;
  border-radius: ${props => props.theme.borderRadius.S};
  /* subtracting 2px vertically to allow distinguishable gap in multiline */
  height: calc(var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}, 1em) - 2px);
  width: 100%;
  vertical-align: middle;
`

type TextLoadingBoxWidth = number | `${number}%` | `${number}ch`
export function isTextLoadingBoxWidth(value: unknown): value is TextLoadingBoxWidth {
  return typeof value === 'number' || (typeof value === 'string' && !!value.match(/^\d+[%,ch]$/g))
}

type TypographyVariant =
  ComponentProps<typeof Heading>['variant'] |
  ComponentProps<typeof Subtitle>['variant'] |
  `body-${ComponentProps<typeof BodyText>['variant']}` |
  `caption-${ComponentProps<typeof Caption>['variant']}`

type TypographyComponentsProps<P = any> = Record<TypographyVariant, [Component: ComponentType<P>, props: P]>
const TYPOGRAPHY_COMPONENTS_PROPS: TypographyComponentsProps = {
  heading1: [Heading, { variant: 'heading1' }],
  heading1alt: [Heading, { variant: 'heading1alt' }],
  heading2: [Heading, { variant: 'heading2' }],
  heading2alt: [Heading, { variant: 'heading2alt' }],
  heading3: [Heading, { variant: 'heading3' }],
  heading3alt: [Heading, { variant: 'heading3alt' }],
  heading4: [Heading, { variant: 'heading4' }],
  heading5: [Heading, { variant: 'heading5' }],
  heading6: [Heading, { variant: 'heading6' }],
  subtitle1: [Subtitle, { variant: 'subtitle1' }],
  subtitle2: [Subtitle, { variant: 'subtitle2' }],
  subtitle3: [Subtitle, { variant: 'subtitle3' }],
  'body-small': [BodyText, { variant: 'small' }],
  'body-medium': [BodyText, { variant: 'medium' }],
  'body-large': [BodyText, { variant: 'large' }],
  'caption-small': [Caption, { variant: 'small' }],
  'caption-medium': [Caption, { variant: 'medium' }],
  'caption-large': [Caption, { variant: 'large' }],
}

interface Props extends Pick<ComponentProps<typeof LoadingBox>, 'colour'> {
  /**
   * sets the number of lines to be generated
   *
   * must be bigger than zero
   *
   * @default 1
   */
  lines?: number
  /**
   * sets the width of the box
   *
   * must be bigger than zero
   *
   * @example
   * 200 => 200px
   * 50% => half the width of the parent
   * 10ch => 10 characters wide
   *  */
  width: TextLoadingBoxWidth
  /**
   * should only be defined if the loading box is supposed to be standalone
   *
   * otherwise, the dimensions will be inferred from the wrapping typography parent
   */
  typography?: TypographyVariant
  className?: string
  inline?: boolean;
  style?: React.CSSProperties;
}

function TextLoadingBox(props: Props) {
  const {
    colour,
    lines = 1,
    width,
    typography,
    className,
    inline,
    style,
  } = props

  const [Wrapper, wrapperProps] = typography ? TYPOGRAPHY_COMPONENTS_PROPS[typography] : [undefined, EmptyObject]

  if (inline && typography) {
    wrapperProps.as = 'span'
  }

  if (!lines) {
    return null
  }

  const element = <>
    {lines === 1 && <TextBox
      forwardedAs={inline && !typography ? 'span' : undefined}
      className={className}
      style={{ width: Number.isInteger(width) ? rem(width) : width }}
      colour={colour}
    />}
    {(lines > 1) && fillArray(lines).map(line => <Fragment key={`${width}-${line}`}>
      <TextBox colour={colour} className={className} style={{ maxWidth: Number.isInteger(width) ? rem(width) : width }} />
      <br />
    </Fragment>)}
  </>

  if (Wrapper) {
    return <Wrapper {...wrapperProps} style={style}>
      {element}
    </Wrapper>
  }

  return element
}

export default TextLoadingBox

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import Group from 'components/utils/Group'
import * as Analytics from 'analytics/analytics'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import { ModalSteps } from '../defaults'
import TravelPreferencesImageItemsGrid from '../TravelPreferencesImageItemsGrid/TravelPreferencesImageItemsGrid'
import { UserPreferenceHolidayOrBrands } from '../types'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { deleteUserPreferenceHolidayType, updateUserPreferenceHolidayType } from 'actions/userTravelPreferencesActions'

interface Props {
  defaultDisplayHolidayTypes?: Array<App.DefaultUserPreferenceHolidayType>;
  selectedHolidayTypes?: Array<App.UserPreferenceHolidayType>;
  onSetNextStep: (step: ModalSteps, isSaveAction?: boolean) => void;
}

function TravelPreferencesModalHolidayTypes(props: Props) {
  const { defaultDisplayHolidayTypes, selectedHolidayTypes, onSetNextStep } = props

  const dispatch = useAppDispatch()
  const userId = useAppSelector(getCurrentUserId)
  const initialSelectedIds: Set<string> = useMemo(() => new Set(selectedHolidayTypes?.map(h => h.holidayTypeId) || []), [selectedHolidayTypes])
  const displayItems: Array<UserPreferenceHolidayOrBrands> | undefined = useMemo(() => (
    defaultDisplayHolidayTypes?.map(i => ({ name: i.holidayTypeDisplayName, id: i.holidayTypeId, image: i.imageId }))
  ), [defaultDisplayHolidayTypes])
  const [selectedStays, setSelectedStays] = useState<Set<string>>(new Set([]))

  useEffect(() => {
    if (selectedHolidayTypes) {
      setSelectedStays(new Set(selectedHolidayTypes.map(h => h.holidayTypeId)))
    }
  }, [selectedHolidayTypes])

  const onToggleSelection = useCallback((item: UserPreferenceHolidayOrBrands) => {
    setSelectedStays(prevSelected => {
      const newSelected = new Set(prevSelected)

      if (newSelected.has(item.id)) {
        newSelected.delete(item.id)
      } else {
        newSelected.add(item.id)
      }

      return newSelected
    })
  }, [])

  const onSkip = useCallback(() => {
    onSetNextStep(ModalSteps.destinations)
  }, [onSetNextStep])

  const onUpdatePreferences = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'favourite_stays',
      action: 'completion',
      type: 'interaction',
      category: 'travel_preferences',
    })

    // compare the current state and redux state, and then send the requests
    for (const id of selectedStays) {
      if (!initialSelectedIds.has(id)) {
        dispatch(updateUserPreferenceHolidayType(userId!, id))
      }
    }
    for (const id of initialSelectedIds) {
      if (!selectedStays.has(id)) {
        dispatch(deleteUserPreferenceHolidayType(userId!, id))
      }
    }

    onSetNextStep(ModalSteps.destinations, true)
  }, [dispatch, onSetNextStep, selectedStays, initialSelectedIds, userId])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'favourite_stays',
      action: 'impression',
      type: 'nonInteraction',
      category: 'travel_preferences',
    })
  }, [])

  return (
    <>
      <ModalBody>
        <ModalContent>
          <Group direction="vertical" gap={16}>
            <BodyText variant="medium">Tell us your travel style and we'll send you our best offers, curated just to you.</BodyText>
            <TravelPreferencesImageItemsGrid
              items={displayItems || []}
              onSelect={onToggleSelection}
              selectedId={selectedStays}
            />
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter primaryActionProps={{ children: 'Save & continue', onClick: onUpdatePreferences }} secondaryActionProps={{ children: 'Skip', onClick: onSkip }} />
    </>
  )
}

const mapStateToProps = (state: App.State) => ({
  defaultDisplayHolidayTypes: state.userTravelPreferences.defaultHolidayTypes.data,
  selectedHolidayTypes: state.userTravelPreferences.holidayTypes.data,
})

export default connect(mapStateToProps)(TravelPreferencesModalHolidayTypes)

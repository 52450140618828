import React, { useCallback, useContext } from 'react'
import SearchFloatingPanel, {
  SearchFloatingPanelProps,
} from 'components/SearchV2/Components/Mobile/Panels/SearchFloatingPanel'
import { GlobalSearchDispatchContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import styled from 'styled-components'
import { rem } from 'polished'
import { beginningOfToday } from 'lib/datetime/dateUtils'
import {
  ISO_DATE_FORMAT,
  SHORT_DAY_NAME_DAY_MONTH,
  SHORT_DAY_NAME_DAY_MONTH_YEAR,
} from 'constants/dateFormats'
import { pluralizeToString } from 'lib/string/pluralize'
import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import ButtonGroupToggle from 'components/Common/ButtonGroupToggle'
import BodyText from 'components/Luxkit/Typography/BodyText'
import FlexibleDatePicker from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker'
import { DATE_SEARCH_OPTION_IDS, FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import config from 'constants/config'
import {
  DurationOption,
  durationOptionsDefault,
} from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker/DurationPicker'
import { FlightDropdownOption } from 'components/Flights/types'
import SearchDatesPicker from 'components/SearchV2/Components/Mobile/DateSearchFloatingPanel/SearchDatePicker'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Caption from 'components/Luxkit/Typography/Caption'

const ContentContainer = styled(Group)`
  padding-top: ${rem(20)};
  height: 100%;
`

const today = beginningOfToday()

interface Props
  extends Omit<
    SearchFloatingPanelProps,
    'title' | 'isConfirmButtonHidden' | 'children'
  > {
  checkinDate?: moment.Moment;
  checkoutDate?: moment.Moment;
  flexibleNights?: FLEXIBLE_DURATION_RANGE,
  flexibleMonths?: string,
  confirmButtonDisabled: boolean;
  activeDateToggleButton?: {
    id: string;
    label: string;
  };
  dateToggleButtons?: Array<{
    id: string;
    label: string;
  }>;
  onToggleDateSelection?: (item: any, e: React.MouseEvent) => void;
  handleDatesChanged?: (dates: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }) => void;
  hasDates: boolean;
  minDate?: Date;
  maxDate?: Date;
  departureAirport?: App.AirportLocation;
  arrivalAirport?: App.AirportLocation;
  userSelectedFlexibleMonths?: boolean;
  title?: string;
  durationOptionsCustom?: Array<DurationOption>;
  hideDatePicker?: boolean;
  fareType?: FlightDropdownOption;
  label?: 'day' | 'night';
  includeLastDayOnCount?: boolean;
  showFlexibleYearSelector?: boolean;
}

function DateFloatingPanel(props: Props) {
  const {
    checkinDate,
    checkoutDate,
    flexibleNights,
    flexibleMonths,
    confirmButtonDisabled,
    activeDateToggleButton,
    dateToggleButtons,
    onToggleDateSelection,
    handleDatesChanged,
    hasDates,
    minDate,
    maxDate,
    userSelectedFlexibleMonths = false,
    title = 'When do you want to go?',
    durationOptionsCustom = durationOptionsDefault,
    hideDatePicker = false,
    label = 'night',
    includeLastDayOnCount = false,
    showFlexibleYearSelector,
  } = props

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  const handleFlexibleNightsChanged = useCallback(
    (flexibleNights: FLEXIBLE_DURATION_RANGE) => {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION, flexibleNights })
      if (flexibleNights) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: DATE_SEARCH_OPTION_IDS.FLEXIBLE })
      }
    },
    [globalSearchDispatch],
  )

  const handleFlexibleMonthOptionsChanged = useCallback(
    (flexibleMonthOptions: string) => {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE,
        flexibleMonths: flexibleMonthOptions,
      })
      if (flexibleMonthOptions.length > 0) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: DATE_SEARCH_OPTION_IDS.FLEXIBLE })
        globalSearchDispatch({
          type: GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS,
          userSelectedFlexibleMonths: true,
        })
      } else {
        globalSearchDispatch({
          type: GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS,
        })
      }
    },
    [globalSearchDispatch],
  )

  return (
    <SearchFloatingPanel
      {...props}
      title={title}
      confirmButtonDisabled={confirmButtonDisabled}
    >
      <ContentContainer direction="vertical" verticalAlign="space-between">
        <VerticalSpacer gap={24}>
          {config.businessTraveller.currentAccountMode !== 'business' && dateToggleButtons && (
            <ButtonGroupToggle
              items={dateToggleButtons}
              activeItem={activeDateToggleButton}
              onItemClick={onToggleDateSelection}
            />
          )}
          {(activeDateToggleButton?.id === DATE_SEARCH_OPTION_IDS.FLEXIBLE ||
          (hideDatePicker && activeDateToggleButton?.id === DATE_SEARCH_OPTION_IDS.ANYTIME)) && (
            <FlexibleDatePicker
              onMonthChange={handleFlexibleMonthOptionsChanged}
              onDurationChange={handleFlexibleNightsChanged}
              previouslySelectedDuration={flexibleNights}
              previouslySelectedMonths={userSelectedFlexibleMonths ? flexibleMonths : undefined}
              durationOptionsCustom={durationOptionsCustom}
              showYearSelector={showFlexibleYearSelector}
            />
          )}
          {!hideDatePicker && activeDateToggleButton?.id !== DATE_SEARCH_OPTION_IDS.FLEXIBLE && (
            <SearchDatesPicker
              startDate={checkinDate}
              endDate={checkoutDate}
              onDatesChange={handleDatesChanged}
              minDate={minDate ?? today}
              maxDate={maxDate}
              type="single"
            />
          )}
        </VerticalSpacer>
        {hasDates && checkinDate && checkoutDate && (
          <Group
            direction="horizontal"
            verticalAlign="center"
            horizontalAlign="center"
            gap={8}
          >
            <BodyText as="time" variant="medium" weight="semi-bold">
              <time dateTime={checkinDate.format(ISO_DATE_FORMAT)}>
                {checkinDate.format(SHORT_DAY_NAME_DAY_MONTH)}
              </time>
            </BodyText>
            <LineArrowRightIcon size="M" />
            <BodyText variant="medium" weight="semi-bold">
              <time dateTime={checkoutDate.format(ISO_DATE_FORMAT)}>
                {checkoutDate.format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}
              </time>
            </BodyText>
            <Caption variant="large">
              ({pluralizeToString(label, checkoutDate.diff(checkinDate, 'days') + (includeLastDayOnCount ? 1 : 0))})
            </Caption>
          </Group>
        )}
      </ContentContainer>
    </SearchFloatingPanel>
  )
}

export default DateFloatingPanel

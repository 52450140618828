import { take } from 'lib/array/arrayUtils'
import dedupeConcat from 'lib/array/arrayConcatDedupe'
import {
  set as setLocalStorage,
  get as getLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { timeNowInSecond } from 'lib/datetime/time'
const LOCAL_STORAGE_HIGH_INTENT_OFFERS_KEY = 'highIntentOffers'

export function getHighIntentOffersFromLocal(): Array<App.RecentlyViewedOffer | App.HotLeadOffer> {
  const highIntentOffers = getLocalStorage(LOCAL_STORAGE_HIGH_INTENT_OFFERS_KEY) as Array<App.RecentlyViewedOffer | App.HotLeadOffer> | undefined
  if (highIntentOffers) {
    const oneWeekInSecond = 604800
    const nowInSecond = timeNowInSecond()
    const notExpiredOffers = highIntentOffers.filter(offer => offer.creationTime > nowInSecond - oneWeekInSecond)
    setLocalStorage(LOCAL_STORAGE_HIGH_INTENT_OFFERS_KEY, notExpiredOffers)
    return notExpiredOffers
  }
  return []
}

export function saveHighIntentOffersToLocal(highIntent: Array<App.RecentlyViewedOffer | App.HotLeadOffer>) {
  const localHighIntent = getHighIntentOffersFromLocal()
  const maxNumLocalHighIntent = 10
  const newLocalHighIntent = take(dedupeConcat(highIntent, localHighIntent, (val) => val.offerId), maxNumLocalHighIntent)
  setLocalStorage(LOCAL_STORAGE_HIGH_INTENT_OFFERS_KEY, newLocalHighIntent)
}

export function removeHighIntentOffersFromLocal(offerId: Array<string>) {
  const localArray = getHighIntentOffersFromLocal()
  const newLocalHighIntent = localArray.filter(offer => !offerId.includes(offer.offerId))
  setLocalStorage(LOCAL_STORAGE_HIGH_INTENT_OFFERS_KEY, newLocalHighIntent)
}

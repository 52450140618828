import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBathIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22 12H5V6.4101a1.9747 1.9747 0 0 1 1.04-1.7587 1.9947 1.9947 0 0 1 1.1476-.2312 3.4911 3.4911 0 0 0 .8377 3.5544L9.086 9.0352a.9994.9994 0 0 0 1.4141 0L14.0352 5.5a.999.999 0 0 0 .2168-1.0897 1.0013 1.0013 0 0 0-.2168-.3244l-1.0606-1.0605a3.494 3.494 0 0 0-4.5297-.3425A3.9924 3.9924 0 0 0 3 6.41V12H2a.9997.9997 0 0 0-1 1 1 1 0 0 0 1 1h1v3a2.9946 2.9946 0 0 0 2 2.8157V21a1 1 0 1 0 2 0v-1h10v1a1.0001 1.0001 0 0 0 2 0v-1.1843A2.9948 2.9948 0 0 0 21 17v-3h1a1.0001 1.0001 0 0 0 0-2ZM9.4395 4.4394a1.5019 1.5019 0 0 1 2.1211 0l.3535.3536L9.793 6.914l-.3535-.3535a1.5012 1.5012 0 0 1 0-2.121ZM19 17a1.0007 1.0007 0 0 1-1 1H6a1.0008 1.0008 0 0 1-1-1v-3h14v3Z"/>
  </SvgIcon>
}

export default LineBathIcon

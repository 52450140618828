import { Moment } from 'moment'

import { NoteItem, TripItem } from './tripItem'
import { GetUserSettingsResponse } from 'tripPlanner/api/userSettings/types'

export type BookmarkIdSet = {
  offerId: string
  itemId: string
}

export interface TripItemAndOfferAndDatesIdSet {
  itemId: string
  offerIdAndMeta: string
  roomTypeId?: string
}

export type RefreshedTrip = FullTrip & {
  changeCount: number
}

export type CreateItemsBatchResponse = FullTrip & {
  updatedItemIds: Array<string>
}

export type Collaborator = {
  id: string
  role: Role
  email?: string
  givenName?: string
  surname?: string
}

export type Invitation = {
  id: string
  email: string
  role: Exclude<Role, 'OWNER'>
}

export type Role = 'VIEWER' | 'EDITOR' | 'OWNER' | 'ADMIN'
export type GuestRole = Exclude<Role, 'OWNER' | 'ADMIN'>

export interface BaseFullTrip {
  id: string
  name: string
  startDate: Moment | undefined
  endDate: Moment | undefined
  imageId?: string
  startDay?: number
  endDay?: number
}

export interface PlaceGeo {
  point: {
    lat: number
    lng: number
  }
  lePlaceName: string
  lePlaceId: string
  cityCode: string
  shortName?: string
  countryCode?: string
  type?: string
}

export interface TravellerRoom {
  adults: number
  childAges: Array<number>
}

export interface FullTrip extends BaseFullTrip {
  createdAt: Moment
  updatedAt: Moment
  interactedAt: Moment
  items: Array<TripItem>
  orderIds?: Array<string>
  role: Role
  isTemplate: boolean
  description?: string
  originGeo?: PlaceGeo
  destinationsGeo?: Array<PlaceGeo>
  isAgentAttributed: boolean
  travellerRooms?: Array<TravellerRoom>
  isConciergeTrip: boolean
}

export type AirportLocation = {
  id: number
  rank?: number
  cityAirportName?: string
  cityCode: string
  cityDesc: string
  isMultiAirport: boolean
  isChildDisplay: boolean
  airportCode: string
  airportName: string
  countryCode: string
  country: string
  isBlocked: boolean
  timezoneKey?: string
  lat?: number
  lng?: number
  timeZone?: string
  icao?: string
  region?: string
  geoCategory?: string
  pointOfInterest?: string
  keywords?: string
  nearAirports?: string
  misspelling?: string
}

export type TripLocation = {
  placeGroup: {
    lePlaceId: string
    lePlaceName: string
    cityCode: string
  }
  isOrigin: boolean
  isLayover: boolean
  startDate?: Moment
  endDate?: Moment
  accommodation?: {
    startDate: Moment
    endDate: Moment
  }
  experiences?: {
    startDate: Moment
    endDate: Moment
  }
  transportTo?: {
    arrivalDate: Moment
  }
  transportFrom?: {
    departureDate: Moment
  }
  itemIds: Array<string>
  startItemIds: Array<string>
  endItemIds: Array<string>
  airports: Array<AirportLocation>
  country?: {
    lePlaceId: string
    lePlaceName: string
    leCountryCode?: string
  }
}

export type TripMetadata = {
  tripId: string
  minGuests?: number
  maxGuests?: number
  locations: Array<TripLocation>
  counts?: {
    days: number
    nights: number
    locations: number
    countries: number
  }
}

export type ExperienceRecommendationData = {
  destinationId: string
  destinationName: string
  count: number
}

export type FlightRecommendationData = {
  origin: {
    airportCode: string
    cityCode: string
    cityName: string
  }
  destination: {
    airportCode: string
    cityCode: string
    cityName: string
  }
  departDate: Moment
  returnDate: Moment
  fareClass: string
  passengers: {
    adults: number
    children: number
    infants: number
  }
}

export type HotelRecommendationData = {
  destinationId: string
  destinationName: string
  startDate: Moment
  endDate: Moment
  occupancy: {
    adults: number
    children: number
    rooms: number
  }
}

export type LayoverRecommendationData = {
  destinationId: string
  destinationName: string
  timezone: string
  startDate: Moment
  startTime: string
  endDate: Moment
  endTime: string
  startUtcDateTime: Moment
  endUtcDateTime: Moment
  layoverDurationText: string
  layoverTitle: string
}

export type ExperienceRecommendation = {
  type: 'EXPERIENCE'
  data: ExperienceRecommendationData
}

export type FlightRecommendation = {
  type: 'FLIGHT'
  data: FlightRecommendationData
}

export type HotelRecommendation = {
  type: 'HOTEL' // hmm?
  data: HotelRecommendationData
}

export type LayoverRecommendation = {
  type: 'LAYOVER'
  data: LayoverRecommendationData
}

export type Recommendation =
  | ExperienceRecommendation
  | FlightRecommendation
  | HotelRecommendation
  | LayoverRecommendation

export type TripSummaryItem = {
  type: 'ITEM'
  item: TripItem
}
export type TripSummaryRecommendation = {
  type: 'RECOMMENDATION'
  recommendation: Recommendation
}
export type TripSummaryEntry = TripSummaryItem | TripSummaryRecommendation

// Trip Summary
export type TripSummary = {
  id: string
  entries: Array<TripSummaryEntry>
}

export interface Location {
  lePlaceId: string
  cityCode?: string
  lePlaceName?: string
  countryLePlaceId?: string
  countryLePlaceName?: string
  countryLeCode?: string
}

export interface Country {
  countryLePlaceId: string
  countryLePlaceName?: string
  countryLeCode?: string
}

export interface Metadata {
  locations?: Array<Location>
  countries?: Array<Country>
  days?: number
}

export type BasicTrip = {
  id: string
  name: string
  createdAt: Moment
  updatedAt: Moment
  interactedAt: Moment
  startDate: Moment | undefined
  endDate: Moment | undefined
  imageUrl?: string
  imageId?: string
  bookmarkIdSets: Array<BookmarkIdSet>
  plannedIdSets: Array<TripItemAndOfferAndDatesIdSet>
  orderIds?: Array<string>
  role: Role
  isTemplate?: boolean
  description?: string
  curator?: Curator
  metadata?: Metadata
  itemCount: number
  destinationsGeo?: Array<PlaceGeo>
  isActivelyPlanned: boolean
  isConciergeTrip: boolean
}

export type FullDestination = {
  id: string
  tripId: string
  place: DestinationPlace
  startDate: Moment | undefined
  endDate: Moment | undefined
  items?: Array<TripItem>
  imageUrl?: string
}

export type BasicDestination = {
  id: string
  tripId: string
  place: DestinationPlace
  startDate: Moment | undefined
  endDate: Moment | undefined
  nights?: number
  imageUrl?: string
  sortOrder?: number
}

export type DestinationPlace = {
  id: string
  name: string
  parentRegion: string
  point: GeoPoint
}

export type Place = GeoPlace | CustomPlace

export type GeoPoint = {
  lat: number
  lng: number
}

export type StartDateTimeString = {
  startTime: string | undefined
  startDate: string | undefined
  startUtcDateTime: string | undefined
}

export type StartDateTimeMoment = {
  startTime: Moment | undefined
  startDate: Moment | undefined
  startUtcDateTime: Moment | undefined
}

export type StartDateTimeMomentWithTimeZone = {
  startTimezone: string | undefined
} & StartDateTimeMoment

export type EndDateTimeMomentWithTimeZone = {
  endTimezone: string | undefined
} & EndDateTimeMoment

export interface ChronologicalTripItem
  extends StartDateTimeMomentWithTimeZone,
    EndDateTimeMomentWithTimeZone {
  type: TripItem['type']
}

export type EndDateTimeString = {
  endTime: string | undefined
  endDate: string | undefined
  endUtcDateTime: string | undefined
}

export type EndDateTimeMoment = {
  endTime: Moment | undefined
  endDate: Moment | undefined
  endUtcDateTime: Moment | undefined
}

type LEPlace = {
  lat: number
  lng: number
  city: {
    placeId: string
    name: string
    code: string
  }
  country: {
    placeId: string
    name: string
    code?: string
  }
}

export type GeoPlace = {
  type: 'GEO'
  id?: string | undefined
  name: string
  address?: string | undefined
  point: GeoPoint
  photoUrl?: string | undefined
  region?: string | undefined
  lePlace?: LEPlace
}

export type CreateGeoPlaceProp = Omit<
  DestinationPlace,
  'parentRegion' | 'point'
>

export type CustomPlace = {
  type: 'CUSTOM'
  name: string
  address?: string | undefined
  photoUrl?: string | undefined
  region?: string | undefined
}

export type SourceType =
  | 'NA'
  | 'Explore'
  | 'LE_Hotel'
  | 'LE_Hotel_Bedbank'
  | 'LE_Flight'
  | 'LE_Cruise'
  | 'LE_Tour'
  | 'LE_Experience'

export type FlightSchedule = {
  /** A key to make it easier to distinguish between multiple flight legs with the same flight number */
  key: string
  airlineCode: string
  airlineName: string
  flightNumber: number
  departureAirportCode: string
  arrivalAirportCode: string
  departureDate: Moment
  departureTime: Moment
  arrivalDate: Moment
  arrivalTime: Moment
  flightDuration: number
}

export type UserPermissions = {
  canEdit: boolean
  canAdmin: boolean
  canPublish: boolean
}

export interface CuratedTripSection {
  sectionHeader?: NoteItem
  items: Array<TripItem>
}

export interface Curator {
  name?: string
  imageId?: string
  position?: string
  showBio?: boolean
}

export interface ReviewFormData {
  name: string
  title?: string
  position?: string
  description?: string
  imageData?: string
  showBio?: boolean
  updateAll?: boolean
  imageId?: string
}

export const NATIVE_NAVIGATION = 'nativeNavigation'
export const NATIVE_NAVIGATION_ERROR = 'nativeNavigationError'
export const TRIP_ITEM_CREATED = 'tripItemCreated'
export const TRIP_ITEM_DELETED = 'tripItemDeleted'

export const CLOSE_MODAL = 'CloseModal'
export const MANAGE_BOOKING = 'ManageBooking'

export interface SlugToPlace {
  lePlaceId: string
  lePlaceName: string
}
export interface RecommendedOffer {
  type: 'offer'
  value: string
}

export interface RecommendedExperience {
  type: 'experience'
  value: string
}

export interface RecommendedPlace {
  type: 'place_name'
  value: string
}

export interface RecommendedGooglePlace {
  type: 'google_place'
  value: string
}

export type RecommendedItems =
  | RecommendedOffer
  | RecommendedExperience
  | RecommendedPlace
  | RecommendedGooglePlace

export type UserSettings = GetUserSettingsResponse['result']

export type PlacePhoto = {
  name: string
  photoUri?: string
}

export type ExploreItemReview = {
  authorName: string
  authorUrl: string
  language: string
  authorPhotoUrl: string
  relativeTimeDescription: string
  text: string
  rating: number
  publishTime: string
}

export type ExploreItem = {
  id: string
  name: string
  primaryTypeDisplayName?: string
  types: Array<string>
  place: GeoPlace
  phone?: string
  website?: string
  rating?: number
  photo?: PlacePhoto
  photoNames?: Array<string>
  userRatingCount?: number
  openingHoursWeekdayDescriptions?: Array<string>
  editorialSummary?: string
  reviews?: Array<ExploreItemReview>
}

export type ItemWithOnlyLatLng = { id: string; place: { point: GeoPoint } }

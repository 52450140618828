import config from 'constants/config'
import getPaymentMethods from 'lib/payment/getPaymentMethods'
import {
  HOOLAH_BP_PAYMENT_TYPE,
  MIN_HOOLAH_BP_SGD_PAYMENT,
  MIN_HOOLAH_BP_MYR_PAYMENT,
  MIN_HOOLAH_BP_HKD_PAYMENT,
  MAX_HOOLAH_BP_SGD_PAYMENT,
  MAX_HOOLAH_BP_MYR_PAYMENT,
  MAX_HOOLAH_BP_HKD_PAYMENT,
} from 'constants/payment'

export const isHoolahBpEnabled = (currency: string): boolean =>
  !!(config.HOOLAH_BP_ENABLED && getPaymentMethods(currency).includes(HOOLAH_BP_PAYMENT_TYPE))

export const isWithinHoolahBpAvailabilityRange = (amount: number, currency:string): boolean => {
  switch (currency) {
    case 'SGD':
      return (amount >= MIN_HOOLAH_BP_SGD_PAYMENT && amount <= MAX_HOOLAH_BP_SGD_PAYMENT)
    case 'MYR':
      return (amount >= MIN_HOOLAH_BP_MYR_PAYMENT && amount <= MAX_HOOLAH_BP_MYR_PAYMENT)
    case 'HKD':
      return (amount >= MIN_HOOLAH_BP_HKD_PAYMENT && amount <= MAX_HOOLAH_BP_HKD_PAYMENT)
    default:
      return false
  }
}

export const getHoolahBpMaxLimit = (currency:string) :number => {
  switch (currency) {
    case 'SGD':
      return MAX_HOOLAH_BP_SGD_PAYMENT
    case 'MYR':
      return MAX_HOOLAH_BP_MYR_PAYMENT
    case 'HKD':
      return MAX_HOOLAH_BP_HKD_PAYMENT
    default:
      return 0
  }
}

import FormatCurrency from 'components/Common/FormatCurrency'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import LineBagAltIcon from 'components/Luxkit/Icons/line/LineBagAltIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import React from 'react'
import ExperienceAppDiscountTag from '../ExperienceAppDiscountTag'

interface Props {
  transferTicket: App.ExperienceTransferOption;
}

function ExperienceTransferBookingOption(props: Props) {
  const { transferTicket } = props

  return <VerticalSpacer gap={4}>
    <BodyText variant="large" weight="bold">{transferTicket.name}</BodyText>
    <BodyText variant="small">{transferTicket.description}</BodyText>
    <BodyText variant="small">
      <Group direction="horizontal" verticalAlign="center" gap={12}>
        <b>Max. capacity:</b>
        <Group direction="horizontal" verticalAlign="center" gap={4} as="span">
          <LineUsersAltIcon size="XS" /> {transferTicket.maxPassengers}
        </Group>
        <Group direction="horizontal" verticalAlign="center" gap={4} as="span">
          <LineBagAltIcon size="XS" /> {transferTicket.maxCheckedBags}
        </Group>
      </Group>
    </BodyText>
    <Group direction="horizontal" horizontalAlign="space-between">
      <Heading variant="heading5"><FormatCurrency value={transferTicket.price} /></Heading>
    </Group>
    <ExperienceAppDiscountTag discountPercentage={transferTicket.discounts?.app.percentage} />
  </VerticalSpacer>
}

export default ExperienceTransferBookingOption

function generateOccupancyStringByRoom(room: App.Occupants): string {
  const { adults, children = 0, infants = 0, childrenAge } = room

  if (childrenAge?.length) {
    return `${adults}-${childrenAge.join(',')}`
  } else if (children > 0 || infants > 0) {
    return `${adults}-${children ?? 0}-${infants ?? 0}`
  }

  return `${adults}`
}

export default generateOccupancyStringByRoom

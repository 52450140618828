import DualDateRangePicker from 'components/Common/Calendar/DualDateRangePicker'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import FormattedSearchDate from 'components/Search/SearchForm/SearchDateInput/FormattedSearchDate'
import { SearchMenuStates } from 'components/Search/type'
import Group from 'components/utils/Group'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { addDays, addMonths, beginningOfToday, isSameDay } from 'lib/datetime/dateUtils'
import noop from 'lib/function/noop'
import moment from 'moment'
import React, { ComponentProps, useCallback, useContext } from 'react'

function findMinDate() {
  if (new Date().getHours() === 23) {
    const tomorrow = addDays(new Date(), 1)
    tomorrow.setHours(0, 0, 0, 0)
    return tomorrow
  }
  const today = beginningOfToday()
  return today
}

const minDate = findMinDate()
const maxDate = addMonths(new Date(), 18)

interface Props extends Pick<ComponentProps<typeof DropdownSheet>, 'anchorRef' | 'triggerRef'> {
  onApply?: () => void;
}

function CarHireDatePickerDropdown(props: Props) {
  const { activeMenu, checkinDate: pickUpDate, checkoutDate: returnDate, dateSearchOptionId } = useContext(GlobalSearchStateContext)
  const { anchorRef, triggerRef, onApply = noop } = props

  const onDatesChange = (dates: {
    startDate: moment.Moment;
    endDate: moment.Moment | null;
  }) => {
    if (dates?.startDate && isSameDay(dates.startDate.toDate(), new Date())) {
      searchDispatch({ type: GlobalSearchStateActions.UNSET_PICKUP_TIME })
    }

    if (dates?.endDate && isSameDay(dates.endDate.toDate(), new Date())) {
      searchDispatch({ type: GlobalSearchStateActions.UNSET_RETURN_TIME })
    }

    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: dates.startDate })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: dates.endDate })
  }

  const clearDates = () => {
    searchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKIN_DATE })
    searchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE })
    toggleDropdown()
  }

  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const showDropdown = activeMenu === SearchMenuStates.CarHireDatePicker

  const toggleDropdown = useCallback(() => {
    const nextMenu = showDropdown ? SearchMenuStates.Closed : SearchMenuStates.CarHireDatePicker
    searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: nextMenu })
    onApply()
  }, [onApply, searchDispatch, showDropdown])

  const hasDates = !!(pickUpDate && returnDate)
  const disableSearchButton = dateSearchOptionId === DATE_SEARCH_OPTION_IDS.SPECIFIC && !hasDates

  return <DropdownSheet
    data-testid="car-hire-date-picker-dropdown"
    size="fill-anchor"
    open={showDropdown}
    anchorRef={anchorRef}
    triggerRef={triggerRef}
    primaryActionProps={{
      'data-testid': 'search-apply-button',
      children: 'Apply',
      disabled: disableSearchButton,
      onClick: toggleDropdown,
    }}
    secondaryActionProps={{
      'data-testid': 'search-nodates-button',
      kind: 'tertiary',
      children: 'Cancel',
      onClick: clearDates,
    }}
    footerStart={<Group direction="horizontal" horizontalAlign="end">
      <FormattedSearchDate includeLastDayOnCount timeUnit="day" />
    </Group>}
    onClose={toggleDropdown}
  >
    <DualDateRangePicker
      onDatesChange={onDatesChange}
      startDate={pickUpDate || null}
      endDate={returnDate || null}
      minDate={minDate}
      maxDate={maxDate}
      startLabel="Pick-up"
      endLabel="Drop-off"
    />
  </DropdownSheet>
}

export default CarHireDatePickerDropdown

import { serialiseOccupancy } from 'lib/search/searchUtils'

export function getBedbankRateKey(
  occupants: Array<App.Occupants>,
  checkIn: string,
  checkOut: string,
) {
  return `${checkIn}-${checkOut}-${serialiseOccupancy(occupants ?? []).join(',')}`
}

export function itemsAreBedbank(
  items: Array<App.Checkout.AccommodationItem>,
): items is Array<App.Checkout.BedbankHotelItem> {
  return items[0].itemType === 'bedbankHotel'
}

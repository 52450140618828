import { dayDurationToMs } from 'lib/datetime/time'
import { get, getWithTimestamp, remove, set } from 'lib/storage/versionedLocalStorageUtils'
import { toUtmParams } from 'lib/url/utmCodesUtils'
import config from 'constants/config'

const TTL_DURATION = dayDurationToMs(10)

// Redux state
// Increment version when we change the state structure in an incompatible way.
const REDUX_CART_STATE_KEY = 'checkoutCartReduxState'
const REDUX_CART_STATE_VERSION = 11

const REDUX_FORM_STATE_KEY = 'checkoutFormReduxState'
const REDUX_FORM_STATE_VERSION = 2

const REDUX_PAYMENT_STATE_KEY = 'checkoutPaymentReduxState'
const REDUX_PAYMENT_STATE_VERSION = 8

// Snapshot form state can persist and be used to restore form data across multiple checkout sessions
const REDUX_FORM_STATE_SNAPSHOT_KEY = 'checkoutFormStateSnapshot'
const REDUX_FORM_STATE_SNAPSHOT_VERSION = 1
/**
 * @remarks
 * Is this actually needed?
 * ENGX-816
 */
interface FormStateSnapshot {
  timestamp: number,
  formState: App.Checkout.FormState,
}

export function saveCheckoutReduxPaymentState(state: App.Checkout.PaymentState) {
  set(REDUX_PAYMENT_STATE_KEY, REDUX_PAYMENT_STATE_VERSION, state)
}

export function getSavedCheckoutReduxPaymentState(): App.Checkout.PaymentState {
  return get(REDUX_PAYMENT_STATE_KEY, REDUX_PAYMENT_STATE_VERSION)
}

export function saveCheckoutReduxCartState(state: App.CheckoutCartState) {
  set(REDUX_CART_STATE_KEY, REDUX_CART_STATE_VERSION, state)
}

export function getSavedCheckoutReduxCartState() {
  return getWithTimestamp<App.CheckoutCartState>(REDUX_CART_STATE_KEY, REDUX_CART_STATE_VERSION)
}

export function saveCheckoutReduxFormState(state: App.Checkout.FormState) {
  set(REDUX_FORM_STATE_KEY, REDUX_FORM_STATE_VERSION, state)
}

export function getSavedCheckoutReduxFormState(): App.Checkout.FormState {
  return get(REDUX_FORM_STATE_KEY, REDUX_FORM_STATE_VERSION)
}

export function clearFormStateCheckout() {
  remove(REDUX_FORM_STATE_KEY)
}

export function saveCheckoutFormStateSnapshot(state: App.Checkout.FormState) {
  const entry: FormStateSnapshot = { timestamp: Date.now(), formState: state }
  set(REDUX_FORM_STATE_SNAPSHOT_KEY, REDUX_FORM_STATE_SNAPSHOT_VERSION, entry)
}

export function clearFormStateSnapshot() {
  remove(REDUX_FORM_STATE_SNAPSHOT_KEY)
}

export function getSavedCheckoutFormStateSnapshot() {
  const snapshot = get(REDUX_FORM_STATE_SNAPSHOT_KEY, REDUX_FORM_STATE_SNAPSHOT_VERSION) as FormStateSnapshot

  if (snapshot) {
    const { timestamp, formState } = snapshot
    if (Date.now() < timestamp + TTL_DURATION) {
      return formState
    } else {
      clearFormStateSnapshot()
    }
  }
}

/**
 * svc-cart still expects UTM params
 *
 * @remarks
 * Customer portal no longer uses it, so we keep this implementation isolated to where still need it
 */
export function getUtmParams(utm: App.UTMState) {
  return {
    utm_source: config.BRAND,
    utm_medium: 'web',
    ...toUtmParams(utm),
  }
}

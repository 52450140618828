import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTimesIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m13.4099 11.9999 4.3-4.29a1.0041 1.0041 0 1 0-1.42-1.42l-4.29 4.3-4.29-4.3a1.0041 1.0041 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1.0003 1.0003 0 0 0 0 1.42.9994.9994 0 0 0 1.0946.2189.9978.9978 0 0 0 .3254-.2189l4.29-4.3 4.29 4.3a.9989.9989 0 0 0 1.0946.2189.9966.9966 0 0 0 .3254-.2189.999.999 0 0 0 .2189-1.0945.9981.9981 0 0 0-.2189-.3255l-4.3-4.29Z"/>
  </SvgIcon>
}

export default LineTimesIcon

import { useMemo } from 'react'
import extractTourV2VariationDetails, { formatTourV2VariationDetailCounts, TourV2VariationDetails } from 'lib/tours/extractTourV2VariationDetails'

type EmptyTourV2VariationDetails = { [key in keyof TourV2VariationDetails]: undefined }

function useTourV2VariationDetails(
  variation?: Tours.TourV2OfferVariation,
): TourV2VariationDetails | EmptyTourV2VariationDetails {
  return useMemo(() => (
    variation ?
      extractTourV2VariationDetails(variation) :
      {} as EmptyTourV2VariationDetails
  ), [variation])
}

export function useFormattedTourV2VariationDetailsCount(variation: Tours.TourV2OfferVariation) {
  return useMemo(() => formatTourV2VariationDetailCounts(variation), [variation])
}

export default useTourV2VariationDetails

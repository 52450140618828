import IconButton from 'components/Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import React, { MouseEventHandler, ReactNode, forwardRef } from 'react'
import styled from 'styled-components'
import _DropdownHeader from '../_DropdownHeader'
import LineArrowLeftIcon from 'components/Luxkit/Icons/line/LineArrowLeftIcon'

const Content = styled(Group)`
  grid-area: content;
`

const DismissButton = styled(IconButton)`
  grid-area: dismiss-button;
  transform: translateX(${rem(4)});
  margin-inline-start: ${rem(8)};
  justify-self: end;
`

const BackButton = styled(IconButton)`
  grid-area: back-button;
  transform: translateX(${rem(-4)});
  margin-inline-end: ${rem(8)};
  justify-self: start;
`

const Extension = styled.div`
  grid-area: extension;

  &:empty {
    display: none;
  }
`

const Container = styled(_DropdownHeader)`
  display: grid;
  padding: ${rem(24)} ${rem(24)} ${rem(8)};

  &.align-start {
    grid-template:
      "back-button content dismiss-button" auto
      "extension extension extension" min-content / auto 1fr auto;
  }
  &.align-center {
    grid-template:
      "back-button content dismiss-button" auto
      "extension extension extension" min-content / 1fr max-content 1fr;

    > ${Content} {
      justify-self: center;
    }
  }

  > ${Content} + ${Extension} {
    margin-top: ${rem(8)};
  }
`

interface Props {
  /**
   * Controls the size of the title
   */
  size: 'S' | 'M'
  alignment: 'start' | 'center'
  subtitle?: string
  title?: string
  actionsSlot?: ReactNode
  extensionSlot?: ReactNode
  onBackClick?: MouseEventHandler<HTMLButtonElement> | (() => void)
  onDismissClick?: MouseEventHandler<HTMLButtonElement> | (() => void)
  backButtonDataTestId?: string
  dismissButtonDataTestId?: string
}

const DropdownSheetHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    size,
    alignment,
    subtitle,
    title,
    actionsSlot,
    extensionSlot,
    onBackClick,
    onDismissClick,
    backButtonDataTestId,
    dismissButtonDataTestId,
  } = props

  const hasContent = !!(title || subtitle || actionsSlot)

  return <Container ref={ref} className={`align-${alignment}`}>
    {hasContent && <Content direction="horizontal" gap={16} horizontalAlign="space-between" wrap="wrap">
      <Group direction="vertical" horizontalAlign={alignment}>
        {!!title && <Heading variant={size === 'S' ? 'heading6' : 'heading3'}>{title}</Heading>}
        {!!subtitle && <BodyText variant="medium">{subtitle}</BodyText>}
      </Group>
      {!!actionsSlot && <Group direction="horizontal" gap={4} noDisplayWhenEmpty>
        {actionsSlot}
      </Group>}
    </Content>}
    {!!extensionSlot && <Extension>{extensionSlot}</Extension>}
    {onBackClick && <BackButton
      data-testid={backButtonDataTestId}
      kind="tertiary"
      size="small"
      shape="square"
      onClick={onBackClick}
    >
      <LineArrowLeftIcon />
    </BackButton>}
    {onDismissClick && <DismissButton
      kind="tertiary"
      size="small"
      shape="square"
      data-testid={dismissButtonDataTestId}
      onClick={onDismissClick}
    >
      <LineTimesIcon />
    </DismissButton>}
  </Container>
})

export default DropdownSheetHeader

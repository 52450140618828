import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineReturnFlightIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20.1585 1.8694C18.8417.5526 16.931.8543 15.8037 1.9816l-3.0059 3.0059-6.5714-2.494a1 1 0 0 0-1.062.2278L2.6909 5.195a1 1 0 0 0 .2732 1.608l5.4116 2.6066-2.1243 2.1244-2.0124-.8795a1.0001 1.0001 0 0 0-1.1075.2093L1.293 12.7022a1.0001 1.0001 0 0 0 0 1.4143l4.791 4.7909c.3905.3906 1.0237.3906 1.4142 0 .3905-.3905.3905-1.0236 0-1.4142l-4.0839-4.0839.649-.6489 2.0124.8794a1 1 0 0 0 1.1075-.2092l3.6032-3.6033a.9997.9997 0 0 0-.2731-1.608L5.1015 5.6127l1.0199-1.0199 6.5714 2.494a1 1 0 0 0 1.0619-.2278l3.4632-3.4632c.5113-.5113 1.1461-.4925 1.5264-.1122.3799.38.3994 1.0146-.1131 1.5273L15.211 8.2311a1.0001 1.0001 0 0 0-.2303 1.0553l.7449 2.0053c.1923.5177.7679.7815 1.2857.5892.5177-.1923.7815-.7679.5892-1.2856l-.5215-1.4038 2.9664-2.9663c1.1276-1.128 1.4302-3.0387.1131-4.3558Z"/>
    <path fill="currentColor" d="M13.8086 11.5147a1.0001 1.0001 0 0 0-1.6081-.2731l-3.6032 3.6032c-.3905.3905-.3905 1.0237 0 1.4142.3905.3905 1.0237.3905 1.4142 0l2.6134-2.6134c.2712.412.8121.57 1.2687.3501.4976-.2397.7067-.8373.467-1.3349l-.552-1.1461ZM22.7201 14.7816a.9996.9996 0 0 1 .21.33c.1.2435.1.5166 0 .76a.9986.9986 0 0 1-.21.33l-2.5 2.5a.9994.9994 0 0 1-1.0945.2189.9981.9981 0 0 1-.3255-.2189.9998.9998 0 0 1 0-1.42l.8-.79h-3.59a.9999.9999 0 0 1-.7071-1.7071 1.0003 1.0003 0 0 1 .7071-.2929h3.59l-.8-.79a1.0041 1.0041 0 1 1 1.42-1.42l2.5 2.5ZM16.0051 18.4916h-3.59l.8-.79a1.0041 1.0041 0 1 0-1.42-1.42l-2.5 2.5a1.0005 1.0005 0 0 0-.21.33 1 1 0 0 0 0 .76.9994.9994 0 0 0 .21.33l2.5 2.5a.9989.9989 0 0 0 1.0946.2189.9966.9966 0 0 0 .3254-.2189.999.999 0 0 0 .2189-1.0945.9981.9981 0 0 0-.2189-.3255l-.8-.79h3.59a1 1 0 1 0 0-2Z"/>
  </SvgIcon>
}

export default LineReturnFlightIcon

export const BUSINESS_TRAVELLER_ROOT_ROUTE = '/business-traveller'
// Business signup
export const BUSINESS_TRAVELLER_BUSINESS_SIGNUP_ROUTE = '/business-traveller/business-signup'
export const BUSINESS_TRAVELLER_BUSINESS_SIGNUP_COMPLETE_ROUTE = '/business-traveller/business-signup-complete'
// Employee setup
export const BUSINESS_TRAVELLER_EMPLOYEE_SETUP_ROUTE = '/business-traveller/employee-setup'
// Order Approval Page
export const BUSINESS_TRAVELLER_APPROVE_ORDER_ROUTE = '/business-traveller/approve-order/:approveRequestId'
export const BUSINESS_TRAVELLER_FAQ_ROUTE = '/business-traveller/frequently-asked-questions'

export const BUSINESS_TRAVELLER_SUPPORT_HEADER_ROUTES = [
  BUSINESS_TRAVELLER_BUSINESS_SIGNUP_ROUTE,
  BUSINESS_TRAVELLER_BUSINESS_SIGNUP_COMPLETE_ROUTE,
]

export const BUSINESS_TRAVELLER_NO_HEADER_ROUTES = [
  BUSINESS_TRAVELLER_EMPLOYEE_SETUP_ROUTE,
]

export const BUSINESS_TRAVELLER_NO_FOOTER_ROUTES = [
  BUSINESS_TRAVELLER_BUSINESS_SIGNUP_ROUTE,
  BUSINESS_TRAVELLER_BUSINESS_SIGNUP_COMPLETE_ROUTE,
  BUSINESS_TRAVELLER_EMPLOYEE_SETUP_ROUTE,
]

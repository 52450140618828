import styled from 'styled-components'
import { rem } from 'polished'

const Caret = styled.span`
  align-self: center;
  margin-left: auto;
  border-top: ${rem(4)} dashed;
  border-right: ${rem(4)} solid transparent;
  border-left: ${rem(4)} solid transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: ${rem(-3)};
    bottom: 1px;
    border-top: ${rem(3)} dashed;
    border-right: ${rem(3)} solid transparent;
    border-left: ${rem(3)} solid transparent;
    border-top-color: inherit;
  }
`

export default Caret

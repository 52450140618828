import React from 'react'
import DropdownChevron from '../Dropdown/DropdownChevron'
import TextLink from '../TextLink'

interface Props extends Omit<React.ComponentProps<typeof TextLink>, 'startIcon' | 'endIcon'>{
  on?: boolean;
  iconPosition?: 'start' | 'end'
}

const SmallToggleButton = React.forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const { on = false, iconPosition = 'end', ...rest } = props
  return (
    <TextLink
      {...rest}
      aria-expanded={on}
      startIcon={iconPosition === 'start' && <DropdownChevron open={on} />}
      endIcon={iconPosition === 'end' && <DropdownChevron open={on} />}
      ref={ref}
    />
  )
})

export default React.memo(SmallToggleButton)

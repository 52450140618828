import React, { useContext } from 'react'
import Group from 'components/utils/Group'
import GeoContext from 'contexts/geoContext'
import { TrustIndicatorUK } from 'home/components/UspBanner/TrustIndicatorUk'
import GoogleLongLogo from 'components/Common/TrustIndicators/GoogleLongLogo'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TrustPilotLogoCombined from './TrustPilotLogoCombined'
import { TrustIndicatorSingapore } from 'home/components/UspBanner/TrustIndicatorSingapore'

function AccountAccessTrustIndicatorsMapper() {
  const { currentRegionCode } = useContext(GeoContext)
  const isUK = currentRegionCode === 'GB'
  const isSG = currentRegionCode === 'SG'

  return (
    <Group direction="horizontal" verticalAlign="center" gap={16} horizontalAlign="center" wrap="wrap">
      {(!isUK && !isSG) && (
        <>
          <Group gap={8} direction="horizontal" verticalAlign="center">
            <GoogleLongLogo width="54" height="18"/>
            <BodyTextBlock colour="neutral-two" variant="medium" weight="bold">4.7/5</BodyTextBlock>
            <CSSBreakpoint min="desktop">
              <BodyTextBlock colour="neutral-three" variant="small">(2.6k reviews)</BodyTextBlock>
            </CSSBreakpoint>
          </Group>
          <Group gap={8} direction="horizontal" verticalAlign="center" >
            <TrustPilotLogoCombined />
            <BodyTextBlock colour="neutral-two" weight="bold" variant="medium" wrap="no-wrap">4.8/5</BodyTextBlock>
            <CSSBreakpoint min="desktop">
              <BodyTextBlock colour="neutral-three" variant="small" wrap="no-wrap">(8k reviews)</BodyTextBlock>
            </CSSBreakpoint>
          </Group>
        </>
      )}
      {isUK && <TrustIndicatorUK />}
      {isSG && <TrustIndicatorSingapore />}
    </Group>
  )
}

export default AccountAccessTrustIndicatorsMapper

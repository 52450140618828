import { FlightDropdownOption } from 'components/Flights/types'
import config from 'constants/config'

export const maxFlightChildAge = 11
export const maxFlightInfantAge = 1
export const maxFlightPassengerCount = 9

export const VIRGIN_AUSTRALIA = 'Virgin Australia'

export const titles = {
  adult: {
    mr: 'Mr.',
    ms: 'Ms.',
    mrs: 'Mrs.',
    miss: 'Miss.',
  },
  child: {
    mstr: 'Master',
    miss: 'Miss.',
  },
  infant: {
    mstr: 'Master',
    miss: 'Miss.',
  },
}

export const frequentFlyerPrograms = {
  qf: 'Qantas',
  ek: 'Emirates',
  jl: 'Vietnam Airlines (Lotus Miles)',
  vn: 'Japan Airlines (JAL Miles)',
}

export const getPrimaryPassengerState = (): Partial<App.Passenger> => ({
  type: 'adult',
  isPrimary: true,
  addedExtras: {
    baggage: {},
    carryOnBaggage: {},
  },
})

export const getAdultPassengerState = (): Partial<App.Passenger> => ({
  type: 'adult',
  isPrimary: false,
  addedExtras: {
    baggage: {},
    carryOnBaggage: {},
  },
})

export const getChildPassengerState = (): Partial<App.Passenger> => ({
  type: 'child',
  isPrimary: false,
  addedExtras: {
    baggage: {},
    carryOnBaggage: {},
  },
})

export const getInfantPassengerState = (): Partial<App.Passenger> => ({
  type: 'infant',
  isPrimary: false,
  addedExtras: {
    baggage: {},
    carryOnBaggage: {},
  },
})

export enum FlightSortOptions {
  Best='best',
  Cheapest='cheapest',
  Fastest='fastest',
  EarliestTakeOff='earliestTakeOff',
  LatestTakeOff='atestTakeOff',
  EarliestLanding='earliestLanding',
}

export const sortOptions = [{
  value: FlightSortOptions.Cheapest,
  label: 'Cheapest',
  tooltip: 'Sort flights by cheapest price.',
}, {
  value: FlightSortOptions.Best,
  label: 'Best',
  tooltip: 'Sort flights based on number of stopovers, price and flight duration.',
}, {
  value: FlightSortOptions.Fastest,
  label: 'Fastest',
  tooltip: 'Sort flights by fastest flight time.',
}]

export const fareClasses = ['economy', 'premium', 'business', 'first'] as const
export const fareTypesConsts = ['return', 'oneWay', 'multiCity'] as const
export const defaultFareClass = 'economy'

export type FareClass = typeof fareClasses[number]
export type FareType = typeof fareTypesConsts[number]

export enum FlightTileType {
  BUNDLING = 'BUNDLING',
  STANDALONE = 'STANDALONE',
  INFO = 'INFO',
  SUMMARY_ITEM = 'SUMMARY_ITEM',
  CHECKOUT = 'CHECKOUT',
}

export enum FlightsFareTypes {
  RETURN = 'return',
  ONE_WAY = 'oneWay',
  MULTI_CITY = 'multiCity',
}

export enum FlightsClassTypes {
  ECONOMY = 'economy',
  PREMIUM = 'premium',
  BUSINESS = 'business',
  FIRST = 'first'
}

export const fareTypeLabelMap = {
  [FlightsFareTypes.RETURN]: 'Return',
  [FlightsFareTypes.ONE_WAY]: 'One-way',
  [FlightsFareTypes.MULTI_CITY]: 'Multi-city',
}

export const fareCabinLabelMap = {
  [FlightsClassTypes.ECONOMY]: 'Economy',
  [FlightsClassTypes.PREMIUM]: 'Premium',
  [FlightsClassTypes.BUSINESS]: 'Business',
  [FlightsClassTypes.FIRST]: 'First',
}

export const labelFareCabinMap = {
  Economy: FlightsClassTypes.ECONOMY,
  Premium: FlightsClassTypes.PREMIUM,
  Business: FlightsClassTypes.BUSINESS,
  First: FlightsClassTypes.FIRST,
}

export const fareTypes: Array<FlightDropdownOption> = [{
  value: FlightsFareTypes.RETURN,
  label: fareTypeLabelMap[FlightsFareTypes.RETURN],
  disabled: false,
}, {
  value: FlightsFareTypes.ONE_WAY,
  label: fareTypeLabelMap[FlightsFareTypes.ONE_WAY],
  disabled: !config.ENABLE_ONE_WAY_FLIGHTS,
  comingSoon: !config.ENABLE_ONE_WAY_FLIGHTS,
}, {
  value: FlightsFareTypes.MULTI_CITY,
  label: fareTypeLabelMap[FlightsFareTypes.MULTI_CITY],
  disabled: false,
  comingSoon: false,
  isNew: true,
}]

export const fareCabins: Array<FlightDropdownOption<FlightsClassTypes>> = [
  {
    value: FlightsClassTypes.ECONOMY,
    label: 'Economy',
    disabled: false,
  }, {
    value: FlightsClassTypes.PREMIUM,
    label: 'Premium Economy',
    disabled: false,
  }, {
    value: FlightsClassTypes.BUSINESS,
    label: 'Business',
    disabled: false,
  }, {
    value: FlightsClassTypes.FIRST,
    label: 'First',
    disabled: false,
  }]

export const childrenSelectWarning = "Children under the age of 2 will be assigned an infant's flight ticket and must sit on an accompanying adult's lap."
export const maxInfantsWarning = 'Cannot have more infants than adults - please call us for assistance with your booking'

export const flightsImageId = 'Flights_wl4url'

export const flightConfirmationDetails = {
  name: 'Flight reservation',
  image: { id: flightsImageId },
  type: 'flights',
}

export const validFlightDateRangeInMonths = 11

export const defaultAirlineLogo = 'kw12fpjilpkaamli2ufq'

// IATA Carrier codes are guranteed to be the first 2 alphanumerics
export const FLIGHT_CODE_REGEX = /^[a-zA-Z0-9]{2}\s?\d{1,4}$/

export const DEFAULT_ADULT_OCCUPANTS = 1

export enum FlightViewTypes {
  TWO_ONE_WAYS = 'TwoOneWays',
  RETURN = 'Return',
  ONE_WAY = 'OneWay',
  TWO_ONE_WAYS_AND_RETURN = 'TwoOneWaysAndReturn',
  MULTI_CITY = 'MultiCity',
}

/**
 * This is the name of the fields that are users interact with on the search page
 * We use this list of filters that change the shown flights in place (without needing to search again)
 * 'search' filters (such as date/airports etc) are not included here as they modify the entire search
 */
export const FlightsSearchInPlaceFilters = [
  'airlines',
  'stops',
  'stopDurations',
  'cabinTypes',
  'durations',
  'minDepartureTime',
  'maxDepartureTime',
  'minArrivalTime',
  'maxArrivalTime',
  'recommended',
  'bags',
  'stopOverCities',
  'alliances',
]

export enum FlightFilterType {
  AIRLINES = 'airlines',
  CABIN_TYPES = 'cabinTypes',
  STOP_DURATIONS = 'stopDurations',
  STOPS = 'stops',
  DURATIONS = 'durations',
  PRICE_FILTER_TYPE = 'priceFilterType',
  MIN_PRICES = 'minPrices',
  MAX_PRICES = 'maxPrices',
  MIN_DEPARTURE_TIME = 'minDepartureTime',
  MAX_DEPARTURE_TIME = 'maxDepartureTime',
  MIN_ARRIVAL_TIME = 'minArrivalTime',
  MAX_ARRIVAL_TIME = 'maxArrivalTime',
  PRICES = 'prices',
  TIMES = 'times',
  DEPARTURE_TIMES = 'departureTimes',
  ARRIVAL_TIMES = 'arrivalTimes',
  RECOMMENDED = 'recommended',
  BAGS = 'bags',
  STOP_OVER_CITIES = 'stopOverCities',
  ALLIANCES = 'alliances',
  APPLIED_FILTERS = 'applied_filters'
}

export enum FlightSegment {
  DEPARTING = 'departing',
  RETURNING = 'returning',
}

export const flightFiltersLabelMap: Record<string, string> = {
  [FlightFilterType.STOPS]: 'Stops',
  [FlightFilterType.AIRLINES]: 'Airlines',
  [FlightFilterType.STOP_DURATIONS]: 'Stopover time',
  [FlightFilterType.CABIN_TYPES]: 'Cabin',
  [FlightFilterType.DURATIONS]: 'Duration',
  [FlightFilterType.PRICES]: 'Prices',
  [FlightFilterType.TIMES]: 'Times',
  [FlightFilterType.BAGS]: 'Bags',
  [FlightFilterType.STOP_OVER_CITIES]: 'Stopover cities',
  [FlightFilterType.ALLIANCES]: 'Alliances',
  [FlightFilterType.APPLIED_FILTERS]: 'Applied filters',
}

export const flightFiltersModalLabelMap: Record<string, string> = {
  [FlightFilterType.STOPS]: 'Stops',
  [FlightFilterType.AIRLINES]: 'Airlines',
  [FlightFilterType.STOP_DURATIONS]: 'Stopover time',
  [FlightFilterType.CABIN_TYPES]: 'Cabin',
  [FlightFilterType.DURATIONS]: 'Flight duration',
  [FlightFilterType.PRICES]: 'Prices',
  [FlightFilterType.TIMES]: 'Times',
  [FlightFilterType.RECOMMENDED]: 'Recommended filters',
  [FlightFilterType.BAGS]: 'Bags',
  [FlightFilterType.STOP_OVER_CITIES]: 'Stopover cities',
  [FlightFilterType.ALLIANCES]: 'Alliances',
  [FlightFilterType.APPLIED_FILTERS]: 'Applied filters',
}

export const flightFilters: Array<{
  label: string;
  value: FlightFilterType;
}> = [
  {
    label: flightFiltersLabelMap[FlightFilterType.STOPS],
    value: FlightFilterType.STOPS,
  },
  {
    label: flightFiltersLabelMap[FlightFilterType.AIRLINES],
    value: FlightFilterType.AIRLINES,
  },
  {
    label: flightFiltersLabelMap[FlightFilterType.STOP_DURATIONS],
    value: FlightFilterType.STOP_DURATIONS,
  },
  {
    label: flightFiltersLabelMap[FlightFilterType.CABIN_TYPES],
    value: FlightFilterType.CABIN_TYPES,
  },
  {
    label: flightFiltersLabelMap[FlightFilterType.DURATIONS],
    value: FlightFilterType.DURATIONS,
  },
  {
    label: flightFiltersLabelMap[FlightFilterType.BAGS],
    value: FlightFilterType.BAGS,
  },
  {
    label: flightFiltersLabelMap[FlightFilterType.STOP_OVER_CITIES],
    value: FlightFilterType.STOP_OVER_CITIES,
  },
  {
    label: flightFiltersLabelMap[FlightFilterType.ALLIANCES],
    value: FlightFilterType.ALLIANCES,
  },
]

export const STANDALONE_FLIGHT_FILTERS = [
  FlightFilterType.AIRLINES,
  FlightFilterType.CABIN_TYPES,
  FlightFilterType.DURATIONS,
  FlightFilterType.PRICES,
  FlightFilterType.STOPS,
  FlightFilterType.STOP_DURATIONS,
  FlightFilterType.TIMES,
  FlightFilterType.RECOMMENDED,
  FlightFilterType.BAGS,
  FlightFilterType.STOP_OVER_CITIES,
  FlightFilterType.ALLIANCES,
]

export const CHECKOUT_FLIGHT_FILTERS = [
  FlightFilterType.AIRLINES,
  FlightFilterType.CABIN_TYPES,
  FlightFilterType.DURATIONS,
  FlightFilterType.STOPS,
  FlightFilterType.STOP_DURATIONS,
  FlightFilterType.TIMES,
  FlightFilterType.RECOMMENDED,
  FlightFilterType.BAGS,
  FlightFilterType.STOP_OVER_CITIES,
  FlightFilterType.ALLIANCES,
]

export const FLEXIBILITY_INCLUSIONS = new Set<App.FareFamilyFeatureType>(['cancellation', 'changes', 'refunds'])

export const TRAVEL_FUSION = 'travelFusion'

export const MAX_MOBILE_CAROUSEL_ITEM_COUNT = 8

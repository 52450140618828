import LineClockIcon from 'components/Luxkit/Icons/line/LineClockIcon'
import { useAppSelector } from 'hooks/reduxHooks'
import { fillArray } from 'lib/array/arrayUtils'
import moment from 'moment'
import React, { useCallback, useId, useMemo, useRef } from 'react'
import Select from './Select'
import TextInput from './TextInput'
import { isCurrentDeviceMobile } from 'selectors/configSelectors'

const minutesInDay = 24 * 60
function generateTimes(step = 30) {
  const count = minutesInDay / step
  const stepsInHour = Math.round(60 / step)
  return fillArray(count).map((index) => {
    const hourPart = Math.floor(index / stepsInHour).toString().padStart(2, '0')
    const minutePart = ((index % stepsInHour) * step).toString().padStart(2, '0')
    const value = `${hourPart}:${minutePart}`
    return {
      value,
      label: moment(value, 'HH:mm').format('h:mm A'),
    }
  })
}

interface Props extends Omit<React.ComponentProps<typeof TextInput>, 'type'> {
  step?: 5 | 10 | 15 | 30;
}

function TimeInput(props: Props) {
  const { step, ...inputProps } = props

  // mobile devices use the native pickers, they're alright and well implemented for their intended use
  // desktop devices however, are not so good. Very inconsistent in their behaviour, especially safaris time picker
  // for now, we go with native pickers except for safari
  const useNativeInput = useAppSelector(state => isCurrentDeviceMobile(state) || !state.config.device.browserName.toLowerCase().includes('safari'))
  const timeInputRef = useRef<HTMLInputElement>(null)
  const dataListId = useId()
  const timeList = useMemo(() => generateTimes(step), [step])
  const showPicker = useCallback(() => timeInputRef.current?.showPicker(), [])

  if (useNativeInput) {
    return <>
      <TextInput
        startIcon={<LineClockIcon onClick={showPicker} />}
        {...inputProps}
        type="time"
        list={dataListId}
        ref={timeInputRef}
      />
      <datalist id={dataListId}>
        {timeList.map(time => <option key={time.value} value={time.value} />)}
      </datalist>
    </>
  }

  // @ts-ignore Select is not a text input, but for 99% of purposes it's the same here
  return <Select
    startIcon={<LineClockIcon />}
    {...inputProps}
  >
    {timeList.map(time => <option key={time.value} value={time.value}>{time.label}</option>)}
  </Select>
}

export default TimeInput

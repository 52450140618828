/**
 * Taken from stackoverflow
 * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
 *
 * Result is in radians
 */
function getDistanceFromLatLong(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)

  return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}

const AVERAGE_RADIUS_OF_EARTH_METRES = 6371000

export function getMetresDistanceFromLatLong(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  return getDistanceFromLatLong(lat1, lon1, lat2, lon2) * AVERAGE_RADIUS_OF_EARTH_METRES
}

export function getKilometersDistanceFromLatLong(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  return getMetresDistanceFromLatLong(lat1, lon1, lat2, lon2) / 1000
}

export default getDistanceFromLatLong

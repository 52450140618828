import { useCallback, useMemo } from 'react'
import useQueryParams from 'hooks/useQueryParams'
import { SEARCH_VERTICALS } from 'constants/search'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { useCruiseSearchEnabled, useGlobalCruisesSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalCruisesSearch'
import CruisesTabContent from '../Components/Mobile/TabContents/CruisesTabContent'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import { useAppDispatch } from 'hooks/reduxHooks'
import { getCruiseGlobalFilters, getCruiseSearchStateFromParams } from 'lib/cruises/cruiseUtils'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'
import qs from 'qs'
import { pushWithRegion } from 'actions/NavigationActions'

function useGlobalSearchVerticalTabCruises(searchVerticalItem?: App.SearchVerticalItem): App.GlobalSearchVerticalTabV2 {
  const isEnabled = useCruiseSearchEnabled()
  const dispatch = useAppDispatch()

  const queryParams = useQueryParams()
  const initialState = useMemo(() => getCruiseSearchStateFromParams(queryParams), [queryParams])
  const {
    globalCruisesSearchDispatch: searchDispatch,
    globalCruisesSearchState: state,
  } = useGlobalCruisesSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    ...initialState,
    eventAnalytics: {
      contextLocation: 'common-search',
    },
  })

  const onSearch = useCallback(() => {
    const {
      searchItems: globalSearchItems,
      secondarySearchItems: globalSecondarySearchItems,
      flexibleMonths: globalFlexibleMonths,
      durationMin: globalDurationMin,
      durationMax: globalDurationMax,
      durationRange: globalDurationRange,
      eventAnalytics: globalEventAnalytics,
      checkinDate: globalCheckinDate,
      checkoutDate: globalCheckoutDate,
      cruiseLines: globalCruiseLines,
    } = state

    if (globalEventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        globalEventAnalytics.contextLocation,
        globalEventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItems: globalSearchItems,
          secondarySearchItems: globalSecondarySearchItems,
          searchVerticals: new Set([SEARCH_VERTICALS.CRUISES]),
          flexibleMonths: globalFlexibleMonths,
          durationMin: globalDurationMin,
          durationMax: globalDurationMax,
          durationRange: globalDurationRange,
          checkinDate: globalCheckinDate,
          checkoutDate: globalCheckoutDate,
          cruiseLines: globalCruiseLines,
        }),
      ))
    }
    const cruiseGlobalFilters = getCruiseGlobalFilters(state)
    const query = qs.stringify(cruiseGlobalFilters, { arrayFormat: 'repeat' })
    dispatch(pushWithRegion('/search/cruises', query))
  }, [dispatch, state])

  return useMemo(() => ({
    isEnabled: isEnabled && !!searchVerticalItem,
    label: searchVerticalItem?.label ?? 'cruises',
    key: SEARCH_VERTICALS.CRUISES,
    context: { dispatch: searchDispatch, state },
    onSearch,
    ContentComponent: CruisesTabContent,
    Icon: LineShipIcon,
  }), [isEnabled, searchVerticalItem, searchDispatch, state, onSearch])
}

export default useGlobalSearchVerticalTabCruises

import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { SVG_ICON_SIZE_CSS_VAR } from '../SvgIcon'

const ThumbnailContainer = styled.div`
  display: grid;
  place-items: center;
  height: ${rem(48)};
  width: ${rem(48)};
  grid-template: 1fr / 1fr;
  border-radius: ${props => props.theme.borderRadius.S};
  overflow: hidden;

  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  &.gutter-none {
    padding: 0;
  }
  &.gutter-S {
    padding: ${rem(4)};
  }

  &.kind-default {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    color: ${props => props.theme.palette.neutral.default.one};
  }
  &.kind-neutral {
    background-color: ${props => props.theme.palette.neutral.default.seven};
    color: ${props => props.theme.palette.neutral.default.one};
  }
  &.kind-highlight-primary {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
    color: ${props => props.theme.palette.neutral.default.one};
  }
  &.kind-highlight-secondary {
    background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
    color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  }
  &.kind-highlight-tertiary {
    background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
    color: ${props => props.theme.palette.highlight.tertiary.normalForeground};
  }

  ${SVG_ICON_SIZE_CSS_VAR}: ${rem(24)};
`

type Props = PropsWithChildren<{
  className?: string
  /**
   * @default S
   */
  gutter?: 'none' | 'S'
  /**
   * Sets the colour of the thumbnail.
   * Including the background and icon colours.
   *
   * @default default
   */
  kind?: 'default' | 'neutral' | 'highlight-primary' | 'highlight-secondary' | 'highlight-tertiary'
}>

function ListItemThumbnail(props: Props) {
  const {
    children,
    className,
    kind = 'default',
    gutter = 'S',
  } = props

  return <ThumbnailContainer
    className={cn(className, `kind-${kind}`, `gutter-${gutter}`)}
  >
    {children}
  </ThumbnailContainer>
}

export default ListItemThumbnail

import HttpStatus from 'components/Common/HttpStatus'
import Error404Page from 'components/Pages/Error404Page'
import { buildPath } from 'lib/url/routeUtils'
import React from 'react'
import { useRouteMatch, Route, Switch } from 'react-router'
import loadable from '@loadable/component'
import { LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP } from './AgentHubRoutes'
import AuthRoute from 'components/App/Routes/AuthRoute'

const AgentHubLandingPage = loadable(() => import(/* webpackChunkName: "AgentHubLandingPage" */ 'agentHub/pages/landing/AgentHubLandingPage'))
const AgentHubCompleteAccountSetup = loadable(() => import(/* webpackChunkName: "AgentHubCompleteAccountSetup" */ 'agentHub/pages/AgentHubAccountSetup/AgentHubCompleteAccountSetupPage'))
const ResetPasswordPage = loadable(() => import(/* webpackChunkName: "ResetPasswordPage" */ 'components/Pages/ResetPasswordPage/ResetPasswordPage'))

function AgentHubGuestPageRoutes() {
  const match = useRouteMatch()

  return <Switch>
    <Route path={buildPath(match, '/')}>
      <Switch>
        <Route exact path={buildPath(match, '/')} component={AgentHubLandingPage} />
        <Route exact path={buildPath(match, LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP)} component={AgentHubCompleteAccountSetup} />
        <Route exact path={buildPath(match, '/reset-password/:resetPasswordToken')} component={ResetPasswordPage} />

        {/**
         * All other page paths are behind a login wall
         * So if a user tries to access anything, make them login instead
         */}
        <AuthRoute showPopup />
      </Switch>
    </Route>
    {/*
      Code below will match all non-declared routes
      It will also stop all routes loaded after it from rendering
      if your route isn't rendering on agent hub, make sure it
      is loaded before this component
    */}
    <Route>
      <HttpStatus code={404}>
        <Error404Page />
      </HttpStatus>
    </Route>
  </Switch>
}

export default AgentHubGuestPageRoutes

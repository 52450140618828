import { rem } from 'polished'
import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import Input from '../../Common/Form/Input/Input'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const RadioElement = styled(Input)`
  appearance: none;
  border: 2px solid var(--radio-color);
  border-radius: ${props => props.theme.borderRadius.round};
  transition: background-color 0.2s, border-color 0.2s;
  position: relative;
  flex-shrink: 0;

  &.size-small {
    height: ${rem(16)};
    width: ${rem(16)};
  }
  &.size-medium {
    height: ${rem(20)};
    width: ${rem(20)};
  }
  &.size-large {
    height: ${rem(24)};
    width: ${rem(24)};
  }

  &::after {
    /* The inner checked circle */
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    opacity: 0;
    background-color: var(--radio-color);
    transition: opacity 0.2s, background-color 0.2s;
    border-radius: ${props => props.theme.borderRadius.round};
  }

  &:not(:checked) {
    --radio-color: ${props => props.theme.palette.neutral.default.four};

    &:disabled {
      --radio-color: ${props => props.theme.palette.neutral.default.five};
      background-color: ${props => props.theme.palette.neutral.default.six};
    }
  }

  &:checked {
    &::after {
      opacity: 1;
    }

    --radio-color: ${props => props.theme.palette.brand.primary.normal};

    &:disabled {
      --radio-color: ${props => props.theme.palette.neutral.default.four};
    }
  }

  &:not(:disabled) {
    &:focus {
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid var(--palette-neutral-default-four));
    }

    &.dirty:invalid {
      --radio-color: ${props => props.theme.palette.messaging.critical.normalForeground};
    }

    ${mediaHoverable} {
      &:hover {
        --radio-color: ${props => props.theme.palette.brand.primary.hover};
      }
    }
  }
`

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> {
  /**
   * @default medium
   */
  size?: 'small' | 'medium' | 'large'
}

/**
 * The circular shaped radio input.
 *
 * To pair it with a label, see `<RadioInput>`
 */
const RadioButton = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, className, size = 'medium', ...inputProps } = props

  const [isDirty, setDirty] = useState<boolean>(false)

  return <RadioElement
    {...inputProps}
    ref={ref}
    className={cn(className, `size-${size}`, { dirty: isDirty })}
    onDirtyChange={setDirty}
    type="radio"
  />
})

export default RadioButton

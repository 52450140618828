import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import SearchForm from 'components/Search/SearchForm'
import Pane from 'components/Common/Pane/index'
import { mediaQueryDown, mediaQueryUp } from 'components/utils/breakpoint'
import LayoutContainer from 'components/Common/LayoutContainer'
import Backdrop from 'components/utils/Backdrop'
import SearchTrendingDestinations from 'components/Search/SearchForm/SearchTrendingDestinations'
import FlightsSearchWidget from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import ErrorBoundary from 'components/Common/ErrorBoundary'
import RecentSearches from 'components/Pages/FrontPage/RecentSearches'
import { verticalChildSpacing } from 'components/utils/spacing'
import ExperienceSearchForm from 'components/Experiences/ExperiencesSearch/ExperienceSearchForm'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import TourV2SearchControls from 'components/Search/TourV2/TourV2SearchControls'
import { GlobalSearchTabContent } from 'components/Search/GlobalSearch/GlobalSearchTabContents'
import TourV2TrendingDestinationsQuickFilters from 'components/Search/TourV2/TourV2TrendingDestinationsQuickFilters'
import { useGlobalHotelsSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHotelsSearch'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { useGlobalToursSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalToursSearch'
import { useGlobalExperiencesSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalExperiencesSearch'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { HOMES_AND_VILLAS_LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL, HOMES_AND_VILLAS_TYPEAHEAD_TYPES, HOTEL_SEARCH_TYPEAHEAD_TYPES, SEARCH_VERTICALS } from 'constants/search'
import IconButton from 'components/Luxkit/Button/IconButton'
import config from 'constants/config'
import ExperiencePageTrendingDestinations from 'components/Experiences/ExperiencesPage/ExperiencesPageTrendingDestinations'
import TabButtonsGroup from 'components/Luxkit/Tabs/TabButtonsGroup'
import TabButton from 'components/Luxkit/Tabs/TabButton'
import { centreElementInView } from 'lib/window/scrollUtils'
import useQueryParams from 'hooks/useQueryParams'
import zIndex from 'styles/tools/z-index'
import GlobalCruisesSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalCruisesSearchTabContent'
import { connect } from 'react-redux'
import { useGlobalCarHireSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalCarHireSearch'
import CarHireSearchBar from 'components/CarHire/CarHireSearchBar/CarHireSearchBar'
import { useIsMobileScreen } from 'hooks/useScreenSize'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'
import { useGlobalHomesAndVillasSearch } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHomesAndVillasSearch'
import { HOMES_AND_VILLAS_FILTERS } from 'constants/offerList'
import { getFlightsRecentSearch } from 'lib/flights/flightUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { isMultiCityEnabled } from 'selectors/flightsSelectors'

const SearchFormContainer = styled(Pane)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${rem(24)};
  ${verticalChildSpacing[24]}
  z-index: ${zIndex.header};
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;

  &.open {
    ${mediaQueryUp.tablet} {
      display: block;
      transform: translateY(0%);
    }
  }
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${rem(16)};
  right: 0;
  z-index: 1;
`

const SearchTabs = styled.div`
  &.is-invisible {
    visibility: hidden;
  }
`

const TabContent = styled.div`
  margin-top: ${rem(24)};

  > .hidden {
    display: none;
  }
`

const StyledSearchTrendingDestinations = styled(SearchTrendingDestinations)`
  margin-top: ${rem(16)};
`

const StyledLayoutContainer = styled(LayoutContainer)`
  position: relative;
  padding-top: ${rem(72)};

  ${mediaQueryUp.desktop} {
    padding-top: ${rem(4)};
  }

  > ${TabContent} + .quick-filters-container {
    margin-top: ${rem(16)};

    &:empty {
      display: none;
    }
  }
`

const StyledBackdrop = styled(Backdrop)`
  z-index: ${zIndex.header - 1};
  ${mediaQueryDown.mobile} {
    display: none;
  }
`

export interface Props {
  closeSearch: () => void;
  activeSearchVertical: SEARCH_VERTICALS | undefined
  memberId?: string;
  tabs: Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTabV2>;
  onSelectTab: (key: SEARCH_VERTICALS) => void
}

interface MappedStateProps {
  filters: App.OfferListFilters
}

function InHeaderSearch(props: Props & MappedStateProps) {
  const { closeSearch, activeSearchVertical, memberId, tabs, onSelectTab, filters } = props
  const isMobileScreen = useIsMobileScreen()

  const tabsArray = useMemo(() => [...tabs.entries()], [tabs])

  const queryParams = useQueryParams()
  const multiCityEnabled = useAppSelector(isMultiCityEnabled)

  const {
    globalHotelsSearchDispatch,
    globalHotelsSearchState,
  } = useGlobalHotelsSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    eventAnalytics: {
      contextLocation: 'common-search',
    },
  }, queryParams)

  const {
    globalHomesAndVillasSearchDispatch,
    globalHomesAndVillasSearchState,
  } = useGlobalHomesAndVillasSearch(GLOBAL_SEARCH_INITIAL_STATE, queryParams)
  const { globalToursSearchDispatch, globalToursSearchState } = useGlobalToursSearchContext(
    {
      ...GLOBAL_SEARCH_INITIAL_STATE,
      eventAnalytics: {
        contextLocation: 'common-search',
      },
    }, queryParams)

  const { globalExperiencesSearchDispatch, globalExperiencesSearchState } = useGlobalExperiencesSearchContext(
    {
      ...GLOBAL_SEARCH_INITIAL_STATE,
      eventAnalytics: {
        contextLocation: 'common-search',
      },
    },
    queryParams,
  )

  const { globalCarHireSearchDispatch, globalCarHireSearchState } = useGlobalCarHireSearchContext(
    {
      ...GLOBAL_SEARCH_INITIAL_STATE,
      eventAnalytics: {
        contextLocation: 'common-search',
      },
    },
    queryParams,
  )

  const shouldUseMemberId = !config.ENABLE_LOGGED_OUT_RECENT_SEARCH

  return (
    <>
      {/*
        GlobalSearchV1
        The GlobalSearchV1 will always show up on screen sizes larger than "mobile".
        For screen size "mobile" the GlobalSearchV1 will only appear if "config.MOBILE_GLOBAL_SEARCH_V2_ENABLED" is false.
        Ideally there would be conditional logic to check if !MOBILE_GLOBAL_SEARCH_V2_ENABLED || !isMobile,
        however isMobile is not defined on the server therefore would cause an SSR issue
      */}
      <SearchFormContainer className={cn({ open: !!activeSearchVertical })}>
        <StyledLayoutContainer>
          <CloseButton
            kind="tertiary"
            variant="dark"
            onClick={closeSearch}
          >
            <LineTimesIcon />
          </CloseButton>
          <SearchTabs className={cn({ 'is-invisible': tabs.size <= 1 })}>
            <TabButtonsGroup>
              {tabsArray.map(([searchVertical, { label, Icon }]) => <TabButton
                key={searchVertical}
                isActive={searchVertical === activeSearchVertical}
                size="medium"
                format="titlecase"
                aria-label={label}
                onClick={(e) => {
                  centreElementInView(e.currentTarget)
                  onSelectTab(searchVertical)
                }}
                startIcon={<Icon />}
              >
                {label}
              </TabButton>)}
            </TabButtonsGroup>
          </SearchTabs>
          {!!activeSearchVertical && <TabContent>
            <GlobalSearchContextProvider state={globalHotelsSearchState} dispatch={globalHotelsSearchDispatch}>
              <SearchForm
                className={cn({ hidden: activeSearchVertical !== SEARCH_VERTICALS.HOTELS })}
                submitOnApply
                filters={filters}
                openLocationInputOnLoad={!isMobileScreen}
                typeaheadTypes={HOTEL_SEARCH_TYPEAHEAD_TYPES}
              />
            </GlobalSearchContextProvider>
            {tabs.has(SEARCH_VERTICALS.HOMES_AND_VILLAS) && <GlobalSearchContextProvider
              state={globalHomesAndVillasSearchState}
              dispatch={globalHomesAndVillasSearchDispatch}
            >
              <SearchForm
                className={cn({ hidden: activeSearchVertical !== SEARCH_VERTICALS.HOMES_AND_VILLAS })}
                submitOnApply
                saleUnit="Property"
                filters={HOMES_AND_VILLAS_FILTERS}
                typeaheadTypes={HOMES_AND_VILLAS_TYPEAHEAD_TYPES}
                baseSearchPath="/search/homes-and-villas"
                baseSearchMapPath="/search/homes-and-villas/map"
                locationSearchPlaceholder={HOMES_AND_VILLAS_LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL}
              />
            </GlobalSearchContextProvider>}
            {tabs.has(SEARCH_VERTICALS.FLIGHTS) &&
              <FlightsSearchWidget
                className={cn({ hidden: activeSearchVertical !== SEARCH_VERTICALS.FLIGHTS })}
                searchData={{ lastSearch: getFlightsRecentSearch() }}
                placement="common-search"
                showMultiCity={multiCityEnabled}
              />}
            {tabs.has(SEARCH_VERTICALS.TOURS) && <GlobalSearchTabContent
              className={cn({ hidden: activeSearchVertical !== SEARCH_VERTICALS.TOURS })}
            >
              <GlobalSearchContextProvider state={globalToursSearchState} dispatch={globalToursSearchDispatch}>
                <TourV2SearchControls />
              </GlobalSearchContextProvider>
            </GlobalSearchTabContent>}
            {tabs.has(SEARCH_VERTICALS.EXPERIENCES) && <GlobalSearchContextProvider
              state={globalExperiencesSearchState}
              dispatch={globalExperiencesSearchDispatch}
            >
              <ExperienceSearchForm
                className={cn({ hidden: activeSearchVertical !== SEARCH_VERTICALS.EXPERIENCES })}
              />
            </GlobalSearchContextProvider>}
            {tabs.has(SEARCH_VERTICALS.CARHIRE) && <GlobalSearchContextProvider
              state={globalCarHireSearchState}
              dispatch={globalCarHireSearchDispatch}
            >
              <CarHireSearchBar
                className={cn({ hidden: activeSearchVertical !== SEARCH_VERTICALS.CARHIRE })}
              />
            </GlobalSearchContextProvider>}
          </TabContent>}
          {activeSearchVertical === SEARCH_VERTICALS.HOTELS &&
            <StyledSearchTrendingDestinations
              titleColour="neutral-two"
              titleWeight="normal"
              onClick={closeSearch}
            />
          }
          {activeSearchVertical === SEARCH_VERTICALS.EXPERIENCES &&
            <ExperiencePageTrendingDestinations colour="neutral-two" weight="normal" onClick={closeSearch} />
          }
          {activeSearchVertical === SEARCH_VERTICALS.TOURS && <div className="quick-filters-container">
            <TourV2TrendingDestinationsQuickFilters
              titleColour="neutral-two"
              titleWeight="normal"
              titleLabel="Popular searches"
              onDestinationClick={closeSearch}
            />
          </div>}

          {activeSearchVertical === SEARCH_VERTICALS.CRUISES && <GlobalCruisesSearchTabContent />}

        </StyledLayoutContainer>
        <ErrorBoundary fallback={null}>
          {(!!memberId || !shouldUseMemberId) && activeSearchVertical === SEARCH_VERTICALS.HOTELS &&
            <RecentSearches onClick={closeSearch} memberId={memberId} />
          }
        </ErrorBoundary>
      </SearchFormContainer>
      <StyledBackdrop
        visible={!!activeSearchVertical}
        onClick={closeSearch}
      />
    </>
  )
}

InHeaderSearch.defaultProps = {
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState): MappedStateProps => {
  const filters = getUrlOfferListFilters(appState)
  return {
    filters,
  }
})(InHeaderSearch)

import React from 'react'
import TextLoadingBox from '../Typography/TextLoadingBox'
import PriceRowCaption from './PriceRowCaption'

function PriceRowTaxesAndFeesLoadingSkeleton() {
  return <PriceRowCaption>
    <TextLoadingBox width="16ch" />
  </PriceRowCaption>
}

export default PriceRowTaxesAndFeesLoadingSkeleton

import React from 'react'
import { Redirect, RedirectProps, Route, useLocation } from 'react-router-dom'

interface Props extends RedirectProps {
  code?: number;
  from?: string;
  to: string;
  withSearchAndHash?: boolean;
}

function RedirectWithStatus(props: Props) {
  const { code, from, withSearchAndHash = true, ...redirectProps } = props
  const location = useLocation()
  const to = {
    pathname: redirectProps.to,
    ...(withSearchAndHash && { search: location.search, hash: location.hash }),
  }

  return <Route path={from} render={({ staticContext }) => {
    if (staticContext) {
      staticContext.statusCode = code || 302
    }
    return <Redirect
      {...redirectProps}
      to={to}
    />
  }}/>
}

export default RedirectWithStatus

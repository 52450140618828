import { OFFER_TYPE_TOUR } from 'constants/offer'
import { RESERVATION_TYPE_BOOK_LATER } from 'constants/reservation'
import moment from 'moment'

// constants and enums

interface UpsellSnackbar {
    header: string
    message: string
    primaryActionLinkLabel: string
    primaryActionLinkPath: string
    secondaryActionLinkLabel: string
}

export enum UpsellSnackbarTypes {
    EXPERIENCES = 'experiences'
}

const SECONDARY_ACTION_LINK_LABEL = 'Maybe later'
export const POST_PURCHASE_UPSELL_SNACKBAR_TIMEOUT = 20000

// order "upsellable" util

/*
  The following orders aren't "upsellable" for experiences; flights, tours, bnbl and bedbank reservation types.
  This function checks if the order if one of these order types and if it is, returns false.
*/
export function isOrderUpsellable(order: App.Order) {
  const orderOffer = order.items[0]?.offer
  const isLeTour = orderOffer?.parentType === OFFER_TYPE_TOUR
  const isTourV2 = order?.tourItems.length > 0
  const isBedbank = order.bedbankItems.length > 0
  const isBnbl = order.items[0]?.reservationType === RESERVATION_TYPE_BOOK_LATER
  const isFlights = order.flightItems.length > 0
  return !(isLeTour || isTourV2 || isBedbank || isBnbl || isFlights)
}

// upsell snackbar content utils
export function experienceUpsellSnackbarContent(location: string, query: string): UpsellSnackbar {
  return {
    header: `Explore things to do in ${location}`,
    message: 'We\'ve handpicked a selection of offers for dining, attractions, events and more',
    primaryActionLinkLabel: 'View experiences',
    primaryActionLinkPath: `/search/experiences?${query}`,
    secondaryActionLinkLabel: SECONDARY_ACTION_LINK_LABEL,
  }
}

export function getLocation(soonestUpcomingOrderOffer: App.Offer) {
  return [soonestUpcomingOrderOffer.locationHeading, soonestUpcomingOrderOffer.locationSubheading].filter(Boolean).join(', ')
}

// upsell snackbar cookie utils
function isSnackbarClosed(memberId: string) {
  return (document.cookie
    .split('; ')
    .find((row) => row.startsWith('upsellSnackbar='))
    ?.split('=')[1]) === `${memberId}`
}

export function upsellSnackbarDismissed(memberId: string) {
  return typeof document !== 'undefined' && isSnackbarClosed(memberId)
}

function closeUpsell(memberId: string, expiryDate?: Date) {
  document.cookie = `upsellSnackbar=${memberId}${';path=/'}${expiryDate ? `;expires=${expiryDate}` : ''}`
}

// upsell snackbar onClick utils
export function onCloseFor10Days(memberId: string) {
  const startDate = moment()
  const expiryDate = moment(startDate, 'DD-MM-YYYY').add(10, 'days').toDate()
  closeUpsell(memberId, expiryDate)
}

export function onOpenUpsellSnackbar(memberId: string) {
  closeUpsell(memberId)
}

import React, { useRef } from 'react'
import { shallowEqual } from 'react-redux'

export function memoWithNestedProps<C extends React.ComponentType<any>, Props extends React.ComponentProps<C>, N extends keyof Props>(
  component: C,
  nestedPropNames: Array<N>,
) {
  const MemoizedComponent = React.memo(component)

  const newComponent = React.forwardRef<React.ComponentRef<C>, Props>(
    (props, ref) => {
      const memoisedPropValues = useRef<{ [key in N]?: Props[N] }>({})

      const combinedProps: Props = { ...props }

      for (const prop of nestedPropNames) {
        const memoisedValue: Props[N] | undefined = memoisedPropValues.current[prop]
        if (shallowEqual(props[prop], memoisedValue)) {
          if (typeof memoisedValue !== 'undefined') {
            combinedProps[prop] = memoisedValue
          }
        } else {
          memoisedPropValues.current[prop] = props[prop]
        }
      }

      // @ts-ignore Not sure what TS is whinging about here
      return <MemoizedComponent {...combinedProps} ref={ref} />
    },
  )

  newComponent.displayName = `memoWithNestedProps(${
    component.displayName || component.name || 'Component'
  })`

  return newComponent
}

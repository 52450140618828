import { useReducer } from 'react'

import ViewState, {
  viewStateReducer,
  VIEW_INITIAL_STATE,
} from 'tripPlanner/contexts/View/ViewState'

export default function useViewState(
  initialState: ViewState = VIEW_INITIAL_STATE,
) {
  const [state, dispatch] = useReducer(viewStateReducer, initialState)
  return { state, dispatch }
}

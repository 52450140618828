import getObjectKey from 'lib/object/getObjectKey'

export function generateOfferCreditKey(
  toBeCalculatedBusinessCreditItem: BusinessTraveller.ToBeCalculatedBusinessCreditItem,
  offer: App.AnyOffer,
): string {
  const creditItemKey = getObjectKey(toBeCalculatedBusinessCreditItem)

  return `${offer.id}__${creditItemKey}`
}

export function generateFlightCreditKey(
  toBeCalculatedBusinessCreditItem: BusinessTraveller.ToBeCalculatedBusinessCreditItem,
): string {
  return getObjectKey(toBeCalculatedBusinessCreditItem)
}

import { createSelector } from 'reselect'
import { isOrganicSearch } from 'lib/search/searchUtils'
import {
  isAndroid,
  isApple,
  isIOS,
  isMobileDevice,
} from 'lib/web/deviceUtils'

export const hasOrganicReferrer = createSelector(
  (state: App.State) => state.config.referrer,
  (referrer) => isOrganicSearch(referrer),
)

export const isAndroidSel = createSelector(
  (state: App.State) => state.config.device,
  (device) => isAndroid(device.osName),
)

export const isAppleSel = createSelector(
  (state: App.State) => state.config.device,
  (device) => isApple(device.osName),
)

export const isIOSSel = createSelector(
  (state: App.State) => state.config.device,
  (device) => isIOS(device.osName),
)

export const isCurrentDeviceMobile = createSelector(
  (state: App.State) => state.config.device,
  (device): boolean => isMobileDevice(device),
)

export const isHeadlessModeSel = (state: App.State) => state.config.headlessMode

export const isMobileAppSel = createSelector(
  isCurrentDeviceMobile,
  isHeadlessModeSel,
  (isMobileDevice, isHeadlessMode) => isMobileDevice && isHeadlessMode,
)

export const useMobileNativeAppAuth = createSelector(
  (state: App.State) => state.config.headlessMode,
  (headlessMode) => {
    return headlessMode &&
    typeof window !== 'undefined' &&
    (
      window.webkit?.messageHandlers?.authenticateUser ||
      window.appInterface
    )
  },
)

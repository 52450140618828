import { getWithTimestamp, set, remove } from 'lib/storage/versionedLocalStorageUtils'

/*
 * For storage of the trip ID that the user most recently saved to
 * in this session, so we can auto-save to that same trip again if
 * they click a bookmark button within 10 minutes.
 */

const KEY = 'recentlySavedTripId'
const VERSION = 1

const TEN_MINUTES = 1000 * 60 * 10

export function setRecentlySavedTripId(tripId: string) {
  set(KEY, VERSION, tripId)
}

export function getRecentlySavedTripId(): string | null {
  const { value, timestamp } = getWithTimestamp<string>(KEY, VERSION)
  if (value && timestamp && Date.now() < timestamp + TEN_MINUTES) {
    return value
  } else {
    return null
  }
}

export function clearRecentlySavedTripId() {
  remove(KEY)
}

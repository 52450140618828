import { authLogout } from 'actions/AuthActions'
import { pushWithRegion } from 'actions/NavigationActions'
import ListItem from 'components/Luxkit/List/ListItem'
import Modal from 'components/Luxkit/Modal/Modal'
import config from 'constants/config'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { useCallback } from 'react'

function AgentHubAccountMenuModal() {
  const dispatch = useAppDispatch()

  const { resolve } = useModalElementContext()

  const onLogout = useCallback(() => {
    dispatch(authLogout())
    dispatch(pushWithRegion('/'))
    resolve()
  }, [dispatch, resolve])

  return <Modal title="Account">
    <ListItem title="View dashboard" href={config.AGENT_PORTAL_URL} target="_blank" />
    <ListItem title="Log out" onClick={onLogout} />
  </Modal>
}

export default AgentHubAccountMenuModal

import { fetchCruiseSearchFacets } from 'actions/CruiseActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import getObjectKey from 'lib/object/getObjectKey'
import { useEffect, useMemo } from 'react'

const emptyState: App.CruiseSearchFacetState = {
  fetching: true,
  facets: [],
}

function useCruiseSearchFacets(
  params: App.CruiseSearchFacetParams,
): [Array<App.CruiseSearchFacet>, boolean, any] {
  const dispatch = useAppDispatch()
  const key = useMemo(() => getObjectKey(params), [params])

  const facetState = useAppSelector(state => state.cruise.cruiseFacets[key] ?? emptyState)
  useEffect(() => {
    dispatch(fetchCruiseSearchFacets(params))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  return [facetState.facets, facetState.fetching || false, facetState.error]
}

export default useCruiseSearchFacets

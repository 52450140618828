import { useEffect, useRef } from 'react'
import equal from 'fast-deep-equal'

/**
 * Forces an object to be a stable reference if it is deeply equal to the previous value.
 */
export default function useStableObjectReference<T>(object: T): T {
  const lastValue = useRef<T>(object)

  const didChange = !equal(object, lastValue.current)

  useEffect(() => {
    if (didChange) {
      lastValue.current = object
    }
  })

  if (didChange) {
    return object
  } else {
    return lastValue.current
  }
}

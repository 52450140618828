import { DISABLE_STICKY_PROMPT_CARD, ENABLE_STICKY_PROMPT_CARD } from 'actions/actionConstants'
import { createReducer } from 'lib/redux/reducerUtils'

export const initialStickyPromptCardState: App.StickyPromptCardState = {
  isEnabled: true,
}

export default createReducer<App.StickyPromptCardState>(initialStickyPromptCardState, {
  [ENABLE_STICKY_PROMPT_CARD]: () => ({
    isEnabled: true,
  }),
  [DISABLE_STICKY_PROMPT_CARD]: () => ({
    isEnabled: false,
  }),
})

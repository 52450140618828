import { DEFAULT_FLEXIBLE_DURATION_RANGE, FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { rem } from 'polished'
import React, { MouseEventHandler, useCallback, useState } from 'react'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import ToggleButton from 'components/Luxkit/ToggleButton'

const SelectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${rem(12)};
  flex-wrap: wrap;
  gap: ${rem(12)};
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: auto;

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`

interface Props {
  durationOptionsCustom?: Array<DurationOption>
  previouslySelectedDuration?: FLEXIBLE_DURATION_RANGE
  onDurationChange?: (duration: string) => void
  className?: string
}

export interface DurationOption {
  title: string,
  caption: string,
  id: FLEXIBLE_DURATION_RANGE
}

export const durationOptionsDefault: Array<DurationOption> = [
  {
    title: 'Any duration',
    caption: 'I’ll decide later',
    id: DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION,
  },
  {
    title: 'Short stay',
    caption: '1 to 3 nights',
    id: DEFAULT_FLEXIBLE_DURATION_RANGE.SHORT_STAY,
  },
  {
    title: 'Medium stay',
    caption: '4 to 7 nights',
    id: DEFAULT_FLEXIBLE_DURATION_RANGE.MEDIUM_STAY,
  },
  {
    title: 'Long stay',
    caption: '8+ nights',
    id: DEFAULT_FLEXIBLE_DURATION_RANGE.LONG_STAY,
  },
]

function DurationPicker(props: Props) {
  const { durationOptionsCustom = durationOptionsDefault, onDurationChange, previouslySelectedDuration } = props
  const [flexibleNights, setFlexibleNights] =
    useState<FLEXIBLE_DURATION_RANGE>(
      previouslySelectedDuration ?? DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION,
    )

  const onDurationClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const duration = event.currentTarget.dataset.option as FLEXIBLE_DURATION_RANGE
      const newFlexibleDuration = duration === flexibleNights ?
        DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION :
        duration
      setFlexibleNights(newFlexibleDuration)
      onDurationChange?.(newFlexibleDuration)
    },
    [flexibleNights, onDurationChange],
  )

  return (
    <SelectionContainer data-testid="flexibleDurationPicker" className={props.className}>
      {durationOptionsCustom.map((option) => <ToggleButton
        key={option.id}
        kind="secondary"
        title={option.title}
        subtitle={option.caption}
        isSelected={option.id === flexibleNights}
        data-testid={`duration-option-${option.id}`}
        data-option={option.id}
        onClick={onDurationClick}
      />)}
    </SelectionContainer>
  )
}

export default DurationPicker

import Group from 'components/utils/Group'
import { EXPERIENCE_MINIMUM_DISCOUNT_PERCENTAGE_TO_SHOW } from 'constants/experience'
import React, { useMemo } from 'react'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import { getAgentHubExperienceType } from 'lib/agentHub/agentHubUtils'

interface Props {
  experience: App.ExperienceBookingView;
  variant?: 'default' | 'condensed';
}

function ExperienceOfferPriceDetails(props: Props) {
  const { experience, variant = 'default' } = props
  const productType = useMemo(() => getAgentHubExperienceType(experience.originalExperience), [experience])
  const { pricing, location } = experience

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <Group direction="vertical" verticalAlign="end">
        <PriceRowPriceCaption>From</PriceRowPriceCaption>
        <PriceRowPrice
          size="L"
          price={pricing.price}
          saleUnit={pricing.saleUnit}
        />
        {pricing.discountPercent >= EXPERIENCE_MINIMUM_DISCOUNT_PERCENTAGE_TO_SHOW && <PriceRowValueDiscountWithCaption
          size="L"
          originalValue={Math.ceil(pricing.value)}
          discountPercentage={pricing.discountPercent}
        />}
        <PriceRowAgentHubCommission
          offerId={experience.id}
          productType={productType}
          offerLocations={location ? [location] : undefined}
          size="L"
        />
      </Group>
      {variant === 'default' && <CSSBreakpoint min="desktop">
        <TextButton kind="primary" nonInteractable>
          View
        </TextButton>
      </CSSBreakpoint>}
      {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
        View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
      </TextButton> }
    </Group>
  )
}

export default ExperienceOfferPriceDetails

import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'

import getPayableTotal from './getPayableTotal'
import getDepositAmountBeforeCreditApplied from './getDepositAmountBeforeCreditApplied'
import getDiscountTotal from './getDiscountTotal'

const getDepositRemaining = createSelector(
  getPayableTotal,
  getDiscountTotal,
  getDepositAmountBeforeCreditApplied,
  (total, discount, deposit): number => {
    const depositRemaining = total - discount - deposit
    return floatify(depositRemaining)
  },
)

export default getDepositRemaining

import uuidV4 from 'lib/string/uuidV4Utils'
import { CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION } from 'constants/checkout'

export function generateLuxPlusSubscriptionItem(offer: App.SubscriptionOffer): App.Checkout.LuxPlusSubscriptionItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
    amount: offer.price,
    benefits: offer.benefits,
    offerId: offer.id,
    tier: offer.tier,
  }
}

export function generateLuxPlusJoiningFeeItem(
  offer: App.SubscriptionOffer,
  subscriptionFeeItemId: string,
  isRenewal: boolean,
): App.Checkout.SubscriptionJoinItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: 'subscription-join',
    amount: isRenewal ? 0 : offer.joinFee,
    waived: offer.joinFeeWaived,
    subscriptionItemId: subscriptionFeeItemId,
    subscriptionOfferId: offer.id,
  }
}

export function generateLuxPlusSubscriptionItems(offer: App.SubscriptionOffer, isRenewal = false): [App.Checkout.LuxPlusSubscriptionItem, App.Checkout.SubscriptionJoinItem] {
  const subscriptionItem = generateLuxPlusSubscriptionItem(offer)
  const feeItem = generateLuxPlusJoiningFeeItem(offer, subscriptionItem.itemId, isRenewal)

  return [subscriptionItem, feeItem]
}

import React from 'react'
import Label from './Label'
import styled from 'styled-components'
import { rem } from 'polished'

const RoundedLabel = styled(Label)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(26)};
  width: ${rem(26)};
  border-radius: ${props => props.theme.borderRadius.round};
  padding: 0;
`

interface Props extends Omit<React.ComponentProps<typeof Label>, 'children' | 'startIcon' | 'endIcon'> {
  icon: React.ReactNode;
}

function IconLabel(props: Props) {
  return <RoundedLabel {...props } startIcon={props.icon} children={null}/>
}

export default IconLabel

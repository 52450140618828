import FormatCurrency from 'components/Common/FormatCurrency'
import React, { ComponentProps, MouseEventHandler, ReactElement, ReactNode, useCallback } from 'react'
import BodyText from '../Typography/BodyText'
import _PriceSummaryRow from './_PriceSummaryRow'
import BodyTextBlock from '../TextBlocks/BodyTextBlock'
import Clickable from 'components/Common/Clickable'
import SolidInfoCircleIcon from '../Icons/solid/SolidInfoCircleIcon'
import TextLoadingBox from '../Typography/TextLoadingBox'
import Group from 'components/utils/Group'

type InheritedBasePriceSummaryRowProps = Pick<ComponentProps<typeof _PriceSummaryRow>, 'data-testid'>

interface Props extends InheritedBasePriceSummaryRowProps {
  /**
   * Formatted price at the end.
   */
  value?: number
  /**
   * @default preciseDollar
   */
  valueFormat?: ComponentProps<typeof FormatCurrency>['format']
  /**
   * Puts the value in a loading state.
   */
  valueLoading?: boolean
  /**
   * Textual content at the start.
   */
  label: ReactElement | string
  labelTrailing?: ReactNode
  labelFormat?: ComponentProps<typeof BodyTextBlock>['format']
  /**
   * Won't bubble/propagate.
  */
  onInfoIconClick?: MouseEventHandler<HTMLButtonElement>
}

function PriceSummaryRowItemSubtitle(props: Props) {
  const {
    label,
    labelTrailing,
    labelFormat,
    value,
    valueFormat = 'preciseDollar',
    valueLoading,
    'data-testid': dataTestId,
    onInfoIconClick,
  } = props

  const handleInfoIconClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()
    onInfoIconClick?.(event)
  }, [onInfoIconClick])

  return <_PriceSummaryRow
    data-testid={dataTestId}
    className={PriceSummaryRowItemSubtitle.name}
    startSlot={<Group direction="horizontal" gap={4} verticalAlign="center" wrap="wrap">
      <BodyTextBlock
        variant="medium"
        weight="bold"
        colour="neutral-one"
        lineClamp={2}
        format={labelFormat}
        endIcon={onInfoIconClick && <Clickable onClick={handleInfoIconClick}><SolidInfoCircleIcon /></Clickable>}
      >
        {label}
      </BodyTextBlock>
      {!!labelTrailing && <div>{labelTrailing}</div>}
    </Group>}
    endSlot={value !== undefined && <BodyText variant="medium" weight="normal" colour="neutral-one" wrap="no-wrap">
      {!valueLoading && <FormatCurrency value={value} format={valueFormat} />}
      {valueLoading && <TextLoadingBox width="8ch" />}
    </BodyText>}
  />
}

export default PriceSummaryRowItemSubtitle

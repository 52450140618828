import config from 'constants/config'
import getPaymentMethods from 'lib/payment/getPaymentMethods'
import {
  ATOME_BP_PAYMENT_TYPE,
  MIN_ATOME_BP_SGD_PAYMENT,
  MIN_ATOME_BP_MYR_PAYMENT,
  MIN_ATOME_BP_HKD_PAYMENT,
  MAX_ATOME_BP_SGD_PAYMENT,
  MAX_ATOME_BP_MYR_PAYMENT,
  MAX_ATOME_BP_HKD_PAYMENT,
} from 'constants/payment'

export const isAtomeBpEnabled = (currency: string): boolean =>
  !!(config.ATOME_BP_ENABLED && getPaymentMethods(currency).includes(ATOME_BP_PAYMENT_TYPE))

export const isWithinAtomeBpAvailabilityRange = (amount: number, currency:string): boolean => {
  switch (currency) {
    case 'SGD':
      return (amount >= MIN_ATOME_BP_SGD_PAYMENT && amount <= MAX_ATOME_BP_SGD_PAYMENT)
    case 'MYR':
      return (amount >= MIN_ATOME_BP_MYR_PAYMENT && amount <= MAX_ATOME_BP_MYR_PAYMENT)
    case 'HKD':
      return (amount >= MIN_ATOME_BP_HKD_PAYMENT && amount <= MAX_ATOME_BP_HKD_PAYMENT)
    default:
      return false
  }
}

export const getAtomeBpMaxLimit = (currency:string) :number => {
  switch (currency) {
    case 'SGD':
      return MAX_ATOME_BP_SGD_PAYMENT
    case 'MYR':
      return MAX_ATOME_BP_MYR_PAYMENT
    case 'HKD':
      return MAX_ATOME_BP_HKD_PAYMENT
    default:
      return 0
  }
}

import Image from 'components/Common/Image'
import NumberRating from 'components/Common/NumberRating'
import { rem } from 'polished'
import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { _searchTileContainerQueryUp } from './_SearchTileBase'
import { SearchTileLogo } from './SearchTile'

const Container = styled.div`
  grid-area: content-secondary;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: ${rem(8)};
`

const RatingContainer = styled.div`
  display: none;

  ${_searchTileContainerQueryUp.tablet} {
    display: block;
  }

  ${_searchTileContainerQueryUp.desktopSmall} {
    display: none;
  }

  ${_searchTileContainerQueryUp.desktop} {
    display: block;
  }
`

const Logo = styled(Image)`
  object-fit: contain;
  margin-inline: auto;
  height: ${rem(90)};
  display: none;

  ${_searchTileContainerQueryUp.tablet} {
    display: block;
  }

  ${_searchTileContainerQueryUp.desktopSmall} {
    display: none;
  }

  ${_searchTileContainerQueryUp.desktop} {
    display: block;
  }
`

interface Props extends PropsWithChildren {
  callToAction?: ReactElement
  rating?: App.OfferRating
  logo?: SearchTileLogo;
}

function _SearchTileContentSecondary({
  children,
  rating,
  logo,
}: Props) {
  return <Container className={_SearchTileContentSecondary.name}>
    {children}
    {logo && <Logo image={logo.full} width={140} dpr={2} />}
    {rating && <RatingContainer>
      <NumberRating
        rating={rating.score}
        total={rating.reviewsTotal}
        variant="small"
        numberPosition="end"
      />
    </RatingContainer>}
  </Container>
}

export default _SearchTileContentSecondary

import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { numToWords } from 'lib/string/numToWords'
import { mediaQueryUp } from 'components/utils/breakpoint'
import PaneBody from 'components/Common/Pane/PaneBody'
import SlideDown from 'components/Common/SlideDown'
import Clickable from 'components/Common/Clickable'
import { getPlural } from 'lib/string/pluralize'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LineBedDoubleIcon from 'components/Luxkit/Icons/line/LineBedDoubleIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import { capitalise } from 'lib/string/stringUtils'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import cn from 'clsx'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { getImageUrl } from 'lib/image/imageUtils'
import { IMAGE_HOST_CONFIG } from 'components/OfferPage/TourV2/TourV2HostsSponsors'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import TourItineraryExperienceCard from 'components/OfferPage/TourV2/Itinerary/TourItineraryExperienceCard'
import Group from 'components/utils/Group'
const BASE_RADIUS = 10
const STROKE_WIDTH = 2

const AccordionSection = styled.div`
  &:last-child {
    > * {
      border-bottom-color: transparent;
    }
  }
`
const AccordionHeader = styled(Clickable)`
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  padding: ${rem(20)} 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};

  ${mediaQueryUp.tablet} {
    background-color: ${props => props.theme.palette.neutral.default.seven};
    border-radius: ${props => props.theme.borderRadius.S};
    border-bottom: unset;
    padding: ${rem(12)};
  }

  &.open {
    border-bottom-color: transparent;
  }
`

const DescriptionWrapper = styled(Group)`
  padding: 0 0 ${rem(32)} 0;
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};

  ${mediaQueryUp.tablet} {
    padding: ${rem(16)} 0;
    border-bottom: none;
  }
`

const PaneBodyStyled = styled(PaneBody)`
  width: 100%;
  ${mediaQueryUp.tablet} {
    padding: 0 ${rem(24)} 0 ${rem(18)};
  }
`

const VisualAccentWrapper = styled.div`
  display: none;

  ${mediaQueryUp.tablet} {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: ${rem(20)};
  }
`

const DashedLine = styled.div`
  height: 100%;
  border: 1px dashed ${props => props.theme.palette.neutral.default.five};
`

const CircleWrapper = styled.div`
  margin-top: ${rem(4)};
  filter: drop-shadow(0px 0.7px 1.4px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1.9px 4px rgba(0, 0, 0, 0.05)) drop-shadow(0px 4.5px 10px rgba(0, 0, 0, 0.05));
`

const TitleWrapper = styled.div`
  display: grid;
  grid-template:
    "dayLabel duration"
    "title title"
    / min-content;
  grid-column-gap: ${rem(12)};
  grid-row-gap: ${rem(4)};

  ${mediaQueryUp.tablet} {
    grid-template:
    "dayLabel title" 1fr
    "dayLabel duration" auto
    / auto 1fr;
    grid-row-gap: 0;
  }
`

const Title = styled(Heading)`
  grid-area: title;
`

const DurationWrapper = styled(Group)`
  grid-area: duration;
`

const DayLabel = styled(StructureLabel)`
  grid-area: dayLabel;
  align-self: center;
`

const ItineraryIcon = styled(LineAngleRightIcon)`
  transform: rotate(90deg);
  transition: transform 0.2s ease-out;

  &.open {
    transform: rotate(-90deg);
  }
`

const StopCircle = styled.circle`
  fill: ${props => props.theme.palette.neutral.default.one};
  stroke: ${props => props.theme.palette.neutral.default.eight};
`

const SpecialEventWrapper = styled(Group)`
  padding: ${rem(8)} ${rem(16)};
  background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
`

const HostImageRounded = styled.img`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.borderRadius.round};

  width: ${rem(40)};
  height: ${rem(40)};
`

interface Props {
  itineraryItem: Tours.TourV2OfferItineraryItem
  open: boolean
  toggleAccordion: (key: string) => void
  last: boolean
  shouldDisplayLocationsVisited?: boolean
  showExperiences: boolean
}

function TourV2ItineraryBreakdown(props: Props) {
  const { itineraryItem, open, toggleAccordion, last, shouldDisplayLocationsVisited, showExperiences } = props

  const {
    duration,
    locationsVisited,
  } = useMemo(() => ({
    duration: `${numToWords(itineraryItem.duration)} ${getPlural('night', itineraryItem.duration)}`,
    locationsVisited: shouldDisplayLocationsVisited ? itineraryItem.locationsVisited?.join(', ') : undefined,
  }), [itineraryItem, shouldDisplayLocationsVisited])

  const daysLabelText = useMemo(() => {
    if (itineraryItem.duration === 1) {
      return itineraryItem.startDay
    }

    return Array
      .from({ length: itineraryItem.duration }, (_, i) => itineraryItem.startDay + i) // Creates the days array
      .join(', ') // Joins then with a comma
      .replace(/, ([^,]*)$/, ' & $1') // Replaces the last comma with an ampersand
  }, [itineraryItem])

  return (
    <AccordionSection>
      <AccordionHeader className={cn({ open })} onClick={() => toggleAccordion(`${itineraryItem.startDay}`)}>

        <Group direction="horizontal" horizontalAlign="center">
          <TitleWrapper>
            <DayLabel variant="dark">Day {daysLabelText}</DayLabel>
            <DurationWrapper direction="vertical" verticalAlign="center">
              <BodyText variant="small">
                {last ? 'Last day' : capitalise(duration)}
              </BodyText>
            </DurationWrapper>
            <Title variant="heading5">{itineraryItem.title}</Title>
          </TitleWrapper>
        </Group>

        <ItineraryIcon className={cn({ open })} />
      </AccordionHeader>

      <SlideDown show={open} animateOpacity>
        <DescriptionWrapper direction="horizontal">
          <VisualAccentWrapper>
            <CircleWrapper>
              <svg width={BASE_RADIUS * 2} height={BASE_RADIUS * 2}>
                <StopCircle
                  cx={BASE_RADIUS}
                  cy={BASE_RADIUS}
                  r={BASE_RADIUS - STROKE_WIDTH}
                  strokeWidth={STROKE_WIDTH}
                />
              </svg>
            </CircleWrapper>
            <DashedLine />
          </VisualAccentWrapper>

          <PaneBodyStyled>
            <VerticalSpacer gap={16}>
              {!!locationsVisited && <Heading variant="heading5">{locationsVisited}</Heading>}
              <MarkdownRender content={itineraryItem.description} fontSize="medium" />
              {!!itineraryItem.specialsEvents?.length &&
                <VerticalSpacer gap={8}>
                  <Heading variant="heading6">Signature highlights</Heading>
                  <VerticalSpacer gap={4}>
                    {itineraryItem.specialsEvents.map((specialEvent, index) => (
                      <SpecialEventWrapper direction="horizontal" gap={16} verticalAlign="center" key={index}>
                        { !!specialEvent.hostImageId &&
                          <HostImageRounded src={getImageUrl(specialEvent.hostImageId, IMAGE_HOST_CONFIG)} />
                        }
                        <MarkdownRender content={specialEvent.description} fontSize="medium" />
                      </SpecialEventWrapper>
                    ))}
                  </VerticalSpacer>
                </VerticalSpacer>
              }
              {(!!itineraryItem.meals?.length || !!itineraryItem.accommodation) && (
                <VerticalSpacer gap={8}>
                  <Heading variant="heading6">Inclusions</Heading>
                  {!!itineraryItem.accommodation && (
                    <Group direction="horizontal" gap={8} verticalAlign="center" data-testid="accommodation">
                      <LineBedDoubleIcon size="S" />
                      <BodyText variant="medium">{itineraryItem.accommodation}</BodyText>
                    </Group>
                  )}
                  {!!itineraryItem.meals?.length && (
                    <Group direction="horizontal" gap={8} verticalAlign="center" data-testid="meals">
                      <LineRestaurantIcon size="S" />
                      <BodyText variant="medium">{[itineraryItem.meals.slice(0, -1).join(', '), itineraryItem.meals.slice(-1)].filter(item => !!item).join(' and ')}</BodyText>
                    </Group>
                  )}
                </VerticalSpacer>
              )}
              {showExperiences && !!itineraryItem.experiences?.length &&
                <VerticalSpacer gap={12}>
                  <div>
                    <Heading variant="heading6">Personalise your day with optional experiences</Heading>
                    <BodyText variant="medium">These will be available when booking your tour</BodyText>
                  </div>
                  <CardCarousel width={`min(${rem(270)}, 100%)`}>
                    {itineraryItem.experiences.map((experience) => {
                      const title = `Day ${daysLabelText}: ${itineraryItem.locationsVisited.join(' - ')}`
                      return <TourItineraryExperienceCard
                        key={experience.id}
                        tourExperience={experience}
                        title={title}
                      />
                    })}
                  </CardCarousel>
                </VerticalSpacer>
              }
            </VerticalSpacer>
          </PaneBodyStyled>
        </DescriptionWrapper>
      </SlideDown>
    </AccordionSection>
  )
}

export default memo(TourV2ItineraryBreakdown)

import { CUDO_TRAVEL, TREATME_TRAVEL, DEALS_TRAVEL, SCOOPON_TRAVEL } from 'constants/brands'

export function getBrandLabel(brand: string) {
  switch (brand) {
    case CUDO_TRAVEL: return 'cudo'
    case TREATME_TRAVEL: return 'treatme'
    case DEALS_TRAVEL: return 'deals'
    case SCOOPON_TRAVEL: return 'scoopon'
    default: return null
  }
}

export function isWhitelabelBrand(brand: string) {
  const label = getBrandLabel(brand)
  return label ? label.length > 0 : false
}

export type WhiteLabelAppConfig = {
  isApp: boolean,
  googleLoginURI?: string;
  clientVersion?: string;
  platform?: 'ios' | 'android',
  isAndroid?: boolean,
  isIOS?: boolean
}
export function getWhiteLabelAppConfig(): WhiteLabelAppConfig {
  return {
    isApp: !!window.whiteLabelAppConfig,
    platform: window.whiteLabelAppConfig?.plataform,
    googleLoginURI: window.whiteLabelAppConfig?.googleLoginURI,
    clientVersion: window.whiteLabelAppConfig?.clientVersion,
    isAndroid: window.whiteLabelAppConfig?.plataform === 'android',
    isIOS: window.whiteLabelAppConfig?.plataform === 'ios',
  }
}

const ENABLED_OFFER_FEATURE_SYMBOLS: Record<App.OfferFeatureSymbol, boolean> = {
  wifi: true,
  parking: true,
  bed: true,
  kitchen: true,
  wardrobe: true,
  cook: true,
  stove: true,
  pool: true,
  coffee: true,
  heat: true,
  ocean: true,
  wind: true,
  cutlery: true,
  sun: true,
  iron: true,
  garden: true,
  juice: true,
  breakfast: true,
  washer: true,
  outdoor: true,
  apple: true,
  bed_with_upwards_arrow: true,
  bus: true,
  laundry: true,
  wheelchair: true,
  dryer: true,
  car: true,
  childs_face_with_plus: true,
  childs_face: true,
  cloche: true,
  clock_with_check: true,
  check: true,
  clock_with_plus: true,
  coffee_cup: true,
  crossed_utensils_with_plus: true,
  crossed_utensils: true,
  dollar_sign_in_circle_with_line_through: true,
  dollar_sign_in_circle: true,
  drink_tray: true,
  golf: true,
  lotus: true,
  lotus_with_plus: true,
  martini_glass: true,
  p_in_circle: true,
  plane_with_arrows: true,
  plane_with_arrow: true,
  plane_with_plus: true,
  plane_departure: true,
  receipt: true,
  seat_with_plane: true,
  ski_lift: true,
  tag: true,
  taxi: true,
  tennis_ball: true,
  wifi_signal: true,
  wine_glass_and_beer_glass: true,
  wine_glass_with_clock: true,
  wine_glass: true,
  air_conditioning: true,
  internet_connection: true,
  beach: true,
  king_size_bed: true,
  ensuite: true,
  heating: true,
  tv: true,
  toiletries: true,
  shower: true,
  underground_parking: true,
  terrace: true,
  balcony: true,
  bathtub: true,
  dining_area: true,
  toaster: true,
  oven: true,
  microwave: true,
  blender: true,
  washing_machine: true,
  hair_dryer: true,
  fridge: true,
  stereo: true,
  kettle: true,
  desk: true,
  gym: true,
  line_bed: true,
  line_room_upgrade: true,
  line_sightseeing: true,
  line_university: true,
  line_mountains_road: true,
  line_iconic_landmark: true,
  line_ship: true,
  line_check: true,
  line_key_skeleton: true,
  line_price_tag: true,
  key: true,
  beverage: true,
}

const KNOWN = Object.entries(ENABLED_OFFER_FEATURE_SYMBOLS).filter(([, v]) => v).map(([k]) => k) as Array<App.OfferFeatureSymbol>

function determineOfferFeatureSymbol(clue: string | undefined): App.OfferFeatureSymbol | undefined {
  if (!clue) {
    return
  }
  const possibleKey = clue.replaceAll(' ', '_').replaceAll('-', '').toLowerCase()
  return KNOWN.find(known => possibleKey.includes(known))
}

export default determineOfferFeatureSymbol

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineInfoCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 11a1.0001 1.0001 0 0 0-1 1v4a1.0001 1.0001 0 0 0 2 0v-4a1.0001 1.0001 0 0 0-1-1Zm.38-3.92a1 1 0 0 0-.76 0 1.0004 1.0004 0 0 0-.33.21 1.1497 1.1497 0 0 0-.21.33A.8395.8395 0 0 0 11 8a1.0005 1.0005 0 0 0 .29.71c.0972.0883.2088.1594.33.21A1.0001 1.0001 0 0 0 13 8a1.0502 1.0502 0 0 0-.29-.71.9994.9994 0 0 0-.33-.21ZM12 2a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm0 18a8.0005 8.0005 0 0 1-7.391-4.9385A8 8 0 1 1 12 20Z"/>
  </SvgIcon>
}

export default LineInfoCircleIcon

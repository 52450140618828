import { Models as FlightModels } from '@luxuryescapes/lib-flights-types'

export function mapFlightFareFamily(serverFareFamily: FlightModels.UpsellFare.IUpsellFare): App.FlightFareFamily {
  return {
    fareFamily: {
      name: serverFareFamily.fareFamily.name,
      carrier: serverFareFamily.fareFamily.carrier,
      description: serverFareFamily.fareFamily.description,
      fareFeatures: serverFareFamily.fareFamily.fareFeatures.map(feature => ({
        code: feature.code,
        group: (feature as any).groupId,
        description: feature.description,
        status: feature.status,
        type: feature.type as App.FareFamilyFeatureType,
      })),
    },
    cost: {
      adult: serverFareFamily.cost.adult?.amount ?? 0,
      children: serverFareFamily.cost.children?.amount ?? 0,
      infant: serverFareFamily.cost.infant?.amount ?? 0,
      all: serverFareFamily.cost.all?.amount ?? 0,
    },
    order: serverFareFamily.hierarchy,
    id: serverFareFamily.id,
    cabin: serverFareFamily.cabin,
    fareBasis: serverFareFamily.fareBasis,
    bookingClass: serverFareFamily.bookingClass,
    fareRulesUrl: (serverFareFamily as any).links?.find((link: any) => link.key === 'fareRules')?.value!,
    pairedUpsellIds: serverFareFamily.pairedUpsellIds,
  }
}

export function mapFareFamily(serverFareFamily: FlightModels.Search.IFareFamily): App.FareFamily {
  return {
    name: serverFareFamily.name,
    carrier: serverFareFamily.carrier,
    description: serverFareFamily.description,
    fareFeatures: serverFareFamily.fareFeatures.map(feature => ({
      code: feature.code,
      group: (feature as any).groupId,
      description: feature.description,
      status: feature.status,
      type: feature.type as App.FareFamilyFeatureType,
    })),
  }
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidFireIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M19.0558 9.8125c-.1955-.2178-.5558-.0732-.5989.2143-.2734 1.8244-1.5126 3.3617-3.2249 4.166-.2468.1159-.5004-.1127-.434-.3738.4483-1.7614.6889-3.5943.6889-5.478 0-3.8104-3.131-6.9466-7.1482-7.3393-.3045-.0298-.4667.3428-.2844.5847 1.3414 1.7799 1.152 4.245-.5681 5.8318L5.4858 9.2634C3.9489 10.678 3 12.6348 3 14.799c0 1.7193.5665 3.3166 1.5366 4.6416.2372.324.7319.1451.771-.2523.2446-2.4853 1.2322-4.1644 2.3024-5.1818.199-.1891.4892-.0309.4525.2389-.1.7358-.2743 1.9852 0 3.8748.2774 1.9107 1.297 3.1686 2.2291 3.9424 1.0198.8468 2.4068 1.1067 3.7118.8332C18.0108 22.0557 21 18.7496 21 14.7989c0-1.895-.7291-3.6325-1.9442-4.9864Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default SolidFireIcon

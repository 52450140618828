import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import { getLuxPlusSubscriptionItems } from '../view/luxPlusSubscription'
import getPayableAfterModifiers from './getPayableAfterModifiers'
import getDepositAmountBeforeCreditApplied from './getDepositAmountBeforeCreditApplied'
import getServiceFeeAmount from './getServiceFeeAmount'

const getCreditPayableAmount = createSelector(
  getPayableAfterModifiers,
  (state: App.State) => state.auth.account.creditsByCurrency[state.checkout.cart.currencyCode],
  isSpoofed,
  getDepositAmountBeforeCreditApplied,
  (state: App.State) => state.checkout.payment.rebookingID,
  getServiceFeeAmount,
  getLuxPlusSubscriptionItems,
  (payable, credits, isSpoofed, depositAmount, rebookingID, serviceFee, luxPlusCartSubscriptionItems): number => {
    let maxCreditTotal: number
    const creditBalance = credits?.balance ?? 0
    if (isSpoofed && rebookingID) {
      const rebookingPayable = depositAmount + serviceFee
      maxCreditTotal = creditBalance < rebookingPayable ? creditBalance : rebookingPayable
    } else {
      maxCreditTotal = Math.min(creditBalance, payable)
    }

    if (luxPlusCartSubscriptionItems.length > 0) {
      const subscriptionItem = luxPlusCartSubscriptionItems[0]
      const maxCreditTotalPayableForSubscription = floatify(payable - subscriptionItem.amount)

      if (maxCreditTotal > maxCreditTotalPayableForSubscription) {
        maxCreditTotal = maxCreditTotalPayableForSubscription
      }
    }

    return floatify(maxCreditTotal)
  },
)

export default getCreditPayableAmount

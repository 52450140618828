import React from 'react'
import SelectOnly from './SelectOnly'
import InputLabelWrap from './InputLabelWrap'

interface Props extends React.ComponentProps<typeof SelectOnly> {
  label?: React.ReactNode;
  optionalIndicatorEnabled?: boolean;
}

const Select = React.forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const {
    className,
    style,
    label,
    optionalIndicatorEnabled = true,
    ...selectProps
  } = props

  return (
    <InputLabelWrap
      label={label}
      className={className}
      style={style}
      id={selectProps.id}
      required={selectProps.required}
      optionalIndicatorEnabled={optionalIndicatorEnabled}
    >
      <SelectOnly {...selectProps} innerRef={ref} />
    </InputLabelWrap>
  )
})

export default Select

import React, { useCallback, useContext, useMemo } from 'react'
import Group from 'components/utils/Group'
import Pill from 'components/Luxkit/Pill'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import SecureWithDepositTagAndTooltip from 'components/Common/SecureWithDeposit/SecureWithDepositTagAndTooltip'
import FormatCurrency from 'components/Common/FormatCurrency'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import CruisesDiscountTsAndCsModal from 'components/Cruises/CruisesDiscountTsAndCsModal'
import ModalContext from 'contexts/ModalContext'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import TextButton from 'components/Luxkit/Button/TextButton'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'

interface Props {
  view: CruiseOfferView;
  isStandalone?: boolean;
  cabinCategoryFromFilter?: string;
}

function CruiseTilePrices(props: Props) {
  const {
    view,
    isStandalone,
    cabinCategoryFromFilter,
  } = props
  const {
    disableDeposits,
    promotionDetails,
    cabinCategory,
    lowestPrice,
    hasOnlySuitesAvailable,
    discountPills,
  } = view
  const showModal = useContext(ModalContext)
  const openDiscountTermsAndConditionsModal = useCallback(() => {
    showModal(<CruisesDiscountTsAndCsModal />)
  }, [showModal])

  const deposit = useMemo(() => (
    <>
      {promotionDetails?.deposit && (
        <Pill
            kind="tertiary"
            variant="primary"
            startIcon={<LineClockDollarIcon />}
          >
          Secure with a {promotionDetails.deposit.formattedValue} deposit
        </Pill>
      )}
      {!promotionDetails?.deposit && (
        <SecureWithDepositTagAndTooltip offerType="cruise" />
      )}
    </>
  )
  , [promotionDetails])

  return (
    <Group gap={8} direction="vertical" fullWidth noDisplayWhenEmpty>
      {isStandalone && !disableDeposits && deposit}
      {!isStandalone && !disableDeposits &&
        <CSSBreakpoint min="desktop">
          {deposit}
        </CSSBreakpoint>
      }
      <Group
        fullWidth
        gap={4}
        direction="horizontal"
        tabletDirection="vertical"
        horizontalAlign="space-between"
        tabletHorizontalAlign="start"
        verticalAlign="end"
        noDisplayWhenEmpty
      >
        {(isStandalone || cabinCategoryFromFilter || hasOnlySuitesAvailable) && <>
          <div>
            {cabinCategory && (
              <PriceRowPriceCaption>
                {cabinCategory} from
              </PriceRowPriceCaption>
            )}
            {lowestPrice && (
              <PriceRowPrice
                size="L"
                price={lowestPrice.primaryPrice.total}
                saleUnit={lowestPrice.primaryPrice.type}
              />
            )}
            {lowestPrice?.secondaryPrice && <PriceRowCaption>
              <FormatCurrency value={lowestPrice.secondaryPrice.total} /> /{lowestPrice.secondaryPrice.type} · Twin Share
            </PriceRowCaption>}
            {!lowestPrice?.secondaryPrice && discountPills?.price && discountPills.discountPercentage && <PriceRowValueDiscountWithCaption
              size="M"
              originalValue={discountPills.price}
              discountPercentage={discountPills.discountPercentage}
              onInfoIconClick={openDiscountTermsAndConditionsModal}
            />}
            {!lowestPrice?.secondaryPrice && <PriceRowCaption>Twin Share</PriceRowCaption>}
            <PriceRowTaxesAndFees />
          </div>
          <CSSBreakpoint max="mobile">
            <TextButton kind="primary">View</TextButton>
          </CSSBreakpoint>
        </>}
      </Group>
    </Group>
  )
}

export default CruiseTilePrices

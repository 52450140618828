import React from 'react'
import styled from 'styled-components'

const Diamond = styled.path`
  fill: ${props => props.theme.palette.product.ultralux.foreground};
`

function UltraLuxLogo({ height = 10, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height={height} {...rest} viewBox="0 0 561 51">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="561"
        height="51"
        fill="none"
      >
        <path
          fill="currentColor"
          d="M6.706 12.332v19.963c0 11.156 4.763 18.658 17.94 18.658 12.135 0 17.941-6.654 17.941-20.354V12.332c0-7.241.587-7.567 6.459-8.35V2.807H33.128v1.175c5.806.783 6.458 1.109 6.458 8.35v18.332c-.065 9.525-3.066 17.027-13.047 17.027-8.677 0-12.265-5.936-12.265-16.114V12.332c0-7.241.652-7.567 6.459-8.35V2.807H.248v1.175c5.806.783 6.458 1.174 6.458 8.35ZM68.659 50.105h21.855c3.653 0 9.72 0 14.156.065l2.479-14.809h-1.239c-3.914 11.026-7.372 12.46-19.18 12.526h-1.11c-2.478 0-2.87-.587-2.87-2.936V12.332c0-7.241.588-7.567 6.459-8.35V2.807h-20.55v1.175c5.871.783 6.459 1.109 6.459 8.35V40.58c0 7.242-.588 7.568-6.46 8.35v1.175ZM137.318 50.105h21.985v-1.24c-6.524-.587-7.176-1.63-7.176-8.285V4.83h1.957c9.72 0 12.787 2.022 16.44 12.786h1.174l-1.892-15.004c-4.958.195-10.633.195-14.678.195h-13.635c-4.11 0-9.786 0-14.744-.195l-1.892 15.004h1.24c3.588-10.764 6.654-12.786 16.375-12.786h2.022v35.75c0 6.655-.652 7.698-7.176 8.285v1.24ZM191.796 50.105h20.68v-1.174c-6.002-.783-6.589-1.11-6.589-8.285V27.727h5.741c1.174 1.631 2.479 4.11 4.893 7.764l3.392 5.284c2.023 3.197 4.306 7.176 5.35 9.329h13.308v-1.174c-3.849-.392-5.741-1.827-8.807-6.59l-9.786-15.265c7.177-1.305 12.2-4.697 12.2-11.873 0-9.786-9.199-12.46-20.485-12.46-2.609 0-8.089.064-12.004.064h-7.893v1.175c5.871.783 6.458 1.109 6.458 8.35V40.58c0 7.242-.587 7.568-6.458 8.35v1.175Zm31.966-34.902c0 8.415-4.632 10.112-12.591 10.112h-5.284V5.156h5.284c7.959 0 12.591 1.696 12.591 10.047ZM254.014 50.105h14.679v-1.174c-5.611-.522-6.654-1.305-1.957-13.309l.326-.913h18.984l3.588 9.133c1.305 3.327.261 4.567-4.436 5.089v1.174h19.115v-1.174c-4.241-.261-4.893-1.566-6.85-6.524L280.697.263h-.261c-.914 1.24-2.284 2.023-3.654 2.218l-14.026 35.36c-3.718 9.524-4.371 10.698-8.742 11.09v1.174Zm22.573-39.469 8.154 20.876h-16.374l8.22-20.876Z"
        />
        <Diamond
          d="m324.589 17.78 8.076-10.47h27.819l7.478 10.47-21.235 26.127-22.138-26.128Z"
        />
        <path
          fill="currentColor"
          d="M388.238 48.468h21.512c3.596 0 9.568 0 13.934.064l2.44-14.576h-1.22c-3.852 10.852-7.256 12.265-18.878 12.329h-1.092c-2.44 0-2.825-.578-2.825-2.89V11.288c0-7.127.577-7.448 6.357-8.219V1.913h-20.228V3.07c5.78.77 6.358 1.092 6.358 8.22v27.804c0 7.128-.578 7.449-6.358 8.22v1.155ZM449.685 11.289v19.649c0 10.98 4.687 18.365 17.658 18.365 11.944 0 17.659-6.55 17.659-20.035v-17.98c0-7.127.578-7.448 6.357-8.219V1.913h-15.668V3.07c5.715.77 6.357 1.092 6.357 8.22v18.044c-.064 9.375-3.018 16.76-12.842 16.76-8.541 0-12.073-5.844-12.073-15.862V11.29c0-7.128.643-7.45 6.358-8.22V1.913h-20.164V3.07c5.715.77 6.358 1.156 6.358 8.22ZM543.058 42.496c1.862 2.89 1.734 4.624-3.339 4.816v1.156h20.356v-1.156c-2.826-.064-6.293-1.862-9.119-6.228l-12.457-19.136 6.293-8.348c6.55-8.797 10.274-10.338 14.127-10.53V1.912h-16.632V3.07c4.495.385 6.037.9.129 8.797l-5.587 7.513-7.448-11.494c-1.927-2.89-1.798-4.623 3.339-4.816V1.913h-20.356V3.07c2.761.064 6.293 1.862 9.118 6.229l10.596 16.374-8.348 11.11c-6.55 8.732-10.274 10.338-14.127 10.53v1.156h16.631v-1.156c-4.495-.385-6.036-.899-.128-8.797l7.705-10.274 9.247 14.255Z"
        />
      </svg>
    </svg>
  )
}

export default UltraLuxLogo

import { useAppSelector } from 'hooks/reduxHooks'
import { getAvailableInsuranceBenefits } from 'luxPlus/selectors/benefits/insurance'

function useLuxPlusInsuranceAvailableCopy() {
  const view = useAppSelector(getAvailableInsuranceBenefits)
  if (view.isAvailable && !!view.totalMemberMaxSavings) {
    return `Save up to ${view.luxPlusDiscountPercentage}% on travel protection at checkout`
  }
}

export default useLuxPlusInsuranceAvailableCopy

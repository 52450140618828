import { sortBy } from 'lib/array/arrayUtils'
import { get } from 'lib/storage/versionedLocalStorageUtils'
import { BasicTrip } from 'tripPlanner/types/common'

const KEY = 'tpTripViewedTimes'
const VERSION = 1

interface ViewedTimes {
  [tripId: string]: number
}

/*
Storing the view times in localstorage is now deprecated in favour of the `interactedAt` property on trips.
That field is new, and has been initialised from the `createdAt` field, so initially it won't be as up-to-date
as the localstorage data. So for now we'll combine both sources of data. After some time has passed we can
remove this localstorage code and rely solely on `interactedAt`.
*/

export function getTripViewedTimes(): ViewedTimes {
  return get(KEY, VERSION) ?? {}
}

export function sortTripsByMostRecentlyViewedOrUpdated(
  trips: Array<BasicTrip>,
  viewedTimes: ViewedTimes,
): Array<BasicTrip> {
  const lastInteractionTime = (trip: BasicTrip) =>
    Math.max(trip.interactedAt.valueOf(), viewedTimes[trip.id] ?? 0)

  return sortBy(trips, lastInteractionTime, 'desc')
}

import LineCommentsAltIcon from 'components/Luxkit/Icons/line/LineCommentsAltIcon'
import LineFastMailIcon from 'components/Luxkit/Icons/line/LineFastMailIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import { TYPE_PAGE_LIMIT } from 'constants/search'
import { SORTBY_VERTICAL_CRUISE } from 'constants/offerList'
import config from './config'
import Places from './places'

export const CRUISE_LOCATION_SEARCH_ERROR_MESSAGE = config.searchFormSection?.escapeSynonym ?
`Check your spelling, try a new destination or place for your next ${config.searchFormSection.escapeSynonym}.` :
  'Check your spelling, try a new destination or place.'
export const CRUISE_LOCATION_SEARCH_ERROR_TOOLTIP_MESSAGE = 'Please enter a destination or place'
export const CRUISE_ARRIVAL_PLACE_SEARCH_PLACEHOLDER_LABEL = 'All destinations'
export const CRUISE_DEPARTURE_PLACE_SEARCH_PLACEHOLDER_LABEL = 'All departure ports'
export const CRUISE_CRUISE_LINES_SEARCH_PLACEHOLDER_LABEL = 'All cruise lines'
export const CRUISE_DEPARTURE_DATE_SEARCH_PLACEHOLDER_LABEL = 'Anytime'
export const CRUISE_LOCATION_SEARCH_TYPEAHEAD_TYPES: Array<App.SearchPlaceType> = [
  'city',
  'colloquial_area',
  'continent',
  'country',
  'high_level_region',
  'multi_city_vicinity',
  'province_state',
]
export const CRUISE_DEPARTURE_SEARCH_TYPEAHEAD_TYPES: Array<App.SearchPlaceType> = ['city']
export const CRUISE_ARRIVAL_PLACE_SEARCH_TITLE_LABEL = 'Destinations'
export const CRUISE_DEPARTURE_PLACE_SEARCH_TITLE_LABEL = 'Departure ports'
export const CRUISE_CRUISE_LINE_SEARCH_TITLE_LABEL = 'Cruise lines'
export const CRUISE_DEPARTURE_DATE_SEARCH_TITLE_LABEL = 'When'
export const CRUISE_DESTINATION_SEARCH_TYPES: Array<App.SearchType> = ['destination']
export const CRUISE_DEPARTURE_SEARCH_TYPES: Array<App.SearchType> = ['destination']
export const CRUISE_SEARCH_HERO_IMAGE_ID = 'cx0zqdkcwd7ok8d10ag'
export const CRUISE_OFFER_TYPES: Array<App.OfferType> = ['cruise']
export const CRUISE_SESSION_EXPIRATION_TIME_IN_MINUTES = 15
export const CRUISE_SEARCH_HERO_VIDEO_URL = 'https://player.vimeo.com/progressive_redirect/playback/796859202/rendition/1080p/file.mp4?loc=external&signature=140b579e292110b350a75b3a823ec915e644a3696c6229cb8d41347b6d8fd9c1'
export const CRUISE_SEARCH_HERO_BAR_DESTINATIONS = ['the Caribbean', 'Europe', 'the Mediterranean', 'Asia', 'Barcelona']

export enum CruiseSectionTabValues {
  DATES_PRICING = 'DATES_PRICING',
  ITINERARY = 'ITINERARY',
  ABOUT_SHIP = 'ABOUT_SHIP',
  FAQ = 'FAQ',
}

export enum CruiseContentSections {
  GALLERY = 'gallery',
  OVERVIEW = 'overview',
  ITINERARY = 'itinerary',
  ABOUT_THE_SHIP = 'about-the-ship',
  // INCLUSIONS = 'inclusions',
  DEPARTURES = 'dates-and-pricing',
  DECKS_AND_CABINS = 'decks-and-cabins',
  FINE_PRINT = 'things-to-know',
  FAQ = 'frequently-asked-questions',
}

export const PASSPORT_WARNING = 'Traveller details must match passport.'
export const PASSPORT_WARNING_TITLE = 'Please ensure accurate details are provided for each passenger.'

export const CRUISE_CONTENT_SECTION_LABELS: Record<Exclude<CruiseContentSections, CruiseContentSections.GALLERY>, string> = {
  [CruiseContentSections.OVERVIEW]: 'Overview',
  [CruiseContentSections.ITINERARY]: 'Itinerary',
  [CruiseContentSections.ABOUT_THE_SHIP]: 'About the Ship',
  // [CruiseContentSections.INCLUSIONS]: 'Highlights & inclusions',
  [CruiseContentSections.DEPARTURES]: 'Dates & Pricing',
  [CruiseContentSections.DECKS_AND_CABINS]: 'Decks & Cabin types',
  [CruiseContentSections.FINE_PRINT]: 'Things to know',
  [CruiseContentSections.FAQ]: 'Frequently Asked Questions',
}

export const CRUISE_OFFER_DUE_DATE_DAY_THRESHOLD = 90

export const CRUISE_CONTACT = [
  {
    title: 'Call us',
    text: 'Found a better price? Please contact us, we’ll match it!',
    subText: 'Call 1300 88 99 00',
    Icon: LinePhoneIcon,
    link: 'tel:1300889900',
  },
  {
    title: 'Email us',
    text: 'The Gift Card will be sent by email for you to print or forward to the lucky recipient.',
    subText: 'Send an email',
    Icon: LineFastMailIcon,
    link: 'mailto:sales@luxuryescapes.com',
  },
  {
    title: 'Chat with us',
    text: 'Need an answer right away? Chat live with a consultant now.',
    subText: 'Chat now',
    Icon: LineCommentsAltIcon,
    link: '/support',
  },
]

export const MONTHS = [
  { label: 'Jan', id: '01', name: 'January' },
  { label: 'Feb', id: '02', name: 'February' },
  { label: 'Mar', id: '03', name: 'March' },
  { label: 'Apr', id: '04', name: 'April' },
  { label: 'May', id: '05', name: 'May' },
  { label: 'Jun', id: '06', name: 'June' },
  { label: 'Jul', id: '07', name: 'July' },
  { label: 'Aug', id: '08', name: 'August' },
  { label: 'Sep', id: '09', name: 'September' },
  { label: 'Oct', id: '10', name: 'October' },
  { label: 'Nov', id: '11', name: 'November' },
  { label: 'Dec', id: '12', name: 'December' },
]

export const CABIN_CATEGORIES: Array<Cruises.CabinCategory> = [
  { category: 'Inside', filter: 'Lowest Inside Price', defaultImageId: '6cxshzr6hfv7a0p5v1a' },
  { category: 'Oceanview', filter: 'Lowest Oceanview Price', defaultImageId: 'k4k2dg9n17f9khvoj19' },
  { category: 'Balcony', filter: 'Lowest Balcony Price', defaultImageId: 'lx75o348zmq1w0tsz3nig' },
  { category: 'Suite', filter: 'Lowest Suite Price', defaultImageId: '812wqcsgf6ysfozdbngl' },
]

export const CABIN_CATEGORIES_SORT_ORDER = ['Inside', 'Balcony', 'Oceanview', 'Suite']

export const CRUISE_MOCK_PRICES = [
  {
    marketName: 'Australia',
    currencyCode: 'AUD',
    categoryCode: 'GS',
    rateCode: 'T',
    adultFare: 2609.32,
    nccf: 153.68,
    isNccfIncluded: false,
    tax: 162.89,
    isTaxIncluded: false,
    cabinCategory: 'Suite',
  },
  {
    marketName: 'Australia',
    currencyCode: 'AUD',
    categoryCode: 'OV',
    rateCode: 'T',
    adultFare: 1447.32,
    nccf: 153.68,
    isNccfIncluded: false,
    tax: 162.89,
    isTaxIncluded: false,
    cabinCategory: 'Oceanview',
  },
  {
    marketName: 'Australia',
    currencyCode: 'AUD',
    categoryCode: '4C',
    rateCode: 'PB4',
    adultFare: 1459.32,
    nccf: 153.68,
    isNccfIncluded: false,
    tax: 162.89,
    isTaxIncluded: false,
    cabinCategory: 'Balcony',
  },
  {
    marketName: 'Australia',
    currencyCode: 'AUD',
    categoryCode: '1A',
    rateCode: 'PB4',
    adultFare: 439.32,
    nccf: 153.68,
    isNccfIncluded: false,
    tax: 162.89,
    isTaxIncluded: false,
    cabinCategory: 'Inside',
    cabinPriceDesc: 'Lowest Inside Price',
  },
]

// TODO it will be replaced by API data
export const SEARCH_DEPARTURE_PORTS: Array<App.SearchItem> = [
  {
    searchType: 'destination',
    value: Places.Anywhere.id,
    format: {
      mainText: 'All departure ports',
    },
  },
  {
    searchType: 'departurePort',
    value: 'le_678c61ac5b4f91ca86e112fc50e63766',
    format: {
      mainText: 'Sydney',
    },
  },
  {
    searchType: 'departurePort',
    value: 'le_ce393994e8cf430867408678d1a5a9c8',
    format: {
      mainText: 'Singapore',
    },
  },
  {
    searchType: 'departurePort',
    value: 'le_af15ce7cbd279b1a769d85d1540a5073',
    format: {
      mainText: 'Barcelona',
    },
  },
  {
    searchType: 'departurePort',
    value: 'le_39c4c0a45681f21cead1b7004d1a67ce',
    format: {
      mainText: 'Seattle',
    },
  },
  {
    searchType: 'departurePort',
    value: 'le_342fdc93f26b8bb8fe3548a9188ea5d2',
    format: {
      mainText: 'Athens',
    },
  },
]

export const CruiseSearchFilterKeys = [
  'cruiseShips',
  'cabinTypes',
  'promotions',
  'isLuxExclusivePromotion',
  'isSpecialOffers',
  'isLuxPlus',
  'price.min',
  'price.max',
]

export const LANDING_PAGE_AVAILABLE = [
  'Royal Caribbean International',
  'Holland America Line',
  'Celebrity Cruises',
  'P&O Cruises Australia',
  'Silversea Cruises',
  'Princess Cruises',
  'MSC Cruises',
]

export const CRUISE_CHECKOUT_ERRORS = [
  'Invalid email',
]

export const CRUISE_LEGACY_FILTERS: App.OfferListFilters = {
  holidayTypes: ['Cruises'], // Only include offers that have Cruises holiday type
  offerTypes: ['tour', 'hotel'], // This is to include cruises that are set up as flash hotel offers (e.g. Disney Cruise)
}

export const CRUISE_UNIFIED_FILTERS: App.OfferListFilters = {
  holidayTypes: ['Cruises'],
  // for agent hub, we only support cruiseV1 for now
  offerTypes: config.agentHub?.isEnabled ? ['tour', 'hotel'] : ['tour', 'cruise', 'hotel'],
  sortBy: SORTBY_VERTICAL_CRUISE,
  limit: TYPE_PAGE_LIMIT,
}

export const CRUISE_BANK_OFFERS_FILTERS: App.OfferListFilters = {
  offerTypes: ['cruise'],
  sortBy: SORTBY_VERTICAL_CRUISE,
  limit: TYPE_PAGE_LIMIT,
}

export const CRUISE_FLASH_OFFERS_FILTERS: App.OfferListFilters = {
  holidayTypes: ['Cruises'],
  offerTypes: ['tour', 'hotel'],
  sortBy: SORTBY_VERTICAL_CRUISE,
  limit: TYPE_PAGE_LIMIT,
}

export const CRUISE_DESTINATION_PILLS = [
  { destinationName: 'Australia', destinationId: 'le_d3d9446802a44259755d38e6d163e820' },
  { destinationName: 'New Zealand', destinationId: 'le_9fc3d7152ba9336a670e36d0ed79bc43' },
  { destinationName: 'South Pacific', destinationId: 'le_a8697c7e6e7988b29a9de094b8d554cc' },
  { destinationName: 'Caribbean', destinationId: 'le_d7bce4d8dbac13945471c343c4973ba3' },
  { destinationName: 'Spain', destinationId: 'le_7e7757b1e12abcb736ab9a754ffb617a' },
  { destinationName: 'Greece', destinationId: 'le_735b90b4568125ed6c3f678819b6e058' },
  { destinationName: 'United States of America', urlName: 'United States of America', destinationId: 'le_757b505cfd34c64c85ca5b5690ee5293' },
]

export const CRUISE_LINE_CUSTOM_INFO = [
  { imageIdSm: 'en19l8ff1fhgec0k207', code: 'AZA' },
  { imageIdSm: 'x6b5w2dw9ihw8i5nu3kzt', code: 'CCL' },
  { imageIdSm: '29c4x6qlv72pdskxtuv', code: 'CEL' },
  { imageIdSm: 'fvzy8xe3zd1xtfiricim', code: 'CEX' },
  { imageIdSm: 'a4she3m3ztzpyvywg2un', code: 'CUN' },
  { imageIdSm: '0tewxh58wsfrur3gjj6', code: 'DCL' },
  { imageIdSm: 'bgrxlvu0d7eizy0cv06', code: 'EXJ' },
  { imageIdSm: '4s07r07b7tvwk8d8uav', code: 'HAL' },
  { imageIdSm: 'l3x4e3rmivugao9lp26m', code: 'MSC' },
  { imageIdSm: 'wyhk47lmzz096q98z6c', code: 'NCL' },
  { imageIdSm: 'tglbrhr4ue6puozki4k', code: 'OCL' },
  { imageIdSm: '4fp27ca9yvkuo9iajbc', code: 'PCL' },
  { imageIdSm: 'qjj296o519z21yc4o8yg', code: 'POA' },
  { imageIdSm: 'nenrq88hvwo2ouxdk8rf', code: 'RCC', name: 'Royal Caribbean' },
  { imageIdSm: 'lcygmrfi654a47ghsx47', code: 'RDC', name: 'Regent Seven Seas' },
  { imageIdSm: 'xhdkxgy1xlumco336f', code: 'SBN' },
  { imageIdSm: 'i0mabf1aai5pnyzmvn', code: 'VVG' },
  { imageIdSm: 'xm94y5k2rfnvovolvq3w', code: 'SRX' },
  // flash offer cruise lines
  { imageIdSm: 'nenrq88hvwo2ouxdk8rf', code: 'Royal Caribbean International', name: 'Royal Caribbean' },
  { imageIdSm: 'w1zodchyrgyd4ri92f3f', code: 'Riverside Luxury Cruises' },
  { imageIdSm: '29c4x6qlv72pdskxtuv', code: 'Celebrity Cruises' },
  { imageIdSm: '2nsxgnhd0b0bm1k12a1v', code: 'Coral Expeditions' },
  { imageIdSm: 'ejvx4zaw3n52xaxjwcan', code: 'Heritage Expeditions' },
]

export const SKIP_STEP_FOR_CABIN_TYPES = ['Suite']

import { get, remove } from 'lib/storage/isomorphicLocalStorage'

const ACCOUNT_ADD_MOBILE_PROMPTED = 'account.addMobilePrompted'

export const accountAddMobilePrompted = (): boolean => {
  return get(ACCOUNT_ADD_MOBILE_PROMPTED)
}

export const removeAddMobilePrompted = () => {
  remove(ACCOUNT_ADD_MOBILE_PROMPTED)
}

import styled from 'styled-components'
import { rem } from 'polished'
import { StyledGlobalSearchQuickFilterButtonsContainer } from './GlobalSearchQuickFilterButtonsContainer'

export const GlobalSearchQuickFiltersContainer = styled.section`
  > .quick-filters-title + ${StyledGlobalSearchQuickFilterButtonsContainer} {
    margin-top: ${rem(8)};
  }
`
export default GlobalSearchQuickFiltersContainer

import Modal from 'components/Luxkit/Modal/Modal'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TourV2Itinerary from 'components/OfferPage/TourV2/Itinerary/TourV2Itinerary'
import Group from 'components/utils/Group'
import ModalContext from 'contexts/ModalContext'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import { pluralizeToString } from 'lib/string/pluralize'
import { getTourV2VariationDuration } from 'lib/tours/tourUtils'
import React, { MouseEventHandler, useCallback, useContext, useMemo } from 'react'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary
  variation?: Tours.TourV2OfferVariation
  filters?: App.OfferListFilters
}

function TourV2SearchTileMeta({ offer, variation, filters }: Props) {
  const showModal = useContext(ModalContext)

  const offerUrl = useOfferUrl(offer, {
    filters,
    offerLinkIncludesFilters: true,
  })

  const list = useMemo<Array<string>>(() => {
    if (variation) {
      return [
        pluralizeToString('day', getTourV2VariationDuration(variation)),
        pluralizeToString('place', variation.placesVisited.length),
        pluralizeToString('country', variation.countriesVisited.length),
      ]
    }
    return []
  }, [variation])

  const openItineraryModal = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault()
    showModal(<Modal
      title="Trip itinerary"
      primaryActionText="View offer"
      primaryActionTo={offerUrl}
    >
      <TourV2Itinerary
        itinerary={variation!.itinerary}
        offerType={offer.type}
        showPrint
        showExperiences={false}
      />
    </Modal>)
  }, [showModal, variation, offer, offerUrl])

  return <div>
    <BodyText variant="medium" colour="neutral-two" weight="bold">{offer.operatedBy}</BodyText>
    {variation && <Group direction="horizontal" gap="4 8" wrap="wrap">
      <BodyText variant="medium">{list.join(' · ')}</BodyText>
      {!!variation.itinerary.length && <TextLink onClick={openItineraryModal} size="medium">View itinerary</TextLink>}
    </Group>}
  </div>
}

export default TourV2SearchTileMeta

import config from 'constants/config'
import getPaymentMethods from 'lib/payment/getPaymentMethods'
import {
  AFTER_PAY_BP_PAYMENT_TYPE,
  MAX_AFTER_PAY_BP_AUD_PAYMENT,
  MAX_AFTER_PAY_BP_NZD_PAYMENT,
  MAX_AFTER_PAY_BP_USD_PAYMENT,
} from 'constants/payment'

export const isAfterPayBpEnabled = (currency: string): boolean => {
  if (currency === 'NZD' && !config.AFTER_PAY_BP_NZ_ENABLED) {
    return false
  }
  if (currency === 'USD' && !config.AFTER_PAY_BP_US_ENABLED) {
    return false
  }
  return !!(config.AFTER_PAY_BP_ENABLED && getPaymentMethods(currency).includes(AFTER_PAY_BP_PAYMENT_TYPE))
}

export const isWithinAfterPayBpAvailabilityRange = (amount: number, currency:string): boolean => {
  switch (currency) {
    case 'NZD':
      return amount <= MAX_AFTER_PAY_BP_NZD_PAYMENT
    case 'USD':
      return amount <= MAX_AFTER_PAY_BP_USD_PAYMENT
    case 'AUD':
      return amount <= MAX_AFTER_PAY_BP_AUD_PAYMENT
    default:
      return false
  }
}

export const getAfterPayBpMaxLimit = (currency:string) :number => {
  switch (currency) {
    case 'NZD':
      return MAX_AFTER_PAY_BP_NZD_PAYMENT
    case 'USD':
      return MAX_AFTER_PAY_BP_USD_PAYMENT
    case 'AUD':
      return MAX_AFTER_PAY_BP_AUD_PAYMENT
    default:
      return 0
  }
}

import React from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  children: React.ReactNode
  weight?: 'bold' | 'normal'
  colour?: 'neutral-eight' | 'neutral-two'
}

function GlobalSearchQuickFiltersTitle(props: Props) {
  const { children, colour, weight } = props

  return <BodyText
    variant="medium"
    weight={weight}
    colour={colour}
    className="quick-filters-title"
  >
    {children}
  </BodyText>
}

export default GlobalSearchQuickFiltersTitle

import config from 'constants/config'
import { last } from 'lib/array/arrayUtils'
import { capitalise } from 'lib/string/stringUtils'

function flightDealDurationMap(duration: { departureDate: string, arrivalDate: string }): App.FlightDealTravelPeriod {
  return {
    departureDate: duration.departureDate,
    arrivalDate: duration.arrivalDate,
  }
}

function mapImageFromUrl(url: string = ''): App.Image | undefined {
  if (!url) {
    return undefined
  }

  if (url.startsWith(config.IMAGE_HOST)) {
    // it's an LE image, extract the ID
    return {
      id: last(url.split('/')).split('.')[0],
    }
  }

  return {
    url,
  }
}

const weekdayKeys = new Set(['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'])
/**
 * Maps the travel days

 * @param days
 * @returns
 */
function mapTravelDays(days: string | Array<string> | undefined): string | undefined {
  /**
    * Travel days can either be a list of 'full' days of the week or sometimes a single string like "Daily"
    * The front end actually wants the "short" version of the weekday name - so we need to map it
    * Sometimes it's a string, sometimes it's an array of weekdays, so we need to handle this
   */
  if (days) {
    if (typeof days === 'string') {
      const short = days.substring(0, 3)
      if (weekdayKeys.has(short)) {
        return `on ${capitalise(short)}`
      }
      return days
    }
    const allDays = days.map((day: string) => {
      const short = day.substring(0, 3)
      if (weekdayKeys.has(short)) {
        return capitalise(short)
      }
      return day
    }).join(', ')

    return `on ${allDays}`
  }
  return undefined
}

export function flightDealMap(deal): App.FlightDeal {
  return {
    id: deal.id,
    groupId: deal.categoryGroup,
    carrier: {
      logo: mapImageFromUrl(deal.carrierLogo),
      code: deal.carrierCode,
      name: deal.carrierName,
    },
    originAirportCode: deal.originAirportCode,
    originName: deal.originAirportName,
    destinationAirportCode: deal.destinationAirportCode,
    destinationName: deal.destinationAirportName,
    fareType: deal.fareType,
    fareClass: deal.fareClass?.toLowerCase(),
    travelPeriods: deal.offerDuration?.filter(duration => !!duration.departureDate && !!duration.arrivalDate).map(flightDealDurationMap) ?? [],
    salesStartDate: deal.salesStartDate,
    salesEndDate: deal.salesEndDate,
    perAdultPrice: Number(deal.perAdultPrice),
    discountLabel: deal.discountLabel,
    adultCount: deal.adultCount,
    dealName: deal.dealName,
    dealDescription: deal.dealDescription,
    heroImage: { id: deal.heroImagePublicId },
    termsAndConditions: deal.termsAndConditions || undefined,
    // we only display "short" day values but back end returns full days
    // so take first 3 chars
    travelDays: mapTravelDays(deal.travelDays),
    hierarchy: deal.hierarchy ?? undefined,
    destinationCountry: deal.destinationCountry,
    originCountry: deal.originCountry,
  }
}

import LineTagAltIcon from 'components/Luxkit/Icons/line/LineTagAltIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import FormatPercent from 'components/utils/Formatters/FormatPercent'
import React from 'react'

interface Props {
  /**
   * 0-1
   *
   * @example 0.2 => 20%
   */
  discountPercentage?: number
}

function SearchTileWalledMessage({ discountPercentage }: Props) {
  return <BodyTextBlock variant="medium" weight="bold" colour="neutral-two" startIcon={<LineTagAltIcon />}>
    {!!discountPercentage && <>Get <FormatPercent value={discountPercentage} /> off with member prices</>}
    {!discountPercentage && 'Sign up to access exclusive rates'}
  </BodyTextBlock>
}

export default SearchTileWalledMessage

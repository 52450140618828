// Adapted from lodash memoize.js
/**
 * Creates a function that memoizes the result of `func`. If `resolver` is
 * provided, it determines the cache key for storing the result based on the
 * arguments provided to the memoized function. By default, the first argument
 * provided to the memoized function is used as the map cache key. The `func`
 * is invoked with the `this` binding of the memoized function.
 *
 * **Note:** The cache is exposed as the `cache` property on the memoized
 * function. Its creation may be customized by replacing the `memoize.Cache`
 * constructor with one whose instances implement the
 * [`Map`](http://ecma-international.org/ecma-262/7.0/#sec-properties-of-the-map-prototype-object)
 * method interface of `clear`, `delete`, `get`, `has`, and `set`.
 *
 * @param {Function} func The function to have its output memoized.
 * @param {Function} [keyResolver] The function to resolve the cache key.
 * @returns {Function} Returns the new memoized function.
 * @example
 *
 * const object = { 'a': 1, 'b': 2 }
 * const other = { 'c': 3, 'd': 4 }
 *
 * const values = memoize(values)
 * values(object)
 * // => [1, 2]
 *
 * values(other)
 * // => [3, 4]
 *
 * object.a = 2
 * values(object)
 * // => [1, 2]
 *
 * // Modify the result cache.
 * values.cache.set(object, ['a', 'b'])
 * values(object)
 * // => ['a', 'b']
 *
 */
// Overload for functions without a key resolver
function memoize<T extends (arg: any) => any>(func: T): T;
// Overload for functions with a key resolver
function memoize<K, T extends (...args: Array<any>) => any>(
  func: T,
  keyResolver: (...args: Parameters<T>) => K
): T;

// Generic implementation
function memoize<K, T extends(...args: Array<any>) => any>(
  func: T,
  keyResolver?: (...args: Parameters<T>) => K,
): T {
  const memoized: any = function(...args: Parameters<T>): ReturnType<T> {
    const realKey: K = keyResolver ? keyResolver(...args) : args[0]
    if (!memoized.cache) {
      const isKeyObject = typeof realKey === 'object' || typeof realKey === 'function'
      if (isKeyObject) {
        // we know it's an object at this point, ts can't pick it up, so ignore
        // @ts-ignore
        memoized.cache = new WeakMap<K, ReturnType<T>>()
      } else {
        // we'd rather use a weakmap where we can, but we can't always.
        // weakmap only supports objects.
        memoized.cache = new Map<K, ReturnType<T>>()
      }
    }

    const cache = memoized.cache

    if (cache.has(realKey)) {
      return cache.get(realKey)
    }

    const result = func(...args)
    if (!IS_SSR) {
      // we dont need memoisation cache on the server, too dangerous on memory
      // do don't ever set any cache values if we're on the server
      cache.set(realKey, result)
    }
    return result
  }
  return memoized
}

export default memoize

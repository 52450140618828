import fetchBusinessEmployees from 'actions/businessTraveller/fetchBusinessEmployees'
import config from 'constants/config'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'

function useBusinessTravellerEmployeeQuery() {
  const appDispatch = useAppDispatch()
  const employees = useAppSelector(state => state.businessTraveller.employees?.all)
  const businessId = useAppSelector(state => state.businessTraveller.currentBusinessAccount?.business?.id)

  useEffect(() => {
    if (config.businessTraveller.currentAccountMode === 'business' && businessId) {
      appDispatch(fetchBusinessEmployees(businessId))
    }
  }, [appDispatch, businessId])

  return { employees }
}

export default useBusinessTravellerEmployeeQuery

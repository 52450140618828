import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineRoomUpgradeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 1.9958 7.7929 6.203c-.3905.3905-.3905 1.0236 0 1.4142.3905.3905 1.0237.3905 1.4142 0L11 5.8242v2.1759c0 .5523.4477 1 1 1s1-.4477 1-1V5.8243l1.7929 1.7929c.3905.3905 1.0237.3905 1.4142 0 .3905-.3906.3905-1.0237 0-1.4142L12 1.9958Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M6 10.5001c-1.6569 0-3 1.3431-3 3v4.2639c-.6138.5493-1 1.3476-1 2.2361v1c0 .5522.4477 1 1 1s1-.4478 1-1v-1c0-.5523.4477-1 1-1h14c.5523 0 1 .4477 1 1v1c0 .5522.4477 1 1 1s1-.4478 1-1v-1c0-.8885-.3863-1.6868-1-2.2361v-4.2639c0-1.6569-1.3431-3-3-3H6Zm13 3.6707v-.6707c0-.5523-.4477-1-1-1H6c-.5523 0-1 .4477-1 1v.6707a2.994 2.994 0 0 1 1-.1707h3c1.6569 0 3 1.3431 3 3 0-1.6569 1.3431-3 3-3h3c.3506 0 .6872.0601 1 .1707Zm-5 2.8293h5c0-.5523-.4477-1-1-1h-3c-.5523 0-1 .4477-1 1Zm-4 0H5c0-.5523.4477-1 1-1h3c.5523 0 1 .4477 1 1Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineRoomUpgradeIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineIronIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7.5 8.06c-.5523 0-1 .4556-1 1.0176v.4579c0 .562.4477 1.0176 1 1.0176s1-.4556 1-1.0176v-.458c0-.562-.4477-1.0175-1-1.0175ZM12.1016 9.0776c0-.562-.4478-1.0176-1-1.0176-.5523 0-1 .4556-1 1.0176v.4579c0 .562.4477 1.0176 1 1.0176.5522 0 1-.4556 1-1.0176v-.458ZM5.7031 15.386c-.5523 0-1 .4556-1 1.0176v.4579c0 .562.4477 1.0176 1 1.0176s1-.4556 1-1.0176v-.4579c0-.562-.4477-1.0176-1-1.0176ZM4.7031 12.7386c0-.562.4477-1.0176 1-1.0176s1 .4556 1 1.0176v.4579c0 .562-.4477 1.0176-1 1.0176s-1-.4556-1-1.0176v-.4579ZM12.8984 11.721c.5523 0 1 .4556 1 1.0176v.4579c0 .562-.4477 1.0176-1 1.0176-.5522 0-1-.4556-1-1.0176v-.4579c0-.562.4478-1.0176 1-1.0176ZM13.8984 16.4036c0-.562-.4477-1.0176-1-1.0176-.5522 0-1 .4556-1 1.0176v.4579c0 .562.4478 1.0176 1 1.0176.5523 0 1-.4556 1-1.0176v-.4579Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M10.6481 2.5644c-.752-.7525-1.9442-.7526-2.6961 0-.7107.7113-1.8877 1.9596-2.9923 3.456C3.8673 7.5 2.7833 9.305 2.3464 11.1244c-.3095 1.2888-.3676 3.1017-.3405 4.7571.0276 1.6837.1465 3.3124.2303 4.2842.091 1.0562.963 1.8343 1.978 1.8343H14.386c1.0151 0 1.8869-.7781 1.978-1.8343a70.35 70.35 0 0 0 .1334-1.8287h.0497c1.6569 0 3-1.3668 3-3.0528V9.2803c0-.562.4477-1.0176 1-1.0176h.4546c.5522 0 1-.4556 1-1.0176s-.4478-1.0176-1-1.0176h-.4546c-1.6568 0-3 1.3668-3 3.0528v6.0039c0 .5489-.4271.9963-.9616 1.0169a48.016 48.016 0 0 0 .0088-.4196c.0271-1.6554-.031-3.4683-.3405-4.7571-.4369-1.8194-1.5209-3.6243-2.6133-5.104-1.1046-1.4964-2.2817-2.7447-2.9923-3.456ZM6.5588 7.2428C7.5548 5.8934 8.625 4.749 9.3 4.07c.675.6791 1.7453 1.8235 2.7413 3.1727 1.0355 1.4027 1.9285 2.9446 2.2696 4.3648.2431 1.0126.3105 2.5953.2835 4.2401-.0262 1.6034-.1393 3.167-.2209 4.1172H4.2265c-.0815-.9502-.1946-2.5138-.2208-4.1172-.027-1.6448.0404-3.2275.2835-4.2401.3411-1.4202 1.2341-2.962 2.2696-4.3647Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineIronIcon

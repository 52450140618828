import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCoffeeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9 17h4a4.9997 4.9997 0 0 0 5-5v-1h1a3 3 0 0 0 0-6h-1V4a1.0002 1.0002 0 0 0-1-1H5a1 1 0 0 0-1 1v8a4.9997 4.9997 0 0 0 5 5Zm9-10h1a1 1 0 1 1 0 2h-1V7ZM6 5h10v7a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5Zm15 14H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 20.8946 2.7348 21 3 21h18a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineCoffeeIcon

import config from 'constants/config'
import { deepOmitKeys, mapObject } from 'lib/object/objectUtils'

let sentry
export function initSentryServerSide(Sentry) {
  sentry = Sentry
  sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ID,
    environment: process.env.REACT_APP_SENTRY_ENV,
  })
}

export function initSentryTestSide(Sentry) {
  sentry = Sentry
  sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ID,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: `CI-${process.env.CIRCLE_BRANCH}-${process.env.CIRCLE_SHA1}`,
  })
}

export function initSentryClientSide(Sentry) {
  sentry = Sentry
  sentry.init({
    dsn: config.SENTRY_ID,
    environment: config.SENTRY_ENV,
    release: config.commitSha || 'unknown',
    integrations: [Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['luxgroup-customer-portal'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    })],
  })
}

// Shortest string that could be contained in props that would be PII
// eg `email`, `first_name` would all be matched by these
const piiKeys = [
  'mail',
  'name',
  'phone',
  'number',
  'post_code',
  'birth',
  'gender',
  'title',
  'signature',
]
export async function sendErrorToSentry(error, user?: App.AuthAccount, extras?: any) {
  try {
    // We also need to remove any PII from the extras to avoid the whole data being hidden on Sentry
    const obfuscatedExtras = deepOmitKeys(extras || {}, piiKeys)
    const stringifiedObfuscatedExtras = mapObject(obfuscatedExtras, (extra) => {
      if (typeof extra !== 'object' && !Array.isArray(extra)) return extra
      return JSON.stringify(extra, null, 2)
    })

    if (user?.memberId) {
      sentry.setUser({ id: user.memberId })
    }

    sentry.withScope((scope) => {
      const exception = error instanceof Error ? error : new Error(JSON.stringify(error))
      scope.setFingerprint(exception.message)
      scope.setExtras(stringifiedObfuscatedExtras)
      sentry.captureException(exception)
    })
  } catch {}
}

import React from 'react'
import Clickable from '../Clickable'
import useToggle from 'hooks/useToggle'
import ReserveForZeroPolicyModal from './ReserveForZeroPolicyModal'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import Pill from 'components/Luxkit/Pill'

function ReserveForZeroTag() {
  const [isOpen, , onOpen, onClose] = useToggle()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onOpen()
  }

  return <>
    <Clickable onClick={handleClick}>
      <Pill kind="tertiary" variant="primary" startIcon={<LineClockDollarIcon />}>
        Reserve now for free
      </Pill>
    </Clickable>
    <ReserveForZeroPolicyModal
      isOpen={isOpen}
      onClose={onClose}
    />
  </>
}

export default ReserveForZeroTag

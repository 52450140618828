import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSearchIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.71 20.29 18 16.61A9 9 0 1 0 16.61 18l3.68 3.68a.9987.9987 0 0 0 .71.2958.9997.9997 0 0 0 .71-.2958 1 1 0 0 0 0-1.39ZM11 18a6.9998 6.9998 0 0 1-6.8655-8.3656A7 7 0 1 1 11 18Z"/>
  </SvgIcon>
}

export default LineSearchIcon

import React from 'react'
import styled, { css } from 'styled-components'
import Typography, { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from './Typography'
import cn from 'clsx'

export const InputLabel = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 20px;
  font-size: 14px;
  font-weight: ${props => props.theme.font.primary.weight.superBold};
  color: ${props => props.theme.palette.neutral.default.one};
`

export const InputSubLabel = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 20px;
  font-size: 14px;
  font-weight: ${props => props.theme.font.primary.weight.regular};
  color: ${props => props.theme.palette.neutral.default.three};
`

export const InputTextBase = css`
  line-height: 20px;
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 20px;
  font-size: 14px;
`

export const InputCaption = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 14px;
  font-size: 12px;
`

const TextElement = styled(Typography)`
  &.variant-label {
    ${InputLabel}
  }

  &.variant-sub-label {
    ${InputSubLabel}
  }

  &.variant-caption {
    ${InputCaption}
  }

  &.variant-text-regular {
    ${InputTextBase}
    font-weight: ${props => props.theme.font.primary.weight.regular};
  }

  &.variant-text-bold {
    ${InputTextBase}
    font-weight: ${props => props.theme.font.primary.weight.bold};
  }

  &.colour-critical {
    color: ${props => props.theme.palette.messaging.critical.normalForeground};
  }

  &.colour-neutral-one {
    color: ${props => props.theme.palette.neutral.default.one};
  }

  &.colour-neutral-two {
    color: ${props => props.theme.palette.neutral.default.two};
  }

  &.colour-neutral-four {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &.colour-neutral-three {
    color: ${props => props.theme.palette.neutral.default.three};
  }
`

interface Props extends Omit<React.ComponentProps<typeof Typography>, 'forwardedAs'> {
  variant: 'label' | 'sub-label' | 'text-regular' | 'text-bold' |'caption';
  colour?: 'neutral-one' | 'neutral-two' | 'neutral-three' | 'neutral-four' | 'critical';
}

function InputText(props: Props) {
  const {
    className,
    variant,
    colour,
    as,
    ...typographyProps
  } = props

  return <TextElement
    {...typographyProps}
    forwardedAs={as}
    className={cn(
      className,
      `variant-${variant}`,
      colour ? `colour-${colour}` : undefined,
    )}
  />
}

export default InputText

import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const ImageLoadingBox = styled(LoadingBox)`
  height: ${rem(200)};
`

const LoaderContainer = styled.div`
  width: 75vw;
  min-height: ${rem(520)};
  display: grid;
  grid-template-rows: ${rem(200)} 1fr;

  ${mediaQueryUp.tablet} {
    width: ${rem(320)};
  }
`

const TagsLoadingBox = styled(LoadingBox)`
  height: ${rem(18)};
  width: ${rem(143)};
  margin-top: ${rem(20)};
`

const ReviewsLoadingBox = styled(LoadingBox)`
  height: ${rem(16)};
  width: ${rem(164)};
`

const PricingLoadingBoxTop = styled(LoadingBox)`
  height: ${rem(18)};
  width: ${rem(132)};
`

const PricingLoadingBoxBottom = styled(LoadingBox)`
  height: ${rem(18)};
  width: ${rem(128)};
  margin-top: ${rem(4)};
`

const ViewButtonLoadingBox = styled(LoadingBox)`
  height: ${rem(40)};
  width: ${rem(80)};
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function CarouselCardSmallLoadingSkeleton(props: Props) {
  return (
    <LoaderContainer {...props}>
      <ImageLoadingBox />
      <Group direction="vertical" verticalAlign="space-between" gap={16}>
        <div>
          <Group direction="vertical" gap={4}>
            <TagsLoadingBox />
            <TextLoadingBox typography="caption-large" width="100%" />
          </Group>
          <TextLoadingBox typography="heading6" width="100%" />
          <TextLoadingBox typography="caption-small" width={80} />
          <Group direction="vertical" gap={16}>
            <ReviewsLoadingBox />
            <TextLoadingBox typography="caption-large" width={32} />
          </Group>
          <TextLoadingBox typography="heading4" width={95} />
        </div>
        <Group direction="horizontal" horizontalAlign="space-between">
          <div>
            <PricingLoadingBoxTop/>
            <PricingLoadingBoxBottom/>
          </div>
          <ViewButtonLoadingBox/>
        </Group>
      </Group>
    </LoaderContainer>
  )
}

export default React.memo(CarouselCardSmallLoadingSkeleton)

import { createMiddleware } from 'redux-beacon'
import { LOCATION_CHANGE } from 'connected-react-router'
import {
  API_CALL_SUCCESS,
  API_CALL_REQUEST,
  CHECKOUT_PROCESS_CANCEL, GENESYS_CUSTOM_EVENT, GENESYS_SEARCH_EVENT,
  OPEN_CHAT_WINDOW,
} from 'actions/actionConstants'
import { USER_LOGIN, USER_REGISTRATION, USER_LOGOUT } from 'actions/apiActionConstants'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import * as isomorphicLocalStorage from 'lib/storage/isomorphicLocalStorage'
import config from 'constants/config'
import getCreditPayAmount from 'checkout/selectors/payment/getCreditPayAmount'
import getCheckoutTotal from 'checkout/selectors/payment/getCheckoutTotal'
import { AnyAction } from 'redux'

interface GenesisEvent {
  type: string;
  [key: string]: any;
}

export const removedGenesysFromStorage = () => {
  if (isomorphicLocalStorage.hasKeyWithSubstring(':gcmcsessionActive')) {
    isomorphicLocalStorage.removeItemsWithSubstringInKey(':gcm')
    return true
  }
}

const pageView = (action: AnyAction, prevState: App.State, nextState: App.State): GenesisEvent => {
  const { pathname } = nextState.router.location
  const searchData = parseSearchString(nextState.router.location.search)
  const grandTotal = getCheckoutTotal(prevState)
  const creditPaymentAmount = getCreditPayAmount(prevState)

  return {
    path: pathname,
    customerEmail: nextState.auth.account?.email || null,
    searchData,
    grandTotal,
    creditPaymentAmount,
    type: 'pageView',
  }
}

const genesysCustomEvent = (action: AnyAction, prevState: App.State, nextState: App.State): GenesisEvent | undefined => {
  switch (action.data?.eventType) {
    case CHECKOUT_PROCESS_CANCEL:
      return {
        path: nextState.router.location.pathname,
        customerEmail: nextState.auth.account?.email || null,
        type: 'exitPayment',
      }
    case GENESYS_SEARCH_EVENT:
      const {
        searchItem = {},
        occupancies = [{ adults: 2 }],
        trackableSearchResults = [],
        checkinDate = 'none',
        checkoutDate = 'none',
        searchTargetLabel = 'none',
      } = action.data

      const { mainText: propertyName = 'none', secondaryText: landmarkName = 'none' } = searchItem
      const { adults = 'none', children = 'none' } = occupancies?.[0] || {}

      const type = trackableSearchResults.length ? 'formSubmitted' : 'noResults'

      const eventPayload = {
        type,
        destinationName: searchTargetLabel,
        propertyName,
        landmarkName,
        checkIn: checkinDate,
        checkOut: checkoutDate,
        adults,
        children,
      }

      return eventPayload
    case OPEN_CHAT_WINDOW:
      return {
        type: 'openChatWindow',
        firstName: nextState.auth.account?.givenName || null,
        lastName: nextState.auth.account?.surname || null,
        customerEmail: nextState.auth.account?.email || null,
        brand: config.BRAND,
        currentPage: nextState.router.location.pathname,
      }
    default:
      console.error(`Unknown custom Genesys eventType ${action.data?.eventType}`)
      break
  }
}

const loginSuccess = (action: AnyAction, prevState: App.State, nextState: App.State): GenesisEvent | null => {
  if (!prevState.auth.source) {
    return null
  }

  return {
    type: 'login',
    customerEmail: nextState.auth.account?.email,
    givenName: nextState.auth.account?.givenName,
    familyName: nextState.auth.account?.surname,
  }
}

function signupSuccess(action: AnyAction, prevState: App.State, nextState: App.State): GenesisEvent {
  return {
    type: 'signup',
    customerEmail: nextState.auth.account.email,
  }
}

function userLogout(): GenesisEvent {
  return {
    type: 'logout',
    customerEmail: null,
  }
}

type EventFunc = (action: AnyAction, prevState: App.State, nextState: App.State) => any

const apiSuccessesMap: Record<string, EventFunc> = {
  [USER_LOGIN]: loginSuccess,
  [USER_REGISTRATION]: signupSuccess,
}

const apiRequestMap: Record<string, EventFunc> = {
  [USER_LOGOUT]: userLogout,
}

const apiSuccessEvents = (action: AnyAction, prevState: App.State, nextState: App.State) => apiSuccessesMap[action.api]?.(action, prevState, nextState)
const apiRequestEvents = (action: AnyAction, prevState: App.State, nextState: App.State) => apiRequestMap[action.api]?.(action, prevState, nextState)

const eventsMap = {
  [LOCATION_CHANGE]: pageView,
  [API_CALL_SUCCESS]: apiSuccessEvents,
  [API_CALL_REQUEST]: apiRequestEvents,
  [GENESYS_CUSTOM_EVENT]: genesysCustomEvent,
  [GENESYS_SEARCH_EVENT]: genesysCustomEvent,
}

const target = (events: Array<any>) => {
  // @ts-ignore
  if (!window.Genesys) return

  events.forEach((evt) => {
    switch (evt.type) {
      case 'pageView':
        // @ts-ignore
        window.Genesys('command', 'Database.update', {
          messaging: {
            customAttributes: {
              currentPage: evt.path,
              customerEmail: evt.customerEmail,
            },
          },
        })

        if (evt.path.includes('/search')) {
          // @ts-ignore
          window.Genesys('command', 'Journey.record', {
            eventName: 'search',
            customAttributes: {
              ...evt.searchData,
            },
          })
        }

        if (evt.path.includes('/payment')) {
          // @ts-ignore
          window.Genesys('command', 'Journey.record', {
            eventName: 'payment_page',
            customAttributes: {
              totalValue: evt.grandTotal + evt.creditPaymentAmount,
            },
          })
        }
        break
      case 'login':
        // @ts-ignore
        window.Genesys('command', 'Journey.pageview', {
          customAttributes: {
            email: evt.customerEmail,
            givenName: evt.givenName,
            familyName: evt.familyName,
          },
          traitsMapper: [
            { fieldName: 'email' },
            { fieldName: 'givenName' },
            { fieldName: 'familyName' },
          ],
        })
        // @ts-ignore
        window.Genesys('command', 'Journey.pageview')
        break
      case 'signup':
      case 'logout':
        // @ts-ignore
        window.Genesys('command', 'Database.update', {
          messaging: {
            customAttributes: {
              customerEmail: evt.customerEmail,
            },
          },
        })
        break
      case 'exitPayment':
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'product_removed',
          customAttributes: {
            removed: 'All',
          },
        })
        break
      case 'enterPaymentPage':
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'payment_page',
          customAttributes: {
            totalValue: evt.totalValue,
          },
        })
        break
      case 'addToCart':
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'product_added',
          customAttributes: {
            currencyCode: evt.currencyCode,
            value: evt.value,
            name: evt.name,
          },
        })
        break
      case 'paymentAttempt':
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'payment_attempt',
          customAttributes: {
            currencyCode: evt.currencyCode,
            value: evt.value,
          },
        })
        break
      case 'paymentFailed':
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'payment_failed',
        })
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'Failed_Payment',
          customAttributes: {
            failed: 'true',
          },
        })
        break
      case 'formSubmitted':
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'form_submitted',
          customAttributes: {
            ...evt,
          },
        })
        break
      case 'noResults':
        // @ts-ignore
        window.Genesys('command', 'Journey.record', {
          eventName: 'No_Results',
          customAttributes: {
            result: evt.destinationName,
          },
        })
        break
      case 'openChatWindow':
        // @ts-ignore
        window.Genesys('command', 'Database.set', {
          messaging: {
            customAttributes: { ...evt },
          },
        })
    }
  })
}

export const createGenesysMiddleware = () => createMiddleware(eventsMap, target)

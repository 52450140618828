import React, { ComponentProps, PropsWithChildren } from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import AssistChip, { ASSIST_CHIP_HEIGHTS } from './AssistChip'

const ChipBox = styled(LoadingBox)`
  border-radius: ${props => props.theme.borderRadius.round};

  &.size-small {
    height: ${rem(ASSIST_CHIP_HEIGHTS.small)};
  }

  &.size-medium {
    height: ${rem(ASSIST_CHIP_HEIGHTS.medium)};
  }

  &.size-large {
    height: ${rem(ASSIST_CHIP_HEIGHTS.large)};
  }
`

type Size = ComponentProps<typeof AssistChip>['size']

interface Props {
  size: Size
  width: number
}

function AssistChipLoadingBox(props: PropsWithChildren<Props>) {
  const { size, width } = props

  return <ChipBox
    className={cn(`size-${size}`)}
    style={{ width: rem(width) }}
  />
}

export default AssistChipLoadingBox

import { SizeOptions } from '@floating-ui/dom'
import { autoUpdate, flip, offset, shift, size, useFloating } from '@floating-ui/react-dom'
import cn from 'clsx'
import { useIsInModal } from 'contexts/ModalProvider'
import useClickOutside from 'hooks/useClickOutside'
import { themeClassName } from 'lib/theme/themeUtils'
import React, { MouseEvent, MouseEventHandler, PropsWithChildren, RefObject, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { createPortal } from 'react-dom'
import { shallowEqual } from 'react-redux'
import styled from 'styled-components'
import zIndex from 'styles/tools/z-index'

const DropdownWrapper = styled.div`
  position: absolute;
  z-index: ${zIndex.menu};
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 200ms ease-out, visibility 0s 200ms;

  &.isPortaledFromModal {
    // In this case the dropdown is outside the stacking context of the modal,
    // so we need a higher z-index so it will still appear above thee modal.
    z-index: ${zIndex.menuInModal};
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: opacity 200ms ease-in, visibility 0s;
  }
`

const applyDynamicWidth: SizeOptions['apply'] = ({ elements, rects }) => {
  Object.assign(elements.floating.style, {
    width: `${rects.reference.width}px`,
  })
}

const applyAvailableWidth: SizeOptions['apply'] = ({ availableWidth, elements }) => {
  Object.assign(elements.floating.style, {
    maxWidth: `${Math.max(availableWidth, 0)}px`,
  })
}

export type _FloatingDropdownCloseHandler = (event: MouseEvent<HTMLElement> | KeyboardEvent, reason: 'outside-click' | 'dismiss-click' | 'internal-click' | 'escape-key') => void | (() => void)

interface Props extends PropsWithChildren {
  /**
   * Determines where the Dropdown sits relative to the anchor.
   *
   * @default bottom
   */
  placement?: 'top' | 'top-start' | 'top-end' | 'right' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left'
  /**
   * Reference to the element to which the dropdown would attach
   */
  anchorRef: RefObject<HTMLElement>
  /**
   * Reference to the element that's triggering the dropdown.
   *
   * While clicking outside of the dropdown closes it,
   * this element will not be considered as outside element.
   */
  triggerRef: RefObject<HTMLElement> | Array<RefObject<HTMLElement>>
  /**
   * Reference to the element within which the dropdown is bounded.
   */
  boundaryRef?: RefObject<HTMLElement>
  open: boolean;
  onClose?: _FloatingDropdownCloseHandler
  /**
   * Space between the anchor and the floating dropdown
   *
   * @default 8
   */
  anchorOffset?: number
  disableFlip?: boolean
  crossAxisShift?: boolean
  width: 'auto' | 'match-anchor'
  /**
   * Mounts the dropdown via a portal to ignore clipping boundaries.
   */
  portaled?: boolean
  'data-testid'?: string
  id?: string
}

/**
 * The base floating behaviour of a dropdown.
 */
const _FloatingDropdown = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    placement = 'bottom',
    anchorRef,
    boundaryRef,
    triggerRef,
    open = false,
    onClose,
    children,
    anchorOffset = 8,
    disableFlip,
    crossAxisShift = false,
    width,
    portaled,
    'data-testid': dataTestId,
    id,
  } = props

  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const float = useFloating({
    placement,
    strategy: 'fixed',
    middleware: [
      offset({ mainAxis: anchorOffset }),
      ...(!disableFlip ? [flip()] : []),
      shift({
        boundary: boundaryRef?.current ?? undefined,
        crossAxis: crossAxisShift,
        padding: 8,
      }),
      size({
        apply: width === 'match-anchor' ? applyDynamicWidth : applyAvailableWidth,
        padding: 20,
      }),
    ],
  })

  useEffect(() => {
    if (open && anchorRef.current && dropdownRef.current) {
      return autoUpdate(anchorRef.current, dropdownRef.current, float.update)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (anchorRef.current) {
      float.refs.setReference(anchorRef.current)
    }
  }, [float.refs.setReference, anchorRef, float.refs])

  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose?.(event, 'escape-key')
    }
  }, [onClose])

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', keyDownHandler)
    } else {
      window.removeEventListener('keydown', keyDownHandler)
    }

    return () => window.removeEventListener('keydown', keyDownHandler)
  }, [open, keyDownHandler])

  const handleOutsideClick = useCallback<MouseEventHandler<HTMLElement>>((event) => {
    // create a truthy array of trigger elements
    const triggerElements = (Array.isArray(triggerRef) ? triggerRef.map(r => r.current) : [triggerRef?.current]).filter(Boolean)

    if (
      triggerElements.every(element =>
        !element!.contains(event.target as Node) &&
        !element!.contains(document.activeElement),
      )
    ) {
      onClose?.(event, 'outside-click')
    }
  }, [onClose, triggerRef])

  // @ts-expect-error need to use React types in the hook
  useClickOutside(dropdownRef, handleOutsideClick, open)

  useImperativeHandle(ref, () => dropdownRef.current!)

  const isInModal = useIsInModal()

  const element = <DropdownWrapper
    id={id}
    ref={(element) => {
      dropdownRef.current = element
      float.refs.setFloating(element)
    }}
    style={float.floatingStyles}
    className={cn(
      themeClassName('default'),
      { visible: open, isPortaledFromModal: isInModal && portaled },
    )}
    data-testid={dataTestId}
  >
    {children}
  </DropdownWrapper>

  if (portaled) {
    if (typeof document !== 'undefined') {
      return createPortal(element, document.body)
    } else {
      return null
    }
  }

  return element
})

_FloatingDropdown.displayName = 'BaseFloatingDropdown'

export default memo(_FloatingDropdown, (prevProps, nextProps) => {
  if (!prevProps.open && !nextProps.open) {
    return true
  }
  return shallowEqual(prevProps, nextProps)
})

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePlusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 11h-6V5a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 5v6H5a1.0002 1.0002 0 0 0-.7071 1.7071C4.4804 12.8946 4.7348 13 5 13h6v6a1.0001 1.0001 0 0 0 2 0v-6h6a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LinePlusIcon

import CounterInput from 'components/Common/Form/Input/CounterInput'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { formatTransferTravellers, getValidTransferOptions } from './ExperienceTransferUtils'

const TabElement = styled.div`
  display: block;
  opacity: 1;
  transition: opacity 0.2s;

  &[hidden] {
    display: block;
    height: 0;
    margin: 0;
    opacity: 0;
    transition: opacity 0.2s, height 0s 0.2s;
    overflow: hidden;
  }
`

const CounterInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};

  ${mediaQueryUp.tablet} {
    flex-direction: row;
    align-items: flex-end;
  }
`

const fadingCss = css`
  opacity: 1;
  height: 100%;
  transition: opacity 0.2s;

  &.hidden {
    height: 0;
    opacity: 0;
    margin: 0;
    pointer-events: none;
    transition: opacity 0.2s, height 0s 0.2s, margin 0s 0.2s;
    overflow: hidden;
  }
`

const FadingCounterInput = styled(CounterInput)`
  ${fadingCss}
`

const FadingCaption = styled(Caption)`
  ${fadingCss}
`

const BookingTravellers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: center;
`

interface Props {
  transfer: App.ExperienceTransferView;
  options: Array<App.ExperienceTransferOption>;
  onChange?: (transfer: App.ExperienceTransferView) => void;
  name?: string;
}

function ExperienceDetailsTransferBookingTravellers(props: Props) {
  const { transfer, onChange = noop, name, options } = props
  const [editMode, setEditMode] = useState(false)
  const { travellers } = transfer

  const maxPassengers = useMemo(() => {
    const transfers = getValidTransferOptions(transfer, options)
    return Math.max(...transfers.map(option => option.maxPassengers))
  }, [options, transfer])

  const onCounterChange = (value: number, input: HTMLInputElement) => {
    onChange({
      ...transfer,
      travellers: {
        ...transfer.travellers,
        [input.dataset.field]: value,
      },
    })
  }

  const onSave = () => {
    setEditMode(false)
  }

  const hasChildren = !!travellers.children

  return <BookingTravellers>
    <VerticalSpacer gap={editMode ? 16 : 8}>
      <Heading variant="heading6">Travellers</Heading>
      <TabElement hidden={!editMode}>
        <VerticalSpacer gap={16}>
          <CounterInputs>
            <CounterInput
              label="Adults"
              subLabel="18+ yrs"
              min={1}
              defaultValue={travellers.adults ?? 2}
              onChange={onCounterChange}
              data-field="adults"
              name={`${name}.travellers.adults`}
              max={maxPassengers - travellers.children}
            />
            <CounterInput
              label="Children"
              subLabel="0-17 yrs"
              defaultValue={travellers.children ?? 0}
              onChange={onCounterChange}
              data-field="children"
              name={`${name}.travellers.children`}
              max={maxPassengers - travellers.adults}
            />
            <FadingCounterInput
              label="Child seats *"
              defaultValue={travellers.childSeats ?? 0}
              onChange={onCounterChange}
              data-field="childSeats"
              name={`${name}.travellers.childSeats`}
              className={!hasChildren ? 'hidden' : undefined }
            />
            <FadingCounterInput
              label="Booster seats *"
              defaultValue={travellers.boosterSeats ?? 0}
              onChange={onCounterChange}
              data-field="boosterSeats"
              name={`${name}.travellers.boosterSeats`}
              className={!hasChildren ? 'hidden' : undefined }
            />
          </CounterInputs>
          <FadingCaption variant="medium" colour="neutral-three" className={!hasChildren ? 'hidden' : undefined }>
            * Subject to availability and additional charges. For specific child seats leave a note in ‘Special requests’.
          </FadingCaption>
          <TextButton kind="secondary" onClick={onSave} fit="mobile-full-width">Save travellers</TextButton>
        </VerticalSpacer>
      </TabElement>
      <TabElement hidden={editMode}>
        <VerticalSpacer gap={4} >
          <BodyText variant="medium">
            {formatTransferTravellers(travellers)}
          </BodyText>
          {travellers.children > 0 && <Caption variant="medium" colour="neutral-three">
            Edit travellers to request child seats, if required.
          </Caption>}
        </VerticalSpacer>
      </TabElement>
    </VerticalSpacer>
    {!editMode && <TextButton kind="tertiary" onClick={() => setEditMode(true)}>Edit</TextButton>}
  </BookingTravellers>
}

export default ExperienceDetailsTransferBookingTravellers

import { BOOKING_PROTECTION_MAX_ORDER_CURRENCY_MAP, BOOKING_PROTECTION_DEFAULT_MAX_ORDER_VALUE } from 'constants/bookingProtection'
import { createSelector } from 'reselect'
import getCheckoutTotalsView from '../payment/getCheckoutTotalsView'
import { isBookingProtectionSelected } from '../view/bookingProtection'
import { getTravelDatesFromCart } from './bookingProtection'
import { CHECKOUT_ITEM_TYPE_LE_HOTEL } from 'constants/checkout'
import config from 'constants/config'
import { isBookingProtectionAvailableForAccommodationOffer } from 'lib/checkout/bookingProtection/utils'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { isStandaloneExperience } from 'selectors/checkoutSelectors'
import { getFlightItems } from '../view/flights'
import { getAccommodationItems } from '../view/hotels'

const isBookingProtectionAvailableForCartItems = createSelector(
  getAccommodationItems,
  getFlightItems,
  (state: App.State) => state.offer.offers,
  isStandaloneExperience,
  (accommodationItems, flightItems, offers, isStandaloneExperience) => {
    const items = [...accommodationItems, ...flightItems]
    for (const item of items) {
      if (!isEnabledForFeature(config.BOOKING_PROTECTION_ENABLED_LIST, item.itemType)) {
        return false
      }

      if (item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL) {
        const offer = offers[item.offerId]
        if (offer && !isBookingProtectionAvailableForAccommodationOffer(offer)) {
          return false
        }
      }
    }

    if (isStandaloneExperience && !config.REFUND_PROTECT_FOR_EXPERIENCES_ENABLED) {
      return false
    }

    return true
  },
)

const isBookingProtectionAvailable = createSelector(
  getTravelDatesFromCart,
  getCheckoutTotalsView,
  (state: App.State) => isBookingProtectionSelected(state),
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.geo.currentCurrency,
  isBookingProtectionAvailableForCartItems,
  (
    travelDates,
    checkoutTotalsView,
    bookingProtectionSelected,
    postPurchase,
    currency,
    isBookingProtectionAvailableForCartItems,
  ): boolean => {
    // If booking protection is already selected, return true
    if (bookingProtectionSelected) return true
    if (postPurchase) return false
    if (!travelDates?.startDate) return false
    if (checkoutTotalsView.data.subTotal > (BOOKING_PROTECTION_MAX_ORDER_CURRENCY_MAP[currency] || BOOKING_PROTECTION_DEFAULT_MAX_ORDER_VALUE)) return false
    // This applies to Flash, LPC, LPP, Experiences, Flights and Bundled
    if (!isBookingProtectionAvailableForCartItems) return false
    return true
  },
)

export default isBookingProtectionAvailable

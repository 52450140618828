import { PREVENT_SCROLL } from 'components/utils/UiConfig'
import uuidV4 from 'lib/string/uuidV4Utils'
import React, { useEffect } from 'react'

interface Props {
  isActive?: boolean;
}

const activeLocks = new Set<string>()

function addLock(id: string) {
  if (activeLocks.size === 0) {
    const documentWidth = document.documentElement.clientWidth
    const windowWidth = window.innerWidth
    const scrollBarWidth = windowWidth - documentWidth
    if (scrollBarWidth > 0) {
      document.body.style.marginRight = `${scrollBarWidth}px`
    }
  }

  activeLocks.add(id)
  document.scrollingElement?.classList.add(PREVENT_SCROLL)
}

function removeLock(id: string) {
  activeLocks.delete(id)
  if (activeLocks.size === 0) {
    document.scrollingElement?.classList.remove(PREVENT_SCROLL)
    document.body.style.marginRight = '0'
  }
}

function ScrollLock({ isActive }: Props) {
  useEffect(() => {
    if (isActive) {
      const lockId = uuidV4()
      addLock(lockId)
      return () => removeLock(lockId)
    }
  }, [isActive])

  return null
}

export default React.memo(ScrollLock)

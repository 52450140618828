import AspectRatio from 'components/utils/AspectRatio'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const Tile = styled(Pane)`
  display: grid;
  grid-template-rows: ${rem(220)} 1fr;

  ${mediaQueryUp.tablet} {
    padding: ${rem(20)} ${rem(20)} 0;
    display: grid;
    grid-template-areas:
      "img description description description"
      "separator separator separator separator"
      "prices prices prices cta";
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    grid-template-rows: 3fr min-content 2fr;
    gap: 24px;

    > .img-area {
      grid-area: img;
    }
    > .description-area {
      grid-area: description;
    }
    > .separator-area {
      grid-area: separator;
    }
    > .prices-area {
      grid-area: prices;
    }
    > .cta-area {
      grid-area: cta;
    }
  }
`

const PositionLockedAspectRatio = styled(AspectRatio)`
  position: relative;
`

function CruiseSearchTileLoadingSkeleton() {
  return <Tile type="clean">
    <CSSBreakpoint min="tablet">
      <PositionLockedAspectRatio ratio="16:9" className="img-area">
        <LoadingBox floating />
      </PositionLockedAspectRatio>
    </CSSBreakpoint>
    <div className="description-area">
      <VerticalSpacer gap={8}>
        <VerticalSpacer gap={4}>
          <TextLoadingBox typography="body-medium" width={180} />
          <TextLoadingBox typography="heading4" width={240} />
          <TextLoadingBox typography="body-small" width={140} />
          <TextLoadingBox typography="body-small" width={140} />
          <TextLoadingBox typography="body-medium" width={180} />
        </VerticalSpacer>
        <TextLoadingBox typography="body-medium" width={180} />
      </VerticalSpacer>
    </div>
    <Divider kind="primary" className="separator-area" />
    <Group gap={20} direction="horizontal" className="prices-area">
      <div>
        <TextLoadingBox typography="caption-small" width={100} />
        <TextLoadingBox typography="heading6" width={180} />
        <TextLoadingBox typography="caption-small" width={200} />
      </div>
      <CSSBreakpoint min="tablet">
        <div>
          <TextLoadingBox typography="caption-small" width={100} />
          <TextLoadingBox typography="heading6" width={180} />
          <TextLoadingBox typography="caption-small" width={200} />
        </div>
        <div>
          <TextLoadingBox typography="caption-small" width={100} />
          <TextLoadingBox typography="heading6" width={180} />
          <TextLoadingBox typography="caption-small" width={200} />
        </div>
      </CSSBreakpoint>
    </Group>
    <Group direction="horizontal" verticalAlign="center" className="cta-area">
      <LoadingBox style={{ height: rem(36), borderRadius: 4, width: '100%' }} />
    </Group>
  </Tile>
}

export default React.memo(CruiseSearchTileLoadingSkeleton)

import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import React, { useCallback, useContext, useMemo, useRef } from 'react'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import LineGuidedtoursIcon from 'components/Luxkit/Icons/line/LineGuidedtoursIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import TourV2SearchGroupItems from 'components/Search/TourV2/TourV2SearchGroupItems'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import LineKidIcon from 'components/Luxkit/Icons/line/LineKidIcon'
import LineUsersMultiIcon from 'components/Luxkit/Icons/line/LineUsersMultiIcon'
import { TOURS_GROUP_TYPE } from 'constants/tours'

export const TourGroupTypesList: Array<Tours.TourItemGroupType> = [
  {
    id: TOURS_GROUP_TYPE.isSmallGroup,
    name: 'Small Group Tours',
    subtitle: 'Join a group of up to 24 travellers',
    icon: <LineUsersAltIcon />,
  },
  {
    id: TOURS_GROUP_TYPE.isLargeGroup,
    name: 'Large Group Tours',
    subtitle: 'Join a group of 24+ travellers',
    icon: <LineUsersMultiIcon />,
  },
  {
    id: TOURS_GROUP_TYPE.isPrivateRequest,
    name: 'Private Requests Available',
    subtitle: 'Travel exclusively with your family and friends',
    icon: <LineGuidedtoursIcon />,
  },
  {
    id: TOURS_GROUP_TYPE.isFamilyFriendly,
    name: 'Family Friendly',
    subtitle: 'Great for both adults and children',
    icon: <LineKidIcon />,
  },
]

export const tourGroupTypeIds = TourGroupTypesList.map(tourGroupType => tourGroupType.id)

export const getTourGroupSelectedText = (tourGroupTypes: Array<Tours.TourGroupType>) => {
  return TourGroupTypesList.reduce((acc, tourGroupType) => {
    if (tourGroupTypes.includes(tourGroupType.id)) acc.push(tourGroupType.name)
    return acc
  }, []).join(' or ')
}

interface Props {
  isToggled: boolean;
  onGroupToggle: (...args: any) => void;
  onApply: (...args: any) => void;
}

function TourV2SearchGroupSelect(props: Props) {
  const { isToggled, onGroupToggle, onApply } = props

  const { tourGroupTypes = [] } = useContext(GlobalSearchStateContext)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  const resetGroupType = useCallback(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SELECT_ALL_TOUR_GROUP_TYPE })
  }, [globalSearchDispatch])

  const tourGroupTypeSelectedText = useMemo(() => getTourGroupSelectedText(tourGroupTypes), [tourGroupTypes])

  const dropdownTriggerRef = useRef<HTMLButtonElement>(null)

  return (<>
    <SearchFormField
      ref={dropdownTriggerRef}
      onClick={onGroupToggle}
      label="Type of group"
      placeholder="e.g Small Group Tours, Private Requests Available..."
      value={tourGroupTypeSelectedText}
    />
    <DropdownList
      size="fill-anchor"
      anchorRef={dropdownTriggerRef}
      triggerRef={dropdownTriggerRef}
      open={isToggled}
      onClose={onGroupToggle}
      secondaryActionProps={{
        kind: 'tertiary',
        children: 'Reset',
        onClick: resetGroupType,
        'data-testid': 'search-tour-grouptype-reset-button',
      }}
      primaryActionProps={{
        children: 'Apply',
        onClick: onApply,
        'data-testid': 'search-tour-grouptype-apply-button',
      }}
    >
      {isToggled && <TourV2SearchGroupItems groupTypes={TourGroupTypesList} />}
    </DropdownList>
  </>

  )
}

export default TourV2SearchGroupSelect

import * as isomorphicSessionStorage from '../../client/lib/storage/isomorphicSessionStorage'

export const DISMISSED_BANNER_STORAGE_KEY = 'luxPlusRenewBannerDismissed'

export function getDismissed() {
  return isomorphicSessionStorage.get(DISMISSED_BANNER_STORAGE_KEY) ?? false
}

export function setDismissed() {
  isomorphicSessionStorage.set(DISMISSED_BANNER_STORAGE_KEY, true)
}

import CruiseOfferTileLoadingSkeleton from 'components/Cruises/CruisesTile/ListingsPage/CruiseOfferTileLoadingSkeleton'
import CruiseSearchTileLoadingSkeleton from 'components/Cruises/CruisesTile/SearchPage/CruiseSearchTileLoadingSkeleton'
import { isTourV2OfferType } from 'lib/offer/offerTypes'
import React from 'react'
import BedbankOfferTileLoadingSkeleton from './BedbankOfferTileLoadingSkeleton'
import FrontPageOfferTileLoadingSkeleton from './FrontPageOfferTiles/FrontPageOfferTileLoadingSkeleton'
import HeroOfferLoadingTile from 'components/OfferList/OfferListTiles/HeroOfferTiles/HeroOfferLoadingTile'
import CarouselCardSmallLoadingSkeleton from '../OfferCards/CarouselCardLoadingSkeleton'
import LoadingSearchTileSkeleton from 'components/SearchKit/SearchTiles/LoadingSearchTileSkeleton'

const basicUuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
type TileType = 'bedbank' | 'hotel' | 'tourv2' | 'cruise' | undefined

/**
 * Attempts to get the offer type from the ID
 * NOTE: This is not 100% accurate, *do not* rely on it for completely accurate offer types
 * @param offerId The id of the offer to try detect
 * @returns Offer type
 */
export function detectOfferTileTypeFromId(offerId: string = ''): TileType {
  if (!offerId) {
    return undefined
  } if (basicUuidRegex.test(offerId)) {
    // bedbank offer use a uuid for their ID
    return 'bedbank'
  } else if (offerId.startsWith('tour')) {
    // tour v2s prefix their idea with 'tour'
    return 'tourv2'
  } else if (offerId.startsWith('cruise')) {
    return 'cruise'
  } else {
    // we have no other information to detect any more detailed type, fallback to hotel
    return 'hotel'
  }
}

function getTileTypeFromOfferType(offerType?: App.OfferListOfferType): TileType {
  if (!offerType) {
    return undefined
  } else if (offerType === 'bedbank_hotel') {
    return 'bedbank'
  } else if (offerType === 'tour_v2' || isTourV2OfferType(offerType)) {
    return 'tourv2'
  } else if (offerType === 'cruise') {
    return 'cruise'
  } else {
    return 'hotel'
  }
}

const tileStyleMap = {
  search: LoadingSearchTileSkeleton,
  frontpage: FrontPageOfferTileLoadingSkeleton,
  hero: HeroOfferLoadingTile,
  card: CarouselCardSmallLoadingSkeleton,
}

interface Props {
  offerId?: string;
  tileStyle: 'search' | 'frontpage' | 'hero' | 'card';
  offerType?: App.OfferListOfferType;
  className?: string;
}

function OfferListLoadingOfferTile(props: Props) {
  const { offerId, tileStyle, offerType, className } = props

  let Loader = tileStyleMap[tileStyle]
  if (tileStyle !== 'hero' && tileStyle !== 'card') {
    const tileType: TileType = getTileTypeFromOfferType(offerType) ?? detectOfferTileTypeFromId(offerId)
    if (tileStyle === 'search') {
      Loader = LoadingSearchTileSkeleton
    } else if (tileType === 'bedbank') {
      Loader = BedbankOfferTileLoadingSkeleton
    } else if (tileType === 'cruise') {
      if (tileStyle === 'frontpage') {
        Loader = CruiseOfferTileLoadingSkeleton
      } else {
        Loader = CruiseSearchTileLoadingSkeleton
      }
    }
  }

  return <Loader className={className} />
}

export default OfferListLoadingOfferTile

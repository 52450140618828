import useAgentHubCommission from 'agentHub/hooks/useAgentHubCommission'
import FormatCurrency from 'components/Common/FormatCurrency'
import PriceRowCommission from 'components/Luxkit/PricePoints/PriceRowCommission'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import config from 'constants/config'
import { useAppSelector } from 'hooks/reduxHooks'
import React from 'react'

type PriceRowCommissionProps = React.ComponentProps<typeof PriceRowCommission>

interface Props {
  size: PriceRowCommissionProps['size'];
  productType: App.ProductType;
  kind?: 'default' | 'plain';
  offerId: string;
  offerLocations?: Array<string>;
  vendorName?: string;
  offerTotal?: number;
}

function PriceRowAgentHubCommission(props: Props) {
  const {
    size,
    productType,
    kind = 'default',
    offerId,
    offerLocations,
    vendorName,
    offerTotal = 0,
  } = props

  const customerView = useAppSelector(state => state.agentHub.customerView)

  const { commissionAmount: commissionPercentage } = useAgentHubCommission({
    productType,
    offerId,
    offerLocations,
    vendorName,
  })

  if (!config.agentHub.isEnabled || !commissionPercentage || !productType || customerView) {
    return null
  }

  if (kind === 'plain') {
    return <BodyTextBlock weight="bold" variant="small" colour="highlight-secondary">
      Earn {offerTotal > 0 ? <FormatCurrency value={offerTotal * (commissionPercentage / 100)} /> : <>{commissionPercentage}%</>} commission
    </BodyTextBlock>
  }

  return <PriceRowCommission
    size={size}
    commissionPercentage={commissionPercentage}
    productType={productType}
    offerTotal={offerTotal}
  />
}

export default PriceRowAgentHubCommission

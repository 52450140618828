import { addRegionToPath, parseSearchString } from 'lib/url/searchUrlUtils'
import { push, replace } from 'connected-react-router'
import { PATH_CHANGE } from './actionConstants'
import qs from 'qs'
import { removeRegionCode } from 'lib/string/regionUtils'
import { AppAction } from './ActionTypes'

function buildUrl(path: string, region: string, search: string = '') {
  let newPath = addRegionToPath(path, region)
  if (search) {
    newPath += `?${search}`
  }

  return newPath
}

export function pushWithRegion(
  path: string,
  search?: string,
  locationState?: App.LocationState,
): AppAction {
  return (dispatch, getState) => {
    const url = buildUrl(path, getState().geo.currentRegionCode, search)
    dispatch(push(url, locationState))
  }
}

export function replaceWithRegion(path: string, search?: string): AppAction {
  return (dispatch, getState) => {
    dispatch(replace(buildUrl(path, getState().geo.currentRegionCode, search)))
  }
}

export function pathChange(): AppAction {
  return ({ type: PATH_CHANGE })
}

export function updateSearchString(queryParams: any): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const regionCode = state.geo.currentRegionCode
    const path = removeRegionCode(state.router.location.pathname, regionCode)
    const currentQueryParams = parseSearchString(state.router.location.search)
    dispatch(pushWithRegion(path, qs.stringify({
      ...currentQueryParams,
      ...queryParams,
    })))
  }
}

export function replaceSearchString(queryParams: any): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const regionCode = state.geo.currentRegionCode
    const path = removeRegionCode(state.router.location.pathname, regionCode)
    const currentQueryParams = parseSearchString(state.router.location.search)
    dispatch(replaceWithRegion(path, qs.stringify({
      ...currentQueryParams,
      ...queryParams,
    })))
  }
}

import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import Clickable from 'components/Common/Clickable'
import { TrendingDestination } from './constants'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { useInView } from 'react-intersection-observer'
import Image from 'components/Common/Image/Image'
import { rem } from 'polished'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { calculateTrackingInView } from 'components/utils/OfferInView'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'

const Section = styled(Clickable)`
  display: grid;
  gap: ${rem(16)};
  grid-template-columns: ${rem(85)} 1fr;
  width: ${rem(280)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: ${rem(180)} 1fr;
    flex-direction: column;
    grid-gap: ${rem(12)};
    width: ${rem(180)};
  }
`

interface Props {
  index: number;
  trendingDestination: TrendingDestination;
  selectedFilter?: App.DestinationSelectedFilters;
}

function TrendingDestinationsTile(props: Props) {
  const { index, selectedFilter, trendingDestination } = props
  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const [inViewRef, _, entry] = useInView({ triggerOnce: true, threshold: 0.5 })
  const trackingInView = useMemo(() => calculateTrackingInView(entry), [entry])

  const onClick = useCallback(() => {
    dispatchOfferListEvent(
      {
        type: OfferListEvents.destinationClick,
        destination: {
          destinationId: trendingDestination.destinationId ?? '',
          destinationName: trendingDestination.name,
          tileType: 'tile',
          destinationImageId: trendingDestination.imageId,
        },
        position: index,
        key: EventDataKey.DestinationClick,
        filter: selectedFilter,
      },
    )
  }, [dispatchOfferListEvent, trendingDestination, index, selectedFilter])

  useEffect(() => {
    if (trackingInView) {
      dispatchOfferListEvent(
        {
          type: OfferListEvents.destinationImpression,
          destination: {
            destinationId: trendingDestination.destinationId ?? '',
            destinationName: trendingDestination.name,
            tileType: 'tile',
            destinationImageId: trendingDestination.imageId,
          },
          position: index,
          key: EventDataKey.DestinationImpression,
          filter: selectedFilter,
        },
      )
    }
  }, [trackingInView, dispatchOfferListEvent, trendingDestination, index, selectedFilter])

  return (<Section to={trendingDestination.url} target="_blank" onClick={onClick} ref={inViewRef}>
    <Image
      fit="center"
      width={180}
      height={180}
      dpr={2}
      aspectRatio="1:1"
      id={trendingDestination.imageId}
    />
    <Group direction="vertical" gap={4} horizontalAlign="start" verticalAlign="center" tabletVerticalAlign="start">
      <BodyText variant="large" weight="bold">{trendingDestination.name}</BodyText>
    </Group>
  </Section>
  )
}

export default TrendingDestinationsTile

import React from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Clickable from 'components/Common/Clickable'
import { StyledSvgIcon } from '../SvgIcon'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import MaxWeightPreservedTypography from 'components/utils/MaxWeightPreservedTypography'

export const TAB_BUTTON_FOCUS_RING_WIDTH: `${number}px` = '1px'

const Tab = styled(Clickable)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${rem(80)};
  transition:
    .2s color,
    .2s background-color,
    .2s box-shadow;
  padding: 0 ${rem(16)};
  box-shadow: var(--focus-shadow, 0 0), inset 0 -1px 0 ${props => props.theme.palette.neutral.default.five};
  overflow: hidden;
  color: ${props => props.theme.palette.neutral.default.three};
  gap: ${rem(8)};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.palette.brand.primary.normal};
    opacity: 0;
    transition: .2s opacity;
  }

  &.active {
    color: ${props => props.theme.palette.brand.primary.normal};
  }

  &.active::after {
    opacity: 1;
  }

  &.size-small {
    height: ${rem(40)};

    > ${StyledSvgIcon} {
      height: ${rem(16)};
      width: ${rem(16)};
    }
  }

  &.size-medium {
    height: ${rem(56)};

    > ${StyledSvgIcon} {
      height: ${rem(20)};
      width: ${rem(20)};
    }
  }

  &.size-large {
    height: ${rem(64)};

    > ${StyledSvgIcon} {
      height: ${rem(24)};
      width: ${rem(24)};
    }
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover:not(.active) {
        color: ${props => props.theme.palette.brand.primary.hover};
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }

    &:focus {
      --focus-shadow: var(${KEYBOARD_MODE_CSS_VAR}, 0 0 0 ${TAB_BUTTON_FOCUS_RING_WIDTH} ${props => props.theme.palette.neutral.default.five});
      border-radius: var(${KEYBOARD_MODE_CSS_VAR}, ${props => props.theme.borderRadius.S});
      color: var(${KEYBOARD_MODE_CSS_VAR}, ${props => props.theme.palette.neutral.default.three});
      background-color: var(${KEYBOARD_MODE_CSS_VAR}, ${props => props.theme.palette.neutral.default.seven});
    }
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Allows applying format to the main text, either through title or children */
  format?: React.ComponentProps<typeof BodyText>['format']
  /** Whether or not the tab is active - changes the visual to imply it's active */
  isActive?: boolean
  /** Use to link to internal pages */
  to?: string
  /** Use to link to external pages */
  href?: string
  /** Determines factors like the height/padding of buttons as well as text/icon sizes */
  size: 'small' | 'medium' | 'large'
  /** An icon element that will go at the start (often the 'left' side) of the Tab */
  startIcon?: React.ReactNode;
  /** An icon element that will go at the end (often the 'right' side) of the Tab */
  endIcon?: React.ReactNode
}

const TabButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    disabled,
    format,
    isActive,
    className,
    size,
    children,
    startIcon,
    endIcon,
    ...buttonProps
  } = props

  return <Tab
    {...buttonProps}
    ref={ref}
    className={cn(
      className,
      `size-${size}`,
      {
        active: isActive,
      },
    )}
    disabled={disabled}
  >
    {startIcon}
    <MaxWeightPreservedTypography
      Typography={BodyText}
      align="center"
      variant={size}
      weight={isActive ? 'bold' : 'normal'}
      format={format}
    >
      {children}
    </MaxWeightPreservedTypography>
    {endIcon}
  </Tab>
})

TabButton.displayName = 'TabButton'
export default TabButton

function useBedbankOfferSell(
  offer: App.BedbankOffer | App.BedbankOfferSummary,
  duration: number,
  rooms: Array<App.Occupants>,
): App.BedbankSellPriceInfo | null {
  if (offer.sell?.price) {
    const numberRooms = rooms?.length || 1
    const los = duration || offer.sell.los
    const price = Math.round((offer.sell.price / offer.sell.los) * los * numberRooms)
    const value = Math.round((offer.sell.value / offer.sell.los) * los * numberRooms)
    const taxesAndFees = Math.round((offer.sell.taxesAndFees / offer.sell.los) * los * numberRooms)
    const propertyFees = Math.round((offer.sell.propertyFees / offer.sell.los) * los * numberRooms)
    const discount = Number(((offer.sell.value - offer.sell.price) / offer.sell.value).toFixed(2))
    return {
      price,
      value,
      propertyFees,
      taxesAndFees,
      discount: discount > 0.02 ? discount : 0,
      los,
    }
  }

  return null
}

export default useBedbankOfferSell

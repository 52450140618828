import { useContext, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchTourSearchFacets } from 'actions/OfferActions'
import Places, { PlacesByPlaceId } from 'constants/places'
import getObjectKey from 'lib/object/getObjectKey'
import GeoContext from 'contexts/geoContext'
import { OFFER_TYPE_TOUR_V2 } from 'constants/offer'

interface Props {
  category?: string;
  places?: Array<App.Place>;
}

export interface TourDestination {
  id: string;
  name: string;
}

interface TourFacetResult {
  locations: Map<string, TourDestination>;
  groupTypes: Map<string, number>;
  categories: Map<string, number>;
  productTypes: Map<string, number>;
}

interface Options {
  disabled?: boolean;
}

function useTourFacetLocationCategory(props: Props, options: Options = {}): App.ApiCallState<TourFacetResult> {
  const { category, places } = props
  const dispatch = useAppDispatch()

  const { currentRegionCode } = useContext(GeoContext)

  const key = useMemo(() => {
    return getObjectKey({ placeId: Places.Anywhere.id, region: currentRegionCode, category })
  }, [category, currentRegionCode])
  const locationsCategory = useAppSelector((state) => state.tour.tourFacets[key])

  useEffect(() => {
    if (places === undefined || places?.length > 0 && !options.disabled) {
      const categories = category ? [category] : undefined
      dispatch(fetchTourSearchFacets({
        destinationId: Places.Anywhere.id,
        offerTypes: [OFFER_TYPE_TOUR_V2],
        categories,
      }))
    }
    // eslint-disable-next-line
  }, [dispatch, category])

  const toursFacetResult = useMemo(() => {
    const locations = new Map()
    const categories = new Map()
    const groupTypes = new Map()
    const productTypes = new Map()
    if (locationsCategory?.data && !locationsCategory.fetching) {
      locationsCategory.data.locations
        ?.filter(location => location.count > 3)
        ?.sort((a, b) => b.count - a.count)
        ?.forEach(location => {
          const place = PlacesByPlaceId[location.value] ?? { id: location.value, name: location.label }
          locations.set(place.id, place)
        })
      locationsCategory.data.categories?.forEach(category => {
        categories.set(category.value, category.count)
      })
      locationsCategory.data.groupTypes?.forEach(groupType => {
        groupTypes.set(groupType.value, groupType.count)
      })
      locationsCategory.data.offerTypes?.forEach(offerType => {
        productTypes.set(offerType.value, offerType.count)
      })
    }
    return {
      fetching: locationsCategory?.fetching,
      locations,
      categories,
      groupTypes,
      productTypes,
    }
  }, [locationsCategory])

  return toursFacetResult
}

export default useTourFacetLocationCategory

import { rem } from 'polished'

const gridColumns = 12
const gutterWidth = 32
export const container = '1140px'
export const containerLarge = rem(1140 + gutterWidth)
const screenSmMin = '768px'
const screenMdMin = '992px'
const screenLgMin = '1200px'

export default {
  gridColumns,
  gutterWidth,
  containerLarge,
  screenSmMin,
  screenMdMin,
  screenLgMin,
}

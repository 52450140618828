import printPage from 'lib/function/printPage'

export const printModal = (elementToPrint: HTMLElement) => {
  // create print section element
  const domClone = elementToPrint.cloneNode(true)
  const printModal = document.createElement('div')
  printModal.id = 'printModal'
  // add into body to be printable
  printModal.appendChild(domClone)
  // hide all other elements for print
  document.body.classList.add('hidden-print-modal')
  document.body.insertBefore(printModal, document.body.firstChild)
  printPage()
  // clean body to be able to print as previously
  document.body.classList.remove('hidden-print-modal')
  document.body.removeChild(printModal)
}

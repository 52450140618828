import {
  Middleware,
} from 'redux'
import apiMiddleware from 'middlewares/apiMiddleware'
import rootReducer from './reducers'
import { History, LocationState } from 'history'
import { configureStore } from '@reduxjs/toolkit'
import config from 'constants/config'

interface StoreOptions {
  apiMiddleware?: boolean;
  devTools?: boolean;
}

export default function buildStore<S>(
  history: History<LocationState>,
  initialState?: Partial<S>,
  middlewares: Array<Middleware<S>> = [],
  options: StoreOptions = {
    apiMiddleware: true,
    devTools: true,
  },
) {
  return configureStore({
    reducer: rootReducer(history),
    middleware: (defaultMiddleware) => defaultMiddleware({
      serializableCheck: false,
      immutableCheck: config.REDUX_IMMUTABILITY_CHECK_ENABLED,
    }).concat([
      ...(options.apiMiddleware ? [apiMiddleware] : []),
      ...middlewares,
    ]),
    devTools: options.devTools ? {
      autoPause: true,
      actionSanitizer: (action: any) => {
        if (action.api) {
          return {
            ...action,
            type: `${action.api} - ${action.type.replace('API_CALL_', '')}`,
          }
        }
        return action
      },
    } : false,
    preloadedState: initialState,
  })
}

export type AppStore = ReturnType<typeof buildStore>
export type AppDispatch = AppStore['dispatch']

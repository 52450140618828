import { fetchAccumulatedOfferById } from 'actions/OfferActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'
import { getAllOffers } from 'selectors/offerSelectors'

interface UseOfferOptions {
  disabled?: boolean;
  requireSummaryOnly?: boolean;
}

function useOffer<T extends App.OfferBase = App.AnyOffer>(
  offerId: string | undefined,
  options: UseOfferOptions = { },
): [T | undefined, boolean, any] {
  const dispatch = useAppDispatch()

  const offer: any = useAppSelector<App.AnyOffer>((state) => getAllOffers(state)[offerId!])

  const offerSummary = useAppSelector((state) =>
    state.offer.offerSummaries[offerId!] ||
    state.offer.bedbankOfferSummaries[offerId!],
  )

  const hasFullOffer = !!offer && offer !== offerSummary

  const isFetchRequired = !!offerId && !options.disabled && (options.requireSummaryOnly ? !offer : !hasFullOffer)
  useEffect(() => {
    if (isFetchRequired) {
      dispatch(fetchAccumulatedOfferById(offerId))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, isFetchRequired])

  const fetching = useAppSelector(state => state.offer.offersLoading[offerId!]) || isFetchRequired
  const error = useAppSelector(state => state.offer.offerErrors[offerId!])

  return [
    offer,
    fetching,
    error,
  ]
}

export default useOffer

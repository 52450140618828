import { createSelector } from 'reselect'
import { checkCanViewLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { isCustomerSupportMode } from 'selectors/featuresSelectors'

/**
 * If a user is not signed up to LuxPlus+, we should prevent them
 * from seeing member-only offers until they become an active member.
 *
 * In place we display a locked offer UI.
 */

export const isLuxPlusHiddenOffer = createSelector(
  isLuxPlusEnabled,
  checkCanViewLuxPlusBenefits,
  isCustomerSupportMode,
  (_state: App.State, offer?: App.AnyOffer) => offer,
  (isLuxPlusEnabled, canViewLuxPlusBenefits, isCustomerSupportMode, offer): boolean => {
    return isLuxPlusEnabled && !canViewLuxPlusBenefits && !isCustomerSupportMode && offer?.luxPlus.access === 'memberOnly'
  },
)

import React, { useCallback, useRef } from 'react'
import Clickable from 'components/Common/Clickable'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import LineCheckCircleIcon from 'components/Luxkit/Icons/line/LineCheckCircleIcon'
import LineTicketIcon from 'components/Luxkit/Icons/line/LineTicketIcon'
import FloatingTooltip from 'components/Luxkit/FloatingTooltip'

const TAG_ICON_AWARD = 'award'
const TAG_ICON_TICK = 'tick_in_circle'
const TAG_ICON_TICKET = 'ticket'

function getIconForTag(tagIcon: string) {
  switch (tagIcon) {
    case TAG_ICON_AWARD:
      return <LineAwardIcon />
    case TAG_ICON_TICK:
      return <LineCheckCircleIcon />
    case TAG_ICON_TICKET:
      return <LineTicketIcon />
    default:
      return <LineTicketIcon />
  }
}

interface Props {
  badge: App.OfferBadge;
}

function OfferBadgeLabel(props: Props) {
  const {
    tagText,
    tagTooltip,
    tagIcon,
    url,
  } = props.badge

  const elementRef = useRef<any>(null)
  const icon = getIconForTag(tagIcon)

  const openUrl = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (url) {
      e.preventDefault()
      e.stopPropagation()
      // prevent firing tooltip's e.stopPropagation()
      window.open(url)
    }
  }, [url])

  const badgeTagText = tagText?.trim().length > 0 ? tagText : null

  if (badgeTagText) {
    return <>
      {url && <Clickable ref={elementRef} onClick={openUrl}>
        <PromotionLabel variant="default" icon={icon}>{badgeTagText}</PromotionLabel>
      </Clickable>}
      {!url && <PromotionLabel ref={elementRef} variant="default" icon={icon}>{badgeTagText}</PromotionLabel>}
      {tagTooltip && <FloatingTooltip
        description={tagTooltip}
        triggerRef={elementRef}
      />}
    </>
  }

  return null
}

export default React.memo(OfferBadgeLabel)

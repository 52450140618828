import clsx from 'clsx'
import FormatCurrency from 'components/Common/FormatCurrency'
import Caption from 'components/Luxkit/Typography/Caption'
import React, { ComponentProps } from 'react'

type InheritedFormatCurrencyProps = Pick<ComponentProps<typeof FormatCurrency>, 'signDisplay'>

interface Props extends InheritedFormatCurrencyProps {
  nonMemberPrice: number
  size: 'M' | 'L'
  className?: string
}

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

/**
 * Displays price for non-members with a proper caption.
 */
function PriceRowLuxPlusNonMemberPrice(props: Props) {
  const {
    nonMemberPrice,
    signDisplay,
    size,
    className,
  } = props

  const captionVariant = SIZES_CAPTION_VARIANTS[size]

  return <Caption
    variant={captionVariant}
    colour="neutral-two"
    className={clsx(PriceRowLuxPlusNonMemberPrice.name, className)}
  >
    Non-member
    {' '}
    <b><FormatCurrency signDisplay={signDisplay} value={nonMemberPrice} format="roundedDollar" /></b>
  </Caption>
}

export default PriceRowLuxPlusNonMemberPrice

import { useResponsive } from './useResponsive'

/**
 * Maps screen sizes to arbitrary values according to the options config passed in.
 */
function useResponsiveSelector<ValueType>(config: Partial<Record<App.ScreenSize, ValueType>>): ValueType {
  const [breakpointMatches] = useResponsive()
  const matchedSize = Object.keys(config).find(size => breakpointMatches[size])

  return matchedSize ? config[matchedSize] : undefined
}

export default useResponsiveSelector

import moment from 'moment'

export const DATE_OF_BIRTH = {
  minValue: moment().subtract(120, 'years').toDate(),
  maxValue: moment().subtract(16, 'years').toDate(),
}

/** @deprecated Format month names where needed with `Intl.DateTimeFormat` */
export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const

export type MonthName = typeof MONTH_NAMES[number]

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBillIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9.5 10.4999H12a1 1 0 1 0 0-2h-1V8a.9997.9997 0 0 0-1-1 1 1 0 0 0-1 1v.55a2.5 2.5 0 0 0 .5 4.95h1a.5001.5001 0 0 1 0 1H8a1.0002 1.0002 0 0 0-.7071 1.7072A1 1 0 0 0 8 16.4999h1v.5a1.0002 1.0002 0 0 0 1 1 1 1 0 0 0 1-1v-.55a2.5002 2.5002 0 0 0 1.9899-2.7012A2.5003 2.5003 0 0 0 10.5 11.4999h-1a.4998.4998 0 0 1-.3536-.8535.5.5 0 0 1 .3536-.1465Zm11.5 1.5h-3v-9a1 1 0 0 0-1.5-.87l-3 1.72-3-1.72a1 1 0 0 0-1 0l-3 1.72-3-1.72A1 1 0 0 0 2 3v16a3 3 0 0 0 3 3h14a2.9998 2.9998 0 0 0 3-3v-6a.9999.9999 0 0 0-1-1Zm-16 8a1 1 0 0 1-1-1v-14.27l2 1.14a1.08 1.08 0 0 0 1 0l3-1.72 3 1.72a1.08 1.08 0 0 0 1 0l2-1.14V19c.0027.3412.0636.6794.18 1H5Zm15-1a1 1 0 1 1-2 0v-5h2v5Z"/>
  </SvgIcon>
}

export default LineBillIcon

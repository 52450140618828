import { rem } from 'polished'
import React, { ComponentProps, forwardRef } from 'react'
import styled from 'styled-components'
import BodyText from '../Typography/BodyText'
import RadioButton from './RadioButton'
import clsx from 'clsx'

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  gap: ${rem(12)};
`

const RadioText = styled(BodyText)`
  flex-grow: 1;
  transition: color 0.2s;
`

interface Props extends ComponentProps<typeof RadioButton> {
}

/**
 * Radio input with a label.
 *
 * For the radio input without the label see `<RadioButton>`
 */
const RadioInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, className, size = 'medium', ...inputProps } = props
  const hasChildren = React.Children.toArray(children).length !== 0

  return <Label className={clsx(className, `size-${size}`)} htmlFor={inputProps.id}>
    <RadioButton {...inputProps} ref={ref} size={size} />
    {hasChildren && <RadioText
      colour={inputProps.disabled ? 'neutral-four' : 'neutral-one'}
      variant={size}
      forwardedAs="span"
    >
      {children}
    </RadioText>}
  </Label>
})

export default RadioInput

import React from 'react'
import BedbankSearchTileWalledContent from './BedbankSearchTileWalledContent'
import SearchTileSignUpButton from '../shared/SearchTileSignUpButton'
import { EmptyObject } from 'lib/object/objectUtils'
import SearchTilePrimaryActionButton from '../shared/SearchTilePrimaryActionButton'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  filters?: App.OfferListFilters
}

function BedbankSearchTileAction({
  offer,
  filters = EmptyObject,
}: Props) {
  return <BedbankSearchTileWalledContent
    wall={<SearchTileSignUpButton />}
    offer={offer}
    filters={filters}
  >
    <SearchTilePrimaryActionButton />
  </BedbankSearchTileWalledContent>
}

export default BedbankSearchTileAction

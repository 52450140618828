import { AppAction } from 'actions/ActionTypes'
import { API_CALL } from 'actions/actionConstants'
import requestGetPolicy from 'businessTraveller/api/requestGetPolicy'
import { BUSINESS_TRAVELLER_API_ACTIONS } from 'reducers/businessTravellerApiActionReducers'

function fetchBusinessAccommodationPolicy(
  businessId: string,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const policy = state.businessTraveller.businessAccommodationPolicy.policy

    if (!policy) {
      dispatch({
        type: API_CALL,
        api: BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_ACCOMMODATION_POLICY,
        request: () => requestGetPolicy({
          businessId,
          policyType: 'hotel',
        }),
      })
    }
  }
}

export default fetchBusinessAccommodationPolicy

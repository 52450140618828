export const immersiveTripIdCookieKey = 'immersiveTripId'

/**
 * Saves the trip ID in a very short-lived cookie that's scoped to only the current path.
 * This is intended to be used in the `beforeunload` event if the user is in immersive trip
 * header mode, so if they reload the page, the trip ID cookie will be sent with the next
 * page request so we can persist the immersive trip header across reloads.
 */
export function setImmersiveTripIdCookie(tripId: string, pathname: string) {
  document.cookie = `${immersiveTripIdCookieKey}=${tripId}; max-age=2; domain=${document.domain}; path=${pathname}`
}

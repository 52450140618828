import qs from 'qs'
import request from 'api/requestUtils'
import {
  carHireOfferMap,
  carHireReservationMap,
  carHireOfferImportantInformationMap,
  carHireInsuranceQuoteMap,
  carHireDestinationPriceInfoMap,
  carHireCheapestOfferMap,
} from './mappers/carHireMap'
import { definitions } from '@luxuryescapes/contract-car-hire'
import moment from 'moment'
import { getPlaceBoundingRadius } from './search'

type VehicleAvailabilityQuery = definitions['availabilityQuery']
type VehicleAvailabilityResponse = definitions['availabilityResponse']
type CheapestOfferResponse = definitions['cheapestVehicleOfferResponse']
type ImportantInformationResponse = definitions['importantInformationResponse']
type ImportantInformationQuery = definitions['importantInformationQuery']
type ReservationResponse = definitions['reservationResponse']
type InsuranceQuoteQuery = definitions['getInsuranceQuoteQuery']
type InsuranceQuoteResponse = definitions['getInsuranceQuoteResponse'] | null
type DestinationPriceInfoQuery = definitions['getTrendsQuery']
type DestinationPriceInfoResponse = definitions['getTrendsResponse']

const BASE_URI = '/api/car-hire/v1'

interface Bounds {
  latLong: string;
  radius: string;
}
interface LocationBounds {
  pickUp?: Bounds
  dropOff?: Bounds
}

async function fetchLocationBoundingRadius(locationId: string) {
  const bounds: Bounds = {
    latLong: '',
    radius: '',
  }
  const locationBoundingRadius = await getPlaceBoundingRadius(locationId)
  if (locationBoundingRadius.boundingRadius) {
    // svc-car-hire expects an integer in kilometers, svc-search returns a float in meters
    // some locations have the radius set to 0, so we set a default of 5km
    bounds.radius = String(Math.ceil(locationBoundingRadius.boundingRadius.radius / 1000) || 5)
    bounds.latLong = String(
      [locationBoundingRadius.boundingRadius.centre.latitude.toString(),
        locationBoundingRadius.boundingRadius.centre.longitude.toString()])
  }

  return bounds
}

async function getLocationBoundingRadius(filter: App.CarHireOfferListFilters): Promise<LocationBounds> {
  const isPickUpGeoSearch = filter.pickUpSearchType === 'geo'
  const isDropOffGeoSearch = filter.dropOffSearchType === 'geo'
  const differentLocations = filter.pickUpLocationId !== filter.dropOffLocationId

  if (!differentLocations && filter.pickUpLocationId) {
    const locationBounds = await fetchLocationBoundingRadius(filter.pickUpLocationId)
    return {
      pickUp: locationBounds,
      dropOff: locationBounds,
    }
  }

  if (differentLocations) {
    if (isPickUpGeoSearch && isDropOffGeoSearch && filter.pickUpLocationId && filter.dropOffLocationId) {
      const locationsBounds = await Promise.all([
        fetchLocationBoundingRadius(filter.pickUpLocationId),
        fetchLocationBoundingRadius(filter.dropOffLocationId),
      ])
      return {
        pickUp: locationsBounds[0],
        dropOff: locationsBounds[1],
      }
    }

    return {
      pickUp: isPickUpGeoSearch && filter.pickUpLocationId ?
        await fetchLocationBoundingRadius(filter.pickUpLocationId) :
        undefined,
      dropOff: isDropOffGeoSearch && filter.dropOffLocationId ?
        await fetchLocationBoundingRadius(filter.dropOffLocationId) :
        undefined,
    }
  }

  return {}
}

async function getAvailabilityQueryParams(filter: App.CarHireOfferListFilters, currencyCode: string, currentRegionCode: string) {
  const [pickUpHour, pickUpMinute] = filter.pickUpTime.split(':')
  const [dropOffHour, dropOffMinute] = filter.dropOffTime.split(':')

  const pickUpSearchType = filter.pickUpSearchType
  const dropOffSearchType = filter.dropOffSearchType

  let locationBounds: LocationBounds = {}

  if (pickUpSearchType === 'geo' || dropOffSearchType === 'geo') {
    locationBounds = await getLocationBoundingRadius(filter)
  }

  const queryParams: Partial<Record<keyof VehicleAvailabilityQuery, any>> = {
    // pick-up
    ...(pickUpSearchType === 'geo' && {
      pickUpGeoCode: locationBounds?.pickUp?.latLong || '',
    }),
    ...(pickUpSearchType === 'airport' && {
      pickUpAirportCode: filter.pickUpLocationId,
    }),
    ...(pickUpSearchType === 'destination' && {
      pickUpLocationId: filter.pickUpLocationId,
    }),
    // drop-off
    ...(dropOffSearchType === 'geo' && {
      returnGeoCode: locationBounds?.dropOff?.latLong || '',
    }),
    ...(dropOffSearchType === 'airport' && {
      returnAirportCode: filter.dropOffLocationId,
    }),
    ...(dropOffSearchType === 'destination' && {
      returnLocationId: filter.dropOffLocationId,
    }),
    ...((pickUpSearchType === 'geo' || dropOffSearchType === 'geo') && {
      // svc-car-hire only accepts one radius for both locations
      radius: locationBounds?.pickUp?.radius || locationBounds?.dropOff?.radius || 25,
    }),
    pickUpDate: moment.utc(filter.pickUpDate).hour(parseInt(pickUpHour)).minutes(parseInt(pickUpMinute)).toISOString(),
    returnDate: moment.utc(filter.dropOffDate).hour(parseInt(dropOffHour)).minutes(parseInt(dropOffMinute)).toISOString(),
    currency: currencyCode,
    region: currentRegionCode,
    driverAge: filter.driversAge,
  }

  return queryParams
}

export async function getCarHireOffers(filter: App.CarHireOfferListFilters, currencyCode: string, currentRegionCode: string) {
  const queryParams = await getAvailabilityQueryParams(filter, currencyCode, currentRegionCode)

  if (!filter.driversAge || filter.driversAge < 18) {
    switch (filter.driversAgeCategory) {
      case 1:
        queryParams.driverAge = 18
        break
      case 2:
        queryParams.driverAge = 30
        break
      case 3:
        queryParams.driverAge = 70
        break
    }
  }

  const response = await request
    .get<App.ApiResponse<VehicleAvailabilityResponse>>(`${BASE_URI}/availability?${qs.stringify(queryParams)}`)

  const pickUpLocationsMap = new Map(response.result.location.pickUp.map((loc) => [loc.Code, { ...loc }]))
  const returnLocationsMap = new Map(response.result.location.return.map((loc) => [loc.Code, { ...loc }]))

  return {
    pickUp: {
      date: response.result.pickUp.date,
      dateChanged: response.result.pickUp.dateChanged,
    },
    dropOff: {
      date: response.result.return.date,
      dateChanged: response.result.return.dateChanged,
    },
    vehicles: response.result.vehicles.map(serverVehicle => {
      return carHireOfferMap(
        serverVehicle as any,
        response.result.uuid,
        pickUpLocationsMap,
        returnLocationsMap,
        response.result.pickUp.date,
        response.result.return.date,
      )
    }),
  } as App.CarHireList
}

export async function getOfferImportantInformation(params: App.CarHireOfferImportantInformationParams, currencyCode: string, currentRegionCode: string) {
  const [pickUpHour, pickUpMinute] = params.pickUpLocation.time.split(':')
  const [dropOffHour, dropOffMinute] = params.dropOffLocation.time.split(':')

  const requestBody: ImportantInformationQuery = {
    pickUpLocation: {
      code: params.pickUpLocation.code,
      date: moment.utc(params.pickUpLocation.date).hour(parseInt(pickUpHour)).minutes(parseInt(pickUpMinute)).toISOString(),
    },
    returnLocation: {
      code: params.dropOffLocation.code,
      date: moment.utc(params.dropOffLocation.date).hour(parseInt(dropOffHour)).minutes(parseInt(dropOffMinute)).toISOString(),
    },
    reference: {
      id: params.reference.id,
      idContext: params.reference.idContext,
      type: params.reference.type,
      dateTime: params.reference.dateTime,
      url: params.reference.url,
    },
    currency: currencyCode,
    region: currentRegionCode,
  }

  const response = await request
    .post<App.ApiResponse<ImportantInformationResponse>, unknown>(`${BASE_URI}/availability/important-information`, requestBody)

  return response.result.importantInformations.map(importantInformation => {
    return carHireOfferImportantInformationMap(importantInformation)
  })
}

export async function getCarHireReservation(reservationId: string, customerId: string) {
  const response = await request.get<App.ApiResponse<ReservationResponse>>(`${BASE_URI}/reservation/${reservationId}?${qs.stringify({ customerId })}`, { credentials: 'include' })

  return carHireReservationMap(response.result)
}

export async function getReservationInsuranceInfo(reservationId: string) {
  const response = await request.get<App.ApiResponse<InsuranceQuoteResponse>>(`${BASE_URI}/reservation/${reservationId}/insurance`, { credentials: 'include' })

  return carHireInsuranceQuoteMap(response.result)
}

export async function getInsuranceQuoteQuery(params: App.CarHireOfferInsuranceQuoteParams, currencyCode: string, currentRegionCode: string) {
  const queryParams: InsuranceQuoteQuery = {
    availabilityUuid: params.availabilityUuid,
    id: params.reference.id,
    idContext: params.reference.idContext,
    type: params.reference.type,
    dateTime: params.reference.dateTime,
    url: params.reference.url,
    passengerName: params.passengerName,
    countryCode: currentRegionCode,
    currency: currencyCode,
  }

  const response = await request
    .get<App.ApiResponse<InsuranceQuoteResponse>>(`${BASE_URI}/insurance-quote?${qs.stringify(queryParams)}`)

  return carHireInsuranceQuoteMap(response.result)
}

export async function getDestinationsPriceInfo(params: App.CarHireDestinationPriceInfoParams, currencyCode: string) {
  const queryParams: DestinationPriceInfoQuery = {
    ...(params.cities && { city: params.cities.toString() }),
    ...(params.airports && { airport: params.airports.toString() }),
    limit: params.limit.toString(),
    currency: currencyCode,
  }

  const response = await request
    .get<App.ApiResponse<DestinationPriceInfoResponse>>(`${BASE_URI}/trends?${qs.stringify(queryParams)}`)

  const destinations = response.result.trends
  const uniqueKeys = new Set(destinations.map(item => item.key.toUpperCase()))
  const uniqueDestinations = Array.from(uniqueKeys).filter(key => key !== 'WORLDWIDE').map(key => {
    return destinations.find(item => item.key.toUpperCase() === key.toUpperCase())
  })

  return uniqueDestinations.map((trendingDestinationInformation) => {
    if (trendingDestinationInformation) {
      return carHireDestinationPriceInfoMap(trendingDestinationInformation)
    }
  })
}

export async function getCheapestVehicle(filter: App.CarHireOfferListFilters, currencyCode: string, currentRegionCode: string) {
  const queryParams = await getAvailabilityQueryParams(filter, currencyCode, currentRegionCode)

  if (!filter.driversAge || filter.driversAge < 18) {
    switch (filter.driversAgeCategory) {
      case 1:
        queryParams.driverAge = 18
        break
      case 2:
        queryParams.driverAge = 30
        break
      case 3:
        queryParams.driverAge = 70
        break
    }
  }

  const response = await request
    .get<App.ApiResponse<CheapestOfferResponse>>(`${BASE_URI}/cheapest-vehicle-offer?${qs.stringify(queryParams)}`)

  return carHireCheapestOfferMap(response.result)
}

import { get, set } from 'lib/storage/isomorphicLocalStorage'
import {
  get as getSS,
  set as setSS,
} from 'lib/storage/isomorphicSessionStorage'

export const SEEN_TOUR_STORAGE_KEY = 'hasSeenTripOnboardingTour'
export const TOUR_SESSION_STORAGE_KEY = 'hasSeenTripOnboardingTourInThisSession'

export function getSeenStatus() {
  return get(SEEN_TOUR_STORAGE_KEY) ?? false
}

export function setSeenStatus(hasSeen: boolean) {
  set(SEEN_TOUR_STORAGE_KEY, hasSeen)
}

export function getSeenInThisSessionStatus() {
  return getSS(TOUR_SESSION_STORAGE_KEY) ?? false
}

export function setSeenInThisSessionStatus(hasSeen: boolean) {
  setSS(TOUR_SESSION_STORAGE_KEY, hasSeen)
}

import BundleOfferTilePricing from 'components/OfferList/OfferListTiles/Support/BundleOfferTilePricing'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import useBestPriceBundleAndSavePackage from 'hooks/useBestPriceBundleAndSavePackage'
import { EmptyArray } from 'lib/array/arrayUtils'
import { EmptyObject } from 'lib/object/objectUtils'
import React from 'react'

interface Props {
  offer: App.BundleOffer
  filters?: App.OfferListFilters
  offerMetaData: App.BundleOfferListMetaData
}

function HotelBundleSearchTilePricePoint({
  offer,
  filters,
  offerMetaData,
}: Props) {
  const directSearchPrices = useDirectSearchPrices({ filters: filters ?? EmptyObject, offerId: offer.id })
  const {
    bestPricePackage,
  } = useBestPriceBundleAndSavePackage(offer, offerMetaData.bundledOfferId, filters, directSearchPrices, offerMetaData)

  const offerUrl = useOfferUrl(offer, {
    filters,
    bundledOfferId: offerMetaData?.bundledOfferId,
    offerLinkIncludesFilters: true,
  })

  return <BundleOfferTilePricing
    offer={offer}
    rooms={filters?.rooms ?? EmptyArray}
    pkg={bestPricePackage}
    offerUrl={offerUrl}
  />
}

export default HotelBundleSearchTilePricePoint

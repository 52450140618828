import React from 'react'
import Label from './Label'

type Variant = 'confirmation' | 'cancelled' | 'pending' | 'added'

const configMap: Record<Variant, React.ComponentProps<typeof Label>> = {
  confirmation: {
    kind: 'tertiary',
    variant: 'success',
  },
  cancelled: {
    kind: 'primary',
    variant: 'urgency',
  },
  pending: {
    kind: 'tertiary',
    variant: 'warning',
  },
  added: {
    kind: 'secondary',
    variant: 'ghost',
  },
}

interface Props {
  children: React.ReactNode;
  variant: Variant;
  className?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

function StatusLabel(props: Props) {
  const { variant, startIcon, endIcon, ...rest } = props

  return <Label
    {...rest}
    {...configMap[variant]}
    startIcon={startIcon}
    endIcon={endIcon}
  />
}

export default StatusLabel

import { rem } from 'polished'
import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { EnsureGoogleMapsLoaded } from 'contexts/GoogleMapsContext'
import { mediaQueryUp } from 'components/utils/breakpoint'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import { GoogleMap, OverlayView, OverlayViewF } from '@react-google-maps/api'
import config from 'constants/config'
import Tooltip from 'components/Luxkit/Tooltip'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import cn from 'clsx'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { unique } from 'lib/array/arrayUtils'

const MapContainer = styled.div`
  order: 1;

  ${mediaQueryUp.tablet} {
    order: 2;
    position: sticky;
    top: ${rem(115)};

    &.modal {
      position: relative;
      top: 0;
    }
  }

  .cruise-location-map {
    width: 100%;
    height: ${rem(450)};

    ${mediaQueryUp.tablet} {
      width: ${rem(475)};
    }
  }

  .cruise-location-map-modal {
    width: 100%;
    height: ${rem(280)};
  }
`

const Root = styled.div`
  position: relative;
  pointer-events: all;
  display: flex;
`

const StyledPill = styled.div`
  height: ${rem(24)};
  padding: 0 ${rem(8)};
  display: inline-flex;
  align-items: center;
  border-radius: ${rem(9999)};
  background-color: ${(props) => props.theme.palette.neutral.default.eight};
  border: ${rem(2)} solid ${(props) => props.theme.palette.neutral.default.one};

  &.active {
    background-color: ${(props) => props.theme.palette.neutral.default.one};
    border-color: ${(props) => props.theme.palette.neutral.default.eight};
    color: ${(props) => props.theme.palette.neutral.default.eight};
  }
`

const MAP_OPTIONS = {
  gestureHandling: 'greedy',
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControlOptions: { position: 3 },
  minZoom: 1,
  maxZoom: 19,
  mapId: config.GOOGLE_MAP_ID,
  clickableIcons: false,
}

// Calculate center of the map based on coordinates
function calculateMapCenter(coordinates: Array<App.MapMarker>) {
  let centerX = 0
  let centerY = 0

  for (const coordinate of coordinates) {
    centerX += coordinate.latitude
    centerY += coordinate.longitude
  }

  centerX /= coordinates.length
  centerY /= coordinates.length

  return {
    lat: centerX,
    lng: centerY,
  }
}

// Calculate zoom level based on distance between coordinates
function getMapZoom(coordinates: Array<App.MapMarker>) {
  const longitudes = coordinates.map((coordinate) => coordinate.longitude)
  const latitudes = coordinates.map((coordinate) => coordinate.latitude)
  const lngDistance = Math.max(...longitudes) - Math.min(...longitudes)
  const latDistance = Math.max(...latitudes) - Math.min(...latitudes)
  const maxDistance = Math.max(lngDistance, latDistance)

  let zoom = 4

  if (maxDistance > 100) zoom = 2
  if (maxDistance > 200) zoom = 1

  return zoom
}

interface Props {
  itinerary: Array<App.CruiseItineraryItem>;
  portNameSelected?: string;
  onSelectPort?: (portName: string | null) => void
  onMapLoaded?: (map: google.maps.Map) => void
  isModal?: boolean
}

function ItineraryMap({
  itinerary,
  portNameSelected,
  onSelectPort,
  onMapLoaded,
  isModal,
}: Props) {
  const markers = useMemo(() => {
    const itineraryWithPorts: Array<App.CruiseItineraryItem> = Object.values(itinerary.reduce((acc, item) => {
      if (!item.port) return acc
      return {
        ...acc,
        [`${item.startDay}_${item.port?.name}`]: item,
      }
    }, {}))

    // Group itinerary items by port
    let portNumber = 0
    let lastPortName = ''
    const itineraryByPort = itineraryWithPorts.reduce<Record<string, { port: App.CruisePort; days: Array<number>; ports: Array<number>; isStart: boolean, isEnd: boolean }>>((acc, { port, startDay }, i) => {
      if (!port) return acc

      const key = port.name
      const value = acc[key] || { port, days: [] as Array<number>, ports: [] as Array<number> }
      const isStart = i === 0
      const isEnd = i === itineraryWithPorts.length - 1
      const addPortCircle = !!port && lastPortName !== port?.name && i > 0

      if (addPortCircle) {
        portNumber += 1
        lastPortName = port.name
      }

      return {
        ...acc,
        [key]: {
          ...value,
          isStart,
          isEnd,
          days: unique([...value.days, startDay]),
          ports: unique([...value.ports, portNumber]),
        },
      }
    }, {})

    return Object.keys(itineraryByPort).map((key) => {
      const { port, days, isStart, isEnd, ports } = itineraryByPort[key]
      let name = `${ports.length > 1 ? 'Ports' : 'Port'} ${ports.join(', ')}`
      let type = 'cruise-pill'

      if (isStart && isEnd) {
        name = 'Start & End'
        type = 'cruise-pill-icon'
      } else if (isStart) {
        name = 'Start'
        type = 'cruise-pill-icon'
      } else if (isEnd) {
        name = 'End'
        type = 'cruise-pill-icon'
      }

      return {
        id: key,
        latitude: Number(port.latitude),
        longitude: Number(port.longitude),
        locationHeading: `Day ${days.join(', ')}: ${port.name}`,
        type,
        name,
      } as App.MapMarker
    })
  }, [itinerary])

  const onLoad = useCallback((map: google.maps.Map) => {
    const centralPoint = calculateMapCenter(markers)
    map.setCenter(centralPoint)
    map.setZoom(getMapZoom(markers))
    onMapLoaded?.(map)
  }, [markers, onMapLoaded])

  const setActiveMarker = useCallback((marker: App.MapMarker) => {
    if (portNameSelected === marker.id) {
      onSelectPort?.(null)
    } else {
      onSelectPort?.(marker.id)
    }
  }, [onSelectPort, portNameSelected])

  return (
    <MapContainer className={isModal ? 'modal' : ''}>
      <EnsureGoogleMapsLoaded loadingElement={<LoadingIndicator inline />}>
        <GoogleMap
          id="cruise-le-map"
          options={MAP_OPTIONS}
          onLoad={onLoad}
          mapContainerClassName={isModal ? 'cruise-location-map-modal' : 'cruise-location-map'}
        >
          {markers.map((marker, i) => {
            const isActive = portNameSelected === marker.id
            const zIndex = isActive ? (markers.length + 1) : (markers.length - i)

            return (
              <OverlayViewF
                key={marker.id}
                position={{
                  lat: marker.latitude ?? 0,
                  lng: marker.longitude ?? 0,
                }}
                zIndex={zIndex}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Root>
                  <Tooltip open={isActive} description={marker.locationHeading}>
                    <StyledPill
                      onClick={() => setActiveMarker(marker)}
                      className={cn({ active: isActive })}
                    >
                      <BodyTextBlock
                        startIcon={marker.type === 'cruise-pill-icon' ? <LineShipIcon /> : undefined}
                        variant="small"
                        weight="bold"
                      >
                        {marker.name || ''}
                      </BodyTextBlock>
                    </StyledPill>
                  </Tooltip>
                </Root>
              </OverlayViewF>
            )
          })}
        </GoogleMap>
      </EnsureGoogleMapsLoaded>
    </MapContainer>
  )
}

export default ItineraryMap

import LoadingBox from 'components/Common/Loading/LoadingBox'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButtonLoadingSkeleton from 'components/Luxkit/Button/TextButtonLoadingSkeleton'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Group from 'components/utils/Group'
import React from 'react'
import styled from 'styled-components'
import _SearchTileBase from './_SearchTileBase'
import _SearchTileContent from './_SearchTileContent'
import _SearchTileContentSecondary from './_SearchTileContentSecondary'
import _SearchTilePricing from './_SearchTilePricing'

const Primary = styled(Group)`
  grid-area: content-primary;
`

const Hero = styled(LoadingBox)`
  grid-area: hero;
`

function LoadingSearchTileSkeleton() {
  return <_SearchTileBase>
    <Hero />
    <_SearchTileContent>
      <Primary direction="vertical" gap={12}>
        <VerticalSpacer gap={4}>
          <TextLoadingBox typography="body-small" width="20ch"/>
          <TextLoadingBox typography="heading5" width="100%" lines={2} />
          <TextLoadingBox typography="body-medium" width="80%"/>
        </VerticalSpacer>
      </Primary>
      <_SearchTileContentSecondary>
        <_SearchTilePricing
          action={<TextButtonLoadingSkeleton width="10ch" />}
        >
        </_SearchTilePricing>
      </_SearchTileContentSecondary>
    </_SearchTileContent>
  </_SearchTileBase>
}

export default LoadingSearchTileSkeleton

export type TypeaheadItemType = App.SearchType | App.OfferType | 'bedbank_promotion'

export function getTypeaheadItemType(item: App.SearchItem): TypeaheadItemType {
  if (item.searchType === 'property') {
    if (item.bedbankHasPromotions) {
      return 'bedbank_promotion'
    } else if (item.offerType) {
      return item.offerType
    } else {
      return 'bedbank_hotel'
    }
  } else {
    return item.searchType
  }
}

import OfferBadgeLabel from 'components/Common/OfferBadgeLabel/OfferBadgeLabel'
import CruiseUrgencyLabel from 'components/Cruises/Common/CruiseUrgencyLabel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import { isCruiseV1Offer } from 'lib/offer/offerTypes'
import React from 'react'

interface Props {
  view: CruiseOfferView;
}

function CruiseSearchTileLabels({ view }: Props) {
  return <LabelGroup>
    {view.urgencyLabels.map(label => <CruiseUrgencyLabel
      key={label.type}
      offer={view.offer}
      label={label}
    />)}
    {isCruiseV1Offer(view.offer) && view.offer.badge && <OfferBadgeLabel badge={view.offer.badge} />}
  </LabelGroup>
}

export default CruiseSearchTileLabels

import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { OptimizelyExperiments } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { getBundleValueUp } from 'lib/bundleAndSave/getBundleValueUp'
import { isBundleOffer, isCruiseOffer } from 'lib/offer/offerTypes'
import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'

interface HotelOfferPricing {
  propertyFees: number;
  totalPrice: number;
  totalMemberPrice: number;
  totalMemberPriceWithFlights: number;
  totalValue: number;
  totalValueWithFlights: number;
  memberPriceOrPrice: number;
  valueOffPercent: number;
  memberValueOffPercent?: number;
  totalPriceWithFlights: number;
  flightPrice: number;
  saleUnit: string;
}

interface OfferPricing {
  total: number;
  value: number | undefined;
  duration?: number;
  saleUnit: string;
  propertyFees?: number;
  valueOffPercent?: number;
  displayPricingAsPerPerson?: boolean;
  memberTotal?: number;
}

export interface PriceOptions {
  flightPrice?: number;
  numberOfNights?: number;
  showMemberPrice?: boolean;
}

const pricePerUnit = (basePriceDetails: HotelOfferPricing, divisor: number, flightPrice: number = 0): Omit<HotelOfferPricing, 'saleUnit'> => {
  const totalValue = safeDivideAndCeil(basePriceDetails.totalValue, divisor)
  return {
    propertyFees: basePriceDetails.propertyFees,
    totalPrice: safeDivideAndCeil(basePriceDetails.totalPrice, divisor),
    totalPriceWithFlights: safeDivideAndCeil(basePriceDetails.totalPrice + flightPrice, divisor),
    flightPrice: safeDivideAndCeil(flightPrice, divisor),
    totalMemberPrice: safeDivideAndCeil(basePriceDetails.totalMemberPrice, divisor),
    totalMemberPriceWithFlights: safeDivideAndCeil(basePriceDetails.totalMemberPrice + flightPrice, divisor),
    totalValue,
    totalValueWithFlights: totalValue + flightPrice,
    memberPriceOrPrice: safeDivideAndCeil(basePriceDetails.memberPriceOrPrice, divisor),
    valueOffPercent: getValueOffPercent(basePriceDetails.totalValue, basePriceDetails.memberPriceOrPrice ? basePriceDetails.memberPriceOrPrice : basePriceDetails.totalPrice),
    memberValueOffPercent: basePriceDetails.totalMemberPrice ? getValueOffPercent(basePriceDetails.totalValue, basePriceDetails.totalMemberPrice) : undefined,
  }
}

const priceDefault = (basePriceDetails: HotelOfferPricing, flightPrice?: number): HotelOfferPricing => {
  return {
    ...pricePerUnit(basePriceDetails, 1, flightPrice),
    saleUnit: basePriceDetails.saleUnit,
  }
}

const packagePricePerNight = (basePriceDetails: HotelOfferPricing, _: App.Offer | App.OfferSummary | App.BundleOffer | App.BundleOfferSummary, options: PriceOptions): HotelOfferPricing => {
  if (!options.numberOfNights) return basePriceDetails
  return {
    ...pricePerUnit(basePriceDetails, options.numberOfNights, options?.flightPrice),
    saleUnit: 'night',
  }
}

const ratePricePerNight = (basePriceDetails: HotelOfferPricing, options: PriceOptions): HotelOfferPricing => {
  if (!options?.numberOfNights) return basePriceDetails
  return {
    ...pricePerUnit(basePriceDetails, options.numberOfNights, options?.flightPrice),
    saleUnit: 'night',
  }
}

const packagePriceDefault = (pkg: App.Package | App.BundlePackageWithPrice, offer: App.Offer | App.OfferSummary | App.BundleOffer | App.BundleOfferSummary, isActiveLuxPlusMember: boolean): HotelOfferPricing => {
  const propertyFees = pkg.propertyFees

  const totalPrice = pkg.price + propertyFees
  let totalValue = (isActiveLuxPlusMember ? pkg.memberValue : pkg.value) + propertyFees
  const totalMemberPrice = pkg.memberPrice > 0 ? pkg.memberPrice + propertyFees : 0
  const memberPriceOrPrice = totalMemberPrice > 0 && isActiveLuxPlusMember ? totalMemberPrice : totalPrice
  let valueOffPercent = getValueOffPercent(totalValue, memberPriceOrPrice)
  const saleUnit = offer.saleUnit

  if (isBundleOffer(offer) && 'packages' in pkg) {
    // TODO look into refactoring all logic into here if AB test is successful
    const bundleValue = getBundleValueUp(offer, pkg, isActiveLuxPlusMember)
    valueOffPercent = bundleValue.hotelDiscountPercent ?? 0
    totalValue = bundleValue.valuedUpTo
  }

  return {
    propertyFees,
    totalPrice,
    totalMemberPrice,
    totalValue,
    memberPriceOrPrice,
    valueOffPercent,
    saleUnit,
    totalValueWithFlights: totalValue,
    flightPrice: 0,
    totalPriceWithFlights: 0,
    totalMemberPriceWithFlights: 0,
  }
}

const ratePriceDefault = (offer: App.BedbankOffer | App.BedbankOfferSummary | undefined, rate?: App.BedbankRate): HotelOfferPricing | undefined => {
  const propertyFees = rate?.totals?.propertyFees ?? 0
  const hotelPrice = rate ? rate.totals.inclusive : offer?.sell?.price
  if (!hotelPrice) return
  const totalValue = rate?.value ?? offer?.sell?.value ?? hotelPrice
  const totalPrice = hotelPrice + propertyFees

  return {
    propertyFees,
    totalPrice,
    totalValue,
    valueOffPercent: getValueOffPercent(totalValue, totalPrice),
    flightPrice: 0,
    memberPriceOrPrice: totalPrice,
    totalMemberPrice: 0,
    totalPriceWithFlights: 0,
    totalMemberPriceWithFlights: 0,
    totalValueWithFlights: totalValue,
    saleUnit: 'total',
  }
}

/**
 * Returns pricing for offer or package if supplied without taking into account occupancies/rooms
 * **CAN BE AFFECTED BY THE FOLLOWING EXPERIMENTS**
 *  - Experiment: cro_price_per_night
 */
export function useOfferPackagePrice(
  pkg: App.Package | App.BundlePackageWithPrice,
  offer: App.Offer | App.OfferSummary | App.BundleOffer | App.BundleOfferSummary,
  options?: PriceOptions,
) {
  const variantPerNight = useOptimizelyExperiment(OptimizelyExperiments.pricePerNight)
  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const basePriceDetails = packagePriceDefault(pkg, offer, options?.showMemberPrice ?? canViewLuxPlusBenefits)
  const calculatedOptions:PriceOptions = {
    numberOfNights: pkg.duration || offer.minDuration,
    ...options || {},
  }
  const usePerNight = variantPerNight && !offer.holidayTypes?.includes('Cruises') && !isCruiseOffer(offer)
  return usePerNight ? packagePricePerNight(basePriceDetails, offer, calculatedOptions) : priceDefault(basePriceDetails, options?.flightPrice)
}

/**
 * Returns pricing for bedbank offer or rate if supplied without taking into account occupancies/rooms
 * **CAN BE AFFECTED BY THE FOLLOWING EXPERIMENTS**
 *  - Experiment: cro_price_per_night
 */
export function useOfferRatePrice(offer: App.BedbankOffer | App.BedbankOfferSummary | undefined, rate?: App.BedbankRate, options?: PriceOptions) {
  const variantPerNight = useOptimizelyExperiment(OptimizelyExperiments.pricePerNight)
  const basePriceDetails = ratePriceDefault(offer, rate)
  if (!basePriceDetails) return
  const hotelNights = rate?.nights ?? offer?.sell?.los
  return variantPerNight ? ratePricePerNight(basePriceDetails, { ...options, numberOfNights: hotelNights }) : priceDefault(basePriceDetails, options?.flightPrice)
}

const getBaseOfferPrice = (pricing: OfferPricing) => {
  return {
    ...pricing,
    valueOffPercent: getValueOffPercent(pricing.value || 0, pricing.total),
    total: pricing.total + (pricing.propertyFees || 0),
  }
}

const getBaseOfferPricePerNight = (pricing: OfferPricing) => {
  if (!pricing.duration) return pricing
  return {
    ...pricing,
    saleUnit: 'night',
    total: safeDivideAndCeil(pricing.total, pricing.duration),
    memberTotal: safeDivideAndCeil(pricing.memberTotal, pricing.duration),
    value: safeDivideAndCeil(pricing.value, pricing.duration),
    propertyFees: safeDivideAndCeil(pricing.propertyFees, pricing.duration),
  }
}

/**
 * Hook for handling different pricing formats
 * **CAN BE AFFECTED BY THE FOLLOWING EXPERIMENTS**
 *  - Experiment: cro_price_per_night
 */
export function useOfferPrice(pricing: OfferPricing): OfferPricing {
  const variantPerNight = useOptimizelyExperiment(OptimizelyExperiments.pricePerNight)
  const basePricing = getBaseOfferPrice(pricing)
  if (variantPerNight && !pricing.displayPricingAsPerPerson) {
    return getBaseOfferPricePerNight(basePricing)
  }
  return basePricing
}

/**
 * Hook for handling different pricing formats
 * **CAN BE AFFECTED BY THE FOLLOWING EXPERIMENTS**
 *  - Experiment: cro_price_per_night
 */
export function useOfferPriceWithMember(pricing: OfferPricing): OfferPricing {
  const variantPerNight = useOptimizelyExperiment(OptimizelyExperiments.pricePerNight)
  const basePricing = getBaseOfferPrice(pricing)
  if (variantPerNight && pricing.duration && !pricing.displayPricingAsPerPerson) {
    return {
      ...getBaseOfferPricePerNight(basePricing),
      memberTotal: safeDivideAndCeil(pricing.memberTotal, pricing.duration),
    }
  }
  return {
    ...basePricing,
    memberTotal: pricing.memberTotal,
  }
}

import LineGuidedtoursIcon from 'components/Luxkit/Icons/line/LineGuidedtoursIcon'
import LineHotAirBalloonIcon from 'components/Luxkit/Icons/line/LineHotAirBalloonIcon'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import LineTheatreIcon from 'components/Luxkit/Icons/line/LineTheatreIcon'
import LineTicketIcon from 'components/Luxkit/Icons/line/LineTicketIcon'
import LineWellnessIcon from 'components/Luxkit/Icons/line/LineWellnessIcon'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import eases from 'styles/tools/eases'

const FadingAnimation = keyframes`
  0% {
    opacity: 0;
  }

  4% {
    opacity: 1;
  }

  12% {
    opacity: 1;
  }

  16% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`

const AnythingWrapper = styled.div`
  position: relative;

  height: 24px;
  width: 24px;

  > * {
    position: absolute;
    opacity: 0;
    animation: ${FadingAnimation} 12s infinite ${eases.sine.inOut};
    inset: 0;
  }

  @media (prefers-reduced-motion: reduce) {
    > * {
      display: none;
      animation: none;
    }

    .fallback {
      display: block;
      opacity: 1;
    }
  }
`

function ExperienceAnythingCategoryIcon() {
  return (<AnythingWrapper>
    <LineRestaurantIcon style={{ animationDelay: '0s' }}/>
    <LineHotAirBalloonIcon style={{ animationDelay: '1.5s' }}/>
    <LineWellnessIcon style={{ animationDelay: '3s' }}/>
    <LineTicketIcon style={{ animationDelay: '4.5s' }}/>
    <LineTheatreIcon style={{ animationDelay: '6s' }}/>
    <LinePlaneFlyIcon style={{ animationDelay: '7.5s' }}/>
    <LineStarIcon className="fallback" style={{ animationDelay: '9s' }}/>
    <LineGuidedtoursIcon style={{ animationDelay: '10.5s' }}/>
  </AnythingWrapper>)
}

export default ExperienceAnythingCategoryIcon

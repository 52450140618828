import { groupByBundlePackages } from 'lib/bundleAndSave/groupByBundlePackages'
import { groupByOfferId } from 'lib/offer/offerUtils'

export function validateBundlePackages(offer: App.BundleOffer, bundledPackages: Array<App.Package>) {
  const groupedByBundle = groupByBundlePackages(offer, bundledPackages)
  const offerIds = Object.keys(offer.bundledOffers)

  const result: Map<string, App.Package> = new Map()
  for (const packages of Object.values(groupedByBundle)) {
    const groupedByOfferId = groupByOfferId(packages)
    const keys = Object.keys(groupedByOfferId)
    if (keys.length === offerIds.length) {
      for (const pkg of packages) {
        result.set(`${pkg.offerId}:${pkg.lePackageId}:${pkg.roomRate?.id}`, pkg)
      }
    }
  }

  return Array.from(result.values())
}

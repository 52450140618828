/*
* We've seen an increasing number of requests for offer specific changes and modifications.
* Here we create a centralised utility for checking and tracking these changes.
*/

// We want to limit the number of adults to 2 for Lanson offer and remove children
const only2AAllowedForLanson = (offerId: string) => {
  return offerId === '006Ii000002HpNcIAK'
}

export {
  only2AAllowedForLanson,
}

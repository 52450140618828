import React from 'react'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'

interface Props {
  rooms: Array<App.Occupants>;
}

export function BedbankTwinShare(props: Props) {
  const {
    rooms,
  } = props

  const showLabel = rooms.length === 1 && rooms[0].adults === 2 && !rooms[0].children && !rooms[0].infants

  if (!showLabel) {
    return null
  }

  return <PriceRowCaption>Twin share</PriceRowCaption>
}

export default BedbankTwinShare

import { createSelector } from 'reselect'
import { getAllOffers } from 'selectors/offerSelectors'
import { getBedbankItems, getLeAccommodationItems } from './view/accommodation'

export const hasFijiHotelInCart = createSelector(
  getAllOffers,
  getLeAccommodationItems,
  getBedbankItems,
  (offers, bedbankItems, leHotelItem) => {
    const isFijiBedbank = bedbankItems.some(item => (offers[item.offerId] as App.BedbankOffer)?.property?.timezone === 'Pacific/Fiji') ?? false
    const isFijiHotel = leHotelItem.some(item => (offers[item.offerId] as App.HotelOffer)?.property?.timezone === 'Pacific/Fiji') ?? false

    return isFijiBedbank || isFijiHotel
  },
)

import React, { useCallback } from 'react'

import InputLabelWrap from 'components/Common/Form/Input/InputLabelWrap'
import OccupancyItem from 'components/Common/OccupancyPicker/OccupancyItem'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import SolidPlusIcon from 'components/Luxkit/Icons/solid/SolidPlusIcon'
import uuidV4 from 'lib/string/uuidV4Utils'
import { TravellerRoom } from 'tripPlanner/types/common'

export type TravellerRoomWithId = TravellerRoom & { id: string }

function addIdToRoom(room: TravellerRoom): TravellerRoomWithId {
  return { ...room, id: uuidV4() }
}

export function addIdsToRooms(
  rooms: Array<TravellerRoom>,
): Array<TravellerRoomWithId> {
  return rooms.map(addIdToRoom)
}

export const defaultRoom: TravellerRoom = {
  adults: 2,
  childAges: [],
}

function travellersToOccupants(travellers: TravellerRoom): App.Occupants {
  const { adults, childAges } = travellers
  return {
    adults,
    children: childAges.length,
    infants: 0,
    childrenAge: childAges,
  }
}

function occupantsToTravellers(occupants: App.Occupants): TravellerRoom {
  const { adults, childrenAge } = occupants
  return {
    adults,
    childAges: childrenAge ?? [],
  }
}

const MAX_ROOMS = 10

interface Props {
  travellerRooms: Array<TravellerRoomWithId>
  onChange: (travellerRooms: Array<TravellerRoomWithId>) => void
  allowEmpty: boolean
}

function TripTravellersField({ travellerRooms, onChange, allowEmpty }: Props) {
  const updateRoom = useCallback(
    (index: number, occupancy: App.Occupants) => {
      const roomId = travellerRooms[index].id
      const newTravellerRooms = [...travellerRooms]
      newTravellerRooms[index] = {
        id: roomId,
        ...occupantsToTravellers(occupancy),
      }
      onChange(newTravellerRooms)
    },
    [onChange, travellerRooms],
  )

  const removeRoom = useCallback(
    (index: number) => {
      const newTravellerRooms = [...travellerRooms]
      newTravellerRooms.splice(index, 1)
      onChange(newTravellerRooms)
    },
    [onChange, travellerRooms],
  )

  const addRoom = useCallback(() => {
    const newTravellerRooms = [...travellerRooms]
    newTravellerRooms.push(addIdToRoom(defaultRoom))
    onChange(newTravellerRooms)
  }, [onChange, travellerRooms])

  return (
    <VerticalSpacer gap={4}>
      <InputLabelWrap label="Who is going?" />
      <VerticalSpacer gap={20}>
        {travellerRooms.map((travellerRoom, index) => (
          <OccupancyItem
            key={travellerRoom.id}
            itemId={index}
            occupancy={travellersToOccupants(travellerRoom)}
            onChange={updateRoom}
            childrenAgeLabel="at time of travel"
            name="travellers"
            title={`Room ${index + 1}`}
            showRemove={allowEmpty || index > 0}
            onRemove={removeRoom}
            showResetWhenClean
          />
        ))}
        <div>
          {travellerRooms.length < MAX_ROOMS && (
            <TextButton
              kind="tertiary"
              outdent="left"
              startIcon={<SolidPlusIcon />}
              onClick={addRoom}
            >
              {travellerRooms.length === 0 && 'Add travellers'}
              {travellerRooms.length > 0 && 'Add another room'}
            </TextButton>
          )}
        </div>
      </VerticalSpacer>
    </VerticalSpacer>
  )
}

export default TripTravellersField

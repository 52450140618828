import { randomIntegerBetween } from 'lib/maths/mathUtils'

/**
 *
 * @TODO replace when svc is ready
 */
export function mockPointsAndCreditsEarned() {
  const statusCreditsEarned = randomIntegerBetween(100, 500)

  return {
    orderPointsEarned: statusCreditsEarned * 10,
    statusCreditsEarned,
  }
}

import React, { useContext, useCallback, useState, useMemo, useRef } from 'react'
import useToggle from 'hooks/useToggle'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { GlobalSearchStateContext, GlobalSearchDispatchContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import IconButton from 'components/Luxkit/Button/IconButton'
import CruiseLocationContent from './CruiseLocationContent'
import CruiseFilterFooter from '../Common/CruiseFilterFooter'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Caption from 'components/Luxkit/Typography/Caption'
import useGlobalSearchTypeahead from 'hooks/GlobalSearch/useGlobalSearchTypeahead'
import { TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES, TOUR_V2_SEARCH_TYPES } from 'constants/tours'

const SET_SEARCH_ITEMS = GlobalSearchStateActions.SET_SEARCH_ITEMS

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void;
}

function CruiseLocationMobileInput({ onChange }: Props) {
  const inputTriggerRef = useRef<HTMLButtonElement>(null)
  const inputSearchRef = useRef<HTMLInputElement>(null)

  const { searchItems } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const { suggestedSearchItems } = useContext(GlobalSearchStateContext)

  const [searchTerm, setSearchTerm] = useState('')
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [isOpen, toggle,, close] = useToggle(false)

  const [facets] = useCruiseSearchFacets({ facetTypes: ['destination_ports'] })
  useGlobalSearchTypeahead({
    search: searchTerm,
    searchTypes: TOUR_V2_SEARCH_TYPES,
    typeaheadTypes: TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES,
  })

  const locations = useMemo(() => {
    if (!searchTerm) return facets.filter(({ isTrend }) => isTrend)

    let filtered = facets.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
    if (!filtered.length) {
      filtered = facets.filter(({ name }) => {
        return suggestedSearchItems?.find((suggestedValue) => {
          return name.includes(suggestedValue?.format?.secondaryText!)
        })
      })
    }
    return filtered.slice(0, 100)
  }, [searchTerm, facets, suggestedSearchItems])

  const inputValue = useMemo(() => {
    if (isOpen) return searchTerm
    if (searchItems.length > 1) {
      const names = searchItems.slice(0, 1).map((item) => item.format.mainText).join(', ')
      return `${names} + ${searchItems.length - 1}`
    }
    return searchItems.map((item) => item.format.mainText).join(', ')
  }, [searchItems, isOpen, searchTerm])

  const handleOnApply = useCallback(() => {
    onChange({ searchItems })
    close()
  }, [onChange, searchItems, close])

  const handleOnClear = useCallback(() => {
    searchDispatch({ type: SET_SEARCH_ITEMS, searchItems: [] })
    setSearchTerm('')
    setIsAllSelected(false)
  }, [searchDispatch])

  const handleOnChange = useCallback((searchItems: Array<App.SearchItem>) => {
    searchDispatch({ type: SET_SEARCH_ITEMS, searchItems })
    setIsAllSelected(false)
  }, [searchDispatch])

  const handleSelectAll = useCallback(() => {
    searchDispatch({ type: SET_SEARCH_ITEMS, searchItems: [] })
    setIsAllSelected(!isAllSelected)
  }, [searchDispatch, isAllSelected])

  const handlerSearchTerm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [])

  const handlerClearSearchTerm = useCallback(() => setSearchTerm(''), [])

  return <>
    <SearchFormField
      onClick={toggle}
      ref={inputTriggerRef}
      label="Cruising to"
      placeholder="All destinations"
      value={inputValue}
    />

    <ModalBase
      mode="drawer"
      isOpen={isOpen}
      onClose={toggle}
    >
      <ModalHeader
        title="Cruising to"
        onCloseButtonClick={toggle}
      />
      <ModalBody>
        <ModalContent>
          <VerticalSpacer gap={12}>
            <TextInputOnly
              ref={inputSearchRef}
              noValidationSpacing
              placeholder="Search destinations"
              defaultValue={inputValue}
              onChange={handlerSearchTerm}
              endIcon={searchTerm.length > 0 ?
                <IconButton kind="tertiary" onClick={handlerClearSearchTerm}><LineTimesIcon /></IconButton> :
                <LineSearchIcon />
              }
            />

            <Caption variant="large" weight="bold">
              {searchTerm ? `Search results for "${searchTerm}"` : 'or browse by popular regions'}
            </Caption>

            <CruiseLocationContent
              locations={locations}
              searchTerm={searchTerm}
              searchItems={searchItems}
              onChange={handleOnChange}
              onSelectAll={handleSelectAll}
              inputSearchRef={inputSearchRef}
              isAllSelected={isAllSelected}
            />
          </VerticalSpacer>
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <CruiseFilterFooter
          onClear={handleOnClear}
          onApply={handleOnApply}
        />
      </ModalFooter>
    </ModalBase>
  </>
}

export default CruiseLocationMobileInput

import watch from 'redux-watch'

import { AppStore } from 'src/client/store'
import { pathChange } from 'actions/NavigationActions'

export function watchNavigation(store: AppStore) {
  store.subscribe(
    watch(store.getState, 'router.location.pathname')(() => {
      store.dispatch(pathChange())
    }),
  )
}

import React from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import Pane from 'components/Common/Pane'
import BodyText from 'components/Luxkit/Typography/BodyText'

const CardContent = styled.div`
  padding: ${rem(40)} ${rem(16)};
`

function CruiseSoldoutDepartureCard() {
  return <Pane shadow>
    <CardContent>
      <Group direction="vertical" horizontalAlign="center" gap={16}>
        <BodyText variant="large" weight="semi-bold" align="center">
          No departures available
        </BodyText>
        <TextButton kind="secondary" to="/cruises">Browse all cruise offers</TextButton>
      </Group>
    </CardContent>
  </Pane>
}

export default CruiseSoldoutDepartureCard

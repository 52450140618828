import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineBusIcon from 'components/Luxkit/Icons/line/LineBusIcon'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import LineMapPinIcon from 'components/Luxkit/Icons/line/LineMapPinIcon'
import LineNotesIcon from 'components/Luxkit/Icons/line/LineNotesIcon'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import LineSubwayIcon from 'components/Luxkit/Icons/line/LineSubwayIcon'
import LineTicketIcon from 'components/Luxkit/Icons/line/LineTicketIcon'
import LineUniversityIcon from 'components/Luxkit/Icons/line/LineUniversityIcon'
import { SvgIconComponent } from 'components/Luxkit/SvgIcon'
import LeIcon from 'tripPlanner/components/Icons/LeIcon'
import {
  ItemType,
  LETripItem,
  ParentItemType,
} from 'tripPlanner/types/tripItem'

interface ParentItemViewModel {
  name: string
  icon: SvgIconComponent
  description?: string
}

interface ItemViewModel {
  name: string
  singularName: string
  icon: SvgIconComponent
  editable: boolean
  labels?: {
    place?: string
    phone?: string
    confirmation?: string
    url?: string
    notes?: string
  }
}

export const ParentItemMetadata: Record<ParentItemType, ParentItemViewModel> = {
  LE_BOOKINGS: {
    name: 'My Luxury Escapes Bookings',
    icon: LeIcon,
  },
  THINGS_TO_DO: {
    name: 'Things to do & see',
    icon: LineMapMarkerIcon,
    description: 'Attractions, restaurants, activities and more',
  },
  FLIGHT: {
    name: 'Flight',
    icon: LinePlaneFlyIcon,
  },
  ACCOMMODATION: {
    name: 'Accommodation',
    icon: LineBedIcon,
  },
  TOUR_AND_CRUISES: {
    name: 'Tours & Cruises',
    icon: LineCompassIcon,
    description: 'Multi-day guided experiences',
  },
  TOURS: {
    name: 'Tour',
    icon: LineCompassIcon,
    description: 'Day or multi-day tours',
  },
  CRUISES: {
    name: 'Cruise',
    icon: LineShipIcon,
  },
  CAR: {
    name: 'Car Rental',
    icon: LineCarIcon,
    description: 'Renting private vehicles',
  },
  TRANSPORT: {
    name: 'Transport',
    icon: LineCarIcon,
    description: 'Car rental, rail, bus, ferry',
  },
  NOTE: {
    name: 'Note',
    icon: LineNotesIcon,
  },
}

const baseLabels: ItemViewModel['labels'] = {
  notes: 'Additional notes',
}

export const LeItemProductTitle: Record<
  Exclude<LETripItem['sourceType'], 'NA' | 'Explore'>,
  string
> = {
  LE_Cruise: 'Cruise',
  LE_Experience: 'Experience',
  LE_Flight: 'Flight',
  LE_Hotel: 'Hotel',
  LE_Hotel_Bedbank: 'Hotel',
  LE_Tour: 'Tour',
  LE_Accommodation_Rental: 'Villa',
}

export const ItemMetadata: Record<ItemType, ItemViewModel> = {
  ACCOMMODATION: {
    name: 'Accommodation',
    singularName: 'Accommodation',
    icon: LineBedIcon,
    labels: {
      ...baseLabels,
      place: 'Place name',
      phone: 'Phone number',
      confirmation: 'Confirmation code',
    },
    editable: true,
  },
  ATTRACTION: {
    name: 'Attractions',
    singularName: 'Attraction',
    icon: LineUniversityIcon,
    editable: true,
  },
  CAR_RENTAL: {
    name: 'Car Rental',
    singularName: 'Car Hire',
    icon: LineCarIcon,
    editable: true,
  },
  EVENT: {
    name: 'Events',
    singularName: 'Event',
    icon: LineTicketIcon,
    editable: true,
  },
  EXPERIENCE: {
    name: 'Experiences',
    singularName: 'Experience',
    icon: LineTicketIcon,
    editable: false,
  },
  FLIGHT: {
    name: 'Flights',
    singularName: 'Flight',
    icon: LinePlaneFlyIcon,
    labels: {
      ...baseLabels,
      confirmation: 'Confirmation code',
    },
    editable: true,
  },
  RESTAURANT_BAR: {
    name: 'Restaurants & Bars',
    singularName: 'Restaurant/Bar',
    icon: LineRestaurantIcon,
    labels: {
      ...baseLabels,
      place: 'Name',
      url: 'Website',
      confirmation: 'Reservation number',
    },
    editable: true,
  },
  BUS: {
    name: 'Buses',
    singularName: 'Bus',
    icon: LineBusIcon,
    editable: true,
  },
  FERRY: {
    name: 'Ferries',
    singularName: 'Ferry',
    icon: LineShipIcon,
    editable: true,
  },
  OTHER_TRANSPORT: {
    name: 'Private Transport',
    singularName: 'Private Transport',
    icon: LineCarIcon,
    editable: true,
  },
  THING_TO_DO: {
    name: 'Other',
    singularName: 'Thing to do',
    icon: LineMapPinIcon,
    editable: true,
  },
  TRAIN: {
    name: 'Trains',
    singularName: 'Train',
    icon: LineSubwayIcon,
    editable: true,
  },
  TOUR: {
    name: 'Tours',
    singularName: 'Tour',
    icon: LineCompassIcon,
    editable: true,
  },
  CRUISE: {
    name: 'Cruises',
    singularName: 'Cruise',
    icon: LineShipIcon,
    editable: true,
  },
  NOTE: {
    name: 'Notes',
    singularName: 'Note',
    icon: LineNotesIcon,
    editable: true,
  },
}

export const FilterIcons: Record<
  App.TripPlannerSubFilterType,
  SvgIconComponent
> = {
  Stay: ItemMetadata.ACCOMMODATION.icon,
  Tour: ItemMetadata.TOUR.icon,
  Flight: ItemMetadata.FLIGHT.icon,
  'Thing to do': ItemMetadata.EXPERIENCE.icon,
  Restaurant: ItemMetadata.RESTAURANT_BAR.icon,
  Transport: ItemMetadata.OTHER_TRANSPORT.icon,
  Cruise: ItemMetadata.CRUISE.icon,
  Note: ItemMetadata.NOTE.icon,
}

export const FilterLabels: Record<App.TripPlannerSubFilterType, string> = {
  Stay: 'Stays',
  Tour: ItemMetadata.TOUR.name,
  Flight: ItemMetadata.FLIGHT.name,
  Transport: 'Transport',
  'Thing to do': 'Things to do',
  Restaurant: 'Restaurants',
  Cruise: ItemMetadata.CRUISE.name,
  Note: ItemMetadata.NOTE.name,
}

/** Don't change the analytics values, even if we change the labels elsewhere */
export const FilterAnalyticsLabels: Record<
  App.TripPlannerSubFilterType,
  string
> = {
  Stay: 'stays',
  Tour: 'tours',
  Flight: 'flights',
  Transport: 'transport',
  'Thing to do': 'things_to_do',
  Restaurant: 'restaurants',
  Cruise: 'cruises',
  Note: 'notes',
}

import { FetchQuoteParams } from 'actions/InsuranceActions'
import { CustomisableProductIds } from 'constants/insurance'
import getObjectKey from 'lib/object/getObjectKey'

interface InsuranceProductKeyOptions {
  isDomestic?: boolean,
  isCruise?: boolean,
  subscriptionId?: string,
}

export function getInsuranceProductListKey(type: App.InsuranceProductType, options: InsuranceProductKeyOptions = {}) {
  return getObjectKey({
    type,
    isDomestic: options.isDomestic,
    isCruise: options.isDomestic,
    subscriptionid: options.subscriptionId,
  })
}

export function getInsuranceQuoteKey(
  productId: string,
  options: Partial<FetchQuoteParams>,
) {
  return getObjectKey({
    productId,
    ...options,
  })
}

export function isCustomisableInsuranceProduct(productId: string) {
  return CustomisableProductIds.has(productId)
}

export function insuranceTypeToInsuranceProductType(type: App.InsuranceType): App.InsuranceProductType {
  if (type === 'cfmr') return 'booking-protection'
  return 'travel-insurance'
}

import cn from 'clsx'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { rem } from 'polished'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import Input from '../../Common/Form/Input/Input'
import LineCheckIcon from '../Icons/line/LineCheckIcon'
import LineMinusIcon from '../Icons/line/LineMinusIcon'
import { SVG_ICON_SIZE_CSS_VAR } from '../SvgIcon'

const Container = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;
  flex-shrink: 0;

  &.size-small {
    height: ${rem(16)};
    width: ${rem(16)};
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(12)};
  }
  &.size-medium {
    height: ${rem(20)};
    width: ${rem(20)};
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(20)};
  }
  &.size-large {
    height: ${rem(24)};
    width: ${rem(24)};
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(20)};
  }
`

const CheckIcon = styled(LineCheckIcon)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  place-self: center;
  pointer-events: none;
`

const MinusIcon = styled(LineMinusIcon)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  place-self: center;
  pointer-events: none;
`

const CheckboxElement = styled(Input)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  appearance: none;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.2s, border-color 0.2s;

  &:checked:not(:indeterminate) ~ ${CheckIcon} { display: block; }
  &:not(:checked) ~ ${CheckIcon} { display: none; }
  &:indeterminate ~ ${MinusIcon} { display: block; }
  &:not(:indeterminate) ~ ${MinusIcon} { display: none; }

  &:not(:checked), &:not(:indeterminate) {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    border-color: ${props => props.theme.palette.neutral.default.four};

    &:disabled {
      background-color: ${props => props.theme.palette.neutral.default.six};
      border-color: ${props => props.theme.palette.neutral.default.five};
    }
  }

  &:checked, &:indeterminate {
    background-color: ${props => props.theme.palette.brand.primary.normal};
    border-color: ${props => props.theme.palette.brand.primary.normal};

    &:disabled {
      background-color: ${props => props.theme.palette.neutral.default.four};
      border-color: ${props => props.theme.palette.neutral.default.four};
    }
  }

  &:not(:disabled) {
    &:focus {
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid var(--palette-neutral-default-four));
    }

    &.dirty:invalid {
      border-color: ${props => props.theme.palette.messaging.critical.normalForeground};
    }

    ${mediaHoverable} {
      &:hover {
        border-color: ${props => props.theme.palette.brand.primary.hover};
      }
    }
  }
`

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> {
  /**
   * A 3rd state in addition to being `checked`.
   * **Visually** putting the input in an indeterministic state.
   *
   * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes)
   */
  indeterminate?: boolean;
  /**
   * @default medium
   */
  size?: 'small' | 'medium' | 'large'
}

/**
 * The rectangular shaped checkbox input.
 *
 * To pair it with a label, see `<CheckboxInput>`
 */
const CheckboxButton = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, className, size = 'medium', indeterminate, ...inputProps } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const [isDirty, setDirty] = useState<boolean>(false)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate
    }
  }, [indeterminate])

  useImperativeHandle(ref, () => inputRef.current!)

  return <Container className={cn(className, `size-${size}`)}>
    <CheckboxElement
      {...inputProps}
      ref={inputRef}
      className={cn({ dirty: isDirty })}
      onDirtyChange={setDirty}
      type="checkbox"
    />
    <CheckIcon colour="neutral-eight" />
    <MinusIcon colour="neutral-eight" />
  </Container>
})

export default CheckboxButton

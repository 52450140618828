import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useContext } from 'react'

import GeoContext from 'contexts/geoContext'
import { processBookmarks } from 'tripPlanner/api/bookmark'
import {
  BookmarkError,
  BookmarkRequest,
  BookmarkResponse,
} from 'tripPlanner/api/bookmark/types'
import { getBasicTrip, invalidateTrip } from 'tripPlanner/hooks/api'
import { BasicTrip } from 'tripPlanner/types/common'

export function useProcessBookmarks(
  options?: UseMutationOptions<
    BookmarkResponse['result'],
    BookmarkError,
    Omit<BookmarkRequest, 'region'>,
    BasicTrip
  >,
): UseMutationResult<
  BookmarkResponse['result'],
  BookmarkError,
  Omit<BookmarkRequest, 'region'>,
  BasicTrip
> {
  const queryClient = useQueryClient()
  const { currentRegionCode } = useContext(GeoContext)

  return useMutation({
    mutationFn: async(args) => {
      // Bookmark currently doesn't support multiple items, will follow up with service PR
      const results = await Promise.all(
        args.items.map((i) =>
          processBookmarks({ ...args, items: [i], region: currentRegionCode }),
        ),
      )

      return results.reduce(
        (val, i) => {
          return {
            created: val.created.concat(i.created),
            updated: val.updated.concat(i.updated),
            removed: val.removed.concat(i.removed),
          }
        },
        {
          created: [],
          removed: [],
          updated: [],
        },
      )
    },
    ...options,
    onMutate: (vars) => {
      options?.onMutate?.(vars)
      // If the trip can be saved to, or removed from, then it already exists in our basic trip list cache
      return getBasicTrip(vars.tripIdsAdded[0], queryClient)
    },
    onSuccess: (data, vars, trip) => {
      options?.onSuccess?.(data, vars, trip)

      vars.tripIdsAdded.forEach((tripId) => {
        invalidateTrip(queryClient, tripId)
      })

      vars.tripIdsRemoved.forEach((tripId) => {
        invalidateTrip(queryClient, tripId)
      })
    },
  })
}

import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { TypographyElement, TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from '../Typography/Typography'
import { mediaQueryUp } from 'components/utils/breakpoint'

export const _TextBlockIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:empty {
    display: none;
  }

  > button, a {
    display: flex;
  }
`

export const _TextBlockStyle = css`
  display: flex;
  align-items: start;
  gap: var(--text-block-gap);

  &.fit-stretch {
    > ${TypographyElement} {
      flex-grow: 1;
    }
  }

  &.fit-auto {
    > ${TypographyElement} {
      flex-grow: 0;
    }
  }

  &.align-start {
    justify-content: start;
  }

  &.align-center {
    justify-content: center;
  }

  &.align-end {
    justify-content: end;
  }

  ${mediaQueryUp.tablet} {
    &.align-on-tablet-start {
      justify-content: start;
    }

    &.align-on-tablet-center {
      justify-content: center;
    }

    &.align-on-tablet-end {
      justify-content: end;
    }
  }

  > ${_TextBlockIcon} {
    height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});
  }
`

export interface TextBlockProps {
  fit?: 'stretch' | 'auto'
  startIcon?: ReactNode
  endIcon?: ReactNode
}

import { AnyAction } from 'redux'

function pinData(data: App.MapPropertyItem) {
  return ({
    category: 'map-view',
    pinId: data.id,
    offerId: data.offerId,
    continentCode: data.continentCode,
    countryCode: data.countryCode,
    country: data.country,
    administrativeAreaLevel1: data.administrativeAreaLevel1,
    administrativeAreaLevel2: data.administrativeAreaLevel2,
  })
}

export function mapPropertyPinClick(action: AnyAction) {
  return ({
    hitType: 'map-property-pin-click',
    mapDetails: pinData(action.data),
  })
}

export function mapOfferInfoClick(action: AnyAction) {
  const {
    lowestPricePackage,
    id,
    property,
  } = action.data
  const pinId = property?.id || lowestPricePackage.tour.id
  const geoData = property?.geoData || lowestPricePackage.tour.geoData
  return {
    hitType: 'map-property-offer-click',
    mapDetails: {
      category: 'map-view',
      offerId: id,
      pinId,
      continentCode: geoData.continentCode,
      countryCode: geoData.countryCode,
      country: geoData.country,
      administrativeAreaLevel1: geoData.administrativeAreaLevel1,
      administrativeAreaLevel2: geoData.administrativeAreaLevel2,
    },
  }
}

export const mapPinOfferView = (action: AnyAction) => ({
  hitType: 'map-property-offer-view',
  mapDetails: pinData(action.data),
})

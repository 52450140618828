import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineDesktopIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 3H5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h6v2H7a1.0002 1.0002 0 0 0-.7071 1.7071C6.4804 20.8946 6.7348 21 7 21h10a1.0001 1.0001 0 0 0 0-2h-4v-2h6a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3Zm1 11a1.0001 1.0001 0 0 1-1 1H5a1.0002 1.0002 0 0 1-1-1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8Z"/>
  </SvgIcon>
}

export default LineDesktopIcon

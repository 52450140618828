import { rem } from 'polished'

import { CreateGeoPlaceProp } from './types/common'

export const DEFAULT_TRIP_IMAGE =
  'https://images.unsplash.com/photo-1465056836041-7f43ac27dcb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80'

export const SYDNEY_PLACE_INFO: CreateGeoPlaceProp = {
  id: 'ChIJP3Sa8ziYEmsRUKgyFmh9AQM',
  name: 'Sydney',
}

export const MAX_TRIP_NAME_CHARS = 50

export const headerHeight = {
  sm: '56px',
  lg: '60px',
}

export const tripItemsMaxWidth = rem(1000)

export const SEARCH_FLIGHTS_COPY = 'Search flights'
export const VIEW_OFFER_COPY = 'View offer'
export const SAVE_ITEM_COPY = 'Save to my trip'
export const BOOKING_DETAILS_COPY = 'Booking details'

export const TRIP_SURVEY_SLUG = 'trip-planner-feedback'

export const TRIP_PLANNER_PATH = '/trip-planner'
export const TRIP_PLANNER_CURATED_PATH = `${TRIP_PLANNER_PATH}/curated` as const
export const TRIP_PLANNER_ABOUT_PATH = `${TRIP_PLANNER_PATH}/about` as const

export const TRIP_IMAGE_IDS = [
  'trip-planner-default-image-01',
  'trip-planner-default-image-02',
  'trip-planner-default-image-03',
  'trip-planner-default-image-04',
  'trip-planner-default-image-05',
  'trip-planner-default-image-06',
  'trip-planner-default-image-07',
  'trip-planner-default-image-08',
  'trip-planner-default-image-09',
  'trip-planner-default-image-10',
  'trip-planner-default-image-11',
  'trip-planner-default-image-12',
  'trip-planner-default-image-13',
  'trip-planner-default-image-14',
  'trip-planner-default-image-15',
  'trip-planner-default-image-16',
]
export const DEFAULT_FLIGHT_IMAGE_ID = 'trip-planner-default-image-13'

export const HIDDEN_SUMMARIES_LOCAL_STORAGE_KEY = 'tripPlannerHiddenSummaries'
export const MINIMISED_SUMMARIES_LOCAL_STORAGE_KEY = 'tripPlannerMinimisedSummaries'
export const TRIP_PLANS_LAST_SELECTED_SORT_OPTION_STORAGE_KEY = 'tripPlannerTripPlansLastSelectedSortOption'
export const TRIP_PLANS_LAST_SELECTED_FILTERS_OPTION_STORAGE_KEY = 'tripPlannerTripPlansLastSelectedFiltersOption'
export const SAVED_ITEMS_LIST_LAST_SELECTED_SORT_OPTION_STORAGE_KEY = 'tripPlannerSavedItemsListLastSelectedSortOption'

export const ENABLED_TRIP_RESTORE_CART_CHECKOUT_ITEMS = new Set<
  App.Checkout.AnyItem['itemType']
>(['bedbankHotel', 'experience', 'hotel', 'flight', 'villa'])

export const DESTINATION_PRODUCT_PAGE_CURATED_TRIP_POSITION = 3

import Caption from 'components/Luxkit/Typography/Caption'
import React from 'react'

interface Props {
  location?: string;
  primaryCategory?: string;
}

function ExperienceLocationCaption({ location, primaryCategory }: Props) {
  return (
    <Caption variant="large" lineClamp={1}>
      {location && <Caption as="span" variant="large" colour="neutral-two">{location}</Caption>}
      {location && primaryCategory && <>{' '}<Caption as="span" variant="large" colour="neutral-three">·</Caption>{' '}</>}
      {primaryCategory && <Caption as="span" variant="large" colour="neutral-three">{primaryCategory}</Caption>}
    </Caption>
  )
}

export default ExperienceLocationCaption

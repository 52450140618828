import { rem } from 'polished'
import styled from 'styled-components'

const OffsetBookmarkContainer = styled.div`
  position: absolute;
  right: ${rem(16)};
  top: 0;
  transform: translateY(-50%);
`
export default OffsetBookmarkContainer

import React, { useCallback, useEffect, useState } from 'react'
import cn from 'clsx'
import { rem } from 'polished'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as Analytics from 'analytics/analytics'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import Group from 'components/utils/Group'
import Clickable from 'components/Common/Clickable'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import LineFamilyIcon from 'components/Luxkit/Icons/line/LineFamilyIcon'
import OccupancyItem from 'components/Common/OccupancyPicker/OccupancyItem'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import LineUserPlusIcon from 'components/Luxkit/Icons/line/LineUserPlusIcon'
import { UserPreferencesTravellerOption } from '../types'
import { updateUserPreferenceOccupancy } from 'actions/userTravelPreferencesActions'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { capitaliseFirstLetterOnly } from 'lib/string/stringUtils'

const TravellersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${rem(16)};
  grid-row-gap: ${rem(24)};
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    grid-column-gap: ${rem(12)};
    grid-template-columns: repeat(3, 1fr);
  }
  ${mediaQueryUp.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const TravellerOption = styled(Clickable)`
  padding: ${rem(20)};
  border: 2px solid ${props => props.theme.palette.neutral.default.five};

  &.selected {
    border: 1px solid ${props => props.theme.palette.brand.primary.normal};
  }
`

const travellerOptions: Array<UserPreferencesTravellerOption> = [
  {
    groupSize: 'Solo',
    occupants: [{ adults: 1 }],
    Icon: <LineUserIcon size="L" />,
  },
  {
    groupSize: 'Couple',
    occupants: [{ adults: 2 }],
    Icon: <LineUsersAltIcon size="L" />,
  },
  {
    groupSize: 'Family',
    occupants: [{ adults: 2, childrenAge: [5], children: 1 }],
    Icon: <LineFamilyIcon size="L" />,

  },
  {
    groupSize: 'Group',
    occupants: [{ adults: 1 }, { adults: 1 }],
    Icon: <LineUserPlusIcon size="L" />,
  },
]

interface Props {
  occupancyPreferences?: App.UserPreferenceOccupancy;
  onSaveLastStep: () => void;
}

function TravelPreferencesModalTravellers(props: Props) {
  const { occupancyPreferences, onSaveLastStep } = props

  const dispatch = useAppDispatch()
  const userId = useAppSelector(getCurrentUserId)
  const [selectedTravellers, setSelectedTravellers] = useState<Array<App.Occupants>>([])
  const [selectedGroupSize, setSelectedGroupSize] = useState<string>('Solo')

  useEffect(() => {
    if (occupancyPreferences) {
      setSelectedTravellers(occupancyPreferences.rooms)
      setSelectedGroupSize(capitaliseFirstLetterOnly(occupancyPreferences.groupSizes || 'Solo'))
    }
  }, [occupancyPreferences])

  const onOccupancyChange = useCallback((index: number, nextOccupancy: App.Occupants) => {
    setSelectedTravellers((prev) => {
      const newOccupants = [...prev]
      newOccupants[index] = nextOccupancy
      return newOccupants
    })
  }, [setSelectedTravellers])

  const onOccupancyRemove = useCallback((index: number) => {
    setSelectedTravellers((prev) => {
      const newOccupants = [...prev]
      newOccupants.splice(index, 1)
      return newOccupants
    })
  }, [setSelectedTravellers])

  const onSelectGroupSize = useCallback((item: UserPreferencesTravellerOption) => {
    if (item.groupSize === selectedGroupSize) return

    setSelectedTravellers(item.occupants)
    setSelectedGroupSize(item.groupSize)
  }, [selectedGroupSize])

  const addRoom = useCallback(() => {
    setSelectedTravellers((prev) => [...prev, { adults: 1 }])
  }, [])

  const onUpdatePreferences = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'travellers',
      action: 'completion',
      type: 'interaction',
      category: 'travel_preferences',
    })

    const reformattedRooms = selectedTravellers.map(({ adults, childrenAge }) => ({ numOfAdults: adults, childrenAges: childrenAge || [] }))
    dispatch(updateUserPreferenceOccupancy(userId!, reformattedRooms, [selectedGroupSize.toUpperCase() as 'GROUP' | 'COUPLE' | 'SOLO' | 'FAMILY']))
    onSaveLastStep()
  }, [dispatch, selectedGroupSize, selectedTravellers, onSaveLastStep, userId])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'travellers',
      action: 'impression',
      type: 'nonInteraction',
      category: 'travel_preferences',
    })
  }, [])

  return (
    <>
      <ModalBody>
        <ModalContent>
          <Group direction="vertical" gap={16}>
            <BodyText variant="medium">Save time when searching and booking by adding your usual number of travellers. You can specify your exact travel party and change this at any time in your account.</BodyText>
            <Group direction="vertical" gap={40}>
              <TravellersGrid>
                {travellerOptions.map((item, index) => (
                  <TravellerOption
                    key={index}
                    className={cn({ selected: selectedGroupSize === item.groupSize })}
                    onClick={() => onSelectGroupSize(item)}
                  >
                    <Group direction="vertical" verticalAlign="center" horizontalAlign="center" gap={4}>
                      {item.Icon}
                      <BodyText variant="large" weight="bold">{item.groupSize}</BodyText>
                    </Group>
                  </TravellerOption>
                ))}
              </TravellersGrid>
              {['Family', 'Group'].includes(selectedGroupSize) && <Group horizontalAlign="start" direction="vertical" gap={24}>
                <Heading variant="heading4">How many travellers in your {selectedGroupSize.toLocaleLowerCase()}?</Heading>
                <Group fullWidth direction="vertical" gap={8}>
                  {selectedTravellers.map((occupants, index) => (
                    <OccupancyItem
                      smallView
                      itemId={index}
                      key={`occupancy-${index}`}
                      title={`Room ${index + 1}`}
                      occupancy={occupants}
                      showRemove={index > 0}
                      onChange={onOccupancyChange}
                      onRemove={onOccupancyRemove}
                    />
                  ))}
                </Group>
                <TextButton kind="tertiary" onClick={addRoom}>Add Room</TextButton>
              </Group>}
            </Group>
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter primaryActionProps={{ children: 'Save & complete', onClick: onUpdatePreferences }} />
    </>
  )
}

const mapStateToProps = (state: App.State) => ({
  occupancyPreferences: state.userTravelPreferences.occupancy?.data,
})

export default connect(mapStateToProps)(TravelPreferencesModalTravellers)

import qs from 'qs'

export const buildHotelUrl = (destinationName: string, destinationId: string, holidayType?: string) => {
  const hotelParams = {
    destinationName,
    destinationId,
    adults: 2,
    children: 'none',
    flexibleWithDate: true,
    holidayTypes: holidayType,

  }
  return `/search?${qs.stringify(hotelParams)}`
}

export const buildViewMapUrl = (destinationName: string, destinationId: string, holidayType: string) => {
  const viewMapParams = {
    destinationName,
    destinationId,
    adults: 2,
    children: 'none',
    flexibleWithDate: true,
    holidayTypes: holidayType,
  }
  return `/search/map?${qs.stringify(viewMapParams)}`
}

import { css } from 'styled-components'
import zIndex from 'styles/tools/z-index'

import './scrollbarWidth'

const REACT_MODAL_TRANSITION_DURATION = 300 // in ms

export default css`
  .ReactModal__Overlay {
    /* overflow: hidden; */ /* Applying this would open a portal to new realms of CSS pain. */
    /* For some unknown reason, when the content (child) element is positioned at the bottom,
      and it needs to animate from outside the parent at a particular offset, it will initially
      be painted at an unpositioned state before the correct transformation can be applied. */
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    max-width: 100vw;
    width: 100%;
    z-index: ${zIndex.modal};
    overflow-y: auto;
    transition-property: background-color, opacity;
    transition-timing-function: ease;
    transition-duration: ${REACT_MODAL_TRANSITION_DURATION}ms, 0ms;

    &,
    &.ReactModal__Overlay--before-close {
      transition-delay: 0ms, ${REACT_MODAL_TRANSITION_DURATION}ms;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0);
    }

    &:not(.ReactModal__Overlay--before-close).ReactModal__Overlay--after-open {
      transition-delay: 0ms;
      opacity: 1;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .ReactModal__Body--open {
    overflow: hidden;
    padding-right: var(--scrollbar-width);

    .genesys-app {
      display: none !important;
    }
    #chat-widget-container {
      display: none !important;
    }
    #_GUARANTEE_SealSpan {
      visibility: hidden !important;
    }
  }

  .ReactModal__Overlay--wait {
    cursor: wait;
  }
`

import { set, get } from '../lib/storage/isomorphicLocalStorage'
import moment from 'moment'

// When changing the structure of local storage item change the key so that
// errors are not created on users browsers.
const KEY = '6231g97'

export enum Status {
  ignore = 'IGNORE',
  redirected = 'REDIRECTED'
}

export function setGeoRedirect(status: Status) {
  return set(KEY, {
    status,
    expiry_timestamp: moment().add(2, 'd').unix(),
  })
}

export function getGeoRedirect() {
  return get(KEY) || {}
}

export function clearGeoRedirect() {
  return set(KEY, {})
}

import LabeledSheet from 'components/Luxkit/LabeledSheet'
import { checkCanViewLuxPlusBenefits, getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import { rem } from 'polished'
import React, { MouseEventHandler, useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Clickable from 'components/Common/Clickable'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LuxPlusLabel from './LuxPlusLabel'
import ModalContext from 'contexts/ModalContext'
import LuxPlusBenefitsModal from './modals/LuxPlusBenefitsModal'
import LuxPlusLabelContext from 'contexts/LuxPlusLabelContext'

const ContentWrapper = styled.div`
  padding: ${rem(8)};
`

interface MappedProps {
  subscription: App.ProductSubscription;
  luxPlusLPPEnabled: boolean;
  canViewLuxPlusBenefits: boolean;
}

interface Props {
  offerPrice: number;
  offerMemberPrice: number;
  hasLuxPlusRate?: boolean;
  hideUpsellModal?: boolean;
  offer: App.BedbankOffer | App.BedbankOfferSummary;
}

function LuxLPPPricingLabel(props: Props & MappedProps) {
  const {
    offerPrice,
    offerMemberPrice,
    luxPlusLPPEnabled,
    hasLuxPlusRate,
    canViewLuxPlusBenefits,
    offer,
  } = props
  const saveAmount = offerPrice - offerMemberPrice
  const showModal = useContext(ModalContext)
  const providerPage = useContext(LuxPlusLabelContext)

  const toggleModal = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()
    showModal(<LuxPlusBenefitsModal
      type="member-price-offer-lpp-no-save-amount"
      saveAmount={saveAmount}
      offer={offer}
      providerPage={providerPage}
    />)
  }, [offer, providerPage, saveAmount, showModal])

  if (!luxPlusLPPEnabled || !hasLuxPlusRate) return null

  return <LabeledSheet
    kind="lux-plus"
    label={<LuxPlusLabel type="only-logo" />}
  >
    <ContentWrapper>
      <BodyTextBlock
        variant="medium"
        weight="bold"
        endIcon={canViewLuxPlusBenefits ? undefined : <Clickable onClick={toggleModal}><LineInfoCircleIcon /></Clickable>}
        fit="auto"
      >
        Member savings on select packages
      </BodyTextBlock>
    </ContentWrapper>
  </LabeledSheet>
}

export default connect((state: App.State): MappedProps => {
  return {
    luxPlusLPPEnabled: getIsLuxPlusLPPEnabled(state),
    subscription: state.luxPlus.products.subscription,
    canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
  }
})(LuxLPPPricingLabel)

declare global {
  interface Window {
    opr:any;
  }

  interface Navigator {
    brave:any;
  }
}

// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
export default !!(typeof navigator !== 'undefined' &&
  navigator.vendor &&
  navigator.vendor.includes('Google') &&
  navigator.userAgent.includes('Chrome') &&
  !navigator.userAgent.includes('Edg') &&
  !navigator.brave &&
  !window.opr)

import React from 'react'
import Modal from 'components/Luxkit/Modal/Modal'

export interface SearchFloatingPanelProps {
  title: string;
  isOpen?: boolean;
  children: React.ReactNode;
  confirmText?: string;
  onConfirm?: () => void;
  onClose: () => void;
  onGoBack: () => void;
  isConfirmButtonHidden?: boolean;
  confirmButtonDisabled?: boolean;
  secondaryActionText?: string;
}

function SearchFloatingPanel(props: SearchFloatingPanelProps) {
  const {
    title,
    isOpen,
    confirmText,
    onConfirm,
    onClose,
    onGoBack,
    children,
    isConfirmButtonHidden,
    confirmButtonDisabled,
    secondaryActionText,
  } = props

  return (
    <Modal
      mode="drawer"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onBack={onGoBack}
      primaryActionText={confirmText}
      onPrimaryActionClick={onConfirm}
      isPrimaryActionDisabled={confirmButtonDisabled}
      isPrimaryActionHidden={isConfirmButtonHidden}
      secondaryActionText={secondaryActionText}
    >
      {children}
    </Modal>
  )
}

export default SearchFloatingPanel

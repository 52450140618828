import { VIRGIN_VELOCITY_BURN_RATE } from 'constants/partnerships'
import { createSelector } from 'reselect'

/**
 * Calculate total modifying amount for partnerships
 *
 * @remarks
 * Currently applicable only for:
 * - VIRGIN_VELOCITY
 */
const getPartnershipModifierAmount = createSelector(
  (state: App.State) => state.checkout.payment.partnerships,
  (partnerships) => {
    let result = 0

    if (partnerships.velocity?.burn?.isApplied && partnerships.velocity?.burn?.pointsBurned) {
      result += Math.round(((partnerships.velocity.burn.pointsBurned * VIRGIN_VELOCITY_BURN_RATE) + Number.EPSILON) * 100) / 100
    }

    return result
  },
)

export default getPartnershipModifierAmount

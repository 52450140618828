import { assertUnreachable } from '..'

import { BookmarkRequest } from 'tripPlanner/api/bookmark/types'
import { LETripItem } from 'tripPlanner/types/tripItem'

export function itemToCuratedBookmarkPayload(
  item: LETripItem,
): BookmarkRequest['items'][number] {
  switch (item.type) {
    case 'CRUISE':
      if (item.savedItemData.offerType === 'cruise') {
        return {
          type: 'cruise_v2',
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
          templateItemId: item.id,
          templateId: item.tripId,
        }
      } else {
        return {
          type: item.savedItemData.offerType,
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
          templateItemId: item.id,
          templateId: item.tripId,
        }
      }
    case 'EXPERIENCE':
      return {
        type: 'experience',
        code: item.savedItemData.experienceId,
        price: item.savedItemData.price,
        value: item.savedItemData.value,
        templateItemId: item.id,
        templateId: item.tripId,
      }
    case 'ACCOMMODATION':
      if (item.savedItemData.offerType === 'bedbank_hotel') {
        return {
          type: 'bedbank_hotel',
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
          templateItemId: item.id,
          templateId: item.tripId,
        }
      } else {
        return {
          type: item.savedItemData.offerType,
          code: item.savedItemData.offerId,
          price: item.savedItemData.price,
          value: item.savedItemData.value,
          durationInDays: item.savedItemData.durationInDays,
          templateItemId: item.id,
          templateId: item.tripId,
        }
      }
    case 'TOUR':
      return {
        type: item.savedItemData.offerType,
        code: item.savedItemData.offerId,
        price: item.savedItemData.price,
        value: item.savedItemData.value,
        templateItemId: item.id,
        templateId: item.tripId,
      }
    case 'FLIGHT':
      throw new Error("Shouldn't be able to save a flight from a curated trip")
    default:
      assertUnreachable(item)
  }
}

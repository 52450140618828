import { isStandaloneExperience } from 'lib/experiences/experienceUtils'
import { getSortedOrdersByDeparture } from 'selectors/orderSelectors'

import { fetchBookingDetailsByIds } from 'actions/ExperienceActions'
import { useEffect, useMemo } from 'react'
import { isOrderCancelled, haveOrdersBeenFetched } from 'lib/order/orderUtils'
import isUpcoming, { isOrderCurrent, isOnlyExperiencesOrderUpcoming } from 'lib/order/isUpcoming'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import { fetchOrders } from 'actions/OrderActions'
import { ITEM_STATUS_AWAITING_DATES } from 'constants/cart'
import { ExperienceBookingType } from 'constants/experience'

function needsRedemption(order: App.Order) {
  if (!order.experienceItems.length || order.experienceItems.some(item => item.status === ITEM_STATUS_AWAITING_DATES || !isStandaloneExperience(item, order))) return false

  const [item] = order.experienceItems

  if (item.status === 'cancelled') return false

  if (item.bookingType === ExperienceBookingType.BUY_NOW_BOOK_LATER && !!item.date) {
    return true
  }

  return !item.date
}

function canBeMarkedAsRedeemed(order: App.Order, bookingDetailsMap: Record<string, App.ExperienceBookingDetails>) {
  if (!needsRedemption(order)) return false

  const item = order.experienceItems[0]

  const bookingDetails = bookingDetailsMap[item.id]

  return bookingDetails && !bookingDetails.markedAsRedeemed
}

export function useOrdersByDepartureStatus() {
  const dispatch = useAppDispatch()
  const { data: allSortedOrders } = useAppSelector(getSortedOrdersByDeparture)
  const bookingDetailsMap = useAppSelector(state => state.experience.bookingDetails)
  const ordersFetched = useAppSelector((state) => haveOrdersBeenFetched(state, 'upcoming'))

  const needsBookingDetails = useMemo(() =>
    allSortedOrders
      .filter(order => needsRedemption(order))
      .map(order => order.experienceItems[0])
      .map(item => item.id)
  , [allSortedOrders],
  )

  const missingBookingDetails = useMemo(() => needsBookingDetails.filter(id => !bookingDetailsMap[id]), [needsBookingDetails, bookingDetailsMap])

  useEffect(() => {
    // will fetch the list every time we visit the page, but once we've already fetched
    // the list, we use that data to show straight away and this will make sure it's synced
    // in the background
    if (!ordersFetched) {
      dispatch(fetchOrders())
    }
  }, [dispatch, ordersFetched])

  useEffect(() => {
    if (missingBookingDetails.length) dispatch(fetchBookingDetailsByIds(missingBookingDetails))
  }, [dispatch, missingBookingDetails])

  const loading = !ordersFetched || missingBookingDetails.length > 0

  const canBeRedeemed = allSortedOrders.filter(order => canBeMarkedAsRedeemed(order, bookingDetailsMap))

  type ClassifiedOrders = { cancelled: Array<App.Order>, upcoming: Array<App.Order>, previous: Array<App.Order> }

  const { upcoming, previous, cancelled } = useMemo(() =>
    allSortedOrders.reduce<ClassifiedOrders>((acc, order) => {
      if (isOrderCancelled(order)) {
        acc.cancelled.push(order)
        return acc
      }

      const isCurrent = isUpcoming(order) || isOrderCurrent(order) || isOnlyExperiencesOrderUpcoming(order)

      if (isCurrent && !(needsRedemption(order) && !canBeMarkedAsRedeemed(order, bookingDetailsMap))) {
        acc.upcoming.push(order)
        return acc
      }

      acc.previous.push(order)
      return acc
    }, { cancelled: [], upcoming: [], previous: [] }),
  [allSortedOrders, bookingDetailsMap])

  return { loading, upcoming, previous, cancelled, canBeRedeemed }
}

import { rem } from 'polished'
import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { _searchTileContainerQueryUp } from './_SearchTileBase'

const Container = styled.div`
  grid-area: pricing;
  display: flex;
  flex-direction: row;
  gap: ${rem(12)};
  align-items: end;

  ${_searchTileContainerQueryUp.tablet} {
    flex-direction: column;
    gap: ${rem(8)};
    align-items: stretch;
  }

  ${_searchTileContainerQueryUp.desktopSmall} {
    flex-direction: row;
    gap: ${rem(12)};
    align-items: end;
  }

  ${_searchTileContainerQueryUp.desktop} {
    flex-direction: column;
    gap: ${rem(8)};
    align-items: stretch;
  }
`

const Details = styled.div`
  flex-grow: 1;
  min-width: ${rem(200)};
`

const Action = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: ${rem(80)};
  max-width: ${rem(200)};

  &:empty {
    display: none;
  }
`

interface Props extends PropsWithChildren {
  action: ReactElement | false
}

function _SearchTilePricing({ children, action }: Props) {
  return <Container>
    <Details>{children}</Details>
    {!!action && <Action>{action}</Action>}
  </Container>
}

export default _SearchTilePricing

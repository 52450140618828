import React from 'react'
import AssistChipLoadingBox from 'components/Luxkit/Chips/AssistChipLoadingBox'

const RANDOM_BUTTON_WIDTHS = [
  84,
  120,
  48,
  72,
  84,
  60,
  48,
]

function GlobalSearchQuickFilterLoadingButtons() {
  return <>
    {RANDOM_BUTTON_WIDTHS.map((randomWidth, index) => <AssistChipLoadingBox
        size="medium"
        key={index}
        width={randomWidth}
      />)}
  </>
}

export default GlobalSearchQuickFilterLoadingButtons

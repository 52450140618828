import React, { PropsWithChildren } from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import styled from 'styled-components'
import { LABEL_HEIGHT } from './Label'
import { rem } from 'polished'

const LabelBox = styled(LoadingBox)`
  height: ${LABEL_HEIGHT};
  border-radius: ${props => props.theme.borderRadius.S};
`

interface Props {
  /**
   * in pixels
   */
  width: number
}

function LabelLoadingBox(props: PropsWithChildren<Props>) {
  const { width } = props

  return <LabelBox style={{ width: rem(width) }} />
}

export default LabelLoadingBox

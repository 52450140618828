import React from 'react'

const noop = () => {
  console.warn('Attempted to open a modal without a ModalProvider')
  return Promise.resolve<any>(null)
}
type ContextType = <T = void>(modal: React.ReactNode) => Promise<T>
const ModalContext = React.createContext<ContextType>(noop)

export default ModalContext

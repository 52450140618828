import React from 'react'

export default function HideLiveChat() {
  return <style dangerouslySetInnerHTML={{
    __html: `
    .genesys-app {
      display: none;
    }
    #chat-widget-container {
      display: none;
    }
  `,
  }}/>
}

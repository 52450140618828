import loadable from '@loadable/component'
import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router'

import HttpStatus from 'components/Common/HttpStatus'
import Error404PageWithOffers from 'components/Pages/Error404PageWithOffers/Error404PageWithOffers'

const HeaderMain = loadable(
  () =>
    import(
      /* webpackChunkName: 'HeaderMain' */ '../../client/components/App/Header/HeaderMain/HeaderMain'
    ),
)

const pageRoute = (match: { path: string }, path: string) =>
  `${match.path}${path}`

interface Props extends RouteComponentProps {}

function Trip404Page(props: Props) {
  const { match } = props

  return (
    <Switch>
      <Route exact path={pageRoute(match, '/trip-planner/trip')}>
        <HttpStatus code={404}>
          <HeaderMain />
          <Error404PageWithOffers />
        </HttpStatus>
      </Route>
      <Route>
        <HttpStatus code={404}>
          <Error404PageWithOffers />
        </HttpStatus>
      </Route>
    </Switch>
  )
}

export default withRouter(Trip404Page)

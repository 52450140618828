import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineNoFeesIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12.0007 3c-1.2821 0-2.499.2675-3.6003.7487-.506.2211-1.0956-.0099-1.3167-.516-.2212-.506.0098-1.0956.5159-1.3167C8.9486 1.3265 10.4378 1 12.0007 1c6.0755 0 11.0007 4.9252 11.0007 11.0007 0 1.5623-.3263 3.051-.9154 4.3996-.221.5061-.8105.7372-1.3166.5161-.5061-.2211-.7372-.8106-.5162-1.3167.4809-1.1009.7482-2.3174.7482-3.599C21.0014 7.0297 16.9716 3 12.0007 3Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M3.5443 4.9643C1.9563 6.871 1 9.325 1 12.0007c0 6.0755 4.9252 11.0007 11.0007 11.0007 2.6757 0 5.1297-.9564 7.0363-2.5444l1.253 1.253a.9997.9997 0 0 0 1.42 0 .9997.9997 0 0 0 0-1.42l-18-18a1.0041 1.0041 0 0 0-1.42 1.42l1.2543 1.2543Zm14.0714 14.0714-2.6814-2.6814c-.6566.512-1.4486.6546-1.9258.6546v1.0001c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1.0001H9.0082c-.5523 0-1-.4477-1-1s.4477-1 1-1h4.0003c.1129 0 .297-.0273.4756-.1048l-1.8955-1.8955h-.5803c-.4933 0-1.2111-.1398-1.8343-.5885-.6742-.4854-1.1658-1.2856-1.1658-2.4116 0-.1871.0136-.3673.0402-.5401L4.9657 6.3857C3.7349 7.9254 3 9.8765 3 12.0007c0 4.9709 4.0297 9.0007 9.0007 9.0007 2.1242 0 4.0752-.7349 5.615-1.9657Z" clipRule="evenodd"/>
    <path fill="currentColor" d="M13.0085 6.0082c0-.5522-.4477-1-1-1s-1 .4478-1 1v1.0002a1 1 0 0 0 .2929.707l.9914.9917c.1876.1875.442.2929.7072.2929h2c.5523 0 1-.4477 1-1s-.4477-1-1-1h-1.5857l-.4058-.4058v-.586Z"/>
  </SvgIcon>
}

export default LineNoFeesIcon

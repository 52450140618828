import { createSelector } from 'reselect'
import { isTourV2Item } from 'lib/checkout/checkoutUtils'

const discountTotalToCartItemsMap = createSelector(
  (state: App.State) => state.checkout.promo?.items,
  (state: App.State) => state.checkout.cart.items,
  (promoItems, cartItems): Map<string, number | undefined> => {
    const map = new Map<string, number | undefined>()
    if (!promoItems) {
      return map
    }

    cartItems.forEach((item) => {
      let itemId: string = item.itemId
      if (isTourV2Item(item)) {
        itemId = 'roomId' in item.occupancy ? item.occupancy.roomId as string : item.itemId
      }
      map.set(item.itemId, promoItems?.find(promoItem => promoItem.itemId === itemId)?.discountAmount)
    })
    return map
  },
)

export default discountTotalToCartItemsMap

import React, { useCallback, useContext } from 'react'

import noop from 'lib/function/noop'
import ModalContext from 'contexts/ModalContext'
import AccountAccessModal, { AccountAccessModalResult } from 'components/Account/AccountAccess/AccountAccessModal'
import { useAppSelector } from './reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'

export default function usePendingLoginHandler(
  onLogin: () => void,
  mode?: App.UiAccountModalMode,
  onShowLogin: () => void = noop,
  onDismissLogin: () => void = noop,
) {
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)

  return useCallback(async() => {
    if (!loggedIn) {
      onShowLogin()
      const result = await showModal<AccountAccessModalResult>(<AccountAccessModal initialMode={mode} />)

      if (result.loggedIn) {
        onLogin()
      } else {
        onDismissLogin()
      }
    } else {
      onLogin()
    }
  }, [loggedIn, mode, onDismissLogin, onLogin, onShowLogin, showModal])
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAngleRightIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m14.8299 11.2899-4.24-4.24a.9988.9988 0 0 0-.71-.2957 1 1 0 0 0-.71.2957 1 1 0 0 0 0 1.41L12.71 12l-3.54 3.54a1.0002 1.0002 0 0 0 0 1.41.9988.9988 0 0 0 .71.29.9984.9984 0 0 0 .71-.29l4.24-4.24a.999.999 0 0 0 .2189-1.0945.9981.9981 0 0 0-.2189-.3255Z"/>
  </SvgIcon>
}

export default LineAngleRightIcon

import debounce from 'lodash.debounce'
import { take, skip } from 'lib/array/arrayUtils'
import { RequireAtLeastOne } from 'type-fest'
import { breakpointEntries, breakpointSizes } from 'components/utils/breakpoint'
import { objectKeys } from 'lib/object/objectUtils'

type BreakpointRange = RequireAtLeastOne<{min: number, max: number}, 'min' | 'max'>

export type Matches = Partial<Record<App.ScreenSize, boolean>>

/**
 * Builds a media query from the min/max values given
 */
function buildQuery(breakpoint: BreakpointRange) {
  const { max, min } = breakpoint
  if (!max) {
    return `(min-width: ${min}px)`
  }
  if (!min) {
    return `(max-width: ${max}px)`
  }

  return `(min-width: ${min}px) and (max-width: ${max}px)`
}

export type QueryFunc = (nextMatch: Matches) => void
// set up a single match media instance for each named query to listen on
// this'll ensure that the minimum number get created and shared
// it also means that query names are unique across all responsive helper instances!
const queries = new Map<string, QueryFunc>()
// Returns a list of all breakpoints from order of xs -> xl
const allBreakpoints = objectKeys(breakpointSizes)

// Helper class to keep track of the 'nextMatch' and ensure that
// only one version of the query listeners needs t exist
class ResponsiveHelper {
  nextMatch: Matches = {}
  listeners = new Set<QueryFunc>()

  // debounced version as what often happens is one breakpoint turns on
  // another turns off. So we're batching them together with a debounce
  setMatches = debounce(() => {
    // let our listeners know we've got new values
    // seeing as nextMatch gets updated outside of the debounce
    // this should always be the latest by now
    const savedNextMatch = this.nextMatch
    this.listeners.forEach(callback => callback(savedNextMatch))
  }, 10)

  updateMatches = (newVal: Matches) => {
    // update our matches with the next value...
    this.nextMatch = {
      ...this.nextMatch,
      ...newVal,
    }
    this.setMatches()
  }

  /**
   * Registered a media query to the listener.
   * Only supports based onwidth at the moment
   * @param query Min/max width pixels values for the break point.
   */
  registerMediaQuery(name: string, query: BreakpointRange) {
    if (!queries.has(name)) {
      const mediaQuery = window.matchMedia(buildQuery(query))
      const listener: QueryFunc = (nextMatch: Matches) => this.updateMatches(nextMatch)
      // initalise the first values of the listener
      this.updateMatches({ [name]: mediaQuery.matches })
      // start listening on changes
      mediaQuery.addEventListener('change', (event: MediaQueryListEvent) => listener({ [name]: event.matches }))
      queries.set(name, listener)
    }
  }

  removeMediaQuery(name: string) {
    const listener = queries.get(name)
    if (listener) {
      this.listeners.delete(listener)
    }

    queries.delete(name)
  }

  registerListener(callback: QueryFunc) {
    this.listeners.add(callback)
  }

  removeListener(callback: QueryFunc) {
    this.listeners.delete(callback)
  }

  matchesMin(match: App.ScreenSize) {
    // minimum requires from value given => last
    const toCheck = skip(allBreakpoints, allBreakpoints.indexOf(match))
    return toCheck.some(bp => this.nextMatch[bp])
  }

  matchesMax(match: App.ScreenSize) {
    const toCheck = take(allBreakpoints, allBreakpoints.indexOf(match) + 1)
    return toCheck.some(bp => this.nextMatch[bp])
  }
}

const responsiveHelper = new ResponsiveHelper()
if (!IS_SSR) {
  breakpointEntries.forEach(([name, query]) => {
    responsiveHelper.registerMediaQuery(name, query)
  })
}
export default responsiveHelper

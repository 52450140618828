import React from 'react'
import BaseClickable from './BaseClickable'
import ClickableLink from './ClickableLink'

interface ClickableProps extends Omit<React.ComponentProps<typeof BaseClickable>, 'ref'>, Omit<React.ComponentProps<typeof ClickableLink>, 'ref'> {

}

const Clickable = React.forwardRef<HTMLButtonElement, ClickableProps>(function({ type = 'button', ...props }: ClickableProps, ref) {
  let ClickableElement: any = null
  // anchor elements don't actually support disabled state
  // so we emulate it by not rendering a link for disabled clickables that have a to/href
  if ((props.href || props.to) && !props.disabled) {
    ClickableElement = ClickableLink
  } else {
    ClickableElement = BaseClickable
  }
  return <ClickableElement {...props} type={type} ref={ref} />
})

Clickable.displayName = 'Clickable'

export default Clickable

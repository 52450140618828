import responsiveHelper, { Matches } from 'components/Common/Breakpoint/responsiveHelper'
import { useEffect, useState } from 'react'

export function useResponsive() {
  const [match, setMatches] = useState<Matches>(responsiveHelper.nextMatch)

  useEffect(() => {
    const callback = (nextMatch: Matches) => {
      setMatches(nextMatch)
    }

    responsiveHelper.registerListener(callback)

    return () => responsiveHelper.removeListener(callback)
  }, [])

  return [match]
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBellIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 13.18V10a6 6 0 0 0-5-5.91V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 3v1.09A6 6 0 0 0 6 10v3.18A3.0002 3.0002 0 0 0 4 16v2a1 1 0 0 0 1 1h3.14A3.9997 3.9997 0 0 0 12 21.951 3.9995 3.9995 0 0 0 15.86 19H19a1.0001 1.0001 0 0 0 1-1v-2a3.0004 3.0004 0 0 0-2-2.82ZM8 10a4 4 0 0 1 8 0v3H8v-3Zm4 10a2.0006 2.0006 0 0 1-1.72-1h3.44A2.0006 2.0006 0 0 1 12 20Zm6-3H6v-1a1 1 0 0 1 1-1h10a1.0001 1.0001 0 0 1 1 1v1Z"/>
  </SvgIcon>
}

export default LineBellIcon

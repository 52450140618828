import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineKidIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10 10a1 1 0 1 0-1.9999 0A1 1 0 0 0 10 10Zm4.5 4.06a1 1 0 0 0-1.37.36 1.2993 1.2993 0 0 1-1.13.6573 1.2995 1.2995 0 0 1-1.13-.6573 1.0002 1.0002 0 0 0-1.37-.36.9998.9998 0 0 0-.37 1.36 3.3099 3.3099 0 0 0 5.74 0 1 1 0 0 0-.37-1.36ZM15 9a1.0002 1.0002 0 0 0-.9808 1.1951.9995.9995 0 0 0 .7857.7857A.9998.9998 0 0 0 16 10a1.0002 1.0002 0 0 0-1-1Zm-3-7a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm0 18A8 8 0 0 1 9 4.57 3.0016 3.0016 0 0 0 9 5a3 3 0 0 0 3 3 1 1 0 1 0 0-2 1 1 0 1 1 0-2 8.0001 8.0001 0 0 1 5.6569 13.6569A8.0005 8.0005 0 0 1 12 20Z"/>
  </SvgIcon>
}

export default LineKidIcon

import { CurrencyFormatterOptions, useCurrencyFormatter } from 'hooks/useNumberFormatters'
import { CurrencyFormat } from 'lib/format/formatCurrencyIntl'
import React from 'react'

interface Props extends CurrencyFormatterOptions {
  value: number
  format?: CurrencyFormat
}

function FormatCurrency({ value, format, ...rest }: Props) {
  const formatter = useCurrencyFormatter(rest)
  const formatted = formatter(value, format)

  return <>{formatted}</>
}

export default React.memo(FormatCurrency)

// TODO: add/use traveller-contract

import { mapObject } from 'lib/object/objectUtils'

export function tourOrderTravellerDetailsMap(travellerDetails: any): App.TourOrderTravellerDetails {
  return {
    itinerary_loaded: travellerDetails.itinerary_loaded,
    total_submitted: travellerDetails.total_submitted,
    total_travellers: travellerDetails.total_travellers,
    travellerFormData: travellerDetails.travellerFormData?.map(tourOrderTravellerFormDataMap) ?? [],
    // @ts-ignore: travellerFormSchema field might be undefined
    travellerFormSchema: travellerDetails.travellerFormSchema ? tourOrderTravellerFormSchemaMap(travellerDetails.travellerFormSchema) : null,
    travellers: travellerDetails.travellers?.map(tourOrderTravellerMap) ?? [],
  }
}

function tourOrderTravellerMap(traveller: any): App.OrderTraveller {
  return {
    all_forms_submitted: traveller.all_forms_submitted,
    arrival_airport: traveller.arrival_airport,
    arrival_date: traveller.arrival_date,
    arrival_flight_number: traveller.arrival_flight_number,
    arrival_time: traveller.arrival_time,
    bedding_configuration: traveller.bedding_configuration,
    booking_number: traveller.booking_number,
    city: traveller.city,
    conditions: traveller.conditions,
    contact_number: traveller.contact_number,
    country: traveller.country,
    created_at: traveller.created_at,
    customer_id: traveller.customer_id,
    departure_airport: traveller.departure_airport,
    departure_date: traveller.departure_date,
    departure_flight_number: traveller.departure_flight_number,
    departure_time: traveller.departure_time,
    dob: traveller.dob,
    email: traveller.email,
    emergency_contact_email: traveller.emergency_contact_email,
    emergency_contact_name: traveller.emergency_contact_name,
    emergency_contact_phone: traveller.emergency_contact_phone,
    emergency_contact_relationship: traveller.emergency_contact_relationship,
    first_name: traveller.first_name ?? undefined,
    flights_submitted: traveller.flights_submitted,
    gender: traveller.gender,
    group_count: traveller.group_count,
    group_details_only_collected: traveller.group_details_only_collected,
    itinerary_loaded: traveller.itinerary_loaded,
    last_name: traveller.last_name ?? undefined,
    nationality: traveller.nationality,
    order_id: traveller.order_id,
    passport_expiry: traveller.passport_expiry,
    passport_number: traveller.passport_number,
    postcode: traveller.postcode,
    purchased_item: traveller.purchased_item ? tourOrderTravellerPurchasedItemMap(traveller.purchased_item) : undefined,
    requests: traveller.requests,
    state: traveller.state,
    stateOfResidence: traveller.stateOfResidence,
    street_address: traveller.street_address,
    traveller_id: traveller.traveller_id,
    traveller_submitted: traveller.traveller_submitted,
    vendor_salesforce_id: traveller.vendor_salesforce_id,
    isPrimaryContact: traveller.is_lead_passenger,
    room_type: traveller.room_type,
    room_number: traveller.room_number,
    customer_origin_airport: traveller.customer_origin_airport,
  }
}

function tourOrderTravellerPurchasedItemMap(purchaseItem: any): App.OrderTravellerPurchaseItem {
  return {
    fk_offer: purchaseItem.fk_offer,
    image_ref: purchaseItem.image_ref,
    location: purchaseItem.location,
    name: purchaseItem.name,
    tour_flight_details_required: purchaseItem.tour_flight_details_required,
    travel_date: purchaseItem.travel_date,
  }
}

function tourOrderTravellerFormDataMap(formData: any): App.OrderTravellerFormData {
  return mapObject<string, any>(formData, (value) => value || undefined)
}

function tourOrderTravellerFormSchemaMap(formSchema: any): App.OrderTravellerFormSchema {
  return {
    definitions: mapObject(formSchema.definitions, tourOrderTravellerFormSchemaDefinitionMap),
    label: formSchema.label,
    properties: mapObject(formSchema.properties, tourOrderTravellerFormSchemaPropertyMap),
    required: formSchema.required ?? [],
    type: 'object',
    ui: tourOrderTravellerFormSchemaUiMap(formSchema.ui),
  }
}

function tourOrderTravellerFormSchemaDefinitionMap(definition: any): App.OrderTravellerFormSchemaDefinition {
  if (definition.type === 'text') {
    return {
      type: 'text',
      subtype: definition.subtype || undefined,
      pattern: definition.pattern || undefined,
      minLength: definition.minLength || undefined,
      maxLength: definition.maxLength || undefined,
      defaultValue: definition.defaultValue || undefined,
      disabled: !!definition.disabled,
      readOnly: !!definition.readonly,
    }
  }
  if (definition.type === 'time') {
    return {
      type: 'time',
      defaultValue: definition.defaultValue || undefined,
      disabled: !!definition.disabled,
      readOnly: !!definition.readonly,
    }
  }
  if (definition.type === 'textarea') {
    return {
      type: 'textarea',
      maxLength: definition.maxLength || undefined,
      defaultValue: definition.defaultValue || undefined,
      disabled: !!definition.disabled,
      readOnly: !!definition.readonly,
    }
  }
  if (definition.type === 'select') {
    return {
      type: 'select',
      subtype: definition.subtype,
      options: Array.isArray(definition.options) ? definition.options : Object.entries(definition.options),
      defaultValue: definition.defaultValue || undefined,
      disabled: !!definition.disabled,
      readOnly: !!definition.readonly,
    }
  }
  if (definition.type === 'date') {
    return {
      type: 'date',
      subtype: definition.subtype || undefined,
      format: definition.format || undefined,
      max: definition.max || undefined,
      min: definition.min || undefined,
      disabled: !!definition.disabled,
      readOnly: !!definition.readonly,
    }
  }
  if (definition.type === 'file') {
    if (definition.subtype === 'image') {
      return {
        type: 'file',
        subtype: 'image',
        accept: definition.accept || undefined,
        max: definition.max || undefined,
        disabled: !!definition.disabled,
        readOnly: !!definition.readonly,
      }
    }
  }
  if (definition.type === 'phone') {
    return {
      type: 'phone',
      defaultValue: definition.defaultValue || undefined,
      defaultPrefixValue: definition.defaultPrefixValue || undefined,
      options: definition.options,
      disabled: !!definition.disabled,
      readOnly: !!definition.readonly,
    }
  }
  if (definition.type === 'message') {
    return {
      type: 'message',
      subtype: definition.subtype || undefined,
    }
  }

  // unsupported type+subtype
  return {
    type: definition.type,
    subtype: definition.subtype,
    disabled: !!definition.disabled,
    readOnly: !!definition.readonly,
  }
}

function tourOrderTravellerFormSchemaPropertyMap(property: any): App.OrderTravellerFormSchemaProperties {
  return {
    label: property.label,
    properties: mapObject<any, any>(property.properties, (propValue) => ({
      definition: propValue.definition,
      subFieldOnValue: propValue.subFieldOnValue ? mapObject(propValue.subFieldOnValue, (subFieldValue) => subFieldValue || []) : undefined,
    })),
    required: property.required,
    type: 'object',
  }
}

function tourOrderTravellerFormSchemaUiMap(ui: any): App.OrderTravellerFormSchemaUi {
  return {
    orderMap: mapObject<any, Array<string>>(ui.orderMap, (orderMap) => orderMap ?? []),
    properties: mapObject<any, any>(ui.properties, (property) => ({
      label: property.label,
      placeholder: property.placeholder || undefined,
    })),
  }
}

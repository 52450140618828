import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMinusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 11H5a1.0002 1.0002 0 0 0-.7071 1.7071C4.4804 12.8946 4.7348 13 5 13h14a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineMinusIcon

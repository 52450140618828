import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { rem } from 'polished'
import { AGE_OPTIONS } from 'constants/search'
import { take } from 'lib/array/arrayUtils'
import { getChildrenAgesLimits } from 'lib/offer/occupancyUtils'
import Group from 'components/utils/Group'
import Select from 'components/Common/Form/Input/Select'
import DateOfBirthInput from 'components/Common/Form/Input/DateOfBirthInput'

const AgeSelect = styled(Select)`
  border-radius: ${props => props.theme.borderRadius.S};
  min-width: ${rem(120)};
`

interface Props extends Omit<React.ComponentProps<typeof Select>, 'onChange' | 'defaultValue'> {
  childId: number;
  onChange: (childId: number, age: number, birthDate?: string) => void;
  defaultValue?: number;
  capacities: Array<App.RoomCapacity> | undefined;
  occupancy: App.Occupants;
  maxChildAge: number;
  maxInfantAge: number;
  minInfantAge?: number;
  className?: string;
  childrenRequireDateOfBirth?: boolean;
  departureDate?: string;
}

function OccupancyChildAgeSelect(props: Props) {
  const { childId, capacities, occupancy, maxChildAge, maxInfantAge, minInfantAge, onChange, defaultValue, className, childrenRequireDateOfBirth, departureDate, ...rest } = props
  const [value, setValue] = useState(defaultValue)
  const [birthDateValue, setBirthDateValue] = useState<string | undefined>(occupancy.childrenBirthDate?.[childId])

  const ageLimits = useMemo(() => (
    getChildrenAgesLimits(capacities, occupancy, childId, maxInfantAge, maxChildAge)
  ), [capacities, occupancy, childId, maxChildAge, maxInfantAge])

  const options = useMemo(() => take(AGE_OPTIONS, ageLimits.max - ageLimits.min + 1, ageLimits.min), [ageLimits])

  // disable invalid birth dates
  const { minDate, maxDate, refDate } = useMemo(() => {
    if (!childrenRequireDateOfBirth || !departureDate) return {}

    const minDate = moment(departureDate).subtract(maxChildAge + 1, 'years').add(1, 'days')
    const maxDate = moment(departureDate).subtract(minInfantAge || 0.5, 'years')

    return {
      minDate: minDate.toDate(),
      maxDate: maxDate.toDate(),
      refDate: new Date(departureDate),
    }
  }, [childrenRequireDateOfBirth, departureDate, minInfantAge, maxChildAge])

  const onSelectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(e.currentTarget.value, 10)
    setValue(selectedValue)

    // reset birth date if age is greater than maxInfantAge
    if (childrenRequireDateOfBirth) {
      setBirthDateValue(undefined)
      onChange(childId, selectedValue, undefined)
      return
    }

    onChange(childId, selectedValue, birthDateValue)
  }, [childId, onChange, birthDateValue, childrenRequireDateOfBirth])

  const onChangeBirthDate = useCallback((date?: string, isoDate?: string) => {
    if (
      childrenRequireDateOfBirth &&
      isoDate
    ) {
      const age = moment(departureDate).diff(isoDate, 'years')
      setBirthDateValue(isoDate)
      onChange(childId, age, isoDate)
    }
  }, [childId, onChange, childrenRequireDateOfBirth, departureDate])

  return (
    <div className={className} style={{ position: 'relative' }}>
      <Group direction="horizontal" gap={40}>
        {!childrenRequireDateOfBirth && <AgeSelect
          {...rest}
          onChange={onSelectChange}
          value={value}
          noValidationMessage
          noValidationSpacing
          requiredErrorMessage="Please select child's age"
          placeholder="select"
        >
          {options.map(age =>
            <option key={age.value} value={age.value}>{age.label}</option>,
          )}
        </AgeSelect>}

        {childrenRequireDateOfBirth && <DateOfBirthInput
          key={`birth-date-${value}`}
          max={maxDate}
          min={minDate}
          defaultValue={birthDateValue}
          onChange={onChangeBirthDate}
          refDate={refDate}
          travellerType="child"
          required
        />}
      </Group>
    </div>
  )
}

export default OccupancyChildAgeSelect

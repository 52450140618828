import config from 'constants/config'
import getPaymentMethods from 'lib/payment/getPaymentMethods'
import {
  WAAVE_BP_PAYMENT_TYPE,
} from 'constants/payment'

export const isWaaveBpEnabled = (currency: string): boolean =>
  !!(config.WAAVE_BP_ENABLED && getPaymentMethods(currency).includes(WAAVE_BP_PAYMENT_TYPE))

export const isWithinWaaveBpAvailabilityRange = (amount: number, currency:string): boolean => {
  switch (currency) {
    case 'AUD':
      return true
    default:
      return false
  }
}

import React, { useCallback, useMemo } from 'react'
import ListItem from 'components/Luxkit/List/ListItem'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import { escapeRegex } from 'lib/string/regexUtils'

interface Props {
  searchItem: App.SearchItem;
  searchTerm?: string
  isSelected?: boolean
  isSearching?: boolean
  isDisabled?: boolean
  onSelect: (value: App.SearchItem | null) => void;
  onUnselect: (value: App.SearchItem | null) => void;
}

function getSearchableTextBold(text: string, searchTerm?: string) {
  if (!searchTerm) return text
  const search = new RegExp(`(${escapeRegex(searchTerm)})`, 'ig')
  const bits = text.split(search)

  return <>
    {bits.map((textBit, index) => textBit.toLowerCase() === searchTerm.toLowerCase() ?
      <b key={index}>{textBit}</b> : textBit, '')}
  </>
}

function CruiseDepartureSelectListItem({ searchItem, searchTerm, isSelected, isDisabled, onSelect, onUnselect }: Props) {
  const onLocationChange = useCallback(() => {
    if (isSelected) {
      onUnselect(searchItem)
    } else {
      onSelect(searchItem)
    }
  }, [onSelect, onUnselect, searchItem, isSelected])

  const title = useMemo(
    () => getSearchableTextBold(searchItem.format.mainText, searchTerm),
    [searchTerm, searchItem.format.mainText],
  )
  const subtitle = useMemo(
    () => getSearchableTextBold(searchItem.format.secondaryText || '', searchTerm),
    [searchTerm, searchItem.format.secondaryText],
  )

  return <ListItem
    onClick={onLocationChange}
    title={title}
    subtitle={subtitle}
    disabled={isDisabled}
    endIcon={<CheckboxButton size="large" checked={isSelected} disabled={isDisabled} />}
  />
}

export default CruiseDepartureSelectListItem

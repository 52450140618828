import { getCarHireSelectedRateOption } from 'checkout/lib/utils/carHire/view'
import { getCarHireAddonTotal, getCarHireItemsView } from 'checkout/selectors/view/carHire'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'

export const getCarHireTotals = createSelector(
  getCarHireItemsView,
  getCarHireAddonTotal,
  (viewWithStatus, carHireAddonFees): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    const paidOnPickUpFees = sum(definedItems, itemView => itemView.taxesAndFees ?? 0)
    const payOnArrival = sum(definedItems, itemView => itemView.carHirePayOnArrival ?? 0)

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => getCarHireSelectedRateOption(itemView.item).payNowAmount + (itemView.item.selectedInsurance?.total || 0)),
        memberPrice: 0,
        value: sum(definedItems, itemView => itemView.value ?? 0),
        surcharge: 0,
        taxesAndFees: 0,
        carHirePaidOnPickUpFees: carHireAddonFees + paidOnPickUpFees,
        carHireAddonFees,
        carHirePayOnArrival: payOnArrival,
      },
    }
  },
)

import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import InputText from 'components/Luxkit/Typography/InputText'
import { rem } from 'polished'
import React, { useState } from 'react'
import styled from 'styled-components'
import HiddenInput from './HiddenInput'
import InputLabelWrap from './InputLabelWrap'
import InputWrap from './InputWrap'
import TextInput from './TextInput'

const FakeInputElement = styled(InputWrap)`
  width: 100%;
  padding: ${rem(12)};
  position: relative;

  &.icon-left {
    padding-left: ${rem(48)};
  }

  &.icon-right {
    padding-right: ${rem(48)};
  }
`

const FakeButton = styled(Clickable)`
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
`

interface Props extends Omit<React.ComponentProps<typeof TextInput>, 'textInputRef' | 'onClick'> {
  onClick?: React.ComponentProps<typeof Clickable>['onClick']
}

const FakeInput = React.forwardRef<HTMLLabelElement, Props>((props, ref) => {
  const {
    value,
    placeholder,
    disabled,
    label,
    optionalIndicatorEnabled,
    startIcon,
    endIcon,
    onClick,
    className,
    ...otherProps
  } = props

  const [error, setError] = useState<string>()
  const showPlaceholder = !value
  const placeholderColour = disabled ? 'neutral-two' : 'neutral-four'

  return <InputLabelWrap
    label={label}

    required={otherProps.required}
    optionalIndicatorEnabled={optionalIndicatorEnabled}
    ref={ref}
  >
    <FakeInputElement
      {...otherProps}
      className={cn(className, { 'icon-left': !!startIcon, 'icon-right': !!endIcon })}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      error={error}
    >
      {onClick && <FakeButton onClick={onClick} disabled={disabled} data-testid="fake-input-button" />}
      <HiddenInput
        {...otherProps}
        data-testid={undefined}
        value={value}
        disabled={disabled}
        invalidMessage={otherProps.requiredErrorMessage}
        onErrorUpdate={setError}
      />
      <InputText
        variant="text-regular"
        wrap="truncate"
        colour={showPlaceholder ? placeholderColour : undefined}
      >
        {value || placeholder}&nbsp;
      </InputText>
    </FakeInputElement>
  </InputLabelWrap>
})

FakeInput.displayName = 'FakeInput'

export default FakeInput

import React, { useContext } from 'react'
import GeoContext from 'contexts/geoContext'
import PartnershipLabel from 'components/Luxkit/Label/PartnershipLabel'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

function isInvalid<T>(types: Array<T>, target: T): boolean {
  return types.length > 0 && !types.includes(target)
}

interface Props {
  className?: string;
  offer: App.Offer | App.OfferSummary;
}

function OfferPartnershipLabel(props: Props) {
  const {
    offer,
    className,
  } = props

  const { partnership } = useContext(GeoContext)

  if (!partnership ||
    (partnership.availableForOfferType && isInvalid(partnership.availableForOfferType, offer.type)) ||
    (partnership.availableForProductType && isInvalid(partnership.availableForProductType, offer.productType)) ||
    partnership.code === VIRGIN_VELOCITY_PARTNERSHIP_CODE ||
    !offer.canEarnPartnershipPoints
  ) {
    return null
  }

  return <>
    <CSSBreakpoint min="tablet">
      <PartnershipLabel
        canEarn={offer.canEarnPartnershipPoints}
        className={className}
        variant="prominent"
      />
    </CSSBreakpoint>
    <CSSBreakpoint max="mobile">
      <PartnershipLabel
        canEarn={offer.canEarnPartnershipPoints}
        className={className}
        variant="icon-prominent"
      />
    </CSSBreakpoint>
  </>
}

export default React.memo(OfferPartnershipLabel)

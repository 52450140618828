import { arrayToObject } from 'lib/array/arrayUtils'
import { useMemo, useState } from 'react'
import useStableObjectReference from './useStableObjectReference'

/**
 * Like useState, but returns an object with setters for each value.
 * Handy when switching between pre-defined values such as several tabs,
 * because the setter functions are stable so you can pass them as `onClick`
 * handlers without having to create your own setters with `useCallback`.
 *
 * @param values The possible values for the state.
 * @param initialState The initial state. Defaults to the first value in `values`.
 * @returns A tuple of the current state, an object with setters for each value, and the setState function.
 */
export default function useStateWithSetters<const S extends string>(
  values: Array<S>,
  initialState?: S | (() => S),
) {
  const [state, setState] = useState<S>(initialState ?? values[0])

  const stableValues = useStableObjectReference(values)

  const setters = useMemo(() => {
    return arrayToObject(
      stableValues,
      (val) => val,
      (val) => () => setState(val),
    ) as { [K in S]: () => void }
  }, [stableValues])

  return [state, setters, setState] as [S, typeof setters, typeof setState]
}

import moment from 'moment'
import { CalendarPickerMonthView } from 'selectors/offerPage/offerPageSelectors'
import { getPlural } from 'lib/string/pluralize'

// MILLISECOND is a unit of time
export const MILLISECOND = 1
// SECOND is 1000 milliseconds
export const SECOND = 1000 * MILLISECOND
// MINUTE is 60 seconds
export const MINUTE = 60 * SECOND
// HOUR is 60 minutes
export const HOUR = 60 * MINUTE
// DAY is 24 hours
export const DAY = 24 * HOUR

// timeStrToMs returns the number of milliseconds equivalent to the given string representing a time
// in the format such as '18:30', '08:30', or '8:30'.
export function timeStrToMs(timeStr: string) {
  const [hourStr, minuteStr] = timeStr.split(':')

  const hour = parseInt(hourStr, 10)
  const minute = parseInt(minuteStr, 10)

  return hour * HOUR + minute * MINUTE
}

export function dayDurationToMs(numDays: number) {
  return numDays * DAY * HOUR * MINUTE * SECOND
}

// getDurationStr converts the given duration represented by hour and minutes
// to a string conforming to the W3C HTML5 specification
export function getDurationStr(hour: string | number, minute: string | number) {
  return `PT${hour}H${minute}M`
}

// toTwelveHourTime converts a military time in format of '22:30' to '8:30pm'
export function toTwelveHourTime(timeStr: string) {
  const [hourStr, minuteStr] = timeStr.split(':')

  let hour = parseInt(hourStr, 10)

  let period
  if (hour < 12) {
    if (hour === 0) {
      hour = 12
    }

    period = 'am'
  } else {
    hour = hour - 12

    if (hour === 0) {
      hour = 12
    }

    period = 'pm'
  }

  return `${hour}:${minuteStr}${period}`
}

export function formatDescriptiveDate(d: moment.Moment) {
  return d.format('ddd, D MMM YYYY')
}

export function generateWeekFrom(date: moment.Moment) {
  const week = [date]

  for (let i = 1; i < (7 - date.day()); i++) {
    const newDate = date.clone().add(i, 'days')
    if (newDate.month() !== date.month()) {
      break
    }

    week.push(newDate)
  }

  return week
}

function padBeginningOfWeek(week: Array<moment.Moment>) {
  const lastDay = getLastDayOfWeek(week)
  const lastDayNumber = lastDay.day() + 1
  return new Array(lastDayNumber - week.length).fill(null).concat(week)
}
function padEndOfWeek(week: Array<moment.Moment>) {
  return week.concat(new Array(7 - week.length).fill(null))
}
function getLastDayOfWeek(week: Array<moment.Moment>) {
  return week[week.length - 1]
}

export function getCalendarMonthWeeks(date: moment.Moment, forceToSixWeeks: boolean = false) {
  const lastDayOfMonth = date.clone().endOf('month').startOf('day')
  const firstDateOfWeek = Math.max(1, date.date() - (date.day() % 7))
  const weeks = [generateWeekFrom(moment([date.year(), date.month(), firstDateOfWeek]))]

  let lastDay = getLastDayOfWeek(weeks[0])
  while (lastDay.isBefore(lastDayOfMonth)) {
    const newWeek = generateWeekFrom(lastDay.clone().add(1, 'day'))
    lastDay = getLastDayOfWeek(newWeek)
    weeks.push(newWeek)
  }

  if (!getLastDayOfWeek(weeks[0]).isSame(lastDayOfMonth)) {
    weeks[0] = padBeginningOfWeek(weeks[0])
  }

  weeks[weeks.length - 1] = padEndOfWeek(weeks[weeks.length - 1])

  if (forceToSixWeeks) {
    while (weeks.length < 6) {
      weeks.push(new Array(7).fill(null))
    }
  }

  return weeks
}

export function calendarDateToMoment(date: App.CalendarDay | App.CalendarMonth | CalendarPickerMonthView): moment.Moment {
  const result = moment()
  result.year(parseInt(date.year)).month(date.month)
  if ('day' in date) {
    result.date(date.day)
  }
  return result
}

/**
 * Get current time since January 1, 1970 in seconds
 */
export function timeNowInSecond() {
  return Math.round(Date.now() / 1000)
}

export function getHourMinutesFromMinutes(minutes: number) {
  if (minutes < 60) {
    return { hours: 0, minutes }
  }
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return { hours, minutes: remainingMinutes }
}

export function formatMinutesToHoursMinutes(minutes: number) {
  const { hours, minutes: remainingMinutes } = getHourMinutesFromMinutes(minutes)
  if (hours === 0 && remainingMinutes < 60) {
    return `${remainingMinutes} minutes`
  }
  return `${hours}${(remainingMinutes > 0 ? `h ${remainingMinutes}m` : getPlural(' hour', hours))}`
}

export function addMinuteToTimeStr(timeStr: string, minuteToAdd: number) {
  const [hourStr, minuteStr] = timeStr.split(':')
  const hour = parseInt(hourStr, 10)
  const minute = parseInt(minuteStr, 10)
  const { hours: hourToAdd, minutes: remainingMinutes } = getHourMinutesFromMinutes(minute + minuteToAdd)
  return `${(hour + hourToAdd).toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`
}

export function convertTwelveHourTimeToTimeStr(twelveTimeStr: string) {
  const period = twelveTimeStr.slice(-2)
  const [hourStr, minuteStr] = twelveTimeStr.slice(0, -2).split(':')
  let hour = parseInt(hourStr, 10)
  const hourToAdd = period.toLowerCase() === 'pm' ? 12 : 0
  hour = (hour === 12 && hourToAdd === 12) ? hour : (parseInt(hourStr, 10) + hourToAdd)
  const minute = parseInt(minuteStr, 10)

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineShowerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M8 7c0-2.7614 2.2386-5 5-5h4c2.7614 0 5 2.2386 5 5v14c0 .5523-.4477 1-1 1s-1-.4477-1-1V7c0-1.6569-1.3431-3-3-3h-4c-1.6569 0-3 1.3431-3 3v.4776c2.4303.378 4.4457 2.1098 4.9028 4.4228H15c.5523 0 1 .4477 1 1s-.4477 1-1 1H3c-.5523 0-1-.4477-1-1s.4477-1 1-1h.0972C3.5543 9.5874 5.5697 7.8556 8 7.4776V7Zm-2.8306 4.9004h7.6612c-.4824-1.3992-1.9508-2.5-3.8306-2.5s-3.3482 1.1008-3.8306 2.5Z" clipRule="evenodd"/>
    <path fill="currentColor" d="M6 15.5996c0-.5523-.4477-1-1-1s-1 .4477-1 1v.9c0 .5523.4477 1 1 1s1-.4477 1-1v-.9ZM5 18.1992c.5523 0 1 .4477 1 1v.9c0 .5523-.4477 1-1 1s-1-.4477-1-1v-.9c0-.5523.4477-1 1-1ZM10 15.5996c0-.5523-.4477-1-1-1s-1 .4477-1 1v.9c0 .5523.4477 1 1 1s1-.4477 1-1v-.9ZM9 18.1992c.5523 0 1 .4477 1 1v.9c0 .5523-.4477 1-1 1s-1-.4477-1-1v-.9c0-.5523.4477-1 1-1ZM14 15.5996c0-.5523-.4477-1-1-1s-1 .4477-1 1v.9c0 .5523.4477 1 1 1s1-.4477 1-1v-.9ZM13 18.1992c.5523 0 1 .4477 1 1v.9c0 .5523-.4477 1-1 1s-1-.4477-1-1v-.9c0-.5523.4477-1 1-1Z"/>
  </SvgIcon>
}

export default LineShowerIcon

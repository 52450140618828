import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineChairWithAirplaneIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22.7057 2.7098c.3905-.3905.3905-1.0237 0-1.4142-.3906-.3905-1.0237-.3905-1.4143 0l-1.6843 1.6843-3.6563-1.2188a1 1 0 0 0-1.2247.5308.9999.9999 0 0 0 .394 1.2754l2.4373 1.4624-1.7667 1.7666-.7696-.3847c-.4939-.247-1.0946-.0468-1.3416.4472-.247.494-.0468 1.0946.4472 1.3416l1.1161.558.558 1.1161c.247.494.8477.6942 1.3417.4472.4939-.247.6942-.8476.4472-1.3416l-.3848-.7696 1.7667-1.7666L20.434 8.881a1 1 0 0 0 1.8061-.8307L21.0214 4.394l1.6843-1.6843Z"/>
    <path fill="currentColor" fillRule="evenodd" d="M1 15.9999c0-1.3332.8697-2.4633 2.0726-2.8539C3.4778 10.7914 5.5297 9 8 9h3c2.4703 0 4.5222 1.7914 4.9274 4.146C17.1303 13.5366 18 14.6667 18 15.9999v2c0 1.3062-.8348 2.4175-2 2.8293v1.1707c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1H5v1c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1.1707c-1.1652-.4118-2-1.5231-2-2.8293v-2Zm15 2c0 .5523-.4477 1-1 1h-1v-3c0-.5523.4477-1 1-1s1 .4477 1 1v2Zm-12 1h1v-3c0-.5523-.4477-1-1-1s-1 .4477-1 1v2c0 .5523.4477 1 1 1Zm2.8293-4A3.0089 3.0089 0 0 0 5.105 13.21c.3468-1.2736 1.5115-2.2101 2.895-2.2101h3c1.3835 0 2.5482.9365 2.8949 2.2101a3.0089 3.0089 0 0 0-1.7242 1.7899H6.8293Zm.1707 4h5v-2H7v2Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineChairWithAirplaneIcon

import { CuratedTripFilters } from 'tripPlanner/api/templates'

export const all = ['curatedTrips'] as const
export const lists = (
  regionCode: string,
  timeZoneOffset: number,
  filters: CuratedTripFilters,
  limit: number = 1,
) => [...all, 'list', regionCode, timeZoneOffset, filters, limit] as const
export const details = [...all, 'details'] as const
export const detail = (id: string) => [...details, id] as const
export const summary = (id: string) => [...all, 'summary', id] as const
export const metadata = (id: string) => [...all, 'metadata', id] as const
export const curator = (tripId: string) => ['curator', tripId] as const
export const paginatedTemplates = [...all, 'paginated'] as const
export const validation = (tripId: string) =>
  [...all, tripId, 'validation'] as const

import { BasicTrip } from 'tripPlanner/types/common'

export const getUniqueTripName = (
  original: string,
  trips: Array<BasicTrip>,
  maxLength: number,
): string => {
  let num = 0
  const nonEmptyOriginal = original || 'New Trip'
  let proposed = prepareProposedTripName(maxLength, undefined, nonEmptyOriginal)

  const existingNames = new Set(trips.map((trip) => trip.name))
  while (existingNames.has(proposed)) {
    num++
    const suffix = ` (${num})`
    proposed = prepareProposedTripName(maxLength, suffix, nonEmptyOriginal)
  }

  return proposed.replace(/[\n\r]/g, ' ')
}

export const prepareProposedTripName = (
  maxLength: number,
  suffix?: string,
  original?: string,
) => {
  if (original) {
    const additionalLength = suffix?.length || 0
    const trimmed = original.substring(0, maxLength - additionalLength - 1)
    return trimmed + (suffix ?? '')
  } else {
    return 'New Trip'
  }
}

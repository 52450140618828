import { sortBy } from 'lib/array/arrayUtils'
import { CheckoutPageId } from 'checkout/constants/pages'
import { matchPath } from 'react-router'

const CRUISE_CHECKOUT_STEP_IDS = [
  CheckoutPageId.CruiseTravelers,
  CheckoutPageId.CruiseCabinType,
  CheckoutPageId.CruiseCabinCategory,
  CheckoutPageId.CruiseCabinLocation,
  CheckoutPageId.CruiseCabinPackage,
  CheckoutPageId.Purchase,
]

const STEPS_REQUIRE_PER_CABIN_SELECTION = [
  CheckoutPageId.CruiseCabinLocation,
]

const STEPS_WITH_NO_CABIN_SELECTION = [
  CheckoutPageId.CruiseTravelers,
  CheckoutPageId.LuxPlus,
]

const CRUISE_CHECKOUT_STEP_ALL = 'all'

export function getIsInFlowStep(stepId: string): boolean {
  return stepId !== CheckoutPageId.Purchase
}

export function getCruiseCheckoutStep(items: Array<App.Checkout.CruiseItem>): CheckoutPageId {
  const stepsByItem = items.map((item) => {
    const adults = item.occupancy?.adults || 0

    // get the booking flow step based on the item data
    if ((item.cabinCode && item.cabinNumber) || item.componentId) return CheckoutPageId.CruiseCabinPackage
    if (item.cabinCodes?.length) return CheckoutPageId.CruiseCabinLocation
    if (item.cabinType) return CheckoutPageId.CruiseCabinCategory
    if (adults) return CheckoutPageId.CruiseCabinType

    return CheckoutPageId.CruiseTravelers
  })

  // We need to restore the booking flow using the earliest step when there are multiple items
  const [earliestStep] = sortBy(
    stepsByItem,
    (step) => CRUISE_CHECKOUT_STEP_IDS.indexOf(step) || 0,
    'asc',
  )

  return earliestStep
}

export function getCabinStepIdFromCruiseItem(cruiseItems: Array<App.Checkout.CruiseItem>, cruiseItemId: string): string {
  const cabinIndex = cruiseItems.findIndex((item) => item.itemId === cruiseItemId)
  return `cabin-${cabinIndex + 1}`
}

export function getCruiseItemFromCabinStepId(cruiseItems: Array<App.Checkout.CruiseItem>, cabinStepId?: string): App.Checkout.CruiseItem | undefined {
  return cruiseItems.find((item, index) => `cabin-${index + 1}` === cabinStepId)
}

export function getCurrentCruiseCheckoutPage(pathname: string, cruiseItems: Array<App.Checkout.CruiseItem>) {
  const isMultiCabin = cruiseItems.length > 1

  const purchasePageMatch = matchPath<{ step: string }>(pathname, '/:regionCode/checkout/:cartId/:step?')
  const cruiseCheckoutMatch = matchPath<{ currentStepId: string, cabinStepId?: string }>(pathname, '/:regionCode/checkout/:currentStepId/:cabinStepId?')

  const isPurchasePage = purchasePageMatch?.params.step === CheckoutPageId.Purchase
  const currentStepId = isPurchasePage ?
    CheckoutPageId.Purchase :
    cruiseCheckoutMatch?.params.currentStepId as CheckoutPageId

  if (isMultiCabin) {
    // Find the cruise item based on the cabinStepId
    const cabinStepId = cruiseCheckoutMatch?.params.cabinStepId
    const selectedCabinItem = getCruiseItemFromCabinStepId(cruiseItems, cabinStepId)

    return {
      isAllCabinsSelected: cabinStepId === CRUISE_CHECKOUT_STEP_ALL,
      selectedCabinItemId: selectedCabinItem?.itemId,
      currentStepId,
    }
  }

  // For single cabin, we always return the first item
  return {
    isAllCabinsSelected: false,
    selectedCabinItemId: cruiseItems[0]?.itemId,
    currentStepId,
  }
}

export function getNextCabinStepId(
  cruiseItems: Array<App.Checkout.CruiseItem>,
  selectedCabinItemId: string | undefined,
  nextCheckoutStepId: CheckoutPageId,
  isAllCabinsSelected: boolean,
): string {
  if (isAllCabinsSelected && !STEPS_REQUIRE_PER_CABIN_SELECTION.includes(nextCheckoutStepId)) {
    return CRUISE_CHECKOUT_STEP_ALL
  }

  const currentCabinIndex = cruiseItems.findIndex((item) => item.itemId === selectedCabinItemId)
  // if there is no next cabin, return to the first cabin
  const nextCabinItem = cruiseItems[currentCabinIndex + 1] || cruiseItems[0]

  // In the URL we want to display a more readable cabin ID for the user, instead of an UUID, e.g: /cabin-1
  return getCabinStepIdFromCruiseItem(cruiseItems, nextCabinItem.itemId)
}

export function getSteps(luxPlusEnabled: boolean, canRedeemLuxPlusBenefits: boolean, cruiseItems: Array<App.Checkout.CruiseItem>): Array<CheckoutPageId> {
  const cruiseItemHasLuxPlusInclusions = cruiseItems.some((item) => !!item.luxPlusInclusionsByTier)
  if (luxPlusEnabled && !canRedeemLuxPlusBenefits && cruiseItemHasLuxPlusInclusions) {
    // Add the LuxPlus step before the purchase step
    return [
      ...CRUISE_CHECKOUT_STEP_IDS.slice(0, -1),
      CheckoutPageId.LuxPlus,
      CRUISE_CHECKOUT_STEP_IDS[CRUISE_CHECKOUT_STEP_IDS.length - 1],
    ]
  }

  return CRUISE_CHECKOUT_STEP_IDS
}

export function getNextCheckoutStepId(
  cruiseItems: Array<App.Checkout.CruiseItem>,
  currentStepId: CheckoutPageId,
  selectedCabinItemId: string | undefined,
  isAllCabinsSelected: boolean,
  steps: Array<CheckoutPageId>,
): CheckoutPageId {
  const currentCabinIndex = cruiseItems.findIndex((item) => item.itemId === selectedCabinItemId)

  // This can only move to a new step when all cabins have been selected or the step is in STEPS_WITH_NO_CABIN_SELECTION
  const canMoveToNextStep = (
    currentCabinIndex + 1 === cruiseItems.length ||
    isAllCabinsSelected ||
    STEPS_WITH_NO_CABIN_SELECTION.includes(currentStepId)
  )

  if (canMoveToNextStep) {
    const currentStepIndex = steps.findIndex(step => step === currentStepId)
    const nextCheckoutStepId = steps[currentStepIndex + 1]
    return nextCheckoutStepId
  }

  // If there are still cabins to select, we will keep the same step
  // Booking steps doc: https://aussiecommerce.atlassian.net/wiki/spaces/CRUIS/pages/edit-v2/3020947492#Booking-flow-diagram%3A
  return currentStepId
}

// When selecting a step data, we need to reset all subsequent steps data, to ensure data integrity
export function clearNextCheckoutStepsData(
  cruiseItem: App.Checkout.CruiseItem,
  currentStepId: CheckoutPageId,
): App.Checkout.CruiseItem {
  const dataByStep = [
    { step: CheckoutPageId.CruiseTravelers, data: ['occupancy'] },
    { step: CheckoutPageId.CruiseCabinType, data: ['cabinType'] },
    { step: CheckoutPageId.CruiseCabinCategory, data: ['cabinCodes'] },
    { step: CheckoutPageId.CruiseCabinLocation, data: ['cabinNumber', 'cabinCode', 'deckId'] },
    { step: CheckoutPageId.CruiseCabinPackage, data: ['componentId', 'cabinPreferences', 'luxPlusInclusions'] },
  ]

  const currentStepIndex = dataByStep.findIndex((step) => step.step === currentStepId)
  if (currentStepIndex === -1) return cruiseItem

  let newItem = { ...cruiseItem }

  const nextSteps = dataByStep.slice(currentStepIndex + 1)
  nextSteps.forEach((step) => {
    step.data.forEach((key) => {
      const value = key === 'occupancy' ? { adults: 0, children: 0, infants: 0 } : undefined
      newItem = { ...newItem, [key]: value }
    })
  })

  return newItem
}

import { createListenerMiddleware } from '@reduxjs/toolkit'
import * as Analytics from 'analytics/analytics'
import { trackPageView } from 'analytics/snowplow/events'
import { waitPageViewDataLoaded } from '../analytics/snowplow/helpers'
import { PAGE_VIEW } from 'actions/actionConstants'

const analyticsPageViewMiddleware = createListenerMiddleware()

const pageChangedBetweenWait = (oldState:App.State, newState:App.State) => {
  return oldState.routeHistory.currentPath !== newState.routeHistory.currentPath
}

analyticsPageViewMiddleware.startListening({
  type: PAGE_VIEW,
  effect: async(action, listenerApi) => {
    const state = listenerApi.getState() as App.State
    if (state.routeHistory.currentPath === state.routeHistory.prevPath) return
    // check conditions for awaiting specific redux state data items
    const numberOfItemsWaitedFor = await waitPageViewDataLoaded(state, listenerApi)
    const newState = listenerApi.getState() as App.State
    // if page changed between wait abandon tracking, the new page view will also trigger and with the correct data
    if (numberOfItemsWaitedFor.length > 0 && pageChangedBetweenWait(state, newState)) return
    Analytics.trackEvent(trackPageView(state.routeHistory.currentPath, state.routeHistory.currentPathParams))
  },
})

export default analyticsPageViewMiddleware

import React from 'react'
import CaptionTextBlock from '../TextBlocks/CaptionTextBlock'
import TextLoadingBox from '../Typography/TextLoadingBox'
import IconLoadingBox from '../Icons/IconLoadingBox'

function PriceRowFlightPackageLoadingSkeleton() {
  return <CaptionTextBlock variant="medium" startIcon={<IconLoadingBox />}>
    <TextLoadingBox width="20ch" />
  </CaptionTextBlock>
}

export default PriceRowFlightPackageLoadingSkeleton

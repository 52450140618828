import Group from 'components/utils/Group'
import React from 'react'
import BedbankOfferPriceDetails from './BedbankOfferPriceDetails'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TextButton from 'components/Luxkit/Button/TextButton'
import WalledContent from 'components/Common/WalledContent'
import OfferTileLoggedInButtons from '../OfferListTiles/Support/OfferTileLoggedInButtons'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary | App.BedbankOfferWithRate
  variant?: 'default' | 'condensed';
  selectedDatesAndRoomsFilter?: App.OfferListFilters
}

function BedbankOfferCardPriceDetails(props: Props) {
  const { offer, variant = 'default', selectedDatesAndRoomsFilter } = props

  const wall =
    <OfferTileLoggedInButtons
      signUpText="Sign up for free"
      discountLabelAlign="end"
      align="right"
      fullWidth={false}
    />

  return <Group direction="horizontal" gap={8} horizontalAlign="space-between" verticalAlign="end">
    <WalledContent enforced={offer.walledGarden} hidePricing={offer.hidePricing} wall={wall}>
      <BedbankOfferPriceDetails variant={variant} offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}/>
      {variant === 'default' && <CSSBreakpoint min="desktop">
        <TextButton kind="primary" nonInteractable>
          View
        </TextButton>
      </CSSBreakpoint>}
      {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
        View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
      </TextButton> }
    </WalledContent>
  </Group>
}

export default BedbankOfferCardPriceDetails

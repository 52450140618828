import * as isomorphicLocalStorage from 'lib/storage/isomorphicLocalStorage'

const now = () => new Date().getTime()

export function setTimeUserLastSeenSnackbar(userId: string) {
  isomorphicLocalStorage.set(`credit-snackbar-${userId}`, JSON.stringify(now()))
}

// The credits snackbar will show again after 24 hours (86400000 ms)
export function isTooSoonToShowSnackbar(userId: string) {
  const lastSavedTime = isomorphicLocalStorage.get(`credit-snackbar-${userId}`)
  return lastSavedTime ? (now() - JSON.parse(lastSavedTime)) < 86400000 : false
}

import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { dateIsAfter, dateIsBefore, isSameDay } from 'lib/datetime/dateUtils'
import { getCalendarMonthWeeks } from 'lib/datetime/time'
import { rem } from 'polished'
import moment from 'moment'
import cn from 'clsx'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import Clickable from '../Clickable'
import BodyText from '../../Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(7 , 1fr);
  grid-auto-rows: ${rem(44)};
`

const DayCell = styled(Clickable)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};
  border-right: 1px solid ${props => props.theme.palette.neutral.default.six};
  transition: background-color 0.2s;

  &.row-0 {
    border-top: 1px solid ${props => props.theme.palette.neutral.default.six};
  }

  &.column-0 {
    border-left: 1px solid ${props => props.theme.palette.neutral.default.six};
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.six};

    background-image: linear-gradient(
      to top right,
      white 50%,
      ${props => props.theme.palette.neutral.default.six},
      white 52%
    );
  }

  &:not(:disabled):not(.selected-date) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
      }
    }
    &:focus {
      background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
    }
  }

  &.selected-date {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }

  &.in-range-date {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  }
`

const EmptyCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.top-row {
    border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};
  }

  &.last-empty {
    border-right: 1px solid ${props => props.theme.palette.neutral.default.six};
  }
`

const SelectedDateLabel = styled.span`
  position: absolute;
  top: 0;
  font-size: ${rem(10)};
  font-weight: ${props => props.theme.font.primary.weight.bold};
  display: none;

  ${mediaQueryUp.desktop} {
    display: inline
  }
`

interface Props {
  startOfMonth: Date;
  minDate?: Date;
  maxDate?: Date;
  onDayClick: (day: moment.Moment) => void;
  startDate?: Date;
  endDate?: Date;
  startLabel?: string;
  endLabel?: string;
}

function CalendarGrid(props: Props) {
  const {
    startOfMonth,
    minDate,
    maxDate,
    onDayClick,
    startDate,
    endDate,
    startLabel,
    endLabel,
  } = props

  const monthIndex = startOfMonth.getMonth()
  const weeks = useMemo(() => getCalendarMonthWeeks(moment(startOfMonth), true), [startOfMonth])

  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.dataset?.date) {
      const day = moment(+e.currentTarget.dataset.date)
      onDayClick(day)
    }
  }, [onDayClick])

  return (
    <Grid>
      {weeks.map((week, weekIndex) => week.map((day, dayIndex) => {
        if (!day) {
          return <EmptyCell
            key={`${monthIndex}-${weekIndex}-${dayIndex}`}
            className={cn({ 'top-row': weekIndex === 0, 'last-empty': !!week[dayIndex + 1] })}
          />
        }

        const dateOfDay = day.toDate()

        let disabled: boolean = false
        if (minDate && maxDate) {
          disabled = dateIsBefore(dateOfDay, minDate) || dateIsAfter(dateOfDay, maxDate)
        } else if (minDate || maxDate) {
          disabled = dateIsBefore(dateOfDay, minDate) || dateIsAfter(dateOfDay, maxDate)
        }

        const isStartDate = (startDate && isSameDay(dateOfDay, startDate))
        const isEndDate = !isStartDate && (endDate && isSameDay(dateOfDay, endDate))
        const isSelected = isStartDate || isEndDate
        const hasLabel = (isStartDate && startLabel) || (isEndDate && endLabel)
        const isInRange = (!isSelected && startDate && endDate) ? dateIsAfter(dateOfDay, startDate) && dateIsBefore(dateOfDay, endDate) : false

        return <DayCell
          key={`${monthIndex}-${weekIndex}-${dayIndex}`}
          disabled={disabled}
          className={cn(`row-${weekIndex}`, `column-${dayIndex}`, { 'selected-date': isSelected, 'in-range-date': isInRange })}
          data-testid={`day-${day.format(ISO_DATE_FORMAT)}`}
          data-date={day.valueOf()}
          onClick={onClick}
        >
          {(isSelected && hasLabel) && <SelectedDateLabel>{isStartDate ? startLabel : endLabel}</SelectedDateLabel>}
          <BodyText variant="medium">{day.date()}</BodyText>
        </DayCell>
      }))}
    </Grid>
  )
}

export default React.memo(CalendarGrid)

import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import { buildCancellationPolicies, buildPartiallyRefundableCancellationPolicies } from '@luxuryescapes/lib-refunds'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'
import uuidV4 from 'lib/string/uuidV4Utils'

function bedGroupsMap(bedGroup: PublicOfferV2.BedGroup): App.RoomBedGroups {
  return {
    id: bedGroup.id,
    description: bedGroup.description,
    configuration: bedGroup.configuration.map(bedGroupsConfigurationMap),
  }
}

function bedGroupsConfigurationMap(configuration: PublicOfferV2.Configuration): App.BedGroupsConfiguration {
  return {
    type: configuration.type,
    size: configuration.size,
    quantity: configuration.quantity,
  }
}

// server code should be one of these 'RO' | 'BB' | 'HB' | 'FB' | 'AL'
function mapBedbankBoardCode(bedbankCode: string | undefined) : App.RatePlanBoardCode {
  switch (bedbankCode) {
    case 'AL':
      return 'allinclusive'
    case 'BB':
      return 'breakfast'
    case 'FB':
      return 'fullboard'
    case 'HB':
      return 'halfboard'
    case 'RO':
      return 'roomonly'
    default:
      return 'LE'
  }
}

// The back end currently creates these specific facilities records based on the board code
// We're goign to hijack this and provide our own
const knownBoardFacilities = new Set<string>([
  'Daily breakfast',
  'Daily breakfast & choice of daily lunch or dinner',
  'Daily breakfast, lunch & dinner',
  'All inclusive: Daily breakfast, lunch, dinner & drinks',
])

const knownFacilityToSymbolMapping = new Map<string, App.OfferFeatureSymbol>([
  ['nightly cocktail', 'martini_glass'],
  ['free-flow', 'wine_glass_and_beer_glass'],
  ['free-flow hour', 'wine_glass_with_clock'],
  ['welcome drink', 'wine_glass'],
  ['welcome bottle of wine', 'drink_tray'],
  ['minibar', 'drink_tray'],
  ['beverage', 'beverage'],
  ['snacks', 'apple'],
  ['dining experience', 'crossed_utensils'],
])

const KnownFacilities = Object.keys(knownFacilityToSymbolMapping)

function getRateInclusions(serverRate: PublicOfferV2.BedbankRate): Array<App.OfferInclusion> {
  const inclusions: Array<App.OfferInclusion> = []
  // board codes directly map to a set of inclusions, until we get this from the back end
  // manually map it ourselves, also select the appropriate symbol
  switch (serverRate.boardCode) {
    case 'AL':
      inclusions.push({
        id: uuidV4(),
        description: 'Daily breakfast',
        symbol: 'coffee_cup',
      })
      inclusions.push({
        id: uuidV4(),
        description: 'Daily lunch',
        symbol: 'crossed_utensils',
      })
      inclusions.push({
        id: uuidV4(),
        description: 'Daily dinner',
        symbol: 'crossed_utensils',
      })
      inclusions.push({
        id: uuidV4(),
        description: 'All inclusive',
        symbol: 'cloche',
      })
      break
    case 'BB':
      inclusions.push({
        id: uuidV4(),
        description: 'Daily breakfast',
        symbol: 'coffee_cup',
      })
      break
    case 'FB':
      inclusions.push({
        id: uuidV4(),
        description: 'Daily breakfast',
        symbol: 'coffee_cup',
      })
      inclusions.push({
        id: uuidV4(),
        description: 'Daily lunch',
        symbol: 'crossed_utensils',
      })
      inclusions.push({
        id: uuidV4(),
        description: 'Daily dinner',
        symbol: 'crossed_utensils',
      })
      break
    case 'HB':
      inclusions.push({
        id: uuidV4(),
        description: 'Daily breakfast',
        symbol: 'coffee_cup',
      })
      inclusions.push({
        id: uuidV4(),
        description: 'Choice of lunch or dinner',
        symbol: 'crossed_utensils',
      })
      break
  }

  const nonBoardCodeInclusions = serverRate.facilities.filter(facility => !knownBoardFacilities.has(facility))
  inclusions.push(...nonBoardCodeInclusions.map<App.OfferInclusion>(inclusion => {
    const knownSymbol = KnownFacilities.find(known => inclusion.toLowerCase().includes(known)) ?? ''
    return {
      id: uuidV4(),
      description: inclusion,
      symbol: knownFacilityToSymbolMapping.get(knownSymbol) ?? determineOfferFeatureSymbol(inclusion),
    }
  }))

  inclusions.push(...serverRate.promotions.map<App.OfferInclusion>(promotion => {
    const knownSymbol = KnownFacilities.find(known => promotion.toLowerCase().includes(known)) ?? ''

    return {
      id: uuidV4(),
      description: promotion,
      symbol: knownFacilityToSymbolMapping.get(knownSymbol) ?? determineOfferFeatureSymbol(promotion),
    }
  }))

  return inclusions
}

export default function bedbankRateMap({
  checkIn,
  checkOut,
  timezone,
  rate,
  room,
} : {
  checkIn: string;
  checkOut: string;
  timezone: string;
  rate: PublicOfferV2.BedbankRate;
  room: App.BedbankPackage;
}): App.BedbankRate {
  return {
    bedGroups: rate.bedGroups.map(bedGroupsMap),
    id: rate.id,
    optionId: rate.optionId,
    roomId: room.roomId,
    room,
    refundable: rate.refundable,
    partiallyRefundable: rate.partiallyRefundable,
    isFlightBundle: rate.isFlightBundle,
    mobilePromotion: rate.mobilePromotion,
    groupId: rate.groupId ?? undefined,
    corporate: rate.corporate,
    occupancyPricing: rate.occupancyPricing.map(op => ({
      ...op,
      occupancy: op.occupancy,
      exclusive: op.exclusive,
      inclusive: op.inclusive,
      taxesAndFees: op.taxesAndFees,
      salesTax: op.salesTax,
      fees: op.fees,
      memberExclusive: op.luxPlusExclusive ?? 0,
      memberInclusive: op.luxPlusInclusive ?? 0,
    })),
    totals: {
      exclusive: rate.totals.exclusive,
      inclusive: rate.totals.inclusive,
      propertyFees: rate.totals.propertyFees,
      taxesAndFees: rate.totals.taxesAndFees,
      memberExclusive: rate.totals.luxPlusExclusive ?? 0,
      memberInclusive: rate.totals.luxPlusInclusive ?? 0,
    },
    currencyCode: rate.currencyCode,
    regionCode: rate.regionCode,
    facilities: rate.facilities,
    promotions: rate.promotions,
    nights: rate.duration,
    cancellationPolicies: rate.cancellationPolicies,
    nonRefundableDateRanges: rate.nonRefundableDateRanges,
    value: rate.value,
    discount: rate.discount,
    memberDiscount: rate.luxPlusDiscount ?? 0,
    cancellationPolicy: rate.partiallyRefundable ? buildPartiallyRefundableCancellationPolicies({ checkIn, checkOut, policies: rate.cancellationPolicies, nonRefundableDates: rate.nonRefundableDateRanges }, { timezone } as any) : buildCancellationPolicies(rate.cancellationPolicies, { timezone } as any),
    margin: rate.margin,
    marginAud: rate.marginAud,
    supplier: rate.supplier,
    supplierCode: rate.supplierCode,
    boardCode: mapBedbankBoardCode(rate.boardCode),
    inclusions: getRateInclusions(rate),
  }
}

import {
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_LAST_MINUTE_ANALYTICS,
  OFFER_TYPE_ALWAYS_ON_ANALYTICS,
  OFFER_TYPE_BED_BANK_ANALYTICS,
  OFFER_TYPE_BUNDLE_AND_SAVE_ANALYTICS,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_VILLA,
} from 'constants/offer'
import { LAST_MINUTE_LABEL_ID, LAST_MINUTE_PRODUCT_NAME } from 'constants/lastMinute'
import config from 'constants/config'

interface OfferConfig<
  P extends App.OfferParentType,
  O extends App.OfferType,
  A extends App.OfferAnalyticsType
> {
  hasBuyNowBookLater: boolean;
  parentType: P;
  type: O;
  typeLabel: string;
  analyticsType: A;
  productType: App.ProductType;
  walledGarden: boolean;
  hidePricing?: boolean;
  overlayImageLabel?: {
    alt: string;
    id: string;
  }
}

const TourConfig: OfferConfig<'tour', 'tour', 'tour'> = {
  analyticsType: OFFER_TYPE_TOUR,
  hasBuyNowBookLater: false,
  parentType: OFFER_TYPE_TOUR,
  type: OFFER_TYPE_TOUR,
  typeLabel: OFFER_TYPE_TOUR,
  walledGarden: false,
  productType: 'tour',
}

const HotelConfig: OfferConfig<'hotel', 'hotel', 'hotel'> = {
  analyticsType: OFFER_TYPE_HOTEL,
  hasBuyNowBookLater: config.HOTEL_BNBL_ENABLED,
  parentType: OFFER_TYPE_HOTEL,
  type: OFFER_TYPE_HOTEL,
  typeLabel: OFFER_TYPE_HOTEL,
  walledGarden: false,
  productType: 'hotel',
}

const LastMinuteConfig: OfferConfig<'hotel', 'last_minute_hotel', 'lastMinuteHotel'> = {
  analyticsType: OFFER_TYPE_LAST_MINUTE_ANALYTICS,
  hasBuyNowBookLater: false,
  parentType: OFFER_TYPE_HOTEL,
  type: OFFER_TYPE_LAST_MINUTE,
  typeLabel: OFFER_TYPE_HOTEL,
  walledGarden: true,
  overlayImageLabel: {
    alt: LAST_MINUTE_PRODUCT_NAME,
    id: LAST_MINUTE_LABEL_ID,
  },
  productType: 'last_minute_hotel',
}

const AlwaysOnConfig: OfferConfig<'hotel', 'tactical_ao_hotel', 'tacticalAOHotel'> = {
  analyticsType: OFFER_TYPE_ALWAYS_ON_ANALYTICS,
  hasBuyNowBookLater: false,
  parentType: OFFER_TYPE_HOTEL,
  type: OFFER_TYPE_ALWAYS_ON,
  typeLabel: OFFER_TYPE_HOTEL,
  walledGarden: true,
  productType: 'tactical_ao_hotel',
}

const BundleAndSaveConfig: OfferConfig<'hotel', 'bundle_and_save', 'bundle_and_save'> = {
  analyticsType: OFFER_TYPE_BUNDLE_AND_SAVE_ANALYTICS,
  hasBuyNowBookLater: false,
  parentType: OFFER_TYPE_HOTEL,
  type: OFFER_TYPE_BUNDLE_AND_SAVE,
  typeLabel: OFFER_TYPE_HOTEL,
  walledGarden: true,
  productType: 'bundle_and_save',
}

const BedbankConfig: OfferConfig<'hotel', 'bedbank_hotel', 'bedbankHotel'> = {
  analyticsType: OFFER_TYPE_BED_BANK_ANALYTICS,
  hasBuyNowBookLater: false,
  parentType: OFFER_TYPE_HOTEL,
  type: OFFER_TYPE_BED_BANK,
  typeLabel: OFFER_TYPE_HOTEL,
  walledGarden: true,
  productType: 'bedbank_hotel',
}

const VillasConfig: OfferConfig<'hotel', 'rental', 'rental'> = {
  analyticsType: 'rental',
  hasBuyNowBookLater: false,
  parentType: 'hotel',
  typeLabel: OFFER_TYPE_HOTEL,
  type: 'rental',
  productType: 'rental',
  walledGarden: false,
}

const configs: Utils.PartialRecord<App.OfferType, OfferConfig<any, any, any>> = {
  [OFFER_TYPE_TOUR]: TourConfig,
  [OFFER_TYPE_HOTEL]: HotelConfig,
  [OFFER_TYPE_LAST_MINUTE]: LastMinuteConfig,
  [OFFER_TYPE_ALWAYS_ON]: AlwaysOnConfig,
  [OFFER_TYPE_BUNDLE_AND_SAVE]: BundleAndSaveConfig,
  [OFFER_TYPE_BED_BANK]: BedbankConfig,
  [OFFER_TYPE_VILLA]: VillasConfig,
}

export default configs

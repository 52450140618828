import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFlightCreditIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.0024 5.081a3.0803 3.0803 0 0 0-5.26-2.18l-2.81 2.81-6.49-2.16a1.0001 1.0001 0 0 0-1 .24l-2.38 2.36a1 1 0 0 0 .29 1.61l5.18 2.3501-2.6 2.6-1.71-.86a.9999.9999 0 0 0-1.16.15l-1.77 1.81a.9999.9999 0 0 0 0 1.41l6.49 6.49a1.0001 1.0001 0 0 0 1.41 0l1.81-1.77a1 1 0 0 0 .19-1.16l-.86-1.71 2.5763-2.5764.0802.1743c.2308.5017.8246.7213 1.3264.4905.5017-.2308.7214-.8246.4906-1.3264l-.1095-.2378.0035-.0017-.5875-1.2925a.9995.9995 0 0 0-1.62-.29l-4.12 4.16a.9996.9996 0 0 0-.19 1.15l.82 1.68-.56.56-5.03-5.04.59-.52 1.71.86a1 1 0 0 0 1.15-.19l4.14-4.16a1.0008 1.0008 0 0 0 .28-.8861.9996.9996 0 0 0-.57-.734l-5.21-2.35.9-.9 6.48 2.16a1 1 0 0 0 1-.24l3.24-3.24a1.0691 1.0691 0 0 1 .765-.3218 1.0702 1.0702 0 0 1 .765.3218 1.0005 1.0005 0 0 1 .32.76 1.0605 1.0605 0 0 1-.29.77l-3.24 3.24a1.0004 1.0004 0 0 0-.24 1.0001l.1474.4246.097.2909c.1746.5239.741.8071 1.2649.6324.5239-.1746.8071-.741.6324-1.2649l-.0236-.0709.0119-.0121 2.77-2.83a3.0498 3.0498 0 0 0 .9-2.18ZM19.8556 11.431c.5523 0 1 .4478 1 1v.4287h1.1429c.5523 0 1 .4478 1 1 0 .5523-.4477 1-1 1h-2.8574a.5902.5902 0 0 0-.1494.0241c-.0658.0188-.1201.0451-.1592.0732-.0355.0256-.0584.0521-.0751.0845-.0163.0316-.045.104-.045.247 0 .1429.0287.2153.045.2469.0167.0324.0396.0589.0751.0845.0391.0281.0934.0544.1592.0732a.5902.5902 0 0 0 .1494.0241h1.4287c.3981 0 .9731.1112 1.4773.4742.5551.3997.9514 1.0571.9514 1.9545s-.3963 1.5547-.9514 1.9545c-.3933.2831-.8296.413-1.1915.4565v.4464c0 .5522-.4477 1-1 1s-1-.4478-1-1v-.4287h-1.1432c-.5523 0-1-.4478-1-1 0-.5523.4477-1 1-1h2.8574a.5896.5896 0 0 0 .1494-.0241c.0658-.0188.1201-.045.1592-.0732.0355-.0256.0584-.0521.0751-.0845.0163-.0316.045-.104.045-.2469 0-.143-.0287-.2154-.045-.247-.0167-.0324-.0396-.0589-.0751-.0845-.0391-.0281-.0934-.0544-.1592-.0732a.588.588 0 0 0-.1494-.024h-1.4287c-.3981 0-.9731-.1113-1.4772-.4743-.5552-.3997-.9515-1.057-.9515-1.9544s.3963-1.5548.9515-1.9545c.3933-.2832.8297-.4132 1.1917-.4566v-.4464c0-.5522.4477-1 1-1Z"/>
  </SvgIcon>
}

export default LineFlightCreditIcon

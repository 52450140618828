/** This is a bundle offer search tile
 *  This tile will first check whether (1) the search performed are property search and (2) the result has been soldout
 *  Then it will render either the (1) Expansed or (2) Condensed search results.
 * */

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { getSumExtraGuestsSurcharge } from 'lib/checkout/cartReservationUtils'
import { rem } from 'polished'
import useBestPriceBundleAndSavePackage from 'hooks/useBestPriceBundleAndSavePackage'
import BodyText from 'components/Luxkit/Typography/BodyText'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'
import { pluralizeToString } from 'lib/string/pluralize'
import { sum } from 'lib/array/arrayUtils'
import { getBundleValueUp } from 'lib/bundleAndSave/getBundleValueUp'
import TextButton from 'components/Luxkit/Button/TextButton'
import ErrorBoundary from 'components/Common/ErrorBoundary'
import Group from 'components/utils/Group'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import config from 'constants/config'
import PriceRowPriceWithCaption from 'components/Luxkit/PricePoints/PriceRowPriceWithCaption'
import { useOfferPrice } from 'hooks/Offers/useOfferPrice'

const Container = styled.div`
  background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  padding: ${rem(16)};
`

const StyledGroup = styled(Group)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  > * + * {
    width: fit-content;
  }
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  bundleOffer: App.BundleOffer;
  filters?: App.OfferListFilters;
  offerLinkIncludesFilters?: boolean;
}

function ViewBundleOfferContent(props: Props) {
  const {
    offer,
    bundleOffer,
    filters,
    offerLinkIncludesFilters,
  } = props

  const {
    bestPricePackage,
    bestPriceError,
    fetching,
    soldOut,
  } = useBestPriceBundleAndSavePackage(bundleOffer, offer.id, filters)

  const offerUrl = useOfferUrl(bundleOffer, {
    filters,
    bundledOfferId: offer.id,
    offerLinkIncludesFilters,
  })

  const priceMultiplier = (filters?.rooms?.length || 1)
  const extraGuestSurcharges = useMemo(() => bestPricePackage ?
    sum(bestPricePackage.packages.map(p =>
      getSumExtraGuestsSurcharge(filters.rooms, bundleOffer.bundledOffers[p.offerId], p),
    )) : 0, [bestPricePackage, filters.rooms, bundleOffer])

  const propertyFees = (bestPricePackage?.propertyFees || 0) * priceMultiplier

  const hotelPrice = (((bestPricePackage?.price ?? 0) + (bestPricePackage?.surcharge ?? 0)) * priceMultiplier) + extraGuestSurcharges
  const baseTotalPrice = hotelPrice + propertyFees

  const {
    saleUnit,
    total: totalPrice,
  } = useOfferPrice({
    value: bestPricePackage.value,
    saleUnit: 'total',
    duration: bestPricePackage.duration,
    total: baseTotalPrice,
  })

  if (!bestPricePackage || bestPriceError || fetching || soldOut) {
    return null
  }

  const secondOfferPackage = bestPricePackage.packages.find(x => x.offerId !== offer.id)
  const secondOffer = Object.values(bundleOffer.bundledOffers).find(x => x.id !== offer.id)

  const { shouldShowDiscountPercent, hotelDiscountPercent } = getBundleValueUp(bundleOffer, bestPricePackage)

  const linkParams = {
    to: offerUrl,
    ...(config.OPEN_NEW_TAB_OFFER_CLICK ? { target: '_blank', rel: 'noopener noreferrer' } : {}),
  }

  return (
    <ErrorBoundary fallback={null} reportErrors={false}>
      <ClickableLink {...linkParams}>
        <Container>
          <StyledGroup direction="vertical" gap={8} fullHeight fullWidth>
            <Group direction="vertical" gap={8}>
              <div>
                <StructureLabel variant="promotion">Bundle & Save</StructureLabel>
              </div>
              <BodyText variant="medium">
                Great savings available when you bundle this hotel with a {pluralizeToString('night', secondOfferPackage.duration)} stay at
                {' '}<BodyText as="span" variant="medium" weight="bold">{secondOffer?.property.name}</BodyText>
              </BodyText>
              <PriceRowPriceWithCaption
                size="S"
                caption={`${pluralizeToString('night', bestPricePackage.duration)} from`}
                price={totalPrice}
                saleUnit={saleUnit}
                discountPercentage={shouldShowDiscountPercent ? hotelDiscountPercent : undefined}
              />
            </Group>
            <Group direction="horizontal" horizontalAlign="end" fullWidth>
              <TextButton fit="mobile-full-width" kind="secondary">View bundle offer</TextButton>
            </Group>
          </StyledGroup>
        </Container>
      </ClickableLink>
    </ErrorBoundary>
  )
}

ViewBundleOfferContent.defaultProps = {
  filters: {},
}

export default ViewBundleOfferContent

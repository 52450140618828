import { get as getLocalStorage, set as setLocalStorage } from 'lib/storage/isomorphicLocalStorage'

export function get(key: string, version: number) {
  const item = getLocalStorage(key)
  if (item?.version !== version) { return null }
  return item.value
}

export function getWithTimestamp<T>(key: string, version: number): { value?: T, timestamp?: number } {
  const item = getLocalStorage(key)
  return (item?.version !== version) ? {} : item
}

export function set<T>(key: string, version: number, value: T) {
  setLocalStorage(key, { version, value, timestamp: Date.now() })
}

export { remove } from 'lib/storage/isomorphicLocalStorage'

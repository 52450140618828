import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWaterIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M2.72 7.65c.1956.0574.3836.138.56.24a4 4 0 0 0 4.1 0 2.6 2.6 0 0 1 2.56 0 4.15 4.15 0 0 0 4.12 0 2.6001 2.6001 0 0 1 2.56 0 4.2502 4.2502 0 0 0 2.08.56 3.88 3.88 0 0 0 2-.56 2.562 2.562 0 0 1 .56-.24 1 1 0 1 0-.56-1.92 4.4499 4.4499 0 0 0-1 .45 2.0797 2.0797 0 0 1-2.1 0 4.6398 4.6398 0 0 0-4.54 0 2.1097 2.1097 0 0 1-2.12 0 4.6397 4.6397 0 0 0-4.54 0 2.08 2.08 0 0 1-2.1 0 4.4497 4.4497 0 0 0-1-.45 1 1 0 1 0-.56 1.92h-.02Zm18 8.08a4.4481 4.4481 0 0 0-1 .45 2.0796 2.0796 0 0 1-2.1 0 4.6401 4.6401 0 0 0-4.54 0 2.1103 2.1103 0 0 1-2.12 0 4.64 4.64 0 0 0-4.54 0 2.08 2.08 0 0 1-2.1 0 4.448 4.448 0 0 0-1-.45.999.999 0 0 0-.7608.0832.999.999 0 0 0-.4792.5968.9997.9997 0 0 0 .68 1.24c.1956.0574.3836.138.56.24a4.0003 4.0003 0 0 0 4.1 0 2.5994 2.5994 0 0 1 2.56 0 4.1499 4.1499 0 0 0 4.12 0 2.5995 2.5995 0 0 1 2.56 0 4.2507 4.2507 0 0 0 2.08.56 3.8808 3.8808 0 0 0 2-.56 2.5614 2.5614 0 0 1 .56-.24 1 1 0 0 0-.56-1.92h-.02Zm0-5a4.4481 4.4481 0 0 0-1 .45 2.0796 2.0796 0 0 1-2.1 0 4.6401 4.6401 0 0 0-4.54 0 2.1103 2.1103 0 0 1-2.12 0 4.64 4.64 0 0 0-4.54 0 2.08 2.08 0 0 1-2.1 0 4.448 4.448 0 0 0-1-.45.9996.9996 0 0 0-1.32.68.9997.9997 0 0 0 .68 1.24c.1956.0574.3836.138.56.24a4.0003 4.0003 0 0 0 4.1 0 2.5994 2.5994 0 0 1 2.56 0 4.1499 4.1499 0 0 0 4.12 0 2.5995 2.5995 0 0 1 2.56 0 4.2507 4.2507 0 0 0 2.08.56 3.8808 3.8808 0 0 0 2-.56 2.5614 2.5614 0 0 1 .56-.24 1 1 0 0 0-.56-1.92h.06Z"/>
  </SvgIcon>
}

export default LineWaterIcon

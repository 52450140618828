import React, { useCallback, useContext, useMemo, useRef } from 'react'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import config from 'constants/config'
import FlightSearchAirportModal from './FlightAirportSelect/FlightSearchAirportModal'
import ModalContext from 'contexts/ModalContext'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import FlightSearchMenuMobile from './FlightSearchMenu/FlightSearchMenuMobile'
import FlightsDatePicker from './FlightsDatePicker/FlightsDatePicker'
import { addDays } from '@luxuryescapes/lib-refunds/build/lib/date'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import FlightSearchTravellerSelectContent from './FlightTravellerSelect/FlightSearchTravellerSelectContent'
import { getAirportDisplayText } from 'lib/flights/flightUtils'
import useFlightDeal from 'hooks/Flights/useFlightDeal'
import FlightDealDuration from 'components/Flights/FlightMerchandising/FlightDealDuration/FlightDealDuration'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { FlightsFareTypes } from 'constants/flight'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import LinePlusIcon from 'components/Luxkit/Icons/line/LinePlusIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import {
  FlightSearchWidgetActions,
  FlightSearchWidgetFlightItem,
  createEmptyFlightSearchWidgetFlightItem,
} from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import LineArrowsSwapVerticalIcon from 'components/Luxkit/Icons/line/LineArrowsSwapVerticalIcon'
import IconButton from 'components/Luxkit/Button/IconButton'
import { rem } from 'polished'
import FlightSearchMaxFlightsModal from './FlightSearchMaxFlightsModal'

const ButtonsContainer = styled(Group)`
  & > button:not(:last-child) {
    border-bottom: 0;
  }
`

const AirportsContainer = styled(Group)`
  position: relative;

  & > button:not(.switch){
    border-bottom: 0;
  }
`

const ExchangeButtonContainer = styled.div`
  position: absolute;
  right: ${rem(16)};
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border-radius: 50%;
`

interface Props {
  /**
   * Sets the airports to a 'read only' mode
   * Used when defaulting airport selections such as on flight deal pages
   */
  readOnlyAirports?: boolean;
}

function FlightSearchWidgetMobileMultiCityForm(props: Props) {
  const { readOnlyAirports } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)
  const isBusinessTravellerSelectEnabled = config.businessTraveller.currentAccountMode === 'business'
  const departingRefs = useRef<Array<HTMLButtonElement | null>>([])

  const { fareType, flightDealId, flights } = state
  const [flightDeal] = useFlightDeal(flightDealId)
  const showModal = useContext(ModalContext)

  const onTravellerSelect = useCallback(async() => {
    await showModal<any>(
      <FlightSearchMenuMobile title="Travellers" showTravellers>
        <FlightSearchTravellerSelectContent />
      </FlightSearchMenuMobile>,
    )
  }, [showModal])

  const earliestBookingDate = useMemo(() => addDays(new Date(), 1), [])
  const finalBookingDate = useMemo(() => addDays(new Date(), 330), [])

  const onDateSelect = useCallback(
    async(flightId: string) => {
      const dates = await showModal<any>(
        <FlightSearchMenuMobile title="When do you want to go?" showDates>
          <Group
            direction="vertical"
            verticalAlign="space-between"
            gap={12}
            fullHeight
          >
            <FlightsDatePicker
              minDate={earliestBookingDate}
              maxDate={finalBookingDate}
              type="single"
              id={flightId}
            />
            <VerticalSpacer gap={12}>
              {flightDeal && <FlightDealDuration flightDeal={flightDeal} />}
              <BodyText variant="small" colour="neutral-two">
                Based on recent return prices (per adult) found
              </BodyText>
            </VerticalSpacer>
          </Group>
        </FlightSearchMenuMobile>,
      )

      const rowIndex = flights.findIndex(f => f.id === flightId)
      const pendingRows = rowIndex < flights.length - 1

      if (pendingRows) {
        departingRefs.current[rowIndex + 1]?.click()
      }

      // disable traveller selection for LEBT
      if (!isBusinessTravellerSelectEnabled && dates && !pendingRows) {
        onTravellerSelect()
      }
    },
    [showModal, earliestBookingDate, finalBookingDate, flightDeal, flights, isBusinessTravellerSelectEnabled, onTravellerSelect],
  )

  const onArrivalSelect = useCallback(
    async(flightId: string) => {
      const airport = await showModal<App.AirportLocation | undefined>(
        <FlightSearchAirportModal direction="arrival" id={flightId} />,
      )

      if (airport) {
        onDateSelect(flightId)
      }
    },
    [onDateSelect, showModal],
  )

  const handleRemoveFlight = useCallback(
    (flightId: string) => {
      dispatch({
        type: FlightSearchWidgetActions.REMOVE_FLIGHT,
        payload: flightId,
      })
    },
    [dispatch],
  )

  const handleAddFlight = useCallback(() => {
    if (flights.length === config.FLIGHTS_MULTI_CITY_MAX_FLIGHTS) {
      showModal<boolean>(<FlightSearchMaxFlightsModal />)
      return
    }

    const item: FlightSearchWidgetFlightItem = createEmptyFlightSearchWidgetFlightItem()

    dispatch({
      type: FlightSearchWidgetActions.ADD_FLIGHT,
      payload: item,
    })
  }, [dispatch, flights.length, showModal])

  const onDepartureSelect = useCallback(
    async(flightId: string) => {
      if (readOnlyAirports) return

      const airport = await showModal<App.AirportLocation | undefined>(
        <FlightSearchAirportModal direction="departure" id={flightId} />,
      )

      if (airport) {
        onArrivalSelect(flightId)
      }
    },
    [onArrivalSelect, readOnlyAirports, showModal],
  )

  const onSwapAirports = useCallback((flightId: string) => {
    const currentFlight = flights.find(flight => flight.id === flightId)!
    const departure = currentFlight.departureAirport
    dispatch({ type: FlightSearchWidgetActions.SET_DEPARTURE_AIRPORT, id: flightId, airport: currentFlight.arrivalAirport })
    dispatch({ type: FlightSearchWidgetActions.SET_ARRIVAL_AIRPORT, id: flightId, airport: departure })
  }, [flights, dispatch])

  const showRemoveButton = flights.length > 1

  return (
    <VerticalSpacer gap={16}>
      {flights.map((flight, index) => (
        <VerticalSpacer key={flight.id} gap={8}>
          <Group
            direction="horizontal"
            gap="0 4"
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Heading variant="heading6">Flight {index + 1}</Heading>

            {showRemoveButton && (
              <TextButton
                kind="tertiary"
                onClick={() => handleRemoveFlight(flight.id)}
              >
                Remove
              </TextButton>
            )}
          </Group>

          <ButtonsContainer direction="vertical">
            <AirportsContainer direction="vertical">
              <SearchFormField
                value={getAirportDisplayText(flight.departureAirport)}
                label="From"
                required
                placeholder="Search by city or airport"
                onClick={() => onDepartureSelect(flight.id)}
                requiredErrorMessage="Please enter a departure"
                hasError={flight.showDepartureError}
                ref={node => departingRefs.current[index] = node}
              />

              <SearchFormField
                value={getAirportDisplayText(flight.arrivalAirport)}
                label="To"
                required
                placeholder="Search by city or airport"
                onClick={() => onArrivalSelect(flight.id)}
                requiredErrorMessage="Please enter a destination"
                hasError={flight.showDestinationError}
              />

              <ExchangeButtonContainer>
                <IconButton variant="default" kind="tertiary" size="medium" shape="circle" className="switch" onClick={() => onSwapAirports(flight.id)} disabled={readOnlyAirports}>
                  <LineArrowsSwapVerticalIcon size="M" />
                </IconButton>
              </ExchangeButtonContainer>
            </AirportsContainer>

            <SearchFormFieldGroup>
              <SearchFormField
                label="Departing"
                placeholder="Select date"
                required
                requiredErrorMessage="Please select travel dates"
                value={flight.checkinDate?.format(DMY_CASUAL_FORMAT)}
                onClick={() => onDateSelect(flight.id)}
                hasError={flight.showTravelDatesError}
              />

              {fareType.value === FlightsFareTypes.RETURN && (
                <SearchFormField
                  label="Returning"
                  placeholder="Select date"
                  required
                  requiredErrorMessage="Please select travel dates"
                  value={flight.checkoutDate?.format(DMY_CASUAL_FORMAT)}
                  onClick={() => onDateSelect(flight.id)}
                  hasError={flight.showTravelDatesError}
                />
              )}
            </SearchFormFieldGroup>
          </ButtonsContainer>
        </VerticalSpacer>
      ))}

      <TextButton
        kind="secondary"
        variant="dark"
        startIcon={<LinePlusIcon />}
        size="medium"
        onClick={handleAddFlight}
      >
        Add flight
      </TextButton>
    </VerticalSpacer>
  )
}

export default FlightSearchWidgetMobileMultiCityForm

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFireIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M9.9202 3.3803c.6218 1.8052.1274 3.8507-1.4509 5.2546l-1.8778 1.6703c-1.2419 1.1023-1.9804 2.5979-1.9804 4.2274 0 .7317.139 1.4371.3979 2.0969.5104-1.7623 1.4513-3.0294 2.462-3.8474a1.1897 1.1897 0 0 1 1.3404-.1112 1.174 1.174 0 0 1 .5805 1.1869c-.0916.6496-.276 1.9731-.0423 3.5249.2104 1.3979.9775 2.3198 1.7101 2.9064.6729.5387 1.6402.7386 2.6094.5427 3.3575-.6787 5.7198-3.3078 5.7198-6.2992 0-.9637-.2585-1.8797-.7266-2.6959-.6248 1.3679-1.7802 2.4614-3.1916 3.1006-1.0583.4793-2.1494-.4845-1.8665-1.5567.3984-1.5098.6119-3.079.6119-4.691 0-2.3823-1.7392-4.5211-4.296-5.3094ZM7.3085 1.7682c.2195-.4537.7195-.8218 1.356-.7618 4.2092.3968 7.6627 3.5997 7.6627 7.6833 0 1.12-.0925 2.2207-.2694 3.2974.5113-.554.8468-1.2209.9573-1.9318.0862-.5549.4824-.939.9099-1.0933.4412-.1592 1.0424-.1119 1.4706.3483 1.304 1.401 2.1044 3.2214 2.1044 5.2223 0 4.1575-3.247 7.5109-7.4096 8.3523-1.4797.2991-3.1151.0285-4.3561-.965-1.0164-.8137-2.1626-2.1681-2.4727-4.2275a12.11 12.11 0 0 1-.0987-.8725c-.2038.5733-.3515 1.2384-.4114 2.0046-.0447.5732-.4268 1.011-.898 1.1842-.4883.1795-1.1126.082-1.5162-.4135C3.1893 18.1862 2.5 16.4357 2.5 14.5326c0-2.2863 1.042-4.3314 2.6838-5.7888l1.8765-1.6692c1.2065-1.0731 1.3185-2.6834.4035-3.8542-.3517-.4499-.3655-1.0179-.1553-1.4522Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineFireIcon

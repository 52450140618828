import React, { useCallback, useMemo, useRef, useState, useContext } from 'react'
import ModalContext from './ModalContext'
import noop from 'lib/function/noop'

export interface ModalElementContextValue<R> {
  open?: boolean;
  resolve: NonNullable<R> extends never ? (result?: undefined) => void : (result: R) => void;
  afterClose: () => void;
}

const defaultValue: ModalElementContextValue<undefined> = {
  open: false,
  resolve: noop,
  afterClose: noop,
}

export const ModalElementContext = React.createContext<ModalElementContextValue<undefined>>(defaultValue)

export function useIsInModal() {
  return useContext(ModalElementContext) !== defaultValue
}

function ModalProvider(props: React.PropsWithChildren) {
  const [modal, setModal] = useState<{
    resolve:(val?: any) => any,
    element: React.ReactNode
  }>()
  const afterCloseRef = useRef<(() => any) | undefined>(undefined)
  const afterClosePromiseRef = useRef<Promise<void> | undefined>()
  const [open, setOpen] = useState<boolean>()

  const showModal = useCallback((nextModal: React.ReactNode) => {
    const modalResolve = (resolve: (value?: any) => void) => {
      setModal({
        element: nextModal,
        resolve,
      })
      setOpen(true)
    }

    const afterResolve = (val: any) => {
      // start closing the modal, we still want the element rendered so it doesn't jank around
      setOpen(false)
      // we need to wait till it's closed before we can fully resolve
      // as that's when the modal is truly "done"
      const onAfterClose = new Promise<any>(resolve => {
        afterCloseRef.current = () => resolve(val)
      })
      afterClosePromiseRef.current = onAfterClose
      // chain the promise until after the close is done
      return onAfterClose
    }

    if (afterClosePromiseRef.current) {
      // an existing modal is already open and we're trying to show a new one...
      // wait for the old one to close then show the next one
      return afterClosePromiseRef.current.then(() => new Promise(modalResolve)).then(afterResolve)
    }
    return new Promise(modalResolve).then(afterResolve)
  }, [])

  const elementContextValue = useMemo(() => ({
    open,
    resolve: modal?.resolve ?? noop,
    afterClose: () => {
      // remove the modal element from being rendered, we're done with it
      setModal(undefined)
      // after close is done, finish the showModal call and resolve it back to the caller
      afterCloseRef.current?.()
      afterCloseRef.current = undefined
      afterClosePromiseRef.current = undefined
    },
  }), [modal, open])

  return <>
    <ModalElementContext.Provider value={elementContextValue}>
      {modal?.element}
    </ModalElementContext.Provider>
    <ModalContext.Provider value={showModal}>
      {props.children}
    </ModalContext.Provider>
  </>
}

export default ModalProvider

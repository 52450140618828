import React, { useCallback } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { hasPassedWalledGarden, hasPassedHidePricing } from 'selectors/accountSelectors'
import { accountAccessShowLogin } from 'actions/UiActions'
import TextButton from 'components/Luxkit/Button/TextButton'

const mapState = (state: App.State) => ({
  passedHidePricing: hasPassedHidePricing(state),
  passedWalledGarden: hasPassedWalledGarden(state),
})

const mapDispatch = {
  accountAccessShowLogin,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  enforced: boolean;
  hidePricing?: boolean;
  buttonText?: string;
  wall?: React.ReactNode;
  children: React.ReactNode;
}

function WalledContent({
  enforced,
  hidePricing,
  buttonText,
  wall,
  passedHidePricing,
  passedWalledGarden,
  accountAccessShowLogin,
  children,
}: Props) {
  const handleOnClick = useCallback(() => {
    accountAccessShowLogin()
  }, [accountAccessShowLogin])
  if ((!enforced && !hidePricing) || passedHidePricing) {
    return <>{children}</>
  }

  if (hidePricing) {
    return <>{wall}</>
  }

  if (!enforced || passedWalledGarden) {
    return <>{children}</>
  }

  if (wall) {
    return <>{wall}</>
  }

  if (buttonText) {
    return (
      <TextButton kind="primary" onClick={handleOnClick}>
        {buttonText}
      </TextButton>
    )
  }

  return null
}

export default connector(WalledContent)

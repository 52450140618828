import React, { useCallback, useContext, useEffect, useRef } from 'react'
import SearchFloatingPanel, { SearchFloatingPanelProps } from 'components/SearchV2/Components/Mobile/Panels/SearchFloatingPanel'
import OccupancyPicker from 'components/Common/OccupancyPicker/OccupancyPicker'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { isRoomsInvalid } from 'lib/search/searchUtils'
import useDidChange from 'hooks/useDidChange'
import config from 'constants/config'
import noop from 'lib/function/noop'

interface Props extends
  Omit<
    SearchFloatingPanelProps,
    'title' | 'isConfirmButtonHidden' | 'children'
  >
{
  saleUnit?: string;
}

function TravellersFloatingPanel(props: Props) {
  const { onConfirm = noop, saleUnit, ...rest } = props

  const formRef = useRef<HTMLFormElement>(null)

  const { occupancies, disableMultiRoom, disableSaleUnit } = useContext(GlobalSearchStateContext)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  // Scroll to bottom every time a occupancy is added so the user don't
  // have to do it manually
  const didOccupanciesChanged = useDidChange(occupancies)
  const previousOccupanciesLength = useRef(occupancies.length)
  useEffect(() => {
    if (didOccupanciesChanged && occupancies.length > previousOccupanciesLength.current && formRef.current) {
      formRef.current.lastElementChild?.scrollIntoView({ block: 'end' })
    }
    previousOccupanciesLength.current = occupancies.length
  }, [didOccupanciesChanged, occupancies, formRef])

  const setOccupancies = useCallback((occupancies: Array<App.Occupants>) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies })
  }, [globalSearchDispatch])

  const onOccupanciesChange = useCallback((nextRooms: Array<App.Occupants>) => {
    setOccupancies(nextRooms)

    if (!isRoomsInvalid(nextRooms)) {
      globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ROOM_ERRORS, shown: false })
    }
  }, [globalSearchDispatch, setOccupancies])

  const onApply = useCallback(() => {
    formRef.current?.requestSubmit()
  }, [formRef])

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onConfirm()
  }, [onConfirm])

  return (
    <SearchFloatingPanel
      {...rest}
      title="Who is going?"
      onConfirm={onApply}
    >
      <form ref={formRef} onSubmit={onSubmit}>
        <OccupancyPicker
          occupancies={occupancies}
          onChange={onOccupanciesChange}
          saleUnit={saleUnit}
          enableMultiRoom={disableMultiRoom ? false : config.accommodation.allowsMultiRoom}
          disableSaleUnit={disableSaleUnit}
        />
      </form>
    </SearchFloatingPanel>
  )
}

export default TravellersFloatingPanel

import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS, CLEAR_LUX_PLUS_MEMBER_SUBSCRIPTION } from 'actions/actionConstants'
import { FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY, FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialLuxPlusState: App.LuxPlusState = {
  products: {
    subscription: {
      offers: undefined,
      fetching: false,
      error: undefined,
    },
    freePreview: {
      offer: undefined,
      fetching: false,
      error: undefined,
    },
  },
}

const apiRequests = reducerSwitch<App.LuxPlusState>({
  [FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION]: (state) => ({
    products: {
      ...state.products,
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state) => ({
    products: {
      ...state.products,
      freePreview: {
        fetching: true,
        error: undefined,
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.LuxPlusState>({
  [FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION]: (state, action) => ({
    products: {
      ...state.products,
      subscription: {
        offers: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state, action) => ({
    products: {
      ...state.products,
      freePreview: {
        offer: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.LuxPlusState>({
  [FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION]: (state, action) => ({
    products: {
      ...state.products,
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state, action) => ({
    products: {
      ...state.products,
      freePreview: {
        fetching: false,
        error: action.error,
      },
    },
  }),
})

export default createReducer<App.LuxPlusState>(initialLuxPlusState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CLEAR_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, _action) => ({
    products: {
      ...state.products,
      freePreview: {
        ...initialLuxPlusState.products.freePreview,
      },
    },
  }),
})

import { useLoadScript } from '@react-google-maps/api'
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'

import config from 'constants/config'

type Libraries = Parameters<typeof useLoadScript>[0]['libraries']
export const GOOGLE_PLACE_LIBRARY_OPTIONS: Libraries = ['places', 'geometry', 'marker']

interface GoogleMapsContextProps {
  placesService?: google.maps.places.PlacesService;
  autocompleteService?: google.maps.places.AutocompleteService;
  isLoaded: boolean;
}

const GoogleMapsContext = createContext<GoogleMapsContextProps>({
  isLoaded: false,
})

export const useGoogleMapsContext = () => {
  return useContext(GoogleMapsContext)
}

interface EnsureGoogleMapsLoadedProps {
  loadingElement?: React.ReactNode;
  children: React.ReactNode;
}
export function EnsureGoogleMapsLoaded({ loadingElement = null, children }: EnsureGoogleMapsLoadedProps) {
  const { isLoaded } = useGoogleMapsContext()
  return <>{isLoaded ? children : loadingElement}</>
}

export function GoogleMapsProvider(props: PropsWithChildren) {
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | undefined>(undefined)
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | undefined>(undefined)

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    libraries: GOOGLE_PLACE_LIBRARY_OPTIONS,
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
  })

  useEffect(() => {
    if (isLoaded) {
      setAutocompleteService(new google.maps.places.AutocompleteService())

      // Empty div is required to initialize the places service
      setPlacesService(
        new google.maps.places.PlacesService(document.createElement('div')),
      )
    }
  }, [isLoaded])

  const value = {
    placesService,
    autocompleteService,
    isLoaded,
  }
  return (
    <GoogleMapsContext.Provider value={value}>
      {props.children}
    </GoogleMapsContext.Provider>
  )
}

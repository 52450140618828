import React, { useContext, useMemo } from 'react'

import { pluralizeToString } from 'lib/string/pluralize'
import { sum } from 'lib/array/arrayUtils'
import KeyValueSection from '../SearchInputTriggerKeyValue'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import Caption from 'components/Luxkit/Typography/Caption'

function Content() {
  const {
    occupancies,
    disableSaleUnit,
  } = useContext(GlobalSearchStateContext)
  const containsChildren = occupancies.some(room => room.childrenAge?.length)
  const guestCount = useMemo(() => sum(occupancies, room => room.adults + (room.childrenAge?.length ?? 0)), [occupancies])

  return (
    <KeyValueSection>
      <Caption colour="neutral-one" variant="large" weight="bold">Guests</Caption>
      <BodyText variant="medium" colour="neutral-two">
        {occupancies.length ?
          `${pluralizeToString(containsChildren ? 'guest' : 'adult', guestCount)} ${disableSaleUnit ? '' : `(${pluralizeToString('room', occupancies.length)})`}` :
          'Select adults/children'
        }
      </BodyText>
    </KeyValueSection>
  )
}

export default Content

import { checkoutShouldShowBookingProtection } from 'checkout/selectors/checkoutSectionsSelectors'
import { getQuoteFromBookingProtectionQuotes } from 'checkout/selectors/request/bookingProtection'
import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { isEnabledLuxPlusDiscountedBookingProtection } from 'luxPlus/selectors/featureToggle'
import { getBookingProtectionItemsView } from 'checkout/selectors/view/bookingProtection'

export const getAvailableBookingProtectionBenefits = createSelector(
  isEnabledLuxPlusDiscountedBookingProtection,
  checkoutShouldShowBookingProtection,
  getBookingProtectionItemsView,
  getQuoteFromBookingProtectionQuotes,
  (state: App.State) => state.bookingProtection.fetchingQuotes,
  (state: App.State) => state.bookingProtection.triedFetchingQuotes,
  (
    isEnabledLuxPlusDiscountedBookingProtection,
    shouldShowBookingProtection,
    bookingProtectionItemsView,
    quote,
    fetching,
    triedFetchingQuotes,
  ): App.Checkout.LuxPlusBookingProtectionBenefits => {
    const luxPlusPrice = quote?.luxPlusPrice ?? 0
    const price = quote?.price ?? 0

    const totalPrice = sum(bookingProtectionItemsView.data, item => item.price)
    const totalMemberPrice = sum(bookingProtectionItemsView.data, item => item.memberPrice)
    const totalMemberPriceSavings = totalPrice - totalMemberPrice

    return {
      isAvailable: !!(isEnabledLuxPlusDiscountedBookingProtection && shouldShowBookingProtection),
      fetching: !!(triedFetchingQuotes && Object.values(fetching).some(fetching => fetching)),
      luxPlusDiscountPercentage: quote?.luxPlusDiscountPercentage ?? 0,
      luxPlusDiscountValueDisplayThreshold: quote?.luxPlusDiscountValueDisplayThreshold ?? 0,
      totalMemberMaxSavings: luxPlusPrice > 0 ? price - luxPlusPrice : 0,
      totalMemberPriceSavings,
      totalPrice,
      totalMemberPrice,
    }
  },
)

import { createGlobalStyle } from 'styled-components'

import Reset from './base/reset'
import Typography from './base/typography'
import Print from './vendor/bootstrap/Print'
import ReactModal from './reactModal'
import Appsflyer from './vendor/appsflyer/banner'
import Genesys from './vendor/genesys/genesys'
import TripMapCSS from 'tripPlanner/components/TripMaps/styles'
import scrollLockGlobalStyles from 'components/Common/ScrollLock/scrollLockGlobalStyles'
import ThemeStyles from './themes/ThemeStyles'

const GlobalStyles = createGlobalStyle`
  ${ThemeStyles}
  ${Reset}
  ${Typography}
  ${Print}
  ${ReactModal}
  ${Appsflyer}
  ${Genesys}
  ${TripMapCSS}
  ${scrollLockGlobalStyles}

  .no-print {
    @media print {
      display: none;
    }
  }
`

export default GlobalStyles

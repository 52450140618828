import React from 'react'
import noop from 'lib/function/noop'
import GlobalSearchQuickFilterLoadingButtons from '../GlobalSearch/QuickFilters/GlobalSearchQuickFilterLoadingButtons'
import PopularDestinationButton from './PopularDestinationButton'

export interface Option {
  value: string;
  text: string;
  url: string;
}

interface Props {
  onClickHandler?: (option: Option) => void;
  onContextMenuHandler?: (option: Option) => void;
  onImpressionHandler?: (option: Option) => void;
  options: Array<Option>;
  fetching: boolean;
}

function PopularButtonList({
  onClickHandler,
  onContextMenuHandler,
  onImpressionHandler,
  options,
  fetching,
}: Props) {
  if (!fetching && !options.length) {
    return null
  }

  return (
    <>
      {fetching && <GlobalSearchQuickFilterLoadingButtons />}
      {!fetching && options.map(option => {
        return <PopularDestinationButton
          key={option.value}
          option={option}
          onClickHandler={onClickHandler}
          onContextMenuHandler={onContextMenuHandler}
          onImpressionHandler={onImpressionHandler}
        />
      })}
    </>
  )
}

PopularButtonList.defaultTypes = {
  onContextMenuHandler: noop,
}

export default PopularButtonList

import { formatRoomType, getRoomTypeCapacity } from 'lib/tours/tourUtils'
import { TOUR_MINIMUM_VALUED_AT_PERCENTAGE_TO_SHOW } from 'constants/tours'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { maybeWithValue } from 'lib/offer/pricing'

export interface TourV2PurchasableOptionDetails {
  hasDiscount: boolean
  pricePerPerson: number
  memberPricePerPerson: number
  fullPricePerPerson: number
  roomTypeName?: string
  totalPriceForRoomType: number
  valuedAt?: {
    price: number
    savingPercentage: number
  }
  spotsLeft: number
  memberPrice: number
  memberSavings: number;
  pricing: App.Pricing
  memberPricing: App.Pricing | undefined
  duration?: number;
}

function extractTourV2PurchasableOptionDetails(purchasableOption: Tours.TourV2OfferPurchasableOption): TourV2PurchasableOptionDetails {
  const pricing: App.Pricing = maybeWithValue({
    price: purchasableOption.price,
    fullPrice: purchasableOption.fullPrice,
    fees: [],
    saleUnit: 'person',
  }, purchasableOption.valuedAt)
  const memberPricing: App.Pricing | undefined = purchasableOption.memberPrice ?
    maybeWithValue({
      price: purchasableOption.memberPrice,
      fullPrice: purchasableOption.fullPrice,
      fees: [],
      saleUnit: 'person',
    }, purchasableOption.valuedAt) :
    undefined

  const extractedDetails: TourV2PurchasableOptionDetails = {
    hasDiscount: Math.round(purchasableOption.fullPrice) - Math.round(purchasableOption.price) > 0,
    pricePerPerson: purchasableOption.price,
    memberPricePerPerson: purchasableOption.memberPrice,
    fullPricePerPerson: purchasableOption.fullPrice,
    roomTypeName: purchasableOption.roomType ? formatRoomType(purchasableOption.roomType) : undefined,
    totalPriceForRoomType: purchasableOption.roomType ? (getRoomTypeCapacity(purchasableOption.roomType) * purchasableOption.price) : 0,
    spotsLeft: purchasableOption.inventoryLeft,
    memberPrice: purchasableOption.memberPrice ? parseFloat(purchasableOption.memberPrice.toFixed(2)) : 0,
    pricing,
    memberPricing,
    memberSavings: 0,
  }

  if (purchasableOption.valuedAt) {
    const savingPercentage = getValueOffPercent(purchasableOption.valuedAt, purchasableOption.price)
    const isSavingValuePercentageValid = savingPercentage > TOUR_MINIMUM_VALUED_AT_PERCENTAGE_TO_SHOW
    if (isSavingValuePercentageValid) {
      extractedDetails.valuedAt = { price: purchasableOption.valuedAt, savingPercentage }
    }
  }

  if (extractedDetails.memberPrice) {
    extractedDetails.memberSavings = extractedDetails.pricePerPerson - extractedDetails.memberPrice
  }

  return extractedDetails
}

export default extractTourV2PurchasableOptionDetails

import cn from 'clsx'
import React from 'react'
import styled from 'styled-components'
import Clickable from '../Clickable'
import LineAngleLeftBIcon from 'components/Luxkit/Icons/line/LineAngleLeftBIcon'
import LineAngleRightBIcon from 'components/Luxkit/Icons/line/LineAngleRightBIcon'
import Heading from '../../Luxkit/Typography/Heading'
import { getMonthShortName } from 'lib/datetime/dateUtils'
import Group from 'components/utils/Group'

const MonthChangeElement = styled(Clickable)`
  transition: color 0.2s;

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
    visibility: none;
  }
`

interface Props {
  onMonthChange: (direction: 'forwards' | 'backwards') => void;
  disableBackwards?: boolean;
  disableForwards?: boolean;
  hideBackwards?: boolean;
  hideForwards?: boolean;
  currentDate: Date;
}

function DatePickerHeader(props: Props) {
  const {
    onMonthChange,
    disableBackwards,
    disableForwards,
    hideBackwards,
    hideForwards,
    currentDate,
  } = props

  const onNextMonth = () => {
    onMonthChange('forwards')
  }

  const onPrevMonth = () => {
    onMonthChange('backwards')
  }

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
      <MonthChangeElement
        disabled={disableBackwards}
        onClick={onPrevMonth}
        className={cn({ hidden: hideBackwards })}
      >
        <LineAngleLeftBIcon size="S" />
      </MonthChangeElement>
      <Heading as="span" variant="heading6">{getMonthShortName(currentDate)} {currentDate.getFullYear()}</Heading>
      <MonthChangeElement
        onClick={onNextMonth}
        disabled={disableForwards}
        data-testid="calendar-header-next-month"
        className={cn({ hidden: hideForwards })}
      >
        <LineAngleRightBIcon size="S" />
      </MonthChangeElement>
    </Group>
  )
}

export default React.memo(DatePickerHeader)

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWatersportsIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19.9654 15.9156c-.7115-.7214-1.7436-.982-2.6956-.7515 4.1786-5.0604 5.1706-11.5437 5.2107-11.8343.0802-.511-.0902-1.002-.4509-1.3628-.3608-.3607-.8518-.521-1.3628-.4509-.2906.0401-6.7939 1.0622-11.8343 5.2207.2305-.952-.04-1.984-.7515-2.6955L5.8364 1.7967c-.3808-.3808-.982-.3808-1.3628 0L1.7781 4.4822c-.3708.3708-.3708.982 0 1.3628l2.2446 2.2446c.6814.6814 1.6834.992 2.6454.7716-3.6575 4.2587-4.7999 9.6097-5.1305 11.7841-.0802.5111.0902 1.0021.4509 1.3628.3607.3608.8518.5311 1.3628.4509 2.1845-.3306 7.5354-1.473 11.7842-5.1305-.2105.942.0601 1.944.7615 2.6455l2.2446 2.2446c.1804.1803.4209.2805.6814.2805s.501-.1002.6814-.2805l2.6955-2.6956c.3708-.3707.3708-.982 0-1.3628l-2.2446-2.2446h.0101ZM6.7282 6.7368c-.1803.1804-.4209.2806-.6714.2806s-.491-.1002-.6713-.2806L3.8223 5.1636 5.155 3.8309 6.7182 5.394c.3708.3707.3708.972 0 1.3427h.01Zm4.9502 3.5874c.481-.2806.982-.431 1.4129-.431.2304 0 .5511.0502.7715.2706.4109.4109.3307 1.2927-.1703 2.1745l-2.0041-2.0041-.01-.01Zm.7214 3.437c-.4509.3407-.9319.5612-1.3828.6514-.3006.0601-.8618.1102-1.1924-.2205-.3307-.3307-.2806-.8918-.2205-1.1924.0902-.461.3106-.932.6513-1.3829l2.1444 2.1444Zm-4.6796-1.1423c-.2304 1.1724.0301 2.2145.7416 2.926.7114.7114 1.7736.982 2.926.7415.8116-.1603 1.6233-.5612 2.3748-1.1724l.461.4609c-3.6675 3.3369-8.4173 4.5093-10.702 4.9101.3909-2.2947 1.5633-7.0344 4.9101-10.702l.461.461c-.6013.7516-1.0021 1.5632-1.1724 2.3749Zm7.4954-3.8279c-1.1624-1.1623-3.1665-1.1022-4.9301.1303l-.481-.481c3.5172-3.0062 8.277-4.4992 10.712-4.9501-.4509 2.425-1.934 7.1947-4.9502 10.7119l-.481-.481c1.2326-1.7736 1.2927-3.7677.1303-4.93Zm3.397 8.4774 1.5632 1.5632-1.3327 1.3327-1.5733-1.5632c-.3707-.3707-.3707-.972 0-1.3327.1804-.1804.4209-.2806.6714-.2806s.491.1002.6714.2806Z"/>
  </SvgIcon>
}

export default LineWatersportsIcon

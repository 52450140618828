import { sendResetPasswordEmail } from 'api/auth'
import { resendConfirmationForTransaction } from 'api/order'
import { ChatActions, createGenesysConversation } from 'api/supportAssistant'

import { get as getLocalStorage } from 'lib/storage/isomorphicLocalStorage'

const resetPasswordAction = (chatMessageMetaPayload?: App.ChatMessageMetaPayload) => {
  const email = chatMessageMetaPayload?.email || chatMessageMetaPayload?.inputPayload

  if (email) {
    sendResetPasswordEmail(email)
  }
}

const resendConfirmationEmailAction = (chatMessageMetaPayload?: App.ChatMessageMetaPayload) => {
  if (chatMessageMetaPayload?.id_orders) {
    resendConfirmationForTransaction(chatMessageMetaPayload.id_orders)
  }
}

const createGenesysConversationAction = (chatMessageMetaPayload: App.ChatMessageMetaPayload) => {
  const { chatId, customerId, firstName, lastName, email, phone, id_orders } = chatMessageMetaPayload

  // sessionCustomerId is the as the sessionId and used for anonymous genesys conversations
  const sessionCustomerId = getLocalStorage('supportAssistantCustomerId')

  createGenesysConversation({
    chatId,
    customerId: customerId ?? sessionCustomerId,
    customer: {
      firstName,
      lastName,
      email,
      phone,
      orderId: id_orders,
    },
  })
}

const manageBookingAction = (chatMessageMetaPayload: App.ChatMessageMetaPayload) => {
  const { id_orders } = chatMessageMetaPayload
  window.open(`/account/my-escapes/order/${id_orders}`, '_blank')
}

const gotoAccountDetailsAction = () => {
  window.open('/account/details', '_blank')
}

const gotoEmailChangeAction = () => {
  window.open('/account/details?isEmailChange=true', '_blank')
}

export const chatActions: Record<string, (chatMessageMetaPayload?: App.ChatMessageMetaPayload) => void> = {
  [ChatActions.RESET_PASSWORD_SENT]: resetPasswordAction,
  [ChatActions.RESEND_CONFIRMATION_EMAIL]: resendConfirmationEmailAction,
  [ChatActions.REQUEST_AGENT]: createGenesysConversationAction,
  [ChatActions.REQUEST_AGENT_ANONYMOUS]: createGenesysConversationAction,
  [ChatActions.MANAGE_BOOKING]: manageBookingAction,
  [ChatActions.GOTO_ACCOUNT_DETAILS]: gotoAccountDetailsAction,
  [ChatActions.GOTO_EMAIL_CHANGE]: gotoEmailChangeAction,
}

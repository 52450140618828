export function isTargetEndpoint(url: string, method?: string): boolean {
  if (url.endsWith('orders') && checkMethod('POST', method)) return true
  if (url.includes('transaction') && checkMethod('GET', method)) return true
  if (url.includes('stripe/payment-intents') && checkMethod('POST', method)) return true
  if (url.includes('payment-intent/create') && checkMethod('POST', method)) return true

  return false
}

function checkMethod(expectedMethod: string, method?: string): boolean {
  if (!method) {
    return true
  }

  return expectedMethod === method
}

/**
 * "Front page" style tile. Not that it just used on the front page,
 * but also pages such as:
 * - /hotels
 * - /tours
 * - /last-minute
 * - /homes-and-villas.h1
 * - Landing pages
 */

import React, { forwardRef, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import classnames from 'clsx'

import MainOfferTile from '../MainOfferTile'
import BundleOfferTile from '../BundleOfferTile'
import TourV2OfferTile from '../TourV2OfferTile'

import offerPageURL from 'lib/offer/offerPageURL'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'

import { buildSearchParamsFromFilters, buildSuggestedDatesParamsKey } from 'lib/search/searchUtils'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import { TopLevelTileProps } from '../OfferTileTypes'
import { isBedbankOffer, isBundleOffer, isCruiseOffer, isHotel, isTourV1Offer, isTourV2Offer, isVillaOffer } from 'lib/offer/offerTypes'
import config from 'constants/config'
import { getImmersiveTripId } from 'tripPlanner/selectors'
import { connect } from 'react-redux'
import { getSuggestedDates } from 'selectors/offerSelectors'
import noop from 'lib/function/noop'
import { useAppSelector } from 'hooks/reduxHooks'
import FrontPageBedbankOfferTile from '../FrontPageBedbankOfferTile'
import CruiseFrontPageTile from 'components/Cruises/CruisesTile/FrontPageOfferTiles/CruiseFrontPageTile'
import useIsHomepage from 'home/hooks/isHomepage'
import CruiseTile from 'components/Cruises/CruiseTiles/CruiseTile'
import HomesAndVillasOfferTile from '../HomesAndVillasOfferTile'

const OfferLink = styled(ClickableLink)`
  position: relative;
  display: block;
`

function getTestId(offer: App.AnyOffer) {
  let flightsEnabled: boolean
  let onlinePurchaseSchedule: App.OfferSchedule

  if (offer.type !== OFFER_TYPE_BED_BANK) {
    const internalOffer = offer as App.Offer

    flightsEnabled = internalOffer.offerFlightsEnabled
    onlinePurchaseSchedule = internalOffer.onlinePurchaseSchedule
  }

  return classnames(
    `offer-${offer.type}`, {
      'offer-with-flights': flightsEnabled,
      [`Purchasable-${offer.type}`]: scheduleIsCurrent(onlinePurchaseSchedule),
    })
}

interface Props extends TopLevelTileProps {
  className?: string;
}

interface MappedStateProps {
  suggestedDates: App.OfferSuggestedDates
}

const FrontPageTile = forwardRef<HTMLAnchorElement, Props & MappedStateProps>((props, ref) => {
  const {
    offer,
    filters,
    productClick = noop,
    eagerLoadFirstImage,
    offerLinkIncludesFilters = true,
    suggestedDates,
    className,
  } = props

  const tripId = useAppSelector(getImmersiveTripId)
  const offerUrl = useMemo(() => {
    const searchParams = offerLinkIncludesFilters ? buildSearchParamsFromFilters(filters, suggestedDates) : new URLSearchParams()
    if (config.OPEN_NEW_TAB_OFFER_CLICK && tripId) {
      // Make sure immersive trip header persists when opening offer links in new tab
      searchParams.set('tripId', tripId)
    }
    return offerPageURL(offer, searchParams)
  }, [filters, offer, offerLinkIncludesFilters, tripId, suggestedDates])

  const isHomepage = useIsHomepage()

  const handleClick = useCallback(() => {
    productClick?.(offer)
  }, [offer, productClick])

  let tileType: 'main' | 'oldflash' | 'bundleOffer' | 'tourv2' | 'cruiseOld' | 'cruise' | 'frontPageBedbank' | 'homesAndVillas'

  if (isBedbankOffer(offer)) {
    tileType = 'frontPageBedbank'
  } else if (isVillaOffer(offer)) {
    tileType = 'homesAndVillas'
  } else if (isBundleOffer(offer)) {
    tileType = 'bundleOffer'
  } else if (isTourV2Offer(offer)) {
    tileType = 'tourv2'
  } else if (isCruiseOffer(offer)) {
    tileType = 'cruise'
  } else if (isHotel(offer) || isTourV1Offer(offer)) {
    tileType = 'main'
    if (offer.holidayTypes.includes('Cruises')) {
      tileType = 'cruise'
    }
  }
  else {
    tileType = 'main'
  }

  const linkParams = useMemo(() => {
    return {
      onClick: handleClick,
      to: offerUrl,
      'data-testid': getTestId(offer),
      ...(config.OPEN_NEW_TAB_OFFER_CLICK ? { target: '_blank' } : {}),
    }
  }, [offerUrl, offer, handleClick])

  return (<OfferLink {...linkParams} className={className} ref={ref}>
    {tileType === 'main' && <MainOfferTile
      offer={offer as App.Offer | App.OfferSummary}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
    />}
    {tileType === 'homesAndVillas' && <HomesAndVillasOfferTile
      offer={offer as App.VillaOffer | App.VillaOfferSummary}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
    />}
    {tileType === 'bundleOffer' && <BundleOfferTile
      offer={offer as App.BundleOffer}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
    />}
    {tileType === 'tourv2' && <TourV2OfferTile
      offer={offer as Tours.TourV2Offer}
    />}
    {tileType === 'cruise' && !isHomepage && <CruiseTile
        offer={offer as App.CruiseOffer | App.TourOffer}
        tileType="standalone"
      />}
    {tileType === 'cruise' && isHomepage && <CruiseFrontPageTile
      cruise={offer as App.CruiseOffer}
      eagerLoadFirstImage={eagerLoadFirstImage}
    />}
    {tileType === 'frontPageBedbank' && <FrontPageBedbankOfferTile offer={offer as App.BedbankOffer} />}
  </OfferLink>
  )
})

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps) => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)
  return {
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
  }
})(FrontPageTile)

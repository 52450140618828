import { getItineraryCountries } from 'checkout/lib/utils/cruises/itinerary'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { max, nonNullable, unique } from 'lib/array/arrayUtils'
import { isValidInsuranceCountry } from 'lib/insurance/insuranceCountries'
import getObjectKey from 'lib/object/getObjectKey'
import moment from 'moment'
import qs from 'qs'

const insuranceTypeMap: Record<App.InsuranceType, string> = {
  travel_protection: 'protection',
  insurance: 'insurance',
  cfmr: 'protection',

}

export function getInsuranceTypeFromOrder(insuranceItem: App.OrderInsuranceItem) {
  if (!insuranceItem.insuranceType) return 'insurance'
  return insuranceTypeMap[insuranceItem.insuranceType]
}

const cancellationProtectionPolicyIds = new Set<App.InsurancePolicyId>([
  'travel_flight_cancellation_cover',
  'comprehensive_travel_insurance',
])

export function orderHasCancellationProtection(order: App.Order): boolean {
  const insuranceItem = order.insuranceItems.find(item => item.status === 'completed')
  if (insuranceItem) {
    return insuranceItem.policyIds.some(id => cancellationProtectionPolicyIds.has(id))
  }
  return false
}

/**
 * This is for actual cancellation protection, as opposed to the above 'orderHasCancellationProtection'
 * which refers to insurance with cancellation protection as a subset
 **/
export function orderHasBookingProtection(order: App.Order): boolean {
  return !!order.bookingProtectionItems.find(item => item.status === 'completed')
}

/**
 * Finds the active refund protection item on the order given if avaialble
 * Note: Refund protect is a specific 'booking protection' product
 * @param order The order to look for
 * @returns The booking protection item or nothing if not applicable
 */
export function getRefundProtectOrderItem(order: App.Order): App.OrderBookingProtectionItem | undefined {
  return order.bookingProtectionItems.find(item => item.status === 'completed')
}

/**
 * Finds the active booking protection insurance item on the order given if avaialble
 * Note: booking protection is a specific 'insurance' product
 * @param order The order to look for
 * @returns The insurance item or nothing if not applicable
 */
export function getBookingProtectionInsuranceOrderItem(order: App.Order): App.OrderInsuranceItem | undefined {
  return order.insuranceItems.find(item => item.status === 'completed' && item.insuranceType === 'cfmr')
}

export function getXCoverClaimUrl(item: App.OrderInsuranceItem) {
  const query = qs.stringify({ bookingID: item.idProduct })
  return `https://www.xcover.com/en/claims/?${query}`
}

export function getInsuranceDatesFromOrder(order: App.Order) {
  if (order.items.length) {
    const reservations = nonNullable(order.items.map(item => item.reservation))

    // BNBL items don't have a reservation
    if (!reservations.length) return
    const startDate = Math.min(...reservations.map(reservation => new Date(reservation.startDate).getTime()))
    const endDate = Math.max(...reservations.map(reservation => new Date(reservation.endDate).getTime()))
    return {
      startDate: moment(startDate).format(ISO_DATE_FORMAT),
      endDate: moment(endDate).format(ISO_DATE_FORMAT),
    }
  } else if (order.bedbankItems.length) {
    const completedItems = order.bedbankItems.filter(item => item.status === 'completed')

    const startItem = max(completedItems, item => item.checkIn.toDate())
    const endItem = max(completedItems, item => item.checkOut.toDate())

    if (!startItem || !endItem) return

    return {
      startDate: moment(startItem.checkIn).format(ISO_DATE_FORMAT),
      endDate: moment(endItem.checkOut).format(ISO_DATE_FORMAT),
    }
  } else if (order.tourItems.length) {
    const completedItems = order.tourItems.filter(item => item.status === 'completed')

    const startItem = max(completedItems, item => new Date(item.tour.startDate))
    const endItem = max(completedItems, item => new Date(item.tour.endDate))

    if (!startItem || !endItem) return

    return {
      startDate: startItem.tour.startDate,
      endDate: endItem.tour.endDate,
    }
  } else if (order.cruiseItems.length) {
    const completedItems = order.cruiseItems.filter(item => item.status === 'completed')

    const startItem = max(completedItems, item => new Date(item.departureDate))
    const endItem = max(completedItems, item => new Date(item.arrivalDate))

    if (!startItem || !endItem) return

    return {
      startDate: startItem.departureDate,
      endDate: endItem.arrivalDate,
    }
  }
}

export function getDestinationCountriesFromOrder(
  order: App.Order,
  cruiseOffers: Record<string, App.CruiseOffer> = {},
): Array<string> {
  if (order.items.length) {
    return nonNullable(order.items.map(item => item.offer.property?.geoData?.country))
  } else if (order.bedbankItems.length) {
    return order.bedbankItems.map(item => item.offer.property.address.countryName)
  } else if (order.tourItems.length) {
    const completedItems = order.tourItems.filter(item => item.status === 'completed')

    return unique(completedItems.flatMap(item => item.tour.countriesVisited))
  } else if (order.cruiseItems.length) {
    const offerIds = unique(order.cruiseItems.map(item => item.cruiseOfferId))

    const countries = offerIds.flatMap(offerId => {
      const offer = cruiseOffers[offerId]
      if (offer) {
        return getItineraryCountries(offer)
      }
    }).filter(isValidInsuranceCountry)

    return nonNullable(countries)
  }

  return []
}

interface UpdateQuoteParams {
  startDate: string;
  endDate: string;
  travellers: Array<App.OrderInsuranceItemTraveller>;
  itemId: string;
  coverageAmount: number;
}

export function getOrderInsuranceUpdateQuoteKey(params: UpdateQuoteParams) {
  return getObjectKey({
    startDate: params.startDate,
    endDate: params.endDate,
    travellers: params.travellers,
    itemId: params.itemId,
    coverageAmount: params.coverageAmount,
  })
}

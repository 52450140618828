import { components } from '@luxuryescapes/contract-public-offer'

export function offerExtraMap(offerExtra: components['schemas']['offerExtra']): App.OfferExtra {
  return {
    id: offerExtra.id,
    viewsInLast24Hours: offerExtra.viewsInLast24Hours,
    roomsPurchasesToday: offerExtra.roomsPurchasesToday,
    viewsToday: offerExtra.viewsToday,
  }
}

import { FETCH_LE_SUBSCRIPTIONS, FETCH_LE_SUBSCRIPTIONS_V2, FETCH_RESUBSCRIPTION_DISMISSED_DATE, FETCH_TRAVEL_PREFERENCES_DISMISSED, UPDATE_LE_SUBSCRIPTIONS, UPDATE_LE_SUBSCRIPTIONS_V2, UPDATE_RESUBSCRIPTION_DISMISSED_DATE, UPDATE_TRAVEL_PREFERENCES_DISMISSED } from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_REQUEST, API_CALL_FAILURE, API_CALL_SUCCESS } from 'actions/actionConstants'

export const initialLESubscriptionState: App.LESubscriptionsState = {
  settings: {
    app_push_subscribed: false,
    curated_collection_subscribed: false,
    email_subscribed: false,
    my_journey_subscribed: false,
    sms_subscribed: false,
    todays_escapes_cadence: 'daily',
    todays_escapes_subscribed: false,
    web_push_subscribed: false,
    whats_app_subscribed: false,
  },
  fetching: false,
  updating: false,
  fetchError: '',
  updateError: '',
  resubscribeDismissedAt: undefined,
  emailSubscribedUpdatedAt: undefined,
  fetchingResubscribeDismissedAt: false,
  hasDismissedTravelPreferencesModal: true,
  fetchingTravelPreferencesDismissed: false,
}

const apiRequests = reducerSwitch<App.LESubscriptionsState>({
  [FETCH_LE_SUBSCRIPTIONS]: () => ({
    fetching: true,
    fetchError: '',
    updateError: '',
  }),
  [FETCH_LE_SUBSCRIPTIONS_V2]: () => ({
    fetching: true,
    fetchError: '',
    updateError: '',
  }),
  [UPDATE_LE_SUBSCRIPTIONS]: () => ({
    updating: true,
    fetchError: '',
    updateError: '',
  }),
  [UPDATE_LE_SUBSCRIPTIONS_V2]: () => ({
    updating: true,
    fetchError: '',
    updateError: '',
  }),
  [FETCH_RESUBSCRIPTION_DISMISSED_DATE]: () => ({
    fetchingResubscribeDismissedAt: true,
  }),
  [FETCH_TRAVEL_PREFERENCES_DISMISSED]: () => ({
    fetchingTravelPreferencesDismissed: true,
  }),
  [UPDATE_TRAVEL_PREFERENCES_DISMISSED]: () => ({
    hasDismissedTravelPreferencesModal: true,
  }),
})

const apiSuccesses = reducerSwitch<App.LESubscriptionsState>({
  [FETCH_LE_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    settings: action.data,
    fetching: false,
    fetchError: '',
  }),
  [FETCH_LE_SUBSCRIPTIONS_V2]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      ...action.data.settings,
    },
    emailSubscribedUpdatedAt: action.data.emailSubscribedUpdatedAt,
    fetching: false,
    fetchError: '',
  }),
  [UPDATE_LE_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    settings: action.data,
    updating: false,
    updateError: '',
  }),
  [UPDATE_LE_SUBSCRIPTIONS_V2]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      ...action.data.settings,
    },
    emailSubscribedUpdatedAt: action.data.emailSubscribedUpdatedAt,
    updating: false,
    updateError: '',
  }),
  [FETCH_RESUBSCRIPTION_DISMISSED_DATE]: (state, action) => ({
    ...state,
    fetchingResubscribeDismissedAt: false,
    resubscribeDismissedAt: action.data,
  }),
  [UPDATE_RESUBSCRIPTION_DISMISSED_DATE]: (state, action) => ({
    ...state,
    resubscribeDismissedAt: action.data,
  }),
  [FETCH_TRAVEL_PREFERENCES_DISMISSED]: (state, action) => ({
    ...state,
    fetchingTravelPreferencesDismissed: false,
    hasDismissedTravelPreferencesModal: action.data,
  }),
})

const apiFailures = reducerSwitch<App.LESubscriptionsState>({
  [FETCH_LE_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    fetching: false,
    fetchError: action.error,
  }),
  [FETCH_LE_SUBSCRIPTIONS_V2]: (state, action) => ({
    ...state,
    fetching: false,
    fetchError: action.error,
  }),
  [UPDATE_LE_SUBSCRIPTIONS]: (state, action) => ({
    ...state,
    updating: false,
    updateError: action.error,
  }),
  [UPDATE_LE_SUBSCRIPTIONS_V2]: (state, action) => ({
    ...state,
    updating: false,
    updateError: action.error,
  }),
  [FETCH_TRAVEL_PREFERENCES_DISMISSED]: (state) => ({
    ...state,
    fetchingTravelPreferencesDismissed: false,
    hasDismissedTravelPreferencesModal: false,
  }),
})

export default createReducer<App.LESubscriptionsState>(initialLESubscriptionState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { EnsureGoogleMapsLoaded } from 'contexts/GoogleMapsContext'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import { GoogleMap } from '@react-google-maps/api'
import PropertyMarker from 'components/Common/PropertyMap/PropertyMarker'
import { fitMarkersOnMap } from 'lib/maps/googleMapUtils'

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

interface MapStyle {
  height: string | number
  width: string | number
  flexGrow?: number
}

interface Props {
  title: string;
  pickupTitle?: string;
  primaryMapMarker: App.MapMarker;
  pickupMapMarker?: App.MapMarker;
  locationMapMarkerList?: Array<App.MapMarker>;
  googleMapId: string;
  zoomLevel: number;
  style: MapStyle;
  onZoomChanged?: (zoomIn: boolean) => void;
}

function ExperienceMap(props: Props) {
  const {
    primaryMapMarker,
    title,
    pickupTitle,
    pickupMapMarker,
    locationMapMarkerList,
    googleMapId,
    zoomLevel,
    style,
    onZoomChanged,
  } = props
  const [map, setMap] = useState<google.maps.Map>()

  // Fit the correct bounds on load
  const onLoad = useCallback((map: google.maps.Map) => { setMap(map) }, [])

  const primaryMarker = primaryMapMarker ?
    <PropertyMarker marker={primaryMapMarker} title={`Experience: ${title}`} multipleMarkers/> :
    null

  const pickupMarker = pickupMapMarker ?
    <PropertyMarker marker={pickupMapMarker} title={`Pickup point: ${pickupTitle}`} multipleMarkers/> :
    null

  const locationMarkers = useMemo(() => {
    const list = locationMapMarkerList ?? []
    return list.map((marker: App.MapMarker) => {
      return <PropertyMarker marker={marker} title={marker.name} key={marker.id} multipleMarkers />
    })
  }, [locationMapMarkerList])

  useEffect(() => {
    if (map) {
      const allMarkers = [
        primaryMapMarker,
        pickupMapMarker,
        ...(locationMapMarkerList ?? []),
      ].filter(Boolean)
      fitMarkersOnMap(map, allMarkers)
    }
  }, [map]) // eslint-disable-line react-hooks/exhaustive-deps
  const prevZoomLevel = useRef(zoomLevel)
  return <Root>
    <EnsureGoogleMapsLoaded loadingElement={<LoadingIndicator inline />}>
      <GoogleMap
        id={googleMapId}
        zoom={zoomLevel}
        onLoad={onLoad}
        mapContainerStyle={style}
        onZoomChanged={() => {
          if (map) {
            const current = prevZoomLevel.current
            prevZoomLevel.current = map.getZoom()
            onZoomChanged?.(prevZoomLevel.current > current)
          } }}
      >
        {primaryMarker && <PropertyMarker
          marker={primaryMapMarker}
          title={`Experience: ${title}`}
          multipleMarkers
        />}
        {locationMarkers.map((marker) => marker)}
        {pickupMarker && <PropertyMarker
          marker={pickupMapMarker}
          title={`Pickup point: ${pickupTitle}`}
          multipleMarkers
        />}
      </GoogleMap>
    </EnsureGoogleMapsLoaded>
  </Root>
}

export default ExperienceMap

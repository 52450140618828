import React, { Component } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import InputWrap from './InputWrap'
import InputLabelWrap from './InputLabelWrap'
import { InputTextBase } from 'components/Luxkit/Typography/InputText'
import { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from 'components/Luxkit/Typography/Typography'

export const StyledTextArea = styled.textarea`
  ${InputTextBase}
  line-height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});
  width: 100%;
  border: none;
  background: transparent;
  transition: color 0.2s;
  padding: ${rem(12)};
  display: block;

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.palette.neutral.default.two};
    }
  }

  &.resize-none { resize: none; }
  &.resize-both { resize: both; }
  &.resize-vertical { resize: vertical; }
  &.resize-horizontal { resize: horizontal; }
`

interface State {
  isDirty?: Boolean;
  error?: string;
}

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onDirtyChange?: (isDirty: boolean) => void;
  onErrorUpdate?: (textArea: HTMLTextAreaElement) => void;
  getInvalidMessage?: (textArea: HTMLTextAreaElement) => string | undefined;
  label?: string;
  inputRef?: React.Ref<HTMLTextAreaElement>;
  // Override the 'required' error message
  requiredErrorMessage?: string;
  // Override any other validation error
  // if you want more granular error handling, use getInvalidMessage
  invalidErrorMessage?: string;
  manualError?: boolean
  noValidationSpacing?: boolean;
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  helpText?: string;
  helpTextPos?: 'left' | 'right';
}

type PropsWithDefaults = typeof TextArea.defaultProps & Props

class TextArea extends Component<React.PropsWithChildren<PropsWithDefaults>, State> {
  state: State = {}

  static defaultProps = {
    onChange: noop,
    onBlur: noop,
    onInvalid: noop,
    onDirtyChange: noop,
    onErrorUpdate: noop,
    getInvalidMessage: (_textArea: HTMLTextAreaElement) => '',
    type: 'text',
  }

  setDirty = () => {
    if (!this.state.isDirty) {
      this.props.onDirtyChange(true)
      this.setState({ isDirty: true })
    }
  }

  updateErrorMessage = (textArea: HTMLTextAreaElement) => {
    const { error } = this.state
    const {
      requiredErrorMessage,
      invalidErrorMessage,
      getInvalidMessage,
      maxLength,
      minLength,
    } = this.props

    const validity = textArea.validity
    if (validity && !validity.valid) {
      let message = getInvalidMessage(textArea)
      if (!message) {
        if (validity.valueMissing && requiredErrorMessage) {
          message = requiredErrorMessage
        } else if (invalidErrorMessage) {
          message = invalidErrorMessage
        } else if (validity.tooShort) {
          message = `Please enter at least ${minLength} characters`
        } else if (validity.tooLong) {
          message = `Please enter at most ${maxLength} characters`
        } else {
          message = textArea.validationMessage
        }
      }
      this.setState({ error: message })
    } else if (error) {
      this.setState({ error: undefined })
    }
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.updateErrorMessage(e.currentTarget)
    this.props.onChange(e)
  }

  onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    this.setDirty()
    this.updateErrorMessage(e.currentTarget)
    this.props.onBlur(e)
  }

  onInvalid = (e: React.InvalidEvent<HTMLTextAreaElement>) => {
    this.setDirty()
    this.updateErrorMessage(e.currentTarget)
    this.props.onInvalid(e)
  }

  render() {
    const {
      inputRef,
      className,
      label,
      disabled,
      noValidationSpacing,
      resize,
      manualError,
      onDirtyChange,
      onErrorUpdate,
      helpText,
      helpTextPos,
      ...rest
    } = this.props
    const { isDirty, error } = this.state

    let errorMsg = isDirty && error

    if (manualError) {
      errorMsg = rest.invalidErrorMessage
    }

    return (
      <InputLabelWrap
        className={className}
        label={label}
        required={rest.required}
      >
        <InputWrap
          error={errorMsg}
          disabled={disabled}
          noValidationSpacing={noValidationSpacing}
          helpText={helpText}
          helpTextPos={helpTextPos}
        >
          <StyledTextArea
            {...rest}
            className={cn(className, { [`resize-${resize}`]: resize })}
            disabled={disabled}
            ref={inputRef}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onInvalid={this.onInvalid}
          />
        </InputWrap>
      </InputLabelWrap>
    )
  }
}

export default TextArea

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGuidedtoursIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22.0772 12.0938 17.6715 8.954c-.1695-.1196-.3688-.1794-.5781-.1794-.0499 0-.1097 0-.1595.01-.2592.0398-.4885.1893-.638.3986l-2.6713 3.738c-.319-.2094-.638-.3888-.947-.5283.638-.5881 1.0965-1.3357 1.3457-2.183.289-.9768.2591-1.9935-.0698-2.9504-.3289-.957-.9469-1.7743-1.7743-2.3624A4.8352 4.8352 0 0 0 9.3683 4a4.8355 4.8355 0 0 0-2.8109.8971c-.8273.5881-1.4354 1.4055-1.7743 2.3624-.3289.9569-.3588 1.9836-.0697 2.9504.2492.8373.7077 1.5849 1.3456 2.183-1.1762.5382-2.2029 1.3656-2.9903 2.4022-.8672 1.1363-1.4055 2.5019-1.565 3.9173-.01.1296 0 .2692.03.3988.0398.1295.0996.2492.1794.3488.1694.2094.4186.3489.6877.3788h.1097c.2292 0 .4585-.0797.638-.2293.2092-.1694.3488-.4186.3787-.6877.1595-1.4454.8473-2.7711 1.9238-3.7379 1.0765-.9669 2.472-1.4952 3.9273-1.4952 1.0665 0 2.1231.299 3.0601.8772l-.7177 1.0067c-.319.4386-.2093 1.0566.2293 1.3756.0997.0697.1993.1196.309.1495.0797.0199.1694.0299.2591.0299.319 0 .618-.1495.8074-.4087l.6081-.8473c.7276.8872 1.1762 1.9338 1.3057 3.0402.0299.2492.1496.4785.3389.6479.1894.1695.4287.2592.6779.2592h.0897c.299-.0299.5382-.1695.7077-.3788a1.0114 1.0114 0 0 0 .2193-.7476c-.1595-1.4254-.7077-2.791-1.5749-3.9373a7.3006 7.3006 0 0 0-.5283-.618l.1894-.2691 3.5983 2.5717c.1695.1196.3688.1794.5782.1794.0498 0 .1096 0 .1594-.01.2592-.0398.4885-.1893.638-.3987l1.9836-2.781c.3189-.4386.2093-1.0566-.2293-1.3755l-.01.0398Zm-1.9437 1.0267-.8373 1.1762-2.8009-1.9935.8373-1.1762 2.8009 1.9935ZM8.8101 6.0733c.1794-.0399.3688-.0498.5482-.0498.3689 0 .7377.0697 1.0766.2192.5183.2094.9569.5782 1.2659 1.0367.309.4685.4784 1.0067.4784 1.565 0 .7575-.289 1.4652-.8273 1.9935-.5283.5283-1.236.8273-1.9936.8273-.5581 0-1.1064-.1595-1.5649-.4784a2.7412 2.7412 0 0 1-1.0366-1.266c-.2094-.5183-.2692-1.0765-.1595-1.6247.1096-.5482.3788-1.0466.7675-1.4453.3887-.3987.8971-.6579 1.4453-.7675v-.01Z"/>
  </SvgIcon>
}

export default LineGuidedtoursIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUsersMultiIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M3.382 9.5308a3.9351 3.9351 0 0 0 .9475 2.0178 7.0843 7.0843 0 0 0-3.3294 6.045.787.787 0 0 0 1.5742 0 5.5098 5.5098 0 0 1 3.1484-5.0217l.1023-.0551.307-.2047a.787.787 0 0 0 .3542-.6769.7871.7871 0 0 0-.3936-.6611A2.361 2.361 0 0 1 4.912 8.9355a2.3613 2.3613 0 0 1 2.3613-2.3613.787.787 0 1 0 0-1.5742A3.9355 3.9355 0 0 0 3.382 9.5308Zm12.2073 1.0143a3.8724 3.8724 0 0 1-.9877 1.3184 6.2969 6.2969 0 0 1 3.6994 5.7301.7871.7871 0 0 1-1.5742 0 4.7223 4.7223 0 0 0-4.7226-4.7226 4.7224 4.7224 0 0 0-4.7226 4.7226.787.787 0 0 1-1.5742 0 6.2966 6.2966 0 0 1 3.6994-5.7301 3.8723 3.8723 0 0 1-1.3381-2.928 3.9355 3.9355 0 1 1 7.871 0 3.8721 3.8721 0 0 1-.3504 1.6096Zm-4.897.3537a2.3612 2.3612 0 1 0 2.2155-4.1449 2.3613 2.3613 0 0 0-2.2155 4.1449Zm9.9257-1.368a3.9348 3.9348 0 0 1-.9475 2.0178 7.0839 7.0839 0 0 1 3.3294 6.045.787.787 0 1 1-1.5742 0 5.5096 5.5096 0 0 0-3.1484-5.0217l-.1023-.0551-.3069-.2047a.786.786 0 0 1 .0393-1.338 2.3613 2.3613 0 0 0 1.1807-2.0386 2.361 2.361 0 0 0-2.3613-2.3613.787.787 0 1 1 0-1.5742 3.9354 3.9354 0 0 1 3.8912 4.5308Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineUsersMultiIcon

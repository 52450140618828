function isIOS() {
  return navigator.userAgent.match(/ipad|iphone/i)
}

function clipboardAPIAvailable() {
  return !!(navigator.clipboard && window.isSecureContext)
}

function legacyMethodAvailable() {
  return !!(document.queryCommandSupported?.('copy'))
}

export function copyAvailable() {
  if (IS_SSR) {
    return false
  } else {
    return clipboardAPIAvailable() || legacyMethodAvailable()
  }
}

export function copyValueToClipboard(value: string): Promise<void> {
  if (clipboardAPIAvailable()) {
    return navigator.clipboard.writeText(value)
  }
  else if (legacyMethodAvailable()) {
    const el = document.createElement('textarea')
    el.value = value
    document.body.appendChild(el)
    if (isIOS()) {
      el.contentEditable = 'true'
      el.readOnly = true
      const range = document.createRange()
      range.selectNodeContents(el)
      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range)
      el.setSelectionRange(0, 999999)
    } else {
      el.select()
    }
    document.execCommand('copy')
    document.body.removeChild(el)
    return Promise.resolve()
  }
  else {
    return Promise.reject()
  }
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineArrowsSwapVerticalIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 21 20">
    <path fill="currentColor" d="M15.3629 10.3744V5.5666l.832.838c.1983.1972.4672.308.7477.308.2804 0 .5494-.1108.7477-.308A1.0489 1.0489 0 0 0 18 5.6608c0-.279-.1114-.5465-.3097-.7437l-2.6328-2.6187a1.053 1.053 0 0 0-.3475-.22 1.0585 1.0585 0 0 0-.8004 0 1.055 1.055 0 0 0-.3475.22l-2.6328 2.6187a1.0438 1.0438 0 0 0-.3115.7437 1.0438 1.0438 0 0 0 .3115.7437c.0979.0981.2144.176.3427.2292a1.0583 1.0583 0 0 0 .81 0 1.0527 1.0527 0 0 0 .3427-.2292l.832-.838v4.8078c0 .2778.111.5442.3085.7406.1974.1965.4653.3068.7446.3068.2793 0 .5472-.1103.7447-.3068a1.0443 1.0443 0 0 0 .3084-.7406ZM8.4397 17.7015a1.0537 1.0537 0 0 1-.3475.2199 1.058 1.058 0 0 1-.8004 0 1.0537 1.0537 0 0 1-.3475-.2199l-2.6328-2.6187a1.0432 1.0432 0 0 1 0-1.4873 1.0577 1.0577 0 0 1 1.4954 0l.832.8379V9.6256c0-.2778.111-.5442.3084-.7407a1.056 1.056 0 0 1 .7447-.3067c.2793 0 .5472.1103.7446.3067a1.045 1.045 0 0 1 .3085.7407v4.8078l.832-.8379a1.0601 1.0601 0 0 1 .7477-.3081c.2804 0 .5494.1108.7477.3081.1983.1972.3097.4647.3097.7436 0 .279-.1114.5465-.3097.7437l-2.6328 2.6187Z"/>
  </SvgIcon>
}

export default LineArrowsSwapVerticalIcon

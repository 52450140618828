import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getInclusionsViewableLuxPlusTier } from 'selectors/luxPlusSelectors'
import { shallowEqual } from 'react-redux'

interface Props {
  departure?: App.CruiseDepartureDetails
  luxPlusInclusionsByTier?: App.LuxPlusInclusionsByTier
}

export function useCruiseOfferInclusions({ departure, luxPlusInclusionsByTier } : Props): {
  inclusionDetails?: Cruises.OfferInclusionDetails,
  standardInclusions: Array<App.PackageInclusion>,
  luxPlusInclusions: Array<App.PackageInclusion>,
} {
  const {
    luxPlusEnabled,
    inclusionsViewableLuxPlusTier,
  } = useAppSelector((state) => ({
    luxPlusEnabled: isLuxPlusEnabled(state),
    inclusionsViewableLuxPlusTier: getInclusionsViewableLuxPlusTier(state),
  }), shallowEqual)

  const inclusionsByTier = departure?.luxPlusInclusionsByTier || luxPlusInclusionsByTier
  const luxPlusInclusions = inclusionsByTier?.[inclusionsViewableLuxPlusTier] || []

  return {
    inclusionDetails: departure?.inclusionDetails,
    luxPlusInclusions: luxPlusEnabled ? luxPlusInclusions : [],
    standardInclusions: departure?.standardInclusions || [],
  }
}

export interface CartInitUnrecoverableOfferErrorDetails {
  type: 'unrecoverable_offer_error',
  code: 'offer_not_found' | 'offer_sold_out',
  message: string,
}

export interface CartInitRecoverableOfferErrorDetails {
  type: 'recoverable_offer_error',
  code: 'room_not_found' | 'package_not_found' | 'price_not_found' | 'tour_date_not_found' | 'date_not_available',
  offer: App.AnyOffer,
  message: string,
}

export type CartInitErrorDetails =
  | CartInitUnrecoverableOfferErrorDetails
  | CartInitRecoverableOfferErrorDetails

export default class CartInitError extends Error {
  details: CartInitErrorDetails

  constructor(details: CartInitErrorDetails) {
    super(details.message)
    this.name = 'CartInitError'
    this.details = details
  }
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCarIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6.62 13.0799a.9013.9013 0 0 0-.54.54 1.0002 1.0002 0 0 0 .7274 1.3513.9998.9998 0 0 0 .5726-.0513 1.1477 1.1477 0 0 0 .33-.21 1.1498 1.1498 0 0 0 .21-.33.8397.8397 0 0 0 .08-.38 1.0503 1.0503 0 0 0-.29-.71 1.0006 1.0006 0 0 0-1.09-.21Zm13.14-4L18.4 5.05a3 3 0 0 0-2.84-2H8.44a3 3 0 0 0-2.84 2L4.24 9.11A3.0002 3.0002 0 0 0 2 12v4a3 3 0 0 0 2 2.82V20a1.0002 1.0002 0 0 0 1 1 1 1 0 0 0 1-1v-1h12v1a1 1 0 0 0 2 0v-1.18A3.0001 3.0001 0 0 0 22 16v-4a3.0003 3.0003 0 0 0-2.24-2.89v-.03ZM7.49 5.68A1 1 0 0 1 8.44 5h7.12a.9998.9998 0 0 1 1 .68L17.61 9H6.39l1.1-3.32ZM20 16a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1.0001 1.0001 0 0 1 1-1h14a1.0001 1.0001 0 0 1 1 1v4Zm-3.38-2.92a.9012.9012 0 0 0-.54.54 1.0009 1.0009 0 0 0 .2213 1.0788.9999.9999 0 0 0 1.0787.2212.8996.8996 0 0 0 .54-.54A.8392.8392 0 0 0 18 14a1.0505 1.0505 0 0 0-.29-.71 1.0005 1.0005 0 0 0-1.09-.21ZM13 13h-2a1.0001 1.0001 0 0 0 0 2h2a1 1 0 1 0 0-2Z"/>
  </SvgIcon>
}

export default LineCarIcon

import { EmptyObject } from 'lib/object/objectUtils'
import qs, { IParseOptions } from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

/**
 * Parses the current search string and returns a memoised set of URLSearchParams
 * @returns A set of URLSearchParams
 */
export default function useQueryParams() {
  const { search } = useLocation()
  return useMemo(() => {
    return new URLSearchParams(search)
  }, [search])
}

/**
 * Returns query params parsed by `qs` instead of url search params
 * @returns The type you set
 */
export function useQsQueryParams<T = unknown>(options: IParseOptions = EmptyObject): T {
  const { search } = useLocation()
  return useMemo(() => {
    return qs.parse(search, { ...options, ignoreQueryPrefix: true }) as T
  }, [search, options])
}

import switchFunc from 'lib/function/switchFunc'

const MILES_PER_KM = 0.621371
const FEET_PER_KM = 3280.84

export type DistanceUnit = 'mi' | 'km'

export const getUnit = switchFunc<DistanceUnit>({ US: 'mi', GB: 'mi' }, 'km')

export function kmToMiles(km: number) {
  return km * MILES_PER_KM
}

export function kmToFeet(km: number) {
  return km * FEET_PER_KM
}

export function roundToNearest(value: number, nearest: number) {
  return Math.round(value / nearest) * nearest
}

import {
  FETCH_ADDON_BOOKING_AVAILABILITY,
  FETCH_OFFER_PACKAGE_ADDONS,
} from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
} from 'actions/actionConstants'

export const initialAddonState: App.AddonState = {
  fetchingAvailability: false,
  fetchingAvailabilityError: '',
  addonAvailability: {},
  addonsByPackage: {},
  addonsByPackageLoading: {},
}

const apiRequests = reducerSwitch<App.AddonState>({
  [FETCH_ADDON_BOOKING_AVAILABILITY]: () => ({
    fetchingAvailability: true,
    fetchingAvailabilityError: undefined,
  }),
  [FETCH_OFFER_PACKAGE_ADDONS]: (state, action) => ({
    addonsByPackageLoading: {
      ...state.addonsByPackageLoading,
      [action.packageId]: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.AddonState>({
  [FETCH_ADDON_BOOKING_AVAILABILITY]: (state, action) => ({
    fetchingAvailability: false,
    fetchingAvailabilityError: undefined,
    addonAvailability: {
      ...state.addonAvailability,
      [action.addonId]: action.data,
    },
  }),
  [FETCH_OFFER_PACKAGE_ADDONS]: (state, action) => ({
    addonsByPackage: {
      ...state.addonsByPackage,
      [action.packageId]: action.data,
    },
    addonsByPackageLoading: {
      ...state.addonsByPackageLoading,
      [action.packageId]: false,
    },
  }),
})

const apiFailures = reducerSwitch<App.AddonState>({
  [FETCH_ADDON_BOOKING_AVAILABILITY]: (state, action) => ({
    fetchingAvailability: false,
    fetchingAvailabilityError: action.error,
  }),
  [FETCH_OFFER_PACKAGE_ADDONS]: (state, action) => ({
    addonsByPackageLoading: {
      ...state.addonsByPackageLoading,
      [action.packageId]: false,
    },
  }),
})

export default createReducer<App.AddonState>(initialAddonState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

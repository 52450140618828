import React from 'react'
import OfferListLoadingOfferTile from './OfferListLoadingOfferTile'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'

const OfferCardLoadingTile = styled(OfferListLoadingOfferTile)`
  width: 75vw;

  ${mediaQueryUp.tablet} {
    width: 55vw;
  }

  ${mediaQueryUp.desktop} {
    width: 90vw;
    max-width: ${rem(1140)};
  }
`

interface Props extends React.ComponentProps<typeof OfferListLoadingOfferTile> {

}

function OfferListLoadingOfferCard(props: Props) {
  return <OfferCardLoadingTile {...props} />
}

export default OfferListLoadingOfferCard

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineExclamationTriangleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 16a.9999.9999 0 0 0-.9808 1.1951.9995.9995 0 0 0 .7857.7857A1 1 0 1 0 12 16Zm10.67 1.47-8.05-14a3 3 0 0 0-5.24 0l-8 14A3.0001 3.0001 0 0 0 3.94 22h16.12a2.9993 2.9993 0 0 0 2.6289-1.5021A3.0001 3.0001 0 0 0 22.67 17.47Zm-1.73 2a.9998.9998 0 0 1-.88.51H3.94a.9997.9997 0 0 1-.88-.51.9998.9998 0 0 1 0-1l8-14a1 1 0 0 1 1.78 0l8.05 14a1.0006 1.0006 0 0 1 .05 1.02v-.02ZM12 8a1 1 0 0 0-1 1v4a.9997.9997 0 0 0 1 1 .9999.9999 0 0 0 1-1V9a1.0002 1.0002 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineExclamationTriangleIcon

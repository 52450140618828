import React, { useCallback } from 'react'
import SwitchButton from 'components/Common/SwitchButton'
import { useAppDispatch } from 'hooks/reduxHooks'
import { connect } from 'react-redux'
import Group from '../../../../utils/Group'
import BodyText from '../../../../Luxkit/Typography/BodyText'
import { toggleDisableMembership } from 'actions/LuxPlusActions'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

interface MappedProps {
  disableMembership: boolean;
}

function DisableMembershipToggle({
  disableMembership,
}: MappedProps) {
  const dispatch = useAppDispatch()

  const onToggleDisableMembership = useCallback(() => {
    dispatch(toggleDisableMembership())
  }, [dispatch])

  return (
    <Group direction="horizontal" gap={8} verticalAlign="center">
      <BodyText variant="medium" colour="neutral-one">Disable {LUXURY_PLUS.PROGRAM_NAME}</BodyText>
      <SwitchButton
        toggled={disableMembership}
        onToggle={onToggleDisableMembership}
      />
    </Group>
  )
}

function mapStateToProps(state: App.State) {
  return {
    disableMembership: state.auth.account.luxPlus.member.disableMembership,
  }
}

export default connect(mapStateToProps)(DisableMembershipToggle)

import React, { useContext } from 'react'
import SearchFloatingPanel, { SearchFloatingPanelProps } from 'components/SearchV2/Components/Mobile/Panels/SearchFloatingPanel'

import { LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL } from 'constants/search'
import SearchLocationInput from 'components/Search/SearchForm/SearchLocationInput/SearchLocationInput'
import useMountedEffect from 'hooks/useMountedEffect'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import noop from 'lib/function/noop'

interface Props extends
  Omit<
    SearchFloatingPanelProps,
    'title' | 'confirmText' | 'isConfirmButtonHidden' | 'children'
  >
{
  searchTypes?: Array<App.SearchType>;
  typeaheadTypes?: Array<App.SearchPlaceType>;
  placeholder?: string;
  isSecondaryLocation?: boolean;
  title?: string;
  popularPlaces?: Array<App.SearchItem>;
  typeaheadVertical?: App.SearchPlaceVertical;
  differentLocations?: boolean;
  recentSearches?: Array<App.FullRecentSearch>;
  overrideSearchItems?: Array<App.SearchItem>;
  onChange?: (item?: App.SearchItem) => void;
}

function LocationFloatingPanel(props: Props) {
  const {
    onConfirm = noop,
    onChange = noop,
    searchTypes,
    typeaheadTypes,
    typeaheadVertical,
    placeholder = LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL,
    isSecondaryLocation,
    title = 'Where are you going?',
    popularPlaces,
    recentSearches,
    isOpen,
    overrideSearchItems,
  } = props

  const { searchItem, secondarySearchItem } = useContext(GlobalSearchStateContext)

  useMountedEffect(() => {
    if (isOpen && (searchItem && secondarySearchItem || searchItem)) {
      // It's open and our item has changed, user must've selected something
      // we do it this way because of a timing issue between setting context state and having the updated values
      // the state updates after the event, so we need to listen on the state change
      onConfirm()
    }
  }, [searchItem, secondarySearchItem])

  return (
    <SearchFloatingPanel
      {...props}
      title={title}
      isOpen={isOpen}
      isConfirmButtonHidden
    >
      <SearchLocationInput
        variant="inline"
        placeholder={placeholder}
        searchTypes={searchTypes}
        placeTypes={typeaheadTypes}
        searchVertical={typeaheadVertical}
        isSecondaryLocation={isSecondaryLocation}
        recentSearchItems={recentSearches}
        placeholderItems={popularPlaces}
        overrideSearchItems={overrideSearchItems}
        onChange={onChange}
      />
    </SearchFloatingPanel>
  )
}

export default LocationFloatingPanel

import React, { forwardRef, useCallback, useContext, useRef } from 'react'
import moment from 'moment'
import CarHireTimeSelect from './CarHireTimeSelect'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'

import { SearchMenuStates } from 'components/Search/type'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import { shortTimeHourFormatAmPm } from 'lib/datetime/dateUtils'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import styled from 'styled-components'

const FieldGroup = styled(SearchFormFieldGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

interface Props {
  date: moment.Moment;
  time: string;
  dateLabel: string;
  inputLabel: string;
  fieldSearchMenu: SearchMenuStates;
  setTime: (time: string) => void;
  emptyTimeLabel: string;
  className?: string
}

const CarHirePickUpDateTimeInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { date, time, dateLabel, fieldSearchMenu, inputLabel, setTime, emptyTimeLabel, className } = props
  const { activeMenu } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const fieldRef = useRef<HTMLButtonElement>(null)

  const showTimeDropdown = activeMenu === fieldSearchMenu
  const showDateDropdown = activeMenu === SearchMenuStates.CarHireDatePicker

  const toggleDropdown = useCallback((visible: boolean, menu: SearchMenuStates) => {
    const nextMenu = visible ? SearchMenuStates.Closed : menu
    searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: nextMenu })
  }, [searchDispatch])

  const setTimeAndClose = (time: string) => {
    setTime(time)
    toggleDropdown(showTimeDropdown, fieldSearchMenu)
  }

  return (
    <FieldGroup ref={ref} className={className}>
      <SearchFormField
        label={inputLabel}
        onClick={() => toggleDropdown(showDateDropdown, SearchMenuStates.CarHireDatePicker)}
        value={date ? date.format(DMY_CASUAL_FORMAT) : undefined}
        placeholder={dateLabel}
        required
      />
      <div>
        <SearchFormField
          label="Time"
          ref={fieldRef}
          onClick={() => toggleDropdown(showTimeDropdown, fieldSearchMenu)}
          value={time}
          displayValue={time ? shortTimeHourFormatAmPm(new Date('1970-01-01T' + time + 'Z'), true) : undefined}
          placeholder={emptyTimeLabel}
          required
        />
        <CarHireTimeSelect
          toggleDropDown={() => toggleDropdown(showTimeDropdown, fieldSearchMenu)}
          open={showTimeDropdown}
          dropdownTriggerRef={fieldRef}
          onChange={setTimeAndClose}
          selectedTime={time}
        />
      </div>
    </FieldGroup>
  )
})

export default CarHirePickUpDateTimeInput

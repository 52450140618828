import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import React, { RefObject, useCallback, useContext, useMemo, useRef, useState } from 'react'
import CruiseLinesSearchList from './CruiseLineSelectContentList'
import FormattedCruiseLineSearchSummary from './FormattedCruiseLineSearchSummary'
import SearchFormTextField from 'components/SearchV2/Components/SearchFormField/SearchFormTextField'
import useToggle from 'hooks/useToggle'

interface Props {
  cruiseLineNames: Array<string>
  dropdownAnchorRef: RefObject<HTMLElement>
  onChange: (values: App.CruiseGlobalFilters) => void
  handlerSearchTerm: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlerClearSearchTerm: () => void
  searchTerm: string
}

function CruiseLineSelectDesktop(props: Props) {
  const {
    cruiseLineNames,
    onChange,
    dropdownAnchorRef,
    handlerSearchTerm,
    handlerClearSearchTerm,
    searchTerm,
  } = props
  const inputTriggerRef = useRef<HTMLInputElement>(null)
  const { value: isOpen, on: openDropdown, off: closeDropdown } = useToggle(false)
  const [showAll, setShowAll] = useState(false)
  const { cruiseLines } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const onApply = useCallback(() => {
    onChange({ cruiseLines })
    closeDropdown()
  }, [onChange, cruiseLines, closeDropdown])

  const onClear = useCallback(() => {
    searchDispatch({ type: GlobalSearchStateActions.SET_CRUISE_LINES, cruiseLines: [] })
    onChange({ cruiseLines: [] })
    setShowAll(false)
    handlerClearSearchTerm()
  }, [handlerClearSearchTerm, onChange, searchDispatch])

  const cruiseLineLabel = useMemo(() => {
    if (isOpen) return searchTerm

    const [firstName] = cruiseLineNames
    const more = cruiseLineNames.length > 1 ? `+${cruiseLineNames.length - 1}` : ''
    return firstName && `${firstName} ${more}`
  }, [cruiseLineNames, isOpen, searchTerm])

  return (
    <div data-testid="cruise-line-select-desktop">
      <SearchFormTextField
        onClick={openDropdown}
        ref={inputTriggerRef}
        label="Cruise lines"
        placeholder={isOpen ? 'Search cruise lines' : 'All cruise lines'}
        value={cruiseLineLabel}
        displayValue={cruiseLineLabel}
        onChange={handlerSearchTerm}
        onClear={handlerClearSearchTerm}
      />

      <DropdownSheet
        size="fill-anchor"
        anchorRef={dropdownAnchorRef}
        triggerRef={inputTriggerRef}
        open={isOpen}
        onClose={closeDropdown}
        primaryActionProps={{
          'data-testid': 'cruise-filter-footer-apply',
          children: 'Apply',
          onClick: onApply,
        }}
        secondaryActionProps={{
          'data-testid': 'cruise-filter-footer-clear-all',
          kind: 'tertiary',
          children: 'Reset all',
          onClick: onClear,
        }}
        footerStart={<FormattedCruiseLineSearchSummary />}
      >
        <CruiseLinesSearchList
          showAll={showAll}
          onSetShowAll={setShowAll}
          searchTerm={searchTerm}
        />
      </DropdownSheet>
    </div>
  )
}

export default CruiseLineSelectDesktop

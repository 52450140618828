import React from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import BookingProtectionComparison from './BookingProtectionComparison/BookingProtectionComparison'
import styled from 'styled-components'
import { rem } from 'polished'

const ModalContent = styled.div`
  padding-top: ${rem(16)};
`

interface Props {
  cancellationType: 'free' | 'flexible' | undefined;
  cancelUntilFullRefundDate: moment.Moment | undefined;
  cancelAfterNonRefundableDate?: moment.Moment | undefined;
  bookingProtectionLastEligibleRefundDate?: moment.Moment;
  cancellationAfterChangeOfMindPeriod?: boolean | '';
}

function BookingProtectionViaInfoModal(props: Props) {
  const {
    cancellationType,
    cancelUntilFullRefundDate,
    cancelAfterNonRefundableDate,
    bookingProtectionLastEligibleRefundDate,
    cancellationAfterChangeOfMindPeriod,
  } = props

  return <Modal
    title="Cancellation Terms"
    size="L"
  >
    <ModalContent>
      <BookingProtectionComparison
        cancellationType={cancellationType}
        cancelUntilFullRefundDate={cancelUntilFullRefundDate}
        cancelAfterNonRefundableDate={cancelAfterNonRefundableDate}
        bookingProtectionLastEligibleRefundDate={bookingProtectionLastEligibleRefundDate}
        cancellationAfterChangeOfMindPeriod={cancellationAfterChangeOfMindPeriod}
      />
    </ModalContent>
  </Modal>
}

export default BookingProtectionViaInfoModal

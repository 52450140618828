import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBedIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7 12.5a2.9996 2.9996 0 0 0 2.7716-1.8519 3.0003 3.0003 0 0 0-.6503-3.2694A3 3 0 1 0 7 12.5Zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm13-2h-8a1 1 0 0 0-1 1v6H3v-8a1 1 0 0 0-2 0v13a1 1 0 1 0 2 0v-3h18v3a1.0001 1.0001 0 0 0 2 0v-9a3 3 0 0 0-3-3Zm1 7h-8v-5h7a1 1 0 0 1 1 1v4Z"/>
  </SvgIcon>
}

export default LineBedIcon

import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import CruiseFilterFooter from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseFilterFooter'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Group from 'components/utils/Group'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useToggle from 'hooks/useToggle'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DeparturePortMapped } from './CruiseDepartureSelect'
import CruiseDepartureSelectList from './CruiseDepartureSelectList'
import CruiseDepartureSelectListAll from './CruiseDepartureSelectListAll'

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void;
  drawerMode?: boolean;
  regions: Array<App.CruiseDepartureRegion>;
  setIsAllSelected: (value: boolean) => void
  isAllSelected: boolean
  setSearchTerm: (value: string) => void
  searchTerm: string
  handleSearchTerm: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleOnChange: (e: Array<App.SearchItem>) => void
  fetching: boolean
  searchDeparturePorts: Array<DeparturePortMapped>
  setSelectedRegion: (value: App.CruiseDepartureRegion) => void
  filterFacetsByRegion: (region: App.CruiseDepartureRegion) => Array<App.CruiseSearchFacet>
  mapDeparturePort: (departurePort: App.CruiseSearchFacet) => DeparturePortMapped
}

const SET_SECONDARY_SEARCH_ITEMS = GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEMS

function CruiseDepartureSelectMobile({
  regions,
  onChange,
  setIsAllSelected,
  isAllSelected,
  setSearchTerm,
  searchTerm,
  handleSearchTerm,
  handleOnChange,
  fetching,
  searchDeparturePorts,
  setSelectedRegion,
  filterFacetsByRegion,
  mapDeparturePort,
}: Props) {
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const { secondarySearchItems } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const [selectedRegions, setSelectedRegions] = useState<Array<string>>([])
  const [isOpen, toggleModal] = useToggle(false)

  useEffect(() => {
    setSelectedRegion(regions[0])
    inputSearchRef.current?.focus({ preventScroll: true })
  }, [regions, setSelectedRegion, isOpen])

  const onClear = useCallback(() => {
    searchDispatch({
      type: SET_SECONDARY_SEARCH_ITEMS,
      secondarySearchItems: [],
    })
    setSearchTerm('')
    setIsAllSelected(false)
    setSelectedRegions([])
  }, [searchDispatch, setIsAllSelected, setSearchTerm])

  const handleOnApply = useCallback(() => {
    onChange({ secondarySearchItems })
    toggleModal()
  }, [onChange, secondarySearchItems, toggleModal])

  const handleSelectAll = useCallback(() => {
    searchDispatch({
      type: SET_SECONDARY_SEARCH_ITEMS,
      secondarySearchItems: [],
    })
    setIsAllSelected(!isAllSelected)
  }, [searchDispatch, setIsAllSelected, isAllSelected])

  const inputValue = useMemo(() => {
    if (isOpen) return searchTerm
    if (secondarySearchItems.length > 1) {
      const names = secondarySearchItems.slice(0, 1).map((item) => item.format.mainText).join(', ')
      return `${names} + ${secondarySearchItems.length - 1}`
    }
    return secondarySearchItems.map((item) => item.format.mainText).join(', ')
  }, [secondarySearchItems, isOpen, searchTerm])

  const handlerClearSearchTerm = useCallback(() => setSearchTerm(''), [setSearchTerm])

  const callback = useCallback((region: App.CruiseDepartureRegion) =>
    filterFacetsByRegion(region).map(mapDeparturePort),
  [filterFacetsByRegion, mapDeparturePort])

  return <div data-testid="cruise-departure-select-mobile">
    <SearchFormField
      data-testid="cruise-departure-select-mobile-input"
      label="Departing from"
      placeholder={isOpen ? 'Search departing from' : 'All departure ports'}
      value={inputValue}
      onClick={toggleModal}
    />
    <ModalBase
      mode="drawer"
      isOpen={isOpen}
      onClose={toggleModal}
    >
      <ModalHeader
        title="Departure ports"
        onCloseButtonClick={toggleModal}
      />
      <ModalBody>
        <ModalContent>
          <Group direction="vertical" gap={16}>
            <TextInputOnly
              ref={inputSearchRef}
              noValidationSpacing
              placeholder="Search departure ports"
              value={searchTerm}
              onChange={handleSearchTerm}
              endIcon={searchTerm.length > 0 ?
                <IconButton kind="tertiary" onClick={handlerClearSearchTerm}><LineTimesIcon/></IconButton> :
                <LineSearchIcon/>
              }
            />

            <CSSBreakpoint max="mobile">
              {(!searchTerm) && <CruiseDepartureSelectListAll
                isSelected={isAllSelected}
                onClick={handleSelectAll}
              />}
            </CSSBreakpoint>

            {searchTerm && <CruiseDepartureSelectList
              inputSearchRef={inputSearchRef}
              searchTerm={searchTerm}
              secondarySearchItems={secondarySearchItems}
              isAllSelected={isAllSelected}
              selectedRegions={selectedRegions}
              onChange={handleOnChange}
              selectedRegion={regions[0]}
              setIsAllSelected={setIsAllSelected}
              setSelectedRegions={setSelectedRegions}
              isMobileMode
              fetching={fetching}
              searchDeparturePorts={searchDeparturePorts}
              setSelectedRegion={setSelectedRegion}
              regions={regions}
            />}

            {!searchTerm && regions.map((region) => <div key={region.identifier}>
              <CruiseDepartureSelectList
                topLevelRegionOnly
                inputSearchRef={inputSearchRef}
                searchTerm={searchTerm}
                secondarySearchItems={secondarySearchItems}
                isAllSelected={isAllSelected}
                selectedRegions={selectedRegions}
                onChange={handleOnChange}
                selectedRegion={region}
                setIsAllSelected={setIsAllSelected}
                setSelectedRegions={setSelectedRegions}
                isMobileMode
                fetching={fetching}
                searchDeparturePorts={callback(region)}
                setSelectedRegion={setSelectedRegion}
                regions={regions}
              />
            </div>)}
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <CruiseFilterFooter
          onApply={handleOnApply}
          onClear={onClear}
        />
      </ModalFooter>
    </ModalBase>
  </div>
}

export default CruiseDepartureSelectMobile

import config from 'constants/config'
import type { PolygonMode } from 'api/recommendations'
import { DistanceUnit, kmToMiles } from 'lib/geo/distanceUnits'
import { isBedbankOffer, isLEOffer } from 'lib/offer/offerTypes'
import { type TrendingDestination } from 'home/components/TrendingDestinations/constants'
import { buildHotelUrl } from 'home/components/TrendingDestinations/utils'
import { DESTINATION_DATA } from 'constants/recommendations'

export function geoCriteriaMetForCalendarRecommendations(offer: App.Offer | App.BedbankOffer | undefined) {
  return (isLEOffer(offer) || isBedbankOffer(offer))
}

async function waitFor(ms = 500) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function waitOnCondition(shouldWait: () => boolean, intervalMS = 250, attemptsLeft = 2): Promise<number> {
  if (attemptsLeft === 0) {
    return attemptsLeft
  }
  if (shouldWait()) {
    await waitFor(intervalMS)
    return waitOnCondition(shouldWait, intervalMS, attemptsLeft - 1)
  }
  return attemptsLeft
}

interface LocationSeededCacheKeyParams {
  destinationId: string;
  polygonMode: PolygonMode;
  nearDestinations: boolean;
  checkIn?: string;
  checkOut?: string;
  duration?: number;
  offerTypes?: Array<string>;
}

export function lereLocationSeededCacheKey({
  destinationId,
  polygonMode,
  nearDestinations,
  checkIn,
  checkOut,
  duration,
  offerTypes,
}: LocationSeededCacheKeyParams) {
  const keyParts = [
    `destinationId=${destinationId}`,
    `polygonMode=${polygonMode}`,
    `nearDestinations=${nearDestinations}`,
  ]
  if (checkIn && checkOut) {
    keyParts.push(`checkIn=${checkIn}`)
    keyParts.push(`checkOut=${checkOut}`)
  } else if (checkIn && duration !== undefined && duration > 0) {
    keyParts.push(`checkIn=${checkIn}`)
    keyParts.push(`duration=${duration}`)
  }
  if (offerTypes) {
    keyParts.push(`offer_types=${offerTypes.sort().join(',')}`)
  }
  return keyParts.join(';')
}

export const lereLocationSeededStickyFooterParameters = {
  nonLppOfferPage: {
    polygonMode: 'polygon_restricted',
    nearDestinations: true,
  },
  searchResultsPage: {
    polygonMode: 'polygon_restricted',
    nearDestinations: true,
  },
} as const

// #region Destination landing page utils

export const lereDlpParameters = {
  mainList: {
    polygonMode: 'polygon_restricted',
    nearDestinations: true,
  },
  ymal: {
    polygonMode: 'polygon_excluded',
    nearDestinations: false,
  },
} as const

export function lereDlpMainListKey(destinationId: string, offerTypes?: Array<string>) {
  return lereLocationSeededCacheKey({
    destinationId,
    polygonMode: lereDlpParameters.mainList.polygonMode,
    nearDestinations: lereDlpParameters.mainList.nearDestinations,
    offerTypes,
  })
}

export function lereDlpYmalListKey(destinationId: string, offerTypes?: Array<string>) {
  return lereLocationSeededCacheKey({
    destinationId,
    polygonMode: lereDlpParameters.ymal.polygonMode,
    nearDestinations: lereDlpParameters.ymal.nearDestinations,
    offerTypes,
  })
}

// #endregion

export function getDistanceLabel(distanceKm: number, distanceFrom: string, distanceUnitOutput: DistanceUnit): string | null {
  if (distanceKm > 20) {
    return null
  }
  if (distanceUnitOutput === 'km') {
    if (distanceKm < 0.1) {
      const meters = (distanceKm * 1000).toFixed(1)
      return `${meters.replace(/\.0$/, '')}m from ${distanceFrom}`
    }

    return `${distanceKm.toFixed(1).replace(/\.0$/, '')}km from ${distanceFrom}`
  }
  if (distanceUnitOutput === 'mi') {
    const miles = kmToMiles(distanceKm)
    return `${miles.toFixed(1).replace(/\.0$/, '')} mi from ${distanceFrom}`
  }
  return null
}

export function isTopPicksForMeEnabled() {
  return config.LERE_ENABLE_TOP_PICKS_FOR_ME
}

/**
 * Converts an array of recommended destinations into an array of trending destinations.
 * Deduplicates destinations based on destination names and filters out invalid destinations.
 *
 * @param {Array<App.RecommendationDestination>} destinations - An array of recommended destination objects.
 * @returns {Array<TrendingDestination>} An array of trending destination objects, each containing valid `name`, `imageId`, `url`, and `destinationId` properties.
 */
export function lereRecommendedDestinationsToTrendingDestinations(destinations: Array<App.RecommendationDestination>): Array<TrendingDestination> {
  const dedupedDestinations: Array<App.RecommendationDestination> = []
  const seenNames = new Set<string>()
  for (const destination of destinations) {
    const name = DESTINATION_DATA[destination.destinationId]?.name
    if (name && !seenNames.has(name)) {
      seenNames.add(name)
      dedupedDestinations.push(destination)
    }
  }

  return dedupedDestinations.map(({ destinationId }) => {
    const name = DESTINATION_DATA[destinationId]?.name
    const imageId = DESTINATION_DATA[destinationId]?.imageId
    const url = name ? buildHotelUrl(name, destinationId) : undefined
    const isValid = !!name && !!imageId && !!url

    return isValid ? ({
      name,
      imageId,
      url,
      destinationId,
    }) : undefined
  }).filter(d => !!d)
}

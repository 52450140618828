import React, { useCallback, useEffect } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import config from 'constants/config'
import { loginApple } from 'actions/AuthActions'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import Group from 'components/utils/Group'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import SolidAppleIcon from 'components/Luxkit/Icons/solid/SolidAppleIcon'
import LuxuryEscapesSmallLogo from 'components/App/Header/Logo/logos/LuxuryEscapesSmallLogo'
import { AccountAccessModalResult } from './AccountAccessModal'

const IconContainer = styled.div`
  padding: ${rem(12)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
`

interface Props {
  user: App.User;
  applePrivateEmailData: App.AppleLoginData | null;
  onModeChange: (nextMode: App.UiAccountModalMode) => void;
  setApplePrivateEmailData: (loginData: App.AppleLoginData | null) => void;
}

function AccountAccessAppleRandomEmail(props: Props) {
  const { user, onModeChange, applePrivateEmailData, setApplePrivateEmailData } = props

  const dispatch = useAppDispatch()
  const loggedIn = useAppSelector(selectLoggedIn)
  const authProcessing = useAppSelector(state => state.auth.processing)
  const modalContext = useModalElementContext<AccountAccessModalResult>()

  const onHaveAccountClick = useCallback(() => {
    setApplePrivateEmailData(null)
    onModeChange('login')
  }, [setApplePrivateEmailData, onModeChange])

  const onCreateAccountClick = useCallback(() => {
    if (applePrivateEmailData) {
      dispatch(loginApple(applePrivateEmailData))
    }
  }, [applePrivateEmailData, dispatch])

  useEffect(() => {
    if (loggedIn) {
      modalContext?.resolve({ loggedIn: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  return (
    <>
      <ModalHeader title="Create new account" dismissible={false}/>
      <ModalBody>
        <ModalContent>
          <Group direction="vertical" gap={20}>
            <Group direction="horizontal" gap={8}>
              <IconContainer><SolidAppleIcon /></IconContainer>
              {config.BRAND === 'luxuryescapes' && <IconContainer><LuxuryEscapesSmallLogo height={24} width={24} /></IconContainer>}
            </Group>
            <BodyText variant="large">You’re about to create a {config.brandConfig.name} account using a unique, random Apple email. This is because your using Apple’s ‘Hide My Email’ feature. You can update your email later in your account.</BodyText>
            <div>
              <BodyText variant="large">Your unique email:</BodyText>
              <BodyText variant="large" weight="bold">{user.email}</BodyText>
            </div>
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: 'Create account',
          onClick: onCreateAccountClick,
          disabled: authProcessing,
        }}
        secondaryActionProps={{
          children: 'Already have an account? Log in',
          onClick: onHaveAccountClick,
          disabled: authProcessing,
        }}
      />
    </>
  )
}

export default AccountAccessAppleRandomEmail

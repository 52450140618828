import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'
import BodyText from '../Typography/BodyText'

const RadioStack = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: ${rem(8)};
  }

  &.expanded > * + * {
    margin-top: ${rem(16)};
  }
`

const FieldSet = styled.fieldset`
  > legend + ${RadioStack} {
    margin-top: ${rem(16)};
  }
`

interface Props extends React.FieldsetHTMLAttributes<HTMLFieldSetElement> {
  label?: React.ReactNode;
  expanded?: boolean;
}

function RadioGroup(props: Props) {
  const { label, expanded, children, ...rest } = props

  return (
    <FieldSet {...rest}>
      {label && <BodyText variant="medium" as="legend" weight="semi-bold">{label}</BodyText>}
      <RadioStack className={cn({ expanded })}>
        {children}
      </RadioStack>
    </FieldSet>
  )
}

export default RadioGroup

import React from 'react'
import { useGlobalExperiencesSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalExperiencesSearch'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import ExperienceSearchForm from 'components/Experiences/ExperiencesSearch/ExperienceSearchForm'

function GlobalExperiencesSearchTabContent() {
  const { globalExperiencesSearchDispatch, globalExperiencesSearchState } = useGlobalExperiencesSearchContext()

  return <GlobalSearchContextProvider
    state={globalExperiencesSearchState}
    dispatch={globalExperiencesSearchDispatch}
  >
    <ExperienceSearchForm />
  </GlobalSearchContextProvider>
}

export default GlobalExperiencesSearchTabContent

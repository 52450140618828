/** This is a bundle offer search tile
 *  This tile will first check whether (1) the search performed are property search and (2) the result has been soldout
 *  Then it will render either the (1) Expansed or (2) Condensed search results.
 * */

import React from 'react'
import { connect } from 'react-redux'
import { hasPassedWalledGarden } from 'selectors/accountSelectors'
import useOffer from 'hooks/Offers/useOffer'
import ViewBundleOfferContent from './ViewBundleOfferContent'

interface Props {
  offer: App.Offer | App.OfferSummary;
  offerMetaData: App.OfferListMetaData;
  showContent: boolean;
  filters?: App.OfferListFilters;
  offerLinkIncludesFilters?: boolean;
}

function ViewBundleOffer(props: Props) {
  const {
    offer,
    filters,
    showContent,
    offerMetaData,
    offerLinkIncludesFilters,
  } = props

  const [bundleOffer, bundleOfferFetching] = useOffer<App.BundleOffer>(offerMetaData.bundleOfferId)

  if (!showContent || !bundleOffer || bundleOfferFetching) {
    return null
  }

  return (
    <ViewBundleOfferContent
      offer={offer}
      bundleOffer={bundleOffer}
      offerLinkIncludesFilters={offerLinkIncludesFilters}
      filters={filters}
    />
  )
}

ViewBundleOffer.defaultProps = {
  filters: {},
}

function mapStateToProps(state: App.State) {
  return {
    showContent: hasPassedWalledGarden(state),
  }
}

export default connect(mapStateToProps)(ViewBundleOffer)

import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchLuxLoyaltyPointsCalculation } from 'actions/LuxLoyaltyActions'
import { buildPointsKey } from 'luxLoyalty/lib/pointsCalculation'
import { getLuxLoyaltyDefaultTier } from 'luxLoyalty/selectors/utils'
import { sum } from 'lib/array/arrayUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getPointsCalculationsByKeys, shouldUseInsuranceMemberPriceForCalculation } from 'luxLoyalty/selectors/pointsCalculation'
import { PRODUCT_TYPE_INSURANCE } from 'constants/offer'

interface TotalPointsCalculation {
  totalPointsCalculation: App.LuxLoyaltyPointsCalculatorResult;
  pointsCalculations: Array<App.LuxLoyaltyPointsCalculatorResult | undefined>;
}

interface AdditionalOptions {
  skip?: boolean;
}

type CalculatorWithAdditionalOptions = App.LuxLoyaltyPointsCalculatorOptions & AdditionalOptions

function useLuxLoyaltyPointsCalculator(
  calculatorOptions: Array<CalculatorWithAdditionalOptions>,
): TotalPointsCalculation {
  const dispatch = useAppDispatch()
  const memberId = useAppSelector(state => state.auth.account.memberId)
  const accountDetails = useAppSelector(state => state.luxLoyalty.account.data)
  const defaultTier = useAppSelector(getLuxLoyaltyDefaultTier)
  const optionsToCalculate = calculatorOptions.filter(options => !options.skip)
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const shouldUseInsuranceMemberPrice = useAppSelector(shouldUseInsuranceMemberPriceForCalculation)
  const formattedOptionsToCalculate = optionsToCalculate.map((options) => formatPointsCalculatorOptions(options, luxPlusEnabled, shouldUseInsuranceMemberPrice))

  type OptionsWithPointsKey = { pointsKey: string; calculatorOptions: App.LuxLoyaltyPointsCalculatorOptions }

  const optionsToCalculateWithPointsKey = useMemo<Array<OptionsWithPointsKey>>(() => {
    return formattedOptionsToCalculate.map((options) => {
      const pointsKey = buildPointsKey({
        ...options,
        memberId: accountDetails?.tier === defaultTier ? undefined : memberId,
      })

      return {
        pointsKey,
        calculatorOptions: options,
      }
    })
  }, [formattedOptionsToCalculate, accountDetails?.tier, defaultTier, memberId])

  const pointsKeys = useMemo(() => optionsToCalculateWithPointsKey.map(({ pointsKey }) => pointsKey), [optionsToCalculateWithPointsKey])

  useEffect(() => {
    optionsToCalculateWithPointsKey.forEach(({ pointsKey, calculatorOptions }) => {
      dispatch(fetchLuxLoyaltyPointsCalculation(pointsKey, calculatorOptions))
    })
  }, [dispatch, optionsToCalculateWithPointsKey])

  const pointsCalculations = useAppSelector(state => getPointsCalculationsByKeys(state, pointsKeys))

  return useMemo(() => {
    const error = !!pointsCalculations.some(pointsCalculation => !!pointsCalculation?.error)
    const fetching = !!pointsCalculations.some(pointsCalculation => !!pointsCalculation?.fetching)

    return {
      totalPointsCalculation: {
        error,
        fetching,
        // if fetching or error for any calculation don't show points
        data: (fetching || error) ? undefined : {
          points: sum(pointsCalculations, calculation => calculation?.data?.points ?? 0),
          statusCredits: sum(pointsCalculations, calculation => calculation?.data?.statusCredits ?? 0),
        },
      },
      pointsCalculations,
    }
  }, [pointsCalculations])
}

export default useLuxLoyaltyPointsCalculator

function formatPointsCalculatorOptions(
  options: CalculatorWithAdditionalOptions,
  luxPlusEnabled: boolean,
  shouldUseInsuranceMemberPrice: boolean,
): Omit<CalculatorWithAdditionalOptions, 'memberPrice' | 'skip'> {
  if (options.productType === PRODUCT_TYPE_INSURANCE) {
    const calculationPrice = shouldUseInsuranceMemberPrice && !!options.memberPrice ? options.memberPrice : options.price

    return {
      price: calculationPrice,
      productType: options.productType,
      margin: options.margin,
    }
  }

  const { skip, memberPrice, ...restOptions } = options
  const calculationPrice = luxPlusEnabled && !!memberPrice ? memberPrice : restOptions.price

  return {
    ...restOptions,
    price: calculationPrice,
  }
}

import luxuryEscapesTheme, { luxuryEscapesBorderRadii, luxuryEscapesDefaultPalette, luxuryEscapesInversePalette } from './luxuryescapes'
import { baseThemeShadows } from './shadows'

export const leAgenthubDefaultPalette: App.ThemePalette<string> = {
  ...luxuryEscapesDefaultPalette,
}

export const leAgenthubInversePalette: App.ThemePalette<string> = {
  ...luxuryEscapesInversePalette,
}

export const leAgentHubShadows: App.ThemeShadows<string> = { ...baseThemeShadows }
export const leAgentHubBorderRadii: App.ThemeBorderRadii<string> = { ...luxuryEscapesBorderRadii }

const leAgenthubTheme: App.Theme = {
  ...luxuryEscapesTheme,
}

export default leAgenthubTheme

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFileAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9 10h1a1 1 0 1 0 0-2H9a1 1 0 0 0 0 2Zm0 2a1.0002 1.0002 0 0 0-.7071 1.7071C8.4804 13.8946 8.7348 14 9 14h6a1.0001 1.0001 0 0 0 0-2H9Zm11-3.06a1.307 1.307 0 0 0-.06-.27v-.09a1.0702 1.0702 0 0 0-.19-.28l-6-6a1.071 1.071 0 0 0-.28-.19.32.32 0 0 0-.09 0 .8801.8801 0 0 0-.33-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.94Zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1V5.41ZM18 19a1.0001 1.0001 0 0 1-1 1H7a1.0002 1.0002 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v9Zm-3-3H9a1.0002 1.0002 0 0 0-.7071 1.7071C8.4804 17.8946 8.7348 18 9 18h6a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineFileAltIcon

import { WhiteLabelAppConfig, getWhiteLabelAppConfig } from 'lib/whitelabels/whitelabels'
import { useEffect, useState } from 'react'

function useWhiteLabelAppConfig() {
  const [appConfig, setAppConfig] = useState<WhiteLabelAppConfig>({ isApp: false })

  useEffect(() => {
    setAppConfig(getWhiteLabelAppConfig())
  }, [])

  return appConfig
}

export default useWhiteLabelAppConfig

import { UAEvent } from 'api/googleTagManager'
import { TripItemsSortOptions } from 'tripPlanner/components/Drawer/SavedItemsList'

import { FilterAnalyticsLabels } from 'tripPlanner/models'
import { MyTripsFilterOptions, MyTripsSortOptions } from 'tripPlanner/pages/myTrips/MyTripsListGrid'

export const customThingToDoItemTypeToAnalyticsValue = {
  RESTAURANT_BAR: 'restaurants_and_bars',
  ATTRACTION: 'attractions',
  EVENT: 'events',
  THING_TO_DO: 'other',
} as const

type AddToTripClickableElement =
  | 'modal_accommodation'
  | 'accommodation_search'
  | 'modal_accommodation_custom'
  | 'suggestion_accommodation'
  | 'suggestion_accommodation_custom'
  | 'suggestion_accommodation_filtered'
  | 'suggestion_accommodation_custom_filtered'
  | 'modal_flights'
  | 'modal_flights_search'
  | 'modal_flights_custom'
  | 'modal_flights_custom_manual'
  | 'modal_flights_custom_lookup'
  | 'suggestion_flights'
  | 'suggestion_flights_custom'
  | 'suggestion_flights_filtered'
  | 'suggestion_flights_custom_filtered'
  | 'modal_experiences'
  | 'modal_tours'
  | 'modal_cruises'
  | 'modal_car_rental'
  | 'modal_transport'
  | 'modal_notes'
  | 'modal_le_booking'
  | 'experiences_search'
  | 'suggestion_experience'
  | 'suggestion_experience_filtered'
  | 'suggestion_experience_custom_restaurants_and_bars'
  | 'suggestion_experience_custom_restaurants_and_bars_filtered'
  | 'suggestion_experience_custom_activites'
  | 'suggestion_experience_custom_activites_filtered'
  | 'suggestion_experience_custom_attractions'
  | 'suggestion_experience_custom_attractions_filtered'
  | 'suggestion_experience_custom_events'
  | 'suggestion_experience_custom_events_filtered'
  | 'suggestion_experience_custom_other'
  | 'suggestion_experience_custom_other_filtered'
  | 'button'

export function addToTripClickEvent(element: AddToTripClickableElement, label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `add_to_trip_${element}_click`,
    label,
  }
}

type recommendationClickableElement = 'flight_recommendation_search' | 'flight_recommendation_custom'

export function recommendationClickEvent(element: recommendationClickableElement, label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `${element}_click`,
    label,
  }
}

export type AddToTripSavableElement =
  | 'accommodation_custom'
  | 'flights_custom_manual'
  | 'flights_custom_lookup'
  | 'experience_custom_restaurants_and_bars'
  | 'experience_custom_activities'
  | 'experience_custom_attractions'
  | 'experience_custom_events'
  | 'experience_custom_other'

export function addToTripSaveEvent(element: AddToTripSavableElement, label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `add_to_trip_${element}_save`,
    label,
  }
}

export function addToTripSaveErrorEvent(element: AddToTripSavableElement, label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `add_to_trip_${element}_save_error`,
    label,
  }
}

export function tripPlannerItemFilterTabClick(filterType: App.TripPlannerSubFilterType | 'all'): UAEvent {
  return {
    category: 'trip_planner',
    action: 'trip_item_filter_tab_click',
    label: filterType === 'all' ? 'all' : FilterAnalyticsLabels[filterType],
  }
}

type SaveToTripElement = 'button' | 'modal' | 'modal_existing_trip' | 'modal_new_trip' | 'modal_new_trip_name' | 'modal_first_trip_name'
type SaveToTripAction = 'click' | 'dismiss' | 'save'
type SaveToTripContext = 'global' | 'immersive'

export function saveToTripEvent(
  element: SaveToTripElement,
  context: SaveToTripContext,
  action: SaveToTripAction,
  label?: string,
): UAEvent {
  return {
    category: 'trip_planner',
    action: `save_to_trip_${context}_${element}_${action}`,
    label,
  }
}

export function tripSavedSnackbarEvent(eventType: 'view' | 'click', snackbarAction: 'view' | 'change' | 'no_action'): UAEvent {
  return {
    category: 'trip_planner',
    action: `save_success_snackbar_${eventType}`,
    label: snackbarAction,
  }
}

type CreateTripElement = 'modal_first_trip_name' | 'modal_location' | 'modal_date' | 'modal_adults' | 'modal_children' | 'modal_additional_info'
type CreateTripAction = 'click' | 'dismiss' | 'select' | 'save' | 'remove' | 'search'
type CreateTripContext = 'global' | 'immersive'

export function createTripEvent(
  element: CreateTripElement,
  context: CreateTripContext,
  action: CreateTripAction,
  isFirstTrip: boolean,
  isInBookmarkingFlow: boolean,
): UAEvent {
  return {
    category: 'trip_planner',
    action: `create_trip_${context}_${element}_${action}`,
    label: isFirstTrip ? 'first_trip' : 'not_first_trip',
    value: isInBookmarkingFlow ? 1 : 0,
  }
}

export function createTripSaveEvent(
  tripName: string,
  isInBookmarkingFlow: boolean,
): UAEvent {
  return {
    category: 'trip_planner',
    action: 'create_trip_global_modal_first_trip_name_save',
    label: tripName,
    value: isInBookmarkingFlow ? 1 : 0,
  }
}

type PostBookingButton = 'add_to_trip' | 'new_trip'

export function postBookingEvent(button: PostBookingButton, label: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `post_booking_${button}_click`,
    label,
  }
}

type ImpressionType = 'flight_recommendation' | 'trip_view'

export function tripImpressionEvent(impression_type: ImpressionType, label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `${impression_type}_impression`,
    label,
  }
}

export function printTripClickEvent(): UAEvent {
  return {
    category: 'trip_planner',
    action: 'print_trip_button_click',
  }
}

export function shareTripClickEvent(label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: 'share_trip_button_click',
    label,
  }
}

type ShareTripModalAction = 'send' | 'send_error' | 'copy_share_link' | 'dismiss'

export function shareTripModalEvents(action: ShareTripModalAction, label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `share_trip_modal_${action}`,
    label,
  }
}

type TripOnboardingAction = 'continue_click' | 'dismiss' | 'done_click'

export function TripOnboardingEvents(action: TripOnboardingAction, step: number, label?: string): UAEvent {
  return {
    category: 'trip_planner',
    action: `onboarding_screen_${step}_${action}`,
    label,
  }
}

export function tripOnboardingImpressionEvent(): UAEvent {
  return {
    category: 'trip_planner',
    action: 'onboarding_screen_1_impression',
  }
}

export function tripNavigationMenuItemClickEvent(): UAEvent {
  return {
    category: 'trip_planner',
    action: 'trip_drawer_header_click',
  }
}

export type TripPlansSource = 'trip_drawer' | 'trip_plans'

export function tripPlansSortOptionSelected(sortOption: MyTripsSortOptions, source: TripPlansSource): UAEvent {
  const label = sortOption.toLowerCase().replace(' ', '_')
  return {
    category: 'trip_planner',
    action: `${source}_trip_plans_sort_click`,
    label,
  }
}

export function tripPlansFilterOptionSelected(filterOption: MyTripsFilterOptions | null, source: TripPlansSource): UAEvent {
  let label = 'all'
  if (filterOption) {
    label = filterOption.toLowerCase().replace(' ', '_')
  }
  return {
    category: 'trip_planner',
    action: `${source}_trip_plans_filter_click`,
    label,
  }
}

export function tripPlansKebabClicked(source: TripPlansSource): UAEvent {
  return {
    category: 'trip_planner',
    action: `${source}_trip_plans_kebab_click`,
  }
}

export function tripPlansTripClicked(source: TripPlansSource): UAEvent {
  return {
    category: 'trip_planner',
    action: `${source}_trip_plans_trip_click`,
  }
}

export function tripPlansCreateNewTripClicked(source: TripPlansSource): UAEvent {
  return {
    category: 'trip_planner',
    action: `${source}_trip_plans_new_trip_click`,
  }
}

export function tripDrawerTripPlansView(): UAEvent {
  return {
    category: 'trip_planner',
    action: 'trip_drawer_trip_plans_view',
  }
}

export function tripDrawerTripAllSavedItemsView(): UAEvent {
  return {
    category: 'trip_planner',
    action: 'trip_drawer_all_saved_items_view',
  }
}

export function tripDrawerSavedItemsListSortOptionSelected(option: TripItemsSortOptions): UAEvent {
  const label = option.toLowerCase().replace(' ', '_')
  return {
    category: 'trip_planner',
    action: 'trip_drawer_all_saved_items_sort_click',
    label,
  }
}

export type TripSavedItemsSource = 'trip_drawer_all_saved_items' | 'trip_summary'

export function tripDrawerSavedItemsListFilterOptionSelected(filters: Array<App.TripPlannerSubFilterType>): UAEvent {
  let label = 'all'
  if (filters.length > 0) {
    label = filters.map((filter) => filter.toLowerCase().replace(' ', '_')).join(',')
  }
  return {
    category: 'trip_planner',
    action: 'trip_drawer_all_saved_items_filter_click',
    label,
  }
}

export function tripDrawerSavedItemsListTripClicked(): UAEvent {
  return {
    category: 'trip_planner',
    action: 'trip_drawer_all_saved_items_view_trip_click',
  }
}

export function tripBookingDetailsButtonClicked(source: TripSavedItemsSource) {
  return {
    category: 'trip_planner',
    action: `${source}_booking_details_click`,
  }
}

export function tripSavedItemOfferDetailsClicked(source: TripSavedItemsSource) {
  return {
    category: 'trip_planner',
    action: `${source}_offer_details_click`,
  }
}

export function tripSavedItemBookNowClicked(source: TripSavedItemsSource) {
  return {
    category: 'trip_planner',
    action: `${source}_book_now_click`,
  }
}

type MenuItemType = 'edit_details' | 'change_image' | 'view_hints' | 'print' | 'share' | 'delete'

export function tripMenuItemClicked(item: MenuItemType): UAEvent {
  return {
    category: 'trip_planner',
    action: `trip_settings_menu_${item}_click`,
  }
}

export type TripLoginSource =
  | 'save'
  | 'save_room'
  | 'saved_drawer'
  | 'save_curated_trip'
  | 'save_curated_trip_item'
  | 'create_trip'
  | 'public_trip_sign_in'
  | 'save_public_trip'
  | 'save_public_trip_item'

export function tripLoginModalSignUpView(source: TripLoginSource): UAEvent {
  return {
    category: 'trip_planner',
    action: 'save_and_sign_up_modal_view',
    label: source,
  }
}

export function tripLoginModalSignUpDismiss(source: TripLoginSource): UAEvent {
  return {
    category: 'trip_planner',
    action: 'save_and_sign_up_modal_dismiss',
    label: source,
  }
}

export function tripSettingsButtonClicked(settingType: 'destinations' | 'travellers' | 'departure' | 'dates' | 'menu'): UAEvent {
  return {
    category: 'trip_planner',
    action: `trip_settings_${settingType}_click`,
  }
}

export function homepageTripModuleBasicEvent(eventType: 'impression' | 'change_trip' | 'all_trips' | 'tile_click' | 'open_menu' | 'dismiss'): UAEvent {
  return {
    category: 'trip_planner',
    action: `homepage_offers_${eventType}`,
  }
}

export function homepageTripModuleViewTripClick(label: 'primary' | 'secondary'): UAEvent {
  return {
    category: 'trip_planner',
    action: 'homepage_offers_view_trip',
    label,
  }
}

export function homepageTripModuleFilterClick(filters: Array<App.TripPlannerSubFilterType>): UAEvent {
  let label = 'all'
  if (filters.length > 0) {
    label = filters.map((filter) => FilterAnalyticsLabels[filter]).join(',')
  }
  return {
    category: 'trip_planner',
    action: 'homepage_offers_view_trip',
    label,
  }
}

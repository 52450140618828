import moment from 'moment'

import { getAirportsSearch } from 'api/flights'
import config from 'constants/config'
import {
  ISO_DATE_FORMAT,
  SHORT_TIME_FORMAT_24_HOUR,
} from 'constants/dateFormats'
import memoize from 'lib/memoize/memoize'
import offerPageURL from 'lib/offer/offerPageURL'
import { CreateTripItemRequest } from 'tripPlanner/api/tripItem/types'
import {
  AttractionItem,
  BookmarkDatesAndOccupancy,
  EventItem,
  RestaurantBarItem,
  ThingToDoItem,
} from 'tripPlanner/types/tripItem'
import { convertOfferPropertyGeoDataToRegion } from 'tripPlanner/utils'

export type CreateTourRequestBody = Extract<
  CreateTripItemRequest,
  { type: 'TOUR' }
>

export type CreateCruiseRequestBody = Extract<
  CreateTripItemRequest,
  {
    type: 'CRUISE'
  }
>

export type CreateAccommodationRequestBody = Extract<
  CreateTripItemRequest,
  { type: 'ACCOMMODATION' }
>

export type CreateAccommodationRentalRequestBody = Extract<
  CreateTripItemRequest,
  { type: 'ACCOMMODATION_RENTAL' }
>

export type CreateExperienceRequestBody = Extract<
  CreateTripItemRequest,
  { type: 'EXPERIENCE' }
>

export type CreateFlightRequestBody = Extract<
  CreateTripItemRequest,
  { type: 'FLIGHT' }
>

export type CreateAttractionRequestBody = Extract<
  CreateTripItemRequest,
  { type: 'ATTRACTION' }
>

export type CreateRestaurantBarRequestBody = Extract<
  CreateTripItemRequest,
  { type: 'RESTAURANT_BAR' }
>

export const getPlaceFromOffer = (
  offer: App.Offer | App.OfferSummary,
): CreateAccommodationRequestBody['place'] => {
  if (offer.property) {
    const { name, latitude, longitude, geoData, address } = offer.property
    let region = ''
    if (geoData) {
      region = convertOfferPropertyGeoDataToRegion(geoData)
    }

    return {
      type: 'GEO',
      id: geoData.placeId ?? undefined,
      name,
      address,
      point: {
        lat: latitude,
        lng: longitude,
      },
      region,
    }
  }

  return {
    type: 'CUSTOM',
    name: offer.name,
    address: offer.location,
  }
}

export function formatBedbankCheckInOutTime(
  timeString: string | undefined,
): string | undefined {
  const isValidTimeString = moment(timeString, 'h:mm a').isValid()

  return isValidTimeString ?
    moment(timeString, 'h:mm a').format(SHORT_TIME_FORMAT_24_HOUR) :
    undefined
}

export const formatDateForPayload = (
  dateMoment: '' | moment.Moment | undefined,
): string | undefined => {
  if (dateMoment && dateMoment.isValid()) {
    return dateMoment.format(ISO_DATE_FORMAT)
  }
}

export const formatTimeForPayload = (
  timeMoment: '' | moment.Moment | undefined,
): string | undefined => {
  if (timeMoment && timeMoment.isValid()) {
    return timeMoment.format(SHORT_TIME_FORMAT_24_HOUR)
  }
}

export function getLeHotelCheckInAndOutTimes(
  offer: App.Offer | App.OfferSummary | undefined,
) {
  if (!offer) return []
  let startTime: string | undefined
  let endTime: string | undefined

  if ('facilities' in offer) {
    const checkInIndex = offer.facilities.indexOf('Check-in: ')
    const checkOutIndex = offer.facilities.indexOf('Checkout: ')

    if (checkInIndex && checkOutIndex) {
      startTime = checkInIndex ?
        offer?.facilities.slice(checkInIndex)?.split(' ')?.at(1) :
        undefined

      endTime = checkOutIndex ?
        offer?.facilities.slice(checkOutIndex)?.split(' ')?.at(1) :
        undefined
    }
  }
  const startTimeMoment = startTime ?
    moment(startTime, ['H', 'ha', 'HH:mm', 'h:mm a']) :
    undefined
  const endTimeMoment = endTime ?
    moment(endTime, ['H', 'ha', 'HH:mm', 'h:mm a']) :
    undefined

  return [startTimeMoment, endTimeMoment]
}

export const createTourV2Payload = (
  order: App.Order,
  orderItem: Tours.TourV2OrderItem,
  offer: Tours.TourV2Offer,
  currentRegionCode: string,
  orderItemIds: Array<string>,
  snapshot?: Tours.TourV2OfferSnapshot,
): CreateTourRequestBody | CreateCruiseRequestBody => {
  const orderId = order.id
  const baseUrl = `${window.location.protocol}//${window.location.hostname}/${currentRegionCode}`
  const confirmationUrl = baseUrl + `/booking/confirmation?orderId=${orderId}`
  const offerUrl = baseUrl + offerPageURL(offer)
  const imageId = offer?.image?.id ?? snapshot?.imageId

  // ToDo: We don't have appropriate addresses here probably check with Tour's team on how to fetch the Region.
  const payload: CreateTourRequestBody = {
    type: 'TOUR',
    name: orderItem.tour.tourOptionTitle,
    isBooked: true,
    sourceType: 'LE_Tour',
    startDate: orderItem.tour.startDate,
    endDate: orderItem.tour.endDate,
    startPlace: {
      type: 'CUSTOM',
      name: orderItem.tour.startLocation,
      photoUrl: imageId ?
        `${config.IMAGE_HOST}/q_auto:eco,c_fill,g_auto,w_520,ar_16:9/${imageId}.jpeg` :
        undefined,
    },
    endPlace: {
      type: 'CUSTOM',
      name: orderItem.tour.endLocation,
      photoUrl: imageId ?
        `${config.IMAGE_HOST}/q_auto:eco,c_fill,g_auto,w_520,ar_16:9/${imageId}.jpeg` :
        undefined,
    },
    confirmationCode: order.id,
    confirmationItemCodes: orderItemIds,
    price: Number(orderItem.total),
    confirmationUrl,
    url: offerUrl,
    code: offer.id,
  }

  return payload
}

const getCheckOutDate = ({
  checkInDate,
  checkOutDate,
  durationInDays,
}: BookmarkDatesAndOccupancy): moment.Moment | undefined => {
  if (checkOutDate) {
    return checkOutDate
  }
  if (checkInDate && durationInDays) {
    return checkInDate.add(durationInDays, 'days')
  }
  return undefined
}

export const getCheckInCheckOutOccupancy = (
  datesAndGuests: BookmarkDatesAndOccupancy,
): {
  checkIn?: string
  checkOut?: string
  occupancies?: Array<App.Occupants>
} => {
  const checkIn = datesAndGuests.checkInDate?.format(ISO_DATE_FORMAT)
  const checkOut = getCheckOutDate(datesAndGuests)?.format(ISO_DATE_FORMAT)
  return {
    checkIn,
    checkOut,
    occupancies: datesAndGuests.occupancies,
  }
}

export const getBedbankCheckInOutTime = (
  finePrint?: App.BedbankPropertyFinePrint,
) => {
  const checkInTime = formatBedbankCheckInOutTime(finePrint?.checkIn?.beginTime)
  const checkOutTime = formatBedbankCheckInOutTime(finePrint?.checkOut?.time)
  return [checkInTime, checkOutTime]
}

async function fetchAirportLocation(
  code: string,
): Promise<App.AirportLocation | undefined> {
  try {
    const data = await getAirportsSearch(code)
    return data.find((d: App.AirportLocation) => d.airportCode === code)
  } catch {
    return undefined
  }
}

export const getAirportLocation = memoize(fetchAirportLocation)

export const getDisplayableCustomThingToDoName = (
  item: ThingToDoItem | RestaurantBarItem | AttractionItem | EventItem,
) => {
  if (item.name) {
    return item.name
  } else if ('place' in item) {
    return item.place.name
  }

  return item.startPlace.name
}

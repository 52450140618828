import { components, PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import { Tour as TourAPI } from '@luxuryescapes/contract-svc-tour'
import { mapObject } from 'lib/object/objectUtils'
import {
  BRAND_SIGNATURE_SERIES,
  TOUR_V2_LE_SOURCE,
  TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD,
  TOUR_V2_PACKAGE_OPTION_BASE,
} from 'constants/tours'
import { arrayToObject, groupBy, nonNullable, sortBy, unique } from 'lib/array/arrayUtils'
import { getPackageOptionType } from 'lib/tours/tourUtils'
import { addMinuteToTimeStr, convertTwelveHourTimeToTimeStr, formatMinutesToHoursMinutes } from 'lib/datetime/time'
import { CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE } from 'constants/checkout'
import uuidV4 from 'lib/string/uuidV4Utils'
import config from 'constants/config'
import { dateDifference, addDays, startOfDay } from 'lib/datetime/dateUtils'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'
import { getTourV2PlacesVisited } from 'lib/tours/extractTourV2VariationDetails'

// pick only the date portion of the date string (no time + timezone)
export function extractAbsoluteDate(dateString: string) {
  return dateString.substring(0, 10)
}

export function mapTourV2OfferType(
  source: string,
  brand: string,
): Tours.V2OfferType {
  if (source === TOUR_V2_LE_SOURCE) {
    if (brand === 'luxuryescapes') {
      return 'direct_tour'
    }
    return 'partner_tour'
  } else {
    return 'connection_tour'
  }
}

export function mapTourV2ProductType(
  offer: PublicOfferV2.TourV2Offer | PublicOfferV2.TourV2OfferSummary,
): Tours.V2ProductType {
  switch (offer.tourStyle) {
    case 'Classic':
      return 'classic_tour'
    case 'Deluxe':
      return 'deluxe_tour'
    case 'Ultra Lux':
      return 'ultra_lux_tour'
    case 'Signature Series':
      return 'signature_series_tour'
    default:
      return offer.productType
  }
}

function getLabelType(tag: string): App.UrgencyLabelType {
  if (tag.endsWith('ends in')) {
    return 'left'
  }
  return tag.toLowerCase() as App.UrgencyLabelType
}

function mapUrgencyTags(urgencyTags: Array<string>): Array<App.OfferUrgencyLabel> {
  // While this does nothing now, there are times we need to manaully add urgency tags to the
  // offer - such as the Best of the Decade 2023 campaign. Keep this method around to support
  // future extra mappings
  const tourTags = urgencyTags?.map(tag => ({
    type: getLabelType(tag),
    message: tag,
  })) ?? []
  return tourTags
}

function mapTourV2Schedule(schedule: PublicOfferV2.TourV2Offer['schedule']): Tours.TourOfferSchedule | undefined {
  if (!schedule) {
    return undefined
  }

  return {
    earlyAccess: schedule.earlyAccess,
    start: schedule.start,
    end: schedule.end,
  }
}

const baseOfferCapacity: App.RoomCapacity = { adults: 4, children: 3, infants: 3, total: 4 }
const privateOfferCapacity: App.RoomCapacity = { adults: 3, children: 2, infants: 2, total: 3 }
const leOfferCapacity: App.RoomCapacity = { adults: 2, children: 0, infants: 0, total: 2 }

function getOfferRoomCapacity(serverOffer: PublicOfferV2.TourV2Offer | PublicOfferV2.TourV2OfferSummary): Array<App.RoomCapacity> {
  const privateRequestKey = extractTourV2DeparturePrivateRequestKey(serverOffer.departures) ?? undefined
  if (privateRequestKey) {
    return [privateOfferCapacity]
  } else if (serverOffer.source === TOUR_V2_LE_SOURCE) {
    return [leOfferCapacity]
  }
  return [baseOfferCapacity]
}

export function tourV2OfferMap(
  offer: PublicOfferV2.TourV2Offer,
): Tours.TourV2Offer {
  const privateRequestKey = extractTourV2DeparturePrivateRequestKey(offer.departures) ?? undefined
  const isLeTour = offer.source === TOUR_V2_LE_SOURCE
  const optionsByDepartureId = groupBy(offer.options, option => option.fkDepartureId)

  const departures = mapObject(offer.departures, (departure, departureId) => tourV2DepartureMap(
    departure,
    departureId as string,
    offer.tourOptions[departure.fkTourOptionId],
    optionsByDepartureId.get(departureId as string) ?? [],
  ))

  const mappedOffer: Tours.TourV2Offer = {
    analyticsType: 'tourV2',
    productType: mapTourV2ProductType(offer),
    brand: tourV2BrandMap(offer.brandObject),
    departures,
    finePrint: tourV2FinePrintMap(offer.finePrint),
    id: offer.id,
    name: offer.name,
    parentType: 'tour_v2',
    purchasableOptions: offer.options.map(option => tourV2PurchasableOptionMap(option, offer.tourOptions[option.fkTourOptionId])),
    roomTypePricing: mapObject(offer.roomTypePricing, tourV2RoomTypePriceMap),
    purchasableExperienceOptions: arrayToObject(
      nonNullable(offer.optionalExperiences.map(experience => {
        return tourV2PurchasableExperienceOptionMap(experience, offer.id)
      })),
      experience => experience.fkExperienceId!,
    ),
    saleUnit: 'room',
    slug: offer.slug,
    type: mapTourV2OfferType(offer.source, offer.brand),
    variations: mapTourV2OfferVariations(offer.tourOptions, departures, offer.hostsDefaultImage ?? undefined),
    urgencyTags: mapUrgencyTags(offer.urgencyTags),
    showPricePerDay: offer.showPricePerDay,
    activityLevel: offer.activityLevel ?? undefined,
    privateRequestKey,
    source: offer.source,
    isLeTour,
    recommendedHotelAddOns: offer.recommendedHotelAddOns ?? undefined,
    video: offer.video ?? undefined,
    sponsors: offer.sponsors ?? [],
    hosts: offer.hosts ?? [],
    depositThresholds: {
      numberOfDays: offer.depositNumberOfDueDays ?? TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD,
      percentage: tourV2DepositAmountMap(offer.depositAmount ?? 0, offer.depositType),
    },
    operatedBy: offer.operatedBy,
    guideType: offer.guideType ?? undefined,
    luxPlus: {
      access: offer.luxPlus.access,
      hasMemberPrices: offer.options.some(option => !!option.memberPrice),
    },
    isAgentHubExclusive: config.agentHub.isEnabled && Boolean(offer.isAgentHubExclusive),
    schedule: mapTourV2Schedule(offer.schedule),
    roomCapacity: getOfferRoomCapacity(offer),
    paymentScheduleInfo: {
      templateId: offer.paymentScheduleTemplateId,
      supplierPaymentNumberOfDays: offer.supplierDueDays,
    },
    isSignatureSeries: BRAND_SIGNATURE_SERIES === offer.brand,
  }

  if (offer.reviewsRating) {
    mappedOffer.rating = {
      score: offer.reviewsRating,
      reviewsTotal: offer.reviewsTotal ?? 0,
      reviewsSource: offer.reviewsSource ?? 'luxuryescapes',
    }
  }

  if (typeof offer.depositAmount !== 'undefined' && typeof offer.depositType !== 'undefined') {
    mappedOffer.depositAmount = tourV2DepositAmountMap(offer.depositAmount ?? 0, offer.depositType)
    mappedOffer.depositType = offer.depositType
  }

  if (offer.faq) {
    mappedOffer.faq = offer.faq.map(tourV2FAQMap)
  }

  return mappedOffer
}

export function tourV2OfferSummaryMap(
  offer: PublicOfferV2.TourV2OfferSummary,
): Tours.TourV2OfferSummary {
  const optionsByDepartureId = groupBy(offer.options, option => option.fkDepartureId)
  const departures = mapObject(offer.departures, (departure, departureId) => tourV2DepartureMap(
    departure,
    departureId as string,
    offer.tourOptions[departure.fkTourOptionId],
    optionsByDepartureId.get(departureId as string) ?? [],
  ))

  const mapped: Tours.TourV2OfferSummary = {
    analyticsType: 'tourV2',
    productType: mapTourV2ProductType(offer),
    allVariationsCount: offer.tourOptionCount,
    brand: tourV2BrandMap(offer.brandObject),
    departures,
    id: offer.id,
    summaryId: `${offer.id}__${Object.keys(offer.tourOptions)[0]}`,
    name: offer.name,
    parentType: 'tour_v2',
    purchasableOptions: offer.options.map(option => tourV2PurchasableOptionMap(option, offer.tourOptions[option.fkTourOptionId])),
    roomTypePricing: mapObject(offer.roomTypePricing, tourV2RoomTypePriceMap),
    purchasableExperienceOptions: arrayToObject(
      nonNullable(offer.optionalExperiences.map(experience => {
        return tourV2PurchasableExperienceOptionMap(experience, offer.id)
      })),
      experience => experience.fkExperienceId!,
    ),
    saleUnit: 'room',
    slug: offer.slug,
    type: mapTourV2OfferType(offer.source, offer.brand),
    variations: mapTourV2OfferVariations(offer.tourOptions, departures),
    urgencyTags: mapUrgencyTags(offer.urgencyTags),
    showPricePerDay: offer.showPricePerDay,
    depositType: offer.depositType,
    depositAmount: tourV2DepositAmountMap(offer.depositAmount ?? 0, offer.depositType),
    source: offer.source,
    isLeTour: (offer.source === TOUR_V2_LE_SOURCE),
    recommendedHotelAddOns: offer.recommendedHotelAddOns ?? undefined,
    sponsors: offer.sponsors ?? [],
    hosts: offer.hosts ?? [],
    depositThresholds: {
      numberOfDays: offer.depositNumberOfDueDays ?? TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD,
      percentage: tourV2DepositAmountMap(offer.depositAmount ?? 0, offer.depositType),
    },
    operatedBy: offer.operatedBy,
    guideType: offer.guideType ?? undefined,
    luxPlus: {
      access: offer.luxPlus.access,
      hasMemberPrices: offer.options.some(option => !!option.memberPrice),
    },
    isAgentHubExclusive: config.agentHub.isEnabled && Boolean(offer.isAgentHubExclusive),
    schedule: mapTourV2Schedule(offer.schedule),
    roomCapacity: getOfferRoomCapacity(offer),
    isSignatureSeries: BRAND_SIGNATURE_SERIES === offer.brand,
  }

  if (offer.reviewsRating) {
    mapped.rating = {
      score: offer.reviewsRating,
      reviewsTotal: offer.reviewsTotal,
      reviewsSource: offer.reviewsSource ?? 'luxuryescapes',
    }
  }

  return mapped
}

export function tourV2BrandMap(
  brandObject: PublicOfferV2.Brand,
): Tours.TourV2OfferBrand {
  const {
    code,
    name,
    svcLogoId,
  } = brandObject

  const brand: Tours.TourV2OfferBrand = {
    code,
    name,
  }

  if (svcLogoId) {
    brand.logoImage = { id: svcLogoId, title: `${name} logo` }
  }

  return brand
}

export function tourV2DepartureMap(
  departure: PublicOfferV2.Departure,
  departureId: string,
  tourOption: PublicOfferV2.TourOption,
  options: Array<PublicOfferV2.PurchasableOption>,
): Tours.TourV2OfferDeparture {
  const startDate = extractAbsoluteDate(departure.startDate)

  const mappedDeparture: Tours.TourV2OfferDeparture = {
    endDate: extractAbsoluteDate(departure.endDate),
    fkSeasonId: departure.fkSeasonId,
    fkVariationId: getTourV2VariationId(tourOption),
    id: departureId,
    isGuaranteed: departure.definiteDeparture,
    startDate,
    month: new Date(startDate).getMonth(),
    year: new Date(startDate).getFullYear(),
    status: departure.availability.status,
    urgencyTags: mapUrgencyTags(departure.urgencyTags),
    privateRequestKey: departure.privateRequestKey,
    duration: dateDifference(new Date(departure.endDate), new Date(departure.startDate)).days,
    options: departure.availability.status === 'available' ? options.map(option => tourV2PurchasableOptionMap(option, tourOption)) : [],
    currencyCode: departure.currencyCode ?? undefined,
    startTimeLocal: departure.startTimeLocal ?? undefined,
    endTimeLocal: departure.endTimeLocal ?? undefined,
    groupSize: departure.groupSize,
    // below will be changed shortly to get the handback date from PO and svc-tour
    handbackDate: addDays(new Date(departure.startDate), -60).toDateString(),
  }
  return mappedDeparture
}

function tourV2FinePrintSectionMap(
  finePrintItem: TourAPI.FinePrintItem,
): Tours.V2OfferFinePrintSection {
  return {
    category: finePrintItem.category,
    title: finePrintItem.title,
    body: finePrintItem.body,
  }
}

export function tourV2FinePrintMap(
  finePrint: TourAPI.FinePrint,
): Tours.TourV2OfferFinePrint {
  return {
    sections: finePrint.items?.map(tourV2FinePrintSectionMap) ?? [],
  }
}

export function tourV2PurchasableOptionMap(
  purchasableOption: PublicOfferV2.PurchasableOption,
  tourOption: PublicOfferV2.TourOption,
): Tours.TourV2OfferPurchasableOption {
  const mappedPurchasableOption: Tours.TourV2OfferPurchasableOption = {
    fkDepartureId: purchasableOption.fkDepartureId,
    fkRoomTypePricingId: purchasableOption.fkRoomTypePricingId,
    fkSeasonId: purchasableOption.fkSeasonId,
    fkTourId: purchasableOption.fkTourId,
    fkVariationId: getTourV2VariationId(tourOption),
    fullPrice: purchasableOption.fullPrice,
    memberPrice: Number(purchasableOption.memberPrice),
    price: purchasableOption.price,
    roomType: purchasableOption.roomType as Tours.V2OfferRoomType, // TODO: this could be a type mapper. needs fallback
    packageType: getPackageOptionType(purchasableOption.roomType as Tours.V2OfferRoomType),
    // a nullable inventory left is the equiv of unlimited
    inventoryLeft: purchasableOption.inventoryLeft ?? 999,
  }

  if (purchasableOption.valuedAtPrice) {
    mappedPurchasableOption.valuedAt = purchasableOption.valuedAtPrice
  }

  return mappedPurchasableOption
}

export function tourV2PurchasableExperienceOptionMap(
  purchasableExperienceOption: components['schemas']['v2TourOptionalExperience'],
  tourId: string,
): Partial<Tours.TourV2OfferPurchasableOption> | null {
  if (purchasableExperienceOption.price === null) return null
  return {
    fkExperienceId: purchasableExperienceOption.id,
    fkTourId: tourId,
    fullPrice: purchasableExperienceOption.price,
    price: purchasableExperienceOption.price,
    timeSlots: tourV2TourExperienceTimeSlotMap(purchasableExperienceOption.timeSlots, purchasableExperienceOption.duration),
    dayNumbers: purchasableExperienceOption.dayNumbers,
    duration: purchasableExperienceOption.duration,
    name: purchasableExperienceOption.name,
    description: purchasableExperienceOption.description,
  }
}

export function tourV2RoomTypePriceMap(
  roomTypePrice: PublicOfferV2.RoomTypePrice,
): Tours.TourV2OfferRoomTypePrice {
  const mappedRoomTypePrice: Tours.TourV2OfferRoomTypePrice = {
    id: roomTypePrice.id,
    maxChildDiscounts: roomTypePrice.maxChildDiscounts || 0,
  }

  if (roomTypePrice.childPrice && roomTypePrice.childPrice >= 0) {
    mappedRoomTypePrice.childPrice = roomTypePrice.childPrice
  }

  return mappedRoomTypePrice
}

export function tourV2InclusionMap(
  inclusion: TourAPI.Inclusion,
): Tours.V2OfferInclusion {
  return {
    title: inclusion.title,
    body: inclusion.body,
  }
}

export function tourV2ImageMap(
  image: PublicOfferV2.Image,
): App.Image {
  return {
    id: image.id,
    title: image.title,
  }
}

export function tourV2TourExperienceTimeSlotMap(timeSlots?: Array<string>, duration?: number | null) {
  if (!duration) return []
  return timeSlots?.map((timeSlot) => {
    const startTime = convertTwelveHourTimeToTimeStr(timeSlot)
    const endTime = addMinuteToTimeStr(startTime, duration)
    return `${startTime} - ${endTime}`
  }) ?? []
}

export function tourV2ExperienceMap(
  tourExperience: components['schemas']['v2TourOptionalExperience'],
  itineraryItem: PublicOfferV2.ItineraryItem,
): Tours.TourExperience | null {
  const location = itineraryItem?.locationsVisitedDetails?.map((location) => location.name).join(' - ') ?? itineraryItem?.locationsVisited.join(' - ') ?? ''
  if (tourExperience.price === null) return null
  return {
    id: tourExperience.id,
    itineraryId: itineraryItem.id,
    dayNumbers: tourExperience.dayNumbers,
    name: tourExperience.name,
    description: tourExperience.description,
    timeSlots: tourV2TourExperienceTimeSlotMap(tourExperience.timeSlots, tourExperience.duration),
    price: tourExperience.price,
    location,
    duration: tourExperience.duration,
    durationFormatted: tourExperience.duration ? formatMinutesToHoursMinutes(tourExperience.duration) : null,
  }
}

export function tourV2ItineraryItemMap(
  itineraryItem: PublicOfferV2.ItineraryItem,
  hostsDefaultImage?: string,
): Tours.TourV2OfferItineraryItem {
  const mappedItineraryItem: Tours.TourV2OfferItineraryItem = {
    id: itineraryItem.id,
    description: itineraryItem.description,
    duration: itineraryItem.duration,
    locationsVisited: itineraryItem.locationsVisited ?? [],
    meals: itineraryItem.meals ?? [],
    startDay: itineraryItem.startDay,
    title: itineraryItem.title,
    locationsVisitedDetails: itineraryItem.locationsVisitedDetails ?? [],
    specialsEvents: itineraryItem.specialCallOuts.map(special => ({
      hostImageId: special.hostImageId === 'default' ? hostsDefaultImage : special.hostImageId ?? undefined,
      description: special.description,
    })) ?? [],
    experiences: itineraryItem.optionalExperiences
      .map(experience => tourV2ExperienceMap(experience, itineraryItem))
      .filter(Boolean) as Array<Tours.TourExperience> ?? [],
  }

  if (itineraryItem.accommodation) {
    mappedItineraryItem.accommodation = itineraryItem.accommodation
  }
  if (itineraryItem.region) {
    mappedItineraryItem.region = itineraryItem.region
  }

  return mappedItineraryItem
}

export function tourV2ReservationSnapshotItineraryItemMap(
  itineraryItem: TourAPI.SnapshotItineraryItem,
): Tours.TourV2OfferItineraryItem {
  const mappedItineraryItem: Tours.TourV2OfferItineraryItem = {
    description: itineraryItem.description,
    duration: itineraryItem.duration,
    locationsVisited: itineraryItem.locationsVisited ?? [],
    meals: itineraryItem.meals ?? [],
    startDay: itineraryItem.startDay,
    title: itineraryItem.title,
    locationsVisitedDetails: [],
    specialsEvents: [],
    experiences: [],
  }

  if (itineraryItem.accommodation) {
    mappedItineraryItem.accommodation = itineraryItem.accommodation
  }
  if (itineraryItem.region) {
    mappedItineraryItem.region = itineraryItem.region
  }

  return mappedItineraryItem
}

function getTourV2VariationId(tourOption: PublicOfferV2.TourOption) {
  return tourOption.slug ?? tourOption.id
}

export function mapTourV2OfferVariations(
  tourOptions: { [key: string]: components['schemas']['v2TourOption'] },
  departures: Record<string, Tours.TourV2OfferDeparture>,
  hostsDefaultImage?: string,
): Tours.TourV2Offer['variations'] {
  const departuresByVariationId = groupBy(departures, dep => dep.fkVariationId)
  const tourOptionsAsArray = Object.values(tourOptions)
  const baseTourOption = tourOptionsAsArray.find((tourOption) => tourOption.sourceTourOptionName === TOUR_V2_PACKAGE_OPTION_BASE)

  const variationsWithDepartures = tourOptionsAsArray.filter(option => !!departuresByVariationId.get(getTourV2VariationId(option)))
  return arrayToObject(
    variationsWithDepartures,
    tourOption => getTourV2VariationId(tourOption),
    tourOption => {
      const baseTourOptionToCompare = tourOption.sourceTourOptionName !== TOUR_V2_PACKAGE_OPTION_BASE ? baseTourOption : undefined
      const departures = departuresByVariationId.get(getTourV2VariationId(tourOption)) ?? []
      return tourV2VariationMap(
        tourOption,
        departures,
        baseTourOptionToCompare,
        hostsDefaultImage,
      )
    },
  )
}

const sevenDaysInFuture = addDays(startOfDay(new Date()), 7)
function tourV2VariationMap(
  tourOption: PublicOfferV2.TourOption,
  departures: Array<Tours.TourV2OfferDeparture>,
  baseTourOption?: PublicOfferV2.TourOption,
  hostsDefaultImage?: string,
): Tours.TourV2OfferVariation {
  const itineraryDestinationsDiff = (baseTourOption) ?
    itineraryDiffDestinationsVisited(tourOption.defaultSeason.itinerary, baseTourOption.defaultSeason.itinerary) :
    extractItinerariesLocations(tourOption.defaultSeason.itinerary)

  const variationId = getTourV2VariationId(tourOption)
  const itinerary = sortBy(
    tourOption.defaultSeason.itinerary?.map(itinerary => tourV2ItineraryItemMap(itinerary, hostsDefaultImage)),
    itinerary => itinerary.startDay, 'asc',
  ) ?? []

  const mappedVariation: Tours.TourV2OfferVariation = {
    countriesVisited: tourOption.defaultSeason.countriesVisited ?? [],
    placesVisited: getTourV2PlacesVisited(itinerary),
    description: tourOption.defaultSeason.copy.description,
    diningInclusions: tourOption.defaultSeason.copy.diningInclusions?.map(tourV2InclusionMap) ?? [],
    endLocation: tourOption.defaultSeason.endLocation ?? undefined,
    fkSeasonId: tourOption.defaultSeason.id,
    fkTourOptionId: tourOption.id,
    id: variationId,
    images: tourOption.defaultSeason.images.map(tourV2ImageMap) ?? [],
    itinerary,
    name: tourOption.defaultSeason.name,
    startLocation: tourOption.defaultSeason.startLocation ?? undefined,
    travelInclusions: tourOption.defaultSeason.copy.travelInclusions?.map(tourV2InclusionMap) ?? [],
    canRequestPrivateDepartures: !!tourOption.isPrivateRequest,
    isReverseItinerary: !!tourOption.isReverseItinerary,
    privateRequestFromPrice: tourOption.privateRequestFromPrice ?? undefined,
    maxPax: tourOption.maxPax ?? undefined,
    maxAge: tourOption.maxAge ?? undefined,
    inclusionItems: tourOption.defaultSeason.inclusionItems.map<App.OfferInclusion>(item => ({
      id: uuidV4(),
      description: item.inclusionBody,
      symbol: determineOfferFeatureSymbol(item.categoryIcon?.split(',')[0]),
      isHighlighted: item.isHighlight,
    })),
    packageOptionName: tourOption.sourceTourOptionName ?? undefined,
    itineraryDestinations: extractItinerariesLocations(tourOption.defaultSeason.itinerary),
    itineraryDestinationsDiff,
    availablePackageUpgrades: tourOption.availablePackageUpgrades?.map<Tours.PackageUpgrade>((packageUpgrade) => ({
      packageType: packageUpgrade.packageOption.toLowerCase(),
      packageOption: packageUpgrade.packageOption,
      inclusions: packageUpgrade.inclusions.map<App.OfferInclusion>(item => ({
        id: uuidV4(),
        description: item.inclusionBody,
        symbol: determineOfferFeatureSymbol(item.categoryIcon?.split(',')[0]),
        isHighlighted: item.isHighlight,
      })),
    })) ?? [],
    baseTourOptionId: baseTourOption ? getTourV2VariationId(baseTourOption) : undefined,
    notes: tourOption.defaultSeason.notes ?? [],
    departures: departures.filter(dep => new Date(dep.startDate) > sevenDaysInFuture),
  }

  if (tourOption.defaultSeason.maxChildPriceAge) {
    mappedVariation.maxChildPriceAge = tourOption.defaultSeason.maxChildPriceAge
  }
  if (tourOption.defaultSeason.minChildPriceAge) {
    mappedVariation.minChildPriceAge = tourOption.defaultSeason.minChildPriceAge
  }
  if (tourOption.defaultSeason.routeMapImage) {
    mappedVariation.routeMapImage = { id: tourOption.defaultSeason.routeMapImage, title: `${tourOption.defaultSeason.name} route map` }
  }

  return mappedVariation
}

function itineraryDiffDestinationsVisited(currentDestinations: Array<PublicOfferV2.ItineraryItem>, baseDestinations: Array<PublicOfferV2.ItineraryItem>) {
  const itinerariesA = extractItinerariesLocations(currentDestinations)
  const itinerariesB = extractItinerariesLocations(baseDestinations)
  return itinerariesA.filter(location => !itinerariesB.includes(location))
}

function extractItinerariesLocations(itineraryItems: Array<PublicOfferV2.ItineraryItem>) {
  return unique(
    itineraryItems
      .flatMap(itinerary => itinerary.locationsVisitedDetails)
      .map(location => location.name),
  )
}

export function tourV2FAQMap(
  faq: TourAPI.FAQQuestion,
): Tours.TourV2OfferFrequentlyAskedQuestion {
  return {
    content: faq.content,
    key: faq.key,
    title: faq.title,
  }
}

export function tourV2DepositAmountMap(depositAmount: number, depositType?: Tours.V2OfferDepositType) {
  if (depositType === 'percentage') {
    return depositAmount * 100
  }

  if (depositType === 'default') {
    return 0
  }

  return depositAmount
}

export function extractTourV2DeparturePrivateRequestKey(departures: { [key: string]: components['schemas']['v2TourDeparture'] }): string | null {
  const departuresPrivateKeys = Object.values(departures)
    .filter(departure => !!departure.privateRequestKey)
    .map(departure => departure.privateRequestKey)
  return departuresPrivateKeys?.[0] ?? null
}

export function tourV2ExperienceSnapshotMap(
  tourExperienceSnapshot: TourAPI.SnapshotOptionalExperience,
): Tours.TourV2OrderExperienceItem {
  return {
    id: tourExperienceSnapshot.id,
    name: tourExperienceSnapshot.name,
    description: tourExperienceSnapshot.description ?? undefined,
    itineraryId: tourExperienceSnapshot.itineraryId,
    dayNumber: tourExperienceSnapshot.dayNumber,
    duration: tourExperienceSnapshot.duration ?? undefined,
    price: tourExperienceSnapshot.price,
    total: tourExperienceSnapshot.price * tourExperienceSnapshot.count,
    timeSlot: tourExperienceSnapshot.timeSlot ?? undefined,
    count: tourExperienceSnapshot.count,
    date: tourExperienceSnapshot.date,
    location: tourExperienceSnapshot.location,
    status: tourExperienceSnapshot.status,
  }
}

export function tourV2ExperienceWithExistingOrder(
  orderTourExperienceItem: Tours.TourV2OrderExperienceItem,
  purchasableTourOptionalExperience: Tours.TourV2OfferPurchasableOption,
): App.Checkout.TourV2ExperienceItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    offerId: purchasableTourOptionalExperience.fkTourId,
    date: orderTourExperienceItem.date,
    itemType: CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE,
    occupants: [{ adults: orderTourExperienceItem.count }],
    purchasableOption: {
      ...purchasableTourOptionalExperience,
      timeSlot: orderTourExperienceItem.timeSlot,
      date: orderTourExperienceItem.date,
      day: orderTourExperienceItem.dayNumber,
      duration: orderTourExperienceItem.duration,
    },
  }
}

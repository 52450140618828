const keyPartnershipAccountNumberField = 'account_id'

function getPartnershipUserFields(partnership: App.Partnership, partnershipFields: Record<string, any>) {
  if (partnership) {
    let otherPartnershipUserFields = partnership.userFields.filter(field => field.name !== keyPartnershipAccountNumberField)

    const partnershipAccountNumberField = Object.assign({
      required: otherPartnershipUserFields.filter(field => partnershipFields[field.name]).length > 0,
      value: partnershipFields[keyPartnershipAccountNumberField],
    }, partnership.userFields.find(field => field.name === keyPartnershipAccountNumberField))

    otherPartnershipUserFields = otherPartnershipUserFields.map(field => (
      Object.assign({
        required: !!partnershipFields[partnershipAccountNumberField.name],
        value: partnershipFields[field.name],
      }, field)
    ))

    return {
      partnershipAccountNumberField,
      otherPartnershipUserFields,
    }
  }

  return {
    partnershipAccountNumberField: null,
    otherPartnershipUserFields: null,
  }
}

export default getPartnershipUserFields

import uuidV4 from 'lib/string/uuidV4Utils'

export function getOfferInclusionDescriptionFromMarkdown(markdown: string): string {
  // remove leading markdown for lists ("- ", "* ", "1. ")
  return markdown.replace(/^([-*]|\d+\.)\s+/g, '').trim()
}

export function getOfferInclusionDescriptionsListFromMarkdown(markdown: string): Array<string> {
  return markdown
    .split('\n')
    .map(getOfferInclusionDescriptionFromMarkdown)
    .filter(Boolean)
}

export function getOfferInclusionFromMarkdown(markdown: string): Array<App.OfferInclusion> | undefined {
  if (!markdown) return

  return getOfferInclusionDescriptionsListFromMarkdown(markdown).map<App.OfferInclusion>(item => ({
    id: uuidV4(),
    description: item,
  }))
}

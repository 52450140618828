import Modal from 'components/Luxkit/Modal/Modal'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { navigateToCheckout } from 'checkout/actions/navigateActions'
import { modalClose } from 'actions/UiActions'
import { getCheckoutDepth } from 'checkout/hooks/useCheckoutRouteTracker'
import { useHistory } from 'react-router'
import { bookingUrlPattern, checkoutUrlPattern } from 'constants/url'
import { checkoutCartItemViews } from 'selectors/checkoutCartSelectors'
import { pluralizeToString } from 'lib/string/pluralize'

interface Props {
  isOpen: boolean;
  itemViews: Array<App.Checkout.CartItemView>;
}
function AddToCartModal({
  isOpen,
  itemViews,
}: Props) {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const pathname = history.location.pathname
  const isOnCheckoutPage = checkoutUrlPattern.test(pathname) || bookingUrlPattern.test(pathname)

  const goBackToOfferPage = useCallback(() => {
    const checkoutDepth = getCheckoutDepth()
    if (checkoutDepth) {
      history.go(-checkoutDepth)
    }
  }, [history])

  const keepBrowsing = useCallback(() => {
    dispatch(modalClose())
    if (isOnCheckoutPage) {
      goBackToOfferPage()
    }
  }, [dispatch, goBackToOfferPage, isOnCheckoutPage])

  const goToCheckout = useCallback(() => {
    dispatch(modalClose())
    dispatch(navigateToCheckout({ skipSpoofedModal: true }))
  }, [dispatch])

  return (
    <Modal
      isOpen={isOpen}
      title="Any more items?"
      onClose={keepBrowsing}
      primaryActionText="Checkout now"
      onPrimaryActionClick={goToCheckout}
      secondaryActionText="Keep browsing"
      onSecondaryActionClick={keepBrowsing}
    >
      <VerticalSpacer gap={16}>
        <BodyText variant="large" align="start">
          Nice choice! We have added your selection in your cart.<br/>
          Do you want to checkout now or keep browsing and checkout later?
        </BodyText>
        <BodyText variant="medium">
          Currently you have
          <BodyText variant="medium" as="span" weight="bold">&nbsp;{pluralizeToString('item', itemViews.length)}&nbsp;</BodyText>
          in your cart.
        </BodyText>
      </VerticalSpacer>
    </Modal>
  )
}

function mapStateToProps(state: App.State) {
  const modal = state.ui.modal
  return {
    isOpen: modal.modalType === 'checkoutCartMode' && modal.open,
    itemViews: checkoutCartItemViews(state),
  }
}

export default connect(mapStateToProps)(AddToCartModal)

import TextLink from 'components/Luxkit/TextLink'
import Group from 'components/utils/Group'
import useListTruncator from 'hooks/useListTruncator'
import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'
import OfferInclusionItem from './OfferInclusionItem'

type InheritedOfferInclusionItemProps = Pick<ComponentProps<typeof OfferInclusionItem>, 'type' | 'size' | 'showMinStayDescription'>

interface Props extends InheritedOfferInclusionItemProps, Utils.PropsWithDataAttributes {
  inclusions: Array<App.OfferInclusion>
  /**
   * The number of items to display before the rest are truncated.
   *
   * If set to zero, nothing will be displayed.
   */
  truncateAfter?: number
  /**
   * Text to display if there are more to show
   * @default "+[truncatedCount] more"
   */
  showMoreText?: string;
  onShowMoreClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

function OfferInclusionsList({
  type,
  size,
  showMinStayDescription,
  inclusions,
  truncateAfter,
  onShowMoreClick,
  showMoreText,
  ...rest
}: Props) {
  const [visible, truncated] = useListTruncator(truncateAfter, inclusions)
  const handleMoreClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault()
    onShowMoreClick?.(event)
  }, [onShowMoreClick])

  if (!visible.length) return null

  return <Group {...rest} className={OfferInclusionsList.name} direction="vertical" gap={4}>
    {visible.map((inclusion) => <OfferInclusionItem
      key={inclusion.id}
      inclusion={inclusion}
      size={size}
      type={type}
      showMinStayDescription={showMinStayDescription}
    />)}
    {(onShowMoreClick && !!truncated.length) && <div>
      <TextLink size="medium" onClick={handleMoreClick}>
        {showMoreText ?? `+${truncated.length} more`}
      </TextLink>
    </div>}
  </Group>
}

export default OfferInclusionsList

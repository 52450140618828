import { getGeoData } from 'api/geo'
import getCurrentGeoLocation from 'lib/geo/navigatorUtils'
import { API_CALL, UPDATE_USER_LAT_LONG } from './actionConstants'
import { FETCH_GEO_AIRPORTS, FETCH_GEO_DATA } from './apiActionConstants'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { getAirports } from 'api/flights'
import { AppAction } from './ActionTypes'

export function updateUserLatLong(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.config.headlessMode) {
      return
    }

    getCurrentGeoLocation().then(coords => {
      dispatch({
        type: UPDATE_USER_LAT_LONG,
        latitude: coords.latitude,
        longitude: coords.longitude,
      })
    }).catch(message => {
      showSnackbar(message, 'warning', { heading: 'Couldn\'t find your location!' })
    })
  }
}

/**
 * Fetches the geo information required for the app to run
 * @param regionCode The region code to load geo data for
 * @param initialiseOnFailure Whether or not to set the state as initialised if we had a failure
 * @returns
 */
export function fetchGeoState(regionCode: string, initialiseOnFailure?: boolean): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.geo.initialised) {
      // already initialised, no need to do it again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_GEO_DATA,
      request: () => getGeoData(regionCode),
      initialiseOnFailure,
    })
  }
}

export function fetchGeoAirports(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.geo.initialised) {
      // already initialised, no need to do it again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_GEO_AIRPORTS,
      request: () => Promise.all([
      /**
       * Airports are region agnostic data, but svc-flights limits them behind certain
       * regions. So we'll get around this by hard coding the region to an enabled region
       * This fixes issues where airports are used for something other than flights (e.g. transfers)
       */
        state.geo.currentRegionCode === 'AU' ? Promise.resolve({} as any) : getAirports('AU'),
        getAirports(state.geo.currentRegionCode),
      ]).then(([allAirportResponse, airportData]) => {
        return {
          ...airportData,
          airports: allAirportResponse.airports ?? airportData.airports,
        }
      }),
    })
  }
}

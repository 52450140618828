import { rem } from 'polished'
import React, { ComponentProps, forwardRef } from 'react'
import styled from 'styled-components'
import BodyText from '../Typography/BodyText'
import CheckboxButton from './CheckboxButton'
import clsx from 'clsx'

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: ${rem(12)};
`

const LabelText = styled(BodyText)`
  flex-grow: 1;
  transition: color 0.2s;
`

interface Props extends ComponentProps<typeof CheckboxButton> {
}

/**
 * Checkbox input with a label.
 *
 * For the checkbox input without the label see `<CheckboxButton>`
 */
const CheckboxInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, className, size = 'medium', ...inputProps } = props

  return <Label className={clsx(className, `size-${size}`)} htmlFor={inputProps.id}>
    <CheckboxButton {...inputProps} ref={ref} size={size} />
    <LabelText
      colour={inputProps.disabled ? 'neutral-four' : 'neutral-one'}
      variant={size}
      forwardedAs="span"
    >
      {children}
    </LabelText>
  </Label>
})

export default CheckboxInput

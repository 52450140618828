import React, { useMemo } from 'react'

import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import { getAirportDisplayText } from 'lib/flights/flightUtils'

interface Props {
  subRecord?: boolean;
  selected?: boolean;
  airport: App.AirportLocation;
  icon?: React.ReactNode;
  onSelectAirport: (airport: App.AirportLocation) => void;
  id?: string;
}

function AirportDropdownOption(props: Props) {
  const { id, subRecord, selected, airport, icon, onSelectAirport } = props
  const { cityDesc, country } = airport
  const selectAirport = () => onSelectAirport(airport)

  const airportIcon = useMemo(() => {
    if (subRecord) {
      return <LineArrowRightIcon colour="primary" />
    } else {
      return icon ?? <LinePlaneFlyIcon colour="primary" />
    }
  }, [subRecord, icon])

  return (
    <ListItem
      size="medium"
      onClick={selectAirport}
      startIcon={airportIcon}
      title={getAirportDisplayText(airport)}
      subtitle={`${cityDesc}, ${country}`}
      data-testid={`${id}-${airport.airportCode}`}
      selected={selected}
    />
  )
}

export default AirportDropdownOption

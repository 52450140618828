import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import Heading from 'components/Luxkit/Typography/Heading'
import { CABIN_CATEGORIES } from 'constants/cruise'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'

/**
 * > What's the purpose of `<Container>` combined with `<EvenRowContainer>`?
 *
 * - There are 4 cell to be displayed in the layout.
 * - The number of columns need to be even at all times. (2 or 4)
 *
 * `<EvenRowContainer>` is meant to only hold 2 cells at a time,
 * while `<Container>` is meant to contain the 2 `<EvenRowContainer>`s,
 * and dictates the minimum width of `<EvenRowContainer>`s.
 */
const Container = styled.div`
  --col-gap: ${rem(4)};
  --col-min-width: ${rem(136)};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(var(--col-min-width) * 2 + var(--col-gap)), 1fr));
  column-gap: var(--col-gap);
  row-gap: ${rem(8)};
`
const EvenRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--col-min-width), 1fr));
  column-gap: var(--col-gap);
`

interface Props {
  view: CruiseOfferView
}

function CruiseSearchTileExtendedPricing({ view }: Props) {
  const { availableCabinCategoriesPriceVariations } = view

  const priceRows = useMemo(() => {
    const prices = CABIN_CATEGORIES.map((cabinCategory) => [
      cabinCategory,
      availableCabinCategoriesPriceVariations!.get(cabinCategory.category),
    ] as const)
    return [
      prices.slice(0, 2),
      prices.slice(2, 4),
    ]
  }, [availableCabinCategoriesPriceVariations])

  return <Container>
    {priceRows.map((items, rowIndex) => <EvenRowContainer key={rowIndex}>
      {items.map(([cabinCategory, price]) => <div key={cabinCategory.category}>
        <PriceRowPriceCaption>
          {cabinCategory.category}
          {!!price?.primaryPrice.total && ' from'}
        </PriceRowPriceCaption>
        {!!price?.primaryPrice.total && <PriceRowPrice
          size="M"
          price={price.primaryPrice.total}
          saleUnit={price.primaryPrice.type}
        />}
        {!price?.primaryPrice.total && <Heading variant="heading5" colour="neutral-three">
          Unavailable
        </Heading>}
      </div>)}
    </EvenRowContainer>)}
  </Container>
}

export default CruiseSearchTileExtendedPricing

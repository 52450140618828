/*
  Some lint rules disabled for this file, so the map style can be saved in valid
  JSON format for easy copying and pasting to Snazzy Maps.
*/
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable quote-props */

export const OFFER_SUMMARY_MAP_HEIGHT = 196

export const PROPERTY_MAP_MARKER_TYPE = 'property'
export const CRUISE_PILL_MARKER_TYPE = 'cruise-pill'
export const CRUISE_PILL_ICON_MARKER_TYPE = 'cruise-pill-icon'
export const MOMENTS_MAP_MARKER_TYPE = 'moments'

import React, { useCallback, useContext } from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LineCommentsIcon from 'components/Luxkit/Icons/line/LineCommentsIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import { connect } from 'react-redux'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import config from 'constants/config'
import shouldRenderTimely from 'lib/timely/timely'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'
import { useRenderWhatsApp } from 'hooks/useRenderWhatsApp'
import ListItem from 'components/Luxkit/List/ListItem'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import switchFunc from 'lib/function/switchFunc'
import ContactContext from 'contexts/contactContext'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

const regionsAvailabilityDescriptions = switchFunc({
  IN: {
    title: 'We\'re your travel companion',
    message: 'Our customer service team is available online or on the phone 7 days a week, 9am to 7pm.',
  },
}, {
  title: 'We\'re your 24/7 travel companion',
  message: 'Our customer service team is available online or on the phone all the time, whenever you need us.',
})

interface Props {
  currentRegionCode: string;
  supportAssistantEnabled: boolean;
  supportAssistantFirst?: boolean;
}

function HeaderContactOptionsModal(props: Props) {
  const {
    currentRegionCode,
    supportAssistantEnabled,
    supportAssistantFirst = false,
  } = props
  const modalElement = useModalElementContext()
  const currentContact = useContext(ContactContext)
  const shouldRenderWhatsApp = useRenderWhatsApp(currentRegionCode)

  const localContact = currentContact.local
  const internationalContact = currentContact.international
  const copy = regionsAvailabilityDescriptions(currentRegionCode.toUpperCase())

  const handleItemClick = useCallback(() => {
    modalElement.resolve()
  }, [modalElement])

  return (
    <Modal title={copy.title}>
      <VerticalSpacer gap={16}>
        <BodyText variant="large">{copy.message}</BodyText>
        <div>
          {supportAssistantFirst && supportAssistantEnabled && <ListItem
            title="Chat now"
            startIcon={<LineCommentsIcon />}
            subtitle="Luxury Escapes Support Assistant"
            onClick={handleItemClick}
            to="/support"
            aria-label="Luxury Escapes Support Assistant"
            rel="nofollow"
            target="_blank"
          />}
          {shouldRenderTimely(currentRegionCode) &&
            <ListItem
              title="Book store appointment"
              startIcon={<img src={getSVGImageUrl('logo-LE')} width="24" height="24" alt="LE store" />}
              onClick={handleItemClick}
              href={config.TIMELY_LINK}
              aria-label="Timely booking"
              rel="noopener"
              target="_blank"
            />
          }
          <ListItem
            title="Local Sales & Support"
            startIcon={<LinePhoneIcon />}
            subtitle={localContact.humanReadable}
            onClick={handleItemClick}
            href={`tel:${localContact.number}`}
            aria-label="Sales & Support contact"
            rel="nofollow"
          />
          {!supportAssistantFirst && supportAssistantEnabled && <ListItem
            title="Chat now"
            startIcon={<LineCommentsIcon />}
            subtitle="Luxury Escapes Support Assistant"
            onClick={handleItemClick}
            to="/support"
            aria-label="Luxury Escapes Support Assistant"
            rel="nofollow"
            target="_blank"
          />}
          {shouldRenderWhatsApp &&
            <ListItem
              title="WhatsApp"
              startIcon={<img src={getSVGImageUrl('whatsapp-logo')} width="24" height="24" alt="WhatsApp"/>}
              onClick={handleItemClick}
              href={`https://wa.me/${config.WHATS_APP_NUMBER}`}
              aria-label="WhatsApp"
              rel="noopener"
              target="_blank"
            />
          }
          <ListItem
            startIcon={<LinePhoneIcon />}
            title="International Support Centre"
            subtitle={internationalContact.humanReadable}
            onClick={handleItemClick}
            href={`tel:${internationalContact.number}`}
            aria-label="International Support Centre contact"
            rel="nofollow"
          />
        </div>
      </VerticalSpacer>
    </Modal>
  )
}

const mapStateToProps = (state: App.State) => ({
  currentRegionCode: state.geo.currentRegionCode,
  supportAssistantEnabled: isSupportAssistantEnabled(state),
})

export default connect(mapStateToProps)(HeaderContactOptionsModal)

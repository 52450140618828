import React, { useCallback } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'

import Clickable from 'components/Common/Clickable'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const Root = styled(Clickable)`
  height: 44px;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${rem(12)};
  padding-top: ${rem(4)};

  &.selected {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }

  &.in-range-date {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &.center-align {
    padding-top: 0;
    align-items: center;
  }

  &:not(:disabled):not(.selected-date) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
        color: ${props => props.theme.palette.neutral.default.one};
      }
    }
    &:focus {
      background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
      color: ${props => props.theme.palette.neutral.default.one};
    }
  }
`

interface Props {
  day: Date;
  dataTestId: string;
  selected: boolean;
  disabled: boolean;
  isInRange: boolean;
  onClick: (day: Date) => void;
}

function SearchDatePickerDay(props: Props) {
  const { day, dataTestId, disabled, selected, isInRange, onClick } = props

  const onSelectDay = useCallback(() => {
    onClick(day)
  }, [day, onClick])

  return (
    <Root
      onClick={onSelectDay}
      disabled={disabled}
      className={cn({
        selected,
        'in-range-date': isInRange,
      }, 'center-align')}
    >
      <BodyText data-testid={dataTestId} variant="medium">
        {day.getDate()}
      </BodyText>
    </Root>
  )
}

export default SearchDatePickerDay

/**
 * Take an object and the body of a PATCH request that would update that object,
 * and emulate the result of the patch.
 *
 * This is achieved by removing undefined fields from the patch object, converting
 * NULLs to undefined, and then spreading that over the original object.
 *
 * Intended for use with optimistic updates.
 *
 * Return type is any because typing this properly is too hard. Might try again later.
 */
export function applyPatchFields<
  T extends {},
  P extends { [K in keyof T]?: T[K] | null | undefined },
>(obj: T, patch: P): any {
  const fixedPatch = { ...patch } as any
  const keys = Object.keys(fixedPatch)
  keys.forEach((key: string) => {
    if (typeof fixedPatch[key] === 'undefined') {
      delete fixedPatch[key]
    } else if (fixedPatch[key] === null) {
      fixedPatch[key] = undefined
    }
  })
  return {
    ...obj,
    ...fixedPatch,
  }
}

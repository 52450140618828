import React from 'react'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import useLuxLoyaltyPointsCalculator from 'luxLoyalty/hooks/useLuxLoyaltyPointsCalculator'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import { getMinimumDurationFromFlexibleNights, isSearchStreamingSupported } from 'lib/search/searchUtils'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'
import useDefaultAirport from 'hooks/useDefaultAirport'
import { PRODUCT_TYPE_FLIGHT } from 'constants/offer'
import { getOfferLuxLoyaltyProductType } from 'luxLoyalty/lib/pointsCalculation'

interface Props {
  offer: App.HotelOffer | App.HotelOfferSummary
  bestPackage?: App.HotelPackage
  bestAvailableRate?: App.OfferAvailableRate
  filters?: App.OfferListFilters
}

function HotelSearchTileEarnablePoints(props: Props) {
  const { offer, bestPackage, bestAvailableRate, filters } = props
  const allowSortByPrice = !!useOptimizelyExperiment(OptimizelyFeatureFlags.searchStreamingSortByPrice)
  const isSearchStreamingEnabled = isSearchStreamingSupported(filters, { allowSortByPrice })
  const duration = filters?.flexibleMonths && filters.flexibleNights ? getMinimumDurationFromFlexibleNights(filters.flexibleNights as FLEXIBLE_DURATION_RANGE) : offer.lowestPricePackage?.duration
  const defaultAirport = useDefaultAirport()
  const flightPrice = (offer.flightPrices[defaultAirport?.code ?? ''] || 0)

  const {
    totalPrice = 0,
    totalMemberPrice = 0,
  } = useSearchPrices({
    offer,
    pkg: bestPackage,
    rate: bestAvailableRate,
    duration,
    filters,
    useRealSearchPrices: isSearchStreamingEnabled,
  })

  const { totalPointsCalculation } = useLuxLoyaltyPointsCalculator([
    {
      price: totalPrice,
      memberPrice: totalMemberPrice,
      productType: getOfferLuxLoyaltyProductType(offer.type),
      margin: 0,
      offerId: offer.id,
      packageId: bestPackage?.id ?? '',
    },
    {
      price: flightPrice,
      productType: PRODUCT_TYPE_FLIGHT,
      margin: 0,
      skip: !offer.bundledWithFlightsOnly || !offer.offerFlightsEnabled,
    },
  ])

  return <LuxLoyaltyPoints pointsCalculation={totalPointsCalculation} />
}

export default HotelSearchTileEarnablePoints

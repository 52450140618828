import React, { useMemo, MouseEventHandler } from 'react'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import noop from 'lib/function/noop'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { connect } from 'react-redux'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import FormatCurrency from 'components/Common/FormatCurrency'
import { isEnabledLuxPlusDiscountedInsurance, isEnabledLuxPlusDiscountedBookingProtection } from 'luxPlus/selectors/featureToggle'

interface MappedProps {
  subscription: App.ProductSubscription;
  isEnabledLuxPlusDiscountedInsurance: boolean;
  isEnabledLuxPlusDiscountedBookingProtection: boolean;
}

interface Props extends MappedProps {
  onTsAndCsLinkClick?: MouseEventHandler<HTMLAnchorElement> | (() => void)
  type?: 'withPrice' | 'firstTimeDiscounted' | 'insuranceAndBookingProtection'
}

/**
 * LuxPlus subscription terms and conditions inside a `<BodyText>`.
 */
function LuxPlusSubscriptionTsAndCs(props: Props) {
  const {
    onTsAndCsLinkClick = noop,
    type,
    subscription,
    isEnabledLuxPlusDiscountedInsurance,
    isEnabledLuxPlusDiscountedBookingProtection,
  } = props

  const defaultTsAndCs = useMemo(() => <>
    Your {LUXURY_PLUS.PROGRAM_NAME} annual membership renews on your sign-up anniversary date.{' '}
    You can cancel your renewal anytime.{' '}
    <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
  </>, [onTsAndCsLinkClick])

  const copy = useMemo<JSX.Element>(() => {
    switch (type) {
      case 'withPrice':
        return <>
          {subscription.error && defaultTsAndCs}
          {subscription.fetching && <LoadingIndicator />}
          {subscription.offers && <>
            Your {LUXURY_PLUS.PROGRAM_NAME} <FormatCurrency value={subscription.offers[0].price} format="roundedDollar" /> annual membership renews on your sign-up anniversary date.{' '}
            You can cancel your renewal anytime. <FormatCurrency value={subscription.offers[0].joinFee} format="roundedDollar" /> one-off joining fee will be waived when you purchase this offer.{' '}
            <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
          </>}
        </>
      case 'firstTimeDiscounted':
        return <>
          {subscription.error && defaultTsAndCs}
          {subscription.fetching && <LoadingIndicator />}
          {subscription.offers && <>*Discount applies to first year of membership. After that, {LUXURY_PLUS.PROGRAM_NAME} renews at <FormatCurrency value={subscription.offers[0].price} />{' '}
            annually on your sign-up anniversary. You can cancel your renewal anytime. The <FormatCurrency value={subscription.offers[0].joinFee} format="rounded" /> one-off joining{' '}
            fee will be waived when you book this offer. <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink></>}
        </>
      case 'insuranceAndBookingProtection':
        if (isEnabledLuxPlusDiscountedInsurance && isEnabledLuxPlusDiscountedBookingProtection) {
          return <>
            ^Members receive {LUXURY_PLUS.INSURANCE_DISCOUNT_PERCENTAGE}% off Travel and Cancellation Protection product(s) (Product) up to a maximum discount of <FormatCurrency value={LUXURY_PLUS.MAX_INSURANCE_DISCOUNT_THRESHOLD} />.{' '}
            The Product may not be available to all members and eligibility and coverage restrictions apply. Members must read and consider the relevant PDS to ensure whether and how the Product applies to them.{' '}
            The relevant terms and PDS of the Product will apply to any purchase. The Product benefit is not exchangeable for cash or credit if not able to be utilised for any reason.{' '}
            <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
          </>
        }
        if (isEnabledLuxPlusDiscountedInsurance) {
          return <>
            ^Members receive {LUXURY_PLUS.INSURANCE_DISCOUNT_PERCENTAGE}% off Travel Protection product(s) (Product) up to a maximum discount of <FormatCurrency value={LUXURY_PLUS.MAX_INSURANCE_DISCOUNT_THRESHOLD} />.{' '}
            The Product may not be available to all members and eligibility and coverage restrictions apply. Members must read and consider the relevant PDS to ensure whether and how the Product applies to them.{' '}
            The relevant terms and PDS of the Product will apply to any purchase. The Product benefit is not exchangeable for cash or credit if not able to be utilised for any reason.{' '}
            <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
          </>
        }
        if (isEnabledLuxPlusDiscountedBookingProtection) {
          return <>
            ^Members receive {LUXURY_PLUS.INSURANCE_DISCOUNT_PERCENTAGE}% off Cancellation Protection product(s) (Product) up to a maximum discount of <FormatCurrency value={LUXURY_PLUS.MAX_INSURANCE_DISCOUNT_THRESHOLD} />.{' '}
            The Product may not be available to all members and eligibility and coverage restrictions apply. Members must read and consider the relevant PDS to ensure whether and how the Product applies to them.{' '}
            The relevant terms and PDS of the Product will apply to any purchase. The Product benefit is not exchangeable for cash or credit if not able to be utilised for any reason.{' '}
            <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
          </>
        }
        return defaultTsAndCs
      default:
        return defaultTsAndCs
    }
  }, [defaultTsAndCs, isEnabledLuxPlusDiscountedBookingProtection, isEnabledLuxPlusDiscountedInsurance, onTsAndCsLinkClick, subscription.error, subscription.fetching, subscription.offers, type])

  return <BodyText variant="small" colour="neutral-one" data-testid="luxury-plus-subscription-ts-and-cs">
    {copy}
  </BodyText>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  subscription: state.luxPlus.products.subscription,
  isEnabledLuxPlusDiscountedInsurance: isEnabledLuxPlusDiscountedInsurance(state),
  isEnabledLuxPlusDiscountedBookingProtection: isEnabledLuxPlusDiscountedBookingProtection(state),
})

export default connect(mapStateToProps)(LuxPlusSubscriptionTsAndCs)

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSunsetIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17.66 8.34a1.0001 1.0001 0 0 0 .7-.29l.71-.71a.9993.9993 0 0 0 .3488-.721.9999.9999 0 0 0-1.0379-1.0378 1.0002 1.0002 0 0 0-.7209.3488l-.66.71a.9998.9998 0 0 0 0 1.41 1 1 0 0 0 .66.29ZM12 6a1 1 0 0 0 1-1V4a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 4v1a1.0002 1.0002 0 0 0 1 1Zm-8 6H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 13.8946 2.7348 14 3 14h1a1.0002 1.0002 0 0 0 .7071-1.7071A1.0002 1.0002 0 0 0 4 12Zm1.64-3.95a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.29 1 1 0 0 0 0-1.41l-.71-.71a1 1 0 0 0-1.41 1.41l.71.71ZM21 12h-1a1.0001 1.0001 0 0 0 0 2h1a1.0001 1.0001 0 0 0 0-2Zm-10 7H8a1.0002 1.0002 0 0 0-.7071 1.7071C7.4804 20.8946 7.7348 21 8 21h3a1.0001 1.0001 0 0 0 0-2Zm7-4h-.88a5.3896 5.3896 0 0 0 .38-2A5.5003 5.5003 0 0 0 12 7.5 5.5 5.5 0 0 0 6.5 13a5.39 5.39 0 0 0 .38 2H6a1.0002 1.0002 0 0 0-.7071 1.7071C5.4804 16.8946 5.7348 17 6 17h12a1.0001 1.0001 0 0 0 0-2Zm-3.15 0h-5.7a3.44 3.44 0 0 1-.65-2 3.5 3.5 0 1 1 7 0 3.4403 3.4403 0 0 1-.65 2ZM16 19h-1a1.0001 1.0001 0 0 0 0 2h1a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineSunsetIcon

import React, { useCallback } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryDown } from 'components/utils/breakpoint'
import variables from 'styles/vendor/bootstrap/variables'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { replaceExperienceQueryString } from 'lib/experiences/experienceUtils'
import config from 'constants/config'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import { destinationsMap } from 'constants/experience'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { SEARCH_VERTICALS } from 'constants/search'
import uuidV4 from 'lib/string/uuidV4Utils'

const Wrapper = styled.div`
  margin-top: ${rem(16)};
  align-self: flex-start;
  position: relative;
`

const LabelsWrapper = styled.nav`
  margin-top: ${rem(8)};
  -webkit-overflow-scrolling: touch;
  padding-right: ${rem(variables.gutterWidth / 2)};
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  > * + * {
    margin-left: ${rem(8)};
  }

  ${mediaQueryDown.tablet} {
    display: flex;
    flex-wrap: wrap;
    gap: ${rem(8)};

    > * + * {
      margin-left: 0;
    }
  }
`

interface Props {
  className?: string;
  weight?: 'normal' | 'semi-bold' | 'bold';
  colour?: 'neutral-eight' | 'neutral-two'
  variant?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}

function ExperiencePageTrendingDestinations({
  className,
  colour = 'neutral-eight',
  weight = 'bold',
  variant = 'medium',
  onClick,
}: Props) {
  const destinations = destinationsMap(config.BRAND)

  const handleClick = useCallback((event: any) => {
    const location = event.target.textContent

    Analytics.trackEvent(searchEventWithContext(
      'common-search',
      'standard', {
        destinationIds: [],
        destinationType: 'location',
        destinations: [location],
        vertical: SEARCH_VERTICALS.EXPERIENCES,
        searchId: uuidV4(),
      },
    ))
    onClick?.()
  }, [onClick])

  return (
    <Wrapper className={className}>
      <BodyText colour={colour} variant={variant} weight={weight}>Popular searches</BodyText>
      <LabelsWrapper>
        {destinations.map(destination => {
          const query = replaceExperienceQueryString({ destinationId: destination.id, destinationName: destination.name })

          return <AssistChip
            size="medium"
            variant="filled"
            key={destination.id}
            to={`/search/experiences?${query}`}
            onClick={handleClick}
            >
            {destination.name}
          </AssistChip>
        })
        }
      </LabelsWrapper>
    </Wrapper>
  )
}

export default ExperiencePageTrendingDestinations

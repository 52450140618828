import { createSelector } from 'reselect'
import { getCurrentLuxPlusMemberTier } from 'selectors/luxPlusSelectors'
import { getLuxPlusSubscriptionItems } from './view/luxPlusSubscription'

export const getCheckoutLuxPlusTier = createSelector(
  getCurrentLuxPlusMemberTier,
  getLuxPlusSubscriptionItems,
  (currentTier, subscriptionItems): App.MembershipSubscriptionTier => {
    const itemTier = subscriptionItems[0]?.tier
    const validCurrentTierForCheckout = currentTier !== 'free_preview' ? currentTier : 'base'
    return itemTier ?? validCurrentTierForCheckout
  },
)

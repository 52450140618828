import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseBorderRadii, whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const cudoTravelDefaultPalette: App.ThemePalette<string> = {
  ...whiteLabelBaseDefaultPalette,
}

export const cudoTravelShadows: App.ThemeShadows<string> = { ...baseThemeShadows }
export const cudoTravelBorderRadii: App.ThemeBorderRadii<string> = { ...whiteLabelBaseBorderRadii }

const cudoTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default cudoTravelTheme

import { isValidInsuranceRegion } from 'lib/insurance/insuranceCountries'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import isBookingProtectionEnabled from './isBookingProtectionEnabled'
import { getInsuranceEnabledCheckoutItems } from '../view/accommodation'
import getDestinationCountriesFromCart from '../view/getDestinationCountriesFromCart'
import getDestinationCountriesFromExistingOrder from '../view/getDestinationCountriesFromExistingOrder'
import { getInsuranceDatesFromExistingOrder } from './insurance'
import getInsuranceDatesFromCart from './getInsuranceDatesFromCart'

/**
 * Get the insurance parameters based on the checkout cart items
 *
 * @remarks
 * - Currently only supports "LE Hotels" (instant booking), "Bedbank" and "TourV2"
 */
const isInsuranceSupportedForCartItems = createSelector(
  (state: App.State) => state.checkout.cart.postPurchase,
  getInsuranceEnabledCheckoutItems,
  getInsuranceDatesFromCart,
  getDestinationCountriesFromCart,
  (postPurchase, items, dateRange, destinationCountries): boolean => {
    return !!(
      items.length &&
      dateRange &&
      destinationCountries.length &&
      (!postPurchase || postPurchase === 'select-date')
    )
  },
)

const isInsuranceSupportedForExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.postPurchase,
  getInsuranceDatesFromExistingOrder,
  getDestinationCountriesFromExistingOrder,
  (postPurchase, insuranceDates, destCountries): boolean => {
    return !!(postPurchase == 'insurance' && insuranceDates && destCountries.length)
  },
)

const isInsuranceSupported = createSelector(
  isInsuranceSupportedForCartItems,
  isInsuranceSupportedForExistingOrder,
  isBookingProtectionEnabled,
  (state: App.State) => state.geo.currentRegionCode,
  isSpoofed,
  (isInsuranceAvailableForCartItems,
    isInsuranceAvailableForExistingOrder,
    isBookingProtectionEnabled,
    currentRegionCode,
    isSpoofed): boolean => {
    if (!isValidInsuranceRegion(currentRegionCode)) return false
    if (!isInsuranceAvailableForCartItems && !isInsuranceAvailableForExistingOrder) return false
    if (isBookingProtectionEnabled && !isSpoofed) return false
    return true
  },
)

export default isInsuranceSupported

import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import LuxPlusLogo from './Logos/LuxPlusLogo'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { rem } from 'polished'

const Logo = styled(LuxPlusLogo)`
  height: 100%;
`

const LogoWrapper = styled.span`
  display: inline-flex;
`

interface Props extends Omit<ComponentProps<typeof LuxPlusLogo>, | 'height'> {
  /**
   * @default 10
   */
  height?: number
}

function LuxPlusLogoWrapper(props: Props) {
  const {
    height = 10,
    className,
    ...rest
  } = props

  return <LogoWrapper
    className={className}
    style={{ height: rem(height) }}
    aria-label={`${LUXURY_PLUS.PROGRAM_NAME} logo`}
  >
    <Logo aria-hidden="true" {...rest} />
  </LogoWrapper>
}

export default LuxPlusLogoWrapper

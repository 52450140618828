import {
  ONE_DAY_BEFORE_CHECK_IN,
  TWO_DAYS_BEFORE_CHECK_IN,
  THREE_DAYS_BEFORE_CHECK_IN,
  SEVEN_DAYS_BEFORE_CHECK_IN,
  FOURTEEN_DAYS_BEFORE_CHECK_IN,
  TWENTY_ONE_DAYS_BEFORE_CHECK_IN,
  TWENTY_EIGHT_DAYS_BEFORE_CHECK_IN,
  THIRTY_ONE_DAYS_BEFORE_CHECK_IN,
  FORTY_FIVE_DAYS_BEFORE_CHECK_IN,
  SIXTY_DAYS_BEFORE_CHECK_IN,
} from 'constants/cancellationPolicies'
import { ConnectionCancellationPolicies, formatDate } from '@luxuryescapes/lib-refunds'
import config from 'constants/config'

export function getAlwaysOnCancellationPolicyDays(cancellationPolicyType: App.CancellationPolicyType | undefined) {
  switch (cancellationPolicyType) {
    case 'prior-to-check-in-one-day':
      return ONE_DAY_BEFORE_CHECK_IN
    case 'prior-to-check-in-two-days':
      return TWO_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-three-days':
      return THREE_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-seven-days':
      return SEVEN_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-fourteen-days':
      return FOURTEEN_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-twenty-one-days':
      return TWENTY_ONE_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-twenty-eight-days':
      return TWENTY_EIGHT_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-thirty-one-days':
      return THIRTY_ONE_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-forty-five-days':
      return FORTY_FIVE_DAYS_BEFORE_CHECK_IN
    case 'prior-to-check-in-sixty-days':
      return SIXTY_DAYS_BEFORE_CHECK_IN
    default:
      return 0
  }
}

export function isAlwaysOnCancellationPolicyRefundable(cancellationPolicyType: App.CancellationPolicyType | undefined) {
  switch (cancellationPolicyType) {
    case 'prior-to-check-in-one-day':
    case 'prior-to-check-in-two-days':
    case 'prior-to-check-in-three-days':
    case 'prior-to-check-in-seven-days':
    case 'prior-to-check-in-fourteen-days':
    case 'prior-to-check-in-twenty-one-days':
    case 'prior-to-check-in-twenty-eight-days':
    case 'prior-to-check-in-thirty-one-days':
    case 'prior-to-check-in-forty-five-days':
    case 'prior-to-check-in-sixty-days':
      return true
    case 'dynamic':
      return config.DEPOSITS_DYNAMIC_LPC_ENABLED
    default:
      return false
  }
}

export function getAlwaysOnDynamicCancellationPolicyDate(cancellationPolicies?: ConnectionCancellationPolicies): Date | null {
  if (!cancellationPolicies?.policies?.length) {
    return null
  }
  const deadline = cancellationPolicies.policies[0]?.deadline ?? null
  const timezone = cancellationPolicies.policies[0]?.timezone ?? null

  return formatDate.convertDateByTimezone(deadline, timezone)
}

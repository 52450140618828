import React from 'react'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'

interface Props {
  className?: string;
}

function BusinessTravellerSelectCarHireDisabled(props: Props) {
  const { className } = props

  return (<SearchFormField
    className={className}
    label="Travellers"
    value="1 Traveller"
    disabled
  />)
}

export default BusinessTravellerSelectCarHireDisabled

import { FlightDropdownOption } from 'components/Flights/types'
import React from 'react'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import Group from 'components/utils/Group'
import styled from 'styled-components'
import { rem } from 'polished'

const RadioContainer = styled(Group)`
  position: relative;
`

const NewLabel = styled.span`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  padding: 0 ${rem(2)};
  border-radius: ${props => props.theme.borderRadius.S};
  font-weight: ${props => props.theme.font.primary.weight.bold};
  font-size: ${rem(10)};
  line-height: ${rem(13)};
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(84%, -84%);
`

interface Props {
  options: Array<FlightDropdownOption>;
  selectedOption?: FlightDropdownOption;
  className?: string;
  'data-testid'?: string;
  onOptionChange: (option: FlightDropdownOption) => void;
}

function FareRadio(props: Props) {
  const { options, selectedOption, onOptionChange } = props

  return (
    <Group direction="horizontal" verticalAlign="center" gap={16}>
      {options.map((option) => (
        <RadioContainer direction="horizontal" key={option.value}>
          <RadioInput
            checked={selectedOption?.value === option.value}
            onClick={() => onOptionChange(option)}
            name="fareType"
            value={option.value}
            size="medium"
          >
            {option.label}
          </RadioInput>

          {option.isNew && <NewLabel>NEW</NewLabel>}
        </RadioContainer>
      ))}
    </Group>
  )
}

export default FareRadio

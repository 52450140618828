import { min } from 'lib/array/arrayUtils'
import getDistanceFromLatLong from 'lib/flights/getDistanceFromLatLong'

function findClosestAirport(
  airportList: Array<App.Airport> = [],
  latitude?: number | null | undefined,
  longitude?: number | null | undefined,
): App.Airport | undefined {
  // double equals checks null/undefined but will let 0 go through
  if (latitude == null || longitude == null) {
    return undefined
  }

  return min(airportList, airport => getDistanceFromLatLong(latitude, longitude, airport.latitude, airport.longitude))
}

export default findClosestAirport

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineKeySkeletonAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m21.71 6.53-1.42-1.41 1.42-1.41a1.0041 1.0041 0 1 0-1.42-1.42L9.75 12.83a5 5 0 1 0 1.42 1.42l4.88-4.89 1.41 1.42a.9993.9993 0 0 0 .71.29 1.0007 1.0007 0 0 0 .71-.29 1.0019 1.0019 0 0 0 .2189-.3254 1.0016 1.0016 0 0 0 0-.7692A1.001 1.001 0 0 0 18.88 9.36L17.46 8l1.42-1.42L20.29 8a.999.999 0 0 0 .71.29 1.0003 1.0003 0 0 0 .71-.29.9999.9999 0 0 0 0-1.47ZM7 20a2.9996 2.9996 0 0 1-2.7717-1.8519 3.0001 3.0001 0 0 1 3.9197-3.9197A3 3 0 0 1 7 20Z"/>
  </SvgIcon>
}

export default LineKeySkeletonAltIcon

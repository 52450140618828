import React, { useMemo } from 'react'
import { SEARCH_VERTICALS } from 'constants/search'
import TabButtonsGroup from 'components/Luxkit/Tabs/TabButtonsGroup'
import TabButton from 'components/Luxkit/Tabs/TabButton'
import styled from 'styled-components'
import { rem } from 'polished'

export interface GlobalSearchTab {
  key: SEARCH_VERTICALS
  label: string
}

const StyledTabButtonsGroup = styled(TabButtonsGroup)`
  padding: 0 ${rem(16)};
`

interface Props {
  selectedTabKey: SEARCH_VERTICALS
  tabs: Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTab>
  onTabSelect?: (selectedTab: SEARCH_VERTICALS) => void
}

function GlobalSearchTabs(props: Props) {
  const { selectedTabKey, tabs, onTabSelect } = props
  const tabsArray = useMemo(() => [...tabs.entries()], [tabs])

  return <StyledTabButtonsGroup>
    {tabsArray.map(([key, { label, Icon }]) => <TabButton
      size="medium"
      key={key}
      isActive={selectedTabKey === key}
      onClick={() => onTabSelect?.(key)}
      startIcon={<Icon />}
      format="propercase"
    >
      {label}
    </TabButton>)}
  </StyledTabButtonsGroup>
}

export default GlobalSearchTabs

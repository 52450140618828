import request from 'api/requestUtils'

type ResendActivationEmailResponse = boolean

async function requestPostResendActivationEmail(agentId: string) {
  const response = await request.post<App.ApiResponse<ResendActivationEmailResponse>, {}>(
    `/api/agent/user/${agentId}/resend-activation-email`,
    {},
  )

  return response.result
}

export default requestPostResendActivationEmail

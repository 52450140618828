import { useEffect, useState, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { getAirportsSearch } from 'api/flights'
import { filterAirportRecords } from 'lib/flights/flightUtils'

interface Options {
  excludedAirport?: App.AirportLocation;
}

function useAirportSearch(phrase: string = '', options: Options = {}): [Array<App.AirportLocation>, boolean] {
  const [airports, setAirports] = useState<Array<App.AirportLocation>>([])
  const [loading, setLoading] = useState(false)

  const debouncedLoadAirports = useMemo(() => debounce((value: string) => {
    getAirportsSearch(value).then(airports => {
      setAirports(filterAirportRecords(airports, options.excludedAirport))
    }).finally(() => {
      setLoading(false)
    })
  }, 500),
  [options.excludedAirport])

  useEffect(() => {
    if (phrase.length > 2) {
      setLoading(true)
      debouncedLoadAirports(phrase)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phrase])

  return [
    airports,
    loading,
  ]
}

export default useAirportSearch

import React from 'react'
import Plural from 'components/utils/Formatters/Plural'
import { formatDecimal } from 'lib/format/formatNumber'
import { getPlural } from 'lib/string/pluralize'
import { useAppSelector } from 'hooks/reduxHooks'
import { getLuxLoyaltyProgramConfigDetails } from 'luxLoyalty/selectors/utils'
import { LUX_LOYALTY_TIER_DISPLAY_NAMES } from 'luxLoyalty/constants/luxLoyaltyContent'

export interface LuxLoyaltyCopyProps {
  type: 'welcome-gift' | 'points-and-status-credits' | 'unlock-status' | 'earn-redeem' | 'earn-points' | 'earn-on-this-order';
  tier?: App.LuxLoyaltyTier;
  earnablePoints?: number;
  statusCreditsEarned?: number;
}

function LuxLoyaltyCopy(props: LuxLoyaltyCopyProps) {
  const { type, tier = 'silver', earnablePoints = 0, statusCreditsEarned = 0 } = props
  const configDetails = useAppSelector(getLuxLoyaltyProgramConfigDetails)
  const currencyName = configDetails.currencyName
  const statusCreditsName = configDetails.statusCreditsName
  const tierConfig = configDetails.tiersConfigs[tier]
  const welcomePoints = tierConfig.welcomePoints

  switch (type) {
    case 'welcome-gift':
      return <>Enjoy a <b>{formatDecimal(welcomePoints)} {currencyName}</b> welcome gift</>
    case 'points-and-status-credits':
      return <>Earn <b>
        <Plural singular={currencyName} count={earnablePoints} withCount /> + <Plural singular={statusCreditsName} count={statusCreditsEarned} withCount />
      </b> for this booking</>
    case 'unlock-status':
      return <>{(tier === 'platinum') ? null : `Unlock ${tier === 'silver' ? 'Gold or ' : ''}`}Platinum status as you spend</>
    case 'earn-redeem':
      return <>Earn & redeem {getPlural(currencyName)} on every booking</>
    case 'earn-points':
      return <>Earn <Plural singular={currencyName} count={earnablePoints} withCount /></>
    case 'earn-on-this-order':
      return <>Earn <Plural singular={currencyName} count={earnablePoints} withCount /> on this order as a {LUX_LOYALTY_TIER_DISPLAY_NAMES[tier]} Traveller</>
    default:
      return null
  }
}

export default LuxLoyaltyCopy

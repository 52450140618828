import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { setGeoRedirect, Status } from 'storage/geo'
import { connect } from 'react-redux'
import setRegionCookie from 'cookies/setRegionCookie'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import TextButton from 'components/Luxkit/Button/TextButton'
import Flag from 'components/Luxkit/Flag'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import config from 'constants/config'
import { RouterLocation } from 'connected-react-router'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

const FooterButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  width: 100%;
`

interface Props {
  accountRegionCode: string;
  currentRegionCode: string;
  location: RouterLocation<unknown>;
}

function GeoRedirectModal(props: Props) {
  const { accountRegionCode, currentRegionCode, location } = props
  const accountRegion = useMemo(() => config.regions.find(region => region.code === accountRegionCode)!, [accountRegionCode])
  const currentRegion = useMemo(() => config.regions.find(region => region.code === currentRegionCode)!, [currentRegionCode])

  const modalContext = useModalElementContext()
  const onRegionChange = () => {
    setRegionCookie(accountRegionCode)
    setGeoRedirect(Status.redirected)
    modalContext.resolve()
    window.location.href = location.pathname.replace(/^\/[a-z]+/, '/' + accountRegionCode.toLowerCase()) + location.search
  }

  const onIgnoreClick = () => {
    setRegionCookie(currentRegionCode)
    setGeoRedirect(Status.ignore)
    modalContext.resolve()
  }

  return (
    <ModalBase
      size="S"
      onClose={onIgnoreClick}
    >
      <ModalHeader title="Are you in the right place?" />
      <ModalBody>
        <ModalContent>
          <BodyText variant="large">
            Looks like you're based in {accountRegion.name}, please select the correct site to browse exclusive offers in your local currency ({accountRegion.currencyCode}).
          </BodyText>
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <FooterButtons>
          <TextButton
            startIcon={<Flag countryCode={accountRegion.code} />}
            kind="secondary"
            variant="dark"
            fit="full-width"
            size="large"
            onClick={onRegionChange}
          >
            Go to {accountRegion.name} site
          </TextButton>
          <TextButton
            startIcon={<Flag countryCode={currentRegion?.code } />}
            kind="secondary"
            variant="dark"
            fit="full-width"
            size="large"
            data-testid="ignore-geo-redirect-modal"
            onClick={onIgnoreClick}
          >
            Stay on {currentRegion?.name } site
          </TextButton>
        </FooterButtons>
      </ModalFooter>
    </ModalBase>
  )
}

function mapStateToProps(state: App.State) {
  return {
    accountRegionCode: state.auth.account.countryCode,
    currentRegionCode: state.geo.currentRegionCode,
    location: state.router.location,
  }
}

export default connect(mapStateToProps)(GeoRedirectModal)

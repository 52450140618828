import { createSelector } from 'reselect'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { getLuxPlusBreakdownView, getSubscriptionJoinItemView } from 'checkout/selectors/view/luxPlusSubscription'

export const getLuxPlusSubscriptionTotals = createSelector(
  getLuxPlusBreakdownView,
  (viewWithStatus): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => (itemView.price ?? 0)),
        value: sum(definedItems, itemView => (itemView.price ?? 0)),
        memberPrice: 0,
        surcharge: 0,
        taxesAndFees: 0,
      },
    }
  },
)

export const getSubscriptionJoinFeeTotals = createSelector(
  getSubscriptionJoinItemView,
  (view): App.WithDataStatus<App.Checkout.ItemTotals> => {
    return {
      hasRequiredData: true,
      data: {
        price: sum(view.data, view => view.price),
        value: sum(view.data, view => view.value || view.price),
        memberPrice: 0,
        taxesAndFees: 0,
        surcharge: 0,
      },
    }
  },
)

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTicketIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9 10a1.0002 1.0002 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1Zm12 1a1.0001 1.0001 0 0 0 1-1V6a1.0002 1.0002 0 0 0-1-1H3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1 1.0002 1.0002 0 0 1 .7071 1.7071A1.0002 1.0002 0 0 1 3 13a1.0002 1.0002 0 0 0-1 1v4a1 1 0 0 0 1 1h18a1.0001 1.0001 0 0 0 1-1v-4a1.0001 1.0001 0 0 0-1-1 1.0001 1.0001 0 0 1 0-2Zm-1-1.82a3.0002 3.0002 0 0 0-1.4332 4.5418A3 3 0 0 0 20 14.82V17H10a1 1 0 1 0-2 0H4v-2.18A2.9997 2.9997 0 0 0 5.9765 12 2.9998 2.9998 0 0 0 4 9.18V7h4a1 1 0 0 0 2 0h10v2.18Z"/>
  </SvgIcon>
}

export default LineTicketIcon

import { AppAction } from 'actions/ActionTypes'
import { API_CALL } from 'actions/actionConstants'
import requestGetApprovalPolicy from 'businessTraveller/api/requestGetApprovalPolicy'
import { BUSINESS_TRAVELLER_API_ACTIONS } from 'reducers/businessTravellerApiActionReducers'

function fetchBusinessApprovalPolicy(
  businessId: string,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const policy = state.businessTraveller.businessApprovalPolicy.policy

    if (!policy) {
      dispatch({
        type: API_CALL,
        api: BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_APPROVAL_POLICY,
        request: () => requestGetApprovalPolicy({
          businessId,
        }),
      })
    }
  }
}

export default fetchBusinessApprovalPolicy

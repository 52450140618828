import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import React from 'react'
import TourDiscountLabel from './TourDiscountLabel'
import TourV2LuxPlusUrgencyTag from './TourV2LuxPlusUrgencyTag'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'

type UrgencyLabelVariant = React.ComponentProps<typeof UrgencyLabel>['variant']
const urgencyLabelTypeToVariant: Utils.PartialRecord<App.UrgencyLabelType, UrgencyLabelVariant> = {
  critical: 'warning',
  fast: 'speed',
  left: 'critical2',
  limited: 'warning',
  new: 'positive',
  popular: 'positive',
}

interface Props {
  label: App.OfferUrgencyLabel;
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
}

function TourV2UrgencyLabel(props: Props) {
  const { label, offer } = props

  switch (label.type) {
    case 'agenthub_exclusive':
      return <PromotionLabel variant="engagement">Agent exclusive</PromotionLabel>
    case 'bestseller':
      return <PromotionLabel variant="default" icon={<LineAwardIcon />} data-testid="UrgencyLabel">
        Bestseller
      </PromotionLabel>
    case 'sale':
      return <TourDiscountLabel key="sale" />
    case 'departuresale':
      return <TourDiscountLabel saleOnDepartures />
    case 'lux_plus_member_price':
      return <TourV2LuxPlusUrgencyTag offer={offer} variant="memberPrice" />
    case 'lux_plus_early_access':
      return <TourV2LuxPlusUrgencyTag offer={offer} variant="earlyAccess" />
    case 'lux_plus_general_release':
      if (!label.end) {
        return null
      }

      return <OfferUrgencyCountdownLabel
        endDate={label.end}
        isLuxPlusEarlyAccess
      />
    default:
      const variant = urgencyLabelTypeToVariant[label.type] ?? 'positive'
      return <UrgencyLabel variant={variant}>
        {label.message}
      </UrgencyLabel>
  }
}

export default TourV2UrgencyLabel

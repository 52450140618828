import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'

const getPaymentScheduleDepositAmount = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    const depositAmount = paymentSchedule.payment_schedules.find(schedule => schedule.paymentType === 'deposit')?.expectedAmount || 0
    return floatify(depositAmount)
  },
)

export default getPaymentScheduleDepositAmount

import GeoContext from 'contexts/geoContext'
import { formatInlineList, InlineListFormatOptions, ListFormatterOptions } from 'lib/format/formatList'
import { useCallback, useContext } from 'react'
import useStableObjectReference from './useStableObjectReference'

type InlineListFormatter = (list: Array<string>) => string

export function useInlineListFormatter(
  type: ListFormatterOptions['type'],
  options: InlineListFormatOptions = {},
): InlineListFormatter {
  const { currentRegionCode } = useContext(GeoContext)
  const stableOptions = useStableObjectReference(options)
  const formatter = useCallback<InlineListFormatter>((list) => {
    return formatInlineList(list, type, { ...stableOptions, regionCode: currentRegionCode })
  }, [currentRegionCode, stableOptions, type])

  return formatter
}

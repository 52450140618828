import ListItem from 'components/Luxkit/List/ListItem'
import React from 'react'

interface Props {
  inputValue: string;
}

function NoAirportsOptionListItem(props: Props) {
  const { inputValue } = props

  return (
    <ListItem
      nonInteractable
      title={`Sorry, we couldn't find "${inputValue}"`}
      subtitle="Check your spelling, or try searching for a new airport."
    />
  )
}

export default NoAirportsOptionListItem

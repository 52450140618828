import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import ExperienceMap from 'components/Experiences/ExperienceMap'

const LocationDetails = styled.div`
  > * + * {
    margin-top: ${rem(16)}
  }
`

const STYLE = {
  height: 500,
  width: '100%',
  flexGrow: 1,
}

interface Props {
  experienceView: App.ExperienceBookingView;
  pickupPoint?: App.ExperiencePickupPoint;
  selectedLocation?: App.ExperienceRedemptionLocation;
  onZoomChanged?: (zoom: boolean) => void;
}

function ExperienceDetailsAboutLocationMap(props: Props) {
  const { experienceView, pickupPoint, selectedLocation, onZoomChanged } = props

  const whereText = useMemo(() => {
    if (selectedLocation?.name) {
      return selectedLocation?.name
    }
    // if only one redemption location, show the default location name
    return (experienceView.originalExperience?.redemptionLocations?.length > 1) ?
      'Multiple Locations' :
      experienceView.originalExperience?.copy?.whereText
  }, [experienceView, selectedLocation])

  const experienceMarker = useMemo<App.MapMarker>(() => {
    const marker: App.MapMarker = {
      id: 'experience',
      type: 'moments',
      latitude: experienceView.originalExperience.location.latitude,
      longitude: experienceView.originalExperience.location.longitude,
    }

    if (!marker.latitude || !marker.longitude) { return undefined }

    if (selectedLocation) {
      marker.latitude = selectedLocation.latitude
      marker.longitude = selectedLocation.longitude
    }

    return marker
  }, [experienceView, selectedLocation])

  const locationMapMarkerList = useMemo<Array<App.MapMarker>>(() => {
    const markers = [...experienceView.interestingLocations]

    if (experienceView.originalExperience.redemptionLocations?.length > 1) {
      markers.push(...experienceView.originalExperience.redemptionLocations.map(location => ({
        id: location.id,
        type: 'see-and-do',
        latitude: location.latitude,
        longitude: location.longitude,
      }) as App.MapMarker))
    }
    return markers
  }, [experienceView])

  const pickupPointMarker = useMemo<App.MapMarker>(() => {
    if (pickupPoint) {
      return {
        id: 'pickupPoint',
        type: 'see-and-do',
        latitude: pickupPoint.latitude,
        longitude: pickupPoint.longitude,
      }
    }
  }, [pickupPoint])

  return (
    <LocationDetails>
      {whereText &&
        <MarkdownRender fontWeight="bold" content={whereText} />
      }
      <ExperienceMap
        title={`Experience: ${experienceView.title}`}
        googleMapId="le-map"
        zoomLevel={17}
        primaryMapMarker={experienceMarker}
        pickupMapMarker={pickupPointMarker}
        locationMapMarkerList={locationMapMarkerList}
        style={STYLE}
        onZoomChanged={onZoomChanged}
      />
      {experienceView.originalExperience?.copy.meetingPoint && <div>
        <BodyText variant="large" weight="bold">Meeting point</BodyText>{' '}
        <MarkdownRender content={experienceView.originalExperience.copy.meetingPoint} />
      </div>}
    </LocationDetails>
  )
}

export default ExperienceDetailsAboutLocationMap

if (!HTMLFormElement.prototype.reportValidity) {
  HTMLFormElement.prototype.reportValidity = function() {
    if (this.checkValidity()) return true
    var btn = document.createElement('button')
    this.appendChild(btn)
    btn.click()
    this.removeChild(btn)
    return false
  }
}

/* polyfill reportValidity for HTML elements for user inputs */
function inputReportValidity() {
  if (this.checkValidity()) return true
  var tmpForm
  if (!this.form) {
    tmpForm = document.createElement('form')
    tmpForm.style.display = 'inline'
    this.before(tmpForm)
    tmpForm.append(this)
  }
  var siblings = Array.from(this.form.elements).filter(function(input: any) {
    return input !== this && !!input.checkValidity && !input.disabled
  }, this)
  siblings.forEach(function(input: any) {
    input.disabled = true
  })
  this.form.reportValidity()
  siblings.forEach(function(input: any) {
    input.disabled = false
  })
  if (tmpForm) {
    tmpForm.before(this)
    tmpForm.remove()
  }
  this.focus()
  this.selectionStart = 0
  return false
}

if (!HTMLInputElement.prototype.reportValidity) {
  HTMLInputElement.prototype.reportValidity = inputReportValidity
}
if (!HTMLSelectElement.prototype.reportValidity) {
  HTMLSelectElement.prototype.reportValidity = inputReportValidity
}
if (!HTMLTextAreaElement.prototype.reportValidity) {
  HTMLTextAreaElement.prototype.reportValidity = inputReportValidity
}

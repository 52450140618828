import isPropValid from '@emotion/is-prop-valid'
import { StyledTarget } from 'styled-components/dist/types'

// This implements the default behavior from styled-components v5
// to assist in migrating to v6.
export function shouldForwardProp(propName: string, target: StyledTarget<'web'>) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineClockEightIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8.0004 8.0004 0 0 1-7.391-4.9385A8 8 0 1 1 11 20Zm0-14a1 1 0 0 0-1 1v4.42l-2.1 1.21a1.0003 1.0003 0 0 0-.473 1.1283.9999.9999 0 0 0 .973.7417.9999.9999 0 0 0 .5-.13l2.6-1.5.09-.09.16-.13a.863.863 0 0 0 .1-.16.891.891 0 0 0 .08-.17.6495.6495 0 0 0 .05-.2L12 12V7a1.0002 1.0002 0 0 0-1-1Z"/>
  </SvgIcon>
}

export default LineClockEightIcon

export default function findCheapestTourV2PurchasableOption(
  offer: Tours.TourV2OfferSummary | Tours.TourV2Offer,
  selectedVariationId?: Tours.TourV2OfferVariation['id'],
  isLuxPlusToursAvailable = false,
  prioritisedRoomType: Tours.V2OfferRoomType = 'twin',
  departurePredicate: (departure: Tours.TourV2OfferDeparture) => boolean = (departure) => departure.status === 'available',
): Tours.TourV2OfferPurchasableOption | undefined {
  const { departures, purchasableOptions } = offer || {}

  if (!departures || Object.keys(departures).length === 0 || purchasableOptions.length === 0) {
    return undefined
  }

  const cheapestPurchasableOptions = purchasableOptions.reduce<{
    other?: Tours.TourV2OfferPurchasableOption,
    prioritised?: Tours.TourV2OfferPurchasableOption
  }>((
    cheapest,
    purchasableOption,
  ) => {
    if (
      departurePredicate(departures[purchasableOption.fkDepartureId]) &&
      (!selectedVariationId || purchasableOption.fkVariationId === selectedVariationId)
    ) {
      const purchasableOptionPrice = isLuxPlusToursAvailable && purchasableOption.memberPrice ? purchasableOption.memberPrice : purchasableOption.price
      const cheapestPrioritisedPrice = isLuxPlusToursAvailable && cheapest.prioritised?.memberPrice ? cheapest.prioritised.memberPrice : cheapest.prioritised?.price
      const cheapestOtherPrice = isLuxPlusToursAvailable && cheapest.other?.memberPrice ? cheapest.other.memberPrice : cheapest.other?.price
      if (
        purchasableOption.roomType === prioritisedRoomType &&
        (!cheapestPrioritisedPrice || purchasableOptionPrice < cheapestPrioritisedPrice)
      ) {
        return { ...cheapest, prioritised: purchasableOption }
      }

      if (!cheapestOtherPrice || purchasableOptionPrice < cheapestOtherPrice) {
        return { ...cheapest, other: purchasableOption }
      }
    }

    return cheapest
  }, {
    other: undefined,
    prioritised: undefined,
  })

  return cheapestPurchasableOptions.prioritised ?? cheapestPurchasableOptions.other
}

import Clickable from 'components/Common/Clickable'
import CruisesDiscountTsAndCsModal from 'components/Cruises/CruisesDiscountTsAndCsModal'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import PriceRowValueDiscount from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscount'
import Group from 'components/utils/Group'
import ModalContext from 'contexts/ModalContext'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import React, { MouseEventHandler, useCallback, useContext } from 'react'

interface Props {
  view: CruiseOfferView
}

function CruiseSearchTilePricingInfo({ view }: Props) {
  const {
    discountPills,
  } = view

  const showModal = useContext(ModalContext)
  const openDiscountModal = useCallback<MouseEventHandler<HTMLOrSVGElement>>((event) => {
    event.preventDefault()
    showModal(<CruisesDiscountTsAndCsModal />)
  }, [showModal])

  return <div className={CruiseSearchTilePricingInfo.name}>
    {discountPills?.discountPercentage && <Group direction="horizontal" verticalAlign="center" gap={4}>
      <PriceRowValueDiscount
        type="estimate"
        discountPercentage={discountPills.discountPercentage}
      />
      <Clickable onClick={openDiscountModal}><SolidInfoCircleIcon size="XS" /></Clickable>
    </Group>}
    <PriceRowCaption>All prices include taxes & fees</PriceRowCaption>
  </div>
}

export default CruiseSearchTilePricingInfo

import { MultipleRoomPackage } from 'contexts/OfferPage/offerPageStateReducer'

/**
 * Removes selected packages that doesn't have a valid roomId
 * @param rooms Current rooms
 * @param selectedPackages Current selected packages
 * @returns New MultipleRoomPackage[] with invalid packages removed
 */
export function removeRoomlessSelectedPackages(
  rooms: Array<App.RoomOccupants>,
  selectedPackages: Array<MultipleRoomPackage> = [],
) : Array<MultipleRoomPackage> {
  const currentRoomIds = new Set(rooms.map(room => room.roomId))
  return selectedPackages.filter(pkg => currentRoomIds.has(pkg.roomId))
}

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSuitcaseIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 6.5h-3v-1a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3Zm-9-1a1.0002 1.0002 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4v-1Zm10 13a1.0001 1.0001 0 0 1-1 1H5a1.0002 1.0002 0 0 1-1-1v-5.05h3v1.05a1 1 0 1 0 2 0v-1.05h6v1.05a1.0001 1.0001 0 0 0 2 0v-1.05h3v5.05Zm0-7H4v-2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2Z"/>
  </SvgIcon>
}

export default LineSuitcaseIcon

import { AppAction } from 'actions/ActionTypes'
import { getBusinessTravellerSelectedTravellerState } from 'businessTraveller/storage/businessTravellerStorage'
import setSelectedBusinessTravellersAndGuests from './setSelectedBusinessTravellerEmployeesAndGuests'

export function restoreSelectedBusinessTravellerEmployeeIds(): AppAction {
  return (dispatch) => {
    const storedBusinessTravellerSelectState = getBusinessTravellerSelectedTravellerState()
    if (storedBusinessTravellerSelectState) {
      dispatch(setSelectedBusinessTravellersAndGuests(storedBusinessTravellerSelectState.employeeIds, storedBusinessTravellerSelectState.guestCount))
    }
  }
}

export default restoreSelectedBusinessTravellerEmployeeIds

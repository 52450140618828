import { getDestinationCountriesFromOrder } from 'lib/order/orderInsuranceUtils'
import { createSelector } from 'reselect'

const getDestinationCountriesFromExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.cruise.cruiseOffers,
  (order, cruiseOffers): Array<string> => {
    if (order) {
      return getDestinationCountriesFromOrder(order, cruiseOffers)
    }

    return []
  },
)

export default getDestinationCountriesFromExistingOrder

import { fetchPopularAirports } from 'actions/FlightActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect, useMemo } from 'react'

interface Options {
  /**
   * Exclude this airport from the result
   */
  excludeAirport?: App.AirportLocation
}

function usePopularAirports(direction: 'arrival' | 'departure', options: Options = {}) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPopularAirports())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const popularAirports = useAppSelector(state => state.flights.popularAirports)

  const airports = direction === 'departure' ? popularAirports.origins : popularAirports.destinations

  return useMemo(() => {
    if (options.excludeAirport) {
      return airports.filter(airport => airport.airportCode !== options.excludeAirport?.airportCode)
    }
    return airports
  }, [airports, options.excludeAirport])
}

export default usePopularAirports

import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'
import React from 'react'
import DurationPicker, { DurationOption } from './DurationPicker'
import MonthPicker from './MonthPicker'

interface Props {
  onDurationChange?: (duration: FLEXIBLE_DURATION_RANGE) => void;
  onMonthChange?: (monthOptions: string) => void;
  onDatesChange?: () => void;
  previouslySelectedDuration?: FLEXIBLE_DURATION_RANGE;
  previouslySelectedMonths?: string;
  durationOptionsCustom?: Array<DurationOption>;
  showYearSelector?: boolean;
}

function FlexibleDatePicker(props: Props) {
  const {
    onDurationChange,
    onMonthChange,
    previouslySelectedDuration,
    previouslySelectedMonths,
    durationOptionsCustom,
    showYearSelector,
  } = props
  return (
    <Group direction="vertical" gap={24} horizontalAlign="center">
      <Group direction="vertical" gap={16} horizontalAlign="center">
        <Heading variant="heading6">How long?</Heading>
        <DurationPicker
          durationOptionsCustom={durationOptionsCustom}
          previouslySelectedDuration={previouslySelectedDuration}
          onDurationChange={onDurationChange}
        />
      </Group>
      <Group direction="vertical" gap={16} horizontalAlign="center">
        <Heading variant="heading6">When?</Heading>
        <MonthPicker
          previouslySelectedMonths={previouslySelectedMonths}
          onMonthChange={onMonthChange}
          showYearSelector={showYearSelector}
        />
      </Group>
    </Group>
  )
}

export default FlexibleDatePicker

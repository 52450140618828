import React, { useMemo, useContext } from 'react'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import GeoContext from 'contexts/geoContext'
import OfferInclusionItem from 'components/Common/Inclusions/OfferInclusionItem'
import CruiseLuxPlusInclusionList from './CruiseLuxPlusInclusionList'

import { buildEvergreenPackageInclusions, buildLegacyPackageInclusions } from 'lib/cruises/cruiseInclusionsUtils'
import styled from 'styled-components'
import { rem } from 'polished'

const PromotionListWrapper = styled.div`
  padding: ${rem(4)} 0;
`

interface Props {
  offer: App.CruiseOffer;
  promotionDetails?: App.CruisePromotionDetails | null
  evergreenInclusions?: Array<App.EvergreenInclusion>
  tileInclusions?: Array<App.OfferInclusion>
  standardInclusions: Array<App.PackageInclusion>;
  luxPlusInclusions: Array<App.PackageInclusion>;
}

function CruiseInclusionList({ offer, standardInclusions, luxPlusInclusions, evergreenInclusions, tileInclusions, promotionDetails } : Props) {
  const geo = useContext(GeoContext)

  const allStandardInclusions = useMemo(() => {
    const evergreenPackageInclusions = buildEvergreenPackageInclusions(evergreenInclusions || [])
    const flashOfferInclusions = tileInclusions || []

    // TODO: Remove support for legacy inclusions - https://aussiecommerce.atlassian.net/browse/CRUZ-2646
    // Display legacy inclusions if there are no new inclusions
    const legacyPackageInclusions = !standardInclusions.length ?
      buildLegacyPackageInclusions(promotionDetails || null, geo.currentRegionCode) : []

    return [
      ...evergreenPackageInclusions,
      ...standardInclusions,
      ...legacyPackageInclusions,
      ...flashOfferInclusions,
    ].map((inclusion, index) => {
      const isHighlighted = index === 0

      const symbol = inclusion.symbol || (isHighlighted ? 'tag' : undefined)
      return {
        ...inclusion,
        symbol,
        isHighlighted,
      }
    }) as Array<App.PackageInclusion>
  }, [evergreenInclusions, standardInclusions, tileInclusions, promotionDetails, geo.currentRegionCode])

  const hasInclusions = !!allStandardInclusions.length || !!luxPlusInclusions?.length

  return <>
    {hasInclusions && <PromotionListWrapper>
      <Group direction="vertical" gap={8} horizontalAlign="start">
        <CruiseLuxPlusInclusionList luxPlusInclusions={luxPlusInclusions} offer={offer} />

        {!!allStandardInclusions.length && <>
          {!!allStandardInclusions.length && <BodyText weight="semi-bold" variant="medium">
            Inclusions (select packages only)
          </BodyText>}

          <Group direction="vertical" gap={4}>
            {allStandardInclusions.map((inclusion) => <OfferInclusionItem
              key={inclusion.id}
              inclusion={inclusion}
              colour={inclusion.isHighlighted ? 'highlight-secondary' : undefined}
              showMinStayDescription
            />)}
          </Group>
        </>}
      </Group>
    </PromotionListWrapper>}
  </>
}

export default CruiseInclusionList

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidAppleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14.94 5.19A4.379 4.379 0 0 0 16 2a4.44 4.44 0 0 0-3 1.52 4.1706 4.1706 0 0 0-1 3.09 3.6902 3.6902 0 0 0 2.94-1.42Zm2.52 7.44a4.5095 4.5095 0 0 1 2.16-3.81 4.6604 4.6604 0 0 0-3.66-2c-1.56-.16-3 .91-3.83.91-.83 0-2-.89-3.3-.87a4.92 4.92 0 0 0-4.14 2.53C2.93 12.45 4.24 17 6 19.47c.8 1.21 1.8 2.58 3.12 2.53 1.32-.05 1.75-.82 3.28-.82 1.53 0 2 .82 3.3.79 1.3-.03 2.22-1.24 3.06-2.45a11.0003 11.0003 0 0 0 1.38-2.85 4.4103 4.4103 0 0 1-2.68-4.04Z"/>
  </SvgIcon>
}

export default SolidAppleIcon

import { useMemo } from 'react'
import { SEARCH_VERTICALS } from 'constants/search'
import { arrayToMap } from 'lib/array/arrayUtils'
import useGlobalSearchVerticalTabHotels from './useGlobalSearchVerticalTabHotels'
import useGlobalSearchVerticalTabTours from './useGlobalSearchVerticalTabTours'
import useGlobalSearchVerticalTabExperiences from './useGlobalSearchVerticalTabExperiences'
import useGlobalSearchVerticalTabFlights from './useGlobalSearchVerticalTabFlights'
import config from 'constants/config'
import useGlobalSearchVerticalTabCruises from './useGlobalSearchVerticalTabCruises'
import useGlobalSearchVerticalTabCarHire from './useGlobalSearchVerticalTabCarHire'
import useGlobalSearchVerticalTabHomesAndVillas from './useGlobalSearchVerticalTabHomesAndVillas'

export const SEARCH_VERTICAL_ITEMS = new Map(config.searchVerticalItems)

function useGlobalSearchVerticalTabsV2(): Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTabV2> {
  const globalSearchVerticalTabHotels = useGlobalSearchVerticalTabHotels(SEARCH_VERTICAL_ITEMS.get(SEARCH_VERTICALS.HOTELS))
  const globalSearchVerticalTabHomesAndVillas = useGlobalSearchVerticalTabHomesAndVillas(SEARCH_VERTICAL_ITEMS.get(SEARCH_VERTICALS.HOMES_AND_VILLAS))
  const globalSearchVerticalTabTours = useGlobalSearchVerticalTabTours(SEARCH_VERTICAL_ITEMS.get(SEARCH_VERTICALS.TOURS))
  const globalSearchVerticalTabExperiences = useGlobalSearchVerticalTabExperiences(SEARCH_VERTICAL_ITEMS.get(SEARCH_VERTICALS.EXPERIENCES))
  const globalSearchVerticalTabFlights = useGlobalSearchVerticalTabFlights(SEARCH_VERTICAL_ITEMS.get(SEARCH_VERTICALS.FLIGHTS))
  const globalSearchVerticalTabCruises = useGlobalSearchVerticalTabCruises(SEARCH_VERTICAL_ITEMS.get(SEARCH_VERTICALS.CRUISES))
  const globalSearchVerticalTabCarHire = useGlobalSearchVerticalTabCarHire(SEARCH_VERTICAL_ITEMS.get(SEARCH_VERTICALS.CARHIRE))

  const globalSearchVerticals = useMemo(() => {
    const availableTabs = [
      globalSearchVerticalTabHotels,
      globalSearchVerticalTabHomesAndVillas,
      globalSearchVerticalTabTours,
      globalSearchVerticalTabCruises,
      globalSearchVerticalTabCarHire,
      globalSearchVerticalTabFlights,
      globalSearchVerticalTabExperiences,
    ].filter(tab => tab.isEnabled)

    return arrayToMap(availableTabs, (tab) => tab.key, (tab) => tab)
  }, [
    globalSearchVerticalTabHotels,
    globalSearchVerticalTabHomesAndVillas,
    globalSearchVerticalTabTours,
    globalSearchVerticalTabExperiences,
    globalSearchVerticalTabFlights,
    globalSearchVerticalTabCruises,
    globalSearchVerticalTabCarHire,
  ])

  if (config.searchVerticals) {
    const sortedSearchVerticals = [...globalSearchVerticals].sort((a, b) => config.searchVerticals.indexOf(a[0]) - config.searchVerticals.indexOf(b[0]))
    return new Map(sortedSearchVerticals)
  }

  return globalSearchVerticals
}

export default useGlobalSearchVerticalTabsV2

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineShipIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3.62 17.28a1.0006 1.0006 0 0 0 1.3.56 1.0008 1.0008 0 0 0 .56-1.3l-1.12-2.82L11 12.25V17a1.0001 1.0001 0 0 0 2 0v-4.75l6.64 1.47-1.12 2.82a.9997.9997 0 0 0 .0104.7661 1.0011 1.0011 0 0 0 .5496.5339.9995.9995 0 0 0 .37.07 1 1 0 0 0 .93-.63l1.55-3.91a1.0004 1.0004 0 0 0-.05-.84.9997.9997 0 0 0-.66-.51L18 11.31V7a.9997.9997 0 0 0-1-1h-2V3a.9997.9997 0 0 0-1-1h-4a1 1 0 0 0-1 1v3H7a1 1 0 0 0-1 1v4.31L2.78 12a1.0003 1.0003 0 0 0-.66.51 1.0001 1.0001 0 0 0-.05.84l1.55 3.93ZM11 4h2v2h-2V4ZM8 8h8v2.86L12.22 10h-.44L8 10.86V8Zm12.71 11.28a4.3813 4.3813 0 0 0-1 .45 2.0796 2.0796 0 0 1-2.1 0 4.6197 4.6197 0 0 0-4.54 0 2.1401 2.1401 0 0 1-2.12 0 4.64 4.64 0 0 0-4.54 0 2.08 2.08 0 0 1-2.1 0 4.3793 4.3793 0 0 0-1-.45.9993.9993 0 0 0-.8166.0659A.999.999 0 0 0 2 20a1 1 0 0 0 .67 1.24 2.1 2.1 0 0 1 .57.25 4.0002 4.0002 0 0 0 2 .55 4.1406 4.1406 0 0 0 2.08-.56 2.6503 2.6503 0 0 1 2.56 0 4.1498 4.1498 0 0 0 4.12 0 2.6505 2.6505 0 0 1 2.56 0 4.0003 4.0003 0 0 0 4.1 0 2.1026 2.1026 0 0 1 .57-.25 1.0005 1.0005 0 0 0 .79-.8273A.9997.9997 0 0 0 22 20a1.0003 1.0003 0 0 0-1.29-.72Z"/>
  </SvgIcon>
}

export default LineShipIcon

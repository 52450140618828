import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import React from 'react'
import { connect } from 'react-redux'

interface MappedStateProps {
  errorMessage?: string
}

function BusinessTravellerAccountListItemFailedAccess(props: MappedStateProps) {
  const { errorMessage } = props

  return <ListItem
    startIcon={<LineBuildingIcon colour="critical-light" />}
    title={errorMessage}
    subtitle="Please log out and try another account"
    as="div"
  />
}

export default connect<MappedStateProps, undefined, {}, App.State>((state) => {
  return {
    errorMessage: state.businessTraveller.currentBusinessAccount?.status === 'failed' ? state.businessTraveller.currentBusinessAccount.error : undefined,
  }
})(BusinessTravellerAccountListItemFailedAccess)

import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import useCruiseSearchCount from 'hooks/Cruise/useCruiseSearchCount'
import useCruiseSearchFlashOffers from 'hooks/Cruise/useCruiseSearchFlashOffers'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getPlural } from 'lib/string/pluralize'
import React, { useContext, useMemo } from 'react'
import { getCruiseOfferFiltersFromParams } from 'lib/cruises/cruiseUtils'
import useQueryParams from 'hooks/useQueryParams'
import FormatDecimal from 'components/utils/Formatters/FormatDecimal'

// Extracted from src/client/components/Cruises/SearchPage/Filters/Inputs/Common/CruiseFilterFooter.tsx
function FormattedCruiseLineSearchSummary() {
  const queryParams = useQueryParams()
  const globalFilters = useContext(GlobalSearchStateContext)
  const {
    cruiseLines,
    searchItems,
    secondarySearchItems,
    durationMax,
    durationMin,
    flexibleMonths,
    checkinDate,
    checkoutDate,
    durationRange,
  } = globalFilters
  const [filters] = useMemo(() => getCruiseOfferFiltersFromParams(queryParams), [queryParams])

  const [searchCount, fetchingSearchCount] = useCruiseSearchCount({
    ...filters,
    cruiseLines,
    destinationIds: searchItems.map(({ value }) => value),
    departureIds: secondarySearchItems.map(({ value }) => value),
    durationMin,
    durationMax,
    durationRange,
    departureMonths: flexibleMonths?.split(',') || EmptyArray,
    ...(!!checkinDate && !!checkoutDate && {
      departureStartDate: checkinDate.format(ISO_DATE_FORMAT),
      departureEndDate: checkoutDate.format(ISO_DATE_FORMAT),
    }),
  })
  const flashOffers = useCruiseSearchFlashOffers({
    filters,
    globalFilters,
  })

  const count = searchCount + (flashOffers.offerCount ?? 0)

  return <BodyText data-testid="cruise-filter-footer-available-results" variant="medium">
    {!fetchingSearchCount && <><FormatDecimal value={count} /> {getPlural('cruise', count)} available</>}
    {fetchingSearchCount && <TextLoadingBox width="16ch" />}
  </BodyText>
}

export default FormattedCruiseLineSearchSummary

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { rem } from 'polished'
import { connect } from 'react-redux'
import styled from 'styled-components'
import noop from 'lib/function/noop'
import config from 'constants/config'
import { fillArray } from 'lib/array/arrayUtils'
import * as Analytics from 'analytics/analytics'
import { useResponsive } from 'hooks/useResponsive'
import { setAppBannerCookie } from 'cookies/appBannerCookie'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import { copyValueToClipboard } from 'lib/web/clipboardUtils'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import Group from 'components/utils/Group'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import FormatCurrency from 'components/Common/FormatCurrency'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import LineBillIcon from 'components/Luxkit/Icons/line/LineBillIcon'
import LineCopyIcon from 'components/Luxkit/Icons/line/LineCopyIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import SolidStarIcon from 'components/Luxkit/Icons/solid/SolidStarIcon'
import LineThumbsUpIcon from 'components/Luxkit/Icons/line/LineThumbsUpIcon'
import LineUserCheckIcon from 'components/Luxkit/Icons/line/LineUserCheckIcon'

const StyledModalContent = styled(ModalContent)`
  padding: 0;
`

const BannerContainer = styled(Group)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
`

const BannerContent = styled(Group)`
  padding: ${rem(32)} ${rem(20)};
`

const Content = styled(Group)`
  padding: 0 ${rem(20)};
`

const CopyContainer = styled(Group)`
  min-height: ${rem(48)};
  padding: ${rem(12)} ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.seven};
`

interface Props {
  currentRegionCode: string;
  onClose?: () => void;
  hasCpcUtmMedium: Boolean;
  hasGoogleClickIdentifier: Boolean;
}

let circuitBreak = 0
function ContinueToAppDrawer({ onClose = noop, hasCpcUtmMedium, hasGoogleClickIdentifier, currentRegionCode }: Props) {
  const [url, setUrl] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOpen(true)
        return window.removeEventListener('scroll', handleScroll)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [match] = useResponsive() // This useResponsive is ok to use because result will be used inside an useEffect and never in the SSR

  const uptoTablet = useMemo(() => match.mobile || match.tablet, [match])

  const isSGorHK = currentRegionCode === 'SG' || currentRegionCode === 'HK'
  const showPromoCode = !!useOptimizelyExperiment(OptimizelyExperiments.croAppPromoInSgHk, isSGorHK)
  const promoAppBannerFeatureFlag = !!useOptimizelyExperiment(OptimizelyFeatureFlags.promoAppBanner)

  const regionPromotionData = config.appBanner?.promotionData[currentRegionCode] || {}
  const showSpecialCodeForAdClicks = !!regionPromotionData.specialCodeForAdClicks && (hasCpcUtmMedium || hasGoogleClickIdentifier)
  const code = showSpecialCodeForAdClicks ? regionPromotionData.specialCodeForAdClicks : regionPromotionData.code
  const { discount, minSpend } = regionPromotionData

  const promoAppDrawer = useMemo(() => {
    return (discount && code && minSpend) && promoAppBannerFeatureFlag && showPromoCode
  }, [discount, code, minSpend, promoAppBannerFeatureFlag, showPromoCode])

  useEffect(() => {
    // This is here because the AF_SMART_SCRIPT does not have a callback
    // So we need to try multiple times (5) to check if the script has loaded
    // Before we show the app banner
    const interval = setInterval(() => {
      // We only want to show this on upto tablet size
      if (!uptoTablet) {
        // So if screen size is larger we clear the interval
        !!interval && clearInterval(interval)
      }
      else if (!window.AF_SMART_SCRIPT_RESULT) {
        // We try every 5 seconds 5 times
        circuitBreak++
        // If past 5 times there is no script loaded we clear the interval
        if (circuitBreak > 4) clearInterval(interval)
      }
      else {
        // If the script is loaded and the screen size is upto tablet we show the banner by setting it all up
        // And then we clear the interval
        if (!promoAppDrawer) {
          const oneLinkURL = 'https://luxuryescapes.onelink.me/3WHB'
          const mediaSource = { keys: ['utm_source'], defaultValue: 'af_banner' }
          const campaign = { keys: ['utm_campaign'], defaultValue: 'af_banner_campaign' }
          const googleClickIdKey = 'af_sub1'
          const ad = { defaultValue: 'nopromo' }
          const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' }
          const custom_ss_gtm_ui = { paramKey: 'af_ss_gtm_ui', defaultValue: 'true' }

          window.AF_SMART_SCRIPT_RESULT = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL,
            afParameters: {
              mediaSource,
              campaign,
              googleClickIdKey,
              ad,
              afCustom: [
                custom_ss_ui,
                custom_ss_gtm_ui,
              ],
            },
          })
        }

        setUrl(window.AF_SMART_SCRIPT_RESULT.clickURL)
        setTimeout(() => {
          window.AF_SMART_SCRIPT.fireImpressionsLink()
        }, 1000)
        clearInterval(interval)
      }
    }, 5000)
    return () => {
      // Clear interval before the component unmounts to prevent any memory leaks
      !!interval && clearInterval(interval)
    }
  }, [promoAppDrawer, uptoTablet])

  useEffect(() => {
    if (open && !!url) {
      Analytics.trackClientEvent({
        subject: promoAppDrawer ? 'promo' : 'nopromo',
        action: 'impression',
        category: 'appsflyer_banner',
        type: 'nonInteraction',
      })
    }
  }, [open, url, promoAppDrawer])

  const closeAndSetCookie = useCallback(() => {
    setOpen(false)
    onClose()
    setAppBannerCookie()
  }, [onClose])

  const onGetTheAppClick = useCallback(() => {
    closeAndSetCookie()

    Analytics.trackClientEvent({
      subject: promoAppDrawer ? 'promo' : 'nopromo',
      action: 'clicked',
      category: 'appsflyer_banner',
      type: 'interaction',
      optimizelyEventId: '30361770142',
      optimizelyEventKey: 'click-download-app',
    })
  }, [closeAndSetCookie, promoAppDrawer])

  const copyToClipboard = useCallback(() => {
    if (!code) return
    setCopied(true)
    copyValueToClipboard(code)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }, [code])

  return (
    <ModalBase isOpen={!!(open && url)} onClose={closeAndSetCookie}>
      <ModalBody>
        <StyledModalContent>
          <Group direction="vertical" gap={20}>
            <BannerContainer direction="horizontal" horizontalAlign="space-between">
              <BannerContent direction="vertical" gap={16}>
                <Heading variant="heading3">
                  {!promoAppDrawer && <>Download the app and save even <i>more!</i></>}
                  {promoAppDrawer && discount && code && <>Download the app and save an extra <Heading variant="heading3" colour="highlight-secondary" as="span"><i><FormatCurrency value={discount} /></i></Heading></>}
                </Heading>
                <Group direction="vertical" gap={4}>
                  <Group direction="horizontal" gap={4} verticalAlign="center">
                    <div>
                      {fillArray(5).map((index) => <SolidStarIcon key={index} size={16} colour="warning" />)}
                    </div>
                    <Caption variant="medium" colour="neutral-two" weight="bold">4.8</Caption>
                  </Group>
                  <Caption variant="medium" colour="neutral-three">30.1k+ ratings</Caption>
                </Group>
              </BannerContent>
              <ResponsiveImage
                id="sa747tnknchju8cwlh1t"
                gravity="auto"
                quality="good"
                width={170}
              />
            </BannerContainer>
            <Content direction="vertical" gap={20}>
              {promoAppDrawer && (
                <Group direction="vertical" gap={8}>
                  <CopyContainer direction="horizontal" gap={16} verticalAlign="center" horizontalAlign="space-between" onClick={copyToClipboard}>
                    <BodyText variant="medium" weight="bold">{code}</BodyText>
                    {copied && <Caption variant="large" weight="bold" colour="highlight-secondary">Copied!</Caption>}
                    {!copied && <LineCopyIcon />}
                  </CopyContainer>
                  {minSpend && <Caption variant="medium" colour="neutral-three">Coupon code only valid on Limited Time LUX Exclusive bookings. Min. spend <FormatCurrency value={minSpend} />. T&Cs apply.</Caption>}
                </Group>
              )}

              <Group direction="vertical" gap={4}>
                <BodyTextBlock variant="medium" startIcon={<LineBillIcon colour="highlight-secondary" />}>
                  Unlock exclusive app prices
                </BodyTextBlock>
                <BodyTextBlock variant="medium" startIcon={<LineUserCheckIcon colour="highlight-secondary" />}>
                  Get personalised picks for you
                </BodyTextBlock>
                <BodyTextBlock variant="medium" startIcon={<LineThumbsUpIcon colour="highlight-secondary" />}>
                  Experience easy booking & management
                </BodyTextBlock>
              </Group>
            </Content>
          </Group>
        </StyledModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: 'Get the app',
          href: url,
          onClick: onGetTheAppClick,
        }}
        secondaryActionProps={{
          children: 'Maybe later',
          onClick: closeAndSetCookie,
        }}
      />
    </ModalBase>
  )
}

const mapStateToProps = (state: App.State) => ({
  currentRegionCode: state.geo.currentRegionCode,
  hasCpcUtmMedium: state.utm.medium === 'cpc',
  hasGoogleClickIdentifier: !!state.router.location.query.gclid,
})

export default connect(mapStateToProps)(ContinueToAppDrawer)

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSubwayIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.9999 17a1 1 0 0 0 1-1 1.3624 1.3624 0 0 0 0-.2.637.637 0 0 0-.06-.18.7543.7543 0 0 0-.09-.18l-.12-.15a1.1495 1.1495 0 0 0-.33-.21c-.2434-.1-.5165-.1-.76 0a1.151 1.151 0 0 0-.33.21l-.12.15a.7618.7618 0 0 0-.09.18.6434.6434 0 0 0-.06.18 1.3624 1.3624 0 0 0 0 .2 1.0001 1.0001 0 0 0 1 1h-.04Zm2-15h-12a3 3 0 0 0-3 3v12a3.0001 3.0001 0 0 0 1.2 2.39l-.91.9a1.001 1.001 0 0 0-.2958.71 1.0003 1.0003 0 0 0 .2958.71 1.001 1.001 0 0 0 .71.2958.9994.9994 0 0 0 .71-.2958L6.41 20h11.18l1.7 1.71a1.0012 1.0012 0 0 0 .71.2958.999.999 0 0 0 .71-.2958.9987.9987 0 0 0 .2958-.71.9997.9997 0 0 0-.2958-.71l-.91-.9A2.9992 2.9992 0 0 0 21 17V5a3 3 0 0 0-3-3Zm-13 6h6v4h-6V8Zm14 9a.9997.9997 0 0 1-1 1h-12a1.0002 1.0002 0 0 1-1-1v-3h14v3Zm0-5h-6V8h6v4Zm0-6h-14V5a1 1 0 0 1 1-1h12a.9998.9998 0 0 1 1 1v1Zm-11 11a1.0002 1.0002 0 0 0 1-1 1.3596 1.3596 0 0 0 0-.2.6409.6409 0 0 0-.06-.18.7595.7595 0 0 0-.09-.18l-.12-.15a1.1498 1.1498 0 0 0-.33-.21c-.2434-.1-.5165-.1-.76 0a1.1497 1.1497 0 0 0-.33.21l-.12.15a.7595.7595 0 0 0-.09.18.64.64 0 0 0-.1.18 1.3596 1.3596 0 0 0 0 .2c.0011.2623.1053.5137.29.7A1 1 0 0 0 8 17Z"/>
  </SvgIcon>
}

export default LineSubwayIcon

import DualDateRangePicker from 'components/Common/Calendar/DualDateRangePicker'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import { DurationOption } from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker/DurationPicker'
import Group from 'components/utils/Group'
import config from 'constants/config'
import { DATE_SEARCH_OPTION_IDS, FLEXIBLE_DURATION_RANGE } from 'constants/search'
import {
  GlobalSearchDispatchContext,
  GlobalSearchStateContext,
} from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { addDays, addMonths, beginningOfToday } from 'lib/datetime/dateUtils'
import moment from 'moment'
import React, { ComponentProps, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import FlexibleDatePicker from './FlexibleDatePicker'
import FormattedSearchDate from './FormattedSearchDate'
import SearchDateToggle from './SearchDateToggle'

interface Props extends Pick<ComponentProps<typeof DropdownSheet>, 'anchorRef' | 'triggerRef' | 'open'> {
  onDatesApply: () => void;
  onDatesDecline: () => void;
  anytimeDateSelected?: boolean;
  selectAnytimeDate?: (shouldApply: boolean) => void;
  selectSpecificDates?: () => void;
  selectFlexibleDates?: () => void;
  selectAnytime?: () => void;
  dateSearchOptionId?: DATE_SEARCH_OPTION_IDS;
  durationOptionsCustom?: Array<DurationOption>;
  isSpecificSearchEnabled?: boolean
  showFlexibleYearSelector?: boolean;
  closeMenu?: () => void;
}

const today = beginningOfToday()

function SearchDateDropDown(props: Props) {
  const {
    anchorRef,
    triggerRef,
    open,
    onDatesApply,
    onDatesDecline,
    anytimeDateSelected,
    selectAnytimeDate,
    selectSpecificDates,
    selectFlexibleDates,
    dateSearchOptionId,
    durationOptionsCustom,
    isSpecificSearchEnabled = true,
    showFlexibleYearSelector,
    closeMenu,
  } = props
  const { checkinDate, checkoutDate, flexibleMonths, flexibleNights, userSelectedFlexibleMonths } = useContext(GlobalSearchStateContext)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  const localInputRef = useRef<HTMLInputElement>(null)

  // show flexible search if the search option is flexible dates or if the search option is anytime with no specific search enabled
  const shouldShowFlexibleSearch = dateSearchOptionId === DATE_SEARCH_OPTION_IDS.FLEXIBLE ||
    (!isSpecificSearchEnabled && dateSearchOptionId === DATE_SEARCH_OPTION_IDS.ANYTIME)

  const shouldShowSpecific = dateSearchOptionId === DATE_SEARCH_OPTION_IDS.SPECIFIC ||
    (isSpecificSearchEnabled && dateSearchOptionId === DATE_SEARCH_OPTION_IDS.ANYTIME)

  useEffect(() => {
    if (localInputRef?.current) {
      localInputRef.current.focus()
    }
  }, [localInputRef])

  const maxDate = useMemo(
    () =>
      checkinDate && !checkoutDate ?
        addDays(checkinDate.toDate(), 31) :
        addMonths(today, 36),
    [checkinDate, checkoutDate],
  )

  const handleDatesChanged = useCallback(
    (dates: { startDate: moment.Moment; endDate: moment.Moment }) => {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_CHECKIN_DATE,
        date: dates.startDate,
      })
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_CHECKOUT_DATE,
        date: dates.endDate,
      })

      if (dates.startDate || dates.endDate) {
        selectSpecificDates?.()
      }
    },
    [globalSearchDispatch, selectSpecificDates],
  )

  const handleFlexibleNightsChanged = useCallback(
    (flexibleNights: FLEXIBLE_DURATION_RANGE) => {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION,
        flexibleNights,
      })
      if (flexibleNights) {
        selectFlexibleDates?.()
      }
    },
    [globalSearchDispatch, selectFlexibleDates],
  )

  const handleFlexibleMonthOptionsChanged = useCallback(
    (flexibleMonthOptions: string) => {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE,
        flexibleMonths: flexibleMonthOptions,
      })
      if (flexibleMonthOptions.length > 0) {
        selectFlexibleDates?.()
        globalSearchDispatch({
          type: GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS,
          userSelectedFlexibleMonths: true,
        })
      } else {
        globalSearchDispatch({
          type: GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS,
        })
      }
    },
    [globalSearchDispatch, selectFlexibleDates],
  )

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      selectAnytimeDate?.(true)
    }
  }, [selectAnytimeDate])

  const selectAnytimeDateNoApply = useCallback(() => {
    selectAnytimeDate?.(false)
  }, [selectAnytimeDate])

  const onSelectSpecificDates = useCallback(() => {
    selectSpecificDates?.()
  }, [selectSpecificDates])

  const hasDates = !!(checkinDate && checkoutDate)
  const disableSearchButton = dateSearchOptionId === DATE_SEARCH_OPTION_IDS.SPECIFIC && !hasDates

  return <DropdownSheet
    data-testid="search-date-dropdown"
    size="fill-anchor"
    open={open}
    onClose={closeMenu}
    anchorRef={anchorRef}
    triggerRef={triggerRef}
    primaryActionProps={{
      'data-testid': 'search-apply-button',
      children: 'Apply',
      disabled: disableSearchButton,
      onClick: onDatesApply,
    }}
    secondaryActionProps={{
      'data-testid': 'search-nodates-button',
      kind: 'tertiary',
      children: 'Cancel',
      onClick: onDatesDecline,
    }}
    footerStart={<Group direction="horizontal" horizontalAlign="end"><FormattedSearchDate /></Group>}
  >
    <VerticalSpacer gap={32} onKeyDown={onKeyDown}>
      {config.businessTraveller.currentAccountMode !== 'business' && <Group
        direction="horizontal"
        horizontalAlign="center"
      >
        <SearchDateToggle
          onSelectAnytime={selectAnytimeDateNoApply}
          onSelectSpecific={onSelectSpecificDates}
          anytimeDateSelected={!!anytimeDateSelected}
          onSelectFlexible={selectFlexibleDates}
          dateSearchOptionId={dateSearchOptionId}
          isSpecificSearchEnabled={isSpecificSearchEnabled}
        />
      </Group>}
      <Group direction="vertical" horizontalAlign="center">
        {shouldShowFlexibleSearch && (
          <FlexibleDatePicker
            onMonthChange={handleFlexibleMonthOptionsChanged}
            onDurationChange={handleFlexibleNightsChanged}
            previouslySelectedDuration={flexibleNights}
            previouslySelectedMonths={userSelectedFlexibleMonths ? flexibleMonths : undefined}
            durationOptionsCustom={durationOptionsCustom}
            showYearSelector={showFlexibleYearSelector}
          />
        )}
        {shouldShowSpecific && (
          <DualDateRangePicker
            onDatesChange={handleDatesChanged}
            startDate={checkinDate}
            endDate={checkoutDate}
            minDate={today}
            maxDate={maxDate}
          />
        )}
      </Group>
    </VerticalSpacer>
  </DropdownSheet>
}

export default SearchDateDropDown

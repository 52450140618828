import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getBundleAndSaveItemViews } from '../view/bundleAndSave'

export const getBundleAndSaveTotals = createSelector(
  getBundleAndSaveItemViews,
  (items): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const views = items.data
    const allHotels = views.flatMap(view => view.hotels)
    return {
      hasRequiredData: true,
      data: {
        price: sum(allHotels, hotel => hotel.price ?? 0),
        memberPrice: sum(allHotels, hotel => hotel.memberPrice ?? 0),
        value: sum(allHotels, hotel => {
          if (hotel.package.bundleDiscountPercent) {
            return Number(Number(((hotel.price ?? 0) * 100) / (100 - hotel.package.bundleDiscountPercent)).toFixed(2))
          }
          return hotel.value ?? 0
        }),
        surcharge: sum(allHotels, hotel => hotel.surcharge ?? 0),
        taxesAndFees: sum(allHotels, hotel => hotel.taxesAndFees ?? 0),
        otherFees: {
          propertyFees: sum(allHotels, hotel => hotel.otherFees?.propertyFees ?? 0),
        },
        propertiesFees: allHotels.reduce((acc, hotel) => {
          if (hotel.otherFees?.propertyFees && hotel.offer) {
            acc.push({
              propertyName: hotel.offer.property.name,
              propertyFees: hotel.otherFees?.propertyFees ?? 0,
              dueAtPropertyMessage: hotel.offer.property.taxesAndFeesContent ? hotel.offer.property.taxesAndFeesContent : '',
            })
          }
          return acc
        }, [] as Array<App.PropertyFeesDetails>),
      },
    }
  },
)

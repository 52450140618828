import { css } from 'styled-components'
import { mediaQueryDown } from 'components/utils/breakpoint'
import { rem } from 'polished'

export type BaseSize = 'zero' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type DirectionalSize = `${BaseSize}-x` | `${BaseSize}-y`
export type PaneSize = BaseSize | DirectionalSize
export type PaneSizeCombo = PaneSize | `${BaseSize} ${DirectionalSize}` | `${DirectionalSize} ${BaseSize}` | `${DirectionalSize} ${DirectionalSize}`

function calculateSpacing(sizes: Array<PaneSize> = []) {
  let spacing: { x?: number, y?: number } = {}

  if (sizes.includes('zero')) spacing = { x: 0, y: 0 }
  if (sizes.includes('xs')) spacing = { x: 4, y: 4 }
  if (sizes.includes('sm')) spacing = { x: 8, y: 8 }
  if (sizes.includes('md')) spacing = { x: 16, y: 16 }
  if (sizes.includes('lg')) spacing = { x: 24, y: 24 }
  if (sizes.includes('xl')) spacing = { x: 40, y: 40 }

  if (sizes.includes('zero-x')) spacing.x = 0
  if (sizes.includes('xs-x')) spacing.x = 16
  if (sizes.includes('sm-x')) spacing.x = 8
  if (sizes.includes('md-x')) spacing.x = 16
  if (sizes.includes('lg-x')) spacing.x = 24
  if (sizes.includes('xl-x')) spacing.x = 40

  if (sizes.includes('zero-y')) spacing.y = 0
  if (sizes.includes('xs-y')) spacing.y = 16
  if (sizes.includes('sm-y')) spacing.y = 8
  if (sizes.includes('md-y')) spacing.y = 16
  if (sizes.includes('lg-y')) spacing.y = 24
  if (sizes.includes('xl-y')) spacing.y = 40

  return spacing
}

export const paneSpacing = css<{ $sizes: Array<PaneSize>; $mobileSizes: Array<PaneSize>; }>`
  ${({ $sizes, $mobileSizes }) => {
    const spacing = {
      x: 0,
      y: 0,
      ...calculateSpacing($sizes),
    }
    const mobileSpacing = {
      ...spacing,
      ...calculateSpacing($mobileSizes),
    }

    return css`
      padding: ${rem(spacing.y)} ${rem(spacing.x)};
      ${mediaQueryDown.mobile} {
        ${$mobileSizes && `
          padding: ${rem(mobileSpacing.y)} ${rem(mobileSpacing.x)};
        `}
      }
    `
  }}
`

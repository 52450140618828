import { ExperienceOfferPageState } from 'contexts/Experience/ExperienceOfferPage/experienceOfferPageStateReducer'
import GeoContext from 'contexts/geoContext'
import { useAppSelector } from 'hooks/reduxHooks'
import { getExperienceTimesKey } from 'lib/experiences/experienceUtils'
import { useContext, useMemo } from 'react'

export function useCurrentExperiencePageTicketOptions(experienceId: string, experiencePageState?: ExperienceOfferPageState) {
  const { currentCurrency } = useContext(GeoContext)

  const key = getExperienceTimesKey(experienceId, experiencePageState?.date, {
    currency: currentCurrency,
    redemptionLocationId: experiencePageState?.redemptionLocation?.id,
    pickupPointId: experiencePageState?.pickupPoint?.id,
  })
  const timeslots = useAppSelector((state) => state.experience.experienceTimes[experienceId]?.[key]?.slots)

  const allTicketOptions = useMemo(() => {
    const allSlotsForTime = (timeslots ?? []).filter(slot => slot.time === experiencePageState?.time)
    return allSlotsForTime.flatMap(slot => slot.tickets)
  }, [experiencePageState?.time, timeslots])

  return allTicketOptions
}

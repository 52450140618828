import Tooltip from 'components/Luxkit/Tooltip'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Group from 'components/utils/Group'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { driverCategories } from 'constants/carHire'
import ModalContext from 'contexts/ModalContext'
import noop from 'lib/function/noop'
import React, { useCallback, useContext } from 'react'

interface Props {
  value?: App.CarHireDriverAgeCategory;
  onChange?: (category: App.CarHireDriverAgeCategory) => void;
}

function CarHireSearchDriversAgeCategorySelect(props: Props) {
  const { value, onChange = noop } = props

  const onSelectDriversAge = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.dataset.category) {
      const nextCategory = parseInt(e.currentTarget.dataset.category) as App.CarHireDriverAgeCategory
      onChange(nextCategory)
    }
  }, [onChange])

  const showModal = useContext(ModalContext)
  const onViewDriversAgeTooltip = () => {
    showModal(<Modal title="Driver's age">
      <BodyText variant="medium">
        Ensure the driver's age aligns with your pick-up date for accurate pricing.
      </BodyText>
    </Modal>)
  }

  return <Group direction="horizontal" verticalAlign="center" gap={16}>
    {/* mobile */}
    <CSSBreakpoint max="tablet">
      <CaptionTextBlock onClick={onViewDriversAgeTooltip} variant="large" endIcon={<SolidInfoCircleIcon />}>
        Driver's age
      </CaptionTextBlock>
    </CSSBreakpoint>

    {/* desktop */}
    <CSSBreakpoint min="desktop">
      <Tooltip description="Ensure the driver's age aligns with your pick-up date for accurate pricing.">
        <CaptionTextBlock variant="large" endIcon={<SolidInfoCircleIcon />}>
          Driver's age
        </CaptionTextBlock>
      </Tooltip>
    </CSSBreakpoint>

    <Group direction="horizontal" gap={4}>
      {driverCategories.map(cat =>
        <FilterChip
          key={cat.id}
          size="medium"
          selected={value === cat.id}
          data-category={cat.id}
          onClick={onSelectDriversAge}
        >
          {cat.label}
        </FilterChip>,
      )}
    </Group>
  </Group>
}

export default CarHireSearchDriversAgeCategorySelect

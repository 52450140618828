import OfferLabels from 'components/Common/Labels/OfferLabels'
import OfferBadgeLabel from 'components/Common/OfferBadgeLabel/OfferBadgeLabel'
import Heading from 'components/Luxkit/Typography/Heading'
import React, { useMemo } from 'react'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import HeroBundleAndSaveOfferTilePricing from '../Support/HeroBundleAndSaveOfferTilePricing'
import OfferTileLoggedInButtons from '../Support/OfferTileLoggedInButtons'
import BundleAndSaveLocation from 'components/OfferList/OfferCards/BundleAndSaveLocation'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const DetailContainer = styled(Group)`
  padding-top: ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(32)};
  }
`

const MinHeightGroup = styled(Group)`
  min-height: ${rem(300)};
  flex-grow: 1;
`

const StyledLabelGroup = styled(LabelGroup)`
  &:empty {
    display: none;
  }
`

interface Props {
  offer: App.BundleOffer | App.BundleOfferSummary;
}

function HeroBundleAndSaveOfferDetails(props: Props) {
  const { offer } = props
  const {
    lowestPriceBundlePackage,
  } = offer

  const bundledOffers = Object.values(offer.bundledOffers)

  const offerPropertiesTitle = useMemo(
    () => offer.location || bundledOffers.map(offer => offer.property.name).join(' + ')
    , [offer.location, bundledOffers])

  return (
    <DetailContainer direction="vertical" gap={16} fullHeight>
      <MinHeightGroup direction="vertical" gap={16} tabletGap={24} verticalAlign="space-between">
        <Group direction="vertical" gap={4} horizontalAlign="start">
          <StyledLabelGroup>
            <OfferLabels offer={offer} />
            {offer.badge && <CSSBreakpoint max="tablet">
              <OfferBadgeLabel badge={offer.badge} />
            </CSSBreakpoint>}
          </StyledLabelGroup>
          <BundleAndSaveLocation bundledOffers={bundledOffers} showHotel />
          <Heading variant="heading6" lineClamp={3}>{offerPropertiesTitle}</Heading>
          <ProductTypeLabel productType={offer.productType} kind="default" />
          <Group direction="vertical" gap={8}>
            <BodyText variant="medium" colour="neutral-one" lineClamp={3}>
              {offer.name}
            </BodyText>
          </Group>
        </Group>
        <Group direction="vertical" gap={8} >
          {!offer.walledGarden && lowestPriceBundlePackage && <HeroBundleAndSaveOfferTilePricing offer={offer} pkg={lowestPriceBundlePackage} />}
          {offer.walledGarden && lowestPriceBundlePackage && (
            <OfferTileLoggedInButtons
              signUpText="Sign up for free"
              discountPercentage={lowestPriceBundlePackage.discountPercent ? Math.round(lowestPriceBundlePackage.discountPercent) : undefined}
              align="none"
              fullWidth
            >
              <HeroBundleAndSaveOfferTilePricing offer={offer} pkg={lowestPriceBundlePackage} />
            </OfferTileLoggedInButtons>
          )}
        </Group>
      </MinHeightGroup>
    </DetailContainer>
  )
}

export default HeroBundleAndSaveOfferDetails

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineDrinksTrayIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M6 2c-.5523 0-1 .4477-1 1v3.6754a1 1 0 0 1-.0513.3163L4.154 9.3759a3 3 0 0 0-.154.9487V20H3c-.5523 0-1 .4477-1 1s.4477 1 1 1h18c.5523 0 1-.4477 1-1s-.4477-1-1-1h-3v-5.1c2.2822-.4633 4-2.481 4-4.9V7c0-.5523-.4477-1-1-1h-8c-.5523 0-1 .4477-1 1v3c0 2.419 1.7178 4.4367 4 4.9V20h-5v-9.6754c0-.3225-.052-.6428-.1539-.9487l-.7948-2.3842A.9996.9996 0 0 1 10 6.6754V3c0-.5523-.4477-1-1-1H6Zm0 14v-2h3v2H6Zm0-4h3v-1.6754c0-.1075-.0173-.2143-.0513-.3163L8.2792 8H6.7208l-.6695 2.0083A1.0005 1.0005 0 0 0 6 10.3246V12Zm1-6V4h1v2H7ZM6 18h3v2H6v-2Zm8-10v2c0 1.6569 1.3431 3 3 3s3-1.3431 3-3V8h-6Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineDrinksTrayIcon

import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { ISO_DATE_FORMAT, SHORT_DAY_NAME_DAY_MONTH, SHORT_DAY_NAME_DAY_MONTH_YEAR } from 'constants/dateFormats'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { pluralizeToString } from 'lib/string/pluralize'
import React, { useContext } from 'react'

interface Props {
  /**
   * @default day
   */
  timeUnit?: 'day' | 'night'
  // TODO: This prop has been carried over from the old `SearchDateFooter`.
  // Needs more justification by the Cruises team.
  includeLastDayOnCount?: boolean
}

function FormattedSearchDate(props: Props) {
  const { timeUnit = 'night', includeLastDayOnCount } = props
  const { checkinDate, checkoutDate } = useContext(GlobalSearchStateContext)

  const hasDates = !!(checkinDate && checkoutDate)

  if (!hasDates) return null

  return <Group direction="horizontal" gap={8} verticalAlign="center">
    <Group direction="horizontal" gap={4} verticalAlign="center">
      <BodyText variant="medium" weight="bold">
        <time dateTime={checkinDate.format(ISO_DATE_FORMAT)}>
          {checkinDate.format(SHORT_DAY_NAME_DAY_MONTH)}
        </time>
      </BodyText>

      <LineArrowRightIcon size="S" />

      <BodyText variant="medium" weight="bold">
        <time dateTime={checkoutDate.format(ISO_DATE_FORMAT)}>
          {checkoutDate.format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}
        </time>
      </BodyText>
    </Group>

    <BodyText variant="medium">
      ({pluralizeToString(timeUnit, checkoutDate.diff(checkinDate, 'days') + (includeLastDayOnCount ? 1 : 0))})
    </BodyText>
  </Group>
}

export default FormattedSearchDate

import { buildCancellationPolicies, buildPartiallyRefundableCancellationPolicies } from '@luxuryescapes/lib-refunds'

export type BedbankCancellationPolicyWithInfo = { cancellationPolicy: App.BedbankCancellationPolicy, refundable: boolean, partiallyRefundable: boolean }
export function buildBedbankCancellationPolicy(cancellationPolicyInfo?: App.OfferListMetaData['cancellationPolicyInfo'], filters?: App.OfferListFilters, timezone?: string): BedbankCancellationPolicyWithInfo | undefined {
  if (!filters?.checkIn || !filters?.checkOut || !cancellationPolicyInfo || !timezone) {
    return undefined
  }

  if (cancellationPolicyInfo.partiallyRefundable) {
    return {
      cancellationPolicy: buildPartiallyRefundableCancellationPolicies(
        {
          checkIn: filters.checkIn,
          checkOut: filters.checkOut,
          policies: cancellationPolicyInfo.cancellationPolicies,
          nonRefundableDates: cancellationPolicyInfo.nonRefundableDateRanges,
        },
        {
          timezone,
        } as any,
      ),
      refundable: false,
      partiallyRefundable: true,
    }
  } else {
    return {
      cancellationPolicy: buildCancellationPolicies(cancellationPolicyInfo.cancellationPolicies, { timezone } as any),
      refundable: cancellationPolicyInfo.refundable,
      partiallyRefundable: cancellationPolicyInfo.partiallyRefundable,
    }
  }
}

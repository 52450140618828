import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTaxiIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17.38 14.58a.9995.9995 0 0 0-.58-.06.6402.6402 0 0 0-.18.06.7618.7618 0 0 0-.18.09l-.15.12a1.0501 1.0501 0 0 0-.29.71 1.0003 1.0003 0 0 0 .29.71c.0969.0913.2085.1656.33.22.1831.0753.3844.0946.5784.0555a1.0007 1.0007 0 0 0 .5116-.2755 1.1495 1.1495 0 0 0 .21-.33.8395.8395 0 0 0 .08-.38 1.0501 1.0501 0 0 0-.29-.71.9305.9305 0 0 0-.33-.21Zm2.62-3.9V7.5a3 3 0 0 0-3-3h-.78l-.77-2.32a1 1 0 0 0-.95-.68h-5a1 1 0 0 0-.95.68L7.78 4.5H7a3 3 0 0 0-3 3v3.18a3.0002 3.0002 0 0 0-2 2.82v6a1 1 0 0 0 1 1h1v1a1 1 0 1 0 2 0v-1h12v1a1.0001 1.0001 0 0 0 2 0v-1h1a1.0001 1.0001 0 0 0 1-1v-6a3.0004 3.0004 0 0 0-2-2.82ZM10.22 3.5h3.56l.33 1H9.89l.33-1ZM6 7.5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v3H6v-3Zm14 11H4v-5a1 1 0 0 1 1-1h14a1.0001 1.0001 0 0 1 1 1v5ZM6.62 14.58a1.0007 1.0007 0 0 0-.33.21 1.0499 1.0499 0 0 0-.29.71 1.0004 1.0004 0 0 0 .4411.8343 1.0001 1.0001 0 0 0 .9389.0957 1.1905 1.1905 0 0 0 .33-.22A1 1 0 0 0 8 15.5a1.0499 1.0499 0 0 0-.29-.71.9996.9996 0 0 0-1.09-.21ZM13 14.5h-2a1.0001 1.0001 0 0 0 0 2h2a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineTaxiIcon

import cn from 'clsx'
import React, { PropsWithChildren, forwardRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { DropdownBehaviourDispatchContext, DropdownBehaviourStateContext } from './DropdownBehaviourContexts'
import { DropdownBehaviourDispatchActions } from './DropdownBehaviourState'

const DropdownFooterContainer = styled.div`
  grid-area: dropdown-footer;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: box-shadow 200ms ease;
  z-index: 1;

  &.has-shadow {
    box-shadow: ${props => props.theme.shadow.top.small};
  }
`

type Props = PropsWithChildren<{
  className?: string
}>

const _DropdownFooter = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, className } = props

  const dropdownContext = useContext(DropdownBehaviourStateContext)
  const dropdownDispatch = useContext(DropdownBehaviourDispatchContext)

  useEffect(() => {
    dropdownDispatch({
      type: DropdownBehaviourDispatchActions.TOGGLE_FOOTER,
      hasFooter: true,
    })

    return () => {
      dropdownDispatch({
        type: DropdownBehaviourDispatchActions.TOGGLE_FOOTER,
        hasFooter: false,
      })
    }
  }, [dropdownDispatch])

  return <DropdownFooterContainer
    ref={ref}
    className={cn(className, { 'has-shadow': !dropdownContext.bodyHasReachedEnd })}
  >
    {children}
  </DropdownFooterContainer>
})

export default _DropdownFooter

import { mediaQueryUp } from 'components/utils/breakpoint'
import React, { HTMLAttributes, PropsWithChildren, forwardRef } from 'react'
import cn from 'clsx'
import styled, { keyframes } from 'styled-components'
import zIndex from 'styles/tools/z-index'
import { MODAL_TRANSITION_DURATION } from './modalConstants'
import ModalSheet from './ModalSheet'

const AppearAnimation = keyframes`
  from {
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(0px);
  }

  to {
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(2px);
  }
`
const DisappearAnimation = keyframes`
  from {
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(2px);
  }

  to {
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(0px);
  }
`

const Backdrop = styled.div`
  z-index: ${zIndex.modal};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  height: 100dvh;
  background-color: rgba(0,0,0,.5);
  backdrop-filter: blur(2px);
  display: flex;
  animation-iteration-count: 1;
  animation-duration: ${MODAL_TRANSITION_DURATION}ms;
  animation-fill-mode: forwards;

  &.transition-in {
    animation-name: ${AppearAnimation}
    
  }
  &.transition-out {
    animation-name: ${DisappearAnimation}
  }

  &.position-fixed {
    position: fixed;
  }
  &.position-absolute {
    position: absolute;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.mode-default {
    align-items: flex-end;
  }

  ${mediaQueryUp.tablet} {
    &.mode-default {
      align-items: center;
      justify-content: center;
    }

    &.mode-drawer, &.mode-menu {
      align-items: stretch;
      justify-items: start;
    }
  }
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  transition?: 'in' | 'out'
  position?: 'absolute' | 'fixed'
  mode: React.ComponentProps<typeof ModalSheet>['mode']
}

const ModalBackdrop = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    transition,
    position = 'fixed',
    mode,
    style, // coming from react-modal and we don't want it!
    /** @todo define used props explicitly */
    ...rest // a11y props coming from react-modal
  } = props

  return <Backdrop
    {...rest}
    ref={ref}
    className={cn(
      `position-${position}`,
      `transition-${transition}`,
      `mode-${mode}`,
    )}
  >
    {children}
  </Backdrop>
})

export default ModalBackdrop

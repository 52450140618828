import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { rem } from 'polished'
import styled from 'styled-components'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import {
  formatDate,
  getOccupancyDescription,
} from 'components/SearchV2/Utils/GlobalSearchV2'
import DateFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/DateFloatingPanel'
import TravellersFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/TravellersFloatingPanel'
import LocationFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/LocationFloatingPanel'
import useDateSearchProps from 'components/SearchV2/Hooks/useDateSearchProps'
import useStepsController from 'components/SearchV2/Hooks/useStepsController'
import { DATE_SEARCH_OPTION_IDS, HOMES_AND_VILLAS_LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL, HOMES_AND_VILLAS_TYPEAHEAD_TYPES, SEARCH_VERTICALS } from 'constants/search'
import { prettyFlexibleDates } from 'components/Search/SearchForm/SearchDateInput/FlexibleDateSearchField'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    &:not(:first-child) {
      margin-top: ${rem(8)};
    }
  }
`

export enum searchSteps {
  LOCATION = 'location',
  DATE = 'date',
  GUESTS = 'guests',
}

interface Props {
  onSearch: () => void
  showSearch?: (show: boolean) => void
}

function HomesAndVillasTabContent(props: Props) {
  const { onSearch } = props

  const {
    searchItem,
    checkinDate,
    checkoutDate,
    occupancies,
    areRoomErrorsShown,
    flexibleMonths,
    flexibleNights,
    dateSearchOptionId,
    userSelectedFlexibleMonths,
  } = useContext(GlobalSearchStateContext)

  const { openedSearchVertical, option } = useGlobalSearchURLHashVertical()

  const isDateSelectOpen = openedSearchVertical === SEARCH_VERTICALS.HOMES_AND_VILLAS && option === 'dateSelect'

  const stepsValidation = useMemo(
    () => ({
      [searchSteps.LOCATION]: searchItem,
      [searchSteps.DATE]:
      // if business traveller is active and they are a business account, then
      // invalidate this step so that they have to choose dates in booking flow
        (dateSearchOptionId === DATE_SEARCH_OPTION_IDS.ANYTIME || (checkinDate && checkoutDate) || flexibleMonths) ||
        (checkinDate && checkoutDate),
      [searchSteps.GUESTS]: !areRoomErrorsShown,
    }),
    [searchItem, dateSearchOptionId, checkinDate, checkoutDate, flexibleMonths, areRoomErrorsShown],
  )

  const flexibleSearchSelectionText =
    prettyFlexibleDates(userSelectedFlexibleMonths ? flexibleMonths : undefined, flexibleNights)

  const [activeStep, setActiveStep] = useState<searchSteps>()
  const { goToNextStep, goToPreviousStep, closeActiveStep, isSearchEnabled } =
    useStepsController({ activeStep, setActiveStep, stepsValidation, onSearch })

  const openLocationSearchStep = useCallback(
    () => setActiveStep(searchSteps.LOCATION),
    [setActiveStep],
  )
  const openDateSearchStep = useCallback(
    () => setActiveStep(searchSteps.DATE),
    [setActiveStep],
  )
  const openGuestsSearchStep = useCallback(
    () => setActiveStep(searchSteps.GUESTS),
    [setActiveStep],
  )

  useEffect(() => {
    if (isDateSelectOpen) {
      openDateSearchStep()
    }
  }, [isDateSelectOpen, openDateSearchStep])

  const { checkin, checkout } = useMemo(
    () => ({
      checkin: formatDate(checkinDate),
      checkout: formatDate(checkoutDate),
    }),
    [checkinDate, checkoutDate],
  )

  const guestsDescription = useMemo(
    () => getOccupancyDescription(occupancies),
    [occupancies],
  )
  const dateSearchProps = useDateSearchProps({
    onAnytimeDatesSelected: goToNextStep,
  })

  return (
    <ContentWrapper>
      <LocationFloatingPanel
        isOpen={activeStep === searchSteps.LOCATION}
        onClose={closeActiveStep}
        onConfirm={goToNextStep}
        onGoBack={goToPreviousStep}
        typeaheadTypes={HOMES_AND_VILLAS_TYPEAHEAD_TYPES}
        placeholder={HOMES_AND_VILLAS_LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL}
      />

      <DateFloatingPanel
        {...dateSearchProps}
        isOpen={activeStep === searchSteps.DATE}
        onClose={closeActiveStep}
        onConfirm={goToNextStep}
        onGoBack={goToPreviousStep}
        confirmText={isSearchEnabled ? 'Search' : 'Apply'}
      />

      <TravellersFloatingPanel
        isOpen={activeStep === searchSteps.GUESTS}
        onClose={closeActiveStep}
        onConfirm={goToNextStep}
        onGoBack={goToPreviousStep}
        saleUnit="property"
        confirmText={isSearchEnabled ? 'Search' : 'Apply'}
      />

      <FieldsWrapper>
        <SearchFormField
          label="Where"
          placeholder="Search destination, landmark or property"
          value={searchItem?.format.mainText}
          onClick={openLocationSearchStep}
        />

        <SearchFormFieldGroup>
          {dateSearchOptionId === DATE_SEARCH_OPTION_IDS.FLEXIBLE && (
            <SearchFormField
              label="When"
              onClick={openDateSearchStep}
              placeholder="Anytime"
              value={flexibleSearchSelectionText}
            />
          )}
          {dateSearchOptionId !== DATE_SEARCH_OPTION_IDS.FLEXIBLE && (
            <>
              <SearchFormField
                  label="Check in"
                  placeholder="Anytime"
                  value={checkin}
                  onClick={openDateSearchStep}
                />
              <SearchFormField
                  label="Check out"
                  placeholder="Anytime"
                  value={checkout}
                  onClick={openDateSearchStep}
                />
            </>
          )}
        </SearchFormFieldGroup>
        <SearchFormField
          label="Guests"
          value={guestsDescription}
          onClick={openGuestsSearchStep}
          hasError={areRoomErrorsShown}
        />
      </FieldsWrapper>
    </ContentWrapper>
  )
}

export default HomesAndVillasTabContent

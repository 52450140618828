import {
  GetUserSettingsResponse,
  UpdateUserSettingsRequest,
  UpdateUserSettingsResponse,
} from './types'
import { baseUri, getOptions } from '../utils'

import request from 'api/requestUtils'
import { UserSettings } from 'tripPlanner/types/common'

const uri = `${baseUri}/user-settings`

export async function getUserSettings(
  accessToken?: string,
): Promise<UserSettings> {
  return request
    .get<GetUserSettingsResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export async function updateUserSettings(
  update: UpdateUserSettingsRequest,
  accessToken?: string,
): Promise<UserSettings> {
  return request
    .patch<UpdateUserSettingsResponse, UpdateUserSettingsRequest>(
      uri,
      update,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

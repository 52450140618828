import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineShoppingCartIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21 16H7a1.0002 1.0002 0 0 1-.7071-1.7071A1.0002 1.0002 0 0 1 7 14h10.44a3 3 0 0 0 2.91-2.27L22 5.24a.9993.9993 0 0 0-.18-.86A1.0008 1.0008 0 0 0 21 4H6.76a3 3 0 0 0-2.82-2H3a1 1 0 0 0 0 2h.94a1 1 0 0 1 1 .76l.06.48L6.73 12A3.003 3.003 0 1 0 7 18h.18a2.9999 2.9999 0 0 0 4.2046 3.6849 3.0005 3.0005 0 0 0 1.5895-2.2678A3 3 0 0 0 12.82 18h2.36a3 3 0 1 0 5.64 0H21a1.0001 1.0001 0 0 0 0-2ZM19.72 6l-1.31 5.24a1 1 0 0 1-1 .76H8.78l-1.5-6h12.44ZM10 20a1.0006 1.0006 0 0 1-.9239-.6173A1 1 0 1 1 10 20Zm8 0a1.0008 1.0008 0 0 1-.5556-.1685 1.0006 1.0006 0 0 1-.4252-1.0266.9995.9995 0 0 1 .7857-.7857A.9998.9998 0 0 1 19 19a1.0001 1.0001 0 0 1-1 1Z"/>
  </SvgIcon>
}

export default LineShoppingCartIcon

import Select from 'components/Common/Form/Input/Select'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { formToObject } from 'lib/forms/formToObject'
import React, { useEffect, useMemo, useState } from 'react'
import ExperienceDetailsTransferBookingTravellers from './ExperienceTransferBookingTravellers'
import ExperienceTransferBookingOption from './ExperienceTransferBookingOption'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import ExperienceTransferBookingForm from './ExperienceTransferBookingForm'
import useToggle from 'hooks/useToggle'
import { TransferForm, findBestTransfer } from './ExperienceTransferUtils'
import noop from 'lib/function/noop'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import ExperienceTransferBookingOptionError from './ExperienceTransferBookingOptionError'
import ExperienceDetailsTransferBookingBaggage from './ExperienceTransferBookingBaggage'

const defaultAirportView: App.ExperienceTransferView = {
  type: 'HOTEL-TO-AIRPORT',
  hotel: {},
  travellers: {
    adults: 2,
    children: 0,
    childSeats: 0,
  },
  baggage: {
    bags: 0,
    oversizedBags: 0,
  },
}

const defaultHotelView: App.ExperienceTransferView = {
  type: 'AIRPORT-TO-HOTEL',
  hotel: {},
  travellers: {
    adults: 2,
    children: 0,
    childSeats: 0,
  },
  baggage: {
    bags: 0,
    oversizedBags: 0,
  },
}

type TransferType = App.ExperienceTransferType | 'ROUND-TRIP'

const TransferTypeLabels = new Map<TransferType, string>([
  ['AIRPORT-TO-HOTEL', 'Airport to hotel transfer only'],
  ['HOTEL-TO-AIRPORT', 'Hotel to airport transfer only'],
  ['ROUND-TRIP', 'Round trip transfer'],
])

interface Props {
  defaultToAirportTransfer?: App.ExperienceTransferView;
  defaultToHotelTransfer?: App.ExperienceTransferView;
  transferOptions: Array<App.ExperienceTransferOption>;
  experience: App.ExperienceBookingView;
  formId?: string;
  onPriceChange?: (total: number, isFromPrice?: boolean) => void;
  onSubmit?: (transfers: Array<App.ExperienceTransferView>) => void;
}

function ExperienceTransferMultipleBooking(props: Props) {
  const {
    defaultToAirportTransfer,
    defaultToHotelTransfer,
    transferOptions,
    experience,
    formId,
    onPriceChange = noop,
    onSubmit = noop,
  } = props

  const [toHotelOptions, toAirportOptions] = useMemo(() => ([
    transferOptions.filter(option => option.transferTypes.includes('AIRPORT-TO-HOTEL')),
    transferOptions.filter(option => option.transferTypes.includes('HOTEL-TO-AIRPORT')),
  ]), [transferOptions])

  const transferTypes = useMemo((): Array<TransferType> => ([
    toHotelOptions.length > 0 && toAirportOptions.length > 0 ? 'ROUND-TRIP' : undefined,
    toHotelOptions.length > 0 ? 'AIRPORT-TO-HOTEL' : undefined,
    toAirportOptions.length > 0 ? 'HOTEL-TO-AIRPORT' : undefined,
  ].filter(Boolean) as Array<TransferType>), [toHotelOptions, toAirportOptions])

  const initialTransferType = useMemo((): TransferType => {
    if (defaultToHotelTransfer && defaultToAirportTransfer) {
      return 'ROUND-TRIP'
    } else if (defaultToHotelTransfer) {
      return 'AIRPORT-TO-HOTEL'
    } else if (defaultToAirportTransfer) {
      return 'HOTEL-TO-AIRPORT'
    }
    return 'ROUND-TRIP'
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [toHotel, setToHotel] = useState<App.ExperienceTransferView>(defaultToHotelTransfer ?? defaultHotelView)
  const [toAirport, setToAirport] = useState<App.ExperienceTransferView>(defaultToAirportTransfer ?? defaultAirportView)

  const [transferType, setTransferType] = useState<TransferType>(initialTransferType)
  const [sameLocation, toggleSameLocation] = useToggle(true)
  const [sameTravellers, toggleSameTravellers] = useToggle(true)
  const [sameBaggage, toggleSameBaggage] = useToggle(true)

  const isRoundTrip = transferType === 'ROUND-TRIP'
  const hasToHotelTransfer = isRoundTrip || transferType === 'AIRPORT-TO-HOTEL'
  const hasToAirportTransfer = isRoundTrip || transferType === 'HOTEL-TO-AIRPORT'

  useEffect(() => {
    if (isRoundTrip && (sameLocation || sameTravellers || sameBaggage)) {
      // we need to sync our changes to the airport object too if it's supposed to be the same
      setToAirport({
        ...toAirport,
        hotel: sameLocation ? toHotel.hotel : toAirport.hotel,
        travellers: sameTravellers ? toHotel.travellers : toAirport.travellers,
        baggage: sameBaggage ? toHotel.baggage : toAirport.baggage,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoundTrip, sameLocation, sameTravellers, sameBaggage, toHotel])

  const hasTicketValues = useMemo(() => {
    const hotelValidity = !!(toHotel.hotel.kmFromAirport)
    const airportValidity = !!(toAirport.hotel.kmFromAirport)

    switch (transferType) {
      case 'ROUND-TRIP':
        return hotelValidity || airportValidity
      case 'AIRPORT-TO-HOTEL':
        return hotelValidity
      case 'HOTEL-TO-AIRPORT':
        return airportValidity
    }
  }, [toHotel, toAirport, transferType])

  // Getting the best option for the user
  const { bestTransfer: bestToHotelOption, noTransferReason: noBestToHotelTransferReason } = useMemo(() => {
    return findBestTransfer(toHotel, toHotelOptions)
  }, [toHotel, toHotelOptions])

  const { bestTransfer: bestToAirportOption, noTransferReason: noBestToAirportTransferReason } = useMemo(() => {
    return findBestTransfer(toAirport, toAirportOptions)
  }, [toAirport, toAirportOptions])

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formValues = formToObject<TransferForm>(e.currentTarget)

    const purchaseToHotel: App.ExperienceTransferView | undefined = formValues.toHotel ? {
      ...toHotel,
      travellers: {
        ...toHotel.travellers,
        // if we have no children, reset the seats. We only kept them around in case the user
        // flicked back and forth between having children and not for convenience
        boosterSeats: toHotel.travellers.children ? toHotel.travellers.boosterSeats : 0,
        childSeats: toHotel.travellers.children ? toHotel.travellers.childSeats : 0,
      },
      baggage: {
        bags: toHotel.baggage?.bags ?? 0,
        oversizedBags: toHotel.baggage?.oversizedBags ?? 0,
      },
      type: formValues.toHotel.type,
      flightNumber: formValues.toHotel.flightNumber,
      time: formValues.toHotel.time,
      date: formValues.toHotel.date,
      option: bestToHotelOption,
      title: experience.title,
    } : undefined

    const purchaseToAirport: App.ExperienceTransferView | undefined = formValues.toAirport ? {
      ...toAirport,
      travellers: {
        ...toAirport.travellers,
        // if we have no children, reset the seats. We only kept them around in case the user
        // flicked back and forth between having children and not for convenience
        boosterSeats: toAirport.travellers.children && toAirport.travellers.children > 0 ? toAirport.travellers.boosterSeats : 0,
        childSeats: toAirport.travellers.children && toAirport.travellers.children > 0 ? toAirport.travellers.childSeats : 0,
      },
      baggage: {
        bags: toAirport.baggage?.bags ?? 0,
        oversizedBags: toAirport.baggage?.oversizedBags ?? 0,
      },
      type: formValues.toAirport.type,
      flightNumber: formValues.toAirport.flightNumber,
      time: formValues.toAirport.time,
      date: formValues.toAirport.date,
      option: bestToAirportOption,
      title: experience.title,
    } : undefined

    onSubmit([purchaseToHotel, purchaseToAirport].filter(Boolean))
  }

  const isFromPrice = !bestToHotelOption && !bestToAirportOption
  const totalPrice = useMemo(() => {
    if (isFromPrice) {
      return experience.price * (isRoundTrip ? 2 : 1)
    }

    const toHotelPrice = hasToHotelTransfer ? bestToHotelOption?.price ?? 0 : 0
    const toAirportPrice = hasToAirportTransfer ? bestToAirportOption?.price ?? 0 : 0
    return toHotelPrice + toAirportPrice
  }, [bestToAirportOption?.price, bestToHotelOption?.price, experience.price, hasToAirportTransfer, hasToHotelTransfer, isFromPrice, isRoundTrip])

  useEffect(() => {
    onPriceChange(totalPrice, isFromPrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, isFromPrice])

  return <VerticalSpacer gap={24} as="form" onSubmit={onFormSubmit} id={formId}>
    <VerticalSpacer gap={16}>
      <Select
        label="Transfer type"
        value={transferType}
        onChange={(e) => setTransferType(e.currentTarget.value as any)}
        required
      >
        {transferTypes.map(type =>
          <option key={type} value={type}>{TransferTypeLabels.get(type)}</option>,
        )}
      </Select>
      {hasToHotelTransfer && <VerticalSpacer gap={16} hidden={!hasToHotelTransfer}>
        <Heading variant="heading5">Airport pick up</Heading>
        <ExperienceTransferBookingForm
          transfer={toHotel}
          name="toHotel"
          onChange={setToHotel}
          experience={experience.originalExperience}
        />
        <ExperienceDetailsTransferBookingTravellers
          name="toHotel"
          transfer={toHotel}
          onChange={setToHotel}
          options={toHotelOptions}
        />
        <ExperienceDetailsTransferBookingBaggage
          name="toHotel"
          transfer={toHotel}
          onChange={setToHotel}
          options={toHotelOptions}
        />
      </VerticalSpacer>}
      {isRoundTrip && <Divider kind="primary" />}
      {hasToAirportTransfer && <VerticalSpacer gap={16}>
        <Heading variant="heading5">Airport drop off</Heading>
        {isRoundTrip && <CheckboxInput size="large" checked={sameLocation} onChange={toggleSameLocation}>
          Locations same as airport pick up
        </CheckboxInput>}
        <ExperienceTransferBookingForm
          transfer={toAirport}
          name="toAirport"
          disableLocations={sameLocation && isRoundTrip}
          onChange={setToAirport}
          experience={experience.originalExperience}
        />
        {isRoundTrip && <CheckboxInput size="large" checked={sameTravellers} onChange={toggleSameTravellers}>
          Travellers same as airport pick up
        </CheckboxInput>}
        {isRoundTrip && <CheckboxInput size="large" checked={sameBaggage} onChange={toggleSameBaggage}>
          Baggage same as airport pick up
        </CheckboxInput>}
        {(!isRoundTrip || !sameTravellers) && <ExperienceDetailsTransferBookingTravellers
          name="toAirport"
          transfer={toAirport}
          onChange={setToAirport}
          options={toAirportOptions}
        />}
        {(!isRoundTrip || !sameBaggage) && <ExperienceDetailsTransferBookingBaggage
          name="toAirport"
          transfer={toAirport}
          onChange={setToAirport}
          options={toAirportOptions}
        />}
      </VerticalSpacer>}
    </VerticalSpacer>
    {hasTicketValues && <>
      <Divider kind="primary" />
      <VerticalSpacer gap={12} style={{ position: 'relative' }}>
        <BodyText variant="large" weight="bold">Your selected airport transfer{isRoundTrip ? 's' : undefined}:</BodyText>
        {hasToHotelTransfer && <>
          {bestToHotelOption && <ExperienceTransferBookingOption transferTicket={bestToHotelOption} />}
          {noBestToHotelTransferReason && <ExperienceTransferBookingOptionError reason={noBestToHotelTransferReason} />}
        </> }
        {isRoundTrip && <Divider kind="primary" />}
        {hasToAirportTransfer && <>
          {bestToAirportOption && <ExperienceTransferBookingOption transferTicket={bestToAirportOption} />}
          {noBestToAirportTransferReason && <ExperienceTransferBookingOptionError reason={noBestToAirportTransferReason} />}
        </> }
        <HiddenInput
          required={(hasToHotelTransfer && !bestToHotelOption) || (hasToAirportTransfer && !bestToAirportOption)}
          invalidMessage="Please update your transfer details and try again."
        />
      </VerticalSpacer>
    </>}
  </VerticalSpacer>
}

export default ExperienceTransferMultipleBooking

import { getBreakdownTitle } from 'checkout/utils'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { arrayToMap, sortBy, sum } from 'lib/array/arrayUtils'
import { formatOccupantsShort, sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { formatRoomType, formatPackageOptionType } from 'lib/tours/tourUtils'
import generateDepartureRoomPriceBreakdown from 'lib/tours/generateDepartureRoomPriceBreakdown'
import moment from 'moment'
import { createSelector } from 'reselect'
import {
  isTourItem,
  isTourV2ExperienceItem,
  isTourV2Item,
  isTourV2ItemWithPackageUpgrade,
} from 'lib/checkout/checkoutUtils'
import { getTourExperienceV2ItemView } from 'checkout/lib/utils/tours/view'
import { tourV2ExperienceWithExistingOrder } from 'api/mappers/TourV2/tourV2OfferMapper'
import { POST_PURCHASE_TOUR_CHANGE_DATES, POST_PURCHASE_TOUR_EXPERIENCE } from 'constants/checkout'
import { TOUR_OPTIONAL_EXPERIENCE_RESERVATION_STATUS_CANCELLED } from 'constants/tours'

const TOUR_POST_PURCHASES = [POST_PURCHASE_TOUR_CHANGE_DATES, POST_PURCHASE_TOUR_EXPERIENCE]

export const getTourV2ItemsForPackageUpgrade = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.TourV2Item> => items.filter(isTourV2ItemWithPackageUpgrade),
)

export const getTourV2Items = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.TourV2Item> => items.filter(isTourV2Item),
)

export const getTourV2ExperienceItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.TourV2ExperienceItem> => items.filter(isTourV2ExperienceItem),
)

export const getTourItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.TourV2Item | App.Checkout.LETourV1Item> => items.filter(isTourItem),
)

export const getExistingTourExperienceItems = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.offer.tourV2Offers,
  (existingOrder, tourOffers): Map<String, App.Checkout.TourV2ExperienceItem> => {
    const tourOffer = existingOrder?.tourItems[0]?.tourId ? tourOffers[existingOrder?.tourItems[0]?.tourId] : undefined
    if (!!existingOrder?.tourExperienceItems?.length && tourOffer?.purchasableExperienceOptions) {
      return arrayToMap(
        existingOrder?.tourExperienceItems?.filter((tourExperienceItem => tourExperienceItem.status !== TOUR_OPTIONAL_EXPERIENCE_RESERVATION_STATUS_CANCELLED)),
        (item: Tours.TourV2OrderExperienceItem) => `${item.id}_${item.dayNumber}`,
        (item: Tours.TourV2OrderExperienceItem) => {
          return tourV2ExperienceWithExistingOrder(item, tourOffer?.purchasableExperienceOptions[item.id])
        },
      )
    }
    return new Map()
  },
)

export function getTourV2BreakdownView(
  offerView: App.Checkout.TourV2AccommodationOfferView,
  hidePrice: boolean,
  postPurchase?: App.PostPurchase,
  commissionData?: App.AgentHubCommission,
): App.Checkout.PriceBreakdownView {
  const { durationLabel, startDate, endDate, saleUnit, itemViews, offer, mainLabel } = offerView
  const isTourPostPurchase = TOUR_POST_PURCHASES.includes(postPurchase ?? '')
  const isOriginalOrderItem = itemViews.some(itemView => itemView.item.isOriginalItem)

  const guestCount = sumUpOccupancies(itemViews.map(itemView => itemView.occupancy))

  const items = itemViews.map<App.Checkout.TourV2ItemBreakdownView>((item: App.Checkout.TourV2AccommodationItemView, index) => {
    const purchasableOption = item.item.purchasableOption
    const { totalPrice, totalMemberPrice } = generateDepartureRoomPriceBreakdown(
      item.occupancy,
      purchasableOption,
      offer,
    )
    const adultLabel = offer?.isLeTour ? 'traveller' : 'adult'
    const packageType = purchasableOption?.roomType ? formatPackageOptionType(item.item.offerId)(purchasableOption.roomType) : undefined
    return {
      title: `${saleUnit} ${index + 1}`,
      additionalInfoText: [
          `${formatOccupantsShort(item.occupancy, undefined, adultLabel)}${purchasableOption.roomType ? ' • ' + formatRoomType(purchasableOption.roomType) : ''}`,
      ],
      additionalElements: [],
      price: hidePrice ? undefined : totalPrice,
      memberPrice: hidePrice ? undefined : totalMemberPrice,
      offerId: offer?.id,
      itemId: item.item.itemId,
      itemType: 'tourV2',
      taxesAndFees: item.taxesAndFees,
      packageOption: packageType ?? undefined,
    }
  })
  const purchasableOption = itemViews?.[0]?.item?.purchasableOption
  const selectedVariation = offer?.variations[purchasableOption?.fkVariationId] ??
    Object.values(offer?.variations ?? {}).find(variation => variation.fkTourOptionId === purchasableOption?.fkVariationId)
  const totalDiscount = !commissionData ? 0 : sum(items, item => commissionData.itemsCommission[item.offerId || '']?.itemDiscount ?? 0)
  const commissionPercentage = Number((totalDiscount / sum(items, item => item.price || 0) * 100).toFixed(2))
  return {
    title: getBreakdownTitle(offerView),
    offerType: offer?.type,
    description: mainLabel,
    additionalInfoText: [
        `${moment(startDate).format(DMY_CASUAL_FORMAT)} - ${moment(endDate).format(DMY_CASUAL_FORMAT)}`,
        `${durationLabel} (${pluralizeToString('room', itemViews.length)} • ${pluralizeToString('guest', guestCount)})`,
    ],
    items: isTourPostPurchase && isOriginalOrderItem ? [] : items,
    itemsAlwaysVisible: items.length === 1,
    alwaysShowItemPrice: !hidePrice,
    price: sum(items, item => item.price || 0),
    memberPrice: sum(items, item => item.memberPrice || 0),
    variation: selectedVariation,
    ...(commissionData && offer?.id && {
      commission: {
        commissionPercentage,
        itemDiscount: totalDiscount,
      },
    }),
  }
}

export const getTourV2ExperienceItemsViews = createSelector(
  getTourV2ExperienceItems,
  (tourExperienceItems): App.WithDataStatus<Array<App.Checkout.TourV2ExperienceItemView>> => {
    if (tourExperienceItems.length === 0) { return { hasRequiredData: true, data: [] } }
    const tourExperienceItemView = sortBy(
      tourExperienceItems.map(item => getTourExperienceV2ItemView(item)),
      item => item.item.purchasableOption.day,
      'asc',
    )
    return {
      hasRequiredData: true,
      data: tourExperienceItemView,
    }
  },
)

export const getTourExperienceV2BreakdownView = createSelector(
  getTourV2ExperienceItemsViews,
  (viewWithStatus): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }
    const items = viewWithStatus.data.map<App.Checkout.TourV2ExperienceItemBreakdownView>((item: App.Checkout.TourV2ExperienceItemView) => {
      const { purchasableOption, occupants } = item.item
      return {
        title: item.mainLabel,
        additionalInfoText: [
          `Day ${purchasableOption.day} • ${moment(purchasableOption.date).format(DMY_CASUAL_FORMAT)} • ${purchasableOption.timeSlot ?? ''} (${pluralizeToString('guest', sumUpOccupancies(occupants))})`,
        ],
        additionalElements: [],
        price: item.item.total,
        offerId: item.item.offerId,
        itemId: item.item.purchasableOption.fkExperienceId,
        itemType: 'tourV2Experience',
        taxesAndFees: item.taxesAndFees,
      }
    })

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: [{
        title: 'Optional experiences',
        offerType: 'tour_experience',
        description: '',
        additionalInfoText: [pluralizeToString('experience', items.length)],
        items,
        itemsAlwaysVisible: false,
        price: sum(items, item => item.price || 0),
        memberPrice: sum(items, item => item.memberPrice || 0),
      }],
    }
  })

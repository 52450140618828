import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { getCruiseExtraFilters, getGlobalFacetFilters } from 'lib/cruises/cruiseUtils'
import { rem } from 'polished'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import CruiseAllLocationItem from './CruiseAllLocationsItem'
import CruiseLocationItem from './CruiseLocationItem'
import useQueryParams from 'hooks/useQueryParams'
import { omitKeys } from 'lib/object/objectUtils'

const LocationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(${rem(288)},1fr));
  column-gap: ${rem(40)};
`

interface Props {
  locations: Array<App.CruiseSearchFacet>;
  searchTerm: string;
  searchItems: Array<App.SearchItem>;
  isAllSelected: boolean;
  onChange: (values: Array<App.SearchItem>) => void;
  onSelectAll: () => void;
  inputSearchRef?: React.RefObject<HTMLInputElement>;
}

function CruiseLocationContent(props: Props) {
  const {
    locations,
    searchTerm,
    searchItems,
    isAllSelected,
    onChange,
    onSelectAll,
    inputSearchRef,
  } = props
  const queryParams = useQueryParams()
  const cruiseExtraFilters = getCruiseExtraFilters(queryParams)
  const globalFilters = useContext(GlobalSearchStateContext)
  const facetFilters = getGlobalFacetFilters(globalFilters)
  const [filteredFacets] = useCruiseSearchFacets({
    facetTypes: ['destination_ports'],
    ...omitKeys(['destinationId', 'destinationIds', 'destinationName', 'destinationNames'], { ...cruiseExtraFilters, ...facetFilters }),
  })

  const searchLocations = useMemo(() => locations.map((location) => {
    const [shortName, ...info] = location.name.split(', ')

    const locationName = location.isTrend ? location.name : shortName
    const locationInfo = location.isTrend ? '' : info.join(', ')

    const searchItem = {
      searchType: 'destination',
      value: location.value as string,
      format: {
        mainText: locationName,
        secondaryText: locationInfo,
      },
    } as App.SearchItem

    const isSelected = (
      (isAllSelected && !searchTerm.length) ||
      searchItems.some(({ value }) => value === searchItem.value)
    )

    const isDisabled = !filteredFacets.some(({ value }) => value === location.value)

    return {
      searchItem,
      imageId: location.imageId,
      isTrend: location.isTrend,
      isSelected,
      isDisabled,
    }
  }), [locations, isAllSelected, searchItems, searchTerm, filteredFacets])

  useEffect(() => {
    if (inputSearchRef?.current) {
      inputSearchRef.current.focus({ preventScroll: true })
    }
  }, [inputSearchRef])

  const handlerSelectItem = useCallback((item: App.SearchItem, isRemoving?: boolean) => {
    if (isAllSelected && isRemoving) {
      // When all items are selected and one is removed, it should add all trend destinations except the removed one
      const newSearchItems = searchLocations
        .filter(({ isTrend, searchItem }) => isTrend && searchItem.value !== item.value)
        .map(({ searchItem }) => searchItem)

      onChange(newSearchItems)
      return
    }

    const newSearchItems = isRemoving ? searchItems.filter(({ value }) => value !== item.value) : [...searchItems, item]
    onChange(newSearchItems)
  }, [searchItems, searchLocations, isAllSelected, onChange])

  return <LocationGrid>
    {!searchTerm && <CruiseAllLocationItem
      isSelected={isAllSelected}
      onSelectAll={onSelectAll}
    />}

    {searchLocations.map(({ searchItem, imageId, isSelected, isDisabled }) => <CruiseLocationItem
      key={searchItem.value}
      imageId={imageId}
      searchItem={searchItem}
      onChange={handlerSelectItem}
      isSelected={isSelected}
      isDisabled={isDisabled}
      isSearching={!!searchTerm}
      searchTerm={searchTerm}
    />)}
  </LocationGrid>
}

export default CruiseLocationContent

import { getTourV2IdForCheckoutItem, isTourV2Item } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import { addTotals } from 'checkout/lib/utils/payments/totals'
import { getTourV2ExperienceItemsViews } from 'checkout/selectors/view/toursv2'

export const cartIncludesTourV2 = (state: App.State) => state.checkout.cart.items.some(isTourV2Item)

export const isCartItemLeTour = (state: App.State) => {
  const cartItems = state.checkout.cart.items
  const tourV2Offers = state.offer.tourV2Offers
  const item = cartItems.find(isTourV2Item)
  if (!item) {
    return false
  }
  const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
  return offer?.isLeTour
}

export const getTourExperienceTotals = createSelector(
  getTourV2ExperienceItemsViews,
  (offerViewsWithStatus): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const tourExperienceTotalView = offerViewsWithStatus.data.map<App.WithDataStatus<App.Checkout.ItemTotals>>(item =>
      ({ hasRequiredData: offerViewsWithStatus.hasRequiredData, data: item }),
    )
    return {
      hasRequiredData: offerViewsWithStatus.hasRequiredData,
      data: addTotals(tourExperienceTotalView).data,
    }
  },
)

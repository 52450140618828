import { CreateTripItemRequest } from 'tripPlanner/api/tripItem/types'
import { TripItem } from 'tripPlanner/types/tripItem'
import { assertUnreachable } from 'tripPlanner/utils'
import {
  nullBookedDetails,
  nullEndDates,
  nullStartDates,
} from 'tripPlanner/utils/nullify'

export function itemToCuratedItemPayload(
  tripItem: TripItem,
): CreateTripItemRequest {
  const {
    id: templateItemId,
    tripId: templateId,
    createdBy,
    updatedBy,
    createdAt,
    updatedAt,
    userUpdatedAt,
    sortableName,
    ...item
  } = tripItem

  switch (item.type) {
    case 'RESTAURANT_BAR':
    case 'ATTRACTION':
      return {
        ...item,
        ...nullStartDates,
        ...nullBookedDetails,
        templateId,
        templateItemId,
      }
    case 'EVENT':
    case 'EXPERIENCE':
    case 'ACCOMMODATION':
    case 'BUS':
    case 'FERRY':
    case 'OTHER_TRANSPORT':
    case 'TRAIN':
    case 'THING_TO_DO':
    case 'FLIGHT':
    case 'CAR_RENTAL':
    case 'CRUISE':
    case 'TOUR':
      return {
        ...item,
        ...nullStartDates,
        ...nullEndDates,
        ...nullBookedDetails,
        templateId,
        templateItemId,
      }
    case 'NOTE':
      return {
        ...item,
        ...nullStartDates,
        ...nullEndDates,
        ...nullBookedDetails,
        isSectionHeader: !!item.isSectionHeader,
        templateId,
        templateItemId,
      }
    default:
      return assertUnreachable(item)
  }
}

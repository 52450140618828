import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePlaneDepartureIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22.0001 5.08a3.0802 3.0802 0 0 0-5.26-2.18l-2.81 2.81-6.49-2.16a1 1 0 0 0-1 .24L4.06 6.15a1 1 0 0 0 .29 1.61l5.18 2.35-2.6 2.6-1.71-.86a1.0003 1.0003 0 0 0-1.16.15l-1.77 1.81a.9999.9999 0 0 0 0 1.41l6.49 6.49a1.0002 1.0002 0 0 0 1.41 0L12 19.94a1.0004 1.0004 0 0 0 .19-1.16l-.86-1.71 2.6-2.6 2.35 5.18a.9995.9995 0 0 0 1.2031.5339.9993.9993 0 0 0 .4069-.2439l2.36-2.36a1.0005 1.0005 0 0 0 .24-1l-2.16-6.49 2.77-2.83a3.05 3.05 0 0 0 .9-2.18Zm-2.32.77-3.24 3.24a.9999.9999 0 0 0-.24 1l2.16 6.48-.9.9-2.35-5.17a1.0001 1.0001 0 0 0-.73-.57.9999.9999 0 0 0-.89.28l-4.12 4.16a1.0002 1.0002 0 0 0-.19 1.15L10 19l-.56.56-5.03-5.04L5 14l1.71.86a1.0002 1.0002 0 0 0 1.15-.19L12 10.51a1.0001 1.0001 0 0 0-.29-1.62L6.5 6.54l.9-.9 6.48 2.16a1 1 0 0 0 1-.24l3.24-3.24a1.0691 1.0691 0 0 1 .765-.3219 1.0694 1.0694 0 0 1 .765.3219 1.0008 1.0008 0 0 1 .32.76 1.0605 1.0605 0 0 1-.29.77Z"/>
  </SvgIcon>
}

export default LinePlaneDepartureIcon

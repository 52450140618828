import { createSelector } from 'reselect'
import { eligibleLuxPlusCheckoutNotGift } from 'checkout/selectors/view/luxPlusSubscription'
import { prioritisedSubscriptionItem } from 'selectors/luxPlusSelectors'
import { getAccommodationItems } from 'checkout/selectors/view/accommodation'
import { excludeNullOrUndefined } from 'checkout/utils'
import { getIsLuxPlusLPPEnabled } from '../featureToggle'

function mapSubscriptionBenefitType(config: App.OfferLuxPlusConfig): App.OrderSubscriptionBenefit['type'] | undefined {
  if (config?.access === 'earlyAccess') return 'early_access'
  if (config?.access === 'memberOnly') return 'exclusive_offers'
}

// We currently only use this to record a redeemed benefit for early access or member only offers
const getOrderSubscriptionBenefits = createSelector(
  getAccommodationItems,
  (state: App.State) => state.offer.offers,
  (items, offers): Array<App.OrderSubscriptionBenefit> => {
    return items.map((item) => {
      const offer = offers?.[item.offerId]
      const type = offer?.luxPlus ? mapSubscriptionBenefitType(offer.luxPlus) : undefined

      if (!type) return undefined

      return {
        type,
        itemTransactionKey: item.transactionKey,
        /*
          Order maps the itemType 'hotel' to 'accommodation'
          but this is not set up for the subscriber benefits so
          hard-coding this for now
        */
        itemType: 'accommodation',
        amount: 1,
        unit: 'units' as const,
      }
    }).filter(excludeNullOrUndefined)
  },
)

export const getOrderSubscriber = createSelector(
  eligibleLuxPlusCheckoutNotGift,
  prioritisedSubscriptionItem,
  getOrderSubscriptionBenefits,
  (eligibleLuxPlusCheckoutNotGift, subscriptionItem, benefits): App.OrderSubscriber | undefined => {
    if (eligibleLuxPlusCheckoutNotGift) {
      return {
        benefits,
        tier: subscriptionItem?.tier ?? 'base',
      }
    }
    return undefined
  },
)

export const getEnquirySubscriber = createSelector(
  getOrderSubscriber,
  getIsLuxPlusLPPEnabled,
  (getOrderSubscriber, luxPlusLPPEnabled): App.OrderSubscriber | undefined => {
    if (luxPlusLPPEnabled) {
      return getOrderSubscriber
    }
    return undefined
  },
)
